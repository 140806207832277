
/* injects from baggage-loader */
require('./bulk-template-wizard-modal.component.html');

import angular from 'angular';
import { ChildMessagePort } from '../../utilities/messaging';
import { getUrlComponents } from '../../utilities/url';

import "./bulk-template-wizard-modal.component.scss";

angular.module('AaApp.Opp').component('bulkTemplateWizardModal', {
    templateUrl: '/app/components/modals/bulk-template-wizard-modal.component.html',
    controller: BulkTemplateWizardModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

function BulkTemplateWizardModalController(
    $element,
    $logFactory,
    $sce,
    $scope
) {
    "ngInject";
    const $ctrl = this;
    const $log = $logFactory.get('bulkTemplateWizard');

    $ctrl.$onInit = $onInit;

    const PASSTHROUGH_MESSAGE_TYPES = [
        "SEARCH_PARAMETERS_REQ",
    ];

    $ctrl.buttons = {
        "BACK": {
            translationKey: 'back',
            onClick: onButtonClicked.bind(null, "BACK"),
        },
        "NEXT": {
            translationKey: 'next',
            onClick: onButtonClicked.bind(null, "NEXT"),
        },
        "CLOSE": {
            translationKey: 'close',
            onClick: onButtonClicked.bind(null, "CLOSE"),
        },
        "CANCEL": {
            translationKey: 'cancel',
            onClick: onButtonClicked.bind(null, "CANCEL"),
        },
        "SEND_EMAIL": {
            translationKey: 'sendEmail',
            onClick: onButtonClicked.bind(null, "SEND_EMAIL"),
        },
        "ARCHIVE_PDF": {
            translationKey: 'archivePdfAndClose',
            onClick: onButtonClicked.bind(null, "ARCHIVE_PDF"),
        },
        "PRINT_PDF": {
            translationKey: 'printToPdf',
            onClick: onButtonClicked.bind(null, "PRINT_PDF"),
        },
    };

    function $onInit() {
        $ctrl.title = $ctrl.resolve.title;

        $ctrl.trustedUrl = $sce.trustAsResourceUrl($ctrl.resolve.src);
        const { origin } = getUrlComponents($ctrl.resolve.src);

        if ($ctrl.resolve.onRendered) {
            $ctrl.resolve.onRendered.promise.then(() => {
                const iframe = $element.find('iframe')[0];
                $ctrl.port = new ChildMessagePort(iframe, origin, $scope);

                $ctrl.port.message$.subscribe(messageHandler);

                $ctrl.$onDestroy = () => {
                    $ctrl.port.close();
                };
            });
        }
    }

    function onButtonClicked(button) {
        $ctrl.port.postMessage({
            type: "DIALOG_BUTTON_CLICKED",
            data: { button }
        })
    }

    function getSearchGridMessagePort() {
        return $ctrl.resolve.searchGridMessagePort;
    }

    function messageHandler(message) {
        if (PASSTHROUGH_MESSAGE_TYPES.includes(message.type)) {
            const port = getSearchGridMessagePort();
            const replyPort = message.replyPort;
            const forwardedMessage = { type: message.type, data: message.data };
            port.postRequest(forwardedMessage)
                .then(reply => {
                    $log.log("forwarding", reply)
                    replyPort.postMessage({ type: reply.type, data: reply.data });
                });

        } else {
            switch (message.type) {
                case "DIALOG_TITLE": {
                    const { text } = message.data;
                    $log.debug("changing title", text);
                    $ctrl.title = text;
                    break;
                }

                case "DIALOG_BUTTON_CONFIGURE": {
                    const { button, state } = message.data;
                    $log.debug("changing buttons", button, state);
                    setButtonStateFromText(button, state);
                    break;
                }

                case "DIALOG_BUTTON_CLICK": {
                    const { button } = message.data;
                    $log.debug("clicking button", button);
                    onButtonClicked(button);
                    break;
                }

                case "DIALOG_CLOSE": {
                    $log.debug("closing dialog")
                    $ctrl.close();
                    break;
                }

                case "ALL_DIALOG_BUTTON_LOCK_UNLOCK": {                    
                    allButtonLockUnlock();
                    break;
                }

                default:
                    $log.debug("Unhandled message type", message);
            }
        }
    }

    function allButtonLockUnlock() {        
        $ctrl.buttons.CANCEL.disabled = !$ctrl.buttons.CANCEL.disabled;
        $ctrl.buttons.ARCHIVE_PDF.disabled = !$ctrl.buttons.ARCHIVE_PDF.disabled;
        $ctrl.buttons.PRINT_PDF.disabled = !$ctrl.buttons.PRINT_PDF.disabled;
        $ctrl.buttons.SEND_EMAIL.disabled = !$ctrl.buttons.SEND_EMAIL.disabled;
    }

    function setButtonStateFromText(button, stateName) {
        const currentState = $ctrl.buttons[button];
        $ctrl.buttons[button] = getState(currentState, stateName);

        function getState(currentState, newStateName) {
            switch (newStateName.toUpperCase()) {
                case "VISIBLE":  return { ...currentState, visible: true };
                case "HIDDEN":   return { ...currentState, visible: false };
                case "ENABLED":  return { ...currentState, visible: true, disabled: false };
                case "DISABLED": return { ...currentState, visible: true, disabled: true };
            }
        }
    }
}
