
/* injects from baggage-loader */
require('./main-menu.component.html');

import angular from 'angular';
import _ from 'lodash';
import { ProductTypes as productTypes } from '../../constants';
import {OpportunitiesModule} from '../../core/opportunities.module'

OpportunitiesModule.component('mainMenu', {
        templateUrl: '/app/components/header/main-menu.component.html',
        controller: MainMenuController,
        bindings: {
            isEulaAccepted: '<',
            selectedTab: '<',
            productId: '<',
            menu: '<'
        }
});

/* @ngInject */
function MainMenuController(storageService, userService) {
        var $ctrl = this;

        $ctrl.moduleIds;
        $ctrl.isSltUser;
        $ctrl.$onChanges = onChanges;

        function onChanges(changes) {
            if (changes.menu && changes.menu.currentValue || changes.productId) {
                removeInaccessibleMenuItems();
            }
        }

        function removeInaccessibleMenuItems() {
            if ($ctrl.productId == productTypes.euroLite){
                _.remove($ctrl.menu, {name: 'social'});
                _.remove($ctrl.menu, {name: 'campaigns'});
                _.remove($ctrl.menu, {name: 'communication'});
            }

            if ($ctrl.isSltUser == null) {
                userService.getUserProfile()
                    .then(userProfile => {
                        $ctrl.isSltUser = userProfile.isSltUser;

                        if ($ctrl.isSltUser) {
                            _.remove($ctrl.menu, {name: 'social'});
                            _.remove($ctrl.menu, {name: 'campaigns'});
                        }
                    });
            }

            // WEBUI-7966 for now just remove in this component. Not clear if it might come back.
            // At some point we can totally remove from appSettings. 07/15/2019
            _.remove($ctrl.menu, { name: 'social' });
        }

        function convertToModuleIds(modules, dealerIds) {
            return modules.filter(function (module) {
                return _.some(_.intersection(module.moduleDealerIds, dealerIds))
            })
            .map(function (module) { return module.moduleId })
        }
}
