import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxModalWrapperService } from '../../../services/ngx-modal-wrapper.service';
import { NloInfoModalComponent } from '..//nlo-info-modal/nlo-info-modal.component';

@Component({
    selector: 'nlo-info',
    templateUrl: './nlo-info.component-ng.html',
    styleUrls: ['./nlo-info.component.scss']
})
export class NloInfoComponent implements OnInit {
    @Input() entityId: string;
    @Input() nloData: any[];
    @Output() onMarkNlo = new EventEmitter<any>();
    bsModalRef: BsModalRef;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService) { }

    ngOnInit() {
        // open the modal on initial load of deal sheet if any data
        if (this.nloData && this.nloData.length > 0) {
            this.nloInfoModal();
        }
    }

    public nloInfoModal() {
        const initialState = {
            entityId: this.entityId,
            nloData: this.nloData
        };

        this.bsModalRef = this.modalService.show(NloInfoModalComponent, { initialState, class: 'nlo-info-modal' });

        this.bsModalRef.content.onClosed.subscribe((result: any) => {
            this.onMarkNlo.emit({ markAsNLO: result.markasNLO });
        });
    }
}
