import "core-js/modules/es6.array.find";
SearchExportModalController.$inject = ["$element", "$scope", "$sce", "legacy", "$logFactory"];

/* injects from baggage-loader */
require('./search-export-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url";
import { ChildMessagePort } from '../../utilities/messaging';
import './search-export-modal.component.scss';
angular.module('AaApp.Opp').component('searchExportModal', {
  templateUrl: '/app/components/search/search-export-modal.component.html',
  controller: SearchExportModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function SearchExportModalController($element, $scope, $sce, legacy, $logFactory) {
  var $ctrl = this;
  var log = $logFactory.get();
  $ctrl.submit = submit;
  $ctrl.$onInit = onInit;
  var originalIframeExportUrl = legacy.iframeUrl + "/AlertDesk/Search/Export";
  $ctrl.selectedDealerId;
  $ctrl.iframeUrl;

  function onInit() {
    var rendered = $ctrl.resolve.renderedPromise.rendered;

    if ($ctrl.resolve.renderedPromise.selectedDealerId === undefined) {
      $ctrl.selectedDealerId = 0;
    } else {
      $ctrl.selectedDealerId = $ctrl.resolve.renderedPromise.selectedDealerId;
    }

    $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeExportUrl + "?lm=Export&dealerID=" + $ctrl.selectedDealerId + "&fa=" + $ctrl.resolve.isFordAlert);
    rendered.then(function () {
      var iframeExport = $element.find(".ifrmSearchExport")[0];
      var originExport = getUrlComponents(originalIframeExportUrl).origin;
      $ctrl.messageChannelExport = new ChildMessagePort(iframeExport, originExport, $scope);
      var subscription = $ctrl.messageChannelExport.subscribe(function (_ref, replyPort) {
        var data = _ref.data;

        if (data.type === "EXPORT_SAVE_LAYOUT_COMPLETED") {
          log.log("EXPORT_SAVE_LAYOUT_COMPLETED message received in Portal.");
          $ctrl.close({
            $value: {
              exportLayout: data.data.layoutData
            }
          });
        }
      });
    });
  }

  function submit() {
    var msg = {
      type: "EXPORT_SAVE_LAYOUT",
      data: {
        selectedDealerId: $ctrl.selectedDealerId
      }
    };
    $ctrl.messageChannelExport.postMessage(msg);
  }
}