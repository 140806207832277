"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var CustomerWorkflowClientService = /** @class */ (function (_super) {
    __extends(CustomerWorkflowClientService, _super);
    function CustomerWorkflowClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param entityId undefined
     * @return Success
     */
    CustomerWorkflowClientService.prototype.GetStartListCustomerByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/CustomerWorkflow/GetStartListCustomer/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    CustomerWorkflowClientService.prototype.GetStartListCustomerByEntityidGET = function (entityId) {
        return this.GetStartListCustomerByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams` containing the following parameters:
     *
     * - `workflowTemplateId`:
     *
     * - `entityId`:
     *
     * - `form`:
     *
     * @return Success
     */
    CustomerWorkflowClientService.prototype.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.form;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/CustomerWorkflow/StartCustomerWorkflow/" + params.entityId + "/" + params.workflowTemplateId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams` containing the following parameters:
     *
     * - `workflowTemplateId`:
     *
     * - `entityId`:
     *
     * - `form`:
     *
     * @return Success
     */
    CustomerWorkflowClientService.prototype.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOST = function (params) {
        return this.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param startableFormTemplateId undefined
     * @return Success
     */
    CustomerWorkflowClientService.prototype.GetStartFormByStartableformtemplateidGETResponse = function (startableFormTemplateId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/CustomerWorkflow/GetStartForm/" + startableFormTemplateId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param startableFormTemplateId undefined
     * @return Success
     */
    CustomerWorkflowClientService.prototype.GetStartFormByStartableformtemplateidGET = function (startableFormTemplateId) {
        return this.GetStartFormByStartableformtemplateidGETResponse(startableFormTemplateId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    CustomerWorkflowClientService.GetStartListCustomerByEntityidGETPath = '/api/CustomerWorkflow/GetStartListCustomer/{entityId}';
    CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTPath = '/api/CustomerWorkflow/StartCustomerWorkflow/{entityId}/{workflowTemplateId}';
    CustomerWorkflowClientService.GetStartFormByStartableformtemplateidGETPath = '/api/CustomerWorkflow/GetStartForm/{startableFormTemplateId}';
    CustomerWorkflowClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerWorkflowClientService_Factory() { return new CustomerWorkflowClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: CustomerWorkflowClientService, providedIn: "root" });
    return CustomerWorkflowClientService;
}(base_service_1.BaseService));
exports.CustomerWorkflowClientService = CustomerWorkflowClientService;
