
/* injects from baggage-loader */
require('./discrepancy-input-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('discrepancyInputModal', {
    templateUrl: '/app/components/deal-sheet/modals/discrepancy-input-modal.component.html',
    controller: DiscrepancyInputModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function DiscrepancyInputModalController() {
    var $ctrl = this;

    $ctrl.discrepancy;
    $ctrl.discrepancyValid;
    $ctrl.setDiscrepancy = setDiscrepancy;

    function setDiscrepancy(discrepancy, valid) {
        $ctrl.discrepancy = discrepancy;
        $ctrl.discrepancyValid = valid;
    }
}
