
/* injects from baggage-loader */
require('./service-drive-offers.component.html');

import angular from 'angular';

import './service-drive.scss';

angular.module('AaApp.Opp').component('serviceDriveOffersComponent', {
    templateUrl: '/app/components/service-drive/service-drive-offers.component.html',
    controller: ServiceDriveOffersController,
    bindings: {

    }
});

/* @ngInject */
function ServiceDriveOffersController(
    // angular and 3rd party modules
    _, $filter, $timeout, $sce, $rootScope, moment, $state, $window,
    //translate
    $translate, $translatePartialLoader, localePaths, printOfferBatchService, translateErrorKeys, warningTitleKey,
    //other providers
    alertTypes, dealSheetService, logger, searchService, legacy, dealSheetUrl, dealerService) {
    var $ctrl = this;

    var offers = [];

    $ctrl.setFilters = setFilters;
    $ctrl.$onInit = oneTimeInit();
    if (dealerService.dealersOneToOneStatusLoaded) {
        reset();
    }
    $rootScope.$on('RUN_USER_INIT.ONE_TO_ONE_READY', function onSiteMapLoaded() {
        var dealers = dealerService.getOneToOneEnabledDealerIds();
        //console.log({ n: 'RUN_USER_INIT.ONE_TO_ONE_READY', d: dealers, ds: dealerService.dealersOneToOneStatusLoaded });
        if (dealers.length === 0) {
            $state.go('notFound');
        } else {
            if (!$ctrl.isReset) {
                reset();
            }
        }
    });

    function oneTimeInit() {
        //one time init
        $translatePartialLoader.addPart(localePaths.general);
        $translatePartialLoader.addPart(localePaths.error);
        $translatePartialLoader.addPart(localePaths.serviceDrive);
        $ctrl.resetButton = reset;
        $ctrl.isReset = false;

        $ctrl.alertStyle = alertStyle;
        $ctrl.clickTableRow = clickTableRow;
        $ctrl.filteredOffers = offers;
        $ctrl.getLatestDate = getLatestDate;
        $ctrl.openDealSheet = openDealSheet;
        $ctrl.printSelected = printSelected;
        $ctrl.resetSearchText = resetSearchText;
        $ctrl.showFutureAppointments = showFutureAppointments;
        $ctrl.toggleAllRows = toggleAllRows;
        $ctrl.updateOffers = updateOffers;

        $ctrl.iframeSource = $sce.trustAsResourceUrl(legacy.iframeUrl + '/Home/ContactUs');
    }

    function alertStyle(alert) {
        return "fa fa" + alert;
    }

    function clickTableRow(offer, e) {
        if (e.target.tagName === 'A') {
            var entityIds = [offer.entityID];
            initiatePrint(entityIds);
        }
        else if (e.target.tagName === 'INPUT') {
            if (e.target.checked) {
                $ctrl.selectedEntityIds.push(offer.entityID);
            }
            else {
                $ctrl.selectedEntityIds = $filter('filter')($ctrl.selectedEntityIds, function (value, index) {
                    return value !== offer.entityID;
                });
            }
        }
        else {
            $ctrl.openDealSheet(offer.entityID);
        }
    }

    function getLatestDate(offer) {
        if (offer.roDate && !offer.scanDate) {
            return $filter('localeDateTime')(offer.roDate);
        } else if (offer.scanDate && !offer.roDate) {
            return $filter('localeDateTime')(offer.scanDate);
        } else if (!offer.scanDate && !offer.roDate) {
            return null;
        } else if (offer.scanDate && offer.roDate) {
            var scanDatePartOnly = moment(offer.scanDate).startOf('day');
            var roDatePartOnly = moment(offer.roDate).startOf('day');
            if (scanDatePartOnly.isAfter(roDatePartOnly)) {
                //only if scan datePart is later date, take it over roDate
                return $filter('localeDateTime')(offer.scanDate);
            }
            else {
                //even if same date, take roDate over scanDate
                return $filter('localeDateTime')(offer.roDate);
            }
        }
    }


    function getOneToOneEntities() {

        $ctrl.getOneToOneEntitiesInProgress = true;

        var params = {};

        var dealers = dealerService.getOneToOneEnabledDealerIds();

        if (dealers.length > 0) {
            params.dealers = dealers;
        } else {
            $state.go('notFound');
        }
        $ctrl.isReset = true;

        params.fromDate = moment($ctrl.dateStart).startOf('day').toDate();
        params.toDate = new Date(params.fromDate);
        params.toDate.setDate(params.fromDate.getDate() + 1);
        //params.toDate = moment(params.fromDate).add(1, 'days').date();
        params.offset = (params.fromDate.getTimezoneOffset()) * (-1);
        //params.offset = params.fromDate.zone() * (-1);
        params.alerts = _.map($ctrl.selectedAlertTypes, 'value');

        $ctrl.getEntitiesPromise = searchService.getOneToOneEntities(params).then(function (data) {
            $ctrl.filteredOffers = data.map(offer => ({
                ...offer,
                alerts: offer.alerts.map(type => ({ type }))
            }));

            offers = $ctrl.filteredOffers;
            resetSearchText();
            $ctrl.getOneToOneEntitiesInProgress = false;
        });
    }

    function openDealSheet(entityId) {
        dealSheetService.openDealSheetModal(entityId);
    }

    function setFilters(result) {
        $ctrl.dateStart = result.dateStart;
        $ctrl.dateEnd = new Date();
        $ctrl.dateEnd.setDate($ctrl.dateStart.getDate() + 1);
        $ctrl.selectedAlertTypes = _.sortBy(result.selectedAlertTypes, 'value');

        getOneToOneEntities();
    }

    function initiatePrint(entityIds) {
        $ctrl.initiatePrintPromise = printOfferBatchService.initiatePrint(entityIds)
            .then(function (data) {
                if (data) {
                    getOneToOneEntities();
                    //updatePrintedDate(data.entityIds);
                    //updateOffers();
                }
            })
    }

    function printSelected() {
        initiatePrint($ctrl.selectedEntityIds);
    }

    function reset() {
        //console.log('reset');
        $ctrl.alertsDropdown = {
            isopen: false
        };

        var date = new Date();
        //var date = new Date("2016-08-05T07:00:00.000Z");
        $ctrl.dateStart = date;
        $ctrl.dateEnd = new Date();
        $ctrl.dateEnd.setDate($ctrl.dateStart.getDate() + 1);
        //var date = moment().utc().startOf('day').toDate();
        //date.setHours(24, 0, 0, 0);

        $ctrl.selectedAlertTypes = [];
        $ctrl.selectedEntityIds = [];
        $ctrl.isIncludePrintedChecked = false;
        $ctrl.isAllSelected = false;
        resetSearchText();

        getOneToOneEntities();
    }

    function resetSearchText() {
        $ctrl.searchText = '';
        updateOffers();
    }

    function toggleAllRows() {
        if ($ctrl.isAllSelected) {
            angular.forEach($ctrl.filteredOffers, function (row) {
                if (row.replacementFullTrimName) {
                    row.isSelected = true;
                    $ctrl.selectedEntityIds.push(row.entityID);
                }
            });
        }
        else {
            angular.forEach($ctrl.filteredOffers, function (row) {
                row.isSelected = false;
            });

            $ctrl.selectedEntityIds = [];
        }
    }

    function showFutureAppointments() {
        $translate([translateErrorKeys.underConstructionKey, warningTitleKey])
            .then(function (translations) {
                logger.warning(
                    translations[translateErrorKeys.underConstructionKey],
                    null,
                    null
                );
            });
    }

    function updateOffers() {
        //var startDate = moment().startOf('day').toDate();
        var startDate = $ctrl.dateStart;

        $ctrl.filteredOffers = $filter('serviceDriveOffersFilter')(offers, $ctrl.searchText, $ctrl.isIncludePrintedChecked, startDate);
        //var filteredAndSelectedRows = _.filter($ctrl.filteredOffers, ['isSelected', true]);
        //$ctrl.selectedEntityIds = _.map(filteredAndSelectedRows, 'entityID');
    }


    function updatePrintedDate(entityIds) {
        angular.forEach($ctrl.filteredOffers, function (row) {
            if (_.includes(entityIds, row.entityID)) {
                row.lastPrintedDate = new Date();
            }
        });
    }
}
