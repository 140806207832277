ReportDiscrepancyActionController.$inject = ["$q", "$filter", "currentVehicleKey", "paymentsKey", "discrepancyTypes", "saleTypes", "dealerCountries"];

/* injects from baggage-loader */
require('./report-discrepancy-action.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('reportDiscrepancyAction', {
  templateUrl: '/app/components/deal-sheet/report-discrepancy-action.component.html',
  controller: ReportDiscrepancyActionController,
  bindings: {
    countryId: '<',
    opportunity: '<',
    saleTypes: '<',
    onInput: '&'
  }
});
/* @ngInject */

function ReportDiscrepancyActionController($q, $filter, currentVehicleKey, paymentsKey, discrepancyTypes, saleTypes, dealerCountries) {
  var $ctrl = this;
  $ctrl.options;
  $ctrl.getOptions = getOptions;

  function getOptions() {
    $ctrl.options = getDiscrepancyOptions();
    return $q.resolve(true);
  }

  function getDiscrepancyOptions() {
    var options = [{
      group: currentVehicleKey,
      type: discrepancyTypes.tradeValue,
      value: $ctrl.opportunity.currentVehicle.tradeValue
    }, {
      group: currentVehicleKey,
      type: discrepancyTypes.payoffAmount,
      value: $ctrl.opportunity.currentContract.payoffAmount
    }];

    if ($ctrl.saleTypes.indexOf(saleTypes.lease) > -1) {
      options.push({
        group: paymentsKey,
        type: discrepancyTypes.leaseRate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.leaseRate, $ctrl.countryId == dealerCountries.usa)
      }, {
        group: paymentsKey,
        type: discrepancyTypes.leaseFactoryRebate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.leaseFactoryRebate)
      }, {
        group: paymentsKey,
        type: discrepancyTypes.leaseDealerRebate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.leaseDealerRebate)
      });
    }

    if ($ctrl.saleTypes.indexOf(saleTypes.retail) > -1) {
      options.push({
        group: paymentsKey,
        type: discrepancyTypes.retailRate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.retailRate)
      }, {
        group: paymentsKey,
        type: discrepancyTypes.retailFactoryRebate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.retailFactoryRebate)
      }, {
        group: paymentsKey,
        type: discrepancyTypes.retailDealerRebate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.retailDealerRebate)
      });
    }

    if ($ctrl.saleTypes.indexOf(saleTypes.balloon) > -1) {
      options.push({
        group: paymentsKey,
        type: discrepancyTypes.balloonRate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.balloonRate)
      }, {
        group: paymentsKey,
        type: discrepancyTypes.balloonFactoryRebate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.balloonFactoryRebate)
      }, {
        group: paymentsKey,
        type: discrepancyTypes.balloonDealerRebate,
        multiple: true,
        value: getValueList($ctrl.opportunity.replacementContracts, discrepancyTypes.balloonDealerRebate)
      });
    }

    options.push({
      type: discrepancyTypes.replacementVehicle,
      value: getReplacementVehicleInfo($ctrl.opportunity)
    }, {
      type: discrepancyTypes.other
    });
    return options;
  }

  function getValueList(contracts, discrepancyType, useMoneyFactor) {
    var rateList = [];

    for (var i = 0; i < contracts.length; i++) {
      var contract = contracts[i];
      var saleType;

      switch (discrepancyType) {
        case discrepancyTypes.leaseRate:
        case discrepancyTypes.leaseFactoryRebate:
        case discrepancyTypes.leaseDealerRebate:
          saleType = saleTypes.lease;
          break;

        case discrepancyTypes.retailRate:
        case discrepancyTypes.retailFactoryRebate:
        case discrepancyTypes.retailDealerRebate:
          saleType = saleTypes.retail;
          break;

        case discrepancyTypes.balloonRate:
        case discrepancyTypes.balloonFactoryRebate:
        case discrepancyTypes.balloonDealerRebate:
          saleType = saleTypes.balloon;
          break;

        default:
          break;
      }

      if (contract.saleType == saleType) {
        var current = {
          term: contract.term
        };

        switch (discrepancyType) {
          case discrepancyTypes.leaseRate:
            current.value = useMoneyFactor ? contract.moneyFactor : contract.apr;
            break;

          case discrepancyTypes.retailRate:
          case discrepancyTypes.balloonRate:
            current.value = contract.apr;
            break;

          case discrepancyTypes.leaseFactoryRebate:
          case discrepancyTypes.retailFactoryRebate:
          case discrepancyTypes.balloonFactoryRebate:
            current.value = contract.factoryRebate;
            break;

          case discrepancyTypes.leaseDealerRebate:
          case discrepancyTypes.retailDealerRebate:
          case discrepancyTypes.balloonDealerRebate:
            current.value = contract.dealerRebate;
            break;

          default:
            break;
        }

        rateList.push(current);
      }
    }

    return rateList;
  }

  function getReplacementVehicleInfo(opportunity) {
    var replacementInfo = opportunity.replacementVehicle.yearID + ', ' + opportunity.replacementVehicle.makeName + ' ' + opportunity.replacementVehicle.trimFullName + ' (' + $filter('localeCurrency')(opportunity.replacementVehicle.price) + ')';
    return replacementInfo;
  }
}