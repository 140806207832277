
/* injects from baggage-loader */
require('./mark-as-on-order.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('markAsOnOrder', {
    templateUrl: '/app/components/deal-sheet/mark-as-on-order.component.html',
    controller: MarkAsOnOrderController,
    bindings: {
        onOrder: '<',
        permissions: '<',
        onChange: '&'
    }
});

/* @ngInject */
function MarkAsOnOrderController() {
    var $ctrl = this;

    $ctrl.visible;
    $ctrl.enabled;
    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if (changes.onOrder && changes.onOrder.currentValue) {
            //$ctrl.visible = $ctrl.status != opportunityStatuses.closed;
            $ctrl.enabled = $ctrl.onOrder;
        }
    }
}
