VehicleActionSelectController.$inject = ["activityService", "saleTypes", "opportunityStatuses", "vehicleActions"];

/* injects from baggage-loader */
require('./vehicle-action-select.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('vehicleActionSelect', {
  templateUrl: '/app/components/deal-sheet/vehicle/vehicle-action-select.component.html',
  controller: VehicleActionSelectController,
  bindings: {
    opportunityId: '<',
    status: '<',
    saleType: '<',
    paidOff: '<',
    paymentsRemaining: '<',
    permissions: '<',
    onStart: '&',
    onSuccess: '&',
    onError: '&',
    onSelect: '&'
  }
});
/* @ngInject */

function VehicleActionSelectController(activityService, saleTypes, opportunityStatuses, vehicleActions) {
  var $ctrl = this;
  $ctrl.canVehicleBePaidOff;
  $ctrl.opportunityStatuses = opportunityStatuses;
  $ctrl.updateVehicleStatus = updateVehicleStatus;
  $ctrl.vehicleActions = vehicleActions;
  $ctrl.$onChanges = onChanges;
  $ctrl.vehicleOptionStatus = "-1";

  function onChanges(changes) {
    if ((changes.saleType && changes.saleType.currentValue || changes.paidOff && changes.paidOff.currentValue != null || changes.paymentsRemaining && changes.paymentsRemaining.currentValue != null) && $ctrl.saleType && $ctrl.paidOff != null && $ctrl.paymentsRemaining != null) {
      $ctrl.canVehicleBePaidOff = !$ctrl.paidOff && $ctrl.paymentsRemaining > 0 && $ctrl.saleType != saleTypes.cash;
    }

    $ctrl.vehicleOptionStatus = "-1";
  }

  function updateVehicleStatus() {
    var vehicleStatusInt = parseInt($ctrl.vehicleOptionStatus);
    if ($ctrl.vehicleStatusInt == -1) return;
    $ctrl.onSelect({
      action: vehicleStatusInt
    });
  }
}