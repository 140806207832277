import "core-js/modules/es6.string.includes";
import "core-js/modules/es7.array.includes";
import "core-js/modules/web.dom.iterable";
reportService.$inject = ["$http", "$state", "coreApi", "apiUrls", "storageService", "reportsMenu", "modules", "tokenService", "authorizationService", "userService", "_"];
import angular from 'angular';
import * as datetime from '../utilities/datetime';
var generateReportApi = '/api/Reports/GenerateReport';
angular.module('AaApp.Opp').factory('reportService', reportService);
/* @ngInject */

function reportService($http, $state, coreApi, apiUrls, storageService, reportsMenu, modules, tokenService, authorizationService, userService, _) {
  var service = {
    getReport: getReport,
    getDateRanges: getDateRanges,
    getFordPilotStoreStatus: getFordPilotStoreStatus,
    getAuthorizedReports: getAuthorizedReports,
    isReportAuthorized: isReportAuthorized,
    getServiceDriveReportDetail: getServiceDriveReportDetail,
    getCreditConvertReportDetail: getCreditConvertReportDetail,
    getDataCleanseReportDetail: getDataCleanseReportDetail,
    getUserActivityLog: getUserActivityLog,
    getSubscriptions: getSubscriptions,
    addSubscription: addSubscription,
    updateSubscription: updateSubscription,
    disableSubscription: disableSubscription,
    enableSubscription: enableSubscription,
    deleteSubscription: deleteSubscription,
    toZuluStringFromLocalDate: toZuluStringFromLocalDate,
    getLmsTranscript: getLmsTranscript,
    getPerformanceReportAvailableMonths: getPerformanceReportAvailableMonths,
    getBetaReportList: getBetaReportList,
    getTableauToken: getTableauToken
  };
  return service;

  function getReport(report, fromDate, toDate, selectedDealerId, format, additionalParams) {
    var userProfile = storageService.getItem('userProfile');
    var params = [{
      Key: 'DateFrom',
      Value: fromDate
    }, {
      Key: 'DateTo',
      Value: toDate
    }, {
      Key: 'LoggedInUserID',
      Value: userProfile.userId
    }, {
      Key: 'DealerGroupID',
      Value: userProfile.dealerGroupId
    }, {
      Key: 'DealerID',
      Value: selectedDealerId
    }, {
      Key: 'Culture',
      Value: userProfile.cultureName
    }];

    if (angular.isArray(additionalParams)) {
      for (var i = 0; i < additionalParams.length; i++) {
        params.push(additionalParams[i]);
      }
    } //var dealerId = 99999;
    //var dealer = _.find(params, function(item){ return item.Key == "DealerID"; });
    //if (dealer != null) {
    //    dealerId = dealer.Value;
    //}
    //// If selectedDealerId = 0 then it means ALL dealers, which is only available to enterprise reports
    //var id = dealerId == 0 ? report.enterprise.id : report.id;


    if (format && (format === 'pdf' || format === 'excel')) {
      return getReportDataExport(report.id, params, format);
    } else {
      return getReportData(report.id, params);
    }
  }

  function getDateRanges() {
    return coreApi.api.Reports.GetReportPeriods.GET().then(getData);
  }

  function getData(response) {
    return response.data;
  }

  function toZuluStringFromLocalDate(date) {
    var atMidnight = datetime.atMidnight(date);
    return datetime.toSameLocalTimeInUTC(atMidnight).toISOString();
  }

  function getReportData(id, params) {
    return $http({
      method: 'POST',
      url: apiUrls.reportMiner + generateReportApi,
      data: {
        reportId: id,
        parameters: params
      },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function successCallback(response) {
      return response.data; //}, function errorCallback(response) {
      //    return "<html><body></body></html>";
    });
  }

  function getReportDataExport(id, params, format) {
    return $http({
      method: 'POST',
      url: apiUrls.reportMiner + generateReportApi,
      data: {
        reportId: id,
        finalFormat: format,
        parameters: params
      },
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'arraybuffer'
    }).then(function successCallback(response) {
      return response; //}, function errorCallback(response) {
      //    return null;
    });
  }

  function getBetaReportList() {
    var accessToken = tokenService.getTokenByType('access');
    var url = apiUrls.tableauApi + "getuserviews";
    return $http({
      method: 'GET',
      url: url,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        'Pragma': function Pragma() {
          return null;
        },
        'Cache-Control': function CacheControl() {
          return null;
        }
      }
    }).then(function successCallback(response) {
      return response.data;
    });
  }

  function getTableauToken(isEnterpriseReport) {
    var accessToken = tokenService.getTokenByType('access');
    var url = apiUrls.tableauApi + "gettoken?isEnterpriseReport=" + isEnterpriseReport;
    return $http({
      method: 'GET',
      url: url,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken,
        'Pragma': function Pragma() {
          return null;
        },
        'Cache-Control': function CacheControl() {
          return null;
        }
      }
    }).then(function successCallback(response) {
      return response.data;
    });
  }

  function getPerformanceReportAvailableMonths(dealerId) {
    return coreApi.api.Reports.Report_Performance_GetAvailableMonths['{dealerId}'].GET({
      dealerId: dealerId
    }).then(function (response) {
      if (response.data && response.data.MPR_AvailableMonths.length > 0) {
        var months = response.data.MPR_AvailableMonths[0].DataMonths;
        months.forEach(function (item) {
          var month = item.Month - 1; // needs to be 0-based

          var startDate = new Date(item.Year, month, 1);
          var endDate = new Date(item.Year, month + 1, 0);
          item.startDate = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
          item.endDate = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
        });
        return months;
      } else return [];
    }).catch(function (response) {
      return [];
    });
  }

  function getFordPilotStoreStatus() {
    return coreApi.api.FordPilotStore.GetFordAlertModuleDealerStatus.GET().then(getData);
  }

  function getServiceDriveReportDetail(params) {
    return coreApi.api.Report_ServiceDrivePerformance.GetServiceDrivePerformanceDetail.POST(params).then(getData);
  }

  function getCreditConvertReportDetail(params) {
    return coreApi.api.Report_CreditConvert.Report_CreditConvert_Detail.POST(params).then(getData);
  }

  function getUserActivityLog(params) {
    return coreApi.api.Report_UserActivity.Report_UserActivity_Main.POST(params).then(getData);
  }

  function getDataCleanseReportDetail(params) {
    return coreApi.api.Report_DataCleanse.Report_DataCleanse_Detail.POST(params).then(getData);
  }

  function getAuthorizedReports(moduleIds, isSltUser) {
    var authorizedReports = [];
    var isReportEnabled = moduleIds.lastIndexOf(modules.reports) > -1;

    if (!isReportEnabled) {
      return authorizedReports;
    }

    if (reportsMenu && moduleIds) {
      for (var i = 0; i < reportsMenu.length; i++) {
        var report = reportsMenu[i];

        if (isReportAuthorized(report, moduleIds, isSltUser)) {
          authorizedReports.push(report);
        }
      }
    }

    return authorizedReports;
  }

  function isReportAuthorized(report, moduleIds, isSltUser) {
    if (report.authorize) {
      var SLT_REPORTS = ['expressRoiReport', 'serviceLaneTechnologyReport'];
      var PANDO_REPORTS = ['pandoUserActivityReport', 'pandoSurveyReport'];
      var EUROLITE_NONQUALIFIED_REPORTS = ['expressRoiReport', 'tradeInsReport', 'serviceDriveSalesPerformanceReport', 'emailTemplateReport', 'opportunitiesReport', 'inventoryReport', 'webinarAttendanceReport', 'creditConvertReport', 'oneToOneReport', 'dataCleanseReport', 'vinScanUtilizationReport', 'onetoOneSoldUnitsReport', 'pandoUserActivityReport', 'pandoSurveyReport']; // EUROLITE users are restricted from these reports

      if (authorizationService.isEuroLite() && EUROLITE_NONQUALIFIED_REPORTS.includes(report.id)) {
        return false;
      } // SLT users are restricted from these reports


      if (isSltUser && SLT_REPORTS.includes(report.id)) {
        return false;
      } // If the report defines a moduleId, the requirement must be met


      if (report.moduleId != null && !moduleIds.includes(report.moduleId)) {
        return false;
      } // If it's a Pando report, they must be a Motofuze user


      var isPandoReport = PANDO_REPORTS.includes(report.id);

      if (isPandoReport) {
        return tokenService.isMotoFuzeUser();
      } // All requirements met


      return true;
    }

    return false;
  }

  function getSubscriptions() {
    return coreApi.api.ReportSubscription.GetReportSelfSubscriptionsByUser.GET().then(function (response) {
      return response.data;
    });
  }

  function addSubscription(subscription) {
    return coreApi.api.ReportSubscription.AddReportSelfSubscription.POST(subscription);
  }

  function updateSubscription(subscription) {
    return coreApi.api.ReportSubscription.UpdateReportSelfSubscription.POST(subscription);
  }

  function enableSubscription(subscriptionId) {
    return coreApi.api.ReportSubscription.EnableReportSelfSubscription['{id}'].POST({}, {
      id: subscriptionId
    });
  }

  function disableSubscription(subscriptionId) {
    return coreApi.api.ReportSubscription.DisableReportSelfSubscription['{id}'].POST({}, {
      id: subscriptionId
    });
  }

  function deleteSubscription(subscriptionId) {
    return coreApi.api.ReportSubscription.DeleteReportSelfSubscription['{id}'].POST({}, {
      id: subscriptionId
    });
  }

  function getLmsTranscript(userId) {
    return coreApi.api.Lms.GetTranscript['{userId}'].GET({
      userId: userId
    }).then(function (response) {
      return response;
    });
  }
}