//
// THIS FILE WAS GENERATED BY 'generate-dmspushapi.ts'
//
// DO NOT EDIT THIS FILE MANUALLY.
//
// TO UPDATE THIS FILE, RUN:
//   yarn run generate:dmspushapi

export default {
    "swagger": "2.0",
    "info": {
        "version": "v1",
        "title": "AutoAlert DmsPush (Business Layer, User Context)",
        "description": "AutoAlert.AlertMiner.DmsPush.Api",
        "termsOfService": "AutoAlert, Inc."
    },
    "paths": {
        "/api/Deskit/IsPushToDeskitValid/{dealerId}": {
            "get": {
                "tags": [
                    "DeskitClient"
                ],
                "operationId": "IsDeskitPushValid_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/IsDeskitPushValidDto"
                        }
                    }
                }
            }
        },
        "/api/Deskit/PushToDeskit": {
            "post": {
                "tags": [
                    "DeskitClient"
                ],
                "operationId": "PushToDeskit_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DeskitQuotingPushDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DeskitQuotingPushResponseDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/IsDmsPushValid/{dealerId}": {
            "get": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "IsDmsPushValid_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/IsDmsPushValidDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushCustomer": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushCustomer_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "customer",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DmsPushPostCustomerDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/PushFAndI": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "PushFAndI_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "fAndI",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DmsPushPostFAndIDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DmsPushResultDto"
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetDmsPushSuccessHistory": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetDmsPushSuccessHistory_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DmsPushHistoryRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/DmsPushHistoryDto"
                            }
                        }
                    }
                }
            }
        },
        "/api/DmsPush/GetLatestDmsPushSuccess": {
            "post": {
                "tags": [
                    "DmsPushClient"
                ],
                "operationId": "GetLatestDmsPushSuccess_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/DmsPushHistoryRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/DmsPushHistoryExtDto"
                        }
                    }
                }
            }
        },
        "/api/Healthz": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/healthz": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/elbtest.html": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "CheckHealth_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/Healthz/Diagnostics": {
            "get": {
                "tags": [
                    "HealthzClient"
                ],
                "operationId": "Diagnostics_GET",
                "consumes": [],
                "produces": [],
                "parameters": [],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        },
        "/api/MultipleApiCalls": {
            "post": {
                "tags": [
                    "MultipleApiCallsClient"
                ],
                "operationId": "DoWork_POST",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "apiRequests",
                        "in": "query",
                        "required": false,
                        "type": "array",
                        "items": {},
                        "collectionFormat": "multi",
                        "uniqueItems": false
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "uniqueItems": false,
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/ApiResponse"
                            }
                        }
                    }
                }
            }
        },
        "/api/vAuto/CreateAppraisal": {
            "post": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "CreateAppraisal_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/vAutoCreateAppraisalRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/vAutoCreateAppraisalResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/GetAppraisalById": {
            "post": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetAppraisalById_POST",
                "consumes": [
                    "application/json-patch+json",
                    "application/json",
                    "text/json",
                    "application/*+json"
                ],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "request",
                        "in": "body",
                        "required": false,
                        "schema": {
                            "$ref": "#/definitions/vAutoGetAppraisalByIdRequestDto"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/vAutoGetAppraisalByIdResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/GetLatestAppraisalByVin/{dealerId}/{vin}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetLatestAppraisalByVin_ByDealerid_ByVin_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "vin",
                        "in": "path",
                        "required": true,
                        "type": "string"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "$ref": "#/definitions/vAutoGetAppraisalByVinResponseDto"
                        }
                    }
                }
            }
        },
        "/api/vAuto/IsEnabled/{dealerId}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "IsEnabled_ByDealerid_GET",
                "consumes": [],
                "produces": [
                    "text/plain",
                    "application/json",
                    "text/json"
                ],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success",
                        "schema": {
                            "type": "boolean"
                        }
                    }
                }
            }
        },
        "/api/vAuto/GetAppraisalHistoryBy/{dealerId}/{entityId}": {
            "get": {
                "tags": [
                    "vAutoClient"
                ],
                "operationId": "GetAppraisalHistoryBy_ByDealerid_ByEntityid_GET",
                "consumes": [],
                "produces": [],
                "parameters": [
                    {
                        "name": "dealerId",
                        "in": "path",
                        "required": true,
                        "type": "integer",
                        "format": "int32"
                    },
                    {
                        "name": "entityId",
                        "in": "path",
                        "required": true,
                        "type": "string",
                        "format": "uuid"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "Success"
                    }
                }
            }
        }
    },
    "definitions": {},
    "securityDefinitions": {
        "Bearer": {
            "name": "Authorization",
            "in": "header",
            "type": "apiKey",
            "description": "access_token from SSO"
        }
    },
    "security": [
        {
            "Bearer": []
        }
    ]
}