
/* injects from baggage-loader */
require('./search-container.component.html');

import angular from 'angular';
import './search-container.component.scss';

angular.module('AaApp.Opp').component('searchContainer', {
        templateUrl: '/app/components/search/search-container.component.html',
        controller: SearchContainer
});

/* @ngInject */
function SearchContainer($state, dealerService) {
    var $ctrl = this;

    $ctrl.searchMenuVisible = true;
    $ctrl.searchToggleMenu = searchToggleMenu;
    $ctrl.$onInit = init;
    $ctrl.permissions = {};
    $ctrl.selectedDealerId = 0;
    $ctrl.updateMenuSettings = updateMenuSettings;
    $ctrl.searchMenuPresetSections;
    $ctrl.selectedUserSearchConditionId;
    $ctrl.searchFormSectionStates;
    $ctrl.currentView = "search";

    function init() {
        $ctrl.searchFormSectionStates = {
            0: true,
            1: true,
            2: false,
            3: false,
            4: false,
        };

        switch ($state.current.name) {
            case "search.manage":
                $ctrl.currentView = "manage";
                break;
            case "search.form":
            default:
                $ctrl.currentView = "search";
        }

        $ctrl.searchMenuPresetSections = {
            mySearchesMenuVisible: true,
            sharedSearchesMenuVisible: true,
            tagBasedSearchesMenuVisible: false,
            predefinedSearchesMenuVisible: false,
        };

        $ctrl.presetId = null;

        $ctrl.selectedDealerId = 0;
        var dealers = dealerService.getSelectedDealers();

        if (dealers.length === 1) {
            $ctrl.selectedDealerId = dealers[0].id;
        }
        else if (Number.isInteger($ctrl.dealerId)) {
            $ctrl.selectedDealerId = $ctrl.dealerId;
        }

        $ctrl.searchMenuVisible = false;

    }

    function updateMenuSettings(searchMenuSettings) {
        $ctrl.presetId = searchMenuSettings.selectedUserSearchConditionId;
    }

    function searchToggleMenu() {
        $ctrl.searchMenuVisible = !$ctrl.searchMenuVisible;  
    }
}
