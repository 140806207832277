"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var RoleClientService = /** @class */ (function (_super) {
    __extends(RoleClientService, _super);
    function RoleClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    RoleClientService.prototype.GetRoleOptionsGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Role/GetRoleOptions", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    RoleClientService.prototype.GetRoleOptionsGET = function () {
        return this.GetRoleOptionsGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param roleId undefined
     * @return Success
     */
    RoleClientService.prototype.GetRoleAccessRightsByRoleidGETResponse = function (roleId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Role/GetRoleAccessRights/" + roleId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param roleId undefined
     * @return Success
     */
    RoleClientService.prototype.GetRoleAccessRightsByRoleidGET = function (roleId) {
        return this.GetRoleAccessRightsByRoleidGETResponse(roleId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param roleId undefined
     * @return Success
     */
    RoleClientService.prototype.GetRoleModuleAccessByRoleidGETResponse = function (roleId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Role/GetRoleModuleAccess/" + roleId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param roleId undefined
     * @return Success
     */
    RoleClientService.prototype.GetRoleModuleAccessByRoleidGET = function (roleId) {
        return this.GetRoleModuleAccessByRoleidGETResponse(roleId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param roleId undefined
     * @return Success
     */
    RoleClientService.prototype.GetRoleModuleCategoriesByRoleidGETResponse = function (roleId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Role/GetRoleModuleCategories/" + roleId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param roleId undefined
     * @return Success
     */
    RoleClientService.prototype.GetRoleModuleCategoriesByRoleidGET = function (roleId) {
        return this.GetRoleModuleCategoriesByRoleidGETResponse(roleId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    RoleClientService.prototype.GetRoleAccessRightCategoriesGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Role/GetRoleAccessRightCategories", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    RoleClientService.prototype.GetRoleAccessRightCategoriesGET = function () {
        return this.GetRoleAccessRightCategoriesGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `RoleClientService.RoleAccessUpdateByRoleidGETParams` containing the following parameters:
     *
     * - `roleId`:
     *
     * - `moduleIdList`:
     *
     * - `accessRightIdList`:
     *
     * @return Success
     */
    RoleClientService.prototype.RoleAccessUpdateByRoleidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (params.moduleIdList || []).forEach(function (val) { if (val != null)
            __params = __params.append('moduleIdList', val.toString()); });
        (params.accessRightIdList || []).forEach(function (val) { if (val != null)
            __params = __params.append('accessRightIdList', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Role/RoleAccessUpdate/" + params.roleId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @param params The `RoleClientService.RoleAccessUpdateByRoleidGETParams` containing the following parameters:
     *
     * - `roleId`:
     *
     * - `moduleIdList`:
     *
     * - `accessRightIdList`:
     *
     * @return Success
     */
    RoleClientService.prototype.RoleAccessUpdateByRoleidGET = function (params) {
        return this.RoleAccessUpdateByRoleidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param role undefined
     * @return Success
     */
    RoleClientService.prototype.AddEditRolePOSTResponse = function (role) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = role;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Role/AddEditRole", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param role undefined
     * @return Success
     */
    RoleClientService.prototype.AddEditRolePOST = function (role) {
        return this.AddEditRolePOSTResponse(role).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    RoleClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Role/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    RoleClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    RoleClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Role/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    RoleClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    RoleClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Role/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    RoleClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    RoleClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Role", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    RoleClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    RoleClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Role", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    RoleClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    RoleClientService.GetRoleOptionsGETPath = '/api/Role/GetRoleOptions';
    RoleClientService.GetRoleAccessRightsByRoleidGETPath = '/api/Role/GetRoleAccessRights/{roleId}';
    RoleClientService.GetRoleModuleAccessByRoleidGETPath = '/api/Role/GetRoleModuleAccess/{roleId}';
    RoleClientService.GetRoleModuleCategoriesByRoleidGETPath = '/api/Role/GetRoleModuleCategories/{roleId}';
    RoleClientService.GetRoleAccessRightCategoriesGETPath = '/api/Role/GetRoleAccessRightCategories';
    RoleClientService.RoleAccessUpdateByRoleidGETPath = '/api/Role/RoleAccessUpdate/{roleId}';
    RoleClientService.AddEditRolePOSTPath = '/api/Role/AddEditRole';
    RoleClientService.ByIdGETPath = '/api/Role/{id}';
    RoleClientService.ByIdDELETEPath = '/api/Role/{id}';
    RoleClientService.GetManyGETPath = '/api/Role/GetMany';
    RoleClientService.PUTPath = '/api/Role';
    RoleClientService.POSTPath = '/api/Role';
    RoleClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleClientService_Factory() { return new RoleClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: RoleClientService, providedIn: "root" });
    return RoleClientService;
}(base_service_1.BaseService));
exports.RoleClientService = RoleClientService;
