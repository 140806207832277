<div class="alert-box">
    <div class="modal-header">
        <div style="float:left">
            <h4 class="modal-title" translate="preview"></h4>
        </div>
        <div style="float:right">
            <button class="close" aria-label="Close" (click)="onCancel()">
                <icon class="icon aa-icon-close-icon"></icon>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <iframe #frame class="ifrmContainer" style="border: none;width: 100%;height: 680px"></iframe>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onCancel()" translate="close"></button>        
    </div>
</div>
