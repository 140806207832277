// NOTE: THIS FILE WILL BE DEPRECATED
// Please create angular CONSTANTS in the /app/constants folder. One constant file per enum.

import angular from 'angular'; 

(function () {
    angular.module('AaApp.Opp')
        .constant('opportunityStatuses', { scheduled: 1, sold: 2, closed: 3, open: 4 })
        .constant('headerLocalizationPath', '/app/i18n/header')
        .constant('printUpgradeProposalPath', '/AlertDesk/Dealsheet/PrintComparison')
        .constant('printTemplateUrlPath', '/alertdesk/dealsheet/TemplatePortal')
        .constant('previewTemplateEmailUrlPath', '/alert-desk/template-email-preview/')
        .constant('modalTypes', {
            postNote: 1,
            logCall: 2,
            scheduleCall: 3,
            scheduleAppt: 4,
        });        
})();
