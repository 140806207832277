<div class="row contact-info">
    <div class="col-xs-2">
        <span class="icon-class icon -size-24 email-icon"></span>
    </div>
    <div class="col-xs-10">
        <div class="row" *ngIf="email?.value">
            <span *ngIf="!email?.isBad && !doNotEmail">{{email?.value}}</span>
            <span *ngIf="email?.isBad && !doNotEmail" class="strike-out">{{email?.value}}</span>
            <span *ngIf="doNotEmail" class="strike-out" style="color:red">{{email?.value}}</span>
        </div>
        <div class="row">
            <span translate="{{type}}"></span>
        </div>
    </div>
</div>