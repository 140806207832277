localeCurrencyFilter.$inject = ["storageService", "localeNumberFilter"];
import angular from 'angular';
angular.module('AaApp.Opp').filter('localeCurrency', localeCurrencyFilter);
localeCurrencyFilter.$inject = ['storageService', 'localeNumberFilter'];
/* @ngInject */

function localeCurrencyFilter(storageService, localeNumberFilter) {
  return function (amount, showFractionDigits, showSymbol) {
    if (typeof amount !== 'number') {
      return amount;
    }

    if (typeof showSymbol !== 'boolean') {
      showSymbol = true;
    }

    var precision = showFractionDigits ? 2 : 0;
    amount = roundWithPrecision(amount, precision);
    var savedCultureName = storageService.getItem('cultureName');
    var cultureName = savedCultureName ? savedCultureName : 'en-us';
    var amountIsNegative;

    if (amount < 0) {
      amountIsNegative = true;
    }

    var absoluteAmount = Math.abs(amount);
    var formattedAmount;

    switch (cultureName.toLowerCase()) {
      case 'en-gb':
        formattedAmount = '£' + localeNumberFilter(absoluteAmount, precision);
        if (amountIsNegative) formattedAmount = '(' + formattedAmount + ')';
        break;

      case 'en-ca':
        formattedAmount = (showSymbol ? '$' : '') + localeNumberFilter(absoluteAmount, precision);
        if (amountIsNegative) formattedAmount = '(' + formattedAmount + ')';
        break;

      case 'fr-ca':
        formattedAmount = localeNumberFilter(absoluteAmount, precision) + (showSymbol ? ' $' : '');
        if (amountIsNegative) formattedAmount = '(' + formattedAmount + ')';
        break;

      case 'fr-fr':
        formattedAmount = localeNumberFilter(absoluteAmount, precision) + (showSymbol ? ' €' : '');
        if (amountIsNegative) formattedAmount = '- ' + formattedAmount;
        break;

      case 'en-us':
      default:
        formattedAmount = '$' + localeNumberFilter(absoluteAmount, precision);
        if (amountIsNegative) formattedAmount = '(' + formattedAmount + ')';
        break;
    } //console.log({ f: formattedAmount, c: cultureName, s: showSymbol, t: typeof showSymbol });


    return formattedAmount;
  };

  function roundWithPrecision(x, precision) {
    if (precision == 0) {
      return Math.round(x);
    }

    var factor = Math.pow(10, precision);
    return Math.round(x * factor) / factor;
  }
}