import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
DataEntryRatesUsedRatesController.$inject = ["$translate", "$q", "dealerService", "dataEntryService", "storageService", "$uibModal", "$document", "cultures", "logger", "$logFactory"];

/* injects from baggage-loader */
require('./data-entry-rates-used-rates.component.html');

import angular from 'angular';
import $ from 'jquery';
import BusyIndicator from '../../utilities/BusyIndicator';
angular.module('AaApp.Opp').component('dataEntryRatesUsedRates', {
  templateUrl: '/app/components/data-entry/data-entry-rates-used-rates.component.html',
  controller: DataEntryRatesUsedRatesController,
  bindings: {
    kendo: '<',
    dealerId: '<',
    onNotifyUnsavedData: '&'
  }
});

function DataEntryRatesUsedRatesController($translate, $q, dealerService, dataEntryService, storageService, $uibModal, $document, cultures, logger, $logFactory) {
  "ngInject";

  var $ctrl = this;
  var log = $logFactory.get();
  var cultureName = storageService.getItem('cultureName') || 'en-US';
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.trimSelections;
  $ctrl.onSave = onSave;
  $ctrl.onCancel = onCancel;
  $ctrl.kendoGrid = getKendoGrid;
  $ctrl.busyIndicator = new BusyIndicator();
  $ctrl.showQuickAction = false;
  $ctrl.onShowQuickAction = onShowQuickAction;
  $ctrl.onHideQuickAction = onHideQuickAction;
  $ctrl.onPerform = onPerform;
  $ctrl.onClear = onClear;
  $ctrl.quickActionToTermChange = quickActionToTermChange;
  $ctrl.quickActionToYearChange = quickActionToYearChange;
  $ctrl.min = 0;
  $ctrl.max = 24;
  $ctrl.enableDealerUsedRates = false;

  function onChanges(changes) {
    if (changes && changes.dealerId) {
      if (changes.dealerId.currentValue) {
        $ctrl.selectedDealerId = changes.dealerId.currentValue;
        dealerService.getDealerSettings($ctrl.selectedDealerId).then(function (settings) {
          $ctrl.enableDealerUsedRates = settings.enableDealerUsedRates;
          getTranslations();
          onDealerSelect();
        });
      }
    }
  }

  function init() {
    $ctrl.fromTerm = [24, 30, 36, 42, 48, 54, 60, 66, 72, 84];
    $ctrl.toTerm = [24, 30, 36, 42, 48, 54, 60, 66, 72, 84];
    $ctrl.quickActionFromTerm = 24;
    $ctrl.quickActionToTerm = 84;
    var yearList = [];
    var startYear = new Date().getUTCFullYear();
    var endYear = startYear - 9;

    for (var y = endYear; y <= startYear; y++) {
      yearList.push(y);
    }

    yearList.reverse();
    $ctrl.fromYear = yearList;
    $ctrl.toYear = yearList;
    $ctrl.quickActionFromYear = startYear;
    $ctrl.quickActionToYear = endYear;
  }

  function quickActionToYearChange() {
    $ctrl.fromYear = $ctrl.toYear.filter(function (item) {
      return item >= $ctrl.quickActionToYear;
    });
  }

  function quickActionToTermChange() {
    $ctrl.fromTerm = $ctrl.toTerm.filter(function (item) {
      return item <= $ctrl.quickActionToTerm;
    });
  }

  function onPerform() {
    if ($ctrl.rateAmount2 == null) return;
    var amount = $ctrl.rateAmount2.replace(",", ".");
    amount = parseFloat(amount);
    if (isNaN(amount)) return;
    $ctrl.rateAmount = amount;

    if (typeof $ctrl.rateAmount !== 'undefined' && $ctrl.rateAmount > -1) {
      var rows = $ctrl.kendoGrid().dataSource.data();

      for (var i = $ctrl.quickActionToYear; i <= $ctrl.quickActionFromYear; i++) {
        rows.forEach(function (row) {
          if (i == row.year) {
            for (var t = $ctrl.quickActionFromTerm; t <= $ctrl.quickActionToTerm; t++) {
              if (t == 24) {
                row.rate24 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate24 = true;else row.dirtyFields = {
                  "rate24": true
                };
              }

              if (t == 30) {
                row.rate30 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate30 = true;else row.dirtyFields = {
                  "rate30": true
                };
              }

              if (t == 36) {
                row.rate36 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate36 = true;else row.dirtyFields = {
                  "rate36": true
                };
              }

              if (t == 42) {
                row.rate42 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate42 = true;else row.dirtyFields = {
                  "rate42": true
                };
              }

              if (t == 48) {
                row.rate48 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate48 = true;else row.dirtyFields = {
                  "rate48": true
                };
              }

              if (t == 54) {
                row.rate54 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate54 = true;else row.dirtyFields = {
                  "rate54": true
                };
              }

              if (t == 60) {
                row.rate60 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate60 = true;else row.dirtyFields = {
                  "rate60": true
                };
              }

              if (t == 66) {
                row.rate66 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate66 = true;else row.dirtyFields = {
                  "rate66": true
                };
              }

              if (t == 72) {
                row.rate72 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate72 = true;else row.dirtyFields = {
                  "rate72": true
                };
              }

              if (t == 84) {
                row.rate84 = $ctrl.rateAmount;
                row.dirty = true;
                if (row.dirtyFields) row.dirtyFields.rate84 = true;else row.dirtyFields = {
                  "rate84": true
                };
              }
            }
          }
        });
      }

      $ctrl.onNotifyUnsavedData({
        result: {
          tab: "usedRates",
          value: true
        }
      });
      $ctrl.kendoGrid().refresh();
    }
  }

  function onClear() {
    var rows = $ctrl.kendoGrid().dataSource.data();
    rows.forEach(function (row) {
      if (row.rate24 || row.rate24 == 0) {
        row.rate24 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate24 = true;else row.dirtyFields = {
          "rate24": true
        };
      }

      if (row.rate30 || row.rate30 == 0) {
        row.rate30 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate30 = true;else row.dirtyFields = {
          "rate30": true
        };
      }

      if (row.rate36 || row.rate36 == 0) {
        row.rate36 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate36 = true;else row.dirtyFields = {
          "rate36": true
        };
      }

      if (row.rate42 || row.rate42 == 0) {
        row.rate42 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate42 = true;else row.dirtyFields = {
          "rate42": true
        };
      }

      if (row.rate48 || row.rate48 == 0) {
        row.rate48 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate48 = true;else row.dirtyFields = {
          "rate48": true
        };
      }

      if (row.rate54 || row.rate54 == 0) {
        row.rate54 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate54 = true;else row.dirtyFields = {
          "rate54": true
        };
      }

      if (row.rate60 || row.rate60 == 0) {
        row.rate60 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate60 = true;else row.dirtyFields = {
          "rate60": true
        };
      }

      if (row.rate66 || row.rate66 == 0) {
        row.rate66 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate66 = true;else row.dirtyFields = {
          "rate66": true
        };
      }

      if (row.rate72 || row.rate72 == 0) {
        row.rate72 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate72 = true;else row.dirtyFields = {
          "rate72": true
        };
      }

      if (row.rate84 || row.rate84 == 0) {
        row.rate84 = null;
        row.dirty = true;
        if (row.dirtyFields) row.dirtyFields.rate84 = true;else row.dirtyFields = {
          "rate84": true
        };
      }
    });
    $ctrl.kendoGrid().refresh();
  }

  function onShowQuickAction() {
    if (!$ctrl.enableDealerUsedRates) {
      gridLockedMessage();
      return;
    }

    $ctrl.showQuickAction = true;
  }

  function onHideQuickAction() {
    $ctrl.showQuickAction = false;
  }

  function getTranslations() {
    $q.all({
      'saveConfirmMessage': $translate('saveConfirmMessage'),
      'saveWarningMessage': $translate('saveWarningMessage'),
      'validationErrorMessage': $translate('validationErrorMessage'),
      'lease': $translate('lease'),
      'retail': $translate('retail'),
      'balloon': $translate('balloon'),
      'expireOn': $translate('expireOn'),
      'year': $translate('year'),
      'trim': $translate('trim'),
      'termMonths': $translate('termMonths')
    }).then(function (stuff) {
      $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
      $ctrl.saveWarningMessage = stuff.saveWarningMessage;
      $ctrl.validationErrorMessage = stuff.validationErrorMessage;
      $ctrl.lease = stuff.lease;
      $ctrl.retail = stuff.retail;
      $ctrl.balloon = stuff.balloon;
      $ctrl.expireOn = stuff.expireOn;
      $ctrl.trim = stuff.trim;
      $ctrl.year = stuff.year;
      $ctrl.termMonths = stuff.termMonths;
      initGridOptions();
    });
  }

  function busy(translationKey, promise) {
    $ctrl.busyMessage = null;
    $translate(translationKey).then(function (translation) {
      $ctrl.busyMessage = translation;
    }, function (err) {
      $ctrl.busyMessage = translationKey;
    });
    var done = $ctrl.busyIndicator.start();
    return promise.finally(done);
  }

  function onDealerSelect(previousValue) {
    return loadUsedRates();
  }

  function gridLockedMessage() {
    var modal1 = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return 'usedRatesTitle';
        },
        message: function message() {
          return 'userRatesDealerSettingNotEnabledMessage';
        },
        submitButtonVisible: function submitButtonVisible() {
          return false;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        closeButtonVisible: function closeButtonVisible() {
          return true;
        },
        closeButtonDisabled: function closeButtonDisabled() {
          return true;
        },
        closeButtonText: function closeButtonText() {
          return "ok";
        }
      }
    });
  }

  function onSave() {
    if (!$ctrl.enableDealerUsedRates) {
      gridLockedMessage();
      return;
    }

    if (!isDataValid()) {
      var modal1 = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "validationError";
          },
          message: function message() {
            return $ctrl.validationErrorMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return false;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          closeButtonVisible: function closeButtonVisible() {
            return true;
          },
          closeButtonDisabled: function closeButtonDisabled() {
            return true;
          },
          closeButtonText: function closeButtonText() {
            return "ok";
          }
        }
      });
      return;
    }

    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "readyToProcess";
        },
        message: function message() {
          return $ctrl.saveConfirmMessage;
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        submitButtonText: function submitButtonText() {
          return "continue";
        }
      }
    });
    modal.result.then(function () {
      var rows = $ctrl.kendoGrid().dataSource.data();
      var dirtyRows = [];
      var isChanged = false;
      rows.forEach(function (row) {
        if (row.dirty) isChanged = true;
      });

      if (isChanged) {
        rows.forEach(function (row) {
          var year = row.year;
          if (row.rate24 && row.rate24 > 0) dirtyRows.push({
            yearId: year,
            termId: 24,
            rate: row.rate24,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate30 && row.rate30 > 0) dirtyRows.push({
            yearId: year,
            termId: 30,
            rate: row.rate30,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate36 && row.rate36 > 0) dirtyRows.push({
            yearId: year,
            termId: 36,
            rate: row.rate36,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate42 && row.rate42 > 0) dirtyRows.push({
            yearId: year,
            termId: 42,
            rate: row.rate42,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate48 && row.rate48 > 0) dirtyRows.push({
            yearId: year,
            termId: 48,
            rate: row.rate48,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate54 && row.rate54 > 0) dirtyRows.push({
            yearId: year,
            termId: 54,
            rate: row.rate54,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate60 && row.rate60 > 0) dirtyRows.push({
            yearId: year,
            termId: 60,
            rate: row.rate60,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate66 && row.rate66 > 0) dirtyRows.push({
            yearId: year,
            termId: 66,
            rate: row.rate66,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate72 && row.rate72 > 0) dirtyRows.push({
            yearId: year,
            termId: 72,
            rate: row.rate72,
            dealerId: $ctrl.selectedDealerId
          });
          if (row.rate84 && row.rate84 > 0) dirtyRows.push({
            yearId: year,
            termId: 84,
            rate: row.rate84,
            dealerId: $ctrl.selectedDealerId
          });
          row.dirty = false;
        });

        if (dirtyRows.length > 0) {
          return updateDealerUsedRates(dirtyRows);
        } else {
          return deleteAllUsedRatesByDealerId();
        }
      } else {
        logger.success("changesSaved");
        return $q.resolve();
      }
    }).catch(function () {
      return false;
    });
  }

  function onCancel() {
    $ctrl.kendoGrid().cancelChanges();
    $ctrl.onNotifyUnsavedData({
      result: {
        tab: "usedRates",
        value: false
      }
    });
  }

  function deleteAllUsedRatesByDealerId() {
    return busy("Saving", dataEntryService.deleteAllUsedRatesByDealerId($ctrl.selectedDealerId).then(function (success) {
      logger.success("changesSaved");
      $ctrl.onNotifyUnsavedData({
        result: {
          tab: "usedRates",
          value: false
        }
      });
      return loadUsedRates();
    }).then(function () {}, function (error) {
      log.log(error);
    }));
  }

  function updateDealerUsedRates(usedRates) {
    return busy("Saving", dataEntryService.updateDealerUsedRates(usedRates).then(function (success) {
      logger.success("changesSaved");
      $ctrl.onNotifyUnsavedData({
        result: {
          tab: "usedRates",
          value: false
        }
      });
      return loadUsedRates();
    }).then(function () {}, function (error) {
      log.log(error);
    }));
  }

  function isDataValid() {
    if (!$ctrl.kendoGrid()) return true;
    var valid = true;
    var rows = $ctrl.kendoGrid().dataSource.data();
    rows.forEach(function (row) {
      if (row.rate24 && row.rate24 < $ctrl.min || row.rate24 && row.rate24 > $ctrl.max || row.rate30 && row.rate30 < $ctrl.min || row.rate30 && row.rate30 > $ctrl.max || row.rate36 && row.rate36 < $ctrl.min || row.rate36 && row.rate36 > $ctrl.max || row.rate42 && row.rate42 < $ctrl.min || row.rate42 && row.rate42 > $ctrl.max || row.rate48 && row.rate48 < $ctrl.min || row.rate48 && row.rate48 > $ctrl.max || row.rate54 && row.rate54 < $ctrl.min || row.rate54 && row.rate54 > $ctrl.max || row.rate60 && row.rate60 < $ctrl.min || row.rate60 && row.rate60 > $ctrl.max || row.rate66 && row.rate66 < $ctrl.min || row.rate66 && row.rate66 > $ctrl.max || row.rate72 && row.rate72 < $ctrl.min || row.rate72 && row.rate72 > $ctrl.max || row.rate84 && row.rate84 < $ctrl.min || row.rate84 && row.rate84 > $ctrl.max) valid = false;
    });
    return valid;
  }

  function isDataDirty() {
    if (!$ctrl.kendoGrid()) return false;
    var dirty = false;
    var rows = $ctrl.kendoGrid().dataSource.data();
    rows.forEach(function (row) {
      if (row.dirty) dirty = true;
    });
    return dirty;
  }

  function initGridOptions() {
    $ctrl.gridOptions = {
      dataSource: $ctrl.ds,
      navigatable: true,
      pageable: false,
      height: 370,
      columns: [{
        field: "year",
        title: $ctrl.year,
        width: 100,
        sortable: false
      }, {
        title: $ctrl.termMonths,
        headerAttributes: {
          style: "text-align: center;"
        },
        columns: [{
          field: "rate24",
          template: rate24Template,
          title: "24",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate30",
          template: rate30Template,
          title: "30",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate36",
          template: rate36Template,
          title: "36",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate42",
          template: rate42Template,
          title: "42",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate48",
          template: rate48Template,
          title: "48",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate54",
          template: rate54Template,
          title: "54",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate60",
          template: rate60Template,
          title: "60",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate66",
          template: rate66Template,
          title: "66",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate72",
          template: rate72Template,
          title: "72",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }, {
          field: "rate84",
          template: rate84Template,
          title: "84",
          width: 20,
          sortable: false,
          headerAttributes: {
            "class": "align-right"
          }
        }]
      }],
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      resizable: false,
      reorderable: true,
      filterable: false,
      editable: "incell",
      cellClose: function cellClose(e) {
        if (isDataDirty()) {
          $ctrl.onNotifyUnsavedData({
            result: {
              tab: "usedRates",
              value: true
            }
          });
        }
      },
      navigate: function navigate(e) {
        if (!$ctrl.enableDealerUsedRates) gridLockedMessage();
      }
    };
  }

  function rate24Template(e) {
    if (!e.rate24 && e.rate24 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate24 >= $ctrl.min && e.rate24 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate24, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate24, 'n2') + "%</div>";
    }
  }

  function rate30Template(e) {
    if (!e.rate30 && e.rate30 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate30 >= $ctrl.min && e.rate30 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate30, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate30, 'n2') + "%</div>";
    }
  }

  function rate36Template(e) {
    if (!e.rate36 && e.rate36 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate36 >= $ctrl.min && e.rate36 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate36, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate36, 'n2') + "%</div>";
    }
  }

  function rate42Template(e) {
    if (!e.rate42 && e.rate42 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate42 >= $ctrl.min && e.rate42 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate42, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate42, 'n2') + "%</div>";
    }
  }

  function rate48Template(e) {
    if (!e.rate48 && e.rate48 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate48 >= $ctrl.min && e.rate48 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate48, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate48, 'n2') + "%</div>";
    }
  }

  function rate54Template(e) {
    if (!e.rate54 && e.rate54 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate54 >= $ctrl.min && e.rate54 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate54, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate54, 'n2') + "%</div>";
    }
  }

  function rate60Template(e) {
    if (!e.rate60 && e.rate60 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate60 >= $ctrl.min && e.rate60 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate60, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate60, 'n2') + "%</div>";
    }
  }

  function rate66Template(e) {
    if (!e.rate66 && e.rate66 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate66 >= $ctrl.min && e.rate66 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate66, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate66, 'n2') + "%</div>";
    }
  }

  function rate72Template(e) {
    if (!e.rate72 && e.rate72 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate72 >= $ctrl.min && e.rate72 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate72, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate72, 'n2') + "%</div>";
    }
  }

  function rate84Template(e) {
    if (!e.rate84 && e.rate84 != 0) return "<div class='edit-box'></div>";else {
      if (e.rate84 >= $ctrl.min && e.rate84 <= $ctrl.max) return "<div class='edit-box'>" + $ctrl.kendo.toString(e.rate84, 'n2') + "%</div>";else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.rate84, 'n2') + "%</div>";
    }
  }

  function processData(usedRatesData) {
    var gridData = [];
    var yearList = [];
    var temp = usedRatesData;
    var d = new Date();
    var startYear = d.getUTCFullYear();
    var endYear = startYear - 10;

    for (var y = startYear; y > endYear; y--) {
      yearList.push(y);
    }

    yearList.forEach(function (ele2) {
      var rate24 = temp.filter(function (t) {
        return t.termID == 24 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 24 && t.yearID == ele2;
      })[0].rate : null;
      var rate30 = temp.filter(function (t) {
        return t.termID == 30 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 30 && t.yearID == ele2;
      })[0].rate : null;
      var rate36 = temp.filter(function (t) {
        return t.termID == 36 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 36 && t.yearID == ele2;
      })[0].rate : null;
      var rate42 = temp.filter(function (t) {
        return t.termID == 42 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 42 && t.yearID == ele2;
      })[0].rate : null;
      var rate48 = temp.filter(function (t) {
        return t.termID == 48 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 48 && t.yearID == ele2;
      })[0].rate : null;
      var rate54 = temp.filter(function (t) {
        return t.termID == 54 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 54 && t.yearID == ele2;
      })[0].rate : null;
      var rate60 = temp.filter(function (t) {
        return t.termID == 60 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 60 && t.yearID == ele2;
      })[0].rate : null;
      var rate66 = temp.filter(function (t) {
        return t.termID == 66 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 66 && t.yearID == ele2;
      })[0].rate : null;
      var rate72 = temp.filter(function (t) {
        return t.termID == 72 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 72 && t.yearID == ele2;
      })[0].rate : null;
      var rate84 = temp.filter(function (t) {
        return t.termID == 84 && t.yearID == ele2;
      }).length > 0 ? temp.filter(function (t) {
        return t.termID == 84 && t.yearID == ele2;
      })[0].rate : null;
      gridData.push({
        "year": ele2,
        "rate24": rate24,
        "rate30": rate30,
        "rate36": rate36,
        "rate42": rate42,
        "rate48": rate48,
        "rate54": rate54,
        "rate60": rate60,
        "rate66": rate66,
        "rate72": rate72,
        "rate84": rate84
      });
    });
    return gridData;
  }

  function loadUsedRates() {
    return busy("Loading", dataEntryService.getDealerUsedRates($ctrl.selectedDealerId).then(function (data) {
      var usedRatesData = processData(data);
      $ctrl.ds = new $ctrl.kendo.data.DataSource({
        data: usedRatesData,
        schema: {
          model: {
            fields: {
              year: {
                type: "string",
                editable: false
              },
              rate24: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate30: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate36: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate42: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate48: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate54: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate60: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate66: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate72: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              },
              rate84: {
                type: "number",
                editable: $ctrl.enableDealerUsedRates
              }
            }
          }
        },
        batch: true,
        pageSize: 50
      });

      switch (cultureName) {
        case cultures.enUS:
        case cultures.enGB:
        case cultures.enCA:
        case cultures.frCA:
        case cultures.esUS:
          $ctrl.kendo.culture(cultureName);
          break;

        default:
          $ctrl.kendo.culture(cultures.enUS);
          break;
      }

      $("#k-datepicker").kendoDatePicker.widget.fn.options.min = new Date(); //$ctrl.kendoGrid = $("#gridDataEntryUsedRates").data("kendoGrid");
    }));
  }

  function getKendoGrid() {
    return $("#gridDataEntryUsedRates").data("kendoGrid");
  }
}