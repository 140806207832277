import { InjectionToken } from '@angular/core';

// Temporary holding bucket for angularjs services that are needed in angular. We should upgrade these for real asap.

// CoreApiService
export const CoreApiService = new InjectionToken<string>('CoreApiService');

export function coreApiServiceFactory(i: any) {
  return i.get('coreApi');
}

export const coreApiServiceProvider = {
  provide: CoreApiService,
  useFactory: coreApiServiceFactory,
  deps: ['$injector']
};

// LegacyService
export const LegacyService = new InjectionToken<string>('LegacyService');

export function legacyServiceFactory(i: any) {
  return i.get('legacyService');
}

export const legacyServiceProvider = {
  provide: LegacyService,
  useFactory: legacyServiceFactory,
  deps: ['$injector']
};

// Auth
export const AuthService = new InjectionToken<string>('AuthService');

export function authServiceFactory(i: any) {
  return i.get('$auth');
}

export const authServiceProvider = {
  provide: AuthService,
  useFactory: authServiceFactory,
  deps: ['$injector']
};

// OpportunityService
export const OpportunityService = new InjectionToken<string>('OpportunityService');

export function opportunityServiceFactory(i: any) {
    return i.get('opportunityService');
}

export const opportunityServiceProvider = {
    provide: OpportunityService,
    useFactory: opportunityServiceFactory,
    deps: ['$injector']
};

// DealSheetService
export const DealSheetService = new InjectionToken<string>('DealSheetService');

export function dealSheetServiceFactory(i: any) {
  return i.get('dealSheetService');
}

export const dealSheetServiceProvider = {
  provide: DealSheetService,
  useFactory: dealSheetServiceFactory,
  deps: ['$injector']
};


// RecentDealSheetService
export const RecentDealSheetService = new InjectionToken<string>('RecentDealSheetService');

export function recentDealSheetServiceFactory(i: any) {
    return i.get('recentDealSheetService');
}

export const recentDealSheetServiceProvider = {
    provide: RecentDealSheetService,
    useFactory: recentDealSheetServiceFactory,
    deps: ['$injector']
};

// TemplateService
export const TemplateService = new InjectionToken<string>('TemplateService');

export function templateServiceFactory(i: any) {
    return i.get('templateService');
}

export const templateServiceProvider = {
    provide: TemplateService,
    useFactory: templateServiceFactory,
    deps: ['$injector']
};


//$rootScope
export const $rootScope = new InjectionToken<string>('$rootScope');

export function rootScopeFactory(i: any) {
    return i.get('$rootScope');
}

export const rootScopeProvider = {
    provide: $rootScope,
    useFactory: rootScopeFactory,
    deps: ['$injector']
};

// EulaService
export const EulaModalService = new InjectionToken<string>('EulaModalService');

export function eulaModalServiceFactory(i: any) {
    return i.get('eulaModalService');
}

export const eulaModalServiceProvider = {
    provide: EulaModalService,
    useFactory: eulaModalServiceFactory,
    deps: ['$injector']
};


// VehicleService
export const VehicleService = new InjectionToken<string>('VehicleService');

export function vehicleServiceFactory(i: any) {
    return i.get('vehicleService');
}

export const vehicleServiceProvider = {
    provide: VehicleService,
    useFactory: vehicleServiceFactory,
    deps: ['$injector']

};


// ContractService
export const ContractService = new InjectionToken<string>('ContractService');

export function contractServiceFactory(i: any) {
    return i.get('contractService');
}

export const contractServiceProvider = {
    provide: ContractService,
    useFactory: contractServiceFactory,
    deps: ['$injector']
};


// MapUtilService
export const MapUtilsService = new InjectionToken<string>('MapUtilsService');

export function mapUtilsServiceFactory(i: any) {
    return i.get('mapUtils');
}

export const mapUtilsServiceProvider = {
    provide: MapUtilsService,
    useFactory: mapUtilsServiceFactory,
    deps: ['$injector']
};

// MapUtilService
export const TmhDynamicLocale = new InjectionToken<string>('TmhDynamicLocale');

export function tmhDynamicLocaleFactory(i: any) {
    return i.get('tmhDynamicLocale');
}

export const tmhDynamicLocaleProvider = {
    provide: TmhDynamicLocale,
    useFactory: tmhDynamicLocaleFactory,
    deps: ['$injector']
};
