<span *ngIf="!isSltUser" class="">
    <button title="{{'presetIconToolTip' | translate}}" class="transparent" type="button" (click)="toggleSearchPresets()">
        <span><icon class="icon aa-icon-core-savedsearches-icon-000000"></icon></span>
    </button>
</span>
<div *ngIf="!isSltUser && visible" class="recent-search header-modal-style">
    <h5 class="h5-modal" translate="searches"></h5>
    <div class="spinner" [ngBusy]="{busy: busy, delay: 200}" *ngIf="searchPresets.length == 0"></div>
    <div class="header-modal-body">
        <h5 class="predefined" *ngIf="userPresets.length > 0" translate="userSearches"></h5>
        <hr *ngIf="userPresets.length > 0" />
        <ul class="row">
            <li class="col-sm-6" *ngFor="let userPreset of userPresets">
                <a href="#" (click)="navigate(userPreset.userSearchConditionID)">{{userPreset.searchConditionName}}</a>
            </li>
        </ul>
        <h5 class="predefined" *ngIf="dealerPresets.length > 0" translate="dealerSearches"></h5>
        <hr *ngIf="dealerPresets.length > 0" />
        <ul class="row">
            <li class="col-sm-6" *ngFor="let dealerPreset of dealerPresets | slice:0:10">
                <a href="#" (click)="navigate(dealerPreset.userSearchConditionID)">{{dealerPreset.searchConditionName}}</a>
            </li>
        </ul>
        <h5 class="predefined" *ngIf="commonPresets.length > 0" translate="predefinedSearches"></h5>
        <hr *ngIf="commonPresets.length > 0" />
        <ul class="row">
            <li class="col-sm-6" *ngFor="let commonPreset of commonPresets | slice:0:10">
                <a href="#" (click)="navigate(commonPreset.userSearchConditionID)">{{commonPreset.searchConditionName}}</a>
            </li>
        </ul>
    </div>
</div>