import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { UserService } from './../../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingResponseDto } from '../../generated/models';

@Component({
    selector: 'admin-settings-vhr-disclaimer',
    templateUrl: './admin-settings-vhr-disclaimer.component-ng.html'
})

export class AdminSettingsVHRDisclaimerComponent {
    @Output() acceptDisclaimer = new EventEmitter();

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public userService: UserService,
        public logger: ToasterLoggerService,
        public _bsModalRef: BsModalRef 
    ) {
    }

    public cancel(): void {
        this._bsModalRef.hide();
    }

    public ok(): void {
        this._bsModalRef.hide();
        this.acceptDisclaimer.emit();
    }
}