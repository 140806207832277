// Some typescript files may reference the angular.module. Make sure manifest stays imported first!
// If you added/converted a constant class that is also used in AngularJS, it needs to be added also in
// ./core/opportunities.manifest.js file.
import './core/opportunities.manifest.js';
/////////////////////////////////////////////////////////////////////////////////////////////////////
import { SafePipe } from './filters/safe.pipe';
import { NgModule, Provider, APP_INITIALIZER, enableProdMode, ErrorHandler, } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule, downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { configureTranslation } from './core/translation';
import { IntlService } from '@progress/kendo-angular-intl';
import { registerLocaleData } from '@angular/common';

import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ReactiveFormsModule } from '@angular/forms';

import { ToasterModule, ToasterService } from 'angular2-toaster';
import { ToasterLoggerService } from './core/toaster-logger.service';

import { DeviceDetectorModule } from 'ngx-device-detector';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import '@progress/kendo-angular-intl/locales/es/all';
import { GridModule } from '@progress/kendo-angular-grid';

import { NgBusyModule } from 'ng-busy';
import { Ng5SliderModule } from 'ng5-slider';

import {
    coreApiServiceProvider
    , legacyServiceProvider
    , authServiceProvider
    , dealSheetServiceProvider
    , templateServiceProvider
    , opportunityServiceProvider
    , rootScopeProvider
    , vehicleServiceProvider
    , eulaModalServiceProvider
    , contractServiceProvider
    , mapUtilsServiceProvider,
    tmhDynamicLocaleProvider
} from './ajs-upgraded-providers';

import { MinDirective } from './directives/min.directive';
import { MaxDirective } from './directives/max.directive';

import { TextActionComponent } from './components/deal-sheet/text-action/text-action.component';

import { MoreVehicleOffersComponent } from './components/deal-sheet/vehicle/more-vehicle-offers/more-vehicle-offers.component';
// tslint:disable-next-line:max-line-length
import { PaymentDifferenceComponent } from './components/deal-sheet/vehicle/more-vehicle-offers/payment-difference/payment-difference.component';
import { CalendarDateRangeComponent } from './components/calendar-date-range.component-ng';

import { TradeSourceLogoComponent } from './components/deal-sheet/contract/trade-source-logo/trade-source-logo.component';
import { DealHistoryComponent } from './components/deal-sheet/deal-history/deal-history.component';
import angular from 'angular';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { TextMessageActivityComponent } from './components/deal-sheet/text-message-activity/text-message-activity.component';
import { AdminRolesComponent } from './components/admin/admin-roles.component';
import { AdminRolesModalAddEditComponent } from './components/admin/admin-roles-modal-addedit.component';
import { AdminSettingsModalSendTestLeadComponent } from './components/admin/admin-settings-modal-sendtestlead.component';
import { AdminSettingsVHRDisclaimerComponent } from './components/admin/admin-settings-vhr-disclaimer.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal.component';
import { AdminRolesSelectorComponent } from './components/admin/admin-roles-selector.component';
import { AdminSettingsComponent } from './components/admin/admin-settings.component';
import { AdminSettingsGeneralComponent } from './components/admin/admin-settings-general.component';
import { AdminSettingsProductComponent } from './components/admin/admin-settings-product.component';
import { AdminSettingsProgramsComponent } from './components/admin/admin-settings-programs.component';
import { AdminSettingsOpportunityComponent } from './components/admin/admin-settings-opportunity.component';
import { AdminSettingsInventoryComponent } from './components/admin/admin-settings-inventory.component';
import { AdminSettingsDealerInfoComponent } from './components/admin/admin-settings-dealerinfo.component';
import { AdminSettingsAdvancedComponent } from './components/admin/admin-settings-advanced.component';
import { AdminSettingsCreditConvertComponent } from './components/admin/admin-settings-creditconvert.component';
import { AdminSettingsCrmLeadsDeliveryComponent } from './components/admin/admin-settings-crmleadsdelivery.component';
import { TooltipComponent } from './components/tooltip.component';
import { LocaleDateTimePipe } from './filters/locale-date-time.pipe';
import { LocaleTimePipe } from './filters/locale-time.pipe';
import { LocaleCurrencyPipe } from './filters/locale-currency.pipe';
import { AdaptivePositionPipe } from './filters/adaptive-position.pipe';
import { TextMessageService } from './services/text-message.service';
import { SearchPresetService } from './services/search-preset.service';
import { LocaleDatePipe } from './filters/locale-date.pipe';
import { LocaleMoneyFactorPipe } from './filters/locale-money-factor.pipe';
import { windowRefProvider, WindowWrapper } from './services/window-ref.service';
import { crossDomainStorageServiceProvider, CrossDomainStorageService } from './modules/storage/cross-domain-storage.service';
import { StorageService } from './modules/storage/storage.service';
import { LogFactory } from './services/log-factory.service';
import { TokenService } from './modules/oidc-auth/token.service';
import { StockNumberSearchService } from './services/stock-number-search.service';
import { UserService } from './services/user.service';
import { CustomerWorkflowService } from './services/customer-workflow.service';
import { NgSelectedDealersInterceptor } from './services/http/ng-selected-dealers-interceptor';
import { NgOidcHttpInterceptor } from './services/http/ng-oidc-http.interceptor';
import { SearchPresetsComponent } from './components/header/search-presets/search-presets.component';
import { PandoCommunicationService } from './services/pando-communication.service';
import { RecentDealSheetService } from './services/recent-deal-sheet.service';
import { CustomerCultureSelectComponent } from './components/general/customer-culture-select/customer-culture-select.component';

// By default angular only includes en-US locale or a locale you build with
// since we switch dynamically we need to register locales manually
import localeFr from '@angular/common/locales/fr';

import { AlertminerApiModule } from './generated/alertminer-api.module';
import { AlertminerApiConfiguration } from './generated/alertminer-api-configuration';
import { Settings } from './constants/app-settings';

import { ActivityService } from './services/activity.service';
import { DashboardOpportunitiesTableComponent } from './components/dashboard/dashboard-opportunities-table/dashboard-opportunities-table.component-ng';
import { AlertsComponent } from './components/deal-sheet/alerts/alerts.component-ng';

import { AutoAssistantService } from './services/auto-assistant.service';

import { LocaleNumberPipe } from './filters/locale-number.pipe';
import { AlertComponent } from './components/deal-sheet/alert/alert.component-ng';
import { IconComponent } from './components/icon/icon.component';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { DefaultTabActionComponent } from './components/deal-sheet/default-tab-action/default-tab-action.component';
import { AdminTemplatesComponent } from './components/admin/admin-templates/admin-templates.component';
import { DoNotCallEmailComponent } from './components/admin/do-not-call-email/do-not-call-email.component';
import { MenuService } from './services/menu.service';
import { CustomerService } from './services/customer.service';
import { LocalePhonePipe } from './filters/locale-phone.pipe';
import { NgNoCacheInterceptor } from './services/http/ng-no-cache-interceptor';
import { AdminRoleService } from './services/admin-role.service';
import { DealerSettingsService } from './services/dealer-settings.service';
import { AdminTemplatesService } from './services/admin-templates.service';

import { NgxToaster } from './components/ngx-toaster.component';
import { UserSettingsComponent } from './components/header/user-settings.component/user-settings.component';
import { AdminTemplatesEditModalComponent } from './components/admin/admin-templates/admin-templates-edit-modal.component';
import { TaggedCustomersComponent } from './components/dashboard/tagged-customers/tagged-customers.component';
import { StockNumberSearchComponent } from './components/header/stock-number-search/stock-number-search.component';

import { DisplayModalComponent } from './components/modals/display-modal/display-modal.component';
import { PandoAvailabilityComponent } from './components/header/pando-availability/pando-availability.component';
import { PandoUnreadMessagesComponent } from './components/header/pando-unread-messages.component/pando-unread-messages.component';
import { RecentDealSheetsComponent } from './components/header/recent-deal-sheets/recent-deal-sheets.component';
import { ImpersonationStatusComponent } from './components/header/impersonation-status/impersonation-status.component';
import { SearchPresetCountsComponent } from './components/dashboard/search-preset-counts/search-preset-counts.component';
import { DealerService } from './services/dealer.service';
import { NotificationService } from './services/notification.service';
import { CustomerEngagementService } from './services/customer-engagement.service';
import { ModalComponent } from './components/modals/modal/modal.component';
import { CommunicationApiService } from './services/communication-api.service';
import { QuickLinksComponent } from './components/dashboard/quick-links/quick-links.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DashboardAgendaComponent } from './components/dashboard/dashboard-agenda/dashboard-agenda.component';
import { AuthorizationService } from './services/authorization.service';
import { DataEntryService } from './services/data-entry.service';
import { Opportunity2Service } from './services/opportunity2.service';

import { NgContentTypeInterceptor } from './services/http/ng-content-type.interceptor';
import { TypeOfCallComponent } from './components/deal-sheet/type-of-call/type-of-call.component';
import { LoggedInDataInitializationService } from './core/logged-in-data-initialization.service';
import { MobileDealSheetComponent } from './components/deal-sheet/mobile/mobile-deal-sheet/mobile-deal-sheet.component';
import { MobileVehiclesComponent } from './components/deal-sheet/mobile/mobile-vehicles/mobile-vehicles.component';
import { VehicleSummaryComponent } from './components/deal-sheet/vehicle/vehicle-summary/vehicle-summary.component';
import { SaleTypeComponent } from './components/deal-sheet/contract/sale-type/sale-type.component';
import { PaymentsComponent } from './components/deal-sheet/contract/payments/payments.component';
import { AnnualPercentageRateComponent } from './components/deal-sheet/contract/annual-percentage-rate/annual-percentage-rate.component';
import { ReplacementContractsComponent } from './components/deal-sheet/contract/replacement-contracts/replacement-contracts.component';
import { CustomerNameComponent } from './components/deal-sheet/customer-name/customer-name.component';
import { AllowedMileageComponent } from './components/deal-sheet/contract/allowed-mileage/allowed-mileage.component';
import { MoneyFactorComponent } from './components/deal-sheet/contract/money-factor/money-factor.component';
import { RepairOrdersComponent } from './components/deal-sheet/contract/repair-orders/repair-orders.component';
import { ServiceAppointmentComponent } from './components/deal-sheet/contract/service-appointment/service-appointment.component';
import { MileageEditActionComponent } from './components/deal-sheet/contract/mileage-edit-action/mileage-edit-action.component';
import { ProjectedMileageComponent } from './components/deal-sheet/contract/projected-mileage/projected-mileage.component';
import { MileagePenaltyComponent } from './components/deal-sheet/contract/mileage-penalty/mileage-penalty.component';
import { CashPaidComponent } from './components/deal-sheet/contract/cash-paid/cash-paid.component';
import { TradeInValueComponent } from './components/deal-sheet/vehicle/trade-in-value/trade-in-value.component';
import { CashDownComponent } from './components/deal-sheet/contract/cash-down/cash-down.component';
import { PayoffComponent } from './components/deal-sheet/contract/payoff/payoff.component';
import { ContractEndComponent } from './components/deal-sheet/contract/contract-end/contract-end.component';
import { ExtendedWarrantyComponent } from './components/deal-sheet/contract/extended-warranty/extended-warranty.component';
import { AutoCheckComponent } from './components/deal-sheet/contract/auto-check/auto-check.component';
import { TagsComponent } from './components/deal-sheet/tags/tags.component';
import { CarfaxComponent } from './components/deal-sheet/contract/carfax/carfax.component';
import { Carfaxv2Component } from './components/deal-sheet/contract/carfaxv2/carfaxv2.component';
import { CarproofComponent } from './components/deal-sheet/contract/carproof/carproof.component';
import { ReplacementVehicleOffersComponent } from './components/deal-sheet/vehicle/replacement-vehicle-offers/replacement-vehicle-offers.component';
import { FactoryRebateComponent } from './components/deal-sheet/contract/factory-rebate/factory-rebate.component';
import { DealerRebateComponent } from './components/deal-sheet/contract/dealer-rebate/dealer-rebate.component';
import { PersonalizedOfferComponent } from './components/deal-sheet/contract/personalized-offer/personalized-offer.component';
import { PullForwardComponent } from './components/deal-sheet/contract/pull-forward/pull-forward.component';
import { PreferredEquityComponent } from './components/deal-sheet/contract/preferred-equity/preferred-equity.component';
import { LoyaltyRebateComponent } from './components/deal-sheet/contract/loyalty-rebate/loyalty-rebate.component';
import { FuelDifferenceComponent } from './components/deal-sheet/vehicle/fuel-difference/fuel-difference.component';
import { MileageComponent } from './components/deal-sheet/vehicle/mileage/mileage.component';
import { PriceComponent } from './components/deal-sheet/vehicle/price/price.component';
import { FinancedAmountComponent } from './components/deal-sheet/contract/financed-amount/financed-amount.component';
import { CapitalCostComponent } from './components/deal-sheet/contract/capital-cost/capital-cost.component';
import { ResidualComponent } from './components/deal-sheet/contract/residual/residual.component';
import { MobileActivitiesComponent } from './components/deal-sheet/mobile/mobile-activities/mobile-activities.component';
import { ActivityListComponent } from './components/deal-sheet/activity-list/activity-list.component';
import { MobileBackNavigationComponent } from './components/deal-sheet/mobile/mobile-back-navigation/mobile-back-navigation.component';
import { CustomerConnectComponent } from './components/deal-sheet/customer-connect/customer-connect.component';
import { CustomerInfoComponent } from './components/deal-sheet/mobile/customer-info/customer-info.component';
import { MobileCustomerPhoneComponent } from './components/deal-sheet/mobile/mobile-customer-phone/mobile-customer-phone.component';
import { MobileCustomerEmailComponent } from './components/deal-sheet/mobile/mobile-customer-email/mobile-customer-email.component';
import { UIRouterModule } from '@uirouter/angular';
import { MobileReplacementContractComponent } from './components/deal-sheet/mobile/mobile-replacement-contract/mobile-replacement-contract.component';
import { DealSheetProcessComponent } from './components/deal-sheet/modals/deal-sheet-process.component';
import { OpenProcessModalComponent } from './components/deal-sheet/modals/open-process-modal.component';
import { MobileCustomerConnectComponent } from './components/deal-sheet/mobile/mobile-customer-connect/mobile-customer-connect.component';
import { LogActionShortcutsComponent } from './components/deal-sheet/log-action-shortcuts/log-action-shortcuts.component';
import { EmailTemplateActionComponent } from './components/deal-sheet/email-template-action/email-template-action.component';
import { CustomerConnectActionsComponent } from './components/deal-sheet/customer-connect/customer-connect-actions/customer-connect-actions.component';
import { LogActionShortcutService } from './services/log-action-shortcut.service';
import { CustomerConnectDetailComponent } from './components/deal-sheet/customer-connect/customer-connect-detail/customer-connect-detail.component';
import { CustomerConnectLogActionComponent } from './components/deal-sheet/customer-connect/customer-connect-log-action/customer-connect-log-action.component';
// tslint:disable-next-line: max-line-length
import { CustomerConnectScheduleActionComponent } from './components/deal-sheet/customer-connect/customer-connect-schedule-action/customer-connect-schedule-action.component';
// tslint:disable-next-line: max-line-length
import { CustomerConnectSubActionsComponent } from './components/deal-sheet/customer-connect/customer-connect-sub-actions/customer-connect-sub-actions.component';
import { CustomerConnectSubDetailComponent } from './components/deal-sheet/customer-connect/customer-connect-sub-detail/customer-connect-sub-detail.component';
import { ConfirmationModalComponent } from './components/deal-sheet/modals/confirmation-modal/confirmation-modal.component';
import { VehicleImageComponent } from './components/deal-sheet/vehicle/vehicle-image/vehicle-image.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DateFormatService } from './services/date-format.service';
import { MileageEditModalComponent } from './components/deal-sheet/contract/mileage-edit-modal/mileage-edit-modal.component';
import { PayoffInputModalComponent } from './components/deal-sheet/contract/payoff-input-modal/payoff-input-modal.component';
import { DeskitComponent } from './components/deal-sheet/deskit/deskit.component';
import { VautoComponent } from './components/deal-sheet/contract/vauto/vauto.component';
import { VautoModalComponent } from './components/deal-sheet/contract/vauto-modal/vauto-modal.component';
import { DmsPushComponent } from './components/deal-sheet/dms-push/dms-push.component';
import { DmsPushModalComponent } from './components/deal-sheet/dms-push-modal/dms-push-modal.component';
import { ScoreInfoComponent } from './components/deal-sheet/score-info/score-info.component';
import { ScoreInfoModalComponent } from './components/deal-sheet/score-info-modal/score-info-modal.component';
import { NloInfoComponent } from './components/deal-sheet/nlo-info/nlo-info.component';
import { NloInfoModalComponent } from './components/deal-sheet/nlo-info-modal/nlo-info-modal.component';


angular.module('AaApp.Opp')
    .directive('moreVehicleOffers', downgradeComponent({ component: MoreVehicleOffersComponent }))
    .directive('tradeSourceLogo', downgradeComponent({ component: TradeSourceLogoComponent }))
    .directive('textAction', downgradeComponent({ component: TextActionComponent }))
    .directive('calendarDateRange', downgradeComponent({ component: CalendarDateRangeComponent }))
    .directive('textMessageActivity', downgradeComponent({ component: TextMessageActivityComponent }))
    .directive('adminRoles', downgradeComponent({ component: AdminRolesComponent }))
    .directive('adminRolesSelector', downgradeComponent({ component: AdminRolesSelectorComponent }))
    .directive('adminRolesModalAddEdit', downgradeComponent({ component: AdminRolesModalAddEditComponent }))
    .directive('confirmModal', downgradeComponent({ component: ConfirmModalComponent }))
    .directive('dealHistory', downgradeComponent({ component: DealHistoryComponent }))
    .directive('alert', downgradeComponent({ component: AlertComponent }))
    .directive('alerts', downgradeComponent({ component: AlertsComponent }))
    .directive('adminTemplates', downgradeComponent({ component: AdminTemplatesComponent }))
    .directive('dashboardOpportunitiesTable', downgradeComponent({ component: DashboardOpportunitiesTableComponent }))
    .directive('icon', downgradeComponent({ component: IconComponent }))
    .directive('defaultTabAction', downgradeComponent({ component: DefaultTabActionComponent }))
    .directive('ngxToaster', downgradeComponent({ component: NgxToaster }))
    .directive('userSettings', downgradeComponent({ component: UserSettingsComponent }))
    .directive('searchPresets', downgradeComponent({ component: SearchPresetsComponent }))
    .directive('stockNumberSearch', downgradeComponent({ component: StockNumberSearchComponent }))
    .directive('taggedCustomers', downgradeComponent({ component: TaggedCustomersComponent }))
    .directive('pandoAvailability', downgradeComponent({ component: PandoAvailabilityComponent }))
    .directive('pandoUnreadMessages', downgradeComponent({ component: PandoUnreadMessagesComponent }))
    .directive('recentDealSheets', downgradeComponent({ component: RecentDealSheetsComponent }))
    .directive('impersonationStatus', downgradeComponent({ component: ImpersonationStatusComponent }))
    .directive('searchPresetCounts', downgradeComponent({ component: SearchPresetCountsComponent }))
    .directive('modal', downgradeComponent({ component: ModalComponent }))
    .directive('quickLinks', downgradeComponent({ component: QuickLinksComponent }))
    .directive('dashboardAgenda', downgradeComponent({ component: DashboardAgendaComponent }))
    .directive('customerCultureSelect', downgradeComponent({ component: CustomerCultureSelectComponent }))
    .directive('typeOfCall', downgradeComponent({ component: TypeOfCallComponent }))
    .directive('openProcessModal', downgradeComponent({ component: OpenProcessModalComponent }))
    .directive('logActionShortcuts', downgradeComponent({ component: LogActionShortcutsComponent }))
    .directive('customerConnect', downgradeComponent({ component: CustomerConnectComponent }))
    .directive('repairOrders', downgradeComponent({ component: RepairOrdersComponent }))
    .directive('serviceAppointment', downgradeComponent({ component: ServiceAppointmentComponent }))
    .directive('projectedMileage', downgradeComponent({ component: ProjectedMileageComponent }))
    .directive('mileagePenalty', downgradeComponent({ component: MileagePenaltyComponent }))
    .directive('vehicleSummary', downgradeComponent({ component: VehicleSummaryComponent }))
    .directive('saleType', downgradeComponent({ component: SaleTypeComponent }))
    .directive('allowedMileage', downgradeComponent({ component: AllowedMileageComponent }))
    .directive('annualPercentageRate', downgradeComponent({ component: AnnualPercentageRateComponent }))
    .directive('cashPaid', downgradeComponent({ component: CashPaidComponent }))
    .directive('tradeInValue', downgradeComponent({ component: TradeInValueComponent }))
    .directive('moneyFactor', downgradeComponent({ component: MoneyFactorComponent }))
    .directive('dateInput', downgradeComponent({ component: DateInputComponent }))
    .directive('tags', downgradeComponent({ component: TagsComponent }))
    .directive('payments', downgradeComponent({ component: PaymentsComponent }))
    .directive('mileageEditAction', downgradeComponent({ component: MileageEditActionComponent }))
    .directive('mileageEditModal', downgradeComponent({ component: MileageEditModalComponent }))
    .directive('cashDown', downgradeComponent({ component: CashDownComponent }))
    .directive('payoff', downgradeComponent({ component: PayoffComponent }))
    .directive('payoffInputModal', downgradeComponent({ component: PayoffInputModalComponent }))
    .directive('residual', downgradeComponent({ component: ResidualComponent }))
    .directive('financedAmount', downgradeComponent({ component: FinancedAmountComponent }))
    .directive('contractEnd', downgradeComponent({ component: ContractEndComponent }))
    .directive('extendedWarranty', downgradeComponent({ component: ExtendedWarrantyComponent }))
    .directive('replacementVehicleOffers', downgradeComponent({ component: ReplacementVehicleOffersComponent }))
    .directive('factoryRebate', downgradeComponent({ component: FactoryRebateComponent }))
    .directive('autoCheck', downgradeComponent({ component: AutoCheckComponent }))
    .directive('carfax', downgradeComponent({ component: CarfaxComponent }))
    .directive('carfaxv2', downgradeComponent({ component: Carfaxv2Component }))
    .directive('carproof', downgradeComponent({ component: CarproofComponent }))
    .directive('capitalCost', downgradeComponent({ component: CapitalCostComponent }))
    .directive('price', downgradeComponent({ component: PriceComponent }))
    .directive('dealerRebate', downgradeComponent({ component: DealerRebateComponent }))
    .directive('deskit', downgradeComponent({ component: DeskitComponent }))
    .directive('vauto', downgradeComponent({ component: VautoComponent }))
    .directive('vautoModal', downgradeComponent({ component: VautoModalComponent }))
    .directive('dmsPush', downgradeComponent({ component: DmsPushComponent }))
    .directive('dmsPushModal', downgradeComponent({ component: DmsPushModalComponent }))
    .directive('scoreInfo', downgradeComponent({ component: ScoreInfoComponent }))
    .directive('scoreInfoModal', downgradeComponent({ component: ScoreInfoModalComponent }))
    .directive('nloInfo', downgradeComponent({ component: NloInfoComponent }))
    .directive('nloInfoModal', downgradeComponent({ component: NloInfoModalComponent }))

    .factory('textMessageService', downgradeInjectable(TextMessageService as any))
    .factory('searchPresetService', downgradeInjectable(SearchPresetService as any))
    .factory('crossDomainStorageService', downgradeInjectable(CrossDomainStorageService))
    .factory('storageService', downgradeInjectable(StorageService as any))
    .factory('$logFactory', downgradeInjectable(LogFactory as any))
    .factory('tokenService', downgradeInjectable(TokenService as any))
    .factory('stockNumberSearchService', downgradeInjectable(StockNumberSearchService as any))
    .factory('activityService', downgradeInjectable(ActivityService as any))
    .factory('menuService', downgradeInjectable(MenuService as any))
    .factory('customerService', downgradeInjectable(CustomerService as any))
    .factory('userService', downgradeInjectable(UserService as any))
    .factory('toaster', downgradeInjectable(ToasterService as any))
    .factory('logger', downgradeInjectable(ToasterLoggerService as any))
    .factory('pandoCommunicationService', downgradeInjectable(PandoCommunicationService as any))
    .factory('recentDealSheetService', downgradeInjectable(RecentDealSheetService as any))
    .factory('dealerService', downgradeInjectable(DealerService as any))
    .factory('notificationService', downgradeInjectable(NotificationService as any))
    .factory('customerEngagementService', downgradeInjectable(CustomerEngagementService as any))
    .factory('communicationApi', downgradeInjectable(CommunicationApiService as any))
    .factory('PortalErrorHandler', downgradeInjectable(ErrorHandler as any))
    .factory('authorizationService', downgradeInjectable(AuthorizationService as any))
    .factory('dataEntryService', downgradeInjectable(DataEntryService as any))
    .factory('opportunity2Service', downgradeInjectable(Opportunity2Service as any))
    .factory('loggedInDataInitializationService', downgradeInjectable(LoggedInDataInitializationService as any))
    .factory('autoAssistantService', downgradeInjectable(AutoAssistantService as any))
    .factory('loggedInDataInitializationService', downgradeInjectable(LoggedInDataInitializationService as any))
    .factory('logActionShortcutService', downgradeInjectable(LogActionShortcutService as any))
    .factory('dateFormatService', downgradeInjectable(DateFormatService as any));

registerLocaleData(localeFr);

export function initApiConfiguration(config: AlertminerApiConfiguration): Function {
    const settingsFunction = () => {
        config.rootUrl = Settings.apiUrls.alertMiner;
    };

    return settingsFunction;
}
export const INIT_API_CONFIGURATION: Provider = {
    provide: APP_INITIALIZER,
    useFactory: initApiConfiguration,
    deps: [AlertminerApiConfiguration],
    multi: true
};

@NgModule({
    declarations: [
        NgxToaster,
        AdminSettingsComponent,
        AdminSettingsGeneralComponent,
        AdminSettingsAdvancedComponent,
        AdminSettingsProductComponent,
        AdminSettingsProgramsComponent,
        AdminSettingsOpportunityComponent,
        AdminSettingsInventoryComponent,
        AdminSettingsDealerInfoComponent,
        AdminSettingsCreditConvertComponent,
        AdminSettingsCrmLeadsDeliveryComponent,
        AdminRolesComponent,
        AdminRolesModalAddEditComponent,
        AdminSettingsModalSendTestLeadComponent,
        AdminSettingsVHRDisclaimerComponent,
        AdminRolesSelectorComponent,
        AdminTemplatesComponent,
        AdminTemplatesEditModalComponent,
        AlertComponent,
        AlertsComponent,
        CalendarDateRangeComponent,
        ConfirmModalComponent,
        DashboardOpportunitiesTableComponent,
        DealHistoryComponent,
        DefaultTabActionComponent,
        DisplayModalComponent,
        DoNotCallEmailComponent,
        IconComponent,
        LocaleCurrencyPipe,
        AdaptivePositionPipe,
        LocaleDatePipe,
        LocaleDateTimePipe,
        LocaleTimePipe,
        LocaleNumberPipe,
        LocalePhonePipe,
        LocaleMoneyFactorPipe,
        MoreVehicleOffersComponent,
        PandoUnreadMessagesComponent,
        PaymentDifferenceComponent,
        SafePipe,
        SearchPresetsComponent,
        TaggedCustomersComponent,
        PandoAvailabilityComponent,
        TextActionComponent,
        TextMessageActivityComponent,
        TradeSourceLogoComponent,
        UserSettingsComponent,
        RecentDealSheetsComponent,
        ImpersonationStatusComponent,
        SearchPresetCountsComponent,
        StockNumberSearchComponent,
        ModalComponent,
        QuickLinksComponent,
        DashboardAgendaComponent,
        TooltipComponent,
        CustomerCultureSelectComponent,
        TypeOfCallComponent,
        MobileDealSheetComponent,
        MobileVehiclesComponent,
        VehicleSummaryComponent,
        SaleTypeComponent,
        PaymentsComponent,
        AnnualPercentageRateComponent,
        ReplacementContractsComponent,
        CustomerNameComponent,
        AllowedMileageComponent,
        MoneyFactorComponent,
        RepairOrdersComponent,
        ServiceAppointmentComponent,
        MileageEditActionComponent,
        ProjectedMileageComponent,
        MileagePenaltyComponent,
        CashPaidComponent,
        TradeInValueComponent,
        CashDownComponent,
        PayoffComponent,
        ContractEndComponent,
        ExtendedWarrantyComponent,
        AutoCheckComponent,
        TagsComponent,
        TypeOfCallComponent,
        CarfaxComponent,
        Carfaxv2Component,
        CarproofComponent,
        ReplacementVehicleOffersComponent,
        FactoryRebateComponent,
        DealerRebateComponent,
        PersonalizedOfferComponent,
        PullForwardComponent,
        PreferredEquityComponent,
        LoyaltyRebateComponent,
        FuelDifferenceComponent,
        MileageComponent,
        PriceComponent,
        FinancedAmountComponent,
        CapitalCostComponent,
        ResidualComponent,
        MobileActivitiesComponent,
        ActivityListComponent,
        MobileBackNavigationComponent,
        CustomerConnectComponent,
        CustomerInfoComponent,
        MobileReplacementContractComponent,
        MobileCustomerPhoneComponent,
        MobileCustomerEmailComponent,
        DealSheetProcessComponent,
        OpenProcessModalComponent,
        MobileCustomerConnectComponent,
        MobileCustomerConnectComponent,
        LogActionShortcutsComponent,
        EmailTemplateActionComponent,
        CustomerConnectActionsComponent,
        VehicleImageComponent,
        DateInputComponent,
        MileageEditModalComponent,
        DateInputComponent,
        VehicleImageComponent,
        CustomerConnectActionsComponent,
        CustomerConnectDetailComponent,
        CustomerConnectLogActionComponent,
        CustomerConnectScheduleActionComponent,
        CustomerConnectSubActionsComponent,
        CustomerConnectSubDetailComponent,
        ConfirmationModalComponent,
        PayoffInputModalComponent,
        MinDirective,
        MaxDirective,
        DeskitComponent,
        VautoComponent,
        VautoModalComponent,
        DmsPushComponent,
        DmsPushModalComponent,
        ScoreInfoComponent,
        ScoreInfoModalComponent,
        NloInfoComponent,
        NloInfoModalComponent,
    ],
    imports: [
        UIRouterModule,
        BrowserAnimationsModule,
        BrowserModule,
        AlertminerApiModule,
        DateInputsModule,
        FormsModule,
        GridModule,
        HttpClientModule,
        IntlModule,
        NgBusyModule,
        AngularMultiSelectModule,
        ReactiveFormsModule,
        ToasterModule.forRoot(),
        TranslateModule.forRoot(),
        UIRouterUpgradeModule.forRoot(),
        UpgradeModule,
        Ng5SliderModule,

        DeviceDetectorModule.forRoot(),
        // ngx-bootstrap modules
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        CollapseModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ButtonsModule.forRoot(),
        PopupModule
    ],
    entryComponents: [
        // Everything that is used by AngularJS needs to be an entry component
        NgxToaster,
        MoreVehicleOffersComponent,
        TradeSourceLogoComponent,
        TextActionComponent,
        CalendarDateRangeComponent,
        TextMessageActivityComponent,
        AdminRolesComponent,
        AdminSettingsComponent,
        AdminSettingsGeneralComponent,
        AdminSettingsProductComponent,
        AdminSettingsProgramsComponent,
        AdminSettingsOpportunityComponent,
        AdminSettingsInventoryComponent,
        AdminSettingsDealerInfoComponent,
        AdminRolesSelectorComponent,
        AdminRolesModalAddEditComponent,
        AdminSettingsModalSendTestLeadComponent,
        AdminSettingsVHRDisclaimerComponent,
        AdminSettingsAdvancedComponent,
        AdminSettingsCreditConvertComponent,
        AdminSettingsCrmLeadsDeliveryComponent,
        ConfirmModalComponent,
        IconComponent,
        AlertsComponent,
        AlertComponent,
        DashboardOpportunitiesTableComponent,
        DealHistoryComponent,
        DefaultTabActionComponent,
        AdminTemplatesComponent,
        DoNotCallEmailComponent,
        UserSettingsComponent,
        AdminTemplatesEditModalComponent,
        SearchPresetsComponent,
        TaggedCustomersComponent,
        DisplayModalComponent,
        PandoAvailabilityComponent,
        PandoUnreadMessagesComponent,
        RecentDealSheetsComponent,
        ImpersonationStatusComponent,
        SearchPresetCountsComponent,
        StockNumberSearchComponent,
        ModalComponent,
        QuickLinksComponent,
        DashboardAgendaComponent,
        TooltipComponent,
        CustomerCultureSelectComponent,
        TypeOfCallComponent,
        MobileDealSheetComponent,
        CustomerInfoComponent,
        MobileActivitiesComponent,
        MobileVehiclesComponent,
        MobileReplacementContractComponent,
        DealSheetProcessComponent,
        OpenProcessModalComponent,
        MobileCustomerConnectComponent,
        LogActionShortcutsComponent,
        CustomerConnectActionsComponent,
        RepairOrdersComponent,
        ServiceAppointmentComponent,
        ProjectedMileageComponent,
        MileagePenaltyComponent,
        VehicleSummaryComponent,
        VehicleImageComponent,
        SaleTypeComponent,
        AllowedMileageComponent,
        AnnualPercentageRateComponent,
        CashPaidComponent,
        TradeInValueComponent,
        MoneyFactorComponent,
        DateInputComponent,
        TagsComponent,
        PaymentsComponent,
        MileageEditActionComponent,
        MileageEditModalComponent,
        CashDownComponent,
        TagsComponent,
        DateInputComponent,
        AllowedMileageComponent,
        MileagePenaltyComponent,
        CustomerConnectComponent,
        PayoffComponent,
        PayoffInputModalComponent,
        ResidualComponent,
        FinancedAmountComponent,
        ContractEndComponent,
        ExtendedWarrantyComponent,
        ReplacementVehicleOffersComponent,
        FactoryRebateComponent,
        AutoCheckComponent,
        CarfaxComponent,
        Carfaxv2Component,
        CarproofComponent,
        CapitalCostComponent,
        PriceComponent,
        DealerRebateComponent,
        DeskitComponent,
        VautoComponent,
        VautoModalComponent,
        DmsPushComponent,
        DmsPushModalComponent,
        ConfirmationModalComponent,
        ScoreInfoComponent,
        ScoreInfoModalComponent,
        NloInfoComponent,
        NloInfoModalComponent,
  ],
    providers: [
        // upgraded service providers
        authServiceProvider,
        coreApiServiceProvider,
        dealSheetServiceProvider,
        legacyServiceProvider,
        opportunityServiceProvider,
        templateServiceProvider,
        rootScopeProvider,
        vehicleServiceProvider,
        eulaModalServiceProvider,
        contractServiceProvider,
        mapUtilsServiceProvider,
        tmhDynamicLocaleProvider,

        // native angular services
        ActivityService,
        AdminRoleService,
        DealerSettingsService,
        DealerService,
        PandoCommunicationService,
        AdminTemplatesService,
        CustomerService,
        LocaleCurrencyPipe,
        AdaptivePositionPipe,
        LocaleDatePipe,
        LocaleDateTimePipe,
        LocaleTimePipe,
        LocaleNumberPipe,
        LocalePhonePipe,
        LocaleMoneyFactorPipe,
        LogFactory,
        SearchPresetService,
        StockNumberSearchService,
        StorageService,
        TextMessageService,
        ToasterLoggerService,
        TokenService,
        TranslateService,
        UserService,
        CustomerWorkflowService,
        WindowWrapper,
        RecentDealSheetService,
        crossDomainStorageServiceProvider,
        CommunicationApiService,
        Opportunity2Service,
        AutoAssistantService,
        windowRefProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NgNoCacheInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NgSelectedDealersInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NgContentTypeInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NgOidcHttpInterceptor,
            multi: true
        },
        INIT_API_CONFIGURATION
    ]
})
export class AppModule {
    constructor(translate: TranslateService,
        private upgrade: UpgradeModule,
        intlService: IntlService) {
        configureTranslation(translate, intlService);
    }

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.getElementById('aa-app'), ['AaApp'], { strictDi: true });
    }
}
