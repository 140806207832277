import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { DealSheetService } from '../../../ajs-upgraded-providers';
import { RecentDealSheetService } from '../../../services/recent-deal-sheet.service';
import { DealSheetHistoryDto } from '../../../generated/models';
import { Alert } from '../../../interfaces/alert.interface';

@Component({
    selector: 'recent-deal-sheets',
    templateUrl: './recent-deal-sheets.component-ng.html',
    styleUrls: ['./recent-deal-sheets.component.scss']
})
export class RecentDealSheetsComponent {
    @Input() visible: boolean = false;
    @Output() visibleChange = new EventEmitter<boolean>();
    public recentDealSheets: DealSheetHistoryDto[] = [];
    public busy: Promise<DealSheetHistoryDto[]>;
    public alerts: Array<Alert>;

    constructor(@Inject(DealSheetService) private dealSheetService,
        private recentDealSheetService: RecentDealSheetService) {
    }

    toggleRecentDealSheets(): void {
        this.visible = !this.visible;
        if (this.visible) {
            this.busy = this.getRecentDealSheets();
        }
        this.visibleChange.emit(this.visible);
    }

    openDealSheet(dealSheetId: number) {
        this.visible = false;

        this.dealSheetService.openDealSheetModal(dealSheetId);
    }

    getRecentDealSheets(): Promise<DealSheetHistoryDto[]> {
        return this.recentDealSheetService.getRecentDealSheets()
            .then(recentDealSheetData => {
                this.recentDealSheets = recentDealSheetData;
                return recentDealSheetData;
            });
    }

    getAlertObjects(dealSheet: DealSheetHistoryDto): Array<Alert> {
        return dealSheet.alerts.map(a => {
            return {
                type: a
            } as Alert;
        });
    }
}
