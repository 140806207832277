
/* injects from baggage-loader */
require('./radio-group.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('radioGroup', {
    templateUrl: '/app/components/radio-group.component.html',
    controller: RadioGroupController,
    bindings: {
        options: '<',
        onSelect: '&'
    }
});

/* @ngInject */
function RadioGroupController() {
    var $ctrl = this;

    $ctrl.selection;
    $ctrl.onOptionSelect = onOptionSelect;

    function onOptionSelect() {
        $ctrl.onSelect({ selection: $ctrl.selection });
    }
}
