FacebookProfileActionController.$inject = ["userService"];

/* injects from baggage-loader */
require('./facebook-profile-action.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('facebookProfileAction', {
  templateUrl: '/app/components/deal-sheet/facebook-profile-action.component.html',
  controller: FacebookProfileActionController,
  bindings: {
    email: '<'
  }
});
/* @ngInject */

function FacebookProfileActionController(userService) {
  var $ctrl = this;
  $ctrl.facebookUserId;
  $ctrl.$onChanges = onChanges;

  function onChanges(changes) {
    if (changes.email && changes.email.currentValue) {
      userService.getFacebookUserId($ctrl.email).then(setFacebookUserId);
    }
  }

  function setFacebookUserId(userId) {
    $ctrl.facebookUserId = userId;
  }
}