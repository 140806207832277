import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class HttpInterceptorOptionsService {
    private _disableErrorHandler = false;
    private _overrideDealerIds: number[] = [];
    
    public disableErrorHandler(): void {
        this._disableErrorHandler = true;
    }

    public get disableErrorHandlerSetting(): boolean {
        const returnValue = this._disableErrorHandler;
        this._disableErrorHandler = false;
        return returnValue;
    }

    public setOverrideDealerIds(overrideDealerIds: number[]): void {
        this._overrideDealerIds = overrideDealerIds;
    }

    public get overrideDealerIdsSetting(): number[] {
        const returnValue = this._overrideDealerIds;
        this._overrideDealerIds = [];
        return returnValue;
    }
}
