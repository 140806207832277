/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealSheetHistoryDto } from '../models/deal-sheet-history-dto';
import { CalculationParamModel } from '../models/calculation-param-model';
import { Usp_GetCustomerEngagementData_ResponseDto } from '../models/usp-_get-customer-engagement-data-_response-dto';
import { Usp_GetCustomerEngagementsByEntityData_ResponseDto } from '../models/usp-_get-customer-engagements-by-entity-data-_response-dto';
import { Usp_GetCustomerEngagementsByEntityData_RequestDto } from '../models/usp-_get-customer-engagements-by-entity-data-_request-dto';
import { Usp_GetCustomerEngagementDashboardDto } from '../models/usp-_get-customer-engagement-dashboard-dto';
import { Usp_GetCustomerEngagementDashboard_RequestDto } from '../models/usp-_get-customer-engagement-dashboard-_request-dto';
@Injectable({
  providedIn: 'root',
})
class DealSheetClientService extends __BaseService {
  static readonly UserDealSheetHistoryGETPath = '/api/DealSheet/History';
  static readonly CalculatePaymentsPOSTPath = '/api/DealSheet/CalculatePayments';
  static readonly GetDealHistoryByEntityidPOSTPath = '/api/DealSheet/GetDealHistory/{entityId}';
  static readonly CustomerEngagementHistoryGETPath = '/api/DealSheet/CustomerEngagementHistory';
  static readonly CustomerEngagementsByEntityPOSTPath = '/api/DealSheet/CustomerEngagementsByEntity';
  static readonly HasReviewProDealerGETPath = '/api/DealSheet/HasReviewProDealer';
  static readonly CustomerEngagementDashboardPOSTPath = '/api/DealSheet/CustomerEngagementDashboard';
  static readonly GetCustomerEngagementLastViewedGETPath = '/api/DealSheet/CustomerEngagementLastViewed';
  static readonly UpdateCustomerEngagementLastViewedPOSTPath = '/api/DealSheet/CustomerEngagementLastViewed';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  UserDealSheetHistoryGETResponse(): __Observable<__StrictHttpResponse<Array<DealSheetHistoryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealSheet/History`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealSheetHistoryDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  UserDealSheetHistoryGET(): __Observable<Array<DealSheetHistoryDto>> {
    return this.UserDealSheetHistoryGETResponse().pipe(
      __map(_r => _r.body as Array<DealSheetHistoryDto>)
    );
  }

  /**
   * @param model undefined
   */
  CalculatePaymentsPOSTResponse(model?: CalculationParamModel): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealSheet/CalculatePayments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param model undefined
   */
  CalculatePaymentsPOST(model?: CalculationParamModel): __Observable<null> {
    return this.CalculatePaymentsPOSTResponse(model).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  GetDealHistoryByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealSheet/GetDealHistory/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetDealHistoryByEntityidPOST(entityId: string): __Observable<null> {
    return this.GetDealHistoryByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `DealSheetClientService.CustomerEngagementHistoryGETParams` containing the following parameters:
   *
   * - `lastViewedDate`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  CustomerEngagementHistoryGETResponse(params: DealSheetClientService.CustomerEngagementHistoryGETParams): __Observable<__StrictHttpResponse<Array<Usp_GetCustomerEngagementData_ResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastViewedDate != null) __params = __params.set('lastViewedDate', params.lastViewedDate.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealSheet/CustomerEngagementHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Usp_GetCustomerEngagementData_ResponseDto>>;
      })
    );
  }
  /**
   * @param params The `DealSheetClientService.CustomerEngagementHistoryGETParams` containing the following parameters:
   *
   * - `lastViewedDate`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  CustomerEngagementHistoryGET(params: DealSheetClientService.CustomerEngagementHistoryGETParams): __Observable<Array<Usp_GetCustomerEngagementData_ResponseDto>> {
    return this.CustomerEngagementHistoryGETResponse(params).pipe(
      __map(_r => _r.body as Array<Usp_GetCustomerEngagementData_ResponseDto>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CustomerEngagementsByEntityPOSTResponse(request?: Usp_GetCustomerEngagementsByEntityData_RequestDto): __Observable<__StrictHttpResponse<Array<Usp_GetCustomerEngagementsByEntityData_ResponseDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealSheet/CustomerEngagementsByEntity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Usp_GetCustomerEngagementsByEntityData_ResponseDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CustomerEngagementsByEntityPOST(request?: Usp_GetCustomerEngagementsByEntityData_RequestDto): __Observable<Array<Usp_GetCustomerEngagementsByEntityData_ResponseDto>> {
    return this.CustomerEngagementsByEntityPOSTResponse(request).pipe(
      __map(_r => _r.body as Array<Usp_GetCustomerEngagementsByEntityData_ResponseDto>)
    );
  }
  HasReviewProDealerGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealSheet/HasReviewProDealer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  HasReviewProDealerGET(): __Observable<null> {
    return this.HasReviewProDealerGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CustomerEngagementDashboardPOSTResponse(request?: Usp_GetCustomerEngagementDashboard_RequestDto): __Observable<__StrictHttpResponse<Array<Usp_GetCustomerEngagementDashboardDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealSheet/CustomerEngagementDashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Usp_GetCustomerEngagementDashboardDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CustomerEngagementDashboardPOST(request?: Usp_GetCustomerEngagementDashboard_RequestDto): __Observable<Array<Usp_GetCustomerEngagementDashboardDto>> {
    return this.CustomerEngagementDashboardPOSTResponse(request).pipe(
      __map(_r => _r.body as Array<Usp_GetCustomerEngagementDashboardDto>)
    );
  }
  GetCustomerEngagementLastViewedGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealSheet/CustomerEngagementLastViewed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetCustomerEngagementLastViewedGET(): __Observable<null> {
    return this.GetCustomerEngagementLastViewedGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  UpdateCustomerEngagementLastViewedPOSTResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealSheet/CustomerEngagementLastViewed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  UpdateCustomerEngagementLastViewedPOST(): __Observable<null> {
    return this.UpdateCustomerEngagementLastViewedPOSTResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DealSheetClientService {

  /**
   * Parameters for CustomerEngagementHistoryGET
   */
  export interface CustomerEngagementHistoryGETParams {
    lastViewedDate?: string;
    dateTo?: string;
    dateFrom?: string;
  }
}

export { DealSheetClientService }
