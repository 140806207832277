import angular from 'angular';
import moduleName from "./module";
import {
    tokenExpired$,
    tokenMissing$,
    unauthorized$,
} from './events';

angular.module(moduleName).factory('oidcHttpInterceptor', function ($logFactory, $q, $auth, $injector, storageService, tokenService) {
    "ngInject";

    const $log = $logFactory.get("oidcHttpInterceptor");

    function urlMatchesConfig(url) {
        return $auth.config.urls && $auth.config.urls.some(u => url.startsWith(u))
    }

    return {

        request(config) {
            if (urlMatchesConfig(config.url)) {
                var appendBearer = false;

                if ($auth.config.enableRequestChecks) {
                    // Only append token when it's valid.
                    if (tokenService.hasToken()) {
                        if (tokenService.hasValidToken()) {
                            appendBearer = true;
                        } else {
                            broadcast(tokenExpired$, { request: config });
                        }
                    } else {
                        broadcast(tokenMissing$, { request: config });
                    }
                } else {
                    appendBearer = tokenService.hasToken();
                }

                if (appendBearer) {
                    var token = tokenService.getTokenByType('access');
                    config.headers['Authorization'] = 'Bearer ' + token;
                    config.withCredentials = true; // Enable CORS
                }
            }

            // do something on success
            return config;
        },

        response(response) {
            if (urlMatchesConfig(response.config.url))
                // Proactively check if the token will expire soon
                $auth.validateExpirity();

            return response;
        },

        responseError(response) {

            if (response.status === 401) {
                if (!tokenService.hasToken()) {
                    // There was probably no token attached, because there is none
                    broadcast(tokenMissing$, { response: response });
                }
                else if (!tokenService.hasValidToken()) {
                    // Seems the token is not valid anymore
                    broadcast(tokenExpired$, { response: response });
                }
                else if (response.data === 'User is disabled' || response.data === 'User is locked' || response.data === 'IP Address is not valid') {
                    broadcast(unauthorized$, { response: response.data });
                }
                else if (response.config && response.config.url && response.config.url.indexOf('LoggedInUser') > -1) {
                    broadcast(unauthorized$);
                }
            } else {                
                if (!response.config.disableErrorHandler) { // "disableErrorHandler = true" means handle error manually
                    if (response.status !== -1 && isErrorEndpoint(response)) {
                        if (storageService.getItem('userProfile')) {
                            var logger = $injector.get('logger');
                            if (logger) {
                                if (response && response.data && response.data.error && response.data.error.translationKey) {
                                    logger.genericError(response.data.error.translationKey, response, 'responseError');
                                }
                                else {
                                    logger.genericError('responseError with API call', response, 'oidcHttpInterceptor:responseError');
                                }
                            } else {
                                $log.error('responseError with API call', response, 'oidcHttpInterceptor:responseError');
                            }
                        }
                    }
                }
            }

            return $q.reject(response);
        }

    };

    function broadcast(event$, data) {
        event$.next(data);
    }

    function isErrorEndpoint(response) {
        var excludedEndpoints = ['CreditPrescreenRequest'];

        for (var i = 0; i < excludedEndpoints.length; i++) {
            if (response.config.url.indexOf(excludedEndpoints[i]) > -1) {
                return false;
            }
        }

        return true;
    }
});
