<div [ngBusy]="busy">
    <div class="container" *ngIf="opportunity && permissions?.viewCustomer && opportunity?.customer?.isFullCustomer"
        style="padding-left:40px">
        <div class="row">{{'contactInformation' | translate}}</div>
        <hr />
        <mobile-customer-phone [doNotCall]="opportunity?.customer?.doNotCall" [type]="'customerCellPhone'"
            [phone]="opportunity?.customer?.cellPhoneNumber"></mobile-customer-phone>
        <hr />
        <mobile-customer-phone [doNotCall]="opportunity?.customer?.doNotCall" [type]="'customerHomePhone'"
            [phone]="opportunity?.customer?.homePhoneNumber"></mobile-customer-phone>
        <hr />
        <mobile-customer-phone [doNotCall]="opportunity?.customer?.doNotCall" [type]="'customerWorkPhone'"
            [phone]="opportunity?.customer?.workPhoneNumber"></mobile-customer-phone>
        <hr />
        <mobile-customer-email [doNotEmail]="opportunity?.customer?.doNotEmail" [type]="'customerEmail'"
            [email]="opportunity?.customer?.emailAddress"></mobile-customer-email>
    </div>
</div>