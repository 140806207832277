import angular from 'angular';

angular.module('AaApp.Opp').filter('localeRoundedCurrency', localeRoundedCurrencyFilter);

/* @ngInject */
function localeRoundedCurrencyFilter(storageService, localeNumberFilter) {
    return function (amount) {
        if (typeof amount == 'number') {
            amount = Math.round(amount);

            if ((amount % 10) != 0) {
                amount = (10 - Math.abs(amount) % 10) + amount;
            }

            var savedCultureName = storageService.getItem('cultureName');
            var cultureName = savedCultureName ? savedCultureName : 'en-us';

            var amountIsNegative;
            if (amount < 0) {
                amountIsNegative = true;
            }

            var absoluteAmount = Math.abs(amount);
            var formattedAmount;

            switch (cultureName.toLowerCase()) {
                case 'en-gb':
                    formattedAmount = '£' + localeNumberFilter(absoluteAmount);
                    break;
                case 'en-ca':
                    formattedAmount = '$' + localeNumberFilter(absoluteAmount);
                    break;
                case 'fr-ca':
                    formattedAmount = localeNumberFilter(absoluteAmount) + ' $';
                    break;
                case 'fr-fr':
                    formattedAmount = localeNumberFilter(absoluteAmount) + ' €';
                    break;
                case 'en-us':
                default:
                    formattedAmount = '$' + localeNumberFilter(absoluteAmount);
                    break;
            }

            if (amountIsNegative) {
                formattedAmount = '(' + formattedAmount + ')';
            }

            return formattedAmount;
        }
    };
}
