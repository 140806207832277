"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var opportunity2_service_1 = require("../../../services/opportunity2.service");
var NloInfoModalComponent = /** @class */ (function () {
    function NloInfoModalComponent(_bsModalRef, tService, opportunity2Service) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.opportunity2Service = opportunity2Service;
        this.onClosed = new core_1.EventEmitter();
        this.featuresLoaded = false;
    }
    NloInfoModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._bsModalRef.hide();
        this.nloData.forEach(function (item) {
            var transKey = 'nloCommSrc_' + item.communicationSourceID;
            item.communicationSource = _this.tService.instant(transKey);
        });
    };
    NloInfoModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    NloInfoModalComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
    };
    NloInfoModalComponent.prototype.markAsNLO = function (markAsNLO) {
        // send event to mark as NLO or decline NLO
        // if successful reload deal sheet
        this.onClosed.emit({ markasNLO: markAsNLO });
        this._bsModalRef.hide();
    };
    return NloInfoModalComponent;
}());
exports.NloInfoModalComponent = NloInfoModalComponent;
