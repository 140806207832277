import { Component, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component-ng.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

    public closeButtonVisible = true;
    public closeButtonText = 'cancel';
    public submitButtonVisible = true;
    public submitButtonDisabled = false;
    public submitButtonText = 'yes';
    public headerTextClass = 'modal-header-text';
    public title: string;
    public message: string;

    private submitSubject = new Subject<any>();
    public onSubmit = this.submitSubject.asObservable();

    constructor(private bsModalRef: BsModalRef) {
    }

    ngOnInit() {
    }

    submit() {
        this.submitSubject.next();
        this.bsModalRef.hide();
    }

    close() {
        this.bsModalRef.hide();
    }

}
