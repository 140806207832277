import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { TagTypes } from '../../../constants/tag-types';
import { OpportunityService } from '../../../ajs-upgraded-providers';
import { StateService } from '@uirouter/core';
import { Opportunity2Service } from '../../../services/opportunity2.service';
import { Settings } from "../../../constants";
import { TagDto } from '../../../generated/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tagged-customers',
    templateUrl: './tagged-customers.component-ng.html',
    styleUrls: ['./tagged-customers.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class TaggedCustomersComponent implements OnInit, OnChanges {

    @Input() selectedDealerId: number;

    widgetDataPromise: object;
    tagData: TagDto[] = [];
    tagDataType1: TagDto[] = [];
    tagDataType2: TagDto[] = [];

    tagTypes = TagTypes;

    constructor(
        @Inject(OpportunityService) private opportunityService,
        private opportunity2Service: Opportunity2Service,
        private stateService: StateService,
        private tsService: TranslateService) {
    }

    ngOnInit() {
        this.getTagsPivotForDealers(false);

        this.opportunityService = Settings.environmentVariables.opportunityServiceAng8Enabled ? this.opportunity2Service : this.opportunityService;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedDealerId && !changes.selectedDealerId.isFirstChange()) {
            this.getTagsPivotForDealers(false);
        }
    }

    goToOppSearch(tagId: number): void {
        this.stateService.go('search.form', { 'tagId': tagId, 'dealerId': this.selectedDealerId });
    }

    getTagsPivotForDealers(flushCache: boolean): void {
        this.widgetDataPromise = this.opportunityService.getTagsPivotForDealers(this.selectedDealerId, flushCache).then(data => {
            this.tagData = data;
            this.tagDataType1 = [];
            this.tagDataType2 = [];

            for (var i = 0; i < this.tagData.length; i++) {
                var tag: TagDto = this.tagData[i];

                var translateKey: string = "tagText" + tag.tagID;
                var translatedText: string = this.tsService.instant(translateKey);

                if (translatedText != translateKey)
                    tag.tagText = translatedText;

                if (tag.tagTypeId == this.tagTypes.dealership) {
                    this.tagDataType1.push(tag);
                }
                else {
                    this.tagDataType2.push(tag);
                }
            }
        });
    }

    refresh(clearCache: boolean): void {
        if (clearCache) {
            this.getTagsPivotForDealers(true);
        }
    }
}
