"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var TextMessageClientService = /** @class */ (function (_super) {
    __extends(TextMessageClientService, _super);
    function TextMessageClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param textMessage undefined
     * @return Success
     */
    TextMessageClientService.prototype.SendPOSTResponse = function (textMessage) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = textMessage;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/TextMessage/Send", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param textMessage undefined
     * @return Success
     */
    TextMessageClientService.prototype.SendPOST = function (textMessage) {
        return this.SendPOSTResponse(textMessage).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    TextMessageClientService.prototype.SendWithUpgradeProposalPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/TextMessage/SendWithUpgradeProposal", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    TextMessageClientService.prototype.SendWithUpgradeProposalPOST = function (request) {
        return this.SendWithUpgradeProposalPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    TextMessageClientService.prototype.GetHistoryForEntityByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/TextMessage/GetHistoryForEntity/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    TextMessageClientService.prototype.GetHistoryForEntityByEntityidGET = function (entityId) {
        return this.GetHistoryForEntityByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param customerId undefined
     * @return Success
     */
    TextMessageClientService.prototype.GetMessagesByCustomerIdByCustomeridGETResponse = function (customerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/TextMessage/GetMessagesByCustomerId/" + customerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param customerId undefined
     * @return Success
     */
    TextMessageClientService.prototype.GetMessagesByCustomerIdByCustomeridGET = function (customerId) {
        return this.GetMessagesByCustomerIdByCustomeridGETResponse(customerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETParams` containing the following parameters:
     *
     * - `messageId`:
     *
     * - `customerId`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.GetMessagesByCustomerIdByCustomeridByMessageidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/TextMessage/GetNewMessagesByCustomerId/" + params.customerId + "/" + params.messageId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETParams` containing the following parameters:
     *
     * - `messageId`:
     *
     * - `customerId`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.GetMessagesByCustomerIdByCustomeridByMessageidGET = function (params) {
        return this.GetMessagesByCustomerIdByCustomeridByMessageidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TextMessageClientService.GetTextableStatusGETParams` containing the following parameters:
     *
     * - `ZipCode`:
     *
     * - `WorkPhone_DoNotCallStatusTypeId`:
     *
     * - `WorkPhone`:
     *
     * - `UpdateDate`:
     *
     * - `StateID`:
     *
     * - `State`:
     *
     * - `PricingPlanID`:
     *
     * - `PreferredCultureName`:
     *
     * - `NormalizedZipCode`:
     *
     * - `MiddleName`:
     *
     * - `LastName`:
     *
     * - `IsFullCustomer`:
     *
     * - `IsAddressNotValid`:
     *
     * - `InvalidContacts`:
     *
     * - `InsertDate`:
     *
     * - `HomePhone_DoNotCallStatusTypeId`:
     *
     * - `HomePhone`:
     *
     * - `FullName`:
     *
     * - `FirstName`:
     *
     * - `ExternalImportID`:
     *
     * - `Email`:
     *
     * - `DoNotText`:
     *
     * - `DoNotMail`:
     *
     * - `DoNotEmail`:
     *
     * - `DoNotCall`:
     *
     * - `DealerID`:
     *
     * - `CustomerID`:
     *
     * - `CreatedByUserID`:
     *
     * - `County`:
     *
     * - `CompanyName`:
     *
     * - `City`:
     *
     * - `CellPhone_DoNotCallStatusTypeId`:
     *
     * - `CellPhone`:
     *
     * - `Address2`:
     *
     * - `Address`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.GetTextableStatusGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.ZipCode != null)
            __params = __params.set('ZipCode', params.ZipCode.toString());
        if (params.WorkPhoneDoNotCallStatusTypeId != null)
            __params = __params.set('WorkPhone_DoNotCallStatusTypeId', params.WorkPhoneDoNotCallStatusTypeId.toString());
        if (params.WorkPhone != null)
            __params = __params.set('WorkPhone', params.WorkPhone.toString());
        if (params.UpdateDate != null)
            __params = __params.set('UpdateDate', params.UpdateDate.toString());
        if (params.StateID != null)
            __params = __params.set('StateID', params.StateID.toString());
        if (params.State != null)
            __params = __params.set('State', params.State.toString());
        if (params.PricingPlanID != null)
            __params = __params.set('PricingPlanID', params.PricingPlanID.toString());
        if (params.PreferredCultureName != null)
            __params = __params.set('PreferredCultureName', params.PreferredCultureName.toString());
        if (params.NormalizedZipCode != null)
            __params = __params.set('NormalizedZipCode', params.NormalizedZipCode.toString());
        if (params.MiddleName != null)
            __params = __params.set('MiddleName', params.MiddleName.toString());
        if (params.LastName != null)
            __params = __params.set('LastName', params.LastName.toString());
        if (params.IsFullCustomer != null)
            __params = __params.set('IsFullCustomer', params.IsFullCustomer.toString());
        if (params.IsAddressNotValid != null)
            __params = __params.set('IsAddressNotValid', params.IsAddressNotValid.toString());
        if (params.InvalidContacts != null)
            __params = __params.set('InvalidContacts', params.InvalidContacts.toString());
        if (params.InsertDate != null)
            __params = __params.set('InsertDate', params.InsertDate.toString());
        if (params.HomePhoneDoNotCallStatusTypeId != null)
            __params = __params.set('HomePhone_DoNotCallStatusTypeId', params.HomePhoneDoNotCallStatusTypeId.toString());
        if (params.HomePhone != null)
            __params = __params.set('HomePhone', params.HomePhone.toString());
        if (params.FullName != null)
            __params = __params.set('FullName', params.FullName.toString());
        if (params.FirstName != null)
            __params = __params.set('FirstName', params.FirstName.toString());
        if (params.ExternalImportID != null)
            __params = __params.set('ExternalImportID', params.ExternalImportID.toString());
        if (params.Email != null)
            __params = __params.set('Email', params.Email.toString());
        if (params.DoNotText != null)
            __params = __params.set('DoNotText', params.DoNotText.toString());
        if (params.DoNotMail != null)
            __params = __params.set('DoNotMail', params.DoNotMail.toString());
        if (params.DoNotEmail != null)
            __params = __params.set('DoNotEmail', params.DoNotEmail.toString());
        if (params.DoNotCall != null)
            __params = __params.set('DoNotCall', params.DoNotCall.toString());
        if (params.DealerID != null)
            __params = __params.set('DealerID', params.DealerID.toString());
        if (params.CustomerID != null)
            __params = __params.set('CustomerID', params.CustomerID.toString());
        if (params.CreatedByUserID != null)
            __params = __params.set('CreatedByUserID', params.CreatedByUserID.toString());
        if (params.County != null)
            __params = __params.set('County', params.County.toString());
        if (params.CompanyName != null)
            __params = __params.set('CompanyName', params.CompanyName.toString());
        if (params.City != null)
            __params = __params.set('City', params.City.toString());
        if (params.CellPhoneDoNotCallStatusTypeId != null)
            __params = __params.set('CellPhone_DoNotCallStatusTypeId', params.CellPhoneDoNotCallStatusTypeId.toString());
        if (params.CellPhone != null)
            __params = __params.set('CellPhone', params.CellPhone.toString());
        if (params.Address2 != null)
            __params = __params.set('Address2', params.Address2.toString());
        if (params.Address != null)
            __params = __params.set('Address', params.Address.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/TextMessage/GetTextableStatus", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TextMessageClientService.GetTextableStatusGETParams` containing the following parameters:
     *
     * - `ZipCode`:
     *
     * - `WorkPhone_DoNotCallStatusTypeId`:
     *
     * - `WorkPhone`:
     *
     * - `UpdateDate`:
     *
     * - `StateID`:
     *
     * - `State`:
     *
     * - `PricingPlanID`:
     *
     * - `PreferredCultureName`:
     *
     * - `NormalizedZipCode`:
     *
     * - `MiddleName`:
     *
     * - `LastName`:
     *
     * - `IsFullCustomer`:
     *
     * - `IsAddressNotValid`:
     *
     * - `InvalidContacts`:
     *
     * - `InsertDate`:
     *
     * - `HomePhone_DoNotCallStatusTypeId`:
     *
     * - `HomePhone`:
     *
     * - `FullName`:
     *
     * - `FirstName`:
     *
     * - `ExternalImportID`:
     *
     * - `Email`:
     *
     * - `DoNotText`:
     *
     * - `DoNotMail`:
     *
     * - `DoNotEmail`:
     *
     * - `DoNotCall`:
     *
     * - `DealerID`:
     *
     * - `CustomerID`:
     *
     * - `CreatedByUserID`:
     *
     * - `County`:
     *
     * - `CompanyName`:
     *
     * - `City`:
     *
     * - `CellPhone_DoNotCallStatusTypeId`:
     *
     * - `CellPhone`:
     *
     * - `Address2`:
     *
     * - `Address`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.GetTextableStatusGET = function (params) {
        return this.GetTextableStatusGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    TextMessageClientService.prototype.IsUserNumberTextableGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/TextMessage/IsUserNumberTextable", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @return Success
     */
    TextMessageClientService.prototype.IsUserNumberTextableGET = function () {
        return this.IsUserNumberTextableGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TextMessageClientService.SearchForNewNumberGETParams` containing the following parameters:
     *
     * - `stateCode`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.SearchForNewNumberGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.stateCode != null)
            __params = __params.set('stateCode', params.stateCode.toString());
        if (params.dealerId != null)
            __params = __params.set('dealerId', params.dealerId.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/TextMessage/SearchForNewNumber", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TextMessageClientService.SearchForNewNumberGETParams` containing the following parameters:
     *
     * - `stateCode`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.SearchForNewNumberGET = function (params) {
        return this.SearchForNewNumberGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TextMessageClientService.SearchForNewNumberByDealeridGETParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `stateCode`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.SearchForNewNumberByDealeridGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.stateCode != null)
            __params = __params.set('stateCode', params.stateCode.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/TextMessage/SearchForNewNumber/" + params.dealerId + "/" + params.stateCode), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TextMessageClientService.SearchForNewNumberByDealeridGETParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `stateCode`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.SearchForNewNumberByDealeridGET = function (params) {
        return this.SearchForNewNumberByDealeridGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TextMessageClientService.SearchForNewNumberByAreaCodeGETParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `areaCode`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.SearchForNewNumberByAreaCodeGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.dealerId != null)
            __params = __params.set('dealerId', params.dealerId.toString());
        if (params.areaCode != null)
            __params = __params.set('areaCode', params.areaCode.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/TextMessage/SearchForNewNumberByAreaCode", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TextMessageClientService.SearchForNewNumberByAreaCodeGETParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `areaCode`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.SearchForNewNumberByAreaCodeGET = function (params) {
        return this.SearchForNewNumberByAreaCodeGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TextMessageClientService.PurchaseNewNumberPOSTParams` containing the following parameters:
     *
     * - `newNumberToPurchase`:
     *
     * - `dealerId`:
     *
     * - `accessRightId`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.PurchaseNewNumberPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.newNumberToPurchase != null)
            __params = __params.set('newNumberToPurchase', params.newNumberToPurchase.toString());
        if (params.dealerId != null)
            __params = __params.set('dealerId', params.dealerId.toString());
        if (params.accessRightId != null)
            __params = __params.set('accessRightId', params.accessRightId.toString());
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/TextMessage/PurchaseNewNumber", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param params The `TextMessageClientService.PurchaseNewNumberPOSTParams` containing the following parameters:
     *
     * - `newNumberToPurchase`:
     *
     * - `dealerId`:
     *
     * - `accessRightId`:
     *
     * @return Success
     */
    TextMessageClientService.prototype.PurchaseNewNumberPOST = function (params) {
        return this.PurchaseNewNumberPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    TextMessageClientService.SendPOSTPath = '/api/TextMessage/Send';
    TextMessageClientService.SendWithUpgradeProposalPOSTPath = '/api/TextMessage/SendWithUpgradeProposal';
    TextMessageClientService.GetHistoryForEntityByEntityidGETPath = '/api/TextMessage/GetHistoryForEntity/{entityId}';
    TextMessageClientService.GetMessagesByCustomerIdByCustomeridGETPath = '/api/TextMessage/GetMessagesByCustomerId/{customerId}';
    TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETPath = '/api/TextMessage/GetNewMessagesByCustomerId/{customerId}/{messageId}';
    TextMessageClientService.GetTextableStatusGETPath = '/api/TextMessage/GetTextableStatus';
    TextMessageClientService.IsUserNumberTextableGETPath = '/api/TextMessage/IsUserNumberTextable';
    TextMessageClientService.SearchForNewNumberGETPath = '/api/TextMessage/SearchForNewNumber';
    TextMessageClientService.SearchForNewNumberByDealeridGETPath = '/api/TextMessage/SearchForNewNumber/{dealerId}/{stateCode}';
    TextMessageClientService.SearchForNewNumberByAreaCodeGETPath = '/api/TextMessage/SearchForNewNumberByAreaCode';
    TextMessageClientService.PurchaseNewNumberPOSTPath = '/api/TextMessage/PurchaseNewNumber';
    TextMessageClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TextMessageClientService_Factory() { return new TextMessageClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: TextMessageClientService, providedIn: "root" });
    return TextMessageClientService;
}(base_service_1.BaseService));
exports.TextMessageClientService = TextMessageClientService;
