"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MobileCustomerPhoneComponent = /** @class */ (function () {
    function MobileCustomerPhoneComponent() {
    }
    MobileCustomerPhoneComponent.prototype.ngOnInit = function () {
    };
    return MobileCustomerPhoneComponent;
}());
exports.MobileCustomerPhoneComponent = MobileCustomerPhoneComponent;
