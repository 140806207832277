import angular from 'angular';

angular.module('AaApp.Opp').constant('alertScriptTypes', {
    alert: 1,
    alertCrossMakeUsed: 2,
    contractEnd: 3,
    leaseEnd: 4,
    mileage: 5,
    inMarket: 6,
    inMarketEngaged: 7,
    warranty: 8,
    phone: 9,
    conquest: 10,
    cash: 11,
    appointment: 12,
    service: 13,
    privateOffer: 14,
    privateOfferWithPreApproval: 15,
});
