
/* injects from baggage-loader */
require('./ui-dropdownlist.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('uiDropdownList', {
    templateUrl: '/app/components/search/ui-dropdownlist.component.html',
    controller: UIDropdownList,
    bindings: {
        fieldName: "<",
        displayNameKey: '<',
        displayNameCssClass: '<',
        values: '<',
        availableGroupNames: '<',
        value: '=',
        multiSelect: '<',
        includeDefaultOption: '<',
        isDisabled: "<",
        triggerInit: "=",
        onChanged: "&"
    }   
});

/* @ngInject */
function UIDropdownList($scope, $translate) {

    var $ctrl = this;

    $ctrl.multiSelect = ($ctrl.multiSelect) ? $ctrl.multiSelect : false;
    $ctrl.$onInit = onInit;

    $ctrl.onSelectionChanged = onSelectionChanged;

    $ctrl.settingsDropdown = {
        dropdownDisable: true,
        template: '{{option.nameText}}',
        smartButtonTextConverter(itemText, option) { return option.nameText; }, 
        checkBoxes: true, 
        smartButtonMaxItems: 5, 
        showCheckAll: true, 
        showUncheckAll: true, 
        scrollable: true,
        idProperty: 'value'
    };

    $scope.$watch('$ctrl.triggerInit', function(newVal, oldVal, scope) {
        if ($ctrl.triggerInit) {
            $ctrl.triggerInit = false;
            onInit();
        }
    });

    $scope.$watch('$ctrl.values', function (newVal, oldVal, scope) {
        if ($ctrl.values) {
            for (const val of $ctrl.values) {
                if (val.nameKey) {
                    val.nameText = $translate.instant(val.nameKey);
                }
            }
        }
    });

    function onInit() {

        if (!$ctrl.displayNameCssClass)
            $ctrl.displayNameCssClass = "searchDisplayNameWide";

        if (!$ctrl.multiSelect && $ctrl.values.length > 0) {
            $ctrl.value = $ctrl.values[0].value;

            for (var ssav in $ctrl.values) {
                if ($ctrl.values[ssav].selected) {
                    $ctrl.value = $ctrl.values[ssav].value;
                }
            }
        }

        if ($ctrl.multiSelect) {
            $ctrl.value = [];

            for (var msav in $ctrl.values) {
                if ($ctrl.values[msav].selected) {
                    $ctrl.value.push($ctrl.values[msav]);
                }
            }
        }
    }

    function onSelectionChanged() {
        $ctrl.onChanged({selectedValues: $ctrl.value });
    }
}
