
/* injects from baggage-loader */
require('./view-email-conversation-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('viewEmailConversationModal', {
    templateUrl: '/app/components/deal-sheet/modals/view-email-conversation-modal.component.html',
    controller: viewEmailConversationModalController,
    bindings: {
        close: '&',
        resolve: '<',
        dismiss: '&'
    }
});

/* @ngInject */
function viewEmailConversationModalController($timeout) {
    var $ctrl = this;
}


