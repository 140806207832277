FinancialEditActionController.$inject = ["conquestTypes", "saleTypes", "financialsService", "$logFactory"];

/* injects from baggage-loader */
require('./financial-edit-action.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('financialEditAction', {
  templateUrl: '/app/components/deal-sheet/financial-edit-action.component.html',
  controller: FinancialEditActionController,
  bindings: {
    contract: '<',
    opportunityId: '<',
    dealerId: '<',
    permissions: '<',
    assignedUserFullName: '<',
    onEdit: '&',
    opportunityType: '<'
  }
});
/* @ngInject */

function FinancialEditActionController(conquestTypes, saleTypes, financialsService, $logFactory) {
  var $ctrl = this;
  var log = $logFactory.get();
  $ctrl.financials;
  $ctrl.conquestTypes = conquestTypes;
  $ctrl.saleTypes = saleTypes;
  $ctrl.getFinancialData = getFinancialData;
  $ctrl.submitFinancialData = submitFinancialData;

  function getFinancialData() {
    return financialsService.getFinancialsData($ctrl.opportunityId, $ctrl.dealerId).then(function (data) {
      if (data) {
        $ctrl.financials = {
          convert: data.convert,
          banks: data.banks,
          saleOptions: data.saleOptions,
          users: data.users,
          assignedUserFullName: $ctrl.assignedUserFullName,
          contract: $ctrl.contract,
          opportunityType: $ctrl.opportunityType
        };
        return true;
      } else {
        return false;
      }
    });
  }

  function submitFinancialData(convert) {
    financialsService.submitConvertInfo(convert).then(function (data) {
      $ctrl.onEdit();
    }).catch(function () {
      log.log('submit financials error caught');
    });
  }
}