"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var MaxDirective = /** @class */ (function () {
    function MaxDirective() {
    }
    MaxDirective.prototype.validate = function (control) {
        return forms_1.Validators.max(this.max)(control);
    };
    return MaxDirective;
}());
exports.MaxDirective = MaxDirective;
