"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dealer_settings_service_1 = require("../../services/dealer-settings.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_1 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var admin_settings_modal_sendtestlead_component_1 = require("./admin-settings-modal-sendtestlead.component");
var storage_service_1 = require("../../modules/storage/storage.service");
var AdminSettingsCrmLeadsDeliveryComponent = /** @class */ (function () {
    function AdminSettingsCrmLeadsDeliveryComponent(modalService, tService, logger, dsService, storageService) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.dsService = dsService;
        this.storageService = storageService;
        this.dsa = {};
        this.isEmployee = false;
        var userProfile = storageService.getItem('userProfile');
        if (userProfile) {
            this.isEmployee = userProfile.employeeUser;
        }
    }
    AdminSettingsCrmLeadsDeliveryComponent.prototype.ngOnInit = function () {
    };
    AdminSettingsCrmLeadsDeliveryComponent.prototype.sendTestLead = function () {
        var _this = this;
        this.bsModalRef = this.modalService.show(admin_settings_modal_sendtestlead_component_1.AdminSettingsModalSendTestLeadComponent);
        this.bsModalRef.content.sendTestLead.subscribe(function (result) {
            _this.dsService.SendTestAdfEmail({ dealerId: _this.dsa.dealerSetting.dealerID, email: result.email, source: result.source });
            _this.logger.success(_this.tService.instant("testLeadSent"));
        });
    };
    AdminSettingsCrmLeadsDeliveryComponent.prototype.onDeliverAllOpportunitiesChanged = function () {
        this.dsa.dealerAdfSetting.deliverOpportunityCreditConvert = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityUpgrade = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityContractEnd = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityMileage = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityWarranty = this.dsa.dealerAdfSetting.deliverAllOpportunities;
    };
    return AdminSettingsCrmLeadsDeliveryComponent;
}());
exports.AdminSettingsCrmLeadsDeliveryComponent = AdminSettingsCrmLeadsDeliveryComponent;
