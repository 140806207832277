export const ActivityResultDescriptors = [
    // logcall setid: 1 ---------------------------------------------------
    {
        'activityResultID': 27,
        'translateKey': 'ResultKeyInterested',
        'priority': 1,
        'setID': 1,
        'column': 1
    },
    {
        'activityResultID': 6,
        'translateKey': 'ResultKeyNotInterested',
        'priority': 2,
        'setID': 1,
        'column': 1
    },
    {
        'activityResultID': 28,
        'translateKey': 'ResultKeyNoLongerOwns',
        'priority': 3,
        'setID': 1,
        'column': 1
    },
    {
        'activityResultID': 7,
        'translateKey': 'ResultKeySold',
        'priority': 4,
        'setID': 1,
        'column': 1
    },
    {
        'activityResultID': 4,
        'translateKey': 'ResultKeyLeftMessage',
        'priority': 1,
        'setID': 1,
        'column': 2
    },
    {
        'activityResultID': 2,
        'translateKey': 'ResultKeyCallBack',
        'priority': 2,
        'setID': 1,
        'column': 2
    },
    {
        'activityResultID': 5,
        'translateKey': 'ResultKeyNoAnswer',
        'priority': 3,
        'setID': 1,
        'column': 2
    },
    {
        'activityResultID': 32,
        'translateKey': 'ResultKeyMiscellaneous',
        'priority': 4,
        'setID': 1,
        'column': 2
    },
    // old portal dialogs still use these
    {
        'activityResultID': 1,
        'translateKey': 'ResultKeyAppointmentSet',
        'priority': 1,
        'setID': 1,
        'column': 0 // for old dialog at activity screen
    },
    // log notes setid: 3 ---------------------------------------------------
    {
        'activityResultID': 22,
        'translateKey': 'ResultKeyUpgradeProposal',
        'priority': 1,
        'setID': 3,
        'column': 1
    },
    {
        'activityResultID': 29,
        'translateKey': 'ResultKeyOneToOnePrintedOffer',
        'priority': 2,
        'setID': 3,
        'column': 1
    },
    {
        'activityResultID': 30,
        'translateKey': 'ResultKeyTestDriveDone',
        'priority': 3,
        'setID': 3,
        'column': 1
    },
    {
        'activityResultID': 23,
        'translateKey': 'ResultKeySold',
        'priority': 4,
        'setID': 3,
        'column': 1
    },
    {
        'activityResultID': 6,
        'translateKey': 'ResultKeyNotInterested',
        'priority': 5,
        'setID': 3,
        'column': 2
    },
    {
        'activityResultID': 31,
        'translateKey': 'ResultKeyNoLongerOwns',
        'priority': 6,
        'setID': 3,
        'column': 2
    },
    {
        'activityResultID': 33,
        'translateKey': 'vehiclePaidOff',
        'priority': 6,
        'setID': 3,
        'column': 2
    },
    {
        'activityResultID': 25,
        'translateKey': 'ResultKeyMiscellaneous',
        'priority': 7,
        'setID': 3,
        'column': 2
    },
    {// ---------------------------------------------------
        'activityResultID': 11,
        'translateKey': 'ResultKeySold',
        'priority': 1,
        'setID': 2,
        'column': 1
    },
    {
        'activityResultID': 10,
        'translateKey': 'ResultKeyShowedOpen',
        'priority': 2,
        'setID': 2,
        'column': 1
    },
    {
        'activityResultID': 9,
        'translateKey': 'ResultKeyShowedClosed',
        'priority': 3,
        'setID': 2,
        'column': 1
    },
    {
        'activityResultID': 8,
        'translateKey': 'ResultKeyDidNotShow',
        'priority': 4,
        'setID': 2,
        'column': 1
    },
    {
        'activityResultID': 33,
        'translateKey': 'vehiclePaidOff',
        'priority': 13,
        'setID': 1,
        'column': 1
    }
];
