"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ReplacementVehicleOffersComponent = /** @class */ (function () {
    function ReplacementVehicleOffersComponent() {
        this.label = 'replacementVehicle';
    }
    ReplacementVehicleOffersComponent.prototype.ngOnInit = function () {
    };
    ReplacementVehicleOffersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.currentVehicle && this.replacementVehicles) {
            switch (this.currentVehicle) {
                case this.replacementVehicles.standardVehicle:
                    this.label = 'replacementVehicle';
                    break;
                case this.replacementVehicles.upgradeVehicle:
                    this.label = 'upgrade';
                    break;
                case this.replacementVehicles.downgradeVehicle:
                    this.label = 'downgrade';
                    break;
                default:
                    this.label = 'alternate';
                    break;
            }
        }
    };
    return ReplacementVehicleOffersComponent;
}());
exports.ReplacementVehicleOffersComponent = ReplacementVehicleOffersComponent;
