
/* injects from baggage-loader */
require('./wish-list-matches-modal.component.html');

import angular from 'angular';
import './wish-list-matches-modal.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';
import eyeIconPath from '../../images/icon-eye-lake.svg';

angular.module('AaApp.Opp').component('wishListMatchesModal', {
    templateUrl: '/app/components/wish-list/wish-list-matches-modal.component.html',
    controller: wishListMatchesModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
        kendo: '<',
    }
});

/* @ngInject */
function wishListMatchesModalController(_, $q, $filter, $translate, cultures, userService, dealerService, dealSheetService) {

    var $ctrl = this;

    $ctrl.$onInit = init;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.ds;
    $ctrl.gridOptions;
    $ctrl.cultureName;
    $ctrl.loadWishListMatches = loadWishListMatches;
    $ctrl.displayDistributionList = displayDistributionList;
    $ctrl.loadDealSheet = loadDealSheet;

    const MATCH_TAKE = 50;

    //$ctrl.resolve is sent by the caller of this directive, and can be any JS object.
    //  I'm using it to enable DI and pass input parameters.
    $ctrl.selectedDealerId = $ctrl.resolve.message.selectedDealerId;
    $ctrl.dealerWishListID = $ctrl.resolve.message.dealerWishListID;
    $ctrl.translations = $ctrl.resolve.message.translations;
    $ctrl.users = $ctrl.resolve.message.users;

    $ctrl.kendo = $ctrl.resolve.message.kendo;
    $ctrl.wishListMatches;
    $ctrl.distributionList = [];
    $ctrl.renderAlerts = renderAlerts;

    var alertTypeHelper = {
        dataSource: [
            { id: "100", alerts: "Alert", code: 'alert', description: 'Alert', translateKey: 'faalertletter' },
            { id: "200", alerts: "Flex", code: 'flexalert', description: 'Flex', translateKey: 'flexAlertLetter' },
            { id: "400", alerts: "In Market", code: 'inmarket', description: 'In Market', translateKey: 'inMarketLetter' },
            { id: "500", alerts: "Engaged", code: 'inmarketengaged', description: 'Engaged', translateKey: 'inMarketEngagedLetter' },
            { id: "600", alerts: "Contract End", code: 'contractend', description: 'Contract End', translateKey: 'contractEndLetter' },
            { id: "700", alerts: "Service", code: 'service', description: 'Service', translateKey: 'serviceLetter' },
            { id: "800", alerts: "Pending Service", code: 'appointment', description: 'Pending Service', translateKey: 'serviceAppointmentLetter' },
            { id: "900", alerts: "Mileage", code: 'mileage', description: 'Mileage', translateKey: 'mileageLetter' },
            { id: "1000", alerts: "Warranty", code: 'warranty', description: 'Warranty', translateKey: 'warrantyLetter' }
        ],

        getByDescription: function (description) {
            var alert = null;
            if (typeof this.dataSource.find === "function") {
                alert = this.dataSource.find(function (item) { return item.description == description; });
                if (typeof (alert) === 'undefined') return null;
                return alert;
            }
            else {
                for (var i = 0; i < description.length; i++) {
                    if (description[i].description == description)
                        alert = description[i];
                }
            }
            return alert;
        },

        getById: function (id) {
            var alert = null;
            if (typeof this.dataSource.find === "function") {
                var alert = this.dataSource.find(function (item) { return item.id == id; });
                if (typeof (alert) === 'undefined') return null;
                return alert;
            }
            else {
                for (var i = 0; i < description.length; i++) {
                    if (description[i].id == id)
                        alert = description[i];
                }
            }
            return alert;
        }
    }

    function init() {
        $q.all([
            userService.getUserProfile(),
        ]).then((data) => {
            var userProfile = data[0];
            $ctrl.cultureName = userProfile.cultureName;
            setKendoCulture();
            initGridOptions();
            loadWishListMatches($ctrl.selectedDealerId, $ctrl.dealerWishListID, MATCH_TAKE);
        });
    }

    function loadDealSheet(entityId) {
        dealSheetService.openDealSheetModal(entityId);
    }

    function renderCustomerLink(e) {
        if (e.entityID == null)
            return e.customerFullName;
        var dsLink = "<a class='deal-sheet-link' ng-click='$ctrl.loadDealSheet(\"" + e.entityID + "\")' style=\"cursor: pointer; height: 25px;\">" + e.customerFullName + "</a>";
        return dsLink;
    }

    function renderAlerts(e) {
        if (e.alerts == null || e.alerts.length == 0)
            return "";

        var alerts = e.alerts;
        var alertCodes = new Array();
        var alertLetterTranslations = new Array();
        for (var i = 0; i < alerts.length; i++) {
            var alert = alertTypeHelper.getById(alerts[i]);
            if (alert !== null) { 
                alertCodes.push(alert.code);
                alertLetterTranslations.push($ctrl.translations[alert.translateKey]);
            }
        }

        var data = {
            alertCodes: alertCodes,
            alertLetterTranslations: alertLetterTranslations
        }

        return renderTemplate("#alerts-template", data);
    }

    function renderTemplate(templateId, data) {
        var templateContent = $(templateId).html();
        var template = $ctrl.kendo.template(templateContent);
        var html = template(data);
        return html;
    }

    function busy(translationKey, promise) {  //global
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function loadWishListMatches(dealerId, dealerWishListID, take) {
        return busy(
            $ctrl.translations.loadingWishlistMatches,
            dealerService.GetDealerWishListLogs(dealerId, dealerWishListID, take)
                .then((data) => {
                    $ctrl.wishListMatches = data;

                    $ctrl.ds = new $ctrl.kendo.data.DataSource({
                        data: $ctrl.wishListMatches,
                        schema: {
                            model: {
                                id: 'dealerWishlistLogID',
                                fields: {
                                    dealerWishlistLogID: { type: "number", editable: false },
                                    dealerWishlistID: { type: "number", editable: false },
                                    dealerID: { type: "number", editable: false },
                                    serviceID: { type: "number", editable: false },
                                    entityID: { type: "string", editable: false },
                                    alerts: { type: "array", editable: false },
                                    opportunityRank: { type: "number", editable: false },
                                    resultDateUtc: { type: "date", editable: false },
                                    distributionSent: { type: "array", editable: false },
                                    notes: { type: "string", editable: false },
                                    customerFullName: { type: "string", editable: false },
                                    vin: { type: "string", editable: false },
                                    yearID: { type: "number", editable: false },
                                    odometer: { type: "number", editable: false },
                                    trimName: { type: "string", editable: false },
                                    trimFullName: { type: "string", editable: false },
                                    rO_ID: { type: "string", editable: false },
                                    distributionSent: { type: "array", editable: false },
                                }
                            }
                        },
                        batch: true,
                        pageSize: 20
                    });
                })
        );
    }

    function notifyTemplate(e) {
        var eyeball = "<div align='center' ng-click='$ctrl.displayDistributionList(" + e.dealerWishlistLogID + ")' style=\"cursor: pointer; width: 25px;display:inline;\" title=\"{{$ctrl.translations.wishlistFullListTooltip}}\" uib-popover-template=\"'/app/components/wish-list/wish-list-distr-list.component.html'\" popover-append-to-body='true' popover-placement=\"auto left-center\" popover-trigger=\"'outsideClick'\" popover-popup-close-delay=\"1\" popover-class=\"wishlist-popover\"><img class='svg-icon xsm' src='" + eyeIconPath + "' /></div>";
        return eyeball;
    }

    function displayDistributionList(dealerWishListLogID) {
        var row = $ctrl.ds.get(dealerWishListLogID);
        $ctrl.distributionList = [];

        if (row.distributionSent == null || row.distributionSent.length == 0)
            return;

        $ctrl.distributionList = row.distributionSent.map(d => ({
            userID: d,
            fullName: "",
            emailAddress: ""
        }));

        for (let user of $ctrl.users) {
            for (let distUser of $ctrl.distributionList) {
                if (user.userID === distUser.userID) {
                    distUser.fullName = user.fullName;
                    distUser.emailAddress = user.email;
                }
            }
        }

        $ctrl.distributionList.sort(byFullName);
    }

    function odometerTemplate(e) {
        var odometer = e.odometer ? e.odometer : '';
        if (odometer) {
            var localeNumberFilter = $filter('localeNumber');
            var localeOdometer = localeNumberFilter(odometer);
            return "<span>" + localeOdometer + "</span>";
        }
        else {
            return "";
        }
    }

    function initGridOptions() {
        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            pageable: true,
            height: 400,
            columns: [
                {
                    field: "opportunityRank",
                    title: $ctrl.translations.score,
                    template: '<span class="bold">{{dataItem.opportunityRank}}</span>',
                    width: "5%",
                    sortable: true
                },
                {
                    field: "customerFullName",
                    title: $ctrl.translations.customerLabel,
                    template: renderCustomerLink,
                    width: "15%",
                    sortable: true,
                },
                {
                    field: "alerts",
                    title: $ctrl.translations.alertsLabel,
                    template: renderAlerts,
                    width: "13%",
                    sortable: false,
                },
                {
                    field: "vin",
                    title: $ctrl.translations.vinLabel,
                    width: "13%",
                    sortable: true,
                },
                {
                    field: "odometer",
                    title: $ctrl.translations.odometer,
                    template: odometerTemplate,
                    width: "9%",
                    sortable: true,
                },
                {
                    field: "trimFullName",
                    title: $ctrl.translations.trim,
                    template: '<span>{{dataItem.yearID}}, {{dataItem.trimFullName}}</span>',
                    width: "14%",
                    sortable: true,
                },
                {
                    field: "rO_ID",
                    title: $ctrl.translations.repairOrderNumberColumnLabel,
                    width: "11%",
                    sortable: true,
                },
                {
                    field: "resultDateUtc",
                    title: $ctrl.translations.dateTime,
                    format: "{0:MM-dd-yyyy hh:mm tt}",
                    width: "13%",
                    sortable: true,
                },
                {
                    field: "distributionSent",
                    title: $ctrl.translations.sentTo,
                    template: notifyTemplate,
                    width: "6%",
                    sortable: false,
                },
            ],
            editable: false,
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: true,
            reorderable: true,
            filterable: false
        }

    }

    function byFullName(object1, object2) {
        var fullName1 = object1.fullName.toLowerCase();
        var fullName2 = object2.fullName.toLowerCase();
        if (fullName1 < fullName2)
            return -1;
        if (fullName1 > fullName2)
            return 1;
        return 0;
    }

    function setKendoCulture() {
        switch ($ctrl.cultureName) {
            case cultures.enUS:
            case cultures.enGB:
            case cultures.enCA:
            case cultures.frCA:
            case cultures.frFR:
            case cultures.esUS:
                $ctrl.kendo.culture($ctrl.cultureName);
                break;
            default:
                $ctrl.kendo.culture(cultures.enUS);
                break;
        }
    }

    if ($ctrl.resolve.closeButtonVisible === undefined)
        $ctrl.resolve.closeButtonVisible = true;

    if ($ctrl.resolve.closeButtonText === undefined)
        $ctrl.resolve.closeButtonText = "cancel";

    if ($ctrl.resolve.submitButtonVisible === undefined)
        $ctrl.resolve.submitButtonVisible = true;

    if ($ctrl.resolve.submitButtonDisabled === undefined)
        $ctrl.resolve.submitButtonDisabled = false;

    if ($ctrl.resolve.submitButtonText === undefined)
        $ctrl.resolve.submitButtonText = "yes";

}

