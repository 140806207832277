import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Pipe, PipeTransform } from '@angular/core';

require('./admin.scss');

@Component({
    selector: 'admin-roles-selector',
    templateUrl: './admin-roles-selector.component-ng.html',
    styleUrls: ['./admin-roles-selector.component.scss']
})

export class AdminRolesSelectorComponent {

    @Output() onSaveChanges = new EventEmitter();
    @Output() onCancelChanges = new EventEmitter();
    @Output() accessRightChanged = new EventEmitter<boolean>();

    @Input() accessList: any = '';
    @Input() categories: any = '';
    @Input() dealerGroupId: number;
    @Input() hideCategoryCheckbox: boolean = false;
    @Input() readOnly: boolean = false;

    public ICONS = {
        "editRights": "https://assets.autoalert.com/img/autoalert-icons/icon-permissions-edit-lake.svg ",
        "viewRights": "https://assets.autoalert.com/img/autoalert-icons/icon-permissions-view-lake.svg",
        "actions": "https://assets.autoalert.com/img/autoalert-icons/icon-check-mark-lake.svg",
        "opportunities": require("../../images/icons/alert-blocks.svg"),
        "employees": require("../../images/icons/icon-usergroup.png"),
        "alertDesk": "https://assets.autoalert.com/img/autoalert-icons/icon-alert-blocks-no-text.svg",
        "inventoryDashboard": "https://assets.autoalert.com/img/autoalert-icons/icon-pre-owned-lake.svg",
        "reports": "https://assets.autoalert.com/img/autoalert-icons/icon-AO-reports-lake.svg",
        "dataEntry": "https://assets.autoalert.com/img/autoalert-icons/icon-data-entry-lake.svg",
        "admin": "https://assets.autoalert.com/img/autoalert-icons/icon-product-system-admin-lake.svg",
        "adminRoles-miscellaneous": "https://assets.autoalert.com/img/autoalert-icons/icon-folder-lake.svg"
    }

    constructor() {

    }

    public onItemChecked(item: any) {
        if (!item.allow) {
            item.forEach(function(value: any) {
                value.allow = item.allow;
            });
        }

        this.accessRightChanged.emit(true);
    }

    public onSubItemChecked(item: any, subitem: any) {
        if (subitem.allow)
            item.allow = subitem.allow;

        this.accessRightChanged.emit(true);
    }

    public onSave() {
        this.accessRightChanged.emit(false);
        this.onSaveChanges.emit();
    }

    public onCancel() {
        this.accessRightChanged.emit(false);
        this.onCancelChanges.emit();
    }

    public filterSubItemsByCategoryId(categoryId: number, items: any): [] {
        if (items != null) {
            var result = items.slice(0).filter((i: any) => i.categoryId == categoryId);
            return result;
        }

        return [];
    }
}