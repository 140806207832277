import { Component, OnInit, Input, Inject, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Activity } from '../../../interfaces/activity.interface';
import { TranslateService } from '@ngx-translate/core';
import { LegacyService } from '../../../ajs-upgraded-providers';
import { TextMessageService } from '../../../services/text-message.service';
import { LocaleDateTimePipe } from '../../../filters/locale-date-time.pipe';
import { TextMessageDto } from '../../../generated/models';
import { Subscription } from 'rxjs';
import { AuthorizationKeys } from '../../../constants';

interface TextMessageDtoExt extends TextMessageDto {
    safeMessage: SafeHtml
}

@Component({
    selector: 'aa-text-message-activity',
    templateUrl: './text-message-activity.component-ng.html',
    styleUrls: ['./text-message-activity.component.scss']
})
export class TextMessageActivityComponent implements OnInit, OnChanges, OnDestroy {
    @Input() activity: Activity;
    @Input() mobileView: any;

    public sentBy: string;
    public displayMessages = false;
    public salesOrServiceLabel = { SalesOrService: 'Sales' };

    private authorizationKeys = AuthorizationKeys;
    private subscriptionAggregator = new Subscription();

    constructor(private domSanitizer: DomSanitizer,
        private translate: TranslateService,
        @Inject(LegacyService) private legacyService,
        public textMessageService: TextMessageService,
        private localeDatetimePipe: LocaleDateTimePipe) { }

    ngOnInit() {
        const sub = this.translate.get('customer')
            .subscribe(translatedCustomerLabel => {
                this.sentBy = this.getSentByLabel(this.activity.textMessages, translatedCustomerLabel);
            });

        this.subscriptionAggregator.add(sub);
    }

    ngOnDestroy(): void {
        this.subscriptionAggregator.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.activity && changes.activity.currentValue && changes.activity.currentValue.textMessages) {
            const textMessages = changes.activity.currentValue.textMessages as TextMessageDtoExt[];
            textMessages.map(t => t.message = t.message.replace("\r\n", "<br />"));
            textMessages.map(t => t.safeMessage = this.domSanitizer.bypassSecurityTrustHtml(TextMessageService.replaceUrlStringWithHtmlLink(t.message, false)));
            this.salesOrServiceLabel.SalesOrService = (textMessages[0].dealerPhoneAccessRightID === this.authorizationKeys.SendTextSales) ? 'Sales' : 'Service';
        }
    }

    viewUpgradeProposal(activityId: number): void {
        if (this.mobileView) return;

        this.legacyService.downloadUpgradeProposalByActivity({
            activityId: activityId
        });
    }

    handleUpgradeProposalLinkClick($event: any, textMessage: TextMessageDto) {
        if (this.mobileView) return;
        
        this.textMessageService.downloadUpgradeProposalIfAvailable($event, textMessage);
    }

    formatSentDateTime(sentDateTime: Date) {
        const localizedDate = new Date(sentDateTime);
        return localizedDate.toLocaleTimeString(this.translate.currentLang, { hour: 'numeric', minute: 'numeric' });
    }

    private getSentByLabel(textMessages: TextMessageDto[], customerLabel: string): string {

        let customerSentBy = '';
        const userSentByMap = new Map<number, string>();

        textMessages.forEach(t => {
            if (t.outgoing) {
                if (!userSentByMap.has(t.userId)) {
                    userSentByMap.set(t.userId, `${t.userFirstName} ${t.userLastName}`);
                }
            } else {
                customerSentBy = `${t.customerFirstName} ${t.customerLastName} (${customerLabel}),`;
            }
        });

        const userSentBy = Array.from(userSentByMap.values()).join(', ');

        return `${customerSentBy} ${userSentBy}`;
    }
}
