"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
var asset_types_1 = require("../constants/asset-types");
var services_1 = require("../generated/services");
var cookie_storage_service_1 = require("../modules/storage/cookie-storage.service");
var object_cache_1 = require("../utilities/object-cache");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../generated/services/dealer-setting-client.service"));
var i2 = __importStar(require("../generated/services/dealer-client.service"));
var i3 = __importStar(require("../generated/services/dealer-search-client.service"));
var i4 = __importStar(require("../generated/services/user-client.service"));
var i5 = __importStar(require("../generated/services/print-offer-batch-client.service"));
var i6 = __importStar(require("../generated/services/dealer-asset-client.service"));
var i7 = __importStar(require("../ajs-upgraded-providers"));
var i8 = __importStar(require("../modules/storage/cross-domain-storage.service"));
var DealerService = /** @class */ (function () {
    function DealerService(dealerSettingClient, dealerClient, dealerSearchClient, userClient, printOfferBatchClient, dealerAssetClient, rootScope, crossDomainStorageService) {
        this.dealerSettingClient = dealerSettingClient;
        this.dealerClient = dealerClient;
        this.dealerSearchClient = dealerSearchClient;
        this.userClient = userClient;
        this.printOfferBatchClient = printOfferBatchClient;
        this.dealerAssetClient = dealerAssetClient;
        this.rootScope = rootScope;
        this.crossDomainStorageService = crossDomainStorageService;
        this.dealersOneToOneStatus = [];
        this.dealersOneToOneStatusLoaded = false;
        // not sure why this was public before but keeping it that way just in case
        this.getIsAssetEnabledForDealerPromise = null;
        this.enabledUsersForDealerCache = new object_cache_1.ObjectCache({ cacheAgeLimitSeconds: constants_1.Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds });
        this.dealersCache = new object_cache_1.ObjectCache({ cacheAgeLimitSeconds: constants_1.Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds });
        this.dealerCache = new object_cache_1.ObjectCache({ cacheAgeLimitSeconds: constants_1.Settings.environmentVariables.defaultInMemoryCacheTimeoutSeconds });
    }
    DealerService.prototype.getDealerSettings = function (dealerId) {
        return this.dealerSettingClient.ByDealeridGET(dealerId).toPromise()
            .then(mapDealerSettings);
        function mapDealerSettings(response) {
            var dealerSettings = {
                allowUsedCalculation: response.allowUsedCalculation,
                allowCPOCalculation: response.allowCPOCalculation,
                enableCarFax: response.enableCarFax,
                enableCarProof: response.enableCarProof,
                enableAutoCheck: response.enableAutoCheck,
                useCreditSoftPull: response.useCreditSoftPull,
                maxScheduleFutureDays: response.activityFutureWindow,
                dncEnabled: response.dncEnabled,
                allowExpiration: response.allowExpiration,
                autoRewardAccountIdentifier: response.autoRewardAccountIdentifier,
                autoRewardPassword: response.autoRewardPassword,
                autoRewardUserName: response.autoRewardUserName,
                enableAutoRewards: response.enableAutoRewards,
                productID: response.productID,
                defaultUserID: response.defaultUserID,
                enableDirectAlertCampaigns: response.enableDirectAlertCampaigns,
                enableDealerUsedRates: response.enableDealerUsedRates,
                enableMultiVehReplTolerances: response.enableMultiVehReplTolerances,
                deskitDealerID: response.deskitDealerID,
                deskitPushEnabled: response.deskitPushEnabled,
                carFaxVersion: response.carFaxVersion,
                carFaxAutoPurchase: response.carFaxAutoPurchase
            };
            return dealerSettings;
        }
    };
    DealerService.prototype.getDealer = function (id) {
        var _this = this;
        return this.dealerCache.getOrFetch(id, function () { return _this.dealerClient.ByIdGET(id).toPromise()
            .then(function resolveDealer(response) {
            var data = response;
            var dealer = {
                name: data.dealerName,
                displayText: data.dealerName + ' (' + data.dealerCode + ')'
            };
            return dealer;
        }); });
    };
    DealerService.prototype.getDealers = function () {
        var _this = this;
        return this.dealersCache.getOrFetch('dealers', function () { return _this.dealerSearchClient.GET().toPromise()
            .then(function getDealersComplete(response) {
            var dealers = response;
            var dealersExt = [];
            dealers.forEach(function (dealer) {
                var displayText = dealer.dealerName + ' (' + dealer.dealerCode + ')';
                var dealerInfoDtoExt = __assign({}, dealer, { displayText: displayText });
                dealersExt.push(dealerInfoDtoExt);
            });
            return dealersExt;
        }); });
    };
    DealerService.prototype.getUsers = function (dealerId) {
        return this.getEnabledUsersForDealer(dealerId)
            .then(function resolveUsers(response) {
            var users = [];
            for (var i = 0; i < response.length; i++) {
                var user = response[i];
                users.push({
                    id: user.userID,
                    fullName: user.fullName,
                    userTypeId: user.userTypeID
                });
            }
            return users;
        });
    };
    DealerService.prototype.getEnabledUsersForDealer = function (dealerId) {
        var _this = this;
        return this.enabledUsersForDealerCache.getOrFetch(dealerId, function () { return _this.userClient.GetEnabledUsersForDealerByDealeridGET(dealerId).toPromise(); });
    };
    DealerService.prototype.getSelectedDealerGroupId = function () {
        var data = this.getRawSelectedDealers();
        if (data) {
            return data.dealerGroupId;
        }
    };
    DealerService.prototype.getSelectedDealerIds = function () {
        var dealers = this.getSelectedDealers();
        if (dealers) {
            return dealers.map(function (d) { return d.id; });
        }
    };
    DealerService.prototype.getDealerDisplayText = function () {
        var data = this.getRawSelectedDealers();
        if (data) {
            return data.dealerDisplayText;
        }
    };
    DealerService.prototype.getSelectedDealers = function (addAllDealersOption) {
        var data = this.getRawSelectedDealers();
        if (addAllDealersOption && data.dealers.length > 1) {
            data.dealers.unshift({ id: 0, name: "All Dealerships" });
        }
        if (data) {
            return data.dealers;
        }
    };
    DealerService.prototype.getRawSelectedDealers = function () {
        return this.crossDomainStorageService.getItem('selectedDealers');
    };
    DealerService.prototype.updateSelectedDealers = function (dealers, dealerGroupId, dealerDisplayText) {
        var data = { dealers: dealers, dealerGroupId: dealerGroupId, dealerDisplayText: dealerDisplayText };
        this.crossDomainStorageService.setItem('selectedDealers', data);
    };
    DealerService.prototype.onSelectedDealersChanged = function (callback) {
        return this.crossDomainStorageService.onchange('selectedDealers', callback);
    };
    DealerService.prototype.getDealersOneToOneStatus = function () {
        var _this = this;
        return this.printOfferBatchClient.GetDealerStatusesGET().toPromise()
            .then(function (response) {
            _this.dealersOneToOneStatus = response || [];
            _this.dealersOneToOneStatusLoaded = true;
            _this.rootScope.$broadcast('RUN_USER_INIT.ONE_TO_ONE_READY');
        });
    };
    DealerService.prototype.getIsDealerNoLongerOwnsEnabled = function (dealerId) {
        return this.dealerAssetClient.GetByDealerByDealeridGET(dealerId).toPromise()
            .then(IsNoLongerOwnsActive);
        function IsNoLongerOwnsActive(response) {
            var data = response;
            var noLongerOwns = data.find(function findNoLongerOwnsAsset(a) {
                return a.assetID === asset_types_1.AssetTypes.noLongerOwns;
            });
            return typeof noLongerOwns != 'undefined' && noLongerOwns.isActive === true;
        }
    };
    DealerService.prototype.getIsAssetEnabledForDealer = function (assetId, dealerId) {
        //If this data changes, the user will need to refresh the screen.
        if (this.getIsAssetEnabledForDealerPromise == null) {
            this.getIsAssetEnabledForDealerPromise = this.dealerAssetClient.GetDealerAssetsGET().toPromise();
        }
        return this.getIsAssetEnabledForDealerPromise.then(processResponse);
        function processResponse(response) {
            var data = response;
            var dealerAsset = data.find(function findDealerAsset(a) {
                var isSelectedDealer = dealerId == null || dealerId == 0 || a.dealerID == dealerId;
                return isSelectedDealer && a.assetID === assetId && a.isActive === true;
            });
            return typeof dealerAsset != 'undefined';
        }
    };
    DealerService.prototype.getIsDealerPandoXEnabled = function (dealerId) {
        return this.dealerAssetClient.GetByDealerByDealeridGET(dealerId).toPromise()
            .then(IsPandoXActive);
        function IsPandoXActive(response) {
            var data = response;
            var pandoX = data.find(function findPandoXAsset(a) {
                return a.assetID === asset_types_1.AssetTypes.pandoX;
            });
            var result = typeof pandoX != 'undefined' && pandoX.isActive === true;
            return result;
        }
    };
    DealerService.prototype.getIsDealerOneToOneEnabled = function (dealerId) {
        var dealer = this.dealersOneToOneStatus.find(function findDealerStatus(o) {
            return o.dealerId === dealerId;
        });
        if (!dealer)
            return false;
        return dealer.printOfferEnabled;
    };
    DealerService.prototype.getIsSomeDealerOneToOneEnabled = function () {
        var isSomeDealerOneToOneEnabled = false;
        this.dealersOneToOneStatus.forEach(function forEachDealerOneToOneStatus(o) {
            if (o.printOfferEnabled) {
                isSomeDealerOneToOneEnabled = true;
                return false;
            }
        });
        return isSomeDealerOneToOneEnabled;
    };
    DealerService.prototype.getIsSomeDealerIntelligentMarketingEnabled = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promise, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promise = this.getIsAssetEnabledForDealer(asset_types_1.AssetTypes.intelMarketing);
                        return [4 /*yield*/, promise];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    DealerService.prototype.getIsSomeDealerAutoAssistantEnabled = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promise, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promise = this.getIsAssetEnabledForDealer(asset_types_1.AssetTypes.autoAssistant);
                        return [4 /*yield*/, promise];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    DealerService.prototype.getIsSomeDealerGeoAlertEnabled = function () {
        return __awaiter(this, void 0, void 0, function () {
            var promise, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promise = this.getIsAssetEnabledForDealer(asset_types_1.AssetTypes.geoAlert);
                        return [4 /*yield*/, promise];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    DealerService.prototype.getOneToOneEnabledDealerIds = function () {
        var dealerIds = [];
        this.dealersOneToOneStatus.forEach(function forEachDealerOneToOneStatus(o) {
            if (o.printOfferEnabled)
                dealerIds.push(o.dealerId);
        });
        return dealerIds;
    };
    DealerService.prototype.getDealerAlertTolerances = function (dealerId, makeId, year, modelId, trimId) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                params = {
                    dealerId: dealerId,
                    makeId: makeId,
                    year: year,
                    modelId: modelId,
                    trimId: trimId
                };
                return [2 /*return*/, this.dealerClient.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGET(params).toPromise()];
            });
        });
    };
    DealerService.prototype.UpdateDealerAlertTolerances = function (dealerId, alertTolerances) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                params = {
                    dealerId: dealerId,
                    alertTolerances: alertTolerances
                };
                return [2 /*return*/, this.dealerClient.UpdateDealerAlertTolerancesByDealeridPOST(params).toPromise()];
            });
        });
    };
    DealerService.prototype.getDealerFordInfo = function (dealerId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.dealerClient.GetDealerFordInfoGET(dealerId).toPromise()];
            });
        });
    };
    DealerService.prototype.getDealerWishLists = function (dealerId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.dealerClient.GetDealerWishListsByDealeridGET(dealerId).toPromise()];
            });
        });
    };
    DealerService.prototype.saveDealerWishList = function (dealerWishList) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.dealerClient.SaveDealerWishListPOST(dealerWishList).toPromise()];
            });
        });
    };
    DealerService.prototype.GetDealerWishListLogs = function (dealerId, dealerWishListID, take) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                params = {
                    dealerWishListID: dealerWishListID,
                    dealerId: dealerId,
                    take: take
                };
                return [2 /*return*/, this.dealerClient.GetDealerWishListLogsByDealeridByDealerwishlistidGET(params).toPromise()];
            });
        });
    };
    DealerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DealerService_Factory() { return new DealerService(i0.ɵɵinject(i1.DealerSettingClientService), i0.ɵɵinject(i2.DealerClientService), i0.ɵɵinject(i3.DealerSearchClientService), i0.ɵɵinject(i4.UserClientService), i0.ɵɵinject(i5.PrintOfferBatchClientService), i0.ɵɵinject(i6.DealerAssetClientService), i0.ɵɵinject(i7.$rootScope), i0.ɵɵinject(i8.CrossDomainStorageService)); }, token: DealerService, providedIn: "root" });
    return DealerService;
}());
exports.DealerService = DealerService;
