<div class="offer-dropdown">
    <div class="dropdown mvos-selector">
        <a class="dropdown-toggle mvos-dropdown" data-toggle="dropdown" href="#">
            <div class="row mvos1">
                <div [class]="getContainerClass()">
                    <span class="mvos-more">{{'moreVehicleOffers' | translate}}</span>
                    <div class="mvos-bottom"></div>
                </div>
            </div>
        </a>
        <ul class="dropdown-menu offercontainer">
            <li *ngIf="upgradeVehicle || downgradeVehicle || alternateVehicles">
                <a (click)="clickHandler('standardVehicle', null, standardVehicle)"
                    [attr.selected]="getSelectedAttribute('standardVehicle')">

                    <div class="row mvos">
                        <div class="row">
                            <div class="row mvos-details-container">
                                <div class="col-xs-4 mvos-image"><img [src]="standardVehicle.imagePath"
                                        (error)="updateUrl($event,standardVehicle)"></div>
                                <div class="col-xs-8 end">
                                    <div class="row">
                                        <div class="col-xs-7 no-padding">
                                            <div class="mvos-type">{{'replacementVehicle' | translate | uppercase}}
                                            </div>
                                        </div>
                                        <payment-difference [vehicle]="standardVehicle" [hasPaymentsPermission]="hasPaymentsPermission && overrideShowPayment"></payment-difference>
                                    </div>
                                    <span class="mvos-yearmake">{{standardVehicle.yearID}}</span><span
                                        class="mvos-yearmake">{{standardVehicle.trimFullName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li *ngIf="upgradeVehicle">
                <a (click)="clickHandler('upgradeVehicle', null, upgradeVehicle)"
                    [attr.selected]="getSelectedAttribute('upgradeVehicle')">

                    <div class="row mvos">
                        <div class="row">
                            <div class="row mvos-details-container">
                                <div class="col-xs-4 mvos-image"><img [src]="upgradeVehicle.imagePath"
                                        (error)="updateUrl($event,upgradeVehicle)"></div>
                                <div class="col-xs-8 end">
                                    <div class="row">
                                        <div class="col-xs-7 no-padding">
                                            <div class="mvos-type">{{'upgrade' | translate | uppercase}}</div>
                                        </div>
                                        <payment-difference [vehicle]="upgradeVehicle" [hasPaymentsPermission]="hasPaymentsPermission"></payment-difference>
                                    </div>
                                    <span class="mvos-yearmake">{{upgradeVehicle.yearID}}</span><span
                                        class="mvos-yearmake">{{upgradeVehicle.trimFullName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li *ngIf="downgradeVehicle">
                <a (click)="clickHandler('downgradeVehicle', null, downgradeVehicle)"
                    [attr.selected]="getSelectedAttribute('downgradeVehicle')">

                    <div class="row mvos">
                        <div class="row">
                            <div class="row mvos-details-container">
                                <div class="col-xs-4 mvos-image"><img [src]="downgradeVehicle.imagePath"
                                        (error)="updateUrl($event,downgradeVehicle)"></div>
                                <div class="col-xs-8 end">
                                    <div class="row">
                                        <div class="col-xs-7 no-padding">
                                            <div class="mvos-type">{{'downgrade' | translate | uppercase}}</div>
                                        </div>
                                        <payment-difference [vehicle]="downgradeVehicle" [hasPaymentsPermission]="hasPaymentsPermission"></payment-difference>
                                    </div>
                                    <span class="mvos-yearmake">{{downgradeVehicle.yearID}}</span><span
                                        class="mvos-yearmake">{{downgradeVehicle.trimFullName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
            <li *ngFor="let alternateVehicle of alternateVehicles; let idx = index;">
                <a (click)="clickHandler('alternateVehicle', idx, alternateVehicle)"
                    [attr.selected]="getSelectedAttribute('alternateVehicle', idx)">
                    <div class="row mvos">
                        <div class="row">
                            <div class="row mvos-details-container">
                                <div class="col-xs-4 mvos-image"><img [src]="alternateVehicle.imagePath"
                                        (error)="updateUrl($event,alternateVehicle)"></div>
                                <div class="col-xs-8 end">
                                    <div class="row">
                                        <div class="col-xs-7 no-padding">
                                            <div class="mvos-type">{{'alternate' | translate | uppercase}}
                                                {{(alternateVehicles.length > 1) ? idx + 1 : null}}</div>
                                        </div>
                                        <payment-difference [vehicle]="alternateVehicle" [hasPaymentsPermission]="hasPaymentsPermission"></payment-difference>
                                    </div>
                                    <span class="mvos-yearmake">{{alternateVehicle.yearID}}</span><span
                                        class="mvos-yearmake">{{alternateVehicle.trimFullName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div>