
/* injects from baggage-loader */
require('./schedule.component.html');

import angular from 'angular';
import moment from 'moment';

angular.module('AaApp.Opp').component('schedule', {
    templateUrl: '/app/components/deal-sheet/modals/schedule.component.html',
    controller: ScheduleController,
    bindings: {
        dealerId: '<',
        opportunityId: '<',
        scheduleType: '<',
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function ScheduleController($filter, $translate, $translatePartialLoader, storageService, userService,
    scheduleTitleApptKey,
    scheduleTitleCallKey,
    rescheduleTitleCallKey,
    rescheduleTitleApptKey,
    reassignTitleCallKey,
    reassignTitleApptKey,
    scheduleAssignToErrorMsgKey,
    activityService,
    dateFormatService,
    ActivityActionEnum) {
    var $ctrl = this;

    //setup ui display object type for custom dialogbox
    var scheduleType = {
        scheduleCall: { activityId: ActivityActionEnum.Schedule_Call, description: scheduleTitleCallKey, IsAssignToVisible: true, IsDateVisible: true, IsTimeVisible: true, IsReminderVisible: true },
        scheduleAppt: { activityId: ActivityActionEnum.Schedule_Appt, description: scheduleTitleApptKey, IsAssignToVisible: true, IsDateVisible: true, IsTimeVisible: true, IsReminderVisible: true },
        rescheduleCall: { activityId: ActivityActionEnum.Reschedule_Call, description: rescheduleTitleCallKey, IsAssignToVisible: false, IsDateVisible: true, IsTimeVisible: true, IsReminderVisible: true },
        rescheduleAppt: { activityId: ActivityActionEnum.Reschedule_Appt, description: rescheduleTitleApptKey, IsAssignToVisible: false, IsDateVisible: true, IsTimeVisible: true, IsReminderVisible: true },
        reassignCall: { activityId: ActivityActionEnum.Reassign_Call, description: reassignTitleCallKey, IsAssignToVisible: true, IsDateVisible: false, IsTimeVisible: false, IsReminderVisible: false },
        reassignAppt: { activityId: ActivityActionEnum.Reassign_Appt, description: reassignTitleApptKey, IsAssignToVisible: true, IsDateVisible: false, IsTimeVisible: false, IsReminderVisible: false }
    }

    initModels();

    $ctrl.isSltUser;
    $ctrl.scheduleDueDateModel;
    $ctrl.submitting;
    $ctrl.$onChanges = onChanges;
    $ctrl.submitClick = submitClick;
    $ctrl.cancelClick = cancelClick;
    $ctrl.calendarClick = calendarClick;
    $ctrl.postActivity = postActivity;
    $ctrl.dateFormat = dateFormatService.getDateFormatString();
    $ctrl.setScheduleDueDate = setScheduleDueDate;

    function initModels() {

        var savedCultureName = storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        $ctrl.editedActivityAssignToUser = null;
        $ctrl.currentLoginUserProfile = storageService.getItem('userProfile');
        $ctrl.validatedAssignee = {}
        $ctrl.validatedAssignee.isValid = false;
        $ctrl.validatedAssignee.index = -1;
        // default user choice

        // get's passed via resolve
        $ctrl.ownerId = null;
        $ctrl.owner = null;
        $ctrl.userList = [];
        $ctrl.activity = null;

        // owner info
        $ctrl.soldById = null;
        $ctrl.soldByName = null;
        $ctrl.soldByStatus = null;

        // setup time picker
        $ctrl.mytime = new Date();
        $ctrl.minDate = new Date();
        $ctrl.hstep = 1;
        $ctrl.mstep = 5;
        $ctrl.ismeridian = (cultureName == 'fr-fr') ? false : true;

        $ctrl.status = { opened: false };

        Date.prototype.addDays = function (days) {
            var dat = new Date(this.valueOf());
            dat.setDate(dat.getDate() + days);
            return dat;
        }

        var now = new Date();

        var dueDate = now.addDays(5 / (24 * 60));// add 5 min to current time
        $ctrl.model = {
            scheduleDueDate: dueDate,
            time: dueDate, //new Date(1, 1, 2000, dueDate.getHours(), dueDate.getMinutes()),
            notes: '',
            user: -1,
            sendReminder: false,
            userChoice: 'other'
        }
    }

    // Disable weekend selection
    function disabled(data) {
        var date = data.date,
          mode = data.mode;
        return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    function calendarClick($event) {
        $ctrl.status.opened = true;
    }

    function onChanges() {
        if ($ctrl.resolve) {
            if ($ctrl.isSltUser == null) {
                userService.getUserProfile()
                    .then(userProfile => {
                        $ctrl.isSltUser = userProfile.isSltUser;
                        updateModel();
                    });
            }
            else {
                updateModel();
            }
        }
    }

    function updateModel() {

        $ctrl.opportunityId = $ctrl.resolve.items.opportunityId;
        $ctrl.dealerId = $ctrl.resolve.items.dealerId;
        $ctrl.scheduleType = $ctrl.resolve.items.modalType;
        $ctrl.maxFutureDays = $ctrl.resolve.items.maxScheduleFutureDays;
        $ctrl.ownerUserInfo = $ctrl.resolve.items.ownerUserInfoObject;

        var now = new Date();
                
        if ($ctrl.isSltUser) {
            var maxSltScheduleDays = 30;
            $ctrl.maxDate = $ctrl.resolve.items.expirationDate ? new Date($ctrl.resolve.items.expirationDate) : maxSltScheduleDays;
        }
        else {
            $ctrl.maxDate = now.addDays($ctrl.maxFutureDays);
        }

        $ctrl.dateOptions = {
            formatYear: 'yy',
            minDate: $ctrl.minDate,
            maxDate: $ctrl.maxDate,
            startingDay: 0,
            showWeeks: false
        };

        for (var i = 0; i < $ctrl.resolve.items.usersObject.length; i++) {
            //Must create a new array and not reference the binding
            //as it can change the parent array. filter and add only user's with type id 1 that is client
            if ($ctrl.resolve.items.usersObject[i].userTypeId == 1)
                $ctrl.userList.push($ctrl.resolve.items.usersObject[i]);
        }
        // sort it to ascending order by fullName
        $ctrl.userList.sort(dynamicSort("fullName"));

        // get passed owner id
        $ctrl.ownerId = $ctrl.resolve.items.dealSheetOwnerId;

        // if activity is passed from parent
        if ($ctrl.resolve.items.activityObject)
            $ctrl.activity = $ctrl.resolve.items.activityObject;
        
            

        $ctrl.scheduleForm.$setValidity()
        
        setupScheduleDialogUX();
    }

    function setupScheduleDialogUX() {
        switch ($ctrl.scheduleType) {
            case ActivityActionEnum.Schedule_Call:
                //setup dialogUX
                $ctrl.currentScheduleType = scheduleType.scheduleCall;
                $ctrl.ownerId = getValidOwnerId($ctrl.ownerId);
                $ctrl.owner = getValidatedAssignToUserObject($ctrl.ownerId, $ctrl.userList);
                $ctrl.model.user = '-1';
                break;
            case ActivityActionEnum.Schedule_Appt:
                $ctrl.currentScheduleType = scheduleType.scheduleAppt;
                $ctrl.ownerId = getValidOwnerId($ctrl.ownerId);
                $ctrl.owner = getValidatedAssignToUserObject($ctrl.ownerId, $ctrl.userList);
                $ctrl.model.user = '-1';
                break;
            case ActivityActionEnum.Reschedule_Call:
                //activity is passed from parent
                $ctrl.currentScheduleType = scheduleType.rescheduleCall;
                $ctrl.ownerId = getValidOwnerId($ctrl.ownerId);
                $ctrl.owner = getValidatedAssignToUserObject($ctrl.ownerId, $ctrl.userList);
                $ctrl.editedActivityAssignToUser = getValidatedAssignToUserObject($ctrl.activity.assignedUserID, $ctrl.userList);
                if (!$ctrl.editedActivityAssignToUser.isValid)
                    $ctrl.model.user = '-1';
                else
                    $ctrl.model.user = $ctrl.editedActivityAssignToUser.id + '';
                break;
            case ActivityActionEnum.Reschedule_Appt:
                //activity is passed from parent
                $ctrl.currentScheduleType = scheduleType.rescheduleAppt;
                $ctrl.ownerId = getValidOwnerId($ctrl.ownerId);
                $ctrl.owner = getValidatedAssignToUserObject($ctrl.ownerId, $ctrl.userList);
                $ctrl.editedActivityAssignToUser = getValidatedAssignToUserObject($ctrl.activity.assignedUserID, $ctrl.userList);
                if (!$ctrl.editedActivityAssignToUser.isValid)
                    $ctrl.model.user = '-1';
                else
                    $ctrl.model.user = $ctrl.editedActivityAssignToUser.id + '';
                break;
            case ActivityActionEnum.Reassign_Call:
                //activity is passed from parent
                $ctrl.currentScheduleType = scheduleType.reassignCall;
                $ctrl.ownerId = getValidOwnerId($ctrl.ownerId);
                $ctrl.owner = getValidatedAssignToUserObject($ctrl.ownerId, $ctrl.userList);
                $ctrl.editedActivityAssignToUser = getValidatedAssignToUserObject($ctrl.activity.assignedUserID, $ctrl.userList);
                if (!$ctrl.editedActivityAssignToUser.isValid)
                    $ctrl.model.user = '-1';
                else
                    $ctrl.model.user = $ctrl.editedActivityAssignToUser.id + '';
                break;
            case ActivityActionEnum.Reassign_Appt:
                //activity is passed from parent
                $ctrl.currentScheduleType = scheduleType.reassignAppt;
                $ctrl.ownerId = getValidOwnerId($ctrl.ownerId);
                $ctrl.owner = getValidatedAssignToUserObject($ctrl.ownerId, $ctrl.userList);
                $ctrl.editedActivityAssignToUser = getValidatedAssignToUserObject($ctrl.activity.assignedUserID, $ctrl.userList);
                if (!$ctrl.editedActivityAssignToUser.isValid)
                    $ctrl.model.user = '-1';
                else
                    $ctrl.model.user = $ctrl.editedActivityAssignToUser.id + '';
                break;
        }

        $ctrl.dialogTitle = $ctrl.currentScheduleType.description;
        getAssignToSelectedState();
    }

    // based on rules of WEBUI-4465 ownerId is replaced with soldByid
    function getValidOwnerId(currentOwnerId) {

        currentOwnerId = getValidatedAssignToUserObject(currentOwnerId, $ctrl.userList).id;
        var userId = -1;

        if ($ctrl.ownerUserInfo) {
            if (currentOwnerId == -1 && $ctrl.ownerUserInfo.soldByUserId == null && $ctrl.ownerUserInfo.soldByUserFullName == null)
                return -1;

            if (currentOwnerId == -1 && $ctrl.ownerUserInfo.soldByUserId == null && $ctrl.ownerUserInfo.soldByUserFullName != null) {
                userId = getUserIdbyName($ctrl.ownerUserInfo.soldByUserFullName, $ctrl.userList);
                return userId;
            }

            if (currentOwnerId == -1 && $ctrl.ownerUserInfo.soldByUserId != null) {
                return $ctrl.ownerUserInfo.soldByUserId;
            }

            if (currentOwnerId != -1 && $ctrl.ownerUserInfo.ownerUserEnabled == false && $ctrl.ownerUserInfo.soldByUserId != null) {
                return $ctrl.ownerUserInfo.soldByUserId;
            }
        }

        return currentOwnerId;
    }

    function getUserIdbyName(userName, userList) {
        for (var i = 0; i < userList.length; i++) {
            if (userList[i].fullName.toLowerCase() == userName.toLowerCase()) {
                return userList[i].id;
            }
        }
        return -1;
    }

    function getValidatedAssignToUserObject(userID, userList) {
        var resultObj = {};
        resultObj.isValid = false;
        resultObj.index = -1;
        resultObj.fullName = null;
        resultObj.id = -1;

        for (var i = 0; i < userList.length; i++) {
            if (userList[i].id == userID) {
                resultObj.isValid = true;
                resultObj.index = i;
                resultObj.fullName = userList[i].fullName;
                resultObj.id = userList[i].id;
                return resultObj;
            }
        }
        return resultObj;
    }

    function getAssignToSelectedState() {

        if (!$ctrl.currentLoginUserProfile.employeeUser) {
            $ctrl.model.userChoice = 'self';
            if ($ctrl.currentLoginUserProfile.userId == $ctrl.owner.id) {
                $ctrl.owner.isValid = false;
            }

            return;
        }

        if ($ctrl.owner.isValid) {
            $ctrl.model.userChoice = 'owner';
            return;
        }

        $ctrl.model.userChoice = 'other';
        return;
    }

    // sort a list object based on property
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    function postActivity() {

        var assignToUserId = -1;
        switch ($ctrl.model.userChoice) {
            case 'self':
                assignToUserId = $ctrl.currentLoginUserProfile.userId;
                break;
            case 'owner':
                assignToUserId = $ctrl.owner.id;
                break;
            case 'other':
                assignToUserId = $ctrl.model.user;
                break;
        }

        if (!assignToUserId) {
            $ctrl.errorMessage = scheduleAssignToErrorMsgKey;
            return;
        }

        if (assignToUserId == -1) {
            $ctrl.errorMessage = scheduleAssignToErrorMsgKey;
            return;
        }


        var dateTime = new Date($ctrl.model.scheduleDueDate.getFullYear(), $ctrl.model.scheduleDueDate.getMonth(), $ctrl.model.scheduleDueDate.getDate(),
           $ctrl.model.time.getHours(), $ctrl.model.time.getMinutes(), $ctrl.model.time.getSeconds());

        var recordId = null;
        if ($ctrl.activity)
            if ($ctrl.currentScheduleType.activityId != ActivityActionEnum.Schedule_Call && $ctrl.currentScheduleType.activityId != ActivityActionEnum.Schedule_Appt)
                recordId = $ctrl.activity.activityId;

        var activity = {
            entityID: $ctrl.opportunityId,
            activityActionID: $ctrl.currentScheduleType.activityId,
            notes: $ctrl.model.notes,
            assignTo: assignToUserId, //$ctrl.model.user,
            dueDate: moment(dateTime).format("YYYY-MM-DD[T]HH:mm:ss"),
            sendReminder: $ctrl.model.sendReminder,
            activityId: recordId,
            activityActionSubTypeID: null
        }

        if (!activity.notes)
            activity.notes = '';

        $ctrl.submitting = activityService.postActivitySubmitAction(activity)
            .then(function (data) {
                data.activityId = $ctrl.currentScheduleType.activityId;
                $ctrl.submitting = null;
                $ctrl.close({ $value: data });
            });

    }

    function cancelClick() {
        $ctrl.close({ $value: 'followup-cancel' });
    }

    function submitClick() {
        postActivity();
    }

    function setScheduleDueDate(date, model) {
        $ctrl.model.scheduleDueDate = date;
        $ctrl.scheduleDueDateModel = model;        
    }
}
