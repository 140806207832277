/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SalePayoffRequestDto } from '../models/sale-payoff-request-dto';
@Injectable({
  providedIn: 'root',
})
class SaleClientService extends __BaseService {
  static readonly GetSaleExtByEntityIdByEntityidGETPath = '/api/Sale/GetSaleExtByEntityId/{entityId}';
  static readonly AddUpdateSalePayoffPOSTPath = '/api/Sale/AddUpdateSalePayoff';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   */
  GetSaleExtByEntityIdByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Sale/GetSaleExtByEntityId/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetSaleExtByEntityIdByEntityidGET(entityId: string): __Observable<null> {
    return this.GetSaleExtByEntityIdByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   */
  AddUpdateSalePayoffPOSTResponse(request?: SalePayoffRequestDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Sale/AddUpdateSalePayoff`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  AddUpdateSalePayoffPOST(request?: SalePayoffRequestDto): __Observable<null> {
    return this.AddUpdateSalePayoffPOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SaleClientService {
}

export { SaleClientService }
