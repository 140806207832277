
/* injects from baggage-loader */
require('./opportunity-block.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('opportunityBlock', {
    templateUrl: '/app/components/dashboard/opportunity-block.component.html',
    controller: OpportunityBlockController,
    bindings: {
        alertName: '@',
        alertCount: '@',
        cssClass: '@',
        selectedDealerId: '<',
        translateKey: '@'
    }
});

/* @ngInject */
function OpportunityBlockController($state, authorizationService,  opportunityCountsService) {
    var $ctrl = this;
    //$ctrl.opportunities = {};

    $ctrl.goToState = goToState;
    
    function goToState() {
        const isEuroLite = authorizationService.isEuroLite();
        //logger.debug('block clicked for ' + 'opportunity' + $ctrl.alertName);

        if (!isEuroLite)
            $state.go('opportunities' + $ctrl.alertName.charAt(0).toUpperCase() + $ctrl.alertName.slice(1), { 'dealerId': $ctrl.selectedDealerId });
        else {
            var filter = { "opportunityBlock": $ctrl.alertName };
            $state.go('euroliteopportunities', { filter: filter });
        }

    }
}
