<img *ngIf="showCarFaxLink && readonly" src="../../../images/carfax.png" />

<a *ngIf="showCarFaxLink && !readonly"
   href="javascript:void(0)"
   uiSref="carfaxAuthorize"
   [uiParams]="{ dealerId: dealerId, entityId: opportunityId, vin: vin }"
   [popover]="popTemplate"
   placement="top" triggers="mouseenter"
   [outsideClick]="true"
   target="_blank"
   class="qa-carfax-link">
    <img src="../../../images/carfax.png" />
</a>

<ng-template #popTemplate>
    <div>
        <p class="carFaxTooltip" translate="carfax_logout_tooltip_a"></p>
        <p class="carFaxTooltip">
            <span translate="carfax_logout_tooltip_b"></span>&nbsp;<a href="#" (click)="logOut()" translate="signOut"></a>&nbsp;<span translate="carfax_logout_tooltip_c"></span>
        </p>
    </div>
</ng-template>

