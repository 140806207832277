
/* injects from baggage-loader */
require('./dashboard.component.html');

import angular from 'angular';
import { Modules } from '../../constants/modules';

import './dashboard.scss';

angular.module('AaApp.Opp').component('dashboard', {
    templateUrl: '/app/components/dashboard/dashboard.component.html',
    controller: DashboardController,
    bindings: {
        moduleIds: '<',
    }
});

function DashboardController($rootScope, dealerService, authorizationService, $state, globalIframeService, storageService, userService) {
    "ngInject";

    const $ctrl = this;

    $ctrl.$onInit = $onInit;
    $ctrl.$onChanges = $onChanges;
    $ctrl.dealers;
    $ctrl.selectedDealerId = null;

    $ctrl.showQuickLinkPanel = true;

    $ctrl.onDealerSelect = onDealerSelect;

    $ctrl.tabs = [];

    const isEuroLite = authorizationService.isEuroLite();
    const tabDefinitions = [
        {
            requiredModules: [],
            sref: 'dashboard.opportunities',
            translationKey: 'opportunitiesDashboardHeader',
        },
        {
            requiredModules: [Modules.managerDashboard],
            sref: 'dashboard.manager',
            translationKey: 'priorityOppDashboard',
        },
    ];

    function $onInit() {
        $ctrl.dealers = dealerService.getSelectedDealers();

        $ctrl.selectedDealerId = storageService.getItem('dashboard-selectedDealerId');

        // if no dealer has been selected yet and there is only one dealer or the selected dealer is no longer on the list of available dealers
        if (($ctrl.selectedDealerId == null && $ctrl.dealers.length == 1) || ($ctrl.selectedDealerId != null && !$ctrl.dealers.some(d => d.id == $ctrl.selectedDealerId)))
            $ctrl.selectedDealerId = $ctrl.dealers[0].id;

        const subscription = globalIframeService.subscribe(function ({ data }) {
            if (data && data['AUTOALERT_IFRAME_LOADED']) { // AA is ready show iframe
                $ctrl.IsVisible = true;
            }
        });
        $ctrl.$onDestroy = () => subscription.unsubscribe();

        userService.getUserProfile()
            .then(function (userProfile) {
                $ctrl.userId = userProfile.userId;
            })

    }

    function $onChanges(changes) {
        if (changes.moduleIds) {
            filterAvailableTabs();            
        }
    }

    function filterAvailableTabs() {
        $ctrl.showQuickLinkPanel = !isEuroLite;

        if (!isEuroLite)
        $ctrl.tabs = tabDefinitions.filter((def) => {
            // the user must have every required module
            return def.requiredModules.every(req => $ctrl.moduleIds.includes(req))
            });
        else
            $ctrl.tabs = tabDefinitions.filter((def) => {                
                return def.sref == 'dashboard.opportunities';
            });

    }

    function onDealerSelect() {
        storageService.setItem('dashboard-selectedDealerId', $ctrl.selectedDealerId);
        $state.go($state.current.name, { selectedDealerId : $ctrl.selectedDealerId });
    }
}
