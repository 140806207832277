import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PaymentTypes, SaleTypes, TradeValueTypes, VehicleTypes } from '../../../../constants';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { UserProfile } from '../../../../interfaces/user-profile.interface';
import { DealSheetDataService } from '../../../../services/deal-sheet-data.service';
import { StateService, Transition } from '@uirouter/core';
import { MobileDealSheetDataCachingService } from '../../../../services/mobile-deal-sheet-data-caching.service';
import { Subscription, Observable } from 'rxjs';
import { MobileRoutes } from '../mobile-routes';

@Component({
    selector: 'mobile-vehicles',
    templateUrl: './mobile-vehicles.component-ng.html',
    styleUrls: ['./mobile-vehicles.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileVehiclesComponent implements OnInit, OnDestroy {
    opportunitySubscription: Subscription;
    opportunityId: string;

    opportunity: DealSheetOpportunity;
    permissions: DealSheetPermissions;
    alertExists: boolean;
    flexAlertExists: boolean;
    serviceAlertExists: boolean;
    appointmentAlertExists: boolean;
    mileageAlertExists: boolean;
    contractEndAlertExists: boolean;
    warrantyAlertExists: boolean;
    userProfile: UserProfile;
    fuelEconomyComparison: any;

    public vehicleTypeKeys = Object.keys(VehicleTypes);
    public vehicleTypes = VehicleTypes;
    public saleTypeKeys = Object.keys(SaleTypes);
    public paymentTypes = PaymentTypes;
    public tradeValueTypes = TradeValueTypes;

    public showPullForward: boolean;

    public busy: Subscription;

    constructor(private dealSheetDataService: DealSheetDataService,
        private stateService: StateService,
        private mobileDealSheetDataCachingService: MobileDealSheetDataCachingService,
        private transition: Transition,
        private changeDetectorRef: ChangeDetectorRef) {
        this.opportunityId = this.transition.params().opportunityId;
    }

    ngOnInit() {
        this.showPullForward = this.dealSheetDataService.shouldDisplayPullForward(this.opportunity);

        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(dealSheetData => {
            this.opportunity = dealSheetData.opportunity;
            this.permissions = dealSheetData.permissions;
            this.alertExists = dealSheetData.alertExists;
            this.flexAlertExists = dealSheetData.flexAlertExists;
            this.serviceAlertExists = dealSheetData.serviceAlertExists;
            this.appointmentAlertExists = dealSheetData.appointmentAlertExists;
            this.mileageAlertExists = dealSheetData.mileageAlertExists;
            this.contractEndAlertExists = dealSheetData.contractEndAlertExists;
            this.warrantyAlertExists = dealSheetData.warrantyAlertExists;
            this.userProfile = dealSheetData.userProfile;
            this.fuelEconomyComparison = dealSheetData.fuelEconomyComparison;

            this.changeDetectorRef.detectChanges();
        });

        this.busy = this.opportunitySubscription;
    }

    ngOnDestroy(): void {
        this.opportunitySubscription.unsubscribe();
    }

    public viewReplacementDetails() {
        this.stateService.go(MobileRoutes.replacementDetails);
    }
}
