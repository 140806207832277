"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var EntityClientService = /** @class */ (function (_super) {
    __extends(EntityClientService, _super);
    function EntityClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetExtByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetExt/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetExtByEntityidGET = function (entityId) {
        return this.GetExtByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    EntityClientService.prototype.ReassignToUserIdPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Entity/ReassignToUserId", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    EntityClientService.prototype.ReassignToUserIdPOST = function (request) {
        return this.ReassignToUserIdPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.CreditPrescreenRequestByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/CreditPrescreenRequest/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.CreditPrescreenRequestByEntityidPOST = function (entityId) {
        return this.CreditPrescreenRequestByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.CreditPrescreenStatusByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/CreditPrescreenStatus/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.CreditPrescreenStatusByEntityidGET = function (entityId) {
        return this.CreditPrescreenStatusByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.MarkAsReadPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = entityId;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Entity/MarkAsRead", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.MarkAsReadPOST = function (entityId) {
        return this.MarkAsReadPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.CustomerDoesNotOwnVehicleByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/CustomerDoesNotOwnVehicle/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.CustomerDoesNotOwnVehicleByEntityidPOST = function (entityId) {
        return this.CustomerDoesNotOwnVehicleByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.VehiclePurchasedByDealerByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/VehiclePurchasedByDealer/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.VehiclePurchasedByDealerByEntityidPOST = function (entityId) {
        return this.VehiclePurchasedByDealerByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.VehiclePaidOffByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/VehiclePaidOff/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.VehiclePaidOffByEntityidPOST = function (entityId) {
        return this.VehiclePaidOffByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.VehicleNotPaidOffByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/VehicleNotPaidOff/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.VehicleNotPaidOffByEntityidPOST = function (entityId) {
        return this.VehicleNotPaidOffByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.HasWarrantyByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/HasWarranty/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.HasWarrantyByEntityidPOST = function (entityId) {
        return this.HasWarrantyByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.SoldWarrantyByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/SoldWarranty/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.SoldWarrantyByEntityidPOST = function (entityId) {
        return this.SoldWarrantyByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.UndoHasWarrantyByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/UndoHasWarranty/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.UndoHasWarrantyByEntityidPOST = function (entityId) {
        return this.UndoHasWarrantyByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    EntityClientService.prototype.UpdateMileagePOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Entity/UpdateMileage", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    EntityClientService.prototype.UpdateMileagePOST = function (request) {
        return this.UpdateMileagePOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.MarkAsSoldByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/MarkAsSold/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.MarkAsSoldByEntityidPOST = function (entityId) {
        return this.MarkAsSoldByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.WatchByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/Watch/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.WatchByEntityidPOST = function (entityId) {
        return this.WatchByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.UnwatchByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/Unwatch/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.UnwatchByEntityidPOST = function (entityId) {
        return this.UnwatchByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.ReopenByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/Reopen/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.ReopenByEntityidPOST = function (entityId) {
        return this.ReopenByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.UnmarkSoldWarrantyByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/UnmarkSoldWarranty/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.UnmarkSoldWarrantyByEntityidPOST = function (entityId) {
        return this.UnmarkSoldWarrantyByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param customerId undefined
     * @return Success
     */
    EntityClientService.prototype.GetEntityReportByCustomerIdByCustomeridGETResponse = function (customerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetEntityReportByCustomerId/" + customerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param customerId undefined
     * @return Success
     */
    EntityClientService.prototype.GetEntityReportByCustomerIdByCustomeridGET = function (customerId) {
        return this.GetEntityReportByCustomerIdByCustomeridGETResponse(customerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETParams` containing the following parameters:
     *
     * - `vin`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    EntityClientService.prototype.GetEntityReportByDealerIdAndVinByDealeridByVinGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetEntityReportByDealerIdAndVin/" + params.dealerId + "/" + params.vin), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETParams` containing the following parameters:
     *
     * - `vin`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    EntityClientService.prototype.GetEntityReportByDealerIdAndVinByDealeridByVinGET = function (params) {
        return this.GetEntityReportByDealerIdAndVinByDealeridByVinGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetEntityStatusByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetEntityStatus/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetEntityStatusByEntityidGET = function (entityId) {
        return this.GetEntityStatusByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.HasReviewProDealerByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/HasReviewProDealer/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.HasReviewProDealerByEntityidGET = function (entityId) {
        return this.HasReviewProDealerByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.HasGeoAlertDealerByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/HasGeoAlertDealer/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    EntityClientService.prototype.HasGeoAlertDealerByEntityidGET = function (entityId) {
        return this.HasGeoAlertDealerByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetVehicleTurnPredictorByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetVehicleTurnPredictor/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetVehicleTurnPredictorByEntityidGET = function (entityId) {
        return this.GetVehicleTurnPredictorByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetActiveNLPNoLongerOwnByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetActiveNLPNoLongerOwn/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetActiveNLPNoLongerOwnByEntityidGET = function (entityId) {
        return this.GetActiveNLPNoLongerOwnByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `agreed`:
     *
     * @return Success
     */
    EntityClientService.prototype.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/UpdateActiveNLPNoLongerOwn/" + params.entityId + "/" + params.agreed), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param params The `EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `agreed`:
     *
     * @return Success
     */
    EntityClientService.prototype.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGET = function (params) {
        return this.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetEntityScoreFeatureByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/GetEntityScoreFeature/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    EntityClientService.prototype.GetEntityScoreFeatureByEntityidGET = function (entityId) {
        return this.GetEntityScoreFeatureByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams` containing the following parameters:
     *
     * - `grantUserId`:
     *
     * - `entityId`:
     *
     * @return Success
     */
    EntityClientService.prototype.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/ExternalGrantEntityAccess/" + params.entityId + "/" + params.grantUserId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams` containing the following parameters:
     *
     * - `grantUserId`:
     *
     * - `entityId`:
     *
     * @return Success
     */
    EntityClientService.prototype.ExternalGrantEntityAccessByEntityidByGrantuseridPOST = function (params) {
        return this.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `entityId`:
     *
     * @return Success
     */
    EntityClientService.prototype.ExternalRevokeEntityAccessByEntityidByUseridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/ExternalRevokeEntityAccess/" + params.entityId + "/" + params.userId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `entityId`:
     *
     * @return Success
     */
    EntityClientService.prototype.ExternalRevokeEntityAccessByEntityidByUseridPOST = function (params) {
        return this.ExternalRevokeEntityAccessByEntityidByUseridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams` containing the following parameters:
     *
     * - `ownerUserId`:
     *
     * - `actingUserId`:
     *
     * - `entites`:
     *
     * @return Success
     */
    EntityClientService.prototype.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.entites;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Entity/ExternalBulkAssignEntityOwners/" + params.actingUserId + "/" + params.ownerUserId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams` containing the following parameters:
     *
     * - `ownerUserId`:
     *
     * - `actingUserId`:
     *
     * - `entites`:
     *
     * @return Success
     */
    EntityClientService.prototype.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOST = function (params) {
        return this.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    EntityClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Entity/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    EntityClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    EntityClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Entity/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    EntityClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    EntityClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Entity/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    EntityClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    EntityClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Entity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    EntityClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    EntityClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Entity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    EntityClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    EntityClientService.GetExtByEntityidGETPath = '/api/Entity/GetExt/{entityId}';
    EntityClientService.ReassignToUserIdPOSTPath = '/api/Entity/ReassignToUserId';
    EntityClientService.CreditPrescreenRequestByEntityidPOSTPath = '/api/Entity/CreditPrescreenRequest/{entityId}';
    EntityClientService.CreditPrescreenStatusByEntityidGETPath = '/api/Entity/CreditPrescreenStatus/{entityId}';
    EntityClientService.MarkAsReadPOSTPath = '/api/Entity/MarkAsRead';
    EntityClientService.CustomerDoesNotOwnVehicleByEntityidPOSTPath = '/api/Entity/CustomerDoesNotOwnVehicle/{entityId}';
    EntityClientService.VehiclePurchasedByDealerByEntityidPOSTPath = '/api/Entity/VehiclePurchasedByDealer/{entityId}';
    EntityClientService.VehiclePaidOffByEntityidPOSTPath = '/api/Entity/VehiclePaidOff/{entityId}';
    EntityClientService.VehicleNotPaidOffByEntityidPOSTPath = '/api/Entity/VehicleNotPaidOff/{entityId}';
    EntityClientService.HasWarrantyByEntityidPOSTPath = '/api/Entity/HasWarranty/{entityId}';
    EntityClientService.SoldWarrantyByEntityidPOSTPath = '/api/Entity/SoldWarranty/{entityId}';
    EntityClientService.UndoHasWarrantyByEntityidPOSTPath = '/api/Entity/UndoHasWarranty/{entityId}';
    EntityClientService.UpdateMileagePOSTPath = '/api/Entity/UpdateMileage';
    EntityClientService.MarkAsSoldByEntityidPOSTPath = '/api/Entity/MarkAsSold/{entityId}';
    EntityClientService.WatchByEntityidPOSTPath = '/api/Entity/Watch/{entityId}';
    EntityClientService.UnwatchByEntityidPOSTPath = '/api/Entity/Unwatch/{entityId}';
    EntityClientService.ReopenByEntityidPOSTPath = '/api/Entity/Reopen/{entityId}';
    EntityClientService.UnmarkSoldWarrantyByEntityidPOSTPath = '/api/Entity/UnmarkSoldWarranty/{entityId}';
    EntityClientService.GetEntityReportByCustomerIdByCustomeridGETPath = '/api/Entity/GetEntityReportByCustomerId/{customerId}';
    EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETPath = '/api/Entity/GetEntityReportByDealerIdAndVin/{dealerId}/{vin}';
    EntityClientService.GetEntityStatusByEntityidGETPath = '/api/Entity/GetEntityStatus/{entityId}';
    EntityClientService.HasReviewProDealerByEntityidGETPath = '/api/Entity/HasReviewProDealer/{entityId}';
    EntityClientService.HasGeoAlertDealerByEntityidGETPath = '/api/Entity/HasGeoAlertDealer/{entityId}';
    EntityClientService.GetVehicleTurnPredictorByEntityidGETPath = '/api/Entity/GetVehicleTurnPredictor/{entityId}';
    EntityClientService.GetActiveNLPNoLongerOwnByEntityidGETPath = '/api/Entity/GetActiveNLPNoLongerOwn/{entityId}';
    EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETPath = '/api/Entity/UpdateActiveNLPNoLongerOwn/{entityId}/{agreed}';
    EntityClientService.GetEntityScoreFeatureByEntityidGETPath = '/api/Entity/GetEntityScoreFeature/{entityId}';
    EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTPath = '/api/Entity/ExternalGrantEntityAccess/{entityId}/{grantUserId}';
    EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTPath = '/api/Entity/ExternalRevokeEntityAccess/{entityId}/{userId}';
    EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTPath = '/api/Entity/ExternalBulkAssignEntityOwners/{actingUserId}/{ownerUserId}';
    EntityClientService.ByIdGETPath = '/api/Entity/{id}';
    EntityClientService.ByIdDELETEPath = '/api/Entity/{id}';
    EntityClientService.GetManyGETPath = '/api/Entity/GetMany';
    EntityClientService.PUTPath = '/api/Entity';
    EntityClientService.POSTPath = '/api/Entity';
    EntityClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityClientService_Factory() { return new EntityClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: EntityClientService, providedIn: "root" });
    return EntityClientService;
}(base_service_1.BaseService));
exports.EntityClientService = EntityClientService;
