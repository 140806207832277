import { DecimalPipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import { StorageService } from '../modules/storage/storage.service';

@Pipe({
    name: 'localeMoneyFactor'
})
export class LocaleMoneyFactorPipe extends DecimalPipe implements PipeTransform {

    constructor(private storageService: StorageService) {
        super('en-us');
    }

    transform(number: any) {
        if (typeof number === 'number') {
            const savedCultureName = this.storageService.getItem('cultureName');
            const cultureName = savedCultureName ? savedCultureName : 'en-us';
            return number.toLocaleString(cultureName, { minimumFractionDigits: 5, maximumFractionDigits: 5 });
        }
    }
}
