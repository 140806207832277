"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ContractEndComponent = /** @class */ (function () {
    function ContractEndComponent() {
    }
    ContractEndComponent.prototype.ngOnInit = function () {
    };
    return ContractEndComponent;
}());
exports.ContractEndComponent = ContractEndComponent;
