import { Inject, Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, flatMap, map, take } from 'rxjs/operators';
import { LegacyService } from '../ajs-upgraded-providers';
import { SendTextWithUpgradeProposalResponse, TextableStatusModel, TextMessageDto } from '../generated/models';
import { TextMessageClientService } from '../generated/services';
import { Customer } from '../interfaces/customer.interface';
import { DealSheetOpportunity } from '../interfaces/deal-sheet/deal-sheet-opportunity.interface';

@Injectable()
export class TextMessageService {
    constructor(@Inject(LegacyService) private legacyService,
        private textMessageClientService: TextMessageClientService) {
    }


    // The "logic" in this method is attempting to parse out the urls and determine which are upgrade proposals and which are dealer contact info urls
    public static replaceUrlStringWithHtmlLink(message: string, useWhiteLink: boolean): string {
        let index = 0;
        let urls: Array<string> = []; // Used to hold all the found urls with http in them.
        let infoUrlNumber = -1;
        while (index < message.length) {
            let urlStartIndex = message.indexOf('http', index);
            if (urlStartIndex < 0)
                break;

            let urlEndIndex = message.indexOf(' ', urlStartIndex);
            
            let urlSecondEndIndex = message.indexOf('<br />', urlStartIndex)

            if (urlSecondEndIndex != -1 && urlSecondEndIndex < urlEndIndex) // Determine if the url ends with a line break or a space
                urlEndIndex = urlSecondEndIndex;

            if (urlEndIndex < 0) { urlEndIndex = message.length; }
            index = urlEndIndex;

            urls.push(message.substring(urlStartIndex, urlEndIndex));

            // Used to determine if this is a dealer contact info url based on the string 'Info: ' being in front *wish there was a better way*
            if (message.indexOf('Info: ', urlStartIndex - 6) > 0) 
                infoUrlNumber = urls.length - 1;
        }
        for (var i = 0; i < urls.length; i++) {
            // Had to embed the style here because of angular's view encapsulation. The alternative was to turn view encapsulation off entirely for the component
            if (i == infoUrlNumber) {
                // Since this function is used in different UI spots the styling color for the link text is different for different backgrounds
                if (useWhiteLink) {
                    message = message.replace(urls[i], `<a style="color:white" iscontactinfo href='${urls[i]}' target="_blank">${urls[i]}</a>`);
                } else {
                    message = message.replace(urls[i], `<a iscontactinfo href='${urls[i]}' target="_blank">${urls[i]}</a>`);
                }
            }
            else {
                if (useWhiteLink) {
                    message = message.replace(urls[i], `<a style="color:white" href='#0'>${urls[i]}</a>`);
                } else {
                    message = message.replace(urls[i], `<a href='#0'>${urls[i]}</a>`);
                }
            }

        }
        return message;
    }

    sendText(newMessage: TextMessageDto): Observable<TextMessageDto> {
        return this.textMessageClientService.SendPOST(newMessage)
            .pipe(
                take(1)
            );
    }

    sendTextWithUpgradeProposal(newMessage: TextMessageDto, opportunity: DealSheetOpportunity): Observable<SendTextWithUpgradeProposalResponse> {
        return from(this.legacyService.getCalculationParameters(opportunity))
            .pipe(
                flatMap((upgradeProposalCalcParameters) => {
                    return this.textMessageClientService.SendWithUpgradeProposalPOST(
                        { textRequest: newMessage, proposalRequest: upgradeProposalCalcParameters }
                    )
                        .pipe(
                            take(1)
                        );
                })
            );
    }

    /**
     * This method is used to get all text messages for a customer
     * If a messageId is provided, only messages that occurred after that id will be returned
     * @param customerId Id of the customer to get
     * @param textMessageId Id of the textMessage to get history after
     * "/api/TextMessage/GeNewMessagesByCustomerId/{customerId}/{messageId}"
     */
    getHistoryByCustomerId(customerId: number, textMessageId?: number): Observable<TextMessageDto[]> {
        {
            const observable = (textMessageId) ?
                this.textMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGET({ customerId: customerId, messageId: textMessageId }) :
                this.textMessageClientService.GetMessagesByCustomerIdByCustomeridGET(customerId);

            return observable
                .pipe(
                    take(1),
                    map(response => {
                        return this.mapGetResponse(response as TextMessageDto[]);
                    }),
                    catchError((error) => {
                        console.error(error);
                        return throwError('Error retrieveing text message history.');
                    }));
        }
    }

    private mapGetResponse(textMessages: TextMessageDto[]) {
        if (Array.isArray(textMessages)) {
            textMessages.forEach(r => {
                // Replace text line breaks with html line breaks so they can be displayed properly
                r.message = r.message.replace(/\n\r?/g, '<br />');
            });
            return textMessages;
        }

        return [] as TextMessageDto[];
    }

    getHistory(opportunity: DealSheetOpportunity): Observable<TextMessageDto[]> {
        return this.getHistoryByCustomerId(opportunity.customer.id);
    }

    downloadUpgradeProposalIfAvailable($event: any, textMessage: TextMessageDto): void {
        if ($event.target.tagName != 'A') { return; }

        // Determine if this is one of our url shortened urls or unshortened up url
        let isFzlnk = ($event.target.outerHTML.indexOf('aalnk') > -1 || $event.target.outerHTML.indexOf('fzlnk') > -1 || $event.target.outerHTML.indexOf('myupgradeproposal') > -1);
        // This triggers an upgrade proposal if it is a shortened url and not the dealer contact info url
        // iscontactinfo is set in replaceUrlStringWithHtmlLink()
        if (textMessage && textMessage.activityId > 0 && isFzlnk && $event.target.outerHTML.indexOf('iscontactinfo') == -1) {
            this.legacyService.downloadUpgradeProposalByActivity({
                activityId: textMessage.activityId
            });
        }
    }

    getTextableStatus(customer: Customer, dealerId: number): Observable<TextableStatusModel> {

        const customerDto: TextMessageClientService.GetTextableStatusGETParams = {
            CellPhone: (customer.cellPhoneNumber) ? customer.cellPhoneNumber.value : '',
            DealerID: dealerId,
            InvalidContacts: (customer.invalidContacts) ? customer.invalidContacts.join(',') : '',
            CustomerID: customer.id
        };

        return this.textMessageClientService.GetTextableStatusGET(customerDto)
            .pipe(
                take(1)
            );
    }

    isUserNumberTextable(): Observable<boolean> {
        return this.textMessageClientService.IsUserNumberTextableGET()
            .pipe(
                take(1)
            );
    }
}
