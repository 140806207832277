CustomerContactController.$inject = ["$q", "$translate", "$filter", "$uibModal", "$document", "contactTypes", "storageService", "googleService", "customerService"];

/* injects from baggage-loader */
require('./customer-contact.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('customerContact', {
  templateUrl: '/app/components/deal-sheet/customer-contact.component.html',
  controller: CustomerContactController,
  bindings: {
    opportunity: '<',
    customer: '<',
    permissions: '<',
    dealerSettings: '<',
    openDate: '<',
    lastTouchedDate: '<',
    countryId: '<',
    onEmailSend: '&',
    isEuroLite: '<'
  }
});
/* @ngInject */

function CustomerContactController($q, $translate, $filter, $uibModal, $document, contactTypes, storageService, googleService, customerService) {
  var $ctrl = this;
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;
  $ctrl.openModalWithMap = openModalWithMap;
  $ctrl.customerAddress = '';
  $ctrl.addressCoordinatesSearched = false;
  $ctrl.hasCoords = false;
  $ctrl.cultureName = 'en-US';
  $ctrl.cultureDisplayName = null;

  function init() {
    getTranslations();
    $ctrl.cultureName = storageService.getItem('cultureName') || 'en-US';
  }

  function getTranslations() {
    $q.all({
      'tel1Label': $translate('tel1Label'),
      'tel2Label': $translate('tel2Label'),
      'phoneCellLabel': $translate('phoneCellLabel'),
      'phoneHomeLabel': $translate('phoneHomeLabel'),
      'phoneWorkLabel': $translate('phoneWorkLabel'),
      'preferredLanguageLabel': $translate('preferredLanguage')
    }).then(function (trans) {
      if ($ctrl.isEuroLite) {
        $ctrl.phoneHomeLabel = trans.tel1Label;
        $ctrl.phoneCellLabel = trans.tel2Label;
      } else {
        $ctrl.phoneHomeLabel = trans.phoneHomeLabel;
        $ctrl.phoneCellLabel = trans.phoneCellLabel;
      }

      $ctrl.phoneWorkLabel = trans.phoneWorkLabel;
      $ctrl.preferredLanguageLabel = trans.preferredLanguageLabel;
    });
  }

  function onChanges(changes) {
    getCustomerAddress();

    if (changes.customer && changes.customer.currentValue) {
      $ctrl.homePhones = [];
      var homePhones = $filter('filter')($ctrl.customer.alternateContacts, {
        contactType: contactTypes.homePhone
      });
      $ctrl.homePhones = $ctrl.homePhones.concat(homePhones);
      $ctrl.homePhones = $filter('unique')($ctrl.homePhones, 'value');
      $ctrl.workPhones = [];
      var workPhones = $filter('filter')($ctrl.customer.alternateContacts, {
        contactType: contactTypes.workPhone
      });
      $ctrl.workPhones = $ctrl.workPhones.concat(workPhones);
      $ctrl.workPhones = $filter('unique')($ctrl.workPhones, 'value');
      $ctrl.cellPhones = [];
      var cellPhones = $filter('filter')($ctrl.customer.alternateContacts, {
        contactType: contactTypes.cellPhone
      });
      $ctrl.cellPhones = $ctrl.cellPhones.concat(cellPhones);
      $ctrl.cellPhones = $filter('unique')($ctrl.cellPhones, 'value');
      $ctrl.emails = [];
      var emails = $filter('filter')($ctrl.customer.alternateContacts, {
        contactType: contactTypes.email
      });
      $ctrl.emails = $ctrl.emails.concat(emails);
      $ctrl.emails = $filter('unique')($ctrl.emails, 'value');
      $ctrl.cultureDisplayName = customerService.getCustomerCultureByCode($ctrl.customer.preferredCultureName);
    }
  }

  function getCustomerAddress() {
    var Address1 = '';
    var Address2 = '';

    if ($ctrl.customer) {
      $ctrl.hasCoords = $ctrl.customer.address.streetAddress != null;
      if ($ctrl.customer.address.streetAddress) Address1 = $ctrl.customer.address.streetAddress;else Address1 = '';
      if ($ctrl.customer.address.streetAddress2) Address2 = ' ' + $ctrl.customer.address.streetAddress2;else Address2 = ' ';
      $ctrl.customerAddress = Address1 + Address2;
      $ctrl.fullAddress = $ctrl.customerAddress + ', ' + $ctrl.customer.address.city + ', ' + $ctrl.customer.address.stateName + ', ' + $ctrl.customer.address.normalizedZipCode;
    }
  }

  function openModalWithMap() {
    var modalInstance = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      windowClass: 'large-modal',
      component: 'googleMapsModal',
      resolve: {
        address: function address() {
          return $ctrl.fullAddress;
        }
      }
    });
  }
}