import angular from 'angular';

angular.module('AaApp.Opp').filter('serviceDriveOffersFilter', serviceDriveOffersFilter);

/* @ngInject */
function serviceDriveOffersFilter($filter, moment) {
    return function (rows, searchText, isIncludePrintedChecked, dateStart) {
        var out = [];

        angular.forEach(rows, function (value, key) {
            if (value.customerFullName.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 || (value.vin && value.vin.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)) {
                if (isIncludePrintedChecked) {
                    out.push(value);
                }
                    //else if (!value.lastPrintedDate || moment(value.lastPrintedDate).isBefore(moment().startOf('day'))) {
                else if (!value.lastPrintedDate || !moment(value.lastPrintedDate).isSame(dateStart, 'day')) {
                    out.push(value);
                }
            }
        });

        return out;
    }
}
