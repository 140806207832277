import angular from 'angular'; 
import moment from 'moment';

export default 'moment';

(function () {
    'use strict';

    angular.module('moment', [])
        .factory('moment', function () {
            return moment;
        });
})();
