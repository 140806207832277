import { Component, Input } from '@angular/core';
import { AlertTypeDescriptors } from '../../../constants';

@Component({
    selector: 'alert',
    templateUrl: './alert.component-ng.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

    @Input() alertTypeId: string;
    @Input() size: string;

    private get typeDescriptor() {
        if (this.alertTypeId == null) return;

        return AlertTypeDescriptors.find(item => item.id === this.alertTypeId.toString());
    }

    get typeDescriptorTranslateKey() {
        return this.typeDescriptor && this.typeDescriptor.translateKey || "";
    }

    get characterClassNames() {
        switch (this.size) {
            case "small":
                return "fa alerts-letterblock-sm";
            default:
                return "fa alerts-letterblock";
        }
    }

    get alertClassNames() {
        if (!this.typeDescriptor) return;

        switch (this.size) {
            case "small":
                return `fa ${this.typeDescriptor.cssClass} ${this.typeDescriptor.cssClass}-sm`;
            default:
                return `fa ${this.typeDescriptor.cssClass}`;
        }
    }
}
