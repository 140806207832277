"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var models_1 = require("../generated/models");
var services_1 = require("../generated/services");
var AdminRoleService = /** @class */ (function () {
    function AdminRoleService(legacyService, roleClientService) {
        this.legacyService = legacyService;
        this.roleClientService = roleClientService;
    }
    AdminRoleService.prototype.GetRoleOptions = function () {
        return this.roleClientService.GetRoleOptionsGET().toPromise();
    };
    AdminRoleService.prototype.GetRoleModuleAccess = function (roleId) {
        return this.roleClientService.GetRoleModuleAccessByRoleidGET(roleId).toPromise();
    };
    AdminRoleService.prototype.GetRoleAccessRights = function (roleId) {
        return this.roleClientService.GetRoleAccessRightsByRoleidGET(roleId).toPromise();
    };
    AdminRoleService.prototype.GetRoleAccessRightCategories = function () {
        return this.roleClientService.GetRoleAccessRightCategoriesGET().toPromise();
    };
    AdminRoleService.prototype.GetRoleModuleCategories = function (roleId) {
        return this.roleClientService.GetRoleModuleCategoriesByRoleidGET(roleId).toPromise();
    };
    AdminRoleService.prototype.RoleAccessUpdate = function (roleId, moduleIdList, accessRightIdList) {
        return this.roleClientService.RoleAccessUpdateByRoleidGET({ roleId: roleId, moduleIdList: moduleIdList, accessRightIdList: accessRightIdList }).toPromise();
    };
    AdminRoleService.prototype.RoleSave = function (role) {
        return this.roleClientService.AddEditRolePOST(role).toPromise();
    };
    return AdminRoleService;
}());
exports.AdminRoleService = AdminRoleService;
