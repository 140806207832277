
/* injects from baggage-loader */
require('./replacement-contracts.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('replacementContracts', {
    templateUrl: '/app/components/deal-sheet/contract/replacement-contracts.component.html',
    controller: ReplacementContractDetailsController,
    bindings: {
        countryId: '<',
        opportunity: '<',
        availableContracts: '<',
        permissions: '<',
        replacementContract: '<',
        onSaleTypeChange: '&',
        onReplacementContractOverride: '&',
        showPullForward: '<',
        showMobileView: '<'
    }
});

/* @ngInject */
function ReplacementContractDetailsController(_, $filter, saleTypes, opportunityService, environmentVariables, opportunity2Service) {
    var $ctrl = this;

    var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;

    $ctrl.contracts;
    $ctrl.selectedContracts;
    $ctrl.availableSaleTypes;
    $ctrl.selectedSaleTypes;
    $ctrl.saleTypes = saleTypes;
    $ctrl.saleTypeTranslateKeys;
    $ctrl.setSelectedSaleTypes = setSelectedSaleTypes;
    $ctrl.setSelectedContract = setSelectedContract;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onInit = onInit;
    $ctrl.setOverrideContract = setOverrideContract;
    $ctrl.showMobileView = false;

    function onInit() {
        $ctrl.saleTypeTranslateKeys = _.invert(saleTypes);
    }

    function onChanges(changes) {
        if (changes.opportunity && changes.opportunity.currentValue) {
            opportunityService.addAlertsAsProperties($ctrl, $ctrl.opportunity.alerts);
        }

        if (changes.availableContracts && changes.availableContracts.currentValue) {
            setSelectedSaleTypes()
            $ctrl.availableSaleTypes = getAvailableSaleTypes($ctrl.availableContracts);
            $ctrl.banks = getBanks($ctrl.availableContracts);
            $ctrl.hasMultipleBanks = $ctrl.banks.length > 1;

            loadContracts();
            var term = $ctrl.opportunity.replacementContract && $ctrl.opportunity.replacementContract.term || undefined;
            $ctrl.selectedContracts = getDefaultContracts($ctrl.availableContracts, $ctrl.banks, $ctrl.availableSaleTypes, term);
        }
    }

    function setFilteredContracts(contracts, saleType, bankName, bankID) {
        if (!contracts)
            return;

        if (!$ctrl.hasMultipleBanks) {
            bankID = bankName = null;
        }
    }

    function setSelectedContract(bank, contract) {
        if (!$ctrl.showMobileView && bank && contract) {
            if ($ctrl.selectedContracts[bank.id] == null) {
                $ctrl.selectedContracts[bank.id] = {};
            }

            $ctrl.selectedContracts[bank.id][contract.saleType] = contract;
        }
    }

    function setOverrideContract(contract) {
        contract.useOverrideTerm = true;
        $ctrl.onReplacementContractOverride({ contract: contract });
    }

    function setSelectedSaleTypes(saleTypes) {
        if (saleTypes) {
            $ctrl.selectedSaleTypes = saleTypes.sort(bySaleType);
        }
        else {
            if ($ctrl.opportunity.replacementContract) {
                $ctrl.selectedSaleTypes = [$ctrl.opportunity.replacementContract.saleType];
            }
            else {
                var currentSaleType = $ctrl.opportunity.currentContract.saleType;
                if (currentSaleType) {
                    var currentSaleTypeContracts = $filter('filter')($ctrl.availableContracts, { saleType: currentSaleType });

                    if (currentSaleTypeContracts && currentSaleTypeContracts.length > 0) {
                        $ctrl.selectedSaleTypes = [currentSaleType];
                    }
                    else {
                        $ctrl.selectedSaleTypes = [$ctrl.saleTypes.retail];
                    }
                }
                else {
                    $ctrl.selectedSaleTypes = [$ctrl.saleTypes.retail];
                }
            }
        }

        $ctrl.onSaleTypeChange({ saleTypes: $ctrl.selectedSaleTypes });
        loadContracts();
    }

    function bySaleType(option1, option2) {
        if (option1 == $ctrl.saleTypes.retail) {
            return -1;
        }
        if (option2 == $ctrl.saleTypes.retail) {
            return 1;
        }

        return option1 - option2;

    }

    function getAvailableSaleTypes(contracts) {
        var availableSaleTypes = [];

        for (var i = 0; i < contracts.length; i++) {
            if (availableSaleTypes.indexOf(contracts[i].saleType) < 0) {
                availableSaleTypes.push(contracts[i].saleType);
            }
        }

        return availableSaleTypes;
    }

    function getBanks(contracts) {
        var uniqueBankContracts = $filter('unique')(contracts, 'bankID');
        var banks = [];

        if (!uniqueBankContracts || uniqueBankContracts.length == 0) {
            return [];
        }

        for (var i = 0; i < uniqueBankContracts.length; i++) {
            banks.push({
                id: uniqueBankContracts[i].bankID,
                name: uniqueBankContracts[i].bankName
            });
        }

        return banks;
    }

    function getDefaultContracts(contracts, banks, availableSaleTypes, term) {
        var defaultContracts = {};

        for (var i = 0; i < banks.length; i++) {
            for (var j = 0; j < availableSaleTypes.length; j++) {
                var defaultContract = $filter('filter')(contracts, { saleType: availableSaleTypes[j], term: term, bankID: banks[i].id });

                if (defaultContract && defaultContract.length > 0) {
                    if (defaultContracts[banks[i].id] == null) {
                        defaultContracts[banks[i].id] = {};
                    }

                    defaultContracts[banks[i].id][availableSaleTypes[j]] = defaultContract[0];
                }
            }
        }

        return defaultContracts;
    }

    function loadContracts() {
        if ($ctrl.selectedSaleTypes && $ctrl.selectedSaleTypes.length > 0) {
            $ctrl.contracts = getSynchronizedContracts($ctrl.availableContracts, $ctrl.selectedSaleTypes, $ctrl.banks);
        } else {
            $ctrl.contracts = $ctrl.availableContracts;
        }

        $ctrl.contractsByBankIdAndSaleType = groupByBankIdAndSaleType($ctrl.contracts)

        var bankNamesByBankId = $ctrl.banks ? _.fromPairs($ctrl.banks.map(function (b) { return [b.id, b.name] })) : {}
        _.forOwn($ctrl.contractsByBankIdAndSaleType, function (contractsBySaleType, bankID) {
            var bankName = bankNamesByBankId[bankID]
            _.forOwn(contractsBySaleType, function (contracts, saleType) {
                setFilteredContracts(contracts, saleType, bankName, bankID)
            })
        })
    }

    /**
     * Returns a nested dictionary to look up contracts
     * by bankID then by saleType. For example:
     * var groupedContracts = groupByBankIdAndSaleType(contracts)
     * var matchingContracts = groupedContracts[bankID][saleType]
    */
    function groupByBankIdAndSaleType(contracts) {
        var contractsByBankId = _.groupBy(contracts, function (c) { return c.bankID })

        return _.mapValues(contractsByBankId, function (cs) {
            return _.groupBy(cs, function (c) { return c.saleType })
        })
    }

    /**
     * Inserts dummy records into a set of contracts, so contracts with the
     * same term length display next to each other (36 month Retail is next
     * to 36 month Lease, etc.)
    */
    function getSynchronizedContracts(contracts, saleTypes, banks) {
        var synchronizedContracts = contracts.slice(0, contracts.length);

        synchronizedContracts.sort(bySaleTypeAndTermMonths);

        for (var i = 0; i < saleTypes.length; i++) {
            for (var j = i + 1; j < saleTypes.length; j++) {
                for (var k = 0; k < banks.length; k++) {
                    if (saleTypes[i] != saleTypes[j]) {
                        synchronizeContractTerms(synchronizedContracts, saleTypes[i], saleTypes[j], banks[k].id);
                    }
                }
            }
        }

        synchronizedContracts.sort(bySaleTypeAndTermMonths);

        return synchronizedContracts;
    }

    function synchronizeContractTerms(contracts, saleType1, saleType2, bankID) {
        var termsToInsert = [];
        var i = 0;
        var j = 0;

        var filteredContracts1 = $filter('filter')(contracts, { saleType: saleType1, bankID: bankID });
        var filteredContracts2 = $filter('filter')(contracts, { saleType: saleType2, bankID: bankID });

        while (filteredContracts1.length > 0 && filteredContracts2.length > 0 && (i < filteredContracts1.length || j < filteredContracts2.length)) {
            if (!filteredContracts1[i]) {
                if (filteredContracts2[j] && filteredContracts2[j].payment) {
                    termsToInsert.push({ bankID: filteredContracts1[0].bankID, saleType: filteredContracts1[0].saleType, term: filteredContracts2[j].term });
                }
                j++;
            }
            else if (!filteredContracts2[j]) {
                if (filteredContracts1[i] && filteredContracts1[i].payment) {
                    termsToInsert.push({ bankID: filteredContracts2[0].bankID, saleType: filteredContracts2[0].saleType, term: filteredContracts1[i].term });
                }
                i++;
            }
            else if (filteredContracts1[i].term > filteredContracts2[j].term) {
                if (filteredContracts2[j] && filteredContracts2[j].payment) {
                    termsToInsert.push({ bankID: filteredContracts1[0].bankID, saleType: filteredContracts1[0].saleType, term: filteredContracts2[j].term });
                }
                j++;
            }
            else if (filteredContracts1[i].term < filteredContracts2[j].term) {
                if (filteredContracts1[i] && filteredContracts1[i].payment) {
                    termsToInsert.push({ bankID: filteredContracts2[0].bankID, saleType: filteredContracts2[0].saleType, term: filteredContracts1[i].term });
                }
                i++;
            }
            else if (filteredContracts1[i].term == filteredContracts2[j].term) {
                i++;
                j++;
            }
        }

        for (let ix = 0; ix < termsToInsert.length; ix++) {
            var termToInsert = termsToInsert[ix];

            if (!termExists(contracts, termToInsert.bankID, termToInsert.saleType, termToInsert.term)) {
                contracts.push(termToInsert);
            }
        }
    }

    function termExists(contracts, bankID, saleType, term) {
        for (var i = 0; i < contracts.length; i++) {
            var contract = contracts[i];

            if (contract.bankID == bankID && contract.saleType == saleType && contract.term == term) {
                return true;
            }
        }

        return false;
    }

    function bySaleTypeAndTermMonths(contract1, contract2) {
        if (contract1.saleType == contract2.saleType) {
            return contract1.term - contract2.term;
        } else {
            return contract1.saleType - contract2.saleType;
        }
    }
}
