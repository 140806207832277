import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import _ from 'lodash';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ActivityActionEnum, ActivityResultDescriptors, ActivityResults, TranslateKeys } from '../../../../constants';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { LogActionShortcutService } from '../../../../services/log-action-shortcut.service';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ScheduleModalParam } from '../ScheduleModalParam.interface';

interface NoteObject {
    call: NoteType;
    note: NoteType;
    email: NoteType;
    cancelCallActivity: NoteType;
    cancelApptActivity: NoteType;
    completeCallActivity: NoteType;
    completeApptActivity: NoteType;
}

interface NoteType {
    activityId: number;
    description: string;
    resultType: number;
    resultLabelKey: string;
    isCallBoundTypeVisible: boolean;
}

interface Activity {
    entityID: string;
    activityActionID: number;
    notes: string;
    resultID: number;
    activityId: number;
    activityActionSubTypeID: number;
    isFollowUpScheduleAppt: boolean;
    uid: number;
}

@Component({
    selector: 'customer-connect-log-action',
    templateUrl: './customer-connect-log-action.component-ng.html',
    styleUrls: ['./customer-connect-log-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectLogActionComponent implements OnInit, OnChanges {
    @Input() subAction: number;
    @Input() permissions: DealSheetPermissions;
    @Input() dialogParams: ScheduleModalParam;
    @Input() isReset: boolean;
    @Input() isError: boolean;
    @Output() onLogScheduleSubmit = new EventEmitter<any>();
    @Input() isEuroLite: boolean;

    public notesType = this.subAction;

    isLogEmail = false;
    activity = null;
    errorMessage = '';

    // ActionResultSetID  mapped to setID of json AutoAlert/API/activity-results.json
    callActionResultSetID = 1;
    apptActionResultSetID = 2;
    noteActionResultSetID = 3;
    emailActionResultSetID = -1;

    currentNoteType: NoteType;
    translateKeys = TranslateKeys;
    activityActionEnum = ActivityActionEnum;

    // models for display
    noteObject = {
        call: {
            activityId: this.activityActionEnum.Log_Call,
            description: this.translateKeys.logCallTitleKey,
            resultType: this.callActionResultSetID,
            resultLabelKey: 'logCallResult',
            isCallBoundTypeVisible: true
        },
        note: {
            activityId: this.activityActionEnum.Post_Note,
            description: this.translateKeys.postNotesTitleKey,
            resultType: this.noteActionResultSetID,
            resultLabelKey: 'postNotesResult',
            isCallBoundTypeVisible: false
        },
        email: {
            activityId: this.activityActionEnum.Email_Sent,
            description: this.translateKeys.logEmailSentTitleKey,
            resultType: this.emailActionResultSetID,
            resultLabelKey: 'result',
            isCallBoundTypeVisible: false
        },
        cancelCallActivity: {
            activityId: this.activityActionEnum.Cancel_Call,
            description: this.translateKeys.cancelTitleCallKey,
            resultType: this.emailActionResultSetID,
            resultLabelKey: 'result',
            isCallBoundTypeVisible: false
        },
        cancelApptActivity: {
            activityId: this.activityActionEnum.Cancel_Appt,
            description: this.translateKeys.cancelTitleApptKey,
            resultType: this.emailActionResultSetID,
            resultLabelKey: 'result',
            isCallBoundTypeVisible: false
        },
        completeCallActivity: {
            activityId: this.activityActionEnum.Phone_Call_Completed,
            description: this.translateKeys.completeTitleCallKey,
            resultType: this.callActionResultSetID,
            resultLabelKey: 'logCallResult',
            isCallBoundTypeVisible: false
        },
        completeApptActivity: {
            activityId: this.activityActionEnum.Appointment_Completed,
            description: this.translateKeys.completeTitleApptKey,
            resultType: this.apptActionResultSetID,
            resultLabelKey: 'resultOfAppointment',
            isCallBoundTypeVisible: false
        }
    } as NoteObject;

    // model for UI
    activityResultsList = {};
    activityResultsListColumn1 = [];
    activityResultsListColumn2 = [];

    status = { opened: false };
    model = { notes: '', resultID: -1, callBoundType: null, setScheduleAppt: false };

    opportunityId: string;
    dealerId: number;
    dialogTitle: string;

    constructor(private logActionShortcutService: LogActionShortcutService,
        private bsModalService: BsModalService) {
    }

    ngOnInit() {
        this.logActionShortcutService.$logShortcutAction.subscribe((activityActionEnum) => {
            if (activityActionEnum) {
                this.notesType = activityActionEnum;
                this.handleNotesTypeChange();
            }
        });
    }

    // set default selection and get drop down list
    setActivityResults(): void {
        this.activityResultsList = ActivityResultDescriptors.filter((value) => value.setID === this.currentNoteType.resultType);
        this.activityResultsListColumn1 = ActivityResultDescriptors.filter((value) => {
            return value.setID === this.currentNoteType.resultType && value.column === 1;
        });
        this.activityResultsListColumn2 = ActivityResultDescriptors.filter((value) => {
            return value.setID === this.currentNoteType.resultType && value.column === 2;
        });

        if (this.permissions) {
            this.removeFromActivityResults(this.permissions.editMarkAsSold, 7);
            this.removeFromActivityResults(this.permissions.editMarkAsSold, 11);
            this.removeFromActivityResults(this.permissions.editMarkAsSold, 23);
            this.removeFromActivityResults(this.permissions.editMarkAsPaidOff, 33);
            this.removeFromActivityResults(this.permissions.editMarkAsNotOwn, 28);
            this.removeFromActivityResults(this.permissions.editMarkAsNotOwn, 31);
            this.removeFromActivityResults(this.isEuroLite && this.currentNoteType === this.noteObject.note, 29);
        }

        this.model.resultID = -1;
    }

    removeFromActivityResults(hasPermission: boolean, activityResultId: number): void {
        if (!hasPermission) {
            const index = _.findIndex(this.activityResultsListColumn1, (item) => item.activityResultID === activityResultId);
            if (index > -1) {
                this.activityResultsListColumn1.splice(index, 1);
            }
            const index2 = _.findIndex(this.activityResultsListColumn2, (item) => item.activityResultID === activityResultId);
            if (index2 > -1) {
                this.activityResultsListColumn2.splice(index2, 1);
            }
        }
    }

    onTypeOfCallSelect(data): void {
        this.model.callBoundType = data;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.isReset && !changes.isReset.isFirstChange()) {
            this.model = { notes: '', resultID: -1, callBoundType: null, setScheduleAppt: false };
            this.errorMessage = '';
        }

        if (changes.isError && !changes.isError.isFirstChange()) {
            this.errorMessage = 'postnotesResultErrorMsg';
        }

        if (this.subAction && this.dialogParams) {
            this.opportunityId = this.dialogParams.opportunityId;
            this.dealerId = this.dialogParams.dealerId;
            this.notesType = this.subAction;
            if (this.dialogParams.activityObject) {
                this.activity = this.dialogParams.activityObject;
            }
        }

        this.handleNotesTypeChange();
    }

    handleNotesTypeChange(): void {
        switch (this.notesType) {

            case ActivityActionEnum.Post_Note:

                this.currentNoteType = this.noteObject.note;
                this.dialogTitle = this.currentNoteType.description;
                this.setActivityResults();
                break;
            case ActivityActionEnum.Log_Call:

                this.currentNoteType = this.noteObject.call;
                this.dialogTitle = this.currentNoteType.description;
                this.setActivityResults();
                break;
            case ActivityActionEnum.Email_Sent:
                this.currentNoteType = this.noteObject.email;
                this.dialogTitle = this.currentNoteType.description;
                this.isLogEmail = true;
                break;
            case ActivityActionEnum.Cancel_Call:
                this.currentNoteType = this.noteObject.cancelCallActivity;
                this.dialogTitle = this.currentNoteType.description;
                this.isLogEmail = true;
                break;
            case ActivityActionEnum.Cancel_Appt:
                this.currentNoteType = this.noteObject.cancelApptActivity;
                this.dialogTitle = this.currentNoteType.description;
                this.isLogEmail = true;
                break;
            case ActivityActionEnum.Phone_Call_Completed:
                this.currentNoteType = this.noteObject.completeCallActivity;
                this.dialogTitle = this.currentNoteType.description;
                this.setActivityResults();
                break;
            case ActivityActionEnum.Appointment_Completed:
                this.currentNoteType = this.noteObject.completeApptActivity;
                this.dialogTitle = this.currentNoteType.description;
                this.setActivityResults();
                break;
        }
    }

    onLogActivityCompleteMarkAsSold(): void {
        const modal = this.bsModalService.show(ConfirmationModalComponent, {
            animated: true,
            initialState: {
                title: 'pleaseConfirm',
                message: 'euroliteMarkAsSoldVehiclePaidOffNoLongerOwnsPrompt', // translation key
                submitButtonText: 'confirm'
            }
        } as ModalOptions);

        (modal.content as ConfirmationModalComponent).onSubmit.subscribe(() => {
            this.onPostSubmit();
        });
    }

    onSubmit(): void {
        if (!this.isEuroLite) {
            this.onPostSubmit();
            return;
        } else {

            if (this.model.resultID === ActivityResults.phoneCall.soldVehicle
                || this.model.resultID === ActivityResults.note.soldVehicle
                || this.model.resultID === ActivityResults.phoneCall.noLongerOwns
                || this.model.resultID === ActivityResults.note.noLongerOwns
                || this.model.resultID === ActivityResults.phoneCall.vehiclePaidOff
                || this.model.resultID === ActivityResults.note.vehiclePaidOff) {
                this.onLogActivityCompleteMarkAsSold();
            } else {
                this.onPostSubmit();
            }
        }
    }

    onPostSubmit(): void {
        if (this.model.resultID <= -1) {
            switch (this.notesType) {
                case ActivityActionEnum.Post_Note:
                case ActivityActionEnum.Log_Call:
                case ActivityActionEnum.Phone_Call_Completed:
                case ActivityActionEnum.Appointment_Completed:
                    this.errorMessage = 'postnotesResultErrorMsg';
                    this.onLogScheduleSubmit.emit(null); // bubble event to parent
                    return;
            }
        }

        let recordId = null;
        if (this.activity) {
            recordId = this.activity.activityId;
        }

        let activity: Activity;
        // model to save
        if (this.notesType === ActivityActionEnum.Post_Note) {
            activity = {
                entityID: this.opportunityId,
                activityActionID: this.currentNoteType.activityId,
                notes: this.model.notes,
                resultID: this.model.resultID,
                activityId: recordId,
                isFollowUpScheduleAppt: this.model.setScheduleAppt
            } as Activity;
        } else if (this.notesType === ActivityActionEnum.Log_Call) {
            activity = {
                entityID: this.opportunityId,
                activityActionID: this.currentNoteType.activityId,
                notes: this.model.notes,
                resultID: this.model.resultID,
                activityId: recordId,
                activityActionSubTypeID: this.model.callBoundType,
                isFollowUpScheduleAppt: this.model.setScheduleAppt
            } as Activity;
        }

        if (!activity) {
            throw new Error('No activity to log, notesType not equal to Post_Note (1) or Log_Call (2)');
        }

        if (!activity.notes) {
            activity.notes = '';
        }

        if (this.dialogParams.uid) {
            activity.uid = this.dialogParams.uid;
        }

        if (activity.resultID === -1) {
            activity.resultID = null;
        }

        // fire event with activity that needs to be submitted
        this.onLogScheduleSubmit.emit(activity);
        this.resetUI();
    }

    onCancel(): void {
        if (this.dialogParams.uid) {
            this.onLogScheduleSubmit.emit({ uid: this.dialogParams.uid, status: 'cancel' });
        } else {
            this.onLogScheduleSubmit.emit('cancel');
        }

        this.resetUI();
    }


    resetUI(): void {
        this.status = { opened: false };
        this.model = { notes: '', resultID: -1, callBoundType: this.model.callBoundType, setScheduleAppt: false };
        this.model.setScheduleAppt = false;
        this.errorMessage = '';
    }

}
