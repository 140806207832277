
import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpHandler, HttpRequest, HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NgNoCacheInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newHeaders = {};

        if (!req.headers.has('Cache-Control') && !req.headers.has('Pragma')) {
            newHeaders['Cache-Control'] = 'no-cache';
            newHeaders['Pragma'] = 'no-cache';

            const newReq = req.clone({ setHeaders: newHeaders });
            return next.handle(newReq);
        }

        return next.handle(req);
    }
}
