<div class="admin-templates-wrapper">
    <div class="row">
        <div class="col-md-6">
            <div class="page-title" translate="templates"></div>
        </div>
        <div class="col-md-6" *ngIf="dealers.length > 1">
            <div class="float-right">
                <div class="title-dealer" translate="dealerships"></div>
                <div class="dealer-select">
                    <select id="dealer-select" class="form-control"
                            [(ngModel)]="selectedDealerId"
                            (change)="onDealerSelect()">
                        <option *ngFor="let dealer of dealers" [ngValue]="dealer.id">
                            {{dealer.name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="divider"></div>
    <div class="admin-templates">
        <tabset>
            <tab heading="{{'list' | translate }}" [active]="tab1" (selectTab)="tab1 = true" (deselect)="tab1 = false">
                <div [ngBusy]="{busy: busy, delay: 200}">

                    <button type="button" class="btn btn-primary create-new-button"
                            (click)="onCreateNewTemplate()">
                        {{ 'createNew' | translate }}
                    </button>

                    <kendo-grid #grid [sortable]="true" [height]="300" [pageSize]="pageSize" (pageChange)="pageChange($event)" [skip]="skip"
                                [filterable]="false" [data]="gridView" [pageable]="true" (sortChange)="sortChange($event)" [sort]="sort">
                        <kendo-grid-column field="templateName" title="{{ 'name' | translate }}">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <a (click)="editTemplate(dataItem.id)">{{dataItem.templateName}}</a>
                                <span *ngIf="dataItem.isActiveInLeadManagement" title="{{'isActiveInLeadManagementToolTip' | translate }}"  class="auto-assignment-svg"></span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="{{ 'preview' | translate }}" width="140">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="preview-links">
                                    <a (click)="pdfPreviewTemplate(dataItem.id)" title="{{ 'PDFPreview' | translate }}">
                                        <img class="pdf-preview-icon" src="https://inbox.autoalert.com/static/assets/img/fileformaticons/pdf.png" />
                                    </a>
                                    <a (click)="emailPreviewTemplate(dataItem.id)" title="{{ 'emailPreview' | translate }}">
                                        <span class="email-preview-icon"></span>
                                    </a>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="createDate" title="{{ 'createDate' | translate }}" width="240">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <span>{{dataItem.createDate | localeDate}}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="createdByFullName" title="{{ 'createBy' | translate }}" width="240">
                        </kendo-grid-column>
                        <kendo-grid-column width="45">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <a (click)="deleteTemplate(dataItem.id)" *ngIf="!dataItem.isSystemTemplate" title="{{ 'deleteTemplate' | translate }}">
                                    <span class="trash-can-icon"></span>
                                </a>
                            </ng-template>
                        </kendo-grid-column>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>                            
                            <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>                            
                            <kendo-pager-next-buttons></kendo-pager-next-buttons>                            
                            <kendo-pager-info></kendo-pager-info>
                        </ng-template>
                    </kendo-grid>
                </div>
            </tab>
            <tab heading="{{'upgradeProposal' | translate }}" [active]="tab2" (selectTab)="tab2 = true" (deselect)="tab2 = false">
                <div [ngBusy]="{busy: busy3, delay: 200}">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="title-marketing-message" translate="marketingMessage"></div>
                            <textarea id="marketingMessageText" class="form-control" placeholder="{{'goalMarketingMessage' | translate}}"
                                      maxlength="255" [cols]="60" [rows]="5" [(ngModel)]="marketingMessageTextValue"></textarea>
                            <span style="color:red" *ngIf="marketingMessageTextValue?.length == 255">
                                {{ 'warningMessageMaxChars255' | translate }}
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6" id="characters-remaining">
                            <span>
                                {{calculateLength()}} {{'charactersLeft' | translate }}
                            </span>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-6">
                            <input id="add-confidential-cover-page"
                                   type="checkbox"
                                   [disabled]="readOnly"
                                   [checked]="addConfidentialCoverPage"
                                   (change)="addConfidentialCoverPage = !addConfidentialCoverPage" />
                            <label for="add-confidential-cover-page" class="field-label add-confidential-cover-page">
                                {{'addConfidentialCoverPageUpgradeProposal' | translate}}
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2">
                            <button type="button" class="btn btn-primary"
                                    (click)="onSaveMarketingMessage(marketingMessageTextValue)">
                                <span translate="saveChanges"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="{{'SMTPSettings' | translate }}" [active]="tab3" (selectTab)="tab3 = true" (deselect)="tab3 = false">
                <div [ngBusy]="{busy: busy2, delay: 200}">
                    <form [formGroup]="SMTPForm">
                        <div class="row col-sm-12">
                            <div class="section-title" translate="smtpSettingTitle"></div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2 smtp-type-selection-radio">
                                <input type="radio" name="smtptypeselection" [value]="1" id="providerEmailServerRadio" [checked]="type == getSMTPSettingEnum.autoAlert"
                                       (click)="selectType(getSMTPSettingEnum.autoAlert)" />
                                <label for="providerEmailServerRadio" class="label-ratio-options">{{'providerEmailServer' | translate}}</label>
                            </div>
                            <div class="col-sm-2 smtp-type-selection-radio">
                                <input type="radio" name="smtptypeselection" [value]="2" id="customEmailServerRadio" [checked]="type == getSMTPSettingEnum.custom"
                                       (click)="selectType(getSMTPSettingEnum.custom)" />
                                <label for="customEmailServerRadio" class="label-ratio-options">{{'customEmailServer' | translate}}</label>
                            </div>
                            <div class="col-sm-2 smtp-type-selection-radio">
                                <input type="radio" name="smtptypeselection" [value]="0" id="noEmailServerRadio" [checked]="type == getSMTPSettingEnum.none"
                                       (click)="selectType(getSMTPSettingEnum.none)" />
                                <label for="noEmailServerRadio" class="label-ratio-options">{{'noEmailServer' | translate }}</label>
                            </div>
                        </div>

                        <div *ngIf="type == getSMTPSettingEnum.custom">
                            <br />
                            <div class="row smtp-type-row">
                                <div class="col-sm-1"><label for="SMTPHost" class="label-smtp" translate="SMTPHost"></label></div>
                                <div class="col-sm-2">
                                    <input type="text" id="SMTPHost" class="form-control SMTPHost" name="SMTPHost" formControlName="SMTPHost" />
                                </div>
                                <div class="col-sm-2">
                                    <div *ngIf="submitted && SMTPForm.controls.SMTPHost.errors" class="error">
                                        <div *ngIf="SMTPForm.controls.SMTPHost.errors.required">{{'errorSMTPHostInvalid' | translate}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row smtp-type-row">
                                <div class="col-sm-1"><label for="port" class="label-smtp" translate="port"></label></div>
                                <div class="col-sm-2">
                                    <input type="text" id="port" class="form-control port" style="width: 70px" name="port" formControlName="port" />
                                </div>
                                <div class="col-sm-2">
                                    <div *ngIf="submitted && SMTPForm.controls.port.errors" class="error">
                                        <div *ngIf="SMTPForm.controls.port.errors.required">{{'errorPortInvalid' | translate}}</div>
                                        <div *ngIf="SMTPForm.controls.port.errors.maxLength">maxLengthIssue</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row use-ssl-row smtp-type-row">
                                <div class="col-sm-1"><label for="useSSL" class="label-smtp" translate="useSSL"></label></div>
                                <div class="col-sm-2">
                                    <input type="checkbox" id="useSSL" name="useSSL" class="use-ssl-checkbox" formControlName="useSSL" />
                                </div>
                            </div>

                            <div class="row smtp-type-row">
                                <div class="col-sm-1"><label for="username" class="label-smtp" translate="username"></label></div>
                                <div class="col-sm-2">
                                    <input type="text" id="username" class="form-control username" name="username" formControlName="username" />
                                </div>
                                <div class="col-sm-2">
                                    <div *ngIf="submitted && SMTPForm.controls.username.errors" class="error">
                                        <div *ngIf="SMTPForm.controls.username.errors.required">{{'errorUsernameInvalid' | translate}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row smtp-type-row">
                                <div class="col-sm-1"><label for="password" class="label-smtp" translate="password"></label></div>
                                <div class="col-sm-2">
                                    <input type="password" id="password" class="form-control password" name="password" formControlName="password" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="port" class="label-smtp" translate="smtpPasswordMessage"></label>
                                </div>
                            </div>

                        </div>
                        <br />
                        <div class="row">
                            <div class="col-sm-2">
                                <button type="submit" class="btn btn-primary"
                                        (click)="onSaveSMTPSettings()">
                                    <span translate="saveSMTPSettings"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </tab>
        </tabset>
    </div>
</div>
