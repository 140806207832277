import angular from 'angular';

angular.module('AaApp.Opp').factory('urlHelper', urlHelper);

function urlHelper() {
    var service = {
        queryString: queryString
    }

    return service;

    function queryString(query) {
        // revised from here: http://stackoverflow.com/a/979995/2506135
        var queryPart = query.slice(query.indexOf('?') + 1, query.length);
        var query_string = {};
        var vars = queryPart.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = decodeURIComponent(pair[1]);
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }
        return query_string;
    }
}
