import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Transition } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { ActivityActionEnum, ActivityResults } from '../../../../constants';
import { ToasterLoggerService } from '../../../../core/toaster-logger.service';
import { CustomerConnectActivityResult } from '../../../../interfaces/deal-sheet/customer-connect-activity-result.interface';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { UserDtoExt } from '../../../../interfaces/user-dto-ext.interface';
import { ActivityService } from '../../../../services/activity.service';
import { CustomerService } from '../../../../services/customer.service';
import { MobileDealSheetDataCachingService } from '../../../../services/mobile-deal-sheet-data-caching.service';

@Component({
    selector: 'mobile-customer-connect',
    templateUrl: './mobile-customer-connect.component-ng.html',
    styleUrls: ['./mobile-customer-connect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileCustomerConnectComponent implements OnInit, OnDestroy {
    opportunitySubscription: Subscription;
    usersSubscription: Subscription;
    opportunity: DealSheetOpportunity;
    permissions: DealSheetPermissions;
    private opportunityId: string;
    public activityActionEnum = ActivityActionEnum;
    public defaultCustomerConnectAction = this.activityActionEnum.Log_Call;
    users: UserDtoExt[];
    public busy: Promise<void>;
    public isCustomerConnectOpen = true;

    constructor(private activityService: ActivityService,
        private toasterLoggerService: ToasterLoggerService,
        private customerService: CustomerService,
        private mobileDealSheetDataCachingService: MobileDealSheetDataCachingService,
        private transition: Transition,
        private changeDetectorRef: ChangeDetectorRef) {
        this.opportunityId = this.transition.params().opportunityId;
    }

    ngOnInit(): void {
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(dealSheetData => {
            this.opportunity = dealSheetData.opportunity;
            this.permissions = dealSheetData.permissions;
            this.usersSubscription = this.mobileDealSheetDataCachingService.getUsers(dealSheetData.opportunity.dealerId)
                .subscribe(users => {
                    this.users = users;
                    // users is passed down through to the upgraded angularjs components and the change is not picked up there.
                    // This can probably be removed once those components are Angular 8
                    this.changeDetectorRef.detectChanges();
                });
        });
    }

    public onLogScheduleSubmit(result: CustomerConnectActivityResult | 'cancel') {
        if (!result) { return; } // user data entry error

        if (result === 'cancel') {
            return;
        }

        this.isCustomerConnectOpen = false;
        this.busy = this.activityService.postActivitySubmitAction(result)
            .then((data) => {

                if (result.isFollowUpScheduleAppt) {
                    this.toasterLoggerService.success('postActivityFollowupMsg');
                    this.defaultCustomerConnectAction = this.activityActionEnum.Schedule_Appt;

                } else {
                    this.toasterLoggerService.success('postActivitySuccessMsg');
                }

                // if vehicle no longer owns
                if (result.resultID === ActivityResults.phoneCall.noLongerOwns || result.resultID === ActivityResults.note.noLongerOwns) {
                    return this.activityService.updateCustomerDoesNotOwnVehicle(this.opportunity.id).then(() => {

                    });
                }

                // if vehicle paid off
                if (result.resultID === ActivityResults.phoneCall.vehiclePaidOff) {
                    return this.activityService.updateVehiclePaidOff(this.opportunity.id)
                        .then(() => {

                        });
                }

                this.isCustomerConnectOpen = true;
                return this.refreshActivities();
            });
    }

    public updateCustomer(customer: any): void {
        this.busy = this.customerService.updateCustomer(customer).then((success) => {
            if (success) {
                this.mobileDealSheetDataCachingService.clearDealSheetData();
            }
        });
    }

    private refreshActivities(): void {
        this.mobileDealSheetDataCachingService.clearActivities();
    }

    ngOnDestroy(): void {
        this.opportunitySubscription.unsubscribe();
        this.usersSubscription.unsubscribe();
    }
}
