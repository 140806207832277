PushToCRMController.$inject = ["$filter", "$translate", "$translatePartialLoader", "ActivityActionEnum", "ActivityResultCodeEnum", "activityService", "localePaths", "exception", "logger", "translateErrorKeys"];

/* injects from baggage-loader */
require('./push-to-crm.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('pushToCrm', {
  templateUrl: '/app/components/deal-sheet/modals/push-to-crm.component.html',
  controller: PushToCRMController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function PushToCRMController($filter, $translate, $translatePartialLoader, ActivityActionEnum, ActivityResultCodeEnum, activityService, localePaths, exception, logger, translateErrorKeys) {
  var $ctrl = this;
  $translatePartialLoader.addPart(localePaths.error);

  $ctrl.submit = function () {
    postToCrm();
  };

  $ctrl.cancel = function () {
    $ctrl.dismiss({
      $value: 'cancel'
    });
  };

  function closeModal() {
    $ctrl.close({
      $value: 'submit'
    });
  }

  function postToCrm() {
    var activity = {
      EntityID: $ctrl.resolve.opportunityId,
      ActivityActionID: ActivityActionEnum.Manual_Push_To_CRM,
      Notes: $ctrl.model ? $ctrl.model.notes : null
    };
    $ctrl.postSubmitActionPromise = activityService.postActivitySubmitAction(activity).then(setSubmitActionResult);
  }

  function setSubmitActionResult(result) {
    if (result.resultCode == ActivityResultCodeEnum.Push_To_Crm_Success) {
      $translate('PushToCrmSuccessMessage').then(function (successMessage) {
        logger.success(successMessage);
      });
      closeModal();
    } else if (result.resultCode == ActivityResultCodeEnum.Push_To_CRM_Fail_Permission) {
      $ctrl.isErrorNoPermission = true;
    } else if (result.resultCode == ActivityResultCodeEnum.Push_To_CRM_Fail_Delivery) {
      $ctrl.duplicationControlInDays = result.responseData.duplicationControlInDays;
      $ctrl.lastSentDate = $filter('localeDate')(result.responseData.lastSentDate);
      $ctrl.isErrorSentRecently = true;
    } else {
      $translate(translateErrorKeys.generalErrorMessageKey).then(function (translation) {
        exception.catcher(translation)(result);
      });
    }
  }
}