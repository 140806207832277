"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./polyfills");
var angular = __importStar(require("angular"));
var static_1 = require("@angular/upgrade/static");
static_1.setAngularJSGlobal(angular);
require("zone.js");
require("reflect-metadata");
var core_1 = require("@uirouter/core");
var core_2 = require("@angular/core");
var constants_1 = require("./constants");
var __NgCli_bootstrap_1 = __importStar(require("./app.module.ngfactory"));
var __NgCli_bootstrap_2 = __importStar(require("@angular/platform-browser"));
// Using AngularJS config block, call `deferIntercept()`.
// This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
angular.module('AaApp.Opp').config(['$urlServiceProvider', function ($urlService) { return $urlService.deferIntercept(); }]);
if (constants_1.Settings.environmentVariables.angularProdMode) {
    core_2.enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (platformRef) {
    // Intialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    var urlService = platformRef.injector.get(core_1.UIRouter).urlService;
    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
        urlService.listen();
        urlService.sync();
    }
    platformRef.injector.get(core_2.NgZone).run(startUIRouter);
});
