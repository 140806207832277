"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DisplayView;
(function (DisplayView) {
    DisplayView[DisplayView["initialView"] = 1] = "initialView";
    DisplayView[DisplayView["customerSelectView"] = 2] = "customerSelectView";
    DisplayView[DisplayView["customerPushedView"] = 3] = "customerPushedView";
    DisplayView[DisplayView["dealPushedView"] = 4] = "dealPushedView";
    DisplayView[DisplayView["errorView"] = 5] = "errorView";
    DisplayView[DisplayView["waitingView"] = 6] = "waitingView";
})(DisplayView = exports.DisplayView || (exports.DisplayView = {}));
var RequestType;
(function (RequestType) {
    RequestType[RequestType["customer"] = 0] = "customer";
    RequestType[RequestType["fandI"] = 1] = "fandI";
})(RequestType = exports.RequestType || (exports.RequestType = {}));
var DmsSaleType;
(function (DmsSaleType) {
    DmsSaleType[DmsSaleType["cash"] = 0] = "cash";
    DmsSaleType[DmsSaleType["lease"] = 1] = "lease";
    DmsSaleType[DmsSaleType["retail"] = 2] = "retail";
})(DmsSaleType = exports.DmsSaleType || (exports.DmsSaleType = {}));
