import moment from 'moment-timezone';

/**
 * @module datetime
 *
 * Provides helper functions for manipulating dates.
 *
 * It would be better to use moment.js than use these helpers. These helpers
 * are useful in areas we are forced to work with the native Date object.
 *
 * To avoid ambiguity, the nomenclature used here matches NodaTime's types:
 * http://nodatime.org/2.2.x/userguide/core-types
 *
 * Definitions:
 *
 *   - Local time:
 *      The date+time as a wall clock might display, but with no offset or time
 *      zone information.
 *      For example, "Jan 9, 2007 at 9:41 AM"
 *
 *   - Offset:
 *      The difference between UTC and local time, in hours+minutes. An offset
 *      is a fixed duration.
 *      For example, -07:00
 *
 *   - Time zone:
 *      A mapping of offsets for different times of the year.
 *      For example, "Pacific time" has offsets of -08:00 in standard time and
 *      -07:00 in daylight saving time
*/

/**
 * Returns a JavaScript {Date} object from a UTC date string.
 *
 * @example
 * let d = "2017-03-20T15:00:00Z";
 * fromUtcString(d);                // Mon Mar 20 2017 08:00:00 GMT-0700 (Pacific Daylight Time)
*/
export function dateFromUtcString(dateString) {
    return moment.utc(dateString).local().toDate();
}

/**
 * Returns a JavaScript {Date} object from a local date string.
 *
 * @example
 * let d = "2017-03-20T15:00:00";
 * dateFromLocalString(d);          // Mon Mar 20 2017 15:00:00 GMT-0700 (Pacific Daylight Time)
 */
export function dateFromLocalString(dateString) {
    return moment(dateString).toDate();
}


/**
 * Calculates a Date representing the instant when UTC had the same local time as the given Date.
 * @example
 * var d = new Date(2015, 03, 20, 12, 00, 00);  // 2015-03-20 12:00 -07:00
 * toSameLocalTimeInUTC(d);                     // 2015-03-20 05:00 -07:00 (2015-03-20 12:00 UTC)
*/
export function toSameLocalTimeInUTC(date) {
    const utcLocalTimestamp = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds());
    return new Date(utcLocalTimestamp);
}

/**
 * Calculates a Date when local time matched the UTC local time of the given Date.
 * @example
 * var d = new Date("2015-03-20T12:00:00Z");    // 2015-03-20 05:00 -07:00
 * toSameLocalTimeInCurrentZone(d);             // 2015-03-20 12:00 -07:00
*/
export function toSameLocalTimeInCurrentZone(date) {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds());
}

/**
 * Returns a new Date object representing the same local date at midnight.
*/
export function atMidnight(date) {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate());
}
