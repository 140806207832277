<div class="modal-wrapper nlo-info-wrapper">
        <div class="modal-header">
            <div class="nlo-title">
                <h5 class="modal-title" translate="nloModalTitle"></h5>
            </div>
            <div class="nlo-float-right">
                <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                    <icon class="icon aa-icon-close-icon"></icon>
                </button>
            </div>
        </div>
        <div class="modal-body form-horizontal" [ngBusy]="{busy: busy, delay: 200}">

            <div class="row intro">
                <div class="col-sm-12" translate="nloModalHeader">
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-12">

                    <table class="table">
                        <thead>
                            <tr>
                                <th translate="source"></th>
                                <th translate="nloMessage"></th>
                                <th translate="date"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of nloData">
                                <td>{{row.communicationSource}}</td>
                                <td>{{row.communicationText}}</td>
                                <td>{{row.insertDateUtc | localeDateTime}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary cancelBtn" (click)="cancelModal()" translate="cancel"></button>
            <button type="button" class="btn btn-primary" (click)="markAsNLO(false)" translate="nlo_Decline"></button>
            <button type="button" class="btn btn-primary" (click)="markAsNLO(true)" translate="nlo_Mark"></button>
        </div>
</div>