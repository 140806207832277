
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'mileage-penalty',
    templateUrl: './mileage-penalty.component-ng.html',
    styleUrls: ['./mileage-penalty.component.scss']
})
export class MileagePenaltyComponent implements OnInit {
    @Input() current: number;
    @Input() projected: number;
    @Input() alertExists: boolean;

    constructor() {}

    ngOnInit() {}
}
