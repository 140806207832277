"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./carfaxv2.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@uirouter/angular/lib/directives/uiSref"));
var i3 = __importStar(require("@uirouter/core"));
var i4 = __importStar(require("ngx-bootstrap/popover"));
var i5 = __importStar(require("ngx-bootstrap/component-loader"));
var i6 = __importStar(require("ngx-bootstrap/positioning"));
var i7 = __importStar(require("@ngx-translate/core"));
var i8 = __importStar(require("@angular/common"));
var i9 = __importStar(require("./carfaxv2.component"));
var i10 = __importStar(require("../../../../ajs-upgraded-providers"));
var styles_Carfaxv2Component = [i0.styles];
var RenderType_Carfaxv2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Carfaxv2Component, data: {} });
exports.RenderType_Carfaxv2Component = RenderType_Carfaxv2Component;
function View_Carfaxv2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../../images/carfax.png"]], null, null, null, null, null))], null, null); }
function View_Carfaxv2Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "a", [["class", "qa-carfax-link"], ["href", "javascript:void(0)"], ["placement", "top"], ["target", "_blank"], ["triggers", "mouseenter"], ["uiSref", "carfaxAuthorize"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { dealerId: 0, entityId: 1, vin: 2 }), i1.ɵdid(4, 212992, null, 0, i4.PopoverDirective, [i4.PopoverConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i5.ComponentLoaderFactory, i6.PositioningService], { popover: [0, "popover"], placement: [1, "placement"], outsideClick: [2, "outsideClick"], triggers: [3, "triggers"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "../../../images/carfax.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "carfaxAuthorize"; var currVal_1 = _ck(_v, 3, 0, _co.dealerId, _co.opportunityId, _co.vin); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 4); var currVal_3 = "top"; var currVal_4 = true; var currVal_5 = "mouseenter"; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_Carfaxv2Component_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "carFaxTooltip"], ["translate", "carfax_logout_tooltip_a"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "p", [["class", "carFaxTooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["translate", "carfax_logout_tooltip_b"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "#"], ["translate", "signOut"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["translate", "carfax_logout_tooltip_c"]], null, null, null, null, null)), i1.ɵdid(11, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "carfax_logout_tooltip_a"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "carfax_logout_tooltip_b"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "signOut"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "carfax_logout_tooltip_c"; _ck(_v, 11, 0, currVal_3); }, null); }
function View_Carfaxv2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_Carfaxv2Component_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Carfaxv2Component_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["popTemplate", 2]], null, 0, null, View_Carfaxv2Component_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showCarFaxLink && _co.readonly); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showCarFaxLink && !_co.readonly); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_Carfaxv2Component_0 = View_Carfaxv2Component_0;
function View_Carfaxv2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "carfaxv2", [], null, null, null, View_Carfaxv2Component_0, RenderType_Carfaxv2Component)), i1.ɵdid(1, 638976, null, 0, i9.Carfaxv2Component, [i10.VehicleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_Carfaxv2Component_Host_0 = View_Carfaxv2Component_Host_0;
var Carfaxv2ComponentNgFactory = i1.ɵccf("carfaxv2", i9.Carfaxv2Component, View_Carfaxv2Component_Host_0, { permissions: "permissions", dealerId: "dealerId", dealerSettings: "dealerSettings", opportunityId: "opportunityId", vin: "vin", readonly: "readonly", isLoggedIn: "isLoggedIn" }, {}, []);
exports.Carfaxv2ComponentNgFactory = Carfaxv2ComponentNgFactory;
