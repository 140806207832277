<div class="row">
    <div class="col-md-12">
        <div class="settingTitle">
            <div style="float:left">
                {{ 'dsAdmin_dealershipInfo' | translate }}
                <span *ngIf="dsa.dealerMakeFranchiseInfo.length > 0" translate="dsAdmin_byMake">
                </span>
            </div>
            <div style="float:left;margin-left:5px" *ngIf="dsa.dealerMakeFranchiseInfo.length > 0">
                <select class="form-control" [(ngModel)]="selectedMakeId" name="dealershipInfo_DealerMake" style="width:200px" (change)="makeChanged()">
                    <option value="0" selected>Default</option>
                    <option *ngFor="let item of dsa.dealerMakeFranchiseInfo" value="{{ item.makeID }}">{{ item.makeName }}</option>
                </select>
            </div>
        </div>
        <tooltip *ngIf="dsa.dealerMakeFranchiseInfo.length == 0" [titleKey]="'dsAdmin_dealershipInfo'" [contentKey]="'dsAdmin_dealershipInfoTooltip'"></tooltip>
        <tooltip *ngIf="dsa.dealerMakeFranchiseInfo.length > 0" [titleKey]="'dsAdmin_dealershipInfoByMake'" [contentKey]="'dsAdmin_dealershipInfoByMakeTooltip'"></tooltip>
        <div *ngIf="selectedMake() != null" class="row settingItem" style="padding-top:20px">
            <div class="col-md-3" translate="dsAdmin_dealershipName">
            </div>
            <div class="col-md-3">
                {{ selectedMake().dealerName }}
            </div>
        </div>
        <div *ngIf="selectedMakeId == 0">
            <div class="row settingItem" style="padding-top:10px">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerTimeZone">
                </div>
                <div class="col-md-3">
                    <select name="DealerContactInfo.TimeZoneID" class="form-control" [(ngModel)]="dsa.dealerContactInfo.timeZoneID">
                        <option value=""></option>
                        <option *ngFor="let item of dsa.timeZone" value="{{ item.timeZoneId }}">{{ item.timeZoneInfo.DisplayName }}</option>
                    </select>
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerAddress">
                </div>
                <div class="col-md-3">
                    <input type="text" name="DealerContactInfo.DealerAddress" [(ngModel)]="dsa.dealerContactInfo.dealerAddress" class="input-sm textbox" style="width:200px" />
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerAddressLine2">
                </div>
                <div class="col-md-3">
                    <input type="text" name="DealerContactInfo.DealerAddress2" [(ngModel)]="dsa.dealerContactInfo.dealerAddress2" class="input-sm textbox" style="width:200px" />
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerCity">
                </div>
                <div class="col-md-3">
                    <input type="text" name="DealerContactInfo.DealerCity" [(ngModel)]="dsa.dealerContactInfo.dealerCity" class="input-sm textbox" style="width:200px" />
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerState">
                </div>
                <div class="col-md-3">
                    <select name="DealerContactInfo.DealerState" class="form-control" [(ngModel)]="dsa.dealerContactInfo.dealerState" style="width:200px">
                        <option value="" translate="selectStateOption"></option>
                        <option *ngFor="let item of dsa.state" value="{{ item.stateAbbr }}">{{ item.stateAbbr }}-{{ item.stateName }}</option>
                    </select>
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerZipCode">
                </div>
                <div class="col-md-3">
                    <input name="DealerContactInfo.DealerZipCode" type="text" [(ngModel)]="dsa.dealerContactInfo.dealerZipCode" class="input-sm textbox" />
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_dealerWebSiteURL">
                </div>
                <div class="col-md-3">
                    <input name="DealerContactInfo.DealerURL" type="text" [(ngModel)]="dsa.dealerContactInfo.dealerURL" class="input-sm textbox" style="width:200px" />
                </div>
            </div>
        </div>
        <div *ngIf="address1" class="row settingItem">
            <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_primaryAddress">
            </div>
            <div class="col-md-3">
                <div>{{ address1.address1 }}</div>
                <div>{{ address1.address2 }}</div>
                <div>{{ address1.city }}, {{ address1.state }} {{ address1.zipCode }}</div>
                <div>{{ address1.brandUrl }}</div>
            </div>
        </div>
        <div *ngIf="address2" class="row settingItem">
            <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_address2">
            </div>
            <div class="col-md-3">
                <div>{{ address2.address1 }}</div>
                <div>{{ address2.address2 }}</div>
                <div>{{ address2.city }}, {{ address2.state }} {{ address2.zipCode }}</div>
                <div>{{ address2.brandUrl }}</div>
            </div>
        </div>
        <div [title]="dsa.isFordAlert ? ('dsAdmin_valueSetByFormProgram' | translate) : ''">
            <div class="row settingItem" *ngIf="selectedMakeId == 0">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_primaryLanguage"></div>
                <div class="col-md-3">
                    <customer-culture-select [isDisabled]="dsa.isFordAlert && !isEmployee" [(preferredCultureName)]="dsa.dealerSetting.defaultCultureName" [selectedDealerId]="dsa.dealerSetting.dealerID" (preferredCultureNameChange)="cultureChange($event)" [includeEmptyEntry]="false" #preferredCultureComponent></customer-culture-select>
                </div>
            </div>
            <div class="row settingItem" *ngIf="selectedMakeId == 0">
                <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_secondaryLanguage"></div>
                <div class="col-md-3">
                    <customer-culture-select [isDisabled]="dsa.isFordAlert && !isEmployee" [(preferredCultureName)]="dsa.dealerSetting.secondaryCultureName" [selectedDealerId]="dsa.dealerSetting.dealerID" (preferredCultureNameChange)="cultureChange($event)" [includeEmptyEntry]="true" #secondaryCultureComponent></customer-culture-select>
                </div>
                <div class="col-md-6">
                    <span *ngIf="!(dsa.isFordAlert && !isEmployee) && !customerCulturesDifferent" class="error" translate="customerPreferredLanguageError"></span>
                </div>
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-3" style="margin-top:5px">
                <div style="float:left;margin-top:10px" translate="dsAdmin_dealershipLogo"></div>
                <tooltip [titleKey]="'dsAdmin_dealershipLogo'" [contentKey]="'dsAdmin_dealershipLogoTooltip'"></tooltip>
            </div>

            <div class="col-md-3">
                <img *ngIf="dealerImageSrc" [src]="dealerImageSrc" style="width:400px;height:auto" />
                <span *ngIf="!dealerImageSrc" translate="dsAdmin_noImageAvailable"></span>
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-3" style="margin-top:5px">
            </div>
            <div class="col-md-3">
                <input style="display: none"
                       type="file" (change)="uploadFile($event)"
                       #fileInput>
                <button *ngIf="selectedMakeId == 0" class="btn btn-primary saveButton" (click)="fileInput.click()" translate="upload"></button>
                <button *ngIf="selectedMakeId == 0 && dealerImageSrc" class="btn btn-primary saveButton" style="margin-left:10px" (click)="deleteFile()" translate="delete"></button>
            </div>
        </div>
    </div>
</div>

