"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mobile-activities[_ngcontent-%COMP%]{margin-left:-6px;padding-right:6px;width:calc(100vw)}.activity-list[_ngcontent-%COMP%]{height:calc(100vh - 112px)}.activity-list[_ngcontent-%COMP%]     .activity-icon{padding-right:5px;padding-left:0;vertical-align:middle}.activity-list[_ngcontent-%COMP%]     .activity-timestamp{display:block}  icon.icon.lg{font-size:25px;color:#1f8bd5}  .svg-icon{width:22px;height:22px;padding:1px;margin-bottom:1px;margin-top:1px}  .selectedbar{border-bottom:4px solid #008be8}  .icon-class,   icon{font-size:20px}  .icon-class:not([icon]),   icon:not([icon]){vertical-align:middle;line-height:1}  .icon-class[icon],   icon[icon]{vertical-align:sub}  .icon-class svg,   icon svg{width:16px;height:16px}  .icon-class.-size-33-30,   icon.-size-33-30{font-size:29px}  .icon-class.-size-33-30 svg,   icon.-size-33-30 svg{width:25px;height:25px}  .icon-class.-size-34,   icon.-size-34{font-size:25px}  .icon-class.-size-34[icon],   icon.-size-34[icon]{font-size:25px}  .icon-class.-size-34 svg,   icon.-size-34 svg{width:25px;height:25px}  customer-connect .modal-body{padding-bottom:0!important;padding-top:0!important}  customer-connect .modal-body .form-group{margin-bottom:0}  customer-connect .customer-connect-log-button .form-group{margin-left:35px;margin-right:-25px;margin-bottom:5px;padding-top:10px;float:right!important}  customer-connect .do-not-contact-options{margin-top:10px}"];
exports.styles = styles;
