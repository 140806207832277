import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { LogActionShortcutService } from '../../../../services/log-action-shortcut.service';
import { ActivityActionEnum } from '../../../../constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'customer-connect-sub-actions',
    templateUrl: './customer-connect-sub-actions.component-ng.html',
    styleUrls: ['./customer-connect-sub-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectSubActionsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() permissions: DealSheetPermissions;
    @Input() subAction: any;
    @Input() subActionTime: number;
    @Output() onAction = new EventEmitter<any>();

    public shortCutActivityActionEnum: number;
    public selectedLogAction: number;
    public selectedScheduleAction: number;
    public activityActionEnum = ActivityActionEnum;

    private shortcutSubscription: Subscription;

    constructor(logActionShortcutService: LogActionShortcutService, changeDetectorRef: ChangeDetectorRef) {
        this.shortcutSubscription = logActionShortcutService.$logShortcutAction.subscribe((shortCutActivityActionEnum) => {
            this.shortCutActivityActionEnum = shortCutActivityActionEnum;
            changeDetectorRef.detectChanges();
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.subActionTime && changes.subActionTime.currentValue) {
            // set default radio choice
            if (this.subAction === this.activityActionEnum.Log_Call || this.subAction === this.activityActionEnum.Post_Note) {
                this.selectedLogAction = this.subAction;
            } else if (this.subAction === this.activityActionEnum.Schedule_Appt || this.subAction === this.activityActionEnum.Schedule_Call) {
                this.selectedScheduleAction = this.subAction;
            }
        }
    }

    ngOnDestroy(): void {
        if (this.shortcutSubscription) {
            this.shortcutSubscription.unsubscribe();
        }
    }
}
