ServiceDriveOffersModalController.$inject = ["logger", "$sce", "$timeout", "$window"];

/* injects from baggage-loader */
require('./service-drive-offers-print.modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('serviceDriveOffersPrintModalComponent', {
  templateUrl: '/app/components/service-drive/modals/service-drive-offers-print.modal.component.html',
  controller: ServiceDriveOffersModalController,
  bindings: {
    resolve: '<',
    dismiss: '&'
  }
});
/* @ngInject */

function ServiceDriveOffersModalController(logger, $sce, $timeout, $window) {
  var $ctrl = this; //$timeout(function myfunction() {
  //    window.location.href = 'http://google.com';
  //https://myupgradeoffer.com/print-offer-collection/7ef0530a24404fef91dc24eaac77ca96
  //    $window.print();
  //}, 3000);
  //$window.print();

  $ctrl.$onInit = function () {
    logger.debug($ctrl.resolve.printUrl);
    $ctrl.closeModal = closeModal;

    if ($ctrl.resolve && $ctrl.resolve.printUrl) {
      $ctrl.printOfferIframeUrl = $sce.trustAsResourceUrl($ctrl.resolve.printUrl);
    }
  };

  function closeModal() {
    $ctrl.dismiss({
      $value: 'close'
    });
  }
}