
/* injects from baggage-loader */
require('./registration-number.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('registrationNumber', {
    templateUrl: '/app/components/deal-sheet/vehicle/registration-number.component.html',
    bindings: {
        value: '<'
    }
});
