import { uniqBy } from 'lodash';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { LocaleDatePipe } from '../../../filters/locale-date.pipe';
import { VehicleService } from '../../../ajs-upgraded-providers';
import { RecallNotice } from "../../../interfaces/recall-notice.interface";
import { Settings } from "../../../constants";

@Component({
    selector: 'tags',
    templateUrl: './tags.component-ng.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnChanges {
    @Input() values: any;
    @Input() vehicleId: number;
    @Input() makeName: string;
    @Input() dealerId: number;
    @Input() isFordAlert: boolean;
    public hasRecallNotice: boolean = false;
    public hasMultipleRecalls: boolean = false;
    public tagExpirationKey: string;
    public recallNotificationList: RecallNotice[] = [];

    constructor(private localeDate: LocaleDatePipe, @Inject(VehicleService) private vehicleService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.values && changes.values.currentValue) {
            this.values = this.values.sort((tag1, tag2) => {
                if (!tag1.expirationDate && tag2.expirationDate) {
                    return 1;
                } else if (tag1.expirationDate && !tag2.expirationDate) {
                    -1
                } else if (!tag1.expirationDate && !tag2.expirationDate) {
                    return 0;
                } else {
                    return this.compareTagsByExpirationDateAndName(tag1, tag2);
                }
            });

            this.hasRecallNotice = false; // reset to false to handle empty tag results when switching deal sheet
            this.setLocalizedExpirationDate();
            this.values = this.values.filter(item => {
                if (item.tagID == 2404 || item.tagID == 10005) {
                    this.hasRecallNotice = true;
                    this.getRecallInformation();
                    return false;
                }
                if (item.text == "Ford Credit Pre-Approved") return false;
                if (item.text == "Ford Credit Pre-Qualified") return false;
                return true;
            });
        }
    }

    getRecallInformation() {
        this.vehicleService.getVinRecall(this.dealerId, this.vehicleId)
            .then((data: any[]) => {
                if (data.length > 0) {

                    // remove duplicates
                    let dedupedRecalls = uniqBy(data, (recall: any) => {
                        return recall.vin + '_' + recall.recallNumber_Nhtsa + '_' + recall.recallNumber_mfr;
                    });

                    this.recallNotificationList = dedupedRecalls.map(data => ({
                        recallDate: data.recallDate,
                        description: data.recallDescription,
                        safetyRisk: data.safetyRiskDescription,
                        remedy: data.remedyDescription,
                        recallNumber_mfr: data.recallNumber_mfr,
                        recallNumber_Nhtsa: data.recallNumber_Nhtsa,
                        isRemedyAvailable: data.isRemedyAvailable,
                        vinRecallDate: data.vinRecallDate,
                        vin: data.vin
                    })).sort(function (a, b) {  // sort by descending recall date:
                        return new Date(b.recallDate).getTime() - new Date(a.recallDate).getTime();
                    });

                    this.hasMultipleRecalls = this.recallNotificationList.length > 1;
                }
            });
    }


    setLocalizedExpirationDate() {
        for (var i = 0; i < this.values.length; i++) {
            var tag = this.values[i];
            if (tag.expirationDate) {
                tag.localizedExpirationDate = this.localeDate.transform(tag.expirationDate);
            }
        }
    }

    compareTagsByExpirationDateAndName(tag1, tag2) {
        if (tag1.expirationDate > tag2.expirationDate) {
            return 1;
        } else if (tag1.expirationDate < tag2.expirationDate) {
            return -1;
        } else {
            return this.compareTagsByName(tag1, tag2);
        }
    }

    compareTagsByName(tag1, tag2) {
        if (tag1.name > tag2.name) {
            return 1;
        } else if (tag1.name < tag2.name) {
            return -1;
        } else {
            return 0;
        }
    }

    openOasisLink() {
        if (this.recallNotificationList.length > 0) {
            var oasisUrl = Settings.environmentVariables.fordOASISUrl;

            oasisUrl = oasisUrl.replace("{0}", this.recallNotificationList[0].vin);
            window.open(oasisUrl, '_blank');
        }
    }
}
