
/* injects from baggage-loader */
require('./recommended-vehicles-action.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('recommendedVehiclesAction', {
    templateUrl: '/app/components/deal-sheet/vehicle/recommended-vehicles-action.component.html',
    controller: RecommendedVehiclesActionController,
    bindings: {
        opportunity: '<',
        pricingPlan: '<',
        onSelect: '&',
        onNoRecommendations: '&'
    }
});

/* @ngInject */
function RecommendedVehiclesActionController($q, $analytics, vehicleService, vehicleSelectColumns, analytics) {
    var $ctrl = this;

    $ctrl.displayColumns;
    $ctrl.vehicles;
    $ctrl.pricingPlanId;
    $ctrl.getVehicles = getVehicles;
    $ctrl.onVehicleSelect = onVehicleSelect;
    $ctrl.$onInit = $onInit;
    $ctrl.$onChanges = $onChanges;

    function $onChanges() {
        if ($ctrl.pricingPlan) {
            // When switching from one deal sheet to another, $ctrl.opportunity
            // sometimes gets set to null before this component is destroyed,
            // which causes a TypeError here.
            if ($ctrl.opportunity && $ctrl.opportunity.customer) {
                $ctrl.pricingPlanId = $ctrl.opportunity.customer.pricingPlanId;
            }
        }
    }

    function $onInit() {
        $ctrl.displayColumns = [
            vehicleSelectColumns.stockNo,
            vehicleSelectColumns.trim,
            vehicleSelectColumns.year,
            vehicleSelectColumns.price,
            vehicleSelectColumns.payments
        ];
    }

    function getVehicles() {
        return vehicleService.getInventoryReplacementVehicles($ctrl.opportunity)
            .then(setVehicles);
    }

    function setVehicles(vehicles) {
        if (vehicles && vehicles.length > 0) {
            $ctrl.vehicles = vehicles;
            return $q.resolve(true);
        }
        else {
            $ctrl.onNoRecommendations();

            return $q.resolve(false);
        }
    }

    function onVehicleSelect(vehicle) {
        $analytics.eventTrack(analytics.actions.changeReplacementVehicle, {
            category: analytics.categories.dealSheet,
            label: analytics.labels.inventoryRecommendation
        });

        $ctrl.onSelect({ vehicle: vehicle });
    }
}
