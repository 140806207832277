/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CustomerAdditionalContactDto } from '../models/customer-additional-contact-dto';
import { CustomerContactHistoryDto } from '../models/customer-contact-history-dto';
@Injectable({
  providedIn: 'root',
})
class CustomerContactHistoryClientService extends __BaseService {
  static readonly GetCustomerAdditionalContactsByEntityidGETPath = '/api/CustomerContactHistory/ByEntity/{entityId}';
  static readonly GetCustomerAdditionalContactsByCustomeridGETPath = '/api/CustomerContactHistory/ByCustomer/{customerId}';
  static readonly ByIdGETPath = '/api/CustomerContactHistory/{id}';
  static readonly ByIdDELETEPath = '/api/CustomerContactHistory/{id}';
  static readonly GetManyGETPath = '/api/CustomerContactHistory/GetMany';
  static readonly PUTPath = '/api/CustomerContactHistory';
  static readonly POSTPath = '/api/CustomerContactHistory';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetCustomerAdditionalContactsByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<Array<CustomerAdditionalContactDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerContactHistory/ByEntity/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerAdditionalContactDto>>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetCustomerAdditionalContactsByEntityidGET(entityId: string): __Observable<Array<CustomerAdditionalContactDto>> {
    return this.GetCustomerAdditionalContactsByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as Array<CustomerAdditionalContactDto>)
    );
  }

  /**
   * @param customerId undefined
   * @return Success
   */
  GetCustomerAdditionalContactsByCustomeridGETResponse(customerId: number): __Observable<__StrictHttpResponse<Array<CustomerAdditionalContactDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerContactHistory/ByCustomer/${customerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerAdditionalContactDto>>;
      })
    );
  }
  /**
   * @param customerId undefined
   * @return Success
   */
  GetCustomerAdditionalContactsByCustomeridGET(customerId: number): __Observable<Array<CustomerAdditionalContactDto>> {
    return this.GetCustomerAdditionalContactsByCustomeridGETResponse(customerId).pipe(
      __map(_r => _r.body as Array<CustomerAdditionalContactDto>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<CustomerContactHistoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerContactHistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerContactHistoryDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<CustomerContactHistoryDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as CustomerContactHistoryDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CustomerContactHistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<CustomerContactHistoryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerContactHistory/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerContactHistoryDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<CustomerContactHistoryDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<CustomerContactHistoryDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: CustomerContactHistoryDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/CustomerContactHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: CustomerContactHistoryDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: CustomerContactHistoryDto): __Observable<__StrictHttpResponse<CustomerContactHistoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CustomerContactHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerContactHistoryDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: CustomerContactHistoryDto): __Observable<CustomerContactHistoryDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as CustomerContactHistoryDto)
    );
  }
}

module CustomerContactHistoryClientService {
}

export { CustomerContactHistoryClientService }
