"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var storage_service_1 = require("../modules/storage/storage.service");
var LocaleDatePipe = /** @class */ (function (_super) {
    __extends(LocaleDatePipe, _super);
    function LocaleDatePipe(storageService) {
        var _this = _super.call(this, 'en-us') || this;
        _this.storageService = storageService;
        return _this;
    }
    LocaleDatePipe.prototype.transform = function (date, args) {
        var savedCultureName = this.storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                return _super.prototype.transform.call(this, date, 'dd/MM/yyyy');
            case 'en-ca':
                return _super.prototype.transform.call(this, date, 'dd/MM/yyyy');
            case 'fr-fr':
                return _super.prototype.transform.call(this, date, 'dd/MM/yyyy');
            case 'fr-ca':
                return _super.prototype.transform.call(this, date, 'yyyy-MM-dd');
            case 'en-us':
            default:
                return _super.prototype.transform.call(this, date, 'MM/dd/yyyy');
        }
    };
    return LocaleDatePipe;
}(common_1.DatePipe));
exports.LocaleDatePipe = LocaleDatePipe;
