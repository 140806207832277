import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'default-tab-action',
    templateUrl: './default-tab-action.component-ng.html',
    styleUrls: ['./default-tab-action.component.scss']
})
export class DefaultTabActionComponent implements OnInit {

    @Input() defaultTab: number;
    @Input() currentTab: number;
    @Output() onAction = new EventEmitter<{}>();

    constructor() { }

    ngOnInit() {
    }

    onClickMarkAsDefault() {
        this.onAction.emit();
    }
}
