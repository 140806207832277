import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { VehicleService } from '../../../../ajs-upgraded-providers';

@Component({
    selector: 'carfaxv2',
    templateUrl: './carfaxv2.component-ng.html',
    styleUrls: ['./carfaxv2.component.scss']
})
export class Carfaxv2Component implements OnInit, OnChanges {

    @Input() permissions: DealSheetPermissions;
    @Input() dealerId: string;
    @Input() dealerSettings: any;
    @Input() opportunityId: string;
    @Input() vin: string;
    @Input() readonly: boolean;
    @Input() isLoggedIn: boolean;
    public showCarFaxLink = false;

    constructor(@Inject(VehicleService) private vehicleService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.permissions || !this.dealerSettings || !this.vin || !this.dealerId) {
            return;
        }
        this.showCarFaxLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableCarFax;
    }

    logOut(): void {
        this.vehicleService.carFaxLogOut()
            .then((data) => {
                var carfaxLogoutUrl = "https://auth.carfax.com/v2/logout";
                window.open(carfaxLogoutUrl, '_blank');
            })
            .catch(function () {
            });
    }

}
