import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
DmsPushAPIProvider.$inject = ["selectedDealersInjectorProvider"];

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import angular from 'angular';
import { defaultsDeep } from 'lodash';
import swaggerDefinition from './swagger-definition-dmspush';
import { OpportunitiesModule } from '../core/opportunities.module'; // AngularJS seems to require we load selectedDealersInjector first, or else
// injecting its provider fails with an error.

import './selected-dealers-injector.service'; //NOTES:
//STEP 1:  add this to the app.config
//$httpProvider.defaults.useXDomain = true;
//STEP 2:  read this article
//http://stackoverflow.com/questions/21455045/angularjs-http-cors-and-http-authentication

OpportunitiesModule.provider('dmsPushApi', DmsPushAPIProvider);

function DmsPushAPIProvider(selectedDealersInjectorProvider) {
  "ngInject";

  var dmsPushApi = {
    config: {}
  };
  return {
    configure: function configure(_ref) {
      var url = _ref.url;
      dmsPushApi.config.domain = url;
      selectedDealersInjectorProvider.addUrl(url);
    },
    $get: ["$http", function DmsPushAPI($http) {
      "ngInject";

      loadAPI();
      return dmsPushApi;

      function loadAPI() {
        for (var path in swaggerDefinition.paths) {
          var clients = CreateClients(swaggerDefinition, path); // This function name isn't great, I'd love to either find
          // a better name for it, split it up into smaller,
          // grokkable functions, or replace it.
          // coreApi = apply(coreApi, clients);

          defaultsDeep(dmsPushApi, clients);
        }

        dmsPushApi.swaggerDefinition = swaggerDefinition;
      }

      function CreateClients(swaggerDefinition, path) {
        var pathSplit = path.split("/");
        var controller;
        var child = controller = {};

        for (var i = 0; i < pathSplit.length; i++) {
          var name = pathSplit[i];
          var isIgnored = name == "";

          if (!isIgnored) {
            if (child[name] == null) child[name] = {};
            child = child[name];
            var isEndpoint = i == pathSplit.length - 1;

            if (isEndpoint) {
              var httpVerbs = swaggerDefinition.paths[path];

              for (var httpVerb in httpVerbs) {
                var upperCaseVerb = httpVerb.toUpperCase();

                switch (upperCaseVerb) {
                  case "GET":
                    child.GET = function (urlParams, httpOptions) {
                      return $http(_objectSpread({
                        method: "GET",
                        url: aggregateUrl(this.GET.config.httpPath, urlParams),
                        params: urlParams,
                        withCredentials: true
                      }, httpOptions));
                    };

                    break;

                  case "POST":
                    child.POST = function (body, urlParams, httpOptions) {
                      return $http(_objectSpread({
                        method: "POST",
                        url: aggregateUrl(this.POST.config.httpPath, urlParams),
                        params: urlParams,
                        data: body,
                        withCredentials: true,
                        headers: _objectSpread({
                          'Content-Type': 'application/json'
                        }, httpOptions && httpOptions.headers)
                      }, httpOptions));
                    };

                    break;

                  case "PUT":
                    child.PUT = function (body, urlParams, httpOptions) {
                      return $http(_objectSpread({
                        method: "PUT",
                        url: aggregateUrl(this.PUT.config.httpPath, urlParams),
                        params: urlParams,
                        data: body,
                        withCredentials: true,
                        headers: _objectSpread({
                          'Content-Type': 'application/json'
                        }, httpOptions && httpOptions.headers)
                      }, httpOptions));
                    };

                    break;

                  case "DELETE":
                    child.DELETE = function (urlParams, httpOptions) {
                      return $http(_objectSpread({
                        method: "DELETE",
                        url: aggregateUrl(this.DELETE.config.httpPath, urlParams),
                        params: urlParams,
                        withCredentials: true,
                        headers: _objectSpread({
                          'Content-Type': 'application/json'
                        }, httpOptions && httpOptions.headers)
                      }, httpOptions));
                    };

                    break;

                  case "PATCH":
                    child.PATCH = function (body, urlParams, httpOptions) {
                      return $http(_objectSpread({
                        method: "PATCH",
                        url: aggregateUrl(this.PATCH.config.httpPath, urlParams),
                        params: urlParams,
                        data: body,
                        withCredentials: true,
                        headers: _objectSpread({
                          'Content-Type': 'application/json'
                        }, httpOptions && httpOptions.headers)
                      }, httpOptions));
                    };

                    break;

                  default:
                    console.Write("HTTP verb: '" + upperCaseVerb + "' is not supported");
                    break;
                }

                child[upperCaseVerb].config = {
                  httpVerb: httpVerb,
                  httpPath: dmsPushApi.config.domain + path,
                  swaggerInfo: httpVerbs[httpVerb]
                };
              }
            }
          }
        }

        return controller;
      }

      function aggregateUrl(url, params) {
        if (params != null) {
          for (var param in params) {
            url = url.replace("{" + param + "}", encodeURI(params[param]));
          }
        }

        return url;
      }
    }]
  };
}