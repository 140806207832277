"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dealer_service_1 = require("../../../services/dealer.service");
var menu_service_1 = require("../../../services/menu.service");
var QuickLinksComponent = /** @class */ (function () {
    function QuickLinksComponent(dealerService, menuService) {
        this.dealerService = dealerService;
        this.menuService = menuService;
    }
    QuickLinksComponent.prototype.ngOnInit = function () {
        var _this = this;
        var moduleIds = this.menuService.moduleIds;
        if (moduleIds) {
            this.initQuickLinks(moduleIds);
        }
        // the following replaces event handler for $rootScope.$broadcast('RUN_USER_INIT.SITE_MAP_READY');
        // menu.service now casts BehaviorSubject when getting sitemap which this subscribes to
        this.siteMapReadySubscription = this.menuService.siteMapReady.subscribe(function (data) {
            _this.onSiteMapLoaded();
        });
    };
    QuickLinksComponent.prototype.onSiteMapLoaded = function () {
        var moduleIds = this.menuService.moduleIds;
        this.initQuickLinks(moduleIds);
    };
    ;
    QuickLinksComponent.prototype.initQuickLinks = function (moduleIds) {
        this.modulePermissions = this.menuService.getModulePermissions(moduleIds);
        this.isSomeDealerOneToOneEnabled = this.dealerService.getIsSomeDealerOneToOneEnabled();
    };
    QuickLinksComponent.prototype.ngOnDestroy = function () {
        this.siteMapReadySubscription.unsubscribe();
    };
    return QuickLinksComponent;
}());
exports.QuickLinksComponent = QuickLinksComponent;
