<div class="col-xs-12 mobile-activities" [ngBusy]="busy">
    <div class="activity-list">
        <activity-list *ngIf="permissions?.activitiesLog" [hasReviewPro]="hasReviewPro" [hasGeoAlert]="hasGeoAlert"
                       [activities]="activities" [permissions]="permissions" [status]="status" [users]="users"
                       [opportunityId]="opportunityId" [assignedUserId]="assignedUserId" [ownerUserInfo]="ownerUserInfo"
                       [assignedUserFullName]="assignedUserFullName" [fullName]="fullName" [mobileView]="true">
        </activity-list>
    </div>
    <div>
        <nav class="navbar navbar-default navbar-fixed-bottom" role="navigation">
            <div class="container">
                <button class="btn btn-primary btn-block"
                        (click)="handleLogActivityNavigation()">
                    {{'customerConnectTab' | translate}}
                </button>
            </div>
        </nav>
    </div>
</div>