DeliveryMonthlyMileage.$inject = ["conquestTypes"];

/* injects from baggage-loader */
require('./delivery-monthly-mileage.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('deliveryMonthlyMileage', {
  templateUrl: '/app/components/deal-sheet/contract/delivery-monthly-mileage.component.html',
  controller: DeliveryMonthlyMileage,
  bindings: {
    delivery: '<',
    monthly: '<',
    conquestType: '<'
  }
});
/* @ngInject */

function DeliveryMonthlyMileage(conquestTypes) {
  var $ctrl = this;
  $ctrl.conquestTypes = conquestTypes;
}