/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TextMessageDto } from '../models/text-message-dto';
import { SendTextWithUpgradeProposalResponse } from '../models/send-text-with-upgrade-proposal-response';
import { SendTextWithUpgradeProposalRequest } from '../models/send-text-with-upgrade-proposal-request';
import { TextableStatusModel } from '../models/textable-status-model';
@Injectable({
  providedIn: 'root',
})
class TextMessageClientService extends __BaseService {
  static readonly SendPOSTPath = '/api/TextMessage/Send';
  static readonly SendWithUpgradeProposalPOSTPath = '/api/TextMessage/SendWithUpgradeProposal';
  static readonly GetHistoryForEntityByEntityidGETPath = '/api/TextMessage/GetHistoryForEntity/{entityId}';
  static readonly GetMessagesByCustomerIdByCustomeridGETPath = '/api/TextMessage/GetMessagesByCustomerId/{customerId}';
  static readonly GetMessagesByCustomerIdByCustomeridByMessageidGETPath = '/api/TextMessage/GetNewMessagesByCustomerId/{customerId}/{messageId}';
  static readonly GetTextableStatusGETPath = '/api/TextMessage/GetTextableStatus';
  static readonly IsUserNumberTextableGETPath = '/api/TextMessage/IsUserNumberTextable';
  static readonly SearchForNewNumberGETPath = '/api/TextMessage/SearchForNewNumber';
  static readonly SearchForNewNumberByDealeridGETPath = '/api/TextMessage/SearchForNewNumber/{dealerId}/{stateCode}';
  static readonly SearchForNewNumberByAreaCodeGETPath = '/api/TextMessage/SearchForNewNumberByAreaCode';
  static readonly PurchaseNewNumberPOSTPath = '/api/TextMessage/PurchaseNewNumber';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param textMessage undefined
   * @return Success
   */
  SendPOSTResponse(textMessage?: TextMessageDto): __Observable<__StrictHttpResponse<TextMessageDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = textMessage;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TextMessage/Send`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TextMessageDto>;
      })
    );
  }
  /**
   * @param textMessage undefined
   * @return Success
   */
  SendPOST(textMessage?: TextMessageDto): __Observable<TextMessageDto> {
    return this.SendPOSTResponse(textMessage).pipe(
      __map(_r => _r.body as TextMessageDto)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  SendWithUpgradeProposalPOSTResponse(request?: SendTextWithUpgradeProposalRequest): __Observable<__StrictHttpResponse<SendTextWithUpgradeProposalResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TextMessage/SendWithUpgradeProposal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SendTextWithUpgradeProposalResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  SendWithUpgradeProposalPOST(request?: SendTextWithUpgradeProposalRequest): __Observable<SendTextWithUpgradeProposalResponse> {
    return this.SendWithUpgradeProposalPOSTResponse(request).pipe(
      __map(_r => _r.body as SendTextWithUpgradeProposalResponse)
    );
  }

  /**
   * @param entityId undefined
   */
  GetHistoryForEntityByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/GetHistoryForEntity/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetHistoryForEntityByEntityidGET(entityId: string): __Observable<null> {
    return this.GetHistoryForEntityByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param customerId undefined
   * @return Success
   */
  GetMessagesByCustomerIdByCustomeridGETResponse(customerId: number): __Observable<__StrictHttpResponse<TextMessageDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/GetMessagesByCustomerId/${customerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TextMessageDto>;
      })
    );
  }
  /**
   * @param customerId undefined
   * @return Success
   */
  GetMessagesByCustomerIdByCustomeridGET(customerId: number): __Observable<TextMessageDto> {
    return this.GetMessagesByCustomerIdByCustomeridGETResponse(customerId).pipe(
      __map(_r => _r.body as TextMessageDto)
    );
  }

  /**
   * @param params The `TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETParams` containing the following parameters:
   *
   * - `messageId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  GetMessagesByCustomerIdByCustomeridByMessageidGETResponse(params: TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETParams): __Observable<__StrictHttpResponse<TextMessageDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/GetNewMessagesByCustomerId/${params.customerId}/${params.messageId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TextMessageDto>;
      })
    );
  }
  /**
   * @param params The `TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETParams` containing the following parameters:
   *
   * - `messageId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  GetMessagesByCustomerIdByCustomeridByMessageidGET(params: TextMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGETParams): __Observable<TextMessageDto> {
    return this.GetMessagesByCustomerIdByCustomeridByMessageidGETResponse(params).pipe(
      __map(_r => _r.body as TextMessageDto)
    );
  }

  /**
   * @param params The `TextMessageClientService.GetTextableStatusGETParams` containing the following parameters:
   *
   * - `ZipCode`:
   *
   * - `WorkPhone_DoNotCallStatusTypeId`:
   *
   * - `WorkPhone`:
   *
   * - `UpdateDate`:
   *
   * - `StateID`:
   *
   * - `State`:
   *
   * - `PricingPlanID`:
   *
   * - `PreferredCultureName`:
   *
   * - `NormalizedZipCode`:
   *
   * - `MiddleName`:
   *
   * - `LastName`:
   *
   * - `IsFullCustomer`:
   *
   * - `IsAddressNotValid`:
   *
   * - `InvalidContacts`:
   *
   * - `InsertDate`:
   *
   * - `HomePhone_DoNotCallStatusTypeId`:
   *
   * - `HomePhone`:
   *
   * - `FullName`:
   *
   * - `FirstName`:
   *
   * - `ExternalImportID`:
   *
   * - `Email`:
   *
   * - `DoNotText`:
   *
   * - `DoNotMail`:
   *
   * - `DoNotEmail`:
   *
   * - `DoNotCall`:
   *
   * - `DealerID`:
   *
   * - `CustomerID`:
   *
   * - `CreatedByUserID`:
   *
   * - `County`:
   *
   * - `CompanyName`:
   *
   * - `City`:
   *
   * - `CellPhone_DoNotCallStatusTypeId`:
   *
   * - `CellPhone`:
   *
   * - `Address2`:
   *
   * - `Address`:
   *
   * @return Success
   */
  GetTextableStatusGETResponse(params: TextMessageClientService.GetTextableStatusGETParams): __Observable<__StrictHttpResponse<TextableStatusModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ZipCode != null) __params = __params.set('ZipCode', params.ZipCode.toString());
    if (params.WorkPhoneDoNotCallStatusTypeId != null) __params = __params.set('WorkPhone_DoNotCallStatusTypeId', params.WorkPhoneDoNotCallStatusTypeId.toString());
    if (params.WorkPhone != null) __params = __params.set('WorkPhone', params.WorkPhone.toString());
    if (params.UpdateDate != null) __params = __params.set('UpdateDate', params.UpdateDate.toString());
    if (params.StateID != null) __params = __params.set('StateID', params.StateID.toString());
    if (params.State != null) __params = __params.set('State', params.State.toString());
    if (params.PricingPlanID != null) __params = __params.set('PricingPlanID', params.PricingPlanID.toString());
    if (params.PreferredCultureName != null) __params = __params.set('PreferredCultureName', params.PreferredCultureName.toString());
    if (params.NormalizedZipCode != null) __params = __params.set('NormalizedZipCode', params.NormalizedZipCode.toString());
    if (params.MiddleName != null) __params = __params.set('MiddleName', params.MiddleName.toString());
    if (params.LastName != null) __params = __params.set('LastName', params.LastName.toString());
    if (params.IsFullCustomer != null) __params = __params.set('IsFullCustomer', params.IsFullCustomer.toString());
    if (params.IsAddressNotValid != null) __params = __params.set('IsAddressNotValid', params.IsAddressNotValid.toString());
    if (params.InvalidContacts != null) __params = __params.set('InvalidContacts', params.InvalidContacts.toString());
    if (params.InsertDate != null) __params = __params.set('InsertDate', params.InsertDate.toString());
    if (params.HomePhoneDoNotCallStatusTypeId != null) __params = __params.set('HomePhone_DoNotCallStatusTypeId', params.HomePhoneDoNotCallStatusTypeId.toString());
    if (params.HomePhone != null) __params = __params.set('HomePhone', params.HomePhone.toString());
    if (params.FullName != null) __params = __params.set('FullName', params.FullName.toString());
    if (params.FirstName != null) __params = __params.set('FirstName', params.FirstName.toString());
    if (params.ExternalImportID != null) __params = __params.set('ExternalImportID', params.ExternalImportID.toString());
    if (params.Email != null) __params = __params.set('Email', params.Email.toString());
    if (params.DoNotText != null) __params = __params.set('DoNotText', params.DoNotText.toString());
    if (params.DoNotMail != null) __params = __params.set('DoNotMail', params.DoNotMail.toString());
    if (params.DoNotEmail != null) __params = __params.set('DoNotEmail', params.DoNotEmail.toString());
    if (params.DoNotCall != null) __params = __params.set('DoNotCall', params.DoNotCall.toString());
    if (params.DealerID != null) __params = __params.set('DealerID', params.DealerID.toString());
    if (params.CustomerID != null) __params = __params.set('CustomerID', params.CustomerID.toString());
    if (params.CreatedByUserID != null) __params = __params.set('CreatedByUserID', params.CreatedByUserID.toString());
    if (params.County != null) __params = __params.set('County', params.County.toString());
    if (params.CompanyName != null) __params = __params.set('CompanyName', params.CompanyName.toString());
    if (params.City != null) __params = __params.set('City', params.City.toString());
    if (params.CellPhoneDoNotCallStatusTypeId != null) __params = __params.set('CellPhone_DoNotCallStatusTypeId', params.CellPhoneDoNotCallStatusTypeId.toString());
    if (params.CellPhone != null) __params = __params.set('CellPhone', params.CellPhone.toString());
    if (params.Address2 != null) __params = __params.set('Address2', params.Address2.toString());
    if (params.Address != null) __params = __params.set('Address', params.Address.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/GetTextableStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TextableStatusModel>;
      })
    );
  }
  /**
   * @param params The `TextMessageClientService.GetTextableStatusGETParams` containing the following parameters:
   *
   * - `ZipCode`:
   *
   * - `WorkPhone_DoNotCallStatusTypeId`:
   *
   * - `WorkPhone`:
   *
   * - `UpdateDate`:
   *
   * - `StateID`:
   *
   * - `State`:
   *
   * - `PricingPlanID`:
   *
   * - `PreferredCultureName`:
   *
   * - `NormalizedZipCode`:
   *
   * - `MiddleName`:
   *
   * - `LastName`:
   *
   * - `IsFullCustomer`:
   *
   * - `IsAddressNotValid`:
   *
   * - `InvalidContacts`:
   *
   * - `InsertDate`:
   *
   * - `HomePhone_DoNotCallStatusTypeId`:
   *
   * - `HomePhone`:
   *
   * - `FullName`:
   *
   * - `FirstName`:
   *
   * - `ExternalImportID`:
   *
   * - `Email`:
   *
   * - `DoNotText`:
   *
   * - `DoNotMail`:
   *
   * - `DoNotEmail`:
   *
   * - `DoNotCall`:
   *
   * - `DealerID`:
   *
   * - `CustomerID`:
   *
   * - `CreatedByUserID`:
   *
   * - `County`:
   *
   * - `CompanyName`:
   *
   * - `City`:
   *
   * - `CellPhone_DoNotCallStatusTypeId`:
   *
   * - `CellPhone`:
   *
   * - `Address2`:
   *
   * - `Address`:
   *
   * @return Success
   */
  GetTextableStatusGET(params: TextMessageClientService.GetTextableStatusGETParams): __Observable<TextableStatusModel> {
    return this.GetTextableStatusGETResponse(params).pipe(
      __map(_r => _r.body as TextableStatusModel)
    );
  }

  /**
   * @return Success
   */
  IsUserNumberTextableGETResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/IsUserNumberTextable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Success
   */
  IsUserNumberTextableGET(): __Observable<boolean> {
    return this.IsUserNumberTextableGETResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `TextMessageClientService.SearchForNewNumberGETParams` containing the following parameters:
   *
   * - `stateCode`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  SearchForNewNumberGETResponse(params: TextMessageClientService.SearchForNewNumberGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.stateCode != null) __params = __params.set('stateCode', params.stateCode.toString());
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/SearchForNewNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `TextMessageClientService.SearchForNewNumberGETParams` containing the following parameters:
   *
   * - `stateCode`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  SearchForNewNumberGET(params: TextMessageClientService.SearchForNewNumberGETParams): __Observable<Array<string>> {
    return this.SearchForNewNumberGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `TextMessageClientService.SearchForNewNumberByDealeridGETParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `stateCode`:
   *
   * @return Success
   */
  SearchForNewNumberByDealeridGETResponse(params: TextMessageClientService.SearchForNewNumberByDealeridGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.stateCode != null) __params = __params.set('stateCode', params.stateCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/SearchForNewNumber/${params.dealerId}/${params.stateCode}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `TextMessageClientService.SearchForNewNumberByDealeridGETParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `stateCode`:
   *
   * @return Success
   */
  SearchForNewNumberByDealeridGET(params: TextMessageClientService.SearchForNewNumberByDealeridGETParams): __Observable<Array<string>> {
    return this.SearchForNewNumberByDealeridGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `TextMessageClientService.SearchForNewNumberByAreaCodeGETParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `areaCode`:
   *
   * @return Success
   */
  SearchForNewNumberByAreaCodeGETResponse(params: TextMessageClientService.SearchForNewNumberByAreaCodeGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    if (params.areaCode != null) __params = __params.set('areaCode', params.areaCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TextMessage/SearchForNewNumberByAreaCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `TextMessageClientService.SearchForNewNumberByAreaCodeGETParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `areaCode`:
   *
   * @return Success
   */
  SearchForNewNumberByAreaCodeGET(params: TextMessageClientService.SearchForNewNumberByAreaCodeGETParams): __Observable<Array<string>> {
    return this.SearchForNewNumberByAreaCodeGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `TextMessageClientService.PurchaseNewNumberPOSTParams` containing the following parameters:
   *
   * - `newNumberToPurchase`:
   *
   * - `dealerId`:
   *
   * - `accessRightId`:
   *
   * @return Success
   */
  PurchaseNewNumberPOSTResponse(params: TextMessageClientService.PurchaseNewNumberPOSTParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.newNumberToPurchase != null) __params = __params.set('newNumberToPurchase', params.newNumberToPurchase.toString());
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    if (params.accessRightId != null) __params = __params.set('accessRightId', params.accessRightId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TextMessage/PurchaseNewNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `TextMessageClientService.PurchaseNewNumberPOSTParams` containing the following parameters:
   *
   * - `newNumberToPurchase`:
   *
   * - `dealerId`:
   *
   * - `accessRightId`:
   *
   * @return Success
   */
  PurchaseNewNumberPOST(params: TextMessageClientService.PurchaseNewNumberPOSTParams): __Observable<number> {
    return this.PurchaseNewNumberPOSTResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }
}

module TextMessageClientService {

  /**
   * Parameters for GetMessagesByCustomerIdByCustomeridByMessageidGET
   */
  export interface GetMessagesByCustomerIdByCustomeridByMessageidGETParams {
    messageId: number;
    customerId: number;
  }

  /**
   * Parameters for GetTextableStatusGET
   */
  export interface GetTextableStatusGETParams {
    ZipCode?: string;
    WorkPhoneDoNotCallStatusTypeId?: 0 | 1 | 2 | 3 | 4;
    WorkPhone?: string;
    UpdateDate?: string;
    StateID?: number;
    State?: string;
    PricingPlanID?: number;
    PreferredCultureName?: string;
    NormalizedZipCode?: string;
    MiddleName?: string;
    LastName?: string;
    IsFullCustomer?: boolean;
    IsAddressNotValid?: boolean;
    InvalidContacts?: string;
    InsertDate?: string;
    HomePhoneDoNotCallStatusTypeId?: 0 | 1 | 2 | 3 | 4;
    HomePhone?: string;
    FullName?: string;
    FirstName?: string;
    ExternalImportID?: number;
    Email?: string;
    DoNotText?: boolean;
    DoNotMail?: boolean;
    DoNotEmail?: boolean;
    DoNotCall?: boolean;
    DealerID?: number;
    CustomerID?: number;
    CreatedByUserID?: number;
    County?: string;
    CompanyName?: string;
    City?: string;
    CellPhoneDoNotCallStatusTypeId?: 0 | 1 | 2 | 3 | 4;
    CellPhone?: string;
    Address2?: string;
    Address?: string;
  }

  /**
   * Parameters for SearchForNewNumberGET
   */
  export interface SearchForNewNumberGETParams {
    stateCode?: string;
    dealerId?: number;
  }

  /**
   * Parameters for SearchForNewNumberByDealeridGET
   */
  export interface SearchForNewNumberByDealeridGETParams {
    dealerId: number;
    stateCode?: string;
  }

  /**
   * Parameters for SearchForNewNumberByAreaCodeGET
   */
  export interface SearchForNewNumberByAreaCodeGETParams {
    dealerId?: number;
    areaCode?: number;
  }

  /**
   * Parameters for PurchaseNewNumberPOST
   */
  export interface PurchaseNewNumberPOSTParams {
    newNumberToPurchase?: string;
    dealerId?: number;
    accessRightId?: number;
  }
}

export { TextMessageClientService }
