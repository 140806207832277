import "core-js/modules/es6.regexp.split";
PandoInboxController.$inject = ["$rootScope", "pandoCommunicationService"];

/* injects from baggage-loader */
require('./pando-inbox.component.html');

import angular from 'angular';
import Pubnub from 'pubnub';
import { OpportunitiesModule } from '../../core/opportunities.module';
OpportunitiesModule.component('pandoInbox', {
  templateUrl: '/app/components/header/pando-inbox.component.html',
  controller: PandoInboxController,
  bindings: {
    isAutoAlertUser: '<'
  }
});
/* @ngInject */

function PandoInboxController($rootScope, pandoCommunicationService) {
  var $ctrl = this;
  $ctrl.$onInit = $onInit;
  $ctrl.isAvailable = null;
  $ctrl.Availability = null;
  $ctrl.unreadMessageCount = null; //$ctrl.loginUserChatId = null;

  $ctrl.messages = [];
  $ctrl.messageGroupIds = [];
  $ctrl.communicationUrl = null;
  var accountControlChannel = null;
  var userControlChannel = null;
  var inboxConfig = {}; //$ctrl.currentUserVenderInfo = null;

  $ctrl.loginUserId = null;
  var pandoMessaging = new PandoMessaging();

  function $onInit() {
    inboxConfig = pandoCommunicationService.getPandoInboxConfig();

    if (!inboxConfig.enabled) {
      $ctrl.pandoInboxEnabled = false;
      return;
    }

    $ctrl.pandoInboxEnabled = true;
    $ctrl.communicationUrl = inboxConfig.pandoInboxUrl;
    setupPubnubMessaging();
  } // setup pubnub


  function setupPubnubMessaging() {
    return pandoCommunicationService.getMessageVendorInfo($ctrl.isAutoAlertUser).then(function (vendorInfo) {
      if (vendorInfo) {
        // if user does not have subkey exit
        if (vendorInfo.SubscriptionKey == null || vendorInfo.SubscriptionKey == '') return; // if user does not have auth key exit

        if (vendorInfo.AuthorizationKey == null || vendorInfo.AuthorizationKey == '') return; //$ctrl.currentUserVenderInfo = vendorInfo;

        var userIds = vendorInfo.UserControl.split("-");
        $ctrl.loginUserId = "1:" + userIds[2];
        accountControlChannel = vendorInfo.AccountControl;
        userControlChannel = vendorInfo.UserControl.UserControl;
        pandoMessaging.initPubnub(vendorInfo.SubscriptionKey, vendorInfo.AuthorizationKey, vendorInfo.Channels, vendorInfo.ChannelGroups);
        pandoMessaging.subscribe(pubnubMessageCallback);

        if ($ctrl.unreadMessageCount == null) {
          getApiMessageCount(); // call motofuze api to show count
        }

        $ctrl.isUserAuthorisedForPando = true;
      }
    }, function (error) {
      return null;
    });
  }

  function pubnubMessageCallback(msgResponseObject) {
    // console.log(msgResponseObject);
    if (!msgResponseObject || !msgResponseObject.message || !msgResponseObject.message.rtid) return;
    var rtid = msgResponseObject.message.rtid;

    switch (rtid) {
      case 111:
      case 112:
      case 113:
      case 114:
      case 126:
        // This list of values provieded by Satish on the KC team
        if (msgResponseObject.message.pn_apns && msgResponseObject.message.pn_apns.aps && msgResponseObject.message.pn_apns.aps.hasOwnProperty('badge')) {
          $ctrl.unreadMessageCount = msgResponseObject.message.pn_apns.aps.badge;
        }

        break;
    }
  } // first time call does not use pubnub but calls api to get messages


  function getApiMessageCount() {
    return pandoCommunicationService.getUnreadMessageCount($ctrl.isAutoAlertUser).then(function (result) {
      processApiMessageCollection(result);
    });
  } // display unread messages based on API call


  function processApiMessageCollection(data) {
    if (!data || !data.hasOwnProperty('msgcount')) return;
    $ctrl.unreadMessageCount = data.msgcount;
  }

  function getBadgeCountUserInfo(data, userId) {
    var badgeCount = typeof data.MessageBadgeCount != "undefined" ? data.MessageBadgeCount : '0';
    var senderId = userId ? userId : '';
    var groupName = data.GroupName ? data.GroupName : '';
    var groupId = data.GroupId ? data.GroupId : '';
    var messageBadgeCountUserInfo = {
      'MessageBadgeCount': badgeCount,
      'UserId': senderId,
      'GroupName': groupName,
      'GroupId': groupId
    };
    return messageBadgeCountUserInfo;
  }
}

function PandoMessaging() {
  // instantiating PubNub generates a UUID for you and stores it in localStorage
  // and reuses that UUID from localStorage on subsequent PubNub instantiations
  var uuid;
  var pubnub;
  var channels = [];
  var channelGroups = [];
  var pandoInboxUrl;

  function addListenerPubnub() {
    pubnub.addListener({
      // message events callback - handles all messages published to the subscribed channels
      message: function message(event) {
        sendPandoChatMessage(event);
      },
      // presence events callback - handles all presence events for all channels subscribed withPresence
      presence: function presence(event) {},
      // status events callback - handles network connectivity status events for all subscribed channels
      status: function status(event) {}
    });
  }

  function subscribePubnub() {
    // subscribe to the game channel and monitor presence events on that channel
    pubnub.subscribe({
      channels: channels,
      channelGroups: channelGroups,
      error: function error(_error) {//console.log(error);
      }
    });
  }

  var subscriptions = [];

  function sendPandoChatMessage(message) {
    for (var _i = 0; _i < subscriptions.length; _i++) {
      var fn = subscriptions[_i];

      try {
        fn(message);
      } catch (e) {//console.log("sendMessageToiFrame Error:" + e);
      }
    }
  }

  return {
    initPubnub: function initPubnub(subKey, authKey, pandoChannels, pandoChannelGroups) {
      channels = pandoChannels;
      channelGroups = pandoChannelGroups;
      pubnub = new Pubnub({
        subscribeKey: subKey,
        authKey: authKey,
        //publishKey: PubNubSubscribeKey,
        ssl: true
      });
      uuid = pubnub.getUUID();
      addListenerPubnub();
      subscribePubnub();
    },
    subscribe: function subscribe(fn) {
      subscriptions.push(fn);
      return {
        unsubscribe: function unsubscribe() {
          var ix = subscriptions.indexOf(fn);

          if (ix > -1) {
            subscriptions.splice(ix, 1);
          }
        }
      };
    },
    quitPandoChat: function quitPandoChat() {
      pubnub.unsubscribe({
        channels: channels,
        channelGroups: channelGroups,
        callback: function callback(msg) {//console.log(msg);
        }
      });
    }
  };
}