import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { StorageKeys } from '../../constants';

type TokenType = 'access' | 'id';
@Injectable()
export class TokenService {
    private window: Window;
    
    private readonly ACCESS_TOKEN_TYPE = 'access';
    private readonly ID_TOKEN_TYPE = 'id';
        
    constructor(private storageService: StorageService) {
        this.window = window;
    }

    private padBase64(base64data: string) {
        while (base64data.length % 4 !== 0) {
            base64data += '=';
        }
        return base64data;
    }

    getPayloadFromRawToken(raw: string) {
        if (typeof raw === 'string') {
            const tokenParts = raw.split('.');
            if (tokenParts.length >= 2) {
                return tokenParts[1];
            }
        }
    }

    getImpersonatingUsername(): string {
        if (!this.hasToken()) { return null; }

        const claims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);
        return claims.impersonating_aa_username;
    }

    deserializeClaims(raw: string): Object {
        const claimsBase64 = this.padBase64(raw);
        const claimsJson = this.window.atob(claimsBase64);

        const claims = JSON.parse(claimsJson);

        return claims;
    }

    convertToClaims(token) {
        try {
            const payload = this.getPayloadFromRawToken(token);
            const claims = this.deserializeClaims(payload);
            return claims;
        } catch (e) {
            return {};
        }
    }

    saveToken(tokenType: TokenType, token: string, isExternalToken: boolean) {
        this.storageService.setItem(tokenType + 'Token', token);
        this.storageService.setItem(StorageKeys.IsExternalToken, !!isExternalToken)

        const claims = this.convertToClaims(token);
        this.storageService.setItem('cached-' + tokenType + '-claims', claims);
    }

    hasExternalToken(): boolean {
        if (this.hasToken()) {
            if (this.storageService.getItem(StorageKeys.IsExternalToken) == true) {
                return true;
            }
        }

        return false;
    }

    hasToken(): boolean {

        if (!this.getTokenByType(this.ACCESS_TOKEN_TYPE)) {
            return false;
        }

        const claims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);

        if (!(claims && claims.hasOwnProperty('nbf') && claims.hasOwnProperty('exp'))) {
            return false;
        }

        return true;
    }

    hasValidToken(validAtTimestamp?: Date): boolean {
        if (!this.hasToken()) { return false; }

        const claims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);

        const now = validAtTimestamp || Date.now();
        const notBeforeMSec = claims.nbf * 1000;
        const expiresAtMSec = claims.exp * 1000;

        const marginMSec = 1000 * 60 * 10; // 10 Minutes

        // Substract margin, because browser time could be a bit in the past
        if (notBeforeMSec - marginMSec > now) {
            return false;
        }

        if (expiresAtMSec < now) {
            return false;
        }

        return true;
    }

    getClaimsByTokenType(tokenType: TokenType) {
        const cachedClaims = this.storageService.getItem('cached-' + tokenType + '-claims');

        if (!cachedClaims) {
            const token = this.getTokenByType(tokenType);

            if (token) {
                const claims = this.convertToClaims(token);
                this.storageService.setItem('cached-' + tokenType + '-claims', claims);

                return claims;
            } else {
                // todo: exception catcher
            }
        }
        return cachedClaims;
    }

    getTokenByType(tokenType) {
        return this.storageService.getItem(tokenType + 'Token');
    }

    clearTokens(): void {
        this.storageService.removeItem('cached-id-claims');
        this.storageService.removeItem('cached-access-claims');
        this.storageService.removeItem('idToken');
        this.storageService.removeItem('accessToken');
    }

    isAutoAlertUser(): boolean {
        let currentClaims = this.getClaimsByTokenType(this.ID_TOKEN_TYPE);

        // If we don't have an id token try to find the user name in the access token
        if (!currentClaims) {
            currentClaims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);
        }

        if (currentClaims) {
            return currentClaims.hasOwnProperty('AA_UserName');
        } 
        else {
            return false;
        }
    }

    isMotoFuzeUser(): boolean {
        const currentClaims = this.getClaimsByTokenType(this.ID_TOKEN_TYPE);

        if (currentClaims) {
            return currentClaims.hasOwnProperty('MF_UserName');
        } else {
            return false;
        }
    }
}

