"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var deal_sheet_data_service_1 = require("../../../../services/deal-sheet-data.service");
var core_2 = require("@uirouter/core");
var mobile_deal_sheet_data_caching_service_1 = require("../../../../services/mobile-deal-sheet-data-caching.service");
var rxjs_1 = require("rxjs");
var mobile_routes_1 = require("../mobile-routes");
var MobileVehiclesComponent = /** @class */ (function () {
    function MobileVehiclesComponent(dealSheetDataService, stateService, mobileDealSheetDataCachingService, transition, changeDetectorRef) {
        this.dealSheetDataService = dealSheetDataService;
        this.stateService = stateService;
        this.mobileDealSheetDataCachingService = mobileDealSheetDataCachingService;
        this.transition = transition;
        this.changeDetectorRef = changeDetectorRef;
        this.vehicleTypeKeys = Object.keys(constants_1.VehicleTypes);
        this.vehicleTypes = constants_1.VehicleTypes;
        this.saleTypeKeys = Object.keys(constants_1.SaleTypes);
        this.paymentTypes = constants_1.PaymentTypes;
        this.tradeValueTypes = constants_1.TradeValueTypes;
        this.opportunityId = this.transition.params().opportunityId;
    }
    MobileVehiclesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showPullForward = this.dealSheetDataService.shouldDisplayPullForward(this.opportunity);
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(function (dealSheetData) {
            _this.opportunity = dealSheetData.opportunity;
            _this.permissions = dealSheetData.permissions;
            _this.alertExists = dealSheetData.alertExists;
            _this.flexAlertExists = dealSheetData.flexAlertExists;
            _this.serviceAlertExists = dealSheetData.serviceAlertExists;
            _this.appointmentAlertExists = dealSheetData.appointmentAlertExists;
            _this.mileageAlertExists = dealSheetData.mileageAlertExists;
            _this.contractEndAlertExists = dealSheetData.contractEndAlertExists;
            _this.warrantyAlertExists = dealSheetData.warrantyAlertExists;
            _this.userProfile = dealSheetData.userProfile;
            _this.fuelEconomyComparison = dealSheetData.fuelEconomyComparison;
            _this.changeDetectorRef.detectChanges();
        });
        this.busy = this.opportunitySubscription;
    };
    MobileVehiclesComponent.prototype.ngOnDestroy = function () {
        this.opportunitySubscription.unsubscribe();
    };
    MobileVehiclesComponent.prototype.viewReplacementDetails = function () {
        this.stateService.go(mobile_routes_1.MobileRoutes.replacementDetails);
    };
    return MobileVehiclesComponent;
}());
exports.MobileVehiclesComponent = MobileVehiclesComponent;
