import { Component, OnInit, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { difference } from 'lodash';
import { SearchPresetService } from '../../../services/search-preset.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'search-preset-counts',
  templateUrl: './search-preset-counts.component-ng.html',
  styleUrls: ['./search-preset-counts.component.scss']
})
export class SearchPresetCountsComponent implements OnInit, OnChanges {
    @Input() widgetData: any;
    @Input() selectedDealerId: number;
    @Input() widgetState: any;
    @Output() widgetStateChange = new EventEmitter<any>();
    @Output() onRunDateChanged = new EventEmitter<any>();

    public readonly defaultRowLimit = 5;
    public readonly maxRowLimit = 50;

    setLimitPromise: any;


    constructor(
        private searchPresetService: SearchPresetService ) 
    { }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.widgetData && changes.widgetData.currentValue) {
            if (this.widgetData.presetCounts && this.widgetData.presetCounts.length > 0) {
                this.widgetData.presetCounts.sort(this.byOrder)
                this.onRunDateChanged.emit(this.widgetData.presetCounts[0].runDate );
            }
            if (this.widgetData.maxRowsDisplayed == null) {
                this.widgetData.maxRowsDisplayed = this.defaultRowLimit;
            }
        }

        if (changes.selectedDealerId && changes.selectedDealerId.currentValue) {
            if (this.widgetData == null) {
                this.widgetData = {};
            }
            this.widgetData.maxRowsDisplayed = this.defaultRowLimit;;
        }
        
    }

    
    increaseLimit() {
        const newMaxRowsDisplayed = this.maxRowLimit;
        if (this.widgetData == null) {
            this.widgetData = {};
        }
        this.widgetState = { maxRowsDisplayed: newMaxRowsDisplayed };
        this.widgetStateChange.emit(this.widgetState);

        const { searchPresets, unusedSearchPresets, presetCounts } = this.widgetData;
        const maxRemainingRows = Math.max(this.defaultRowLimit, newMaxRowsDisplayed - searchPresets.length);
        const searchPresetsToQuery = unusedSearchPresets.slice(0, maxRemainingRows);

        this.setLimitPromise =
            this.searchPresetService.getSearchPresetCounts(searchPresetsToQuery, false)
                .then(widgetData => {
                    this.widgetData = {
                        searchPresets: [...searchPresets, ...searchPresetsToQuery],
                        unusedSearchPresets: difference(unusedSearchPresets, searchPresetsToQuery),
                        presetCounts: [...presetCounts, ...widgetData.presetCounts],
                        maxRowsDisplayed: newMaxRowsDisplayed,
                    };
                });
    }

    private byOrder(searchPreset1: any, searchPreset2: any) {
        return searchPreset1.order - searchPreset2.order;
    }

}
