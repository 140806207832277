"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dealer_settings_service_1 = require("../../services/dealer-settings.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_1 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var models_1 = require("../../generated/models");
var AdminSettingsAdvancedComponent = /** @class */ (function () {
    function AdminSettingsAdvancedComponent(modalService, tService, logger, dsService) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.dsService = dsService;
        this.dsa = {};
    }
    AdminSettingsAdvancedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.UpdateSelfRegisrtationLink();
        this.dsService.GetTrim(this.dsa.dealerSetting.defaultTrimID).then(function (trim) {
            _this.dsService.GetTrims(trim.modelID).then(function (trims) {
                _this.trims = trims;
                _this.dsService.GetModel(trim.modelID).then(function (model) {
                    _this.dsService.GetModelsByMakeYear(model.makeID, model.yearID).then(function (models) {
                        _this.models = models;
                        _this.selectedModelId = trim.modelID;
                        _this.selectedMakeId = model.makeID;
                        _this.selectedYear = model.yearID;
                    });
                });
            });
        });
    };
    AdminSettingsAdvancedComponent.prototype.updateModelsTrims = function () {
        var _this = this;
        this.dsService.GetModelsByMakeYear(this.selectedMakeId, this.selectedYear).then(function (models) {
            if (models && models.length > 0) {
                _this.models = models;
                _this.selectedModelId = models[0].modelID;
                _this.updateTrims();
            }
            else {
                _this.models = null;
                _this.trims = null;
            }
        });
    };
    AdminSettingsAdvancedComponent.prototype.updateTrims = function () {
        var _this = this;
        this.dsService.GetTrims(this.selectedModelId).then(function (trims) {
            if (trims && trims.length > 0) {
                _this.trims = trims;
                _this.dsa.dealerSetting.defaultTrimID = trims[0].trimID;
            }
            else {
                _this.trims = null;
            }
        });
    };
    AdminSettingsAdvancedComponent.prototype.GetTradeInSource = function () {
        if (this.dsa.dealerSetting.extTradeValueSourceID == 0) {
            return 'Source: AutoAlert';
        }
        else if (this.dsa.dealerSetting.extTradeValueSourceID == 1) {
            switch (this.dsa.dealerSetting.extTradeValueTier) {
                case 1:
                    return 'dsAdmin_sourceBBClean';
                case 2:
                    return 'dsAdmin_sourceBBAverage';
                case 3:
                    return 'dsAdmin_sourceBBRough';
            }
        }
        else if (this.dsa.dealerSetting.extTradeValueSourceID == 2) {
            return 'dsAdmin_sourceGalves';
        }
    };
    AdminSettingsAdvancedComponent.prototype.UpdateSelfRegisrtationLink = function () {
        this.selfRegistrationLink = window.location.origin + '/register/' + this.dsa.dealer.dealerCode + '?c=' + this.dsa.dealerSetting.registrationCode;
    };
    return AdminSettingsAdvancedComponent;
}());
exports.AdminSettingsAdvancedComponent = AdminSettingsAdvancedComponent;
