import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Customer } from '../../../interfaces/customer.interface';
import { DealSheetOpportunity } from '../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { ActivityActionEnum } from '../../../constants';
import { ScheduleModalParam } from './ScheduleModalParam.interface';
import { CollapseDirective } from 'ngx-bootstrap/collapse';

@Component({
    selector: 'customer-connect',
    templateUrl: './customer-connect.component-ng.html',
    styleUrls: ['./customer-connect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectComponent implements OnInit, OnChanges {

    @Input() isCustomerConnectOpen: boolean;
    @Input() opportunity: DealSheetOpportunity;
    @Input() customer: Customer;
    @Input() users: any[];
    @Input() maxScheduleFutureDays: number;
    @Input() permissions: DealSheetPermissions;
    @Input() defaultCustomerConnectAction: any;
    @Output() onLogScheduleSubmit = new EventEmitter<any>();
    @Output() onCustomerEdit = new EventEmitter<any>();
    @Input() isEuroLite: boolean;
    @Input() isTextingEnabled: boolean;
    @Input() mobileView: boolean;

    @ViewChild('collapse', { static: true }) collapse: CollapseDirective;

    public ActivityActionEnum = ActivityActionEnum;
    public scheduleModalParamObject: ScheduleModalParam;
    public action: any;
    public actionTime: number;

    constructor(private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        // init defaults
        this.scheduleModalParamObject = {
            modalType: null,
            opportunityId: this.opportunity && this.opportunity.id ? this.opportunity.id : null,
            dealerId: this.opportunity && this.opportunity.customer ? this.opportunity.customer.dealerID : null,
            dealSheetOwnerId: this.opportunity && this.opportunity.assignedUserId ? this.opportunity.assignedUserId : null,
            maxScheduleFutureDays: this.maxScheduleFutureDays,
            usersObject: this.users,
            activityObject: null,
            ownerUserInfoObject: this.opportunity && this.opportunity.ownerUserInfo ? this.opportunity.ownerUserInfo : null,
            expirationDate: this.opportunity && this.opportunity.expirationDate ? this.opportunity.expirationDate : null,
            uid: this.opportunity && this.opportunity.uid ? this.opportunity.uid : null
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.opportunity && changes.opportunity.currentValue
            || changes.users && changes.users.currentValue
            || changes.maxScheduleFutureDays && changes.maxScheduleFutureDays.currentValue
            || changes.opportunity && changes.opportunity.currentValue && changes.opportunity.currentValue.assignedUserId)
            && (this.opportunity && this.users && this.maxScheduleFutureDays)) {

            this.scheduleModalParamObject = {
                modalType: null,
                opportunityId: this.opportunity && this.opportunity.id ? this.opportunity.id : null,
                dealerId: this.opportunity && this.opportunity.customer ? this.opportunity.customer.dealerID : null,
                dealSheetOwnerId: this.opportunity && this.opportunity.assignedUserId ? this.opportunity.assignedUserId : null,
                maxScheduleFutureDays: this.maxScheduleFutureDays,
                usersObject: this.users,
                activityObject: null,
                ownerUserInfoObject: this.opportunity && this.opportunity.ownerUserInfo ? this.opportunity.ownerUserInfo : null,
                expirationDate: this.opportunity && this.opportunity.expirationDate ? this.opportunity.expirationDate : null,
                uid: this.opportunity && this.opportunity.uid ? this.opportunity.uid : null
            };
        }

        if (changes.isCustomerConnectOpen && changes.isCustomerConnectOpen.currentValue) {
            // always default to Log Call when opening Customer Connect
            if (!this.action) {
                this.onActionSelect(this.defaultCustomerConnectAction);
            }

            this.collapse.show();
        }
    }

    onActionSelect(actionSelected) {
        this.actionTime = new Date().getTime();
        this.action = actionSelected;
        this.changeDetectorRef.detectChanges();
    }
}
