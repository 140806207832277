PrintOfferController.$inject = ["$q", "printOfferBatchService"];

/* injects from baggage-loader */
require('./print-offer.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('printOffer', {
  templateUrl: '/app/components/deal-sheet/print-offer.component.html',
  controller: PrintOfferController,
  bindings: {
    opportunityId: '<',
    showWarning: '<',
    onStart: '&',
    onFinish: '&',
    onActivityUpdate: '&'
  }
});
/* @ngInject */

function PrintOfferController($q, printOfferBatchService) {
  var $ctrl = this;
  $ctrl.showWarningMessage = showWarningMessage;
  $ctrl.printOffer = printOffer;

  function showWarningMessage() {
    if ($ctrl.showWarning) {
      return $q.resolve(true);
    } else {
      printOffer();
      return $q.resolve(false);
    }
  }

  function printOffer() {
    $ctrl.onStart();
    return printOfferBatchService.initiatePrint($ctrl.opportunityId).then(function (data) {
      $ctrl.onFinish();
      $ctrl.onActivityUpdate({
        $event: {
          data: data
        }
      });
    });
  }
}