<div class="modal-title-bar" *ngIf="includeTitleBar">
    <div class="modal-title-bar-container">

        <div class="modal-header-icon">
            <ng-content select="modal-header-icon"></ng-content>
        </div>

        <h5 [class]="headerTextClass" [innerHtml]="translatedHeaderTitle"></h5>

        <div class="modal-header-extras">
            <ng-content select="modal-header"></ng-content>
        </div>

        <div class="modal-header-buttons" *ngIf="closeIconVisible == null || closeIconVisible == true" (click)="onClose.emit()">
            <icon class="icon aa-icon-close-icon" title="{{ closeButtonText ? closeButtonText : 'close' | translate }}"></icon>
        </div>

    </div>
</div>

<div class="modal-body form-horizontal">
    <div>
        <div class="row">
            <div class="col-sm-12">
                <ng-content select="modal-body"></ng-content>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-span pull-right">
        <button class="btn btn-primary qa-modal-btn-cancel" *ngIf="closeButtonVisible" (click)="onClose.emit()" 
                translate="{{closeButtonText ? closeButtonText : 'cancel'}}"></button>
        <button class="btn btn-primary qa-modal-btn-submit" *ngIf="submitButtonVisible" [disabled]="submitButtonDisabled" (click)="onSubmit.emit()" 
                translate="{{submitButtonText ? submitButtonText : 'submit'}}"></button>
    </div>
    <div class="dynamic-buttons">
        <ng-content select="modal-footer"></ng-content>
    </div>
</div>
