HeaderController.$inject = ["dealerService", "$scope", "environmentVariables", "featureToggleService"];

/* injects from baggage-loader */
require('./header.component.html');

import angular from 'angular';
import { ProductTypes as productTypes } from '../../constants';
import { OpportunitiesModule } from '../../core/opportunities.module';
OpportunitiesModule.component('aaHeader', {
  templateUrl: '/app/components/header/header.component.html',
  controller: HeaderController,
  bindings: {
    allowChangingDealer: '<',
    isEulaAccepted: '<',
    isHeaderVisible: '<',
    selectedTab: '<',
    liveSupportDomain: '<',
    menu: '<',
    overrideLogoPath: '<',
    userFullName: '<',
    userMenuItems: '<',
    isAutoAlertUser: '<',
    isAuthenticated: '<',
    onDealersChanged: '&',
    onCustomerEngagementClicked: '&',
    productId: '<'
  },
  transclude: {
    'userContext': '?headerUserContext',
    'tabBarGutter': '?headerTabBarGutter',
    'subMenu': '?headerSubMenu'
  }
});

function HeaderController(dealerService, $scope, environmentVariables, featureToggleService) {
  "ngInject";

  var $ctrl = this;
  $ctrl.$onInit = $onInit;
  $ctrl.$onChanges = $onChanges;
  $ctrl.productTypes = productTypes;

  function $onInit() {
    $ctrl.pandoInboxEnabled = environmentVariables.pandoInboxEnabled;
    $ctrl.enableCustomerEngagementWidget = featureToggleService.customerEngagementWidget();
  }

  function $onChanges(changes) {
    if (changes.overrideLogoPath) {
      // The binding is `overrideLogoPath` instead of just `logoPath`
      // becuase we would rather let the header choose to fall back
      // to the default header, rather than forcing the app send in
      // the default header's path. But, if we do that, this happens:
      //
      // 1. This component renders bound to `undefined`, so it falls
      //    back to the default logo.
      // 2. The app resolves a promise and discovers this is a Ford user,
      //    so it changes the binding to the fordLogoPath.
      // 3. This component re-renders with the ford Log.
      //
      // So until we can find a way to fix this,  we have the app
      // supplying both the default and Ford logo paths.
      $ctrl.logoPath = $ctrl.overrideLogoPath;
      $ctrl.pandoInboxEnabled = environmentVariables.pandoInboxEnabled;
    }
  }
}