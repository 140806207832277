import angular from 'angular';

angular.module('AaApp.Opp').constant('euroOpportunityGridLayout', [
    { 
        'group': 'ranking', 
        'displayName': 'Ranking',
        'hide': true,
        'fields': [
            { 
                "name": "_Opportunity_Rank",
                "db_name": "_Opportunity",
                "displayname": "Score",
                "key": "score",
                "isChecked": true,
                "selectable": false
            }
        ]
    },
    { 
        'group': 'customerLabel', 
        'displayName': 'Customer',
        'fields': [
            { 
                "name": "full_Name",
                "db_name": "Full Name",
                "displayname": "Full Name",
                "key": "fullName",
                "isChecked": true,
                "selectable": true
            },
            { 
                "name": "customer_Owner",
                "db_name": "Customer Owner",
                "displayname": "Client Owner",
                "key": "clientOwner",
                "isChecked": true,
                "selectable": true
            },
            {
                "name": "home_Phone",
                "db_name": "Home Phone",
                "displayname": "Home Phone",
                "key": "phone1",
                "isChecked": false,
                "selectable": true

            },
            {
                "name": "cell_Phone",
                "db_name": "Cell Phone",
                "displayname": "Cell Phone",
                "key": "phone2",
                "isChecked": true,
                "selectable": true
            }				
        ]
    },
    { 
        'group': 'dealinfoLabel', 
        'displayName': 'Deal Info',
        'fields': [
            {
                "name": "sale_Type",
                "db_name": "Sale Type",
                "displayname": "Sale Type",
                "key": "saleType",
                "isChecked": true,
                "selectable": true
            },
            {
                "name": "dmS_Deal_ID",
                "db_name": "DMS Deal ID",
                "displayname": "Contract ID",
                "key": "dmsDealId",
                "isChecked": false,
                "selectable": true
            }
        ]
    },
    { 
        'group': 'vehicleLabel', 
        'displayName': 'Vehicle',
        'fields': [
            { 
                "name": "current_Model_Name",
                "db_name": "Current Model Name",
                "displayname": "Model",
                "key": "model",
                "isChecked": true,
                "selectable": true
            },			
            { 
                "name": "current_Model_Year",
                "db_name": "Current Model Year",
                "displayname": "Year",
                "key": "year",
                "isChecked": true,
                "selectable": true
            }		
        ]
    },
    { 
            'group': 'opportunityCategoriesLabel', 
            'displayName': 'Opportunity Categories',
            'fields': [
                { 
                    "name": "Categories",
                    "db_name": "__Categories__",
                    "displayname": "Categories",
                    "key": "categories",
                    "isChecked": true,
                    "selectable": true
                }
    ]
    },	
    { 
        'group': 'calculationLabel', 
        'displayName': 'Calculation',
        'fields': [
            { 
                "name": "payment_Remaining",
                "db_name": "Payment Remaining",
                "displayname": "Payments Remaining",
                "key": "paymentsLeftToolTip",
                "isChecked": true,
                "selectable": true
            },
			
            { 
                "name": "payments_Made",
                "db_name": "Payments Made",
                "displayname": "Payments Made",
                "key": "paymentsMadeToolTip",
                "isChecked": true,
                "selectable": true
            },
			
            { 
                "name": "payment_Difference__",
                "db_name": "Payment Difference %",
                "displayname": "Pmt. Diff. %",
                "key": "paymentsDifferenceToolTip",
                "isChecked": true,
                "selectable": true
            }			
        ]
    },
    { 
            'group': 'recentActivityLabel', 
            'displayName': 'Recent Activity',
            'fields': [
                { 
                    "name": "performed_On",
                    "db_name": "Performed On",
                    "displayname": "Activity Date",
                    "key": "lastActivityToolTip",
                    "isChecked": true,
                    "selectable": true
                }			
        ]
    },
]);