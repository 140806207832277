"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var ExtendedWarrantyComponent = /** @class */ (function () {
    function ExtendedWarrantyComponent() {
        this.conquestTypes = constants_1.ConquestTypes;
        this.saleTypes = constants_1.SaleTypes;
        this.vehicleTypes = constants_1.VehicleTypes;
    }
    ExtendedWarrantyComponent.prototype.ngOnInit = function () {
    };
    ExtendedWarrantyComponent.prototype.getWarrantyStatus = function () {
        if (this.contract && this.contract.hasExtendedWarranty) {
            return 'EXTENDED';
        }
        if (this.vehicle && this.vehicle.warrantyMilesRemaining && this.vehicle.warrantyMonthsRemaining) {
            return 'ACTIVE';
        }
        if (this.vehicle && (this.vehicle.warrantyMilesRemaining === 0 || this.vehicle.warrantyMonthsRemaining === 0)) {
            return 'EXPIRED';
        }
        if (this.contract && this.contract.warrantyMilesRemaining && this.contract.warrantyMonthsRemaining) {
            return 'ACTIVE';
        }
        if (this.contract) {
            if (this.contract.warrantyMonthsRemaining === undefined && this.contract.warrantyMilesRemaining === undefined) {
                return 'NONE';
            }
            else if (this.contract.warrantyMonthsRemaining === false && this.contract.warrantyMilesRemaining === false) {
                return 'EXPIRED';
            }
        }
    };
    return ExtendedWarrantyComponent;
}());
exports.ExtendedWarrantyComponent = ExtendedWarrantyComponent;
