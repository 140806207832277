/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TagExtDto } from '../models/tag-ext-dto';
import { TagPivotItemDto } from '../models/tag-pivot-item-dto';
import { TagDto } from '../models/tag-dto';
import { ActiveTagsRequestDto } from '../models/active-tags-request-dto';
@Injectable({
  providedIn: 'root',
})
class TagClientService extends __BaseService {
  static readonly GetTagsForEntityByEntityidGETPath = '/api/Tag/GetTagsForEntity/{entityId}';
  static readonly GetTagsPivotForDealersGETPath = '/api/Tag/GetTagsPivotForDealers';
  static readonly IsTagActivePast6MonthsByDealeridByTagtextGETPath = '/api/Tag/IsTagActivePast6Months/{dealerId}/{tagText}';
  static readonly GetTagsForDealersByDealeridGETPath = '/api/Tag/GetTagsForDealers/{dealerId}';
  static readonly GetActiveTagsInDateRangePOSTPath = '/api/Tag/GetActiveTagsInDateRange';
  static readonly GetActiveTagsInDateRangeForDealerByDealeridPOSTPath = '/api/Tag/GetActiveTagsInDateRangeForDealer/{dealerId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetTagsForEntityByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<Array<TagExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tag/GetTagsForEntity/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagExtDto>>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetTagsForEntityByEntityidGET(entityId: string): __Observable<Array<TagExtDto>> {
    return this.GetTagsForEntityByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as Array<TagExtDto>)
    );
  }

  /**
   * @param flushCache undefined
   * @return Success
   */
  GetTagsPivotForDealersGETResponse(flushCache?: boolean): __Observable<__StrictHttpResponse<Array<TagPivotItemDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (flushCache != null) __params = __params.set('flushCache', flushCache.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tag/GetTagsPivotForDealers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagPivotItemDto>>;
      })
    );
  }
  /**
   * @param flushCache undefined
   * @return Success
   */
  GetTagsPivotForDealersGET(flushCache?: boolean): __Observable<Array<TagPivotItemDto>> {
    return this.GetTagsPivotForDealersGETResponse(flushCache).pipe(
      __map(_r => _r.body as Array<TagPivotItemDto>)
    );
  }

  /**
   * @param params The `TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETParams` containing the following parameters:
   *
   * - `tagText`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  IsTagActivePast6MonthsByDealeridByTagtextGETResponse(params: TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tag/IsTagActivePast6Months/${params.dealerId}/${params.tagText}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETParams` containing the following parameters:
   *
   * - `tagText`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  IsTagActivePast6MonthsByDealeridByTagtextGET(params: TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETParams): __Observable<boolean> {
    return this.IsTagActivePast6MonthsByDealeridByTagtextGETResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetTagsForDealersByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<TagDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tag/GetTagsForDealers/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TagDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetTagsForDealersByDealeridGET(dealerId: number): __Observable<Array<TagDto>> {
    return this.GetTagsForDealersByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<TagDto>)
    );
  }

  /**
   * @param request undefined
   */
  GetActiveTagsInDateRangePOSTResponse(request?: ActiveTagsRequestDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Tag/GetActiveTagsInDateRange`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  GetActiveTagsInDateRangePOST(request?: ActiveTagsRequestDto): __Observable<null> {
    return this.GetActiveTagsInDateRangePOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `request`:
   */
  GetActiveTagsInDateRangeForDealerByDealeridPOSTResponse(params: TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Tag/GetActiveTagsInDateRangeForDealer/${params.dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `request`:
   */
  GetActiveTagsInDateRangeForDealerByDealeridPOST(params: TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTParams): __Observable<null> {
    return this.GetActiveTagsInDateRangeForDealerByDealeridPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TagClientService {

  /**
   * Parameters for IsTagActivePast6MonthsByDealeridByTagtextGET
   */
  export interface IsTagActivePast6MonthsByDealeridByTagtextGETParams {
    tagText: string;
    dealerId: number;
  }

  /**
   * Parameters for GetActiveTagsInDateRangeForDealerByDealeridPOST
   */
  export interface GetActiveTagsInDateRangeForDealerByDealeridPOSTParams {
    dealerId: number;
    request?: ActiveTagsRequestDto;
  }
}

export { TagClientService }
