import "core-js/modules/es6.array.sort";
CustomerEngagementWidgetController.$inject = ["$logFactory", "$timeout", "$uibModal", "customerEngagementService"];

/* injects from baggage-loader */
require('./customer-engagement-widget.component.html');

import angular from 'angular';
import { OpportunitiesModule } from '../../core/opportunities.module';
OpportunitiesModule.component('customerEngagementWidget', {
  templateUrl: '/app/components/header/customer-engagement-widget.component.html',
  controller: CustomerEngagementWidgetController,
  bindings: {
    onCustomerEngagementClicked: '&'
  }
});

function CustomerEngagementWidgetController($logFactory, $timeout, $uibModal, customerEngagementService) {
  "ngInject";

  var $ctrl = this;
  var $log = $logFactory.get("customerEngagementWidget");
  $ctrl.hoursBack = 24;
  $ctrl.filters = {};
  $ctrl.engagements = [];
  $ctrl.newEngagementsCount = 0;
  $ctrl.lastViewedDate;
  $ctrl.$onInit = $onInit;
  $ctrl.show = show;
  $ctrl.onHoursBackChanged = _onHoursBackChanged;

  function $onInit() {
    $timeout(checkForNewEngagements);
    var subscription = customerEngagementService.newEngagements.subscribe(function (evt) {
      var engagementTime = new Date(evt.data.engagementTimeUtc);

      if (!$ctrl.lastViewedDate || engagementTime > $ctrl.lastViewedDate) {
        $log.debug("incremented count");
        $ctrl.newEngagementsCount = $ctrl.newEngagementsCount + 1;
      }
    });

    this.$onDestroy = function () {
      return subscription.unsubscribe();
    };
  }

  function checkForNewEngagements() {
    customerEngagementService.getCustomerEngagement($ctrl.hoursBack).then(function (_ref) {
      var lastViewedDate = _ref.lastViewedDate,
          engagements = _ref.engagements;
      $log.debug("getCustomerEngagement:", {
        lastViewedDate: lastViewedDate,
        engagements: engagements
      });
      $ctrl.lastViewedDate = lastViewedDate;
      $ctrl.engagements = sortEngagementsByDateDescending(engagements);
      $ctrl.newEngagementsCount = getNewEngagementsCount($ctrl.engagements);
    });

    function getNewEngagementsCount(engagements) {
      return engagements.filter(function (e) {
        return e.lastViewedCount > 0;
      }).reduce(function (total, engagement) {
        return total + engagement.lastViewedCount;
      }, 0);
    }
  }

  function show() {
    // Tell the server we've viewed everything, then mark it all as viewed
    // on our end
    customerEngagementService.markCustomerEngagementsViewed().then(function () {
      $ctrl.newEngagementsCount = 0;
      customerEngagementService.getCustomerEngagementLastViewed().then(function (lastViewed) {
        $ctrl.lastViewedDate = new Date(lastViewed.lastViewedDateUTC);
        $log.debug("new last viewed:", $ctrl.lastViewedDate);
      });
    });
    $uibModal.open({
      component: 'customerEngagementWidgetWindow',
      size: 'mdsm',
      // medium-small
      resolve: {
        engagements: function engagements() {
          return $ctrl.engagements;
        },
        hoursBack: function hoursBack() {
          return $ctrl.hoursBack;
        },
        onHoursBackChanged: function onHoursBackChanged() {
          return _onHoursBackChanged;
        },
        onCustomerEngagementClicked: function onCustomerEngagementClicked() {
          return $ctrl.onCustomerEngagementClicked;
        }
      }
    });
  }

  function _onHoursBackChanged(_ref2) {
    var engagements = _ref2.engagements,
        hoursBack = _ref2.hoursBack;
    $ctrl.engagements = engagements;
    $ctrl.hoursBack = hoursBack;
  }
}

export function sortEngagementsByDateDescending(engagements) {
  return engagements.slice().sort(function (a, b) {
    // subtracting Dates gives you the difference in milliseconds
    return b.eventDate - a.eventDate;
  });
}