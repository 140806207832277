export enum AdminSettingTabs {
    General = 1,
    Programs = 2,
    Opportunity = 3,
    Inventory = 4,
    DealerInfo = 5,
    Advanced = 6,
    CreditConvert = 7,
    Product = 8,
    CRMLeadsDelivery = 9
}
