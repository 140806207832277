"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".recall-single-wrapper[_ngcontent-%COMP%]{margin-top:10px;margin-bottom:10px;padding:0 15px 10px}.recall-list-wrapper[_ngcontent-%COMP%]{overflow-y:scroll;height:450px;margin-top:10px;margin-bottom:10px;padding:0;border:1px solid #e7e7e7;border-radius:10px}.recall-list-wrapper[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]{list-style-type:none;margin:0;padding:0}.recall-list-wrapper[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%] > [_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.recall-list-wrapper[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{padding:0 8px 10px;border-bottom:2px solid #ccc}.recall-list-wrapper[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:last-child{border:none}.vinRecall[_ngcontent-%COMP%]   .titleMainLabel[_ngcontent-%COMP%]{font-size:18px;font-weight:700;color:#1f8bd5}.vinRecall[_ngcontent-%COMP%]   .titleMainBody[_ngcontent-%COMP%]{font-size:14px;font-weight:700}.vinRecall[_ngcontent-%COMP%]   .header-container[_ngcontent-%COMP%]{margin-left:0;margin-right:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap}.vinRecall[_ngcontent-%COMP%]   .flexitem[_ngcontent-%COMP%]{display:inline}.vinRecall[_ngcontent-%COMP%]   .oasisTooltip[_ngcontent-%COMP%]{font-size:12px}.recallItem[_ngcontent-%COMP%]{display:inline-block}"];
exports.styles = styles;
