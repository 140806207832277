import { OnInit, Component, Input, Inject } from '@angular/core';
import { UserService } from './../../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingResponseDto } from '../../generated/models';
import { DOCUMENT } from '@angular/common';
import { ProductTypes } from '../../constants';


@Component({
    selector: 'admin-settings-opportunity',
    templateUrl: './admin-settings-opportunity.component-ng.html'
})

export class AdminSettingsOpportunityComponent {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};

    public leaseContractEndRangeValid: Boolean = true;
    public purchaseContractEndRangeValid: Boolean = true;
    public productTypes = ProductTypes;
    public unitLabelKey: String;

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public userService: UserService,
        public logger: ToasterLoggerService,
        @Inject(DOCUMENT) private document
    ) {
    }

    public ngOnInit() {
        this.unitLabelKey = this.tService.instant((this.dsa.isMetric) ? "unitKilometers" : "unitMiles");
    }

    public validateContractEndRange() {
        this.leaseContractEndRangeValid = (this.dsa.dealerSetting.leaseContractEndRangeFrom <= this.dsa.dealerSetting.leaseContractEndRangeTo);
        this.purchaseContractEndRangeValid = (this.dsa.dealerSetting.purchaseContractEndRangeFrom <= this.dsa.dealerSetting.purchaseContractEndTo);
    }
}