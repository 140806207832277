"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetTypes = {
    contentFuze: 1,
    connectPro: 2,
    showroomPro: 3,
    comparePro: 4,
    servicePro: 5,
    spanishContentFuze: 6,
    vDPTool: 7,
    autoWebSupport: 9,
    reputationPro: 10,
    curatorPro: 11,
    curatorProPlus: 12,
    reviewBuilderPro: 13,
    websiteContentPage: 14,
    fuzeCast: 15,
    managedServices: 16,
    profileFuze: 17,
    alertMiner: 18,
    oneToOne: 19,
    noLongerOwns: 20,
    inMarketAlert: 21,
    galvesTradeValues: 22,
    geoAlert: 23,
    autoAssistant: 24,
    pandoX: 25,
    intelMarketing: 26,
    textMessaging: 27,
    inventoryCalculations: 28,
    pandoDsButtonsOverride: 29
};
