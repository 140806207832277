"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var AdminSettingsProductComponent = /** @class */ (function () {
    function AdminSettingsProductComponent(modalService, tService, logger) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.dsa = {};
        this.onSaveChanges = new core_1.EventEmitter();
    }
    return AdminSettingsProductComponent;
}());
exports.AdminSettingsProductComponent = AdminSettingsProductComponent;
