"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../generated/services/dealer-rates-client.service"));
var i2 = __importStar(require("../generated/services/dealer-rebate-client.service"));
var i3 = __importStar(require("../generated/services/dealer-price-client.service"));
var i4 = __importStar(require("../generated/services/dealer-tradein-client.service"));
var i5 = __importStar(require("../generated/services/replacement-client.service"));
var i6 = __importStar(require("../generated/services/trim-alternate-offer-client.service"));
var DataEntryService = /** @class */ (function () {
    function DataEntryService(dealerRatesClientService, dealerRebateClientService, dealerPriceClientService, dealerTradeInClientService, replacementClientService, trimAlternateOfferClientService) {
        this.dealerRatesClientService = dealerRatesClientService;
        this.dealerRebateClientService = dealerRebateClientService;
        this.dealerPriceClientService = dealerPriceClientService;
        this.dealerTradeInClientService = dealerTradeInClientService;
        this.replacementClientService = replacementClientService;
        this.trimAlternateOfferClientService = trimAlternateOfferClientService;
    }
    DataEntryService.prototype.deleteAllUsedRatesByDealerId = function (dealerId) {
        return this.dealerRatesClientService.DeleteAllUsedRatesByDealerIdPOSTResponse(dealerId).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    };
    DataEntryService.prototype.getDealerUsedRates = function (dealerId) {
        return this.dealerRatesClientService.GetDealerUsedRatesByDealeridGET(dealerId).toPromise();
    };
    DataEntryService.prototype.updateDealerUsedRates = function (usedRates) {
        return this.dealerRatesClientService.UpdateDealerUsedRatesPOSTResponse(usedRates).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    };
    DataEntryService.prototype.updateDealerOverAllowance = function (overAllowance) {
        return this.dealerRatesClientService.UpdateDealerOverAllowancePOSTResponse(overAllowance).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    };
    DataEntryService.prototype.updateDealerRateMarkUps = function (rates) {
        return this.dealerRatesClientService.UpdateDealerRateMarkUpsPOSTResponse(rates).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    };
    DataEntryService.prototype.getDealerOverAllowances = function (dealerId) {
        return this.dealerRatesClientService.GetDealerOverAllowancesByDealeridGET(dealerId).toPromise();
    };
    DataEntryService.prototype.getRateMarkUps = function (dealerId) {
        return this.dealerRatesClientService.GetDealerRateMarkUpsByDealeridGET(dealerId).toPromise();
    };
    DataEntryService.prototype.getDealerRebates = function (dealerId, makeId, year, modelId, trimId) {
        var params = { dealerId: dealerId, makeId: makeId, year: year, modelId: modelId, trimId: trimId };
        return this.dealerRebateClientService.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGET(params).toPromise();
    };
    DataEntryService.prototype.updateDealerRebates = function (rebates) {
        return this.dealerRebateClientService.UpdateDealerRebatesPATCH(rebates).toPromise();
    };
    DataEntryService.prototype.getDealerPrices = function (dealerId, makeId, year, modelId, trimId) {
        var params = { dealerId: dealerId, makeId: makeId, year: year, modelId: modelId, trimId: trimId };
        return this.dealerPriceClientService.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGET(params).toPromise();
    };
    DataEntryService.prototype.updateDealerPrices = function (prices) {
        return this.dealerPriceClientService.UpdateDealerPricesPATCH(prices).toPromise();
    };
    DataEntryService.prototype.resolveDealerDataEntryUpdate = function (response) {
        return response.status == 204;
    };
    // Dealer Trade Ins
    DataEntryService.prototype.updateDealerTradeins = function (tradeIns) {
        return this.dealerTradeInClientService.UpdateDealerTradeinsPATCH(tradeIns).toPromise();
    };
    DataEntryService.prototype.getDealerTradeIns = function (dealerId, makeId, yearId, modelId, trimId) {
        var params = { dealerId: dealerId, makeId: makeId, yearId: yearId, modelId: modelId, trimId: trimId };
        return this.dealerTradeInClientService.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGET(params).toPromise();
    };
    DataEntryService.prototype.getReplacementSpecifics = function (dealerId, filterByNew, makeId, yearId, modelId, trimId) {
        if (modelId == 0)
            modelId = null;
        if (trimId == 0)
            trimId = null;
        var params = { DealerId: dealerId, FilterByNew: filterByNew, MakeId: makeId, YearId: yearId, ModelId: modelId, TrimId: trimId };
        return this.replacementClientService.GetReplacementSpecificsGET(params).toPromise();
    };
    DataEntryService.prototype.getReplacementUpDown = function (dealerId, makeId, yearId, modelId, trimId) {
        if (modelId == 0)
            modelId = null;
        if (trimId == 0)
            trimId = null;
        var params = { DealerId: dealerId, MakeId: makeId, YearId: yearId, ModelId: modelId, TrimId: trimId };
        return this.replacementClientService.GetReplacementUpDownGET(params).toPromise();
    };
    DataEntryService.prototype.deleteTrimAlternateOffer = function (dealerID, baseTrimID, level) {
        var params = { dealerID: dealerID, baseTrimID: baseTrimID, level: level };
        return this.trimAlternateOfferClientService.ByDealeridByBasetrimidByLevelDELETE(params).toPromise();
    };
    DataEntryService.prototype.updateTrimAlternateOffer = function (dealerId, baseTrimId, level, alternateTrimID, retailTolerance, leaseTolerance) {
        var trimAlertnateOfferDto = {
            dealerID: dealerId,
            baseTrimID: baseTrimId,
            level: level,
            alternateTrimID: alternateTrimID,
            retailTolerance: retailTolerance,
            leaseTolerance: leaseTolerance
        };
        return this.trimAlternateOfferClientService.PUT(trimAlertnateOfferDto).toPromise();
    };
    DataEntryService.prototype.createTrimAlternateOffer = function (dealerId, baseTrimId, level, alternateTrimID, retailTolerance, leaseTolerance) {
        var trimAlertnateOfferDto = {
            dealerID: dealerId,
            baseTrimID: baseTrimId,
            level: level,
            alternateTrimID: alternateTrimID,
            retailTolerance: retailTolerance,
            leaseTolerance: leaseTolerance
        };
        return this.trimAlternateOfferClientService.POST(trimAlertnateOfferDto).toPromise();
    };
    DataEntryService.prototype.saveBulkReplacements = function (request, dealerId) {
        var params = {
            defaultDealerId: dealerId,
            request: request
        };
        return this.replacementClientService.SaveBulkReplacementsByDefaultdealeridPOST(params).toPromise();
    };
    DataEntryService.prototype.bulkDelete = function (trimIds, dealerID, isUpgrade) {
        var level = isUpgrade ? 100 : -100;
        var params = {
            dealerID: dealerID,
            level: level,
            trimIDs: trimIds
        };
        return this.trimAlternateOfferClientService.BulkDeletePOST(params).toPromise();
    };
    DataEntryService.prototype.bulkReplacementDelete = function (trimIds, dealerID) {
        var params = {
            dealerID: dealerID,
            trimIDs: trimIds
        };
        return this.replacementClientService.BulkDeletePOST(params).toPromise();
    };
    DataEntryService.prototype.bulkRemoveMappings = function (trimIds, dealerID, isUpgrade) {
        var level = isUpgrade ? 100 : -100;
        var params = {
            dealerID: dealerID,
            level: level,
            trimIDs: trimIds
        };
        return this.trimAlternateOfferClientService.BulkRemoveMappingsPOST(params).toPromise();
    };
    DataEntryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataEntryService_Factory() { return new DataEntryService(i0.ɵɵinject(i1.DealerRatesClientService), i0.ɵɵinject(i2.DealerRebateClientService), i0.ɵɵinject(i3.DealerPriceClientService), i0.ɵɵinject(i4.DealerTradeinClientService), i0.ɵɵinject(i5.ReplacementClientService), i0.ɵɵinject(i6.TrimAlternateOfferClientService)); }, token: DataEntryService, providedIn: "root" });
    return DataEntryService;
}());
exports.DataEntryService = DataEntryService;
