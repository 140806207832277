
/* injects from baggage-loader */
require('./bank-name.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('bankName', {
    templateUrl: '/app/components/deal-sheet/contract/bank-name.component.html',
    bindings: {
        value: '<',
        url: '<',
    }
});
