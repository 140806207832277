"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PaymentDifferenceComponent = /** @class */ (function () {
    function PaymentDifferenceComponent() {
        this.Math = Math;
        this.hasPaymentsPermission = false;
    }
    PaymentDifferenceComponent.prototype.ngOnInit = function () {
        this.paymentDifference = this.vehicle.payment - this.vehicle.existingPayment;
    };
    PaymentDifferenceComponent.prototype.getPaymentClass = function () {
        if (!this.hasPaymentsPermission) {
            return 'hidden';
        }
        var style = (this.paymentDifference < 0) ? 'payment-less' : 'payment-more';
        style += ' pull-right';
        return style;
    };
    PaymentDifferenceComponent.prototype.getPaymentDifferenceLabel = function () {
        return this.paymentDifference < 0 ? 'less' : 'more';
    };
    return PaymentDifferenceComponent;
}());
exports.PaymentDifferenceComponent = PaymentDifferenceComponent;
