/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StartListCustomerDto } from '../models/start-list-customer-dto';
import { ActiveWorkflowTemplateData } from '../models/active-workflow-template-data';
import { PandoFormRequestDto } from '../models/pando-form-request-dto';
import { PandoFormDto } from '../models/pando-form-dto';
@Injectable({
  providedIn: 'root',
})
class CustomerWorkflowClientService extends __BaseService {
  static readonly GetStartListCustomerByEntityidGETPath = '/api/CustomerWorkflow/GetStartListCustomer/{entityId}';
  static readonly StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTPath = '/api/CustomerWorkflow/StartCustomerWorkflow/{entityId}/{workflowTemplateId}';
  static readonly GetStartFormByStartableformtemplateidGETPath = '/api/CustomerWorkflow/GetStartForm/{startableFormTemplateId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetStartListCustomerByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<StartListCustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerWorkflow/GetStartListCustomer/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StartListCustomerDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetStartListCustomerByEntityidGET(entityId: string): __Observable<StartListCustomerDto> {
    return this.GetStartListCustomerByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as StartListCustomerDto)
    );
  }

  /**
   * @param params The `CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams` containing the following parameters:
   *
   * - `workflowTemplateId`:
   *
   * - `entityId`:
   *
   * - `form`:
   *
   * @return Success
   */
  StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTResponse(params: CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams): __Observable<__StrictHttpResponse<ActiveWorkflowTemplateData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.form;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CustomerWorkflow/StartCustomerWorkflow/${params.entityId}/${params.workflowTemplateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveWorkflowTemplateData>;
      })
    );
  }
  /**
   * @param params The `CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams` containing the following parameters:
   *
   * - `workflowTemplateId`:
   *
   * - `entityId`:
   *
   * - `form`:
   *
   * @return Success
   */
  StartCustomerWorkflowByEntityidByWorkflowtemplateidPOST(params: CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams): __Observable<ActiveWorkflowTemplateData> {
    return this.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTResponse(params).pipe(
      __map(_r => _r.body as ActiveWorkflowTemplateData)
    );
  }

  /**
   * @param startableFormTemplateId undefined
   * @return Success
   */
  GetStartFormByStartableformtemplateidGETResponse(startableFormTemplateId: string): __Observable<__StrictHttpResponse<PandoFormDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerWorkflow/GetStartForm/${startableFormTemplateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PandoFormDto>;
      })
    );
  }
  /**
   * @param startableFormTemplateId undefined
   * @return Success
   */
  GetStartFormByStartableformtemplateidGET(startableFormTemplateId: string): __Observable<PandoFormDto> {
    return this.GetStartFormByStartableformtemplateidGETResponse(startableFormTemplateId).pipe(
      __map(_r => _r.body as PandoFormDto)
    );
  }
}

module CustomerWorkflowClientService {

  /**
   * Parameters for StartCustomerWorkflowByEntityidByWorkflowtemplateidPOST
   */
  export interface StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams {
    workflowTemplateId: string;
    entityId: string;
    form?: PandoFormRequestDto;
  }
}

export { CustomerWorkflowClientService }
