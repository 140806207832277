import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { TranslateKeys } from '../../../../constants';
import { LocaleDatePipe } from '../../../../filters/locale-date.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'repair-orders',
    templateUrl: './repair-orders.component-ng.html',
    styleUrls: ['./repair-orders.component.scss']
})
export class RepairOrdersComponent implements OnInit, OnChanges {
    @Input() date: any;
    @Input() total: number;
    @Input() alertExists: boolean;
    @Input() abbreviate: boolean;

    public recentRepairOrder: string = "";
    public idUnique: string;

    constructor(private localeDate: LocaleDatePipe, private translateService: TranslateService) { }

    ngOnInit() {
        this.idUnique = 'repair-orders' + (Date.now() + (Math.floor(Math.random() * 1000))).toString();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date && !changes.date.isFirstChange() && changes.date.currentValue !== null) {
            let repairOrderDate = this.localeDate.transform(this.date);
            if (changes.total && changes.total.currentValue !== null) {
                let translateKey = this.getTranslateKey();
                this.recentRepairOrder = this.translateService.instant(translateKey, { latestDate: repairOrderDate, total: this.total });
            }
            else {
                this.recentRepairOrder = repairOrderDate;
            }
        }
    }

    private getTranslateKey(): any {
        let key;

        if (this.abbreviate) {
            key = TranslateKeys.recentRepairOrdersAbbreviatedKey;
        } else {
            key = TranslateKeys.recentRepairOrdersKey;

            if (this.total === 1) {
                key += TranslateKeys.SingularKey;
            }
        }

        return key;
    }

}
