import { Component, OnInit, Input } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { ConquestTypes, SaleTypes, VehicleTypes } from '../../../../constants';


@Component({
  selector: 'extended-warranty',
  templateUrl: './extended-warranty.component-ng.html',
  styleUrls: ['./extended-warranty.component.scss']
 })
export class ExtendedWarrantyComponent implements OnInit {

    @Input() contract: any;
    @Input() vehicle: any;
    @Input() alertExists: boolean;
    @Input() permissions: DealSheetPermissions;
    @Input() replacementVehicleType: any;

    public conquestTypes = ConquestTypes;
    public saleTypes = SaleTypes;
    public vehicleTypes = VehicleTypes;

   constructor() { }

   ngOnInit() {
   }

   public getWarrantyStatus(): any  {

    if (this.contract && this.contract.hasExtendedWarranty) {
        return 'EXTENDED';
    }

    if (this.vehicle && this.vehicle.warrantyMilesRemaining && this.vehicle.warrantyMonthsRemaining) {
        return 'ACTIVE';
    }

    if (this.vehicle && (this.vehicle.warrantyMilesRemaining === 0 || this.vehicle.warrantyMonthsRemaining === 0)) {
        return 'EXPIRED';
    }

    if (this.contract && this.contract.warrantyMilesRemaining && this.contract.warrantyMonthsRemaining) {
        return 'ACTIVE';
    }

    if (this.contract) {
        if (this.contract.warrantyMonthsRemaining === undefined && this.contract.warrantyMilesRemaining === undefined) {
            return 'NONE';
        } else if (this.contract.warrantyMonthsRemaining === false && this.contract.warrantyMilesRemaining === false) {
            return 'EXPIRED';
        }
    }
  }
 }
