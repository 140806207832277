"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../../../constants");
var AlertComponent = /** @class */ (function () {
    function AlertComponent() {
    }
    Object.defineProperty(AlertComponent.prototype, "typeDescriptor", {
        get: function () {
            var _this = this;
            if (this.alertTypeId == null)
                return;
            return constants_1.AlertTypeDescriptors.find(function (item) { return item.id === _this.alertTypeId.toString(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertComponent.prototype, "typeDescriptorTranslateKey", {
        get: function () {
            return this.typeDescriptor && this.typeDescriptor.translateKey || "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertComponent.prototype, "characterClassNames", {
        get: function () {
            switch (this.size) {
                case "small":
                    return "fa alerts-letterblock-sm";
                default:
                    return "fa alerts-letterblock";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertComponent.prototype, "alertClassNames", {
        get: function () {
            if (!this.typeDescriptor)
                return;
            switch (this.size) {
                case "small":
                    return "fa " + this.typeDescriptor.cssClass + " " + this.typeDescriptor.cssClass + "-sm";
                default:
                    return "fa " + this.typeDescriptor.cssClass;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AlertComponent;
}());
exports.AlertComponent = AlertComponent;
