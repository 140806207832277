SearchSaveModalController.$inject = ["dealerService", "userService", "authorizationService", "authorizationKeys"];

/* injects from baggage-loader */
require('./search-save-modal.component.html');

import angular from 'angular';
import './search-save-modal.component.scss';
import { PandoXRuleCategory } from '../../constants';
import { roundWithPrecision } from '../../utilities/math';
angular.module('AaApp.Opp').component('searchSaveModal', {
  templateUrl: '/app/components/search/search-save-modal.component.html',
  controller: SearchSaveModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function SearchSaveModalController(dealerService, userService, authorizationService, authorizationKeys) {
  var $ctrl = this;
  $ctrl.submit = submit;
  $ctrl.$onInit = onInit;
  $ctrl.pandoXCategoryChange = pandoXCategoryChange;
  $ctrl.autoShareSearchClicked = autoShareSearchClicked;
  $ctrl.pandoXRuleQuantityChanged = pandoXRuleQuantityChanged;
  $ctrl.selectedDealerId;
  $ctrl.iframeUrl;
  $ctrl.savedSearchName;
  $ctrl.autoShareSearch = false;
  $ctrl.autoShareSearchPosition = "1";
  $ctrl.hasShareSearchAccess = false;
  $ctrl.hasManageSearchAccess = false;
  $ctrl.assignPandoXRule = false;
  $ctrl.pandoXError = false;
  $ctrl.errorTooSmall = false;
  $ctrl.errorTooBig = false;
  $ctrl.assignPandoXRule = false;
  $ctrl.pandoXRuleCategory = PandoXRuleCategory;
  $ctrl.pandoXRuleQuantity;
  $ctrl.executePandoXImmediately;
  $ctrl.pandoXRuleCategoryId;
  $ctrl.isDealerPandoXEnabled = false;
  $ctrl.datePickerOptions = {
    startingDay: 0,
    showWeeks: false,
    minDate: null,
    maxDate: null
  };
  $ctrl.selectedValueFirstCSSClass = "valid";
  $ctrl.selectedValueLastCSSClass = "valid";
  $ctrl.useActiveDates = false;
  $ctrl.searchActiveStartDate = null;
  $ctrl.searchActiveEndDate = null;

  function onInit() {
    if ($ctrl.resolve.data.selectedDealerId === undefined) {
      $ctrl.selectedDealerId = 0;
    } else {
      $ctrl.selectedDealerId = $ctrl.resolve.data.selectedDealerId;
    }

    if ($ctrl.resolve.data.searchConditionName.length > 4) {
      $ctrl.savedSearchName = $ctrl.resolve.data.searchConditionName;
    }

    var continueLoop = true;
    var dealers = $ctrl.selectedDealerId === 0 ? $ctrl.resolve.data.dealers : [{
      id: $ctrl.selectedDealerId
    }];

    for (var index = 0; index < dealers.length; index++) {
      var dealerId = dealers[index].id;

      if (!continueLoop) {
        break;
      }

      dealerService.getIsDealerPandoXEnabled(dealerId).then(function (result) {
        if (result === true) {
          $ctrl.isDealerPandoXEnabled = result;
          continueLoop = false;
        }
      });
    }

    userService.getUserProfile().then(function (userProfile) {
      $ctrl.hasManageSearchAccess = authorizationService.isAuthorized(authorizationKeys.ManageSearches) || userProfile.employeeUser;
      $ctrl.hasShareSearchAccess = authorizationService.isAuthorized(authorizationKeys.ShareSearch) || userProfile.employeeUser;
    });
  }

  function autoShareSearchClicked(newValue) {
    if (newValue === false) {
      $ctrl.assignPandoXRule = false;
    }
  }

  function pandoXRuleQuantityChanged(newQuantity) {
    if (newQuantity >= 100) {
      $ctrl.pandoXRuleQuantity = 100;
      return;
    }

    $ctrl.pandoXRuleQuantity = roundWithPrecision(newQuantity, 0);
  }

  function pandoXCategoryChange() {
    $ctrl.pandoXRuleQuantity = 10;
  }

  function submit() {
    if (isInputValid()) {
      if (!$ctrl.autoShareSearch) {
        $ctrl.assignPandoXRule = null;
        $ctrl.pandoXRuleQuantity = null;
        $ctrl.executePandoXImmediately = null;
        $ctrl.pandoXRuleCategoryId = null;
      }

      if (!$ctrl.useActiveDates) {
        $ctrl.searchActiveStartDate = null;
        $ctrl.searchActiveEndDate = null;
      }

      $ctrl.close({
        $value: {
          savedSearchName: $ctrl.savedSearchName,
          autoShareSearch: $ctrl.autoShareSearch,
          autoShareSearchPosition: $ctrl.autoShareSearchPosition,
          assignPandoXRule: $ctrl.assignPandoXRule,
          pandoXRuleQuantity: $ctrl.pandoXRuleQuantity,
          executePandoXImmediately: $ctrl.executePandoXImmediately,
          pandoXRuleCategoryId: $ctrl.pandoXRuleCategoryId,
          searchActiveStartDate: $ctrl.searchActiveStartDate,
          searchActiveEndDate: $ctrl.searchActiveEndDate
        }
      });
    }
  }

  function isInputValid() {
    $ctrl.errorTooSmall = false;
    $ctrl.errorTooBig = false;
    $ctrl.pandoXError = false;
    $ctrl.invalidDates = false;

    if ($ctrl.savedSearchName === undefined || $ctrl.savedSearchName.length < 4) {
      $ctrl.errorTooSmall = true;
      return false;
    }

    if ($ctrl.savedSearchName.length > 64) {
      $ctrl.errorTooBig = true;
      return false;
    }

    if ($ctrl.assignPandoXRule) {
      if (!$ctrl.pandoXRuleCategoryId || !$ctrl.pandoXRuleQuantity) {
        $ctrl.pandoXError = true;
        return false;
      }

      if ($ctrl.pandoXRuleQuantity < 1 || $ctrl.pandoXRuleQuantity > 100) {
        return false;
      }
    }

    if ($ctrl.useActiveDates) {
      if ($ctrl.searchActiveStartDate != null && $ctrl.searchActiveEndDate != null && new Date($ctrl.searchActiveEndDate) < new Date($ctrl.searchActiveStartDate)) {
        $ctrl.invalidDates = true;
        return false;
      }
    }

    return true;
  }
}