/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import { StoreVehicleUserTradeValueDto } from '../models/store-vehicle-user-trade-value-dto';
import { VehicleUserTradeValueDto } from '../models/vehicle-user-trade-value-dto';

@Injectable({
    providedIn: 'root',
})
class VehicleUserTradeValueClientService extends __BaseService {
    static readonly StoreVehicleUserTradeValuePath = '/api/VehicleUserTradeValue/StoreVehicleUserTradeValue';
    static readonly GetVehicleUserTradeValuePath = '/api/VehicleUserTradeValue/GetVehicleUserTradeValue';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param model undefined
     */
    StoreVehicleUserTradeValueResponse(model?: StoreVehicleUserTradeValueDto): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = model;

        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/VehicleUserTradeValue/StoreVehicleUserTradeValue`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    StoreVehicleUserTradeValue(model?: StoreVehicleUserTradeValueDto): __Observable<null> {
        return this.StoreVehicleUserTradeValueResponse(model).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
   * @param id undefined
   * @return Success
   */
    GetVehicleUserTradeValueResponse(id: number): __Observable<__StrictHttpResponse<VehicleUserTradeValueDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/VehicleUserTradeValue/GetVehicleUserTradeValue/${id}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<VehicleUserTradeValueDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    GetVehicleUserTradeValue(id: number): __Observable<VehicleUserTradeValueDto> {
        return this.GetVehicleUserTradeValueResponse(id).pipe(
            __map(_r => _r.body as VehicleUserTradeValueDto)
        );
    }

}

module VehicleUserTradeValueClientService {

}

export { VehicleUserTradeValueClientService }
