import angular from 'angular';
import _ from 'lodash';

angular.module('AaApp.Opp').factory('printOfferBatchService', printOfferBatchService);

/* @ngInject */
function printOfferBatchService($document, $q, $timeout, $translate, $uibModal, $window, coreApi, logger) {
    var service = {
        initiatePrint: initiatePrint,
        getByBatch: getByBatch
    };

    return service;

    function getByBatch(batchId) {
        return coreApi.api.PrintOffer.GetByBatch['{batchId}'].GET({ batchId: batchId })
            .then(function (response) {
                return response.data;
            });
    }

    function initiatePrint(entityIds) {
        if (!entityIds) {
            return $q.reject("No entity IDs provided");
        }

        //input can be either single entityId or entityIds.
        //this casts it as an array
        if (entityIds && !Array.isArray(entityIds)) {
            entityIds = [entityIds];
        }

        return postOpenBatch(entityIds)
            .then(resolvePostOpenBatchSuccess, resolvePostOpenBatchError);

        function postOpenBatch(entityIds) {
            var deferred = $q.defer();
            var batchId = null;
            var pollCount = 0;
            var pollMaxCount = 60;

            var poller = function () {
                coreApi.api.PrintOfferBatch['{id}'].GET({ id: batchId }).then(function (response) {
                    pollCount++;

                    if (response.data) {
                        if (response.data.isActive) {
                            if (pollCount < pollMaxCount) {
                                $timeout(poller, 1000);
                                logger.debug('Continuing to poll. Polling count #: ' + pollCount);
                            }
                            else {
                                const errorCode = 'POLLING_TIMEOUT';
                                const error = {
                                    code: errorCode
                                };
                                return deferred.reject(error);
                            }
                        }
                        else if (response.data.isFailed) {
                            const errorCode = 'API_TO_API_ERROR';
                            const error = {
                                code: errorCode
                            };
                            return deferred.reject(error);
                        }
                        else if (!response.data.isActive && !response.data.isFailed) {
                            if (response.data.failedCount === 0 && response.data.collectionUrl) {
                                //display the new tab with the
                                response.data.entityIds = entityIds;
                                deferred.resolve(response.data);
                            }
                            else if (response.data.failedCount > 0) {
                                //all failed
                                if (response.data.successCount === 0) {
                                    const errorCode = 'BATCH_ALL_ENTITIES_FAILED';
                                    const error = {
                                        code: errorCode,
                                        entityIds: entityIds
                                    };
                                    return deferred.reject(error);
                                }
                                    //some failed (still display new tab)
                                else if (response.data.successCount > 0 && response.data.collectionUrl) {
                                    const errorCode = 'BATCH_SOME_ENTITIES_FAILED';
                                    const error = {
                                        code: errorCode,
                                        batchId: batchId,
                                        entityIds: entityIds,
                                        collectionUrl: response.data.collectionUrl
                                    };
                                    return deferred.reject(error);
                                }
                            }
                        }
                    }
                })
                .catch(function (e) {
                    deferred.reject(e);
                })
            };

            coreApi.api.PrintOfferBatch.OpenBatch.POST(entityIds)
                .then(function (response) {
                    batchId = response.data;
                    poller(batchId);
                    //deferred.resolve(response.data);
                })
                .catch(function (e) {
                    deferred.reject(e);
                });

            return deferred.promise;
        }

        function resolvePostOpenBatchSuccess (data) {
            openPrintModal(data.collectionUrl);

            $translate('SERVICE_DRIVE.PRINT_SUCCEEDED').then(function (successMessage) {
                logger.success(successMessage);
            });

            return data;
        }

        function resolvePostOpenBatchError(error) {
            if (error.code === 'API_TO_API_ERROR') {
                $translate('SERVICE_DRIVE.PRINT_FAILED_ALL').then(function (failedMessage) {
                    logger.warning(failedMessage);
                });
            }
            else if (error.code === 'POLLING_TIMEOUT') {
                logger.genericError(error.code);
                //call api to get failed entities
            }
            else if (error.code === 'BATCH_ALL_ENTITIES_FAILED') {
                $translate('SERVICE_DRIVE.PRINT_FAILED_ALL').then(function (failedMessage) {
                    logger.warning(failedMessage);
                });
            }
            else if (error.code === 'BATCH_SOME_ENTITIES_FAILED') {
                if (error.collectionUrl) {
                    //display, but show there were errors
                    $timeout(function () {
                        openPrintModal(error.collectionUrl);
                    }, 10000);
                }

                if (error.batchId && error.entityIds) {
                    //get entities that succeeded through API
                    printOfferBatchService.getByBatch(error.batchId).then(function (data) {
                        var successfulEntityIds = _.map(data, 'entityID');
                        //updatePrintedDate(data.successfulEntityIds);
                        //updateOffers();

                        //get failed entities by removing the successful entityIds from original array of entityIds
                        var failedEntityIds = _.remove(error.entityIds, function (entityId) {
                            return _.indexOf(successfulEntityIds, entityId) !== -1
                        });

                        var namesConcatenatedString = getFailedCustomerNamesByEntityIds(failedEntityIds);
                        //display message that says which names failed
                        //logger.warning('Offer statement could not be generated for the following customers: ' + namesConcatenatedString);

                        $translate('SERVICE_DRIVE.PRINT_FAILED_PARTIALLY').then(function (warningMessage) {
                            logger.warning(warningMessage + namesConcatenatedString);
                        });

                        return data.successfulEntityIds;
                    });
                }
            }

            function getFailedCustomerNamesByEntityIds() {
                var names = [];
                angular.forEach(error.entityIds, function (id) {
                    // 'offers' is not available because it hasn't been
                    // returned by the API. For now, just make this a noop.
                    var offer = _.find(/* offers */ [], function (o) { return o.entityID === id });
                    names.push(offer.customerFullName);
                });

                return names.join(', ');
            }
        }
    }

    function openPrintModal(printUrl) {
        var parentElem = angular.element($document[0].querySelector('#aa-app'));
        $uibModal.open({
            animation: true,
            component: 'serviceDriveOffersPrintModalComponent',
            appendTo: parentElem,
            windowClass: 'modal-aa-lg',
            backdropClass: 'modal-aa-lg-backdrop',
            resolve: {
                printUrl: function () {
                    return printUrl;
                }
            }
        });
    }
}
