import { Component, OnInit, Input } from '@angular/core';

@Component({
   selector: 'cash-down',
   templateUrl: './cash-down.component-ng.html',
   styleUrls: ['./cash-down.component.scss']
})
export class CashDownComponent implements OnInit {
    @Input() value: number;

    constructor() { }

    ngOnInit() {
    }

 }
