
/* injects from baggage-loader */
require('./search-export-epsilon-result-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url"
import { ChildMessagePort } from '../../utilities/messaging';
import './search-export-epsilon-modal.component.scss';

angular.module('AaApp.Opp').component('searchExportEpsilonResultModal', {
    templateUrl: '/app/components/search/search-export-epsilon-result-modal.component.html',
    controller: SearchExportEpsilonResultModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
});

/* @ngInject */
function SearchExportEpsilonResultModalController($element, $scope, $sce, legacy, $logFactory, searchService) {
    var $ctrl = this;

    const log = $logFactory.get();

    $ctrl.$onInit = onInit;
    $ctrl.requestsCreated;

    $ctrl.campaignTypeTranslateKey = campaignTypeTranslateKey;

    function campaignTypeTranslateKey(input) {
        if (input.toLowerCase() == 'call center') {
            return 'exportCampaignType_callCenter';
        } else {
            return 'exportCampaignType_' + input.toLowerCase()
        }
    }
    
    function onInit() {
        $ctrl.requestsCreated = $ctrl.resolve.renderedPromise;
    }
}
