
/* injects from baggage-loader */
require('./user-select-action.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('userSelectAction', {
    templateUrl: '/app/components/deal-sheet/user-select-action.component.html',
    bindings: {
        defaultUser: '<',
        users: '<',
        permissions: '<',
        onSelect: '&',
        isActionMenu:'<'
    }
});
