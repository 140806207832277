import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { VehicleTypes, DealerCountries, PricingPlanTypes, TrimPricePlanSource, Settings } from '../../../../constants';

@Component({
    selector: 'vehicle-summary',
    templateUrl: './vehicle-summary.component-ng.html',
    styleUrls: ['./vehicle-summary.component.scss']
})
export class VehicleSummaryComponent implements OnChanges, OnInit {
    @Input() vehicle: any;
    @Input() countryId: number;
    @Input() isReplacement: boolean;
    @Input() isFordAlert: boolean;
    @Input() pricingPlanId: number;
    @Input() trimPlanPriceSourceName: string;
    @Input() trimPlanPriceSourceId: number;

    public vehicleTypes = VehicleTypes;
    public vehicleTypeKeys = Object.keys(VehicleTypes);
    public pricingPlanTypes = PricingPlanTypes;
    public pricingPlanTypeKeys = Object.keys(PricingPlanTypes);
    public dealerCountries = DealerCountries;
    public trimPricePlanSource = TrimPricePlanSource;
    public averageTranslateKey = "average";
    public planTranslationKey: string;

    //constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        let hasEmployeePricing = this.trimPlanPriceSourceId === this.trimPricePlanSource.employeePricing;

        if (hasEmployeePricing) {
            this.planTranslationKey = "planEmpPrice";
        } else if (this.pricingPlanId && this.pricingPlanId > 0) {
            this.planTranslationKey = this.pricingPlanId == PricingPlanTypes.azPlan ? "azPlan" : "xPlan";
        } else {
            this.planTranslationKey = "";
        }

        if (this.vehicle) {
            this.averageTranslateKey = ((this.vehicle.pricingPlanMsrp && this.vehicle.pricingPlanId) || hasEmployeePricing) ?
                "avg" : "average";
        }

    }

    openOasisLink() {
        var oasisUrl = Settings.environmentVariables.fordOASISUrl;

        oasisUrl = oasisUrl.replace("{0}", this.vehicle.vin);
        window.open(oasisUrl, '_blank');
    }

}