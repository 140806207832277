"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var engagement_event_types_constant_1 = require("../../../constants/engagement-event-types.constant");
var DashboardOpportunitiesTableComponent = /** @class */ (function () {
    function DashboardOpportunitiesTableComponent(dealSheetService) {
        var _this = this;
        this.dealSheetService = dealSheetService;
        this.isEuroLite = false;
        // tslint:disable-next-line:no-output-on-prefix
        this.onCloseCallback = new core_1.EventEmitter();
        this.engagementEventTypes = engagement_event_types_constant_1.EngagementEventTypes;
        this.engagementEventGroups = engagement_event_types_constant_1.EngagementEventGroups;
        // Use fat arrow definition to capture the correct this context because this is called as a callback function
        this._dealsheetClose = function (opId) {
            _this.onCloseCallback.emit({ opId: opId });
        };
    }
    DashboardOpportunitiesTableComponent.prototype.ngOnInit = function () { };
    DashboardOpportunitiesTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.widgetData && changes.widgetData.currentValue) {
            this.sortedWidgetData = this.widgetData.sort(function (item1, item2) {
                if (item1.assignedDate < item2.assignedDate) {
                    return -1;
                }
                else if (item1.assignedDate > item2.assignedDate) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
            for (var _i = 0, _a = this.sortedWidgetData; _i < _a.length; _i++) {
                var opportunity = _a[_i];
                if (opportunity.alerts) {
                    opportunity.detailedAlerts = opportunity.alerts.map(function (a) {
                        return {
                            type: a
                        };
                    });
                }
            }
        }
    };
    DashboardOpportunitiesTableComponent.prototype.openDealSheet = function (entityId) {
        if (!this.isEuroLite) {
            this.dealSheetService.openDealSheetModal(entityId, null, this._dealsheetClose);
        }
        else {
            this.dealSheetService.openDealSheetLiteModal(null, entityId);
        }
    };
    DashboardOpportunitiesTableComponent.prototype.iconClassForReviewRating = function (onlineReviewRating) {
        return 'rating-' + onlineReviewRating + 'star';
    };
    return DashboardOpportunitiesTableComponent;
}());
exports.DashboardOpportunitiesTableComponent = DashboardOpportunitiesTableComponent;
