import angular from 'angular';
import { OpportunitiesModule } from '../core/opportunities.module';
import { Subject } from 'rxjs';
import { appSettings } from '../core/config.module';
import { getUrlComponents } from '../utilities/url';
import { ChildMessagePort } from '../utilities/messaging';

OpportunitiesModule.factory('globalIframeService', function($logFactory) {
    "ngInject";
    return new GlobalIframeService($logFactory.get("globalIframeService"));
});

/**
 * A message port that always sends/receives data from the "current"
 * #autoalertiframe.
 *
 * This is necessary because three different components all create an
 * #autoalertiframe element, and they just happen to be mutually exclusive on
 * the page:
 *
 * - aaIframe
 * - legacyIframeSupport
 * - iframeModal
 *
 * Each of these components has coincidentally created an #autoalertiframe when
 * our page is expecting to be able to postMessage to that iframe.
 *
 * THIS IS A BACKWARDS-COMPATIBILITY UTILITY. We should gradually separate "the
 * iframe that is displaying a legacy page" and "the iframe that responds to
 * messages like GET_SSO_ID_TOKEN". We should have a single, hidden,
 * never-destroyed iframe that sends/receives all messages, and our app can
 * expose that one iframe as a service.
 */
class GlobalIframeService {
    constructor($log) {
        this._$log = $log;
        this._subscriptions = new Subject();
        this._subscriptions.subscribe(({ event, port }) => $log.debug("Received", event.data, port))
    }

    setCurrentMessagePort(port, $scope) {
        this._port = port;

        let onMessageReceived;
        if ($scope) {
            onMessageReceived = event => $scope.$apply(() => this._subscriptions.next({ event, port }));
        } else {
            onMessageReceived = event => this._subscriptions.next({ event, port });
        }
        this._port.subscribe(onMessageReceived);
    }

    postMessage(data) {
        if (this._port) {
            this._$log.debug("Posting message", data);
            return this._port.postMessage(data);
        } else {
            this._$log.warn("Tried to post a message when no port is available");
        }
    }

    subscribe(callback) {
        return this._subscriptions.subscribe({
            next: ({ event, port }) => callback(event, port),
        });
    }
}

