"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var customer_service_1 = require("../../../services/customer.service");
var core_1 = require("@ngx-translate/core");
var storage_service_1 = require("../../../modules/storage/storage.service");
var constants_1 = require("../../../constants");
var DoNotCallEmailComponent = /** @class */ (function () {
    function DoNotCallEmailComponent(customerService, translateService, storageService) {
        var _this = this;
        this.customerService = customerService;
        this.translateService = translateService;
        this.doNotCallType = 'donotcall';
        this.doNotEmailType = 'donotemail';
        this.selectTypeStep = 'SelectType';
        this.uploadFileStep = 'UploadFile';
        this.selectColumnStep = 'SelectColumn';
        this.confirmStep = 'ConfirmMatches';
        this.finishStep = 'FinishStep';
        this.okStep = 'OkStep';
        this.rowsSampleSize = 20;
        this.steps = [this.selectTypeStep, this.uploadFileStep, this.selectColumnStep, this.confirmStep, this.finishStep, this.okStep];
        this.currentStepIndex = 0;
        this.listOf = 'phone numbers';
        this.typeLabel = 'Do Not Call';
        this.matchedMessage = '';
        this.uploadMessage = '';
        this.nextLabelNext = 'Next';
        this.nextLabelFinish = 'Finish';
        this.nextLabelOk = 'OK';
        this.nextLabel = this.nextLabelNext;
        this.confirmList = {};
        this.typeSelected = this.doNotCallType;
        this.columnHoveredIndex = null;
        this.selectedColumnIndex = null;
        this.sampleSizeMessage = 'Only the first 20 are shown.';
        this.translationKeys = [
            'doNotEmailCallColumnTooltip',
            'doNotEmailCallMatchedRecords',
            'doNotEmailCallListOfPhoneNumbers',
            'doNotEmailCallListOfEmails',
            'doNotEmailCallTypeLabelEmail',
            'doNotEmailCallTypeLabelCall',
            'doNotEmailCallFileError',
            'doNotEmailCallNoItemsConfirmMessage',
            'next',
            'finish',
            'ok',
            'doNotEmailCallPageTitle',
            'doNotCallEmailSelectFileTypeLabel',
            'doNotCallEmailStep1Label',
            'doNotCallEmailStep2Label',
            'doNotCallEmailStep3Label',
            'upload',
            'doNotEmailCallColumnNameLabel',
            'cancel',
            'back'
        ];
        this.translations = {};
        this.hasDoNotCallModule = false;
        this.hasDoNotEmailModule = false;
        translateService.get(this.translationKeys)
            .subscribe(function (translations) {
            _this.translations = translations;
            _this.listOf = _this.translations.doNotEmailCallListOfPhoneNumbers;
            _this.typeLabel = _this.translations.doNotEmailCallTypeLabelCall;
            _this.nextLabelNext = _this.translations.next;
            _this.nextLabelFinish = _this.translations.finish;
            _this.nextLabelOk = _this.translations.ok;
        });
        var moduleIds = storageService.getItem('moduleIds');
        if (moduleIds) {
            this.hasDoNotCallModule = (moduleIds.indexOf(constants_1.Modules.doNotCall) > -1);
            this.hasDoNotEmailModule = (moduleIds.indexOf(constants_1.Modules.doNotEmail) > -1);
        }
        translateService.get('doNotEmailCallMatchedRecordsLimitedNumber', { limitedNumber: this.rowsSampleSize })
            .subscribe(function (sampleSizeTranslation) { _this.sampleSizeMessage = sampleSizeTranslation; });
    }
    DoNotCallEmailComponent.prototype.next = function () {
        if (this.currentStepIndex < this.steps.length - 1) {
            this.currentStepIndex++;
            this.handleNextStep();
        }
    };
    DoNotCallEmailComponent.prototype.back = function () {
        if (this.currentStepIndex > 0) {
            this.currentStepIndex--;
            this.handlePreviousStep();
        }
    };
    DoNotCallEmailComponent.prototype.cancel = function () {
        this.currentStepIndex = 0;
        this.handlePreviousStep();
    };
    DoNotCallEmailComponent.prototype.shouldDisplayCancelBackButton = function () {
        return (this.currentStepIndex > 0 && this.currentStepIndex < this.steps.length - 2);
    };
    DoNotCallEmailComponent.prototype.startColumnHover = function (columnIndex) {
        this.columnHoveredIndex = columnIndex;
    };
    DoNotCallEmailComponent.prototype.endColumnHover = function (columnIndex) {
        if (this.columnHoveredIndex === columnIndex) {
            this.columnHoveredIndex = null;
        }
    };
    DoNotCallEmailComponent.prototype.getColumnClass = function (columnIndex) {
        var style = '';
        if (this.selectedColumnIndex === columnIndex) {
            style = 'selected';
        }
        else if (this.columnHoveredIndex === columnIndex) {
            style = 'hover';
        }
        else {
            style = '';
        }
        return style;
    };
    DoNotCallEmailComponent.prototype.selectColumn = function (columnIndex) {
        this.selectedColumnIndex = columnIndex;
    };
    DoNotCallEmailComponent.prototype.selectType = function (type) {
        this.typeSelected = type;
        if (this.typeSelected === this.doNotEmailType) {
            this.listOf = this.translations.doNotEmailCallListOfEmails;
            this.typeLabel = this.translations.doNotEmailCallTypeLabelEmail;
        }
        else {
            this.listOf = this.translations.doNotEmailCallListOfPhoneNumbers;
            this.typeLabel = this.translations.doNotEmailCallTypeLabelCall;
        }
    };
    DoNotCallEmailComponent.prototype.upload = function (file) {
        var _this = this;
        this.fileName = file.name;
        var eightMB = 83886080;
        if ((file.name.lastIndexOf('.csv') < file.name.length - 5) ||
            (file.size > eightMB)) {
            this.errorMessage = this.translations.doNotEmailCallFileError;
            return;
        }
        else {
            this.errorMessage = '';
        }
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            var csv = reader.result;
            _this.parsedCsv = _this.parseCsv(csv);
            _this.next();
            var columnSelectMessageTranslationKey;
            // Since we only have one column we don't need the user to select it, go straight to uploading it
            if (_this.parsedCsv && _this.parsedCsv.length > 0 && _this.parsedCsv[0].length === 1) {
                columnSelectMessageTranslationKey = 'doNotEmailCallOneColumnMessage';
                _this.selectColumn(0);
            }
            else {
                columnSelectMessageTranslationKey = 'doNotEmailCallMultipleColumnMessage';
            }
            _this.matchedMessage = _this.translateService
                .instant('doNotEmailCallMatchedRecords', { numberOfMatches: _this.parsedCsv.length - 1 });
            if (_this.parsedCsv.length > _this.rowsSampleSize + 1) {
                _this.matchedMessage += ' ' + _this.sampleSizeMessage;
            }
            _this.columnSelectMessage = _this.translateService.instant(columnSelectMessageTranslationKey, { typeLabel: _this.typeLabel, type: _this.listOf });
        };
    };
    DoNotCallEmailComponent.prototype.getNextDisabledStatus = function () {
        if (this.steps[this.currentStepIndex] === this.selectColumnStep) {
            return (this.selectedColumnIndex === null);
        }
        if (this.steps[this.currentStepIndex] === this.uploadFileStep) {
            return (!this.parsedCsv || (this.parsedCsv && this.parsedCsv.length === 0));
        }
        if (this.steps[this.currentStepIndex] === this.confirmStep) {
            return (!this.confirmList || !this.confirmList.customerMatchCount || this.confirmList.customerMatchCount === 0);
        }
        return false;
    };
    DoNotCallEmailComponent.prototype.resetFileInfo = function () {
        if (this.selectTypeStep === this.steps[this.currentStepIndex]) {
            this.errorMessage = '';
            this.fileName = '';
            this.parsedCsv = [];
            this.selectedColumnIndex = null;
            this.confirmList = {};
        }
    };
    DoNotCallEmailComponent.prototype.handleNextStep = function () {
        if (this.steps[this.currentStepIndex] === this.uploadFileStep) {
            this.uploadMessage = this.translateService.instant('doNotCallEmailUploadMessage', { type: this.listOf, typeLabel: this.typeLabel });
        }
        if (this.steps[this.currentStepIndex] === this.confirmStep) {
            this.uploadSelectedColumn();
        }
        if (this.steps[this.currentStepIndex] === this.finishStep) {
            this.finish();
        }
        if (this.steps[this.currentStepIndex] === this.okStep) {
            this.nextLabel = this.nextLabelNext;
            this.cancel();
        }
    };
    DoNotCallEmailComponent.prototype.handlePreviousStep = function () {
        if (this.steps[this.currentStepIndex] === this.selectColumnStep) {
            this.confirmList = {};
            this.errorMessage = '';
            this.nextLabel = this.nextLabelNext;
            return;
        }
        if (this.steps[this.currentStepIndex] === this.uploadFileStep) {
            this.nextLabel = this.nextLabelNext;
            this.resetFileInfo();
            return;
        }
        if (this.steps[this.currentStepIndex] === this.selectTypeStep) {
            this.nextLabel = this.nextLabelNext;
            this.resetFileInfo();
            this.selectType((this.hasDoNotCallModule) ? this.doNotCallType : this.doNotEmailType);
            this.statusMessage = '';
            return;
        }
    };
    DoNotCallEmailComponent.prototype.uploadSelectedColumn = function () {
        var _this = this;
        var selectedValues = [];
        for (var _i = 0, _a = this.parsedCsv; _i < _a.length; _i++) {
            var row = _a[_i];
            selectedValues.push(row[this.selectedColumnIndex]);
        }
        var observable;
        if (this.typeSelected === this.doNotEmailType) {
            observable = this.customerService.stageDoNotEmailList(selectedValues);
        }
        else {
            observable = this.customerService.stageDoNotCallList(selectedValues);
        }
        this.busy = observable
            .subscribe(function (result) {
            _this.nextLabel = _this.nextLabelFinish;
            if (result.customerMatchCount === 0) {
                _this.confirmMessage = _this.translations.doNotEmailCallNoItemsConfirmMessage;
                _this.errorMessage = _this.translateService.instant('doNotEmailCallNoItemsErrorMessage', { type: _this.listOf });
            }
            else {
                _this.confirmMessage = _this.translateService
                    .instant('doNotEmailCallMatchedConfirmMessage', { type: _this.listOf, finish: _this.nextLabelFinish, typeLabel: _this.typeLabel });
                _this.confirmList = result;
                _this.matchedMessage = _this.translateService
                    .instant('doNotEmailCallMatchedRecords', { numberOfMatches: _this.confirmList.customerMatchCount });
                if (_this.confirmList.customerMatchCount > _this.rowsSampleSize) {
                    _this.matchedMessage += ' ' + _this.sampleSizeMessage;
                }
            }
        });
    };
    DoNotCallEmailComponent.prototype.finish = function () {
        var _this = this;
        var observable;
        if (this.typeSelected === this.doNotEmailType) {
            observable = this.customerService.finishDoNotEmailList(this.confirmList.sessionID);
        }
        else {
            observable = this.customerService.finishDoNotCallList(this.confirmList.sessionID);
        }
        this.busy = observable
            .subscribe(function () {
            _this.nextLabel = _this.nextLabelOk;
            _this.statusMessage = _this.translateService.instant('doNotEmailCallFinishStatusMessage', { typeLabel: _this.typeLabel });
        });
    };
    DoNotCallEmailComponent.prototype.parseCsv = function (csv) {
        if (csv.length <= 0) {
            return [];
        }
        var lines = csv.split('\n');
        var result = [];
        for (var _i = 0, lines_1 = lines; _i < lines_1.length; _i++) {
            var line = lines_1[_i];
            var columns = line.split(',');
            result.push(columns);
        }
        return result;
    };
    return DoNotCallEmailComponent;
}());
exports.DoNotCallEmailComponent = DoNotCallEmailComponent;
