import { Injectable } from '@angular/core';
import { DealSheetClientService } from '../generated/services';
import { DealSheetHistoryDto } from '../generated/models';

@Injectable({
    providedIn: 'root'
})
export class RecentDealSheetService {

    constructor(
        private dealSheetClientService: DealSheetClientService) {
    }

    getRecentDealSheets(): Promise<DealSheetHistoryDto[]> {
        return this.dealSheetClientService.UserDealSheetHistoryGET().toPromise();
    }
}
