"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var core_1 = require("@angular/core");
var locale_date_pipe_1 = require("../../../filters/locale-date.pipe");
var constants_1 = require("../../../constants");
var TagsComponent = /** @class */ (function () {
    function TagsComponent(localeDate, vehicleService) {
        this.localeDate = localeDate;
        this.vehicleService = vehicleService;
        this.hasRecallNotice = false;
        this.hasMultipleRecalls = false;
        this.recallNotificationList = [];
    }
    TagsComponent.prototype.ngOnInit = function () {
    };
    TagsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.values && changes.values.currentValue) {
            this.values = this.values.sort(function (tag1, tag2) {
                if (!tag1.expirationDate && tag2.expirationDate) {
                    return 1;
                }
                else if (tag1.expirationDate && !tag2.expirationDate) {
                    -1;
                }
                else if (!tag1.expirationDate && !tag2.expirationDate) {
                    return 0;
                }
                else {
                    return _this.compareTagsByExpirationDateAndName(tag1, tag2);
                }
            });
            this.hasRecallNotice = false; // reset to false to handle empty tag results when switching deal sheet
            this.setLocalizedExpirationDate();
            this.values = this.values.filter(function (item) {
                if (item.tagID == 2404 || item.tagID == 10005) {
                    _this.hasRecallNotice = true;
                    _this.getRecallInformation();
                    return false;
                }
                if (item.text == "Ford Credit Pre-Approved")
                    return false;
                if (item.text == "Ford Credit Pre-Qualified")
                    return false;
                return true;
            });
        }
    };
    TagsComponent.prototype.getRecallInformation = function () {
        var _this = this;
        this.vehicleService.getVinRecall(this.dealerId, this.vehicleId)
            .then(function (data) {
            if (data.length > 0) {
                // remove duplicates
                var dedupedRecalls = lodash_1.uniqBy(data, function (recall) {
                    return recall.vin + '_' + recall.recallNumber_Nhtsa + '_' + recall.recallNumber_mfr;
                });
                _this.recallNotificationList = dedupedRecalls.map(function (data) { return ({
                    recallDate: data.recallDate,
                    description: data.recallDescription,
                    safetyRisk: data.safetyRiskDescription,
                    remedy: data.remedyDescription,
                    recallNumber_mfr: data.recallNumber_mfr,
                    recallNumber_Nhtsa: data.recallNumber_Nhtsa,
                    isRemedyAvailable: data.isRemedyAvailable,
                    vinRecallDate: data.vinRecallDate,
                    vin: data.vin
                }); }).sort(function (a, b) {
                    return new Date(b.recallDate).getTime() - new Date(a.recallDate).getTime();
                });
                _this.hasMultipleRecalls = _this.recallNotificationList.length > 1;
            }
        });
    };
    TagsComponent.prototype.setLocalizedExpirationDate = function () {
        for (var i = 0; i < this.values.length; i++) {
            var tag = this.values[i];
            if (tag.expirationDate) {
                tag.localizedExpirationDate = this.localeDate.transform(tag.expirationDate);
            }
        }
    };
    TagsComponent.prototype.compareTagsByExpirationDateAndName = function (tag1, tag2) {
        if (tag1.expirationDate > tag2.expirationDate) {
            return 1;
        }
        else if (tag1.expirationDate < tag2.expirationDate) {
            return -1;
        }
        else {
            return this.compareTagsByName(tag1, tag2);
        }
    };
    TagsComponent.prototype.compareTagsByName = function (tag1, tag2) {
        if (tag1.name > tag2.name) {
            return 1;
        }
        else if (tag1.name < tag2.name) {
            return -1;
        }
        else {
            return 0;
        }
    };
    TagsComponent.prototype.openOasisLink = function () {
        if (this.recallNotificationList.length > 0) {
            var oasisUrl = constants_1.Settings.environmentVariables.fordOASISUrl;
            oasisUrl = oasisUrl.replace("{0}", this.recallNotificationList[0].vin);
            window.open(oasisUrl, '_blank');
        }
    };
    return TagsComponent;
}());
exports.TagsComponent = TagsComponent;
