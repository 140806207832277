import angular from 'angular';

//Named ActivityResultCodeEnum to match the name in c# AutoAlert.AlertMiner.ServiceContract
angular.module('AaApp.Opp')
    .constant('ActivityResultCodeEnum', {
        "Push_To_Crm_Success": 110,
        "Push_To_CRM_Fail_Permission": 111,
        "Push_To_CRM_Fail_Delivery": 113,
        "Standard_Activity_Success": 120,
        "General_Exception": 131
    })
