import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import { ReplacementVehicle } from '../../../../interfaces/replacement-vehicle.interface';
import { AuthorizationService } from '../../../../services/authorization.service';

@Component({
    selector: 'aa-more-vehicle-offers',
    templateUrl: './more-vehicle-offers.component-ng.html',
    styleUrls: ['./more-vehicle-offers.component.scss']
})
export class MoreVehicleOffersComponent implements OnInit {

    // define input bindings
    @Input() upgradeVehicle: ReplacementVehicle;
    @Input() downgradeVehicle: ReplacementVehicle;
    @Input() standardVehicle: ReplacementVehicle;
    @Input() alternateVehicles: Array<ReplacementVehicle>;
    @Input() selectedVehicle: { selectedVehicleType: string, alternateVehicleIndex: number };
    @Input() replacementContract: any;

    // define eventOUT signature
    @Output() select = new EventEmitter<{}>();
    vehicleImageNotAvailableUrl = require('../../../../images/vehicle-image-not-available.png');
    selectedVehicleObject: ReplacementVehicle;
    hasPaymentsPermission: boolean;
    overrideShowPayment: boolean;

    constructor(private authorizationService: AuthorizationService) {
        this.selectedVehicle = { selectedVehicleType: 'standardVehicle', alternateVehicleIndex: 0 };
    }

    ngOnInit(): void {
        this.selectedVehicleObject = this.standardVehicle;

        this.authorizationService.getDealSheetPermissions()
            .then(dealSheetPermissions => {
                this.hasPaymentsPermission = dealSheetPermissions.payments;
            });
        if (this.replacementContract) {
            this.overrideShowPayment = true;
            this.standardVehicle.existingPayment = 0;
            this.standardVehicle.payment = this.replacementContract.paymentDifference;    
        } else {
            this.overrideShowPayment = false;            
        }
    }

    clickHandler(chosenVehicleType: string, alternateVehicleIndex: number, vehicle: ReplacementVehicle): void {
        this.selectedVehicle = { selectedVehicleType: chosenVehicleType, alternateVehicleIndex: alternateVehicleIndex };
        this.select.emit(this.selectedVehicle);
        this.selectedVehicleObject = vehicle;
    }

    // on error of image src load default fallback image
    updateUrl(event, vehicle: ReplacementVehicle) {
        vehicle.imagePath = this.vehicleImageNotAvailableUrl;
    }

    getSelectedAttribute(vehicleType: string, index: number = 0): boolean {

        if (this.selectedVehicle &&
            this.selectedVehicle.selectedVehicleType === vehicleType) {
            const selectedIndex = this.selectedVehicle.alternateVehicleIndex;
            if (selectedIndex !== undefined && selectedIndex !== null) {
                return (this.selectedVehicle.alternateVehicleIndex === index);
            }
            return true;
        }

        return false;
    }

    getContainerClass(): string {
        return 'row mvos-more-container1';
    }
}
