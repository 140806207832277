DealSheetLiteModalController.$inject = ["$rootScope", "globalIframeService"];

/* injects from baggage-loader */
require('./deal-sheet-lite-modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('dealSheetLiteModal', {
  templateUrl: '/app/components/deal-sheet/modals/deal-sheet-lite-modal.component.html',
  controller: DealSheetLiteModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function DealSheetLiteModalController($rootScope, globalIframeService) {
  var $ctrl = this;
  $ctrl.$onInit = $onInit;

  function $onInit() {
    var _globalIframeService$ = globalIframeService.subscribe(messageHandler),
        unsubscribe = _globalIframeService$.unsubscribe;

    $ctrl.$onDestroy = unsubscribe;
  } // this is called from legacy popup deal sheet not the angular deal sheet.


  function messageHandler(_ref) {
    var data = _ref.data;

    if (data['DEAL_SHEET_CLOSE']) {
      $ctrl.close();
    }
  }
}