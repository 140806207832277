import { Component, OnInit, Input, ElementRef, Injector } from '@angular/core';
import { TradeValueTypes } from '../../../../constants/trade-value-types';
import { DealerCountries } from '../../../../constants/dealer-countries';
import { VehicleUserTradeValueClientService } from '../../../../generated/services';
import { VehicleUserTradeValueDto } from '../../../../generated/models/vehicle-user-trade-value-dto';

@Component({
  selector: 'trade-source-logo',
  templateUrl: './trade-source-logo.component-ng.html',
  styleUrls: ['./trade-source-logo.component.scss']
})
export class TradeSourceLogoComponent implements OnInit {

  @Input() tradeSourceId: number;
  @Input() countryId: number;
  @Input() vehicle: any;
  tradeValueTypes = TradeValueTypes;
  dealerCountries = DealerCountries;
  tradeValueIsManual = false;
  tradeValueInsertDt = '';
  saveVehicleId = 0;

  constructor(elementRef: ElementRef, injector: Injector, private vehicleUserTradeValueClientService: VehicleUserTradeValueClientService) {   }

  ngOnInit() {}

  public ngOnChanges() {
      if (this.vehicle && this.vehicle.vehicleID != this.saveVehicleId) {
          this.tradeValueIsManual = false;
          this.saveVehicleId = this.vehicle.vehicleID;
          this.vehicleUserTradeValueClientService.GetVehicleUserTradeValue(this.vehicle.vehicleID).toPromise().then(vutv => {
              if (vutv && vutv.tradeValue > 0) {
                  let expirationDt = Date.parse(vutv.expirationDt);
                  if (isNaN(expirationDt) == false) {
                      let expDt = new Date(expirationDt).getTime();
                      let currDt = Date.now();
                      if (expDt > currDt) {
                          this.tradeValueIsManual = true;
                          let date = vutv.insertDt.split('T')[0];
                          let dateParts = date.split('-');
                          this.tradeValueInsertDt = dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0];
                      }
                  }
              }
          });
      }
    }

  isCanada(): boolean {
    return this.countryId === this.dealerCountries.canada;
  }
}
