<div class="row">
    <div class="col-md-11">

        <div class="settingTitle" translate="dsAdmin_alertTolerances"></div>
        <tooltip [titleKey]="'dsAdmin_alertTolerances'" [contentKey]="'dsAdmin_alertTolerancesTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-2">
            </div>
            <div class="col-md-2">
                <span class="bold" translate="lease"></span>
            </div>
            <div class="col-md-2 bold">
                <span class="bold" translate="retail">Retail</span>
            </div>
        </div>
        <div [title]="dsa.isFordAlert ? ('dsAdmin_valueSetByFormProgram' | translate) : ''">
            <div class="row settingItem">
                <div class="col-md-2" translate="dsAdmin_alertsTolerance">
                </div>
                <div class="col-md-2">
                    <input [disabled]="dsa.isFordAlert" name="DealerSetting.LeaseAlertTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.leaseAlertTolerance" /> %
                </div>
                <div class="col-md-2">
                    <input [disabled]="dsa.isFordAlert" name="DealerSetting.RetailAlertTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.retailAlertTolerance" /> %
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2" translate="dsAdmin_flexAlertsTolerance">
                </div>
                <div class="col-md-2">
                    <input [disabled]="dsa.isFordAlert" name="DealerSetting.LeaseFlexAlertTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.leaseFlexAlertTolerance" /> %
                </div>
                <div class="col-md-2">
                    <input [disabled]="dsa.isFordAlert" name="DealerSetting.RetailFlexAlertTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.retailFlexAlertTolerance" /> %
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2" translate="dsAdmin_flexMonthTolerance">
                </div>
                <div class="col-md-2">
                    <input [disabled]="dsa.isFordAlert" name="DealerSetting.FlexMonthsTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.flexMonthsTolerance" /> {{ ('Months' | translate).toLowerCase() }}
                </div>
            </div>
            <div *ngIf="!dsa.isFordLincolnDealer || (dsa.isFordAlert && dsa.isUSADealer)">
                <div class="sectionSpacer"></div>
                <div class="settingTitle" translate="dsAdmin_multiVehicleReplacementOffersTolerances"></div>
                <tooltip [titleKey]="'dsAdmin_multiVehicleReplacementOffersTolerances'" [contentKey]="'dsAdmin_multiVehicleReplacementOffersTolerancesTooltip'"></tooltip>
                <div class="settingItem">
                    <label>
                        <input [disabled]="dsa.isFordAlert" type="checkbox" [(ngModel)]="dsa.dealerSetting.enableMultiVehReplTolerances" /> {{ 'dsAdmin_enableMultiVehicleReplacementOffers' | translate }}
                    </label>
                </div>
                <div class="row settingItem">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-2">
                        <span class="bold" translate="lease"></span>
                    </div>
                    <div class="col-md-2 bold">
                        <span class="bold" translate="retail"></span>
                    </div>
                </div>
                <div class="row settingItem">
                    <div class="col-md-2" translate="upgradeVehicles">
                    </div>
                    <div class="col-md-2">
                        <input [disabled]="dsa.isFordAlert" name="DealerSetting.MultiVehReplLeaseUpgradeTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.multiVehReplLeaseUpgradeTolerance" /> %
                    </div>
                    <div class="col-md-2">
                        <input [disabled]="dsa.isFordAlert" name="DealerSetting.MultiVehReplRetailUpgradeTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.multiVehReplRetailUpgradeTolerance" /> %
                    </div>
                    <div class="col-md-4">
                        {{ 'dsAdmin_leaseRetailpaymentNotToExceed' | translate }} $ <input [disabled]="dsa.isFordAlert" name="DealerSetting.UpgradeVehiclePaymentIncrease" type="number" class="input-sm textboxMed" [(ngModel)]="dsa.dealerSetting.upgradeVehiclePaymentIncrease" />
                    </div>
                </div>
                <div *ngIf="!dsa.isFordAlert" class="row settingItem">
                    <div class="col-md-2" translate="downgradeVehicles">
                    </div>
                    <div class="col-md-2">
                        <input name="DealerSetting.MultiVehReplLeaseDowngradeTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.multiVehReplLeaseDowngradeTolerance" /> %
                    </div>
                    <div class="col-md-2">
                        <input name="DealerSetting.MultiVehReplRetailDowngradeTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.multiVehReplRetailDowngradeTolerance" /> %
                    </div>
                </div>
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle below" translate="dsAdmin_ContractEndRangeInMonths"></div>
        <tooltip [titleKey]="'dsAdmin_ContractEndRangeInMonths'" [contentKey]="'dsAdmin_ContractEndRangeInMonthsTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-2" translate="lease">
            </div>
            <div class="col-md-3">
                <input name="DealerSetting.LeaseContractEndRangeFrom" type="number" class="input-sm textboxSm" [class.invalid]="!leaseContractEndRangeValid" [(ngModel)]="dsa.dealerSetting.leaseContractEndRangeFrom" (change)="validateContractEndRange()" /> {{ 'To' | translate }} <input name="DealerSetting.LeaseContractEndRangeTo" type="number" class="input-sm textboxSm" [class.invalid]="!leaseContractEndRangeValid" [(ngModel)]="dsa.dealerSetting.leaseContractEndRangeTo" (change)="validateContractEndRange()" /> {{ ('Months' | translate).toLowerCase() }}
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-2" translate="retail">
            </div>
            <div class="col-md-3">
                <input name="DealerSetting.PurchaseContractEndRangeFrom" type="number" class="input-sm textboxSm" [class.invalid]="!purchaseContractEndRangeValid" [(ngModel)]="dsa.dealerSetting.purchaseContractEndRangeFrom" (change)="validateContractEndRange()" /> {{ 'To' | translate }} <input name="DealerSetting.PurchaseContractEndTo" type="number" class="input-sm textboxSm" [class.invalid]="!purchaseContractEndRangeValid" [(ngModel)]="dsa.dealerSetting.purchaseContractEndTo" (change)="validateContractEndRange()" /> {{ ('Months' | translate).toLowerCase() }}
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle below" translate="dsAdmin_mileageWarrantyServiceAlerts"></div>
        <tooltip [titleKey]="'dsAdmin_mileageWarrantyServiceAlerts'" [contentKey]="'dsAdmin_mileageWarrantyServiceAlertsTooltip'"></tooltip>
        <div class="row settingItem" *ngIf="dsa.dealerSetting.productID !== productTypes.serviceDriveOnly">
            <div class="col-md-2" translate="dsAdmin_serviceAlertExpiration">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.ServiceAlertWindow" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.serviceAlertWindow" /> {{ ('days' | translate).toLowerCase() }}
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-2" translate="dsAdmin_mileagePenalty">
            </div>
            <div class="col-md-1">
                <input name="DealerSetting.LeaseEstimatedPenaltyTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.leaseEstimatedPenaltyTolerance" />
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-2" translate="WarrantyRemaining">
            </div>
            <div class="col-md-3">
                <input name="DealerSetting.WarrantyMilesRemainingTolerance" type="number" class="input-sm textboxMed" [(ngModel)]="dsa.dealerSetting.warrantyMilesRemainingTolerance" /> {{ (unitLabelKey | translate).toLowerCase() }} {{ ('or' | translate).toLowerCase() }} <input name="DealerSetting.WarrantyMonthsRemainingTolerance" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.warrantyMonthsRemainingTolerance" /> {{ ('Months' | translate).toLowerCase() }}
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle below" translate="dsAdmin_markOpportunitiesUnread"></div>
        <tooltip [titleKey]="'dsAdmin_markOpportunitiesUnread'" [contentKey]="'dsAdmin_markOpportunitiesUnreadTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-2" translate="dsAdmin_markOpportunitiesUnreadAfter">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.DaysToMarkAsUnread" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.daysToMarkAsUnread" /> {{ ('days' | translate).toLowerCase() }}
            </div>
        </div>
    </div>
</div>

