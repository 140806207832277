"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./service-appointment.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("@angular/common"));
var i4 = __importStar(require("./service-appointment.component"));
var i5 = __importStar(require("../../../../filters/locale-date.pipe"));
var i6 = __importStar(require("../../../../filters/locale-date-time.pipe"));
var i7 = __importStar(require("../../../../filters/locale-time.pipe"));
var styles_ServiceAppointmentComponent = [i0.styles];
var RenderType_ServiceAppointmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceAppointmentComponent, data: {} });
exports.RenderType_ServiceAppointmentComponent = RenderType_ServiceAppointmentComponent;
function View_ServiceAppointmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "field-label"], ["translate", "serviceAppointmentLongLabel"]], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [["class", "field-data qa-service-appt-date"]], [[8, "id", 0]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { appointment: 0 }), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "serviceAppointmentLongLabel"; _ck(_v, 2, 0, currVal_1); var currVal_3 = "field-data qa-service-appt-date"; var currVal_4 = _ck(_v, 6, 0, _co.alertExists); _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.idUnique, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.idUnique, ""); _ck(_v, 3, 0, currVal_2); var currVal_5 = _co.serviceAppointment; _ck(_v, 7, 0, currVal_5); }); }
function View_ServiceAppointmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceAppointmentComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ServiceAppointmentComponent_0 = View_ServiceAppointmentComponent_0;
function View_ServiceAppointmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "service-appointment", [], null, null, null, View_ServiceAppointmentComponent_0, RenderType_ServiceAppointmentComponent)), i1.ɵdid(1, 638976, null, 0, i4.ServiceAppointmentComponent, [i5.LocaleDatePipe, i6.LocaleDateTimePipe, i7.LocaleTimePipe, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ServiceAppointmentComponent_Host_0 = View_ServiceAppointmentComponent_Host_0;
var ServiceAppointmentComponentNgFactory = i1.ɵccf("service-appointment", i4.ServiceAppointmentComponent, View_ServiceAppointmentComponent_Host_0, { date: "date", alertExists: "alertExists", abbreviate: "abbreviate" }, {}, []);
exports.ServiceAppointmentComponentNgFactory = ServiceAppointmentComponentNgFactory;
