import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { ScheduleModalParam } from '../ScheduleModalParam.interface';
import { ActivityActionEnum } from '../../../../constants';

@Component({
    selector: 'customer-connect-sub-detail',
    templateUrl: './customer-connect-sub-detail.component-ng.html',
    styleUrls: ['./customer-connect-sub-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectSubDetailComponent implements OnInit {
  

    @Input() permissions: DealSheetPermissions;
    @Input() subAction: number;
    @Input() dialogParams: ScheduleModalParam;
    @Output() onAction = new EventEmitter<any>();
    @Output() onLogScheduleSubmit = new EventEmitter<any>();
    @Input() isEuroLite: boolean;

    public ActivityActionEnum = ActivityActionEnum;

    constructor() { }

    ngOnInit() {
    }
}
