import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'payoff-input-modal',
    templateUrl: './payoff-input-modal.component-ng.html',
    styleUrls: ['./payoff-input-modal.component.scss']
})

export class PayoffInputModalComponent implements OnInit, AfterViewInit {
    public payoffAmount: number;
    public dateOptions: any;
    public goodThroughDateModel: any;
    public goodThroughDate: Date;
    public showAmountMsg: boolean = false;
    public minValidValue: number = 1;
    @ViewChild('payoffForm', { static: false }) payoffForm: NgForm;
    @Input() minPayoff: number;
    @Output() onSaveChanges = new EventEmitter();

    private static readonly payoffGoodThroughDays: number = 15;

    constructor(
        public _bsModalRef: BsModalRef,
        public tService: TranslateService) {
    }

    public ngOnInit(): void {
        this._bsModalRef.hide();

        let minDate = new Date();
        let maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + PayoffInputModalComponent.payoffGoodThroughDays);

        this.dateOptions = {
            formatYear: 'yy',
            minDate: minDate,
            maxDate: maxDate,
            startingDay: 0,
            showWeeks: false
        };

        if (this.minPayoff && typeof this.minPayoff === 'number' && this.minPayoff > 0) {
            this.minValidValue = Math.ceil(this.minPayoff);
        } else {
            this.minValidValue = 1;
        }
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
    }

    public setGoodThroughDate(date, model) {
        this.goodThroughDateModel = model;
        this.goodThroughDate = date;
    }

    public setShowAmountMsg() {
        this.showAmountMsg = !this.payoffForm.controls['payoffAmount'].pristine && !this.payoffForm.controls['payoffAmount'].valid;
    }

    public savePayoff(payoffForm: NgForm): void {
        if (payoffForm.valid) {
            this.onSaveChanges.emit({ payoffAmount: this.payoffAmount, goodThroughDate: this.goodThroughDate });
            this._bsModalRef.hide();
        }
    }
}

