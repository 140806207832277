"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./dms-push.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("ngx-bootstrap/popover"));
var i4 = __importStar(require("ngx-bootstrap/component-loader"));
var i5 = __importStar(require("ngx-bootstrap/positioning"));
var i6 = __importStar(require("./dms-push.component"));
var i7 = __importStar(require("../../../services/ngx-modal-wrapper.service"));
var i8 = __importStar(require("../../../core/toaster-logger.service"));
var styles_DmsPushComponent = [i0.styles];
var RenderType_DmsPushComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DmsPushComponent, data: {} });
exports.RenderType_DmsPushComponent = RenderType_DmsPushComponent;
function View_DmsPushComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "dmsPushTooltip"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("dmsPushTooltip")); _ck(_v, 0, 0, currVal_0); }); }
function View_DmsPushComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "margin-bottom:10px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, "button", [["class", "btn btn-primary  btn-sm"], ["placement", "top"], ["translate", "dmsOpenDialogButtonText"], ["triggers", "mouseenter:mouseleave"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dmsPushModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(3, 212992, null, 0, i3.PopoverDirective, [i3.PopoverConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i5.PositioningService], { popover: [0, "popover"], placement: [1, "placement"], triggers: [2, "triggers"] }, null), (_l()(), i1.ɵand(0, [["popDmsPushTemplate", 2]], null, 0, null, View_DmsPushComponent_1))], function (_ck, _v) { var currVal_0 = "dmsOpenDialogButtonText"; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4); var currVal_2 = "top"; var currVal_3 = "mouseenter:mouseleave"; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
exports.View_DmsPushComponent_0 = View_DmsPushComponent_0;
function View_DmsPushComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dmsPush", [], null, null, null, View_DmsPushComponent_0, RenderType_DmsPushComponent)), i1.ɵdid(1, 638976, null, 0, i6.DmsPushComponent, [i7.NgxModalWrapperService, i2.TranslateService, i8.ToasterLoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DmsPushComponent_Host_0 = View_DmsPushComponent_Host_0;
var DmsPushComponentNgFactory = i1.ɵccf("dmsPush", i6.DmsPushComponent, View_DmsPushComponent_Host_0, { dealerId: "dealerId", customer: "customer", states: "states", currentVin: "currentVin", currentTradeValue: "currentTradeValue", currentYear: "currentYear", currentMake: "currentMake", currentModel: "currentModel", currentMileage: "currentMileage", replacementVin: "replacementVin", replacementPrice: "replacementPrice", replacementSaleType: "replacementSaleType" }, {}, []);
exports.DmsPushComponentNgFactory = DmsPushComponentNgFactory;
