import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';

@Directive({
    selector: 'replacement-contracts'
})
export class ReplacementContractsComponent extends UpgradeComponent {
    @Input() countryId: number;
    @Input() opportunity: DealSheetOpportunity;
    @Input() availableContracts: boolean;
    @Input() permissions: DealSheetPermissions;
    @Input() replacementContract: any;
    @Input() showPullForward: boolean;
    @Input() showMobileView: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('replacementContracts', elementRef, injector);
    }
}