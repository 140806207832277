"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var log_action_shortcut_service_1 = require("../../../services/log-action-shortcut.service");
var LogActionShortcutsComponent = /** @class */ (function () {
    function LogActionShortcutsComponent(logActionShortcutService) {
        this.logActionShortcutService = logActionShortcutService;
        this.logShortcut = new core_1.EventEmitter();
    }
    LogActionShortcutsComponent.prototype.ngOnInit = function () {
    };
    LogActionShortcutsComponent.prototype.logCall = function () {
        this.logShortcut.emit();
        this.logActionShortcutService.logCall();
    };
    LogActionShortcutsComponent.prototype.logNote = function () {
        this.logShortcut.emit();
        this.logActionShortcutService.logNote();
    };
    LogActionShortcutsComponent.prototype.scheduleAppt = function () {
        this.logShortcut.emit();
        this.logActionShortcutService.scheduleAppt();
    };
    return LogActionShortcutsComponent;
}());
exports.LogActionShortcutsComponent = LogActionShortcutsComponent;
