import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingResponseDto } from '../../generated/models';

@Component({
    selector: 'admin-settings-product',
    templateUrl: './admin-settings-product.component-ng.html'
})

export class AdminSettingsProductComponent {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};
    @Output() onSaveChanges = new EventEmitter();

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
    ) {
    }
}