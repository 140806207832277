
/* injects from baggage-loader */
require('./activity-actions.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('activityActions', {
    templateUrl: '/app/components/deal-sheet/activity-actions.component.html',
    controller: ActivityActionController,
    bindings: {
        opportunity: '<',
        users: '<',
        maxScheduleFutureDays: '<',
        permissions: '<',
        onAction: '&'
    }
});

/* @ngInject */
function ActivityActionController(ActivityActionEnum, dealSheetService) {
    var $ctrl = this;

    $ctrl.ActivityActionEnum = ActivityActionEnum;
    $ctrl.openModal = openModal;
    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if ((changes.opportunity && changes.opportunity.currentValue || changes.users && changes.users.currentValue
            || changes.maxScheduleFutureDays && changes.maxScheduleFutureDays.currentValue) && ($ctrl.opportunity && $ctrl.users && $ctrl.maxScheduleFutureDays)) {
            $ctrl.scheduleModalParamObject = dealSheetService.getModalParamObject(
                null,
                $ctrl.opportunity.id,
                $ctrl.opportunity.customer ? $ctrl.opportunity.customer.dealerID : null,
                $ctrl.opportunity.assignedUserId,
                $ctrl.maxScheduleFutureDays,
                $ctrl.users,
                null,
                $ctrl.opportunity ? $ctrl.opportunity.ownerUserInfo : null);
        }
    }

    function openModal(modalType) {
        $ctrl.scheduleModalParamObject.modalType = modalType;
        dealSheetService.openScheduleModal($ctrl.scheduleModalParamObject, onAction);
    }

    function onAction() {
        $ctrl.onAction();
    }
}
