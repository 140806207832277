
/* injects from baggage-loader */
require('./crm-action.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('crmAction', {
    templateUrl: '/app/components/deal-sheet/crm-action.component.html',
    bindings: {
        opportunityId: '<'
    }
});
