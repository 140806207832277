"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./user-settings.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@uirouter/angular/lib/directives/uiSref"));
var i3 = __importStar(require("@uirouter/core"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("@ngx-translate/core"));
var i6 = __importStar(require("./user-settings.component"));
var styles_UserSettingsComponent = [i0.styles];
var RenderType_UserSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserSettingsComponent, data: {} });
exports.RenderType_UserSettingsComponent = RenderType_UserSettingsComponent;
function View_UserSettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "float: right"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "icon ", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_UserSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (((_co.dropDownVisible = false) || (_v.parent.context.$implicit.action ? _v.parent.context.$implicit.action() : null)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.sref, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.icon; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.parent.context.$implicit.translate)); _ck(_v, 5, 0, currVal_2); }); }
function View_UserSettingsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "float: right"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "icon ", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_UserSettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[8, "href", 4], [1, "target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.dropDownVisible = false) || (_v.parent.context.$implicit.action ? _v.parent.context.$implicit.action() : null)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.href; var currVal_1 = (_v.parent.context.$implicit.target || undefined); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.parent.context.$implicit.translate)); _ck(_v, 3, 0, currVal_3); }); }
function View_UserSettingsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "float: right"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "icon ", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_UserSettingsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_7)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.parent.context.$implicit.translate)); _ck(_v, 3, 0, currVal_1); }); }
function View_UserSettingsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
function View_UserSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_4)), i1.ɵdid(5, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_6)), i1.ɵdid(7, 16384, null, 0, i4.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_8)), i1.ɵdid(9, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "SREF"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "HREF"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "SEPARATOR"; _ck(_v, 9, 0, currVal_3); }, null); }
function View_UserSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["id", "aa-header-user"]], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = ((_co.dropDownVisible = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "clickable"], ["id", "aa-header-user-name"]], null, [[null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.dropDownVisible = true) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.dropDownVisible = !_co.dropDownVisible) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { active: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["id", "aa-header-user-avatar"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "background-image": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "aa-icon-chevron-down small-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["id", "aa-header-user-dropdown"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ul", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserSettingsComponent_1)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "clickable"; var currVal_1 = _ck(_v, 4, 0, _co.dropDownVisible); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 8, 0, (_co.avatarUrl ? (("url(\"" + _co.avatarUrl) + "\")") : undefined)); _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.items; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.userFullName; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.dropDownVisible; _ck(_v, 12, 0, currVal_4); }); }
exports.View_UserSettingsComponent_0 = View_UserSettingsComponent_0;
function View_UserSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-settings", [], null, null, null, View_UserSettingsComponent_0, RenderType_UserSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i6.UserSettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UserSettingsComponent_Host_0 = View_UserSettingsComponent_Host_0;
var UserSettingsComponentNgFactory = i1.ɵccf("user-settings", i6.UserSettingsComponent, View_UserSettingsComponent_Host_0, { items: "items", userFullName: "userFullName" }, {}, []);
exports.UserSettingsComponentNgFactory = UserSettingsComponentNgFactory;
