import { Component, OnInit, Input, Output, EventEmitter, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'modal',
    templateUrl: './modal.component-ng.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {
    @Input() headerTitle: string;
    @Input() includeTitleBar: boolean;
    @Input() submitButtonVisible: boolean;
    @Input() submitButtonDisabled: boolean;
    @Input() submitButtonText: string;
    @Input() closeButtonText: string;
    @Input() closeButtonVisible: boolean;
    @Input() closeIconVisible: boolean;
    @Output() onClose = new EventEmitter<null>();
    @Output() onSubmit = new EventEmitter<null>();
    @Input() headerTextClass: string;

    public translatedHeaderTitle: SafeHtml;

    constructor(private translateService: TranslateService,
        private sanitizer: DomSanitizer) { }

    ngOnInit() {
        if (this.closeButtonVisible === undefined) {
            this.closeButtonVisible = true;
        }

        if (this.includeTitleBar === undefined) {
            this.includeTitleBar = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.headerTitle && changes.headerTitle.currentValue) {
            const title = this.translateService.instant(changes.headerTitle.currentValue);
            this.translatedHeaderTitle = this.sanitizer.bypassSecurityTrustHtml(title);
        }

        if (changes.headerClass && changes.headerClass.firstChange) {

            if (changes.headerClass && changes.headerClass.currentValue) {
                this.headerTextClass = `modal-header-text ${this.headerTextClass}`;
            }
        }
    }

}
