"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var storage_service_1 = require("../modules/storage/storage.service");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../modules/storage/storage.service"));
var DateFormatService = /** @class */ (function () {
    function DateFormatService(storageService) {
        this.storageService = storageService;
    }
    DateFormatService.prototype.getDateTimeFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'dd/MM/yyyy hh:mm a';
            case 'en-ca':
                return 'dd/MM/yyyy h:mm a';
            case 'fr-ca':
                return 'yyyy-MM-dd hh:mm a';
            case 'fr-fr':
                return 'dd/MM/yyyy H:mm';
            case 'en-us':
            default:
                return 'MM/dd/yyyy h:mm a';
        }
    };
    DateFormatService.prototype.getDateTimeMomentFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        return this.getDateMomentFormatString(thisCultureName) + " " + this.getTimeMomentFormatString(thisCultureName);
    };
    DateFormatService.prototype.getDateMomentFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'DD/MM/YYYY';
            case 'en-ca':
                return 'DD/MM/YYYY';
            case 'fr-ca':
                return 'DD/MM/YYYY';
            case 'fr-fr':
                return 'DD/MM/YYYY';
            case 'en-us':
            default:
                return 'MM/DD/YYYY';
        }
    };
    DateFormatService.prototype.getTimeMomentFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'hh:mm A';
            case 'en-ca':
                return 'hh:mm A';
            case 'fr-ca':
                return 'hh:mm A';
            case 'fr-fr':
                return 'H:mm';
            case 'en-us':
            default:
                return 'hh:mm A';
        }
    };
    DateFormatService.prototype.getDateFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'dd/MM/yyyy';
            case 'en-ca':
                return 'dd/MM/yyyy';
            case 'fr-ca':
                return 'yyyy-MM-dd';
            case 'fr-fr':
                return 'dd/MM/yyyy';
            case 'en-us':
            default:
                return 'MM/dd/yyyy';
        }
    };
    // These are the alternate input formats supported by the datePicker.  This
    // allows the input of dates with a single digit month or date without having
    // to provide a leading zero.
    DateFormatService.prototype.getAlternateDateFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'd!/M!/yyyy';
            case 'en-ca':
                return 'd!/M!/yyyy';
            case 'fr-ca':
                return 'yyyy-M!-d!';
            case 'fr-fr':
                return 'd!/M!/yyyy';
            case 'en-us':
            default:
                return 'M!/d!/yyyy';
        }
    };
    DateFormatService.prototype.getTimeFormatString = function (cultureName) {
        var thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'hh:mm a';
            case 'en-ca':
                return 'h:mm a';
            case 'fr-ca':
                return 'hh:mm a';
            case 'en-us':
            default:
                return 'h:mm a';
        }
    };
    DateFormatService.prototype.getCultureFromStorage = function () {
        return this.storageService.getItem('cultureName') || 'en-us';
    };
    DateFormatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateFormatService_Factory() { return new DateFormatService(i0.ɵɵinject(i1.StorageService)); }, token: DateFormatService, providedIn: "root" });
    return DateFormatService;
}());
exports.DateFormatService = DateFormatService;
