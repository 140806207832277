"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var DealerSettingsService = /** @class */ (function () {
    function DealerSettingsService(legacyService, dsClientService, modelClientService, trimClientService) {
        this.legacyService = legacyService;
        this.dsClientService = dsClientService;
        this.modelClientService = modelClientService;
        this.trimClientService = trimClientService;
    }
    DealerSettingsService.prototype.GetDealerSettingAll = function (dealerId) {
        var result = this.dsClientService.GetDealerSettingAllByDealeridGET(dealerId).toPromise();
        return result;
    };
    DealerSettingsService.prototype.UpdateDealerSettingAll = function (dsa) {
        var result = this.dsClientService.UpdateDealerSettingAllPOST(dsa).toPromise();
        return result;
    };
    DealerSettingsService.prototype.GetTrim = function (trimId) {
        return this.trimClientService.ByIdGET(trimId).toPromise();
    };
    DealerSettingsService.prototype.GetTrims = function (modelId) {
        return this.trimClientService.GetTrimsByModelsPOST([modelId]).toPromise();
    };
    DealerSettingsService.prototype.GetModel = function (modelId) {
        return this.modelClientService.ByIdGET(modelId).toPromise();
    };
    DealerSettingsService.prototype.GetModels = function (makeId) {
        return this.modelClientService.GetModelsByMakeByMakeidGET(makeId).toPromise();
    };
    DealerSettingsService.prototype.GetModelsByMakeYear = function (makeId, year) {
        return this.modelClientService.GetModelsByMakesYearsPOST({ makeIDs: [makeId], years: [year] }).toPromise();
    };
    DealerSettingsService.prototype.SendTestAdfEmail = function (request) {
        return this.dsClientService.SendTestAdfEmailPOST(request).toPromise();
    };
    DealerSettingsService.prototype.GetTextNumbers = function (dealerId, overrideStateCode, areaCode) {
        var params = {
            dealerId: dealerId,
            stateCode: overrideStateCode,
            areaCode: overrideStateCode != null ? null : areaCode
        };
        return this.dsClientService.GetTextNumbersByDealeridGET(params).toPromise();
    };
    DealerSettingsService.prototype.UseLegacyDealerSettingsPage = function () {
        return this.dsClientService.UseLegacyDealerSettingsPageGET().toPromise();
    };
    return DealerSettingsService;
}());
exports.DealerSettingsService = DealerSettingsService;
