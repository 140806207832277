
/* injects from baggage-loader */
require('./eula-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('eulaModal', {
    templateUrl: '/app/components/deal-sheet/modals/eula-modal.component.html',
    controller: EulaModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function EulaModalController() {
    var $ctrl = this;

    $ctrl.selection;
    $ctrl.onSelect = onSelect;
    $ctrl.setSelection = setSelection;

    function onSelect(selection) {
        $ctrl.selection = selection;
    }

    function setSelection(selection) {
        $ctrl.selection = selection;
        $ctrl.close({ $value: selection });
    }
}
