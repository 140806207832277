import "core-js/modules/es6.function.name";
QuickSearchController.$inject = ["$state", "$translate", "$transitions", "toaster", "warningTitleKey", "quickSearchWarningKey", "quickSearchInvalidCharacterWarningKey"];

/* injects from baggage-loader */
require('./quick-search.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('quickSearch', {
  templateUrl: '/app/components/header/quick-search.component.html',
  controller: QuickSearchController,
  bindings: {
    searchPresetsVisible: '='
  }
});
/* @ngInject */

function QuickSearchController($state, $translate, $transitions, toaster, warningTitleKey, quickSearchWarningKey, quickSearchInvalidCharacterWarningKey) {
  var $ctrl = this;
  $ctrl.searchText;
  $ctrl.isSltUser;
  $ctrl.placeholder;
  $ctrl.searchOnKeyPress = searchOnKeyPress;
  $ctrl.searchOnButtonClick = searchOnButtonClick;
  $ctrl.$onInit = init;

  function init() {
    $transitions.onBefore({
      from: 'quickSearch'
    }, function (transition) {
      if (transition.to().name != 'quickSearch') {
        $ctrl.searchText = '';
      }
    });
  }

  function searchOnKeyPress(event) {
    if (event.which === 13) {
      trySearch();
    }
  }

  function searchOnButtonClick() {
    trySearch();
  }

  function trySearch() {
    if ($ctrl.toast) {
      toaster.clear($ctrl.toast);
    }

    if ($state.params.searchText == $ctrl.searchText) {
      $state.reload();
    } else {
      if ($ctrl.searchText && /[&#]/.test($ctrl.searchText)) {
        $translate([warningTitleKey, quickSearchInvalidCharacterWarningKey]).then(showWarningMessageInvalidCharacter);
      } else if ($ctrl.searchText && $ctrl.searchText.length >= 3) {
        search();
      } else {
        $translate([warningTitleKey, quickSearchWarningKey]).then(showWarningMessage);
      }
    }
  }

  function showWarningMessageInvalidCharacter(translations) {
    $ctrl.toast = toaster.pop('warning', translations.warningTitle, translations.quickSearchInvalidCharacterWarning);
  }

  function showWarningMessage(translations) {
    $ctrl.toast = toaster.pop('warning', translations.warningTitle, translations.quickSearchWarning);
  }

  function search() {
    $state.go('quickSearch', {
      searchText: $ctrl.searchText
    });
  }
}