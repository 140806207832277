"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var AdminSettingsModalSendTestLeadComponent = /** @class */ (function () {
    function AdminSettingsModalSendTestLeadComponent(_bsModalRef, tService, logger) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.logger = logger;
        //public isEmailIValid: boolean = true;
        this.email = "";
        this.source = "AutoAlert";
        this.sendTestLead = new core_1.EventEmitter();
    }
    AdminSettingsModalSendTestLeadComponent.prototype.ngOnInit = function () {
        this._bsModalRef.hide();
    };
    AdminSettingsModalSendTestLeadComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    AdminSettingsModalSendTestLeadComponent.prototype.sendEmail = function (sendLeadForm) {
        if (sendLeadForm.valid) {
            this.sendTestLead.emit({ email: this.email, source: this.source });
            this._bsModalRef.hide();
        }
    };
    AdminSettingsModalSendTestLeadComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
    };
    return AdminSettingsModalSendTestLeadComponent;
}());
exports.AdminSettingsModalSendTestLeadComponent = AdminSettingsModalSendTestLeadComponent;
