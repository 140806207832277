import angular from 'angular';

angular.module('AaApp.Opp').constant('alertIconClasses', {
    100: 'faalert',
    200: 'faflexalert',
    400: 'fainmarket',
    500: 'fainmarketengaged',
    600: 'facontractEnd',
    700: 'faservice',
    800: 'faappointment',
    900: 'famileage',
    1000: 'fawarranty'
});
