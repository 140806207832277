"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var core_2 = require("@ngx-translate/core");
var text_message_service_1 = require("../../../services/text-message.service");
var locale_date_time_pipe_1 = require("../../../filters/locale-date-time.pipe");
var rxjs_1 = require("rxjs");
var constants_1 = require("../../../constants");
var TextMessageActivityComponent = /** @class */ (function () {
    function TextMessageActivityComponent(domSanitizer, translate, legacyService, textMessageService, localeDatetimePipe) {
        this.domSanitizer = domSanitizer;
        this.translate = translate;
        this.legacyService = legacyService;
        this.textMessageService = textMessageService;
        this.localeDatetimePipe = localeDatetimePipe;
        this.displayMessages = false;
        this.salesOrServiceLabel = { SalesOrService: 'Sales' };
        this.authorizationKeys = constants_1.AuthorizationKeys;
        this.subscriptionAggregator = new rxjs_1.Subscription();
    }
    TextMessageActivityComponent.prototype.ngOnInit = function () {
        var _this = this;
        var sub = this.translate.get('customer')
            .subscribe(function (translatedCustomerLabel) {
            _this.sentBy = _this.getSentByLabel(_this.activity.textMessages, translatedCustomerLabel);
        });
        this.subscriptionAggregator.add(sub);
    };
    TextMessageActivityComponent.prototype.ngOnDestroy = function () {
        this.subscriptionAggregator.unsubscribe();
    };
    TextMessageActivityComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes && changes.activity && changes.activity.currentValue && changes.activity.currentValue.textMessages) {
            var textMessages = changes.activity.currentValue.textMessages;
            textMessages.map(function (t) { return t.message = t.message.replace("\r\n", "<br />"); });
            textMessages.map(function (t) { return t.safeMessage = _this.domSanitizer.bypassSecurityTrustHtml(text_message_service_1.TextMessageService.replaceUrlStringWithHtmlLink(t.message, false)); });
            this.salesOrServiceLabel.SalesOrService = (textMessages[0].dealerPhoneAccessRightID === this.authorizationKeys.SendTextSales) ? 'Sales' : 'Service';
        }
    };
    TextMessageActivityComponent.prototype.viewUpgradeProposal = function (activityId) {
        if (this.mobileView)
            return;
        this.legacyService.downloadUpgradeProposalByActivity({
            activityId: activityId
        });
    };
    TextMessageActivityComponent.prototype.handleUpgradeProposalLinkClick = function ($event, textMessage) {
        if (this.mobileView)
            return;
        this.textMessageService.downloadUpgradeProposalIfAvailable($event, textMessage);
    };
    TextMessageActivityComponent.prototype.formatSentDateTime = function (sentDateTime) {
        var localizedDate = new Date(sentDateTime);
        return localizedDate.toLocaleTimeString(this.translate.currentLang, { hour: 'numeric', minute: 'numeric' });
    };
    TextMessageActivityComponent.prototype.getSentByLabel = function (textMessages, customerLabel) {
        var customerSentBy = '';
        var userSentByMap = new Map();
        textMessages.forEach(function (t) {
            if (t.outgoing) {
                if (!userSentByMap.has(t.userId)) {
                    userSentByMap.set(t.userId, t.userFirstName + " " + t.userLastName);
                }
            }
            else {
                customerSentBy = t.customerFirstName + " " + t.customerLastName + " (" + customerLabel + "),";
            }
        });
        var userSentBy = Array.from(userSentByMap.values()).join(', ');
        return customerSentBy + " " + userSentBy;
    };
    return TextMessageActivityComponent;
}());
exports.TextMessageActivityComponent = TextMessageActivityComponent;
