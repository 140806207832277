"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var toaster_logger_service_1 = require("../../../core/toaster-logger.service");
var rxjs_1 = require("rxjs");
var constants_1 = require("../../../constants");
var DeskitComponent = /** @class */ (function () {
    function DeskitComponent(dealSheetService, logger) {
        this.dealSheetService = dealSheetService;
        this.logger = logger;
        this.subscriptionAggregator = new rxjs_1.Subscription();
        this.showDeskitLink = false;
        this.saleTypes = constants_1.SaleTypes;
        this.vehicleTypes = constants_1.VehicleTypes;
        this.deskitImagePath = require('../../../images/icon-desking-lake.svg');
    }
    DeskitComponent.prototype.ngOnInit = function () {
    };
    DeskitComponent.prototype.ngOnDestroy = function () {
        this.subscriptionAggregator.unsubscribe();
    };
    DeskitComponent.prototype.ngOnChanges = function (changes) {
        if (!this.dealerSettings || !this.dealerId || !this.entityId || !this.customer || !this.currentVehicle) {
            return;
        }
        this.showDeskitLink = this.dealerSettings.deskitPushEnabled;
    };
    DeskitComponent.prototype.deskitPush = function () {
        var _this = this;
        var trade = {
            vin: this.currentVehicle.vin,
            year: this.currentVehicle.yearID,
            make: this.currentVehicle.makeName,
            model: this.currentVehicle.modelName,
            modelTrim: this.currentVehicle.trimFullName,
            mileage: this.currentVehicle.estimatedCurrentMileage
        };
        var soldVehicle = {
            stockNumber: this.replacementVehicle.stockNo,
            inventoryType: this.replacementVehicle.type == this.vehicleTypes.new ? 'N' : 'U',
            vin: this.replacementVehicle.vin,
            make: this.replacementVehicle.makeName,
            model: this.replacementVehicle.modelName,
            year: this.replacementVehicle.yearID,
            modelTrim: this.replacementVehicle.trimFullName
        };
        var request = {
            dealerID: this.dealerId,
            entityID: this.entityId,
            customerID: String(this.customer.id),
            dealType: (this.saleType === this.saleTypes.lease || this.saleType === this.saleTypes.onePayLease) ? "Lease" : "Finance",
            firstName: this.customer.firstName,
            middleName: this.customer.middleName,
            lastName: this.customer.lastName,
            companyName: this.customer.companyName,
            addressLines: [this.customer.address.streetAddress, this.customer.address.streetAddress2].filter(function (value) { return value !== null && value !== undefined && value !== ""; }),
            city: this.customer.address.city,
            county: this.customer.county,
            state: this.customer.address.stateName,
            zipCode: this.customer.address.zipCode,
            homePhone: this.customer.homePhoneNumber && this.customer.homePhoneNumber.value !== undefined ? this.customer.homePhoneNumber.value : null,
            cellPhone: this.customer.cellPhoneNumber && this.customer.cellPhoneNumber.value !== undefined ? this.customer.cellPhoneNumber.value : null,
            workPhone: this.customer.workPhoneNumber && this.customer.workPhoneNumber.value !== undefined ? this.customer.workPhoneNumber.value : null,
            email: this.customer.emailAddress && this.customer.emailAddress.value !== undefined ? this.customer.emailAddress.value : null,
            tradeVehicles: [trade],
            soldVehicle: soldVehicle
        };
        return rxjs_1.from(this.dealSheetService.pushToDeskit(request))
            .subscribe(function (result) {
            if (result != null) {
                if (result.message.status == 1) {
                    _this.logger.success("Data successfully pushed to Deskit.", "Deskit DealID: " + result.dealID, "Push to Deskit");
                }
                else {
                    _this.logger.error("There was an error attempting to push data to Deskit.", result.message.errorMessage, "Push to Deskit");
                }
            }
        });
    };
    DeskitComponent.prototype.pushToDeskit = function () {
        this.busy = this.deskitPush();
        this.subscriptionAggregator.add(this.busy);
    };
    return DeskitComponent;
}());
exports.DeskitComponent = DeskitComponent;
