import "core-js/modules/es6.regexp.split";
ReportsHeaderController.$inject = ["$logFactory"];

/* injects from baggage-loader */
require('./reports-header.component.html');

import angular from 'angular';
import reportHeaders from '../../constants/reports/report-header-types';
import moment from 'moment';
angular.module('AaApp.Opp').component('reportsHeader', {
  templateUrl: '/app/components/reports/reports-header.component.html',
  controller: ReportsHeaderController,
  bindings: {
    reportId: '<',
    reportTitle: '<',
    headerType: '<',
    headerData: '<',
    loadReport: '<',
    updateMakes: '<',
    loadReportPresetRange: '<',
    generateReportExport: '<',
    datePickerSelection: '<',
    reportSpecification: '<',
    onDealershipChanged: '&',
    isPandoReportAvailable: '<'
  }
});
/* @ngInject */

function ReportsHeaderController($logFactory) {
  var $ctrl = this;
  var log = $logFactory.get();
  $ctrl.showDealerSelector;
  $ctrl.showMakeSelector;
  $ctrl.showDatesSelectors;
  $ctrl.showStartDateSelector;
  $ctrl.showDatePresets;
  $ctrl.showValidatedSelector;
  $ctrl.showSurveySelectors;
  $ctrl.startDatePickerVisible = false;
  $ctrl.endDatePickerVisible = false;
  $ctrl.$onChanges = onChanges;
  $ctrl.reportSettingsToggle = reportSettingsToggle;
  $ctrl.setDatePickerVisiblity = setDatePickerVisiblity;
  $ctrl.onDealershipChangedWrapper = onDealershipChangedWrapper;
  $ctrl.setPandoReportState = setPandoReportState;
  $ctrl.setSurveyFamilySurveySelections = setSurveyFamilySurveySelections;
  $ctrl.showReportSettingsButton = false;
  $ctrl.isTabletDevice = false;
  $ctrl.onCalendarDateRangeSelect = onCalendarDateRangeSelect;
  $ctrl.onCalendarPresetDateRangeSelect = onCalendarPresetDateRangeSelect;
  $ctrl.onPerfReportDateRangeSelect = onPerfReportDateRangeSelect;
  $ctrl.perfReportDateRange; // 2/15/2017 as per product team we are showing the new calendar control in ipad
  // so no need to detect tablet;
  //if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i))
  //    $ctrl.isTabletDevice = true;
  //else
  //    $ctrl.isTabletDevice = false;

  $ctrl.datePickerOptions = {
    startingDay: 0,
    showWeeks: false,
    minDate: null,
    maxDate: new Date()
  };

  function onChanges(changes) {
    $ctrl.showDealerSelector = true;
    $ctrl.showMakeSelector = false;
    $ctrl.showDatesSelectors = false;
    $ctrl.showStartDateSelector = false;
    $ctrl.showDatePresets = false;
    $ctrl.showValidatedSelector = false;
    $ctrl.showSurveySelectors = false;

    switch ($ctrl.headerType) {
      case reportHeaders.full:
        setFullHeaderTypeInputs();
        break;

      case reportHeaders.validatedFull:
        setValidatedFullHeaderTypeInputs();
        break;

      case reportHeaders.singleDate:
        setSingleDateHeaderTypeInputs();
        break;

      case reportHeaders.dealerOnly:
        setDealerOnlyHeaderTypeInputs();
        break;

      case reportHeaders.dealerOnlyNoSubscriptionDateSelector:
        setDealerOnlyHeaderTypeInputs();
        break;

      case reportHeaders.performanceReport:
        setPerformanceReportHeaderTypeInputs();
        break;

      case reportHeaders.dealerMake:
        setDealerMakeHeaderTypeInputs();
        break;

      case reportHeaders.dealerMakeNoSubscriptionDateSelector:
        setDealerMakeHeaderTypeInputs();
        break;

      case reportHeaders.dealerDatesPresets:
        setDealerDatesPresetsHeaderTypeInputs();
        break;

      case reportHeaders.pandoSurvey:
        setPandoSurveyTypeInputs();
        break;

      default:
        break;
    }

    setReportSettingsButtonVisiblity(); //console.log("headerData", $ctrl.headerData)
  }

  function setFullHeaderTypeInputs() {
    $ctrl.showMakeSelector = true;
    $ctrl.showDatesSelectors = true;
    $ctrl.showStartDateSelector = true;
    $ctrl.showDatePresets = true;
  }

  function setValidatedFullHeaderTypeInputs() {
    $ctrl.showMakeSelector = true;
    $ctrl.showDatesSelectors = true;
    $ctrl.showStartDateSelector = true;
    $ctrl.showDatePresets = true;
  }

  function setSingleDateHeaderTypeInputs() {
    $ctrl.showDatesSelectors = true;
  }

  function setDealerOnlyHeaderTypeInputs() {}

  function setDealerDatesPresetsHeaderTypeInputs() {
    $ctrl.showDatesSelectors = true;
    $ctrl.showStartDateSelector = true;
    $ctrl.showDatePresets = true;
  }

  function setPerformanceReportHeaderTypeInputs() {
    $ctrl.showPerformanceReportDateSelector = true; // set initial date range

    if ($ctrl.headerData.performanceReportDateRanges.length > 0) {
      $ctrl.perfReportDateRange = $ctrl.headerData.performanceReportDateRanges[0].startDate + "|" + $ctrl.headerData.performanceReportDateRanges[0].endDate;
    }
  }

  function setDealerMakeHeaderTypeInputs() {
    $ctrl.showMakeSelector = true;
  }

  function setPandoSurveyTypeInputs() {
    $ctrl.showDealerSelector = false;
    $ctrl.showSurveySelectors = true;
  }

  function reportSettingsToggle() {
    $ctrl.headerData.showReportSettings = !$ctrl.headerData.showReportSettings;
  }

  function setDatePickerVisiblity(isStart) {
    if (isStart) {
      $ctrl.startDatePickerVisible = true;
    } else {
      $ctrl.endDatePickerVisible = true;
    }
  }

  function onDealershipChangedWrapper(dealerId, makeId) {
    $ctrl.headerData.dealerId = dealerId;
    $ctrl.headerData.makeId = makeId;

    if ($ctrl.onDealershipChanged) {
      $ctrl.onDealershipChanged({
        $onDealershipChanged: {
          dealerId: dealerId,
          makeId: makeId
        }
      });
    }
  }

  function setPandoReportState(state) {
    $ctrl.pandoReportState = state;
  }

  function setSurveyFamilySurveySelections(surveyFamilyId, surveyId, surveys, state) {
    $ctrl.headerData.surveyFamilyId = surveyFamilyId;
    $ctrl.headerData.surveyId = surveyId;
    $ctrl.headerData.surveys = surveys;
    $ctrl.pandoReportState = state;
    $ctrl.loadReport();
  }

  function setReportSettingsButtonVisiblity() {
    if ($ctrl.reportSpecification) {
      $ctrl.showReportSettingsButton = $ctrl.reportSpecification.pdfExportEnabled === true || $ctrl.reportSpecification.excelExportEnabled === true || $ctrl.reportSpecification.subscriptionEnabled === true;
    }
  }

  function onCalendarDateRangeSelect(dateRange) {
    //console.log(dateRange);
    $ctrl.headerData.endDate = dateRange.end;
    $ctrl.headerData.startDate = dateRange.start;
    $ctrl.headerData.selectedDateRangeType = "UI";
    $ctrl.loadReport('DateRangeSelectionChanged', true);
  }

  function onCalendarPresetDateRangeSelect(presetDateType) {
    //console.log(presetDateType);
    $ctrl.headerData.selectedDateRangeType = presetDateType;
    $ctrl.loadReportPresetRange($ctrl.headerData.selectedDateRangeType);
  }

  function onPerfReportDateRangeSelect(dateRange) {
    log.log("dateRange change: " + dateRange);
    var dates = dateRange.split("|");
    $ctrl.headerData.startDate = moment(dates[0], 'YYYY-M-D', true).toDate();
    $ctrl.headerData.endDate = moment(dates[1], 'YYYY-M-D', true).toDate();
    $ctrl.loadReport('DateRangeSelectionChanged', true);
  }
}