<div class="admin-roles-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="page-title" translate="rolesmanagement">
            </div>
        </div>
    </div>
    <div class="divider">
    </div>
    <div class="row">
        <div class="roleSelectContainer">
            <div class="col-md-10">
                <div class="roleLabel" translate="rolesAdmin_roleAndCustomPermissions"></div>
                <div class="roleSelect">
                    <select class="form-control" (change)="onRoleChanged()" [(ngModel)]="selectedRole">
                        <optgroup *ngFor="let item of roleOptions" label="{{ item[0].optionGroupNameKey | translate }}">
                            <option *ngFor="let subItem of item" [ngValue]="subItem">
                                {{ subItem.roleNameForSelect }}
                            </option>
                        </optgroup>
                    </select>
                </div>
                <div *ngIf="selectedRole && selectedRole.isEditable" (click)="onEditRole()" class="roleEditButton"><img src="../../images/icon-pencil-lake.svg" /></div>
                <div *ngIf="selectedRole && selectedRole.isEditable && selectedRole.parentRoleID" class="roleSelectNote" [innerHtml]="getRoleSelectNote()"></div>
                <div *ngIf="selectedRole && selectedRole.isEditable && !selectedRole.parentRoleID" class="roleSelectNotePleaseEdit" translate="adminRoles_pleaseEdit"></div>
            </div>
            <div class="col-md-2">
                <div class="roleAddNew"><a href="#" (click)="onAddRole()" translate="addNewRole"></a></div>
            </div>
        </div>
    </div>
    <div class="adminRoleTabContainer">
        <tabset>
            <tab heading="{{ 'moduleAccess' | translate }}">
                <div class="bold" translate="managemodulesaccess"></div>
                <admin-roles-selector [accessList]="moduleSettings"
                                      [categories]="moduleCategories"
                                      [readOnly]="selectedRole && !selectedRole.isEditable"
                                      (onSaveChanges)="saveChanges()"
                                      (onCancelChanges)="cancelChanges()"
                                      (accessRightChanged)="accessRightChanged = $event">
                </admin-roles-selector>
            </tab>
            <tab heading="{{ 'adminRoles_accessRights' | translate }}">
                <div class="bold" translate="manageaccessrights"></div>
                <admin-roles-selector [accessList]="rightSettings"
                                      [categories]="rightCategories"
                                      [readOnly]="selectedRole && !selectedRole.isEditable"
                                      [hideCategoryCheckbox]="true"
                                      (onSaveChanges)="saveChanges()"
                                      (onCancelChanges)="cancelChanges()"
                                      (accessRightChanged)="accessRightChanged = $event">
                </admin-roles-selector>
            </tab>
        </tabset>
    </div>
</div>