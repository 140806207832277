"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityResultTypeDescriptors = [
    {
        'activityResultID': 1,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.APPOINTMENT_SET',
        'activityResultPriority': 1,
        'needPageReload': 0
    },
    {
        'activityResultID': 2,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.CALL_BACK',
        'activityResultPriority': 4,
        'needPageReload': 0
    },
    {
        'activityResultID': 3,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.DO_NOT_CALL',
        'activityResultPriority': 8,
        'needPageReload': 1
    },
    {
        'activityResultID': 4,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.LEFT_MESSAGE',
        'activityResultPriority': 5,
        'needPageReload': 0
    },
    {
        'activityResultID': 5,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.NO_ANSWER',
        'activityResultPriority': 6,
        'needPageReload': 0
    },
    {
        'activityResultID': 6,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.NOT_INTERESTED',
        'activityResultPriority': 7,
        'needPageReload': 0
    },
    {
        'activityResultID': 7,
        'activityResultSetID': 2,
        'activityResultNameTranslateKey': 'ACTIVITY.SOLD',
        'activityResultPriority': 4,
        'needPageReload': 0
    },
    {
        'activityResultID': 8,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.DID_NOT_SHOW',
        'activityResultPriority': 1,
        'needPageReload': 0
    },
    {
        'activityResultID': 9,
        'activityResultSetID': 2,
        'activityResultNameTranslateKey': 'ACTIVITY.SHOWED_CLOSED',
        'activityResultPriority': 3,
        'needPageReload': 0
    },
    {
        'activityResultID': 10,
        'activityResultSetID': 2,
        'activityResultNameTranslateKey': 'ACTIVITY.SHOWED_OPEN',
        'activityResultPriority': 2,
        'needPageReload': 0
    },
    {
        'activityResultID': 11,
        'activityResultSetID': 2,
        'activityResultNameTranslateKey': 'ACTIVITY.SOLD',
        'activityResultPriority': 1,
        'needPageReload': 0
    },
    {
        'activityResultID': 19,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.SPOKE_TO_CLIENT',
        'activityResultPriority': 3,
        'needPageReload': 0
    },
    {
        'activityResultID': 20,
        'activityResultSetID': 3,
        'activityResultNameTranslateKey': 'ACTIVITY.APPOINTMENT_SET',
        'activityResultPriority': 2,
        'needPageReload': 0
    },
    {
        'activityResultID': 21,
        'activityResultSetID': 3,
        'activityResultNameTranslateKey': 'ACTIVITY.SPOKE_TO_CLIENT',
        'activityResultPriority': 3,
        'needPageReload': 0
    },
    {
        'activityResultID': 22,
        'activityResultSetID': 3,
        'activityResultNameTranslateKey': 'ACTIVITY.UPGRADE_PROPOSAL',
        'activityResultPriority': 4,
        'needPageReload': 0
    },
    {
        'activityResultID': 23,
        'activityResultSetID': 3,
        'activityResultNameTranslateKey': 'ACTIVITY.SOLD',
        'activityResultPriority': 5,
        'needPageReload': 0
    },
    {
        'activityResultID': 24,
        'activityResultSetID': 3,
        'activityResultNameTranslateKey': 'ACTIVITY.NOT_INTERESTED',
        'activityResultPriority': 6,
        'needPageReload': 0
    },
    {
        'activityResultID': 25,
        'activityResultSetID': 3,
        'activityResultNameTranslateKey': 'ACTIVITY.MISCELLANEOUS',
        'activityResultPriority': 1,
        'needPageReload': 0
    },
    {
        'activityResultID': 33,
        'activityResultSetID': 1,
        'activityResultNameTranslateKey': 'ACTIVITY.VEHICLEPAIDOFF',
        'activityResultPriority': 1,
        'needPageReload': 0
    }
];
