"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../../core/toaster-logger.service");
var dms_push_modal_component_1 = require("../dms-push-modal/dms-push-modal.component");
var ngx_modal_wrapper_service_1 = require("../../../services/ngx-modal-wrapper.service");
var DmsPushComponent = /** @class */ (function () {
    function DmsPushComponent(modalService, tService, logger) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
    }
    DmsPushComponent.prototype.ngOnInit = function () {
    };
    DmsPushComponent.prototype.ngOnChanges = function (changes) {
    };
    DmsPushComponent.prototype.dmsPushModal = function () {
        var initialState = {
            dealerId: this.dealerId,
            customer: this.customer,
            states: this.states,
            currentVin: this.currentVin,
            currentTradeValue: this.currentTradeValue,
            replacementVin: this.replacementVin,
            replacementPrice: this.replacementPrice,
            replacementSaleType: this.replacementSaleType,
            currentYear: this.currentYear,
            currentMake: this.currentMake,
            currentModel: this.currentModel,
            currentMileage: this.currentMileage,
        };
        this.bsModalRef = this.modalService.show(dms_push_modal_component_1.DmsPushModalComponent, { initialState: initialState, class: 'dms-push-modal' });
        this.bsModalRef.content.onSaveChanges.subscribe(function (result) {
        });
    };
    return DmsPushComponent;
}());
exports.DmsPushComponent = DmsPushComponent;
