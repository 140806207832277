"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var stock_number_search_service_1 = require("../../../services/stock-number-search.service");
var core_2 = require("@uirouter/core");
var user_service_1 = require("../../../services/user.service");
var StockNumberSearchComponent = /** @class */ (function () {
    function StockNumberSearchComponent(userService, stockNumberSearchService, stateService) {
        this.userService = userService;
        this.stockNumberSearchService = stockNumberSearchService;
        this.stateService = stateService;
        this.inventoryStockMinViewCtrl = new forms_1.FormControl();
        this.myForm = new forms_1.FormGroup({
            inventoryStockMinView: this.inventoryStockMinViewCtrl
        });
        this.inventoryStockMinViews = [];
        this.selected = {};
    }
    StockNumberSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchText = '';
        this.isLoaded = false;
        this.userService.getUserProfile()
            .then(function (userProfile) { return _this.isSltUser = userProfile.isSltUser; });
        this.stockNumberSearchService.getStockList()
            .then(function (inventoryStockMinViews) { return _this.inventoryStockMinViews = inventoryStockMinViews; });
    };
    StockNumberSearchComponent.prototype.onSelect = function (stockNoInput) {
        var stockNo = (stockNoInput.value) ? stockNoInput.value : stockNoInput;
        if (this.stateService.params.stockNo == stockNo) {
            this.stateService.reload();
        }
        else {
            var params = {
                isShowUsed: true,
                hasOpp: true,
                sortColumnName: 'GrossProfitFormatted',
                stockNo: stockNo
            };
            this.stateService.go('preOwnedManagerFindABuyer', params);
        }
    };
    return StockNumberSearchComponent;
}());
exports.StockNumberSearchComponent = StockNumberSearchComponent;
