import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InventoryStockMinViewDto } from '../../../generated/models/inventory-stock-min-view-dto';
import { StockNumberSearchService } from '../../../services/stock-number-search.service';
import { StateService } from '@uirouter/core';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'stock-number-search',
    templateUrl: './stock-number-search.component-ng.html',
    styleUrls: ['./stock-number-search.component.scss']
})
export class StockNumberSearchComponent implements OnInit {

    inventoryStockMinViewCtrl = new FormControl();

    myForm = new FormGroup({
        inventoryStockMinView: this.inventoryStockMinViewCtrl
    });
    
    inventoryStockMinViews: Array<InventoryStockMinViewDto> = [];

    selected: InventoryStockMinViewDto = {};
    searchText: string;
    isSltUser: boolean;
    isLoaded: boolean;
    
    constructor(
        private userService: UserService,
        private stockNumberSearchService: StockNumberSearchService,
        private stateService: StateService
    ) { }

    ngOnInit() {
        this.searchText = '';
        this.isLoaded = false;

        this.userService.getUserProfile()
            .then((userProfile) => this.isSltUser = userProfile.isSltUser);

        this.stockNumberSearchService.getStockList()
            .then((inventoryStockMinViews) => this.inventoryStockMinViews = inventoryStockMinViews);
    }
    
    onSelect(stockNoInput: any): void {
        let stockNo = (stockNoInput.value) ? stockNoInput.value : stockNoInput;
        if (this.stateService.params.stockNo == stockNo) {
            this.stateService.reload();
        }
        else {
            var params = {
                isShowUsed: true,
                hasOpp: true,
                sortColumnName: 'GrossProfitFormatted',
                stockNo: stockNo
            };
            this.stateService.go('preOwnedManagerFindABuyer', params)
        }
    }
}
