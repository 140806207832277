import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { Activity } from '../../../../interfaces/activity.interface';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { UserDtoExt } from '../../../../interfaces/user-dto-ext.interface';
import { UserInfo } from '../../../../interfaces/user-info.interface';
import { MobileDealSheetDataCachingService } from '../../../../services/mobile-deal-sheet-data-caching.service';

@Component({
    selector: 'mobile-activities',
    templateUrl: './mobile-activities.component-ng.html',
    styleUrls: ['./mobile-activities.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileActivitiesComponent implements OnInit, OnDestroy {
    opportunitySubscription: Subscription;
    usersSubscription: Subscription;
    activitiesSubscription: Subscription;
    opportunityId: string;

    public busy: Promise<any> | Subscription;

    activities: Activity[];
    permissions: DealSheetPermissions;
    status: any;
    users: UserDtoExt[];
    assignedUserFullName: string;
    assignedUserId: number;
    ownerUserInfo: UserInfo;
    hasReviewPro: boolean;
    hasGeoAlert: boolean;
    fullName: string;
    opportunity: DealSheetOpportunity;

    constructor(private stateService: StateService,
        private mobileDealSheetDataCachingService: MobileDealSheetDataCachingService,
        private transition: Transition) {

        this.opportunityId = this.transition.params().opportunityId;
    }

    ngOnInit() {

        this.startWorkingState();
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(dealSheetData => {
            this.opportunity = dealSheetData.opportunity;
            this.permissions = dealSheetData.permissions;
            this.status = dealSheetData.opportunity.status;
            this.assignedUserFullName = dealSheetData.opportunity.assignedUserFullName;
            this.assignedUserId = dealSheetData.opportunity.assignedUserId;
            this.ownerUserInfo = dealSheetData.opportunity.ownerUserInfo;
            this.hasReviewPro = dealSheetData.hasReviewPro;
            this.hasGeoAlert = dealSheetData.hasGeoAlert;
            this.fullName = dealSheetData.opportunity.customer.fullName;
            this.activitiesSubscription = this.mobileDealSheetDataCachingService.getActivities(this.opportunityId,
                dealSheetData.opportunity.dealerId, dealSheetData.opportunity.customerId)
                .subscribe(activities => {
                    this.activities = activities;
                    this.stopWorkingState();
                });

            this.usersSubscription = this.mobileDealSheetDataCachingService.getUsers(dealSheetData.opportunity.dealerId).subscribe(users => this.users = users);
        });


    }

    public handleLogActivityNavigation() {
        this.stateService.go('mobileDealSheet.customer-connect');
    }

    public startWorkingState() {
        this.busy = new Promise((resolve) => {
            this.stopWorkingState = resolve;
        });
    }

    public stopWorkingState() { }

    ngOnDestroy(): void {
        this.opportunitySubscription.unsubscribe();
        this.activitiesSubscription.unsubscribe();
        this.usersSubscription.unsubscribe();
    }
}
