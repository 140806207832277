import { Component, OnInit, Input } from '@angular/core';
import { ReplacementVehicle } from '../../../../../interfaces/replacement-vehicle.interface';

@Component({
    selector: 'payment-difference',
    templateUrl: './payment-difference.component-ng.html',
    styleUrls: ['./payment-difference.component.scss']
})
export class PaymentDifferenceComponent implements OnInit {
    public paymentDifference: number;
    public Math = Math;

    @Input() vehicle: ReplacementVehicle;
    @Input() hasPaymentsPermission = false;

    ngOnInit() {
        this.paymentDifference = this.vehicle.payment - this.vehicle.existingPayment;
    }

    getPaymentClass() {
        if (!this.hasPaymentsPermission) { return 'hidden'; }

        let style = (this.paymentDifference < 0) ? 'payment-less' : 'payment-more';
        style += ' pull-right';
        return style;
    }

    getPaymentDifferenceLabel(): string {
        return this.paymentDifference < 0 ? 'less' : 'more';
    }
}
