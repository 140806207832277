<ng-template #stockNumberSearchTemplate let-model="item" let-index="index" let-query="query">
    <div class="stock-info" (click)="onSelect(model.stockNo)">
        <div class="stock-data stock-no" [innerHtml]="model.stockNo | adaptivePosition:query"></div>
        <div class="stock-data stock-full-trim">{{model.yearID}}, {{model.fullTrimName}}</div>
        <div class="stock-data stock-new-used-cpo">
            <div *ngIf="model.isNew">New</div>
            <div *ngIf="!model.isNew && !model.isCPO">Used</div>
            <div *ngIf="model.isCPO">CPO</div>
        </div>
    </div>
</ng-template>

<div *ngIf="!isSltUser" class="custom-search-input stock-number-search">
    <div [formGroup]="myForm">
        <icon class="icon aa-icon-core-search-icon-000000"></icon>
        <input class="input-sm search-query form-control" 
            formControlName="inventoryStockMinView"
            [typeahead]="inventoryStockMinViews" 
            [typeaheadOptionsLimit]="10" 
            [typeaheadMinLength]="2"
            (typeaheadOnSelect)="onSelect($event)"
            typeaheadOptionField="stockNo" 
            [typeaheadItemTemplate]="stockNumberSearchTemplate"
            placeholder="{{'stockNo' | translate}}">
    </div>
</div>