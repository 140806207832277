import { InjectionToken, FactoryProvider, PLATFORM_ID, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export const WINDOW_REF = new InjectionToken('WindowRefToken');

export function browserWindowFactory(platformId: Object) {
    if (isPlatformBrowser(platformId)) {
        return window;
    }

    return {};
}

export const windowRefProvider: FactoryProvider = {
    provide: WINDOW_REF,
    useFactory: browserWindowFactory,
    deps: [PLATFORM_ID]
};

// This wrapper is needed because Angular compile fails when trying to use the Window class as a type
// Window is an interface and Angular doesn't like trying to inject that (only affects prod mode compile)
@Injectable()
export class WindowWrapper extends Window {

}
