"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var locale_number_pipe_1 = require("../../../../filters/locale-number.pipe");
var AllowedMileageComponent = /** @class */ (function () {
    function AllowedMileageComponent(elementRef, injector, localeNumber) {
        this.localeNumber = localeNumber;
    }
    return AllowedMileageComponent;
}());
exports.AllowedMileageComponent = AllowedMileageComponent;
