AlertScriptTypeSelectController.$inject = ["_", "alertTypes", "alertScriptTypes", "saleTypes", "conquestTypes"];

/* injects from baggage-loader */
require('./alert-script-type-select.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('alertScriptTypeSelect', {
  templateUrl: '/app/components/deal-sheet/alert-script-type-select.component.html',
  controller: AlertScriptTypeSelectController,
  bindings: {
    alerts: '<',
    contract: '<',
    permissions: '<',
    isCrossMake: '<',
    showPrivateOfferScript: '<',
    showPrivateOfferPreApprovalScript: '<',
    onSelect: '&'
  }
});
/* @ngInject */

function AlertScriptTypeSelectController(_, alertTypes, alertScriptTypes, saleTypes, conquestTypes) {
  var $ctrl = this;
  $ctrl.selectedAlertScriptType;
  $ctrl.alertTypeKeys;
  $ctrl.saleTypes = saleTypes;
  $ctrl.conquestTypes = conquestTypes;
  $ctrl.alertTypes = alertTypes;
  $ctrl.alertScriptTypes = alertScriptTypes;
  $ctrl.changeAlertType = changeAlertType;
  $ctrl.changeScriptType = changeScriptType;
  $ctrl.isConquestScript = isConquestScript;
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;

  function init() {
    $ctrl.alertTypeKeys = _.invert(alertTypes);
  }

  function onChanges(changes) {
    if ((changes.alerts && changes.alerts.currentValue || changes.contract && changes.contract.currentValue || changes.permissions && changes.permissions.currentValue) && $ctrl.alerts && $ctrl.contract && $ctrl.permissions) {
      if ($ctrl.showPrivateOfferScript) {
        setSelectedAlertScriptType($ctrl.alertScriptTypes.privateOffer);
      } else if ($ctrl.showPrivateOfferPreApprovalScript) {
        setSelectedAlertScriptType($ctrl.alertScriptTypes.privateOfferWithPreApproval);
      } else {
        for (var i = 0; i < $ctrl.alerts.length; i++) {
          var alert = $ctrl.alerts[i];

          if (alert != null) {
            changeAlertType(alert.type);
            break;
          }
        }

        if (!$ctrl.selectedAlertScriptType) {
          if (isConquestScript()) {
            changeScriptType(alertScriptTypes.conquest);
          } else if ($ctrl.contract.saleType == saleTypes.cash) {
            changeScriptType(alertScriptTypes.cash);
          } else {
            changeScriptType(alertScriptTypes.phone);
          }
        }
      }
    }
  }

  function changeScriptType(scriptType) {
    $ctrl.selectedAlertType = null;
    setSelectedAlertScriptType(scriptType);
  }

  function changeAlertType(alertType) {
    $ctrl.selectedAlertType = alertType;

    switch (alertType) {
      case alertTypes.alert:
      case alertTypes.flexAlert:
        if ($ctrl.isCrossMake) {
          setSelectedAlertScriptType($ctrl.alertScriptTypes.alertCrossMakeUsed);
          break;
        }

        setSelectedAlertScriptType($ctrl.alertScriptTypes.alert);
        break;

      case alertTypes.appointment:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.appointment);
        break;

      case alertTypes.inMarket:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.inMarket);
        break;

      case alertTypes.inMarketEngaged:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.inMarketEngaged);
        break;

      case alertTypes.contractEnd:
        if ($ctrl.contract.saleType == saleTypes.lease) {
          setSelectedAlertScriptType($ctrl.alertScriptTypes.leaseEnd);
          break;
        }

        setSelectedAlertScriptType($ctrl.alertScriptTypes.contractEnd);
        break;

      case alertTypes.mileage:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.mileage);
        break;

      case alertTypes.service:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.service);
        break;

      case alertTypes.warranty:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.warranty);
        break;

      default:
        setSelectedAlertScriptType($ctrl.alertScriptTypes.phone);
    }
  }

  function setSelectedAlertScriptType(alertScriptType) {
    $ctrl.selectedAlertScriptType = alertScriptType;
    $ctrl.onSelect({
      alertScriptType: $ctrl.selectedAlertScriptType
    });
  }

  function isConquestScript() {
    if ($ctrl.contract && $ctrl.permissions) {
      return $ctrl.contract.conquestType == conquestTypes.notConverted && $ctrl.permissions.editConquestContract || $ctrl.contract.conquestType == conquestTypes.creditConvert && $ctrl.permissions.editConquestContract && (!$ctrl.contract.term || !$ctrl.contract.paymentMinimum || !$ctrl.contract.paymentMaximum);
    }
  }
}