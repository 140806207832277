DiscrepancyInputController.$inject = ["_", "discrepancyTypes", "dealerCountries"];

/* injects from baggage-loader */
require('./discrepancy-input.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('discrepancyInput', {
  templateUrl: '/app/components/deal-sheet/discrepancy-input.component.html',
  controller: DiscrepancyInputController,
  bindings: {
    countryId: '<',
    options: '<',
    onInput: '&'
  }
});
/* @ngInject */

function DiscrepancyInputController(_, discrepancyTypes, dealerCountries) {
  var $ctrl = this;
  $ctrl.discrepancyInputForm;
  $ctrl.selectedOption;
  $ctrl.expectedValue;
  $ctrl.discrepancyTypes;
  $ctrl.discrepancyTypeTranslateKeys;
  $ctrl.dealerCountries;
  $ctrl.onDiscrepancyInput = onDiscrepancyInput;
  $ctrl.$onInit = init;

  function init() {
    $ctrl.dealerCountries = dealerCountries;
    $ctrl.discrepancyTypes = discrepancyTypes;
    $ctrl.discrepancyTypeTranslateKeys = _.invert(discrepancyTypes);
  }

  function onDiscrepancyInput() {
    var discrepancy = {
      type: $ctrl.selectedOption ? $ctrl.selectedOption.type : null,
      multiple: $ctrl.selectedOption ? $ctrl.selectedOption.multiple : false,
      currentValue: $ctrl.selectedOption ? $ctrl.selectedOption.value : null,
      expectedValue: $ctrl.expectedValue
    };
    $ctrl.onInput({
      discrepancy: discrepancy,
      valid: $ctrl.discrepancyInputForm.$valid
    });
  }
}