"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PandoFormControlType;
(function (PandoFormControlType) {
    PandoFormControlType[PandoFormControlType["SectionHeader"] = 0] = "SectionHeader";
    PandoFormControlType[PandoFormControlType["Boolean"] = 1] = "Boolean";
    PandoFormControlType[PandoFormControlType["TextBox"] = 2] = "TextBox";
    PandoFormControlType[PandoFormControlType["TextArea"] = 3] = "TextArea";
    PandoFormControlType[PandoFormControlType["DropDownList"] = 4] = "DropDownList";
    PandoFormControlType[PandoFormControlType["MultiSelectList"] = 5] = "MultiSelectList";
    PandoFormControlType[PandoFormControlType["Date"] = 6] = "Date";
    PandoFormControlType[PandoFormControlType["Time"] = 7] = "Time";
    PandoFormControlType[PandoFormControlType["SingleSelectRadio"] = 8] = "SingleSelectRadio";
    PandoFormControlType[PandoFormControlType["LinearScale"] = 9] = "LinearScale";
    PandoFormControlType[PandoFormControlType["ShortName"] = 10] = "ShortName";
    PandoFormControlType[PandoFormControlType["FullName"] = 11] = "FullName";
    PandoFormControlType[PandoFormControlType["Address"] = 12] = "Address";
    PandoFormControlType[PandoFormControlType["Currency"] = 21] = "Currency";
    PandoFormControlType[PandoFormControlType["Password"] = 22] = "Password";
    PandoFormControlType[PandoFormControlType["Email"] = 23] = "Email";
    PandoFormControlType[PandoFormControlType["Phone"] = 24] = "Phone";
})(PandoFormControlType = exports.PandoFormControlType || (exports.PandoFormControlType = {}));
