import angular from 'angular';

angular.module('AaApp.Opp').constant('cultures', {
    enUS: 'en-US',
    enGB: 'en-GB',
    enCA: 'en-CA',
    frCA: 'fr-CA',
    frFR: 'fr-FR',
    esUS: 'es-US'
});
