
/* injects from baggage-loader */
require('./third-party-report.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('thirdPartyReport', {
    templateUrl: '/app/components/third-party-report.component.html',
    controller: ThirdPartyReportController,
    bindings: {
        reportType: '<',
        opportunityId: '<',
        vin: '<'
    }
});

/* @ngInject */
function ThirdPartyReportController(vehicleService, $window, $state) {
    var $ctrl = this;

    $ctrl.$onInit = function () {
        switch ($ctrl.reportType) {
            case 'autoCheck':
                displayAutoCheckReport();
                break;
            case 'carFax':
                displayCarFaxReport();
                break;
            case 'carProof':
                displayCarProofReport();
                    break;
        }
    }

    function displayAutoCheckReport() {
        vehicleService.getAutoCheckReport($ctrl.opportunityId, $ctrl.vin)
            .then(displayReport)
            .catch(function () {
                $state.go('home')
            });
    }

    function displayCarFaxReport() {
        vehicleService.getCarFaxReport($ctrl.opportunityId, $ctrl.vin)
            .then(displayReport)
            .catch(function () {
                $state.go('home')
            });
    }

    function displayCarProofReport() {
        vehicleService.getCarProofReport($ctrl.opportunityId, $ctrl.vin)
            .then(redirectToReport)
            .catch(function () {
                $state.go('home')
            });
    }

    function redirectToReport(uri) {
        $window.location.replace(uri);
    }

    function displayReport(report) {
        var iframe = angular.element('#third-party-report-iframe');
        var iframedoc = null;

        if (iframe[0].contentDocument)
            iframedoc = iframe[0].contentDocument;
        else if (iframe.contentWindow)
            iframedoc = iframe[0].contentWindow.document;

        if (iframedoc) {
            iframedoc.open();
            iframedoc.write(report);
            iframedoc.close();
        }
    }
}

