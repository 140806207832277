"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CustomerConnectDetailComponent = /** @class */ (function () {
    function CustomerConnectDetailComponent() {
        this.onLogScheduleSubmit = new core_1.EventEmitter();
    }
    CustomerConnectDetailComponent.prototype.ngOnInit = function () {
    };
    CustomerConnectDetailComponent.prototype.ngOnChanges = function (changes) {
        this.onActionSelect(this.action);
    };
    CustomerConnectDetailComponent.prototype.onActionSelect = function (actionSelected) {
        this.subAction = actionSelected;
    };
    return CustomerConnectDetailComponent;
}());
exports.CustomerConnectDetailComponent = CustomerConnectDetailComponent;
