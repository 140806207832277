import "core-js/modules/es6.array.find";
AutoAssistantController.$inject = ["toaster", "messageTypes", "$translate", "successKey", "$uibModal", "$document", "autoAssistantService"];

/* injects from baggage-loader */
require('./auto-assistant.component.html');

import angular from 'angular';
import { ActivityTypes as activityTypes } from '../../constants';
angular.module('AaApp.Opp').component('autoAssistant', {
  templateUrl: '/app/components/deal-sheet/auto-assistant.component.html',
  controller: AutoAssistantController,
  bindings: {
    opportunityId: '<',
    dealerId: '<'
  }
});
/* @ngInject */

function AutoAssistantController(toaster, messageTypes, $translate, successKey, $uibModal, $document, autoAssistantService) {
  var $ctrl = this;
  $ctrl.activityTypes = activityTypes;
  $ctrl.$onChanges = onChanges;
  $ctrl.endAutoAssistCommunication = endAutoAssistCommunication;
  $ctrl.getIsAutoAssistantConversationOpenVariable = getIsAutoAssistantConversationOpenVariable;
  $ctrl.getAutoAssistantConversation = getAutoAssistantConversation;
  $ctrl.isAutoAssistantConversationOpen = false;
  $ctrl.isLoaded = false;
  $ctrl.conversationTranslateButtonKey = "viewFullConversation";
  $ctrl.conversationTranslatePopoverKey = "";
  $ctrl.hasConversation = false;

  function onChanges() {
    if ($ctrl.opportunityId && !$ctrl.isLoaded) {
      $ctrl.isLoaded = true;
      autoAssistantService.getConversicaConversationStatuses($ctrl.dealerId, $ctrl.opportunityId).then(function (data) {
        if (data && data.length > 0) {
          var conversicaStatus = data.find(function (status) {
            return status.entityId == $ctrl.opportunityId;
          });

          if (conversicaStatus) {
            if (conversicaStatus.hasConversation) {
              $ctrl.hasConversation = true;
              $ctrl.conversationTranslateButtonKey = "viewPastConversations";

              if (conversicaStatus.isActive) {
                $ctrl.conversationTranslateButtonKey = "viewCurrentConversation";
                $ctrl.conversationTranslatePopoverKey = "viewFullConversationPopoverText";
                $ctrl.isAutoAssistantConversationOpen = true;
              }
            }
          }
        }
      });
    }
  }

  function endAutoAssistCommunication() {
    if ($ctrl.isAutoAssistantConversationOpen) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "endCommunicationModalTitle";
          },
          message: function message() {
            return "endCommunicationModalMessage";
          },
          showSubmitButton: function showSubmitButton() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "endCommunication";
          },
          headerTextClass: function headerTextClass() {
            return 'modal-header-text end-auto-assist-modal-title';
          }
        }
      }); //"Yes" clicked.

      modal.result.then(function () {
        endCommunicationAction();
      });
    }
  }

  function getIsAutoAssistantConversationOpenVariable() {
    return $ctrl.isAutoAssistantConversationOpen;
  }

  function endCommunicationAction() {
    autoAssistantService.endCommunication($ctrl.opportunityId).then(function () {
      $ctrl.isAutoAssistantConversationOpen = false;
      $translate([successKey, 'changesSaved']).then(function (translations) {
        toaster.pop({
          type: messageTypes.success,
          title: translations[successKey],
          body: translations['changesSaved']
        });
      });
    });
  }

  function getAutoAssistantConversation() {
    return autoAssistantService.getAutoAssistFullView($ctrl.dealerId, $ctrl.opportunityId);
  }
}