import { PipeTransform, Pipe } from '@angular/core';
import { StorageService } from '../modules/storage/storage.service';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'localeCurrency' })
export class LocaleCurrencyPipe implements PipeTransform {

    constructor(private storageService: StorageService) { }

    transform(amount: number, showFractionDigits: boolean = false, showSymbol: boolean = true) {
        const precision = showFractionDigits ? 2 : 0;
        amount = this.roundWithPrecision(amount, precision);

        const savedCultureName = this.storageService.getItem('cultureName');
        const cultureName = savedCultureName ? savedCultureName : 'en-us';
        const decimalPipe = new DecimalPipe(cultureName);
        const amountIsNegative = (amount < 0);
        const nbsp = '\u{A0}';

        const absoluteAmount = Math.abs(amount);

        let formattedAmount: string;

        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                formattedAmount = '£' + decimalPipe.transform(absoluteAmount, `0.${precision}-${precision}`);
                break;
            case 'en-ca':
                formattedAmount = (showSymbol ? '$' : '') + decimalPipe.transform(absoluteAmount, `0.${precision}-${precision}`);
                break;
            case 'fr-ca':
                formattedAmount = decimalPipe.transform(absoluteAmount,
                    `0.${precision}-${precision}`) + (showSymbol ? nbsp + '$' : '');
                break;
            case 'fr-fr':
                formattedAmount = decimalPipe.transform(absoluteAmount,
                    `0.${precision}-${precision}`) + (showSymbol ? nbsp + '€' : '');
                break;
            case 'en-us':
            default:
                formattedAmount = '$' + decimalPipe.transform(absoluteAmount, `0.${precision}-${precision}`);
                break;
        }

        if (amountIsNegative) {
            switch (cultureName.toLowerCase()) {
                case 'fr-fr':
                    formattedAmount = `-${nbsp}${formattedAmount}`;
                    break;
                case 'en-gb':
                case 'en-ca':
                case 'fr-ca':
                default:
                    formattedAmount = `(${formattedAmount})`;
                    break;
            }
        }

        return formattedAmount;
    }

    private roundWithPrecision(x: number, precision: number) {
        if (precision === 0) {
            return Math.round(x);
        }

        const factor = Math.pow(10, precision);
        return Math.round(x * factor) / factor;
    }
}
