
/* injects from baggage-loader */
require('./culture-select.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('cultureSelect', {
    templateUrl: '/app/components/deal-sheet/culture-select.component.html',
    controller: CultureSelectController,
    bindings: {
        countryId: '<',
        onSelect: '&'
    }
});

/* @ngInject */
function CultureSelectController($translate, $timeout, userService, dealerCountries, cultures) {
    var $ctrl = this;

    $ctrl.selectedCulture;
    $ctrl.cultures = cultures;
    $ctrl.dealerCountries = dealerCountries;
    $ctrl.changeCulture = changeCulture;
    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;

    function init() {
        $ctrl.selectedCulture = userService.getCultureNameSync();
    }

    function onChanges(changes) {
        if (changes.countryId && changes.countryId.currentValue) {
            if ($ctrl.countryId == $ctrl.dealerCountries.canada
                && ($ctrl.selectedCulture != $ctrl.cultures.enCA && $ctrl.selectedCulture != $ctrl.cultures.frCA)) {
                $ctrl.selectedCulture = $ctrl.cultures.enCA;
            }
        }
    }

    function changeCulture(culture) {
        if ($translate.getTranslationTable(culture)) {
            $ctrl.selectedCulture = culture;
            $ctrl.onSelect({ culture: $ctrl.selectedCulture });
        }
        else {
            $ctrl.selectedCulture = culture;

            $timeout(function () {
                $ctrl.selectedCulture = null;
            }, 100);

            $timeout(function () {
                $ctrl.selectedCulture = culture;
                $ctrl.onSelect({ culture: $ctrl.selectedCulture });
            }, 100);
        }
    }
}
