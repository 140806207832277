import { Component, Input } from '@angular/core';

@Component({
    selector: 'pando-unread-messages',
    templateUrl: './pando-unread-messages.component-ng.html',
})
export class PandoUnreadMessagesComponent {
    @Input() isAutoAlertUser: boolean;
    @Input() unreadMessageCount: number;
    @Input() communicationUrl: string;

    private isUnreadMessageCountHover = false;
}
