"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var platform_browser_1 = require("@angular/platform-browser");
var srcDoc = __importStar(require("srcdoc-polyfill"));
var DisplayModalComponent = /** @class */ (function () {
    function DisplayModalComponent(_bsModalRef, sanitizer) {
        this._bsModalRef = _bsModalRef;
        this.sanitizer = sanitizer;
        this.onClosed = new core_1.EventEmitter();
    }
    DisplayModalComponent.prototype.ngOnInit = function () {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
        // TO Support Edge, IE as they do not support iframe/srcDoc following polyfill was used
        srcDoc.set(this.iframe.nativeElement, this.safeHtml);
    };
    DisplayModalComponent.prototype.onCancel = function () {
        this.onClosed.emit({ confirmed: false });
        this._bsModalRef.hide();
    };
    return DisplayModalComponent;
}());
exports.DisplayModalComponent = DisplayModalComponent;
