"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var mobile_deal_sheet_data_caching_service_1 = require("../../../../services/mobile-deal-sheet-data-caching.service");
var CustomerInfoComponent = /** @class */ (function () {
    function CustomerInfoComponent(mobileDealSheetDataCachingService, transition, changeDetectorRef) {
        this.mobileDealSheetDataCachingService = mobileDealSheetDataCachingService;
        this.transition = transition;
        this.changeDetectorRef = changeDetectorRef;
        this.opportunityId = this.transition.params().opportunityId;
    }
    CustomerInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(function (dealSheetData) {
            _this.opportunity = dealSheetData.opportunity;
            _this.permissions = dealSheetData.permissions;
            _this.changeDetectorRef.detectChanges();
        });
        this.busy = this.opportunitySubscription;
    };
    CustomerInfoComponent.prototype.ngOnDestroy = function () {
        this.opportunitySubscription.unsubscribe();
    };
    return CustomerInfoComponent;
}());
exports.CustomerInfoComponent = CustomerInfoComponent;
