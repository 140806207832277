<div class="row">
    <div class="col-md-11">
        <div class="settingTitle" translate="dsAdmin_defaultVehicleMapping"></div>
        <tooltip [titleKey]="'dsAdmin_defaultVehicleMapping'" [contentKey]="'dsAdmin_defaultVehicleMappingTooltip'"></tooltip>
        <div class="settingItem">
            <div style="float:left;margin-top:5px" translate="make"></div>
            <div style="float:left;margin-left:5px">
                <select class="form-control" [(ngModel)]="selectedMakeId" style="width:200px" (change)="updateModelsTrims()">
                    <option *ngFor="let item of dsa.dealerMake" value="{{ item.makeID }}">{{ item.makeName }}</option>
                </select>
            </div>
            <div style="float:left;margin:5px 0 0 10px" translate="year"></div>
            <div style="float:left;margin-left:5px">
                <select class="form-control" [(ngModel)]="selectedYear" (change)="updateModelsTrims()">
                    <option *ngFor="let item of dsa.dealerMakeYears" value="{{ item }}">{{ item }}</option>
                </select>
            </div>
            <div style="float:left;margin:5px 0 0 10px" translate="model"></div>
            <div style="float:left;margin-left:5px">
                <select class="form-control" [(ngModel)]="selectedModelId" (change)="updateTrims()">
                    <option *ngFor="let item of models" value="{{ item.modelID }}">{{ item.modelName }}</option>
                </select>
            </div>
            <div style="float:left;margin:5px 0 0 10px" translate="trim">Trim</div>
            <div style="float:left;margin-left:5px">
                <select class="form-control" [(ngModel)]="dsa.dealerSetting.defaultTrimID">
                    <option *ngFor="let item of trims" value="{{ item.trimID }}">{{ item.trimName }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-md-11">
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_tradeInSettings"></div>
        <tooltip [titleKey]="'dsAdmin_tradeInSettings'" [contentKey]="'dsAdmin_tradeInSettingsTooltip'"></tooltip>
        <div class="settingItem below">
            <div style="float:left">
                {{ 'source' | translate }}: {{ GetTradeInSource() | translate }}
            </div>
            <div style="float:left;font-size:12px;margin:2px 0 0 20px" translate="dsAdmin_tradeInValueNote">
            </div>
        </div>
        <div class="settingItem below">
            <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.useTradeMileageAdjustment" [disabled]="dsa.dealerSetting.extTradeValueSourceID > 0" /> {{ 'dsAdmin_adjustTradeValueByMileage' | translate }}</label>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_subprimeCreditSettings"></div>
        <tooltip [titleKey]="'dsAdmin_subprimeCreditSettings'" [contentKey]="'dsAdmin_subprimeCreditSettingsTooltip'"></tooltip>
        <div class="settingItem">
            <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.enableCustomAPRForSubprime" /> {{ 'dsAdmin_enableCustomAPR' | translate }}</label>
        </div>
        <div class="settingItem" style="margin-left:10px">
            {{ 'dsAdmin_ifExistingVehicleAPRAbove' | translate }}
            <input name="DealerSetting.CustomAPRForSubprimeMax" type="number" class="input-sm textboxSm" [disabled]="!dsa.dealerSetting.enableCustomAPRForSubprime" [ngModel]="dsa.dealerSetting.customAPRForSubprimeMax" (change)="dsa.dealerSetting.customAPRForSubprimeMax = $event.target.value" /> %, {{ 'dsAdmin_setAPRTo' | translate }}
            <input name="DealerSetting.CustomAPRForSubprimeValue" type="number" class="input-sm textboxSm" [disabled]="!dsa.dealerSetting.enableCustomAPRForSubprime" [ngModel]="dsa.dealerSetting.customAPRForSubprimeValue" (change)="dsa.dealerSetting.customAPRForSubprimeValue = $event.target.value" /> %
        </div>
        <div class="settingItem" style="margin-left:10px">
            <label><input type="checkbox" [disabled]="!dsa.dealerSetting.enableCustomAPRForSubprime" [(ngModel)]="dsa.dealerSetting.customAPRForSubprimeCaptiveBankRebates" /> {{ 'dsAdmin_applyCaptiveBankRebates' | translate }}</label>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_conversionTolerance"></div>
        <tooltip [titleKey]="'dsAdmin_conversionTolerance'" [contentKey]="'dsAdmin_conversionToleranceTooltip'"></tooltip>
        <div class="settingItem below">
            {{ 'dsAdmin_retailToLeaseConversionTolerance' | translate }}
            <input name="DealerSetting.ConversionAlertTolerence" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.conversionAlertTolerence" /> %
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_usersManagement"></div>
        <tooltip class="userManagement" [titleKey]="'dsAdmin_usersManagement'" [contentKey]="'dsAdmin_usersManagementTooltip'"></tooltip>
        <div class="settingItem below">
            <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.enforcePasswordSecurity" /> {{ 'dsAdmin_enforcePasswordPolicy' | translate }}</label>
        </div>
        <div class="sectionSpacer"></div>
        <div *ngIf="dsa.isSelfRegistrationAccess">
            <div class="settingTitle" translate="dsAdmin_selfRegistration"></div>
            <tooltip [titleKey]="'dsAdmin_selfRegistration'" [contentKey]="'dsAdmin_selfRegistrationTooltip'"></tooltip>
            <div class="settingItem below">
                {{ 'dsAdmin_selfRegistrationLink' | translate }} <a href="{{ selfRegistrationLink }}" target="_blank">{{ selfRegistrationLink }}</a>
            </div>
        </div>
        <div *ngIf="dsa.extDealerVendor.length > 0">
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_vendorDataSettings"></div>
            <tooltip [titleKey]="'dsAdmin_vendorDataSettings'" [contentKey]="'dsAdmin_vendorDataSettingsTooltip'"></tooltip>
            <div class="settingItem below">
                <div class="row settingItem">
                    <div class="col-md-2 settingHeader" translate="vendor">
                    </div>
                    <div class="col-md-4 settingHeader" translate="dsAdmin_includeActivityOnDealsheet">
                    </div>
                </div>
                <div *ngFor="let item of dsa.extDealerVendor" class="row settingItem">
                    <div class="col-md-2">
                        {{ item.vendorName }}
                    </div>
                    <div class="col-md-4">
                        <input type="checkbox" [(ngModel)]="item.includeActivityOnDealSheet" />
                    </div>
                </div>
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_includeCRMActivityInActivitySearch"></div>
        <tooltip [titleKey]="'dsAdmin_includeCRMActivityInActivitySearch'" [contentKey]="'dsAdmin_includeCRMActivityInActivitySearchTooltip'"></tooltip>
        <div class="settingItem below">
            <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.includeCRMActivityInSearch" /> {{ 'dsAdmin_includeCRMActivityInSearch' | translate }}</label>
        </div>
    </div>
</div>

