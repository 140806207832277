"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var constants_1 = require("../../../../constants");
var ngx_modal_wrapper_service_1 = require("../../../../services/ngx-modal-wrapper.service");
var payoff_input_modal_component_1 = require("../payoff-input-modal/payoff-input-modal.component");
var PayoffComponent = /** @class */ (function () {
    function PayoffComponent(modalService, tService) {
        this.modalService = modalService;
        this.tService = tService;
        this.onPayoffChange = new core_1.EventEmitter();
        this.saleTypes = constants_1.SaleTypes;
    }
    PayoffComponent.prototype.ngOnInit = function () {
    };
    PayoffComponent.prototype.inputPayoffModal = function () {
        var _this = this;
        var initialState = {
            minPayoff: this.residualAmount,
        };
        this.bsModalRef = this.modalService.show(payoff_input_modal_component_1.PayoffInputModalComponent, { initialState: initialState });
        this.bsModalRef.content.onSaveChanges.subscribe(function (result) {
            _this.onPayoffChange.emit(result);
        });
    };
    return PayoffComponent;
}());
exports.PayoffComponent = PayoffComponent;
