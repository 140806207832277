import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../modules/storage/storage.service';

@Pipe({
    name: 'localePhone'
})
export class LocalePhonePipe implements PipeTransform {

    constructor(private storageService: StorageService) { }

    transform(localePhone: any): string {
        if (!localePhone) {
            return localePhone;
        }

        const savedCultureName = this.storageService.getItem('cultureName');
        let cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        if (cultureName === 'fr-fr') {
            return this.formatEurope(localePhone);
        }

        if (cultureName === 'en-gb') {
            return;
        }

        localePhone = localePhone + '';
        let numbers = localePhone.replace(/\D/g, '');

        // If the phone number is exactly 10 digits, assume it should be in NANP format.
        if (numbers.length === 10) {
            const char = { 0: '(', 3: ') ', 6: '-' };
            numbers = numbers.slice(0, 10);
            let formattedNumber = '';

            for (let i = 0; i < numbers.length; i++) {
                formattedNumber += (char[i] || '') + numbers[i];
            }
            return formattedNumber;
        } else {
            // If we can't recognize the format, return the value unformatted.
            return localePhone;
        }
    }

    private formatEurope(phoneNumber: string): string {
        let formatted = '';
        // if phone number has an odd length, the leading digit should stand alone.
        if (phoneNumber.length % 2 === 1) {
            formatted += '0' + phoneNumber[0] + ' ';
            phoneNumber = phoneNumber.substring(1);
        }
        for (let i = 0; i < phoneNumber.length; i++) {
            if (i % 2 === 0 && i !== 0) {
                formatted += ' ';
            }
            formatted += phoneNumber[i];
        }
        return formatted;
    }

}
