/* tslint:disable */
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AlertminerApiConfiguration } from './alertminer-api-configuration';

import { ActivityClientService } from './services/activity-client.service';
import { ActivityHistoryClientService } from './services/activity-history-client.service';
import { AnnouncementClientService } from './services/announcement-client.service';
import { CacheManagementClientService } from './services/cache-management-client.service';
import { ChangeLogClientService } from './services/change-log-client.service';
import { CreditSoftPullResultEntityDelClientService } from './services/credit-soft-pull-result-entity-del-client.service';
import { CustomerClientService } from './services/customer-client.service';
import { CustomerActivityHistoryClientService } from './services/customer-activity-history-client.service';
import { CustomerContactHistoryClientService } from './services/customer-contact-history-client.service';
import { CustomerWorkflowClientService } from './services/customer-workflow-client.service';
import { DealerClientService } from './services/dealer-client.service';
import { DealerAssetClientService } from './services/dealer-asset-client.service';
import { DealerPriceClientService } from './services/dealer-price-client.service';
import { DealerRatesClientService } from './services/dealer-rates-client.service';
import { DealerRebateClientService } from './services/dealer-rebate-client.service';
import { DealerSearchClientService } from './services/dealer-search-client.service';
import { DealerSettingClientService } from './services/dealer-setting-client.service';
import { DealerSmtpSettingClientService } from './services/dealer-smtp-setting-client.service';
import { DealerTradeinClientService } from './services/dealer-tradein-client.service';
import { DealerUpgradeProposalSettingClientService } from './services/dealer-upgrade-proposal-setting-client.service';
import { DealSheetClientService } from './services/deal-sheet-client.service';
import { DeskitClientService } from './services/deskit-client.service';
import { DiscrepancyReportClientService } from './services/discrepancy-report-client.service';
import { EmailConversationClientService } from './services/email-conversation-client.service';
import { EntityClientService } from './services/entity-client.service';
import { EntityOpportunityClientService } from './services/entity-opportunity-client.service';
import { EulaClientService } from './services/eula-client.service';
import { EulaHistoryClientService } from './services/eula-history-client.service';
import { ExtCustomerActivityClientService } from './services/ext-customer-activity-client.service';
import { FordCreditApprovalClientService } from './services/ford-credit-approval-client.service';
import { InventoryClientService } from './services/inventory-client.service';
import { LogClientService } from './services/log-client.service';
import { MakeClientService } from './services/make-client.service';
import { MessagingClientService } from './services/messaging-client.service';
import { ModelClientService } from './services/model-client.service';
import { PandoClaimClientService } from './services/pando-claim-client.service';
import { PandoPassthroughClientService } from './services/pando-passthrough-client.service';
import { PrintOfferBatchClientService } from './services/print-offer-batch-client.service';
import { ReplacementClientService } from './services/replacement-client.service';
import { RoleClientService } from './services/role-client.service';
import { SaleClientService } from './services/sale-client.service';
import { SearchClientService } from './services/search-client.service';
import { TagClientService } from './services/tag-client.service';
import { TextMessageClientService } from './services/text-message-client.service';
import { TrimClientService } from './services/trim-client.service';
import { TrimAlternateOfferClientService } from './services/trim-alternate-offer-client.service';
import { UserClientService } from './services/user-client.service';
import { UserModuleAccessClientService } from './services/user-module-access-client.service';
import { UserProfileClientService } from './services/user-profile-client.service';
import { UserSearchConditionClientService } from './services/user-search-condition-client.service';
import { VehicleFinalTradeValueTempChangeClientService } from './services/vehicle-final-trade-value-temp-change-client.service';
import { VehicleUserTradeValueClientService } from './services/vehicle-user-trade-value-client.service';

/**
 * Provider for all alertminer-api services, plus AlertminerApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AlertminerApiConfiguration,
    ActivityClientService,
    ActivityHistoryClientService,
    AnnouncementClientService,
    CacheManagementClientService,
    ChangeLogClientService,
    CreditSoftPullResultEntityDelClientService,
    CustomerClientService,
    CustomerActivityHistoryClientService,
    CustomerContactHistoryClientService,
    CustomerWorkflowClientService,
    DealerClientService,
    DealerAssetClientService,
    DealerPriceClientService,
    DealerRatesClientService,
    DealerRebateClientService,
    DealerSearchClientService,
    DealerSettingClientService,
    DealerSmtpSettingClientService,
    DealerTradeinClientService,
    DealerUpgradeProposalSettingClientService,
    DealSheetClientService,
    DeskitClientService,
    DiscrepancyReportClientService,
    EmailConversationClientService,
    EntityClientService,
    EntityOpportunityClientService,
    EulaClientService,
    EulaHistoryClientService,
    ExtCustomerActivityClientService,
    FordCreditApprovalClientService,
    InventoryClientService,
    LogClientService,
    MakeClientService,
    MessagingClientService,
    ModelClientService,
    PandoClaimClientService,
    PandoPassthroughClientService,
    PrintOfferBatchClientService,
    ReplacementClientService,
    RoleClientService,
    SaleClientService,
    SearchClientService,
    TagClientService,
    TextMessageClientService,
    TrimClientService,
    TrimAlternateOfferClientService,
    UserClientService,
    UserModuleAccessClientService,
    UserProfileClientService,
    UserSearchConditionClientService,
    VehicleFinalTradeValueTempChangeClientService,
    VehicleUserTradeValueClientService
  ],
})
export class AlertminerApiModule { }
