/* global window document */
import $ from 'jquery';
window.$ = $;

import angular from 'angular';

// This creates the module
import opportunities from './opportunities.module';

import '../constants/AutoAlert.AlertMiner.ServiceContract.Enums/ActivityResultCodeEnum';
import '../constants/activity-action-name-types';
import '../constants/activity-result-descriptors';
import '../constants/activity-types';
import '../constants/alert-icon-classes';
import '../constants/alert-script-types';
import '../constants/alert.types';
import '../constants/analytics';
import '../constants/api/activity-actions';
import '../constants/api/activity-rule';
import '../constants/vci-result-type';
import '../constants/api/activity-statuses';
import '../constants/api/activity-types';
import '../constants/api/crm-activity-types';
import '../constants/credit-prescreen-statuses';
import '../constants/credit-tiers';
import '../constants/cultures';
import '../constants/date-range-types';
import '../constants/defaults';
import '../constants/donotcall-status-types';
import '../constants/entityActionAccessEnum';
import '../constants/eula-response.types';
import '../constants/euro-opportunity-grid-layout';
import '../constants/fuel-types';
import '../constants/legacy-url-mappings';
import '../constants/message-types';
import '../constants/msrp-types';
import '../constants/price-set-to-options';
import '../constants/product-types';
import '../constants/reports/reports-menu';
import '../constants/reports/report-subscription-view-types';
import '../constants/reports/report-subscription-periods';
import '../constants/reports/report-categories';
import '../constants/roles';
import '../constants/router-states';
import '../constants/sale-types';
import '../constants/sub-menu-unauthorized';
import '../constants/tag-types';
import '../constants/territories';
import '../constants/translate-paths';
import '../constants/translate.keys';
import '../constants/userProfileRoles';
import '../constants/vehicle-select-columns';
import '../constants/widget-types';
import '../constants/vehicle-actions';
import '../constants/smtp-provider-types';
import '../constants/pando-action-types';
import '../constants/customer-activity-types';
import '../constants/engagement-event-types.constant';


// import and map typescript constants to AngularJS
import {
    DealerCountries
    , Modules
    , SubMenu
    , TradeValueTypes
    , TimeZones
    , TimeZonesById
    , TimeZonesByTzdbName
    , DealSheetTabs
    , AlertTypes
    , AlertTypeDescriptors
    , AlertTypeDescriptorsEuroLite
    , TranslateErrorKeys
    , AuthorizationKeys
    , ContactTypes
    , SearchPresetTypes
    , AssetTypes
    , ActivityResultTypeDescriptors
    , ActivityStatusTypes
    , ActivityIconClasses
    , OpportunityStatuses
    , OpportunityTypes
    , DiscrepancyTypes
    , VehicleTypes
    , ConquestTypes    
    , EulaStatusTypes
    , ActivitySubTypes
    , PaymentTypes
    , SaleTypes
    , ActivityActionEnum
    , ActivityResults
    , CustomerProcessStatuses
    , ActivityResultDescriptors
    , FordFBATypes
} from '../constants';

angular.module(opportunities).constant('dealerCountries', DealerCountries)
    .constant('modules', Modules)
    .constant('subMenu', SubMenu)
    .constant('tradeValueTypes', TradeValueTypes)
    .constant('timeZones', TimeZones)
    .constant('timeZonesById', TimeZonesById)
    .constant('timeZonesByTzdbName', TimeZonesByTzdbName)
    .constant('dealSheetTabs', DealSheetTabs)
    .constant('alertTypeDescriptorsEuroLite', AlertTypeDescriptorsEuroLite)
    .constant('alertTypeDescriptors', AlertTypeDescriptors)
    .constant('alertTypes', AlertTypes)
    .constant('translateErrorKeys', TranslateErrorKeys)
    .constant('authorizationKeys', AuthorizationKeys)
    .constant('contactTypes', ContactTypes)
    .constant('searchPresetTypes', SearchPresetTypes)
    .constant('assetTypes', AssetTypes)
    .constant('activityResultTypeDescriptors', ActivityResultTypeDescriptors)
    .constant('activityStatusTypes', ActivityStatusTypes)
    .constant('activityIconClasses', ActivityIconClasses)
    .constant('opportunityStatuses', OpportunityStatuses)
    .constant('opportunityTypes', OpportunityTypes)
    .constant('discrepancyTypes', DiscrepancyTypes)
    .constant('vehicleTypes', VehicleTypes)
    .constant('conquestTypes', ConquestTypes)
    .constant('eulaStatusTypes', EulaStatusTypes)
    .constant('activitySubTypes', ActivitySubTypes)
    .constant('paymentTypes', PaymentTypes)
    .constant('saleTypes', SaleTypes)
    .constant('ActivityActionEnum', ActivityActionEnum)
    .constant('activityResults', ActivityResults)
    .constant('customerProcessStatuses', CustomerProcessStatuses)
    .constant('activityResultDescriptors', ActivityResultDescriptors)
    .constant('fordFBATypes', FordFBATypes)

import {
    QuickSearchUrl
    , DealSheetUrl
    , SearchPresetUrl
    , UserProfileUrl
    , SubMenuLocalizationPath
    , WarningTitleKey
    , QuickSearchWarningKey
    , QuickSearchInvalidCharacterWarningKey
    , PageNotFoundKey} from '../core/constants';

angular.module(opportunities).value('quickSearchUrl', QuickSearchUrl)
    .value('dealSheetUrl', DealSheetUrl)
    .value('searchPresetUrl', SearchPresetUrl)
    .value('userProfileUrl', UserProfileUrl)
    .value('subMenuLocalizationPath', SubMenuLocalizationPath)
    .value('warningTitleKey', WarningTitleKey)
    .value('quickSearchWarningKey', QuickSearchWarningKey)
    .value('quickSearchInvalidCharacterWarningKey', QuickSearchInvalidCharacterWarningKey)
    .value('pageNotFoundKey', PageNotFoundKey)

import '../constants';

import '../core/opportunities.config';
import '../core/opportunities.run';
import '../core/exception';

import '../directives/compile-template.directive';
import '../directives/currency-input.directive';
import '../directives/disable-click.directive';
import '../directives/fallback-src.directive';
import '../directives/min-date.directive';
import '../directives/max-date.directive';
import '../directives/number-input.directive';
import '../directives/percent-input.directive';
import '../directives/phone-number.directive';

import '../filters/locale-currency.filter';
import '../filters/locale-date-time-with-day.filter';
import '../filters/locale-date-time.filter';
import '../filters/locale-date.filter';
import '../filters/locale-decimal.filter';
import '../filters/locale-fuel-economy.filter';
import '../filters/locale-money-factor.filter';
import '../filters/locale-number.filter';
import '../filters/locale-phone.filter';
import '../filters/locale-rounded-currency.filter';
import '../filters/locale-time.filter';
import '../filters/trim.filter';
import '../filters/unique.filter';
import '../filters/elapsed-time.filter';

import '../scripts/constants';
import '../scripts/init';
import '../scripts/jquery-resizable.nobabel';

import '../utilities/lodash';
import '../utilities/map-utils';
import '../utilities/moment';
import '../utilities/url-helper';

import '../services/activity.service';
import '../services/contract.service';
import '../services/deal-sheet.service';
import '../services/eula-modal.service';
import '../services/external.service';
import '../services/feature-toggle.service';
import '../services/financials.service';
import '../services/global-iframe.service';
import '../services/google.service';
import '../services/legacy.service';
import '../services/opportunity-count.service';
import '../services/opportunity.service';
import '../services/pando-survey.service';
import '../services/print-offer-batch.service';
import '../services/recent-deal-sheet.service';
import '../services/report.service';
import '../services/search-preset.service';
import '../services/search.service';
import '../services/services.service';
import '../services/stock-number-search.service';
import '../services/template.service';
import '../services/vehicle.service';

// Former Header Services
import '../services/core-api';
import '../services/dmspush-api';
import '../services/customer-engagement.service';
import '../services/dealer.service';
import '../services/log-factory.service';
import '../services/selected-dealers-injector.service';

import '../components/data-entry/replacement/data-entry-replacement-offers.component';
import '../components/data-entry/replacement/edit-upgrade-downgrade-multi-items-modal.component';
import '../components/data-entry/replacement/edit-upgrade-downgrade-modal.component';
import '../components/data-entry/replacement/data-entry-replacement-popover-list.component';
import '../components/data-entry/replacement/edit-replacements-advanced-modal.component';

import '../components/data-entry/data-entry-frame.component';
import '../components/data-entry/data-entry-prices.component';
import '../components/data-entry/data-entry-trade-ins.component';
import '../components/data-entry/data-entry-rebates.component';
import '../components/data-entry/data-entry-rates.component';
import '../components/data-entry/data-entry-alerts.component';

import '../components/data-entry/data-entry-rates-rate-mark-ups.component';
import '../components/data-entry/data-entry-rates-over-allowance.component';
import '../components/data-entry/data-entry-rates-used-rates.component';


import '../components/admin/admin-report-subscriptions.component';
import '../components/dashboard/dashboard-manager.component';
import '../components/dashboard/dashboard-opportunities.component';
import '../components/dashboard/dashboard-widget.component';
import '../components/dashboard/dashboard.component';
import '../components/dashboard/opportunity-block.component';
import '../components/dashboard/tagged-customers/tagged-customers.component';
import '../components/deal-sheet/activity-actions.component';
import '../components/deal-sheet/activity.component';
import '../components/deal-sheet/activity-list.component';
import '../components/deal-sheet/auto-assistant.component';
import '../components/deal-sheet/alert-relevant-info.component';
import '../components/deal-sheet/alert-script-type-select.component';
import '../components/deal-sheet/alert-script.component';
import '../components/deal-sheet/change-log.component';
import '../components/deal-sheet/contract/balance-to-maturity.component';
import '../components/deal-sheet/contract/balloon-payment.component';
import '../components/deal-sheet/contract/bank-name.component';
import '../components/deal-sheet/contract/bank-rate.component';
import '../components/deal-sheet/contract/contract-dates.component';
import '../components/deal-sheet/contract/contract-select.component';
import '../components/deal-sheet/contract/delivery-monthly-mileage.component';
import '../components/deal-sheet/contract/feature-comparison-features.component';
import '../components/deal-sheet/contract/feature-comparison-fuel.component';
import '../components/deal-sheet/contract/lease-mileage.component';
import '../components/deal-sheet/contract/loyalty-rebate.component';
import '../components/deal-sheet/contract/monthly-mileage.component';
import '../components/deal-sheet/contract/msrp-type-select.component';
import '../components/deal-sheet/contract/personalized-offer.component';
import '../components/deal-sheet/contract/preferred-equity.component';
import '../components/deal-sheet/contract/pull-forward.component';
import '../components/deal-sheet/contract/rate-type-select.component';
import '../components/deal-sheet/contract/replacement-contracts.component';
import '../components/deal-sheet/contract/sale-type-select.component';
import '../components/deal-sheet/contract/salesperson-name.component';
import '../components/deal-sheet/contract/warranty-actions.component';
import '../components/deal-sheet/credit-inquiries.component';
import '../components/deal-sheet/credit-prescreen-spinner.component';
import '../components/deal-sheet/credit-prescreen.component';
import '../components/deal-sheet/crm-action.component';
import '../components/deal-sheet/culture-select.component';
import '../components/deal-sheet/customer-contact.component';
import '../components/deal-sheet/customer-edit-action.component';
import '../components/deal-sheet/customer-edit.component';
import '../components/deal-sheet/customer-name.component';
import '../components/deal-sheet/customer-email.component';
import '../components/deal-sheet/customer-email-list.component';
import '../components/deal-sheet/customer-phone.component';
import '../components/deal-sheet/customer-phone-list.component';
import '../components/deal-sheet/deal-sheet.component';
import '../components/deal-sheet/deal-sheet-vci.component';
import '../components/deal-sheet/deal-sheet-vci-eula-modal.component';
import '../components/deal-sheet/deal-sheet-lite.component';
import '../components/deal-sheet/deal-sheet-tab-select.component';
import '../components/deal-sheet/discrepancy-input.component';
import '../components/deal-sheet/email-template-action.component';
import '../components/deal-sheet/facebook-profile-action.component';
import '../components/deal-sheet/facebook-photo.component';
import '../components/deal-sheet/financial-edit-action.component';
import '../components/deal-sheet/financial/add-financial-info.component';
import '../components/deal-sheet/financial/financials-balloon-sale.component';
import '../components/deal-sheet/financial/financials-cash-sale.component';
import '../components/deal-sheet/financial/financials-lease-sale.component';
import '../components/deal-sheet/financial/financials-retail-sale.component';
import '../components/deal-sheet/log-call-action.component';
import '../components/deal-sheet/log-call.component';
import '../components/deal-sheet/action-checkbox.component';
import '../components/deal-sheet/mark-as-sold.component';
import '../components/deal-sheet/mark-as-on-order.component';
import '../components/deal-sheet/modals/activity-follow-up-modal.component';
import '../components/deal-sheet/modals/confirmation-modal.component';
import '../components/deal-sheet/modals/credit-pre-approval-modal.component';
import '../components/deal-sheet/modals/credit-pre-qualified-modal.component';
import '../components/deal-sheet/modals/credit-soft-pull-result-modal.component';
import '../components/deal-sheet/modals/customer-edit-modal.component';
import '../components/deal-sheet/modals/deal-sheet-modal.component';
import '../components/deal-sheet/modals/deal-sheet-lite-modal.component';
import '../components/deal-sheet/modals/discrepancy-input-modal.component';
import '../components/deal-sheet/modals/eula-modal.component';
import '../components/deal-sheet/modals/financial-info-modal.component';
import '../components/deal-sheet/modals/inventory-search-modal.component';
import '../components/deal-sheet/modals/log-call-modal.component';
import '../components/deal-sheet/modals/message-modal.component';
import '../components/deal-sheet/modals/post-notes.component';
import '../components/deal-sheet/modals/push-to-crm.component';
import '../components/deal-sheet/modals/schedule.component';
import '../components/deal-sheet/modals/select-modal.component';
import '../components/deal-sheet/modals/show-full-conversation-modal.component';
import '../components/deal-sheet/modals/service-detail-modal.component';
import '../components/deal-sheet/modals/update-mileage.component';
import '../components/deal-sheet/modals/user-select-modal.component';
import '../components/deal-sheet/modals/vehicle-select-modal.component';
import '../components/deal-sheet/modals/view-customer-conversation-modal.component';
import '../components/deal-sheet/modals/hot-trade-details-modal.component';
import '../components/deal-sheet/open-new-window.component';
import '../components/deal-sheet/opportunity-select.component';
import '../components/deal-sheet/other-vehicles-owned.component';
import '../components/deal-sheet/pricing-plan-client.component';
import '../components/deal-sheet/print-offer.component';
import '../components/deal-sheet/print-upgrade-proposal.component';
import '../components/deal-sheet/reopen.component';
import '../components/deal-sheet/report-discrepancy-action.component';
import '../components/deal-sheet/service-history.component';
import '../components/deal-sheet/user-select-action.component';
import '../components/deal-sheet/user-select.component';
import '../components/deal-sheet/vehicle/color.component';
import '../components/deal-sheet/vehicle/fuel-difference.component';
import '../components/deal-sheet/vehicle/inventory-search-action.component';
import '../components/deal-sheet/vehicle/inventory-search.component';
import '../components/deal-sheet/vehicle/mileage.component';
import '../components/deal-sheet/vehicle/recommended-vehicles-action.component';
import '../components/deal-sheet/vehicle/registration-number.component';
import '../components/deal-sheet/vehicle/stock-vehicle.component';
import '../components/deal-sheet/vehicle/trim-select.component';
import '../components/deal-sheet/vehicle/vehicle-action-select.component';
import '../components/deal-sheet/vehicle/vehicle-equity.component';
import '../components/deal-sheet/vehicle/vehicle-select.component';
import '../components/deal-sheet/vehicle/vehicle-type-select.component';
import '../components/deal-sheet/vehicle/vehicle-type.component';
import '../components/deal-sheet/vehicle/vin.component';
import '../components/deal-sheet/watch.component';
import '../components/deal-sheet/pando-process-claim-info.component';
import '../components/deal-sheet/template-email-preview.component';
import '../components/deal-sheet/auto-assigned-access.component';

import '../components/deal-sheet/email-info-edit.component';
import '../components/deal-sheet/modals/email-info-edit-modal.component';
import '../components/deal-sheet/modals/send-email-template-modal.component';
import '../components/deal-sheet/modals/view-email-conversation-modal.component';
import '../components/deal-sheet/view-email-conversation-action.component';
import '../components/deal-sheet/modals/view-customer-email-sent-modal.component';

import '../components/opportunities/opportunity-block-horizontal.component';
import '../components/opportunities/modals/euro-lite-grid-layout-selector-modal.component';

import '../components/edit-contact.component';
import '../components/error/unauthorized.component';
import '../components/header-wrapper.component';
import '../components/header/quick-search.component';
import '../components/header/sub-menu.component';

import '../components/dealer-dropdown.component';
import '../components/email-unsubscribe.component';
import '../components/html-textarea.component';
import '../components/iframe.component';
import '../components/legacy-iframe-support.component';
import '../components/login.component';
import '../components/modals/open-modal.component';
import '../components/modals/announcement-modal.component';
import '../components/modals/bulk-template-wizard-modal.component';
import '../components/modals/google-maps-modal.component';
import '../components/radio-group.component';
import '../components/reports/modals/lms-transcript-modal.component';
import '../components/reports/lms-transcript.component';
import '../components/reports/dealer-make-select.component';
import '../components/reports/pando-survey-select.component';
import '../components/reports/report-render.component';
import '../components/reports/reports-container.component';
import '../components/reports/reports-menu.component';
import '../components/reports/reports.component';
import '../components/reports/beta-reports.component';
import '../components/reports/reports-header.component';
import '../components/reports/report-subscriptions-modal.component';
import '../components/reports/report-subscriptions.component';
import '../components/reports/report-subscription-list.component';
import '../components/reports/report-subscription-edit.component';
import '../components/template-email.component';

import '../components/service-drive/filters/service-drive-offers.filter';
import '../components/service-drive/modals/service-drive-offers-filter.modal.component';
import '../components/service-drive/modals/service-drive-offers-print.modal.component';
import '../components/service-drive/service-drive-offers.component';
import '../components/third-party-report.component';
import '../components/carfax-callback.component';
import '../components/carfax-authorize.component';

import '../components/toggle-button.component';
import '../components/portal-footer.component';

import "../components/opportunities/euroliteopportunities.component";
import "../components/search/search-form.component";
import "../components/search/ui-checkboxlist.component";
import "../components/search/ui-textbox.component";
import "../components/search/ui-dropdownlist.component";
import "../components/search/ui-textbox-range.component";
import "../components/search/ui-datepicker-range.component";
import "../components/search/search-menu.component";
import "../components/search/search-container.component";

import "../components/search/search-manage-presets-container.component";
import "../components/search/search-manage-presets.component";
import '../components/search/search-preset-selector.component';
import '../components/search/search-preset-selector-modal.component';
import '../components/search/search-preset-settings.component';
import '../components/search/search-preset-settings-modal.component';
import '../components/search/search-export-modal.component';
import '../components/search/search-export-epsilon-modal.component';
import '../components/search/search-export-epsilon-result-modal.component';
import '../components/search/search-save-modal.component';
import '../components/search/search-save-layout-modal.component';
import '../components/search/search-reassign-modal.component';
import '../components/search/search-add-access-modal.component';
import '../components/search/search-direct-alert-modal.component';
import '../components/search/search-directalert.component';
import '../components/search/search-save-response-modal.component';
import '../components/wish-list/wish-list.component';
import '../components/wish-list/edit-wish-list-modal.component';
import '../components/wish-list/wish-list-trim-list.component';
import '../components/wish-list/wish-list-distr-list.component';
import '../components/wish-list/wish-list-matches-modal.component';
import '../components/possible-nlo/possible-nlo.component';

import '../core/exception-handler.provider';

// Formerly in header
import '../components/header/customer-engagement-widget.component';
import '../components/header/customer-engagement-widget-window.component';
import '../components/header/customer-engagements-by-entity-modal.component';
import '../components/header/dealer-list.component';
import '../components/header/header.component';
import '../components/header/live-support.component';
import '../components/header/main-menu.component';

import '../components/header/pando-inbox.component';
import '../components/pagination.component';
import '../components/filter-button.component';

// bootstrap the app
angular.module('AaApp', [opportunities]);
