
/* injects from baggage-loader */
require('./legacy-iframe-support.component.html');

import angular from 'angular';
import { ChildMessagePort } from '../utilities/messaging';
import { getUrlComponents } from '../utilities/url';

angular.module('AaApp.Opp').component('legacyIframeSupport', {
    templateUrl: '/app/components/legacy-iframe-support.component.html',
    controller: legacyIframeSupportController
});

/* @ngInject */
function legacyIframeSupportController($auth, $element, $sce, $state, $location, $scope, globalIframeService, legacy, userService) {
    var $ctrl = this;

    const originalUrl = legacy.iframeUrl + '/Home/MessagingFrame';
    $ctrl.iframeSource = $sce.trustAsResourceUrl(originalUrl);

    $ctrl.$onInit = $onInit;

    function $onInit() {
        // if public pages return
        if (!$auth.isAuthenticated())
            return;

        const iframe = $element.find('iframe')[0];
        const { origin } = getUrlComponents(originalUrl);
        const port = new ChildMessagePort(iframe, origin);
        globalIframeService.setCurrentMessagePort(port, $scope);

        // if logged in user verifiy the user has signed EULA.if not return to Accept/decline EULA screen. only allow 'user/profile' path
        userService.getUserProfile()
            .then(userProfile => {
                if (!userProfile.eulaAcceptedDate && $location.url().indexOf('user/profile') <= -1)
                    $state.go('login');
            });

        $ctrl.$onDestroy = () => port.close();
    }
}
