"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var rxjs_1 = require("rxjs");
var ConfirmationModalComponent = /** @class */ (function () {
    function ConfirmationModalComponent(bsModalRef) {
        this.bsModalRef = bsModalRef;
        this.closeButtonVisible = true;
        this.closeButtonText = 'cancel';
        this.submitButtonVisible = true;
        this.submitButtonDisabled = false;
        this.submitButtonText = 'yes';
        this.headerTextClass = 'modal-header-text';
        this.submitSubject = new rxjs_1.Subject();
        this.onSubmit = this.submitSubject.asObservable();
    }
    ConfirmationModalComponent.prototype.ngOnInit = function () {
    };
    ConfirmationModalComponent.prototype.submit = function () {
        this.submitSubject.next();
        this.bsModalRef.hide();
    };
    ConfirmationModalComponent.prototype.close = function () {
        this.bsModalRef.hide();
    };
    return ConfirmationModalComponent;
}());
exports.ConfirmationModalComponent = ConfirmationModalComponent;
