"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var FactoryRebateComponent = /** @class */ (function () {
    function FactoryRebateComponent() {
        this.programSourceTypes = constants_1.ProgramSourceTypes;
    }
    FactoryRebateComponent.prototype.ngOnInit = function () {
    };
    return FactoryRebateComponent;
}());
exports.FactoryRebateComponent = FactoryRebateComponent;
