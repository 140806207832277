import { Injectable, Inject } from '@angular/core';
import { WINDOW_REF, WindowWrapper } from './window-ref.service';
import { StorageService } from '../modules/storage/storage.service';
import { Settings } from '../constants';

export class NamedLog {
    constructor(private windowRef: Window, private _name?: string, private debugEnabled = false) {

    }

    get name(): string {
        return this._name;
    }

    log(...args: string[]) {
        this._logImpl('log', args);
    }

    info(...args: string[]) {
        this._logImpl('info', args);
    }

    warn(...args: string[]) {
        this._logImpl('warn', args);
    }

    error(...args: string[]) {
        this._logImpl('error', args);
    }

    debug(...args: string[]) {
        if (this.debugEnabled) {
            this._logImpl('debug', args);
        }
    }

    _logImpl(level: string, args: string[]) {
        const console = this.windowRef.console || {};
        const noop = function (dummyMessage) { };

        const logFunction = console[level] || console['log'] || noop;

        const message = this.prependName(this.name, args);

        // Support: IE only
        // console methods don't inherit from Function.prototype in IE so we can't
        // call `logFunction.apply(console, args)` directly. Stolen from AngularJs source
        return Function.prototype.apply.call(logFunction, console, message);
    }

    private prependName(name: string, args: string[]) {
        if (name) {
            return [`[${name}]`, ...args];
        } else {
            return args;
        }
    }
}

@Injectable()
export class LogFactory {
    constructor(@Inject(WINDOW_REF) private windowRef: WindowWrapper,
        storageService: StorageService) {
        const debugSetInLocalStorage = storageService.getItem('debug');
        this.debugEnabled = (debugSetInLocalStorage || Settings.environmentVariables.logDebugEnabled);
    }

    private debugEnabled = false;

    get(name?: string): NamedLog {
        return new NamedLog(this.windowRef, name, this.debugEnabled);
    }
}

