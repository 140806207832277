"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var VehicleSummaryComponent = /** @class */ (function () {
    function VehicleSummaryComponent() {
        this.vehicleTypes = constants_1.VehicleTypes;
        this.vehicleTypeKeys = Object.keys(constants_1.VehicleTypes);
        this.pricingPlanTypes = constants_1.PricingPlanTypes;
        this.pricingPlanTypeKeys = Object.keys(constants_1.PricingPlanTypes);
        this.dealerCountries = constants_1.DealerCountries;
        this.trimPricePlanSource = constants_1.TrimPricePlanSource;
        this.averageTranslateKey = "average";
    }
    //constructor() { }
    VehicleSummaryComponent.prototype.ngOnInit = function () {
    };
    VehicleSummaryComponent.prototype.ngOnChanges = function (changes) {
        var hasEmployeePricing = this.trimPlanPriceSourceId === this.trimPricePlanSource.employeePricing;
        if (hasEmployeePricing) {
            this.planTranslationKey = "planEmpPrice";
        }
        else if (this.pricingPlanId && this.pricingPlanId > 0) {
            this.planTranslationKey = this.pricingPlanId == constants_1.PricingPlanTypes.azPlan ? "azPlan" : "xPlan";
        }
        else {
            this.planTranslationKey = "";
        }
        if (this.vehicle) {
            this.averageTranslateKey = ((this.vehicle.pricingPlanMsrp && this.vehicle.pricingPlanId) || hasEmployeePricing) ?
                "avg" : "average";
        }
    };
    VehicleSummaryComponent.prototype.openOasisLink = function () {
        var oasisUrl = constants_1.Settings.environmentVariables.fordOASISUrl;
        oasisUrl = oasisUrl.replace("{0}", this.vehicle.vin);
        window.open(oasisUrl, '_blank');
    };
    return VehicleSummaryComponent;
}());
exports.VehicleSummaryComponent = VehicleSummaryComponent;
