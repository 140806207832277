
/* injects from baggage-loader */
require('./financials-lease-sale.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('financialsLeaseSale', {
    templateUrl: '/app/components/deal-sheet/financial/financials-lease-sale.component.html',
    controller: FinancialsLeaseSaleController,
    bindings: {
        financials: '<',
        financialForm: '<',
        reset: '<',
        checkValidation: '&'
    }
});

/* @ngInject */
function FinancialsLeaseSaleController($timeout,opportunityTypes) {
    var $ctrl = this;
    
    $ctrl.$onChanges = onChanges;


    function onChanges(changes) {
       
        if (changes.reset && !changes.reset.isFirstChange()) {
            resetFinancials();
        }
    }

    $ctrl.$onInit = function () {
        $ctrl.financialForm.$setPristine();
        $ctrl.checkValidation();
        SetCalculationOpton();
    };

    $ctrl.calc = function () {
        if ($ctrl.financialForm.$dirty){
            _doLeaseCalc();
            $ctrl.checkValidation();
        }
    }

    function SetCalculationOpton(){
        if ($ctrl.financials.opportunityType == opportunityTypes.conquest){
            $ctrl.calculations = 1;
            //webui-6138 rule: display APR
            _getSetLeaseRate();
        }
        else {
            $ctrl.calculations = 2;
        }
    }

    function _doLeaseCalc() {
        
        if (!$ctrl.financials.convert.leaseTotalPayment) { return; }
        _calcBasePayment();
        if (!$ctrl.financials.convert.termID) { return; }
        var termId = $ctrl.financials.convert.termID;
        var basePayment = $ctrl.financials.convert.leaseBasePayment;

        var paymentsMade = _paymentsMade(termId);

        if ($ctrl.financials.convert.leasePayoff && paymentsMade) {
            var apr = _getSetLeaseMoneyFactor();
            _calcLeaseNetCapCost(basePayment, termId, apr, paymentsMade);
            _calcLeaseResidual(basePayment, $ctrl.financials.convert.leaseNetCapCost, apr, termId);

        } else if ($ctrl.financials.convert.leaseNetCapCost && $ctrl.financials.convert.leaseResidual) {
            _moneyFactorByAmtResidulaAndTerm(basePayment, termId);
            _leaseRateByMoneyFactor();
        } else { return; }
    }

    function _calcBasePayment() {
        var payment = $ctrl.financials.convert.leaseTotalPayment;
        if ($ctrl.financials.convert.taxMonthlyPayment) {
            payment = Math.ceil(payment / (100 + $ctrl.financials.convert.leaseTaxRate) * 100);
        }
        $ctrl.financials.convert.leaseBasePayment = payment;
    }

    function _paymentsMade(termId) {

        if (!$ctrl.financials.convert.dealDate){
            return;
        }

        var date = $ctrl.financials.convert.dealDate;
        var today = new Date();
        var oneMonth = 1000 * 60 * 60 * 24 * 30.42;
        var paymentsMade = Math.ceil((today.getTime() - date.getTime()) / oneMonth);
        if (paymentsMade < 0) {
            paymentsMade = 0;
        } else if (paymentsMade > termId) {
            return NaN;
        }
        return paymentsMade;
    }

    function _getSetLeaseRate() {

        if ($ctrl.financialForm.leaseRate && $ctrl.financialForm.leaseRate.$dirty 
            && $ctrl.financials.convert.leaseRate > -1 && $ctrl.financials.opportunityType == opportunityTypes.conquest)
            return $ctrl.financials.convert.leaseRate;

        if (!$ctrl.financials.convert.leaseRate  && $ctrl.financials.opportunityType == opportunityTypes.conquest) {
            $ctrl.financials.convert.leaseRate = 6.00;
        }

        return $ctrl.financials.convert.leaseRate;
    }

    function _getSetLeaseMoneyFactor() {
        var leaseRate = _getSetLeaseRate();
        var apr = Math.ceil((leaseRate / 2400) * 10000) / 10000;
        $ctrl.financials.convert.leaseMoneyFactor = apr;
        return apr;
    }

    function _calcLeaseNetCapCost(basePayment, termId, apr, paymentsMade) {
        var leaseNetCapCost = Math.ceil((basePayment + (1 - termId * apr) * $ctrl.financials.convert.leasePayoff / paymentsMade) / (2 * apr + (1 - termId * apr) / paymentsMade));
        $ctrl.financials.convert.leaseNetCapCost = leaseNetCapCost;
    }

    function _calcLeaseResidual(basePayment, leaseNetCapCost, apr, termId) {
        var leaseResidual = Math.ceil((basePayment - leaseNetCapCost * (apr + 1 / termId)) / (apr - 1 / termId));
        $ctrl.financials.convert.leaseResidual = leaseResidual
    }

    function _moneyFactorByAmtResidulaAndTerm(basePayment, termId) {
        var leaseNetCapCost = $ctrl.financials.convert.leaseNetCapCost;
        var residual = $ctrl.financials.convert.leaseResidual;
        var moneyFactor = Math.ceil(10000 * (basePayment - (leaseNetCapCost - residual) / termId) / (leaseNetCapCost + residual)) / 10000;
        $ctrl.financials.convert.leaseMoneyFactor = moneyFactor;
    }

    function _leaseRateByMoneyFactor() {
        var apr = $ctrl.financials.convert.leaseMoneyFactor;
        var leaseRate = Math.ceil(apr * 2400 * 100) / 100;
        $ctrl.financials.convert.leaseRate = leaseRate;
    }

    function resetFinancials() {
        $ctrl.financials.convert.soldByID = null;
        $ctrl.financials.convert.selectedBank = null;
        $ctrl.financials.convert.termID = null;
        $ctrl.financials.convert.taxMonthlyPayment = true;
        $ctrl.financials.convert.leaseTotalPayment = null;
        $ctrl.financials.convert.leasePayoff = null;
        $ctrl.financials.convert.leaseRate = null;
        $ctrl.financials.convert.leaseMoneyFactor = null;
        $ctrl.financials.convert.leaseNetCapCost = null;
        $ctrl.financials.convert.leaseResidual = null;
        $ctrl.financials.convert.leaseAnnualMiles = null;
        $ctrl.financials.convert.cashDown = null;
        $ctrl.financials.convert.mileageAtDelivery = null;
        $ctrl.financials.convert.soldAsNew = true;
        $ctrl.financialForm.$setPristine();




        $ctrl.checkValidation();
    }
}
