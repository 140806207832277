import moment from 'moment-timezone';

interface TimeZone {
    /** The ID of this time zone in the AutoAlert database. */
    id: number;
    /** The Tzdb identifier for this time zone. */
    tzdbName: string;
    /** The BCL identifier for this time zone. */
    bclName: string;
    /** The offset when this zone is in standard time (in `-HHMM` format). */
    standard: string;
    /** The offset when this zone is in daylight saving time (in `-HHMM` format). */
    daylight: string;
}

/**
 * The time zones supported by the application, keyed by strings like
 * "pacificStandardTime".
 */
export const TimeZones = {
    hawaiianStandardTime: <TimeZone>{
        id: 1,
        tzdbName: 'Pacific/Honolulu',
        bclName: 'Hawaiian Standard Time',
        standard: '-1000',
        daylight: '-1000',
    },
    alaskanStandardTime: <TimeZone>{
        id: 2,
        tzdbName: 'America/Anchorage',
        bclName: 'Alaskan Standard Time',
        standard: '-0900',
        daylight: '-0800',
    },
    pacificStandardTime: <TimeZone>{
        id: 3,
        tzdbName: 'America/Los_Angeles',
        bclName: 'Pacific Standard Time',
        standard: '-0800',
        daylight: '-0700',
    },
    mountainStandardTime: <TimeZone>{
        id: 4,
        tzdbName: 'America/Denver',
        bclName: 'Mountain Standard Time',
        standard: '-0700',
        daylight: '-0600',
    },
    centralStandardTime: <TimeZone>{
        id: 5,
        tzdbName: 'America/Chicago',
        bclName: 'Central Standard Time',
        standard: '-0600',
        daylight: '-0500',
    },
    easternStandardTime: <TimeZone>{
        id: 6,
        tzdbName: 'America/New_York',
        bclName: 'Eastern Standard Time',
        standard: '-0500',
        daylight: '-0400',
    },
    atlanticStandardTime: <TimeZone>{
        id: 7,
        tzdbName: 'America/Halifax',
        bclName: 'Atlantic Standard Time',
        standard: '-0400',
        daylight: '-0300',
    },
    westPacificStandardTime: <TimeZone>{
        id: 8,
        tzdbName: 'Pacific/Port_Moresby',
        bclName: 'West Pacific Standard Time',
        standard: '+1000',
        daylight: '+1000',
    },
    utcMinus11: <TimeZone>{
        id: 9,
        tzdbName: 'Etc/GMT+11', // The positive sign is correct
        bclName: 'UTC-11',
        standard: '-1100',
        daylight: '-1100',
    },
    usMountainStandardTime: <TimeZone>{
        id: 10,
        tzdbName: 'America/Phoenix',
        bclName: 'US Mountain Standard Time',
        standard: '-0700',
        daylight: '-0700',
    },
    newfoundlandStandardTime: <TimeZone>{
        id: 11,
        tzdbName: 'America/St_Johns',
        bclName: 'Newfoundland Standard Time',
        standard: '-0330',
        daylight: '-0230',
    },
    utcPlus12: <TimeZone>{
        id: 12,
        tzdbName: 'Etc/GMT-12', // The negative sign is correct
        bclName: 'UTC+12',
        standard: '+1200',
        daylight: '+1200',
    },
    gmtStandardTime: <TimeZone>{
        id: 13,
        tzdbName: 'Europe/London',
        bclName: 'GMT Standard Time',
        standard: '+0000',
        daylight: '+0000',
    },
    romanceStandardTime: <TimeZone>{
        id: 15,
        tzdbName: 'Europe/Paris',
        bclName: 'Romance Standard Time',
        standard: '+0100',
        daylight: '+0100',
    },
};

/**
 * The time zones supported by the application, keyed by database ID.
 */
export const TimeZonesById = new Map<number, TimeZone>();

/**
 * The time zones supported by the application keyed by Tzdb name, such as
 * "America/Los_Angeles".
 */
export const TimeZonesByTzdbName = new Map<string, TimeZone>();

for (const timeZone of Object.values(TimeZones)) {
    TimeZonesById.set(timeZone.id, timeZone);
    TimeZonesByTzdbName.set(timeZone.tzdbName, timeZone);
}

// Guesses the browser's current time zone.
export function guessBrowserTimeZone() {
    const tzdbName = moment.tz.guess();
    const timeZone = TimeZonesByTzdbName[tzdbName];
    return timeZone || TimeZones.pacificStandardTime;
}
