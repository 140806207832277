OpenModalController.$inject = ["$document", "$timeout", "$uibModal"];

/* injects from baggage-loader */
require('./open-modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('openModal', {
  templateUrl: '/app/components/modals/open-modal.component.html',
  controller: OpenModalController,
  transclude: true,
  bindings: {
    component: '@',
    windowClass: '@',
    resolve: '<',
    onOpening: '&',
    onCancel: '&',
    onResult: '&'
  }
});
/* @ngInject */

function OpenModalController($document, $timeout, $uibModal) {
  var $ctrl = this;
  $ctrl.open = open;
  $ctrl.currentDialog = null;

  function open() {
    if ($ctrl.component) {
      var onOpening = $ctrl.onOpening();

      if (onOpening) {
        onOpening.then(function (success) {
          if (success) {
            $timeout(function () {
              openModal();
            });
          }
        });
      } else {
        openModal();
      }
    }
  }

  function openModal() {
    var modalResolve = loadResolve($ctrl.resolve); // if launching the same skip.

    if ($ctrl.currentDialog == $ctrl.component) return;
    $ctrl.currentDialog = $ctrl.component;
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      windowClass: $ctrl.windowClass,
      component: $ctrl.component,
      resolve: modalResolve
    });
    modal.result.then(function (result) {
      $ctrl.currentDialog = null;
      $ctrl.onResult({
        result: result
      });
    }, function () {
      $ctrl.currentDialog = null;
      $ctrl.onCancel();
    });
  }

  function loadResolve(resolve) {
    // Input:  { a: 1,       b: 2       }
    // Output: { a: () => 1, b: () => 2 }
    var modalResolve = {};

    for (var property in resolve) {
      if (resolve.hasOwnProperty(property)) {
        (function () {
          var value = resolve[property];

          modalResolve[property] = function () {
            return value;
          };
        })();
      }
    }

    return modalResolve;
  }
}