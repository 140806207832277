import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';

@Component({
    selector: 'admin-settings-modal-sendtestlead',
    templateUrl: './admin-settings-modal-sendtestlead.component-ng.html'
})

export class AdminSettingsModalSendTestLeadComponent implements OnInit, AfterViewInit {
    //public isEmailIValid: boolean = true;
    public email: string = "";
    public source: string = "AutoAlert";

    @Output() sendTestLead = new EventEmitter();

    constructor(
        public _bsModalRef: BsModalRef,
        public tService: TranslateService,
        private logger: ToasterLoggerService) {

    }

    public ngOnInit(): void {
        this._bsModalRef.hide();
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public sendEmail(sendLeadForm: NgForm): void {
        if (sendLeadForm.valid) {
            this.sendTestLead.emit({ email: this.email, source: this.source });
            this._bsModalRef.hide();
        }
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
    }
}
