import "core-js/modules/es6.function.name";
SaveSearchResponseModalController.$inject = ["$element", "$scope", "$sce", "$uibModal", "$document", "legacy", "urlHelper"];

/* injects from baggage-loader */
require('./search-save-response-modal.component.html');

import angular from 'angular';
import { saveResponseCodes } from './searchfilter-definitions';
import './search-save-response-modal.component.scss';
angular.module('AaApp.Opp').component('searchSaveResponseModal', {
  templateUrl: '/app/components/search/search-save-response-modal.component.html',
  controller: SaveSearchResponseModalController,
  bindings: {
    resolve: '<',
    saveSearchResponse: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function SaveSearchResponseModalController($element, $scope, $sce, $uibModal, $document, legacy, urlHelper) {
  var $ctrl = this;
  $ctrl.$onInit = onInit;
  $ctrl.saveResponse = $ctrl.resolve.saveResponse;
  $ctrl.saveResponseCodes = saveResponseCodes;
  $ctrl.getDealerNameById = getDealerNameById;
  $ctrl.dealers = $ctrl.resolve.dealers;

  function onInit() {}

  function getDealerNameById(dealerId) {
    if ($ctrl.dealers != null) {
      var dealer = $ctrl.dealers.filter(function (d) {
        return d.id == dealerId;
      });
      if (dealer.length > 0) return dealer[0].name;
    }

    return "<Unknown>"; // this should never happen
  }
}