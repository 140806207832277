import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Settings } from '../../../constants/app-settings';

@Component({
  selector: 'admin-templates-edit-modal',
  templateUrl: './admin-templates-edit-modal.component-ng.html',
  styleUrls: ['./admin-templates-edit-modal.component.scss']
})
export class AdminTemplatesEditModalComponent implements OnInit, AfterViewInit {
    @Input() id: number;
    @Input() dealerId: number;
    @Output() saveChanges = new EventEmitter();
    
    public url: string = "";

    constructor(public _bsModalRef: BsModalRef) {
    }

    ngOnInit(): void {
        this.url = Settings.legacy.iframeUrl + "/Settings/OutreachTemplates/EditModal/?" + "id=" + this.id + "&dealerID=" + this.dealerId;        

        this._bsModalRef.hide();
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.saveChanges.emit();
        this._bsModalRef.hide();
    }

}