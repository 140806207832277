<div class="modal-wrapper">
    <form #mileageForm="ngForm" (ngSubmit)="saveMileage(mileageForm)">
        <div class="alert-box">
            <div class="modal-header">
                <div style="float:left">
                    <h5 class="modal-title" translate="updateMileage"></h5>
                </div>
                <div style="float:right">
                    <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                        <icon class="icon aa-icon-close-icon"></icon>
                    </button>
                </div>
            </div>
            <div class="modal-body form-horizontal">
                <div class="row form-group">
                    <label class="col-sm-2  col-form-label control-label" translate="mileageLabel"></label>
                    <div class="col-sm-10">
                        <input autofocus="true" type="number" class="form-control" [(ngModel)]="mileage" id="mileage1" name="mileage1" #mileage1="ngModel" required>
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-sm-2 col-form-label control-label" translate="readOn"></label>
                    <div class="col-sm-10">
                        <date-input [defaultDate]="model.date" [options]="dateOptions" id="readOnDate" name="readOnDate"
                                    (onInput)="setDate($event.date, $event.model)"></date-input>

                    </div>
                </div>
                <div class="form-group row" *ngIf="dateModel?.errors?.minDate || dateModel?.errors?.maxDate">
                    <div class="col-sm-12">
                        <span class="error" translate="dateOutOfRange"></span>
                    </div>
                </div>
                <div class="row form-group" *ngIf="dateModel?.invalid && !dateModel?.errors?.minDate && !dateModel?.errors?.maxDate">
                    <div class="col-sm-12">
                        <span class="error" translate="invalidDateFormat"></span>
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="cancelModal()" translate="cancel"></button>
                <button type="submit" class="btn btn-primary" translate="submit" [disabled]="!mileageForm.form.valid || !dateModel?.valid"></button>
            </div>
        </div>
    </form>
</div>