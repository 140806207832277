"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var log_action_shortcut_service_1 = require("../../../../services/log-action-shortcut.service");
var CustomerConnectActionsComponent = /** @class */ (function () {
    function CustomerConnectActionsComponent(logActionShortcutService, changeDetectorRef) {
        this.logActionShortcutService = logActionShortcutService;
        this.changeDetectorRef = changeDetectorRef;
        this.ActivityActionEnum = constants_1.ActivityActionEnum;
        this.onAction = new core_1.EventEmitter();
        this.onCustomerEdit = new core_1.EventEmitter();
        this.activityActionEnum = this.ActivityActionEnum.Log_Call;
        this.selectedBar = 'selectedbar';
        this.activityActionEnums = constants_1.ActivityActionEnum;
        this.showLogActivity = true;
        this.showScheduleAppt = true;
        this.showEmail = true;
        this.showText = true;
        this.shortcutInProgress = false;
    }
    CustomerConnectActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.logActionShortcutService.$logShortcutAction.subscribe(function (activityActionEnum) { return _this.handleShortcutEvent(activityActionEnum); });
    };
    CustomerConnectActionsComponent.prototype.activityClicked = function (activityActionEnum) {
        if (!activityActionEnum) {
            activityActionEnum = this.ActivityActionEnum.Log_Call;
        }
        this.onAction.emit(activityActionEnum);
        this.selectLogActivity();
        this.changeDetectorRef.detectChanges();
    };
    CustomerConnectActionsComponent.prototype.scheduleClicked = function () {
        this.onAction.emit(this.ActivityActionEnum.Schedule_Appt);
        this.selectScheduleActivity();
        this.changeDetectorRef.detectChanges();
    };
    CustomerConnectActionsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.isCustomerConnectOpen && changes.isCustomerConnectOpen.currentValue) {
            if (this.shortcutInProgress) {
                this.shortcutInProgress = false;
                return;
            }
            if (this.defaultCustomerConnectAction === this.ActivityActionEnum.Log_Call
                || this.defaultCustomerConnectAction === this.ActivityActionEnum.Post_Note) {
                this.activityActionEnum = this.defaultCustomerConnectAction;
                this.activityClicked();
            }
            else if (this.defaultCustomerConnectAction === this.ActivityActionEnum.Schedule_Appt
                || this.defaultCustomerConnectAction === this.ActivityActionEnum.Schedule_Call) {
                this.scheduleClicked();
            }
            this.logActionShortcutService.reset();
        }
        else {
            this.setAllShowFlags(true);
            this.activityActionEnum = this.defaultCustomerConnectAction;
            this.shortcutInProgress = false;
        }
        if (this.customer) {
            this.doNotCall = this.customer.doNotCall;
            this.doNotEmail = this.customer.doNotEmail;
        }
    };
    CustomerConnectActionsComponent.prototype.handleShortcutEvent = function (activityActionEnum) {
        if (activityActionEnum === null) {
            return;
        }
        this.shortcutInProgress = true;
        this.setAllShowFlags(false);
        this.activityActionEnum = activityActionEnum;
        switch (activityActionEnum) {
            case this.activityActionEnums.Log_Call:
            case this.activityActionEnums.Post_Note:
                this.showLogActivity = true;
                this.activityClicked(this.activityActionEnum);
                break;
            case this.activityActionEnums.Schedule_Appt:
                this.showScheduleAppt = true;
                this.scheduleClicked();
                break;
        }
    };
    CustomerConnectActionsComponent.prototype.setAllShowFlags = function (on) {
        this.showLogActivity = on;
        this.showScheduleAppt = on;
        this.showEmail = on;
        this.showText = on;
    };
    CustomerConnectActionsComponent.prototype.selectLogActivity = function () {
        this.selectedLogActivity = this.selectedBar;
        this.selectedscheduleActivity = '';
    };
    CustomerConnectActionsComponent.prototype.selectScheduleActivity = function () {
        this.selectedLogActivity = '';
        this.selectedscheduleActivity = this.selectedBar;
    };
    CustomerConnectActionsComponent.prototype.onDonotCallChange = function (doNotCall) {
        this.doNotCall = !doNotCall;
        this.onDonotCallEmailChange();
    };
    CustomerConnectActionsComponent.prototype.onDonotEmailChange = function (doNotEmail) {
        this.doNotEmail = !doNotEmail;
        this.onDonotCallEmailChange();
    };
    CustomerConnectActionsComponent.prototype.onDonotCallEmailChange = function () {
        this.editableCustomer = this.getCustomerCopy();
        this.editableCustomer.doNotCall = this.doNotCall;
        this.editableCustomer.doNotEmail = this.doNotEmail;
        var emitValue = { $value: this.editableCustomer };
        this.onCustomerEdit.emit(emitValue);
    };
    CustomerConnectActionsComponent.prototype.getCustomerCopy = function () {
        var cloneCustomer = {
            id: this.opportunity.customer.id,
            firstName: this.opportunity.customer.firstName,
            middleName: this.opportunity.customer.middleName,
            lastName: this.opportunity.customer.lastName,
            fullName: this.opportunity.customer.fullName,
            companyName: this.opportunity.customer.companyName,
            invalidContacts: this.opportunity.customer.invalidContacts.slice(),
            alternateContacts: this.opportunity.customer.alternateContacts.slice(),
            doNotCall: this.opportunity.customer.doNotCall,
            doNotEmail: this.opportunity.customer.doNotEmail,
            doNotText: this.opportunity.customer.doNotText,
            isFullCustomer: this.opportunity.customer.isFullCustomer,
            isAddressNotValid: this.opportunity.customer.isAddressNotValid,
            address: {
                streetAddress: this.opportunity.customer.address.streetAddress,
                streetAddress2: this.opportunity.customer.address.streetAddress2,
                city: this.opportunity.customer.address.city,
                stateId: this.opportunity.customer.address.stateId,
                stateName: this.opportunity.customer.address.stateName,
                zipCode: this.opportunity.customer.address.zipCode,
                normalizedZipCode: this.opportunity.customer.address.normalizedZipCode
            },
            // Create phony records for the UI if there is currenly no value
            homePhoneNumber: this.opportunity.customer.homePhoneNumber,
            workPhoneNumber: this.opportunity.customer.workPhoneNumber,
            cellPhoneNumber: this.opportunity.customer.cellPhoneNumber,
            emailAddress: this.opportunity.customer.emailAddress
        };
        return cloneCustomer;
    };
    return CustomerConnectActionsComponent;
}());
exports.CustomerConnectActionsComponent = CustomerConnectActionsComponent;
