<modal [headerTitle]="title" 
       [submitButtonVisible]="submitButtonVisible" 
       [submitButtonDisabled]="submitButtonDisabled" 
       [submitButtonText]="submitButtonText" 
       [closeButtonText]="closeButtonText" 
       (onClose)="close()" 
       (onSubmit)="submit()"
       [headerTextClass]="headerTextClass">
    <div class="modal-body">
        <p translate="{{message}}"></p>
    </div>
</modal>