import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { ScheduleModalParam } from '../ScheduleModalParam.interface';

@Component({
    selector: 'customer-connect-detail',
    templateUrl: './customer-connect-detail.component-ng.html',
    styleUrls: ['./customer-connect-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectDetailComponent implements OnInit, OnChanges {
    @Input() permissions: DealSheetPermissions;
    @Input() isCustomerConnectOpen: boolean;
    @Input() action: any;
    @Input() dialogParams: ScheduleModalParam;
    @Input() actionTime: number;
    @Output() onLogScheduleSubmit = new EventEmitter<any>();
    @Input() isEuroLite: boolean;

    public subAction: any;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onActionSelect(this.action);
    }

    onActionSelect(actionSelected) {
        this.subAction = actionSelected;
    }
}
