/**
 * Rounds a number to the specified precision
 * From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 */
export function roundWithPrecision(number, precision) {
    const shiftedToInteger = shift(number, precision, false);
    const roundedInteger = Math.round(shiftedToInteger);
    const unshiftedToFloat = shift(roundedInteger, precision, true);
    return unshiftedToFloat;

    function shift(number, precision, reverseShift) {
        if (reverseShift) {
            precision = -precision;
        }
        var numArray = ("" + number).split("e");
        return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
    }
}