"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".sent-time[_ngcontent-%COMP%]{margin-left:5px}.user-message[_ngcontent-%COMP%]{color:#34699e}.customer-message[_ngcontent-%COMP%]{color:#444b4f}.activity-icon[_ngcontent-%COMP%]{vertical-align:top}.btn.view-upgrade-proposal-link[_ngcontent-%COMP%]{padding-top:0;padding-bottom:0}.column-wrap[_ngcontent-%COMP%]{word-break:break-word}"];
exports.styles = styles;
