DealSheetVciController.$inject = ["$timeout", "$q", "$translate", "$translatePartialLoader", "$sce", "$scope", "$window", "$document", "$analytics", "$state", "$uibModal", "logger", "$auth", "$rootScope", "toaster", "dealSheetService", "opportunity2Service", "opportunityService", "vehicleService", "contractService", "authorizationService", "customerService", "globalIframeService", "userService", "dealerService", "activityService", "servicesService", "storageService", "eulaModalService", "searchService", "localePaths", "switchToNewDealSheetKey", "switchToClassicDealSheetKey", "dontHaveAccessWarningKey", "legacy", "dealSheetTabs", "saleTypes", "conquestTypes", "msrpTypes", "vehicleTypes", "alertScriptTypes", "activityRule", "analytics", "userProfileRoles", "modules", "submitDiscrepancySuccessMessageKey", "paymentTypes", "entityActionAccessEnum", "ActivityActionEnum", "activityResults", "tradeValueTypes", "vehicleActions", "opportunityStatuses", "environmentVariables", "pandoActionTypes", "assetTypes", "territories", "cultures", "vciResultType", "_", "mapUtils"];

/* injects from baggage-loader */
require('./deal-sheet-vci.component.html');

import angular from 'angular';
import './deal-sheet-vci.component.scss';
import { forEach } from '@uirouter/core';
angular.module('AaApp.Opp').component('dealSheetVci', {
  templateUrl: '/app/components/deal-sheet/deal-sheet-vci.component.html',
  controller: DealSheetVciController,
  bindings: {
    opportunityId: '<',
    dealerId: '<',
    makeId: '<',
    vin: '<'
  }
});
/* @ngInject */

function DealSheetVciController( //angular services
$timeout, $q, $translate, $translatePartialLoader, $sce, $scope, $window, $document, $analytics, $state, $uibModal, logger, $auth, $rootScope, toaster, //shared services
dealSheetService, //other services
opportunity2Service, opportunityService, vehicleService, contractService, authorizationService, customerService, globalIframeService, userService, dealerService, activityService, servicesService, storageService, eulaModalService, searchService, //$translate related
localePaths, switchToNewDealSheetKey, switchToClassicDealSheetKey, dontHaveAccessWarningKey, //constants
legacy, dealSheetTabs, saleTypes, conquestTypes, msrpTypes, vehicleTypes, alertScriptTypes, activityRule, analytics, userProfileRoles, modules, submitDiscrepancySuccessMessageKey, paymentTypes, entityActionAccessEnum, ActivityActionEnum, activityResults, tradeValueTypes, vehicleActions, opportunityStatuses, environmentVariables, pandoActionTypes, assetTypes, territories, cultures, vciResultType, //Tools
_, mapUtils) {
  // props
  var $ctrl = this;
  var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;
  $ctrl.showVciDataClick = showVciDataClick;
  $ctrl.fixupString = fixupString;
  $ctrl.buildFullName = buildFullName;
  $ctrl.boolToYorN = boolToYorN;
  $ctrl.showVciDataSection = false;
  $ctrl.vciDataLoaded = false;
  $ctrl.vciDataDisplay = false;
  $ctrl.vciDataErrored = false;
  $ctrl.vciApiErrored = false;
  $ctrl.vciVinNotFound = false;
  $ctrl.vciOptedOutNonoriginatingDealer = false;
  $ctrl.vciOptedOutOriginatingDealer = false;
  $ctrl.vciData;
  $ctrl.dataloadWorking;
  $ctrl.sectionStates = {
    0: false,
    1: false,
    2: true,
    3: false,
    4: false
  };
  console.log($ctrl);
  vehicleService.dealerMakeHasVciConfig($ctrl.dealerId, $ctrl.makeId).then(function (data) {
    console.log(data);
    $ctrl.showVciDataSection = data;
  });

  function buildFullName(buyerBlock) {
    var rslt = "";

    if (buyerBlock.firstName) {
      rslt = buyerBlock.firstName + " ";
    }

    if (buyerBlock.middleInitial) {
      rslt = rslt + buyerBlock.middleInitial + " ";
    }

    if (buyerBlock.middleInitial) {
      rslt = rslt + buyerBlock.lastName;
    }

    return fixupString(rslt);
  }

  function fixupString(value) {
    if (value) {
      return value.trim().length == 0 ? "N/A" : value.trim();
    } else {
      return "N/A";
    }
  }

  function boolToYorN(value) {
    if (value) {
      return "Y";
    } else {
      return "N";
    }
  }

  function showVciDataClick() {
    console.log($ctrl.opportunityId, $ctrl.makeId, $ctrl.vin);
    $ctrl.vciApiErrored = false;
    $ctrl.dataloadWorking = $q.defer().promise;
    vehicleService.getVciMaturityData($ctrl.opportunityId, $ctrl.makeId, $ctrl.vin).then(function (data) {
      console.log(data);

      switch (data.resultType) {
        case vciResultType.EulaRequired:
          showEulaRequiredModal();
          break;

        case vciResultType.NotConfigured:
          // should never get here
          $ctrl.vciDataErrored = true;
          $ctrl.vciVinNotFound = true;
          break;

        case vciResultType.OptedOutNonoriginatingDealer:
          $ctrl.vciOptedOutNonoriginatingDealer = true;
          $ctrl.vciDataErrored = true;
          break;

        case vciResultType.OptedOutOriginatingDealer:
          $ctrl.vciOptedOutOriginatingDealer = true;
          $ctrl.vciDataErrored = true;
          break;

        case vciResultType.GeneralError:
        case vciResultType.VinNotFound:
          $ctrl.vciDataErrored = true;
          $ctrl.vciVinNotFound = true;
          break;

        case vciResultType.HasData:
          $ctrl.vciData = data.detail;
          $ctrl.vciDataLoaded = true;
          $ctrl.vciDataDisplay = true;
          console.log('HasData');
          break;
      }

      $ctrl.dataloadWorking = null;
    }).catch(function (e) {
      $ctrl.vciApiErrored = true;
      $ctrl.dataloadWorking = null;
    });
    ;
  }

  function showEulaRequiredModal() {
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'dealSheetVciEulaModal',
      windowClass: 'large-modal',
      resolve: {
        title: function title() {
          return "Volkswagen Credit EULA"; //$translate.instant("oneToOneIntelligentMarketing");
        }
      }
    });
    modal.result.then(function () {
      addVciEulaAccepted();
    });
  }

  function addVciEulaAccepted() {
    vehicleService.addVciEulaAccepted().then(function (data) {
      showVciDataClick();
    });
  }
}