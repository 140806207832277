"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DealerRebateComponent = /** @class */ (function () {
    function DealerRebateComponent() {
    }
    DealerRebateComponent.prototype.ngOnInit = function () {
    };
    return DealerRebateComponent;
}());
exports.DealerRebateComponent = DealerRebateComponent;
