import angular from 'angular';
import { defaultsDeep } from 'lodash';
import swaggerDefinition from './swagger-definition-dmspush';
import { OpportunitiesModule } from '../core/opportunities.module';

// AngularJS seems to require we load selectedDealersInjector first, or else
// injecting its provider fails with an error.
import './selected-dealers-injector.service';

//NOTES:
//STEP 1:  add this to the app.config
//$httpProvider.defaults.useXDomain = true;

//STEP 2:  read this article
//http://stackoverflow.com/questions/21455045/angularjs-http-cors-and-http-authentication

OpportunitiesModule.provider('dmsPushApi', DmsPushAPIProvider);

function DmsPushAPIProvider(selectedDealersInjectorProvider) {
    "ngInject";

    const dmsPushApi = {
        config: {}
    };

    return {

        configure({ url }) {
            dmsPushApi.config.domain = url;
            selectedDealersInjectorProvider.addUrl(url)
        },

        $get: function DmsPushAPI($http) {
            "ngInject";

            loadAPI();
            return dmsPushApi;

            function loadAPI() {
                for (var path in swaggerDefinition.paths) {
                    var clients = CreateClients(swaggerDefinition, path);

                    // This function name isn't great, I'd love to either find
                    // a better name for it, split it up into smaller,
                    // grokkable functions, or replace it.
                    // coreApi = apply(coreApi, clients);
                    defaultsDeep(dmsPushApi, clients);
                }
                dmsPushApi.swaggerDefinition = swaggerDefinition
            }

            function CreateClients(swaggerDefinition, path) {
                var pathSplit = path.split("/");
                var controller;
                var child = controller = {};
                for (var i = 0; i < pathSplit.length; i++) {
                    var name = pathSplit[i];
                    var isIgnored = (name == "")
                    if (!isIgnored) {
                        if (child[name] == null) child[name] = {};
                        child = child[name];

                        var isEndpoint = (i == pathSplit.length - 1);
                        if (isEndpoint) {
                            var httpVerbs = swaggerDefinition.paths[path];
                            for (var httpVerb in httpVerbs) {
                                var upperCaseVerb = httpVerb.toUpperCase();
                                switch (upperCaseVerb) {
                                    case "GET":
                                        child.GET = function (urlParams, httpOptions) {
                                            return $http({
                                                method: "GET",
                                                url: aggregateUrl(this.GET.config.httpPath, urlParams),
                                                params: urlParams,
                                                withCredentials: true,
                                                ...httpOptions
                                            });
                                        };
                                        break;
                                    case "POST":
                                        child.POST = function (body, urlParams, httpOptions) {
                                            return $http({
                                                method: "POST",
                                                url: aggregateUrl(this.POST.config.httpPath, urlParams),
                                                params: urlParams,
                                                data: body,
                                                withCredentials: true,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    ...(httpOptions && httpOptions.headers)
                                                },
                                                ...httpOptions
                                            });
                                        };
                                        break;
                                    case "PUT":
                                        child.PUT = function (body, urlParams, httpOptions) {
                                            return $http({
                                                method: "PUT",
                                                url: aggregateUrl(this.PUT.config.httpPath, urlParams),
                                                params: urlParams,
                                                data: body,
                                                withCredentials: true,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    ...(httpOptions && httpOptions.headers)
                                                },
                                                ...httpOptions
                                            });
                                        };
                                        break;
                                    case "DELETE":
                                        child.DELETE = function (urlParams, httpOptions) {
                                            return $http({
                                                method: "DELETE",
                                                url: aggregateUrl(this.DELETE.config.httpPath, urlParams),
                                                params: urlParams,
                                                withCredentials: true,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    ...(httpOptions && httpOptions.headers)
                                                },
                                                ...httpOptions
                                            });
                                        };
                                        break;
                                    case "PATCH":
                                        child.PATCH = function (body, urlParams, httpOptions) {
                                            return $http({
                                                method: "PATCH",
                                                url: aggregateUrl(this.PATCH.config.httpPath, urlParams),
                                                params: urlParams,
                                                data: body,
                                                withCredentials: true,
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    ...(httpOptions && httpOptions.headers)
                                                },
                                                ...httpOptions
                                            });
                                        };
                                        break;
                                    default:
                                        console.Write("HTTP verb: '" + upperCaseVerb + "' is not supported");

                                        break;
                                }
                                child[upperCaseVerb].config = {
                                    httpVerb: httpVerb,
                                    httpPath: dmsPushApi.config.domain + path,
                                    swaggerInfo: httpVerbs[httpVerb],
                                };
                            }
                        }
                    }
                }
                return controller;
            }

            function aggregateUrl(url, params) {
                if (params != null) {
                    for (var param in params) {
                        url = url.replace("{" + param + "}", encodeURI(params[param]));
                    }
                }

                return url;
            }
        }
    }
}
