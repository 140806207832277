"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./vauto.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("./vauto.component"));
var i4 = __importStar(require("../../../../services/ngx-modal-wrapper.service"));
var i5 = __importStar(require("../../../../core/toaster-logger.service"));
var i6 = __importStar(require("../../../../ajs-upgraded-providers"));
var styles_VautoComponent = [i0.styles];
var RenderType_VautoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VautoComponent, data: {} });
exports.RenderType_VautoComponent = RenderType_VautoComponent;
function View_VautoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "btn qa-vAuto-btn"], ["href", "#"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.vautoModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "../../../../images/asset-vauto.png"], ["style", "height:32px;width:auto;"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("vAutoCreateAppraisalButtonText")), ""); _ck(_v, 1, 0, currVal_0); }); }
exports.View_VautoComponent_0 = View_VautoComponent_0;
function View_VautoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vauto", [], null, null, null, View_VautoComponent_0, RenderType_VautoComponent)), i1.ɵdid(1, 638976, null, 0, i3.VautoComponent, [i4.NgxModalWrapperService, i2.TranslateService, i5.ToasterLoggerService, i6.VehicleService, i6.DealSheetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_VautoComponent_Host_0 = View_VautoComponent_Host_0;
var VautoComponentNgFactory = i1.ɵccf("vauto", i3.VautoComponent, View_VautoComponent_Host_0, { dealerId: "dealerId", customer: "customer", vehicle: "vehicle" }, {}, []);
exports.VautoComponentNgFactory = VautoComponentNgFactory;
