"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".replacement-contracts-wrapper .row{margin-left:0;margin-right:0}  .replacement-contracts-wrapper .field-label{display:block;margin-bottom:-5px;margin-top:2px}"];
exports.styles = styles;
