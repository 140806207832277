import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'financed-amount',
    templateUrl: './financed-amount.component-ng.html',
    styleUrls: ['./financed-amount.component.scss']
})
export class FinancedAmountComponent implements OnInit {
    @Input() value: number;

    constructor() { }

    ngOnInit() {
    }

}
