<img *ngIf="showCarFaxLink && readonly" src="../../../images/carfax.png" />

<a *ngIf="showCarFaxLink && !readonly" 
  href="javascript:void(0)" 
  uiSref = "carFaxReport" 
  [uiParams] = "{ opportunityId: opportunityId, vin: vin }" 
  target="_blank" 
  class="qa-carfax-link">
<img src="../../../images/carfax.png" />
</a>
