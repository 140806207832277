import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { Transition } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { MobileDealSheetDataCachingService } from '../../../../services/mobile-deal-sheet-data-caching.service';

@Component({
    selector: 'customer-info',
    templateUrl: './customer-info.component-ng.html',
    styleUrls: ['./customer-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerInfoComponent implements OnInit, OnDestroy {
    opportunitySubscription: Subscription;
    opportunity: DealSheetOpportunity;
    permissions: DealSheetPermissions;
    private opportunityId: string;

    public busy: Subscription;

    constructor(private mobileDealSheetDataCachingService: MobileDealSheetDataCachingService,
        private transition: Transition,
        private changeDetectorRef: ChangeDetectorRef) {
        this.opportunityId = this.transition.params().opportunityId;
    }

    ngOnInit(): void {
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(dealSheetData => {
            this.opportunity = dealSheetData.opportunity;
            this.permissions = dealSheetData.permissions;

            this.changeDetectorRef.detectChanges();
        });

        this.busy = this.opportunitySubscription;
    }

    ngOnDestroy(): void {
        this.opportunitySubscription.unsubscribe();
    }
}
