"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var app_settings_1 = require("../../../constants/app-settings");
var AdminTemplatesEditModalComponent = /** @class */ (function () {
    function AdminTemplatesEditModalComponent(_bsModalRef) {
        this._bsModalRef = _bsModalRef;
        this.saveChanges = new core_1.EventEmitter();
        this.url = "";
    }
    AdminTemplatesEditModalComponent.prototype.ngOnInit = function () {
        this.url = app_settings_1.Settings.legacy.iframeUrl + "/Settings/OutreachTemplates/EditModal/?" + "id=" + this.id + "&dealerID=" + this.dealerId;
        this._bsModalRef.hide();
    };
    AdminTemplatesEditModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    AdminTemplatesEditModalComponent.prototype.onCancel = function () {
        this.saveChanges.emit();
        this._bsModalRef.hide();
    };
    return AdminTemplatesEditModalComponent;
}());
exports.AdminTemplatesEditModalComponent = AdminTemplatesEditModalComponent;
