
import { Injectable } from '@angular/core';
import { invert } from 'lodash';
import moment from 'moment';
import { filter } from 'rxjs/operators';
import { AssetTypes } from '../constants';
import { EngagementEventGroups } from '../constants/engagement-event-types.constant';
import { RATING_ICONS, RATING_TITLES } from '../constants/review-pro-rating';
import { Usp_GetCustomerEngagementDashboard_RequestDto, Usp_GetCustomerEngagementsByEntityData_RequestDto } from '../generated/models';
import { DealSheetClientService } from '../generated/services';
import { DealerService } from './dealer.service';
import { HttpInterceptorOptionsService } from './http/http-interceptor-options.service';
import { EventTypes, NotificationService } from './notification.service';
import { Observable } from 'rxjs';

@Injectable(
    {providedIn: 'root'}
)
export class CustomerEngagementService {

    constructor(private notificationService: NotificationService, 
        private dealSheetClient: DealSheetClientService,
        private dealerService: DealerService, 
        private httpInterceptorOptionsService: HttpInterceptorOptionsService) {}
        
    public getCustomerEngagementLastViewed() {
        return this.dealSheetClient.GetCustomerEngagementLastViewedGET().toPromise();
    }

    public get newEngagements(): Observable<any> {
        return this.notificationService.message$
            .pipe(
                filter(evt => evt.eventType == EventTypes.engagement)
            )
    }

    public getCustomerEngagement(hoursBack: number) {
        let dateFrom = moment().subtract(hoursBack || 24, "hours").toDate();
        const params = {
            dateFrom: dateFrom.toISOString().replace("Z", ""),
            lastViewedDate: undefined,
            IncludeReviewPro: false
        };       

        const result = this.dealSheetClient.GetCustomerEngagementLastViewedGET().toPromise()
            .then((lastViewedResponse: any) => {
               
                params.lastViewedDate =  (lastViewedResponse !== null) ?
                    lastViewedResponse.lastViewedDateUTC :
                    new Date();

                return this.dealerService.getIsAssetEnabledForDealer(AssetTypes.reviewBuilderPro)
                    .then(response => {
                        params.IncludeReviewPro = response;
                        return this.dealSheetClient.CustomerEngagementHistoryGET(params).toPromise()
                            .then(engagementsResponse => {
                                return {
                                    engagements: engagementsResponse,
                                    lastViewed: lastViewedResponse
                                }
                            })
                    });
            })
            .then((combinedResponse: {lastViewed: any, engagements: any}) => {
                
                if (!combinedResponse) { return {lastViewed: null, engagements: null}; }

                const lastViewed = combinedResponse.lastViewed;
                const engagements = combinedResponse.engagements;
                return {
                    lastViewedDate:
                        lastViewed && lastViewed.data && lastViewed.data.lastViewedDateUTC
                            ? moment.utc(lastViewed.data.lastViewedDateUTC).local().toDate()
                            : null,
                    engagements:
                        engagements.map(item => ({
                            customerFullName: item.customerFullName,
                            engagementEventGroupId: item.engagementEventGroupID,
                            engagementEventTypeId: item.engagementEventTypeID,
                            eventDate: this.utcStringToLocalDate(item.eventDateUTC),
                            reviewRating: item.reviewRating,
                            highEngagement: item.highEngagement,
                            opportunityId: item.entityId,
                            group: invert(EngagementEventGroups)[item.engagementEventGroupID],
                            one2oneCount: item.one2OneCount,
                            emailCount: item.emailCount,
                            textMessageCount: item.textMessageCount,
                            autoAssistantCount: item.autoAssistantCount,
                            webCount: item.webCount,
                            serviceCount: item.serviceCount,
                            reviewCount: item.reviewCount,
                            totalCount: item.one2OneCount + item.emailCount + item.webCount + item.serviceCount + item.reviewCount + item.textMessageCount + item.autoAssistantCount + item.intelligentMarketingCount + item.geoAlertCount,
                            lastViewedCount: item.lastViewedCount,
                            ratingIcon: RATING_ICONS[item.reviewRating],
                            ratingTitle: RATING_TITLES[item.reviewRating],
                            intelligentMarketingCount: item.intelligentMarketingCount,
                            geoAlertCount: item.geoAlertCount
                         }))
                }
            });

        return result;
    }

    public getCustomerEngagementByGroup(selectedDealerId, { engagementEventGroupIds, dateFrom, take, hotLeadsOnly, notWorkedOnly, dealerId }) {
        const params = {
            engagementEventGroupIds: engagementEventGroupIds,
            dateFrom: dateFrom ? moment(dateFrom).utc().toISOString() : undefined,
            take: take || 100,
            hotLeadsOnly: !!hotLeadsOnly,
            notWorkedOnly: !!notWorkedOnly,
            dealerId: dealerId
        } as Usp_GetCustomerEngagementDashboard_RequestDto;

        this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        
        let result = this.dealSheetClient.CustomerEngagementDashboardPOST(params).toPromise()
            .then(response => response.map(engagement => ({
                ...engagement,
                entityID: engagement.entityId, // used by dashboardOpportunitiesTable
                opportunityId: engagement.entityId,
                eventDate: this.utcStringToLocalDate(engagement.eventDateUTC),
                ratingIcon: RATING_ICONS[engagement.reviewRating],
                ratingTitle: RATING_TITLES[engagement.reviewRating]
            })))

        return result;        
    }

    // This returns engagements for an Entity with eventDate, engagementEventTypeID
    // and highEngagement.
    public getCustomerEngagementsByEntity(entityId, dateFrom, hotLeadsOnly) {
        const params = {
            entityId: entityId,
            dateFrom: dateFrom ? moment(dateFrom).utc().toISOString() : undefined,
            hotLeadsOnly: hotLeadsOnly
        } as Usp_GetCustomerEngagementsByEntityData_RequestDto;

        const result = this.dealSheetClient.CustomerEngagementsByEntityPOST(params).toPromise()
            .then(response => response.map(engagement => ({
                engagementEventTypeId: engagement.engagementEventTypeID,
                engagementEventGroupId: engagement.engagementEventGroupID,
                eventDate: this.utcStringToLocalDate(engagement.eventDateUTC),
                reviewRating: engagement.reviewRating,
                highEngagement: engagement.highEngagement,
                ratingIcon: RATING_ICONS[engagement.reviewRating],
                ratingTitle: RATING_TITLES[engagement.reviewRating]
            })));

        return result;
    }

    public markCustomerEngagementsViewed(): Promise<null> {
        return this.dealSheetClient.UpdateCustomerEngagementLastViewedPOST().toPromise();
    }

    private utcStringToLocalDate(str: string): Date {
        return moment.utc(str).local().toDate();
    }
}
