export enum PandoFormControlType {
    SectionHeader = 0,
    Boolean = 1,
    TextBox = 2,
    TextArea = 3,
    DropDownList = 4,
    MultiSelectList = 5,
    Date = 6,
    Time = 7,
    SingleSelectRadio = 8,
    LinearScale = 9,
    ShortName = 10,
    FullName = 11,
    Address = 12,

    Currency = 21,
    Password = 22,
    Email = 23,
    Phone = 24,
}

export interface PandoFormControl {
    formControlId?: string
    order?: number,
    inputType?: number,  // form control type name coming from KC api
    controlType?: PandoFormControlType,  // our form control type name
    description: string,
    label: string,
    value?: string,
    values?: any[],
    minValue?: number,
    maxValue?: number,
    minLabel?: string,
    maxLabel?: string,
    fieldValueStep?: number,
    selectOptions?: SelectOption[],
    hasOtherValue?: boolean,
    otherValue?: string,
    helpText?: string,
}

export interface SelectOption {
    label: string,
    value: string
} 
