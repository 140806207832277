/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerTrimPlanPriceDto } from '../models/dealer-trim-plan-price-dto';
import { DealerPriceExtDto } from '../models/dealer-price-ext-dto';
import { DealerPriceDto } from '../models/dealer-price-dto';
@Injectable({
  providedIn: 'root',
})
class DealerPriceClientService extends __BaseService {
  static readonly GetTrimPriceByDealeridByTrimidGETPath = '/api/DealerPrice/GetTrimPrice/{dealerId}/{trimId}';
  static readonly GetTrimPlanPriceGETPath = '/api/DealerPrice/GetTrimPlanPrice';
  static readonly GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETPath = '/api/DealerPrice/GetAdjustedInventoryVehiclePrice/{dealerId}/{vehicleType}/{msrp}';
  static readonly GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETPath = '/api/DealerPrice/GetDealerPrices/{dealerId}/{makeId}/{year}/{modelId}/{trimId}';
  static readonly UpdateDealerPricesPATCHPath = '/api/DealerPrice';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DealerPriceClientService.GetTrimPriceByDealeridByTrimidGETParams` containing the following parameters:
   *
   * - `trimId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetTrimPriceByDealeridByTrimidGETResponse(params: DealerPriceClientService.GetTrimPriceByDealeridByTrimidGETParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerPrice/GetTrimPrice/${params.dealerId}/${params.trimId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `DealerPriceClientService.GetTrimPriceByDealeridByTrimidGETParams` containing the following parameters:
   *
   * - `trimId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetTrimPriceByDealeridByTrimidGET(params: DealerPriceClientService.GetTrimPriceByDealeridByTrimidGETParams): __Observable<number> {
    return this.GetTrimPriceByDealeridByTrimidGETResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `DealerPriceClientService.GetTrimPlanPriceGETParams` containing the following parameters:
   *
   * - `trimId`:
   *
   * - `pricingPlanId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetTrimPlanPriceGETResponse(params: DealerPriceClientService.GetTrimPlanPriceGETParams): __Observable<__StrictHttpResponse<DealerTrimPlanPriceDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.trimId != null) __params = __params.set('trimId', params.trimId.toString());
    if (params.pricingPlanId != null) __params = __params.set('pricingPlanId', params.pricingPlanId.toString());
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerPrice/GetTrimPlanPrice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DealerTrimPlanPriceDto>;
      })
    );
  }
  /**
   * @param params The `DealerPriceClientService.GetTrimPlanPriceGETParams` containing the following parameters:
   *
   * - `trimId`:
   *
   * - `pricingPlanId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetTrimPlanPriceGET(params: DealerPriceClientService.GetTrimPlanPriceGETParams): __Observable<DealerTrimPlanPriceDto> {
    return this.GetTrimPlanPriceGETResponse(params).pipe(
      __map(_r => _r.body as DealerTrimPlanPriceDto)
    );
  }

  /**
   * @param params The `DealerPriceClientService.GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETParams` containing the following parameters:
   *
   * - `vehicleType`:
   *
   * - `msrp`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETResponse(params: DealerPriceClientService.GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerPrice/GetAdjustedInventoryVehiclePrice/${params.dealerId}/${params.vehicleType}/${params.msrp}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `DealerPriceClientService.GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETParams` containing the following parameters:
   *
   * - `vehicleType`:
   *
   * - `msrp`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGET(params: DealerPriceClientService.GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETParams): __Observable<number> {
    return this.GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `DealerPriceClientService.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `trimId`:
   *
   * - `modelId`:
   *
   * - `makeId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params: DealerPriceClientService.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETParams): __Observable<__StrictHttpResponse<Array<DealerPriceExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerPrice/GetDealerPrices/${params.dealerId}/${params.makeId}/${params.year}/${params.modelId}/${params.trimId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerPriceExtDto>>;
      })
    );
  }
  /**
   * @param params The `DealerPriceClientService.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `trimId`:
   *
   * - `modelId`:
   *
   * - `makeId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGET(params: DealerPriceClientService.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETParams): __Observable<Array<DealerPriceExtDto>> {
    return this.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params).pipe(
      __map(_r => _r.body as Array<DealerPriceExtDto>)
    );
  }

  /**
   * @param prices undefined
   * @return Success
   */
  UpdateDealerPricesPATCHResponse(prices?: Array<DealerPriceDto>): __Observable<__StrictHttpResponse<Array<DealerPriceDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prices;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/DealerPrice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerPriceDto>>;
      })
    );
  }
  /**
   * @param prices undefined
   * @return Success
   */
  UpdateDealerPricesPATCH(prices?: Array<DealerPriceDto>): __Observable<Array<DealerPriceDto>> {
    return this.UpdateDealerPricesPATCHResponse(prices).pipe(
      __map(_r => _r.body as Array<DealerPriceDto>)
    );
  }
}

module DealerPriceClientService {

  /**
   * Parameters for GetTrimPriceByDealeridByTrimidGET
   */
  export interface GetTrimPriceByDealeridByTrimidGETParams {
    trimId: number;
    dealerId: number;
  }

  /**
   * Parameters for GetTrimPlanPriceGET
   */
  export interface GetTrimPlanPriceGETParams {
    trimId?: number;
    pricingPlanId?: number;
    dealerId?: number;
  }

  /**
   * Parameters for GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGET
   */
  export interface GetAdjustedInventoryVehiclePriceByDealeridByVehicletypeByMsrpGETParams {
    vehicleType: 0 | 1 | 2;
    msrp: number;
    dealerId: number;
  }

  /**
   * Parameters for GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGET
   */
  export interface GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGETParams {
    year: number;
    trimId: number;
    modelId: number;
    makeId: number;
    dealerId: number;
  }
}

export { DealerPriceClientService }
