import angular from "angular";
import { downgradeInjectable } from '@angular/upgrade/static';

import moduleName from "./module";
export default moduleName;

import "./auth.provider";
import "./oidc-http.interceptor";
import { TokenService } from "./token.service";

angular.module(moduleName)
    .factory("tokenService", downgradeInjectable(TokenService))
