import angular from 'angular';

angular.module('AaApp.Opp').constant('subMenuUnauthorized', [
    {
        "id": "help",
        "moduleId": 24,
        "displayName": "help",
        "authorize": false,
        "imgUrl": "/Content/images/icons/16/Help Blue 16 n p.png",
        "baseUrl": "about",
        "url": "about/contact-us",
        "subMenuItems": [
        {
            "id": "contactUs",
            "moduleId": 26,
            "displayName": "contactUs",
            "authorize": false,
            "url": "about/contact-us"
        },
        {
            "id": "about",
            "moduleId": 25,
            "displayName": "about",
            "authorize": false,
            "url": "about"
        }
        ]
    }
]);
