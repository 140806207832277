import "core-js/modules/es7.promise.finally";
legacyService.$inject = ["$http", "$interval", "$q", "$timeout", "$translate", "apiUrls", "conquestTypes", "coreApi", "dealerService", "legacy", "opportunityTypes", "printTemplateUrlPath", "saleTypes", "storageService", "tokenService", "translateErrorKeys", "vehicleTypes"];
import angular from 'angular';
import { filter, take } from 'rxjs/operators';
import { defaultTo } from '../utilities/utils';
import { downloadArraybufferResponse } from '../utilities/http';
import { ChildMessagePort } from '../utilities/messaging';
import { getUrlComponents } from '../utilities/url';
angular.module('AaApp.Opp').factory('legacyService', legacyService);
var UPGRADE_PROPOSAL_PATH = "/api/Proposal";
var MESSAGING_FRAME_PATH = "/Home/MessagingFrame";
var SECOND = 1000;
var MINUTE = 60 * SECOND;
/* @ngInject */

function legacyService($http, $interval, $q, $timeout, $translate, apiUrls, conquestTypes, coreApi, dealerService, legacy, opportunityTypes, printTemplateUrlPath, saleTypes, storageService, tokenService, translateErrorKeys, vehicleTypes) {
  var messagePort = createDedicatedLegacyMessagePort();
  var service = {
    getCalculationParameters: getCalculationParameters,
    generateUpgradeProposal: generateUpgradeProposal,
    downloadUpgradeProposalByActivity: downloadUpgradeProposalByActivity,
    getPrintTemplateUrl: getPrintTemplateUrl,
    sendLogOutMessage: sendLogOutMessage,
    sendChangeDealerMessageToIframe: sendChangeDealerMessageToIframe
  };
  return service;

  function generateUpgradeProposal(opportunity) {
    return getUpgradeProposalUrl(opportunity).then(function (url) {
      return $http({
        method: 'GET',
        url: url,
        withCredentials: true,
        responseType: 'arraybuffer'
      }).then(function (response) {
        return response;
      });
    });
  }

  function downloadUpgradeProposalByActivity(_ref) {
    var activityId = _ref.activityId;
    return coreApi.api.UpgradeProposal.GetByActivityId['{activityId}'].GET({
      activityId: activityId
    }, {
      responseType: 'arraybuffer'
    }).then(downloadArraybufferResponse({
      contentType: 'application/pdf',
      filename: 'UpgradeProposal.pdf'
    })).catch(function (err) {
      if (err.message == "popupBlockerEnabled") {
        $translate(translateErrorKeys.popupBlockerEnabledErrorMessage).then(function (response) {
          alert(response);
        });
      }
    });
  }

  function getUpgradeProposalUrl(opportunity) {
    return getCalculationParameters(opportunity).then(function (parameters) {
      var queryString = '?';

      for (var property in parameters) {
        if (parameters.hasOwnProperty(property)) {
          queryString += property + '=' + parameters[property] + '&';
        }
      }

      queryString = queryString.slice(0, -1);
      return apiUrls.reportMiner + UPGRADE_PROPOSAL_PATH + queryString;
    });
  }

  function getPrintTemplateUrl(opportunity, templateId, openInEditMode) {
    var queryString = '?';
    var tradeEquity;
    var newPaymentCPO = null;
    var newPaymentDifferenceCPO = null;
    if (opportunity.currentVehicle.tradeValue != null && opportunity.currentContract.payoffAmount != null) tradeEquity = opportunity.currentVehicle.tradeValue - opportunity.currentContract.payoffAmount;else tradeEquity = opportunity.currentVehicle.tradeValue;

    if (opportunity.replacementVehicle.type == vehicleTypes.cpo) {
      if (opportunity.replacementContract) {
        newPaymentCPO = opportunity.replacementContract.payment;
        newPaymentDifferenceCPO = opportunity.replacementContract.paymentDifference;
      }
    }

    var parameters = {
      DealerID: opportunity.dealerId,
      EntityID: opportunity.id,
      Make: opportunity.replacementVehicle.makeName,
      Model: opportunity.replacementVehicle.modelName,
      Year: opportunity.replacementVehicle.yearID,
      Trim: opportunity.replacementVehicle.trimName,
      IsNew: opportunity.replacementVehicle.type == vehicleTypes.new,
      Price: opportunity.replacementVehicle.price,
      Mileage: opportunity.replacementVehicle.odometer,
      NewPaymentValue: newPaymentCPO,
      NewPaymentDifferenceValue: newPaymentDifferenceCPO,
      StockNo: opportunity.replacementVehicle.stockNo,
      FullTrimName: opportunity.replacementVehicle.trimFullName,
      TradeValue: opportunity.currentVehicle.tradeValue,
      Equity: tradeEquity,
      templateId: templateId,
      openInEditMode: openInEditMode
    };

    for (var property in parameters) {
      if (parameters.hasOwnProperty(property)) {
        queryString += property + '=' + parameters[property] + '&';
      }
    }

    queryString = queryString.slice(0, -1);
    return legacy.iframeUrl + printTemplateUrlPath + queryString;
  }

  function sendLogOutMessage() {
    messagePort.postMessage({
      'task': 'logout'
    });
  }

  function sendChangeDealerMessageToIframe(dealerIds) {
    messagePort.message$.pipe(filter(function (msg) {
      return msg.type == "CHANGE_DEALER" || msg["CHANGE_DEALER"] == "success";
    })).subscribe(function () {
      sessionStorage.removeItem('reportHeaderData');
      coreApi.api.CacheManagement.FlushUserRelatedCache.GET().finally(function () {
        return document.location.reload();
      });
    });
    messagePort.postMessage({
      'task': 'change_dealer',
      'dealer_ids': dealerIds
    });
  }

  function getCalculationParameters(opportunity) {
    return $q.all([coreApi.api.BalanceToMaturity['{entityId}'].GET({
      entityId: opportunity.id,
      saleId: opportunity.currentContract.saleID
    }), coreApi.api.SalePayoffEstimate['{saleId}'].GET({
      saleId: opportunity.currentContract.saleID
    })]).then(function (response) {
      var balanceToMaturity = response[0].data;
      var salePayoffEstimate = response[1].data;
      var userProfile = storageService.getItem('userProfile');
      var stillOwnsVehicle = opportunity.type == 3 || opportunity.stillOwned == true;
      var finalTradeValue = defaultTo(function () {
        return opportunity.currentVehicle.tradeValue;
      }, 0) * stillOwnsVehicle;
      var adjustedBalance = defaultTo(function () {
        return salePayoffEstimate.adjustedBalance;
      }, 0) * stillOwnsVehicle;
      var tradeEquity;
      var source;

      switch (opportunity.type) {
        case opportunityTypes.native:
          source = 'Native';
          break;

        case opportunityTypes.converted:
          source = 'Converted';
          break;

        case opportunityTypes.conquest:
          source = 'Conquest';
          break;
      }

      var maturityDate;

      if (!opportunity.currentContract.saleType) {
        maturityDate = new Date(2000, 0, 1);
        maturityDate = maturityDate.getFullYear() + "-" + (maturityDate.getMonth() + 1) + "-" + maturityDate.getDate();
      } else if (opportunity.currentContract.saleType != saleTypes.lease) {
        maturityDate = opportunity.currentContract.lastPaymentDate ? opportunity.currentContract.lastPaymentDate : opportunity.currentContract.dealDate;
      } else {
        maturityDate = new Date(opportunity.currentContract.lastPaymentDate);
        maturityDate.setMonth(maturityDate.getMonth() - 1);
        maturityDate = maturityDate.getFullYear() + "-" + (maturityDate.getMonth() + 1) + "-" + maturityDate.getDate();
      }

      if (opportunity.currentVehicle.tradeValue != null && opportunity.currentContract.payoffAmount) {
        tradeEquity = opportunity.currentVehicle.tradeValue - opportunity.currentContract.payoffAmount;
      } else {
        tradeEquity = opportunity.currentVehicle.tradeValue;
      }

      var parameters = {
        EntityID: opportunity.id,
        KeyID: opportunity.currentContract.saleID,
        SaleID: opportunity.currentContract.saleID,
        OldBankID: opportunity.currentContract.bankID,
        DealerID: opportunity.dealerId,
        StateID: opportunity.customer.address.stateId,
        ZipCode: opportunity.customer.address.normalizedZipCode,
        SaleTypes: [opportunity.currentContract.saleType].filter(Boolean),
        // Conquest saleType is null, filter it out
        LastPaymentSaleType: opportunity.currentContract.saleType,
        UseBalloon: true,
        DispositionFee: balanceToMaturity.dispositionFee,
        BalanceToMaturity: balanceToMaturity.balanceToMaturity,
        CanBTBeApplied: balanceToMaturity.canBeApplied,
        CanBTDispositionFeeBeForgiven: balanceToMaturity.canDispositionFeeBeForgiven,
        UsePullForward: balanceToMaturity.usePullForward,
        HasCreditConvertFinancialData: opportunity.currentContract.conquestType == conquestTypes.creditConvert,
        PayOffForward: opportunity.currentContract.payoffAmount,
        AdjustedBalance: adjustedBalance ? adjustedBalance : opportunity.currentContract.adjustedBalance,
        TotalPenalty: salePayoffEstimate.totalPenalty,
        ForgivePenaltyIfTheSameBank: salePayoffEstimate.forgivePenaltyIfTheSameBank === true,
        FinalTradeValue: finalTradeValue,
        NewTrimID: opportunity.replacementVehicle.trimID,
        NewMakeID: opportunity.replacementVehicle.makeID,
        NewTrimYearID: opportunity.replacementVehicle.yearID,
        IsConquest: opportunity.replacementVehicle.IsConquest == 1 ? true : false,
        NewVehiclePrice: opportunity.replacementVehicle.price,
        IsUsed: opportunity.replacementVehicle.type == vehicleTypes.used,
        IsCPO: opportunity.replacementVehicle.type == vehicleTypes.cpo,
        MSRP: opportunity.replacementVehicle.msrp,
        CashDown: 0,
        OldMaturityDate: maturityDate,
        OldMakeID: opportunity.currentVehicle.makeID,
        OldTrimID: opportunity.currentVehicle.trimID,
        OldSaleTypeID: opportunity.currentContract.saleType,
        OldTermID: opportunity.currentContract.term,
        OldPaymentsRemaining: opportunity.currentContract.paymentsRemaining,
        OldMonthlyPayment: opportunity.currentContract.payment ? opportunity.currentContract.payment : 0,
        TradeEquity: tradeEquity,
        StillOwnAndNotPaidOff: opportunity.stillOwned === true && opportunity.paidOff === false,
        Source: source,
        dt: new Date(),
        e: opportunity.id,
        repMileage: opportunity.replacementVehicle.stockNo ? opportunity.replacementVehicle.odometer : '',
        repVIN: opportunity.replacementVehicle.stockNo ? opportunity.replacementVehicle.vin : '',
        OverrideTerm: opportunity.replacementContract && opportunity.replacementContract.useOverrideTerm ? opportunity.replacementContract.term : null,
        Culture: userProfile.cultureName,
        pricingPlanId: opportunity.customer.pricingPlanId
      };
      return parameters;
    });
  }

  function createDedicatedLegacyMessagePort() {
    var url = legacy.iframeUrl + MESSAGING_FRAME_PATH;
    var iframe = document.createElement("iframe");
    iframe.src = url;
    iframe.style.display = "none";
    iframe.classList.add("legacyService-message-port-" + Date.now());
    document.body.appendChild(iframe);

    var _getUrlComponents = getUrlComponents(url),
        origin = _getUrlComponents.origin;

    var port = new ChildMessagePort(iframe, origin);
    addStandardMessageHandlers(port);
    addKeepAlive(port);
    return port;

    function addStandardMessageHandlers(port) {
      port.message$.subscribe(function (data) {
        if (data['GET_SSO_ID_TOKEN']) {
          sendSSOToken(dealerService, tokenService, port);
        }
      });
    }

    function addKeepAlive(port) {
      // Wait to receive the first message before starting the keep alive
      port.message$.pipe(take(1)).subscribe(addKeepAliveImpl);

      function addKeepAliveImpl() {
        var keepAlive = $interval(sendKeepAlive, 10 * MINUTE); // Stop when the port is closed

        var cancelled = false;
        port.status$.subscribe(null, null, function () {
          $interval.cancel(keepAlive);
          cancelled = true;
        });

        function sendKeepAlive() {
          sendSSOToken(dealerService, tokenService, port);
          var failed = $timeout(reloadIframe, 10 * SECOND);
          port.message$.subscribe(function (data) {
            if (data["AUTOALERT_IFRAME_LOADED"] || data["POST_LOGIN_REDIRECT_PORTAL_HOME"]) {
              $timeout.cancel(failed);
            }
          });

          function reloadIframe() {
            if (!cancelled) {
              var src = iframe.src;
              iframe.src = "";
              iframe.src = src;
            }
          }
        }
      }
    }
  }
}

export function sendSSOToken(dealerService, tokenService, port) {
  // Send our token
  var ssoToken = tokenService.getTokenByType('id');

  if (ssoToken != null) {
    port.postMessage({
      'task': 'sso_token',
      'token_value': ssoToken,
      'access_token_value': tokenService.getTokenByType('access'),
      'dealer_ids': dealerService.getSelectedDealerIds(),
      'impersonating_username': tokenService.getImpersonatingUsername() || ""
    });
  }
}