import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'replacement-vehicle-offers',
    templateUrl: './replacement-vehicle-offers.component-ng.html',
    styleUrls: ['./replacement-vehicle-offers.component.scss']
})
export class ReplacementVehicleOffersComponent implements OnInit {
    @Input() currentVehicle: any;
    @Input() replacementVehicles: any;
    public label: string = 'replacementVehicle';

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentVehicle && this.replacementVehicles) {
            switch (this.currentVehicle) {
                case this.replacementVehicles.standardVehicle:
                    this.label = 'replacementVehicle';
                    break;
                case this.replacementVehicles.upgradeVehicle:
                    this.label = 'upgrade';
                    break;
                case this.replacementVehicles.downgradeVehicle:
                    this.label = 'downgrade';
                    break;
                default:
                    this.label = 'alternate';
                    break;
            }
        }
    }

}
