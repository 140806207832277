"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityIconClasses = {
    1: "aa-icon-core-takenote-icon-000000",
    2: "aa-icon-core-schedule-phone-call-000002",
    3: "aa-icon-core-calendar-icon-000000",
    4: "aa-icon-core-email-icon-000000",
    5: "aa-icon-core-send-icon-000000",
    6: "aa-icon-core-send-icon-000000"
};
