
/* injects from baggage-loader */
require('./ui-checkboxlist.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('uiCheckboxList', {
    templateUrl: '/app/components/search/ui-checkboxlist.component.html',
    controller: UICheckBoxList,
    bindings: {
        titleKey : '<',
        displayNameKey : '<',
        values: '<',
        hideDisplayName: '<',
        displayNameCssClass: '<',
        value: '=',
        triggerInit: '='
    }   
});

/* @ngInject */
function UICheckBoxList($scope, $state) {
    
    var $ctrl = this;
    $ctrl.$onInit = onInit;

    $scope.$watch('$ctrl.triggerInit', function(newVal, oldVal, scope) {
        if ($ctrl.triggerInit) {
            $ctrl.triggerInit = false;
            onInit();
        }
    });

    function onInit() {
        if (!$ctrl.displayNameCssClass)
            $ctrl.displayNameCssClass = "searchDisplayNameWide";

        $ctrl.value = {};
        $ctrl.values.forEach(function(option) {
            $ctrl.value[option.name] = option.value;
        });
    }
}
