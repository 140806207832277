"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".email-icon[_ngcontent-%COMP%]{vertical-align:text-bottom!important;margin-top:5px;background:url(https://assets.autoalert.com/img/autoalert-icons/icon-email-lake.svg) no-repeat;width:1em;height:36px;display:inline-block;line-height:1}"];
exports.styles = styles;
