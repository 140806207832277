<div class="do-not-call-email-wrapper">

  <div class="row">
    <div class="col-md-12">
      <div class="page-title">
        {{translations.doNotEmailCallPageTitle}}
      </div>
    </div>
  </div>
  <div class="divider"> </div>

  <div class="row do-not-call-email">

    <div class="panel panel-default do-not-call-panel" [ngBusy]="{busy: busy, delay: 200}">
      <div class="panel-heading"></div>
      <div class="panel-body do-not-call-panel-body">

        <div *ngIf="steps[currentStepIndex] === selectTypeStep" class="step">
          <div class="row">
            <div class="col-sm-6">
              <label>
                <span class="type-selection-title">
                  {{translations.doNotCallEmailSelectFileTypeLabel}}
                </span>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 type-selection-radio">
              <input type="radio" name="typeselection" value="donotcall" id="donotcallradio" checked
                (click)="selectType(doNotCallType)" [disabled]="!hasDoNotCallModule">
              <label for="donotcallradio">{{translations.doNotEmailCallTypeLabelCall}}</label>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 type-selection-radio">
              <input type="radio" name="typeselection" value="donotemail" id="donotemailradio" [checked]="!hasDoNotCallModule"
                (click)="selectType(doNotEmailType)" [disabled]="!hasDoNotEmailModule">
              <label for="donotemailradio">{{translations.doNotEmailCallTypeLabelEmail}}</label>
            </div>
          </div>
        </div>

        <div *ngIf="steps[currentStepIndex] === uploadFileStep" class="step">
          <div class="row">

            <div class="col-sm-12 margin-bottom-15">
              <span>{{translations.doNotCallEmailStep1Label}}</span>
            </div>
            <div class="col-sm-12 margin-bottom-15">
              <span>{{uploadMessage}} </span>
            </div>
            <div class="col-sm-12 margin-bottom-15">
              <label class="btn btn-primary btn-file">
                {{translations.upload}} <input type="file" style="display: none;" (change)="upload($event.target.files[0])">
              </label>
              <span class="margin-left-15">{{fileName}}</span>
            </div>
          </div>
          <div class="row status-row">
            <div class="col-sm-12">
              <span class="error-message">{{errorMessage}}</span>
            </div>

          </div>
        </div>

        <div *ngIf="steps[currentStepIndex] === selectColumnStep" class="step">
          <div class="col-sm-12 margin-bottom-15">
            <span>{{translations.doNotCallEmailStep2Label}}</span>
          </div>
          <div class="col-sm-12 margin-bottom-15">
            <span>{{columnSelectMessage}}</span>
          </div>
          <div class="col-sm-12 table-col">
            <table>
              <tr>
                <th *ngFor="let header of parsedCsv[0]; let colIdx = index;" (mouseenter)="startColumnHover(colIdx)"
                  (mouseleave)="endColumnHover(colIdx)" (click)="selectColumn(colIdx)" [class]="getColumnClass(colIdx)"
                  title="{{translations.doNotEmailCallColumnTooltip}}">
                  {{header}}
                </th>
              </tr>
              <tr *ngFor="let row of parsedCsv | slice:1:rowsSampleSize">
                <td *ngFor="let col of row; let colIdx = index;" (mouseenter)="startColumnHover(colIdx)"
                  (mouseleave)="endColumnHover(colIdx)" (click)="selectColumn(colIdx)" [class]="getColumnClass(colIdx)">
                  {{col}}
                </td>
              </tr>
              <tfoot>
                <tr>
                  <td colspan="4">
                    <span>{{matchedMessage}}</span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div *ngIf="steps[currentStepIndex] === confirmStep || 
          steps[currentStepIndex] === finishStep ||
          steps[currentStepIndex] === okStep" class="step">
          <div class="row">
            <div class="col-sm-12 margin-bottom-15">
              <span>{{translations.doNotCallEmailStep3Label}}</span>
            </div>
            <div class="col-sm-12 margin-bottom-15">
              <span>{{confirmMessage}}</span>
            </div>
            <div class="col-sm-12 table-col" *ngIf="confirmList.customerMatchCount > 0">

              <table>
                <thead>
                  <tr>
                    <th>{{translations.doNotEmailCallColumnNameLabel}}</th>
                    <th *ngIf="typeSelected === doNotCallType">Cell</th>
                    <th *ngIf="typeSelected === doNotCallType">Home</th>
                    <th *ngIf="typeSelected === doNotCallType">Work</th>
                    <th *ngIf="typeSelected === doNotEmailType">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let matchedCustomer of confirmList.customerMatchList | slice:0:rowsSampleSize">
                    <td>{{matchedCustomer.displayName}}</td>
                    <td *ngIf="typeSelected === doNotCallType" [ngClass]="{'bold': matchedCustomer.cellPhoneMatch}">
                      {{matchedCustomer.cellPhone | localePhone}}</td>
                    <td *ngIf="typeSelected === doNotCallType" [ngClass]="{'bold': matchedCustomer.homePhoneMatch}">
                      {{matchedCustomer.homePhone | localePhone}}</td>
                    <td *ngIf="typeSelected === doNotCallType" [ngClass]="{'bold': matchedCustomer.workPhoneMatch}">
                      {{matchedCustomer.workPhone | localePhone}}</td>
                    <td *ngIf="typeSelected === doNotEmailType" [ngClass]="{'bold': matchedCustomer.emailAddressMatch}">
                      {{matchedCustomer.emailAddress}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">
                      <span>{{matchedMessage}}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>

            </div>
          </div>

          <div class="row status-row">
            <div class="col-sm-12">
              <span class="error-message">{{errorMessage}}</span>              
            </div>
            <div class="col-sm-12">
              <span>{{statusMessage}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="panel-footer">
        <div class="pull-right">
          <button class="btn btn-primary" (click)="cancel()" *ngIf="shouldDisplayCancelBackButton()">{{translations.cancel}}</button>
          <button class="btn btn-primary" (click)="back()" *ngIf="shouldDisplayCancelBackButton()">{{translations.back}}</button>
          <button class="btn btn-primary" (click)="next()" [disabled]="getNextDisabledStatus()">{{nextLabel}}</button>
        </div>
      </div>
    </div>
  </div>

</div>