/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerSettingDto } from '../models/dealer-setting-dto';
import { DealerSmtpSettingSelectionDto } from '../models/dealer-smtp-setting-selection-dto';
import { DealerSettingResponseDto } from '../models/dealer-setting-response-dto';
import { DealerSettingRequestDto } from '../models/dealer-setting-request-dto';
import { DealerSettingSendTestAdfRequest } from '../models/dealer-setting-send-test-adf-request';
import { DealerInventorySettingDto } from '../models/dealer-inventory-setting-dto';
@Injectable({
  providedIn: 'root',
})
class DealerSettingClientService extends __BaseService {
  static readonly ByDealeridGETPath = '/api/DealerSetting/{dealerId}';
  static readonly GetDealerSmtpSettingByDealeridGETPath = '/api/DealerSetting/GetDealerSmtpSetting/{dealerId}';
  static readonly DealerSettingChangedPOSTPath = '/api/DealerSetting/DealerSettingChanged';
  static readonly GetDealerOutboundEmailProviderInfoByDealeridGETPath = '/api/DealerSetting/GetDealerOutboundEmailProviderInfo/{dealerId}';
  static readonly GetDealerSettingAllByDealeridGETPath = '/api/DealerSetting/GetDealerSettingAll/{dealerId}';
  static readonly UpdateDealerSettingAllPOSTPath = '/api/DealerSetting/UpdateDealerSettingAll';
  static readonly SendTestAdfEmailPOSTPath = '/api/DealerSetting/SendTestAdfEmail';
  static readonly GetTextNumbersByDealeridGETPath = '/api/DealerSetting/GetTextNumbers/{dealerId}';
  static readonly UseLegacyDealerSettingsPageGETPath = '/api/DealerSetting/UseLegacyDealerSettings';
  static readonly UpdateInventoryDealerSettingByDealerIdAsyncPOSTPath = '/api/DealerSetting/UpdateInventoryDealerSettingByDealerId';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  ByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<DealerSettingDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerSetting/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DealerSettingDto>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  ByDealeridGET(dealerId: number): __Observable<DealerSettingDto> {
    return this.ByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as DealerSettingDto)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerSmtpSettingByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<DealerSmtpSettingSelectionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerSetting/GetDealerSmtpSetting/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DealerSmtpSettingSelectionDto>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerSmtpSettingByDealeridGET(dealerId: number): __Observable<DealerSmtpSettingSelectionDto> {
    return this.GetDealerSmtpSettingByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as DealerSmtpSettingSelectionDto)
    );
  }

  /**
   * @param dealerId undefined
   */
  DealerSettingChangedPOSTResponse(dealerId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (dealerId != null) __params = __params.set('dealerId', dealerId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerSetting/DealerSettingChanged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param dealerId undefined
   */
  DealerSettingChangedPOST(dealerId?: number): __Observable<null> {
    return this.DealerSettingChangedPOSTResponse(dealerId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerOutboundEmailProviderInfoByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerSetting/GetDealerOutboundEmailProviderInfo/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerOutboundEmailProviderInfoByDealeridGET(dealerId: number): __Observable<number> {
    return this.GetDealerOutboundEmailProviderInfoByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerSettingAllByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<DealerSettingResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerSetting/GetDealerSettingAll/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DealerSettingResponseDto>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerSettingAllByDealeridGET(dealerId: number): __Observable<DealerSettingResponseDto> {
    return this.GetDealerSettingAllByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as DealerSettingResponseDto)
    );
  }

  /**
   * @param dsa undefined
   * @return Success
   */
  UpdateDealerSettingAllPOSTResponse(dsa?: DealerSettingRequestDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dsa;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerSetting/UpdateDealerSettingAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param dsa undefined
   * @return Success
   */
  UpdateDealerSettingAllPOST(dsa?: DealerSettingRequestDto): __Observable<number> {
    return this.UpdateDealerSettingAllPOSTResponse(dsa).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param request undefined
   */
  SendTestAdfEmailPOSTResponse(request?: DealerSettingSendTestAdfRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerSetting/SendTestAdfEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  SendTestAdfEmailPOST(request?: DealerSettingSendTestAdfRequest): __Observable<null> {
    return this.SendTestAdfEmailPOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `DealerSettingClientService.GetTextNumbersByDealeridGETParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `stateCode`:
   *
   * - `areaCode`:
   *
   * @return Success
   */
  GetTextNumbersByDealeridGETResponse(params: DealerSettingClientService.GetTextNumbersByDealeridGETParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.stateCode != null) __params = __params.set('stateCode', params.stateCode.toString());
    if (params.areaCode != null) __params = __params.set('areaCode', params.areaCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerSetting/GetTextNumbers/${params.dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param params The `DealerSettingClientService.GetTextNumbersByDealeridGETParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `stateCode`:
   *
   * - `areaCode`:
   *
   * @return Success
   */
  GetTextNumbersByDealeridGET(params: DealerSettingClientService.GetTextNumbersByDealeridGETParams): __Observable<Array<string>> {
    return this.GetTextNumbersByDealeridGETResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @return Success
   */
  UseLegacyDealerSettingsPageGETResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerSetting/UseLegacyDealerSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Success
   */
  UseLegacyDealerSettingsPageGET(): __Observable<boolean> {
    return this.UseLegacyDealerSettingsPageGETResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param inventoryDealerSettingDto undefined
   * @return Success
   */
  UpdateInventoryDealerSettingByDealerIdAsyncPOSTResponse(inventoryDealerSettingDto?: DealerInventorySettingDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = inventoryDealerSettingDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerSetting/UpdateInventoryDealerSettingByDealerId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param inventoryDealerSettingDto undefined
   * @return Success
   */
  UpdateInventoryDealerSettingByDealerIdAsyncPOST(inventoryDealerSettingDto?: DealerInventorySettingDto): __Observable<number> {
    return this.UpdateInventoryDealerSettingByDealerIdAsyncPOSTResponse(inventoryDealerSettingDto).pipe(
      __map(_r => _r.body as number)
    );
  }
}

module DealerSettingClientService {

  /**
   * Parameters for GetTextNumbersByDealeridGET
   */
  export interface GetTextNumbersByDealeridGETParams {
    dealerId: number;
    stateCode?: string;
    areaCode?: number;
  }
}

export { DealerSettingClientService }
