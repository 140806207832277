
/* injects from baggage-loader */
require('./announcement-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('announcementModal', {
    templateUrl: '/app/components/modals/announcement-modal.component.html',
    controller: AnnouncementModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function AnnouncementModalController() {
    var $ctrl = this;

    $ctrl.doNotShow;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.doNotShow = false;
    }
}

