import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { LocaleDatePipe } from '../../../../filters/locale-date.pipe';
import { LocaleDateTimePipe } from '../../../../filters/locale-date-time.pipe';
import { LocaleTimePipe } from '../../../../filters/locale-time.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'service-appointment',
    templateUrl: './service-appointment.component-ng.html',
    styleUrls: ['./service-appointment.component.scss']
})
export class ServiceAppointmentComponent implements OnInit, OnChanges {
    @Input() date: any;
    @Input() alertExists: boolean;
    @Input() abbreviate: boolean;
    public serviceAppointment: string = "";
    public idUnique: string;

    constructor(private localeDate: LocaleDatePipe, private localeDateTime: LocaleDateTimePipe, private localeTime: LocaleTimePipe, private translateService: TranslateService) { }

    ngOnInit() {
        this.idUnique = 'service-appointment' + (Date.now() + (Math.floor(Math.random() * 1000))).toString();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date && !changes.date.isFirstChange() && changes.date.currentValue !== null && changes.date.currentValue !== undefined) {
            if (this.abbreviate) {
                let serviceAppointmentDateTime = this.localeDateTime.transform(this.date);
                this.serviceAppointment = this.translateService.instant('serviceAppointmentShortText', { date: serviceAppointmentDateTime });
            }
            else {
                let serviceAppointmentDate = this.localeDate.transform(this.date);
                let serviceAppointmentTime = this.localeTime.transform(this.date);
                this.serviceAppointment = this.translateService.instant('serviceAppointmentLongText', { date: serviceAppointmentDate, time: serviceAppointmentTime });
            }
        }
    }

}
