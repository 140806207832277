
/* injects from baggage-loader */
require('./data-entry-rates-rate-mark-ups.component.html');

import angular from 'angular';
import $ from 'jquery';
import BusyIndicator from '../../utilities/BusyIndicator';

angular.module('AaApp.Opp').component('dataEntryRatesRateMarkUp', {
    templateUrl: '/app/components/data-entry/data-entry-rates-rate-mark-ups.component.html',
    controller: DataEntryRatesRateMarkUpController,
    bindings: {
        kendo: '<',
        dealerId: '<',
        onNotifyUnsavedData: '&',
    }
});


function DataEntryRatesRateMarkUpController($translate, $q, dataEntryService, storageService, userService, dealerCountries,  
    $uibModal, $document, cultures, logger, $logFactory) {
    "ngInject";
    const $ctrl = this;
    const log = $logFactory.get();

    const cultureName = storageService.getItem('cultureName') || 'en-US';

    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;
    $ctrl.onDealerSelect = onDealerSelect;    
    
    $ctrl.onSave = onSave;
    $ctrl.onCancel = onCancel;
    $ctrl.kendoGrid = getKendoGrid;
    $ctrl.busyIndicator = new BusyIndicator();

    // For USA
    $ctrl.minStdLease = 0;
    $ctrl.maxStdLease = 0.01;

    //For Others
    $ctrl.minStdAprLease = 0;
    $ctrl.maxStdAprLease = 24;


    $ctrl.minStdAprRetail = 0;
    $ctrl.maxStdAprRetail = 20;

    $ctrl.countryId;
    $ctrl.cultureName;

    $ctrl.moneyFactorCheck = false;

    function onChanges(changes) {
        if (changes && changes.dealerId) {
            if (changes.dealerId.currentValue) {
                $ctrl.selectedDealerId = changes.dealerId.currentValue;
                getTranslations();
                onDealerSelect();
            }
        }
    }


    function init() { 
        userService.getUserProfile()
            .then(function (userProfile) {
                $ctrl.countryId = userProfile.countryId;
                $ctrl.cultureName = userProfile.cultureName;
                $ctrl.moneyFactorCheck = ($ctrl.countryId == dealerCountries.usa);
            });
    }

    function getTranslations() {
        $q.all({
            'saveConfirmMessage': $translate('saveConfirmMessage'),
            'saveWarningMessage': $translate('saveWarningMessage'),
            'validationErrorMessage': $translate('validationErrorMessage'),
            'bankMake': $translate('bankMake'),
            'stdLeaseRateMarkUp': $translate('standardLease'),
            'stdRetailRateMarkUp': $translate('standardRetail'),
            'specLeaselRateMarkUp': $translate('specialLease'),
            'cpoRateMarkUp': $translate('cpoRate'),
            'specRetailRateMarkUp': $translate('specialRetail'),
        }).then(function (stuff) {
            $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
            $ctrl.saveWarningMessage = stuff.saveWarningMessage;
            $ctrl.validationErrorMessage = stuff.validationErrorMessage;
            $ctrl.bankMake = stuff.bankMake;
            $ctrl.stdLeaseRateMarkUp = stuff.stdLeaseRateMarkUp;
            $ctrl.stdRetailRateMarkUp = stuff.stdRetailRateMarkUp;
            $ctrl.specLeaselRateMarkUp = stuff.specLeaselRateMarkUp;
            $ctrl.cpoRateMarkUp = stuff.cpoRateMarkUp;
            $ctrl.specRetailRateMarkUp = stuff.specRetailRateMarkUp;

            initGridOptions();
        });
    }

    function busy(translationKey, promise) {
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function onDealerSelect(previousValue) {
                    return loadRates();
    }


    function onSave() {        
        if (!isDataValid()) {
            var modal1 = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "validationError";
                    },
                    message: function () {
                        return $ctrl.validationErrorMessage;
                    },
                    submitButtonVisible: function () {
                        return false;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    closeButtonVisible: function () {
                        return true;
                    },
                    closeButtonDisabled: function () {
                        return true;
                    },
                    closeButtonText: function () {
                        return "ok";
                    }
                }
            });

            return;
        }

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'confirmationModal',
            resolve: {
                title: function () {
                    return "readyToProcess";
                },
                message: function () {
                    return $ctrl.saveConfirmMessage;
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "continue";
                }
            }
        });

        modal.result.then(function () {
            var rows = $ctrl.kendoGrid().dataSource.data();
            var dirtyRows = [];
            
            rows.forEach(function (row) {
                if (row.dirty) {
                    dirtyRows.push({                        
                        stdLeaseRateMarkUp: setLeaseAmount(row.stdLeaseRateMarkUp),
                        stdRetailRateMarkUp: setRetailCPOAmount(row.stdRetailRateMarkUp),
                        specLeaselRateMarkUp: setLeaseAmount(row.specLeaselRateMarkUp),
                        specRetailRateMarkUp: setRetailCPOAmount(row.specRetailRateMarkUp),
                        cpoRateMarkUp: setRetailCPOAmount(row.cpoRateMarkUp),
                        bankID: row.bankID,
                        dealerMakeRegionID: row.dealerMakeRegionID,
                        dealerMakeRegion_DealerMakeRegionID: row.dealerMakeRegion_DealerMakeRegionID,
                        makeID: row.makeID,
                        makeName: row.makeName,
                    });
                    row.dirty = false;
                }
            });            
            if (dirtyRows.length > 0) {
                return updateDealerRateMarkUps(dirtyRows);
            } else {
                logger.success("changesSaved");
                return $q.resolve();
            }
        })
            .catch(function () {
                return false;
            });
    }

    function setRetailCPOAmount(amount) {
        if (!amount)
            return 0;
        else
            return amount;
    }


    function setLeaseAmount(amount) {

        if (!amount) return 0;

        if (!$ctrl.moneyFactorCheck && amount) {
                return amount / 2400;
        }
        else
            return amount;
    }


    function onCancel() {        
        $ctrl.kendoGrid().cancelChanges();
        $ctrl.onNotifyUnsavedData({ result: { tab: "markUp", value: false } });
    }



    function updateDealerRateMarkUps(rates) {
        return busy(
            "Saving Rates",
            dataEntryService.updateDealerRateMarkUps(rates)
                .then(function (success) {
                    logger.success("changesSaved");
                    $ctrl.onNotifyUnsavedData({ result: { tab: "markUp", value: false } });
                    return loadRates();
                }).then(() => {

                }, function (error) {
                    log.log(error);
                })
        );
    }



    function isDataValid() {
        if (!$ctrl.kendoGrid())
            return true;
        var valid = true;
        var rows = $ctrl.kendoGrid().dataSource.data();
        rows.forEach(function (row) {
            if ($ctrl.moneyFactorCheck) {
                if ((row.stdLeaseRateMarkUp && row.stdLeaseRateMarkUp < $ctrl.minStdLease) || (row.stdLeaseRateMarkUp && row.stdLeaseRateMarkUp > $ctrl.maxStdLease) ||
                    (row.specLeaselRateMarkUp && row.specLeaselRateMarkUp < $ctrl.minStdLease) || (row.specLeaselRateMarkUp && row.specLeaselRateMarkUp > $ctrl.maxStdLease))
                    valid = false;
            }
            else {
                if ((row.stdLeaseRateMarkUp && row.stdLeaseRateMarkUp < $ctrl.minStdAprLease) || (row.stdLeaseRateMarkUp && row.stdLeaseRateMarkUp > $ctrl.maxStdAprLease) ||
                    (row.specLeaselRateMarkUp && row.specLeaselRateMarkUp < $ctrl.minStdAprLease) || (row.specLeaselRateMarkUp && row.specLeaselRateMarkUp > $ctrl.maxStdAprLease))
                    valid = false;
            }


            if ((row.stdRetailRateMarkUp && row.stdRetailRateMarkUp < $ctrl.minStdAprRetail) || (row.stdRetailRateMarkUp && row.stdRetailRateMarkUp > $ctrl.maxStdAprRetail) ||
                (row.specRetailRateMarkUp && row.specRetailRateMarkUp < $ctrl.minStdAprRetail) || (row.specRetailRateMarkUp && row.specRetailRateMarkUp > $ctrl.maxStdAprRetail) ||
                (row.cpoRateMarkUp && row.cpoRateMarkUp < $ctrl.minStdAprRetail) || (row.cpoRateMarkUp && row.cpoRateMarkUp > $ctrl.maxStdAprRetail))
                valid = false;

        });
        return valid;
    }

    function isDataDirty() {
        if (!$ctrl.kendoGrid())
            return false;
        var dirty = false;
        var rows = $ctrl.kendoGrid().dataSource.data();
        rows.forEach(function (row) {
            if (row.dirty)
                dirty = true;
        });
        return dirty;
    }

    function initGridOptions() {
        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            navigatable: true,
            pageable: false,
            height: 330,
            columns: [
                {
                    field: "bankName",
                    template: bankMakeTemplate,
                    sortable: {
                        compare: bankMakeSorting
                    },
                    title: $ctrl.bankMake, width: 100
                },
                {
                    field: "stdLeaseRateMarkUp",
                    template: stdLeaseRateMarkUpTemplate, title: $ctrl.stdLeaseRateMarkUp, editor: numericEditor5, width: 50,                    
                    headerAttributes: { "class": "align-right" }
                },
                { field: "stdRetailRateMarkUp", template: stdRetailRateMarkUpTemplate, title: $ctrl.stdRetailRateMarkUp, width: 50, headerAttributes: { "class": "align-right" } },
                { field: "specLeaselRateMarkUp", template: specLeaselRateMarkUpTemplate, title: $ctrl.specLeaselRateMarkUp, editor: numericEditor5, width: 50, headerAttributes: { "class": "align-right" } },
                { field: "specRetailRateMarkUp", template: specRetailRateMarkUpTemplate, title: $ctrl.specRetailRateMarkUp, width: 50, headerAttributes: { "class": "align-right" } },
                { field: "cpoRateMarkUp", template: cpoRateMarkUpTemplate, title: $ctrl.cpoRateMarkUp, width: 50, headerAttributes: { "class": "align-right" } }
            ],            
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: false,
            reorderable: true,
            filterable: false,
            editable: "incell",
            cellClose: function (e) {
                if (isDataDirty()) {
                    $ctrl.onNotifyUnsavedData({ result: { tab: "markUp", value: true } });
                }
            }
        };

    }


    function numericEditor5(container, options) {
        $('<input data-bind="value:' + options.field + '"/>')
            .appendTo(container)
            .kendoNumericTextBox({
                format: "n5",
                decimals: 5
            });
    }


    function bankMakeSorting(a, b) {
        if (a.bankName > b.bankName)
            return 1;
        else
            return -1;
    }


    function bankMakeTemplate(e) {
        return e.bankName + " - " + e.makeName;
    }

    function stdLeaseRateMarkUpTemplate(e) {
        if (!e.stdLeaseRateMarkUp)
        {
            if (e.stdLeaseRateMarkUp == 0)
            {
                if ($ctrl.moneyFactorCheck)
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.stdLeaseRateMarkUp, 'n0') + "</div>";
                else
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.stdLeaseRateMarkUp, 'n0') + "%</div>";
            }           
            else
                return "<div class='edit-box'></div>";
        }            
        else {            
            if ($ctrl.moneyFactorCheck) {
                if (e.stdLeaseRateMarkUp >= $ctrl.minStdLease && e.stdLeaseRateMarkUp <= $ctrl.maxStdLease)
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.stdLeaseRateMarkUp, 'n5') + "</div>";
                else
                    return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.stdLeaseRateMarkUp, 'n5') + "</div>";
            }
            else {
                if (e.stdLeaseRateMarkUp >= $ctrl.minStdAprLease && e.stdLeaseRateMarkUp <= $ctrl.maxStdAprLease)
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.stdLeaseRateMarkUp, 'n2') + "%</div>";
                else
                    return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.stdLeaseRateMarkUp, 'n2') + "%</div>";
            }
        }
    }


    function stdRetailRateMarkUpTemplate(e) {
        if (!e.stdRetailRateMarkUp)
        {
            if (e.stdRetailRateMarkUp == 0)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.stdRetailRateMarkUp, 'n0') + "%</div>";
            else
                return "<div class='edit-box'></div>";
        }
        else {
            if (e.stdRetailRateMarkUp >= $ctrl.minStdAprRetail && e.stdRetailRateMarkUp <= $ctrl.maxStdAprRetail)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.stdRetailRateMarkUp, 'n2') + "%</div>";
            else
                return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.stdRetailRateMarkUp, 'n2') + "%</div>";
        }
    }

    function specLeaselRateMarkUpTemplate(e) {
        if (!e.specLeaselRateMarkUp)
        {
            if (e.specLeaselRateMarkUp == 0)
            {
                if ($ctrl.moneyFactorCheck)
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.specLeaselRateMarkUp, 'n0') + "</div>";
                else
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.specLeaselRateMarkUp, 'n0') + "%</div>";
            }                
            else
                return "<div class='edit-box'></div>";
        }
        else {
            if ($ctrl.moneyFactorCheck) {
                if (e.specLeaselRateMarkUp >= $ctrl.minStdLease && e.specLeaselRateMarkUp <= $ctrl.maxStdLease)
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.specLeaselRateMarkUp, 'n5') + "</div>";
                else
                    return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.specLeaselRateMarkUp, 'n5') + "</div>";
            }
            else {
                if (e.specLeaselRateMarkUp >= $ctrl.minStdAprLease && e.specLeaselRateMarkUp <= $ctrl.maxStdAprLease)
                    return "<div class='edit-box'>" + $ctrl.kendo.toString(e.specLeaselRateMarkUp, 'n2') + "%</div>";
                else
                    return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.specLeaselRateMarkUp, 'n2') + "%</div>";
            }
        }
    }

    function specRetailRateMarkUpTemplate(e) {
        if (!e.specRetailRateMarkUp)
        {
            if (e.specRetailRateMarkUp == 0)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.specRetailRateMarkUp, 'n0') + "%</div>";
            else
                return "<div class='edit-box'></div>";
        }
        else {
            if (e.specRetailRateMarkUp >= $ctrl.minStdAprRetail && e.specRetailRateMarkUp <= $ctrl.maxStdAprRetail)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.specRetailRateMarkUp, 'n2') + "%</div>";
            else
                return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.specRetailRateMarkUp, 'n2') + "%</div>";
        }
    }

    function cpoRateMarkUpTemplate(e) {
        if (!e.cpoRateMarkUp)
        {
            if (e.cpoRateMarkUp == 0)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.cpoRateMarkUp, 'n0') + "%</div>";
            else
                return "<div class='edit-box'></div>";
        }
        else {
            if (e.cpoRateMarkUp >= $ctrl.minStdAprRetail && e.cpoRateMarkUp <= $ctrl.maxStdAprRetail)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.cpoRateMarkUp, 'n2') + "%</div>";
            else
                return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.cpoRateMarkUp, 'n2') + "%</div>";
        }
    }


    function processData(data) {
        if (!$ctrl.moneyFactorCheck) {
            data.forEach(function (item, index, arr) {
                if (item.specLeaselRateMarkUp) arr[index].specLeaselRateMarkUp = item.specLeaselRateMarkUp * 2400;
                if (item.stdLeaseRateMarkUp) arr[index].stdLeaseRateMarkUp = item.stdLeaseRateMarkUp * 2400;
            });
        }       
        return data;
    }




    function loadRates() {

        return busy(
            "Loading Rates",
            dataEntryService.getRateMarkUps($ctrl.selectedDealerId)
                .then((data) => {                    
                    var markupData = processData(data);
                    $ctrl.ds = new $ctrl.kendo.data.DataSource({
                        data: markupData,
                        schema: {
                            model: {
                                fields: {
                                    bankMake: { type: "string", editable: false },
                                    stdLeaseRateMarkUp: { type: "number", editable: true },
                                    stdRetailRateMarkUp: { type: "number", editable: true },
                                    specLeaselRateMarkUp: { type: "number", editable: true },
                                    specRetailRateMarkUp: { type: "number", editable: true },
                                    cpoRateMarkUp: { type: "number", editable: true },
                                    bankID: { type: "string", editable: false },
                                    dealerMakeRegionID: { type: "string", editable: false },
                                    dealerMakeRegion_DealerMakeRegionID: { type: "string", editable: false },
                                    makeID: { type: "string", editable: false },
                                    makeName: { type: "string", editable: false },
                                }
                            }
                        },
                        batch: true,
                        pageSize: 50
                    });

                    switch (cultureName) {
                        case cultures.enUS:
                        case cultures.enGB:
                        case cultures.enCA:
                        case cultures.frCA:
                        case cultures.esUS:
                            $ctrl.kendo.culture(cultureName);
                            break;
                        default:
                            $ctrl.kendo.culture(cultures.enUS);
                            break;
                    }

                    $("#k-datepicker").kendoDatePicker.widget.fn.options.min = new Date();
                    
                    //$ctrl.kendoGrid = $("#gridDataEntryRateMarkUps").data("kendoGrid");

                })
        );
    }

    function getKendoGrid() {
        return $("#gridDataEntryRateMarkUps").data("kendoGrid");
    }
} 
