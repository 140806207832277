
/* injects from baggage-loader */

import angular from 'angular';

angular.module('AaApp.Opp').component('carfaxCallback', {
    template: '<div style="color:white;padding:15px;text-align: center;width:100%">{{$ctrl.message}}</div>',
    controller: CarfaxCallbackController,
    bindings: {
        code: '<',
        state: '<'
    }
});

/* @ngInject */
function CarfaxCallbackController(vehicleService, $window) {
    var $ctrl = this;
    $ctrl.message = ""; 

    $ctrl.$onInit = function () {
        getCarFaxReport(this.code, this.state);
    }

    function getCarFaxReport(code, state) {

        vehicleService.getCarFaxUrlFromAuthCode(code, state)
            .then((carfaxUrl) => {
                if (carfaxUrl.startsWith("ERROR:")) {
                    $ctrl.message = carfaxUrl;
                }
                else {
                    $window.location.replace(carfaxUrl);
                }
            })
            .catch(function () {
            });
    }
}

