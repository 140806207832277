export const ApiCrmActivityTypes = {
    beBack: 'be-back',
    inboundCall: 'inbound call',
    inboundEmail: 'inbound email',
    letter: 'letter',
    outboundCall: 'outbound call',
    outboundEmail: 'outbound email',
    prospectAlert: 'prospect alert',
    prospectStatus: 'prospect status',
    remark: 'remark',
    remarkShowroom: 'remarkshowroom',
    reminder: 'reminder',
    textMessage: 'text message (sms)',
    inboundTextMessage: 'inbound text message',
    outboundTextMessage: 'outbound text message'
};
