ReopenController.$inject = ["opportunityStatuses"];

/* injects from baggage-loader */
require('./reopen.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('reopen', {
  templateUrl: '/app/components/deal-sheet/reopen.component.html',
  controller: ReopenController,
  bindings: {
    status: '<',
    permissions: '<',
    onReopen: '&'
  }
});
/* @ngInject */

function ReopenController(opportunityStatuses) {
  var $ctrl = this;
  $ctrl.$onChanges = onChanges;

  function onChanges(changes) {
    if (changes.status || changes.permissions) {
      if ($ctrl.status && $ctrl.permissions) {
        $ctrl.reopenEnabled = ($ctrl.status == opportunityStatuses.closed || $ctrl.status == opportunityStatuses.sold) && ($ctrl.permissions.editMarkAsNotOwn || $ctrl.permissions.editMarkAsSold);
      } else {
        $ctrl.reopenEnabled = false;
      }
    }
  }
}