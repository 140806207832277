<div class="modal-wrapper">
    <form #payoffForm="ngForm" (ngSubmit)="savePayoff(payoffForm)">
        <div class="modal-header">
            <div style="float:left">
                <h5 class="modal-title" translate="payoffEdit"></h5>
            </div>
            <div style="float:right">
                <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                    <icon class="icon aa-icon-close-icon"></icon>
                </button>
            </div>
        </div>
        <div class="modal-body form-horizontal">
            <div class="row form-group">
                <div class="col-sm-6">
                    <label translate="amount"></label>
                    <input autofocus type="number" class="form-control" [(ngModel)]="payoffAmount" [min]="minValidValue" id="payoffAmount" name="payoffAmount" (change)="setShowAmountMsg()" required>
                </div>

                <div class="col-sm-6">
                    <label translate="goodThrough"></label>
                    <date-input [options]="dateOptions" [required]="false"
                                (onInput)="setGoodThroughDate($event.date, $event.model)"></date-input>
                </div>

            </div>
            <div class="form-group row" *ngIf="showAmountMsg">
                <div class="col-sm-12">
                    <span class="error" translate="payoffAmountWarning" [translateParams]="{ amount: this.minValidValue | localeCurrency }"></span>
                </div>
            </div>
            <div class="form-group row" *ngIf="goodThroughDateModel?.dirty && goodThroughDateModel?.errors?.minDate || goodThroughDateModel?.errors?.maxDate">
                <div class="col-sm-12">
                    <span class="error" translate="dateOutOfRange"></span>
                </div>
            </div>
            <div class="row form-group" *ngIf="goodThroughDateModel?.invalid && !goodThroughDateModel?.errors?.minDate && !goodThroughDateModel?.errors?.maxDate">
                <div class="col-sm-12">
                    <span class="error" translate="invalidDateFormat"></span>
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="cancelModal()" translate="cancel"></button>
            <button type="submit" class="btn btn-primary" translate="submit" [disabled]="!payoffForm.form.valid || !goodThroughDateModel?.valid"></button>
        </div>
    </form>
</div>