export const ReportHeaderTypes = {
    full: 1,
    validatedFull: 2,
    singleDate: 3,
    dealerOnly: 4,
    dealerMake: 5,
    dealerDatesPresets: 6,
    pandoSurvey: 7,
    dealerOnlyNoSubscriptionDateSelector: 8,
    dealerMakeNoSubscriptionDateSelector: 9,
    performanceReport: 10
};
export default ReportHeaderTypes;
// usage samples:
// import { ReportHeaderTypes } from './report-header-types';
// import header from './report-header-types';
// import header from '../constants/reports/report-header-types';