
/* injects from baggage-loader */
require('./filter-button.component.html');

import { OpportunitiesModule } from '../core/opportunities.module';

OpportunitiesModule.component('filterButton', {
    templateUrl: '/app/components/filter-button.component.html',
    controller: FilterButtonController,
    transclude: {
        'icon': '?filterButtonIcon',
        'text': 'filterButtonText',
    },
    bindings: {
        ngModel: '=',
        ngChange: '&',
        moduleName: '<'
    }
});

function FilterButtonController() {
    "ngInject";
    const $ctrl = this;
}
