TemplateEmailPreviewController.$inject = ["$sce", "templateService", "storageService"];

/* injects from baggage-loader */
require('./template-email-preview.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('templateEmailPreview', {
  templateUrl: '/app/components/deal-sheet/template-email-preview.component.html',
  controller: TemplateEmailPreviewController,
  bindings: {
    opportunityId: '<',
    templateId: '<',
    replMakeId: '<'
  }
});
/* @ngInject*/

function TemplateEmailPreviewController($sce, templateService, storageService) {
  var $ctrl = this;
  $ctrl.templateEmailContent;
  $ctrl.getTemplateEmailContent = getTemplateEmailContent;
  $ctrl.$onInit = onInit;

  function onInit() {
    $ctrl.content = templateService.retrievePreviewTemplate();
    $ctrl.emailContentPromise = this.getTemplateEmailContent();
  }

  function getTemplateEmailContent() {
    return templateService.getEmailPreviewContent($ctrl.opportunityId, $ctrl.templateId, $ctrl.content, $ctrl.replMakeId).then(setTemplateEmailContent);
  }

  function setTemplateEmailContent(content) {
    $ctrl.templateEmailContent = $sce.trustAsHtml(content);
    $ctrl.emailContentPromise = null;
    return true;
  }
}