"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../../icon/icon.component.ngfactory"));
var i2 = __importStar(require("../../icon/icon.component"));
var i3 = __importStar(require("@angular/platform-browser"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("./pando-unread-messages.component"));
var styles_PandoUnreadMessagesComponent = [];
var RenderType_PandoUnreadMessagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PandoUnreadMessagesComponent, data: {} });
exports.RenderType_PandoUnreadMessagesComponent = RenderType_PandoUnreadMessagesComponent;
function View_PandoUnreadMessagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unreadMessageCount; _ck(_v, 1, 0, currVal_0); }); }
function View_PandoUnreadMessagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "icon", [["class", "-white -hover-pandoblue"], ["icon", "'pando'"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(2, 638976, null, 0, i2.IconComponent, [i3.DomSanitizer], { icon: [0, "icon"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PandoUnreadMessagesComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "'pando'"; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.unreadMessageCount > 0); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.communicationUrl; _ck(_v, 0, 0, currVal_0); }); }
exports.View_PandoUnreadMessagesComponent_0 = View_PandoUnreadMessagesComponent_0;
function View_PandoUnreadMessagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pando-unread-messages", [], null, null, null, View_PandoUnreadMessagesComponent_0, RenderType_PandoUnreadMessagesComponent)), i0.ɵdid(1, 49152, null, 0, i5.PandoUnreadMessagesComponent, [], null, null)], null, null); }
exports.View_PandoUnreadMessagesComponent_Host_0 = View_PandoUnreadMessagesComponent_Host_0;
var PandoUnreadMessagesComponentNgFactory = i0.ɵccf("pando-unread-messages", i5.PandoUnreadMessagesComponent, View_PandoUnreadMessagesComponent_Host_0, { isAutoAlertUser: "isAutoAlertUser", unreadMessageCount: "unreadMessageCount", communicationUrl: "communicationUrl" }, {}, []);
exports.PandoUnreadMessagesComponentNgFactory = PandoUnreadMessagesComponentNgFactory;
