/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CustomerDto } from '../models/customer-dto';
import { CustomerMarkDoNotEmailDto } from '../models/customer-mark-do-not-email-dto';
import { Customer_Get_DoNotEmailDto } from '../models/customer-_get-_do-not-email-dto';
import { CustomerMarkDoNotCallDto } from '../models/customer-mark-do-not-call-dto';
import { Customer_Get_DoNotCallDto } from '../models/customer-_get-_do-not-call-dto';
import { CustomerPatch } from '../models/customer-patch';
import { DoNotEmailStagingResponseDto } from '../models/do-not-email-staging-response-dto';
import { DoNotCallStagingResponseDto } from '../models/do-not-call-staging-response-dto';
import { UpdatePreferredLanguageResultDto } from '../models/update-preferred-language-result-dto';
import { CustomerPreferredLanguageDto } from '../models/customer-preferred-language-dto';
@Injectable({
  providedIn: 'root',
})
class CustomerClientService extends __BaseService {
  static readonly ByEntityByEntityidGETPath = '/api/Customer/ByEntity/{entityId}';
  static readonly GetNameOnlyByEntityidGETPath = '/api/Customer/NameByEntity/{entityId}';
  static readonly CustomerMarkDoNotEmailPOSTPath = '/api/Customer/Customer_Mark_DoNotEmail';
  static readonly CustomerGetDoNotEmailBySessionidGETPath = '/api/Customer/Customer_Get_DoNotEmail/{sessionID}';
  static readonly CustomerMarkDoNotCallPOSTPath = '/api/Customer/Customer_Mark_DoNotCall';
  static readonly CustomerGetDoNotCallBySessionidGETPath = '/api/Customer/Customer_Get_DoNotCall/{sessionID}';
  static readonly PUTPath = '/api/Customer';
  static readonly POSTPath = '/api/Customer';
  static readonly UpdatePATCHPath = '/api/Customer';
  static readonly UpdateCustomerByUpdatinguseridPOSTPath = '/api/Customer/UpdateCustomer/{updatingUserId}';
  static readonly InsertDoNotEmailStagingPOSTPath = '/api/Customer/Insert_DoNotEmailStaging';
  static readonly InsertDoNotCallStagingPOSTPath = '/api/Customer/Insert_DoNotCallStaging';
  static readonly UpdatePreferredLanguagePOSTPath = '/api/Customer/UpdatePreferredLanguage';
  static readonly ByIdGETPath = '/api/Customer/{id}';
  static readonly ByIdDELETEPath = '/api/Customer/{id}';
  static readonly GetManyGETPath = '/api/Customer/GetMany';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  ByEntityByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<CustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Customer/ByEntity/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  ByEntityByEntityidGET(entityId: string): __Observable<CustomerDto> {
    return this.ByEntityByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as CustomerDto)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetNameOnlyByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<CustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Customer/NameByEntity/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetNameOnlyByEntityidGET(entityId: string): __Observable<CustomerDto> {
    return this.GetNameOnlyByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as CustomerDto)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CustomerMarkDoNotEmailPOSTResponse(request?: CustomerMarkDoNotEmailDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer/Customer_Mark_DoNotEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CustomerMarkDoNotEmailPOST(request?: CustomerMarkDoNotEmailDto): __Observable<number> {
    return this.CustomerMarkDoNotEmailPOSTResponse(request).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param sessionID undefined
   * @return Success
   */
  CustomerGetDoNotEmailBySessionidGETResponse(sessionID: string): __Observable<__StrictHttpResponse<Customer_Get_DoNotEmailDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Customer/Customer_Get_DoNotEmail/${sessionID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Customer_Get_DoNotEmailDto>;
      })
    );
  }
  /**
   * @param sessionID undefined
   * @return Success
   */
  CustomerGetDoNotEmailBySessionidGET(sessionID: string): __Observable<Customer_Get_DoNotEmailDto> {
    return this.CustomerGetDoNotEmailBySessionidGETResponse(sessionID).pipe(
      __map(_r => _r.body as Customer_Get_DoNotEmailDto)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CustomerMarkDoNotCallPOSTResponse(request?: CustomerMarkDoNotCallDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer/Customer_Mark_DoNotCall`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CustomerMarkDoNotCallPOST(request?: CustomerMarkDoNotCallDto): __Observable<number> {
    return this.CustomerMarkDoNotCallPOSTResponse(request).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param sessionID undefined
   * @return Success
   */
  CustomerGetDoNotCallBySessionidGETResponse(sessionID: string): __Observable<__StrictHttpResponse<Customer_Get_DoNotCallDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Customer/Customer_Get_DoNotCall/${sessionID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Customer_Get_DoNotCallDto>;
      })
    );
  }
  /**
   * @param sessionID undefined
   * @return Success
   */
  CustomerGetDoNotCallBySessionidGET(sessionID: string): __Observable<Customer_Get_DoNotCallDto> {
    return this.CustomerGetDoNotCallBySessionidGETResponse(sessionID).pipe(
      __map(_r => _r.body as Customer_Get_DoNotCallDto)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: CustomerDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Customer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: CustomerDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: CustomerDto): __Observable<__StrictHttpResponse<CustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: CustomerDto): __Observable<CustomerDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as CustomerDto)
    );
  }

  /**
   * @param customerProfile undefined
   */
  UpdatePATCHResponse(customerProfile?: CustomerPatch): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = customerProfile;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Customer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param customerProfile undefined
   */
  UpdatePATCH(customerProfile?: CustomerPatch): __Observable<null> {
    return this.UpdatePATCHResponse(customerProfile).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CustomerClientService.UpdateCustomerByUpdatinguseridPOSTParams` containing the following parameters:
   *
   * - `updatingUserId`:
   *
   * - `customerProfile`:
   */
  UpdateCustomerByUpdatinguseridPOSTResponse(params: CustomerClientService.UpdateCustomerByUpdatinguseridPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.customerProfile;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer/UpdateCustomer/${params.updatingUserId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CustomerClientService.UpdateCustomerByUpdatinguseridPOSTParams` containing the following parameters:
   *
   * - `updatingUserId`:
   *
   * - `customerProfile`:
   */
  UpdateCustomerByUpdatinguseridPOST(params: CustomerClientService.UpdateCustomerByUpdatinguseridPOSTParams): __Observable<null> {
    return this.UpdateCustomerByUpdatinguseridPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param emailaddresslist undefined
   * @return Success
   */
  InsertDoNotEmailStagingPOSTResponse(emailaddresslist?: Array<string>): __Observable<__StrictHttpResponse<DoNotEmailStagingResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = emailaddresslist;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer/Insert_DoNotEmailStaging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DoNotEmailStagingResponseDto>;
      })
    );
  }
  /**
   * @param emailaddresslist undefined
   * @return Success
   */
  InsertDoNotEmailStagingPOST(emailaddresslist?: Array<string>): __Observable<DoNotEmailStagingResponseDto> {
    return this.InsertDoNotEmailStagingPOSTResponse(emailaddresslist).pipe(
      __map(_r => _r.body as DoNotEmailStagingResponseDto)
    );
  }

  /**
   * @param phoneNumberList undefined
   * @return Success
   */
  InsertDoNotCallStagingPOSTResponse(phoneNumberList?: Array<string>): __Observable<__StrictHttpResponse<DoNotCallStagingResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = phoneNumberList;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer/Insert_DoNotCallStaging`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DoNotCallStagingResponseDto>;
      })
    );
  }
  /**
   * @param phoneNumberList undefined
   * @return Success
   */
  InsertDoNotCallStagingPOST(phoneNumberList?: Array<string>): __Observable<DoNotCallStagingResponseDto> {
    return this.InsertDoNotCallStagingPOSTResponse(phoneNumberList).pipe(
      __map(_r => _r.body as DoNotCallStagingResponseDto)
    );
  }

  /**
   * @param customerPreferredLanguage undefined
   * @return Success
   */
  UpdatePreferredLanguagePOSTResponse(customerPreferredLanguage?: CustomerPreferredLanguageDto): __Observable<__StrictHttpResponse<UpdatePreferredLanguageResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = customerPreferredLanguage;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Customer/UpdatePreferredLanguage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdatePreferredLanguageResultDto>;
      })
    );
  }
  /**
   * @param customerPreferredLanguage undefined
   * @return Success
   */
  UpdatePreferredLanguagePOST(customerPreferredLanguage?: CustomerPreferredLanguageDto): __Observable<UpdatePreferredLanguageResultDto> {
    return this.UpdatePreferredLanguagePOSTResponse(customerPreferredLanguage).pipe(
      __map(_r => _r.body as UpdatePreferredLanguageResultDto)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<CustomerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Customer/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<CustomerDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as CustomerDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Customer/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<CustomerDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Customer/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<CustomerDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<CustomerDto>)
    );
  }
}

module CustomerClientService {

  /**
   * Parameters for UpdateCustomerByUpdatinguseridPOST
   */
  export interface UpdateCustomerByUpdatinguseridPOSTParams {
    updatingUserId: number;
    customerProfile?: CustomerPatch;
  }
}

export { CustomerClientService }
