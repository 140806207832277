// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'email-template-action',
//   templateUrl: './email-template-action.component.html',
//   styleUrls: ['./email-template-action.component.scss']
// })
// export class EmailTemplateActionComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Directive, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { DealSheetOpportunity } from '../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../interfaces/deal-sheet/deal-sheet-permissions.interface';

@Directive({
    selector: 'email-template-action'
})
export class EmailTemplateActionComponent extends UpgradeComponent {
    @Input()  opportunity: DealSheetOpportunity;
    @Input() permissions: DealSheetPermissions;
    @Output() onComplete = new EventEmitter<any>();
    @Input() isEuroLite: boolean;
    @Input() isShortcutButton: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('emailTemplateAction', elementRef, injector);
    }
}
