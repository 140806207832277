import angular from 'angular';

angular.module('AaApp.Opp')
    .constant('roles', {
        globalAdmin: 1,
        customerSupport: 2,
        dealerAdmin: 4,
        generalManager: 5,
        salesManager: 6,
        salesAssociate: 7,
        financeAndInsuranceManager: 8, 
        salesAllClients: 9,
        trainer: 10,
        baseSalesAssociate: 12,
        dataEntry: 29,
        regionalSalesManager: 853,
        clientCareAdvisor: 854,
        portfolioManager: 855,
        usedCarSalesManager: 1005
    })
