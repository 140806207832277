RunEnsureRouteServiceIsConstructed.$inject = ["$route"];
ConfigAuth.$inject = ["$authProvider", "$httpProvider", "$routeProvider", "oidc"];
// This file isn't wonderful, sorry :(
// This function is shared between Opportunities and Identity.
export function ConfigureCommonItemsForModule(module) {
  module.config(ConfigAuth).run(RunEnsureRouteServiceIsConstructed);
}

function ConfigAuth($authProvider, $httpProvider, $routeProvider, oidc) {
  "ngInject";

  $authProvider.configure(oidc);

  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }

  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache'; // Register callback route

  $routeProvider.when('/auth/callback/:data', {
    resolve: {
      handleSignInCallback: ["$auth", "$route", function handleSignInCallback($auth, $route) {
        "ngInject";

        $auth.handleSignInCallback($route.current.params.data);
      }]
    }
  }).when('/auth/clear', {
    resolve: {
      handleSignOutCallback: ["$auth", function handleSignOutCallback($auth) {
        "ngInject";

        $auth.handleSignOutCallback();
      }]
    }
  }).when('/auth/logout-from-sso', {
    resolve: {
      signOut: ["$auth", function signOut($auth) {
        "ngInject";

        $auth.signOut();
      }]
    }
  });
}

function RunEnsureRouteServiceIsConstructed($route) {
  "ngInject"; // You cannot remove this function, and you can't remove the unused $route
  // parameter. Seriously.
  //
  // The only hint about this I found was in this COMMENT (the question and
  // answer are irrelevant):
  //
  //   `You also need to inject "$route" somewhere or these events never fire.`
  //   https://stackoverflow.com/questions/14765719/how-to-watch-for-a-route-change-in-angularjs#comment28277400_14765954
  //
  // If we don't inject $route anywhere, then AngularJS never constructs the
  // service, so the router never does any work.
}