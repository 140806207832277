
/* injects from baggage-loader */
require('./view-email-conversation-action.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('viewEmailConversationAction', {
        templateUrl: '/app/components/deal-sheet/view-email-conversation-action.component.html',
        controller: ViewEmailConversationActionController,
        bindings: {
            emailConversationItemId: '<'
      }
});

/* @ngInject */
function ViewEmailConversationActionController($q, activityService) {
        var $ctrl = this;
}

