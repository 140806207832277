"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("./../../services/user.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_1 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var constants_1 = require("../../constants");
var AdminSettingsOpportunityComponent = /** @class */ (function () {
    function AdminSettingsOpportunityComponent(modalService, tService, userService, logger, document) {
        this.modalService = modalService;
        this.tService = tService;
        this.userService = userService;
        this.logger = logger;
        this.document = document;
        this.dsa = {};
        this.leaseContractEndRangeValid = true;
        this.purchaseContractEndRangeValid = true;
        this.productTypes = constants_1.ProductTypes;
    }
    AdminSettingsOpportunityComponent.prototype.ngOnInit = function () {
        this.unitLabelKey = this.tService.instant((this.dsa.isMetric) ? "unitKilometers" : "unitMiles");
    };
    AdminSettingsOpportunityComponent.prototype.validateContractEndRange = function () {
        this.leaseContractEndRangeValid = (this.dsa.dealerSetting.leaseContractEndRangeFrom <= this.dsa.dealerSetting.leaseContractEndRangeTo);
        this.purchaseContractEndRangeValid = (this.dsa.dealerSetting.purchaseContractEndRangeFrom <= this.dsa.dealerSetting.purchaseContractEndTo);
    };
    return AdminSettingsOpportunityComponent;
}());
exports.AdminSettingsOpportunityComponent = AdminSettingsOpportunityComponent;
