export const AlertTypes = {
    alert: 100,
    flexAlert: 200,
    inMarket: 400,
    inMarketEngaged: 500,
    contractEnd: 600,
    service: 700,
    appointment: 800,
    mileage: 900,
    warranty: 1000
};
