
/* injects from baggage-loader */
require('./email-info-edit.component.html');

import angular from 'angular';


    angular.module('AaApp.Opp').component('emailInfoEdit', {
        templateUrl: '/app/components/deal-sheet/email-info-edit.component.html',
        controller: SendTestEmailController,
        bindings: {
            userSubject: '<',
            emailFrom :'<',
            emailTo:'<',
            emailReplyTo:'<',
            isTest:'<',
            customerName:'<',
            userName:'<',
            onCloseCallback: '&',
            onEdit:'&'
        }
    });

    /* @ngInject */
    function SendTestEmailController() {
        var $ctrl = this;
        $ctrl.userEmailTo = "";
        $ctrl.onTestEmailEdit = onTestEmailEdit;
        $ctrl.$onInit = init;

        function init() {
            if (!$ctrl.isTest && $ctrl.userSubject != '') {
                $ctrl.onEdit({ $value: true });
            }
        }

        function onTestEmailEdit(isEmailValid) {
            var finalEmailToAddress = "";

            if ($ctrl.userSubject == ''){
                $ctrl.onEdit({ $value: false });
                return;
            }

            if ($ctrl.isTest) {
                // if test email then validate input
                if ($ctrl.userEmailTo == ''){
                    $ctrl.onEdit({ $value: false });
                    return;
                }

                if (!isEmailValid){
                    $ctrl.onEdit({ $value: false });
                    return;
                }

                finalEmailToAddress = $ctrl.userEmailTo; // user changed address
            }
            else
            {
                finalEmailToAddress = $ctrl.emailTo; // the default
            }

            // model to save
            var userChanges = { subject: $ctrl.userSubject, emailTo: finalEmailToAddress} ;
            //fire event with user changes
            $ctrl.onEdit({ $value: true });
            $ctrl.onCloseCallback({ $result: userChanges });


        }

    }
