"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var ngx_modal_wrapper_service_1 = require("../../../services/ngx-modal-wrapper.service");
var nlo_info_modal_component_1 = require("..//nlo-info-modal/nlo-info-modal.component");
var NloInfoComponent = /** @class */ (function () {
    function NloInfoComponent(modalService, tService) {
        this.modalService = modalService;
        this.tService = tService;
        this.onMarkNlo = new core_1.EventEmitter();
    }
    NloInfoComponent.prototype.ngOnInit = function () {
        // open the modal on initial load of deal sheet if any data
        if (this.nloData && this.nloData.length > 0) {
            this.nloInfoModal();
        }
    };
    NloInfoComponent.prototype.nloInfoModal = function () {
        var _this = this;
        var initialState = {
            entityId: this.entityId,
            nloData: this.nloData
        };
        this.bsModalRef = this.modalService.show(nlo_info_modal_component_1.NloInfoModalComponent, { initialState: initialState, class: 'nlo-info-modal' });
        this.bsModalRef.content.onClosed.subscribe(function (result) {
            _this.onMarkNlo.emit({ markAsNLO: result.markasNLO });
        });
    };
    return NloInfoComponent;
}());
exports.NloInfoComponent = NloInfoComponent;
