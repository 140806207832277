"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./replacement-vehicle-offers.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@angular/common"));
var i3 = __importStar(require("@ngx-translate/core"));
var i4 = __importStar(require("./replacement-vehicle-offers.component"));
var styles_ReplacementVehicleOffersComponent = [i0.styles];
var RenderType_ReplacementVehicleOffersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReplacementVehicleOffersComponent, data: {} });
exports.RenderType_ReplacementVehicleOffersComponent = RenderType_ReplacementVehicleOffersComponent;
function View_ReplacementVehicleOffersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "replacement-vehicle-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(5, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.label)))); _ck(_v, 3, 0, currVal_0); }); }
exports.View_ReplacementVehicleOffersComponent_0 = View_ReplacementVehicleOffersComponent_0;
function View_ReplacementVehicleOffersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "replacement-vehicle-offers", [], null, null, null, View_ReplacementVehicleOffersComponent_0, RenderType_ReplacementVehicleOffersComponent)), i1.ɵdid(1, 638976, null, 0, i4.ReplacementVehicleOffersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ReplacementVehicleOffersComponent_Host_0 = View_ReplacementVehicleOffersComponent_Host_0;
var ReplacementVehicleOffersComponentNgFactory = i1.ɵccf("replacement-vehicle-offers", i4.ReplacementVehicleOffersComponent, View_ReplacementVehicleOffersComponent_Host_0, { currentVehicle: "currentVehicle", replacementVehicles: "replacementVehicles" }, {}, []);
exports.ReplacementVehicleOffersComponentNgFactory = ReplacementVehicleOffersComponentNgFactory;
