"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var modal_1 = require("ngx-bootstrap/modal");
var toaster_logger_service_1 = require("../../../../core/toaster-logger.service");
var mileage_edit_modal_component_1 = require("../mileage-edit-modal/mileage-edit-modal.component");
var ngx_modal_wrapper_service_1 = require("../../../../services/ngx-modal-wrapper.service");
var MileageEditActionComponent = /** @class */ (function () {
    function MileageEditActionComponent(modalService, tService, logger, vehicleService) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.vehicleService = vehicleService;
        this.onMileageValueChange = new core_1.EventEmitter();
        this.startWorkingState = new core_1.EventEmitter();
    }
    MileageEditActionComponent.prototype.editMileageModal = function (mileage, readOnDate) {
        var _this = this;
        this.bsModalRef = this.modalService.show(mileage_edit_modal_component_1.MileageEditModalComponent);
        this.bsModalRef.content.onSaveChanges.subscribe(function (result) {
            _this.startWorkingState.emit();
            _this.vehicleService.updateMileAge(_this.opportunityId, result.mileage, result.readOnDate)
                .then(function (data) {
                _this.onMileageValueChange.emit({ $event: { mileageValue: result } });
            });
        });
    };
    return MileageEditActionComponent;
}());
exports.MileageEditActionComponent = MileageEditActionComponent;
