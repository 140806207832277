
/* injects from baggage-loader */
require('./ui-datepicker-range.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('uiDatepickerRange', {
    templateUrl: '/app/components/search/ui-datepicker-range.component.html',
    controller: UIDatepickerRange,
    bindings: {
        displayNameKey : "<",
        displayNameCssClass: '<',
        selectedValueFirst: "=",
        selectedValueLast: "=",
        isValidSelectedValueFirst:"=",
        isValidSelectedValueLast:"=",
        triggerInit: "=",
    }   
});

/* @ngInject */
function UIDatepickerRange($scope, $state, dateFormatService) {
    var $ctrl = this;
    $ctrl.$onInit = onInit;
    $ctrl.selectedValueFirstCSSClass = "searchDateInput valid";
    $ctrl.selectedValueLastCSSClass = "searchDateInput valid";
    $ctrl.dateFormat;
    $ctrl.datePickerOptions = {
        startingDay: 0,
        showWeeks: false,
        minDate: null,
        maxDate: null
    }

    $ctrl.onInputValueFirst = onInputValueFirst;
    $ctrl.onInputValueLast = onInputValueLast;

    $scope.$watch('$ctrl.triggerInit', function(newVal, oldVal, scope) {
        if ($ctrl.triggerInit) {
            $ctrl.triggerInit = false;
            onInit();
        }
    });

    function onInit()
    {
        $ctrl.selectedValueFirst = null;
        $ctrl.selectedValueLast = null;

        if (!$ctrl.displayNameCssClass)
            $ctrl.displayNameCssClass = "searchDisplayNameWide";


        $ctrl.dateFormat = dateFormatService.getDateFormatString();

        validate();
    }

    function onInputValueFirst(obj) {
        $ctrl.selectedValueFirst = angular.copy(obj);
        validate();
    }   

    function onInputValueLast(obj) {
        $ctrl.selectedValueLast = angular.copy(obj);
        validate();
    }

    function validate() {
        $ctrl.isValidSelectedValueFirst = true;
        $ctrl.isValidSelectedValueLast = true;

        if ($ctrl.selectedValueFirst && $ctrl.selectedValueLast) {
            $ctrl.isValidSelectedValueFirst = ($ctrl.selectedValueFirst <= $ctrl.selectedValueLast);
            $ctrl.isValidSelectedValueLast = ($ctrl.selectedValueFirst <= $ctrl.selectedValueLast);
        }

        $ctrl.selectedValueFirstCSSClass = "searchDateInput " + (($ctrl.isValidSelectedValueFirst) ? "valid" : "invalid");
        $ctrl.selectedValueLastCSSClass = "searchDateInput " + (($ctrl.isValidSelectedValueLast) ? "valid" : "invalid");
    }
}
