"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./admin-roles-selector.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@angular/forms"));
var i3 = __importStar(require("@ngx-translate/core"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("./admin-roles-selector.component"));
var styles_AdminRolesSelectorComponent = [i0.styles];
var RenderType_AdminRolesSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminRolesSelectorComponent, data: {} });
exports.RenderType_AdminRolesSelectorComponent = RenderType_AdminRolesSelectorComponent;
function View_AdminRolesSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.parent.context.$implicit.allow = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onItemChecked(_co.filterSubItemsByCategoryId(_v.parent.context.$implicit.categoryId, _co.accessList)) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.readOnly; var currVal_8 = _v.parent.context.$implicit.allow; _ck(_v, 3, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_AdminRolesSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "accessRight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.allow = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onSubItemChecked(_v.parent.context.$implicit, _v.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(5, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(8, null, ["\u00A0", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.readOnly; var currVal_8 = _v.context.$implicit.allow; _ck(_v, 5, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_v.context.$implicit.accessOptionName)); _ck(_v, 8, 0, currVal_9); }); }
function View_AdminRolesSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "categoriesContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "label", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminRolesSelectorComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "categoryImage"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "accessRightContainer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminRolesSelectorComponent_3)), i1.ɵdid(10, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideCategoryCheckbox; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.filterSubItemsByCategoryId(_v.context.$implicit.categoryId, _co.accessList); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.ICONS[_v.context.$implicit.accessOptionName]; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.accessOptionName)); _ck(_v, 6, 0, currVal_2); }); }
function View_AdminRolesSelectorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["translate", "cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "cancel"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AdminRolesSelectorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary saveButton"], ["translate", "saveChanges"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "saveChanges"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AdminRolesSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminRolesSelectorComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "actionButtonContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["style", "float:right;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminRolesSelectorComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminRolesSelectorComponent_5)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.readOnly; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.readOnly; _ck(_v, 7, 0, currVal_2); }, null); }
exports.View_AdminRolesSelectorComponent_0 = View_AdminRolesSelectorComponent_0;
function View_AdminRolesSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-roles-selector", [], null, null, null, View_AdminRolesSelectorComponent_0, RenderType_AdminRolesSelectorComponent)), i1.ɵdid(1, 49152, null, 0, i5.AdminRolesSelectorComponent, [], null, null)], null, null); }
exports.View_AdminRolesSelectorComponent_Host_0 = View_AdminRolesSelectorComponent_Host_0;
var AdminRolesSelectorComponentNgFactory = i1.ɵccf("admin-roles-selector", i5.AdminRolesSelectorComponent, View_AdminRolesSelectorComponent_Host_0, { accessList: "accessList", categories: "categories", dealerGroupId: "dealerGroupId", hideCategoryCheckbox: "hideCategoryCheckbox", readOnly: "readOnly" }, { onSaveChanges: "onSaveChanges", onCancelChanges: "onCancelChanges", accessRightChanged: "accessRightChanged" }, []);
exports.AdminRolesSelectorComponentNgFactory = AdminRolesSelectorComponentNgFactory;
