<div *ngIf="permissions?.postActivity" class="action-menu">
    <div class="comm-tools {{selectedLogActivity}}" [ngClass]="{'display-none': !showLogActivity}">
        <a class="btn qa-log-activity-btn" (click)="activityClicked()" title="{{'postNotesTooltip' | translate}}">
            <icon class="icon lg aa-icon-core-takenote-icon-000000"></icon>
            <div class="caption">
                <label translate="logActivity"></label>
            </div>
        </a>
    </div>
    <div class="comm-tools {{selectedscheduleActivity}}" [ngClass]="{'display-none': !showScheduleAppt}">
        <a class="btn qa-schedule-activity-btn" (click)="scheduleClicked()"
            title="{{'scheduleAppointmentToolTip' | translate}}">
            <icon class="icon lg aa-icon-core-calendar-icon-000000"></icon>
            <div class="caption">
                <label translate="scheduleActivity"></label>
            </div>
        </a>
    </div>
    <div class="comm-tools" [ngClass]="{'display-none': mobileView || !showEmail}">
        <email-template-action [opportunity]="opportunity" [isEuroLite]="isEuroLite" [permissions]="permissions">
        </email-template-action>
    </div>
    <div class="comm-tools" [ngClass]="{'display-none': !showText}">
        <text-action [opportunity]="opportunity" [isTextingEnabled]="isTextingEnabled"
            [isCustomerConnectOpen]="isCustomerConnectOpen"></text-action>
    </div>

    <div class="comm-tools do-not-contact-options" [ngClass]="{'display-none': activityActionEnum !== null && activityActionEnum !== activityActionEnums.Log_Call}">
        <div class="wide-tools" *ngIf="permissions.editDoNotContact">
            <input id="doNotCall" type="checkbox" (ngModelChange)="onDonotCallChange(doNotCall)" [ngModel]="doNotCall">&nbsp;
            <label for="doNotCall" translate="customerDoNotCall"></label>
        </div>

        <div class="wide-tools" *ngIf="permissions.editDoNotContact && isEuroLite != true" [ngClass]="{'display-none': activityActionEnum === activityActionEnums.Log_Call && !showEmail}">
            <input id="doNotEmail" type="checkbox" (ngModelChange)="onDonotEmailChange(doNotEmail)"
                [ngModel]="doNotEmail">&nbsp;
            <label for="doNotEmail" translate="customerDoNotEmail"></label>
        </div>
    </div>
</div>