
export const TradeValueTypes = {
     dealerTradeValue: 0,
     autoAlertTradeValue: 1,
     blackBookTradeValue: 2,
     auctionTradeValue: 3,
     estimateTradeValue: 4,
     galvesTradeValue: 5,
     manualTradeValue: 6
};
