import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.promise.finally";
DataEntryReplacementOfferController.$inject = ["$translate", "$q", "vehicleService", "dealerService", "userService", "dataEntryService", "$uibModal", "$document", "cultures", "environmentVariables", "logger", "$timeout", "modules", "_"];

/* injects from baggage-loader */
require('./data-entry-replacement-offers.component.html');

import angular from 'angular';
import './data-entry-replacement-offers.component.scss';
import BusyIndicator from '../../../utilities/BusyIndicator';
import eyeIconPath from '../../../images/icon-eye-lake.svg';
import $ from 'jquery';
angular.module('AaApp.Opp').component('dataEntryReplacementOffers', {
  templateUrl: '/app/components/data-entry/replacement/data-entry-replacement-offers.component.html',
  controller: DataEntryReplacementOfferController,
  bindings: {
    kendo: '<'
  }
});

function DataEntryReplacementOfferController($translate, $q, vehicleService, dealerService, userService, dataEntryService, $uibModal, $document, cultures, environmentVariables, logger, $timeout, modules, _) {
  "ngInject";

  var $ctrl = this;
  $ctrl.$onInit = init;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.renderAdvancedGrid = renderAdvancedGrid;
  $ctrl.setTrimSelections = setTrimSelections;
  $ctrl.busyIndicator = new BusyIndicator();
  $ctrl.setKendoCulture = setKendoCulture;
  $ctrl.editUpgradeDowngrade = editUpgradeDowngrade;
  $ctrl.editUpgradeDowngradeMultiItems = editUpgradeDowngradeMultiItems;
  $ctrl.editTrimAdvanced = editTrimAdvanced;
  $ctrl.saveUpgradeDowngradeMultiItems = saveUpgradeDowngradeMultiItems;
  $ctrl.createTrimRenderer = createTrimRenderer;
  $ctrl.saveAdvancedModalData = saveAdvancedModalData;
  $ctrl.saveUpDown = saveUpDown;
  $ctrl.loadReplacements = loadReplacements;
  $ctrl.eyeIconPath = eyeIconPath;
  $ctrl.saveModalData = saveModalData;
  $ctrl.loadEyeballList = loadEyeballList;
  $ctrl.setToDefaultsConfirmation = setToDefaultsConfirmation;
  $ctrl.setToDefaults = setToDefaults;
  $ctrl.setReplacementsToDefaultsConfirmation = setReplacementsToDefaultsConfirmation;
  $ctrl.removeMappings = removeMappings;
  $ctrl.removeMappingsConfirmation = removeMappingsConfirmation;
  $ctrl.getTranslations = getTranslations;
  $ctrl.editMultiItemsTrimRenderer = editMultiItemsTrimRenderer;
  $ctrl.editAdvancedRenderer = editAdvancedRenderer;
  $ctrl.editTrimRenderer = editTrimRenderer;
  $ctrl.dealerService = dealerService;
  $ctrl.processUpgradeDowngradeColumns = processUpgradeDowngradeColumns;
  $ctrl.vehicleService = vehicleService;
  $ctrl.environmentVariables = environmentVariables;
  $ctrl.showUpgradesDowngrades = true;
  $ctrl.isFordDealerMakeSelected = false; //$ctrl.isFordAlert = false;

  $ctrl.isFordLincoln = false;
  $ctrl.replacementsMMT;
  $ctrl.advancedMMT;
  $ctrl.cultureName;
  $ctrl.replacementEyeballList = [];
  $ctrl.dataReplacementSpecifics = [];
  $ctrl.dataReplacementAdvanced = [];
  $ctrl.translations = {};

  function init() {
    $ctrl.dealers = dealerService.getSelectedDealers();
    $ctrl.selectedDealerId = $ctrl.dealers[0].id;
    $q.all([userService.getUserProfile(), $ctrl.getTranslations(), $ctrl.dealerService.getDealerSettings($ctrl.selectedDealerId), $ctrl.vehicleService.getMakes($ctrl.selectedDealerId)]).then(function (data) {
      var userProfile = data[0];
      var translations = data[1];
      var dealerSettings = data[2];
      var makes = data[3];
      $ctrl.isFordLincoln = makes.some(function (m) {
        return $ctrl.environmentVariables.fordMakeIds.indexOf(m.makeID) > -1;
      }) || makes.some(function (m) {
        return $ctrl.environmentVariables.lincolnMakeIds.indexOf(m.makeID) > -1;
      }); //$ctrl.isFordAlert = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);

      $ctrl.showUpgradesDowngrades = dealerSettings.enableMultiVehReplTolerances; // && !isFordLincoln; //HARD CODED:  Decision by product that this WILL be hidden for ALL dealerships that sell FORD cars

      $ctrl.processUpgradeDowngradeColumns();
      $ctrl.cultureName = userProfile.cultureName;
      setKendoCulture();
      initGridOptions();
      onDealerSelect();
    });
  }

  function busy(translationKey, promise) {
    //global
    $ctrl.busyMessage = null;
    $translate(translationKey).then(function (translation) {
      $ctrl.busyMessage = translation;
    }, function (err) {
      $ctrl.busyMessage = translationKey;
    });
    var done = $ctrl.busyIndicator.start();
    return promise.finally(done);
  }

  function onDealerSelect(dealerId) {
    if (dealerId > 0) $ctrl.selectedDealerId = dealerId;
    return busy($ctrl.translations.loadingMakeModelTrim, $q.all([vehicleService.getMakes($ctrl.selectedDealerId), vehicleService.getYearIDs($ctrl.selectedDealerId, false), $ctrl.dealerService.getDealerSettings($ctrl.selectedDealerId)]).then(function (data) {
      var makes = data[0];
      var years = data[1];
      $ctrl.isFordLincoln = makes.some(function (m) {
        return $ctrl.environmentVariables.fordMakeIds.indexOf(m.makeID) > -1;
      }) || makes.some(function (m) {
        return $ctrl.environmentVariables.lincolnMakeIds.indexOf(m.makeID) > -1;
      }); //$ctrl.isFordAlert = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);

      $ctrl.showUpgradesDowngrades = data[2].enableMultiVehReplTolerances; // && !isFordLincoln;

      $ctrl.processUpgradeDowngradeColumns();
      $ctrl.selectedMakeId = makes[0].makeID;
      $ctrl.selectedYearId = years[0];
      var currentYear = new Date().getFullYear();
      if ($ctrl.selectedYearId != currentYear) $ctrl.selectedYearId = currentYear;
      $ctrl.replacementsMMT = $ctrl.advancedMMT = {
        vehicleTypes: 0,
        makeID: makes[0].makeID,
        makeName: makes[0].makeName,
        yearID: $ctrl.selectedYearId,
        modelID: null,
        modelName: null,
        trimID: null,
        trimName: null
      };
      return loadReplacements();
    }));
  }

  function renderAdvancedGrid(e) {
    $timeout(function () {
      $ctrl.dsAdvanced.read(); //calling the page event triggers Kendo to recalculate the grid size.
    }, 250);
  }

  function processUpgradeDowngradeColumns() {
    if ($ctrl.ds) {
      if ($ctrl.showUpgradesDowngrades) {
        $("#gridDataEntryReplacementSpecifics").data("kendoGrid").showColumn(1);
      } else {
        $("#gridDataEntryReplacementSpecifics").data("kendoGrid").hideColumn(1);
      }
    }
  }

  function setTrimSelections(selections, tabName) {
    switch (tabName) {
      case "replacements":
        $ctrl.replacementsMMT = selections;
        break;

      case "advanced":
        $ctrl.advancedMMT = selections;
        break;
    }

    return loadReplacements(tabName);
  }

  function saveModalData(modalState) {
    var isChanged = modalState.upgradeChanged || modalState.downgradeChanged;
    if (!isChanged) return;

    if (modalState.upgradeChanged) {
      $ctrl.saveUpDown(modalState, "upgrade");
    }

    if (modalState.downgradeChanged) {
      $ctrl.saveUpDown(modalState, "downgrade");
    }
  }

  function saveAdvancedModalData(modalState) {
    dataEntryService.saveBulkReplacements([{
      replacementID: modalState.row.replacementID,
      dealerID: $ctrl.selectedDealerId,
      oldTrimID: modalState.row.oldTrimID,
      newTrimID: modalState.replacementTrim.trimID
    }], $ctrl.selectedDealerId).then(function () {
      logger.success("changesSaved");
      $ctrl.loadReplacements();
    });
  }

  function confirmationModal(config) {
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return config.title;
        },
        message: function message() {
          return config.message;
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        submitButtonText: function submitButtonText() {
          return "continue";
        },
        closeButtonVisible: function closeButtonVisible() {
          return true;
        },
        closeButtonDisabled: function closeButtonDisabled() {
          return true;
        },
        closeButtonText: function closeButtonText() {
          return "cancel";
        }
      }
    });
    modal.result.then(function () {
      config.doWorkIfConfirmed();
    });
  }

  function setToDefaultsConfirmation(e) {
    confirmationModal({
      title: 'setToDefaultsConfirmation',
      message: 'setToDefaultsExplanation',
      doWorkIfConfirmed: function doWorkIfConfirmed() {
        setToDefaults(e);
      }
    });
  }

  function setReplacementsToDefaultsConfirmation(e) {
    confirmationModal({
      title: 'setToDefaultsConfirmation',
      message: 'setToDefaultsReplacementExplanation',
      doWorkIfConfirmed: function doWorkIfConfirmed() {
        setReplacementsToDefaults(e);
      }
    });
  }

  function removeMappingsConfirmation(e) {
    confirmationModal({
      title: 'removeMappingsConfirmation',
      message: 'removeMappingsMessage',
      doWorkIfConfirmed: function doWorkIfConfirmed() {
        removeMappings(e);
      }
    });
  }

  function setToDefaults(e) {
    if ($ctrl.dataReplacementSpecifics.length < 0) return;
    var trims = [];

    for (var i = 0; i < $ctrl.dataReplacementSpecifics.length; i++) {
      var row = $ctrl.dataReplacementSpecifics[i];
      trims.push(row.baseTrimID);
    }

    dataEntryService.bulkDelete(trims, $ctrl.selectedDealerId, true).then(function () {
      dataEntryService.bulkDelete(trims, $ctrl.selectedDealerId, false).then(function () {
        logger.success("changesSaved");
        $ctrl.loadReplacements();
      });
    });
  }

  function setReplacementsToDefaults(e) {
    if ($ctrl.dataReplacementAdvanced.length < 0) return;
    var trims = [];

    for (var i = 0; i < $ctrl.dataReplacementAdvanced.length; i++) {
      var row = $ctrl.dataReplacementAdvanced[i];
      trims.push(row.oldTrimID);
    }

    dataEntryService.bulkReplacementDelete(trims, $ctrl.selectedDealerId).then(function () {
      logger.success("changesSaved");
      $ctrl.loadReplacements();
    });
  }

  function removeMappings(e) {
    if ($ctrl.dataReplacementSpecifics.length < 0) return;
    var trims = [];

    for (var i = 0; i < $ctrl.dataReplacementSpecifics.length; i++) {
      var row = $ctrl.dataReplacementSpecifics[i];
      trims.push(row.baseTrimID);
    }

    dataEntryService.bulkRemoveMappings(trims, $ctrl.selectedDealerId, true).then(function () {
      dataEntryService.bulkRemoveMappings(trims, $ctrl.selectedDealerId, false).then(function () {
        logger.success("changesSaved");
        $ctrl.loadReplacements();
      });
    });
  }

  function saveUpDown(modalState, upDown) {
    var upgradeInt = upDown == 'upgrade' ? 100 : -100;
    var trimId = modalState[upDown + 'Override'].trimID == undefined ? null : modalState[upDown + 'Override'].trimID;
    if (modalState[upDown + 'Selection'] == 'doNotOffer') trimId = null;

    if (modalState[upDown + 'Selection'] == 'useDefault') {
      dataEntryService.deleteTrimAlternateOffer($ctrl.selectedDealerId, modalState.row.baseTrimID, upgradeInt).then(function () {
        logger.success("changesSaved");
        $ctrl.loadReplacements();
      });
    } else {
      var retailTolerance = upDown == "upgrade" ? modalState.upgradeRetailTolerance : modalState.downgradeRetailTolerance;
      var leaseTolerance = upDown == "upgrade" ? modalState.upgradeLeaseTolerance : modalState.downgradeLeaseTolerance;
      ;

      if (modalState.row[upDown + 'OverrideExists']) {
        dataEntryService.updateTrimAlternateOffer($ctrl.selectedDealerId, modalState.row.baseTrimID, upgradeInt, trimId, retailTolerance, leaseTolerance).then(function () {
          logger.success("changesSaved");
          $ctrl.loadReplacements();
        });
      } else {
        dataEntryService.createTrimAlternateOffer($ctrl.selectedDealerId, modalState.row.baseTrimID, upgradeInt, trimId, retailTolerance, leaseTolerance).then(function () {
          logger.success("changesSaved");
          $ctrl.loadReplacements();
        });
      }
    }
  }

  function editTrimAdvanced(oldTrimID) {
    var row = $ctrl.dsAdvanced.get(oldTrimID);
    $ctrl.editTrimAdvancedModal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'editReplacementsAdvancedModal',
      size: 'lg',
      resolve: {
        title: function title() {
          return $ctrl.translations.replacementTrim;
        },
        message: function message() {
          return {
            row: row,
            selectedDealerId: $ctrl.selectedDealerId,
            save: $ctrl.saveAdvancedModalData
          };
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return true;
        },
        submitButtonText: function submitButtonText() {
          return "submit"; // translation key
        }
      }
    });
  }

  function editUpgradeDowngrade(baseTrimID) {
    var row = $ctrl.ds.get(baseTrimID);
    var renderer = $ctrl.createTrimRenderer("base", true);

    var _title = renderer(row);

    $ctrl.upDownModal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'editUpgradeDowngradeModal',
      size: 'lg',
      resolve: {
        title: function title() {
          return _title;
        },
        message: function message() {
          return {
            row: row,
            selectedDealerId: $ctrl.selectedDealerId,
            save: $ctrl.saveModalData
          };
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        submitButtonText: function submitButtonText() {
          return "submit";
        }
      }
    });
  }

  function saveUpgradeDowngradeMultiItems(modalState) {
    var saveList = [];

    for (var i = 0; i < modalState.replacements.length; i++) {
      var row = modalState.replacements[i];
      row.newTrimID = modalState.replacementTrim.trimID;
      saveList.push(row);
    }

    for (var j = 0; j < modalState.mapToItself.length; j++) {
      var itself = modalState.mapToItself[j];
      itself.newTrimID = itself.oldTrimID;
      saveList.push(itself);
    }

    dataEntryService.saveBulkReplacements(saveList, $ctrl.selectedDealerId).then(function (rows) {
      logger.success("changesSaved");
      $ctrl.loadReplacements();
    });
  }

  function editUpgradeDowngradeMultiItems(baseTrimID) {
    var row = $ctrl.ds.get(baseTrimID);
    dataEntryService.getReplacementSpecifics($ctrl.selectedDealerId, true, row.baseMakeID, row.baseYearID, row.baseModelID, row.baseTrimID).then(function (dataReplacementAdvanced) {
      $ctrl.upDownModal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'editUpgradeDowngradeMultiItemsModal',
        size: 'lg',
        resolve: {
          title: function title() {
            return $ctrl.translations.replacementTrim;
          },
          message: function message() {
            return {
              row: row,
              dataReplacementAdvanced: dataReplacementAdvanced,
              selectedDealerId: $ctrl.selectedDealerId,
              save: $ctrl.saveUpgradeDowngradeMultiItems,
              translations: $ctrl.translations,
              _: _
            };
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return true;
          },
          submitButtonText: function submitButtonText() {
            return "submit"; // translation key
          }
        }
      });
    });
  }

  function initGridOptions() {
    $ctrl.gridOptions = {
      dataSource: $ctrl.ds,
      pageable: true,
      height: 580,
      columns: [{
        title: $ctrl.translations.standardReplacementOffers,
        headerAttributes: {
          "class": "align-center"
        },
        columns: [{
          field: "replacementTrimMappings",
          title: $ctrl.translations.replacementTrimMappings,
          template: $ctrl.createTrimRenderer("base"),
          width: 60,
          sortable: {
            compare: function compare(a, b) {
              var first = a.baseYearID + ", " + a.baseTrimName;
              var second = b.baseYearID + ", " + b.baseTrimName;
              var answer = first < second ? -1 : 1;
              return answer;
            }
          }
        }, {
          field: "editTrimMappings",
          title: $ctrl.translations.editTrimMappings,
          template: $ctrl.editMultiItemsTrimRenderer,
          width: 10,
          sortable: false,
          headerAttributes: {
            "class": "align-center"
          }
        }]
      }, {
        field: "upgradeDowngradeColumns",
        hidden: !$ctrl.showUpgradesDowngrades,
        title: $ctrl.translations.multiVehicleOffers,
        headerAttributes: {
          "class": "align-center"
        },
        columns: [{
          field: "upgradeColumns",
          title: $ctrl.translations.upgrade,
          headerAttributes: {
            "class": "align-center"
          },
          columns: [{
            field: "upgradeTrim",
            title: $ctrl.translations.trim,
            template: $ctrl.createTrimRenderer("upgrade"),
            width: 40,
            sortable: {
              compare: function compare(a, b) {
                var first = a.upgradeOverrideExists ? a.upgradeOverrideTrimName : a.upgradeDefaultTrimName;
                var second = b.upgradeOverrideExists ? b.upgradeOverrideTrimName : b.upgradeDefaultTrimName;
                first = first == null ? first = "ZZZZ" : first;
                second = second == null ? second = "ZZZZ" : second;
                var answer = first < second ? -1 : 1;
                return answer;
              }
            }
          }, {
            field: "upgradeLeaseTolerance",
            title: $ctrl.translations.leaseTolerance,
            headerTemplate: '<span title="Lease Tolerance">Lease Tolerance</span>',
            //template: $ctrl.createTrimRenderer("upgrade"),
            width: 15,
            sortable: false
          }, {
            field: "upgradeRetailTolerance",
            title: $ctrl.translations.retailTolerance,
            headerTemplate: '<span title="Retail Tolerance">Retail Tolerance</span>',
            //template: $ctrl.createTrimRenderer("upgrade"),
            width: 15,
            sortable: false
          }]
        }, {
          field: "downgradeColumns",
          title: $ctrl.translations.downgrade,
          headerAttributes: {
            "class": "align-center"
          },
          columns: [{
            field: "downgradeTrim",
            title: $ctrl.translations.trim,
            template: $ctrl.createTrimRenderer("downgrade"),
            width: 40,
            sortable: {
              compare: function compare(a, b) {
                var first = a.downgradeOverrideExists ? a.downgradeOverrideTrimName : a.downgradeDefaultTrimName;
                var second = b.downgradeOverrideExists ? b.downgradeOverrideTrimName : b.downgradeDefaultTrimName;
                first = first == null ? first = "ZZZZ" : first;
                second = second == null ? second = "ZZZZ" : second;
                var answer = first < second ? -1 : 1;
                return answer;
              }
            }
          }, {
            field: "downgradeLeaseTolerance",
            title: $ctrl.translations.leaseTolerance,
            headerTemplate: '<span title="Lease Tolerance">Lease Tolerance</span>',
            //template: $ctrl.createTrimRenderer("upgrade"),
            width: 15,
            sortable: false
          }, {
            field: "downgradeRetailTolerance",
            title: $ctrl.translations.retailTolerance,
            headerTemplate: '<span title="Retail Tolerance">Retail Tolerance</span>',
            //template: $ctrl.createTrimRenderer("upgrade"),
            width: 15,
            sortable: false
          }]
        }, {
          field: "editUpgradeDowngrade",
          hidden: $ctrl.isFordLincoln,
          title: $ctrl.translations.editUpgradeDowngrade,
          template: $ctrl.editTrimRenderer,
          width: 10,
          sortable: false,
          headerAttributes: {
            "style": "align-center"
          }
        }]
      }],
      editable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      resizable: true,
      reorderable: true,
      filterable: false
    };
    $ctrl.gridOptionsAdvanced = {
      dataSource: $ctrl.dsAdvanced,
      pageable: true,
      height: 580,
      columns: [{
        field: "existingMakeName",
        title: $ctrl.translations.existingYearModelTrimMappings,
        template: $ctrl.createTrimRenderer("existing"),
        width: 60,
        headerAttributes: {
          "class": "align-center"
        },
        sortable: {
          compare: function compare(a, b) {
            var first = a.existingYearID + ", " + a.existingTrimName;
            var second = b.existingYearID + ", " + b.existingTrimName;
            first = first == null ? first = "ZZZZ" : first;
            second = second == null ? second = "ZZZZ" : second;
            var answer = first < second ? -1 : 1;
            return answer;
          }
        }
      }, {
        field: "replacementMakeName",
        title: $ctrl.translations.replacementYearModelTrimMappings,
        template: $ctrl.createTrimRenderer("replacement"),
        width: 60,
        headerAttributes: {
          "class": "align-center"
        },
        sortable: {
          compare: function compare(a, b) {
            var first = a.replacementYearID + ", " + a.replacementTrimName;
            var second = b.replacementYearID + ", " + b.replacementTrimName;
            first = first == null ? first = "ZZZZ" : first;
            second = second == null ? second = "ZZZZ" : second;
            var answer = first < second ? -1 : 1;
            return answer;
          }
        }
      }, {
        field: "editAdvanced",
        title: $ctrl.translations.editAdvanced,
        width: 10,
        template: $ctrl.editAdvancedRenderer,
        headerAttributes: {
          "style": "align-center"
        }
      }],
      editable: false,
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      resizable: true,
      reorderable: true,
      filterable: false
    };
  }

  function loadEyeballList(baseTrimID) {
    var row = $ctrl.ds.get(baseTrimID);
    $ctrl.replacementsEyeballListTrimID = row.baseTrimID;
    if ($ctrl.replacementEyeballList[row.baseTrimID] != null) return;
    dataEntryService.getReplacementSpecifics($ctrl.selectedDealerId, true, row.baseMakeID, row.baseYearID, row.baseModelID, row.baseTrimID).then(function (dataReplacementAdvanced) {
      $ctrl.replacementEyeballList[row.baseTrimID] = dataReplacementAdvanced;
    });
  }

  function createTrimRenderer(baseUpDown, hideEyeball) {
    return function (e) {
      var eyeball = "<div ng-click='$ctrl.loadEyeballList(" + e.baseTrimID + ")' style=\"cursor: pointer; width: 25px; height: 25px; float:right;\" uib-popover-template=\"'/app/components/data-entry/replacement/data-entry-replacement-popover-list.component.html'\" popover-append-to-body='true' popover-placement=\"right-bottom\" popover-trigger=\"'outsideClick'\" popover-popup-close-delay=\"1\" popover-class=\"popover\"><img class='svg-icon xsm' src='" + eyeIconPath + "' /></div>";
      var isBold = false;

      switch (baseUpDown) {
        case "base":
          var trimElement = formatTrim(false, false, e.baseYearID, e.baseTrimName);
          return hideEyeball ? trimElement : trimElement + eyeball;

        case "upgrade":
          if (e.upgradeOverrideExists) return formatTrim(true, true, e.upgradeOverrideYearID, e.upgradeOverrideTrimName);
          return formatTrim(true, false, e.upgradeDefaultYearID, e.upgradeDefaultTrimName);

        case "downgrade":
          if (e.downgradeOverrideExists) return formatTrim(false, true, e.downgradeOverrideYearID, e.downgradeOverrideTrimName);
          return formatTrim(false, false, e.downgradeDefaultYearID, e.downgradeDefaultTrimName);

        case "existing":
          isBold = !e.isDefaultTrim;
          return formatTrim(false, isBold, e.existingYearID, e.existingTrimName);

        case "replacement":
          isBold = !e.isDefaultTrim;
          return formatTrim(false, isBold, e.replacementYearID, e.replacementTrimName);
      }
    };
  }

  function formatTrim(isUpgrade, isBold, yearId, trimName) {
    var start = isBold ? "<div style='font-weight:bold; float:left;'>" : "<div style='float:left'>";

    if (yearId == null) {
      if (isUpgrade) return start + $ctrl.translations.noUpgradeDefined + "</div>";
      return start + $ctrl.translations.noDowngradeDefined + "</div>";
    }

    return start + yearId + ", " + trimName + "</div>";
  }

  function editTrimRenderer(e) {
    var template = "<div align='center'><icon style='cursor: pointer' ng-click='$ctrl.editUpgradeDowngrade(";
    template += e.baseTrimID;
    template += ")'class='icon aa-icon-core-edit-icon-000000'></icon></div>";
    return template;
  }

  function editAdvancedRenderer(e) {
    var template = "<div align='center'><icon style='cursor: pointer' ng-click='$ctrl.editTrimAdvanced(";
    template += e.oldTrimID;
    template += ")'class='icon aa-icon-core-edit-icon-000000'></icon></div>";
    return template;
  }

  function editMultiItemsTrimRenderer(e) {
    var template = "<div align='center'><icon style='cursor: pointer'  ng-click='$ctrl.editUpgradeDowngradeMultiItems(";
    template += e.baseTrimID;
    template += ")'class='icon aa-icon-core-edit-icon-000000'></icon></div>";
    return template;
  }

  function loadReplacements(tabName) {
    $ctrl.replacementEyeballList = [];

    switch (tabName) {
      case "replacements":
        return loadReplacementUpDown();

      case "advanced":
        return loadReplacementAdvanced();

      default:
        loadReplacementUpDown();
        loadReplacementAdvanced();
        break;
    }
  }

  function loadReplacementUpDown() {
    var makeID = $ctrl.replacementsMMT.makeID;
    var yearID = $ctrl.replacementsMMT.yearID;
    var modelID = $ctrl.replacementsMMT.modelID ? $ctrl.replacementsMMT.modelID : 0;
    var trimID = $ctrl.replacementsMMT.trimID ? $ctrl.replacementsMMT.trimID : 0;
    return busy($ctrl.translations.loadingReplacementTabData, dataEntryService.getReplacementUpDown($ctrl.selectedDealerId, makeID, yearID, modelID, trimID).then(function (dataReplacementSpecifics) {
      $ctrl.dataReplacementSpecifics = dataReplacementSpecifics;
      $ctrl.ds = new $ctrl.kendo.data.DataSource({
        data: dataReplacementSpecifics,
        schema: {
          model: {
            id: 'baseTrimID',
            fields: {
              baseTrimID: {
                type: "number",
                editable: false
              },
              baseMakeName: {
                type: "string",
                editable: false
              },
              baseModelName: {
                type: "string",
                editable: false
              },
              baseTrimName: {
                type: "string",
                editable: false
              },
              baseYearID: {
                type: "number",
                editable: false
              },
              upgradeDefaultTrimID: {
                type: "number",
                editable: false
              },
              upgradeDefaultMakeName: {
                type: "string",
                editable: false
              },
              upgradeDefaultModelName: {
                type: "string",
                editable: false
              },
              upgradeDefaultTrimName: {
                type: "string",
                editable: false
              },
              upgradeDefaultYearID: {
                type: "number",
                editable: false
              },
              upgradeOverrideExists: {
                type: "boolean",
                editable: false
              },
              upgradeOverrideMakeID: {
                type: "number",
                editable: false
              },
              upgradeOverrideMakeName: {
                type: "string",
                editable: false
              },
              upgradeOverrideModelID: {
                type: "number",
                editable: false
              },
              upgradeOverrideModelName: {
                type: "string",
                editable: false
              },
              upgradeOverrideTrimID: {
                type: "number",
                editable: false
              },
              upgradeOverrideTrimName: {
                type: "string",
                editable: false
              },
              upgradeOverrideYearID: {
                type: "number",
                editable: false
              },
              upgradeRetailTolerance: {
                type: "number",
                editable: false
              },
              upgradeLeaseTolerance: {
                type: "number",
                editable: false
              },
              downgradeDefaultTrimID: {
                type: "number",
                editable: false
              },
              downgradeDefaultMakeName: {
                type: "string",
                editable: false
              },
              downgradeDefaultModelName: {
                type: "string",
                editable: false
              },
              downgradeDefaultTrimName: {
                type: "string",
                editable: false
              },
              downgradeDefaultYearID: {
                type: "number",
                editable: false
              },
              downgradeOverrideExists: {
                type: "boolean",
                editable: false
              },
              downgradeOverrideMakeID: {
                type: "number",
                editable: false
              },
              downgradeOverrideMakeName: {
                type: "string",
                editable: false
              },
              downgradeOverrideModelID: {
                type: "number",
                editable: false
              },
              downgradeOverrideModelName: {
                type: "string",
                editable: false
              },
              downgradeOverrideTrimID: {
                type: "number",
                editable: false
              },
              downgradeOverrideTrimName: {
                type: "string",
                editable: false
              },
              downgradeOverrideYearID: {
                type: "number",
                editable: false
              },
              downgradeRetailTolerance: {
                type: "number",
                editable: false
              },
              downgradeLeaseTolerance: {
                type: "number",
                editable: false
              },
              upgradeTrimID: {
                type: "number",
                editable: false
              },
              upgradeIsDefault: {
                type: "boolean",
                editable: false
              },
              downgradeTrimID: {
                type: "number",
                editable: false
              },
              downgradeIsDefault: {
                type: "boolean",
                editable: false
              }
            }
          }
        },
        batch: true,
        pageSize: 50
      });
    }));
  }

  function loadReplacementAdvanced() {
    var makeID = $ctrl.advancedMMT.makeID;
    var yearID = $ctrl.advancedMMT.yearID;
    var modelID = $ctrl.advancedMMT.modelID ? $ctrl.advancedMMT.modelID : 0;
    var trimID = $ctrl.advancedMMT.trimID ? $ctrl.advancedMMT.trimID : 0;
    return busy($ctrl.translations.loadingAdvancedTabData, dataEntryService.getReplacementSpecifics($ctrl.selectedDealerId, false, makeID, yearID, modelID, trimID).then(function (dataReplacementAdvanced) {
      _.forEach(dataReplacementAdvanced, function (item) {
        return item.id = item.oldTrimID;
      });

      $ctrl.dataReplacementAdvanced = dataReplacementAdvanced;
      $ctrl.dsAdvanced = new $ctrl.kendo.data.DataSource({
        data: dataReplacementAdvanced,
        schema: {
          model: {
            fields: {
              id: {
                type: "number",
                editable: false
              },
              isDefaultTrim: {
                type: "boolean",
                editable: false
              },
              existingMakeName: {
                type: "string",
                editable: false
              },
              existingModelName: {
                type: "string",
                editable: false
              },
              existingTrimName: {
                type: "string",
                editable: false
              },
              existingYearID: {
                type: "number",
                editable: false
              },
              replacementMakeName: {
                type: "string",
                editable: false
              },
              replacementModelName: {
                type: "string",
                editable: false
              },
              replacementTrimName: {
                type: "string",
                editable: false
              },
              replacementYearID: {
                type: "number",
                editable: false
              }
            }
          }
        },
        batch: true,
        pageSize: 50
      });
    }));
  }

  function setKendoCulture() {
    switch ($ctrl.cultureName) {
      case cultures.enUS:
      case cultures.enGB:
      case cultures.enCA:
      case cultures.frCA:
      case cultures.frFR:
      case cultures.esUS:
        $ctrl.kendo.culture($ctrl.cultureName);
        break;

      default:
        $ctrl.kendo.culture(cultures.enUS);
        break;
    }
  }

  function getTranslations() {
    return $q.all({
      'loadingMakeModelTrim': $translate('loadingMakeModelTrim'),
      'loadingAdvancedTabData': $translate('loadingAdvancedTabData'),
      'replacementTrim': $translate('replacementTrim'),
      'standardReplacementOffers': $translate('standardReplacementOffers'),
      'replacementTrimMappings': $translate('replacementTrimMappings'),
      'editTrimMappings': $translate('editTrimMappings'),
      'multiVehicleOffers': $translate('multiVehicleOffers'),
      'upgradeTrim': $translate('upgradeTrim'),
      'downgradeTrim': $translate('downgradeTrim'),
      'editUpgradeDowngrade': $translate('editUpgradeDowngrade'),
      'existingYearModelTrimMappings': $translate('existingYearModelTrimMappings'),
      'replacementYearModelTrimMappings': $translate('replacementYearModelTrimMappings'),
      'editAdvanced': $translate('editAdvanced'),
      'noUpgradeDefined': $translate('noUpgradeDefined'),
      'noDowngradeDefined': $translate('noDowngradeDefined'),
      'loadingReplacementTabData': $translate('loadingReplacementTabData'),
      'previewChanges': $translate('previewChanges'),
      'leaseTolerance': $translate('leaseTolerance'),
      'retailTolerance': $translate('retailTolerance'),
      'trim': $translate('trim'),
      'upgrade': $translate('upgrade'),
      'downgrade': $translate('downgrade')
    }).then(function (translations) {
      $ctrl.translations = translations;
    });
  }
}