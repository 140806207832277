import angular from 'angular';

angular.module('AaApp.Opp').constant('reportsMenu', [
    {
        "id": "dashboardReport",
        "moduleId": 78,
        "displayName": "dashboardReport",
        "authorize": true,
        "url": "/dashboard",
        "beta": true,
        "betaOnly": true,
        "category": null
    },
    {
        "id": "performanceReport",
        "moduleId": 78,
        "displayName": "performanceReport",
        "authorize": true,
        "url": "/performance",
        "beta": true,
        "betaOnly": true,
        "category": 6
    },
    {
        "id": "summaryReport",
        "moduleId": 28,
        "displayName": "summaryReport",
        "authorize": true,
        "url": "/summary",
        "beta": false
    },
    {
        "id": "certificationReport",
        "moduleId": 40,
        "displayName": "certificationReport",
        "authorize": true,
        "url": "/certification",
        "beta": true,
        "betaKey": "certificationAndTrainingReport",
        "category": 1
    },
    {
        "id": "userActivityReport",
        "moduleId": 12,
        "displayName": "userActivityReport",
        "authorize": true,
        "url": "/user-activity",
        "beta": true,
        "category": 1
    },
    {
        "id": "soldUnitsReport",
        "moduleId": 13,
        "displayName": "soldUnitsReport",
        "authorize": true,
        "url": "/sold-units",
        "beta": true,
        "category": 1
    },
    {
        "id": "salesGrossReport",
        "moduleId": 34,
        "displayName": "salesGrossReport",
        "authorize": true,
        "url": "/sales-gross",
        "beta": true,
        "betaOnly": true,
        "category": 1
    },
    {
        "id": "serviceDriveSalesPerformanceReport",
        "moduleId": 53,
        "displayName": "serviceDriveSalesPerformanceReport",
        "authorize": true,
        "url": "/service-drive-sales-performance",
        "beta": true,
        "betaOnly": true,
        "category": 1
    },
    {
        "id": "creditConvertReport",
        "moduleId": 48,
        "displayName": "creditConvertReport",
        "authorize": true,
        "url": "/credit-convert",
        "beta": true,
        "category": 1
    },
    {
        "id": "dataCleanseReport",
        "moduleId": 85,
        "displayName": "dataCleanseReport",
        "authorize": true,
        "url": "/dataCleanse",
        "beta": true,
        "category": 1
    },
    {
        "id": "inventoryReport",
        "moduleId": 37,
        "displayName": "inventoryReport",
        "authorize": true,
        "url": "/inventory",
        "beta": true,
        "category": 1
    },
    {
        "id": "soldUnitsDetailReport",
        "moduleId": 34,
        "displayName": "soldUnitsDetailReport",
        "authorize": true,
        "url": "/sales-roi",
        "beta": false
    },
    {
        "id": "expressRoiReport",
        "moduleId": 54,
        "displayName": "expressRoiReport",
        "authorize": true,
        "url": "/express-roi",
        "beta": false
    },
    {
        "id": "tradeInsReport",
        "moduleId": 38,
        "displayName": "tradeInsReport",
        "authorize": true,
        "url": "/trade-ins",
        "beta": false
    },
    {
        "id": "activityReport",
        "moduleId": 11,
        "displayName": "activityReport",
        "authorize": true,
        "url": "/activity",
        "beta": false
    },
    {
        "id": "emailTemplateReport",
        "moduleId": 47,
        "displayName": "emailTemplateReport",
        "authorize": true,
        "url": "/template-email",
        "beta": false
    },
    {
        "id": "opportunitiesReport",
        "moduleId": 10,
        "displayName": "opportunitiesReport",
        "authorize": true,
        "url": "/opportunities",
        "beta": false
    },
    {
        "id": "webinarAttendanceReport",
        "moduleId": 45,
        "displayName": "webinarAttendanceReport",
        "authorize": true,
        "url": "/webinar-attendance",
        "beta": false
    },
    {
        "id": "vinScanUtilizationReport",
        "moduleId": 53,
        "displayName": "vinScanUtilizationReport",
        "authorize": true,
        "url": "/vin-scan-utilization",
        "beta": false
    },
    {
        "id": "autoAssistantSoldUnitsReport",
        "moduleId": 92,
        "displayName": "autoAssistantSoldUnitsReport",
        "authorize": true,
        "url": "/autoAssistantSoldUnits",
        "beta": true,
        "category": 7
    },
    {
        "id": "autoAssistantActivityReport",
        "moduleId": 94,
        "displayName": "autoAssistantActivityReport",
        "authorize": true,
        "url": "/autoAssistantActivity",
        "beta": true,
        "category": 7
    },
    {
        "id": "geoAlertSoldUnitsReport",
        "moduleId": 91,
        "displayName": "geoAlertSoldUnitsReport",
        "authorize": true,
        "url": "/geoalert",
        "beta": true,
        "category": 8
    },
    {
        "id": "onetoOneSoldUnitsReport",
        "moduleId": 86,
        "displayName": "onetoOneSoldUnitsReport",
        "authorize": true,
        "url": "/one-to-one-sold-units",
        "beta": true,
        "category": 5
    },
    {
        "id": "pandoUserActivityReport",
        "moduleId": 81,
        "displayName": "pandoUserActivityReport",
        "authorize": true,
        "url": "/pando-activity",
        "beta": true,
        "betaOnly": true,
        "category": 4
    },
    {
        "id": "pandoSurveyReport",
        "displayName": "pandoSurveyReport",
        "authorize": true,
        "url": "/pando-surveys",
        "beta": true,
        "betaOnly": true,
        "category": 4
    },
    {
        "id": "partnerExportSoldUnitsReport",
        "moduleId": 96,
        "displayName": "partnerExportSoldUnitsReport",
        "authorize": true,
        "url": "/partner-export-sold-units",
        "beta": true,
        "category": 10
    },
]);
