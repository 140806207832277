"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var modal_1 = require("ngx-bootstrap/modal");
var toaster_logger_service_1 = require("../../../../core/toaster-logger.service");
var vauto_modal_component_1 = require("../vauto-modal/vauto-modal.component");
var ngx_modal_wrapper_service_1 = require("../../../../services/ngx-modal-wrapper.service");
var VautoComponent = /** @class */ (function () {
    function VautoComponent(modalService, tService, logger, vehicleService, dealSheetService) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.vehicleService = vehicleService;
        this.dealSheetService = dealSheetService;
    }
    VautoComponent.prototype.ngOnInit = function () {
    };
    VautoComponent.prototype.ngOnChanges = function (changes) {
    };
    VautoComponent.prototype.vautoModal = function () {
        var _this = this;
        var initialState = {
            dealerId: this.dealerId,
            customer: this.customer,
            vehicle: this.vehicle
        };
        this.bsModalRef = this.modalService.show(vauto_modal_component_1.VautoModalComponent, { initialState: initialState, class: 'vauto-modal' });
        this.bsModalRef.content.onSaveChanges.subscribe(function (result) {
            var request = {
                dealerId: _this.dealerId,
                vin: _this.vehicle.vin,
                make: _this.vehicle.makeName,
                model: _this.vehicle.modelName,
                year: _this.vehicle.yearID,
                mileage: _this.vehicle.estimatedCurrentMileage,
                firstName: _this.customer.firstName,
                lastName: _this.customer.lastName,
                postalCode: _this.customer.address.zipCode
            };
            _this.dealSheetService.vAutoCreateAppraisal(request)
                .then(function (data) {
                if (data && data.appraisalID) {
                    _this.logger.success("vAuto appraisal successfully created with appraisal id: " + data.appraisalID);
                    _this.showAppraisalPopup(data);
                }
                else {
                    _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                }
            });
        });
    };
    VautoComponent.prototype.showAppraisalPopup = function (data) {
        console.log('Appraisial ID: ' + data.appraisalID + ' vAuto url: ' + data.deepLink);
        window.open(data.deepLink, '_blank');
    };
    return VautoComponent;
}());
exports.VautoComponent = VautoComponent;
