financialsService.$inject = ["$q", "coreApi", "$filter", "dealerService"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('financialsService', financialsService);
/* @ngInject */

function financialsService($q, coreApi, $filter, dealerService) {
  var service = {
    getConvertInfo: getConvertInfo,
    getBankSelect: getBankSelect,
    getConvertSaleTypes: getConvertSaleTypes,
    getFinancialsData: getFinancialsData,
    getUsers: getUsers,
    submitConvertInfo: submitConvertInfo
  };
  return service;

  function getFinancialsData(entityId, dealerId) {
    var deferred = $q.defer();
    $q.all([service.getConvertInfo(entityId), service.getBankSelect(dealerId), service.getConvertSaleTypes(), service.getUsers(dealerId)]).then(resolveGetFinancialsData(deferred)).catch(function (ex) {
      deferred.reject(ex);
    });
    return deferred.promise;
  }

  function resolveGetFinancialsData(deferred) {
    return function (data) {
      var financials = {
        convert: data[0],
        banks: data[1],
        saleOptions: data[2],
        users: data[3]
      };
      deferred.resolve(financials);
    };
  }

  function getConvertInfo(entityId) {
    var deferred = $q.defer();
    coreApi.api.Convert.GetConvertInfo['{entityId}'].GET({
      entityId: entityId
    }).then(resolveGetConvertInfo(deferred)).catch(function (ex) {
      deferred.reject(ex);
    });
    return deferred.promise;
  }

  function resolveGetConvertInfo(deferred) {
    return function (response) {
      var convert = response.data;

      if (convert.selectedBank && convert.selectedBank.type !== undefined && convert.selectedBank.type !== null) {
        convert.selectedBank.typeName = $filter('translate')('bankSelectType' + convert.selectedBank.type);
      }

      deferred.resolve(convert);
    };
  }

  function getBankSelect(dealerId) {
    var deferred = $q.defer();
    coreApi.api.Bank.GetBankSelect['{dealerId}'].GET({
      dealerId: dealerId
    }).then(resolveGetBankSelect(deferred)).catch(function (ex) {
      deferred.reject(ex);
    });
    return deferred.promise;
  }

  function resolveGetBankSelect(deferred) {
    return function (response) {
      var banks = response.data;

      for (var i = 0; i < banks.length; i++) {
        banks[i].typeName = $filter('translate')('bankSelectType' + banks[i].type);
      }

      deferred.resolve(banks);
    };
  }

  function getConvertSaleTypes() {
    var deferred = $q.defer();
    coreApi.api.Convert.GetConvertSaleTypes.GET().then(resolveGetConvertSaleTypes(deferred)).catch(function (ex) {
      deferred.reject(ex);
    });
    return deferred.promise;
  }

  function resolveGetConvertSaleTypes(deferred) {
    return function (response) {
      deferred.resolve(response.data);
    };
  }

  function getUsers(dealerId) {
    var deferred = $q.defer();
    dealerService.getEnabledUsersForDealer(dealerId).then(resolveGetUsers(deferred)).catch(function (ex) {
      deferred.reject(ex);
    });
    return deferred.promise;
  }

  function resolveGetUsers(deferred) {
    return function (response) {
      var users = [];

      for (var i = 0; i < response.length; i++) {
        var user = {
          id: response[i].userID,
          fullName: response[i].fullName
        };
        users.push(user);
      }

      deferred.resolve(users);
    };
  }

  function submitConvertInfo(convert) {
    var deferred = $q.defer();
    coreApi.api.Convert.PostConvertInfo.POST(convert).then(resolveSubmitConvertInfo(deferred)).catch(function (ex) {
      deferred.reject(ex);
    });
    return deferred.promise;
  }

  function resolveSubmitConvertInfo(deferred) {
    return function (response) {
      deferred.resolve(response);
    };
  }
}