
/* injects from baggage-loader */
require('./customer-phone.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('customerPhone', {
    templateUrl: '/app/components/deal-sheet/customer-phone.component.html',
    controller: CustomerPhoneController,
    bindings: {
        phone: '<',
        dealerSettings: '<',
        doNotCall: '<'
    }
});

/* @ngInject */
function CustomerPhoneController(doNotCallStatusTypes) {
    var $ctrl = this;
    
    $ctrl.doNotCallStatusTypes = doNotCallStatusTypes;
    $ctrl.doNotCallStatusTranslateKeys;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.doNotCallStatusTranslateKeys = {};
        $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.notProcessed] = 'ndncNotProcessedAbbrev';
        $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.invalidNumber] = 'ndncInvalidNumberAbbrev';
        $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.notSubscribed] = 'ndncNotSubscribedAbbrev';
        $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.onDoNotCallList] = 'ndncOnDoNotCallListAbbrev';
        $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.offDoNotCallList] = 'ndncOffDoNotCallListAbbrev';
    }
}
