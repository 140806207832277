import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { SearchPresetService } from '../../../services/search-preset.service';
import { UserService } from '../../../services/user.service';
import { SearchPresetTypes } from '../../../constants';
import _ from 'lodash';
import { UserSearchCondition } from '../../../interfaces/user-search-condition.interface';
import { Subscription, from } from 'rxjs';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'search-presets',
  templateUrl: './search-presets.component-ng.html',
  styleUrls: ['./search-presets.component.scss']
})

export class SearchPresetsComponent implements OnInit, OnDestroy {
    @Input() visible: boolean = false;
    @Output() visibleChange = new EventEmitter<boolean>();
    public isSltUser: boolean = false;
    public searchPresets: UserSearchCondition[] = [];
    public userPresets: UserSearchCondition[] = [];
    public dealerPresets: UserSearchCondition[] = [];
    public commonPresets: UserSearchCondition[] = [];
    public busy: Subscription;

    private searchPresetTypes = SearchPresetTypes;
    private subscriptionAggregator = new Subscription();

    constructor(private searchPresetService: SearchPresetService,
        private userService: UserService,
        private stateService: StateService) {
    }

    public ngOnInit(): void {
        this.userService.getUserProfile()
            .then((userProfile) => this.isSltUser = userProfile.isSltUser);
    }

    ngOnDestroy(): void {
        this.subscriptionAggregator.unsubscribe();
    }

    public toggleSearchPresets(): void {
        this.visible = !this.visible;

        if (this.visible) {
            this.busy = this.getSearchPresets();
            this.subscriptionAggregator.add(this.busy);
        }
        this.visibleChange.emit(this.visible);
    }

    getSearchPresets(): Subscription {
        return from(this.searchPresetService.getSearchPresets())
            .subscribe(searchPresetData => {
                this.setSearchPresets(searchPresetData);
            });
    }

    private setSearchPresets(searchPresetData) {
        this.searchPresets = searchPresetData.sort(this.byDescSearchConditionID);
        this.userPresets = this.searchPresets.filter(this.byGroupType(this.searchPresetTypes.user));
        this.dealerPresets = _.uniqBy(this.searchPresets.filter(this.byGroupType(this.searchPresetTypes.dealer)), 'userSearchConditionID');
        this.commonPresets = this.searchPresets.filter(this.byGroupType(this.searchPresetTypes.common));
    }

    private byGroupType(groupType) {
        return function (searchPreset) {
            return searchPreset.userSearchConditionGroupType === groupType;
        };
    }

    private byDescSearchConditionID(item1, item2) {
        if (item1.userSearchConditionID > item2.userSearchConditionID)
            return -1;
        if (item1.userSearchConditionID < item2.userSearchConditionID)
            return 1;
        return 0;
    }

    public navigate(presetId): void {
        this.visible = false;

        if (this.stateService.params.presetId == presetId && !this.stateService.params.recentActivity) {
            this.stateService.reload();
        }
        else {
            this.stateService.go('search.form', { presetId: presetId, recentActivity: null }, { reload: 'search.form', inherit: false });
        }
    }

}
