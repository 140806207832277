CreditSoftPullResultModalController.$inject = ["_", "creditTiers", "$window"];

/* injects from baggage-loader */
require('./credit-soft-pull-result-modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('creditSoftPullResultModal', {
  templateUrl: '/app/components/deal-sheet/modals/credit-soft-pull-result-modal.component.html',
  controller: CreditSoftPullResultModalController,
  bindings: {
    close: '&',
    dismiss: '&',
    resolve: '<'
  }
});
/* @ngInject */

function CreditSoftPullResultModalController(_, creditTiers, $window) {
  var $ctrl = this;
  $ctrl.creditTierTranslateKeys;
  $ctrl.$onInit = init;
  $ctrl.getCreditReport = getCreditReport;

  function init() {
    $ctrl.creditTierTranslateKeys = _.invert(creditTiers);
  }

  function getCreditReport() {
    var wnd = $window.open("about:blank", "_blank");
    wnd.document.write($ctrl.resolve.value.customReportHTML);
  }
}