
/* injects from baggage-loader */
require('./auto-assigned-access.component.html');

import angular from 'angular';


angular.module('AaApp.Opp').component('autoAssignedAccess', {
    templateUrl: '/app/components/deal-sheet/auto-assigned-access.component.html',
    controller: AutoAssignedAccessController,
    bindings: {
        autoAssignAccessInfo:'<',
    }
});

/* @ngInject */
function AutoAssignedAccessController() {
    var $ctrl = this;
    $ctrl.newLineSeparator = "\n";
    $ctrl.dateSeparator = ": ";
}