"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
/**
* Icons can come from two sources:
*  1. Part of the Fontastic icon font
*  2. An individual SVG file
*
* We were using a previously-undefined element <icon> and applying the classes
* "icon aa-icons-foobar" to it to create icons:
*
*     <icon class="icon aa-icon-circle"></icon>
*
* To support SVG icons, we've created now defined the icon component. If you
* add the 'icon' binding, we will look for an SVG with that name:
*
*     <icon icon="'circle'"></icon>
*
* The old syntax still works because the template is empty if $ctrl.icon is
* not provided.
*
*/
var IconComponent = /** @class */ (function () {
    function IconComponent(sanitizer) {
        // ICONS is a hash of filenames mapped to their contents. The 'icon'
        // binding is the key name in this hash.
        //
        // We have to specify svg-inline-loader inline instead of adding a rule in
        // webpack.config.js because we have some <img> tags depending on the old
        // behavior. Once those img tags are removed, we can add
        // `{ test: /\.svg$/, use: 'svg-inline-loader' }` to webpack.config.js and
        // remove the loader syntax here.
        //
        // For syntax, see: https://webpack.js.org/concepts/loaders/#inline
        this.ICONS = {
            'close': require('!svg-inline-loader!../../images/icons/close.svg'),
            'engagement': require('!svg-inline-loader!../../images/icons/engagement.svg'),
            'hot-flame': require('!svg-inline-loader!../../images/icons/hot-flame.svg'),
            'o2o-offer': require('!svg-inline-loader!../../images/icons/o2o-offer.svg'),
            'pando': require('!svg-inline-loader!../../images/icons/pando.svg'),
            'pando-outline': require('!svg-inline-loader!../../images/icons/pando-outline.svg'),
            'pando-offer-email': require('!svg-inline-loader!../../images/icons/pando-offer-icon.svg'),
            'web': require('!svg-inline-loader!../../images/icons/web.svg'),
            'pando-settings': require('!svg-inline-loader!../../images/icons/icon-Pando-Settings.svg'),
            'common-search': require('!svg-inline-loader!../../images/icons/icon-saved-search.svg'),
            'dealer-search': require('!svg-inline-loader!../../images/icons/icon-store-chooser.svg'),
            'tag-search': require('!svg-inline-loader!../../images/icons/icon-tag.svg'),
            'rating-1star': require('!svg-inline-loader!../../images/icons/rating-1star.svg'),
            'rating-2star': require('!svg-inline-loader!../../images/icons/rating-2star.svg'),
            'rating-3star': require('!svg-inline-loader!../../images/icons/rating-3star.svg'),
            'rating-4star': require('!svg-inline-loader!../../images/icons/rating-4star.svg'),
            'rating-5star': require('!svg-inline-loader!../../images/icons/rating-5star.svg'),
            'reviews': require('!svg-inline-loader!../../images/icons/reviews.svg'),
            'auto-assign': require('!svg-inline-loader!../../images/icons/icon-user-review-vanilla.svg'),
            'clipboard-assigned': require('!svg-inline-loader!../../images/icons/auto-assigned.svg'),
            'trash': require('!svg-inline-loader!../../images/icons/icon-trash-vanilla.svg'),
            'share': require('!svg-inline-loader!../../images/icons/icon-invite-user-vanilla.svg'),
            'unshare': require('!svg-inline-loader!../../images/icons/icon-unshare-search-vanilla.svg'),
            'info': require('!svg-inline-loader!../../images/icons/icon-information.svg'),
            'email-sms': require('!svg-inline-loader!../../images/icons/icon-messaging-email-sms.svg'),
            'sms': require('!svg-inline-loader!../../images/icons/icon-sms.svg'),
            'geoalert': require('!svg-inline-loader!../../images/icons/icon-geoalert.svg'),
            'auto-assistant': require('!svg-inline-loader!../../images/icons/icon-auto-assistant.svg'),
            'hot-trade': require('!svg-inline-loader!../../images/icons/icon-hot-flame-apple.svg'),
            'o2o-im-filter': require('!svg-inline-loader!../../images/icons/icon-marketing-mailer-lake.svg'),
            'im-mail-sent': require('!svg-inline-loader!../../images/icons/icon-im-offer-lake.svg'),
            'im-email-events': require('!svg-inline-loader!../../images/icons/icon-im-offer-arrow-lake.svg'),
            'wish-list': require('!svg-inline-loader!../../images/icons/icon-wish-list.svg'),
            'info-i': require('!svg-inline-loader!../../images/icons/icon-info-i-lake.svg'),
            'info-warn': require('!svg-inline-loader!../../images/icons/icon-info-warning-apple.svg'),
            'nlo': require('!svg-inline-loader!../../images/icons/icon-nlo-vanilla.svg'),
            'ai-manager': require('!svg-inline-loader!../../images/icons/icon-ai-manager-vanilla.svg'),
        };
        // Go back through every icon and replace it with a trusted version so we
        // can ngBindHtml it.
        for (var _i = 0, _a = Object.entries(this.ICONS); _i < _a.length; _i++) {
            var _b = _a[_i], name_1 = _b[0], svgHtml = _b[1];
            this.ICONS[name_1] = sanitizer.bypassSecurityTrustHtml(svgHtml);
        }
    }
    IconComponent.prototype.ngOnInit = function () {
    };
    IconComponent.prototype.ngOnChanges = function (changes) {
        // Replace tick in property name. Previous angular version required the ticks. Doing this hack to avoid changing every single binding
        if (changes.icon && changes.icon.currentValue) {
            this.icon = changes.icon.currentValue.replace(/'/g, '');
        }
    };
    return IconComponent;
}());
exports.IconComponent = IconComponent;
