"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var StockNumberSearchService = /** @class */ (function () {
    function StockNumberSearchService(inventoryClientService) {
        this.inventoryClientService = inventoryClientService;
    }
    StockNumberSearchService.prototype.getProcessQueryString = function (quickSearchQuery) {
        var queryType = {
            Query: quickSearchQuery,
            IsVIN: true,
            IsPhone: true,
            IsName: true,
        };
        queryType = this.getQueryType(queryType);
        return queryType;
    };
    StockNumberSearchService.prototype.getQueryType = function (qtype) {
        // ( ) - signs Phone
        if (qtype.Query.indexOf('(') > -1 || qtype.Query.indexOf(')') > -1) {
            qtype.IsVIN = false;
            qtype.IsName = false;
            qtype.Query = this.cleanQueryString(qtype.Query);
            return qtype;
        }
        // '-' can be in name or in phone number
        if (qtype.Query.indexOf('-') > -1) {
            // Phone should not contain any letter characters
            if (!/[a-zA-Z]/.test(qtype.Query)) {
                qtype.IsVIN = false;
                qtype.IsName = false;
                qtype.Query = this.cleanQueryString(qtype.Query);
                return qtype;
            }
        }
        // clean spaces
        qtype.Query = this.cleanQueryString(qtype.Query);
        var HasSpaces = qtype.Query.includes(' ');
        if (HasSpaces) {
            qtype.IsVIN = false;
        }
        if (/[o,O,i,I,q,Q]/.test(qtype.Query)) {
            qtype.IsVIN = false; // VIN cannot contain o,q or i
            qtype.IsPhone = false; // literals - no phone
            return qtype;
        }
        // phone or vin or name or vrn left        
        var HasLetters = /[a-zA-Z]/.test(qtype.Query);
        var HasNumbers = /[0-9]/.test(qtype.Query);
        // tests
        if (HasLetters)
            qtype.IsPhone = false;
        else
            qtype.IsName = false;
        if (!HasNumbers)
            qtype.IsVIN = false;
        // require at least 8 last of the vin
        if (qtype.Query.length < 8 || qtype.Query.length > 17)
            qtype.IsVIN = false;
        if (qtype.Query.length > 8 && !HasLetters)
            qtype.IsVIN = false;
        // vin has last 5 digits        
        if (!/[0-9]{5}/.test(qtype.Query))
            qtype.IsVIN = false;
        // vin and phone - decide by length
        if (qtype.IsVIN && qtype.IsPhone && qtype.Query.length == 8)
            qtype.IsPhone = false;
        // name or vin undecided - default to VIN
        if (qtype.IsVIN && qtype.IsName) {
            qtype.IsName = false;
        }
        return qtype;
    };
    StockNumberSearchService.prototype.cleanQueryString = function (query) {
        query = query.trim().replace('(', ' ').replace(')', ' ').replace('-', ' ');
        // remove double spaces
        while (query.indexOf("  ") > -1) {
            query = query.replace("  ", " ");
        }
        return query;
    };
    StockNumberSearchService.prototype.getStockList = function () {
        return this.inventoryClientService.GetStockViewsGET().toPromise();
    };
    return StockNumberSearchService;
}());
exports.StockNumberSearchService = StockNumberSearchService;
