import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ActivitySubTypes } from '../../../constants';

@Component({
    selector: 'type-of-call',
    templateUrl: './type-of-call.component-ng.html',
    styleUrls: ['./type-of-call.component.scss']
})
export class TypeOfCallComponent implements OnInit{

    @Output() onBlur = new EventEmitter<any>();
    @Output() onTypeSelect = new EventEmitter<any>();

    public activitySubTypes = ActivitySubTypes;
    public selectedCallType: string;

    constructor() {
        
    }

    ngOnInit() {
        this.selectedCallType = this.activitySubTypes.outBoundCall.toString();
        this.onTypeSelect.emit(this.selectedCallType); // send default choice to parent       
    }

    public onSelectionChange(data: string) {
        this.onTypeSelect.emit(data);
    }
}
