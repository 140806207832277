export const DiscrepancyTypes = {
    tradeValue: 1,
    payoffAmount: 2,
    replacementVehicle: 3,
    balloonRate: 4,
    balloonFactoryRebate: 5,
    balloonDealerRebate: 6,
    retailRate: 7,
    retailFactoryRebate: 8,
    retailDealerRebate: 9,
    leaseRate: 10,
    leaseFactoryRebate: 11,
    leaseDealerRebate: 12,
    other: 13
}