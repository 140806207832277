"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./admin-templates-edit-modal.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../../filters/safe.pipe"));
var i3 = __importStar(require("@angular/platform-browser"));
var i4 = __importStar(require("@ngx-translate/core"));
var i5 = __importStar(require("../../icon/icon.component.ngfactory"));
var i6 = __importStar(require("../../icon/icon.component"));
var i7 = __importStar(require("./admin-templates-edit-modal.component"));
var i8 = __importStar(require("ngx-bootstrap/modal"));
var styles_AdminTemplatesEditModalComponent = [i0.styles];
var RenderType_AdminTemplatesEditModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminTemplatesEditModalComponent, data: {} });
exports.RenderType_AdminTemplatesEditModalComponent = RenderType_AdminTemplatesEditModalComponent;
function View_AdminTemplatesEditModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "admin-templates-edit-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["style", "float:left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"], ["translate", "editTemplate"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["style", "float:right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "icon", [["class", "icon aa-icon-close-icon"]], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i1.ɵdid(9, 638976, null, 0, i6.IconComponent, [i3.DomSanitizer], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "iframe", [["class", "ifrmContainer"], ["style", "border: none;width: 100%;height: 680px"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["translate", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "editTemplate"; _ck(_v, 5, 0, currVal_0); _ck(_v, 9, 0); var currVal_2 = "close"; _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.url)); _ck(_v, 12, 0, currVal_1); }); }
exports.View_AdminTemplatesEditModalComponent_0 = View_AdminTemplatesEditModalComponent_0;
function View_AdminTemplatesEditModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "admin-templates-edit-modal", [], null, null, null, View_AdminTemplatesEditModalComponent_0, RenderType_AdminTemplatesEditModalComponent)), i1.ɵdid(1, 4308992, null, 0, i7.AdminTemplatesEditModalComponent, [i8.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AdminTemplatesEditModalComponent_Host_0 = View_AdminTemplatesEditModalComponent_Host_0;
var AdminTemplatesEditModalComponentNgFactory = i1.ɵccf("admin-templates-edit-modal", i7.AdminTemplatesEditModalComponent, View_AdminTemplatesEditModalComponent_Host_0, { id: "id", dealerId: "dealerId" }, { saveChanges: "saveChanges" }, []);
exports.AdminTemplatesEditModalComponentNgFactory = AdminTemplatesEditModalComponentNgFactory;
