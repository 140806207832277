import { DecimalPipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import { StorageService } from '../modules/storage/storage.service';

@Pipe({
    name: 'localeNumber'
})
export class LocaleNumberPipe extends DecimalPipe implements PipeTransform {

    constructor(private storageService: StorageService) {
        super('en-us');
    }

    transform(number: any, fractionDigits?: string) {

        if (this.isNumber(number)) {
            number = parseFloat(number);

            const savedCultureName = this.storageService.getItem('cultureName');
            const cultureName = savedCultureName ? savedCultureName : 'en-us';

            if (fractionDigits != null) {
                return super.transform(number, fractionDigits, cultureName);
            } else {
                return super.transform(number);
            }
        } else {
            return number;
        }
    }

    isNumber(obj: any) {
        return !isNaN(parseFloat(obj));
    }
}
