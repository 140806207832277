import { Inject, Injectable } from '@angular/core';
import { DealerSettingClientService,  ModelClientService, TrimClientService } from '../generated/services';
import { DealerSettingRequestDto, DealerSettingSendTestAdfRequest, ModelDto, TrimDto, DealerSettingResponseDto } from '../generated/models';
import { LegacyService } from '../ajs-upgraded-providers';
 
@Injectable()
export class DealerSettingsService {
    constructor(@Inject(LegacyService) private legacyService,
        private dsClientService: DealerSettingClientService,
        private modelClientService: ModelClientService,
        private trimClientService: TrimClientService) {
    }

    GetDealerSettingAll(dealerId: number): Promise<DealerSettingResponseDto> {
        var result = this.dsClientService.GetDealerSettingAllByDealeridGET(dealerId).toPromise();
        return result;
    }

    UpdateDealerSettingAll(dsa: DealerSettingRequestDto): Promise<number> {
        var result = this.dsClientService.UpdateDealerSettingAllPOST(dsa).toPromise();
        return result;
    }

    GetTrim(trimId: number): Promise<TrimDto> {
        return this.trimClientService.ByIdGET(trimId).toPromise();
    }

    GetTrims(modelId: number): Promise<null> {
        return this.trimClientService.GetTrimsByModelsPOST([modelId]).toPromise();
    }

    GetModel(modelId: number): Promise<ModelDto> {
        return this.modelClientService.ByIdGET(modelId).toPromise();
    }

    GetModels(makeId: number): Promise<ModelDto[]> {
        return this.modelClientService.GetModelsByMakeByMakeidGET(makeId).toPromise();
    }

    GetModelsByMakeYear(makeId: number, year: number): Promise<ModelDto[]> {
        return this.modelClientService.GetModelsByMakesYearsPOST({ makeIDs: [makeId], years: [year] }).toPromise();
    }

    SendTestAdfEmail(request: DealerSettingSendTestAdfRequest): Promise<null> {
        return this.dsClientService.SendTestAdfEmailPOST(request).toPromise();
    }

    GetTextNumbers(dealerId: number, overrideStateCode: string, areaCode: number): Promise<string[]> {
        const params: DealerSettingClientService.GetTextNumbersByDealeridGETParams = {
            dealerId: dealerId,
            stateCode: overrideStateCode,
            areaCode: overrideStateCode != null ? null : areaCode
        };
        return this.dsClientService.GetTextNumbersByDealeridGET(params).toPromise();
    }

    UseLegacyDealerSettingsPage() {
        return this.dsClientService.UseLegacyDealerSettingsPageGET().toPromise();
    }
}
