<div class="modal-wrapper">
    <form #sendLeadForm="ngForm" (ngSubmit)="sendEmail(sendLeadForm)">
        <div class="alert-box">
            <div class="modal-header">
                <div style="float:left">
                    <h4 class="modal-title" translate="sendTestLead"></h4>
                </div>
                <div style="float:right">
                    <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                        <icon class="icon aa-icon-close-icon"></icon>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-2" translate="email">
                    </div>
                    <div class="col-xs-7">
                        <div style="float:left">
                            <input autofocus="true" type="email" class="input-sm textbox txtEmail" [(ngModel)]="email" required email="true" id="testemail" name="testemail" #testemail="ngModel" placeholder="{{'crmEmailAddress' | translate}}" maxlength="100" />
                        </div>
                    </div>
                    <div class="col-xs-3">
                    </div>
                </div>
                <div class="row sourceSelectContainer">
                    <div class="col-xs-2">
                        <div style="float:left" translate="source"></div>
                    </div>
                    <div class="col-xs-7">
                        <select class="form-control sourceSelect" [(ngModel)]="source" name="source">
                            <option value="AutoAlert">AutoAlert</option>
                            <option value="AutoAlert-CreditConvert">AutoAlert-{{ 'creditConvert' | translate }}</option>
                            <option value="AutoAlert-Upgrade">AutoAlert-{{ 'upgrade' | translate }}</option>
                            <option value="AutoAlert-Mileage">AutoAlert-{{ 'mileageLabel' | translate }}</option>
                            <option value="AutoAlert-ContractEnd">AutoAlert-{{ 'contractEnd' | translate }}</option>
                            <option value="AutoAlert-Warranty">AutoAlert-{{ 'warrantyLabel' | translate }}</option>
                        </select>
                    </div>
                    <div class="col-xs-3">
                    </div>
                </div>
                <div class="row" style="margin-top:10px;">
                    <div class="col-xs-12">
                        <div [hidden]="testemail.valid || testemail.pristine" class="invalidField" *ngIf="testemail.errors?.email" translate="invalidEmailAddress"></div>
                        <div [hidden]="testemail.valid || testemail.pristine" class="invalidField" *ngIf="testemail.errors?.required" translate="requiredEmailAddress"></div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="cancelModal()" translate="cancel"></button>
                <button type="submit" class="btn btn-primary" translate="send" [disabled]="!sendLeadForm.form.valid"></button>
            </div>
        </div>
    </form>
</div>