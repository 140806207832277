import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { Transition } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { MobileDealSheetDataCachingService } from '../../../../services/mobile-deal-sheet-data-caching.service';
import { DealSheetDataService } from '../../../../services/deal-sheet-data.service';

@Component({
    selector: 'mobile-replacement-contract',
    templateUrl: './mobile-replacement-contract.component-ng.html',
    styleUrls: ['./mobile-replacement-contract.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileReplacementContractComponent implements OnInit, OnDestroy {
    opportunitySubscription: Subscription;
    opportunityId: string;

    opportunity: DealSheetOpportunity;
    permissions: DealSheetPermissions;
    countryId: any;
    availableContracts: any;
    showMobileView = true;
    showPullForward: boolean;

    constructor(private dealSheetDataService: DealSheetDataService,
        private mobileDealSheetDataCachingService: MobileDealSheetDataCachingService, private transition: Transition) {
        this.opportunityId = this.transition.params().opportunityId;
    }

    ngOnInit() {
        this.showPullForward = this.dealSheetDataService.shouldDisplayPullForward(this.opportunity);

        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(dealSheetData => {
            this.opportunity = dealSheetData.opportunity;
            this.permissions = dealSheetData.permissions;
            this.countryId = dealSheetData.userProfile.countryId;
            this.availableContracts = dealSheetData.opportunity.replacementContracts;
        });
    }

    ngOnDestroy(): void {
        this.opportunitySubscription.unsubscribe();
    }
}
