<div [ngBusy]="{busy: busy, delay: 200}" class="process-wrapper">
    <div *ngIf="formReady">
        <form #submitProcessForm="ngForm" (ngSubmit)="submitForm(submitProcessForm)">
            <div class="modal-header">
                <div style="float:left">
                    <icon class="start-processes-icon"></icon>
                </div>
                <div style="float:left">
                    <h4 class="modal-title" translate="dealSheetStartAProcessTitle"></h4>
                </div>
                <div style="float:right">
                    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
                        <icon class="icon aa-icon-close-icon"></icon>
                    </button>
                </div>
            </div>
            <div class="modal-body form-horizontal">
                <div *ngIf="processesAvailable">
                    <div *ngIf="top5ProcessesAvailable">
                        <div class="dealSheetTopProcessesText" translate="dealSheetTopProcesses"></div>
                        <div *ngFor="let item of top5Recent" style="float:left">
                            <div class="pillContainer">
                                <a class="btn btn-primary qa-push-to-crm-cancel-btn ng-scope pill" (click)="onTop5Clicked(item)">{{ item.templateName }} <icon class="circle-plus"></icon></a>
                            </div>
                        </div>
                        <div class="dividerLine"></div>
                    </div>
                    <div class="chooseAProcessText" translate="dealSheetChooseAProcess"></div>
                    <div class="processSelectContainer">
                        <select class="form-control" name="processSelect" [(ngModel)]="selectedWorkFlowTemplate" (change)="workflowTemplateSelect()">
                            <option *ngFor="let item of workFlowTemplates" [ngValue]="item">{{ item.name }}</option>
                        </select>
                    </div>

                    <div *ngIf="formLoaded && processForm.formControls.length > 0">
                        <div *ngIf="processForm.formTitle && processForm.formTitle.length > 0" class="process-form-title-container">
                            <div class="process-form-title">{{processForm.formTitle}}</div>
                            <tooltip [titleKey]="''" [contentKey]="'processFormTitleTooltip'"></tooltip>
                        </div>
                        <div class="process-form-wrapper">
                            <div *ngFor="let field of processForm.formControls" class="form-field">
                                <div *ngIf="field.fieldType == formControlType.SectionHeader" class="process-form-section">
                                    {{ field.label }}
                                </div>
                                <div *ngIf="field.controlType == formControlType.TextBox">
                                    <label>{{ field.label }}</label>
                                    <input class="input-md textbox form-control" id="process{{field.formControlId}}" name="process{{field.formControlId}}" type="text" title="{{field.helpText}}" [(ngModel)]="field.value" />
                                </div>
                                <div *ngIf="field.controlType == formControlType.Password">
                                    <label>{{ field.label }}</label>
                                    <input class="input-md textbox form-control" id="process{{field.formControlId}}" name="process{{field.formControlId}}" type="password" title="{{field.helpText}}" [(ngModel)]="field.value" />
                                </div>
                                <div *ngIf="field.controlType == formControlType.TextArea">
                                    <label>{{ field.label }}</label>
                                    <textarea rows="5" cols="40" id="process{{field.formControlId}}" name="process{{field.formControlId}}" title="{{field.helpText}}" class="input-md textbox form-control" [(ngModel)]="field.value"></textarea>
                                </div>
                                <div *ngIf="field.controlType == formControlType.Phone">
                                    <label>{{ field.label }}</label>
                                    <input type="tel" id="process{{field.formControlId}}" name="process{{field.formControlId}}" title="{{field.helpText}}" class="input-md textbox form-control" [(ngModel)]="field.value" placeholder="555-555-5555" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" maxlength="12" />
                                </div>
                                <div *ngIf="field.controlType == formControlType.Email">
                                    <label>{{ field.label }}</label>
                                    <input type="email" id="process{{field.formControlId}}" name="process{{field.formControlId}}" title="{{field.helpText}}" class="input-md textbox form-control" [(ngModel)]="field.value" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" />
                                </div>
                                <div *ngIf="field.controlType == formControlType.DropDownList">
                                    <label>{{ field.label }}</label>
                                    <select id="process{{field.formControlId}}" name="process{{field.formControlId}}" class="form-control" [(ngModel)]="field.value" title="{{field.helpText}}">
                                        <option *ngFor="let item of field.selectOptions" [ngValue]="item.value">{{ item.label }}</option>
                                    </select>
                                    <div *ngIf="field.hasOtherValue" class="otherRow">
                                        <div class="form-inline">
                                            <span>Other: </span>
                                            <input class="input-sm textbox form-control form-control-sm" id="other{{field.formControlId}}" name="other{{field.formControlId}}" type="text" [(ngModel)]="field.otherValue" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="field.controlType == formControlType.SingleSelectRadio">
                                    <label title="{{field.helpText}}">{{ field.label }}</label>
                                    <div class="single-select-radio-group">
                                        <div *ngFor="let item of field.selectOptions; let j = index" style="display:inline-block" class="radio-control">
                                            <input type="radio"  id="process{{field.formControlId + '-' + j}}" name="process{{field.formControlId}}" (change)="setRadioSelection(field.formControlId, j)" />
                                            <label>{{ item.label }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="field.controlType == formControlType.MultiSelectList">
                                    <label title="{{field.helpText}}">{{ field.label }}</label>
                                    <angular2-multiselect [data]="field.selectOptions" class="form-control" id="process{{field.formControlId}}" name="process{{field.formControlId}}"
                                                          [(ngModel)]="field.values"
                                                          [settings]="multiListSettings"></angular2-multiselect>
                                    <div *ngIf="field.hasOtherValue">
                                        <span>Other: </span>
                                        <input class="input-md textbox form-control" id="other{{field.formControlId}}" name="other{{field.formControlId}}" type="text" [(ngModel)]="field.otherValue" />
                                    </div>
                                </div>
                                <div *ngIf="field.controlType == formControlType.Date">
                                    <label>{{ field.label }}</label>
                                    <date-input id="process{{field.formControlId}}" style="font-size:14px;"
                                                (onInput)="setDate($event.date, field.formControlId)"></date-input>
                                </div>
                                <div *ngIf="field.controlType == formControlType.Time">
                                    <label>{{ field.label }}</label>
                                    <timepicker [(ngModel)]="field.value" name="process{{field.formControlId}}"></timepicker>
                                </div>
                                <div *ngIf="field.controlType == formControlType.LinearScale" class="slidecontainer" [ngStyle]="{'padding-bottom': field.minLabel && field.minLabel != field.minValue.toString() ? '20px' : '0'}">
                                    <label title="{{field.helpText}}">{{ field.label }}</label>
                                    <ng5-slider [(value)]="field.value" [options]="sliderOptions[field.formControlId]"></ng5-slider>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="!processesAvailable" translate="noProcessesAvailable"></div>
            </div>
            <div class="modal-footer">
                <div class="button-span pull-right">
                    <a class="btn btn-primary qa-push-to-crm-cancel-btn" (click)="onCancel()">{{processSettings.cancel}}</a>
                    <a *ngIf="processesAvailable && showLoadFormButton" class="btn btn-primary qa-push-to-crm-submit-btn" (click)="loadForm()">Details</a>
                    <button type="submit" *ngIf="processesAvailable" [class.disabled]="!startButtonEnabled || !submitProcessForm.form.valid" class="btn btn-primary">{{processSettings.start}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
