
/* injects from baggage-loader */
require('./possible-nlo.component.html');

import angular from 'angular';
import './possible-nlo.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';
import $ from 'jquery';

angular.module('AaApp.Opp').component('possibleNlo', {
    templateUrl: '/app/components/possible-nlo/possible-nlo.component.html',
    controller: PossibleNloController,
    bindings: {
        kendo: '<',
    }
});

function PossibleNloController(
    $translate,
    $q,
    dealerService,
    userService,
    dealSheetService,
    searchService,
    cultures,
    environmentVariables,
    logger,
    $filter,
    opportunity2Service,
    opportunityService,
    _
) {
    "ngInject";
    const $ctrl = this;

    var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;

    $ctrl.$onInit = init;
    $ctrl.onDealerSelect = onDealerSelect;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.setKendoCulture = setKendoCulture;
    $ctrl.loadNloData = loadNloData;
    $ctrl.getTranslations = getTranslations;
    $ctrl.environmentVariables = environmentVariables;
    $ctrl.cultureName;
    $ctrl.translations = {};
    $ctrl.users;
    $ctrl.childGridOptions = childGridOptions;
    $ctrl.loadDealSheet = loadDealSheet;

    function init() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        $ctrl.selectedDealerId = $ctrl.dealers[0].id;

        $q.all([
            userService.getUserProfile(),
            $ctrl.getTranslations(),
        ]).then((data) => {
            var userProfile = data[0];
            $ctrl.translations = data[1];

            $ctrl.cultureName = userProfile.cultureName;
            setKendoCulture();
            initGridOptions();
            onDealerSelect();
        });
    }
    
    function busy(translationKey, promise) {  //global
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function onDealerSelect(dealerId) {
        if (dealerId > 0) $ctrl.selectedDealerId = dealerId;

        loadNloData();

    }

    function setTestNloData() {
        var testData = [
            {
                entityID: 'A6496507-C998-E611-80D6-3CA82A11AF1E',
                dealerID: 1167,
                customerFullName: 'John Henderson',
                makeName: 'Honda',
                modelName: 'Accord',
                yearID: '2019',
                trimName: 'LX',
                maxInsertDateUtc: '07/04/2021 09:00:00',
                nloDetails: [
                    {
                        communicationSourceID: '001',
                        communicationText: 'CUSTOMER SOLD CAR',
                        insertDateUtc: '07/04/2021 09:00:00'
                    },
                    {
                        communicationSourceID: '002',
                        communicationText: 'Vehicle was sold',
                        insertDateUtc: '06/28/2021 09:00:00'
                    },
                    {
                        communicationSourceID: '003',
                        communicationText: 'No longer owns vehicle',
                        insertDateUtc: '06/14/2021 11:00:00'
                    }
                ]
            },
            {
                entityID: 'A6496507-C998-E611-80D6-3CA82A11AF1E',
                dealerID: 1167,
                customerFullName: 'Jessica Smith',
                makeName: 'Mercedes-Benz',
                modelName: 'GLS',
                yearID: '2017',
                trimName: '',
                maxInsertDateUtc: '05/15/2021 09:00:00',
                nloDetails: [
                    {
                        communicationSourceID: '001',
                        communicationText: 'CUSTOMER SOLD CAR',
                        insertDateUtc: '07/04/2021 09:00:00'
                    },
                    {
                        communicationSourceID: '001',
                        communicationText: 'Customer no longer owns vehicle',
                        insertDateUtc: '06/22/2021 10:30:00'
                    }
                ]
            },
            {
                entityID: 'A6496507-C998-E611-80D6-3CA82A11AF1E',
                dealerID: 1167,
                customerFullName: 'Jason Williams',
                makeName: 'Mercedes-Benz',
                modelName: 'S',
                yearID: '2015',
                trimName: '',
                maxInsertDateUtc: '05/10/2021 09:00:00',
                nloDetails: [
                    {
                        communicationSourceID: '001',
                        communicationText: 'CUSTOMER SOLD CAR',
                        insertDateUtc: '07/10/2021 14:00:00'
                    },
                    {
                        communicationSourceID: '001',
                        communicationText: 'Sold car',
                        insertDateUtc: '07/06/2021 15:00:00'
                    },
                    {
                        communicationSourceID: '002',
                        communicationText: 'Vehicle was sold',
                        insertDateUtc: '06/28/2021 09:00:00'
                    },
                    {
                        communicationSourceID: '003',
                        communicationText: 'No longer owns vehicle',
                        insertDateUtc: '06/14/2021 11:00:00'
                    }
                ]
            }
        ];

        return testData;
    }

    function loadNloData() {
        return busy(
            $ctrl.translations.loading,
            searchService.getNoLongerOwnForDealer($ctrl.selectedDealerId)
                .then((data) => {
                    $ctrl.nloData = data;

                    //$ctrl.nloData = setTestNloData();

                    $ctrl.ds = new $ctrl.kendo.data.DataSource({
                        data: $ctrl.nloData,
                        schema: {
                            model: {
                                id: 'entityID',
                                fields: {
                                    entityID: { type: "string", editable: false },
                                    dealerID: { type: "number", editable: false },
                                    customerFullName: { type: "string", editable: false },
                                    makeName: { type: "string", editable: false },
                                    modelName: { type: "string", editable: false },
                                    yearID: { type: "string", editable: false },
                                    trimName: { type: "string", editable: false },
                                    maxInsertDateUtc: { type: "date", editable: false },
                                    nloDetails: { type: "array", editable: false },
                                }
                            }
                        },
                        sort: { field: "maxInsertDateUtc", dir: "desc" },
                        batch: false,
                        pageSize: 20
                    });
                })
        );
    }

    function initGridOptions() {
        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            pageable: true,
            height: 550,
            columns: [
                {
                    field: "customerFullName",
                    title: $ctrl.translations.customerLabel,
                    template: renderCustomerLink,
                    width: "14%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.customerFullName.toLowerCase();
                            var second = b.customerFullName.toLowerCase();
                            first = first == null ? first = "zzzz" : first;
                            second = second == null ? second = "zzzz" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    }
                },
                {
                    field: "makeName",
                    title: $ctrl.translations.make,
                    template: '{{dataItem.makeName}}',
                    width: "14%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.makeName;
                            var second = b.makeName;
                            first = first == null ? first = "ZZZZ" : first;
                            second = second == null ? second = "ZZZZ" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    }
                },
                {
                    field: "yearID",
                    title: $ctrl.translations.year,
                    width: "8%",
                    attributes: { style: "text-align: center" },
                    headerAttributes: { "class": "align-center" },
                    sortable: true,
                },
                {
                    field: "modelName",
                    title: $ctrl.translations.model,
                    template: '{{dataItem.modelName}}',
                    width: "14%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.modelName;
                            var second = b.modelName;
                            first = first == null ? first = "ZZZZ" : first;
                            second = second == null ? second = "ZZZZ" : second;
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    field: "trimName",
                    title: $ctrl.translations.trim,
                    width: "23%",
                    sortable: true,
                },
                {
                    field: "maxInsertDateUtc",
                    title: $ctrl.translations.nloLatestDate,
                    template: mostRecentDateTemplate,
                    format: "{0:d}",
                    width: "14%",
                    sortable: {
                        compare: function (a, b) {
                            var first = a.maxInsertDateUtc ? a.maxInsertDateUtc : '';
                            var second = b.maxInsertDateUtc ? b.maxInsertDateUtc : '';
                            var answer = first < second ? -1 : 1;
                            return answer;
                        }
                    },
                },
                {
                    command: [
                        {
                            name: "Mark",
                            text: $ctrl.translations.nlo_Mark,
                            click: markAsNLO
                    },
                        {
                            name: "Decline",
                            text: $ctrl.translations.nlo_Decline,
                            click: declineNLO
                        }
                    ],
                    title: $ctrl.translations.actions,
                    width: "230px",
                    attributes: { style: "text-align: center" },
                    headerAttributes: { "class": "align-center" },
                }
            ],
            editable: false,
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: true,
            reorderable: true,
            filterable: false
        }

    }

    function loadDealSheet(entityId) {
        dealSheetService.openDealSheetModal(entityId);
    }

    function renderCustomerLink(e) {
        if (e.entityID == null)
            return e.customerFullName;
        var dsLink = "<a class='deal-sheet-link' ng-click='$ctrl.loadDealSheet(\"" + e.entityID + "\")' style=\"cursor: pointer; height: 25px;\">" + e.customerFullName + "</a>";
        return dsLink;
    }

    function markAsNLO(e) {
        e.preventDefault(); //prevent page scroll reset
        var tr = $(e.target).closest("tr");
        var data = this.dataItem(tr);

        updateNLO(data.entityID, true);
    }

    function declineNLO(e) {
        e.preventDefault(); //prevent page scroll reset
        var tr = $(e.target).closest("tr");
        var data = this.dataItem(tr);

        updateNLO(data.entityID, false);
    }

    function updateNLO(entityId, agreed) {
        // call api to mark as NLO or decline NLO then refresh deal sheet:
        opportunityService.updateActiveNLPNoLongerOwn(entityId, agreed).then(() => {
            logger.success("changesSaved");
            loadNloData();
        });
    }

    function childGridOptions(dataItem) {

        dataItem.nloDetails.forEach(item => {
            var transKey = 'nloCommSrc_' + item.communicationSourceID;
            item.communicationSource = $ctrl.translations[transKey];
        });

        var ds = new $ctrl.kendo.data.DataSource({
            data: dataItem.nloDetails,
            schema: {
                model: {
                    fields: {
                        insertDateUtc: { type: "date", editable: false },
                        communicationSource: { type: "string", editable: false },
                        communicationText: { type: "string", editable: false },
                    }
                }
            },
            sort: { field: "insertDateUtc", dir: "desc" },
            batch: false,
        });

        return {
            dataSource: ds,
            pageable: false,
            columns: [
                {
                    field: "insertDateUtc",
                    title: $ctrl.translations.date,
                    width: "10%",
                    template: insertDateTemplate,
                    format: "{0:d}",
                    sortable: true,
                },
                {
                    field: "communicationSource",
                    title: $ctrl.translations.source,
                    width: "10%",
                    sortable: true,
                },
                {
                    field: "communicationText",
                    title: $ctrl.translations.nloMessage,
                    width: "80%",
                    sortable: true,
                },
            ],
            editable: false,
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: false,
            reorderable: false,
            filterable: false
        };
    }

    function mostRecentDateTemplate(e) {
        var dateUtc = e.maxInsertDateUtc ? e.maxInsertDateUtc : '';
        if (dateUtc) {
            var localeDateFilter = $filter('localeDate');
            var localeDate = localeDateFilter(dateUtc);
            return "<span>" + localeDate + "</span>";
        }
        else {
            return "";
        }
    }

    function insertDateTemplate(e) {
        var dateUtc = e.insertDateUtc ? e.insertDateUtc : '';
        if (dateUtc) {
            var localeDateFilter = $filter('localeDate');
            var localeDate = localeDateFilter(dateUtc);
            return "<span>" + localeDate + "</span>";
        }
        else {
            return "";
        }
    }

    function setKendoCulture() {
        switch ($ctrl.cultureName) {
            case cultures.enUS:
            case cultures.enGB:
            case cultures.enCA:
            case cultures.frCA:
            case cultures.frFR:
            case cultures.esUS:
                $ctrl.kendo.culture($ctrl.cultureName);
                break;
            default:
                $ctrl.kendo.culture(cultures.enUS);
                break;
        }
    }

    function getTranslations() {
        return $q.all({
            'dealerships': $translate('dealerships'),
            'make': $translate('make'),
            'model': $translate('model'),
            'year': $translate('year'),
            'date': $translate('date'),
            'loading': $translate('loading'),
            'faalertletter': $translate('faalertletter'),
            'flexAlertLetter': $translate('flexAlertLetter'),
            'inMarketLetter': $translate('inMarketLetter'),
            'inMarketEngagedLetter': $translate('inMarketEngagedLetter'),
            'contractEndLetter': $translate('contractEndLetter'),
            'serviceLetter': $translate('serviceLetter'),
            'serviceAppointmentLetter': $translate('serviceAppointmentLetter'),
            'mileageLetter': $translate('mileageLetter'),
            'warrantyLetter': $translate('warrantyLetter'),
            'customerLabel': $translate('customerLabel'),
            'trim': $translate('trim'),
            'source': $translate('source'),
            'actions': $translate('actions'),
            'nloCommSrc_001': $translate('nloCommSrc_001'),
            'nloCommSrc_002': $translate('nloCommSrc_002'),
            'nloCommSrc_003': $translate('nloCommSrc_003'),
            'nlo_Mark': $translate('nlo_Mark'),
            'nlo_Decline': $translate('nlo_Decline'),
            'nloCommandBtnsTooltip': $translate('nloCommandBtnsTooltip'),
            'nloMessage': $translate('nloMessage'),
            'nloLatestDate': $translate('nloLatestDate'),
        });
    }
}
