import angular from 'angular';

angular.module('AaApp.Opp').factory('servicesService', servicesService);

/* @ngInject */
function servicesService($q, $timeout, coreApi, logger) {

    var service = {
        getServicesBy: getServicesBy,
        getServiceLineDetails: getServiceLineDetails
    };

    return service;

    function getServicesBy(entityId) {
        return coreApi.api.Service.GetServicesBy['{entityId}'].GET({ entityId: entityId })
            .then(unpackServicesInfo);

        function unpackServicesInfo(raw) {
            var customer = 0;
            var dealer = 0;
            var warranty = 0;
            var counts = {
                Current: 0,
                Total: 0
            };
            var otherCustomer = false;
            angular.forEach(raw.data, function (value) {

                if (value.wasOwnedByCurrentCustomer && !isNaN(value.dealerPaid)) { dealer += value.dealerPaid; }
                if (value.wasOwnedByCurrentCustomer && !isNaN(value.customerPaid)) { customer += value.customerPaid; }
                if (value.wasOwnedByCurrentCustomer && !isNaN(value.warrantyPaid)) { warranty += value.warrantyPaid; }
                if (value.wasOwnedByCurrentCustomer) {
                    counts.Current++;
                } else {
                    otherCustomer = true;
                }
                counts.Total++;
            }, logger);
            var rslt = {
                wasServicedByOtherCustomer: otherCustomer,
                Totals: {
                    Customer: customer,
                    Dealer: dealer,
                    Warranty: warranty,
                    Counts: counts
                },
                History: raw.data
            };

            return rslt;
        }
    }

    function getServiceLineDetails(dealerId, roId) {
        return coreApi.api.Service.GetServiceDetail['{dealerId}']['{roId}'].GET({ dealerId: dealerId, roId: roId })
            .then(resolveData);
    }

    function resolveData(response) {
        if (response.data == null)
            return {};

        response.data.forEach(function (data) {
            data.roSale = data.roSale || 0;
            data.roSaleCP = data.roSaleCP || 0;
            data.roSaleWP = data.roSaleWP || 0;
            data.roSaleIP = data.roSaleIP || 0;
        });

        return response.data;
    }

}
