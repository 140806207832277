
/* injects from baggage-loader */
require('./search-save-layout-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url"
import { ChildMessagePort } from '../../utilities/messaging';
import './search-save-layout-modal.component.scss';

angular.module('AaApp.Opp').component('searchSaveLayoutModal', {
    templateUrl: '/app/components/search/search-save-layout-modal.component.html',
    controller: SearchSaveLayoutModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
});

/* @ngInject */
function SearchSaveLayoutModalController($element, $scope, $sce, $uibModal, $document, legacy, urlHelper) {
    var $ctrl = this;

    $ctrl.submit = submit;
    $ctrl.$onInit = onInit;
    const originalIframeLayoutUrl = legacy.iframeUrl + "/AlertDesk/Search/Export";
    $ctrl.selectedDealerId;
    $ctrl.iframeUrl;

    function onInit() {
        var rendered = $ctrl.resolve.renderedPromise.rendered;
        if ($ctrl.resolve.renderedPromise.selectedDealerId === undefined) {
            $ctrl.selectedDealerId = 0;
        }
        else {
            $ctrl.selectedDealerId = $ctrl.resolve.renderedPromise.selectedDealerId;
        }
        $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeLayoutUrl + "?lm=Search&dealerID=" + $ctrl.selectedDealerId + "&fa=" + $ctrl.resolve.isFordAlert);

        rendered.then(function () {
            const iframeLayout = $element.find(".ifrmSearchLayout")[0];
            const originLayout = getUrlComponents(originalIframeLayoutUrl).origin;
            $ctrl.messageChannelLayout = new ChildMessagePort(iframeLayout, originLayout, $scope);

            const subscription = $ctrl.messageChannelLayout.subscribe(function ({ data }, replyPort) {
                if (data.type === "SEARCH_SAVE_LAYOUT_COMPLETED") {
                    //console.log("SEARCH_SAVE_LAYOUT_COMPLETED message received in Portal.");
                    $ctrl.close({ $value: { success: true, exportLayout: data.data.layoutData } });

                }
            })
        });
    }

    function submit() {
        var msg = {
            type: "SEARCH_SAVE_LAYOUT",
            data: {
                selectedDealerId: $ctrl.selectedDealerId
            }
        };
        $ctrl.messageChannelLayout.postMessage(msg);
    }
}
