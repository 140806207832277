
/* injects from baggage-loader */
require('./dealer-list.component.html');

import angular from 'angular';
import { OpportunitiesModule } from '../../core/opportunities.module';

OpportunitiesModule.component('dealerList', {
    templateUrl: '/app/components/header/dealer-list.component.html',
    controller: DealerListController,
    bindings: {
        allowChanges: '<',
        isDisabled: '<',
        onDealersChanged: '&',
    }
});

function DealerListController($q, $document, $scope, $window, $uibModal, dealerService, _) {
    "ngInject";
    var $ctrl = this;

    //props
    $ctrl.errorMessage;
    $ctrl.visible = false;
    $ctrl.dealerSelections = {};
    $ctrl.IsReloadDialogOpen = false;
    $ctrl.noMatchFoundClick = false;
    $ctrl.selectionsAvailable;
    $ctrl.dealerIds = [];

    //methods
    $ctrl.$onInit = $onInit;
    $ctrl.$onDestroy = $onDestroy;
    $ctrl.$onChanges = $onChanges;
    $ctrl.toggleDealerList = toggleDealerList;
    $ctrl.setDealerSearchLimit = setDealerSearchLimit;
    $ctrl.selectAll = selectAll;
    $ctrl.removeAll = removeAll;
    $ctrl.cancelSelections = cancelSelections;
    $ctrl.changeDealers = changeDealers;
    $ctrl.noMatchSubmitChange = noMatchSubmitChange;
    $ctrl.selectDealerPromise;
    $ctrl.filterForEuroLite = filterForEuroLite;

    function sendOnDealersChanged(dealerGroupId, dealerIds, dealers, dealerDisplayText) {
        if ($ctrl.onDealersChanged) {
            $ctrl.onDealersChanged({
                dealerGroupId: dealerGroupId,
                dealerIds: dealerIds,
                dealers: dealers,
                dealerDisplayText: dealerDisplayText,
            });
        }
    }

    function noMatchSubmitChange(selectionsAvailable) {
        $ctrl.selectionsAvailable = selectionsAvailable;
        if ($ctrl.dealerSearchLimit > 0 && $ctrl.selectionsAvailable == 0) {
            var submitButton = $document[0].querySelector('.dealer-search-submit');
            submitButton.focus();
        }
        $ctrl.errorMessage = '';
    }

    function showReloadDialog() {
        $ctrl.IsReloadDialogOpen = true;
        var parentElem = angular.element($document[0].querySelector('#aa-app'));
        var modalInstance = $uibModal.open({
            animation: true,
            component: 'confirmationModal',
            appendTo: parentElem,
            resolve: {
                title: function () {
                    return "dealerChangedTitle"; // translation key
                },
                message: function () {
                    return "dealerChangedMsg"; // translation key
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "ok"; // translation key
                },
                closeButtonVisible: function () {
                    return false;
                }
            }
        });

        modalInstance.result
            .then(function () {
                $window.location.reload()
            }, function () {
                $window.location.reload()
            });
    }

    function $onInit() {
        fetchAndSetDealers();

        // Reload the page if selectedDealerIds changes
        $ctrl.selectedDealersChangedSubscription = dealerService.onSelectedDealersChanged(value => {
            if (!$ctrl.IsReloadDialogOpen) {
                showReloadDialog();
            }
        });
    }

    function $onDestroy() {
        if ($ctrl.selectedDealersChangedSubscription) {
            $ctrl.selectedDealersChangedSubscription.unsubscribe();
        }
    }

    function $onChanges(changes) {
        $ctrl.dealerDisplayText = dealerService.getDealerDisplayText();
        if (changes.isDisabled) {
            $ctrl.errorMessage = $ctrl.isDisabled ? 'Changing dealers is not available on a Deal Sheet page.' : null;
        }

        function changeOrExistingValue(propertyChange, existingValue) {
            if (propertyChange) {
                return propertyChange.currentValue;
            } else {
                return existingValue;
            }
        }
    }

    function fetchAndSetDealers() {
        return dealerService.getDealers()
            .then((allowedDealers) => {
                $ctrl.dealerIds = dealerService.getSelectedDealerIds() || [];
                $ctrl.allowedDealers = allowedDealers;
                $ctrl.selectedDealers = allowedDealers.filter(d => $ctrl.dealerIds.includes(d.dealerID));
                $ctrl.dealerSelections.list = $ctrl.selectedDealers;
                $ctrl.setDealerSearchLimit();
            });
    }

    function toggleDealerList() {
        $ctrl.visible = !$ctrl.visible;
        $ctrl.dealerSelections.list = $ctrl.selectedDealers;
        $ctrl.errorMessage = '';

        $scope.$broadcast('DealerSelectFocus');

        if ($ctrl.visible) {
            fetchAndSetDealers();                    
        };
    }
    
    function setDealerSearchLimit(searchText) {
        if (!($ctrl.dealerSearchLimit > 0 && $ctrl.selectionsAvailable == 0)) {
            if ($ctrl.dealerSelections && $ctrl.dealerSelections.list && $ctrl.dealerSelections.list.length > 0) {
                $ctrl.dealerSearchLimit = 50;
            }
            else if (searchText && searchText.length >= 2) {
                $ctrl.dealerSearchLimit = 30;
            }
            else {
                $ctrl.dealerSearchLimit = 0;
            }
            $scope.$broadcast('DealerSelectFocus');
        }
    }

    function cancelSelections () {
        $ctrl.dealerSelections.list = $ctrl.selectedDealers;
        $ctrl.visible = false;
    }

    function filterForEuroLite(dealers){
        var dealersForEuroLite = _.filter(dealers, function(ds){ return ds.productID == 5; });
        if (dealersForEuroLite.length > 0) {
            return dealersForEuroLite;
        }
        return dealers;
    }

    function changeDealers() {
        if ($ctrl.dealerSelections.list.length > 0 && $ctrl.selectedDealers.length > 0
            && $ctrl.dealerSelections.list.length == $ctrl.selectedDealers.length
            && isArrayEqual($ctrl.dealerSelections.list, $ctrl.selectedDealers, function(dealer) { return dealer.dealerID })) {
            return;
        }
        $ctrl.dealerSelections.list = filterForEuroLite($ctrl.dealerSelections.list);

        if ($ctrl.dealerSelections.list.length > 0) {
            $ctrl.selectDealerPromise = $q.defer();

            var dealerGroupId = $ctrl.dealerSelections.list[0].dealerGroupID;
            var dealerIds = getDealerIds($ctrl.dealerSelections.list);
            var selectedDealers = getSelectedDealersBasicInfo($ctrl.dealerSelections.list);
            var dealerDisplayText = $ctrl.dealerSelections.list.length > 1
                ? $ctrl.dealerSelections.list[0].dealerGroupName
                : $ctrl.dealerSelections.list[0].displayText;

            // Don't display the "changed dealers" message because, duh, we already know
            $ctrl.selectedDealersChangedSubscription && $ctrl.selectedDealersChangedSubscription.unsubscribe();

            sendOnDealersChanged(dealerGroupId, dealerIds, selectedDealers, dealerDisplayText);

        } else {
            $ctrl.errorMessage = 'Please select at least one dealer.';
        }
    }

    var isArrayEqual = function (array1, array2, selector) {
        selector = selector || function (a) { return a }

        // If the lengths are unequal, the arrays must be unequal
        if (array1.length != array2.length)
            return false;

        // For each element in array1, look for a matching element in array2
        for (var i in array1) {
            var control = selector(array1[i]);
            if (!array2.some(function(candidate) { return selector(candidate) === control })) {
                // If no matching element exists, the arrays are unequal
                return false;
            }
        }

        return true;
    };

    function getSelectedDealersBasicInfo(dealers) {
        var info = dealers.map(function (s) {
            return { id: s.dealerID, name: s.displayText }
        });
        return info;
    }

    function getDealerIds (dealers) {
        var dealerIds = [];

        for (var i = 0; i < dealers.length; i++) {
            dealerIds.push(dealers[i].dealerID);
        }

        return dealerIds;
    }

    function selectAll() {
        if ($ctrl.dealerSelections.list.length > 0) {
            var dealerGroupId = $ctrl.dealerSelections.list[0].dealerGroupID;

            $ctrl.dealerSelections.list = $ctrl.allowedDealers
                .filter(function (d) { return d.dealerGroupID == dealerGroupId });
        }
    }

    function removeAll() {
        $ctrl.dealerSelections.list = [];
    }
}
