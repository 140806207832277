"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./modal.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../icon/icon.component.ngfactory"));
var i3 = __importStar(require("../../icon/icon.component"));
var i4 = __importStar(require("@angular/platform-browser"));
var i5 = __importStar(require("@ngx-translate/core"));
var i6 = __importStar(require("@angular/common"));
var i7 = __importStar(require("./modal.component"));
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
exports.RenderType_ModalComponent = RenderType_ModalComponent;
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-header-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "icon", [["class", "icon aa-icon-close-icon"]], [[8, "title", 0]], null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(2, 638976, null, 0, i3.IconComponent, [i4.DomSanitizer], null, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.closeButtonText ? _co.closeButtonText : i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("close"))), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-title-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-title-bar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "modal-header-icon"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h5", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "modal-header-extras"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.closeIconVisible == null) || (_co.closeIconVisible == true)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTextClass; var currVal_1 = _co.translatedHeaderTitle; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary qa-modal-btn-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.closeButtonText ? _co.closeButtonText : "cancel"), ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary qa-modal-btn-submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", (_co.submitButtonText ? _co.submitButtonText : "submit"), ""); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.submitButtonDisabled; _ck(_v, 0, 0, currVal_0); }); }
function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "modal-body form-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-sm-12"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "button-span pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_4)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "dynamic-buttons"]], null, null, null, null, null)), i1.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.includeTitleBar; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.closeButtonVisible; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.submitButtonVisible; _ck(_v, 12, 0, currVal_2); }, null); }
exports.View_ModalComponent_0 = View_ModalComponent_0;
function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 638976, null, 0, i7.ModalComponent, [i5.TranslateService, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ModalComponent_Host_0 = View_ModalComponent_Host_0;
var ModalComponentNgFactory = i1.ɵccf("modal", i7.ModalComponent, View_ModalComponent_Host_0, { headerTitle: "headerTitle", includeTitleBar: "includeTitleBar", submitButtonVisible: "submitButtonVisible", submitButtonDisabled: "submitButtonDisabled", submitButtonText: "submitButtonText", closeButtonText: "closeButtonText", closeButtonVisible: "closeButtonVisible", closeIconVisible: "closeIconVisible", headerTextClass: "headerTextClass" }, { onClose: "onClose", onSubmit: "onSubmit" }, ["modal-header-icon", "modal-header", "modal-body", "modal-footer"]);
exports.ModalComponentNgFactory = ModalComponentNgFactory;
