
/* injects from baggage-loader */
require('./edit-upgrade-downgrade-modal.component.html');

import angular from 'angular';
import './edit-upgrade-downgrade-modal.component.scss';

angular.module('AaApp.Opp').component('editUpgradeDowngradeModal', {
    templateUrl: '/app/components/data-entry/replacement/edit-upgrade-downgrade-modal.component.html',
    controller: editUpgradeDowngradeModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

function editUpgradeDowngradeModalController() {
    
    var $ctrl = this;

    //$ctrl.resolve is sent by the caller of this directive, and can be any JS object.
    //  I'm using it to enable DI and pass input parameters.
    $ctrl.row = $ctrl.resolve.message.row;
    $ctrl.selectedDealerId = $ctrl.resolve.message.selectedDealerId;
    $ctrl.save = $ctrl.resolve.message.save;
    $ctrl.setTrimSelections = setTrimSelections;
    $ctrl.closeWrapper = closeWrapper;

    $ctrl.upgrade = $ctrl.upgradeOriginal = getSelection("upgrade");
    $ctrl.upgradeOverrideDefaults = $ctrl.upgradeOverrideValue = setDefaults("upgrade");

    $ctrl.downgrade = $ctrl.downgradeOriginal = getSelection("downgrade");
    $ctrl.downgradeOverrideDefaults = $ctrl.downgradeOverrideValue = setDefaults("downgrade");

    $ctrl.upgradeRetailTolerance = $ctrl.upgradeRetailToleranceOriginal = $ctrl.row.upgradeRetailTolerance;
    $ctrl.upgradeLeaseTolerance = $ctrl.upgradeLeaseToleranceOriginal = $ctrl.row.upgradeLeaseTolerance;
    $ctrl.downgradeRetailTolerance = $ctrl.downgradeRetailToleranceOriginal = $ctrl.row.downgradeRetailTolerance;
    $ctrl.downgradeLeaseTolerance = $ctrl.downgradeLeaseToleranceOriginal = $ctrl.row.downgradeLeaseTolerance;

    $ctrl.invalidUpgradeLeaseTolerance = false;
    $ctrl.invalidUpgradeRetailTolerance = false;
    $ctrl.invalidDowngradeLeaseTolerance = false;
    $ctrl.invalidDowngradeRetailTolerance = false;

    function setTrimSelections(selections, upDown) {
        if (upDown == "upgrade") {
            $ctrl.upgradeOverrideValue = selections;
            $ctrl.upgrade = 'useOverride';
        }
        if (upDown == "downgrade") {
            $ctrl.downgradeOverrideValue = selections;
            $ctrl.downgrade = 'useOverride';
        }
    }

    function closeWrapper() {
        var rowState = {
            upgradeChanged: $ctrl.upgrade != $ctrl.upgradeOriginal || ($ctrl.upgrade == "useOverride" && (
                ($ctrl.upgradeOverrideDefaults.trimID != $ctrl.upgradeOverrideValue.trimID) ||
                $ctrl.upgradeRetailTolerance != $ctrl.upgradeRetailToleranceOriginal ||
                $ctrl.upgradeLeaseTolerance != $ctrl.upgradeLeaseToleranceOriginal
            )),
            upgradeSelection: $ctrl.upgrade,
            upgradeOverride: $ctrl.upgradeOverrideValue,

            downgradeChanged: $ctrl.downgrade != $ctrl.downgradeOriginal || ($ctrl.downgrade == "useOverride" && (
                ($ctrl.downgradeOverrideDefaults.trimID != $ctrl.downgradeOverrideValue.trimID) ||
                $ctrl.downgradeRetailTolerance != $ctrl.downgradeRetailToleranceOriginal ||
                $ctrl.downgradeLeaseTolerance != $ctrl.downgradeLeaseToleranceOriginal
            )),
            downgradeSelection: $ctrl.downgrade,
            downgradeOverride: $ctrl.downgradeOverrideValue,

            row: $ctrl.row,

            upgradeRetailTolerance: $ctrl.upgradeRetailTolerance,
            upgradeLeaseTolerance: $ctrl.upgradeLeaseTolerance,
            downgradeRetailTolerance: $ctrl.downgradeRetailTolerance,
            downgradeLeaseTolerance: $ctrl.downgradeLeaseTolerance
        }

        if (rowState.upgradeChanged || rowState.downgradeChanged) {
            $ctrl.invalidUpgradeLeaseTolerance = $ctrl.upgradeLeaseTolerance != null && ($ctrl.upgradeLeaseTolerance < 0 || $ctrl.upgradeLeaseTolerance > 200);
            $ctrl.invalidUpgradeRetailTolerance = $ctrl.upgradeRetailTolerance != null && ($ctrl.upgradeRetailTolerance < 0 || $ctrl.upgradeRetailTolerance > 200);
            $ctrl.invalidDowngradeLeaseTolerance = $ctrl.downgradeLeaseTolerance != null && ($ctrl.downgradeLeaseTolerance < 0 || $ctrl.downgradeLeaseTolerance > 200);
            $ctrl.invalidDowngradeRetailTolerance = $ctrl.downgradeRetailTolerance != null && ($ctrl.downgradeRetailTolerance < 0 || $ctrl.downgradeRetailTolerance > 200);

            if ($ctrl.invalidUpgradeLeaseTolerance || $ctrl.invalidUpgradeRetailTolerance || $ctrl.invalidDowngradeLeaseTolerance || $ctrl.invalidDowngradeRetailTolerance) {
                return;
            }
        }

        $ctrl.save(rowState);

        //The CLOSE() event should be called asyncronously, after the SAVE feature is successful.
        return $ctrl.close();
    }

    function getSelection(upDown) {
        if ($ctrl.row[upDown + "OverrideExists"] == true && $ctrl.row[upDown + "OverrideTrimID"] == null) return "doNotOffer";
        if ($ctrl.row[upDown + "OverrideExists"] == true && $ctrl.row[upDown + "OverrideTrimID"] > 0) return "useOverride";
        return "useDefault";
    }

    //Get the override trim.  If override is null, get the default trim.  If the default is null, get the base.
    function setDefaults(upDown) {

        var upgradeRetailTolerance = $ctrl.row[upDown + "DefaultTrimID"]


        if ($ctrl.row[upDown + "OverrideTrimID"] == null) {
            if ($ctrl.row[upDown + "DefaultTrimID"] == null) {
                return {
                    vehicleTypes: 0,
                    makeID: $ctrl.row.baseMakeID,
                    makeName: $ctrl.row.baseMakeName,
                    yearID: $ctrl.row.baseYearID,
                    modelID: $ctrl.row.baseModelID,
                    modelName: $ctrl.row.baseModelName,
                    trimID: $ctrl.row.baseTrimID,
                    trimName: $ctrl.row.baseTrimName
                };
            }
            return {
                vehicleTypes: 0,
                makeID: $ctrl.row[upDown + "DefaultMakeID"],
                makeName: $ctrl.row[upDown + "DefaultMakeName"],
                yearID: $ctrl.row[upDown + "DefaultYearID"],
                modelID: $ctrl.row[upDown + "DefaultModelID"],
                modelName: $ctrl.row[upDown + "DefaultModelName"],
                trimID: $ctrl.row[upDown + "DefaultTrimID"],
                trimName: $ctrl.row[upDown + "DefaultTrimName"]
            };
        }
        return {
            vehicleTypes: 0,
            makeID: $ctrl.row[upDown + "OverrideMakeID"],
            makeName: $ctrl.row[upDown + "OverrideMakeName"],
            yearID: $ctrl.row[upDown + "OverrideYearID"],
            modelID: $ctrl.row[upDown + "OverrideModelID"],
            modelName: $ctrl.row[upDown + "OverrideModelName"],
            trimID: $ctrl.row[upDown + "OverrideTrimID"],
            trimName: $ctrl.row[upDown + "OverrideTrimName"]
        }

    }


    if ($ctrl.resolve.closeButtonVisible === undefined)
        $ctrl.resolve.closeButtonVisible = true;

    if ($ctrl.resolve.closeButtonText === undefined)
        $ctrl.resolve.closeButtonText = "cancel";

    if ($ctrl.resolve.submitButtonVisible === undefined)
        $ctrl.resolve.submitButtonVisible = true;

    if ($ctrl.resolve.submitButtonDisabled === undefined)
        $ctrl.resolve.submitButtonDisabled = false;

    if ($ctrl.resolve.submitButtonText === undefined)
        $ctrl.resolve.submitButtonText = "yes";

}

