import angular from 'angular';

angular.module('AaApp.Opp').directive('disableClick', disableClickDirective);

/* @ngInject */
function disableClickDirective($parse, $rootScope) {
    return {
        priority: 100,
        restrict: 'A',
        compile: function($element, attr) {
            var fn = $parse(attr.disableClick);
            return {
                pre: function link(scope, element) {
                    var eventName = 'click';
                    element.on(eventName, function(event) {
                        var callback = function() {
                            if (fn(scope, {$event: event})) {
                                event.stopImmediatePropagation();
                                event.preventDefault();
                                return false;
                            }
                        };
                        if ($rootScope.$$phase) {
                            scope.$evalAsync(callback);
                        } else {
                            scope.$apply(callback);
                        }
                    });
                },
                post: function() {}
            }
        }
    }
}
