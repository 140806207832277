
/* injects from baggage-loader */
require('./reports.component.html');

import angular from 'angular';
import $ from 'jquery';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';

import reportSpecs from '../../constants/reports/report-specifications';
import reportHeaders from '../../constants/reports/report-header-types';
import * as datetime from '../../utilities/datetime';

import './reports.scss';
import './reports.css';

// Two of the URLs in the mobile file are invalid (they point to non-existent files)
// import '@progress/kendo-ui/css/mobile/kendo.blueopal.mobile.css'

window.JSZip = JSZip; // Required for Kendo export to Excel
window.moment = moment;

angular.module('AaApp.Opp').component('reports', {
    templateUrl: '/app/components/reports/reports.component.html',
    controller: ReportsController,
    bindings: {
        kendo: '<',
        thisReportSpec: '<'
    }
});

/* @ngInject */
function ReportsController(_, $q, $state, logger, dateRangeTypes, dealerService, reportService, dateFormatService,
    vehicleService, storageService, pandoSurveyService, assetTypes, reportsMenu, $translate, $uibModal, $document, $logFactory) {
    var $ctrl = this;
    var kendo; // Set in $onChanges
    const log = $logFactory.get();

    $ctrl.fordPilotDealerInfo;
    $ctrl.reportsEnabled = false;
    $ctrl.reportEnabled = false;
    $ctrl.optionalParams;
    $ctrl.reportPromise;
    $ctrl.reportRenderedData;
    $ctrl.reportSpecification;
    $ctrl.headerData = {
        dealerId: null,
        makeId: null,
        startDate: null,
        endDate: null,
        showReportSettings: false,
        showRefresh: false,
        dealers: [],
        makes: [],
        dateRanges: [],
        dateRangeTypes: [
            dateRangeTypes.day,
            dateRangeTypes.last7Days,
            dateRangeTypes.last30Days,
            dateRangeTypes.last90Days,
            dateRangeTypes.monthToDate,
            dateRangeTypes.yearToDate,
            dateRangeTypes.userInput
        ],
        dateRangeTypeTranslateKeys: _.invert(dateRangeTypes),
        selectedDateRangeType: dateRangeTypes.last30Days,
        dateFormat: dateFormatService.getAlternateDateFormatString(),
        performanceReportDateRanges: []
    };
    $ctrl.headerData.dateRangeTypeTranslateKeys.day = 'yesterday';
    $ctrl.headerData.dateRangeTypeTranslateKeys.userInput = 'custom';
    $ctrl.headerData.dateRangeTypeTranslateKeys.MTD = 'MTD';
    $ctrl.headerData.dateRangeTypeTranslateKeys.YTD = 'YTD';

    //Remove yesterday/day from list if no access to useractivity
    var accessTo = reportService.getAuthorizedReports(storageService.getItem('moduleIds'));
    var hasUserActivityAccess = accessTo.filter((x) => {
        return x.id == 'userActivityReport';
    }).length > 0;
    if (!hasUserActivityAccess && $ctrl.headerData.dateRangeTypes.indexOf('D') > -1) {
        $ctrl.headerData.dateRangeTypes.splice($ctrl.headerData.dateRangeTypes.indexOf('D'), 1);
    }

    $ctrl.$onInit = init;
    $ctrl.$onChanges = $onChanges;
    $ctrl.changeDateRange = changeDateRange;
    $ctrl.datePickerSelection = datePickerSelection;
    $ctrl.generateReportExport = generateReportExport;
    $ctrl.loadReport = loadReport;
    $ctrl.loadReportPresetRange = loadReportPresetRange;
    $ctrl.reportNavigationCallback = reportNavigationCallback;
    $ctrl.updateMakes = updateMakes;
    $ctrl.updateSurveys = updateSurveys;
    $ctrl.passOptionalParameter = passOptionalParameter;
    $ctrl.onDealershipChanged = onDealershipChanged;
    $ctrl.openLmsTranscriptModal = openLmsTranscriptModal;

    // reportState:
    // 0 = Report Unavailable
    // 1 = Report Available but no Survey Data available
    // 2 = Report Available and Survey Data available
    $ctrl.REPORT_UNAVAILABLE = 0;
    $ctrl.REPORT_AVAILABLE_WITH_NO_DATA = 1;
    $ctrl.REPORT_AVAILABLE_WITH_DATA = 2;
    $ctrl.pandoReportState = $ctrl.REPORT_AVAILABLE_WITH_DATA;

    if ($state && $state.params) {
        // set local state params here
        if ($state.params.optionalParams) {
            $ctrl.optionalParams = $state.params.optionalParams;
        }
    }
    // default this to an emty array if it is not already set
    if (!$ctrl.optionalParams) {
        $ctrl.optionalParams = [];
    }

    function init() {
        var moduleIds = storageService.getItem('moduleIds');
        var authorizedReports = reportService.getAuthorizedReports(moduleIds)
        $ctrl.reportsEnabled = authorizedReports.length > 0;
        if (!$ctrl.reportsEnabled)
            return;

        var dateRangesTask = reportService.getDateRanges().then(function (dateRanges) {
            $ctrl.headerData.dateRanges = dateRanges;
            if ($ctrl.headerData.selectedDateRangeType != dateRangeTypes.userInput) {
                setPresetDateRange();
            }
        });

        var fordPilotStatusTask = reportService.getFordPilotStoreStatus().then(function (data) {
            $ctrl.fordPilotDealerInfo = data;
        });

        let reportHasEnterpriseVersion = $ctrl.thisReportSpec && $ctrl.thisReportSpec.enterprise;

        var dealers = dealerService.getSelectedDealers(reportHasEnterpriseVersion);
        $ctrl.headerData.dealers = dealers;

        var dealerMakesTask = getDealerMakes(dealers[0].id);

        // find out if user has permission to view dashboard report.
        var currentReport = _.find(reportsMenu, { 'id': $ctrl.thisReportSpec.titleKey });
        $ctrl.reportEnabled = reportService.isReportAuthorized(currentReport, moduleIds);
        if (!$ctrl.reportEnabled)
            return;

        var surveyTask = setPandoSurveyData();
        var perfRptTask = null;
        if ($ctrl.thisReportSpec.titleKey === reportSpecs.performanceReport.titleKey) {
            perfRptTask = getPerformanceReportAvailableMonths(dealers[0].id);
        }

        return $q.all([dateRangesTask, dealerMakesTask, fordPilotStatusTask, surveyTask, perfRptTask]).then(function () {
            loadHeaderData();
            if ($ctrl.thisReportSpec.titleKey === reportSpecs.performanceReport.titleKey) {
                // set date range for previous month
                $ctrl.headerData.selectedDateRangeType = dateRangeTypes.previousMonth;
                setPresetDateRange();
            }
            loadReport();
        });
    }

    function onDealershipChanged(options) {
        $ctrl.reportSpecification = getReportSpecForSelectedDealerId(options.dealerId);
        if ($ctrl.reportSpecification == reportSpecs.performanceReport) {
            return getPerformanceReportAvailableMonths(options.dealerId).then(function () {
                loadReport();
            });
        }
        else {
            loadReport();
        }
    }

    function $onChanges(changes) {
        if (changes.kendo && changes.kendo.currentValue) {
            kendo = changes.kendo.currentValue;
        }
    }

    function loadHeaderData() {
        var headerData = storageService.getItem('reportHeaderData');
        if (headerData) {
            if ($ctrl.headerData.dealers) {
                headerData.dealers = $ctrl.headerData.dealers;
            }
            if (!headerData.dealerId) {
                headerData.dealerId = headerData.dealers[0].id;
            }
            if ($ctrl.headerData.performanceReportDateRanges) {
                headerData.performanceReportDateRanges = $ctrl.headerData.performanceReportDateRanges;
            }
            $ctrl.headerData = headerData;
            if ($ctrl.optionalParams)
                updateOptionalParams("BaseUnits", headerData.BaseUnits);
        } else {
            $ctrl.headerData.dealerId = $ctrl.headerData.dealers[0].id;
            if ($ctrl.surveyFamilies) {
                $ctrl.headerData.surveyFamilyId = $ctrl.headerData.surveyFamilies[0].id;
                $ctrl.headerData.surveyId = $ctrl.headerData.surveys[0].id;
            }
        }
        $ctrl.reportSpecification = getReportSpecForSelectedDealerId($ctrl.headerData.dealerId);
    }

    function getReportSpecForSelectedDealerId(selectedDealerId) {
        const reportHasEnterpriseVersion = $ctrl.thisReportSpec.enterprise;
        const hasSelectedEnterpriseReport = selectedDealerId === 0;
        return reportHasEnterpriseVersion && hasSelectedEnterpriseReport
            ? $ctrl.thisReportSpec.enterprise
            : $ctrl.thisReportSpec;
    }

    function getDealerMakes(dealerId) {
        return vehicleService.getMakes(dealerId != null ? [dealerId] : null).then(function (response) {
            $ctrl.headerData.makes = getSimpleMakes(response);
            $ctrl.headerData.makeId = $ctrl.headerData.makes[0].makeId;
        });
    }

    function getSimpleMakes(response) {
        if (response && response.length > 0) {
            var simpleMakes = response.map(function (o) {
                return { makeId: o.makeID, makeName: o.makeName }
            });
            if (simpleMakes.length > 0) {
                simpleMakes.unshift({ makeId: -1, makeName: 'All Makes' });
            }
            return simpleMakes;
        } else {
            return [{ makeId: -1, makeName: 'All Makes' }];
        }
    }

    function setPandoSurveyData(surveyFamilyId) {
        return pandoSurveyService.getAccessibleSurveys()
            .then((surveyFamilies) => {
                if (surveyFamilies) {
                    if (surveyFamilies.length > 0) {
                        $ctrl.headerData.surveyFamilies = getSimpleSurveyFamilies(surveyFamilies);
                        $ctrl.headerData.surveyFamilyId = surveyFamilyId
                            ? surveyFamilyId
                            : $ctrl.headerData.surveyFamilies[0].id;

                        $ctrl.headerData.surveys = getSimpleSurveys(surveyFamilies, $ctrl.headerData.surveyFamilyId);
                        $ctrl.headerData.surveyId = $ctrl.headerData.surveys[0].id;
                        $ctrl.pandoReportState = $ctrl.REPORT_AVAILABLE_WITH_DATA;
                    } else {
                        $ctrl.pandoReportState = $ctrl.REPORT_AVAILABLE_WITH_NO_DATA;
                    }
                } else {
                    $ctrl.pandoReportState = $ctrl.REPORT_UNAVAILABLE;
                }
            })
            .catch((response) => {
                $ctrl.pandoReportState = $ctrl.REPORT_UNAVAILABLE;
            })
    }

    function getMonthYearLabel(date) {
        const cultureName = storageService.getItem('cultureName') || 'en-US';
        var startDate = moment(date, 'YYYY-M-D', true).locale(cultureName);
        return startDate.format("MMMM") + " " + startDate.format("YYYY");
    }

    function byDescendingDate(dateRange1, dateRange2) {
        if (moment(dateRange1.startDate, 'YYYY-M-D', true) > moment(dateRange2.startDate, 'YYYY-M-D', true)) {
            return -1;
        } else if (moment(dateRange1.startDate, 'YYYY-M-D', true) < moment(dateRange2.startDate, 'YYYY-M-D', true)) {
            return 1;
        } else {
            return 0;
        }
    }

    function getPerformanceReportAvailableMonths(dealerId) {
        return reportService.getPerformanceReportAvailableMonths(dealerId)
            .then((results) => {
                $ctrl.headerData.performanceReportDateRanges = [];
                if (results && results.length > 0) {
                    // limit to 6 months:
                    $ctrl.headerData.performanceReportDateRanges = results.sort(byDescendingDate).slice(0, 6).map(item => ({
                        startDate: item.startDate,
                        endDate: item.endDate,
                        text: getMonthYearLabel(item.startDate)
                    }));
                }
            });
    }

    function getSimpleSurveyFamilies(surveyFamilies) {
        if (surveyFamilies && surveyFamilies.length > 0) {
            return surveyFamilies.map(function (o) {
                return { id: o.ID, name: o.DisplayName }
            })
        } else {
            return [];
        }
    }

    function getSimpleSurveys(surveyFamilies, surveyFamilyId) {
        if (surveyFamilies && surveyFamilies.length > 0) {
            var surveyFamily = _.find(surveyFamilies, (o) => { return o.ID === surveyFamilyId });
            return surveyFamily.Surveys.map(function (o) {
                return { id: o.ID, name: o.DisplayName, startDate: o.SurveyStartDT, endDate: o.SurveyEndDT }
            })
        } else {
            return [];
        }
    }

    function setPerfReportDateRange(beginDate, endDate) {

        $ctrl.headerData.startDate = datetime.toSameLocalTimeInCurrentZone(new Date(beginDate));
        $ctrl.headerData.endDate = datetime.toSameLocalTimeInCurrentZone(new Date(endDate));
    }

    function setPresetDateRange() {
        if ($ctrl.headerData.selectedDateRangeType === dateRangeTypes.userInput)
            return;

        var selectedDateRange = _.find($ctrl.headerData.dateRanges, { code: $ctrl.headerData.selectedDateRangeType });
        $ctrl.headerData.startDate = datetime.toSameLocalTimeInCurrentZone(new Date(selectedDateRange.beginDate));
        $ctrl.headerData.endDate = datetime.toSameLocalTimeInCurrentZone(new Date(selectedDateRange.endDate));
    }

    function changeDateRange(dateRangeType) {
        $ctrl.headerData.selectedDateRangeType = dateRangeType;
        updateOptionalParams("DateRangeSelectionChanged", true);
        setPresetDateRange();
    }

    function validDates() {
        return angular.isDate($ctrl.headerData.startDate) && angular.isDate($ctrl.headerData.endDate);
    }

    function datePickerSelection(isStart) {
        $ctrl.headerData.showRefresh = false;
        if (!validDates()) return;

        $ctrl.headerData.selectedDateRangeType = dateRangeTypes.userInput;
        if ($ctrl.reportSpecification.header == reportHeaders.singleDate) {
            // report is ran for 4 months starting with the first of the month, if end date is first, we roll back 1 day
            if ($ctrl.headerData.endDate.getDate() === 1) {
                var endDateTicks = new Date($ctrl.headerData.endDate).setDate(0);
                $ctrl.headerData.endDate = new Date(endDateTicks);
            }
            var startMonthIndex = $ctrl.headerData.endDate.getMonth() - 3;
            var startMonthTicks = new Date($ctrl.headerData.endDate).setMonth(startMonthIndex, 1);
            $ctrl.headerData.startDate = new Date(startMonthTicks);
            loadReport();
        } else {
            $ctrl.headerData.showRefresh = true;
            if ($ctrl.headerData.startDate > $ctrl.headerData.endDate) {
                if (isStart) {
                    $ctrl.headerData.endDate = $ctrl.headerData.startDate;
                } else {
                    $ctrl.headerData.startDate = $ctrl.headerData.endDate;
                }
            }
            else if (isInvalidDateRange()) {
                if (isStart) {
                    var endDate = new Date($ctrl.headerData.startDate);
                    endDate.setFullYear(endDate.getFullYear() + 1);

                    $ctrl.headerData.endDate = endDate;
                } else {
                    var startDate = new Date($ctrl.headerData.endDate);
                    startDate.setFullYear(startDate.getFullYear() - 1);

                    $ctrl.headerData.startDate = startDate;
                }
            }
        }
        updateOptionalParams("DateRangeSelectionChanged", true);
    }

    function isInvalidDateRange() {
        var minDate = new Date($ctrl.headerData.endDate);
        minDate.setFullYear(minDate.getFullYear() - 1);

        var startDate = new Date($ctrl.headerData.startDate);
        return startDate < minDate;
    }

    function loadReportPresetRange(dateRangeType) {
        changeDateRange(dateRangeType);
        loadReport();
    }

    function updateMakes() {
        return getDealerMakes($ctrl.headerData.dealerId).then(loadReport);
    }

    function getDealerSelectionName() {
        var selectedDealer = _.find($ctrl.headerData.dealers, function (o) { return o.id === $ctrl.headerData.dealerId });
        return selectedDealer.name;
    }

    function getMakeSelectionName() {
        var selectedMake = _.find($ctrl.headerData.makes, function (o) { return o.makeId === $ctrl.headerData.makeId });
        
        return (selectedMake) ? selectedMake.makeName : '';
    }

    function updateSurveys() {
        return setPandoSurveyData($ctrl.headerData.surveyFamilyId).then(loadReport);
    }

    function getSurveyFamilySelectionName() {
        var selectedSurveyFamily = _.find($ctrl.headerData.surveyFamilies, function (s) { return s.id === $ctrl.headerData.surveyFamilyId; });
        return selectedSurveyFamily.name;
    }

    function getSurveySelectionName() {
        var selectedSurvey = _.find($ctrl.headerData.surveys, function (s) { return s.id === $ctrl.headerData.surveyId; });
        return selectedSurvey.name;
    }

    function showOneToOneExtraFields() {
        if ($ctrl.headerData.dealerId === 0) { return false; }

        if ($ctrl.fordPilotDealerInfo) {
            var dealerFordPilotStatus = _.find($ctrl.fordPilotDealerInfo, function (o) { return o.dealerID == $ctrl.headerData.dealerId });
            return dealerFordPilotStatus.isFordPilotStore;
        } else {
            return false;
        }
    }

    function isDealerInFordNationalProgram() {
        if ($ctrl.headerData.dealerId === 0) {
            return $ctrl.fordPilotDealerInfo.some(function (d) { return d.isFordPilotStore; });
        }
        if ($ctrl.fordPilotDealerInfo) {
            var dealerFordPilotStatus = _.find($ctrl.fordPilotDealerInfo, function (o) { return o.dealerID == $ctrl.headerData.dealerId });
            return dealerFordPilotStatus.isFordPilotStore;
        }
        else {
            return false;
        }
    }

    function showSingleHeaderReportParam() {
        return $ctrl.headerData.dealers[0].id === 0;
    }

    function createReportParams(isExport, optionalParams) {
        var params = [];
        if (angular.isArray(optionalParams)) {
            for (var i = 0; i < optionalParams.length; i++) {
                params.push(optionalParams[i]);
            }
        }
        if (isExport) {
            var dealerName = getDealerSelectionName();
            var makeSelection = getMakeSelectionName();
            params.push({ Key: 'DealerName', Value: dealerName }, { Key: 'Make', Value: makeSelection });
        }
        var isFnpDealer = isDealerInFordNationalProgram();
        switch ($ctrl.reportSpecification) {
            case reportSpecs.dashboardReport:
                var showSingleHeader = showSingleHeaderReportParam();
                var showO2OExtras = showOneToOneExtraFields();
                params.push({ Key: 'MakeId', Value: $ctrl.headerData.makeId }, { Key: 'ShowO2OExtras', Value: showO2OExtras }, { Key: 'Code', Value: $ctrl.headerData.selectedDateRangeType }, { Key: 'ShowSingleHeader', Value: showSingleHeader });
                break;
            case reportSpecs.dashboardReport.enterprise:
                params.push({ Key: 'MakeId', Value: $ctrl.headerData.makeId }, { Key: 'Code', Value: $ctrl.headerData.selectedDateRangeType });
                break;
            case reportSpecs.creditConvertReport:
            case reportSpecs.salesGrossReport:
            case reportSpecs.soldUnitsReport:
            case reportSpecs.salesGrossReport.enterprise:
            case reportSpecs.soldUnitsReport.enterprise:
                params.push({ Key: 'isFnpDealer', Value: isFnpDealer }, 
                { Key: 'MakeId', Value: $ctrl.headerData.makeId }, 
                { Key: 'TimeHourOffset', Value: '7' },
                { Key: 'ActiveTagSelectedTagIds', Value: $ctrl.headerData.ActiveTagSelectedTagIds});
                break;
            case reportSpecs.autoAssistantSoldUnitsReport:
            case reportSpecs.geoAlertSoldUnitsReport:
            case reportSpecs.onetoOneSoldUnitsReport:
            case reportSpecs.partnerExportSoldUnitsReport:
                params.push({ Key: 'MakeId', Value: $ctrl.headerData.makeId },
                { Key: 'TimeHourOffset', Value: '7' },
                { Key: 'ActiveTagSelectedTagIds', Value: $ctrl.headerData.ActiveTagSelectedTagIds});
                break;
            case reportSpecs.inventoryReport:
            case reportSpecs.serviceDriveSalesPerformanceReport:
            case reportSpecs.dataCleanseReport:
                params.push({ Key: 'MakeId', Value: $ctrl.headerData.makeId });
                break;
            case reportSpecs.certificationReport:
            case reportSpecs.certificationReport.enterprise:
                params.push({ Key: 'TimeHourOffset', Value: '7' }, { Key: 'CertificateId', Value: '8' });  // For now we'll only be reporting on this certificate
                var isEnterprise = ($ctrl.headerData.dealerId == 0);
                params.push({ key: "IsEnterprise", Value: isEnterprise });
                break;
            case reportSpecs.userActivityReport:
            case reportSpecs.userActivityReport.enterprise:
            case reportSpecs.geoAlertSoldUnitsReport.enterprise:
            case reportSpecs.onetoOneSoldUnitsReport.enterprise:
            case reportSpecs.autoAssistantSoldUnitsReport.enterprise:
            case reportSpecs.serviceDriveSalesPerformanceReport.enterprise:
            case reportSpecs.serviceLaneTechnologyReport:
            case reportSpecs.autoAssistantActivityReport:
                params.push({ Key: 'TimeHourOffset', Value: '7' });
                break;
            case reportSpecs.pandoSurveyReport:
                if ($ctrl.pandoReportState !== $ctrl.REPORT_AVAILABLE_WITH_DATA) return null;

                var survey = _.find($ctrl.headerData.surveys, function (o) { return o.id === $ctrl.headerData.surveyId; });
                params.push(
                    { Key: 'surveyId', Value: survey.id },
                    { Key: 'surveyName', Value: survey.name },
                    { Key: 'surveyStartDate', Value: survey.startDate },
                    { Key: 'surveyEndDate', Value: survey.endDate }
                );
                break;
            default:
                break;
        }
        return params;
    }

    async function loadReport(optionalParamKey, optionalParamValue) {
        if (optionalParamKey && optionalParamValue) {
            updateOptionalParams(optionalParamKey, optionalParamValue);
        }
        
        $ctrl.headerData.showRefresh = false;
        if (!validDates()) return;
        storageService.setItem('reportHeaderData', $ctrl.headerData);

        if ($ctrl.thisReportSpec.titleKey === "performanceReport") {
            //$ctrl.headerData.selectedDateRangeType = dateRangeTypes.previousMonth;
            //setPresetDateRange();
            //setPerfReportDateRange(beginDate, endDate);
        }
        var start = reportService.toZuluStringFromLocalDate($ctrl.headerData.startDate);
        var end = reportService.toZuluStringFromLocalDate($ctrl.headerData.endDate);
        var params = createReportParams(false, $ctrl.optionalParams);
        if (!params) return;
        var dealerId = $ctrl.headerData.dealerId == 0 ? $ctrl.headerData.dealers[1].id : $ctrl.headerData.dealerId;

        // check if the dealership is Auto Assistant enabled for Auto Assistant Sold Units report.
        if ($ctrl.reportSpecification.titleKey === "autoAssistantSoldUnitsReport") {
          var isAutoAssistantEnabled = await dealerService.getIsAssetEnabledForDealer(assetTypes.autoAssistant,dealerId);
          if (!isAutoAssistantEnabled) {
              $ctrl.reportSpecification.header = reportHeaders.dealerOnly;
              $ctrl.reportSpecification.pdfExportEnabled = false;
              $ctrl.reportSpecification.excelExportEnabled = false;
              $ctrl.reportSpecification.subscriptionEnabled = false;
              setReportDisbaledForDealerMessage();
              return;
          }
          else {
              $ctrl.reportSpecification.header = reportHeaders.full;
              $ctrl.reportSpecification.pdfExportEnabled = true;
              $ctrl.reportSpecification.excelExportEnabled = true;
              $ctrl.reportSpecification.subscriptionEnabled = true;
          }
        }

        // check if the dealership is GeoAlert enabled for GeoAlert Sold Units report.
        if ($ctrl.reportSpecification.titleKey === "geoAlertSoldUnitsReport") {
          var isGeoAlertEnabled = await dealerService.getIsAssetEnabledForDealer(assetTypes.geoAlert,dealerId);
          if (!isGeoAlertEnabled) {
              $ctrl.reportSpecification.header = reportHeaders.dealerOnly;
              $ctrl.reportSpecification.pdfExportEnabled = false;
              $ctrl.reportSpecification.excelExportEnabled = false;
              $ctrl.reportSpecification.subscriptionEnabled = false;
              setReportDisbaledForDealerMessage();
              return;
          }
          else {
              $ctrl.reportSpecification.header = reportHeaders.full;
              $ctrl.reportSpecification.pdfExportEnabled = true;
              $ctrl.reportSpecification.excelExportEnabled = true;
              $ctrl.reportSpecification.subscriptionEnabled = true;
          }
        }

        // check if the dealership is one-to-one enabled for onetoOne report.
        if ($ctrl.reportSpecification.titleKey === "onetoOneSoldUnitsReport") {
            if (!dealerService.getIsDealerOneToOneEnabled(dealerId)) {
                $ctrl.reportSpecification.header = reportHeaders.dealerOnly;
                $ctrl.reportSpecification.pdfExportEnabled = false;
                $ctrl.reportSpecification.excelExportEnabled = false;
                $ctrl.reportSpecification.subscriptionEnabled = false;
                setReportDisbaledForDealerMessage();
                return;
            }
            else {
                $ctrl.reportSpecification.header = reportHeaders.full;
                $ctrl.reportSpecification.pdfExportEnabled = true;
                $ctrl.reportSpecification.excelExportEnabled = true;
                $ctrl.reportSpecification.subscriptionEnabled = true;
            }
        }

        // check if the dealership is NoLongerOwns enabled for data cleanse report
        if ($ctrl.thisReportSpec.titleKey === "dataCleanseReport") {
            var isEnabled = await dealerService.getIsDealerNoLongerOwnsEnabled(dealerId);

            if (!isEnabled) {
                $ctrl.reportSpecification.header = reportHeaders.dealerOnly;
                setReportDisbaledForDealerMessage();
                return;
            }
        }

        $ctrl.reportPromise = reportService.getReport($ctrl.reportSpecification, start, end, dealerId, 'html', params)
            .then(function (reportRenderedData) {
                $ctrl.reportRenderedData = reportRenderedData;
                $ctrl.reportPromise = null;
            })
            .catch((response) => {
                setReportUnavailableContent();
            });
    }

    function setReportUnavailableContent() {
        $translate("reportUnavailable").then((response) => {
            $ctrl.reportRenderedData = "<div style='margin:15px'>" + response + "</div>";
            $ctrl.reportPromise = null;
        })
    }

    function setReportDisbaledForDealerMessage() {

        $translate($ctrl.reportSpecification.titleKey).then((reportTitle) => {
            $translate("reportNotEnabledForDealer", { reportName: reportTitle })
                .then((response) => {
                    $ctrl.reportRenderedData = "<div style='margin:15px'>" + response + "</div>";
                });
        });
    }

    function generateReportExport(type) {
        //temporary:  In the future, we will simply call newReportExport(type) on all requests.
        switch ($ctrl.reportSpecification.titleKey) {
            case "salesGrossReport":
            case "salesGrossEnterpriseReport":
            case "soldUnitsReport":
            case "soldUnitsEnterpriseReport":
            case "serviceDriveSalesPerformanceReport":
            case "serviceDriveEnterpriseReport":
            case "userActivityReport":
            case "userActivityEnterpriseReport":
            case "inventoryReport":
            case "autoAssistantSoldUnitsReport":
            case "autoAssistantSoldUnitsEnterpriseReport":  
            case "autoAssistantActivityReport":
            case "geoAlertSoldUnitsReport":
            case "geoAlertSoldUnitsEnterpriseReport":
            case "onetoOneSoldUnitsReport":
            case "onetoOneSoldUnitsEnterpriseReport":
            case "certificationReport":
            case "certificationEnterpriseReport":
            case "pandoSurveyReport":
            case "pandoUserActivityReport":
            case "pandoUserActivityEnterpriseReport":
            case "dataCleanseReport":
            case "creditConvertReport":
            case "partnerExportSoldUnitsReport":
                return newReportExport(type);
            default:
                return generateReportExportOld(type);
        }

    }

    function generateReportExportOld(type) {
        switch (type) {
            case 'pdf':
                return generatePdfExport();
            case 'excel':
                return generateExcelExport();
            default:
                logger.error("Unsupported export type: " + type);
        }
    }

    function generatePdfExport() {
        // temporary...
        newReportExport('pdf');
    }

    function getReportTitleForExport() {
        function returnResult(title) {
            var result = title.replace(/ /g, '');
            return result;
        }
        return $translate($ctrl.reportSpecification.titleKey).then(returnResult);
    }

    function newReportExport(fileType) {
        var headerType = '';
        var extension = '';
        var info = '';
        switch (fileType) {
            case 'pdf':
                headerType = 'application/pdf';
                extension = "pdf";
                info = 'Summary';
                break;
            case 'excel':
                headerType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                extension = "xlsx";
                info = 'Details';
                break;
            default:
                logger.error("Unsupported export type: " + fileType);
        }
        var date = moment(new Date()).format('YYYYMMDD');
        var start = reportService.toZuluStringFromLocalDate($ctrl.headerData.startDate);
        var end = reportService.toZuluStringFromLocalDate($ctrl.headerData.endDate);
        var params = createReportParams(true, $ctrl.optionalParams);
        var dealerId = $ctrl.headerData.dealerId == 0 ? $ctrl.headerData.dealers[1].id : $ctrl.headerData.dealerId;
        // create report export tasks
        var titleTask = getReportTitleForExport();
        var dataTask = reportService.getReport($ctrl.reportSpecification, start, end, dealerId, fileType, params);
        // run all tasks and save the result
        $ctrl.reportPromise = $q.all([titleTask, dataTask]).then(function (responses) {
            var title = responses[0];
            var response = responses[1];
            $ctrl.reportPromise = null;
            var fileName = `${title}-${info}-${date}.${extension}`;
            $ctrl.headerData.showReportSettings = false;
            try {
                var blob = new Blob([response.data], { type: headerType });
                saveAs(blob, fileName);
            } catch (e) {
                log.log(e);
            }
        }).catch((responses) => {
            setReportUnavailableContent();
        });
    }

    function generateExcelExport() {
        // Need to save multiple grids in separate sheets in the same excel file
        // http://docs.telerik.com/kendo-ui/controls/data-management/grid/how-to/excel/multiple-grid-export
        return getSheets().then(sheets => {
            if (sheets.length == 0) {
                // TODO: localize
                logger.info("This report cannot be exported to Excel");
                return;
            }

            const fileName = createFileName('report', '.xlsx');
            const workbook = new kendo.ooxml.Workbook({ sheets, fileName });
            return kendo.saveAs({ fileName, dataURI: workbook.toDataURL() });
        });

        function getSheets() {
            const grids = $('.k-grid').not('.inner-grid');
            const promises = grids.map((i, el) => {
                return new Promise((resolve, reject) => {
                    const grid = $(el).data('kendoGrid')
                    grid.bind('excelExport', e => {
                        e.preventDefault();
                        try {
                            const sheet = e.workbook.sheets[0];
                            for (const column of sheet.columns) {
                                column.width = 120;
                            }
                            resolve(sheet);
                        } catch (e) {
                            reject(e);
                        }
                    });
                    grid.saveAsExcel();
                });
            });

            return Promise.all(promises);
        }

        function createFileName(name, extension) {
            return name + kendo.toString(new Date(), " yyyy-MM-dd hh-mm-ss") + extension;
        }
    }

    function reportNavigationCallback(reportInfo) {
        var report = reportSpecs[reportInfo.name];
        if (!report) { throw new Error('This report does not exist: ' + reportInfo.name); }

        $ctrl.reportSpecification = report;
        if (reportInfo.dealerId) {
            $ctrl.headerData.dealerId = reportInfo.dealerId;
            storageService.setItem('reportHeaderData', $ctrl.headerData);
        }

        switch ($ctrl.reportSpecification) {
            case reportSpecs.soldUnitsReport:
            case reportSpecs.salesGrossReport:
                updateOptionalParams('ShowValidatedOnly', reportInfo.validatedOnly);
                break;
            default:
                break;
        }
        $state.go(reportInfo.name, { optionalParams: $ctrl.optionalParams }, { reload: true, inherit: true, notify: true });
    }

    function passOptionalParameter(toggleInfo) {
        updateOptionalParams(toggleInfo.name, toggleInfo.value);
        $ctrl.headerData[toggleInfo.name] = toggleInfo.value;
        storageService.setItem('reportHeaderData', $ctrl.headerData);
    }

    function updateOptionalParams(key, value) {
        // if this key exists in optionalParams then update the value
        if ($ctrl.optionalParams.some(function (o) { return o.Key === key; })) {
            var idx = $ctrl.optionalParams.findIndex(function (o) { return o.Key === key });
            $ctrl.optionalParams[idx].Value = value;
        } else { // otherwise add the key value pair to the optionalParms array
            $ctrl.optionalParams.push({ Key: key, Value: value });
        }
    }

    function getLmsTranscript(userId) {
        return reportService.getLmsTranscript(userId)
            .then(function (response) {
                return response.data;
            });
    }

    function openLmsTranscriptModal(userId) {

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            windowClass: 'large-modal',
            component: 'lmsTranscriptModal',
            resolve: {
                transcript: function () {
                    return getLmsTranscript(userId);
                }
            }
        });
    }

}