eulaModalService.$inject = ["$q", "$state", "$document", "$uibModal", "coreApi", "userService", "eulaStatusTypes", "eulaResponseTypes", "storageService"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('eulaModalService', eulaModalService);
/* @ngInject */

function eulaModalService($q, $state, $document, $uibModal, coreApi, userService, eulaStatusTypes, eulaResponseTypes, storageService) {
  var service = {
    validateEulaOnStart: validateEulaOnStart,
    validateEula: validateEula,
    statusId: null,
    isEmailEnabled: isEmailEnabled
  };

  function isEmailEnabled() {
    return validateStatusId().then(function () {
      switch (service.statusId) {
        case eulaStatusTypes.fullyAccepted:
          return true;

        case eulaStatusTypes.notAccepted:
          return false;

        case eulaStatusTypes.canadaAddendumNeeded:
          return false;

        case eulaStatusTypes.canadaAddendumDeferred:
          return false;
      }

      return true;
    });
  }

  function validateEulaOnStart() {
    return userService.getEulaStatus().then(function (sId) {
      storageService.setItem('statusId', sId);
      service.statusId = sId;

      if (service.statusId != eulaStatusTypes.notAccepted) {
        return processStatus();
      }
    });
  }

  function validateEula() {
    return processStatus();
  }

  function validateStatusId() {
    if (!service.statusId) {
      service.statusId = storageService.getItem('statusId');

      if (service.statusId == null) {
        return validateEulaOnStart();
      }
    }

    return $q.resolve();
  }

  function processStatus() {
    return validateStatusId().then(function () {
      switch (service.statusId) {
        case eulaStatusTypes.fullyAccepted:
          //do nothing.  The response is known.
          return $q.resolve(true);

        case eulaStatusTypes.notAccepted:
          return challengeResponse(0);
        // 0 = Canada Addendum. TODO: make a type that corresponds to "Eula" table.

        case eulaStatusTypes.canadaAddendumNeeded:
          return challengeResponse(0);
        // 0 = Canada Addendum. TODO: make a type that corresponds to "Eula" table.

        case eulaStatusTypes.canadaAddendumDeferred:
          return challengeResponse(0);
        // 0 = Canada Addendum. TODO: make a type that corresponds to "Eula" table.
      }
    });
  }

  function openModal(options) {
    options.appendTo = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open(options); //wierdest factory constructor ever.

    return modalInstance;
  }

  function challengeResponse(eulaId) {
    return userService.getEula(eulaId).then(function (data) {
      var modal = openModal({
        animation: true,
        component: 'eulaModal',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          title: function title() {
            return "End User License Agreement - CASL Compliance Addendum"; //change this
          },
          message: function message() {
            return data.eulaText;
          },
          warning: function warning() {
            return "Note: Declining or deferring acceptance of this addendum will result in all email functionality within AutoAlert to be disabled until such a time as the addendum is accepted.";
          }
        }
      });
      return modal.result.then(function (modalResponse) {
        switch (modalResponse) {
          case "accept":
            userService.postResponse(eulaId, eulaResponseTypes.accept); //addendum ID

            service.statusId = eulaStatusTypes.fullyAccepted;
            storageService.setItem('statusId', eulaStatusTypes.fullyAccepted);
            return true;

          case "decline":
            userService.postResponse(eulaId, eulaResponseTypes.decline); //addendum ID

            service.statusId = eulaStatusTypes.notAccepted;
            storageService.setItem('statusId', eulaStatusTypes.notAccepted);
            return false;

          case "defer":
            userService.postResponse(eulaId, eulaResponseTypes.defer); //addendum ID

            service.statusId = eulaStatusTypes.canadaAddendumDeferred;
            storageService.setItem('statusId', eulaStatusTypes.canadaAddendumDeferred);
            return false;
        }
      });
    });
  }

  return service;
}