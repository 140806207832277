"use strict";
// import { Component, OnInit } from '@angular/core';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
// @Component({
//   selector: 'email-template-action',
//   templateUrl: './email-template-action.component.html',
//   styleUrls: ['./email-template-action.component.scss']
// })
// export class EmailTemplateActionComponent implements OnInit {
//   constructor() { }
//   ngOnInit() {
//   }
// }
var core_1 = require("@angular/core");
var static_1 = require("@angular/upgrade/static");
var EmailTemplateActionComponent = /** @class */ (function (_super) {
    __extends(EmailTemplateActionComponent, _super);
    function EmailTemplateActionComponent(elementRef, injector) {
        var _this = _super.call(this, 'emailTemplateAction', elementRef, injector) || this;
        _this.onComplete = new core_1.EventEmitter();
        return _this;
    }
    return EmailTemplateActionComponent;
}(static_1.UpgradeComponent));
exports.EmailTemplateActionComponent = EmailTemplateActionComponent;
