
/* injects from baggage-loader */
require('./email-info-edit-modal.component.html');

import angular from 'angular';
(function () {
    angular.module('AaApp.Opp').component('emailInfoEditModal', {
        templateUrl: '/app/components/deal-sheet/modals/email-info-edit-modal.component.html',
        controller: SendTestEmailModalController,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    });

    /* ngInject */
    function SendTestEmailModalController() {
        var $ctrl = this;

    }
})();