import { Inject, Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, flatMap, map, take } from 'rxjs/operators';
import { LegacyService } from '../ajs-upgraded-providers';

@Injectable({
    providedIn: 'root',
})

export class AdminTemplatesService {
    constructor(@Inject(LegacyService) private legacyService
    ) {
    }

}
