"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var CustomerConnectSubDetailComponent = /** @class */ (function () {
    function CustomerConnectSubDetailComponent() {
        this.onAction = new core_1.EventEmitter();
        this.onLogScheduleSubmit = new core_1.EventEmitter();
        this.ActivityActionEnum = constants_1.ActivityActionEnum;
    }
    CustomerConnectSubDetailComponent.prototype.ngOnInit = function () {
    };
    return CustomerConnectSubDetailComponent;
}());
exports.CustomerConnectSubDetailComponent = CustomerConnectSubDetailComponent;
