import angular from 'angular';

angular.module('AaApp.Opp').constant('vehicleSelectColumns', {
    vehicleType: 1,
    stockNo: 2,
    yearID: 3,
    trim: 4,
    options: 5,
    price: 6,
    mileage: 7,
    color: 8,
    daysInStock: 9,
    payments: 10
});
