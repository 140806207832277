/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ActivityActionTargetEntityRequest } from '../models/activity-action-target-entity-request';
import { ActivityActionResponseModel } from '../models/activity-action-response-model';
import { ActivityActionSubmissionModel } from '../models/activity-action-submission-model';
import { ActivityExtendedWithVwSearchDto } from '../models/activity-extended-with-vw-search-dto';
import { ActivityWithEntityDto } from '../models/activity-with-entity-dto';
import { UpgradeProposalActivityRequest } from '../models/upgrade-proposal-activity-request';
import { ActivityDto } from '../models/activity-dto';
@Injectable({
  providedIn: 'root',
})
class ActivityClientService extends __BaseService {
  static readonly RunActivityRulePOSTPath = '/api/Activity/RunActivityRule';
  static readonly SubmitActionPOSTPath = '/api/Activity/SubmitAction';
  static readonly GetAgendaItemsGETPath = '/api/Activity/GetAgendaItems';
  static readonly GetAgendaItemsWithEntityDataGETPath = '/api/Activity/GetAgendaItemsWithEntityData';
  static readonly GetActivitiesForEntityPOSTPath = '/api/Activity/GetActivitiesForEntity';
  static readonly GetNewActivityNotificationsByEntityidGETPath = '/api/Activity/GetNewActivityNotifications/{entityId}';
  static readonly AddUpgradeProposalActivityNotePOSTPath = '/api/Activity/UpgradeProposal';
  static readonly ByIdGETPath = '/api/Activity/{id}';
  static readonly ByIdDELETEPath = '/api/Activity/{id}';
  static readonly GetManyGETPath = '/api/Activity/GetMany';
  static readonly PUTPath = '/api/Activity';
  static readonly POSTPath = '/api/Activity';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   */
  RunActivityRulePOSTResponse(request?: ActivityActionTargetEntityRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Activity/RunActivityRule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  RunActivityRulePOST(request?: ActivityActionTargetEntityRequest): __Observable<null> {
    return this.RunActivityRulePOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param submission undefined
   * @return Success
   */
  SubmitActionPOSTResponse(submission?: ActivityActionSubmissionModel): __Observable<__StrictHttpResponse<ActivityActionResponseModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = submission;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Activity/SubmitAction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActivityActionResponseModel>;
      })
    );
  }
  /**
   * @param submission undefined
   * @return Success
   */
  SubmitActionPOST(submission?: ActivityActionSubmissionModel): __Observable<ActivityActionResponseModel> {
    return this.SubmitActionPOSTResponse(submission).pipe(
      __map(_r => _r.body as ActivityActionResponseModel)
    );
  }

  /**
   * @param params The `ActivityClientService.GetAgendaItemsGETParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `flushCache`:
   *
   * @return Success
   */
  GetAgendaItemsGETResponse(params: ActivityClientService.GetAgendaItemsGETParams): __Observable<__StrictHttpResponse<Array<ActivityExtendedWithVwSearchDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.flushCache != null) __params = __params.set('flushCache', params.flushCache.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Activity/GetAgendaItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActivityExtendedWithVwSearchDto>>;
      })
    );
  }
  /**
   * @param params The `ActivityClientService.GetAgendaItemsGETParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `flushCache`:
   *
   * @return Success
   */
  GetAgendaItemsGET(params: ActivityClientService.GetAgendaItemsGETParams): __Observable<Array<ActivityExtendedWithVwSearchDto>> {
    return this.GetAgendaItemsGETResponse(params).pipe(
      __map(_r => _r.body as Array<ActivityExtendedWithVwSearchDto>)
    );
  }

  /**
   * @param params The `ActivityClientService.GetAgendaItemsWithEntityDataGETParams` containing the following parameters:
   *
   * - `viewAllUsers`:
   *
   * - `take`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `activityTypes`:
   *
   * @return Success
   */
  GetAgendaItemsWithEntityDataGETResponse(params: ActivityClientService.GetAgendaItemsWithEntityDataGETParams): __Observable<__StrictHttpResponse<Array<ActivityWithEntityDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewAllUsers != null) __params = __params.set('viewAllUsers', params.viewAllUsers.toString());
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    (params.activityTypes || []).forEach(val => {if (val != null) __params = __params.append('activityTypes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Activity/GetAgendaItemsWithEntityData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActivityWithEntityDto>>;
      })
    );
  }
  /**
   * @param params The `ActivityClientService.GetAgendaItemsWithEntityDataGETParams` containing the following parameters:
   *
   * - `viewAllUsers`:
   *
   * - `take`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `activityTypes`:
   *
   * @return Success
   */
  GetAgendaItemsWithEntityDataGET(params: ActivityClientService.GetAgendaItemsWithEntityDataGETParams): __Observable<Array<ActivityWithEntityDto>> {
    return this.GetAgendaItemsWithEntityDataGETResponse(params).pipe(
      __map(_r => _r.body as Array<ActivityWithEntityDto>)
    );
  }

  /**
   * @param params The `ActivityClientService.GetActivitiesForEntityPOSTParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `activityTypes`:
   *
   * - `activityStatuses`:
   */
  GetActivitiesForEntityPOSTResponse(params: ActivityClientService.GetActivitiesForEntityPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    (params.activityTypes || []).forEach(val => {if (val != null) __params = __params.append('activityTypes', val.toString())});
    (params.activityStatuses || []).forEach(val => {if (val != null) __params = __params.append('activityStatuses', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Activity/GetActivitiesForEntity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ActivityClientService.GetActivitiesForEntityPOSTParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `activityTypes`:
   *
   * - `activityStatuses`:
   */
  GetActivitiesForEntityPOST(params: ActivityClientService.GetActivitiesForEntityPOSTParams): __Observable<null> {
    return this.GetActivitiesForEntityPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  GetNewActivityNotificationsByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Activity/GetNewActivityNotifications/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetNewActivityNotificationsByEntityidGET(entityId: string): __Observable<null> {
    return this.GetNewActivityNotificationsByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   */
  AddUpgradeProposalActivityNotePOSTResponse(request?: UpgradeProposalActivityRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Activity/UpgradeProposal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  AddUpgradeProposalActivityNotePOST(request?: UpgradeProposalActivityRequest): __Observable<null> {
    return this.AddUpgradeProposalActivityNotePOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<ActivityDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Activity/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActivityDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<ActivityDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as ActivityDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Activity/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<ActivityDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Activity/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ActivityDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<ActivityDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<ActivityDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: ActivityDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: ActivityDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: ActivityDto): __Observable<__StrictHttpResponse<ActivityDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActivityDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: ActivityDto): __Observable<ActivityDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as ActivityDto)
    );
  }
}

module ActivityClientService {

  /**
   * Parameters for GetAgendaItemsGET
   */
  export interface GetAgendaItemsGETParams {
    take?: number;
    flushCache?: boolean;
  }

  /**
   * Parameters for GetAgendaItemsWithEntityDataGET
   */
  export interface GetAgendaItemsWithEntityDataGETParams {
    viewAllUsers?: boolean;
    take?: number;
    dateTo?: string;
    dateFrom?: string;
    activityTypes?: Array<1 | 2 | 3 | 4 | 5 | 6>;
  }

  /**
   * Parameters for GetActivitiesForEntityPOST
   */
  export interface GetActivitiesForEntityPOSTParams {
    entityId?: string;
    activityTypes?: Array<number>;
    activityStatuses?: Array<number>;
  }
}

export { ActivityClientService }
