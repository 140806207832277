"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerCultures = [
    { name: 'af-ZA', displayName: 'Afrikaans' },
    { name: 'sq-AL', displayName: 'Albanian' },
    { name: 'ar-SA', displayName: 'Arabic' },
    { name: 'hy-AM', displayName: 'Armenian' },
    { name: 'bn-BD', displayName: 'Bangla (Bangladesh)' },
    { name: 'bn-IN', displayName: 'Bangla (Bengali India)' },
    { name: 'bs-Latn-BA', displayName: 'Bosnian (Latin)' },
    { name: 'bg-BG', displayName: 'Bulgarian' },
    { name: 'ca-ES', displayName: 'Catalan' },
    { name: 'ca-ES-valencia', displayName: 'Catalan (Valencia)' },
    { name: 'zh-CN', displayName: 'Chinese (Simplified)' },
    { name: 'zh-TW', displayName: 'Chinese (Traditional)' },
    { name: 'hr-HR', displayName: 'Croatian' },
    { name: 'cs-CZ', displayName: 'Czech' },
    { name: 'da-DK', displayName: 'Danish' },
    { name: 'nl-NL', displayName: 'Dutch' },
    { name: 'en-US', displayName: 'English (US)' },
    { name: 'en-CA', displayName: 'English (Canada)' },
    { name: 'et-EE', displayName: 'Estonian' },
    { name: 'fi-FI', displayName: 'Finnish' },
    { name: 'fr-FR', displayName: 'French' },
    { name: 'fr-CA', displayName: 'French (Canada)' },
    { name: 'ka-GE', displayName: 'Georgian' },
    { name: 'de-DE', displayName: 'German' },
    { name: 'el-GR', displayName: 'Greek' },
    { name: 'he-IL', displayName: 'Hebrew' },
    { name: 'hi-IN', displayName: 'Hindi' },
    { name: 'hu-HU', displayName: 'Hungarian' },
    { name: 'is-IS', displayName: 'Icelandic' },
    { name: 'id-ID', displayName: 'Indonesian' },
    { name: 'ga-IE', displayName: 'Irish' },
    { name: 'it-IT', displayName: 'Italian' },
    { name: 'ja-JP', displayName: 'Japanese' },
    { name: 'ko-KR', displayName: 'Korean' },
    { name: 'lv-LV', displayName: 'Latvian' },
    { name: 'lt-LT', displayName: 'Lithuanian' },
    { name: 'lb-LU', displayName: 'Luxembourgish' },
    { name: 'mk-MK', displayName: 'Macedonian (North Macedonia)' },
    { name: 'ms-MY', displayName: 'Malay (Latin)' },
    { name: 'mt-MT', displayName: 'Maltese' },
    { name: 'ne-NP', displayName: 'Nepali' },
    { name: 'nb-NO', displayName: 'Norwegian Bokmål' },
    { name: 'nn-NO', displayName: 'Norwegian Nynorsk' },
    { name: 'fa-IR', displayName: 'Persian (Farsi)' },
    { name: 'pl-PL', displayName: 'Polish' },
    { name: 'pt-PT', displayName: 'Portuguese (Portugal)' },
    { name: 'pt-BR', displayName: 'Portuguese (Brazil)' },
    { name: 'ro-RO', displayName: 'Romanian' },
    { name: 'ru-RU', displayName: 'Russian' },
    { name: 'gd-GB', displayName: 'Scottish Gaelic' },
    { name: 'sr-Cyrl-RS', displayName: 'Serbian (Cyrillic, Serbia)' },
    { name: 'sr-Latn-RS', displayName: 'Serbian (Latin, Serbia)' },
    { name: 'sr-Cyrl-BA', displayName: 'Serbian (Cyrillic, Bosnia & Herzegovina)' },
    { name: 'sk-SK', displayName: 'Slovak' },
    { name: 'sl-SI', displayName: 'Slovenian' },
    { name: 'es-ES', displayName: 'Spanish' },
    { name: 'sv-SE', displayName: 'Swedish' },
    { name: 'th-TH', displayName: 'Thai' },
    { name: 'tr-TR', displayName: 'Turkish' },
    { name: 'uk-UA', displayName: 'Ukrainian' },
    { name: 'vi-VN', displayName: 'Vietnamese' },
    { name: 'cy-GB', displayName: 'Welsh' },
];
