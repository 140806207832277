
/* injects from baggage-loader */
require('./data-entry-rates.component.html');

import angular from 'angular';
import $ from 'jquery';
import './data-entry-rates.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';

angular.module('AaApp.Opp').component('dataEntryRates', {
    templateUrl: '/app/components/data-entry/data-entry-rates.component.html',
    controller: DataEntryRatesController,
    bindings: {
        kendo: '<',
    }
});


function DataEntryRatesController($translate, $scope, $q, $filter, vehicleService, dealerService, dataEntryService, storageService, $uibModal, $document, saleTypes, cultures, logger) {
    "ngInject";

    const $ctrl = this;
    const cultureName = storageService.getItem('cultureName') || 'en-US';

    $ctrl.$onInit = init;
    $ctrl.onDealerSelect = onDealerSelect;
    $ctrl.onNotifyUnsavedData = onNotifyUnsavedData;


    $ctrl.isOverAllowanceNotSaved = false;
    $ctrl.isMarkUpNotSaved = false;
    $ctrl.isUsedRatesNotSaved = false;
    $ctrl.currentTab = 1;
    
    function onNotifyUnsavedData(result) {        
        if (result.tab == "markUp") $ctrl.isMarkUpNotSaved = result.value;
        if (result.tab == "overAllowance") $ctrl.isOverAllowanceNotSaved = result.value;
        if (result.tab == "usedRates") $ctrl.isUsedRatesNotSaved = result.value;
    }


    function init() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        $ctrl.selectedDealerId = $ctrl.dealers[0].id;
        $ctrl.tabDealerId = $ctrl.dealers[0].id;

        getTranslations();
    }

    function getTranslations() {        
        $q.all({
            'saveConfirmMessage': $translate('saveConfirmMessage'),
            'saveWarningMessage': $translate('saveWarningMessage'),
        }).then(function (stuff) {
            $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
            $ctrl.saveWarningMessage = stuff.saveWarningMessage;
        });
    }



    function onDealerSelect(previousValue) {        
        if ($ctrl.isOverAllowanceNotSaved || $ctrl.isMarkUpNotSaved || $ctrl.isUsedRatesNotSaved) {

            var modal = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "pleaseConfirm";
                    },
                    message: function () {
                        return $ctrl.saveWarningMessage;
                    },
                    submitButtonVisible: function () {
                        return true;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    submitButtonText: function () {
                        return "continue";
                    }
                }
            });

            modal.result.then(function () {                                
                $ctrl.tabDealerId = $ctrl.selectedDealerId;
                $ctrl.isOverAllowanceNotSaved = false;
                $ctrl.isMarkUpNotSaved = false;
                $ctrl.isUsedRatesNotSaved = false;
            })
            .catch(function () {
                $ctrl.selectedDealerId = previousValue;
            });

        }
        else {            
            $ctrl.tabDealerId = $ctrl.selectedDealerId;
        }




    }



} 
