import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { DealSheetOpportunity } from '../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { LogActionShortcutService } from '../../../services/log-action-shortcut.service';

@Component({
    selector: 'log-action-shortcuts',
    templateUrl: './log-action-shortcuts.component-ng.html',
    styleUrls: ['./log-action-shortcuts.component.scss']
})
export class LogActionShortcutsComponent implements OnInit {

    constructor(private logActionShortcutService: LogActionShortcutService) { }

    @Output() logShortcut = new EventEmitter<any>();
    @Input() isTextingEnabled: boolean;
    @Input() permissions: DealSheetPermissions;
    @Input() opportunity: DealSheetOpportunity;

    ngOnInit() {
    }

    public logCall() {
        this.logShortcut.emit();
        this.logActionShortcutService.logCall();
    }

    public logNote() {
        this.logShortcut.emit();
        this.logActionShortcutService.logNote();
    }

    public scheduleAppt() {
        this.logShortcut.emit();
        this.logActionShortcutService.scheduleAppt();
    }

}
