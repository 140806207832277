"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var services_1 = require("../generated/services");
var TextMessageService = /** @class */ (function () {
    function TextMessageService(legacyService, textMessageClientService) {
        this.legacyService = legacyService;
        this.textMessageClientService = textMessageClientService;
    }
    // The "logic" in this method is attempting to parse out the urls and determine which are upgrade proposals and which are dealer contact info urls
    TextMessageService.replaceUrlStringWithHtmlLink = function (message, useWhiteLink) {
        var index = 0;
        var urls = []; // Used to hold all the found urls with http in them.
        var infoUrlNumber = -1;
        while (index < message.length) {
            var urlStartIndex = message.indexOf('http', index);
            if (urlStartIndex < 0)
                break;
            var urlEndIndex = message.indexOf(' ', urlStartIndex);
            var urlSecondEndIndex = message.indexOf('<br />', urlStartIndex);
            if (urlSecondEndIndex != -1 && urlSecondEndIndex < urlEndIndex) // Determine if the url ends with a line break or a space
                urlEndIndex = urlSecondEndIndex;
            if (urlEndIndex < 0) {
                urlEndIndex = message.length;
            }
            index = urlEndIndex;
            urls.push(message.substring(urlStartIndex, urlEndIndex));
            // Used to determine if this is a dealer contact info url based on the string 'Info: ' being in front *wish there was a better way*
            if (message.indexOf('Info: ', urlStartIndex - 6) > 0)
                infoUrlNumber = urls.length - 1;
        }
        for (var i = 0; i < urls.length; i++) {
            // Had to embed the style here because of angular's view encapsulation. The alternative was to turn view encapsulation off entirely for the component
            if (i == infoUrlNumber) {
                // Since this function is used in different UI spots the styling color for the link text is different for different backgrounds
                if (useWhiteLink) {
                    message = message.replace(urls[i], "<a style=\"color:white\" iscontactinfo href='" + urls[i] + "' target=\"_blank\">" + urls[i] + "</a>");
                }
                else {
                    message = message.replace(urls[i], "<a iscontactinfo href='" + urls[i] + "' target=\"_blank\">" + urls[i] + "</a>");
                }
            }
            else {
                if (useWhiteLink) {
                    message = message.replace(urls[i], "<a style=\"color:white\" href='#0'>" + urls[i] + "</a>");
                }
                else {
                    message = message.replace(urls[i], "<a href='#0'>" + urls[i] + "</a>");
                }
            }
        }
        return message;
    };
    TextMessageService.prototype.sendText = function (newMessage) {
        return this.textMessageClientService.SendPOST(newMessage)
            .pipe(operators_1.take(1));
    };
    TextMessageService.prototype.sendTextWithUpgradeProposal = function (newMessage, opportunity) {
        var _this = this;
        return rxjs_1.from(this.legacyService.getCalculationParameters(opportunity))
            .pipe(operators_1.flatMap(function (upgradeProposalCalcParameters) {
            return _this.textMessageClientService.SendWithUpgradeProposalPOST({ textRequest: newMessage, proposalRequest: upgradeProposalCalcParameters })
                .pipe(operators_1.take(1));
        }));
    };
    /**
     * This method is used to get all text messages for a customer
     * If a messageId is provided, only messages that occurred after that id will be returned
     * @param customerId Id of the customer to get
     * @param textMessageId Id of the textMessage to get history after
     * "/api/TextMessage/GeNewMessagesByCustomerId/{customerId}/{messageId}"
     */
    TextMessageService.prototype.getHistoryByCustomerId = function (customerId, textMessageId) {
        var _this = this;
        {
            var observable = (textMessageId) ?
                this.textMessageClientService.GetMessagesByCustomerIdByCustomeridByMessageidGET({ customerId: customerId, messageId: textMessageId }) :
                this.textMessageClientService.GetMessagesByCustomerIdByCustomeridGET(customerId);
            return observable
                .pipe(operators_1.take(1), operators_1.map(function (response) {
                return _this.mapGetResponse(response);
            }), operators_1.catchError(function (error) {
                console.error(error);
                return rxjs_1.throwError('Error retrieveing text message history.');
            }));
        }
    };
    TextMessageService.prototype.mapGetResponse = function (textMessages) {
        if (Array.isArray(textMessages)) {
            textMessages.forEach(function (r) {
                // Replace text line breaks with html line breaks so they can be displayed properly
                r.message = r.message.replace(/\n\r?/g, '<br />');
            });
            return textMessages;
        }
        return [];
    };
    TextMessageService.prototype.getHistory = function (opportunity) {
        return this.getHistoryByCustomerId(opportunity.customer.id);
    };
    TextMessageService.prototype.downloadUpgradeProposalIfAvailable = function ($event, textMessage) {
        if ($event.target.tagName != 'A') {
            return;
        }
        // Determine if this is one of our url shortened urls or unshortened up url
        var isFzlnk = ($event.target.outerHTML.indexOf('aalnk') > -1 || $event.target.outerHTML.indexOf('fzlnk') > -1 || $event.target.outerHTML.indexOf('myupgradeproposal') > -1);
        // This triggers an upgrade proposal if it is a shortened url and not the dealer contact info url
        // iscontactinfo is set in replaceUrlStringWithHtmlLink()
        if (textMessage && textMessage.activityId > 0 && isFzlnk && $event.target.outerHTML.indexOf('iscontactinfo') == -1) {
            this.legacyService.downloadUpgradeProposalByActivity({
                activityId: textMessage.activityId
            });
        }
    };
    TextMessageService.prototype.getTextableStatus = function (customer, dealerId) {
        var customerDto = {
            CellPhone: (customer.cellPhoneNumber) ? customer.cellPhoneNumber.value : '',
            DealerID: dealerId,
            InvalidContacts: (customer.invalidContacts) ? customer.invalidContacts.join(',') : '',
            CustomerID: customer.id
        };
        return this.textMessageClientService.GetTextableStatusGET(customerDto)
            .pipe(operators_1.take(1));
    };
    TextMessageService.prototype.isUserNumberTextable = function () {
        return this.textMessageClientService.IsUserNumberTextableGET()
            .pipe(operators_1.take(1));
    };
    return TextMessageService;
}());
exports.TextMessageService = TextMessageService;
