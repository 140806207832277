"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("./../../services/user.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var models_1 = require("../../generated/models");
var AdminSettingsInventoryComponent = /** @class */ (function () {
    function AdminSettingsInventoryComponent(modalService, tService, userService, logger) {
        this.modalService = modalService;
        this.tService = tService;
        this.userService = userService;
        this.logger = logger;
        this.dsa = {};
        this.onSaveChanges = new core_1.EventEmitter();
    }
    AdminSettingsInventoryComponent.prototype.rvsMove = function (direction) {
        if (this.rvsSelectedItem && this.rvsSelectedItem.length == 1) {
            var index = this.dsa.dealerInventoryMatchRankingField.indexOf(this.rvsSelectedItem[0]);
            var newIndex = 0;
            if (direction == '+')
                newIndex = index - 1;
            else
                newIndex = index + 1;
            if (newIndex < 0 || newIndex > this.dsa.dealerInventoryMatchRankingField.length - 1)
                return;
            var temp = this.dsa.dealerInventoryMatchRankingField[newIndex];
            this.dsa.dealerInventoryMatchRankingField[newIndex] = this.dsa.dealerInventoryMatchRankingField[index];
            this.dsa.dealerInventoryMatchRankingField[index] = temp;
            var order = 1;
            for (var _i = 0, _a = this.dsa.dealerInventoryMatchRankingField; _i < _a.length; _i++) {
                var item = _a[_i];
                item.order = order++;
            }
        }
        this.dsa.dealerInventoryMatchRankingField;
    };
    return AdminSettingsInventoryComponent;
}());
exports.AdminSettingsInventoryComponent = AdminSettingsInventoryComponent;
