// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'personalized-offer',
//   templateUrl: './personalized-offer.component.html',
//   styleUrls: ['./personalized-offer.component.scss']
// })
// export class PersonalizedOfferComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }


import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'personalized-offer'
})
export class PersonalizedOfferComponent extends UpgradeComponent {
    @Input() programDetails: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('personalizedOffer', elementRef, injector);
    }
}