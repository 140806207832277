/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerRebateExtDto } from '../models/dealer-rebate-ext-dto';
@Injectable({
  providedIn: 'root',
})
class DealerRebateClientService extends __BaseService {
  static readonly GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETPath = '/api/DealerRebate/GetDealerRebates/{dealerId}/{makeId}/{year}/{modelId}/{trimId}';
  static readonly UpdateDealerRebatesPATCHPath = '/api/DealerRebate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DealerRebateClientService.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `trimId`:
   *
   * - `modelId`:
   *
   * - `makeId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params: DealerRebateClientService.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETParams): __Observable<__StrictHttpResponse<Array<DealerRebateExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerRebate/GetDealerRebates/${params.dealerId}/${params.makeId}/${params.year}/${params.modelId}/${params.trimId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerRebateExtDto>>;
      })
    );
  }
  /**
   * @param params The `DealerRebateClientService.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `trimId`:
   *
   * - `modelId`:
   *
   * - `makeId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGET(params: DealerRebateClientService.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETParams): __Observable<Array<DealerRebateExtDto>> {
    return this.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params).pipe(
      __map(_r => _r.body as Array<DealerRebateExtDto>)
    );
  }

  /**
   * @param rebates undefined
   */
  UpdateDealerRebatesPATCHResponse(rebates?: Array<DealerRebateExtDto>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = rebates;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/DealerRebate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param rebates undefined
   */
  UpdateDealerRebatesPATCH(rebates?: Array<DealerRebateExtDto>): __Observable<null> {
    return this.UpdateDealerRebatesPATCHResponse(rebates).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DealerRebateClientService {

  /**
   * Parameters for GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGET
   */
  export interface GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGETParams {
    year: number;
    trimId: number;
    modelId: number;
    makeId: number;
    dealerId: number;
  }
}

export { DealerRebateClientService }
