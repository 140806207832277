"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NgNoCacheInterceptor = /** @class */ (function () {
    function NgNoCacheInterceptor() {
    }
    NgNoCacheInterceptor.prototype.intercept = function (req, next) {
        var newHeaders = {};
        if (!req.headers.has('Cache-Control') && !req.headers.has('Pragma')) {
            newHeaders['Cache-Control'] = 'no-cache';
            newHeaders['Pragma'] = 'no-cache';
            var newReq = req.clone({ setHeaders: newHeaders });
            return next.handle(newReq);
        }
        return next.handle(req);
    };
    return NgNoCacheInterceptor;
}());
exports.NgNoCacheInterceptor = NgNoCacheInterceptor;
