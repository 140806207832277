/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { InventoryBestRepTrimMatchDto } from '../models/inventory-best-rep-trim-match-dto';
import { InventoryBestMatchToTrimRequestDto } from '../models/inventory-best-match-to-trim-request-dto';
import { InventoryBestMatchResultDto } from '../models/inventory-best-match-result-dto';
import { InventoryBestMatchRequestDto } from '../models/inventory-best-match-request-dto';
import { InventoryExtDto } from '../models/inventory-ext-dto';
import { InventoryRecommendations_NewCarRequest } from '../models/inventory-recommendations-_new-car-request';
import { InventoryStockMinViewDto } from '../models/inventory-stock-min-view-dto';
import { InventoryOpportunitiesExtractDto } from '../models/inventory-opportunities-extract-dto';
import { InventorySearchRequestDto } from '../models/inventory-search-request-dto';
@Injectable({
  providedIn: 'root',
})
class InventoryClientService extends __BaseService {
  static readonly InventoryBestMatchToTrimPOSTPath = '/api/Inventory/InventoryBestMatchToTrim';
  static readonly InventoryBestMatchPOSTPath = '/api/Inventory/InventoryBestMatch';
  static readonly InventoryRecommendationUsedCarByEntityidGETPath = '/api/Inventory/InventoryRecommendation_UsedCar/{entityId}';
  static readonly InventoryRecommendationNewCarPOSTPath = '/api/Inventory/InventoryRecommendation_NewCar';
  static readonly GetStockViewByDealeridGETPath = '/api/Inventory/GetStockView/{dealerId}';
  static readonly GetStockViewsGETPath = '/api/Inventory/GetStockView';
  static readonly GetExtByStockNoByStocknoByEntityidByPricingplanidGETPath = '/api/Inventory/GetExtByStockNo/{stockNo}/{entityId}/{pricingPlanID}';
  static readonly GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETPath = '/api/Inventory/GetInventoryOpportunities/{dealerId}/{stockNumber}/{cashAdjustment}';
  static readonly SearchPOSTPath = '/api/Inventory/Search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  InventoryBestMatchToTrimPOSTResponse(request?: InventoryBestMatchToTrimRequestDto): __Observable<__StrictHttpResponse<InventoryBestRepTrimMatchDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Inventory/InventoryBestMatchToTrim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InventoryBestRepTrimMatchDto>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  InventoryBestMatchToTrimPOST(request?: InventoryBestMatchToTrimRequestDto): __Observable<InventoryBestRepTrimMatchDto> {
    return this.InventoryBestMatchToTrimPOSTResponse(request).pipe(
      __map(_r => _r.body as InventoryBestRepTrimMatchDto)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  InventoryBestMatchPOSTResponse(request?: InventoryBestMatchRequestDto): __Observable<__StrictHttpResponse<InventoryBestMatchResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Inventory/InventoryBestMatch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InventoryBestMatchResultDto>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  InventoryBestMatchPOST(request?: InventoryBestMatchRequestDto): __Observable<InventoryBestMatchResultDto> {
    return this.InventoryBestMatchPOSTResponse(request).pipe(
      __map(_r => _r.body as InventoryBestMatchResultDto)
    );
  }

  /**
   * @param params The `InventoryClientService.InventoryRecommendationUsedCarByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `saleType`:
   *
   * - `currentTerm`:
   *
   * - `currentPayment`:
   *
   * @return Success
   */
  InventoryRecommendationUsedCarByEntityidGETResponse(params: InventoryClientService.InventoryRecommendationUsedCarByEntityidGETParams): __Observable<__StrictHttpResponse<Array<InventoryExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.saleType != null) __params = __params.set('saleType', params.saleType.toString());
    if (params.currentTerm != null) __params = __params.set('currentTerm', params.currentTerm.toString());
    if (params.currentPayment != null) __params = __params.set('currentPayment', params.currentPayment.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Inventory/InventoryRecommendation_UsedCar/${params.entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InventoryExtDto>>;
      })
    );
  }
  /**
   * @param params The `InventoryClientService.InventoryRecommendationUsedCarByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `saleType`:
   *
   * - `currentTerm`:
   *
   * - `currentPayment`:
   *
   * @return Success
   */
  InventoryRecommendationUsedCarByEntityidGET(params: InventoryClientService.InventoryRecommendationUsedCarByEntityidGETParams): __Observable<Array<InventoryExtDto>> {
    return this.InventoryRecommendationUsedCarByEntityidGETResponse(params).pipe(
      __map(_r => _r.body as Array<InventoryExtDto>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  InventoryRecommendationNewCarPOSTResponse(request?: InventoryRecommendations_NewCarRequest): __Observable<__StrictHttpResponse<Array<InventoryExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Inventory/InventoryRecommendation_NewCar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InventoryExtDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  InventoryRecommendationNewCarPOST(request?: InventoryRecommendations_NewCarRequest): __Observable<Array<InventoryExtDto>> {
    return this.InventoryRecommendationNewCarPOSTResponse(request).pipe(
      __map(_r => _r.body as Array<InventoryExtDto>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetStockViewByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<InventoryStockMinViewDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Inventory/GetStockView/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InventoryStockMinViewDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetStockViewByDealeridGET(dealerId: number): __Observable<Array<InventoryStockMinViewDto>> {
    return this.GetStockViewByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<InventoryStockMinViewDto>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetStockViewsGETResponse(dealerId?: Array<number>): __Observable<__StrictHttpResponse<Array<InventoryStockMinViewDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (dealerId || []).forEach(val => {if (val != null) __params = __params.append('dealerId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Inventory/GetStockView`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InventoryStockMinViewDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetStockViewsGET(dealerId?: Array<number>): __Observable<Array<InventoryStockMinViewDto>> {
    return this.GetStockViewsGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<InventoryStockMinViewDto>)
    );
  }

  /**
   * @param params The `InventoryClientService.GetExtByStockNoByStocknoByEntityidByPricingplanidGETParams` containing the following parameters:
   *
   * - `stockNo`:
   *
   * - `pricingPlanID`:
   *
   * - `entityId`:
   *
   * @return Success
   */
  GetExtByStockNoByStocknoByEntityidByPricingplanidGETResponse(params: InventoryClientService.GetExtByStockNoByStocknoByEntityidByPricingplanidGETParams): __Observable<__StrictHttpResponse<InventoryExtDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Inventory/GetExtByStockNo/${params.stockNo}/${params.entityId}/${params.pricingPlanID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InventoryExtDto>;
      })
    );
  }
  /**
   * @param params The `InventoryClientService.GetExtByStockNoByStocknoByEntityidByPricingplanidGETParams` containing the following parameters:
   *
   * - `stockNo`:
   *
   * - `pricingPlanID`:
   *
   * - `entityId`:
   *
   * @return Success
   */
  GetExtByStockNoByStocknoByEntityidByPricingplanidGET(params: InventoryClientService.GetExtByStockNoByStocknoByEntityidByPricingplanidGETParams): __Observable<InventoryExtDto> {
    return this.GetExtByStockNoByStocknoByEntityidByPricingplanidGETResponse(params).pipe(
      __map(_r => _r.body as InventoryExtDto)
    );
  }

  /**
   * @param params The `InventoryClientService.GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETParams` containing the following parameters:
   *
   * - `stockNumber`:
   *
   * - `dealerId`:
   *
   * - `cashAdjustment`:
   *
   * @return Success
   */
  GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETResponse(params: InventoryClientService.GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETParams): __Observable<__StrictHttpResponse<Array<InventoryOpportunitiesExtractDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Inventory/GetInventoryOpportunities/${params.dealerId}/${params.stockNumber}/${params.cashAdjustment}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InventoryOpportunitiesExtractDto>>;
      })
    );
  }
  /**
   * @param params The `InventoryClientService.GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETParams` containing the following parameters:
   *
   * - `stockNumber`:
   *
   * - `dealerId`:
   *
   * - `cashAdjustment`:
   *
   * @return Success
   */
  GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGET(params: InventoryClientService.GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETParams): __Observable<Array<InventoryOpportunitiesExtractDto>> {
    return this.GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETResponse(params).pipe(
      __map(_r => _r.body as Array<InventoryOpportunitiesExtractDto>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  SearchPOSTResponse(request?: InventorySearchRequestDto): __Observable<__StrictHttpResponse<Array<InventoryExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Inventory/Search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InventoryExtDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  SearchPOST(request?: InventorySearchRequestDto): __Observable<Array<InventoryExtDto>> {
    return this.SearchPOSTResponse(request).pipe(
      __map(_r => _r.body as Array<InventoryExtDto>)
    );
  }
}

module InventoryClientService {

  /**
   * Parameters for InventoryRecommendationUsedCarByEntityidGET
   */
  export interface InventoryRecommendationUsedCarByEntityidGETParams {
    entityId: string;
    saleType?: 1 | 2 | 3 | 4 | 6 | 7 | 8;
    currentTerm?: number;
    currentPayment?: number;
  }

  /**
   * Parameters for GetExtByStockNoByStocknoByEntityidByPricingplanidGET
   */
  export interface GetExtByStockNoByStocknoByEntityidByPricingplanidGETParams {
    stockNo: string;
    pricingPlanID: number;
    entityId: string;
  }

  /**
   * Parameters for GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGET
   */
  export interface GetInventoryOpportunitiesByDealeridByStocknumberByCashadjustmentGETParams {
    stockNumber: string;
    dealerId: number;
    cashAdjustment: number;
  }
}

export { InventoryClientService }
