
/* injects from baggage-loader */
require('./msrp-type-select.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('msrpTypeSelect', {
    templateUrl: '/app/components/deal-sheet/contract/msrp-type-select.component.html',
    controller: MsrpTypeSelectController,
    bindings: {
        vehicle: '<',
        onSelect: '&'
    }
});

/* @ngInject */
function MsrpTypeSelectController(msrpTypes) {
    var $ctrl = this;

    $ctrl.msrpTypes = msrpTypes;
    $ctrl.selectedMsrpType = $ctrl.msrpTypes.average;
    $ctrl.onSelectionChange = onSelectionChange;
    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if (changes.vehicle && changes.vehicle.currentValue) {
            $ctrl.selectedMsrpType = $ctrl.vehicle.stockNo ? msrpTypes.inventory : msrpTypes.average;
        }
    }

    function onSelectionChange() {
        $ctrl.onSelect({ msrpType: $ctrl.selectedMsrpType });
        $ctrl.selectedMsrpType = $ctrl.vehicle.stockNo ? msrpTypes.inventory : msrpTypes.average;
    }
}
