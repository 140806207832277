"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var modal_1 = require("ngx-bootstrap/modal");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var admin_role_service_1 = require("../../services/admin-role.service");
var confirm_modal_component_1 = require("../modals/confirm-modal.component");
var admin_roles_modal_addedit_component_1 = require("./admin-roles-modal-addedit.component");
var AdminRolesComponent = /** @class */ (function () {
    function AdminRolesComponent(modalService, roleService, tService, logger) {
        this.modalService = modalService;
        this.roleService = roleService;
        this.tService = tService;
        this.logger = logger;
        this.accessRightChanged = false;
    }
    AdminRolesComponent.prototype.ngOnInit = function () {
        this.getRoleOptions();
    };
    AdminRolesComponent.prototype.getRoleOptions = function () {
        var _this = this;
        return this.roleService.GetRoleOptions().then(function (roleOptions) {
            _this.roleOptions = roleOptions;
            _this.selectedRole = roleOptions[0][0];
            _this.roleService.GetRoleAccessRightCategories().then(function (rightCategories) {
                _this.rightCategories = rightCategories;
            });
            _this.getRoleAccess(_this.selectedRole.roleID);
        });
    };
    AdminRolesComponent.prototype.onEditRole = function () {
        this.openAddEditRoleModalWithChangesCheck(false);
    };
    AdminRolesComponent.prototype.onAddRole = function () {
        this.openAddEditRoleModalWithChangesCheck(true);
    };
    AdminRolesComponent.prototype.setSelectedRole = function (roleId) {
        var flatRoles = [].concat.apply([], this.roleOptions);
        this.selectedRole = flatRoles.find(function (r) { return r.roleID == roleId; });
    };
    AdminRolesComponent.prototype.getRoleName = function (roleId) {
        var flatRoles = [].concat.apply([], this.roleOptions);
        var role = flatRoles.find(function (r) { return r.roleID == roleId; });
        if (role != null)
            return role.roleName;
        else
            return '';
    };
    AdminRolesComponent.prototype.getRoleSelectNote = function () {
        return this.tService.instant("adminRoles_customRoleBasedOn").replace("{parentRoleName}", "<span class='bolditalic'>" + this.getRoleName(this.selectedRole.parentRoleID) + "</span>");
    };
    AdminRolesComponent.prototype.openAddEditRoleModalWithChangesCheck = function (newRole) {
        var _this = this;
        if (this.accessRightChanged) {
            this.accessRightChanged = false;
            var initialState = {
                saveWarningMessage: false,
                messageKey: "saveWarningMessage"
            };
            this.bsModalRef = this.modalService.show(confirm_modal_component_1.ConfirmModalComponent, { initialState: initialState });
            this.bsModalRef.content.onClosed.subscribe(function (result) {
                if (!result.confirmed)
                    _this.accessRightChanged = true;
                else
                    _this.openAddEditRoleModal(newRole);
            });
        }
        else
            this.openAddEditRoleModal(newRole);
    };
    AdminRolesComponent.prototype.openAddEditRoleModal = function (newRole) {
        var _this = this;
        var initialState = {
            newRole: newRole,
            selectedRole: Object.assign({}, this.selectedRole),
            roleOptions: this.roleOptions.slice()
        };
        this.bsModalRef = this.modalService.show(admin_roles_modal_addedit_component_1.AdminRolesModalAddEditComponent, { initialState: initialState });
        this.bsModalRef.content.onSaveChanges.subscribe(function (result) {
            _this.roleService.RoleSave(result.selectedRole).then(function (roleId) {
                _this.previousRoleId = roleId;
                _this.getRoleOptions().then(function (roleOptions) {
                    // update role option and set selected to id of option just edited
                    _this.setSelectedRole(roleId);
                    if (result.selectedRole.startWithParentRole && result.selectedRole.parentRoleID != null)
                        roleId = result.selectedRole.parentRoleID;
                    _this.getRoleAccess(roleId).then(function (result) {
                        _this.saveModuleAndRightSettings().then(function (result) {
                            if (result)
                                _this.logger.success(_this.tService.instant("changesSaved"));
                            else
                                _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                        });
                    });
                });
            });
        });
    };
    AdminRolesComponent.prototype.onRoleChanged = function () {
        var _this = this;
        if (this.accessRightChanged) {
            this.accessRightChanged = false;
            var initialState = {
                saveWarningMessage: false,
                messageKey: "saveWarningMessage"
            };
            this.bsModalRef = this.modalService.show(confirm_modal_component_1.ConfirmModalComponent, { initialState: initialState });
            this.bsModalRef.content.onClosed.subscribe(function (result) {
                if (!result.confirmed) {
                    _this.accessRightChanged = true;
                    _this.setSelectedRole(_this.previousRoleId);
                }
                else
                    _this.getRoleAccess(_this.selectedRole.roleID);
            });
        }
        else {
            this.getRoleAccess(this.selectedRole.roleID);
            this.previousRoleId = this.selectedRole.roleID;
        }
    };
    AdminRolesComponent.prototype.getRoleAccess = function (roleId) {
        var _this = this;
        var promise1 = this.roleService.GetRoleModuleCategories(roleId).then(function (result) {
            _this.moduleCategories = result;
        });
        var promise2 = this.roleService.GetRoleAccessRights(roleId).then(function (result) {
            _this.rightSettings = result;
        });
        var promise3 = this.roleService.GetRoleModuleAccess(roleId).then(function (result) {
            _this.moduleSettings = result;
        });
        return Promise.all([promise1, promise2, promise3]);
    };
    AdminRolesComponent.prototype.cancelChanges = function () {
        this.getRoleAccess(this.selectedRole.roleID);
        this.logger.warning(this.tService.instant("settingsReturnedToDefault"));
    };
    AdminRolesComponent.prototype.saveChanges = function () {
        var _this = this;
        this.saveModuleAndRightSettings().then(function (result) {
            if (result)
                _this.logger.success(_this.tService.instant("changesSaved"));
            else
                _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
        });
    };
    AdminRolesComponent.prototype.saveModuleAndRightSettings = function () {
        var csvRight = [];
        var csvModule = [];
        this.rightSettings.forEach(function (item) {
            if (item.allow)
                csvRight.push(item.accessOptionId);
        });
        // we also need to save the moduleCategories, but not accessRightsCategories
        this.moduleCategories.forEach(function (item) {
            if (item.allow)
                csvModule.push(item.accessOptionId);
        });
        this.moduleSettings.forEach(function (item) {
            if (item.allow)
                csvModule.push(item.accessOptionId);
        });
        return this.roleService.RoleAccessUpdate(this.selectedRole.roleID, csvModule, csvRight);
    };
    return AdminRolesComponent;
}());
exports.AdminRolesComponent = AdminRolesComponent;
