"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var storage_service_1 = require("../modules/storage/storage.service");
var common_1 = require("@angular/common");
var LocaleCurrencyPipe = /** @class */ (function () {
    function LocaleCurrencyPipe(storageService) {
        this.storageService = storageService;
    }
    LocaleCurrencyPipe.prototype.transform = function (amount, showFractionDigits, showSymbol) {
        if (showFractionDigits === void 0) { showFractionDigits = false; }
        if (showSymbol === void 0) { showSymbol = true; }
        var precision = showFractionDigits ? 2 : 0;
        amount = this.roundWithPrecision(amount, precision);
        var savedCultureName = this.storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        var decimalPipe = new common_1.DecimalPipe(cultureName);
        var amountIsNegative = (amount < 0);
        var nbsp = "\u00A0";
        var absoluteAmount = Math.abs(amount);
        var formattedAmount;
        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                formattedAmount = '£' + decimalPipe.transform(absoluteAmount, "0." + precision + "-" + precision);
                break;
            case 'en-ca':
                formattedAmount = (showSymbol ? '$' : '') + decimalPipe.transform(absoluteAmount, "0." + precision + "-" + precision);
                break;
            case 'fr-ca':
                formattedAmount = decimalPipe.transform(absoluteAmount, "0." + precision + "-" + precision) + (showSymbol ? nbsp + '$' : '');
                break;
            case 'fr-fr':
                formattedAmount = decimalPipe.transform(absoluteAmount, "0." + precision + "-" + precision) + (showSymbol ? nbsp + '€' : '');
                break;
            case 'en-us':
            default:
                formattedAmount = '$' + decimalPipe.transform(absoluteAmount, "0." + precision + "-" + precision);
                break;
        }
        if (amountIsNegative) {
            switch (cultureName.toLowerCase()) {
                case 'fr-fr':
                    formattedAmount = "-" + nbsp + formattedAmount;
                    break;
                case 'en-gb':
                case 'en-ca':
                case 'fr-ca':
                default:
                    formattedAmount = "(" + formattedAmount + ")";
                    break;
            }
        }
        return formattedAmount;
    };
    LocaleCurrencyPipe.prototype.roundWithPrecision = function (x, precision) {
        if (precision === 0) {
            return Math.round(x);
        }
        var factor = Math.pow(10, precision);
        return Math.round(x * factor) / factor;
    };
    return LocaleCurrencyPipe;
}());
exports.LocaleCurrencyPipe = LocaleCurrencyPipe;
