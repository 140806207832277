mapUtils.$inject = ["_"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('mapUtils', mapUtils);

function mapUtils(_) {
  "ngInject";

  var util = {
    getVehicleTrimFullName: getVehicleTrimFullName,
    hasActionAccess: hasActionAccess
  };
  return util;

  function getVehicleTrimFullName(vehicle) {
    var trimFullName;

    if (vehicle.addModelToTrim) {
      trimFullName = vehicle.modelName + ' ';

      if (vehicle.displayCodes && vehicle.mfcModelCode) {
        trimFullName += vehicle.mfcModelCode;
      } else {
        trimFullName += vehicle.trimName;
      }
    } else {
      trimFullName = vehicle.trimName;
    }

    return trimFullName;
  }

  function hasActionAccess(actionAccess, key) {
    return _.some(actionAccess, function (aa) {
      return aa === key;
    });
  }
}