"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./default-tab-action.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../icon/icon.component.ngfactory"));
var i3 = __importStar(require("../../icon/icon.component"));
var i4 = __importStar(require("@angular/platform-browser"));
var i5 = __importStar(require("@ngx-translate/core"));
var i6 = __importStar(require("@angular/common"));
var i7 = __importStar(require("./default-tab-action.component"));
var styles_DefaultTabActionComponent = [i0.styles];
var RenderType_DefaultTabActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DefaultTabActionComponent, data: {} });
exports.RenderType_DefaultTabActionComponent = RenderType_DefaultTabActionComponent;
function View_DefaultTabActionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "default-tab-indicator qa-default-tab-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "icon", [["class", "default-tab-icon icon aa-icon-star-icon-on"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(2, 638976, null, 0, i3.IconComponent, [i4.DomSanitizer], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["translate", "markAsDefaultView"]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "markAsDefaultView"; _ck(_v, 4, 0, currVal_0); }, null); }
function View_DefaultTabActionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "transparent default-tab-indicator qa-default-tab-indicator"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickMarkAsDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "icon", [["class", "default-tab-icon icon aa-icon-star-icon-off"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(2, 638976, null, 0, i3.IconComponent, [i4.DomSanitizer], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["translate", "markAsDefaultView"]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_0 = "markAsDefaultView"; _ck(_v, 4, 0, currVal_0); }, null); }
function View_DefaultTabActionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultTabActionComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultTabActionComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.defaultTab == _co.currentTab); _ck(_v, 1, 0, currVal_0); var currVal_1 = !(_co.defaultTab == _co.currentTab); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_DefaultTabActionComponent_0 = View_DefaultTabActionComponent_0;
function View_DefaultTabActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "default-tab-action", [], null, null, null, View_DefaultTabActionComponent_0, RenderType_DefaultTabActionComponent)), i1.ɵdid(1, 114688, null, 0, i7.DefaultTabActionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DefaultTabActionComponent_Host_0 = View_DefaultTabActionComponent_Host_0;
var DefaultTabActionComponentNgFactory = i1.ɵccf("default-tab-action", i7.DefaultTabActionComponent, View_DefaultTabActionComponent_Host_0, { defaultTab: "defaultTab", currentTab: "currentTab" }, { onAction: "onAction" }, []);
exports.DefaultTabActionComponentNgFactory = DefaultTabActionComponentNgFactory;
