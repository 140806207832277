"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var storage_service_1 = require("../modules/storage/storage.service");
var LocaleNumberPipe = /** @class */ (function (_super) {
    __extends(LocaleNumberPipe, _super);
    function LocaleNumberPipe(storageService) {
        var _this = _super.call(this, 'en-us') || this;
        _this.storageService = storageService;
        return _this;
    }
    LocaleNumberPipe.prototype.transform = function (number, fractionDigits) {
        if (this.isNumber(number)) {
            number = parseFloat(number);
            var savedCultureName = this.storageService.getItem('cultureName');
            var cultureName = savedCultureName ? savedCultureName : 'en-us';
            if (fractionDigits != null) {
                return _super.prototype.transform.call(this, number, fractionDigits, cultureName);
            }
            else {
                return _super.prototype.transform.call(this, number);
            }
        }
        else {
            return number;
        }
    };
    LocaleNumberPipe.prototype.isNumber = function (obj) {
        return !isNaN(parseFloat(obj));
    };
    return LocaleNumberPipe;
}(common_1.DecimalPipe));
exports.LocaleNumberPipe = LocaleNumberPipe;
