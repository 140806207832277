"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./impersonation-status.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../icon/icon.component.ngfactory"));
var i3 = __importStar(require("../../icon/icon.component"));
var i4 = __importStar(require("@angular/platform-browser"));
var i5 = __importStar(require("@ngx-translate/core"));
var i6 = __importStar(require("@angular/common"));
var i7 = __importStar(require("./impersonation-status.component"));
var i8 = __importStar(require("../../../ajs-upgraded-providers"));
var i9 = __importStar(require("../../../services/user.service"));
var i10 = __importStar(require("../../../modules/oidc-auth/token.service"));
var styles_ImpersonationStatusComponent = [i0.styles];
var RenderType_ImpersonationStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImpersonationStatusComponent, data: {} });
exports.RenderType_ImpersonationStatusComponent = RenderType_ImpersonationStatusComponent;
function View_ImpersonationStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "impersonation-status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "icon", [["class", "icon lg aa-icon-view-as-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(3, 638976, null, 0, i3.IconComponent, [i4.DomSanitizer], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "#"], ["translate", "dismissViewAs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { _ck(_v, 3, 0); var currVal_1 = "dismissViewAs"; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.impersonationStatus; _ck(_v, 4, 0, currVal_0); }); }
function View_ImpersonationStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImpersonationStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userFullName && _co.impersonatingUserFullName); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ImpersonationStatusComponent_0 = View_ImpersonationStatusComponent_0;
function View_ImpersonationStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "impersonation-status", [], null, null, null, View_ImpersonationStatusComponent_0, RenderType_ImpersonationStatusComponent)), i1.ɵdid(1, 638976, null, 0, i7.ImpersonationStatusComponent, [i8.AuthService, i9.UserService, i10.TokenService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ImpersonationStatusComponent_Host_0 = View_ImpersonationStatusComponent_Host_0;
var ImpersonationStatusComponentNgFactory = i1.ɵccf("impersonation-status", i7.ImpersonationStatusComponent, View_ImpersonationStatusComponent_Host_0, { userFullName: "userFullName" }, {}, []);
exports.ImpersonationStatusComponentNgFactory = ImpersonationStatusComponentNgFactory;
