
/* injects from baggage-loader */
require('./customer-name.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('customerName', {
    templateUrl: '/app/components/deal-sheet/customer-name.component.html',
    bindings: {
        customer: '<'
    }
});
