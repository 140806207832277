
/* injects from baggage-loader */
require('./stock-vehicle.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('stockVehicle', {
    templateUrl: '/app/components/deal-sheet/vehicle/stock-vehicle.component.html',
    controller: StockVehicleController,
    bindings: {
        vehicle: '<'
    }
});

/* @ngInject */
function StockVehicleController(vehicleTypes) {
    var $ctrl = this;

    $ctrl.vehicleTypes = vehicleTypes;

}