import { Component, ElementRef, Injector, Input } from '@angular/core';
import { LocaleCurrencyPipe } from '../../../../filters/locale-currency.pipe';

@Component({
    selector: 'cash-paid',
    templateUrl: './cash-paid.component-ng.html',
    styleUrls: ['./cash-paid.component.scss']
})
export class CashPaidComponent {
    @Input() value: number;

    constructor(elementRef: ElementRef, injector: Injector, public localeCurrency: LocaleCurrencyPipe) {
    }
}