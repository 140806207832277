import angular from 'angular';

angular.module('AaApp.Opp').filter('localePhone', localePhoneFilter);

localePhoneFilter.$inject = ['storageService'];

function localePhoneFilter(storageService) {
    return function formartPhone(localePhone) {
        if (!localePhone) {
            return localePhone;
        }

        function formatEurope(phoneNumber) {
            var formatted = "";
            //if phone number has an odd length, the leading digit should stand alone.
            if (phoneNumber.length % 2 == 1) {
                formatted += '0' + phoneNumber[0] + ' ';
                phoneNumber = phoneNumber.substring(1);
            }
            for (var i = 0; i < phoneNumber.length; i++) {
                if (i % 2 == 0 && i != 0) {
                    formatted += ' ';
                }
                formatted += phoneNumber[i];
            }
            return formatted;
        }

        var savedCultureName = storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        if (cultureName == 'fr-fr')
            return formatEurope(localePhone);

        if (cultureName == 'en-gb')
            return;

        localePhone = localePhone + '';
        var numbers = localePhone.replace(/\D/g, '');

        // If the phone number is exactly 10 digits, assume it should be in NANP format.
        if (numbers.length == 10) {
            var char = { 0: '(', 3: ') ', 6: '-' };
            numbers = numbers.slice(0, 10);
            var formattedNumber = '';

            for (var i = 0; i < numbers.length; i++) {
                formattedNumber += (char[i] || '') + numbers[i];
            }
            return formattedNumber;
        }
        else {
            // If we can't recognize the format, return the value unformatted.
            return localePhone;
        }
    }
}
