import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, SimpleChanges } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Opportunity2Service } from '../../../services/opportunity2.service';

@Component({
    selector: 'nlo-info-modal',
    templateUrl: './nlo-info-modal.component-ng.html',
    styleUrls: ['./nlo-info-modal.component.scss']
})

export class NloInfoModalComponent implements OnInit, AfterViewInit {
    @Input() entityId: string;
    @Input() nloData: any[];
    @Output() onClosed = new EventEmitter();

    public busy: Promise<any>;
    public featuresLoaded: boolean = false;

    constructor(
        public _bsModalRef: BsModalRef,
        private tService: TranslateService,
        private opportunity2Service: Opportunity2Service) { }

    public ngOnInit(): void {
        this._bsModalRef.hide();

        this.nloData.forEach(item => {
            let transKey = 'nloCommSrc_' + item.communicationSourceID;
            item.communicationSource = this.tService.instant(transKey);
        });
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
    }

    public markAsNLO(markAsNLO): void {
        // send event to mark as NLO or decline NLO
        // if successful reload deal sheet
        this.onClosed.emit({ markasNLO: markAsNLO });
        this._bsModalRef.hide();
    }
}

