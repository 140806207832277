import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
import { Component, Input, Output, OnChanges, EventEmitter, LOCALE_ID, Inject, ViewChild } from '@angular/core';
import { SelectionRange, DateRangeSelectionDirective } from '@progress/kendo-angular-dateinputs';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
    selector: 'calendar-date-range',
    templateUrl: './calendar-date-range.component-ng.html'
})

export class CalendarDateRangeComponent implements OnChanges {
    // define input bindings
    @Input() dateRangeTypes;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() defaultSelectedPresetType: string;
    // define eventOUT signature
    @Output() dateRangeSelect = new EventEmitter<any>();
    @Output() presetDateRangeSelect = new EventEmitter<any>();
    @ViewChild(DateRangeSelectionDirective, {static: false}) kendoDateRangeSelectionDirective: DateRangeSelectionDirective;

    public show = false;
    public lastPreselect = 'none';
    public currentPreselect = 'none';

    public maxEndDate: Date = new Date();
    public minStartDate: Date = new Date(2009, 11, 31);

    public defaultEndDate: Date = this.endDate;
    public defaultStartDate: Date = this.startDate;
    public range = { start: new Date(), end: new Date() };
    public activeRangeEnd: 'end' | 'start';
    public isDurationError = false;
    public isDurationNullError = false;
    public isFromDtGreaterThanToDt = false;

    public enCADateFormat = 'dd/MM/yyyy';
    public manualForcedDateFormat = '';


    constructor(@Inject(LOCALE_ID) public localeId: string, public intlService: IntlService, public tService: TranslateService) {

        if ((<CldrIntlService>this.intlService).localeId === 'en-CA') {
            this.manualForcedDateFormat = this.enCADateFormat;
        } else {
            this.manualForcedDateFormat = '';
        }


        // console.log("defaultSelectedPresetType", this.defaultSelectedPresetType);

        if (this.defaultSelectedPresetType) {
            this.lastPreselect = this.defaultSelectedPresetType;
            this.currentPreselect = this.defaultSelectedPresetType;
        } else {
            this.lastPreselect = 'none';
            this.currentPreselect = 'none';
        }
    }

    public dateSelected(date: Date): void {
        // Cast to any so I can access the private method
        (<any>this.kendoDateRangeSelectionDirective).handleChange(date);
    }

    public onToggle(): void {
        this.isDurationError = false;
        this.isDurationNullError = false;
        this.isFromDtGreaterThanToDt = false;
        this.onCancel();
    }

    public onCancel(): void {
        this.currentPreselect = this.lastPreselect;
        this.show = !this.show;
        this.range = { start: this.defaultStartDate, end: this.defaultEndDate };
    }

    public onUpdate(): void {
        this.show = !this.show;
        this.lastPreselect = 'none';
        this.currentPreselect = 'none';
        this.isDurationError = false;
        this.isDurationNullError = false;
        this.isFromDtGreaterThanToDt = false;
        this.dateRangeSelect.emit(this.range);
    }

    public onPresetSelect(presetType): void {
        if (presetType !== 'none') {
            this.lastPreselect = presetType;
            this.currentPreselect = presetType;
            this.isDurationError = false;
            this.isDurationNullError = false;
            this.isFromDtGreaterThanToDt = false;
            this.presetDateRangeSelect.emit(presetType);
            this.show = !this.show;
        }
    }



    public checkOneYearDateRangeValidation(start: Date, end: Date): void {

        if (start && end) {
            this.isDurationNullError = false;

            if (start == null) {
                this.isDurationError = true;
                return;
            }

            if (end == null) {
                this.isDurationError = true;
                return;
            }

            const endMoment = moment(end);
            const startMoment = moment(start);

            // console.log('endMoment.toObject().years',endMoment.toObject().years);
            // console.log('startMoment.toObject().years',startMoment.toObject().years);

            if (endMoment.toObject().years < 2010 || startMoment.toObject().years < 2010) {
                return;
            }

            const duartionOfYearInDays = moment(end).isLeapYear() ? 366 : 365;
            // console.log(duartionOfYearInDays);

            if (endMoment.diff(startMoment, 'days') > duartionOfYearInDays) {
                // console.log('date is greater then 1 Yr');
                this.isDurationError = true;
                return;
            } else {
                this.isDurationError = false;
            }

            if (endMoment.diff(startMoment, 'days') < 0) {
                // console.log('"From" date must be before the "To" date');
                this.isFromDtGreaterThanToDt = true;
            } else {
                this.isFromDtGreaterThanToDt = false;
            }
        } else {
            this.isDurationError = false;
            this.isFromDtGreaterThanToDt = false;
            this.isDurationNullError = true;
        }

    }

    public handleSelectionRange(selRange: SelectionRange): void {

        // if invalid date then revert to original
        if ((selRange.end > this.maxEndDate) || (selRange.start > this.maxEndDate)) {
            this.range = { start: this.defaultStartDate, end: this.defaultEndDate };
            return;
        }

        this.currentPreselect = 'none';
        this.range = { start: selRange.start, end: selRange.end };
        this.checkOneYearDateRangeValidation(selRange.start, selRange.end);
    }

    public onStartChange(value: Date): void {
        this.currentPreselect = 'none';
        this.range.start = value;
        this.checkOneYearDateRangeValidation(this.range.start, this.range.end);

    }

    public onEndChange(value: Date): void {
        this.currentPreselect = 'none';
        this.range.end = value;
        this.checkOneYearDateRangeValidation(this.range.start, this.range.end);

    }

    public isFutureDate(date: Date): string {
        return date > this.maxEndDate ? 'futureDates' : '';
    }

    public isValidDate(date): boolean {
        return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    }

    ngOnChanges() {

        if (!this.isValidDate(this.endDate)) {
            this.endDate = new Date(this.endDate + '');
        }

        if (!this.isValidDate(this.startDate)) {
            this.startDate = new Date(this.startDate + '');
        }


        this.defaultEndDate = this.endDate;
        this.defaultStartDate = this.startDate;
        this.range = { start: this.defaultStartDate, end: this.defaultEndDate };


        if (this.defaultSelectedPresetType) {
            this.lastPreselect = this.defaultSelectedPresetType;
            this.currentPreselect = this.defaultSelectedPresetType;
        } else {
            this.lastPreselect = 'none';
            this.currentPreselect = 'none';
        }
    }
}
