pandoSurveyService.$inject = ["coreApi", "$q", "tokenService"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('pandoSurveyService', pandoSurveyService);
/* @ngInject */

function pandoSurveyService(coreApi, $q, tokenService) {
  var service = {
    getAccessibleSurveys: getAccessibleSurveys,
    getWorkflowTemplates: getWorkflowTemplates,
    getDealerAutoReportSubscription: getDealerAutoReportSubscription,
    getReportDealerPandoGroupSubscriptions: getReportDealerPandoGroupSubscriptions,
    removeDealerAutoReportSubscription: removeDealerAutoReportSubscription,
    updateDealerAutoReportSubscription: updateDealerAutoReportSubscription,
    addReportDealerPandoGroupSubscription: addReportDealerPandoGroupSubscription,
    updateReportDealerPandoGroupSubscription: updateReportDealerPandoGroupSubscription
  };
  return service;

  function getAccessibleSurveys() {
    if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.PandoPassthrough.GetString.POST({
      "uriTemplate": "/pando/pandosurveyreporting/accessiblesurveys"
    }).then(function (response) {
      return response.data.SurveyFamilies;
    });
  }

  function getWorkflowTemplates(dealerId) {
    if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.PandoPassthrough.GetWorkflowTemplates["{dealerId}"].GET({
      dealerId: dealerId
    }, {
      disableErrorHandler: true
    }).then(function (response) {
      return response.data;
    });
  }

  function getDealerAutoReportSubscription(dealerId) {
    //if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.ReportSubscription.GetDealerAutoReportSubscription["{dealerId}"].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function addReportDealerPandoGroupSubscription(subscription) {
    //if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.ReportSubscription.AddReportDealerPandoGroupSubscription.POST(subscription).then(function (response) {
      return response.data;
    });
  }

  function updateReportDealerPandoGroupSubscription(subscription) {
    //if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.ReportSubscription.UpdateReportDealerPandoGroupSubscription.POST(subscription).then(function (response) {
      return response.data;
    });
  }

  function getReportDealerPandoGroupSubscriptions(dealerId) {
    //if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.ReportSubscription.GetReportDealerPandoGroupSubscriptions["{dealerId}"].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function removeDealerAutoReportSubscription(dealerId) {
    //if (!tokenService.isMotoFuzeUser()) return $q.resolve();
    return coreApi.api.ReportSubscription.RemoveDealerAutoReportSubscription["{dealerId}"].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function updateDealerAutoReportSubscription(subscription) {
    //if (!tokenService.isMotoFuzeUser()) return $q.resolve();        
    return coreApi.api.ReportSubscription.UpdateDealerAutoReportSubscription.POST(subscription).then(function (response) {
      return response.data;
    });
  }
}