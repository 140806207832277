import { Injectable, Inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { UserService } from './user.service';
import { AuthorizationService } from './authorization.service';
import { DealSheetData } from '../interfaces/deal-sheet/deal-sheet-data.interface';
import { StorageService } from '../modules/storage/storage.service';
import { StorageKeys } from '../constants';
import { Opportunity2Service } from './opportunity2.service';
import { DealerService } from './dealer.service';
import { MenuService } from './menu.service';
import { DealSheetOpportunity } from '../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { ReplacementVehicle } from '../interfaces/replacement-vehicle.interface';
import { VehicleService } from '../ajs-upgraded-providers';
import { UserProfile } from '../interfaces/user-profile.interface';
import { DealSheetPermissions } from '../interfaces/deal-sheet/deal-sheet-permissions.interface';

// Refactor of methods that get an opportunity formatted for use in a dealsheet
@Injectable({ providedIn: 'root' })
export class DealSheetDataService {
    constructor(private userService: UserService,
        private authorizationService: AuthorizationService,
        private storageService: StorageService,
        private opportunityService: Opportunity2Service,
        private dealerService: DealerService,
        private menuService: MenuService,
        @Inject(VehicleService) private vehicleService) { }

    // refactor of getDealSheet from deal-sheet.component
    public initDealsheetData(opportunityId: string, stockNo?: string): Observable<DealSheetData> {
        let userProfile: UserProfile;
        let selectedDealerIds: number[];
        const dealSheetData = {} as DealSheetData;

        return from(
            this.userService.getUserProfile()
                .then((userProfileResult) => {
                    userProfile = userProfileResult;
                    selectedDealerIds = this.dealerService.getSelectedDealerIds();
                    return this.authorizationService.getAccessRightIdsFromCache(selectedDealerIds);
                })
                .then((accessRightIds) => {
                    return Promise.all([
                        this.authorizationService.getDealSheetPermissions(accessRightIds),
                        this.authorizationService.getModuleIdsFromCache(selectedDealerIds)
                    ]);
                })
                .then((results: Array<any>) => {
                    dealSheetData.permissions = results[0];
                    dealSheetData.moduleIds = results[1];

                    dealSheetData.permissions.featureComparison = true;
                    dealSheetData.permissions.otherVehiclesOwned = true;
                    dealSheetData.isSltUser = userProfile.isSltUser;
                    dealSheetData.userProfile = userProfile;

                    this.opportunityService.hasReviewProDealer(opportunityId).then((hasReviewPro) => {
                        dealSheetData.hasReviewPro = hasReviewPro;
                    });
                    this.opportunityService.hasGeoAlertDealer(opportunityId).then((hasGeoAlert) => {
                        dealSheetData.hasGeoAlert = hasGeoAlert;
                    });

                    // this appears to always be null in deal-sheet
                    const calculationType = null;
                    return this.opportunityService.getOpportunity(opportunityId, stockNo, calculationType, dealSheetData.permissions, dealSheetData.moduleIds)
                        .then((opportunity) => {
                            dealSheetData.opportunity = opportunity;

                            this.setAdditionalProperties(dealSheetData);

                            return dealSheetData;
                        })
                        .then(dealSheetDataResult => this.getFuelEconomyComparisonReplacementVehicleFuelCost(dealSheetDataResult.opportunity.replacementVehicle,
                            dealSheetDataResult.opportunity.id))
                        .then(fuelEconomyComparison => {
                            dealSheetData.fuelEconomyComparison = fuelEconomyComparison;

                            return dealSheetData;
                        });
                }));
    }

    /**
     * Refactored from opportunity.component setOpportunity.
     */
    private setAdditionalProperties(dealSheetData: DealSheetData): void {
        this.opportunityService.addAlertsAsProperties(dealSheetData, dealSheetData.opportunity.alerts);
    }

    public shouldDisplayPullForward(opportunity: DealSheetOpportunity): boolean {
        if (opportunity != null && opportunity.replacementContract != null && opportunity.replacementContract.pullForwardAmount > 0) {
            let tradeEquity = 0;
            if (opportunity.currentVehicle.tradeValue != null && opportunity.currentContract.payoffAmount != null) {
                tradeEquity = opportunity.currentVehicle.tradeValue - opportunity.currentContract.payoffAmount;
            } else {
                tradeEquity = opportunity.currentVehicle.tradeValue;
            }

            const btmEquity = -opportunity.currentContract.balanceToMaturity +
                opportunity.currentContract.dispositionFee +
                opportunity.replacementContract.pullForwardAmount;

            return btmEquity > tradeEquity;
        }

        return false;
    }

    private getFuelEconomyComparisonReplacementVehicleFuelCost(vehicle: ReplacementVehicle, opportunityId: string) {
        if (vehicle && vehicle.trimID && vehicle.trimID > 0) {
            return this.vehicleService.getFuelEconomyComparison(vehicle.trimID, opportunityId)
                .catch(() => Promise.resolve(null));
        } else {
            return Promise.resolve(null);
        }
    }
}
