"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".modal-body[_ngcontent-%COMP%]{font-size:14px}.modal-body[_ngcontent-%COMP%]   .bold[_ngcontent-%COMP%]{font-weight:500}.dms-row[_ngcontent-%COMP%]{margin-top:5px}.intro[_ngcontent-%COMP%]{font-size:14px;margin-bottom:20px}.row-underline[_ngcontent-%COMP%]{border-bottom:1px solid #e5e5e5}.sectionHeader[_ngcontent-%COMP%]{font-size:16px;font-weight:500}.table[_ngcontent-%COMP%]{margin-top:0;margin-bottom:5px}.table[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]{font-size:14px}.table[_ngcontent-%COMP%]   tbody[_ngcontent-%COMP%]{font-size:12px}.table[_ngcontent-%COMP%]   tr.highlighted[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]{background-color:#e7e7e7!important}.table[_ngcontent-%COMP%]   td.highlight[_ngcontent-%COMP%]{color:#1f8bd5}.table[_ngcontent-%COMP%]   td.right[_ngcontent-%COMP%], .table[_ngcontent-%COMP%]   th.right[_ngcontent-%COMP%]{text-align:right}.disabled-deal[_ngcontent-%COMP%], .disabled-deal[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{color:#d3d3d3!important}.dms-customer-list[_ngcontent-%COMP%]{overflow-y:auto;min-height:140px;max-height:380px}.dms-id-label[_ngcontent-%COMP%]{width:130px}.dms-colon-suffix[_ngcontent-%COMP%]:after{content:\":\"}"];
exports.styles = styles;
