"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Provider for all alertminer-api services, plus AlertminerApiConfiguration
 */
var AlertminerApiModule = /** @class */ (function () {
    function AlertminerApiModule() {
    }
    return AlertminerApiModule;
}());
exports.AlertminerApiModule = AlertminerApiModule;
