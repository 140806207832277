"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UserSettingsComponent = /** @class */ (function () {
    function UserSettingsComponent() {
        this.dropDownVisible = false;
    }
    UserSettingsComponent.prototype.ngOnInit = function () { };
    UserSettingsComponent.prototype.reloadPage = function () {
        window.location.reload();
    };
    return UserSettingsComponent;
}());
exports.UserSettingsComponent = UserSettingsComponent;
