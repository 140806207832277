"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var tag_types_1 = require("../../../constants/tag-types");
var core_2 = require("@uirouter/core");
var opportunity2_service_1 = require("../../../services/opportunity2.service");
var constants_1 = require("../../../constants");
var core_3 = require("@ngx-translate/core");
var TaggedCustomersComponent = /** @class */ (function () {
    function TaggedCustomersComponent(opportunityService, opportunity2Service, stateService, tsService) {
        this.opportunityService = opportunityService;
        this.opportunity2Service = opportunity2Service;
        this.stateService = stateService;
        this.tsService = tsService;
        this.tagData = [];
        this.tagDataType1 = [];
        this.tagDataType2 = [];
        this.tagTypes = tag_types_1.TagTypes;
    }
    TaggedCustomersComponent.prototype.ngOnInit = function () {
        this.getTagsPivotForDealers(false);
        this.opportunityService = constants_1.Settings.environmentVariables.opportunityServiceAng8Enabled ? this.opportunity2Service : this.opportunityService;
    };
    TaggedCustomersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedDealerId && !changes.selectedDealerId.isFirstChange()) {
            this.getTagsPivotForDealers(false);
        }
    };
    TaggedCustomersComponent.prototype.goToOppSearch = function (tagId) {
        this.stateService.go('search.form', { 'tagId': tagId, 'dealerId': this.selectedDealerId });
    };
    TaggedCustomersComponent.prototype.getTagsPivotForDealers = function (flushCache) {
        var _this = this;
        this.widgetDataPromise = this.opportunityService.getTagsPivotForDealers(this.selectedDealerId, flushCache).then(function (data) {
            _this.tagData = data;
            _this.tagDataType1 = [];
            _this.tagDataType2 = [];
            for (var i = 0; i < _this.tagData.length; i++) {
                var tag = _this.tagData[i];
                var translateKey = "tagText" + tag.tagID;
                var translatedText = _this.tsService.instant(translateKey);
                if (translatedText != translateKey)
                    tag.tagText = translatedText;
                if (tag.tagTypeId == _this.tagTypes.dealership) {
                    _this.tagDataType1.push(tag);
                }
                else {
                    _this.tagDataType2.push(tag);
                }
            }
        });
    };
    TaggedCustomersComponent.prototype.refresh = function (clearCache) {
        if (clearCache) {
            this.getTagsPivotForDealers(true);
        }
    };
    return TaggedCustomersComponent;
}());
exports.TaggedCustomersComponent = TaggedCustomersComponent;
