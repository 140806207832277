import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component-ng.html',
    styleUrls: ['./confirm-modal.component.scss']
})

export class ConfirmModalComponent implements OnInit {

    @Input() messageKey: string;
    @Input() message: string;
    @Output() onClosed = new EventEmitter();

    constructor(public _bsModalRef: BsModalRef,
        public tService: TranslateService) {

    }

    public ngOnInit(): void {
    }

    public onContinue(): void {
        this.onClosed.emit({ confirmed: true });
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClosed.emit({ confirmed: false });
        this._bsModalRef.hide();
    }
}