"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../../core/toaster-logger.service");
var ngx_modal_wrapper_service_1 = require("../../../services/ngx-modal-wrapper.service");
var deal_sheet_process_component_1 = require("./deal-sheet-process.component");
var OpenProcessModalComponent = /** @class */ (function () {
    function OpenProcessModalComponent(tService, modalService, logger, document) {
        this.tService = tService;
        this.modalService = modalService;
        this.logger = logger;
        this.document = document;
        this.openModalChanged = new core_1.EventEmitter();
    }
    OpenProcessModalComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.openModal) {
            var initialState = {
                entityId: this.entityId
            };
            this.bsModalRef = this.modalService.show(deal_sheet_process_component_1.DealSheetProcessComponent, { initialState: initialState });
            this.bsModalRef.content.onClosed.subscribe(function (result) {
                if (result.started != null) {
                    if (result.started) {
                        _this.logger.success(_this.tService.instant("processSuccessfullyInitiated"));
                    }
                    else {
                        _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                    }
                }
                _this.openModalChanged.emit(false);
            });
            this.openModalChanged.emit(false);
        }
    };
    return OpenProcessModalComponent;
}());
exports.OpenProcessModalComponent = OpenProcessModalComponent;
