EditContactController.$inject = ["_", "$translate", "contactTypes", "doNotCallStatusTypes"];

/* injects from baggage-loader */
require('./edit-contact.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('editContact', {
  templateUrl: '/app/components/edit-contact.component.html',
  controller: EditContactController,
  bindings: {
    contact: '<',
    dealerSettings: '<',
    onChange: '&'
  }
});
/* @ngInject */

function EditContactController(_, $translate, contactTypes, doNotCallStatusTypes) {
  var $ctrl = this;
  $ctrl.update = update;
  $ctrl.markAsBadChanged = markAsBadChanged;
  $ctrl.markAsPreferred = markAsPreferred;
  $ctrl.isPhoneNumber = isPhoneNumber;
  $ctrl.isEmailAddress = isEmailAddress;
  $ctrl.$onChanges = onChanges;
  $ctrl.doNotCallStatusTypes = doNotCallStatusTypes;
  $ctrl.doNotCallStatusTranslateKeys = {};
  $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.notProcessed] = 'ndncNotProcessed';
  $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.invalidNumber] = 'ndncInvalidNumber';
  $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.notSubscribed] = 'ndncNotSubscribed';
  $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.onDoNotCallList] = 'ndncOnDoNotCallList';
  $ctrl.doNotCallStatusTranslateKeys[doNotCallStatusTypes.offDoNotCallList] = 'ndncOffDoNotCallList';
  $ctrl.doNotCallStatusAbbrevTranslateKeys = {};
  $ctrl.doNotCallStatusAbbrevTranslateKeys[doNotCallStatusTypes.notProcessed] = 'ndncNotProcessedAbbrev';
  $ctrl.doNotCallStatusAbbrevTranslateKeys[doNotCallStatusTypes.invalidNumber] = 'ndncInvalidNumberAbbrev';
  $ctrl.doNotCallStatusAbbrevTranslateKeys[doNotCallStatusTypes.notSubscribed] = 'ndncNotSubscribedAbbrev';
  $ctrl.doNotCallStatusAbbrevTranslateKeys[doNotCallStatusTypes.onDoNotCallList] = 'ndncOnDoNotCallListAbbrev';
  $ctrl.doNotCallStatusAbbrevTranslateKeys[doNotCallStatusTypes.offDoNotCallList] = 'ndncOffDoNotCallListAbbrev';

  function onChanges(changes) {
    if (changes.contact && changes.contact.currentValue) {
      $ctrl.displayValue = $ctrl.contact.value;
    }
  }

  function isPhoneNumber() {
    return $ctrl.contact && ($ctrl.contact.contactType == contactTypes.homePhone || $ctrl.contact.contactType == contactTypes.workPhone || $ctrl.contact.contactType == contactTypes.cellPhone);
  }

  function isEmailAddress() {
    return $ctrl.contact && $ctrl.contact.contactType == contactTypes.email;
  }

  function update() {
    var newContact = _.extend({}, $ctrl.contact, {
      value: $ctrl.displayValue
    });

    sendOnChange(newContact);
  }

  function markAsBadChanged() {
    var value = $ctrl.contact.isBad;

    var newContact = _.extend({}, $ctrl.contact, {
      isBad: value
    });

    sendOnChange(newContact);
  }

  function markAsPreferred() {
    var newContact = _.extend({}, $ctrl.contact, {
      isPreferred: true
    });

    sendOnChange(newContact);
  }

  function sendOnChange(newContact) {
    // Same schema as Angular's $onChanges
    var change = {
      previousValue: $ctrl.contact,
      currentValue: newContact
    };
    $ctrl.onChange({
      change: change
    });
  }
}