"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".modal-body[_ngcontent-%COMP%]{padding-top:10px!important}.form-group[_ngcontent-%COMP%]{margin-bottom:0}.nlo-info-wrapper[_ngcontent-%COMP%]   .nlo-title[_ngcontent-%COMP%]{float:left}.nlo-info-wrapper[_ngcontent-%COMP%]   .intro[_ngcontent-%COMP%]{font-size:14px;margin-top:8px;margin-bottom:15px}.nlo-info-wrapper[_ngcontent-%COMP%]   .nlo-float-right[_ngcontent-%COMP%]{float:right}.nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%]{width:100%;max-width:100%;margin-bottom:15px}.nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%] > tbody[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%] > td[_ngcontent-%COMP%], .nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%] > tbody[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%] > th[_ngcontent-%COMP%], .nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%] > tfoot[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%] > td[_ngcontent-%COMP%], .nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%] > tfoot[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%] > th[_ngcontent-%COMP%], .nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%] > thead[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%] > td[_ngcontent-%COMP%], .nlo-info-wrapper[_ngcontent-%COMP%]   .table[_ngcontent-%COMP%] > thead[_ngcontent-%COMP%] > tr[_ngcontent-%COMP%] > th[_ngcontent-%COMP%]{padding:4px 2px;border-top:none}.nlo-info-wrapper[_ngcontent-%COMP%]   .cancelBtn[_ngcontent-%COMP%]{margin-right:20px}"];
exports.styles = styles;
