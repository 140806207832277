/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerTradeinExtDto } from '../models/dealer-tradein-ext-dto';
@Injectable({
  providedIn: 'root',
})
class DealerTradeinClientService extends __BaseService {
  static readonly GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETPath = '/api/DealerTradein/GetDealerTradeins/{dealerId}/{makeId}/{yearId}/{modelId}/{trimId}';
  static readonly UpdateDealerTradeinsPATCHPath = '/api/DealerTradein';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DealerTradeinClientService.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETParams` containing the following parameters:
   *
   * - `yearId`:
   *
   * - `trimId`:
   *
   * - `modelId`:
   *
   * - `makeId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETResponse(params: DealerTradeinClientService.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETParams): __Observable<__StrictHttpResponse<Array<DealerTradeinExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;





    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerTradein/GetDealerTradeins/${params.dealerId}/${params.makeId}/${params.yearId}/${params.modelId}/${params.trimId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerTradeinExtDto>>;
      })
    );
  }
  /**
   * @param params The `DealerTradeinClientService.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETParams` containing the following parameters:
   *
   * - `yearId`:
   *
   * - `trimId`:
   *
   * - `modelId`:
   *
   * - `makeId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGET(params: DealerTradeinClientService.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETParams): __Observable<Array<DealerTradeinExtDto>> {
    return this.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETResponse(params).pipe(
      __map(_r => _r.body as Array<DealerTradeinExtDto>)
    );
  }

  /**
   * @param tradeins undefined
   * @return Success
   */
  UpdateDealerTradeinsPATCHResponse(tradeins?: Array<DealerTradeinExtDto>): __Observable<__StrictHttpResponse<Array<DealerTradeinExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = tradeins;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/DealerTradein`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerTradeinExtDto>>;
      })
    );
  }
  /**
   * @param tradeins undefined
   * @return Success
   */
  UpdateDealerTradeinsPATCH(tradeins?: Array<DealerTradeinExtDto>): __Observable<Array<DealerTradeinExtDto>> {
    return this.UpdateDealerTradeinsPATCHResponse(tradeins).pipe(
      __map(_r => _r.body as Array<DealerTradeinExtDto>)
    );
  }
}

module DealerTradeinClientService {

  /**
   * Parameters for GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGET
   */
  export interface GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGETParams {
    yearId: number;
    trimId: number;
    modelId: number;
    makeId: number;
    dealerId: number;
  }
}

export { DealerTradeinClientService }
