<h5 class="quick-links-header" translate="quickLinks"></h5>
<div *ngIf="modulePermissions.opportunities" class="quick-link-row" uiSref="opportunities">
    <icon class="icon aa-icon-opportunities"></icon>
    <label class="quick-link-description" translate="opportunities"></label>
</div>
<div *ngIf="modulePermissions.activities" class="quick-link-row" uiSref="activities">
    <icon class="icon aa-icon-activities"></icon>
    <label class="quick-link-description" translate="activities"></label>
</div>
<div *ngIf="modulePermissions.search" class="quick-link-row" uiSref="search.form">
    <icon class="icon aa-icon-search"></icon>
    <label class="quick-link-description" translate="search"></label>
</div>

<div *ngIf="modulePermissions.financeInsuranceManager" class="quick-link-row" uiSref="financeInsuranceManager">
    <icon class="icon aa-icon-finance-insurance-manager"></icon>
    <label class="quick-link-description" translate="financeInsuranceManager"></label>
</div>
<div *ngIf="modulePermissions.preOwnedManager" class="quick-link-row" uiSref="preOwnedManagerFindABuyer"
     [uiParams]="{ isExactMatch: false,
                    isShowUsed: true,
                    hasOpp: false,
                    inventorySortColumnName: 'DaysInStock',
                    ageVehicleGroup: -1 }">
    <icon class="icon aa-icon-find-buyer"></icon>
    <label class="quick-link-description" translate="preOwnedManagerQuickLinkLabel"></label>
</div>
<div *ngIf="modulePermissions.serviceManager" class="quick-link-row" uiSref="serviceManager">
    <icon class="icon aa-icon-service-drive"></icon>
    <label class="quick-link-description" translate="serviceManager"></label>
</div>
<div *ngIf="isSomeDealerOneToOneEnabled" class="quick-link-row" uiSref="serviceDriveOffers">
    <icon class="icon aa-icon-view-offer-letter"></icon>
    <label class="quick-link-description" translate="serviceDriveOffers"></label>
</div>
<div *ngIf="modulePermissions.conquests" class="quick-link-row" uiSref="conquests">
    <icon class="icon aa-icon-conquest"></icon>
    <label class="quick-link-description" translate="conquestsQuickLinkLabel"></label>
</div>

<div *ngIf="modulePermissions.wishlist" class="quick-link-row" uiSref="wishList">
    <icon icon="'wish-list'" class="-size-wish-list"></icon>
    <label class="quick-link-description" translate="inventoryWishlist"></label>
</div>

<div *ngIf="modulePermissions.wishlist" class="quick-link-row" uiSref="potentialNLO">
    <icon icon="'ai-manager'" class="-size-wish-list"></icon>
    <label class="quick-link-description" translate="nlo_MenuLabel"></label>
</div>
