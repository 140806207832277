import angular from 'angular';

angular.module('AaApp.Opp').filter('localeFuelEconomy', localeFuelEconomy);

/* @ngInject */
function localeFuelEconomy($filter, storageService, fuelTypes) {
    var PER_GALLON = " per gallon";
    var PER_LITER = " per liter";
    var KWH = " kWh";
    var CENTS_PER_LITER = " c/L";
    var CANADIAN_CULTURES = ['en-ca', 'fr-ca'];

    return function localFuelEconomyFilter(value, fuelTypeId, isMetric) {
        var savedCultureName = storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        if (fuelTypeId == fuelTypes.electric) {
            // Electric format: $123.50 kWh

            var formattedValue = $filter('localeCurrency')(value, true);
            return formattedValue + KWH;
        }
        else {
            // Canadian metric:     250 c/L
            // Non-Canadian metric: $2.50 per liter
            // US:                  $2.50 per gallon


            var isCanada = CANADIAN_CULTURES.indexOf(cultureName) > -1;
            if (isCanada && isMetric) {
                return $filter('localeNumber')(value) + CENTS_PER_LITER;
            }
            else if (isMetric) {
                return $filter('localeCurrency')(value, true) + (isMetric ? PER_LITER : PER_GALLON);
            }
            else {
                return $filter('localeCurrency')(value, true) + (isMetric ? PER_LITER : PER_GALLON);
            }
        }
    }
}
