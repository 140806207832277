import angular from 'angular';

angular.module('AaApp.Opp').directive('maxDate', maxDateDirective);

/* @ngInject */
function maxDateDirective(storageService, cultures) {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            var validateMinDate = function (input) {
                var maxDate = attrs.maxDate.replace('"', '').replace('"', '');
                var isValidInput = isValidDate(input);
                var isValidMaxDate = isValidDate(maxDate);

                if (!isValidInput || !isValidMaxDate) {
                    ctrl.$setValidity('maxDate', false);
                }
                else {
                    var inputDate;

                    if (Object.prototype.toString.call(input) === '[object Date]') {
                        inputDate = input;
                    }
                    else {
                        inputDate = createDateFromString(input);
                    }

                    maxDate = new Date(maxDate);

                    inputDate.setHours(0, 0, 0, 0);
                    maxDate.setHours(0, 0, 0, 0);

                    if (inputDate > maxDate) {
                        ctrl.$setValidity('maxDate', false);
                    }
                    else {
                        ctrl.$setValidity('maxDate', true);
                    }
                }

                return input;
            };

            ctrl.$parsers.unshift(validateMinDate);
            ctrl.$formatters.push(validateMinDate);

            attrs.$observe('maxDate', function () {
                validateMinDate(ctrl.$viewValue);
            });
        }
    };

    function isValidDate(dateStr) {
        if (dateStr == undefined)
            return false;

        if (Object.prototype.toString.call(new Date(dateStr)) !== '[object Date]') {
            return false;
        }

        return true;
    }

    function createDateFromString(dateString) {
        const culture = storageService.getItem('cultureName');
        let dateParts;

        switch (culture) {
            case cultures.enCA:
                dateParts = dateString.split('/');
                return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
            case cultures.frCA:
                dateParts = dateString.split('-');
                return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            case cultures.enUS:
            default:
                dateParts = dateString.split('/');
                return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
        }
    }
}
