/* global window */
import $ from 'jquery'
import './redactor.nobabel'
import './jquery.bind-first'

if (!window.RedactorPlugins)
    window.RedactorPlugins = {};

window.RedactorPlugins.linkVisibilityMessage = {
    init: function () {
        this.linkVisibilityMessageInsertLinkDialog();
        this.opts["modalOpenedCallback"] = this.modalOpenedCallback;
        this.opts["modalClosedCallback"]= this.modalClosedCallback;
        this.opts["imageUploadCallback"] = this.imageUploadCallback;

        this.$editor.on("mousemove", function () {
            var $resizingImg = $("#redactor-image-box img");
            if ($resizingImg.length == 1) {
                $resizingImg.attr("height", $resizingImg.height())
                    .attr("width", $resizingImg.width());
            }
        });

        // prevent moving img by dragging
        this.$editor.on("drop", function () {
            return false;
        });
    },
    linkVisibilityMessageInsertLinkDialog: function () {
        this.opts.modal_link = String()
            + '<section id="redactor-modal-link-insert">'
            + '<div>Links will only be clickable in an email message. Any links will be unlinked in PDF output.</div>'
            + '<form id="modal-form" method="get" action="">'
            + '<select id="redactor-predefined-links" style="width: 99.5%; display: none;"></select>'
            + '<label>' + this.opts.curLang.link + '</label>'
            + '<input type="text" class="redactor_input" required="" name="redactor_link_url" id="redactor_link_url" />'
            + '<label>' + this.opts.curLang.text + '</label>'
            + '<input type="text" class="redactor_input" required="" name="redactor_link_url_text" id="redactor_link_url_text" />'
            + '<label style="visibility:hidden"><input type="checkbox" id="redactor_link_blank" checked="checked"> ' + this.opts.curLang.link_new_tab + '</label>'
            + '</form>'
            + '</section>'
            + '<footer>'
            + '<button class="redactor_modal_btn redactor_btn_modal_close">' + this.opts.curLang.cancel + '</button>'
            + '<button id="redactor_insert_link_btn" class="redactor_modal_btn redactor_modal_action_btn">' + this.opts.curLang.insert + '</button>'
            + '</footer>';
    },
    imageUploadCallback: function ($image) {
        this.insertImageIntoLayoutTable($image);
    },
    modalOpenedCallback: function ($modal) {
        var modalId = $modal.find("section").attr("id");

        if (modalId == "redactor-modal-link-insert") {
            this.modalInsertLinkCallback($modal);
        } else if (modalId == "redactor-modal-image-edit") {
            this.modalEditImageCallback($modal);
        }
    },
    modalClosedCallback: function () {
        $(".ui-tooltip.ui-state-error").remove();
    },
    modalInsertLinkCallback: function () {
        var okText = this.opts.curLang.insert;
        var modalTitle = this.opts.curLang.link_insert;
        if (this.insert_link_node !== false) {  /* editing existing link */
            okText = this.opts.curLang.save;
            modalTitle = this.opts.curLang.link_edit;
        }
        $("#redactor_insert_link_btn").text(okText);
        $("#redactor_modal_header").text(modalTitle);
    },
    modalEditImageCallback: function () {
        $("#redactor_modal_header").text("Edit Image");
        var $image = $("#redactor-image-box").find("img");

        // default to opening new link in new tab
        $("#redactor_link_blank").prop("checked", true)
            .parent("label").css("visibility", "hidden").css("height", "0");

        // disable aligning; doesn't work for HTML email
        $("#redactor_form_image_align").hide()
            .prev("label").css("visibility", "hidden").css("height", "0");

        // LEFT and CENTER alignment that work for HTML email
        if ($image.closest(".redactor-layout-table").length > 0) {
            $("#redactor-modal-image-edit").append('<label>Position</label><select id="redactor_table_image_align"><option value="left">Left</option><option value="center">Center</option></select>');
        }

        var currentAlignment = "left";
        if ($image.closest("td[align='center']").length > 0) {
            currentAlignment = "center";
        }
        $("#redactor_table_image_align").val(currentAlignment);

        $("#redactorSaveBtn").bindFirst("click", $.proxy(function () {
            //validate link
            var link = $.trim($("#redactor_file_link").val());
            if (link !== "") {
                var pattern = '((xn--)?[a-z0-9]+(-[a-z0-9]+)*\\.)+[a-z]{2,}';
                var re = new RegExp('^(http|ftp|https)://' + pattern, 'i');
                var re2 = new RegExp('^' + pattern, 'i');

                if (link.search(re) == -1 && link.search(re2) == 0 && this.opts.linkProtocol) {
                    link = this.opts.linkProtocol + link;
                }
                $("#redactor_file_link").val(link)
            }
        }, this));

        $('#redactorSaveBtn').on("click", $.proxy(function () {
            //alignment
            if ($("#redactor_table_image_align").val() == "center") {
                $image.closest("td").attr("align", "center");
            } else {
                $image.closest("td").removeAttr("align");
            }

            this.sync();
        }, this));
    },
    insertImageIntoLayoutTable: function ($image) {
        if ($image.closest("table.redactor-layout-table").length < 1) {
            var tableId = parseInt(Math.floor(Math.random() * 99999));
            var $table = $('<table cellspacing="0" cellpadding="0" id="table' + tableId + '" class="redactor-layout-table"><tbody><tr><td class="col-1" align="center"></td></tr></tbody></table>');
            $image.wrap($table);
            this.sync();
        }

        var $loadImg = $("<img />").attr("src", "");
        $loadImg.attr("src", $image.attr("src"))
            .load(function () {
                $image.attr("height", this.height)
                    .attr("width", this.width)
                    .css("width", this.width + "px");
            });
    }
};
