
/* injects from baggage-loader */
require('./customer-edit-action.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('customerEditAction', {
    templateUrl: '/app/components/deal-sheet/customer-edit-action.component.html',
    bindings: {
        customer: '<',
        permissions: '<',
        dealerSettings: '<',
        pricingPlan: '<',
        useIcon: '<',
        onCustomerEdit: '&',
        onCancel: '&'
    }
});
