
/* injects from baggage-loader */
require('./google-maps-modal.component.html');

import angular from 'angular';
//import {getGoogle} from '../../services/google.service'

angular.module('AaApp.Opp').component('googleMapsModal', {
    templateUrl: '/app/components/modals/google-maps-modal.component.html',
    controller: GoogleMapsModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
        address: '<'
    }
});

/* @ngInject */
function GoogleMapsModalController($q, googleService) {
    var $ctrl = this;

    $ctrl.$onInit = init;
    function init(changes) {
        $ctrl.address = $ctrl.resolve.address;

        return $q.all({
            responses: googleService.getCoordinates($ctrl.address),
            google: googleService.getGoogle()
        }).then((data) => setAddressCoordinates(data.responses, data.google),
        (error) => addressIsInvalid(error));
    }

    function addressIsInvalid(error){
        var node = document.createElement("div");
        var textnode = document.createTextNode('The address was not found by Google Maps.  Please correct the address and try again. Address: ' + $ctrl.address);
        node.appendChild(textnode);
        document.getElementById('map').appendChild(node);
        return null;
    }

    function setAddressCoordinates(responses, google){
        if (responses.results.length < 1) return addressIsInvalid();
        var coords = responses.results[0].geometry.location;

        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 20,
            center: coords,
            mapTypeId: 'satellite'
        });

        var marker = new google.maps.Marker({
            position: coords,
            map: map
        });
    }
}
