<div class="mobile-vehicles-wrapper" [ngBusy]="busy">
    <alerts [values]="opportunity?.alerts" [showRelavantInfo]="false"></alerts>
    <tags [values]="opportunity?.tags" [dealerId]="opportunity?.dealerId" [vehicleId]="opportunity?.vehicleId"></tags>
    <div class="dealsheet-body">
        <div class="carcomparison">
            <div class="carcomparisonie11flexfix">
                <div class="current-contract-summary contract-header qa-existing-vehicle" [ngClass]="opportunity?.currentContract.conquestType != null ? 'conquest-header'
                      : vehicleTypeKeys[opportunity?.currentContract.vehicleType] + '-header'">
                    <div class="contract">
                        <div class="current-vehicle-label">
                            <span>{{'currentVehicle' | translate | uppercase}}</span>
                        </div>
                        <div class="body">
                            <div class="col-sm-12 current-contract-summary-header">
                                <div class="col-sm-6">
                                    <div class="vehicle-info">
                                        <vehicle-summary [vehicle]="opportunity?.currentVehicle"
                                            [countryId]="userProfile?.countryId" [isReplacement]="false">
                                        </vehicle-summary>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="contract-info">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <sale-type [value]="opportunity?.currentContract.saleType"
                                                    [conquestType]="opportunity?.currentContract.conquestType">
                                                </sale-type>
                                            </div>
                                        </div>
                                        <div>
                                            <payments [type]="paymentTypes.current"
                                                [contract]="opportunity?.currentContract" [permissions]="permissions"
                                                [alertExists]="alertExists" [flexAlertExists]="flexAlertExists">
                                            </payments>
                                        </div>
                                        <annual-percentage-rate class="inline"
                                            [value]="opportunity?.currentContract.apr">
                                        </annual-percentage-rate>
                                        <money-factor [value]="opportunity?.currentContract.moneyFactor"></money-factor>
                                        <span class="inline right left"
                                            *ngIf="opportunity?.currentContract.allowedMileage != null && permissions?.viewContract">,</span>
                                        <allowed-mileage [value]="opportunity?.currentContract.allowedMileage">
                                        </allowed-mileage>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-xs-12 current-contract-summary-body">
                                <div class="col-sm-6 col-xs-12">
                                    <repair-orders [date]="opportunity?.currentVehicle.openDate"
                                        [total]="opportunity?.currentVehicle.eighteenMonthServiceCount"
                                        [alertExists]="serviceAlertExists" [abbreviate]="true"></repair-orders>
                                    <service-appointment [date]="opportunity?.currentVehicle.appointmentDate"
                                        [alertExists]="appointmentAlertExists" [abbreviate]="true">
                                    </service-appointment>
                                    <mileage-edit-action [recordedMileage]="opportunity?.currentVehicle.odometer"
                                        [recordedDate]="opportunity?.currentVehicle.lastOdometerReadingDate"
                                        [estimatedMileage]="opportunity?.currentVehicle.estimatedCurrentMileage"
                                        [permissions]="permissions" [opportunityId]="opportunity?.id">
                                    </mileage-edit-action>
                                    <projected-mileage [value]="opportunity?.currentContract.estimatedMaturityMileage"
                                        alertExists="mileageAlertExists"></projected-mileage>
                                    <mileage-penalty [current]="opportunity?.currentContract.currentMileagePenalty"
                                        [alertExists]="mileageAlertExists"
                                        [projected]="opportunity?.currentContract.estimatedMileagePenalty">
                                    </mileage-penalty>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <cash-paid [value]="opportunity?.currentContract.cashPaid"></cash-paid>
                                    <trade-in-value inputName="summary-tradeinvalue"
                                        [value]="opportunity?.currentVehicle?.tradeValue" [permissions]="permissions"
                                        [readonly]="true">
                                    </trade-in-value>
                                    <cash-down [value]="opportunity?.currentContract.cashDown"></cash-down>
                                    <payoff [value]="opportunity?.currentContract.payoffAmount"
                                        [paymentsRemaining]="opportunity?.currentContract.paymentsRemaining"
                                        [paidOff]="opportunity?.currentContract.paidOff"
                                        [residualAmount]="opportunity?.currentContract.residualAmount"
                                        [permissions]="permissions" [saleType]="opportunity?.currentContract.saleType">
                                    </payoff>
                                    <contract-end [value]="opportunity?.currentContract.lastPaymentDate"
                                        [alertExists]="contractEndAlertExists"></contract-end>
                                    <extended-warranty [contract]="opportunity?.currentContract"
                                        [vehicle]="opportunity?.currentVehicle" [permissions]="permissions"
                                        [alertExists]="warrantyAlertExists"
                                        [replacementVehicleType]="opportunity?.replacementVehicle.type">
                                    </extended-warranty>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!opportunity?.currentVehicle.trimID">
                        <div class="col-sm-offset-1 col-sm-10" translate="asteriskNoInformationAvailable">
                        </div>
                        <div class="col-sm-offset-1 col-sm-10" *ngIf="opportunity?.currentVehicle.yearID"
                            translate="vehicleManufacturedInYear"
                            [translateParams]="{year: opportunity?.currentVehicle.yearID}">
                        </div>
                    </div>
                    <div>
                        <div class="col-sm-4">
                            <trade-source-logo [countryId]="userProfile?.countryId"
                                [tradeSourceId]="opportunity?.currentVehicle.tradeSourceID"
                                [hidden]="!(opportunity?.currentVehicle.tradeSourceID == tradeValueTypes.blackBookTradeValue || opportunity?.currentVehicle.tradeSourceID == tradeValueTypes.galvesTradeValue)">
                            </trade-source-logo>
                        </div>
                        <div class="col-sm-8">
                            <div class="vehicle-reports pull-right">
                                <auto-check [permissions]="permissions" [dealerSettings]="opportunity?.dealerSettings"
                                    [opportunityId]="opportunity?.id" [vin]="opportunity?.currentVehicle.vin"
                                    [readonly]="true">
                                </auto-check>
                                <carfax [permissions]="permissions" [dealerSettings]="opportunity?.dealerSettings"
                                    [opportunityId]="opportunity?.id" [vin]="opportunity?.currentVehicle.vin"
                                    [readonly]="true">
                                </carfax>
                                <carproof [permissions]="permissions" [dealerSettings]="opportunity?.dealerSettings"
                                    [opportunityId]="opportunity?.id" [vin]="opportunity?.currentVehicle.vin"
                                    [readonly]="true">
                                </carproof>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="vehicle-seperator">
            <div class="carcomparisonie11flexfix">
                <div class="replacement-contract-summary contract-header  qa-replacement-vehicle"
                    [ngClass]="vehicleTypeKeys[opportunity?.replacementVehicle.type] + '-header'">
                    <div class="contract">
                        <replacement-vehicle-offers [currentVehicle]="opportunity?.replacementVehicle"
                            [replacementVehicles]="opportunity?.replacementVehicles"></replacement-vehicle-offers>
                        <div class="body">
                            <div class="col-sm-12 replacement-contract-summary-header">
                                <div class="col-sm-6 col-xs-12">
                                    <div class="vehicle-info">
                                        <vehicle-summary [vehicle]="opportunity?.replacementVehicle"
                                            [countryId]="userProfile?.countryId" [isReplacement]="true"
                                            [pricingPlanId]="(opportunity?.customer.pricingPlanId ? opportunity?.customer.pricingPlanId : null)"
                                            [trimPlanPriceSourceId]="opportunity?.replacementVehicle.trimPlanPriceSourceId "
                                            [trimPlanPriceSourceName]="opportunity?.replacementVehicle.trimPlanPriceSourceName">
                                        </vehicle-summary>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <div class="contract-info qa-replacement-contract-info">
                                        <span class="value text-uppercase qa-replacement-sale-type"
                                            *ngIf="opportunity?.replacementContract?.saleType && opportunity?.replacementContract?.payment"
                                            translate="{{saleTypeKeys[opportunity?.replacementContract?.saleType]}}"></span>
                                        <div>
                                            <payments [type]="paymentTypes.replacement"
                                                [contract]="opportunity?.replacementContract"
                                                [flexAlertExists]="opportunity?.replacementContract?.isFlexAlert"
                                                [alertExists]="opportunity?.replacementContract?.isAlert"></payments>
                                        </div>
                                        <annual-percentage-rate [value]="opportunity?.replacementContract?.apr">
                                        </annual-percentage-rate>
                                        <money-factor [value]="opportunity?.replacementContract?.moneyFactor">
                                        </money-factor>
                                        <span class="warning-text"
                                            *ngIf="opportunity?.replacementContract?.isOverAllowance"
                                            translate="ltvOverAllowance"></span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-12 replacement-contract-summary-body">
                                <div class="col-sm-6 col-xs-12">
                                    <factory-rebate [value]="opportunity?.replacementContract?.factoryRebate"
                                        [programSourceId]="opportunity?.replacementContract?.programSourceID">
                                    </factory-rebate>
                                    <dealer-rebate [value]="opportunity?.replacementContract?.dealerRebate">
                                    </dealer-rebate>
                                    <personalized-offer
                                        [programDetails]="opportunity?.replacementContract?.programDetails">
                                    </personalized-offer>
                                    <pull-forward *ngIf="showPullForward"
                                        [value]="opportunity?.replacementContract?.pullForwardAmount"></pull-forward>
                                    <preferred-equity [value]="opportunity?.replacementContract?.preferredEquity">
                                    </preferred-equity>
                                    <loyalty-rebate [value]="opportunity?.replacementContract?.loyaltyRebate">
                                    </loyalty-rebate>
                                    <fuel-difference [fuelEconomyComparison]="fuelEconomyComparison"></fuel-difference>
                                    <mileage [value]="opportunity?.replacementVehicle.odometer"
                                        [vehicleType]="opportunity?.replacementVehicle.type"></mileage>
                                </div>
                                <div class="col-sm-6 col-xs-12">
                                    <price [value]="opportunity?.replacementVehicle.price" [permissions]="permissions"
                                        readonly="true">
                                    </price>
                                    <financed-amount [value]="opportunity?.replacementContract?.financedAmount">
                                    </financed-amount>
                                    <capital-cost [value]="opportunity?.replacementContract?.capitalCost">
                                    </capital-cost>
                                    <residual [value]="opportunity?.replacementContract?.residualAmount"></residual>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vehicle-reports"
                        *ngIf="opportunity?.replacementVehicle.type == vehicleTypes.used || opportunity?.replacementVehicle.type == vehicleTypes.cpo">
                        <auto-check [permissions]="permissions" [dealerSettings]="opportunity?.dealerSettings"
                            [opportunityId]="opportunity?.id" [vin]="opportunity?.replacementVehicle.vin"
                            [readonly]="true">
                        </auto-check>
                        <carfax [permissions]="permissions" [dealerSettings]="opportunity?.dealerSettings"
                            [opportunityId]="opportunity?.id" [vin]="opportunity?.replacementVehicle.vin"
                            [readonly]="true">
                        </carfax>
                        <carproof [permissions]="permissions" [dealerSettings]="opportunity?.dealerSettings"
                            [opportunityId]="opportunity?.id" [vin]="opportunity?.replacementVehicle.vin"
                            [readonly]="true">
                        </carproof>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <nav class="navbar navbar-default navbar-fixed-bottom" role="navigation">
        <div class="container">
            <button (click)="viewReplacementDetails()"
                class="btn btn-primary btn-block">{{'viewReplacementDetails' | translate}}</button>
        </div>
    </nav>
</div>