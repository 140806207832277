
/* injects from baggage-loader */
require('./activity.component.html');

import { RATING_ICONS, RATING_TITLES } from '../../constants/review-pro-rating';
import {ActivityTypes as activityTypes} from '../../constants';

import angular from 'angular';
import _ from 'lodash';

angular.module('AaApp.Opp').component('activity', {
    templateUrl: '/app/components/deal-sheet/activity.component.html',
    controller: ActivityController,
    bindings: {
        activity: '<',
        type: '<',
        submittedBy: '<',
        assignedTo: '<',
        notes: '<',
        result: '<',
        templateName: '<',
        fileUrl: '<',
        submittedDateTime: '<',
        scheduledDateTime: '<',
        activityActionId: '<',
        firstOpenDate: '<',
        lastOpenDate: '<',
        totalCount: '<',
        emailFrom: '<',
        emailTo: '<',
        emailSubject: '<',
        contactPhone: '<',
        oneToOneKey: '<',
        typeOfCall: '<',
        templateEmailId: '<',
        accessCode: '<',
        fullName:'<',
        mobileView: '<'
    }
});

function ActivityController(
    activityResults, activitySubTypes,
    legacyService, templateService, customerProcessStatuses
) {
    "ngInject";

    const $ctrl = this;
    $ctrl.activityTypeTranslateKeys;
    $ctrl.activityResultTranslateKeys;
    $ctrl.playableUrl = null;
    $ctrl.activityResults = activityResults;

    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;
    $ctrl.activitySubTypes = activitySubTypes;
    $ctrl.activityTypes = activityTypes;
    $ctrl.downloadUpgradeProposal = downloadUpgradeProposal;
    $ctrl.getIconClass = getIconClass;
    $ctrl.getProcessStatusClass = getProcessStatusClass;
    $ctrl.hasAudioLink = hasAudioLink;
    $ctrl.viewTemplateOutput = viewTemplateOutput;
    $ctrl.ratingIcon = null;
    $ctrl.ratingTitle = null;
    $ctrl.customerProcessStatuses = customerProcessStatuses;
  
    function onChanges(changes) {
        
        if ((changes.activity && 
                changes.activity.currentValue && 
                (changes.activity.currentValue.type == $ctrl.activityTypes.reviewProServiceRating || 
                changes.activity.currentValue.type == $ctrl.activityTypes.reviewProSalesRating))) {
            $ctrl.ratingIcon = RATING_ICONS[$ctrl.activity.reviewRating];
            $ctrl.ratingTitle = RATING_TITLES[$ctrl.activity.reviewRating];
        }
    }

    function init() {
        $ctrl.activityTypeTranslateKeys = _.invert(activityTypes);
        $ctrl.activityResultTranslateKeys = _.assign({},
            _.invert(activityResults.phoneCall),
            _.invert(activityResults.appointment),
            _.invert(activityResults.note));
    }

    function downloadUpgradeProposal() {
        legacyService.downloadUpgradeProposalByActivity({
            activityId: $ctrl.activity.activityId
        })
    }

    function hasAudioLink() {
        return (isPhoneType() && hasRecordingUrl());
    }

    function hasRecordingUrl() {
        return ($ctrl.activity && $ctrl.activity.recordingUrl && $ctrl.activity.recordingUrl !== '');
    }

    function isPhoneType() {
        switch ($ctrl.type) {
            case activityTypes.logPhoneCall:
            case activityTypes.schedulePhoneCall:
            case activityTypes.scheduleAppointment:
                return true;
        }
        return false;
    }

    function getProcessStatusClass(status) {
        var statusClass = '';
        switch (status) {
            case customerProcessStatuses.new:
                statusClass = 'process-status-new';
                break;
            case customerProcessStatuses.inProgress:
            case customerProcessStatuses.inProgressExternal:
                statusClass = 'process-status-in-progress';
                break;
            case customerProcessStatuses.success:
                statusClass = 'process-status-completed';
                break;
            case customerProcessStatuses.overdue:
            case customerProcessStatuses.cancelled:
            case customerProcessStatuses.autoCancelled:
                statusClass = 'process-status-overdue';
                break;
            case customerProcessStatuses.waiting:
                statusClass = 'process-status-waiting';
                break;
            default:
                statusClass = 'process-status-waiting';
        }

        return statusClass;
    }

    function getIconClass() {
        switch ($ctrl.type) {
            case activityTypes.logNote:
            case activityTypes.logCrmProspectAlert:
            case activityTypes.logCrmProspectStatus:
            case activityTypes.logCrmRemark:
            case activityTypes.logCrmShowroomRemark:
            case activityTypes.logCrmCustomerWillBeBack:
            case activityTypes.autofiDigitalRetailing:
            case activityTypes.autofiDigitalRetailingCreditApp:
            case activityTypes.autofiDigitalRetailingApptSched:
            case activityTypes.export:
            case activityTypes.appraisal:
            case activityTypes.dmsPush:
                return 'aa-icon-core-takenote-icon-000000';
            case activityTypes.logPhoneCall:
            case activityTypes.completePhoneCall:
            case activityTypes.expirePhoneCall:
            case activityTypes.logCrmInboundCall:
            case activityTypes.logCrmOutboundCall:
                return 'aa-icon-core-phone-icon-000000';
            case activityTypes.scheduleAppointment:
            case activityTypes.rescheduleAppointment:
            case activityTypes.reassignAppointment:
            case activityTypes.completeAppointment:
            case activityTypes.expireAppointment:
            case activityTypes.cancelAppointment:
                return 'aa-icon-core-calendar-icon-000000';
            case activityTypes.cancelPhoneCall:
            case activityTypes.schedulePhoneCall:
            case activityTypes.reschedulePhoneCall:
            case activityTypes.reassignPhoneCall:
                return 'aa-icon-core-schedule-phone-call-000002';
            case activityTypes.printTemplate:
                return 'aa-icon-core-print-icon-000000';
            case activityTypes.printOfferLetter:
                return 'aa-icon-view-offer-letter';
            case activityTypes.pushToCrm:
                return 'aa-icon-core-send-icon-000000';
            case activityTypes.sendOneToOneMail:
            case activityTypes.sendOneToOneEmail:
            case activityTypes.sendPartnerExportMail:
            case activityTypes.purlView:
            case activityTypes.purlSubmit:
            case activityTypes.facebookAdded:
            case activityTypes.facebookVerified:
                return 'aa-icon-1-2-1-icon-46';
            case activityTypes.logCrmOutboundEmail:
            case activityTypes.logCrmInboundEmail:
            case activityTypes.sendCrmLetter:
            case activityTypes.sendEmailTemplate:
            case activityTypes.sendEmail:
            case activityTypes.emailReply:
            case activityTypes.emailReplyCustomer:
            case activityTypes.emailReplyDealer:
                return 'aa-icon-core-email-icon-000000';
            case activityTypes.sendCrmReminder:
                return 'aa-icon-aa-core-icons-copy-48';
            case activityTypes.sendCrmTextMessage:
                return 'aa-icon-aa-core-icons-copy-47';
            case activityTypes.sentSLMEmailOffer:
            case activityTypes.pandoProcess:
            case activityTypes.customerPandoProcess:
                return 'aa-icon-pandoicon-1';
            case activityTypes.websiteEngaged:
            case activityTypes.websiteLeadSubmission:
                return 'web';
            default:
                return '';
        }
    }

    function viewTemplateOutput() {
        templateService.downloadPdf($ctrl.activity.templateOutputId, $ctrl.activity.templateName)
    }
}
