import { Component, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { VehicleUserTradeValueClientService } from '../../../../generated/services';
import { StoreVehicleUserTradeValueDto } from '../../../../generated/models';
@Component({
    selector: 'trade-in-value',
    templateUrl: './trade-in-value.component-ng.html',
    styleUrls: ['./trade-in-value.component.scss']
})
export class TradeInValueComponent {
    @Input() vehicle: any;
    @Input() value: number;
    @Input() showAdjustment: boolean;
    @Input() mileageAdjustment: any;
    @Input() permissions: DealSheetPermissions;
    @Input() inputName: string;
    @Output() tradeValueChange = new EventEmitter<any>();
    @Input() readonly: boolean
    private hasChanged: boolean = false;

    constructor(elementRef: ElementRef, injector: Injector, private vehicleUserTradeValueClientService: VehicleUserTradeValueClientService,) {
    }

    public onValueChange(value) {
        this.validate(value);
        this.tradeValueChange.emit({ tradeValue: this.value, logged: this.hasChanged });
        if (this.hasChanged) {
            this.hasChanged = false;
        }
    }
    
    public onStoreTradeValue(value) {
        this.validate(value);
        if (this.vehicle && this.vehicle.tradeValue != value) {
            const params: StoreVehicleUserTradeValueDto = { dealerid: this.vehicle.dealerID, vehicleid: this.vehicle.vehicleID, tradevalue: value };
            this.vehicleUserTradeValueClientService.StoreVehicleUserTradeValueResponse(params).toPromise<any>();

            this.tradeValueChange.emit({ tradeValue: this.value, logged: this.hasChanged });
            if (this.hasChanged) {
                this.hasChanged = false;
            }
        }
    }

    public tradeOnKeyPress(event) {
        if (event.which === 13) {
            this.tradeValueChange.emit({ tradeValue: this.value, logged: this.hasChanged });
        }
    }

    public update(source) {
        this.validate(source);
        this.hasChanged = true;
    }

    // vlidate input to number only
    private validate(source) {
        if (source == '') { this.value = 0; return; }
        if (!source) { this.value = 0; return; }
        source = source + '';
        source = source.trim();
        source = source.replace(/[^0-9]/g, '');
        this.value = parseInt(source);
    }
}