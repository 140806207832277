"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../constants");
var collapse_1 = require("ngx-bootstrap/collapse");
var CustomerConnectComponent = /** @class */ (function () {
    function CustomerConnectComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.onLogScheduleSubmit = new core_1.EventEmitter();
        this.onCustomerEdit = new core_1.EventEmitter();
        this.ActivityActionEnum = constants_1.ActivityActionEnum;
    }
    CustomerConnectComponent.prototype.ngOnInit = function () {
        // init defaults
        this.scheduleModalParamObject = {
            modalType: null,
            opportunityId: this.opportunity && this.opportunity.id ? this.opportunity.id : null,
            dealerId: this.opportunity && this.opportunity.customer ? this.opportunity.customer.dealerID : null,
            dealSheetOwnerId: this.opportunity && this.opportunity.assignedUserId ? this.opportunity.assignedUserId : null,
            maxScheduleFutureDays: this.maxScheduleFutureDays,
            usersObject: this.users,
            activityObject: null,
            ownerUserInfoObject: this.opportunity && this.opportunity.ownerUserInfo ? this.opportunity.ownerUserInfo : null,
            expirationDate: this.opportunity && this.opportunity.expirationDate ? this.opportunity.expirationDate : null,
            uid: this.opportunity && this.opportunity.uid ? this.opportunity.uid : null
        };
    };
    CustomerConnectComponent.prototype.ngOnChanges = function (changes) {
        if ((changes.opportunity && changes.opportunity.currentValue
            || changes.users && changes.users.currentValue
            || changes.maxScheduleFutureDays && changes.maxScheduleFutureDays.currentValue
            || changes.opportunity && changes.opportunity.currentValue && changes.opportunity.currentValue.assignedUserId)
            && (this.opportunity && this.users && this.maxScheduleFutureDays)) {
            this.scheduleModalParamObject = {
                modalType: null,
                opportunityId: this.opportunity && this.opportunity.id ? this.opportunity.id : null,
                dealerId: this.opportunity && this.opportunity.customer ? this.opportunity.customer.dealerID : null,
                dealSheetOwnerId: this.opportunity && this.opportunity.assignedUserId ? this.opportunity.assignedUserId : null,
                maxScheduleFutureDays: this.maxScheduleFutureDays,
                usersObject: this.users,
                activityObject: null,
                ownerUserInfoObject: this.opportunity && this.opportunity.ownerUserInfo ? this.opportunity.ownerUserInfo : null,
                expirationDate: this.opportunity && this.opportunity.expirationDate ? this.opportunity.expirationDate : null,
                uid: this.opportunity && this.opportunity.uid ? this.opportunity.uid : null
            };
        }
        if (changes.isCustomerConnectOpen && changes.isCustomerConnectOpen.currentValue) {
            // always default to Log Call when opening Customer Connect
            if (!this.action) {
                this.onActionSelect(this.defaultCustomerConnectAction);
            }
            this.collapse.show();
        }
    };
    CustomerConnectComponent.prototype.onActionSelect = function (actionSelected) {
        this.actionTime = new Date().getTime();
        this.action = actionSelected;
        this.changeDetectorRef.detectChanges();
    };
    return CustomerConnectComponent;
}());
exports.CustomerConnectComponent = CustomerConnectComponent;
