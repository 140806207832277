"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./mobile-activities.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../activity-list/activity-list.component"));
var i3 = __importStar(require("ng-busy"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("@ngx-translate/core"));
var i6 = __importStar(require("./mobile-activities.component"));
var i7 = __importStar(require("@uirouter/core"));
var i8 = __importStar(require("../../../../services/mobile-deal-sheet-data-caching.service"));
var styles_MobileActivitiesComponent = [i0.styles];
var RenderType_MobileActivitiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileActivitiesComponent, data: {} });
exports.RenderType_MobileActivitiesComponent = RenderType_MobileActivitiesComponent;
function View_MobileActivitiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "activity-list", [], null, null, null, null, null)), i1.ɵdid(1, 999424, null, 0, i2.ActivityListComponent, [i1.ElementRef, i1.Injector], { activities: [0, "activities"], permissions: [1, "permissions"], status: [2, "status"], users: [3, "users"], opportunityId: [4, "opportunityId"], assignedUserFullName: [5, "assignedUserFullName"], assignedUserId: [6, "assignedUserId"], ownerUserInfo: [7, "ownerUserInfo"], hasReviewPro: [8, "hasReviewPro"], hasGeoAlert: [9, "hasGeoAlert"], fullName: [10, "fullName"], mobileView: [11, "mobileView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activities; var currVal_1 = _co.permissions; var currVal_2 = _co.status; var currVal_3 = _co.users; var currVal_4 = _co.opportunityId; var currVal_5 = _co.assignedUserFullName; var currVal_6 = _co.assignedUserId; var currVal_7 = _co.ownerUserInfo; var currVal_8 = _co.hasReviewPro; var currVal_9 = _co.hasGeoAlert; var currVal_10 = _co.fullName; var currVal_11 = true; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_MobileActivitiesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 12, "div", [["class", "col-xs-12 mobile-activities"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.InstanceConfigHolderService, i3.InstanceConfigHolderService, []), i1.ɵprd(131584, null, i3.ɵa, i3.ɵa, []), i1.ɵdid(3, 409600, null, 0, i3.NgBusyDirective, [i3.ɵb, i3.InstanceConfigHolderService, i1.ComponentFactoryResolver, i3.ɵa, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i1.Renderer2, i1.Injector], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "activity-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileActivitiesComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "nav", [["class", "navbar navbar-default navbar-fixed-bottom"], ["role", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-primary btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLogActivityNavigation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.busy; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.permissions == null) ? null : _co.permissions.activitiesLog); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("customerConnectTab")); _ck(_v, 11, 0, currVal_2); }); }
exports.View_MobileActivitiesComponent_0 = View_MobileActivitiesComponent_0;
function View_MobileActivitiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-activities", [], null, null, null, View_MobileActivitiesComponent_0, RenderType_MobileActivitiesComponent)), i1.ɵdid(1, 245760, null, 0, i6.MobileActivitiesComponent, [i7.StateService, i8.MobileDealSheetDataCachingService, i7.Transition], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileActivitiesComponent_Host_0 = View_MobileActivitiesComponent_Host_0;
var MobileActivitiesComponentNgFactory = i1.ɵccf("mobile-activities", i6.MobileActivitiesComponent, View_MobileActivitiesComponent_Host_0, {}, {}, []);
exports.MobileActivitiesComponentNgFactory = MobileActivitiesComponentNgFactory;
