"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiActivityTypes = {
    note: 1,
    phoneCall: 2,
    appointment: 3,
    email: 4,
    compass: 5,
    crm: 6,
    pandoProcess: 7
};
