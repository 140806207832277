import angular from 'angular';
import $ from 'jquery';
import { getCultureByCode } from '../utilities/culture';
import { makeNumberLinkFunction, wrapElementInInputGroup } from './number-input.directive';

const NumberLinkFunction = makeNumberLinkFunction({ precision: 2 });

angular.module('AaApp.Opp').directive('percentInput', function (storageService) {
    "ngInject";

    const cultureName = storageService.getItem('cultureName') || 'en-US';
    const culture = getCultureByCode(cultureName);


    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            precision: '<',
        },
        link: function PercentLinkFunction($scope, $element, $attributes, $ngModelCtrl) {
            if (!$scope.addonText) {
                $scope.addonText = '%';
                $scope.addonTextBefore = culture.percentSymbolBefore;
            }

            NumberLinkFunction($scope, $element, $attributes, $ngModelCtrl);
        },
    }
})
