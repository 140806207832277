"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./score-info-modal.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("@angular/common"));
var i4 = __importStar(require("../../icon/icon.component.ngfactory"));
var i5 = __importStar(require("../../icon/icon.component"));
var i6 = __importStar(require("@angular/platform-browser"));
var i7 = __importStar(require("ng-busy"));
var i8 = __importStar(require("./score-info-modal.component"));
var i9 = __importStar(require("ngx-bootstrap/modal"));
var i10 = __importStar(require("../../../services/opportunity2.service"));
var styles_ScoreInfoModalComponent = [i0.styles];
var RenderType_ScoreInfoModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScoreInfoModalComponent, data: {} });
exports.RenderType_ScoreInfoModalComponent = RenderType_ScoreInfoModalComponent;
function View_ScoreInfoModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [["class", "feature-desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "grid-alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "fa alerts-letterblock-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(5, null, ["\u00A0", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "fa fa", _v.parent.context.$implicit.alertCode, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.alertCodeLetter; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("alertName")); _ck(_v, 5, 0, currVal_2); }); }
function View_ScoreInfoModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.featureDescription; _ck(_v, 1, 0, currVal_0); }); }
function View_ScoreInfoModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [["class", "feature-desc"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.isAlert; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScoreInfoModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "td", [["class", "text-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "posValue": 0, "negValue": 1 }), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "text-center"; var currVal_1 = _ck(_v, 4, 0, (_v.context.$implicit.weight > 0), (_v.context.$implicit.weight < 0)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.isAlert; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_v.context.$implicit.isAlert; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.posneg; _ck(_v, 5, 0, currVal_2); }); }
function View_ScoreInfoModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [["class", "text-center"], ["translate", "influence"]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "th", [["translate", "ScoreFeatureLabel"]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_2)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "influence"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "ScoreFeatureLabel"; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.features; _ck(_v, 11, 0, currVal_2); }, null); }
function View_ScoreInfoModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "form-group row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-12 no-score-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["translate", "NoScoreData"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "NoScoreData"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ScoreInfoModalComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "form-group row note-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "noteLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ":"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [["class", "noteList"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [["translate", "scoreNote1"]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [["translate", "scoreNote2"]], null, null, null, null, null)), i1.ɵdid(9, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_1 = "scoreNote1"; _ck(_v, 7, 0, currVal_1); var currVal_2 = "scoreNote2"; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("note")); _ck(_v, 3, 0, currVal_0); }); }
function View_ScoreInfoModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "modal-wrapper score-info-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "score-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "modal-title"], ["translate", "BehindScoreTitle"]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "score-float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "icon", [["class", "icon aa-icon-close-icon"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(8, 638976, null, 0, i5.IconComponent, [i6.DomSanitizer], null, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 10, "div", [["class", "modal-body form-horizontal"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.InstanceConfigHolderService, i7.InstanceConfigHolderService, []), i1.ɵprd(131584, null, i7.ɵa, i7.ɵa, []), i1.ɵdid(12, 409600, null, 0, i7.NgBusyDirective, [i7.ɵb, i7.InstanceConfigHolderService, i1.ComponentFactoryResolver, i7.ɵa, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i1.Renderer2, i1.Injector], { options: [0, "options"] }, null), i1.ɵpod(13, { busy: 0, delay: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_1)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_6)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScoreInfoModalComponent_7)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["translate", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "BehindScoreTitle"; _ck(_v, 4, 0, currVal_0); _ck(_v, 8, 0); var currVal_1 = _ck(_v, 13, 0, _co.busy, 200); _ck(_v, 12, 0, currVal_1); var currVal_2 = (_co.featuresLoaded && (_co.features.length > 0)); _ck(_v, 15, 0, currVal_2); var currVal_3 = (_co.featuresLoaded && (_co.features.length == 0)); _ck(_v, 17, 0, currVal_3); var currVal_4 = (_co.featuresLoaded && (_co.features.length > 0)); _ck(_v, 19, 0, currVal_4); var currVal_5 = "close"; _ck(_v, 22, 0, currVal_5); }, null); }
exports.View_ScoreInfoModalComponent_0 = View_ScoreInfoModalComponent_0;
function View_ScoreInfoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "score-info-modal", [], null, null, null, View_ScoreInfoModalComponent_0, RenderType_ScoreInfoModalComponent)), i1.ɵdid(1, 4308992, null, 0, i8.ScoreInfoModalComponent, [i9.BsModalRef, i2.TranslateService, i10.Opportunity2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ScoreInfoModalComponent_Host_0 = View_ScoreInfoModalComponent_Host_0;
var ScoreInfoModalComponentNgFactory = i1.ɵccf("score-info-modal", i8.ScoreInfoModalComponent, View_ScoreInfoModalComponent_Host_0, { entityId: "entityId" }, {}, []);
exports.ScoreInfoModalComponentNgFactory = ScoreInfoModalComponentNgFactory;
