/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LightTrimDto } from '../models/light-trim-dto';
import { TrimDto } from '../models/trim-dto';
@Injectable({
  providedIn: 'root',
})
class TrimClientService extends __BaseService {
  static readonly GetTrimsByModelIdByModelidGETPath = '/api/Trim/GetTrimsByModelId/{modelId}';
  static readonly GetTrimExtIdByTrimidGETPath = '/api/Trim/GetTrimExtId/{trimId}';
  static readonly GetFuelEconomyComparisonByNewtrimidByEntityidGETPath = '/api/Trim/GetFuelEconomyComparison/{newTrimId}/{entityId}';
  static readonly GetFeatureComparisonByNewtrimidByEntityidGETPath = '/api/Trim/GetFeatureComparison/{newTrimId}/{entityId}';
  static readonly GetFeatureComparisonGETPath = '/api/Trim/GetFeatureComparison';
  static readonly GetTrimsByModelsPOSTPath = '/api/Trim/GetTrimsByModels';
  static readonly ByIdGETPath = '/api/Trim/{id}';
  static readonly ByIdDELETEPath = '/api/Trim/{id}';
  static readonly GetManyGETPath = '/api/Trim/GetMany';
  static readonly PUTPath = '/api/Trim';
  static readonly POSTPath = '/api/Trim';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param modelId undefined
   * @return Success
   */
  GetTrimsByModelIdByModelidGETResponse(modelId: number): __Observable<__StrictHttpResponse<Array<LightTrimDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/GetTrimsByModelId/${modelId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LightTrimDto>>;
      })
    );
  }
  /**
   * @param modelId undefined
   * @return Success
   */
  GetTrimsByModelIdByModelidGET(modelId: number): __Observable<Array<LightTrimDto>> {
    return this.GetTrimsByModelIdByModelidGETResponse(modelId).pipe(
      __map(_r => _r.body as Array<LightTrimDto>)
    );
  }

  /**
   * @param trimId undefined
   * @return Success
   */
  GetTrimExtIdByTrimidGETResponse(trimId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/GetTrimExtId/${trimId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param trimId undefined
   * @return Success
   */
  GetTrimExtIdByTrimidGET(trimId: number): __Observable<string> {
    return this.GetTrimExtIdByTrimidGETResponse(trimId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
   *
   * - `newTrimId`:
   *
   * - `entityId`:
   */
  GetFuelEconomyComparisonByNewtrimidByEntityidGETResponse(params: TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/GetFuelEconomyComparison/${params.newTrimId}/${params.entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
   *
   * - `newTrimId`:
   *
   * - `entityId`:
   */
  GetFuelEconomyComparisonByNewtrimidByEntityidGET(params: TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETParams): __Observable<null> {
    return this.GetFuelEconomyComparisonByNewtrimidByEntityidGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
   *
   * - `newTrimId`:
   *
   * - `entityId`:
   */
  GetFeatureComparisonByNewtrimidByEntityidGETResponse(params: TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/GetFeatureComparison/${params.newTrimId}/${params.entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
   *
   * - `newTrimId`:
   *
   * - `entityId`:
   */
  GetFeatureComparisonByNewtrimidByEntityidGET(params: TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETParams): __Observable<null> {
    return this.GetFeatureComparisonByNewtrimidByEntityidGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TrimClientService.GetFeatureComparisonGETParams` containing the following parameters:
   *
   * - `trimId`:
   *
   * - `dealerId`:
   */
  GetFeatureComparisonGETResponse(params: TrimClientService.GetFeatureComparisonGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.trimId || []).forEach(val => {if (val != null) __params = __params.append('trimId', val.toString())});
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/GetFeatureComparison`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TrimClientService.GetFeatureComparisonGETParams` containing the following parameters:
   *
   * - `trimId`:
   *
   * - `dealerId`:
   */
  GetFeatureComparisonGET(params: TrimClientService.GetFeatureComparisonGETParams): __Observable<null> {
    return this.GetFeatureComparisonGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param modelIds undefined
   */
  GetTrimsByModelsPOSTResponse(modelIds?: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = modelIds;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Trim/GetTrimsByModels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param modelIds undefined
   */
  GetTrimsByModelsPOST(modelIds?: Array<number>): __Observable<null> {
    return this.GetTrimsByModelsPOSTResponse(modelIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<TrimDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrimDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<TrimDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as TrimDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Trim/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<TrimDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Trim/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TrimDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<TrimDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<TrimDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: TrimDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Trim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: TrimDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: TrimDto): __Observable<__StrictHttpResponse<TrimDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Trim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrimDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: TrimDto): __Observable<TrimDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as TrimDto)
    );
  }
}

module TrimClientService {

  /**
   * Parameters for GetFuelEconomyComparisonByNewtrimidByEntityidGET
   */
  export interface GetFuelEconomyComparisonByNewtrimidByEntityidGETParams {
    newTrimId: number;
    entityId: string;
  }

  /**
   * Parameters for GetFeatureComparisonByNewtrimidByEntityidGET
   */
  export interface GetFeatureComparisonByNewtrimidByEntityidGETParams {
    newTrimId: number;
    entityId: string;
  }

  /**
   * Parameters for GetFeatureComparisonGET
   */
  export interface GetFeatureComparisonGETParams {
    trimId?: Array<number>;
    dealerId?: number;
  }
}

export { TrimClientService }
