import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'adaptivePosition' })
export class AdaptivePositionPipe implements PipeTransform {

    constructor(private dom: DomSanitizer) {

    }

    transform(input: string, boldText: any): string {
        return this.highlightQuery(input, boldText);
    }

    highlightQuery(str, query): any {
        query = query.join(' ');
        if (str.toLocaleLowerCase().indexOf(query) > -1) {
            let left_str = str.substring(0, str.toLocaleLowerCase().indexOf(query));
            let right_str = str.substring(str.substring(0, str.toLocaleLowerCase().indexOf(query)).length + query.length, str.lenght);
            return left_str + '<strong>' + query + '</strong>' + right_str;
        }
        else
            return str;
    }

}
