"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var SaleTypeComponent = /** @class */ (function () {
    function SaleTypeComponent(elementRef, injector) {
        this.saleTypes = constants_1.SaleTypes;
        this.conquestTypes = constants_1.ConquestTypes;
        this.saleTypeTranslateKeys = Object.keys(constants_1.SaleTypes);
    }
    SaleTypeComponent.prototype.ngOnChanges = function (changes) {
        if (changes.saleTypeOriginalLookupId) {
            this.saleTypeOriginalLookupKey = 'saleTypeOriginalLookup' + this.saleTypeOriginalLookupId;
        }
    };
    return SaleTypeComponent;
}());
exports.SaleTypeComponent = SaleTypeComponent;
