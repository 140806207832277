
/* injects from baggage-loader */
require('./deal-sheet-tab-select.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('dealSheetTabSelect', {
    templateUrl: '/app/components/deal-sheet/deal-sheet-tab-select.component.html',
    controller: DealSheetTabSelectController,
    bindings: {
        'default': '<',
        permissions: '<',
        showActivityIndicator: '<',
        showVehicleIndicator: '<',
        userRole: '<',
        current: '<',
        onSelect: '&'
    }
});

/* @ngInject */
function DealSheetTabSelectController(dealSheetTabs, userProfileRoles, authorizationService) {
    var $ctrl = this;

    $ctrl.isEuroLite = false;
    $ctrl.tabs;
    $ctrl.userProfileRoles;
    $ctrl.changeTab = changeTab;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.tabs = dealSheetTabs;
        $ctrl.userProfileRoles = userProfileRoles;
        $ctrl.isEuroLite = authorizationService.isEuroLite();
    }

    function onChanges(changes) {
        if (changes.default && changes.default.currentValue && !$ctrl.current) {
            $ctrl.current = $ctrl.default;
        }
    }

    function changeTab(tab) {
        $ctrl.current = tab;
        $ctrl.onSelect({ tab: tab });
    }
}
