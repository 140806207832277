"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./nlo-info.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("ngx-bootstrap/popover"));
var i4 = __importStar(require("ngx-bootstrap/component-loader"));
var i5 = __importStar(require("ngx-bootstrap/positioning"));
var i6 = __importStar(require("../../icon/icon.component.ngfactory"));
var i7 = __importStar(require("../../icon/icon.component"));
var i8 = __importStar(require("@angular/platform-browser"));
var i9 = __importStar(require("./nlo-info.component"));
var i10 = __importStar(require("../../../services/ngx-modal-wrapper.service"));
var styles_NloInfoComponent = [i0.styles];
var RenderType_NloInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NloInfoComponent, data: {} });
exports.RenderType_NloInfoComponent = RenderType_NloInfoComponent;
function View_NloInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["translate", "nloInfoTooltip"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "nloInfoTooltip"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NloInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "a", [["container", "body"], ["href", "javascript:void(0)"], ["placement", "bottom"], ["triggers", "mouseenter:mouseleave"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nloInfoModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i3.PopoverDirective, [i3.PopoverConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i5.PositioningService], { popover: [0, "popover"], placement: [1, "placement"], triggers: [2, "triggers"], container: [3, "container"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "icon", [["icon", "'info-warn'"]], null, null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i1.ɵdid(3, 638976, null, 0, i7.IconComponent, [i8.DomSanitizer], { icon: [0, "icon"] }, null), (_l()(), i1.ɵand(0, [["popNloInfoTemplate", 2]], null, 0, null, View_NloInfoComponent_1))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4); var currVal_1 = "bottom"; var currVal_2 = "mouseenter:mouseleave"; var currVal_3 = "body"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "'info-warn'"; _ck(_v, 3, 0, currVal_4); }, null); }
exports.View_NloInfoComponent_0 = View_NloInfoComponent_0;
function View_NloInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nlo-info", [], null, null, null, View_NloInfoComponent_0, RenderType_NloInfoComponent)), i1.ɵdid(1, 114688, null, 0, i9.NloInfoComponent, [i10.NgxModalWrapperService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NloInfoComponent_Host_0 = View_NloInfoComponent_Host_0;
var NloInfoComponentNgFactory = i1.ɵccf("nlo-info", i9.NloInfoComponent, View_NloInfoComponent_Host_0, { entityId: "entityId", nloData: "nloData" }, { onMarkNlo: "onMarkNlo" }, []);
exports.NloInfoComponentNgFactory = NloInfoComponentNgFactory;
