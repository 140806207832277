"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./mobile-customer-email.component.scss.shim.ngstyle"));
var i1 = __importStar(require("../customer-info/customer-info.component.scss.shim.ngstyle"));
var i2 = __importStar(require("@angular/core"));
var i3 = __importStar(require("@angular/common"));
var i4 = __importStar(require("@ngx-translate/core"));
var i5 = __importStar(require("./mobile-customer-email.component"));
var styles_MobileCustomerEmailComponent = [i0.styles, i1.styles];
var RenderType_MobileCustomerEmailComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_MobileCustomerEmailComponent, data: {} });
exports.RenderType_MobileCustomerEmailComponent = RenderType_MobileCustomerEmailComponent;
function View_MobileCustomerEmailComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.email == null) ? null : _co.email.value); _ck(_v, 1, 0, currVal_0); }); }
function View_MobileCustomerEmailComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "span", [["class", "strike-out"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.email == null) ? null : _co.email.value); _ck(_v, 1, 0, currVal_0); }); }
function View_MobileCustomerEmailComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "span", [["class", "strike-out"], ["style", "color:red"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.email == null) ? null : _co.email.value); _ck(_v, 1, 0, currVal_0); }); }
function View_MobileCustomerEmailComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MobileCustomerEmailComponent_2)), i2.ɵdid(2, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MobileCustomerEmailComponent_3)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MobileCustomerEmailComponent_4)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!((_co.email == null) ? null : _co.email.isBad) && !_co.doNotEmail); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.email == null) ? null : _co.email.isBad) && !_co.doNotEmail); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.doNotEmail; _ck(_v, 6, 0, currVal_2); }, null); }
function View_MobileCustomerEmailComponent_0(_l) { return i2.ɵvid(2, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [["class", "row contact-info"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-2"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "span", [["class", "icon-class icon -size-24 email-icon"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 5, "div", [["class", "col-xs-10"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_MobileCustomerEmailComponent_1)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(6, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), i2.ɵdid(8, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i2.ElementRef, i2.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.email == null) ? null : _co.email.value); _ck(_v, 5, 0, currVal_0); var currVal_1 = i2.ɵinlineInterpolate(1, "", _co.type, ""); _ck(_v, 8, 0, currVal_1); }, null); }
exports.View_MobileCustomerEmailComponent_0 = View_MobileCustomerEmailComponent_0;
function View_MobileCustomerEmailComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "mobile-customer-email", [], null, null, null, View_MobileCustomerEmailComponent_0, RenderType_MobileCustomerEmailComponent)), i2.ɵdid(1, 114688, null, 0, i5.MobileCustomerEmailComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileCustomerEmailComponent_Host_0 = View_MobileCustomerEmailComponent_Host_0;
var MobileCustomerEmailComponentNgFactory = i2.ɵccf("mobile-customer-email", i5.MobileCustomerEmailComponent, View_MobileCustomerEmailComponent_Host_0, { doNotEmail: "doNotEmail", email: "email", type: "type" }, {}, []);
exports.MobileCustomerEmailComponentNgFactory = MobileCustomerEmailComponentNgFactory;
