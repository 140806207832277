
import Pubnub from 'pubnub';
import { Subject, Observable } from 'rxjs';
import { Settings } from '../constants';
import { LogFactory, NamedLog } from './log-factory.service';
import { MessagingClientService } from '../generated/services';
import { PubNubConfigDto } from '../generated/models/pub-nub-config-dto';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

export const EventTypes = {
    engagement: 0,
    dmsPushResponse: 1
};

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private enabled = true;
    private log: NamedLog;
    private pubnub;
    private readonly messageReceivedSubject = new Subject<any>();
    private readonly statusReceivedSubject = new Subject<any>();
    public message$: Observable<any>;
    public statusReceived: Observable<any>;

    constructor(logFactory: LogFactory, private messagingClient: MessagingClientService) {        
        // default to enabled if the variable is not set
        this.enabled = (Settings.environmentVariables.notificationServiceEnabled == null ||
                    Settings.environmentVariables.notificationServiceEnabled)

        this.log = logFactory.get('notificationService');

        this.message$ = this.messageReceivedSubject.asObservable();
        this.statusReceived = this.statusReceivedSubject.asObservable();
    }   

    public get newPushResponse(): Observable<any> {
        return this.message$
            .pipe(
                filter(evt => evt.eventType == EventTypes.dmsPushResponse)
            )
    }

    public async connect(): Promise<any> {
        
        if (!this.enabled) {
            // Not configured, do not connect
            return Promise.resolve();
        }

        const connectWithConfig = (config: PubNubConfigDto) => {
            const pubnub = new Pubnub({
                subscribeKey: config.subscriptionKey,
                authKey: config.authorizationKey,
                ssl: true,
                uuid: undefined //config.uuid,
            });

            pubnub.addListener({
                message: (msg) => {
                    this.log.debug('received message:', msg);
                    try {
                        const parsedEvent = JSON.parse(msg.message);
                        this.messageReceivedSubject.next(parsedEvent);
                    } catch (err) {
                        this.log.error('could not parse message property:', msg, err);
                    }
                },
                status: (status) => {
                    this.log.debug('received status:', status);
                    this.statusReceivedSubject.next(status);
                },
            });

            this.log.debug('connecting');
            pubnub.subscribe({
                channels: config.channels || undefined,
                channelGroups: config.channelGroups || undefined,
            });

            return pubnub;
        }

        try {
            const pubNubConfig = await this.messagingClient.GetPubNubConfigGET().toPromise();
            if (!pubNubConfig) {
                this.log.debug('PubNub disabled')
                return;
            }
            const p = await connectWithConfig(pubNubConfig);
            this.pubnub = p;
        } catch (err) {
            this.log.error('connection error:', err);
            return;
        }
    }
}
