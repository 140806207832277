import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
UITextBox.$inject = ["$scope", "$state"];

/* injects from baggage-loader */
require('./ui-textbox.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('uiTextbox', {
  templateUrl: '/app/components/search/ui-textbox.component.html',
  controller: UITextBox,
  bindings: {
    displayNameKey: '<',
    displayNameCssClass: '<',
    value: '=',
    triggerInit: "=",
    availableValues: "<",
    maxLength: "<",
    isValid: "="
  }
});
/* @ngInject */

function UITextBox($scope, $state) {
  var $ctrl = this;
  $ctrl.$onInit = onInit;
  $ctrl.validate = validate;
  $scope.$watch('$ctrl.triggerInit', function (newVal, oldVal, scope) {
    if ($ctrl.triggerInit) {
      $ctrl.triggerInit = false;
      onInit();
    }
  });

  function onInit() {
    if (!$ctrl.displayNameCssClass) $ctrl.displayNameCssClass = "searchDisplayNameWide";
    $ctrl.value = "";
  }

  function validate() {
    if ($ctrl.value && $ctrl.value.value) $ctrl.value = $ctrl.value.value; // get rid of the object created by typeahead
    // disallow illegal characters

    var invalidCharacters = ['<'];
    var isValid = true;

    for (var _i = 0; _i < invalidCharacters.length; _i++) {
      var c = invalidCharacters[_i];
      if ($ctrl.value && $ctrl.value.includes(c)) isValid = false;
    }

    $ctrl.isValid = isValid;
  }
}