import { Component, Input, OnChanges, Inject,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PandoCommunicationService } from '../../../services/pando-communication.service';
import { ToasterLoggerService } from '../../../core/toaster-logger.service';




@Component({
    selector: 'pandoAvailability',
    templateUrl: './pando-availability.component-ng.html',
    styleUrls: ['./pando-availability.component.scss']
})

export class PandoAvailabilityComponent implements OnChanges,OnInit {
    // define input bindings
    @Input() isAutoAlertUser?: boolean;
    @Input() isAvailable?: boolean;
    @Input() availability? = null;

    public busyReasons;
    public unavailableReasons;
    public chatStaff = { Availability: null}
    public availabilityReasons = {}

    public error = false;
    public busyNote = '';
    public busySelectedOption? = null;
    public busyForMinutes = 15;
    public unavailableNote = '';
    public unavailableSelectedOption? = null;
    public isDropdownVisible = false;

    public currentStateImage = "../../../images/timeclock-available.svg";
    public userAvailability = { Available: 10, Busy: 20, NotAvailable: 100 };
    public modelUserSelection:any = this.userAvailability.NotAvailable;

    public errorMsgKey = '';


    constructor(public communicationService: PandoCommunicationService, public translateService: TranslateService, public toaster: ToasterLoggerService) {




    }

    ngOnInit() {
        this.getAvailabilityReasons();
        this.getChatStaff();

        //// block click event so drop down can stay open when clicked inside the panel not inside a button
        $('#availabilityPanelNgx').on("click", function (e) {
            if (e && e.target) { 
                    e.stopPropagation();
            }
        });
    }

    ngOnChanges() {
        if (this.chatStaff && this.chatStaff.Availability != null)
            this.chatStaff.Availability = this.availability; // binding from pando-inbox rtc msg
    }

    public trackByBusyReasonsById(index, data) {
        if (!data) return null;
        return data.Id;
    }

    public trackByUnavailableReasonsById(index, data) {
        if (!data) return null;
        return data.Id;
    }



    private getAvailabilityReasons() {
        return this.communicationService.getAvailabilityReasons(this.isAutoAlertUser)
            .then( reasons => {
                if (reasons) {

                    this.availabilityReasons = reasons;
                    this.busyReasons = reasons.BusyReasons;
                    this.unavailableReasons = reasons.UnavailableReasons;
                }
            })
            .catch((e) => { this.handleError(e); });
    }

    private getChatStaff() {
        
        if (this.isAutoAlertUser) {
            return this.communicationService.getChatStaff(this.isAutoAlertUser)
                .then(chatStaff => {
                    if (chatStaff && chatStaff.Availability) {
                        this.chatStaff = chatStaff;
                        this.modelUserSelection = this.chatStaff.Availability.toString();
                    }
                })
                .catch((e) => { this.handleError(e); });
        }
        else {
            // if PandoUser
            return;
        }
    }

    private setAvailable() {

        if (!this.chatStaff) {
            return;
        }

        
        return this.communicationService.setAvailable(this.isAutoAlertUser)
            .then(chatStaff => {
                if (chatStaff && chatStaff.Availability) {
                    this.isDropdownVisible = false;
                    this.chatStaff = chatStaff;
                    this.modelUserSelection = this.chatStaff.Availability.toString();
                    this.resetUI();
                }
            })
            .catch((e) => { this.handleError(e); });
         
    }

    private setUnAvailable() {

        if (!this.chatStaff) {
            return;
        }

        if (!this.unavailableSelectedOption) {
            this.showWarningMessage("invalidSelection");
            return;
        }
        
        return this.communicationService.setUnavailable(this.isAutoAlertUser, this.unavailableNote, this.unavailableSelectedOption.Id)
            .then(chatStaff => {
                if (chatStaff) {
                    this.isDropdownVisible = false;
                    this.chatStaff = chatStaff;
                    this.resetUI();
                    this.showSuccessMessage("Your status has been changed to not available.");
                }
            })
            .catch((e) => { this.handleError(e); });
        
    }

    private setBusy() {

        if (!this.chatStaff) {
            return;
        }

        if (typeof (this.busyForMinutes) === "undefined") {
            this.showWarningMessage("invalidBusyMin");
            return;
        }

        if (!this.busySelectedOption) {
            this.showWarningMessage("invalidSelection");
            return;
        }

        
        return this.communicationService.setBusy(this.isAutoAlertUser, this.busyForMinutes, this.busyNote, this.busySelectedOption.Id)
            .then(chatStaff => {
                if (chatStaff) {
                    this.isDropdownVisible = false;
                    this.chatStaff = chatStaff;
                    this.resetUI();
                    this.showSuccessMessage("Your status has been changed as busy for the next " + this.busyForMinutes + " minuites.");
                }
            })
            .catch((e) => { this.handleError(e); });
        
    }

    public onUserAvailabilityClick() {
        if (this.chatStaff) {
            if (this.chatStaff.Availability == this.userAvailability.NotAvailable) {
                this.isDropdownVisible = false;
                this.setAvailable();
            }
            else
                this.isDropdownVisible = true;

        }

    }

    public toggleAvailabilitySwitch() {
        this.error = null;
        this.busyNote = '';
        this.busySelectedOption = null;
        this.busyForMinutes = 15;
        this.unavailableNote = '';
        this.unavailableSelectedOption = null;
    }

    public validateBusyMin() {
        if (typeof (this.busyForMinutes) === "undefined")
            this.showWarningMessage("invalidBusyMin");
        else
            this.error = false;
    }

    public resetUI() {
        this.error = false;
        if (this.chatStaff && this.chatStaff.Availability != null)
            this.modelUserSelection = this.chatStaff.Availability.toString();
        this.toggleAvailabilitySwitch();
        this.isDropdownVisible = false;
        $(".dropdown").removeClass("open");

    }


    public submitAvailabilityStatus() {
        // modelUserSelection assignement in html: like *ngIf="modelUserSelection == userAvailability.NotAvailable"
        // may convert it to string so need to prase it.

        var availability = parseInt(this.modelUserSelection);

        switch (availability) {
            case this.userAvailability.Available:
                this.setAvailable();
                break;
            case this.userAvailability.Busy:
                this.setBusy();
                break;
            case this.userAvailability.NotAvailable:
                this.setUnAvailable();
                break;
        }
        
    }


    private handleError(e) {
        this.toaster.error("generalErrorMessageKey",e,"");
    }

    private showWarningMessage(msg) {
        this.errorMsgKey = msg;
        this.error = true;
    }

    private showSuccessMessage(msg) {
        this.errorMsgKey = msg;
        this.error = false;
    }



}
