<a href="javascript:void(0)" (click)="scoreInfoModal()"
   [popover]="popScoreInfoTemplate" 
   placement="bottom" 
   triggers="mouseenter:mouseleave"
   container="body">
    <icon icon="'info-i'"></icon>
</a>
<ng-template #popScoreInfoTemplate>
    <span translate="scoreInfoTooltip"></span>
</ng-template>
