
/* injects from baggage-loader */
require('./view-customer-conversation-modal.component.html');

import angular from 'angular';
(function () {
    angular.module('AaApp.Opp').component('viewCustomerConversationModal', {
        templateUrl: '/app/components/deal-sheet/modals/view-customer-conversation-modal.component.html',
        controller: ViewCustomerConversationModalController,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    });

/* @ngInject */
    function ViewCustomerConversationModalController($sce) {
        var $ctrl = this;
        $ctrl.conversation = $sce.trustAsHtml($ctrl.resolve.conversation);
    }
})();