PaginationController.$inject = ["$scope"];

/* injects from baggage-loader */
require('./pagination.component.html');

import angular from 'angular';
import { OpportunitiesModule } from '../core/opportunities.module';
import $ from 'jquery';
OpportunitiesModule.component('pagination', {
  templateUrl: '/app/components/pagination.component.html',
  controller: PaginationController,
  bindings: {
    pagedItems: '<',
    pager: '<',
    filteredItems: '<',
    maxPageSize: '<',
    maxMinArrows: '<',
    maxMinPage: '<',
    onPageChanged: '&',
    onPagedItems: '&',
    onPagerChange: '&',
    setPage: '<',
    resetPage: '<',
    onResetComplete: '&'
  }
});
PaginationController.$inject = ['$scope'];

function PaginationController($scope) {
  "ngInject";

  $scope.title = 'Test Pagination';
  var $ctrl = this;
  $ctrl.setPage = setPage;
  $ctrl.maxPageSize = 100;
  $ctrl.getDefaultPager = getDefaultPager;
  $ctrl.pageDownDisabled = false;
  $ctrl.pageUpDisabled = false;
  $ctrl.maxMinPage = false;
  $ctrl.maxMinArrows = false;
  $ctrl.$onChanges = onChanges;
  $ctrl.currentPage = -1;
  $ctrl.totalPages = 0;
  $ctrl.totalItems = 0;
  $ctrl.maxSize = 0;
  $scope.maxSize = $ctrl.maxSize;

  function GetPager(totalItems, newPage, $scope) {
    // default to first page
    newPage = newPage || 1; // default page size is MaxPerPage

    var pageSize = $ctrl.maxPageSize; // calculate total pages

    var totalPages = Math.ceil(totalItems / pageSize);
    var startPage, endPage; // calculate start and end item indexes

    var startIndex = (newPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    var arrayLimit = totalPages; // (endPage - startPage) + 1 > 5 ? 5 : (endPage - startPage) + 1;
    // create an array of pages to ng-repeat in the pager control

    var pages = Array.apply(null, Array(arrayLimit)).map(function (_, i) {
      return i;
    }); // return object with all pager properties required by the view

    return {
      totalItems: totalItems,
      currentPage: newPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
      isReset: false
    };
  }

  function getDefaultPager() {
    return {
      totalItems: 0,
      currentPage: -1,
      pageSize: 0,
      totalPages: 0,
      startPage: -1,
      endPage: -1,
      startIndex: -1,
      endIndex: -1,
      pages: []
    };
  }

  function setPage(newPage) {
    newPage = newPage ? newPage : $scope.currentPage ? $scope.currentPage : 1;
    $ctrl.totalPages = Math.ceil($ctrl.filteredItems.length / $ctrl.maxPageSize);
    $scope.totalPages = $ctrl.totalPages;
    $scope.totalItems = $ctrl.filteredItems.length;
    $ctrl.pager = GetPager($ctrl.filteredItems ? $ctrl.filteredItems.length : 0, newPage);
    onPagerChange();
    $ctrl.currentPage = $scope.currentPage = newPage; // get current page of items

    $ctrl.pagedItems = $ctrl.filteredItems.slice($ctrl.pager.startIndex, $ctrl.pager.endIndex + 1);
    onPagedItems($ctrl.pagedItems, $ctrl.filteredItems.length);
  }

  function onPagerChange() {
    $ctrl.onPagerChange({
      $value: $ctrl.pager
    });
  }

  function onChanges(changes) {
    if (changes.filteredItems && changes.filteredItems.currentValue) {
      setPage(1);
    }

    if (changes.resetPage && changes.resetPage.currentValue == true) {
      setPage(1);
      $ctrl.resetPage = false;
      $ctrl.onResetComplete();
    } // these are for Internet Explorer to ensure the anchors do not cause the page to refresh


    $(".pagination-prev a").attr("href", "#");
    $(".pagination-next a").attr("href", "#");
  }

  function onPagedItems(pagedItems, totalItems) {
    $ctrl.onPagedItems({
      $value: pagedItems
    });
    $scope.totalItems = $ctrl.filteredItems.length;
  }
}