
/* injects from baggage-loader */
require('./post-notes.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('postNotes', {
    templateUrl: '/app/components/deal-sheet/modals/post-notes.component.html',
    controller: PostNotesController,
    bindings: {
        opportunityId: '<',
        notesType: '<',
        resolve: '<',
        close: '&',
        dismiss: '&',

    }
});

/* @ngInject */
function PostNotesController($filter, $translate, $translatePartialLoader,
    logEmailSentTitleKey,
    postNotesTitleKey,
    logCallTitleKey,
    cancelTitleCallKey,
    cancelTitleApptKey,
    completeTitleCallKey,
    completeTitleApptKey,
    activityService, ActivityActionEnum, activitySubTypes, activityResultDescriptors, activityResults) {
    var $ctrl = this;

    $ctrl.$onChanges = onChanges;
    $ctrl.submitClick = submitClick;
    $ctrl.cancelClick = cancelClick;
    $ctrl.postActivity = postActivity;
    $ctrl.isLogEmail = false;
    $ctrl.activity = null;
    $ctrl.onTypeOfCallSelect = onTypeOfCallSelect;

    // ActionResultSetID  mapped to setID of json AutoAlert/API/activity-results.json
    var callActionResultSetID = 1;
    var apptActionResultSetID = 2;
    var noteActionResultSetID = 3;
    var emailActionResultSetID = -1;

    // models for display
    var noteObject = {
        call: { activityId: ActivityActionEnum.Log_Call, description: logCallTitleKey, resultType: callActionResultSetID, resultLabelKey: "logCallResult", isCallBoundTypeVisible: true },
        note: { activityId: ActivityActionEnum.Post_Note, description: postNotesTitleKey, resultType: noteActionResultSetID, resultLabelKey: "postNotesResult", isCallBoundTypeVisible: false },
        email: { activityId: ActivityActionEnum.Email_Sent, description: logEmailSentTitleKey, resultType: emailActionResultSetID, resultLabelKey: "result", isCallBoundTypeVisible: false },
        cancelCallActivity: { activityId: ActivityActionEnum.Cancel_Call, description: cancelTitleCallKey, resultType: emailActionResultSetID, resultLabelKey: "result", isCallBoundTypeVisible: false },
        cancelApptActivity: { activityId: ActivityActionEnum.Cancel_Appt, description: cancelTitleApptKey, resultType: emailActionResultSetID, resultLabelKey: "result", isCallBoundTypeVisible: false },
        completeCallActivity: { activityId: ActivityActionEnum.Phone_Call_Completed, description: completeTitleCallKey, resultType: callActionResultSetID, resultLabelKey: "logCallResult", isCallBoundTypeVisible: false },
        completeApptActivity: { activityId: ActivityActionEnum.Appointment_Completed, description: completeTitleApptKey, resultType: apptActionResultSetID, resultLabelKey: "resultOfAppointment", isCallBoundTypeVisible: false }
    }

    // model for UI
    $ctrl.activityResultsList = {};
    $ctrl.status = { opened: false };
    $ctrl.model = { notes: '', resultID: -1, callBoundType: null };
    $ctrl.open = function ($event) {
        $ctrl.status.opened = true;
    };

    function onTypeOfCallSelect(data) {
        $ctrl.model.callBoundType = data;
    }

    function onChanges() {
        if ($ctrl.resolve) {

            $ctrl.opportunityId = $ctrl.resolve.items.opportunityId;
            $ctrl.dealerId = $ctrl.resolve.items.dealerId;
            $ctrl.notesType = $ctrl.resolve.items.modalType;
            if ($ctrl.resolve.items.activityObject)
                $ctrl.activity = $ctrl.resolve.items.activityObject;

        }
        switch ($ctrl.notesType) {
            case ActivityActionEnum.Post_Note:
                $ctrl.currentNoteType = noteObject.note;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
            case ActivityActionEnum.Log_Call:
                $ctrl.currentNoteType = noteObject.call;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
            case ActivityActionEnum.Email_Sent:
                $ctrl.currentNoteType = noteObject.email;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                $ctrl.isLogEmail = true;
                break;
            case ActivityActionEnum.Cancel_Call:
                $ctrl.currentNoteType = noteObject.cancelCallActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                $ctrl.isLogEmail = true;
                break;
            case ActivityActionEnum.Cancel_Appt:
                $ctrl.currentNoteType = noteObject.cancelApptActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                $ctrl.isLogEmail = true;
                break;
            case ActivityActionEnum.Phone_Call_Completed:
                $ctrl.currentNoteType = noteObject.completeCallActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
            case ActivityActionEnum.Appointment_Completed:
                $ctrl.currentNoteType = noteObject.completeApptActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
        }
    }

    // set default selection and get drop down list
    function setActivityResults() {
        $ctrl.activityResultsList = $filter('filter')(activityResultDescriptors, { setID: $ctrl.currentNoteType.resultType });
        //$ctrl.model.resultID = $ctrl.activityResultsList[0].activityResultID + "";
        $ctrl.model.resultID = -1 + "";
    }

    function postActivity() {
        if ($ctrl.model.resultID <= -1) {
            switch ($ctrl.notesType) {
                case ActivityActionEnum.Post_Note:
                case ActivityActionEnum.Log_Call:
                case ActivityActionEnum.Phone_Call_Completed:
                case ActivityActionEnum.Appointment_Completed:
                    $ctrl.errorMessage = "postnotesResultErrorMsg";
                    return;
            }
        }

        //usSpinnerService.spin('post-notes-spinner');
        var recordId = null;
        if ($ctrl.activity)
            recordId = $ctrl.activity.activityId;

        // model to save
        var activity = {
            entityID: $ctrl.opportunityId,
            activityActionID: $ctrl.currentNoteType.activityId,
            notes: $ctrl.model.notes,
            resultID: $ctrl.model.resultID,
            activityId: recordId,
            activityActionSubTypeID: $ctrl.model.callBoundType
        }

        if (!activity.notes)
            activity.notes = '';

        if (activity.resultID == -1)
            activity.resultID = null;

        $ctrl.submitting = activityService.postActivitySubmitAction(activity)
            .then(function (data) {
                data.activityId = $ctrl.currentNoteType.activityId;
                data.activityResult = activity.resultID;
                $ctrl.submitting = null;
                $ctrl.model = { notes: '', resultID: -1 }

                // WEBUI-8085 - the following code was added here but at some point it might be preferable to refactor the code to have this be 
                // part of a common service as this was copied from the deal-sheet component (onLogScheduleSubmit). onLogScheduleSubmit seems to work
                // a little differently though so it wasn't a clear choice to just call that.

                // if vehicle no longer owns
                if (activity.resultID == activityResults.phoneCall.noLongerOwns || activity.resultID == activityResults.note.noLongerOwns) {
                    activityService.updateCustomerDoesNotOwnVehicle($ctrl.opportunityId)
                        .then(() => {
                            $ctrl.close({ $value: data });// pass follow-up actions to caller
                        });
                }

                // if vehicle paid off
                else if (activity.resultID == activityResults.phoneCall.vehiclePaidOff) {
                    activityService.updateVehiclePaidOff($ctrl.opportunityId)
                        .then(() => {
                            $ctrl.close({ $value: data });// pass follow-up actions to caller
                        });
                }
                else {
                    $ctrl.close({ $value: data });// pass follow-up actions to caller
                }
            });

    }

    function cancelClick() {
        $ctrl.dismiss({ $value: 'cancel' });
    }

    function submitClick() {
        postActivity();
    }
}
