import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
FeatureToggleService.$inject = ["featureToggles", "dealerService"];
import angular from 'angular';
import opportunities from '../core/opportunities.module';
angular.module(opportunities).factory('featureToggleService', FeatureToggleService);

function FeatureToggleService(featureToggles, dealerService) {
  "ngInject";

  return {
    customerEngagementWidget: customerEngagementWidget,
    useFakeCustomerEngagementData: useFakeCustomerEngagementData
  };

  function customerEngagementWidget() {
    return calculateFeatureToggle(featureToggles.enableCustomerEngagementWidget);
  }

  function useFakeCustomerEngagementData() {
    return calculateFeatureToggle(featureToggles.useFakeCustomerEngagementData);
  }

  function calculateFeatureToggle(feature) {
    // falsy means the feature is off
    if (!feature) return false; // Literal 'true' means the feature is on for all dealers

    if (feature === true) return true; // If it's an array, it is a list of dealer IDs that should have the
    // feature enabled.

    if (Array.isArray(feature)) {
      var selectedDealers = dealerService.getSelectedDealerIds() || [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = selectedDealers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var selectedDealerId = _step.value;

          if (feature.includes(selectedDealerId)) {
            return true;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    return false;
  }
}