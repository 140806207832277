import angular from 'angular';

angular.module('AaApp.Opp').factory('opportunityCountsService', opportunityCountsService);

/* @ngInject */
function opportunityCountsService($q, coreApi) {
    var service = {
        getOpportunityCounts: getOpportunityCounts,

    };

    return service;

    function getOpportunityCounts(selectedDealerId, flushCache) {
        return coreApi.api.OpportunityCounts.GetOpportunityCounts.GET({ flushCache }, { overrideDealerIds: [ selectedDealerId ] })
            .then(response => response.data);
    }
}
