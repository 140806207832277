"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../../../../node_modules/angular2-toaster/src/toaster-container.component.ngfactory"));
var i2 = __importStar(require("angular2-toaster/src/toaster-container.component"));
var i3 = __importStar(require("angular2-toaster/src/toaster.service"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("./ngx-toaster.component"));
var styles_NgxToaster = [];
var RenderType_NgxToaster = i0.ɵcrt({ encapsulation: 2, styles: styles_NgxToaster, data: {} });
exports.RenderType_NgxToaster = RenderType_NgxToaster;
function View_NgxToaster_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.popToast() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Test Toaster"]))], null, null); }
function View_NgxToaster_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "toaster-container", [], null, null, null, i1.View_ToasterContainerComponent_0, i1.RenderType_ToasterContainerComponent)), i0.ɵdid(2, 245760, null, 0, i2.ToasterContainerComponent, [i3.ToasterService, i0.ChangeDetectorRef, i0.NgZone], { toasterconfig: [0, "toasterconfig"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NgxToaster_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configToaster; _ck(_v, 2, 0, currVal_0); var currVal_1 = false; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_NgxToaster_0 = View_NgxToaster_0;
function View_NgxToaster_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-toaster", [], null, null, null, View_NgxToaster_0, RenderType_NgxToaster)), i0.ɵdid(1, 49152, null, 0, i5.NgxToaster, [i3.ToasterService], null, null)], null, null); }
exports.View_NgxToaster_Host_0 = View_NgxToaster_Host_0;
var NgxToasterNgFactory = i0.ɵccf("ngx-toaster", i5.NgxToaster, View_NgxToaster_Host_0, {}, {}, []);
exports.NgxToasterNgFactory = NgxToasterNgFactory;
