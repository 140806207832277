import { Component, NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';


@Component({
  selector: 'ngx-toaster',
    template: `<div>
                <toaster-container [toasterconfig]="configToaster"></toaster-container>
                <!-- test button -->
                <button *ngIf="false" (click)="popToast()">Test Toaster</button>
               </div>
               `,
})

export class NgxToaster {
    private toasterService: ToasterService;

    public configToaster: ToasterConfig = new ToasterConfig({
        positionClass: 'toast-top-right',
        animation: 'fade',
        showCloseButton: true,
        timeout:5000 // 5 seconds is the default for all toaster duration.
    });

    constructor(toasterService: ToasterService) {
        this.toasterService = toasterService;
    }

    // test button event handler
    popToast() {
        var toastSuccess: Toast = { type: 'success', body: 'Success Toast Body', };
        var toastInfo: Toast = { type: 'info', body: 'Info Toast Body', };
        var toastError: Toast = { type: 'error', body: 'Error Toast Body', };
        var toastWarning: Toast = { type: 'warning', body: 'Warning Toast Body', };

        this.toasterService.pop(toastSuccess);
        this.toasterService.pop(toastInfo);
        this.toasterService.pop(toastError);
        this.toasterService.pop(toastWarning);
    }
}