import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RoleOptionDto } from '../../generated/models';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';

@Component({
    selector: 'admin-roles-modal-addedit',
    templateUrl: './admin-roles-modal-addedit.component-ng.html',
    styleUrls: ['./admin-roles-modal-addedit.component.scss']
})

export class AdminRolesModalAddEditComponent implements OnInit, AfterViewInit {
    public selectedRole: RoleOptionDto;
    public selectedParentRole: RoleOptionDto;
    public roleOptions: any;
    public newRole: boolean;
    public parentRoleInvalid: boolean = false;
    public roleNameInvalid: boolean = false;

    @Output() onSaveChanges = new EventEmitter();

    constructor(
        public _bsModalRef: BsModalRef,
        public tService: TranslateService,
        private logger: ToasterLoggerService) {

    }

    public ngOnInit(): void {
        if (this.newRole) {
            this.selectedRole.roleID = 0;
            this.selectedRole.roleName = '';
            this.selectedRole.parentRoleID = null;
            this.selectedRole.startWithParentRole = true;
        }

        this._bsModalRef.hide();
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public onConfirm(): void {
        var alreadyExists = false;
        this.roleNameInvalid = false;
        this.parentRoleInvalid = false;

        this.parentRoleInvalid = (this.selectedRole.parentRoleID == null);

        if (this.selectedRole.roleName == '') {
            this.roleNameInvalid = true;
        }

        var flatRoles = [].concat.apply([], this.roleOptions);
        if (this.newRole && flatRoles.some((r: any) => this.selectedRole.roleName == r.roleName)) {
            this.logger.warning(this.tService.instant("rolesadmin_roleexists"));
            alreadyExists = true;
        }

        if (!this.roleNameInvalid && !this.parentRoleInvalid && !alreadyExists) {
            this.onSaveChanges.emit({ selectedRole: this.selectedRole });
            this._bsModalRef.hide();
        }
    }

    public onCancel(): void {
        this._bsModalRef.hide();
    }
}
