"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var PrintOfferBatchClientService = /** @class */ (function (_super) {
    __extends(PrintOfferBatchClientService, _super);
    function PrintOfferBatchClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param entityIds undefined
     */
    PrintOfferBatchClientService.prototype.OpenBatchPOSTResponse = function (entityIds) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = entityIds;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/PrintOfferBatch/OpenBatch", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityIds undefined
     */
    PrintOfferBatchClientService.prototype.OpenBatchPOST = function (entityIds) {
        return this.OpenBatchPOSTResponse(entityIds).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    PrintOfferBatchClientService.prototype.GetDealerStatusesGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/PrintOfferBatch/GetDealerStatuses", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    PrintOfferBatchClientService.prototype.GetDealerStatusesGET = function () {
        return this.GetDealerStatusesGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    PrintOfferBatchClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/PrintOfferBatch/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    PrintOfferBatchClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    PrintOfferBatchClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/PrintOfferBatch/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    PrintOfferBatchClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    PrintOfferBatchClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/PrintOfferBatch/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    PrintOfferBatchClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    PrintOfferBatchClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/PrintOfferBatch", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    PrintOfferBatchClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    PrintOfferBatchClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/PrintOfferBatch", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    PrintOfferBatchClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    PrintOfferBatchClientService.OpenBatchPOSTPath = '/api/PrintOfferBatch/OpenBatch';
    PrintOfferBatchClientService.GetDealerStatusesGETPath = '/api/PrintOfferBatch/GetDealerStatuses';
    PrintOfferBatchClientService.ByIdGETPath = '/api/PrintOfferBatch/{id}';
    PrintOfferBatchClientService.ByIdDELETEPath = '/api/PrintOfferBatch/{id}';
    PrintOfferBatchClientService.GetManyGETPath = '/api/PrintOfferBatch/GetMany';
    PrintOfferBatchClientService.PUTPath = '/api/PrintOfferBatch';
    PrintOfferBatchClientService.POSTPath = '/api/PrintOfferBatch';
    PrintOfferBatchClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrintOfferBatchClientService_Factory() { return new PrintOfferBatchClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: PrintOfferBatchClientService, providedIn: "root" });
    return PrintOfferBatchClientService;
}(base_service_1.BaseService));
exports.PrintOfferBatchClientService = PrintOfferBatchClientService;
