"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./deskit.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("@angular/common"));
var i4 = __importStar(require("./deskit.component"));
var i5 = __importStar(require("../../../ajs-upgraded-providers"));
var i6 = __importStar(require("../../../core/toaster-logger.service"));
var styles_DeskitComponent = [i0.styles];
var RenderType_DeskitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeskitComponent, data: {} });
exports.RenderType_DeskitComponent = RenderType_DeskitComponent;
function View_DeskitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "btn qa-deskit-btn"], ["href", "#"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pushToDeskit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "svg-icon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["translate", "deskitDeal"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_2 = "deskitDeal"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("deskitDealTitle")), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.deskitImagePath; _ck(_v, 2, 0, currVal_1); }); }
function View_DeskitComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "comm-tools"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeskitComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDeskitLink; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_DeskitComponent_0 = View_DeskitComponent_0;
function View_DeskitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "deskit", [], null, null, null, View_DeskitComponent_0, RenderType_DeskitComponent)), i1.ɵdid(1, 770048, null, 0, i4.DeskitComponent, [i5.DealSheetService, i6.ToasterLoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DeskitComponent_Host_0 = View_DeskitComponent_Host_0;
var DeskitComponentNgFactory = i1.ɵccf("deskit", i4.DeskitComponent, View_DeskitComponent_Host_0, { dealerId: "dealerId", dealerSettings: "dealerSettings", entityId: "entityId", customer: "customer", currentVehicle: "currentVehicle", replacementVehicle: "replacementVehicle", saleType: "saleType" }, {}, []);
exports.DeskitComponentNgFactory = DeskitComponentNgFactory;
