"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AdminSettingTabs;
(function (AdminSettingTabs) {
    AdminSettingTabs[AdminSettingTabs["General"] = 1] = "General";
    AdminSettingTabs[AdminSettingTabs["Programs"] = 2] = "Programs";
    AdminSettingTabs[AdminSettingTabs["Opportunity"] = 3] = "Opportunity";
    AdminSettingTabs[AdminSettingTabs["Inventory"] = 4] = "Inventory";
    AdminSettingTabs[AdminSettingTabs["DealerInfo"] = 5] = "DealerInfo";
    AdminSettingTabs[AdminSettingTabs["Advanced"] = 6] = "Advanced";
    AdminSettingTabs[AdminSettingTabs["CreditConvert"] = 7] = "CreditConvert";
    AdminSettingTabs[AdminSettingTabs["Product"] = 8] = "Product";
    AdminSettingTabs[AdminSettingTabs["CRMLeadsDelivery"] = 9] = "CRMLeadsDelivery";
})(AdminSettingTabs = exports.AdminSettingTabs || (exports.AdminSettingTabs = {}));
