LiveSupportController.$inject = ["$window", "$timeout"];

/* injects from baggage-loader */
require('./live-support.component.html');

import { OpportunitiesModule } from "../../core/opportunities.module";
OpportunitiesModule.component("liveSupport", {
  templateUrl: "/app/components/header/live-support.component.html",
  bindings: {
    isAuthenticated: "<",
    domain: "<"
  },
  controller: LiveSupportController
});
/* @ngInject */

function LiveSupportController($window, $timeout) {
  var $ctrl = this; //$ctrl.linkId = "static_id";

  $ctrl.$onInit = function () {
    //$timeout(prepareLiveSupport, 2000);
    var initESW = function initESW(gslbBaseURL) {
      embedded_svc.settings.displayHelpButton = true; //Or false

      embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

      embedded_svc.settings.defaultMinimizedText = 'Live Support'; //(Defaults to Chat with an Expert)

      embedded_svc.settings.disabledMinimizedText = 'Submit A Case'; //(Defaults to Agent Offline)
      //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
      //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
      // Settings for Chat
      //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      //};
      //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
      //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId

      embedded_svc.settings.offlineSupportMinimizedText = 'Submit A Case'; //(Defaults to Contact Us)

      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      var chat = document.getElementById("aa-header-live-support");
      embedded_svc.settings.targetElement = chat;
      embedded_svc.settings.extraPrechatInfo = [{
        "entityName": "Case",
        "entityFieldMaps": [{
          "doCreate": true,
          "fieldName": "Status",
          "value": "Working"
        }]
      }];
      embedded_svc.init('https://autoalert.my.salesforce.com', 'https://autoalert.secure.force.com', gslbBaseURL, '00Di0000000eHQ2', 'Chat', {
        baseLiveAgentContentURL: 'https://c.la1-c1-iad.salesforceliveagent.com/content',
        deploymentId: '572f3000000Cbfr',
        buttonId: '573f3000000Cbi2',
        baseLiveAgentURL: 'https://d.la1-c1-iad.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04If3000000XZAHEA4_16f6d2b9055',
        isOfflineSupportEnabled: true
      });
    }; //initESW('https://service.force.com');


    if (!window.embedded_svc) {
      var s = document.createElement('script');
      s.setAttribute('src', 'https://autoalert.my.salesforce.com/embeddedservice/5.0/esw.min.js');

      s.onload = function () {
        initESW(null);
      };

      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
  }; //function prepareLiveSupport() {
  //    // liveagent loaded in index.html
  //    liveagent.init(
  //        "https://d.la1-c2cs-iad.salesforceliveagent.com/chat",
  //        "572c00000008Ogl",
  //        "00Dc0000003l4N7"
  //    );
  //    if (!$window._laq) {
  //        $window._laq = [];
  //    }
  //    $window._laq.push(function() {
  //        liveagent.showWhenOnline(
  //            "573c00000008P0W",
  //            document.getElementById("salesForcePlaceholder")
  //        );
  //        liveagent.showWhenOffline(
  //            "573c00000008P0W",
  //            document.getElementById("salesForcePlaceholder")
  //        );
  //    });
  //    let chat = document.getElementById("salesForcePlaceholder");
  //    chat.classList.remove("disabled");
  //    chat.classList.add("enabled");
  //}

}