<div class="aa-header-modal-mask" [hidden]="!isDropdownVisible" (click)="isDropdownVisible=false"></div>    
<span class="dropdown">
    <a id="availabilityTogglerDropdown" href="javascript:void(0);" ng-click="onUserAvailabilityClick()" data-toggle="dropdown">
        <img *ngIf="chatStaff.Availability == userAvailability.Available" src="../../../images/timeclock-available.svg" class="availabilityIcon" />
        <img *ngIf="chatStaff.Availability == userAvailability.Busy" src="../../../images/timeclock-busy.svg" class="availabilityIcon" />
        <img *ngIf="chatStaff.Availability == userAvailability.NotAvailable" src="../../../images/timeclock-unavailable.svg" class="availabilityIcon" />
    </a>
    <ul [hidden]="!isDropdownVisible" id="availabilityPanelNgx" class="dropdown-menu pull-right" >
        <li><h5 class="header-modal-h5" translate="setAvailability"></h5></li>
        <li>
            <div id="availabilityPanel">
                <div>
                    <div class="avilabilityBottomPadding">
                        <select class="form-control" id="userChoice" [(ngModel)]="modelUserSelection" (click)="toggleAvailabilitySwitch();">
                            <option value="{{userAvailability.Available}}" translate="Available"></option>
                            <option value="{{userAvailability.Busy}}" translate="busy"></option>
                            <option value="{{userAvailability.NotAvailable}}" translate="notAvailable"></option>
                        </select>
                    </div>
                    <div *ngIf="modelUserSelection == userAvailability.NotAvailable" >
                        <div *ngFor="let data of unavailableReasons; trackBy: trackByUnavailableReasonsById; let i = index">
                            <div class="avilabilityBottomPadding">
                                <div class="form-check">
                                    <input name="unavailableOption" type="radio" class="form-check-input" id="control_unavailable_optionRB_{{i}}" [value]="data.Id" [(ngModel)]="unavailableSelectedOption">
                                    <label for="control_unavailable_optionRB_{{i}}" class="form-check-label">
                                        <small>{{data.Text}}</small>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div translate="reason"></div>
                        <div class="avilabilityBottomPadding">
                            <textarea  [(ngModel)]="unavailableNote"></textarea>
                        </div>
                    </div>
                    <div *ngIf="modelUserSelection == userAvailability.Busy">
                        <div class="form-horizontal" align="left" >
                            <div *ngFor="let data of busyReasons; trackBy: trackByBusyReasonsById;let i = index">
                                <div class="avilabilityBottomPadding">
                                    <div class="form-check">
                                        <input  name="busyOption" type="radio" class="form-check-input" id="control_busy_optionRB_{{i}}" [value]="data.Id" [(ngModel)]="busySelectedOption">
                                        <label for="control_busy_optionRB_{{i}}" class="form-check-label" >
                                            <small>{{data.Text}}</small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div translate="reason"></div>
                            <div class="avilabilityBottomPadding">
                                <textarea  [(ngModel)]="busyNote"></textarea>
                            </div>
                            <div class="avilabilityBottomPadding"><span translate="busyFor"class="busyFor"></span><input id="busynumber" (change)="validateBusyMin()" type="number" min="1" max="120"  [(ngModel)]="busyForMinutes" /> <span translate="minutes"></span> [1-120]</div>
                        </div>
                    </div>
                    <div *ngIf="error" class="error" translate="{{errorMsgKey}}"></div>
                    <div class="modal-footer">
                        <div class="button-span">
                            <button class="btn btn-primary" (click)="resetUI()" translate="cancel"></button>
                            <button class="btn btn-primary" (click)="submitAvailabilityStatus()" translate="update"></button>
                        </div>
                    </div>
                 </div>
             </div>
        </li>
    </ul>
</span>




