
/* injects from baggage-loader */
require('./bank-rate.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('bankRate', {
    templateUrl: '/app/components/deal-sheet/contract/bank-rate.component.html',
    controller: BankRateController,
    bindings: {
        countryId: '<',
        bankName: '<',
        bankUrl: '<',
        apr: '<',
        moneyFactor: '<',
        saleType: '<',
        conquestType: '<',
        permissions: '<'
    }
});

/* @ngInject */
function BankRateController($translate, saleTypes, dealerCountries) {
    var $ctrl = this;

    $ctrl.saleTypes = saleTypes;
    $ctrl.dealerCountries = dealerCountries;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onInit = init;
    $ctrl.runOnce = false;

    function init() {
        if (!($ctrl.bankName)) {
            loadUnknownBankLabel();
        }
    }

    function onChanges(changes) {
        if (changes.bankName && !changes.bankName.currentValue) {
            loadUnknownBankLabel();
        }

        if (changes.moneyFactor && changes.moneyFactor.currentValue != null) {
            $ctrl.runOnce = true;
        }

        if ($ctrl.countryId == $ctrl.dealerCountries.france && $ctrl.runOnce) {
            $ctrl.moneyFactor = $ctrl.moneyFactor * 2400;
            $ctrl.runOnce = false;
        }
    }


    function loadUnknownBankLabel() {
        $translate('unknown')
                .then((translation) => $ctrl.bankName = translation);
    }
}
