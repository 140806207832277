/* global window document sessionStorage */
import angular from 'angular';
import $ from 'jquery';

function IsLegacyPage($currentState) {
    if ($currentState) {
        return $currentState.component == 'aaIframe' ||
            ($currentState.data && $currentState.data.resolvedComponent == 'aaIframe');
    }
}

function shouldShowFooter($currentState) {
    if (IsLegacyPage($currentState))
        return false;
    if ($currentState.data && $currentState.data.hideFooter)
        return false;
    
    return true;
}

export function showAutoAlertiFrame($currentState) {

    if (!IsLegacyPage($currentState)) 
        $("#aa-app").removeClass("aa-legacy");
    else 
        $("#aa-app").addClass("aa-legacy");    

    // fix for PandoConnect : PandoConnect chrome extension was adding the following styles with a value: hidden on html and body tags. This
    // was hiding main menubar sub menus and recent deealsheet menu.
    // The following two lines of code fixes the issue and gets called when our iframe page gets loaded. Which always happens, after our main page and chrome extension load process.
    //
    document.getElementById('root').style.overflowX = "visible";
    document.getElementById('aa-app').style.overflowY = "visible";

    document.getElementById('autoalertiframe').style.visibility = "visible";
}

export function removeQuotes(token) {
    if (!token)
        return null;
    else {
        return token.replace(/^"(.*)"$/, '$1')
    }
}

export function getPortalSubPath() {
    var portalAutoAlert = '/alert-desk/deal-sheets';
    return portalAutoAlert;
}

function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
}

/**
 * Adjusts the width of the header to accomodate the page's scrollbar.
 * @param $currentState - The currently active router state
 */
export function resizeHeader($currentState, messagePort) {
    const iframe = document.getElementById('autoalertiframe');
    const isIframeReady = (iframe !== null) && iframe.style.visibility == 'visible';
    const footer = document.getElementById('aa-footer');

    if (shouldShowFooter($currentState)) {
        if (footer) footer.style.display = "";
        return;
    } else {
        if (footer) footer.style.display = "none";
    }

    // if legacy is in iframe or in full screen mode
    if (isIframeReady || IsLegacyPage($currentState) ) {
       let newWidth = $("body").width() - getScrollbarWidth();
       if (newWidth) {
            $("#header-app").width(newWidth);
            sendHeaderHeightMessage(messagePort);
       }
    } else {
        $("#header-app").width('100%');
    }
}

function sendHeaderHeightMessage(messagePort) {
    var headerHight = $("#header-app").height();
    if (headerHight) {
        const onResizePortalHeaderMessage = { 'task': 'onresize_portalheader', 'header_height': headerHight };
        messagePort.postMessage(onResizePortalHeaderMessage);
    }
    $("#ui-view").css('margin-top: ' + headerHight);
}
