import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.find";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
ReportSubscriptionListController.$inject = ["dateRangeTypes", "reportSubscriptionPeriods", "$translate"];

/* injects from baggage-loader */
require('./report-subscription-list.component.html');

import angular from 'angular';
import { ReportSpecsList } from '../../constants/reports/report-specifications';
import _ from 'lodash';
angular.module('AaApp.Opp').component('reportSubscriptionList', {
  templateUrl: '/app/components/reports/report-subscription-list.component.html',
  controller: ReportSubscriptionListController,
  bindings: {
    subscriptions: '<',
    onSubscriptionToggle: '&',
    onSubscriptionEdit: '&',
    onSubscriptionDelete: '&'
  }
});
/* @ngInject */

function ReportSubscriptionListController(dateRangeTypes, reportSubscriptionPeriods, $translate) {
  var $ctrl = this;
  $ctrl.dateRangeTypes;
  $ctrl.subscriptionPeriodTranslateKeys;
  $ctrl.$onChanges = onChanges;
  $ctrl.$onInit = init;
  $ctrl.translateCodes = [];

  function init() {
    $ctrl.dateRangeTranslateKeys = _.invert(dateRangeTypes);
    $ctrl.subscriptionPeriodTranslateKeys = _.invert(reportSubscriptionPeriods);
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $ctrl.subscriptions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var subscription = _step.value;
        if (subscription.code == dateRangeTypes.day) subscription.translateCode = dateRangeTypes.previousDay;else subscription.translateCode = subscription.code;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }

  function onChanges(changes) {
    if (changes.subscriptions && changes.subscriptions.currentValue) {
      for (var i = 0; i < $ctrl.subscriptions.length; i++) {
        var subscription = $ctrl.subscriptions[i]; //replace "Enterprise" to quickly and easily use the associated non-enterprise report version titleKey.

        var report = _.find(ReportSpecsList, function (item) {
          return item.id.toLowerCase() == subscription.reportId;
        });

        if (!report) continue;
        subscription.translateKey = report.titleKey.replace("Enterprise", "");
        subscription.hideDateRange = report.hideDateRange;
      }
    }
  }
}