import { Injectable, TemplateRef, RendererFactory2, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';

@Injectable({
    providedIn: 'root'
})

// This service helps ngx-bootstrap modals play nice with ui-bootstrap modals
// This is only needed while the deal sheet is opened in angularjs
// Nesting of ngx-bootstrap modals on top of angularjs modals does not work correctly
// because the two libraries don't know about each other
// This bumps the modal backdrop up above the deal sheet modal and re-adds the 'modal-open' class to the body
// Known issues: Scroll bar leaves then comes back on nested modal close, ngx-bootstrap removes this first thing and I can't trick it to not remove it
export class NgxModalWrapperService extends BsModalService {

    private isModalStacked = false;

    constructor(private renderFactory: RendererFactory2, private clr: ComponentLoaderFactory) {
        super(renderFactory, clr);

        this.onHide.subscribe(() => {
            if (this.isModalStacked) {
                document.body.classList.add('modal-open');
            }
        });
    }

    show(content: string | TemplateRef<any> | any, config?: ModalOptions): BsModalRef {
        this.isModalStacked = document.body.classList.contains('modal-open');

        const modalRef = super.show(content, config);
        
        if (this.isModalStacked && (<any>this).modalsCount === 1) {
            const backdrop = document.querySelector('bs-modal-backdrop');
            backdrop.setAttribute('style', 'z-index: 1070');
        }

        if (this.isModalStacked) {
            // the following fixes issues with ngx-bootstrap modal showing behind deal sheet if deal sheet
            // was opened by engagement widget which is a ui-bootstrap modal:
            const containerList = document.querySelectorAll('modal-container');
            containerList.forEach(function (item) {
                item.setAttribute('style', 'display:block;z-index: 1070');
            });
        }

        return modalRef;
    }
}
