import {
    AfterViewChecked, Component, ElementRef, Inject, Input, OnDestroy,
    Renderer2, ViewChild, OnChanges, SimpleChanges, OnInit, ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import $ from 'jquery';
import moment from 'moment';
import { Observable, Subject, Subscription, concat } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { ToasterLoggerService as LoggerService } from '../../../core/toaster-logger.service';

import { Modules } from '../../../constants/modules';
import { UserProfile } from '../../../interfaces/user-profile.interface';
import { TextMessageService } from '../../../services/text-message.service';
import { TextMessageUI } from './text-message-ui.interface';
import { SelectOption } from '../../../interfaces/select-option.interface';
import { LocaleDateTimePipe } from '../../../filters/locale-date-time.pipe';
import { NotificationMessage, EventType, EngagementEventTypeEnum } from '../../../interfaces/notification-message.interface';
import { TextMessageDto, TextableStatusModel } from '../../../generated/models';
import { AuthorizationKeys, EulaStatusTypes, SaleTypes } from '../../../constants';
import { NotificationService } from '../../../services/notification.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { EulaModalService } from '../../../ajs-upgraded-providers';
import { DOCUMENT } from '@angular/common';
import { DealSheetOpportunity } from '../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';

@Component({
    selector: 'text-action',
    templateUrl: './text-action.component-ng.html',
    styleUrls: ['./text-action.component-ng.scss']
})
export class TextActionComponent implements OnChanges, OnDestroy, AfterViewChecked, OnInit {
    private cultureName = 'en-us';
    private readonly textModalSelector = '#textModal';
    private readonly noUserNumberModal = '#noUserNumberModal';
    private filteredMessagesModified = false;
    private userProfile: UserProfile;
    private modalClosedSubject = new Subject<boolean>();
    private upgradeProosalLinkPlaceholder = '[Upgrade Proposal Link]';
    private notificationSubscription: Subscription;
    private subscriptionAggregator = new Subscription();
    private authorizationKeys = AuthorizationKeys;
    private eulaStatusTypes = EulaStatusTypes;
    private originalBackdropZindex: number;
    private readonly nestedModalBackdropZindex = 1060;
    private textableStatus: TextableStatusModel;
    private saleTypes = SaleTypes;
    public readonly last6Months = 'Last6Mon';
    public readonly lastMonth = 'LastMon';
    public readonly lastYear = 'LastYear';
    public readonly allTexts = 'AllTexts';
    private readonly sales = 'text_sales';
    private readonly service = 'text_service';
    private readonly translationKeys = [
        this.last6Months,
        this.lastMonth,
        this.lastYear,
        this.allTexts,
        this.sales,
        this.service
    ];
    private translations: any = {};

    public textMessages: TextMessageUI[] = [];
    public filteredMessages: TextMessageUI[] = [];
    public dateRangeOptions: SelectOption[] = [];
    public newTextMessageValue = '';
    public busy: Subscription;
    public timeSpanToDisplay = this.last6Months;
    public modules = Modules;
    public isTextable = false;
    public tooltip: string;
    public authorizedKeys = {};
    public selectedType = this.authorizationKeys.SendTextSales;

    @Input() opportunity: DealSheetOpportunity;
    @Input() isTextingEnabled: string;
    @Input() isCustomerConnectOpen: boolean;
    @Input() isShortcutButton = false;
    @ViewChild('modalBody', { static: false }) modalBodyElement: ElementRef;
    @ViewChild('newTextMessage', { static: false }) newTextMessageElement: ElementRef;

    constructor(private domSanitizer: DomSanitizer,
        private userService: UserService,
        private logger: LoggerService,
        private notificationService: NotificationService,
        private authorizationService: AuthorizationService,
        private renderer: Renderer2,
        private textMessageService: TextMessageService,
        private translateService: TranslateService,
        private localeDateTimePipe: LocaleDateTimePipe,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private document,
        @Inject(EulaModalService) private eulaModalService) {

        this.translateService.get(this.translationKeys)
            .subscribe(translations => {
                this.translations = translations;
                this.dateRangeOptions = [
                    { value: this.last6Months, text: this.translations.Last6Mon },
                    { value: this.lastMonth, text: this.translations.LastMon },
                    { value: this.lastYear, text: this.translations.LastYear },
                    { value: this.allTexts, text: this.translations.AllTexts },
                ]
            });
    }

    private latestNotificationObservable: Observable<TextMessageDto[]> = null;
    private handleNotification(message: NotificationMessage) {

        if (message.eventType === EventType.engagement &&
            message.data && message.data.type === EngagementEventTypeEnum.textMessageReplied &&
            message.data.customerID && message.data.customerID === this.opportunity.customer.id) {

            const lastMessage = this.textMessages[this.textMessages.length - 1];
            const lastMessageId = (lastMessage) ? lastMessage.messageId : undefined;

            const obs = this.textMessageService.getHistoryByCustomerId(this.opportunity.customer.id, lastMessageId);

            // Concatenate all observables so that rapid fire notifications will queue up
            if (this.latestNotificationObservable) {
                this.latestNotificationObservable = concat(obs);
            } else {
                this.latestNotificationObservable = obs;
            }
            const sub = this.latestNotificationObservable.subscribe(newMessages => {
                const messagesToBeAppended: TextMessageDto[] = [];

                // only add messages that we don't already have. We need to do this because there is a chance
                // we get duplicate pubnub notifications or multiple notifications that come quick enough before
                // the api call finishes

                const currentLastMessage = this.textMessages[this.textMessages.length - 1];
                const currentLastMessageId = (currentLastMessage) ? currentLastMessage.messageId : undefined;
                for (let index = 0; index < newMessages.length; index++) {
                    if (newMessages[index].messageId > currentLastMessageId) {
                        messagesToBeAppended.push(newMessages[index]);
                    }
                }

                const mappedMessages = this.mapServiceResults(messagesToBeAppended);
                this.textMessages = this.textMessages.concat(mappedMessages);

                this.filterMessages();
            });

            this.subscriptionAggregator.add(sub);
        }
    }

    ngOnInit() {
        this.authorizedKeys[this.authorizationKeys.SendTextSales] = this.authorizationService.isAuthorized(this.authorizationKeys.SendTextSales);
        this.authorizedKeys[this.authorizationKeys.SendTextService] = this.authorizationService.isAuthorized(this.authorizationKeys.SendTextService);

        // Default the initial selection. If they have both permisions they go to sales
        if (this.authorizedKeys[this.authorizationKeys.SendTextService]) { this.selectedType = this.authorizationKeys.SendTextService; }
        if (this.authorizedKeys[this.authorizationKeys.SendTextSales]) { this.selectedType = this.authorizationKeys.SendTextSales; }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.isCustomerConnectOpen || changes.isTextingEnabled) && this.isCustomerConnectOpen && this.isTextingEnabled) {
            this.refreshTextableStatus();

            this.userService.getUserProfile()
                .then((userProfile: UserProfile) => {
                    this.cultureName = userProfile.cultureName ? userProfile.cultureName : 'en-us';
                    this.cultureName = this.cultureName.toLowerCase();

                    this.userProfile = userProfile;
                });

            const translatedLinkText = this.translateService.instant('upgradeProposalLinkPlaceholder');
            this.upgradeProosalLinkPlaceholder = `[${translatedLinkText}]`;
        }

        if (changes.opportunity && changes.opportunity.firstChange) {
            this.refreshTextableStatus();
        }
    }

    ngOnDestroy(): void {
        //$(this.textModalSelector).remove();
        $(this.noUserNumberModal).remove();

        this.subscriptionAggregator.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (this.filteredMessagesModified &&
            this.modalBodyElement.nativeElement.scrollTop !== this.modalBodyElement.nativeElement.scrollHeight) {
            // Scroll the modal body to the bottom

            this.renderer.setProperty(this.modalBodyElement.nativeElement, 'scrollTop', this.modalBodyElement.nativeElement.scrollHeight);

            this.filteredMessagesModified = false;
        }
    }

    getSanitizedAvatar(message: TextMessageUI): SafeStyle {
        if (!message) { throw new Error('No message passed to getSanitizedAvatar'); }
        return (message.userAvatarUrl) ? this.domSanitizer.bypassSecurityTrustStyle('url(' + message.userAvatarUrl + ')') : '';
    }

    insertUpgradeProposalLink(): void {
        if (!this.authorizedKeys[this.selectedType]) { return; }
        // The trailing space after the placeholder is important so that we can easily find the link in the future and replace it with a anchor tag
        // don't remove it
        this.newTextMessageValue = `${this.newTextMessageValue} ${this.upgradeProosalLinkPlaceholder} `;
    }

    getNewTextMessagePlaceholder(): string {
        if (!this.authorizedKeys[this.selectedType]) {
            const alternateView = this.selectedType === this.authorizationKeys.SendTextSales ? this.translations.text_service : this.translations.text_sales;
            return this.translateService.instant('text_switch_conversation', { viewType: alternateView });
        } else {
            return this.translateService.instant('enterTextMessage');
        }
    }

    openDialog(): void {

        const validateUserNumberAndOpenDialog = () => {
            this.textMessageService.isUserNumberTextable()
                .subscribe(isTextable => {
                    if (!isTextable) {
                        const noUserNumberModal = $(this.noUserNumberModal);
                        noUserNumberModal.modal({ show: true, backdrop: true });
                        noUserNumberModal.appendTo('body');

                        noUserNumberModal.on('hidden.bs.modal', () => this.handleStackedDialogClose());

                        return;
                    } else {
                        this.openTextingDialog();
                    }
                });
        };

        if (this.opportunity.customer.doNotText) {
            this.logger.warning('Customer has requested not to be sent any texts.');
            return;
        }

        if (!this.opportunity.customer.cellPhoneNumber ||
            !this.opportunity.customer.cellPhoneNumber.value ||
            !(this.opportunity.customer.cellPhoneNumber.value.length > 0) ||
            this.opportunity.customer.cellPhoneNumber.isBad) {

            this.logger.warning(this.translateService.instant('textMessageNoCustomerCellNumber'));
            return;
        }

        if (this.textableStatus.eulaStatus &&
            this.textableStatus.eulaStatus !== this.eulaStatusTypes.fullyAccepted) {
            this.eulaModalService.validateEula()
                .then((result: boolean) => {
                    if (!result) {
                        this.logger.warning('caslNotAccepted');
                        return;
                    }

                    validateUserNumberAndOpenDialog();
                });
        } else {
            validateUserNumberAndOpenDialog();
        }
    }

    continueUserNumberConfirmation() {
        this.openTextingDialog();
    }

    private openTextingDialog() {
        this.textMessages = [];
        this.filteredMessages = [];

        if (this.modalClosedSubject && !this.modalClosedSubject.isStopped) {
            this.modalClosedSubject.next(true);
            this.modalClosedSubject.unsubscribe();
        }
        this.modalClosedSubject = new Subject<boolean>();

        this.busy = this.getMessageHistory()
            .subscribe(() => {
                $(this.textModalSelector).modal({ show: true, backdrop: true });

                // Bootstrap recommends that all of their modals be appended to the top level of the body
                // (see https://getbootstrap.com/docs/3.3/javascript/#modals)
                // Here I am using jquery to move my component modal html to the body tag. In ngOnDestroy I remove it from the body tag
                //$(this.textModalSelector).appendTo('body');

                //const modalBackdrop = this.document.querySelector('.modal-backdrop.in');
                //this.originalBackdropZindex = modalBackdrop.style['z-index'];
                //modalBackdrop.style['z-index'] = this.nestedModalBackdropZindex;
            });

        this.subscriptionAggregator.add(this.busy);

        this.notificationSubscription = this.notificationService.message$.subscribe((message: NotificationMessage) => {
            this.handleNotification(message);
        });

        this.subscriptionAggregator.add(this.notificationSubscription);

        $(this.textModalSelector).on('hidden.bs.modal', () => this.handleModalClosed());
    }

    shouldDisplayDateLine(currentTextMessageIndex: number): boolean {
        if (currentTextMessageIndex === 0) { return true; }

        const currentMessageSentDate = moment(this.filteredMessages[currentTextMessageIndex].sentDateTime);
        const prevousMessageSentDate = this.filteredMessages[currentTextMessageIndex - 1].sentDateTime;

        return moment(currentMessageSentDate).isAfter(prevousMessageSentDate, 'day');
    }

    shouldDisplayUpgradeProposalLink(): boolean {
        return (this.opportunity && this.opportunity.currentContract && this.opportunity.currentContract.saleType !== this.saleTypes.balloon);
    }

    getDisplayDate(date: string): string {
        if (moment(new Date(date)).isSame(new Date(), 'day')) {
            return 'Today';
        }

        if (moment(new Date(date)).isSame(moment(new Date()).subtract(1, 'day'), 'day')) {
            return 'Yesterday';
        }

        return new Date(date).toLocaleDateString(this.cultureName, { year: 'numeric', month: 'long', day: 'numeric' });
    }

    getMessageFooter(message: TextMessageUI): string {

        if (!message) {
            throw new Error('No message passed to getMessageFooter in TextActionComponent');
        }

        const formattedTime = new Date(message.sentDateTime).toLocaleTimeString(this.cultureName, { hour: 'numeric', minute: 'numeric' });

        // If its from us and its not from the current user then display the name of the user who sent it
        const userName = (message.outgoing &&
            message.userId !== this.userProfile.userId) ? `${message.userFirstName} ${message.userLastName} - ` : '';

        return `${userName}${formattedTime}`;
    }

    sendMessage(message: string) {
        // TODO need a way to get user avatar if this is the first message

        if (!message || message.trim() === '') { return; }

        let newMessage = <TextMessageUI>{};
        newMessage = {
            userAvatarUrl: null,
            customerId: this.opportunity.customer.id,
            entityId: this.opportunity.id,
            userFirstName: this.userProfile.firstName,
            userLastName: this.userProfile.lastName,
            message: message,
            cleanedMessage: message,
            sentDateTime: new Date().toISOString(),
            userId: this.userProfile.userId,
            messageId: -1,
            dealerId: this.opportunity.dealerId,
            accountSid: '',
            smsSid: '',
            customerPhone: this.opportunity.customer.cellPhoneNumber.value,
            outgoing: true,
            displayDate: this.getDisplayDate(new Date().toISOString()),
            messageFooter: '',
            customerFirstName: '',
            customerLastName: '',
            activityId: -1,
            isTextable: true,
            dealerPhoneAccessRightID: this.selectedType,
            hasUpgradeProposalLink: false
        };

        newMessage.messageFooter = this.getMessageFooter(newMessage);

        if (message.indexOf(this.upgradeProosalLinkPlaceholder) > 0) {
            newMessage.hasUpgradeProposalLink = true;
            this.busy = this.textMessageService.sendTextWithUpgradeProposal({ ...newMessage }, this.opportunity)
                .subscribe((response) => {
                    response.textResponse.activityId = response.proposalResponse.activityId;

                    this.handleSendMessageResponse(newMessage, response.textResponse);
                });
        } else {
            this.busy = this.textMessageService.sendText({ ...newMessage })
                .subscribe((textMessage) => {
                    this.handleSendMessageResponse(newMessage, textMessage);
                });
        }
    }

    private handleSendMessageResponse(messageRequest: TextMessageUI, messageResponse: TextMessageDto): void {

        if (!messageResponse.isTextable) {

            this.logger.warning(this.translateService.instant('textMessageCustomerStatusMayHaveChanged'));
            $(this.textModalSelector).modal('hide');
            this.refreshTextableStatus();
            return;
        }

        const newMessageResponse = <TextMessageUI>messageRequest;

        newMessageResponse.message = messageResponse.message.replace(/\n\r?/g, '<br />');

        if (messageResponse.activityId > 0) {
            const cleanedMessage = TextMessageService.replaceUrlStringWithHtmlLink(messageResponse.message, true);
            newMessageResponse.cleanedMessage = this.domSanitizer.bypassSecurityTrustHtml(cleanedMessage);
        }

        newMessageResponse.activityId = messageResponse.activityId;
        newMessageResponse.messageFooter = messageRequest.messageFooter;
        newMessageResponse.displayDate = messageRequest.displayDate;
        newMessageResponse.isTextable = messageResponse.isTextable;
        newMessageResponse.messageId = messageResponse.messageId;
        newMessageResponse.dealerPhoneAccessRightID = messageResponse.dealerPhoneAccessRightID;

        this.textMessages.push(newMessageResponse);
        this.filterMessages();

        this.newTextMessageValue = '';
    }

    getMaxRows(currentMessage: string): number {
        const maxRows = 10;
        const columns = 60;
        const arrayOfLines = currentMessage.split('\n');
        let rows = arrayOfLines.length;

        for (let i = 0; i < arrayOfLines.length; i++) {
            rows += Math.floor((arrayOfLines[i].length / columns));
            if (rows < 2) { rows = 2; }
        }

        return (rows > maxRows) ? maxRows : rows;
    }

    timeSpanChanged(timeSpanToDisplay: string): void {
        this.timeSpanToDisplay = timeSpanToDisplay;
        this.filterMessages();
    }

    toggleSalesServiceSelection(type: number) {
        this.selectedType = type;
        this.filterMessages();
    }

    getMessageHistory(): Observable<any> {
        return this.textMessageService.getHistory(this.opportunity)
            .pipe(
                map(messages => {
                    this.textMessages = this.mapServiceResults(messages);
                    this.filterMessages();
                }));
    }

    refreshTextableStatus() {
        if (this.opportunity) {
            const sub = this.textMessageService.getTextableStatus(this.opportunity.customer, this.opportunity.dealerId)
                .subscribe(textableStatus => {
                    this.updateTextableStatus(textableStatus);
                });
            this.subscriptionAggregator.add(sub);
        }
    }

    private updateTextableStatus(textableStatus: TextableStatusModel) {
        this.tooltip = '';
        this.isTextable = textableStatus.isTextable;
        this.textableStatus = textableStatus;

        const appendToTooltip = (toolTipTranslationKey: string, substitutionOptions?: object) => {
            const toolTipLineBreak = '\u000A';
            if (this.tooltip.length > 0) {
                this.tooltip += toolTipLineBreak;
            }

            this.tooltip = this.translateService.instant(toolTipTranslationKey, substitutionOptions);
        };

        if (textableStatus.isTextable) {
            if (textableStatus.consentDateTime) {
                appendToTooltip('textMessageOptInStatus',
                    { consentDateTime: this.localeDateTimePipe.transform(textableStatus.consentDateTime) });
            } else {
                if (textableStatus.hasValidTouchpoint) {
                    appendToTooltip('textMessageHasValidTouchpoint',
                        { touchpointDate: this.localeDateTimePipe.transform(textableStatus.touchpointDate) });
                } else {
                    appendToTooltip('textMessageDealerNumberProvisionedTooltip',
                        { dealerNumberProvisionedDate: this.localeDateTimePipe.transform(textableStatus.dealerNumberProvisionedDate) });
                }
            }
        } else {
            if (textableStatus.consentNotGranted) {
                // This will be null if they opted out so specifically checking for false here
                if (textableStatus.hasValidTouchpoint === false) {
                    appendToTooltip('textMessageCustomerHasNotConsented');
                } else {
                    appendToTooltip('textMessageCustomerOptOutTooltip',
                        { consentDateTime: this.localeDateTimePipe.transform(textableStatus.consentDateTime) });
                }
            } else {
                if (!textableStatus.hasContactInfo) {
                    appendToTooltip('textMessageNoContactInfo');
                }
                if (textableStatus.noCustomerCellNumber) {
                    appendToTooltip('textMessageNoCustomerCellNumber');
                } else if (textableStatus.invalidCustomerNumber) {
                    appendToTooltip('textMessageNumberInvalidTooltip');
                }
            }

            if (textableStatus.outsideTextingHours) {
                appendToTooltip('textMessageBusinessHoursTooltip');
            }

            if (this.tooltip.length === 0) {
                appendToTooltip('textNumbersNotProvisioned');
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    private handleModalClosed() {
        if (!this.modalClosedSubject.isStopped) {
            this.modalClosedSubject.next(true);
            this.modalClosedSubject.unsubscribe();
        }

        this.notificationSubscription.unsubscribe();

        this.handleStackedDialogClose();
    }

    private handleStackedDialogClose() {
        // Hack because closing a stacked modal removes the modal-open class from all open modals
        // Components should not be modifying dom outside of themselves this is a hack
        if (document.querySelector('body > .modal')) {
            document.body.classList.add('modal-open');
        }

        const modalBackdrop = this.document.querySelector('.modal-backdrop.in');
        if (modalBackdrop) {
            modalBackdrop.style['z-index'] = this.originalBackdropZindex;
        }
    }

    private mapServiceResults(messages: TextMessageDto[]): TextMessageUI[] {
        const textMessages = messages as TextMessageUI[];

        textMessages.forEach(m => {
            m.displayDate = this.getDisplayDate(m.sentDateTime);
            m.messageFooter = this.getMessageFooter(m);
            m.cleanedMessage = m.message;
            // Limit skipping sanitization for only outgoing messages.
            if (m.outgoing) {
                m.cleanedMessage = this.domSanitizer.bypassSecurityTrustHtml(TextMessageService.replaceUrlStringWithHtmlLink(m.message, true));
            }
        });

        return textMessages;
    }

    private filterMessages() {
        if (!this.textMessages || this.textMessages.length === 0) { return; }

        const fromDate = this.getFromDateFromTimespan();
        this.filteredMessagesModified = true;
        this.filteredMessages = this.textMessages.filter(message => {
            return moment(message.sentDateTime).isAfter(fromDate) && message.dealerPhoneAccessRightID === this.selectedType;
        });
    }

    private getFromDateFromTimespan(): Date {
        switch (this.timeSpanToDisplay) {
            case this.lastMonth:
                return moment().subtract(30, 'day').toDate();
            case this.last6Months:
                return moment().subtract(180, 'day').toDate();
            case this.lastYear:
                return moment().subtract(365, 'day').toDate();
            case this.allTexts:
                return new Date(1900, 1, 1);
        }
    }
}
