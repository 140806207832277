import { isEqual } from 'lodash';
import { StorageInterface } from './storage.interface';

/**
 * Implements the Web Storage API using cookies as the underlying storage.
 *  domain - The domain under which to store the cookie. If not provided, the current page's domain is used.
 *  keyPrefix - A string to prefix cookie names with, to differentiate environments.
 */
export class CookieStorage implements StorageInterface {
    constructor(private window: Window, private domain?: string, private keyPrefix?: string) {
     }

    // As we access keys, we learn of their existence. We're assuming that
    // every key we access belongs to us and should be cleared together.
    KNOWN_KEYS = new Set<string>();

    /**
     * Retrieves and deserializes an item from the store
     */
    getItem(key: string): any {
        // Format: cookie1=value1; cookie2=value2
        const cookies = this.window.document.cookie.split(';');
        const prefixedKey = this.addKeyPrefix(key);

        for (let i = 0; i < cookies.length; i++) {
            const pair = cookies[i].split('=');
            const [k, value] = pair;

            if (k.trim() === prefixedKey && value) {
                try {
                    this.KNOWN_KEYS.add(key);
                    return JSON.parse(decodeURIComponent(value.trim()));
                } catch (e) {
                    // fallthrough and return undefined
                }
            }
        }
    }

    setItem(key: string, value: any): void {
        const cookieSafeValue = encodeURIComponent(JSON.stringify(value));
        const cookieValue = this.makeCookieString([
            this.keyValueString(this.addKeyPrefix(key), cookieSafeValue),
            this.domainString(),
            this.pathString(),
            this.secureString(),
        ]);
        this.window.document.cookie = cookieValue;
        this.KNOWN_KEYS.add(key);
    }

    removeItem(key: string): void {
        const cookieValue = this.makeCookieString([
            this.keyValueString(this.addKeyPrefix(key), ''),
            this.domainString(),
            this.pathString(),
            this.expiresString('Thu, 01 Jan 1970 00:00:01 GMT')
        ]);
        this.window.document.cookie = cookieValue;
        this.KNOWN_KEYS.delete(key);
    }

    onchange(key: string, callback: ({}) => void): {} {
        let prevValue = this.getItem(key);
        const checkFn = () => {
            const value = this.getItem(key);
            if (typeof prevValue !== 'undefined' && !isEqual(prevValue, value)) {
                callback({ key, value });
            }
            prevValue = value;
        };
        this.KNOWN_KEYS.add(key);

        const intervalHandle = this.window.setInterval(checkFn, 5000);
        return { unsubscribe: () => this.window.clearInterval(intervalHandle) };
    }

    /**
     * Removes all known keys from the store.
    */
    clear() {
        this.KNOWN_KEYS.forEach(key => {
            this.removeItem(key);
        });
    }

    private addKeyPrefix(key: string) {
        if (this.keyPrefix) {
            return `${this.keyPrefix}:${key}`;
        }
        return key;
    }

    private makeCookieString(components: string[]) {
        return components.filter(c => !!c).join(';');
    }

    private keyValueString(key: string, value: string) {
        return key + '=' + value;
    }

    private expiresString(date: string) {
        return 'expires=' + date;
    }

    private pathString() {
        return 'path=/';
    }

    private domainString() {
        if (this.domain) {
            return 'domain=' + this.domain;
        }
    }

    private secureString() {
        if (this.window.location.protocol === 'https') {
            return 'secure';
        }
    }
}
