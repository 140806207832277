"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var UserClientService = /** @class */ (function (_super) {
    __extends(UserClientService, _super);
    function UserClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    UserClientService.prototype.LoggedInUserGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/User/LoggedInUser", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.LoggedInUserGET = function () {
        return this.LoggedInUserGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.CurrentUserGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/User/CurrentUser", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.CurrentUserGET = function () {
        return this.CurrentUserGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param username undefined
     * @return Success
     */
    UserClientService.prototype.GetUserByUsernameGETResponse = function (username) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/GetUser/" + username), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param username undefined
     * @return Success
     */
    UserClientService.prototype.GetUserByUsernameGET = function (username) {
        return this.GetUserByUsernameGETResponse(username).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    UserClientService.prototype.GetEnabledUsersForDealerByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/GetEnabledUsersForDealer/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    UserClientService.prototype.GetEnabledUsersForDealerByDealeridGET = function (dealerId) {
        return this.GetEnabledUsersForDealerByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    UserClientService.prototype.GetEnabledClientUsersExtByDealerByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/GetEnabledClientUsersExtByDealer/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    UserClientService.prototype.GetEnabledClientUsersExtByDealerByDealeridGET = function (dealerId) {
        return this.GetEnabledClientUsersExtByDealerByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param userId undefined
     * @return Success
     */
    UserClientService.prototype.GetUserFullNameByUseridGETResponse = function (userId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/FullName/" + userId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param userId undefined
     * @return Success
     */
    UserClientService.prototype.GetUserFullNameByUseridGET = function (userId) {
        return this.GetUserFullNameByUseridGETResponse(userId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    UserClientService.prototype.GetUserFullNameByEntityByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/FullNameByEntity/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    UserClientService.prototype.GetUserFullNameByEntityByEntityidGET = function (entityId) {
        return this.GetUserFullNameByEntityByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    UserClientService.prototype.GetEntityUserInfoByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/GetEntityUserInfo/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    UserClientService.prototype.GetEntityUserInfoByEntityidGET = function (entityId) {
        return this.GetEntityUserInfoByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    UserClientService.prototype.LogUserLoginPOSTResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/User/LogUserLogin", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    UserClientService.prototype.LogUserLoginPOST = function () {
        return this.LogUserLoginPOSTResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param emailAddress undefined
     * @return Success
     */
    UserClientService.prototype.FacebookUserIdByEmailaddressGETResponse = function (emailAddress) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/FacebookUserId/" + emailAddress), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param emailAddress undefined
     * @return Success
     */
    UserClientService.prototype.FacebookUserIdByEmailaddressGET = function (emailAddress) {
        return this.FacebookUserIdByEmailaddressGETResponse(emailAddress).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.GetUserDealersGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/User/GetUserDealers", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.GetUserDealersGET = function () {
        return this.GetUserDealersGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param countryId undefined
     * @return Success
     */
    UserClientService.prototype.GetUserIsMetricByCountryidGETResponse = function (countryId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/GetUserIsMetric/" + countryId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @param countryId undefined
     * @return Success
     */
    UserClientService.prototype.GetUserIsMetricByCountryidGET = function (countryId) {
        return this.GetUserIsMetricByCountryidGETResponse(countryId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserClientService.CheckUserIPGETParams` containing the following parameters:
     *
     * - `userName`:
     *
     * - `isMobile`:
     *
     * - `ipAddress`:
     *
     * - `dealerIds`:
     *
     * @return Success
     */
    UserClientService.prototype.CheckUserIPGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.userName != null)
            __params = __params.set('userName', params.userName.toString());
        if (params.isMobile != null)
            __params = __params.set('isMobile', params.isMobile.toString());
        if (params.ipAddress != null)
            __params = __params.set('ipAddress', params.ipAddress.toString());
        (params.dealerIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('dealerIds', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/User/CheckUserIP", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserClientService.CheckUserIPGETParams` containing the following parameters:
     *
     * - `userName`:
     *
     * - `isMobile`:
     *
     * - `ipAddress`:
     *
     * - `dealerIds`:
     *
     * @return Success
     */
    UserClientService.prototype.CheckUserIPGET = function (params) {
        return this.CheckUserIPGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.IsWhiteSalePartnerGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/User/IsWhiteSalePartner", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @return Success
     */
    UserClientService.prototype.IsWhiteSalePartnerGET = function () {
        return this.IsWhiteSalePartnerGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    UserClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/User/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    UserClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    UserClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/User/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    UserClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    UserClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/User/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    UserClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    UserClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/User", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    UserClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    UserClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/User", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    UserClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    UserClientService.LoggedInUserGETPath = '/api/User/LoggedInUser';
    UserClientService.CurrentUserGETPath = '/api/User/CurrentUser';
    UserClientService.GetUserByUsernameGETPath = '/api/User/GetUser/{username}';
    UserClientService.GetEnabledUsersForDealerByDealeridGETPath = '/api/User/GetEnabledUsersForDealer/{dealerId}';
    UserClientService.GetEnabledClientUsersExtByDealerByDealeridGETPath = '/api/User/GetEnabledClientUsersExtByDealer/{dealerId}';
    UserClientService.GetUserFullNameByUseridGETPath = '/api/User/FullName/{userId}';
    UserClientService.GetUserFullNameByEntityByEntityidGETPath = '/api/User/FullNameByEntity/{entityId}';
    UserClientService.GetEntityUserInfoByEntityidGETPath = '/api/User/GetEntityUserInfo/{entityId}';
    UserClientService.LogUserLoginPOSTPath = '/api/User/LogUserLogin';
    UserClientService.FacebookUserIdByEmailaddressGETPath = '/api/User/FacebookUserId/{emailAddress}';
    UserClientService.GetUserDealersGETPath = '/api/User/GetUserDealers';
    UserClientService.GetUserIsMetricByCountryidGETPath = '/api/User/GetUserIsMetric/{countryId}';
    UserClientService.CheckUserIPGETPath = '/api/User/CheckUserIP';
    UserClientService.IsWhiteSalePartnerGETPath = '/api/User/IsWhiteSalePartner';
    UserClientService.ByIdGETPath = '/api/User/{id}';
    UserClientService.ByIdDELETEPath = '/api/User/{id}';
    UserClientService.GetManyGETPath = '/api/User/GetMany';
    UserClientService.PUTPath = '/api/User';
    UserClientService.POSTPath = '/api/User';
    UserClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserClientService_Factory() { return new UserClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: UserClientService, providedIn: "root" });
    return UserClientService;
}(base_service_1.BaseService));
exports.UserClientService = UserClientService;
