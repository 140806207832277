
/* injects from baggage-loader */
require('./service-history.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('serviceHistory', {
    templateUrl: '/app/components/deal-sheet/service-history.component.html',
    controller: ServiceHistoryController,
    bindings: {
        history: '<',
        permissions: '<',
        defaultTab: '<',
        onSetAsDefault: '&'
    }
});

/* @ngInject */
function ServiceHistoryController($translate, generalNaKey, dealSheetTabs, servicesService) {
    var $ctrl = this;

    $ctrl.tabs = dealSheetTabs;
    $ctrl.GetAdvisor = getAdvisor;
    $ctrl.GetAmount = getAmount;
    $ctrl.getServiceLineDetails = getServiceLineDetails;

    var naMessage = 'N/A';
    $translate(generalNaKey).then(function (value) {
        naMessage = value;
    }).catch(function (e) {
    });

    function getAdvisor(item) {
        if (!item) { return ''; }
        if (item.serviceAdvisorName && typeof (item.serviceAdvisorName) === 'string' && item.serviceAdvisorName.trim() !== '') { return item.serviceAdvisorName; }
        if (item.serviceAdvisorID && typeof (item.serviceAdvisorID) === 'string' && item.serviceAdvisorID.trim() !== '') { return item.serviceAdvisorID; }
        return '';
    }

    function getAmount(item, type) {

        if (!item || !type) { return naMessage; }
        switch (type) {
            case 'CP':
                if (!(typeof (item.customerPaid) === 'undefined' || item.customerPaid === null)) {
                    return item.customerPaid;
                }
                break;
            case 'DP':
                if (!(typeof (item.dealerPaid) === 'undefined' || item.dealerPaid === null)) {
                    return item.dealerPaid;
                }
                break;
            case 'WP':
                if (!(typeof (item.warrantyPaid) === 'undefined' || item.warrantyPaid === null)) {
                    return item.warrantyPaid;
                }
                break;
        }
        return naMessage;
    }

    function getServiceLineDetails(dealerId, roId) {
        return servicesService.getServiceLineDetails(dealerId, roId);
    }

}
