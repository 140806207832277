"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NgContentTypeInterceptor = /** @class */ (function () {
    function NgContentTypeInterceptor() {
    }
    NgContentTypeInterceptor.prototype.intercept = function (req, next) {
        var method = req.method.toUpperCase();
        var newReq = null;
        if (method === 'POST' ||
            method === 'PUT' ||
            method === 'PATCH' ||
            method === 'DELETE') {
            newReq = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }
        return next.handle(newReq || req);
    };
    return NgContentTypeInterceptor;
}());
exports.NgContentTypeInterceptor = NgContentTypeInterceptor;
