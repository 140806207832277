"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactTypes = {
    address: 1,
    homePhone: 2,
    workPhone: 3,
    cellPhone: 4,
    email: 5,
    doNotCall: 6,
    doNotEmail: 7
};
