"use strict";
// import { Component, OnInit } from '@angular/core';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
// @Component({
//   selector: 'loyalty-rebate',
//   templateUrl: './loyalty-rebate.component.html',
//   styleUrls: ['./loyalty-rebate.component.scss']
// })
// export class LoyaltyRebateComponent implements OnInit {
//   constructor() { }
//   ngOnInit() {
//   }
// }
var core_1 = require("@angular/core");
var static_1 = require("@angular/upgrade/static");
var LoyaltyRebateComponent = /** @class */ (function (_super) {
    __extends(LoyaltyRebateComponent, _super);
    function LoyaltyRebateComponent(elementRef, injector) {
        return _super.call(this, 'loyaltyRebate', elementRef, injector) || this;
    }
    return LoyaltyRebateComponent;
}(static_1.UpgradeComponent));
exports.LoyaltyRebateComponent = LoyaltyRebateComponent;
