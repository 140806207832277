"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var popover_1 = require("ngx-bootstrap/popover");
var TooltipComponent = /** @class */ (function () {
    function TooltipComponent(popoverModule) {
        this.popoverModule = popoverModule;
        this.titleKey = null;
        this.contentKey = null;
    }
    return TooltipComponent;
}());
exports.TooltipComponent = TooltipComponent;
