"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MobileCustomerEmailComponent = /** @class */ (function () {
    function MobileCustomerEmailComponent() {
    }
    MobileCustomerEmailComponent.prototype.ngOnInit = function () {
    };
    return MobileCustomerEmailComponent;
}());
exports.MobileCustomerEmailComponent = MobileCustomerEmailComponent;
