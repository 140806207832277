import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CustomerContact } from '../../../../interfaces/customer-contact.interface';

@Component({
    selector: 'mobile-customer-phone',
    templateUrl: './mobile-customer-phone.component-ng.html',
    styleUrls: ['./mobile-customer-phone.component.scss', '../customer-info/customer-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileCustomerPhoneComponent implements OnInit {

    @Input() doNotCall: boolean;
    @Input() phone: CustomerContact;
    @Input() type: string;

    constructor() { }

    ngOnInit() {
    }
}
