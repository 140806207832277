"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".modal-body[_ngcontent-%COMP%]   .bold[_ngcontent-%COMP%]{font-weight:500}.row-underline[_ngcontent-%COMP%]{border-bottom:1px solid #e5e5e5}.sectionHeader[_ngcontent-%COMP%]{font-size:18px;font-weight:500}.table[_ngcontent-%COMP%]{margin-bottom:10px}.table[_ngcontent-%COMP%]   td.right[_ngcontent-%COMP%], .table[_ngcontent-%COMP%]   th.right[_ngcontent-%COMP%]{text-align:right}"];
exports.styles = styles;
