"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var user_service_1 = require("./user.service");
var authorization_service_1 = require("./authorization.service");
var storage_service_1 = require("../modules/storage/storage.service");
var opportunity2_service_1 = require("./opportunity2.service");
var dealer_service_1 = require("./dealer.service");
var menu_service_1 = require("./menu.service");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("./user.service"));
var i2 = __importStar(require("./authorization.service"));
var i3 = __importStar(require("../modules/storage/storage.service"));
var i4 = __importStar(require("./opportunity2.service"));
var i5 = __importStar(require("./dealer.service"));
var i6 = __importStar(require("./menu.service"));
var i7 = __importStar(require("../ajs-upgraded-providers"));
// Refactor of methods that get an opportunity formatted for use in a dealsheet
var DealSheetDataService = /** @class */ (function () {
    function DealSheetDataService(userService, authorizationService, storageService, opportunityService, dealerService, menuService, vehicleService) {
        this.userService = userService;
        this.authorizationService = authorizationService;
        this.storageService = storageService;
        this.opportunityService = opportunityService;
        this.dealerService = dealerService;
        this.menuService = menuService;
        this.vehicleService = vehicleService;
    }
    // refactor of getDealSheet from deal-sheet.component
    DealSheetDataService.prototype.initDealsheetData = function (opportunityId, stockNo) {
        var _this = this;
        var userProfile;
        var selectedDealerIds;
        var dealSheetData = {};
        return rxjs_1.from(this.userService.getUserProfile()
            .then(function (userProfileResult) {
            userProfile = userProfileResult;
            selectedDealerIds = _this.dealerService.getSelectedDealerIds();
            return _this.authorizationService.getAccessRightIdsFromCache(selectedDealerIds);
        })
            .then(function (accessRightIds) {
            return Promise.all([
                _this.authorizationService.getDealSheetPermissions(accessRightIds),
                _this.authorizationService.getModuleIdsFromCache(selectedDealerIds)
            ]);
        })
            .then(function (results) {
            dealSheetData.permissions = results[0];
            dealSheetData.moduleIds = results[1];
            dealSheetData.permissions.featureComparison = true;
            dealSheetData.permissions.otherVehiclesOwned = true;
            dealSheetData.isSltUser = userProfile.isSltUser;
            dealSheetData.userProfile = userProfile;
            _this.opportunityService.hasReviewProDealer(opportunityId).then(function (hasReviewPro) {
                dealSheetData.hasReviewPro = hasReviewPro;
            });
            _this.opportunityService.hasGeoAlertDealer(opportunityId).then(function (hasGeoAlert) {
                dealSheetData.hasGeoAlert = hasGeoAlert;
            });
            // this appears to always be null in deal-sheet
            var calculationType = null;
            return _this.opportunityService.getOpportunity(opportunityId, stockNo, calculationType, dealSheetData.permissions, dealSheetData.moduleIds)
                .then(function (opportunity) {
                dealSheetData.opportunity = opportunity;
                _this.setAdditionalProperties(dealSheetData);
                return dealSheetData;
            })
                .then(function (dealSheetDataResult) { return _this.getFuelEconomyComparisonReplacementVehicleFuelCost(dealSheetDataResult.opportunity.replacementVehicle, dealSheetDataResult.opportunity.id); })
                .then(function (fuelEconomyComparison) {
                dealSheetData.fuelEconomyComparison = fuelEconomyComparison;
                return dealSheetData;
            });
        }));
    };
    /**
     * Refactored from opportunity.component setOpportunity.
     */
    DealSheetDataService.prototype.setAdditionalProperties = function (dealSheetData) {
        this.opportunityService.addAlertsAsProperties(dealSheetData, dealSheetData.opportunity.alerts);
    };
    DealSheetDataService.prototype.shouldDisplayPullForward = function (opportunity) {
        if (opportunity != null && opportunity.replacementContract != null && opportunity.replacementContract.pullForwardAmount > 0) {
            var tradeEquity = 0;
            if (opportunity.currentVehicle.tradeValue != null && opportunity.currentContract.payoffAmount != null) {
                tradeEquity = opportunity.currentVehicle.tradeValue - opportunity.currentContract.payoffAmount;
            }
            else {
                tradeEquity = opportunity.currentVehicle.tradeValue;
            }
            var btmEquity = -opportunity.currentContract.balanceToMaturity +
                opportunity.currentContract.dispositionFee +
                opportunity.replacementContract.pullForwardAmount;
            return btmEquity > tradeEquity;
        }
        return false;
    };
    DealSheetDataService.prototype.getFuelEconomyComparisonReplacementVehicleFuelCost = function (vehicle, opportunityId) {
        if (vehicle && vehicle.trimID && vehicle.trimID > 0) {
            return this.vehicleService.getFuelEconomyComparison(vehicle.trimID, opportunityId)
                .catch(function () { return Promise.resolve(null); });
        }
        else {
            return Promise.resolve(null);
        }
    };
    DealSheetDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DealSheetDataService_Factory() { return new DealSheetDataService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.AuthorizationService), i0.ɵɵinject(i3.StorageService), i0.ɵɵinject(i4.Opportunity2Service), i0.ɵɵinject(i5.DealerService), i0.ɵɵinject(i6.MenuService), i0.ɵɵinject(i7.VehicleService)); }, token: DealSheetDataService, providedIn: "root" });
    return DealSheetDataService;
}());
exports.DealSheetDataService = DealSheetDataService;
