import { Component, OnInit, Input, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { ToasterLoggerService } from '../../../core/toaster-logger.service';
import { DealSheetService } from '../../../ajs-upgraded-providers';
import { DeskitQuotingPushResponseDto, DeskitQuotingPushDto, DeskitQuotingPushSoldVehicle, DeskitQuotingPushTradeVehicle } from '../../../generated/models';
import { Subscription, from } from 'rxjs';
import { SaleTypes, VehicleTypes } from '../../../constants';

@Component({
    selector: 'deskit',
    templateUrl: './deskit.component-ng.html',
    styleUrls: ['./deskit.component.scss']
})
export class DeskitComponent implements OnInit, OnChanges {
    @Input() dealerId: number;
    @Input() dealerSettings: any;
    @Input() entityId: string;
    @Input() customer: any;
    @Input() currentVehicle: any;
    @Input() replacementVehicle: any;
    @Input() saleType: number;
    private subscriptionAggregator = new Subscription();
    public showDeskitLink = false;
    public busy: Subscription;
    public saleTypes = SaleTypes;
    public vehicleTypes = VehicleTypes;
    public deskitImagePath = require('../../../images/icon-desking-lake.svg');

    constructor(@Inject(DealSheetService) private dealSheetService,
                public logger: ToasterLoggerService) { }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.subscriptionAggregator.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.dealerSettings || !this.dealerId || !this.entityId || !this.customer || !this.currentVehicle) {
            return;
        }
        this.showDeskitLink = this.dealerSettings.deskitPushEnabled;

    }

    deskitPush(): Subscription {

        const trade: DeskitQuotingPushTradeVehicle = {
            vin: this.currentVehicle.vin,
            year: this.currentVehicle.yearID,
            make: this.currentVehicle.makeName,
            model: this.currentVehicle.modelName,
            modelTrim: this.currentVehicle.trimFullName,
            mileage: this.currentVehicle.estimatedCurrentMileage
        };

        const soldVehicle: DeskitQuotingPushSoldVehicle = {
            stockNumber: this.replacementVehicle.stockNo,
            inventoryType: this.replacementVehicle.type == this.vehicleTypes.new ? 'N' : 'U',
            vin: this.replacementVehicle.vin, 
            make: this.replacementVehicle.makeName,
            model: this.replacementVehicle.modelName,
            year: this.replacementVehicle.yearID,
            modelTrim: this.replacementVehicle.trimFullName
        }

        const request: DeskitQuotingPushDto = {
            dealerID: this.dealerId,
            entityID: this.entityId,
            customerID: String(this.customer.id),
            dealType: (this.saleType === this.saleTypes.lease || this.saleType === this.saleTypes.onePayLease) ? "Lease" : "Finance",
            firstName: this.customer.firstName,
            middleName: this.customer.middleName,
            lastName: this.customer.lastName,
            companyName: this.customer.companyName,
            addressLines: [this.customer.address.streetAddress, this.customer.address.streetAddress2].filter((value) => value !== null && value !== undefined && value !== ""),
            city: this.customer.address.city,
            county: this.customer.county,
            state: this.customer.address.stateName,
            zipCode: this.customer.address.zipCode,
            homePhone: this.customer.homePhoneNumber && this.customer.homePhoneNumber.value !== undefined ? this.customer.homePhoneNumber.value : null,
            cellPhone: this.customer.cellPhoneNumber && this.customer.cellPhoneNumber.value !== undefined ? this.customer.cellPhoneNumber.value : null,
            workPhone: this.customer.workPhoneNumber && this.customer.workPhoneNumber.value !== undefined ? this.customer.workPhoneNumber.value : null,
            email: this.customer.emailAddress && this.customer.emailAddress.value !== undefined ? this.customer.emailAddress.value : null,
            tradeVehicles: [trade],
            soldVehicle: soldVehicle
        };

        return from(this.dealSheetService.pushToDeskit(request))
            .subscribe((result: DeskitQuotingPushResponseDto | null) => {
                if (result != null) {
                    if (result.message.status == 1) {
                        this.logger.success("Data successfully pushed to Deskit.", "Deskit DealID: " + result.dealID, "Push to Deskit");
                    }
                    else {
                        this.logger.error("There was an error attempting to push data to Deskit.", result.message.errorMessage, "Push to Deskit")
                    }
                }
            });
    }

    public pushToDeskit() {
        this.busy = this.deskitPush();
        this.subscriptionAggregator.add(this.busy);
    }
}
