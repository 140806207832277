import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {

    constructor(private dom: DomSanitizer) {

    }

    transform(url: string) {
        return this.dom.bypassSecurityTrustResourceUrl(url);
    }

}
