<span class="value" *ngIf="widgetData && widgetData.length == 0" translate="noInformationAvailable"></span>
<table class="table table-hover" *ngIf="widgetData && widgetData.length > 0">
    <thead>
        <tr>
            <th translate="type"></th>
            <th translate="date"></th>
            <th translate="time"></th>
            <th translate="lblTransactionType"></th>
            <th translate="lblLastRO"></th>
            <th translate="lblLastMarketedDate"></th>
            <th translate="owner"></th>
            <th translate="clientName"></th>
            <th translate="lblCurrentYear"></th>
            <th translate="lblCurrentModel"></th>
            <th translate="mileageLabel"></th>
            <th translate="lblReplacementYear"></th>
            <th translate="lblReplacementModel"></th>
        </tr>
    </thead>
    <tbody>
        <tr class="cursor" *ngFor="let item of widgetData" (click)="openDealSheet(item.entityID)">
            <td><icon class="schedule icon {{activityIconClasses[item.activityTypeID]}}"></icon></td>
            <td>{{item.dueDate | localeDate}}</td>
            <td>{{item.dueDate | localeTime}}</td>
            <td>{{item.new_Used}}</td>
            <td>{{item.openDate | localeDate}}</td>
            <td>{{item.lastMarketedDate | localeDate}}</td>


            <td>{{item._Customer_Owner}}</td>
            <td>{{item.customerFullName}}</td>
            <td>{{item.current_Model_Year}}</td>
            <td>{{item.current_Model_Name}}</td>
            <td>{{item.estimated_Current_Mileage | localeNumber }}</td>
            <td>{{item.replacement_Model_Year}}</td>
            <td>{{item.replacement_Model_Name}}</td>
        </tr>
    </tbody>
</table>
