"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var storage_service_1 = require("../storage/storage.service");
var constants_1 = require("../../constants");
var TokenService = /** @class */ (function () {
    function TokenService(storageService) {
        this.storageService = storageService;
        this.ACCESS_TOKEN_TYPE = 'access';
        this.ID_TOKEN_TYPE = 'id';
        this.window = window;
    }
    TokenService.prototype.padBase64 = function (base64data) {
        while (base64data.length % 4 !== 0) {
            base64data += '=';
        }
        return base64data;
    };
    TokenService.prototype.getPayloadFromRawToken = function (raw) {
        if (typeof raw === 'string') {
            var tokenParts = raw.split('.');
            if (tokenParts.length >= 2) {
                return tokenParts[1];
            }
        }
    };
    TokenService.prototype.getImpersonatingUsername = function () {
        if (!this.hasToken()) {
            return null;
        }
        var claims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);
        return claims.impersonating_aa_username;
    };
    TokenService.prototype.deserializeClaims = function (raw) {
        var claimsBase64 = this.padBase64(raw);
        var claimsJson = this.window.atob(claimsBase64);
        var claims = JSON.parse(claimsJson);
        return claims;
    };
    TokenService.prototype.convertToClaims = function (token) {
        try {
            var payload = this.getPayloadFromRawToken(token);
            var claims = this.deserializeClaims(payload);
            return claims;
        }
        catch (e) {
            return {};
        }
    };
    TokenService.prototype.saveToken = function (tokenType, token, isExternalToken) {
        this.storageService.setItem(tokenType + 'Token', token);
        this.storageService.setItem(constants_1.StorageKeys.IsExternalToken, !!isExternalToken);
        var claims = this.convertToClaims(token);
        this.storageService.setItem('cached-' + tokenType + '-claims', claims);
    };
    TokenService.prototype.hasExternalToken = function () {
        if (this.hasToken()) {
            if (this.storageService.getItem(constants_1.StorageKeys.IsExternalToken) == true) {
                return true;
            }
        }
        return false;
    };
    TokenService.prototype.hasToken = function () {
        if (!this.getTokenByType(this.ACCESS_TOKEN_TYPE)) {
            return false;
        }
        var claims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);
        if (!(claims && claims.hasOwnProperty('nbf') && claims.hasOwnProperty('exp'))) {
            return false;
        }
        return true;
    };
    TokenService.prototype.hasValidToken = function (validAtTimestamp) {
        if (!this.hasToken()) {
            return false;
        }
        var claims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);
        var now = validAtTimestamp || Date.now();
        var notBeforeMSec = claims.nbf * 1000;
        var expiresAtMSec = claims.exp * 1000;
        var marginMSec = 1000 * 60 * 10; // 10 Minutes
        // Substract margin, because browser time could be a bit in the past
        if (notBeforeMSec - marginMSec > now) {
            return false;
        }
        if (expiresAtMSec < now) {
            return false;
        }
        return true;
    };
    TokenService.prototype.getClaimsByTokenType = function (tokenType) {
        var cachedClaims = this.storageService.getItem('cached-' + tokenType + '-claims');
        if (!cachedClaims) {
            var token = this.getTokenByType(tokenType);
            if (token) {
                var claims = this.convertToClaims(token);
                this.storageService.setItem('cached-' + tokenType + '-claims', claims);
                return claims;
            }
            else {
                // todo: exception catcher
            }
        }
        return cachedClaims;
    };
    TokenService.prototype.getTokenByType = function (tokenType) {
        return this.storageService.getItem(tokenType + 'Token');
    };
    TokenService.prototype.clearTokens = function () {
        this.storageService.removeItem('cached-id-claims');
        this.storageService.removeItem('cached-access-claims');
        this.storageService.removeItem('idToken');
        this.storageService.removeItem('accessToken');
    };
    TokenService.prototype.isAutoAlertUser = function () {
        var currentClaims = this.getClaimsByTokenType(this.ID_TOKEN_TYPE);
        // If we don't have an id token try to find the user name in the access token
        if (!currentClaims) {
            currentClaims = this.getClaimsByTokenType(this.ACCESS_TOKEN_TYPE);
        }
        if (currentClaims) {
            return currentClaims.hasOwnProperty('AA_UserName');
        }
        else {
            return false;
        }
    };
    TokenService.prototype.isMotoFuzeUser = function () {
        var currentClaims = this.getClaimsByTokenType(this.ID_TOKEN_TYPE);
        if (currentClaims) {
            return currentClaims.hasOwnProperty('MF_UserName');
        }
        else {
            return false;
        }
    };
    return TokenService;
}());
exports.TokenService = TokenService;
