"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var DealSheetClientService = /** @class */ (function (_super) {
    __extends(DealSheetClientService, _super);
    function DealSheetClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    DealSheetClientService.prototype.UserDealSheetHistoryGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/DealSheet/History", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    DealSheetClientService.prototype.UserDealSheetHistoryGET = function () {
        return this.UserDealSheetHistoryGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param model undefined
     */
    DealSheetClientService.prototype.CalculatePaymentsPOSTResponse = function (model) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = model;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealSheet/CalculatePayments", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param model undefined
     */
    DealSheetClientService.prototype.CalculatePaymentsPOST = function (model) {
        return this.CalculatePaymentsPOSTResponse(model).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    DealSheetClientService.prototype.GetDealHistoryByEntityidPOSTResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/DealSheet/GetDealHistory/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    DealSheetClientService.prototype.GetDealHistoryByEntityidPOST = function (entityId) {
        return this.GetDealHistoryByEntityidPOSTResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `DealSheetClientService.CustomerEngagementHistoryGETParams` containing the following parameters:
     *
     * - `lastViewedDate`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * @return Success
     */
    DealSheetClientService.prototype.CustomerEngagementHistoryGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.lastViewedDate != null)
            __params = __params.set('lastViewedDate', params.lastViewedDate.toString());
        if (params.dateTo != null)
            __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null)
            __params = __params.set('dateFrom', params.dateFrom.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/DealSheet/CustomerEngagementHistory", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `DealSheetClientService.CustomerEngagementHistoryGETParams` containing the following parameters:
     *
     * - `lastViewedDate`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * @return Success
     */
    DealSheetClientService.prototype.CustomerEngagementHistoryGET = function (params) {
        return this.CustomerEngagementHistoryGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    DealSheetClientService.prototype.CustomerEngagementsByEntityPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealSheet/CustomerEngagementsByEntity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    DealSheetClientService.prototype.CustomerEngagementsByEntityPOST = function (request) {
        return this.CustomerEngagementsByEntityPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    DealSheetClientService.prototype.HasReviewProDealerGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/DealSheet/HasReviewProDealer", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    DealSheetClientService.prototype.HasReviewProDealerGET = function () {
        return this.HasReviewProDealerGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    DealSheetClientService.prototype.CustomerEngagementDashboardPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealSheet/CustomerEngagementDashboard", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    DealSheetClientService.prototype.CustomerEngagementDashboardPOST = function (request) {
        return this.CustomerEngagementDashboardPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    DealSheetClientService.prototype.GetCustomerEngagementLastViewedGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/DealSheet/CustomerEngagementLastViewed", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    DealSheetClientService.prototype.GetCustomerEngagementLastViewedGET = function () {
        return this.GetCustomerEngagementLastViewedGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    DealSheetClientService.prototype.UpdateCustomerEngagementLastViewedPOSTResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealSheet/CustomerEngagementLastViewed", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    DealSheetClientService.prototype.UpdateCustomerEngagementLastViewedPOST = function () {
        return this.UpdateCustomerEngagementLastViewedPOSTResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    DealSheetClientService.UserDealSheetHistoryGETPath = '/api/DealSheet/History';
    DealSheetClientService.CalculatePaymentsPOSTPath = '/api/DealSheet/CalculatePayments';
    DealSheetClientService.GetDealHistoryByEntityidPOSTPath = '/api/DealSheet/GetDealHistory/{entityId}';
    DealSheetClientService.CustomerEngagementHistoryGETPath = '/api/DealSheet/CustomerEngagementHistory';
    DealSheetClientService.CustomerEngagementsByEntityPOSTPath = '/api/DealSheet/CustomerEngagementsByEntity';
    DealSheetClientService.HasReviewProDealerGETPath = '/api/DealSheet/HasReviewProDealer';
    DealSheetClientService.CustomerEngagementDashboardPOSTPath = '/api/DealSheet/CustomerEngagementDashboard';
    DealSheetClientService.GetCustomerEngagementLastViewedGETPath = '/api/DealSheet/CustomerEngagementLastViewed';
    DealSheetClientService.UpdateCustomerEngagementLastViewedPOSTPath = '/api/DealSheet/CustomerEngagementLastViewed';
    DealSheetClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DealSheetClientService_Factory() { return new DealSheetClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: DealSheetClientService, providedIn: "root" });
    return DealSheetClientService;
}(base_service_1.BaseService));
exports.DealSheetClientService = DealSheetClientService;
