import angular from 'angular';
import { legacyUrlMappings } from './legacy-url-mappings'
import { importKendo } from '../core/report-routes.config';
import { DoNotCallEmailComponent } from '../components/admin/do-not-call-email/do-not-call-email.component';
import { AdminTemplatesComponent } from '../components/admin/admin-templates/admin-templates.component';
import { AdminSettingsComponent } from '../components/admin/admin-settings.component';

export const routerStates = [
    {
        name: 'opportunitiesAlert',
        url: '/alert-desk/opportunities/alert?dealerId'
    },
    {
        name: 'opportunitiesFlexAlert',
        url: '/alert-desk/opportunities/flex?dealerId'
    },
    {
        name: 'opportunitiesAppointment',
        url: '/alert-desk/opportunities/appointment?dealerId'
    },
    {
        name: 'opportunitiesService',
        url: '/alert-desk/opportunities/service?dealerId'
    },
    {
        name: 'opportunitiesMileage',
        url: '/alert-desk/opportunities/mileage?dealerId'
    },
    {
        name: 'opportunitiesWarranty',
        url: '/alert-desk/opportunities/warranty?dealerId'
    },
    {
        name: 'opportunitiesContractEnd',
        url: '/alert-desk/opportunities/contract-end?dealerId'
    },
    {
        name: 'opportunitiesInMarket',
        url: '/alert-desk/opportunities/in-market?dealerId'
    },
    {
        name: 'opportunitiesInMarketEngaged',
        url: '/alert-desk/opportunities/in-market-engaged?dealerId'
    },
    {
        name: 'opportunitiesRetail',
        url: '/alert-desk/opportunities/retail?dealerId'
    },
    {
        name: 'opportunitiesLease',
        url: '/alert-desk/opportunities/lease?dealerId'
    },
    {
        name: 'activities',
        url: '/alert-desk/activities'
    },
    {
        name: 'activitiesAgenda',
        url: '/alert-desk/activities/agenda?dealerId'
    },
    {
        name: 'conquests',
        url: '/alert-desk/conquests'
    },
    {
        name: 'preOwnedManager',
        url: '/alert-desk/pre-owned-manager'
    },
    {
        name: 'serviceManager',
        url: '/alert-desk/service-manager'
    },
    {
        name: 'financeInsuranceManager',
        url: '/alert-desk/finance-insurance-manager'
    },
    {
        name: 'inventoryDashboard',
        url: '/inventory-dashboard'
    },
    {
        name: 'dataEntry',
        abstract: true,
        url: '/data-entry',
        component: 'dataEntryFrame',
        resolve: {
            kendo: importKendo,
        }
    },
    {
        name: 'dataEntry.prices',
        url: '/prices',
        component: 'dataEntryPrices',
    },
    {
        name: 'dataEntry.rates',
        url: '/rates',
        component: 'dataEntryRates',
    },
    {
        name: 'dataEntry.rebates',
        url: '/rebates',
        component: 'dataEntryRebates',
    },
    {
        name: 'dataEntry.tradeIns',
        url: '/trade-ins',
        component: 'dataEntryTradeIns',
    },
    {
        name: 'dataEntry.alerts',
        url: '/alerts',
        component: 'dataEntryAlerts',
    },
    {
        name: 'dataEntry.replacements',
        url: '/replacements',
        component: 'dataEntryReplacementOffers',
    },
    {
        name: 'settings',
        url: '/admin/settings',
        onExit: function (userService) {
            "ngInject";
            // This is specifically for the Lead Management "leadAssignOwnerAccess" module.
            // If the user changed the value, we need the user's new set of DealerModules.
            return userService.getUserProfile(true);
        }
    },
    {
        name: 'newSettings',
        url: '/admin/dealer-settings',
        component: AdminSettingsComponent,
    },
    {
        name: 'users',
        url: '/admin/users'
    },
    {
        name: 'roles',
        url: '/admin/roles',
        component: 'adminRoles',
    },
    {
        name: 'notifications',
        url: '/admin/notifications'
    },
    {
        name: 'adminReportSubscriptions',
        url: '/adminReportSubscriptions',
        component: 'adminReportSubscriptions',
    },
    {
        name: 'pandoIntegration',
        url: '/admin/pando-integration'
    },
    {
        name: 'doNotCallEmailList',
        url: '/admin/do-not-call-email-list',
        component: DoNotCallEmailComponent
    },
    {
        name: 'outreachTemplates',
        url: '/admin/outreach-templates',
        component: AdminTemplatesComponent
    },
    {
        name: 'creditConvertEstimator',
        url: '/admin/creditconvert'
    },
    {
        name: 'accessRights',
        url: '/admin/access-rights'
    },
    {
        name: 'employees',
        url: '/admin/employees'
    },
    {
        name: 'userProfile',
        url: '/user/profile',
    },
    {
        name: 'about',
        access: 'public',
        url: '/about'
    },
    {
        name: 'help',
        access: 'public',
        url: '/about/contact-us'
    },
    {
        name: 'contactUs',
        access: 'public',
        url: '/about/contact-us'
    },
    {
        name: 'wishList',
        url: '/alert-desk/wish-list',
        component: 'wishList',
        resolve: {
            kendo: importKendo,
        }
    },
    {
        name: 'potentialNLO',
        url: '/alert-desk/ai-manager',
        component: 'possibleNlo',
        resolve: {
            kendo: importKendo,
        }
    }
];

for (const state of routerStates) {
    // Add on legacyUrl if it exists
    state.legacyUrl = legacyUrlMappings[state.name];
}

angular.module('AaApp.Opp').constant('routerStates', routerStates);
