/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrintOfferDealerStatusDto } from '../models/print-offer-dealer-status-dto';
import { PrintOfferBatchDto } from '../models/print-offer-batch-dto';
@Injectable({
  providedIn: 'root',
})
class PrintOfferBatchClientService extends __BaseService {
  static readonly OpenBatchPOSTPath = '/api/PrintOfferBatch/OpenBatch';
  static readonly GetDealerStatusesGETPath = '/api/PrintOfferBatch/GetDealerStatuses';
  static readonly ByIdGETPath = '/api/PrintOfferBatch/{id}';
  static readonly ByIdDELETEPath = '/api/PrintOfferBatch/{id}';
  static readonly GetManyGETPath = '/api/PrintOfferBatch/GetMany';
  static readonly PUTPath = '/api/PrintOfferBatch';
  static readonly POSTPath = '/api/PrintOfferBatch';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityIds undefined
   */
  OpenBatchPOSTResponse(entityIds?: Array<string>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityIds;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PrintOfferBatch/OpenBatch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityIds undefined
   */
  OpenBatchPOST(entityIds?: Array<string>): __Observable<null> {
    return this.OpenBatchPOSTResponse(entityIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetDealerStatusesGETResponse(): __Observable<__StrictHttpResponse<Array<PrintOfferDealerStatusDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PrintOfferBatch/GetDealerStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PrintOfferDealerStatusDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDealerStatusesGET(): __Observable<Array<PrintOfferDealerStatusDto>> {
    return this.GetDealerStatusesGETResponse().pipe(
      __map(_r => _r.body as Array<PrintOfferDealerStatusDto>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: string): __Observable<__StrictHttpResponse<PrintOfferBatchDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PrintOfferBatch/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintOfferBatchDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: string): __Observable<PrintOfferBatchDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as PrintOfferBatchDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PrintOfferBatch/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: string): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<Array<PrintOfferBatchDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PrintOfferBatch/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PrintOfferBatchDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<string>): __Observable<Array<PrintOfferBatchDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<PrintOfferBatchDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: PrintOfferBatchDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PrintOfferBatch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: PrintOfferBatchDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: PrintOfferBatchDto): __Observable<__StrictHttpResponse<PrintOfferBatchDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PrintOfferBatch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintOfferBatchDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: PrintOfferBatchDto): __Observable<PrintOfferBatchDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as PrintOfferBatchDto)
    );
  }
}

module PrintOfferBatchClientService {
}

export { PrintOfferBatchClientService }
