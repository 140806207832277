
/* injects from baggage-loader */
require('./customer-email.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('customerEmail', {
        templateUrl: '/app/components/deal-sheet/customer-email.component.html',
        controller: CustomerEmailController,
        bindings: {
            permissions: '<',
            opportunity: '<',
            email: '<',
            displayTextKey: '<',
            doNotEmail: '<',
            countryId: '<',
            openDate: '<',
            lastTouchDate: '<',
            onEmailSend: '&'
        }
});

/* @ngInject */
function CustomerEmailController($q, $translate, dealSheetService, eulaModalService, legacyService, dealerService, featureToggleService, templateService, translateErrorKeys, logger) {
    var $ctrl = this;

    $ctrl.blankTemplateId;
    $ctrl.scheduleModalParamObject;
    $ctrl.showEmailLink = false;
    $ctrl.isTouchpointOk = true;

    $ctrl.getEmailTemplateUrl = getEmailTemplateUrl;
    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;
    $ctrl.displayText;
    $ctrl.preEmailValidation = preEmailValidation;

    function init() {
        $ctrl.blankTemplateId = 16720;
        eulaModalService.isEmailEnabled().then(isEnabled => $ctrl.showEmailLink = isEnabled);
    }

    function onChanges(changes) {
        if (changes.displayTextKey || changes.email) {
            if ($ctrl.displayTextKey) {
                $translate($ctrl.displayTextKey).then(value => {
                    $ctrl.displayText = value;
                })
            } else if ($ctrl.email) {
                $ctrl.displayText = $ctrl.email.value;
            }
        }

        if ((changes.countryId && changes.countryId.currentValue || changes.openDate && changes.openDate.currentValue
            || changes.lastTouchDate && changes.lastTouchDate.currentValue) && $ctrl.countryId && $ctrl.openDate && $ctrl.lastTouchDate) {

            var twoYearsAgo = new Date();
            twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
            $ctrl.isTouchpointOk = ((new Date($ctrl.lastTouchedDate) > twoYearsAgo)
                                            || (new Date($ctrl.openDate) > twoYearsAgo)
                                            || ($ctrl.countryId != 2)); //Canada Only
        }
    }

    function getEmailTemplateUrl() {
        $ctrl.url = legacyService.getPrintTemplateUrl($ctrl.opportunity, $ctrl.blankTemplateId, true);

        return preEmailValidation();
        //return $q.resolve(true);
    }

    function preEmailValidation(){
        return $q.all([
            eulaModalService.validateEula(),
            templateService.validateMaxEmailsPerDay($ctrl.opportunity.id)
        ]).then(function(response){
            var acceptedEula = response[0];
            var templateServiceResponse = response[1];

            //Shouldn't there be a warning message for EULA not accepted?
            if (!templateServiceResponse.data.success){
                $translate([translateErrorKeys.maxEmailsExceeded], { maxEmailsPerEntityPerDay: templateServiceResponse.data.maxEmailsPerEntityPerDay }).then(function(translation){
                    logger.warning(
                        translation.maxEmailsExceeded,
                        null,
                        null
                    );
                });
            }
            if ($ctrl.opportunity.customer.doNotEmail){
                logger.warning("doNotEmailCheckedWarning");
            }

            return acceptedEula && templateServiceResponse.data.success && !$ctrl.opportunity.customer.doNotEmail;
        });
    }

}
