"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var recent_deal_sheet_service_1 = require("../../../services/recent-deal-sheet.service");
var RecentDealSheetsComponent = /** @class */ (function () {
    function RecentDealSheetsComponent(dealSheetService, recentDealSheetService) {
        this.dealSheetService = dealSheetService;
        this.recentDealSheetService = recentDealSheetService;
        this.visible = false;
        this.visibleChange = new core_1.EventEmitter();
        this.recentDealSheets = [];
    }
    RecentDealSheetsComponent.prototype.toggleRecentDealSheets = function () {
        this.visible = !this.visible;
        if (this.visible) {
            this.busy = this.getRecentDealSheets();
        }
        this.visibleChange.emit(this.visible);
    };
    RecentDealSheetsComponent.prototype.openDealSheet = function (dealSheetId) {
        this.visible = false;
        this.dealSheetService.openDealSheetModal(dealSheetId);
    };
    RecentDealSheetsComponent.prototype.getRecentDealSheets = function () {
        var _this = this;
        return this.recentDealSheetService.getRecentDealSheets()
            .then(function (recentDealSheetData) {
            _this.recentDealSheets = recentDealSheetData;
            return recentDealSheetData;
        });
    };
    RecentDealSheetsComponent.prototype.getAlertObjects = function (dealSheet) {
        return dealSheet.alerts.map(function (a) {
            return {
                type: a
            };
        });
    };
    return RecentDealSheetsComponent;
}());
exports.RecentDealSheetsComponent = RecentDealSheetsComponent;
