"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var DealerSettingClientService = /** @class */ (function (_super) {
    __extends(DealerSettingClientService, _super);
    function DealerSettingClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.ByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/DealerSetting/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.ByDealeridGET = function (dealerId) {
        return this.ByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.GetDealerSmtpSettingByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/DealerSetting/GetDealerSmtpSetting/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.GetDealerSmtpSettingByDealeridGET = function (dealerId) {
        return this.GetDealerSmtpSettingByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     */
    DealerSettingClientService.prototype.DealerSettingChangedPOSTResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (dealerId != null)
            __params = __params.set('dealerId', dealerId.toString());
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealerSetting/DealerSettingChanged", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     */
    DealerSettingClientService.prototype.DealerSettingChangedPOST = function (dealerId) {
        return this.DealerSettingChangedPOSTResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.GetDealerOutboundEmailProviderInfoByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/DealerSetting/GetDealerOutboundEmailProviderInfo/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.GetDealerOutboundEmailProviderInfoByDealeridGET = function (dealerId) {
        return this.GetDealerOutboundEmailProviderInfoByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.GetDealerSettingAllByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/DealerSetting/GetDealerSettingAll/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerSettingClientService.prototype.GetDealerSettingAllByDealeridGET = function (dealerId) {
        return this.GetDealerSettingAllByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dsa undefined
     * @return Success
     */
    DealerSettingClientService.prototype.UpdateDealerSettingAllPOSTResponse = function (dsa) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = dsa;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealerSetting/UpdateDealerSettingAll", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param dsa undefined
     * @return Success
     */
    DealerSettingClientService.prototype.UpdateDealerSettingAllPOST = function (dsa) {
        return this.UpdateDealerSettingAllPOSTResponse(dsa).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    DealerSettingClientService.prototype.SendTestAdfEmailPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealerSetting/SendTestAdfEmail", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    DealerSettingClientService.prototype.SendTestAdfEmailPOST = function (request) {
        return this.SendTestAdfEmailPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `DealerSettingClientService.GetTextNumbersByDealeridGETParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `stateCode`:
     *
     * - `areaCode`:
     *
     * @return Success
     */
    DealerSettingClientService.prototype.GetTextNumbersByDealeridGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.stateCode != null)
            __params = __params.set('stateCode', params.stateCode.toString());
        if (params.areaCode != null)
            __params = __params.set('areaCode', params.areaCode.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/DealerSetting/GetTextNumbers/" + params.dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `DealerSettingClientService.GetTextNumbersByDealeridGETParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `stateCode`:
     *
     * - `areaCode`:
     *
     * @return Success
     */
    DealerSettingClientService.prototype.GetTextNumbersByDealeridGET = function (params) {
        return this.GetTextNumbersByDealeridGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    DealerSettingClientService.prototype.UseLegacyDealerSettingsPageGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/DealerSetting/UseLegacyDealerSettings", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @return Success
     */
    DealerSettingClientService.prototype.UseLegacyDealerSettingsPageGET = function () {
        return this.UseLegacyDealerSettingsPageGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param inventoryDealerSettingDto undefined
     * @return Success
     */
    DealerSettingClientService.prototype.UpdateInventoryDealerSettingByDealerIdAsyncPOSTResponse = function (inventoryDealerSettingDto) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = inventoryDealerSettingDto;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/DealerSetting/UpdateInventoryDealerSettingByDealerId", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param inventoryDealerSettingDto undefined
     * @return Success
     */
    DealerSettingClientService.prototype.UpdateInventoryDealerSettingByDealerIdAsyncPOST = function (inventoryDealerSettingDto) {
        return this.UpdateInventoryDealerSettingByDealerIdAsyncPOSTResponse(inventoryDealerSettingDto).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    DealerSettingClientService.ByDealeridGETPath = '/api/DealerSetting/{dealerId}';
    DealerSettingClientService.GetDealerSmtpSettingByDealeridGETPath = '/api/DealerSetting/GetDealerSmtpSetting/{dealerId}';
    DealerSettingClientService.DealerSettingChangedPOSTPath = '/api/DealerSetting/DealerSettingChanged';
    DealerSettingClientService.GetDealerOutboundEmailProviderInfoByDealeridGETPath = '/api/DealerSetting/GetDealerOutboundEmailProviderInfo/{dealerId}';
    DealerSettingClientService.GetDealerSettingAllByDealeridGETPath = '/api/DealerSetting/GetDealerSettingAll/{dealerId}';
    DealerSettingClientService.UpdateDealerSettingAllPOSTPath = '/api/DealerSetting/UpdateDealerSettingAll';
    DealerSettingClientService.SendTestAdfEmailPOSTPath = '/api/DealerSetting/SendTestAdfEmail';
    DealerSettingClientService.GetTextNumbersByDealeridGETPath = '/api/DealerSetting/GetTextNumbers/{dealerId}';
    DealerSettingClientService.UseLegacyDealerSettingsPageGETPath = '/api/DealerSetting/UseLegacyDealerSettings';
    DealerSettingClientService.UpdateInventoryDealerSettingByDealerIdAsyncPOSTPath = '/api/DealerSetting/UpdateInventoryDealerSettingByDealerId';
    DealerSettingClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DealerSettingClientService_Factory() { return new DealerSettingClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: DealerSettingClientService, providedIn: "root" });
    return DealerSettingClientService;
}(base_service_1.BaseService));
exports.DealerSettingClientService = DealerSettingClientService;
