/* global window document */
import $ from 'jquery'
import './autoalert.redactor.button.scss'

if (!window.RedactorPlugins)
    window.RedactorPlugins = {};

window.RedactorPlugins.button = {
    buttonModalBody:
    '<section id="redactor-modal-button-insert">'
    + '<div>Buttons will only be rendered in an email message. Any buttons will not be visible in PDF output.</div>'
    + '<form id="modal-form" method="get" action="">'
    + '<label>Link</label>'
    + '<input type="text" class="redactor_input" required="" name="redactor_button_url" id="redactor_button_url" />'
    + '<label>Text</label>'
    + '<input type="text" class="redactor_input" required="" name="redactor_button_text" id="redactor_button_text" />'
    + '</form>'
    + '</section>',
    buttonNewModalFooter:
    '<footer>'
    + '<button class="redactor_modal_btn redactor_btn_modal_close">Cancel</button>'
    + '<button id="redactor_insert_button_btn" class="redactor_modal_btn redactor_modal_action_btn">Insert</button>'
    + '</footer>',
    buttonEditModalFooter:
    '<footer>'
    + '<button class="redactor_modal_btn redactor_modal_delete_btn" id="redactor_button_delete_btn">Delete</button>'
    + '<button class="redactor_modal_btn redactor_btn_modal_close">Cancel</button>'
    + '<button id="redactor_insert_button_btn" class="redactor_modal_btn redactor_modal_action_btn">Save</button>'
    + '</footer>',
    init: function () {
        var $redactor = this;
        var buttonName = 'button';
        this.buttonAdd('button', 'Button', this.buttonButtonClick);
        this.buttonTagToActiveState(buttonName, 'div.redactor-button a');

        this.$editor.on("click", "div.redactor-button table", function () {
            var $button = $(this).closest(".redactor-button");
            $redactor.linkObserverTooltipClose(false);
            if ($button.closest("#redactor-button-box").length > 0) {
                return false;
            }
            $redactor.buttonEditControl($button);
            return false;
        });

        $(document).on('click.redactor-button-edit-hide', $.proxy(this.buttonEditControlHide, this));
        this.$editor.on('click.redactor-button-edit-hide', $.proxy(this.buttonEditControlHide, this));
        $(document).on('keydown.redactor-button-edit-hide', $.proxy(this.buttonEditControlHide, this));
    },
    buttonButtonClick: function () {
        this.selectionSave();

        this.$existingButton = $(this.getParent()).next("div.redactor-button");
        var inEditMode = this.$existingButton.length == 1;

        var modalTitle = inEditMode ? "Edit Button" : "Insert Button";
        var content = this.buttonModalBody + (inEditMode ? this.buttonEditModalFooter : this.buttonNewModalFooter);
        this.modalInit(modalTitle, content, 460, $.proxy(function () {
            $('#redactor_button_url').focus();
            if (inEditMode) {
                $("#redactor_button_url").val(this.$existingButton.find("a").attr("href"));
                $("#redactor_button_text").val(this.$existingButton.find("a").text());
            }

            $('#redactor_insert_button_btn').click($.proxy(this.buttonInsert, this));
            $('#redactor_button_delete_btn').click($.proxy(this.buttonRemove, this));
        }, this));
    },
    buttonEditControl: function ($button) {
        var buttonBox = $('<span id="redactor-button-box" data-redactor="verified">');
        buttonBox.css({
            position: 'relative',
            display: 'block',
            lineHeight: 0,
            margin: '0 auto',
            outline: '1px dashed rgba(0, 0, 0, .6)'
        });
        buttonBox.attr('contenteditable', false);

        buttonBox.css({
            marginTop: $button[0].style.marginTop,
            marginBottom: $button[0].style.marginBottom,
            marginLeft: $button[0].style.marginLeft,
            marginRight: $button[0].style.marginRight
        });
        $button.css('margin', '');
        $button.css('opacity', .5).after(buttonBox);

        // editter
        var $buttonEditter = $('<span id="redactor-button-editter" data-redactor="verified">' + this.opts.curLang.edit + '</span>');
        $buttonEditter.css({
            position: 'absolute',
            zIndex: 5,
            top: '50%',
            left: '50%',
            marginTop: '-11px',
            marginLeft: '-18px',
            lineHeight: 1,
            backgroundColor: '#000',
            color: '#fff',
            fontSize: '11px',
            padding: '7px 10px',
            cursor: 'pointer'
        });
        $buttonEditter.attr('contenteditable', false);
        $buttonEditter.on('click', $.proxy(this.buttonButtonClick, this));
        buttonBox.append($buttonEditter);
        buttonBox.append($button);

        return false;
    },
    buttonEditControlHide: function () {
        var buttonBox = this.$editor.find('#redactor-button-box');
        if (buttonBox.length == 0) {
            return;
        }

        this.$editor.find('#redactor-button-editter').remove();

        buttonBox.find('a').css({
            marginTop: buttonBox[0].style.marginTop,
            marginBottom: buttonBox[0].style.marginBottom,
            marginLeft: buttonBox[0].style.marginLeft,
            marginRight: buttonBox[0].style.marginRight
        });

        buttonBox.css('margin', '');
        buttonBox.find('.redactor-button').css('opacity', '');
        buttonBox.replaceWith(function () {
            return $(this).contents();
        });

        this.sync()
    },
    buttonInsert: function () {
        this.selectionRestore();
        var target = 'target="_blank"';
        var link = $('#redactor_button_url').val();
        var text = $('#redactor_button_text').val();
        var pattern = '((xn--)?[a-z0-9]+(-[a-z0-9]+)*\\.)+[a-z]{2,}';
        var re = new RegExp('^(http|ftp|https)://' + pattern, 'i');
        var re2 = new RegExp('^' + pattern, 'i');

        if (link.search(re) == -1 && link.search(re2) == 0 && this.opts.linkProtocol) {
            link = this.opts.linkProtocol + link;
        }
        text = text.replace(/<|>/g, '');
        if (this.$existingButton.length == 1) {
            this.$existingButton.find("a").attr("href", link).text(text);
        } else {
            var html = '<div class="redactor-button" contenteditable="false">' +
                '<table align="center" cellpadding="0" cellspacing="0">' +
                '<tr>' +
                '<td align="center">' +
                '<a href="' + link + '"' + target + ' contenteditable="false">' +text + '</a>'
            '</td>' +
                '</tr>' +
                '</table>' +
                '</div>';
            this.insertHtmlAdvanced(html, true);
        }
        this.modalClose();
        this.sync();
    },
    buttonRemove: function () {
        this.selectionRestore();
        var $button = this.$existingButton;
        $button.remove();
        this.modalClose();
        this.sync();
    }
};
