<div class="panel panel-default dark" [hidden]="!tagDataType1.length && !tagDataType2.length">
    <div class="panel-heading">
        <span translate="taggedOpportunities"></span>
        <span class="cursor dashboard-widget-header-item pull-right">
            <icon title="{{'refreshContent' | translate}}" (click)="refresh(true);" class="icon aa-icon-refresh"></icon>
        </span>
    </div>
    <div class="panel-body" style="position:relative">
        <div class="cg-busy-widget-container"
             cg-busy="{promise:widgetDataPromise,backdrop:false}">
        </div>
        <div style="position:relative">
            <div class="tag-wrapper" *ngFor="let tag of tagDataType2; let i = index">
                <div class="tag-label">
                    <span [ngClass]="{'placeholder': i != 0}" translate="programsAndIncentives"></span>
                </div>
                <div class="tag-container tagTypeTwo">
                    <div (click)="goToOppSearch(tag.tagID)">
                        <div class="tag-int">{{tag.count}}</div>
                        <div class="description-wrapper">
                            <div class="tag-description">{{tag.tagText}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tag-wrapper" *ngFor="let tag of tagDataType1; let i = index">
                <div class="tag-label">
                    <span [ngClass]="{'placeholder': i != 0}" translate="customTags"></span>
                </div>
                <div class="tag-container tagTypeOne">
                    <div (click)="goToOppSearch(tag.tagID)">
                        <div class="tag-int">{{tag.count}}</div>
                        <div class="description-wrapper">
                            <div class="tag-description">{{tag.tagText}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>