"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var locale_currency_pipe_1 = require("../filters/locale-currency.pipe");
var dealer_service_1 = require("./dealer.service");
var customer_service_1 = require("./customer.service");
var user_service_1 = require("./user.service");
var entity_client_service_1 = require("../generated/services/entity-client.service");
var user_client_service_1 = require("../generated/services/user-client.service");
var activity_client_service_1 = require("../generated/services/activity-client.service");
var entity_opportunity_client_service_1 = require("../generated/services/entity-opportunity-client.service");
var tag_client_service_1 = require("../generated/services/tag-client.service");
var credit_soft_pull_result_entity_del_client_service_1 = require("../generated/services/credit-soft-pull-result-entity-del-client.service");
var ford_credit_approval_client_service_1 = require("../generated/services/ford-credit-approval-client.service");
var vehicle_final_trade_value_temp_change_client_service_1 = require("../generated/services/vehicle-final-trade-value-temp-change-client.service");
var discrepancy_report_client_service_1 = require("../generated/services/discrepancy-report-client.service");
var change_log_client_service_1 = require("../generated/services/change-log-client.service");
var pando_claim_client_service_1 = require("../generated/services/pando-claim-client.service");
var sale_client_service_1 = require("../generated/services/sale-client.service");
var http_interceptor_options_service_1 = require("./http/http-interceptor-options.service");
var search_client_service_1 = require("../generated/services/search-client.service");
var constants_1 = require("../constants");
var log_factory_service_1 = require("./log-factory.service");
var Opportunity2Service = /** @class */ (function () {
    function Opportunity2Service(vehicleService, contractService, dealSheetService, mapUtilsService, entityClient, dealerService, customerService, userService, userClient, activityClientService, entityOpportunityClientService, creditSoftPullResultEntityDelClientService, fordCreditApprovalClientService, vehicleFinalTradeValueTempChangeClientService, discrepancyReportClientService, changeLogClientService, pandoClaimClientService, saleClientService, tagClientService, localeCurrency, httpInterceptorOptionsService, searchClientService, logFactory) {
        this.vehicleService = vehicleService;
        this.contractService = contractService;
        this.dealSheetService = dealSheetService;
        this.mapUtilsService = mapUtilsService;
        this.entityClient = entityClient;
        this.dealerService = dealerService;
        this.customerService = customerService;
        this.userService = userService;
        this.userClient = userClient;
        this.activityClientService = activityClientService;
        this.entityOpportunityClientService = entityOpportunityClientService;
        this.creditSoftPullResultEntityDelClientService = creditSoftPullResultEntityDelClientService;
        this.fordCreditApprovalClientService = fordCreditApprovalClientService;
        this.vehicleFinalTradeValueTempChangeClientService = vehicleFinalTradeValueTempChangeClientService;
        this.discrepancyReportClientService = discrepancyReportClientService;
        this.changeLogClientService = changeLogClientService;
        this.pandoClaimClientService = pandoClaimClientService;
        this.saleClientService = saleClientService;
        this.tagClientService = tagClientService;
        this.localeCurrency = localeCurrency;
        this.httpInterceptorOptionsService = httpInterceptorOptionsService;
        this.searchClientService = searchClientService;
        this.activityNotification = { triggered: false, notify: false };
        this.log = logFactory.get();
    }
    // no reference in entire app so commented out.
    // public getAllSaleTypeLocalization() {
    //    //return this.coreApiService.api.Sale.GetAllSaleTypeLocalization.GET()
    //    return this.saleClientService.GetSaleExtByEntityIdByEntityidGET().toPromise<any>()
    //        .then(function (response) {
    //            return response ? response : [];
    //        });
    // }
    Opportunity2Service.prototype.getSaleExtByEntityId = function (entityId) {
        // return coreApi.api.Sale.GetSaleExtByEntityId['{entityId}'].GET({ entityId: entityId })
        return this.saleClientService.GetSaleExtByEntityIdByEntityidGET(entityId).toPromise()
            .then(function (response) {
            return response;
        });
    };
    Opportunity2Service.prototype.getVehicleTurnPredictor = function (entityId) {
        return this.entityClient.GetVehicleTurnPredictorByEntityidGET(entityId).toPromise()
            .then(function (response) {
            return response;
        });
    };
    Opportunity2Service.prototype.getActiveNLPNoLongerOwn = function (entityId) {
        return this.entityClient.GetActiveNLPNoLongerOwnByEntityidGET(entityId).toPromise()
            .then(function (response) {
            //response.hasData = true;
            //response.data = [
            //    {
            //        communicationSourceID: 1,
            //        communicationText: 'no longer owns the car',
            //        insertDate: '07-04-2021 09:45',
            //    },
            //    {
            //        communicationSourceID: 2,
            //        communicationText: 'she told me she is not in the market, does not own the lexus anymore, and to stop calling',
            //        insertDate: '2021-06-08 12:36:02.363',
            //    }
            //];
            return response;
        });
    };
    Opportunity2Service.prototype.updateActiveNLPNoLongerOwn = function (opportunityId, agreed) {
        return this.entityClient.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETResponse({ 'entityId': opportunityId, 'agreed': agreed }).toPromise();
    };
    Opportunity2Service.prototype.getOpportunity = function (opportunityId, stockNo, calculationType, dsPermissions, moduleIds) {
        var _this = this;
        // return this.coreApiService.api.Entity.GetExt['{entityId}'].GET({ entityId: opportunityId });
        return this.entityClient.GetExtByEntityidGET(opportunityId).toPromise()
            .then(function (entityResponse) {
            return _this.dealerService.getDealerSettings(entityResponse.dealerID)
                .then(function (dealerSettings) {
                if (dealerSettings && dealerSettings.allowExpiration && _this.isExpired(entityResponse.expirationDate)) {
                    throw new Error('Deal sheet has expired');
                }
                var status;
                if (entityResponse.scheduled) {
                    status = constants_1.OpportunityStatuses.scheduled;
                }
                else if (entityResponse.isMarkedAsSold) {
                    status = constants_1.OpportunityStatuses.sold;
                }
                else if (entityResponse.closed) {
                    status = constants_1.OpportunityStatuses.closed;
                }
                else {
                    status = constants_1.OpportunityStatuses.open;
                }
                var opportunity = {
                    id: entityResponse.entityID,
                    actionAccess: entityResponse.actionAccess,
                    keyId: entityResponse.entityKeyID,
                    dealerId: entityResponse.dealerID,
                    vehicleId: entityResponse.vehicleID,
                    customerId: entityResponse.customerID,
                    type: entityResponse.entityTypeID,
                    hasExtendedWarranty: entityResponse.hasWarranty,
                    status: status,
                    countryID: entityResponse.countryID,
                    stillOwned: (entityResponse.stillOwn == null) ? true : entityResponse.stillOwn,
                    paidOff: entityResponse.paidOff,
                    watched: entityResponse.watched,
                    assignedUserId: entityResponse.ownerUserID,
                    isMarkedAsSold: entityResponse.isMarkedAsSold,
                    expirationDate: entityResponse.expirationDate,
                    entityScore: entityResponse.entityScore,
                    autoAssignAccessUserName: entityResponse.autoAssignAccessUserName,
                    autoAssignOwner: entityResponse.autoAssignOwner,
                    autoAssignAccessUser: entityResponse.autoAssignAccessUser,
                    autoAssignAccessSearch: entityResponse.autoAssignAccessSearch,
                    autoAssignAccessDate: entityResponse.autoAssignAccessDate,
                    autoAssignOwnerDate: entityResponse.autoAssignOwnerDate,
                    autoAssignOwnerSearch: entityResponse.autoAssignOwnerSearch,
                    dealerSettings: dealerSettings,
                    actionChecked: entityResponse.actionChecked,
                    onOrder: entityResponse.onOrder == null ? false : entityResponse.onOrder
                };
                if (_this.activityNotification.triggered === false) {
                    _this.activityNotification.triggered = true;
                    // this.coreApiService.api.Activity.GetNewActivityNotifications['{entityId}'].GET({ entityId: opportunity.id })
                    _this.activityClientService.GetNewActivityNotificationsByEntityidGET(opportunity.id).toPromise()
                        .then(function (notificationsResponse) {
                        if (notificationsResponse && _this.activityNotification) {
                            _this.activityNotification.notify = notificationsResponse.notification;
                        }
                    });
                }
                _this.getNonEssentialOpportunityData(opportunity, dsPermissions, moduleIds);
                var customerServicePromise = dsPermissions && dsPermissions.viewCustomer ?
                    _this.customerService.getCustomer(opportunity.id) : _this.customerService.getCustomerName(opportunity.id);
                return customerServicePromise
                    .then(function (customer) {
                    return Promise.all([
                        _this.vehicleService.getCurrentVehicle(opportunity.vehicleId),
                        _this.contractService.getCurrentContract(opportunity.id, opportunity.type),
                        _this.vehicleService.getReplacementVehicles(opportunity.id, stockNo, customer.pricingPlanId),
                        _this.getTags(opportunity.id),
                        // this.coreApiService.api.User.FullNameByEntity['{entityId}'].GET({ entityId: opportunity.id }),
                        _this.userClient.GetUserFullNameByEntityByEntityidGET(opportunity.id).toPromise() // results[3]
                    ])
                        .then(function (results) {
                        var data = {
                            customer: customer,
                            currentVehicle: results[0],
                            currentContract: results[1],
                            replacementVehicles: results[2],
                            tags: results[3],
                            fullName: results[4]
                        };
                        return _this.resolveOpportunityDetails(data, opportunity, dsPermissions);
                    });
                });
            });
        });
    };
    Opportunity2Service.prototype.getNonEssentialOpportunityData = function (opportunity, dsPermissions, moduleIds) {
        // WEBUI-7833 Non-essential api calls are made here (moved out of $q.all):
        var _this = this;
        // this.coreApiService.api.User.GetEntityUserInfo['{entityId}'].GET({ entityId: opportunity.id })
        try {
            this.userClient.GetEntityUserInfoByEntityidGET(opportunity.id).toPromise()
                .then(function (data) {
                opportunity.ownerUserInfo = data;
            })
                .catch(function (err) {
                _this.log.warn(err);
            });
        }
        catch (err) {
            this.log.warn(err);
        }
        try {
            this.getCreditSoftPullResultAsync(dsPermissions, opportunity.id)
                .then(function (data) { opportunity.creditSoftPull = data; })
                .catch(function (err) {
                _this.log.warn(err);
            });
        }
        catch (err) {
            this.log.warn(err);
        }
        try {
            this.getCreditPrescreenStatus(opportunity.id, moduleIds)
                .then(function (data) { opportunity.creditPrescreenStatus = data; })
                .catch(function (err) {
                _this.log.warn(err);
            });
        }
        catch (err) {
            this.log.warn(err);
        }
        try {
            var isFordAlertEnabled = this.userService.isDealerEnabledForModule(opportunity.dealerId, constants_1.Modules.fordAlert);
            if (isFordAlertEnabled) {
                this.getCreditPreApprovalAsync(opportunity.id)
                    .then(function (data) { opportunity.creditPreApproval = data; })
                    .catch(function (err) {
                    _this.log.warn(err);
                });
            }
            else {
                opportunity.creditPreApproval = null;
            }
        }
        catch (err) {
            this.log.warn(err);
        }
        try {
            this.getPandoClaim(opportunity.id)
                .then(function (data) {
                opportunity.pandoClaim = data;
            })
                .catch(function (err) {
                _this.log.warn(err);
            });
        }
        catch (err) {
            this.log.warn(err);
        }
    };
    Opportunity2Service.prototype.resolveOpportunityDetails = function (data, opportunity, permissions) {
        var _this = this;
        opportunity.currentContract = data.currentContract;
        opportunity.currentVehicle = data.currentVehicle;
        opportunity.currentVehicle.type = opportunity.currentContract.vehicleType;
        opportunity.currentContract.hasExtendedWarranty = opportunity.hasExtendedWarranty;
        opportunity.currentContract.paidOff = opportunity.paidOff;
        if (opportunity.currentContract.paidOff) {
            opportunity.currentContract.paymentsRemaining = 0;
            opportunity.currentContract.payoffAmount = 0;
        }
        opportunity.replacementVehicles = data.replacementVehicles;
        opportunity.replacementVehicle = opportunity.replacementVehicles.standardVehicle;
        opportunity.customer = data.customer;
        opportunity.tags = data.tags;
        opportunity.assignedUserFullName = data.fullName;
        // this.coreApiService.api.Entity.MarkAsRead.POST("'" + opportunity.id + "'");
        this.entityClient.MarkAsReadPOST('\'' + opportunity.id + '\'').toPromise();
        if (opportunity.type == constants_1.OpportunityTypes.conquest) {
            opportunity.currentContract.conquestType =
                this.isCreditConvertOpportunity(opportunity) ? constants_1.ConquestTypes.creditConvert : constants_1.ConquestTypes.notConverted;
        }
        else if (opportunity.type == constants_1.OpportunityTypes.converted) {
            opportunity.currentContract.conquestType = constants_1.ConquestTypes.converted;
        }
        var tradeValue = (opportunity.stillOwned === null || opportunity.stillOwned === true) ? opportunity.currentVehicle.tradeValue : 0;
        var equityValue = opportunity.currentContract.payoffAmount ? opportunity.currentContract.payoffAmount : 0;
        opportunity.currentContract.equity = { tradeValue: tradeValue, equityValue: tradeValue - equityValue };
        // return this.coreApiService.api.EntityOpportunity.GetOpportunitiesForEntity['{entityId}'].GET({ entityId: opportunity.id })
        return this.entityOpportunityClientService.GetOpportunitiesForEntityByEntityidGET(opportunity.id).toPromise()
            .then(function (response) {
            var alerts = [];
            for (var i = 0; i < response.length; i++) {
                var alert_1 = response[i];
                alerts.push({
                    type: alert_1.alertType,
                    typeName: _this.getAlertTypeName(alert_1.alertType)
                });
            }
            return alerts.sort(_this.byType);
        })
            .then(function (alerts) {
            opportunity.alerts = alerts;
            return opportunity;
        })
            .then(function () {
            return _this.contractService.getReplacementContracts(opportunity, permissions)
                .then(function (data) {
                opportunity.replacementContracts = data.contracts;
                opportunity.replacementContract = data.defaultContract;
                if (opportunity.replacementContract) {
                    opportunity.replacementContract.vehicleType = opportunity.replacementVehicle.type;
                }
                _this.setAlertDetails(opportunity.alerts, opportunity.currentVehicle, opportunity.currentContract, opportunity.replacementVehicle, opportunity.replacementContract);
                return opportunity;
            });
        });
    };
    Opportunity2Service.prototype.isExpired = function (dealSheetExpirationDate) {
        var todayDate = (new Date()).setHours(0, 0, 0, 0);
        var expirationDate = (new Date(dealSheetExpirationDate)).setHours(0, 0, 0, 0);
        return (todayDate > expirationDate);
    };
    Opportunity2Service.prototype.getOpportunityEntityOnly = function (opportunityId) {
        return this.entityClient.GetExtByEntityidGET(opportunityId).toPromise()
            // return this.coreApiService.api.Entity.GetExt['{entityId}'].GET({ entityId: opportunityId })
            .then(function (response) {
            var status;
            if (response.scheduled) {
                status = constants_1.OpportunityStatuses.scheduled;
            }
            else if (response.isMarkedAsSold) {
                status = constants_1.OpportunityStatuses.sold;
            }
            else if (response.closed) {
                status = constants_1.OpportunityStatuses.closed;
            }
            else {
                status = constants_1.OpportunityStatuses.open;
            }
            var opportunity = {
                id: response.entityID,
                actionAccess: response.actionAccess,
                keyId: response.entityKeyID,
                dealerId: response.dealerID,
                vehicleId: response.vehicleID,
                customerId: response.customerID,
                type: response.entityTypeID,
                hasExtendedWarranty: response.hasWarranty,
                status: status,
                stillOwned: (response.stillOwn == null) ? true : response.stillOwn,
                paidOff: response.paidOff,
                watched: response.watched,
                assignedUserId: response.ownerUserID,
                isMarkedAsSold: response.isMarkedAsSold,
                expirationDate: response.expirationDate,
                entityScore: response.entityScore,
                actionChecked: response.actionChecked
            };
            return opportunity;
        });
    };
    Opportunity2Service.prototype.getTags = function (opportunityId) {
        // return this.coreApiService.api.Tag.GetTagsForEntity['{entityId}'].GET({ entityId: opportunityId })
        return this.tagClientService.GetTagsForEntityByEntityidGET(opportunityId).toPromise()
            .then(function (response) {
            var tags = [];
            for (var i = 0; i < response.length; i++) {
                var tag = response[i];
                tag.text = tag.tagText,
                    tag.expirationDate = tag.tagExpireDate;
                tags.push(tag);
            }
            return tags;
        });
    };
    Opportunity2Service.prototype.getTagsPivotForDealers = function (selectedDealerId, flushCache) {
        // return this.coreApiService.api.Tag.GetTagsPivotForDealers.GET({ flushCache: flushCache }, { overrideDealerIds: [selectedDealerId] })
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        }
        return this.tagClientService.GetTagsPivotForDealersGET(flushCache).toPromise()
            .then(function (data) {
            return data;
        });
    };
    Opportunity2Service.prototype.resetActivityNotification = function () {
        if (this.activityNotification) {
            this.activityNotification.triggered = false;
            this.activityNotification.notify = false;
        }
    };
    Opportunity2Service.prototype.getOpportunitiesByCustomerId = function (customerId) {
        var _this = this;
        return this.entityClient.GetEntityReportByCustomerIdByCustomeridGET(customerId).toPromise()
            // return this.coreApiService.api.Entity.GetEntityReportByCustomerId['{customerId}'].GET({ customerId: customerId })
            .then(function (response) {
            var opportunities = [];
            for (var i = 0; i < response.length; i++) {
                var opportunity = response[i];
                if (opportunity) {
                    opportunities.push({
                        id: opportunity.entityID,
                        type: opportunity.entityTypeID,
                        alerts: opportunity.alerts,
                        stillOwned: (opportunity.stillOwn == null) ? true : opportunity.stillOwn,
                        assignedUserFullName: opportunity.ownerFullName,
                        status: opportunity.closed,
                        opportunityRank: opportunity.opportunityRank,
                        entityScore: opportunity.entityScore,
                        currentVehicle: {
                            type: opportunity.soldAsNew ? constants_1.VehicleTypes.new : constants_1.VehicleTypes.used,
                            makeName: opportunity.makeName,
                            yearID: opportunity.yearID == 0 ? '' : opportunity.yearID,
                            modelName: opportunity.modelName,
                            trimName: opportunity.trimName,
                            trimFullName: _this.mapUtilsService.getVehicleTrimFullName(opportunity),
                            lastServiceDate: opportunity.lastServiceDate,
                            vin: opportunity.vin
                        },
                        currentContract: {
                            saleType: opportunity.saleTypeID,
                            startDate: opportunity.dealDate,
                            soldByFullName: opportunity.soldByFullName
                        }
                    });
                }
            }
            return opportunities;
        });
    };
    Opportunity2Service.prototype.getOpportunitiesByVin = function (dealerId, vin) {
        return this.entityClient.GetEntityReportByDealerIdAndVinByDealeridByVinGET({ dealerId: dealerId, vin: vin }).toPromise()
            // return this.coreApiService.api.Entity.GetEntityReportByDealerIdAndVin['{dealerId}']['{vin}'].GET({ dealerId: dealerId, vin: vin })
            .then(function (response) {
            var data = response;
            var opportunities = [];
            for (var i = 0; i < data.length; i++) {
                opportunities.push({
                    id: data[i].entityID,
                    customerFullName: data[i].customerFullName,
                    alerts: data[i].alerts
                });
            }
            return opportunities;
        });
    };
    Opportunity2Service.prototype.getOwnerUserInfo = function (opportunityId) {
        // return this.coreApiService.api.User.GetEntityUserInfo['{entityId}'].GET({ entityId: opportunityId });
        return this.userClient.GetEntityUserInfoByEntityidGET(opportunityId).toPromise();
    };
    Opportunity2Service.prototype.addAlertsAsProperties = function (target, alerts) {
        this.clearAlerts(target);
        for (var i = 0; i < alerts.length; i++) {
            var alert_2 = alerts[i];
            for (var alertType in constants_1.AlertTypes) {
                if (constants_1.AlertTypes.hasOwnProperty(alertType)) {
                    if (alert_2.type == constants_1.AlertTypes[alertType]) {
                        if (alert_2.typeName.toLowerCase().indexOf('alert') !== -1) {
                            target[alert_2.typeName + 'Exists'] = true;
                        }
                        else {
                            target[alert_2.typeName + 'AlertExists'] = true;
                        }
                    }
                }
            }
        }
    };
    Opportunity2Service.prototype.updateAssignedUser = function (opportunityId, userId) {
        return this.entityClient.ReassignToUserIdPOSTResponse({ 'entityId': opportunityId, 'assignToUserId': userId }).toPromise();
        // return this.coreApiService.api.Entity.ReassignToUserId.POST({ "entityId": opportunityId, "assignToUserId": userId });
    };
    Opportunity2Service.prototype.markOpportunityWatched = function (opportunityId) {
        return this.entityClient.WatchByEntityidPOSTResponse(opportunityId).toPromise();
        // return this.coreApiService.api.Entity.Watch['{entityId}'].POST({}, { entityId: opportunityId });
    };
    Opportunity2Service.prototype.markOpportunityUnwatched = function (opportunityId) {
        return this.entityClient.UnwatchByEntityidPOSTResponse(opportunityId).toPromise();
        // return this.coreApiService.api.Entity.Unwatch['{entityId}'].POST({}, { entityId: opportunityId });
    };
    Opportunity2Service.prototype.reopenOpportunity = function (opportunityId) {
        return this.entityClient.ReopenByEntityidPOSTResponse(opportunityId).toPromise();
        // return this.coreApiService.api.Entity.Reopen['{entityId}'].POST({}, { entityId: opportunityId });
    };
    Opportunity2Service.prototype.submitCreditPrescreenRequest = function (opportunityId, permissions, moduleIds, dealerSettings) {
        if (permissions.requestCreditPrescreen
            && moduleIds.indexOf(constants_1.Modules.creditConvert) > -1
            && dealerSettings.useCreditSoftPull) {
            // return this.coreApiService.api.Entity.CreditPrescreenRequest['{entityId}'].POST({}, { entityId: opportunityId });
            return this.entityClient.CreditPrescreenRequestByEntityidPOSTResponse(opportunityId).toPromise();
        }
        else {
            return Promise.resolve(false);
        }
    };
    Opportunity2Service.prototype.getCreditPrescreenStatus = function (opportunityId, moduleIds) {
        if (moduleIds.indexOf(constants_1.Modules.creditConvert) > -1) {
            // return this.coreApiService.api.Entity.CreditPrescreenStatus['{entityId}'].GET({ entityId: opportunityId })
            return this.entityClient.CreditPrescreenStatusByEntityidGET(opportunityId).toPromise()
                .then(function (response) {
                return response != '' ? response : null;
            }, function () { });
        }
        else {
            return Promise.resolve(null);
        }
    };
    Opportunity2Service.prototype.getCreditSoftPullResultAsync = function (permissions, opportunityId) {
        if (permissions && permissions.requestCreditPrescreen) {
            // return this.coreApiService.api.CreditSoftPullResultEntityDel['{entityId}'].GET({ entityId: opportunityId })
            return this.creditSoftPullResultEntityDelClientService.GetCreditSoftPullResultEntityDelByEntityidGET(opportunityId).toPromise()
                .then(function (result) { return result; }, function () { });
        }
        else {
            return Promise.resolve(undefined);
        }
    };
    Opportunity2Service.prototype.getCreditPreApprovalAsync = function (opportunityId) {
        // return this.coreApiService.api.FordCreditApproval.FordCreditApprovalByEntityId['{entityId}'].GET({ entityId: opportunityId })
        return this.fordCreditApprovalClientService.FordCreditApprovalByEntityIdByEntityidGET(opportunityId).toPromise()
            .then(function (result) { return result; }, function () { });
    };
    Opportunity2Service.prototype.setAlertDetails = function (alerts, currentVehicle, currentContract, replacementVehicle, replacementContract) {
        for (var i = 0; i < alerts.length; i++) {
            var alert_3 = alerts[i];
            switch (alert_3.type) {
                case constants_1.AlertTypes.alert:
                case constants_1.AlertTypes.flexAlert:
                    this.setFlexAlertDetails(alert_3, replacementVehicle, replacementContract);
                    break;
                case constants_1.AlertTypes.service:
                    this.setServiceAlertDetails(alert_3, currentVehicle);
                    break;
                case constants_1.AlertTypes.appointment:
                    this.setAppointmentAlertDetails(alert_3, currentVehicle);
                    break;
                case constants_1.AlertTypes.contractEnd:
                    this.setContractEndAlertDetails(alert_3, currentContract);
                    break;
                case constants_1.AlertTypes.mileage:
                    this.setMileageAlertDetails(alert_3, currentContract);
                    break;
                case constants_1.AlertTypes.warranty:
                    this.setWarrantyAlertDetails(alert_3, currentVehicle, currentContract);
                    break;
                default:
                    break;
            }
            alert_3.translateKey = this.getAlertTranslateKey(alert_3);
        }
    };
    Opportunity2Service.prototype.getAlertTranslateKey = function (alert) {
        if (alert.details && !alert.details.payment
            && (alert.type == constants_1.AlertTypes.alert || alert.type == constants_1.AlertTypes.flexAlert)) {
            return alert.typeName + 'RelevantInfoNoPayment';
        }
        return alert.typeName + 'RelevantInfo';
    };
    Opportunity2Service.prototype.isCreditConvertOpportunity = function (opportunity) {
        return this.containsTag(opportunity, 'CreditConvert');
    };
    Opportunity2Service.prototype.containsTag = function (opportunity, tagText) {
        if (opportunity && opportunity.tags) {
            return opportunity.tags.some(function (tag) {
                return tag.text === tagText;
            });
        }
    };
    Opportunity2Service.prototype.byType = function (alert1, alert2) {
        if (alert1.type > alert2.type) {
            return 1;
        }
        else if (alert1.type < alert2.type) {
            return -1;
        }
        else {
            return 0;
        }
    };
    Opportunity2Service.prototype.setFlexAlertDetails = function (alert, vehicle, contract) {
        alert.details = {
            yearID: vehicle.yearID,
            makeName: vehicle.makeName,
            modelName: vehicle.modelName,
            trimName: vehicle.trimName,
            contractTerm: contract ? contract.term : null,
            payment: contract ? contract.payment : null,
            paymentDifferenceAmount: contract ? contract.paymentDifference : null
        };
    };
    Opportunity2Service.prototype.setServiceAlertDetails = function (alert, vehicle) {
        alert.details = {
            openDate: vehicle.openDate,
            eighteenMonthServiceCount: vehicle.eighteenMonthServiceCount
        };
    };
    Opportunity2Service.prototype.setAppointmentAlertDetails = function (alert, vehicle) {
        alert.details = {
            appointmentDate: vehicle.appointmentDate
        };
    };
    Opportunity2Service.prototype.setContractEndAlertDetails = function (alert, contract) {
        alert.details = {
            paymentsMade: contract.paymentsMade,
            paymentsRemaining: contract.paymentsRemaining
        };
    };
    Opportunity2Service.prototype.setMileageAlertDetails = function (alert, contract) {
        alert.details = {
            estimatedMaturityMileage: contract.estimatedMaturityMileage,
            currentMileagePenalty: contract.currentMileagePenalty,
            estimatedMileagePenalty: contract.estimatedMileagePenalty
        };
    };
    Opportunity2Service.prototype.setWarrantyAlertDetails = function (alert, vehicle, contract) {
        alert.details = {
            warrantyMonthsRemaining: vehicle.warrantyMonthsRemaining ? vehicle.warrantyMonthsRemaining : contract.warrantyMonthsRemaining,
            warrantyMilesRemaining: vehicle.warrantyMilesRemaining ? vehicle.warrantyMilesRemaining : contract.warrantyMilesRemaining
        };
    };
    Opportunity2Service.prototype.getAlertTypeName = function (alertType) {
        switch (alertType) {
            case constants_1.AlertTypes.alert:
                return 'alert';
            case constants_1.AlertTypes.flexAlert:
                return 'flexAlert';
            case constants_1.AlertTypes.inMarket:
                return 'inMarket';
            case constants_1.AlertTypes.inMarketEngaged:
                return 'inMarketEngaged';
            case constants_1.AlertTypes.contractEnd:
                return 'contractEnd';
            case constants_1.AlertTypes.service:
                return 'service';
            case constants_1.AlertTypes.appointment:
                return 'appointment';
            case constants_1.AlertTypes.mileage:
                return 'mileage';
            case constants_1.AlertTypes.warranty:
                return 'warranty';
            default:
                return '';
        }
    };
    Opportunity2Service.prototype.clearAlerts = function (target) {
        target.alertExists = null;
        target.flexAlertExists = null;
        target.serviceAlertExists = null;
        target.appointmentAlertExists = null;
        target.mileageAlertExists = null;
        target.contractEndAlertExists = null;
        target.warrantyAlertExists = null;
    };
    Opportunity2Service.prototype.logTradeValueChange = function (vehicleId, tradeValue) {
        // return this.coreApiService.api.VehicleFinalTradeValueTempChange.LogTradeValueChange.POST({ VehicleID: vehicleId, TradeValue: tradeValue });
        var params = { vehicleID: vehicleId, tradeValue: tradeValue };
        return this.vehicleFinalTradeValueTempChangeClientService.LogTradeValueChangePOSTResponse(params).toPromise();
    };
    Opportunity2Service.prototype.getChangeLogs = function (opportunityId) {
        // return this.coreApiService.api.ChangeLog['{entityId}'].GET({ entityId: opportunityId })
        return this.changeLogClientService.ByEntityidGET(opportunityId).toPromise()
            .then(function (response) {
            if (!response) {
                return null;
            }
            return response;
        });
    };
    Opportunity2Service.prototype.submitDiscrepancy = function (opportunity, discrepancy) {
        var request = {
            entityId: opportunity.id,
            reportableFieldId: discrepancy.type,
            reportableExpectedValue: discrepancy.expectedValue,
            dealerId: opportunity.dealerId,
            dealerName: opportunity.dealer.name,
            customerFullName: opportunity.customer.fullName,
            currentVehicleVin: opportunity.currentVehicle.vin,
            reportDiscrepancyVehicle: {
                yearID: opportunity.replacementVehicle.yearID,
                fullTrimName: opportunity.replacementVehicle.makeName + ' ' + opportunity.replacementVehicle.trimFullName,
                localePrice: this.localeCurrency.transform(opportunity.replacementVehicle.price)
            },
            dealSheetUrl: this.dealSheetService.getDealSheetUrl(opportunity.id),
            ReportableCurrentValues: null,
            reportableCurrentValue: null
        };
        if (discrepancy.multiple) {
            request.ReportableCurrentValues = discrepancy.currentValue;
        }
        else {
            if (discrepancy.type == constants_1.DiscrepancyTypes.tradeValue || discrepancy.type == constants_1.DiscrepancyTypes.payoffAmount) {
                request.reportableCurrentValue = this.localeCurrency.transform(discrepancy.currentValue);
            }
            else {
                request.reportableCurrentValue = discrepancy.currentValue;
            }
        }
        // return this.coreApiService.api.DiscrepancyReport.ReportDiscrepancy.POST(request);
        return this.discrepancyReportClientService.ReportDiscrepancyPOSTResponse(request).toPromise();
    };
    Opportunity2Service.prototype.addTermOverrideChangeLog = function (vehicleId, term) {
        // return this.coreApiService.api.ChangeLog.Term['{vehicleId}']['{term}'].POST({}, { vehicleId: vehicleId, term: term });
        return this.changeLogClientService.AddTermChangeLogByVehicleidByTermPOSTResponse({ vehicleId: vehicleId, term: term }).toPromise();
    };
    Opportunity2Service.prototype.addPriceOverrideChangeLog = function (vehicleId, price) {
        // return this.coreApiService.api.ChangeLog.Price['{vehicleId}']['{price}'].POST({}, { vehicleId: vehicleId, price: price });
        return this.changeLogClientService.AddPriceChangeLogByVehicleidByPricePOSTResponse({ vehicleId: vehicleId, price: price }).toPromise();
    };
    Opportunity2Service.prototype.getPandoClaim = function (opportunityId) {
        // return (this.coreApiService.api.PandoClaim.ByEntity['{entityId}'].GET({ entityId: opportunityId }))
        return this.pandoClaimClientService.ByEntityByEntityidGET(opportunityId).toPromise()
            .then(function (results) {
            return results;
        });
    };
    Opportunity2Service.prototype.hasReviewProDealer = function (entityId) {
        return this.entityClient.HasReviewProDealerByEntityidGET(entityId).toPromise()
            // return (this.coreApiService.api.Entity.HasReviewProDealer['{entityId}'].GET({ entityId: entityId }))
            .then(function (results) {
            return results;
        }, function () {
            return false;
        });
    };
    Opportunity2Service.prototype.hasGeoAlertDealer = function (entityId) {
        return this.entityClient.HasGeoAlertDealerByEntityidGET(entityId).toPromise()
            // return (this.coreApiService.api.Entity.HasGeoAlertDealer['{entityId}'].GET({ entityId: entityId }))
            .then(function (results) {
            return results;
        }, function () {
            return false;
        });
    };
    Opportunity2Service.prototype.getEntityScoreFeature = function (entityId) {
        return this.entityClient.GetEntityScoreFeatureByEntityidGET(entityId).toPromise()
            .then(function (response) {
            return response;
        });
    };
    /* BEGIN --- Unused redundent functions that were created for dealsheet lite in service but never used */
    Opportunity2Service.prototype.showIfNotNullOrEmpty = function (defaultValue, item, obj) {
        if (item != null && item != '') {
            if (obj != null) {
                return obj;
            }
            return item;
        }
        return defaultValue;
    };
    Opportunity2Service.prototype.toYesNoTypeID = function (yesNoTypeName) {
        if (yesNoTypeName == null) {
            return 0;
        }
        yesNoTypeName = yesNoTypeName.toString().toLowerCase();
        if (yesNoTypeName == 'yes') {
            return 1;
        }
        if (yesNoTypeName == 'no') {
            return 0;
        }
        return 0;
    };
    Opportunity2Service.prototype.toBoolean = function (yesNoTypeName) {
        if (yesNoTypeName == null) {
            return false;
        }
        yesNoTypeName = yesNoTypeName.toString().toLowerCase();
        if (yesNoTypeName == 'yes') {
            return true;
        }
        if (yesNoTypeName == 'no') {
            return false;
        }
        return false;
    };
    Opportunity2Service.prototype.nullIf = function (valueIfNull, defaultValue) {
        if (valueIfNull) {
            return null;
        }
        return defaultValue;
    };
    Opportunity2Service.prototype.defaultValue = function (value, defaultValue) {
        if (value == null) {
            return defaultValue;
        }
        return value;
    };
    Opportunity2Service.prototype.toSaleTypeID = function (saleType) {
        if (saleType == null) {
            return '';
        }
        saleType = saleType.toString().toLowerCase();
        if (saleType == 'lease') {
            return 1;
        }
        if (saleType == 'retail') {
            return 2;
        }
        if (saleType == 'balloon') {
            return 3;
        }
        if (saleType == 'cash') {
            return 4;
        }
        if (saleType == 'onepay lease') {
            return 5;
        }
        if (saleType == 'wholesale') {
            return 6;
        }
        if (saleType == 'incomplete') {
            return 7;
        }
        return '';
    };
    Opportunity2Service.prototype.NewUsedCpoToID = function (vehicleType) {
        if (vehicleType == null) {
            return '';
        }
        vehicleType = vehicleType.toString().toLowerCase();
        if (vehicleType == 'new') {
            return 0;
        }
        if (vehicleType == 'used') {
            return 1;
        }
        if (vehicleType == 'cpo') {
            return 2;
        }
        return '';
    };
    Opportunity2Service.prototype.toAlertTypeList = function (vw_search, replacementVehicle) {
        replacementVehicle.payment = vw_search.replacement_Payment;
        var alerts = [];
        if (vw_search.is_Alert == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.alert, 'alert', replacementVehicle);
        }
        if (vw_search.is_Appointment == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.appointment, 'appointment', replacementVehicle);
        }
        if (vw_search.is_Contract_End == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.contractEnd, 'contractEnd', replacementVehicle);
        }
        // if (vw_search.is_Conversion_Alert == "Yes") this.pushAlert(alerts, alertTypes.alert, 'alert');
        if (vw_search.is_Flex_Alert == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.flexAlert, 'flexAlert', replacementVehicle);
        }
        if (vw_search.is_In_Market == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.inMarket, 'inMarket', replacementVehicle);
        }
        if (vw_search.is_In_Market_Engaged == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.inMarketEngaged, 'inMarketEngaged', replacementVehicle);
        }
        if (vw_search.is_Mileage_Alert == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.mileage, 'mileage', replacementVehicle);
        }
        if (vw_search.is_Warranty_Alert == 'Yes') {
            this.pushAlert(alerts, constants_1.AlertTypes.warranty, 'warranty', replacementVehicle);
        }
        return alerts;
    };
    Opportunity2Service.prototype.pushAlert = function (arr, type, name, replacementVehicle) {
        arr.push({
            type: type,
            typeName: name,
            translateKey: name + 'RelevantInfo',
            details: replacementVehicle,
        });
    };
    /* END ---- Unused redundent functions that were created for dealsheet lite in service but never used */
    Opportunity2Service.prototype.getOpportunityFromViewEntityId = function (entityId, vw_SearchRow) {
        if (vw_SearchRow == null || vw_SearchRow['_EntityID'] == null) {
            entityId = (vw_SearchRow == null) ? entityId : vw_SearchRow.opportunityId;
            // return this.coreApiService.api.Search.GetSearchByEntityId['{entityId}'].GET({ entityId: entityId });
            return this.searchClientService.GetSearchByEntityIdByEntityidGET(entityId).toPromise()
                .then(function (results) { return results; });
        }
        else {
            return Promise.resolve(vw_SearchRow);
        }
    };
    Opportunity2Service.prototype.getOpportunityFromView = function (vw_SearchRow) {
        if (vw_SearchRow == null) {
            return;
        }
        var opportunity = {
            id: vw_SearchRow._EntityID,
            vehicleId: vw_SearchRow._VehicleID,
            type: vw_SearchRow._EntityTypeID,
            stillOwned: this.toBoolean(vw_SearchRow.still_Own),
            paidOff: this.toBoolean(vw_SearchRow.paid_Off),
            dealerId: vw_SearchRow._DealerID,
            customer: {
                fullName: vw_SearchRow.full_Name,
                address: {
                    streetAddress: vw_SearchRow.address,
                    streetAddress2: vw_SearchRow.address_2,
                    stateId: vw_SearchRow._StateID,
                    normalizedZipCode: vw_SearchRow.zip_Code,
                    zipCode: vw_SearchRow.zip_Code,
                    city: vw_SearchRow.city,
                    stateName: vw_SearchRow.state
                },
                pricingPlanId: null,
                alternateContacts: [],
                cellPhoneNumber: this.showIfNotNullOrEmpty(null, vw_SearchRow.cell_Phone, {
                    'contactType': 4,
                    'value': vw_SearchRow.cell_Phone,
                    'isBad': false,
                    'isPreferred': true,
                    'doNotCallStatusTypeId': 4
                }),
                'companyName': '',
                'doNotCall': this.toBoolean(vw_SearchRow.do_Not_Call),
                'doNotEmail': this.toBoolean(vw_SearchRow.do_Not_Email),
                'doNotMail': this.toBoolean(vw_SearchRow.do_Not_Mail),
                'emailAddress': this.showIfNotNullOrEmpty(null, vw_SearchRow.email, {
                    'contactType': 5,
                    'value': vw_SearchRow.email,
                    'isBad': vw_SearchRow.email != '',
                    'isPreferred': vw_SearchRow.email != ''
                }),
                'firstName': vw_SearchRow.first_Name,
                'homePhoneNumber': this.showIfNotNullOrEmpty(null, vw_SearchRow.home_Phone, {
                    'contactType': 2,
                    'value': vw_SearchRow.home_Phone,
                    'isBad': false,
                    'isPreferred': true,
                    'doNotCallStatusTypeId': 4
                }),
                'id': vw_SearchRow._CustomerID,
                'invalidContacts': [],
                'isAddressNotValid': false,
                'isFullCustomer': true,
                'lastName': vw_SearchRow.last_Name,
                'middleName': vw_SearchRow.middle_Name,
                'workPhoneNumber': this.showIfNotNullOrEmpty(null, vw_SearchRow.work_Phone, {
                    'contactType': 3,
                    'value': vw_SearchRow.work_Phone,
                    'isBad': false,
                    'isPreferred': true,
                    'doNotCallStatusTypeId': 4
                })
            },
            // "dealer": {
            //    "name": "MB Demo",
            //    "displayText": "MB Demo (MBDEMO)"
            // },
            entityScore: vw_SearchRow._Opportunity_Rank,
            assignedUserId: vw_SearchRow._OwnerUserID,
            assignedUserFullName: vw_SearchRow._Customer_Owner,
            // alerts: toAlertTypeList(vw_SearchRow),
            permissions: {
                viewContract: true,
            },
            currentContract: {
                adjustedBalance: vw_SearchRow.estimated_Payoff - vw_SearchRow.totalPenalty,
                saleType: vw_SearchRow._SaleTypeID,
                moneyFactor: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.rate : null,
                payment: vw_SearchRow.current_Payment,
                soldByName: vw_SearchRow.sold_By_Name,
                vehicleType: this.NewUsedCpoToID(vw_SearchRow.new_Used),
                paymentsMade: vw_SearchRow.payments_Made,
                paymentsRemaining: vw_SearchRow.payment_Remaining,
                // "cashPaid": null,
                allowedMileage: vw_SearchRow.lease_Total_Allowed_Miles,
                // "estimatedMaturityMileage": 51700,
                // "currentMileagePenalty": 0,
                // "estimatedMileagePenalty": 900,
                // "preferredEquity": 5350,
                // "balanceToMaturity": 13125,
                // "dispositionFee": 595,
                entityID: vw_SearchRow._EntityID,
                // "hasWarranty": false,
                bankName: vw_SearchRow.bank_Name,
                // "bankUrl": "https://new.mb-advantage.com",
                // "isCreditConvert": true,
                // "paymentMinimum": 1700,
                // "paymentMaximum": 1750,
                payoffAmount: vw_SearchRow.estimated_Payoff,
                // "payoffGoodThroughDate": null,
                lastTouchedDate: vw_SearchRow.last_Payment_Date,
                saleID: vw_SearchRow._SaleID,
                // "insertDate": "2015-04-25T01:25:57.84Z",
                // "dealerID": 1167,
                // "customerID": 38827071,
                // "vehicleID": 89569084,
                // "soldAsNew": true,
                // "mileageAtDelivery": 8,
                // "bankID": 48,
                dmsDealId: vw_SearchRow.dmS_Deal_ID,
                // "soldByID": 314698,
                term: vw_SearchRow.deal_Term,
                dealDate: vw_SearchRow.deal_Date,
                // "firstPaymentDate": "2015-04-18T00:00:00",
                lastPaymentDate: vw_SearchRow.last_Payment_Date,
                // "leaseBasePayment": 1646,
                // "leaseTotalPayment": 1790,
                capitalCost: vw_SearchRow.lease_Net_Cap_Cost,
                residualAmount: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.lease_Residual : null,
                // "leaseAnnualMiles": null,
                // "retailPayment": 0,
                financedAmount: vw_SearchRow.purchase_Amount_Financed,
                // "balloonPayment": null,
                cashDown: vw_SearchRow.cash_Down,
                apr: vw_SearchRow._SaleTypeID == 1 ? null : vw_SearchRow.rate,
                // "isNative": true,
                // "boughtExtWarranty": false,
                // "hasExtendedWarranty": false,
                paidOff: this.toYesNoTypeID(vw_SearchRow.paid_Off),
                // "equity": {
                //  "tradeValue": 10, // 62900,
                //  "equityValue": 5350
                // }
                financedOrCapCost: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.lease_Net_Cap_Cost : vw_SearchRow.purchase_Amount_Financed
            },
            currentVehicle: {
                openDate: vw_SearchRow.openDate,
                tradeValue: vw_SearchRow.trade_Value,
                trimID: vw_SearchRow._TrimID,
                yearID: vw_SearchRow._YearID,
                makeID: vw_SearchRow._MakeID,
                // imagePath: "https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
                // "imagePath": "../../../images/Capture1.png",
                // imagePath: oneImage,
                type: this.NewUsedCpoToID(vw_SearchRow.new_Used),
                // pricingPlanMsrp: vw_SearchRow.
                // "addModelToTrim": false,
                // "appointmentDate": "2018-05-04T12:00:00",
                // "dealerID": 1167,
                // "displayCodes": false,
                // "eighteenMonthServiceCount": 3,
                // "estimatedCurrentMileage": 44200,
                // "estimatedMilesPerMonth": 1140,
                // "extColorID": null,
                // "exteriorColor": "DESIGNO GRAPHITE",
                // "intColorID": null,
                // "interiorColor": null,
                // "lastOdometerReadingDate": "2018-05-04T15:24:00",
                'makeName': vw_SearchRow.current_Make_Name,
                // "mfcModelCode": null,
                'modelName': vw_SearchRow.current_Model_Name,
                // "modelNo": "E63SP",
                // "odometer": 38750,
                // "replacementTrimID": 422662,
                // "tradeMileageAdjustment": -2400,
                // "tradeSourceID": 1,
                'trimFullName': this.defaultValue(vw_SearchRow.current_Model_Name, '') + ' ' + this.defaultValue(vw_SearchRow.current_Trim_Name, ''),
                'trimName': this.defaultValue(vw_SearchRow.current_Trim_Name, ''),
                // "vehicleID": 89569084,
                // "viN8Key": "WDDHH7GB_F",
                'vin': vw_SearchRow.vin,
            },
            replacementVehicle: {
                paymentDifferenceAmount: vw_SearchRow.replacement_Payment - vw_SearchRow.current_Payment,
                type: this.NewUsedCpoToID(vw_SearchRow.replacement_New_Used),
                stockNo: vw_SearchRow.replacement_Stock_No,
                // "odometer": 0,
                // "vin": null,
                msrp: vw_SearchRow.replacement_MSRP,
                price: vw_SearchRow.replacement_MSRP,
                trimID: vw_SearchRow.replacementTrimID,
                // "daysInStock": null,
                // "interiorColor": null,
                // "exteriorColor": null,
                // "countryID": 4,
                // "countryName": "France",
                makeID: vw_SearchRow.replacementMakeID,
                makeName: vw_SearchRow.replacement_Make_Name,
                // modelID: vw_SearchRow.replacementModelID, //replacementModelID needs to be added to vw_search
                modelName: vw_SearchRow.replacement_Model_Name,
                yearID: vw_SearchRow.replacement_Model_Year,
                trimName: vw_SearchRow.replacement_Trim_Name,
                // "rawTrimName": null,
                // "mfcModelCode": "E43W4",
                // "extID": "7778055",
                // "extOptionId": "0",
                // "extFuelTypeId": 1059,
                // "cityMPG": 19,
                // "cityMPGe": null,
                // "highwayMPG": 25,
                // "highwayMPGe": null,
                // "combinedMPG": 21,
                // "batteryRange": null,
                trimFullName: vw_SearchRow.replacement_Full_Trim_Name,
            },
            replacementContract: {
                keyID: vw_SearchRow._EntityKeyID,
                saleType: this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type),
                // "saleTypeName": "Lease",
                // "bankID": 48,
                // "bankName": "Mercedes-Benz Financial",
                term: vw_SearchRow.replacement_Term,
                // "termBeforeImprovement": null,
                apr: this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 2 ? vw_SearchRow.replacement_Rate : null,
                moneyFactor: this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 1 ? vw_SearchRow.replacement_Rate : null,
                // "standardRebate": 0,
                // "loyaltyRebate": 0,
                // "conquestRebate": 0,
                // "cashOrAprRebate": 1000,
                // "cashOrApr": true,
                // "isValueAdded": false,
                // "pullForwardAmount": 5370,
                // "specialLoyaltyValue": 0,
                isOverAllowance: this.toYesNoTypeID(vw_SearchRow.is_Over_Allowance),
                financedAmount: this.nullIf(this.toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 1, vw_SearchRow.replacement_Loan_Amount),
                // "residualAmount": 35571.55,
                preferredEquity: vw_SearchRow.preferred_Equity,
                payment: vw_SearchRow.replacement_Payment,
                paymentDifference: vw_SearchRow.replacement_Payment - vw_SearchRow.current_Payment,
                // "capitalCost": 67512,
                // "paymentBeforeImprovement": null,
                // "salesTax": 8.75,
                // "isClosestTerm": true,
                isAlert: this.toYesNoTypeID(vw_SearchRow.is_Alert),
                isFlexAlert: this.toYesNoTypeID(vw_SearchRow.is_Flex_Alert),
                isConversionAlert: this.toYesNoTypeID(vw_SearchRow.is_Conversion_Alert),
            },
            alerts: []
        };
        opportunity.alerts = this.toAlertTypeList(vw_SearchRow, opportunity.replacementVehicle);
        return opportunity;
    };
    return Opportunity2Service;
}());
exports.Opportunity2Service = Opportunity2Service;
