/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TrimAlternateOfferDto } from '../models/trim-alternate-offer-dto';
@Injectable({
  providedIn: 'root',
})
class TrimAlternateOfferClientService extends __BaseService {
  static readonly ByDealeridByBasetrimidByLevelDELETEPath = '/api/TrimAlternateOffer/{dealerID}/{baseTrimID}/{level}';
  static readonly PUTPath = '/api/TrimAlternateOffer';
  static readonly POSTPath = '/api/TrimAlternateOffer';
  static readonly BulkDeletePOSTPath = '/api/TrimAlternateOffer/BulkDelete';
  static readonly BulkRemoveMappingsPOSTPath = '/api/TrimAlternateOffer/BulkRemoveMappings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TrimAlternateOfferClientService.ByDealeridByBasetrimidByLevelDELETEParams` containing the following parameters:
   *
   * - `level`:
   *
   * - `dealerID`:
   *
   * - `baseTrimID`:
   */
  ByDealeridByBasetrimidByLevelDELETEResponse(params: TrimAlternateOfferClientService.ByDealeridByBasetrimidByLevelDELETEParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/TrimAlternateOffer/${params.dealerID}/${params.baseTrimID}/${params.level}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TrimAlternateOfferClientService.ByDealeridByBasetrimidByLevelDELETEParams` containing the following parameters:
   *
   * - `level`:
   *
   * - `dealerID`:
   *
   * - `baseTrimID`:
   */
  ByDealeridByBasetrimidByLevelDELETE(params: TrimAlternateOfferClientService.ByDealeridByBasetrimidByLevelDELETEParams): __Observable<null> {
    return this.ByDealeridByBasetrimidByLevelDELETEResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: TrimAlternateOfferDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/TrimAlternateOffer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: TrimAlternateOfferDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: TrimAlternateOfferDto): __Observable<__StrictHttpResponse<TrimAlternateOfferDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TrimAlternateOffer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrimAlternateOfferDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: TrimAlternateOfferDto): __Observable<TrimAlternateOfferDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as TrimAlternateOfferDto)
    );
  }

  /**
   * @param params The `TrimAlternateOfferClientService.BulkDeletePOSTParams` containing the following parameters:
   *
   * - `trimIDs`:
   *
   * - `level`:
   *
   * - `dealerID`:
   */
  BulkDeletePOSTResponse(params: TrimAlternateOfferClientService.BulkDeletePOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.trimIDs;
    if (params.level != null) __params = __params.set('level', params.level.toString());
    if (params.dealerID != null) __params = __params.set('dealerID', params.dealerID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TrimAlternateOffer/BulkDelete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TrimAlternateOfferClientService.BulkDeletePOSTParams` containing the following parameters:
   *
   * - `trimIDs`:
   *
   * - `level`:
   *
   * - `dealerID`:
   */
  BulkDeletePOST(params: TrimAlternateOfferClientService.BulkDeletePOSTParams): __Observable<null> {
    return this.BulkDeletePOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TrimAlternateOfferClientService.BulkRemoveMappingsPOSTParams` containing the following parameters:
   *
   * - `trimIDs`:
   *
   * - `level`:
   *
   * - `dealerID`:
   */
  BulkRemoveMappingsPOSTResponse(params: TrimAlternateOfferClientService.BulkRemoveMappingsPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.trimIDs;
    if (params.level != null) __params = __params.set('level', params.level.toString());
    if (params.dealerID != null) __params = __params.set('dealerID', params.dealerID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/TrimAlternateOffer/BulkRemoveMappings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TrimAlternateOfferClientService.BulkRemoveMappingsPOSTParams` containing the following parameters:
   *
   * - `trimIDs`:
   *
   * - `level`:
   *
   * - `dealerID`:
   */
  BulkRemoveMappingsPOST(params: TrimAlternateOfferClientService.BulkRemoveMappingsPOSTParams): __Observable<null> {
    return this.BulkRemoveMappingsPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TrimAlternateOfferClientService {

  /**
   * Parameters for ByDealeridByBasetrimidByLevelDELETE
   */
  export interface ByDealeridByBasetrimidByLevelDELETEParams {
    level: number;
    dealerID: number;
    baseTrimID: number;
  }

  /**
   * Parameters for BulkDeletePOST
   */
  export interface BulkDeletePOSTParams {
    trimIDs?: Array<number>;
    level?: number;
    dealerID?: number;
  }

  /**
   * Parameters for BulkRemoveMappingsPOST
   */
  export interface BulkRemoveMappingsPOSTParams {
    trimIDs?: Array<number>;
    level?: number;
    dealerID?: number;
  }
}

export { TrimAlternateOfferClientService }
