export const TranslateErrorKeys = {
    generalErrorTitleKey: 'generalErrorTitleKey',
    generalErrorMessageKey: 'generalErrorMessageKey',
    unauthorizedAppErrorKey: 'unauthorizedAppErrorKey',
    unauthorizedPageErrorKey: 'unauthorizedPageErrorKey',
    unauthorizedAPIAccessKey: 'unauthorizedAPIAccessKey',
    unauthorizedDisabledUserKey: 'unauthorizedDisabledUserKey',
    unauthorizedLockedUserKey: 'unauthorizedLockedUserKey',
    unauthorizedNoUserProfileKey: 'unauthorizedNoUserProfileKey',
    underConstructionKey: 'underConstructionKey',
    maxEmailsExceeded: 'maxEmailsExceeded',
    invalidIPAddress: 'invalidIPAddressKey',
    generalErrorDncProcessKey: 'generalErrorDncProcessKey',
    generalErrorCreditSoftPullNoEditKey: 'generalErrorCreditSoftPullNoEditKey',
    popupBlockerEnabledErrorMessage: 'popupBlockerEnabledErrorMessage'
};
