
/* injects from baggage-loader */
require('./vehicle-type.component.html');

import angular from 'angular';
import _ from 'lodash';

(function () { 
    angular.module('AaApp.Opp').component('vehicleType', {
        templateUrl: '/app/components/deal-sheet/vehicle/vehicle-type.component.html',
        controller: VehicleTypeController,
        bindings: {
            value: '<',
            hasExtendedWarranty: '<',
            permissions: '<',
            conquestType: '<'
        }
    });

    /* @ngInject */
    function VehicleTypeController(conquestTypes, vehicleTypes) {
        var $ctrl = this;

        $ctrl.vehicleTypeKeys;
        $ctrl.conquestTypes = conquestTypes;
        $ctrl.$onInit = init;

        function init() {
            $ctrl.vehicleTypeKeys = _.invert(vehicleTypes);
        }
    }
})();
