"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var constants_1 = require("../../../constants");
var CustomerCultureSelectComponent = /** @class */ (function () {
    function CustomerCultureSelectComponent(tService) {
        this.tService = tService;
        this.includeEmptyEntry = true;
        this.isDisabled = false;
        this.preferredCultureNameChange = new core_1.EventEmitter();
    }
    CustomerCultureSelectComponent.prototype.ngOnInit = function () {
        this.customerCultures = constants_1.CustomerCultures;
        this.selectCultureEmptyValueText = this.tService.instant('selectLanguage');
        this.selectedCultureName = this.preferredCultureName ? this.preferredCultureName : "";
        if (this.selectLabelKey) { // only define label if key passed in. This determines if label is displayed.
            this.selectCultureLabel = this.tService.instant(this.selectLabelKey);
        }
    };
    CustomerCultureSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedDealerId && !changes.selectedDealerId.isFirstChange()) {
            this.selectedCultureName = this.preferredCultureName ? this.preferredCultureName : "";
        }
        if (changes.preferredCultureName && !changes.preferredCultureName.isFirstChange()) {
            this.selectedCultureName = this.preferredCultureName ? this.preferredCultureName : "";
            this.preferredCultureNameChange.emit(this.selectedCultureName == "" ? null : this.selectedCultureName);
        }
    };
    CustomerCultureSelectComponent.prototype.cultureChange = function (event) {
        this.preferredCultureNameChange.emit(event.target.value == "" ? null : event.target.value);
    };
    return CustomerCultureSelectComponent;
}());
exports.CustomerCultureSelectComponent = CustomerCultureSelectComponent;
