<customer-connect-log-action *ngIf="subAction === ActivityActionEnum.Log_Call 
    || subAction === ActivityActionEnum.Post_Note" 
          [subAction]="subAction"
          [permissions]="permissions"
          [dialogParams]="dialogParams" 
          (onLogScheduleSubmit)="onLogScheduleSubmit.emit($event)"
          [isEuroLite]="isEuroLite">
</customer-connect-log-action>
<customer-connect-schedule-action *ngIf="subAction === ActivityActionEnum.Schedule_Appt 
    || subAction === ActivityActionEnum.Schedule_Call" 
          [subAction]="subAction"
          [permissions]="permissions"
          [dialogParams]="dialogParams" 
          (onLogScheduleSubmit)="onLogScheduleSubmit.emit($event)">
</customer-connect-schedule-action>