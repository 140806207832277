import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { UserService } from './../../services/user.service';
import { CustomerService } from './../../services/customer.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerMakeLeaseMileageDto, DealerSettingDto, DealerTwilioPhoneNumberDto, DealerSettingResponseDto, DealerMakeFranchiseAddressDto, DealerMakeFranchiseInfoExtDto } from '../../generated/models';
import { toDataURL } from '@progress/kendo-angular-excel-export';
import { StorageService } from '../../modules/storage/storage.service';

@Component({
    selector: 'admin-settings-dealerinfo',
    templateUrl: './admin-settings-dealerinfo.component-ng.html'
})

export class AdminSettingsDealerInfoComponent implements OnInit, OnChanges {
    @ViewChild('fileInput', {static: false}) fileInput: any;
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};

    public selectedMakeId: number = 0;

    public dealerImageSrc: any;
    public address1: DealerMakeFranchiseAddressDto;
    public address2: DealerMakeFranchiseAddressDto;
    public defaultPreferredLanguage: string;
    public secondaryPreferredLanguage: string;
    public customerCulturesDifferent: Boolean = true;
    public isReadOnly: Boolean = false;
    public isEmployee: boolean = false;

    public selectedMake(): DealerMakeFranchiseInfoExtDto {
        return this.dsa.dealerMakeFranchiseInfo.find(s => s.makeID == this.selectedMakeId);
    }

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public userService: UserService,
        public logger: ToasterLoggerService,
        public customerService: CustomerService,
        private storageService: StorageService
    ) {
        const userProfile = storageService.getItem('userProfile');
        if (userProfile) {
            this.isEmployee = userProfile.employeeUser;
        }
    }

    public ngOnInit() {
        this.defaultPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.defaultCultureName);
        this.secondaryPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.secondaryCultureName);
        this.isReadOnly = false;  // set to true if non-default Make selected
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dsa) {
            this.customerCulturesDifferent = (this.dsa.dealerSetting.defaultCultureName !== this.dsa.dealerSetting.secondaryCultureName);
        }
        if (changes.dsa && !changes.dsa.isFirstChange()) {
            if (changes.dsa.currentValue.dealer && changes.dsa.previousValue.dealer
                && changes.dsa.currentValue.dealer.dealerID != changes.dsa.previousValue.dealer.dealerID) {
                this.defaultPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.defaultCultureName);
                this.secondaryPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.secondaryCultureName);
            }
        }
    }

    public cultureChange() {
        this.customerCulturesDifferent = true;
        if (this.dsa.dealerSetting.defaultCultureName == this.dsa.dealerSetting.secondaryCultureName) {
            this.customerCulturesDifferent = false;
        }
    }

    private updateImage(logoImageBinary: string, fileExtension: string) {
        if (fileExtension != null)
            this.dealerImageSrc = 'data:image/' + fileExtension.toLowerCase().substring(1) + ';base64,' + logoImageBinary;
        else
            this.dealerImageSrc = null;
    }

    public uploadFile(event: any) {
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            var fileExtension = file.name.substr(file.name.lastIndexOf('.'));
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.dealerImageSrc = reader.result;
                this.dsa.dealerLogoImage.logoImageBinary = this.dealerImageSrc.split('base64,')[1];
                this.dsa.dealerLogoImage.fileExtension = fileExtension.toUpperCase();
            }

            this.dsa.isDealerLogoUpdate = true;
            this.fileInput.nativeElement.value = ''; // to allow another upload if necessary
        }
    }

    public deleteFile() {
        this.dsa.dealerLogoImage.logoImageBinary = null;
        this.dsa.dealerLogoImage.fileExtension = null;
        this.dealerImageSrc = null;
        this.dsa.isDealerLogoUpdate = true;
        this.fileInput.nativeElement.value = ''; // to allow another upload if necessary
    }

    public makeChanged() {
        if (this.selectedMakeId == 0) {
            this.address1 = null;
            this.address2 = this.dsa.dealerMakeFranchiseAddress.find(d => d.makeID == this.selectedMakeId);
            this.isReadOnly = false;
        }
        else {
            this.address1 = this.dsa.dealerMakeFranchiseAddress.find(d => d.makeID == this.selectedMakeId && d.displayOrder == 1);
            this.address2 = this.dsa.dealerMakeFranchiseAddress.find(d => d.makeID == this.selectedMakeId && d.displayOrder == 2);
            this.isReadOnly = true;
        }

        if (this.selectedMake())
            this.updateImage(this.selectedMake().logoImageBinary, this.selectedMake().logoFileExtension);
        else if (this.dsa.dealerLogoImage)
            this.updateImage(this.dsa.dealerLogoImage.logoImageBinary, this.dsa.dealerLogoImage.fileExtension);
    }
}