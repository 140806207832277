import angular from 'angular';

//Named ActivityRuleEnum to match the name in c# AutoAlert.AlertMiner.ServiceContract
angular.module('AaApp.Opp').constant('activityRule', {
    Close: 1,
    Mark_As_Sold: 2,
    New_Call: 3,
    New_Appointment: 4,
    New_Call_Or_Appointment: 5,
    Re_Open: 6,
    Schedule: 7,
    Set_To_Completed: 8,
    Set_To_Scheduled: 9,
    Set_To_Uncomplete_Existing: 10,
    Set_To_Uncompleted: 11,
    Watch: 12,
    Unwatch: 13,
    Read: 14,
    Not_Own: 15,
    Paid_Off: 16,
    Has_Warranty: 17,
    Add_Payoff: 18,
    Add_Mileage: 19,
    Warranty_Sold: 20,
    Convert: 21,
    Edit_Sale: 22,
    View_Deal_Sheet: 23,
    Sent_To_CRM: 24,
    Unmark_As_Sold: 25,
    Print_Deal_Sheet: 26,
    Mark_as_Do_Not_Call: 27,
    Opened_from_CRM: 28,
    Re_Open_and_Re_Calculate: 29,
    Unmark_As_Paid_Off: 30,
    Convert_edit_via_PURL: 31,
    Verify_PURL_Info: 32,
    Bought_Vehicle: 34,
    Convert_by_CreditConvert: 35,
    Unmark_as_Has_Warranty: 36,
    Unmark_as_Warranty_Sold: 37,
    New_Push_to_Compass: 38,
    Open_ContractEndNonAlert_Window: 39,
    Close_ContractEndNonAlert_Window: 40,
    Open_LeaseEnd_Call_Script_Window: 41,
    Close_LeaseEnd: 42,
    Open_MileageAlert: 43,
    Close_MileageAlert: 44,
    Open_PhoneMessage: 45,
    Close_PhoneMessage: 46,
    Open_PhoneMessageConquest: 47,
    Close_PhoneMessageConquest: 48,
    Open_SalesAlert: 49,
    Close_SalesAlert: 50,
    Open_ServiceOnly: 51,
    Close_ServiceOnly: 52,
    Open_WarrantyAlert: 53,
    Close_WarrantyAlert: 54,
    View_Deal_Sheet_From_Partner: 55,
    Activity_Posted_From_Partner: 56,
    DealSheet_Printed_From_Search: 57,
    Pando_Claim: 58,
    Mark_As_On_Order: 59,
    Unmark_As_On_Order: 60
});
