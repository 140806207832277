"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var services_1 = require("../../../../generated/services");
var TradeInValueComponent = /** @class */ (function () {
    function TradeInValueComponent(elementRef, injector, vehicleUserTradeValueClientService) {
        this.vehicleUserTradeValueClientService = vehicleUserTradeValueClientService;
        this.tradeValueChange = new core_1.EventEmitter();
        this.hasChanged = false;
    }
    TradeInValueComponent.prototype.onValueChange = function (value) {
        this.validate(value);
        this.tradeValueChange.emit({ tradeValue: this.value, logged: this.hasChanged });
        if (this.hasChanged) {
            this.hasChanged = false;
        }
    };
    TradeInValueComponent.prototype.onStoreTradeValue = function (value) {
        this.validate(value);
        if (this.vehicle && this.vehicle.tradeValue != value) {
            var params = { dealerid: this.vehicle.dealerID, vehicleid: this.vehicle.vehicleID, tradevalue: value };
            this.vehicleUserTradeValueClientService.StoreVehicleUserTradeValueResponse(params).toPromise();
            this.tradeValueChange.emit({ tradeValue: this.value, logged: this.hasChanged });
            if (this.hasChanged) {
                this.hasChanged = false;
            }
        }
    };
    TradeInValueComponent.prototype.tradeOnKeyPress = function (event) {
        if (event.which === 13) {
            this.tradeValueChange.emit({ tradeValue: this.value, logged: this.hasChanged });
        }
    };
    TradeInValueComponent.prototype.update = function (source) {
        this.validate(source);
        this.hasChanged = true;
    };
    // vlidate input to number only
    TradeInValueComponent.prototype.validate = function (source) {
        if (source == '') {
            this.value = 0;
            return;
        }
        if (!source) {
            this.value = 0;
            return;
        }
        source = source + '';
        source = source.trim();
        source = source.replace(/[^0-9]/g, '');
        this.value = parseInt(source);
    };
    return TradeInValueComponent;
}());
exports.TradeInValueComponent = TradeInValueComponent;
