"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var angular2_toaster_1 = require("angular2-toaster");
var app_settings_1 = require("../constants/app-settings");
var translate_error_keys_1 = require("../constants/translate-error-keys");
var log_factory_service_1 = require("../services/log-factory.service");
var ToasterLoggerService = /** @class */ (function () {
    function ToasterLoggerService(logFactory, translate, toasterService) {
        this.logFactory = logFactory;
        this.translate = translate;
        this.toasterService = toasterService;
    }
    ToasterLoggerService.prototype.genericError = function (message, data, title) {
        if (message != 'carFaxUnauthorizedException') {
            if (app_settings_1.Settings.environmentVariables.environment === "production"
                || app_settings_1.Settings.environmentVariables.environment === "staging" && app_settings_1.Settings.environmentVariables.logDebugEnabled === false) {
                var translations = this.translate.instant([translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey, translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey]);
                message = translations[translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey];
                title = translations[translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey];
            }
        }
        this.error(message, data, title);
    };
    ToasterLoggerService.prototype.error = function (message, data, title) {
        var _this = this;
        if (!title)
            title = "";
        if (!data)
            data = "";
        if (!message) {
            message = translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey;
            title = translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey;
        }
        this.translate.get([message, title]).subscribe(function (result) {
            _this.toasterService.pop({
                type: 'warning',
                title: result[title],
                body: result[message]
            });
            _this.logFactory.get('error').error('Error: ' + result[message], data);
        });
    };
    ToasterLoggerService.prototype.info = function (message, data, title) {
        var _this = this;
        if (!title)
            title = "";
        if (!data)
            data = "";
        if (!message) {
            message = translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey;
            title = translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey;
        }
        this.translate.get([message, title]).subscribe(function (result) {
            _this.toasterService.pop({
                type: 'info',
                title: result[title],
                body: result[message]
            });
            _this.logFactory.get('info').info('Info: ' + result[message], data);
        });
    };
    ToasterLoggerService.prototype.success = function (message, data, title) {
        var _this = this;
        if (!title)
            title = "";
        if (!data)
            data = "";
        if (!message) {
            message = translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey;
            title = translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey;
        }
        this.translate.get([message, title]).subscribe(function (result) {
            _this.toasterService.pop({
                type: 'success',
                title: result[title],
                body: result[message]
            });
            _this.logFactory.get('log').log('Success: ' + result[message], data);
            _this.debug(result[message]);
        });
    };
    ToasterLoggerService.prototype.warning = function (message, data, title) {
        var _this = this;
        if (!title)
            title = "";
        if (!data)
            data = "";
        if (!message) {
            message = translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey;
            title = translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey;
        }
        this.translate.get([message, title]).subscribe(function (result) {
            _this.toasterService.pop({
                type: 'warning',
                title: result[title],
                body: result[message],
                timeout: 10000
            });
            _this.logFactory.get('warn').warn('Warning: ' + result[message], data);
        });
    };
    ToasterLoggerService.prototype.debug = function (message) {
        if (!message) {
            var translations = this.translate.instant([translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey, translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey]);
            message = translations[translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey];
        }
        this.logFactory.get('debug').debug(message);
    };
    ToasterLoggerService.prototype.log = function (message) {
        if (!message) {
            var translations = this.translate.instant([translate_error_keys_1.TranslateErrorKeys.generalErrorTitleKey, translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey]);
            message = translations[translate_error_keys_1.TranslateErrorKeys.generalErrorMessageKey];
        }
        this.logFactory.get('log').log(message);
    };
    return ToasterLoggerService;
}());
exports.ToasterLoggerService = ToasterLoggerService;
