<div *ngFor="let item of categories" class="categoriesContainer">
    <label>
        <input *ngIf="!hideCategoryCheckbox" type="checkbox"
               [disabled]="readOnly"
               [(ngModel)]="item.allow"
               (change)="onItemChecked(filterSubItemsByCategoryId(item.categoryId, accessList))" />&nbsp;<img [src]="ICONS[item.accessOptionName]" class="categoryImage" /> {{item.accessOptionName | translate}}
    </label>
    <div class="accessRightContainer">
        <div *ngFor="let subitem of filterSubItemsByCategoryId(item.categoryId, accessList)" class="accessRight">
            <label>
                <input type="checkbox"
                       [disabled]="readOnly"
                       [(ngModel)]="subitem.allow"
                       (change)="onSubItemChecked(item, subitem)" />&nbsp;{{subitem.accessOptionName | translate}}
            </label>
        </div>
    </div>
</div>
<div class="actionButtonContainer">
    <div style="float:right;">
        <button *ngIf="!readOnly" type="button" class="btn btn-primary" (click)="onCancel()" translate="cancel"></button>
        <button *ngIf="!readOnly" type="button" class="btn btn-primary saveButton" (click)="onSave()" translate="saveChanges"></button>
    </div>
</div>