import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivityActionEnum } from '../../../../constants';
import { Customer } from '../../../../interfaces/customer.interface';
import { DealSheetOpportunity } from '../../../../interfaces/deal-sheet/deal-sheet-opportunity.interface';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { LogActionShortcutService } from '../../../../services/log-action-shortcut.service';

@Component({
    selector: 'customer-connect-actions',
    templateUrl: './customer-connect-actions.component-ng.html',
    styleUrls: ['./customer-connect-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectActionsComponent implements OnInit, OnChanges {
    constructor(private logActionShortcutService: LogActionShortcutService,
        private changeDetectorRef: ChangeDetectorRef) { }

    private ActivityActionEnum = ActivityActionEnum;

    @Input() opportunity: DealSheetOpportunity;
    @Input() customer: Customer;
    @Input() permissions: DealSheetPermissions;
    @Input() isCustomerConnectOpen: boolean;
    @Input() defaultCustomerConnectAction: any;
    @Output() onAction = new EventEmitter<any>();
    @Output() onCustomerEdit = new EventEmitter<any>();
    @Input() isEuroLite: boolean;
    @Input() isTextingEnabled: boolean;
    @Input() mobileView: boolean;

    private activityActionEnum = this.ActivityActionEnum.Log_Call;

    private editableCustomer: any;

    public selectedLogActivity: string;
    public selectedscheduleActivity: string;
    private doNotCall: boolean;
    private doNotEmail: boolean;
    private readonly selectedBar = 'selectedbar';
    private activityActionEnums = ActivityActionEnum;

    public showLogActivity = true;
    public showScheduleAppt = true;
    public showEmail = true;
    public showText = true;

    private shortcutInProgress = false;

    ngOnInit() {
        this.logActionShortcutService.$logShortcutAction.subscribe((activityActionEnum) => this.handleShortcutEvent(activityActionEnum));
    }

    public activityClicked(activityActionEnum?: number) {
        if (!activityActionEnum) { activityActionEnum = this.ActivityActionEnum.Log_Call; }
        this.onAction.emit(activityActionEnum);
        this.selectLogActivity();
        this.changeDetectorRef.detectChanges();
    }

    public scheduleClicked() {
        this.onAction.emit(this.ActivityActionEnum.Schedule_Appt);
        this.selectScheduleActivity();
        this.changeDetectorRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isCustomerConnectOpen && changes.isCustomerConnectOpen.currentValue) {

            if (this.shortcutInProgress) {
                this.shortcutInProgress = false;
                return;
            }

            if (this.defaultCustomerConnectAction === this.ActivityActionEnum.Log_Call
                || this.defaultCustomerConnectAction === this.ActivityActionEnum.Post_Note) {
                this.activityActionEnum = this.defaultCustomerConnectAction;
                this.activityClicked();
            } else if (this.defaultCustomerConnectAction === this.ActivityActionEnum.Schedule_Appt
                || this.defaultCustomerConnectAction === this.ActivityActionEnum.Schedule_Call) {

                this.scheduleClicked();
            }

            this.logActionShortcutService.reset();

        } else {
            this.setAllShowFlags(true);
            this.activityActionEnum = this.defaultCustomerConnectAction;
            this.shortcutInProgress = false;
        }

        if (this.customer) {
            this.doNotCall = this.customer.doNotCall;
            this.doNotEmail = this.customer.doNotEmail;
        }
    }

    private handleShortcutEvent(activityActionEnum?: number) {
        if (activityActionEnum === null) { return; }

        this.shortcutInProgress = true;
        this.setAllShowFlags(false);
        this.activityActionEnum = activityActionEnum;

        switch (activityActionEnum) {
            case this.activityActionEnums.Log_Call:
            case this.activityActionEnums.Post_Note:
                this.showLogActivity = true;
                this.activityClicked(this.activityActionEnum);
                break;
            case this.activityActionEnums.Schedule_Appt:
                this.showScheduleAppt = true;
                this.scheduleClicked();
                break;
        }
    }

    private setAllShowFlags(on: boolean) {
        this.showLogActivity = on;
        this.showScheduleAppt = on;
        this.showEmail = on;
        this.showText = on;
    }

    private selectLogActivity() {
        this.selectedLogActivity = this.selectedBar;
        this.selectedscheduleActivity = '';
    }

    private selectScheduleActivity() {
        this.selectedLogActivity = '';
        this.selectedscheduleActivity = this.selectedBar;
    }

    onDonotCallChange(doNotCall: boolean): void {
        this.doNotCall = !doNotCall;
        this.onDonotCallEmailChange();
    }

    onDonotEmailChange(doNotEmail: boolean): void {
        this.doNotEmail = !doNotEmail;
        this.onDonotCallEmailChange();
    }

    onDonotCallEmailChange(): void {
        this.editableCustomer = this.getCustomerCopy();
        this.editableCustomer.doNotCall = this.doNotCall;
        this.editableCustomer.doNotEmail = this.doNotEmail;
        const emitValue = { $value: this.editableCustomer };
        this.onCustomerEdit.emit(emitValue);
    }

    private getCustomerCopy(): Customer {

        const cloneCustomer = {
            id: this.opportunity.customer.id,
            firstName: this.opportunity.customer.firstName,
            middleName: this.opportunity.customer.middleName,
            lastName: this.opportunity.customer.lastName,
            fullName: this.opportunity.customer.fullName,
            companyName: this.opportunity.customer.companyName,
            invalidContacts: this.opportunity.customer.invalidContacts.slice(),
            alternateContacts: this.opportunity.customer.alternateContacts.slice(),
            doNotCall: this.opportunity.customer.doNotCall,
            doNotEmail: this.opportunity.customer.doNotEmail,
            doNotText: this.opportunity.customer.doNotText,
            isFullCustomer: this.opportunity.customer.isFullCustomer,
            isAddressNotValid: this.opportunity.customer.isAddressNotValid,
            address: {
                streetAddress: this.opportunity.customer.address.streetAddress,
                streetAddress2: this.opportunity.customer.address.streetAddress2,
                city: this.opportunity.customer.address.city,
                stateId: this.opportunity.customer.address.stateId,
                stateName: this.opportunity.customer.address.stateName,
                zipCode: this.opportunity.customer.address.zipCode,
                normalizedZipCode: this.opportunity.customer.address.normalizedZipCode
            },

            // Create phony records for the UI if there is currenly no value
            homePhoneNumber: this.opportunity.customer.homePhoneNumber,
            workPhoneNumber: this.opportunity.customer.workPhoneNumber,
            cellPhoneNumber: this.opportunity.customer.cellPhoneNumber,
            emailAddress: this.opportunity.customer.emailAddress
        } as Customer;

        return cloneCustomer;
    }
}
