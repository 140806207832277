
/* injects from baggage-loader */
require('./vehicle-type-select.component.html');

import angular from 'angular';
import _ from 'lodash';

(function () { 
    angular.module('AaApp.Opp').component('vehicleTypeSelect', {
        templateUrl: '/app/components/deal-sheet/vehicle/vehicle-type-select.component.html',
        controller: VehicleTypeSelectController,
        bindings: {
            defaults: '<',
            onSelect: '&'
        }
    });

    /* @ngInject */
    function VehicleTypeSelectController(vehicleTypes) {
        var $ctrl = this;

        $ctrl.newOptionSelected;
        $ctrl.usedOptionSelected;
        $ctrl.onSelectionChange = onSelectionChange;
        $ctrl.$onChanges = onChanges;

        function onChanges(changes) {
            if (changes.defaults && changes.defaults.currentValue) {
                if (!Array.isArray($ctrl.defaults)) {
                    $ctrl.defaults = [$ctrl.defaults];
                }

                $ctrl.newOptionSelected = _.includes($ctrl.defaults, vehicleTypes.new);

                $ctrl.usedOptionSelected =
                    _.includes($ctrl.defaults, vehicleTypes.used) ||
                    _.includes($ctrl.defaults, vehicleTypes.cpo);
            }
        }

        function onSelectionChange() {
            var selections = [];

            if ($ctrl.newOptionSelected) {
                selections.push(vehicleTypes.new);
            }

            if ($ctrl.usedOptionSelected) {
                selections.push(vehicleTypes.used)
            }

            $ctrl.onSelect({ vehicleTypes: selections });
        }
    }
})();
