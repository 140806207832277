"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./customer-connect-detail.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../customer-connect-sub-actions/customer-connect-sub-actions.component.ngfactory"));
var i3 = __importStar(require("../customer-connect-sub-actions/customer-connect-sub-actions.component"));
var i4 = __importStar(require("../../../../services/log-action-shortcut.service"));
var i5 = __importStar(require("../customer-connect-sub-detail/customer-connect-sub-detail.component.ngfactory"));
var i6 = __importStar(require("../customer-connect-sub-detail/customer-connect-sub-detail.component"));
var i7 = __importStar(require("./customer-connect-detail.component"));
var styles_CustomerConnectDetailComponent = [i0.styles];
var RenderType_CustomerConnectDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerConnectDetailComponent, data: {} });
exports.RenderType_CustomerConnectDetailComponent = RenderType_CustomerConnectDetailComponent;
function View_CustomerConnectDetailComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-connect-sub-actions", [], null, [[null, "onAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAction" === en)) {
        var pd_0 = (_co.onActionSelect($event.action) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerConnectSubActionsComponent_0, i2.RenderType_CustomerConnectSubActionsComponent)), i1.ɵdid(1, 770048, null, 0, i3.CustomerConnectSubActionsComponent, [i4.LogActionShortcutService, i1.ChangeDetectorRef], { permissions: [0, "permissions"], subAction: [1, "subAction"], subActionTime: [2, "subActionTime"] }, { onAction: "onAction" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "customer-connect-sub-detail", [], null, [[null, "onLogScheduleSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogScheduleSubmit" === en)) {
        var pd_0 = (_co.onLogScheduleSubmit.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CustomerConnectSubDetailComponent_0, i5.RenderType_CustomerConnectSubDetailComponent)), i1.ɵdid(3, 114688, null, 0, i6.CustomerConnectSubDetailComponent, [], { permissions: [0, "permissions"], subAction: [1, "subAction"], dialogParams: [2, "dialogParams"], isEuroLite: [3, "isEuroLite"] }, { onLogScheduleSubmit: "onLogScheduleSubmit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissions; var currVal_1 = _co.action; var currVal_2 = _co.actionTime; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.permissions; var currVal_4 = _co.subAction; var currVal_5 = _co.dialogParams; var currVal_6 = _co.isEuroLite; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
exports.View_CustomerConnectDetailComponent_0 = View_CustomerConnectDetailComponent_0;
function View_CustomerConnectDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-connect-detail", [], null, null, null, View_CustomerConnectDetailComponent_0, RenderType_CustomerConnectDetailComponent)), i1.ɵdid(1, 638976, null, 0, i7.CustomerConnectDetailComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CustomerConnectDetailComponent_Host_0 = View_CustomerConnectDetailComponent_Host_0;
var CustomerConnectDetailComponentNgFactory = i1.ɵccf("customer-connect-detail", i7.CustomerConnectDetailComponent, View_CustomerConnectDetailComponent_Host_0, { permissions: "permissions", isCustomerConnectOpen: "isCustomerConnectOpen", action: "action", dialogParams: "dialogParams", actionTime: "actionTime", isEuroLite: "isEuroLite" }, { onLogScheduleSubmit: "onLogScheduleSubmit" }, []);
exports.CustomerConnectDetailComponentNgFactory = CustomerConnectDetailComponentNgFactory;
