
/* injects from baggage-loader */
require('./toggle-button.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('toggleButton', {
    templateUrl: '/app/components/toggle-button.component.html',
    controller:ToggleButtonController,
    transclude: {
        'icon': '?icon'
    },
    bindings: {
        onClick: '&',
        onIcon: '<',
        offIcon:'<',
        buttonState:'<',
        titleText:'@',
    }
});

function ToggleButtonController() {
    "ngInject";
    const $ctrl = this;
    $ctrl.ngModel = false;
    $ctrl.isIcon = false;
    $ctrl.svgIcon = $ctrl.offIcon;

    $ctrl.$onChanges = onChanges;
    $ctrl.onToggle = onToggle;
    initIconState();

    function onChanges(changes){
        if(changes.buttonState){
            $ctrl.ngModel = $ctrl.buttonState;
            updateIcons();
        }
    }

    function initIconState(){
        if ($ctrl.offIcon && $ctrl.onIcon)
            $ctrl.isIcon = true;
        else
            $ctrl.isIcon = false;
    }

    function updateIcons(){
        if ($ctrl.ngModel)
            $ctrl.svgIcon = $ctrl.onIcon;
        else
            $ctrl.svgIcon = $ctrl.offIcon;
    }

    function onToggle(){// on user click
        updateIcons();
        $ctrl.onClick({$value:$ctrl.ngModel});
    }
}


/*
This component can be used to display two state standard button or tabs with icon only, text only, icon with text, icon with transcluded icon badge
here are some calling examples:
TAB TEXT ONLY: 
<toggle-button class="tab"  on-click ="$ctrl.LogActivityClick($value)" title-text="customerConnectTab" button-state ="$ctrl.isCustomerConnectOpen"></toggle-button>

ICON ONLY TAB:
<toggle-button class="tab-icon" on-click ="$ctrl.toggleClick($value)"  on-icon  ="$ctrl.pandoOnIcon"  off-icon ="$ctrl.pandoOffIcon" button-state ="$ctrl.isPandoOpen"> 
</toggle-button>

TEXT ONLY BUTTON: 
<toggle-button on-click ="$ctrl.LogActivityClick($value)" title-text="customerConnectTab" button-state ="$ctrl.isCustomerConnectOpen"></toggle-button>

ICON ONLY BUTTON:
<toggle-button class="tab-icon" on-click ="$ctrl.toggleClick($value)"  on-icon  ="$ctrl.pandoOnIcon"  off-icon ="$ctrl.pandoOffIcon" button-state ="$ctrl.isPandoOpen">
</toggle-button>

ICON ONLY BUTTON WITH TRANSCLUDED ADDITIONAL BADGE ICON:
<toggle-button class="tab-icon" on-click ="$ctrl.toggleClick($value)"  on-icon  ="$ctrl.pandoOnIcon"  off-icon ="$ctrl.pandoOffIcon" button-state ="$ctrl.isPandoOpen">
<icon><pando-customer-connect-unread-messages></pando-customer-connect-unread-messages></icon> 
</toggle-button>

This component is based on: <filter-button></filter-button>
*/
