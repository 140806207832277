SearchManagePresetsContainer.$inject = ["dealerService"];

/* injects from baggage-loader */
require('./search-manage-presets-container.component.html');

import angular from 'angular';
import './search-manage-presets-container.component.scss';
angular.module('AaApp.Opp').component('searchManagePresetsContainer', {
  templateUrl: '/app/components/search/search-manage-presets-container.component.html',
  controller: SearchManagePresetsContainer,
  bindings: {
    dealerId: '<',
    view: '<',
    kendo: '<'
  }
});
/* @ngInject */

function SearchManagePresetsContainer(dealerService) {
  var $ctrl = this;
  $ctrl.$onInit = init;

  function init() {
    $ctrl.view = "Manage";
    var dealers = dealerService.getSelectedDealers();

    if (dealers.length === 1) {
      $ctrl.dealerId = dealers[0].id;
    } else if ($ctrl.dealerId === undefined || $ctrl.dealerId === null) {
      $ctrl.dealerId = 0;
    }
  }
}