import angular from 'angular';

angular.module('AaApp.Opp').constant('vciResultType', {
    EulaRequired: 0,
    NotConfigured: 1,
    OptedOutNonoriginatingDealer: 2,
    OptedOutOriginatingDealer: 3,
    GeneralError: 4,
    VinNotFound: 5,
    HasData: 6
});