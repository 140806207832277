
/* injects from baggage-loader */
require('./monthly-mileage.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('monthlyMileage', {
    templateUrl: '/app/components/deal-sheet/contract/monthly-mileage.component.html',
    bindings: {
        value: '<'
    }
});
