import angular from 'angular';

angular.module('AaApp.Opp').constant('creditTiers', {
    superPrime: 'A',
    prime: 'B',
    nearPrime: 'C',
    subPrime: 'D',
    deepSubPrime: 'E',
    didNotQualify: 'F',
    infoNotAvailable: 'G'
});
