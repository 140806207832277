import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
EuroLiteOpportunitiesController.$inject = ["$q", "$filter", "$uibModal", "$document", "$translate", "$timeout", "$state", "_", "dealSheetService", "opportunity2Service", "opportunityService", "activityService", "searchService", "vehicleService", "authorizationService", "userService", "dealerService", "customerService", "storageService", "legacyService", "dateFormatService", "alertTypeDescriptorsEuroLite", "ActivityActionEnum", "activityResults", "saleTypes", "opportunityStatuses", "widgetTitleKeys", "authorizationKeys", "logger", "dealerCountries", "stockNumberSearchService", "$logFactory", "environmentVariables", "modules"];

/* injects from baggage-loader */
require('./euroliteopportunities.component.html');

import angular from 'angular';
import moment from 'moment-timezone';
import $ from 'jquery';
import './euroliteopportunities.component.scss';
import { downloadArraybufferResponse } from '../../utilities/http';
import { ApiActivityTypes, ProductTypes } from '../../constants';
angular.module('AaApp.Opp').component('euroLiteOpportunities', {
  templateUrl: '/app/components/opportunities/euroliteopportunities.component.html',
  controller: EuroLiteOpportunitiesController,
  bindings: {
    kendo: '<',
    opportunityId: '<',
    dealerIds: '<',
    filter: '<'
  }
});
/* @ngInject */

function EuroLiteOpportunitiesController($q, $filter, $uibModal, $document, $translate, $timeout, $state, _, dealSheetService, opportunity2Service, opportunityService, activityService, searchService, vehicleService, authorizationService, userService, dealerService, customerService, storageService, legacyService, dateFormatService, alertTypeDescriptorsEuroLite, ActivityActionEnum, activityResults, saleTypes, opportunityStatuses, widgetTitleKeys, authorizationKeys, logger, dealerCountries, stockNumberSearchService, $logFactory, environmentVariables, modules) {
  "ngInject";

  var $ctrl = this;
  var log = $logFactory.get();
  var allFilters = [];
  var allOpportunityFilters = [];
  var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService; // props

  $ctrl.alertTypeDescriptorsEuroLite = alertTypeDescriptorsEuroLite;
  $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
  $ctrl.lastOpenedLogActivityRow = null;
  $ctrl.markSelectedAlerts = [];
  $ctrl.isAlertSelectedBefore = false;
  $ctrl.isFlexAlertSelectedBefore = false;
  $ctrl.cultureName = storageService.getItem('cultureName');
  $ctrl.dateFormat = dateFormatService.getDateTimeMomentFormatString();
  $ctrl.filterUnscheduledOnly_show = authorizationService.isAuthorized(authorizationKeys.Scheduled);
  $ctrl.isTextingEnabled = false;
  $ctrl.getAlertTranslations = getAlertTranslations; // binding events

  $ctrl.$onInit = $onInit;
  $ctrl.refreshData = refreshData; // events

  $ctrl.onReloadGridDS = onReloadGridDS; // popup action menus

  $ctrl.onActionMenuMarkAsSoldClick = onActionMenuMarkAsSoldClick;
  $ctrl.onActionMenuLogActivityClick = onActionMenuLogActivityClick;
  $ctrl.onActionMenuActivityNotesClick = onActionMenuActivityNotesClick; //component events

  $ctrl.onReassignComplete = onReassignComplete;
  $ctrl.onLogActivityComplete = onLogActivityComplete;
  $ctrl.onUpdateCustomer = onUpdateCustomer;
  $ctrl.onActivityNotesClosed = onActivityNotesClosed;
  $ctrl.onSelectedOpportunityId = onSelectedOpportunityId;
  $ctrl.onOfferTemplateClick = onOfferTemplateClick;
  $ctrl.isLogActivityOpen = isLogActivityOpen;
  $ctrl.isActivityNotesOpen = isActivityNotesOpen;
  $ctrl.onActivityNotesScheduleActionComplete = onActivityNotesScheduleActionComplete;
  $ctrl.onGridRowChange = onGridRowChange;
  $ctrl.onUserCustomFilterClick = onUserCustomFilterClick;
  $ctrl.onUserCustomFilterKeyPress = onUserCustomFilterKeyPress;
  $ctrl.onLastActivityMouseOver = onLastActivityMouseOver; //grid events

  $ctrl.onGridLayoutChanges = onGridLayoutChanges;
  $ctrl.onFilterSelect = onFilterSelect;
  $ctrl.onAlertClick = onAlertClick;
  $ctrl.onHeaderFilterSelect = onHeaderFilterSelect;
  $ctrl.onFlagClicked = onFlagClicked; // spinner animation

  $ctrl.stopWorkingState = stopWorkingState;
  $ctrl.startWorkingState = startWorkingState;
  $ctrl.hideWhenBusy = false;
  $ctrl.dealsheetWorking = null; // Make model info Models

  $ctrl.makesSelected = [];
  $ctrl.alertsSelected = [];
  $ctrl.dealerMakesOptions = [];
  $ctrl.alertOptions = [];
  $ctrl.settingsMakesDropdown = {
    template: '{{option.name}}',
    smartButtonTextConverter: function smartButtonTextConverter(skip, option) {
      return option.name;
    },
    checkBoxes: true,
    smartButtonMaxItems: 2,
    showCheckAll: false,
    showUncheckAll: false
  };
  $ctrl.settingsAlertsDropdown = {
    template: '{{option.name}}',
    smartButtonTextConverter: function smartButtonTextConverter(skip, option) {
      return option.name;
    },
    checkBoxes: true,
    smartButtonMaxItems: 2,
    showCheckAll: false,
    showUncheckAll: false
  };
  $ctrl.currentGridColumns = [];
  $ctrl.filterNew = null;
  $ctrl.filterUsed = null;
  $ctrl.filterCash = null;
  $ctrl.filterLease = null;
  $ctrl.filterRetail = null;
  $ctrl.filterBalloon = null;
  $ctrl.filterUnreadOnly = null;
  $ctrl.filterUnscheduledOnly = null;
  $ctrl.filterFlaggedOnly = null; // grid details

  $ctrl.newDealSheetReady = false;
  $ctrl.contractReady = false;
  $ctrl.opportunityDetailsReady = false;
  $ctrl.permissions = null;
  $ctrl.isSltUser = null;
  $ctrl.moduleIds = null;
  $ctrl.customer = null;
  $ctrl.userProfile = null;
  $ctrl.users = null;
  $ctrl.dealerSettings = null;
  $ctrl.isCustomerConnectOpen = null;
  $ctrl.tabLoadingPromise = null;
  $ctrl.activities = null;
  $ctrl.isEuroLite = null;
  $ctrl.openedActivityOrNotesEntity = null;
  $ctrl.activities = null;
  $ctrl.isLogActivityOpen = false;
  $ctrl.isNotesOpen = false;
  var emailFieldName = null;
  var homePhoneFieldName = null;
  var cellPhoneFieldName = null; // grid

  $ctrl.opportunity = {
    id: null,
    assignedUserId: null,
    assignedUserFullName: null,
    customer: $ctrl.customer,
    dealerId: null,
    expirationDate: null
  };
  $ctrl.currentGridRow = null;
  $ctrl.currentEntityId = null; // detail tabs

  $ctrl.tabs = {
    "LogActivity": 0,
    "ActivityNotesList": 1
  };
  $ctrl.currentTab = null;
  $ctrl.dealerCountries = dealerCountries;
  var openEuroliteMarkAsSoldTranslationKey = "euroliteMarkAsSoldPrompt";
  var openEuroliteNewLogActivityTranslationKey = "euroliteNewLogActivityandNotesPrompt";

  function onLastActivityMouseOver(item) {
    activityService.getLastActivityDetails(item._EntityID).then(function (activity) {
      if (activity) {
        var toolTipHtml = "";

        if (activity.activityTypeID) {
          toolTipHtml += '<span class="activity-tooltip-icon ' + getIconClass(activity.activityTypeID) + ' icon"></span> ';
        }

        if (activity.activityActionName) {
          toolTipHtml += "<span class='activity-tooltip-title'>" + getLastActionKeys(activity.activityActionName, activity.activityActionID) + " </span><span class='activity-tooltip-notes'>" + $ctrl.by + " " + activity.insertUserFullName + "</span><br /><hr>";
        }

        if (activity.dueDate) {
          toolTipHtml += "<span class='activity-tooltip-title'>" + $ctrl.dueDate + ": </span><span class='activity-tooltip-notes'>" + moment(activity.dueDate).format($ctrl.dateFormat) + "</span><br />";
        }

        if (activity.responsibleUserFullName) {
          toolTipHtml += "<span class='activity-tooltip-title'>" + $ctrl.assignedTo + ": </span><span class='activity-tooltip-notes'>" + activity.responsibleUserFullName + "</span><br />";
        }

        if (activity.notes) {
          toolTipHtml += "<span class='activity-tooltip-notes'>" + activity.notes.substring(0, 30) + "</span>....<br />";
        }
      }

      item.lastActivityToolTipDesc = toolTipHtml;
    });
  }

  function getIconClass(type) {
    switch (type) {
      case ApiActivityTypes.note:
        return 'aa-icon-core-takenote-icon-000000';

      case ApiActivityTypes.phoneCall:
        return 'aa-icon-core-phone-icon-000000';

      case ApiActivityTypes.appointment:
        return 'aa-icon-core-calendar-icon-000000';

      case ApiActivityTypes.email:
        return 'aa-icon-core-email-icon-000000';

      default:
        return '';
    }
  }

  $ctrl.gridDataLoadingCompleted = 2;
  $ctrl.gridloaded = 0;
  $ctrl.matchedExternalEntityID = false;

  function onDataboundInitKendoGrid(e) {
    var dataSource = this.dataSource;
    this.element.find('tr.k-master-row').each(function () {
      var row = $(this);
      var data = dataSource.getByUid(row.data('uid'));
      row.find('.k-hierarchy-cell a').css({
        opacity: 0.0,
        cursor: 'default',
        width: '0px'
      }).attr("href", "");
    });
    selectGrid();
  }

  function selectGrid() {
    $ctrl.gridloaded++;
    var grid = getKendoGrid();

    if ($ctrl.gridloaded == $ctrl.gridDataLoadingCompleted) {
      var gridData = grid.dataSource.data();

      var index = _.findIndex(gridData, {
        _EntityID: $ctrl.currentEntityId
      }); //gridData.findIndex(x => x._EntityID == $ctrl.currentEntityId);


      if (index > -1) {
        var pageNum = Math.ceil((index + 1) / grid.dataSource.pageSize());
        $ctrl.matchedExternalEntityID = true;
        grid.dataSource.page(pageNum);
      }
    }

    if ($ctrl.matchedExternalEntityID) {
      grid.items().each(function () {
        var data = grid.dataItem(this);

        if (data._EntityID == $ctrl.currentEntityId) {
          grid.select('tr[data-uid="' + data.uid + '"]');
          grid.content.animate({
            scrollTop: grid.select().position().top
          }, 400);
        }
      });
    }
  }

  function onPagerPageChange(e) {
    //if the user manually changed a page reset user state to null
    $ctrl.openedActivityOrNotesEntity = null;
  }

  function getAllCustomerSearchFilters() {
    var temp = null;
    var tempFilter = [];
    var qtypeOperator = "and";

    if ($ctrl.userCustomFilter && !/^[a-zA-Z0-9'\- ]*$/.test($ctrl.userCustomFilter)) {
      logger.warning("invalidQuickSearchString");
      return temp;
    }

    if ($ctrl.userCustomFilter && $ctrl.userCustomFilter.replace(/[ ]/g, '').length > 2) {
      var qtype = stockNumberSearchService.getProcessQueryString($ctrl.userCustomFilter);

      if (qtype.IsName) {
        $ctrl.userCustomFilter.split(" ").forEach(function (element) {
          tempFilter.push({
            "field": "full_Name",
            "operator": "contains",
            "value": element
          });
        });
      } else if (qtype.IsPhone) {
        //IsPhone is any number
        qtypeOperator = "or";
        tempFilter.push({
          "field": "cell_PhoneString",
          "operator": "contains",
          "value": $ctrl.userCustomFilter.replace(/[ ]/g, '')
        });
        tempFilter.push({
          "field": "home_PhoneString",
          "operator": "contains",
          "value": $ctrl.userCustomFilter.replace(/[ ]/g, '')
        });
        tempFilter.push({
          "field": "dmS_Deal_ID",
          "operator": "contains",
          "value": $ctrl.userCustomFilter.replace(/[ ]/g, '')
        });
        tempFilter.push({
          "field": "zip_Code",
          "operator": "contains",
          "value": $ctrl.userCustomFilter.replace(/[ ]/g, '')
        });
      } else if (qtype.IsVIN) {
        tempFilter.push({
          "field": "vin",
          "operator": "contains",
          "value": $ctrl.userCustomFilter
        });
      }

      temp = {
        qtype: qtype,
        filter: tempFilter,
        qtypeOperator: qtypeOperator
      };
    }

    return temp;
  }

  function onUserCustomFilterKeyPress(event) {
    if (event.which === 13) {
      onUserCustomFilterClick();
    }
  }

  function checkMinSearch(minLength) {
    if (!$ctrl.userCustomFilter) return true;

    if ($ctrl.userCustomFilter && $ctrl.userCustomFilter.replace(/[ ]/g, '').length > minLength) {
      return true;
    } else {
      logger.warning("quickSearchWarning");
      return false;
    }
  }

  function onUserCustomFilterClick() {
    if (checkMinSearch(2)) onFilterSelect();
  }

  function onGridRowChange(e) {
    var grid = e.sender;
    $ctrl.currentEntityId = grid.dataItem(grid.select())._EntityID;
    log.log("eurolite row entityid:", $ctrl.currentEntityId);
  }

  function showMessage(messageKey) {
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "customerRecord";
        },
        message: function message() {
          return messageKey;
        },
        submitButtonVisible: function submitButtonVisible() {
          return false;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        closeButtonVisible: function closeButtonVisible() {
          return true;
        },
        closeButtonDisabled: function closeButtonDisabled() {
          return true;
        },
        closeButtonText: function closeButtonText() {
          return "ok"; // translation key
        }
      }
    });
  } // User Column Settings -----------------------------------------


  function getUserColumnSettings() {
    return storageService.getItem('euroliteUserColumnSettings');
  }

  function setUserColumnSettings(columnNames) {
    storageService.setItem('euroliteUserColumnSettings', columnNames);
  }

  function clearUserColumnSettings() {
    storageService.removeItem('euroliteUserColumnSettings');
  } // all inits ---------------------------------------------------


  function $onInit() {
    // if binding has valid opportunity id then select it.
    if ($ctrl.opportunityId) {
      $ctrl.currentEntityId = $ctrl.opportunityId;
      log.log("activity or report row entityid:", $ctrl.opportunityId);
    }

    $ctrl.startWorkingState();
    $ctrl.currentGridColumns = ["_Opportunity_Rank", "_EntityWatched", "full_Name", "customer_Owner", "cell_Phone", "sale_Type", "current_Model_Name", "current_Model_Year", "Categories", "payment_Remaining", "payments_Made", "payment_Difference__", "performed_On", "offer", "actions"];
    getTranslations();
    initSelectedOpportunityInfo();
    $ctrl.isAlertSelectedBefore = false;
    $ctrl.isFlexAlertSelectedBefore = false;
  }

  function getTranslations() {
    $q.all({
      'alertName': $translate('alertName'),
      'flexAlertName': $translate('flexAlertName'),
      'warrantyName': $translate('warrantyName'),
      'contractEndName': $translate('contractEndName'),
      'noRecordsAvailable': $translate('noRecordsAvailable'),
      'flag': $translate('flag'),
      'score': $translate('score'),
      'sortBy': $translate('sortBy'),
      'allAlerts': $translate('allAlerts'),
      'allMakes': $translate('allMakes'),
      'owner': $translate('euroLiteOwner'),
      'clientOwner': $translate('clientOwner'),
      'full': $translate('full'),
      'DealsheetPrintOfferCaption': $translate('DealsheetPrintOfferCaption'),
      'fullName': $translate('fullName'),
      'home': $translate('home'),
      'customerHomePhone': $translate('customerHomePhone'),
      'cell': $translate('cell'),
      'phone1': $translate('phone1'),
      'phone2': $translate('phone2'),
      'customerCellPhone': $translate('customerCellPhone'),
      'lastActivityTitle': $translate('lastActivityTitle'),
      'lastActivityToolTip': $translate('lastActivityToolTip'),
      'paymentsMadeTitle': $translate('paymentsMadeTitle'),
      'paymentsMadeToolTip': $translate('paymentsMadeToolTip'),
      'paymentsLeftTitle': $translate('paymentsLeftTitle'),
      'paymentsLeftToolTip': $translate('paymentsLeftToolTip'),
      'paymentsDifferenceTitle': $translate('paymentsDifferenceTitle'),
      'paymentsDifferenceToolTip': $translate('paymentsDifferenceToolTip'),
      'year': $translate('year'),
      'model': $translate('model'),
      'email': $translate('email'),
      'saleType': $translate('saleType'),
      'categories': $translate('categories'),
      'action': $translate('action'),
      'postNoteActivity': $translate('postNoteActivity'),
      'rescheduleActivity': $translate('rescheduleActivity'),
      'completedActivity': $translate('completedActivity'),
      'reassigned': $translate('reassigned'),
      'canceled': $translate('canceled'),
      'cancelledAppointment': $translate('cancelledAppointment'),
      'cancelledCall': $translate('cancelledCall'),
      'loggedActivity': $translate('loggedActivity'),
      'scheduledCallActivity': $translate('scheduledCallActivity'),
      'expiredActivity': $translate('expiredActivity'),
      'loading': $translate('loading'),
      'bykey': $translate('Bykey'),
      'assignedTo': $translate('assignedTo'),
      'dueDate': $translate('dueDate'),
      'dmsDealId': $translate('dmsDealId')
    }).then(function (stuff) {
      $ctrl.alertName = stuff.alertName;
      $ctrl.flexAlertName = stuff.flexAlertName;
      $ctrl.warrantyName = stuff.warrantyName;
      $ctrl.contractEndName = stuff.contractEndName;
      $ctrl.noRecordsAvailable = stuff.noRecordsAvailable;
      $ctrl.flag = stuff.flag;
      $ctrl.score = stuff.score;
      $ctrl.sortBy = stuff.sortBy;
      $ctrl.allAlertsCaption = stuff.allAlerts;
      $ctrl.allMakesCaption = stuff.allMakes;
      $ctrl.owner = stuff.owner;
      $ctrl.clientOwner = $ctrl.sortBy + ' ' + stuff.clientOwner;
      $ctrl.full = stuff.full;
      $ctrl.offer = stuff.DealsheetPrintOfferCaption;
      $ctrl.fullName = $ctrl.sortBy + ' ' + stuff.fullName;
      $ctrl.home = stuff.home;
      $ctrl.customerHomePhone = $ctrl.sortBy + ' ' + stuff.phone1;
      $ctrl.cell = stuff.cell;
      $ctrl.phone1 = stuff.phone1;
      $ctrl.phone2 = stuff.phone2;
      $ctrl.customerCellPhone = $ctrl.sortBy + ' ' + stuff.phone2;
      $ctrl.lastActivityTitle = stuff.lastActivityTitle;
      $ctrl.lastActivityToolTip = $ctrl.sortBy + ' ' + stuff.lastActivityToolTip;
      $ctrl.paymentsMadeTitle = stuff.paymentsMadeTitle;
      $ctrl.paymentsMadeToolTip = $ctrl.sortBy + ' ' + stuff.paymentsMadeToolTip;
      $ctrl.paymentsLeftTitle = stuff.paymentsLeftTitle;
      $ctrl.paymentsLeftToolTip = $ctrl.sortBy + ' ' + stuff.paymentsLeftToolTip;
      $ctrl.paymentsDifferenceTitle = stuff.paymentsDifferenceTitle;
      $ctrl.paymentsDifferenceToolTip = $ctrl.sortBy + ' ' + stuff.paymentsDifferenceToolTip;
      $ctrl.year = stuff.year;
      $ctrl.model = stuff.model;
      $ctrl.email = stuff.email;
      $ctrl.saleType = stuff.saleType;
      $ctrl.dmsDealId = stuff.dmsDealId;
      $ctrl.categories = stuff.categories;
      $ctrl.action = stuff.action;
      $ctrl.postNoteActivity = stuff.postNoteActivity;
      $ctrl.rescheduleActivity = stuff.rescheduleActivity;
      $ctrl.completedActivity = stuff.completedActivity;
      $ctrl.reassigned = stuff.reassigned;
      $ctrl.canceled = stuff.canceled;
      $ctrl.cancelledAppointment = stuff.cancelledAppointment;
      $ctrl.cancelledCall = stuff.cancelledCall;
      $ctrl.loggedActivity = stuff.loggedActivity;
      $ctrl.scheduledCallActivity = stuff.scheduledCallActivity;
      $ctrl.expiredActivity = stuff.expiredActivity;
      $ctrl.loading = stuff.loading;
      $ctrl.by = stuff.bykey;
      $ctrl.assignedTo = stuff.assignedTo;
      $ctrl.dueDate = stuff.dueDate;
      initAlertDropDown();
      $ctrl.alertListTitle = $ctrl.allAlertsCaption;
      $ctrl.makeListSelected = $ctrl.allMakesCaption;
    });
  }

  function initSelectedOpportunityInfo() {
    return userService.getUserProfile().then(function (userProfile) {
      return $q.all({
        'dealSheetPermissions': authorizationService.getDealSheetPermissions(),
        'userProfile': $q.resolve(userProfile),
        'users': dealerService.getUsers($ctrl.dealerIds[0]),
        'dealerSettings': dealerService.getDealerSettings($ctrl.dealerIds[0])
      });
    }).then(function (data) {
      $ctrl.permissions = data.dealSheetPermissions;
      $ctrl.isSltUser = data.userProfile.isSltUser;
      $ctrl.userProfile = data.userProfile;
      $ctrl.users = data.users;
      $ctrl.dealerSettings = data.dealerSettings; //console.log('dealsettings',$ctrl.dealerSettings);
      //if not eurolite dealer switch to standard opportunity page

      if ($ctrl.dealerSettings.productID != ProductTypes.euroLite) {
        $state.go('opportunities');
        return;
      }

      vehicleService.getMakes(data.userProfile.dealerIds[0]).then(function (dealerMakes) {
        $ctrl.dealerMakesOptions = dealerMakes.map(function (obj) {
          var rObj = {};
          rObj['id'] = obj.makeID;
          rObj['name'] = obj.makeName;
          return rObj;
        });
      }).catch(function (error) {//console.log(error);
      });
      $ctrl.isEuroLite = $ctrl.dealerSettings.productID == ProductTypes.euroLite;
      initGridOptions();
      initGridDataSource(data.userProfile.dealerIds[0]); //$ctrl.stopWorkingState(); 
    }).catch(function (error) {
      $ctrl.stopWorkingState(); //console.log(error);
    });
  }

  function initActionMenuTemplate(e) {
    var actionTemplate = '<div class="grid-action-menu kendo-angularjs">';
    actionTemplate += '<a class="btn btn-mini dropdown-toggle" data-toggle="dropdown" ng-click="$ctrl.onSelectedOpportunityId(dataItem)"><icon class="aa-icon-core-arrow-icon-000000 -smaller"></icon></a>';
    actionTemplate += '<ul class="dropdown-menu" uib-dropdown-menu>';
    actionTemplate += '<li><a ng-click="$ctrl.onActionMenuMarkAsSoldClick(dataItem._EntityID)" translate="euroliteMarkSold"></a></li>';
    actionTemplate += '<li><a><user-select-action  default-user="{id: dataItem.assignedUserId, fullName: dataItem.assignedUserFullName}" is-action-menu="true" users="$ctrl.users" permissions="$ctrl.permissions" on-select="$ctrl.onReassignComplete(user, dataItem._EntityID,dataItem);"></user-select-action></a></li>';
    actionTemplate += '<li><a ng-click="$ctrl.onActionMenuLogActivityClick(dataItem._EntityID,dataItem)" translate="customerConnectTab"></a></li>';
    actionTemplate += '<li><a ng-click="$ctrl.onActionMenuActivityNotesClick(dataItem._EntityID,dataItem)" translate="activityAndNotes"></a></li>';
    actionTemplate += '</ul>';
    actionTemplate += '</div>';
    return actionTemplate;
  }

  function initGridOptions() {
    $ctrl.mainGridOptions = {
      height: 645,
      scrollable: true,
      resizable: true,
      sortable: true,
      filterable: false,
      pageable: {
        input: false,
        numeric: true,
        buttonCount: 5,
        pageSizes: [50, 75, 100],
        change: onPagerPageChange
      },
      columns: [{
        field: "_Opportunity_Rank",
        template: scoreTemplate,
        width: "60px",
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.score + '">' + $ctrl.score + '</span>',
        attributes: {
          style: "text-align: center;"
        },
        headerAttributes: {
          "class": "align-right"
        }
      }, {
        field: "_EntityWatched",
        template: FlagTemplate,
        width: "50px",
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.flag + '"><icon class="icon aa-icon-flag -smaller"></icon></span>',
        attributes: {
          style: "text-align: center;"
        },
        headerAttributes: {
          "class": "align-right"
        }
      }, {
        field: "full_Name",
        template: openDealsheetTemplate,
        width: "140px",
        headerTemplate: '<span title="' + $ctrl.fullName + '">' + $ctrl.full + '</span>'
      }, {
        field: "customer_Owner",
        template: "#=data['customer_Owner']==null?'':data['customer_Owner']#",
        width: "140px",
        headerTemplate: '<span title="' + $ctrl.clientOwner + '">' + $ctrl.owner + '</span>'
      }, {
        field: "home_Phone",
        template: HomePhoneTemplate,
        hidden: true,
        width: "110px",
        headerTemplate: '<span title="' + $ctrl.customerHomePhone + '">' + $ctrl.phone1 + '</span>'
      }, {
        field: "cell_Phone",
        template: CellPhoneTemplate,
        width: "110px",
        headerTemplate: '<span title="' + $ctrl.customerCellPhone + '">' + $ctrl.phone2 + '</span>'
      }, {
        field: "sale_Type",
        template: SaleTypeTemplate,
        width: "80px",
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.saleType + '">' + $ctrl.saleType + '</span>'
      }, {
        field: "dmS_Deal_ID",
        template: "#=data['dmS_Deal_ID']== null ? '' : data['dmS_Deal_ID']#",
        hidden: true,
        width: "140px",
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.dmsDealId + '">' + $ctrl.dmsDealId + '</span>'
      }, {
        field: "current_Model_Name",
        template: "#=data['current_Model_Name']==null?'':data['current_Model_Name']#",
        width: "90px",
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.model + '">' + $ctrl.model + '</span>'
      }, {
        field: "current_Model_Year",
        template: "#=data['current_Model_Year']==null?'':data['current_Model_Year']#",
        width: "50px",
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.year + '">' + $ctrl.year + '</span>'
      }, {
        field: "Categories",
        template: CategoriesTemplate,
        width: "100px",
        sortable: {
          compare: categorySorting
        },
        headerTemplate: '<span title="' + $ctrl.sortBy + ' ' + $ctrl.categories + '">' + $ctrl.categories + '</span>'
      }, {
        field: "payment_Remaining",
        template: "#=data['payment_Remaining']==null?'':data['payment_Remaining']#",
        width: "80px",
        headerTemplate: '<span title="' + $ctrl.paymentsLeftToolTip + '">' + $ctrl.paymentsLeftTitle + '</span>'
      }, {
        field: "payments_Made",
        template: "#=data['payments_Made']==null?'':data['payments_Made']#",
        width: "85px",
        headerTemplate: '<span title="' + $ctrl.paymentsMadeToolTip + '">' + $ctrl.paymentsMadeTitle + '</span>'
      }, {
        field: "payment_Difference__",
        template: PaymentDifferencePctTemplate,
        width: "100px",
        headerTemplate: '<span title="' + $ctrl.paymentsDifferenceToolTip + '">' + $ctrl.paymentsDifferenceTitle + '</span>'
      }, {
        field: "performed_On",
        template: PerformedOnTemplate,
        width: "90px",
        headerTemplate: '<span title="' + $ctrl.lastActivityToolTip + '">' + $ctrl.lastActivityTitle + '</span>'
      }, {
        field: "offer",
        template: OfferTemplate,
        width: "60px",
        sortable: false,
        headerTemplate: '<span title="' + $ctrl.offer + '">' + $ctrl.offer + '</span>',
        attributes: {
          style: "text-align: center;"
        },
        headerAttributes: {
          "class": "align-right"
        }
      }, {
        field: "actions",
        width: "120px",
        template: initActionMenuTemplate,
        sortable: false,
        headerTemplate: '<span title="' + $ctrl.action + '">' + $ctrl.action + '</span>'
      }],
      dataBound: onDataboundInitKendoGrid,
      selectable: true,
      noRecords: {
        template: '<span class="noRecordsAvailable">' + $ctrl.noRecordsAvailable + '</span>'
      }
    };
    emailFieldName = "email".toLowerCase();
    homePhoneFieldName = "home_Phone".toLowerCase();
    cellPhoneFieldName = "cell_Phone".toLowerCase();
  }

  function initGridDataSource(dealerId) {
    var params = new Object();
    params.EntityIds = [];
    params.ReturnFields = ['[_Customer Owner]', '[Replacement Monthly Fuel Difference]', '[Rate]', '[sold By Name]', '[real Payoff Amount]', '[lease Residual]', '[cash Down]', '[_YearID]', '[replacement New Used]', '[Replacement Stock No]', '[replacement MSRP]', '[replacement Make Name]', '[replacement Trim Name]', '[replacement Full Trim Name]', '[Replacement Model Name]', '[replacement Rate]', '[is Over Allowance]', '[replacement Loan Amount]', '[preferred Equity]', '[replacement Payment]', '[_is Alert]', '[_is Flex Alert]', '[_is Conversion Alert]', '[lease Total Allowed Miles]', '[cash Down]', '[Current Trim Name]', '[_BankID]', '[_Client]', '[_CustomerID]', '[_CssPriority]', '[_DealerID]', '[_EntityID]', '[_EntityRead]', '[_EntityScheduled]', '[_EntityTypeID]', '[_EntityWatched]', '[_MakeID]', '[_Opportunity Rank]', '[_OwnerUserID]', '[_SaleID]', '[_SaleTypeID]', '[_StateID]', '[_TrimID]', '[_VehicleID]', '[Address 2]', '[Address]', '[Assigned To]', '[AssignedToUserID]', '[Balance To Maturity]', '[Bank Name]', '[Cell Phone]', '[City]', '[Company Name]', '[Current Make Name]', '[Current Model Name]', '[Current Model Year]', '[Current Payment]', '[Customer Owner]', '[Deal Term]', '[Deal Date]', '[DMS Deal ID]', '[Do Not Call]', '[Do Not Email]', '[Email]', '[Estimated Payoff]', '[First Name]', '[Full Name]', '[Home Phone]', '[Is Alert]', '[Is Appointment]', '[Is Contract End]', '[Is Conversion Alert]', '[Is Flex Alert]', '[Is In-Market Engaged]', '[Is In-Market]', '[Is Mileage Alert]', '[Is Service]', '[Is Warranty Alert]', //IsConquest removed
    '[Last Name]', '[Last Payment Date]', '[Lease Net Cap Cost]', '[Middle Name]', '[New Used]', '[NormZipCode]', '[OpenDate]', '[Paid Off]', '[Payment Difference %]', '[Payment Remaining]', '[Payments Made]', '[Purchase Amount Financed]', '[RO Appt Date]', '[Replacement Term]', '[Replacement Model Year]', '[Replacement MSRP]', '[Replacement Sale Type]', '[Replacement Stock No]', '[ReplacementMakeID]', '[ReplacementTrimID]', '[Sale Type]', '[State]', '[Still Own]', '[Trade Value]', '[Vin]', '[Zip Code]', '[Recent Action]', '[Performed On]', '[Performed By]'];
    params.DealerId = dealerId;
    params.Alerts = ['Alert', 'Flex', 'ContractEnd', 'Warranty'];
    params.CultureName = $ctrl.cultureName;
    return searchService.getEntitiesListCustom(params).then(function (result) {
      var gridData = result;
      var localePhoneFilter = $filter('localePhone');
      gridData.forEach(function (e) {
        if (e.cell_Phone) e.cell_PhoneString = localePhoneFilter(e.cell_Phone).replace(/[ ]/g, '');else e.cell_PhoneString = '';
        if (e.home_Phone) e.home_PhoneString = localePhoneFilter(e.home_Phone).replace(/[ ]/g, '');else e.home_PhoneString = '';
      });
      $ctrl.ds = new $ctrl.kendo.data.DataSource({
        pageSize: 50,
        data: gridData,
        model: {
          fields: {
            _opportunity_Rank: {
              field: "_Opportunity_Rank",
              type: "number"
            },
            full_name: {
              field: "full_Name",
              type: "string"
            },
            customer_owner: {
              field: "customer_Owner",
              type: "string"
            },
            sale_type: {
              field: "sale_Type",
              type: "string"
            },
            current_model_Name: {
              field: "current_Model_Name",
              type: "string"
            },
            current_model_Year: {
              field: "current_Model_Year",
              type: "string"
            },
            categories: {},
            payment_remaining: {
              field: "payment_Remaining",
              type: "string"
            },
            payments_made: {
              field: "payments_Made",
              type: "string"
            },
            payment_difference_pct: {
              field: "payment_Difference__",
              type: "number"
            },
            performed_On: {
              field: "performed_On",
              type: "date"
            },
            ro_Appt_Date: {
              field: "ro_Appt_Date",
              type: "date"
            },
            is_Alert: {
              field: "is_Alert",
              type: "string"
            },
            is_Flex_Alert: {
              field: "is_Flex_Alert",
              type: "string"
            },
            is_Warranty_Alert: {
              field: "is_Warranty_Alert",
              type: "string"
            },
            is_Contract_End: {
              field: "is_Contract_End",
              type: "string"
            },
            _MakeID: {
              field: "_MakeID",
              type: "number"
            },
            current_make_Name: {
              field: "current_Make_Name",
              type: "string"
            },
            _SaleTypeID: {
              field: "_SaleTypeID",
              type: "number"
            },
            dmS_Deal_ID: {
              field: "dmS_Deal_ID",
              type: "string"
            },
            zipCode: {
              field: "zip_Code",
              type: "string"
            },
            _EntityRead: {
              field: "_EntityRead",
              type: "string"
            },
            _EntityWatched: {
              field: "_EntityWatched",
              type: "string"
            },
            _EntityScheduled: {
              field: "_EntityScheduled",
              type: "number"
            },
            new_Used: {
              field: "new_Used",
              type: "string"
            },
            cell_PhoneString: {
              type: "string"
            },
            home_PhoneString: {
              type: "string"
            }
          }
        }
      });
      var userSettings = getUserColumnSettings();
      if (userSettings) $ctrl.onGridLayoutChanges(userSettings);
      var grid = $("#grid").data("kendoGrid");
      grid.setDataSource($ctrl.ds);

      if ($ctrl.filter) {
        if ($ctrl.filter.type) {
          if ($ctrl.filter.type == widgetTitleKeys.lease) {
            $ctrl.filterLease = true;
            $ctrl.filterUnscheduledOnly = true;
          }

          if ($ctrl.filter.type == widgetTitleKeys.retail) {
            $ctrl.filterRetail = true;
            $ctrl.filterBalloon = true;
            $ctrl.filterUnscheduledOnly = true;
          }

          onHeaderFilterSelect();
          grid.dataSource.filter({
            "logic": "and",
            "filters": allFilters
          });
        } else if ($ctrl.filter.opportunityBlock) {
          onAlertClick($ctrl.filter.opportunityBlock);
          grid.dataSource.filter({
            "logic": "and",
            "filters": allOpportunityFilters
          });
        }
      } else {
        grid.dataSource.filter({
          "logic": "and",
          "filters": allFilters
        });
      }

      grid.dataSource.read();
      $ctrl.stopWorkingState();
    }).catch(function () {
      $ctrl.stopWorkingState();
    });
  }

  function refreshData(data) {
    var gridData = data;
    var gridds = new $ctrl.kendo.data.DataSource({
      pageSize: 50,
      data: gridData,
      model: {
        fields: {
          _opportunity_Rank: {
            field: "_Opportunity_Rank",
            type: "number"
          },
          full_name: {
            field: "full_Name",
            type: "string"
          },
          customer_owner: {
            field: "customer_Owner",
            type: "string"
          },
          sale_type: {
            field: "sale_Type",
            type: "string"
          },
          current_model_Name: {
            field: "current_Model_Name",
            type: "string"
          },
          current_model_Year: {
            field: "current_Model_Year",
            type: "string"
          },
          categories: {},
          payment_remaining: {
            field: "payment_Remaining",
            type: "string"
          },
          payments_made: {
            field: "payments_Made",
            type: "string"
          },
          payment_difference_pct: {
            field: "payment_Difference__",
            type: "number"
          },
          performed_On: {
            field: "performed_On",
            type: "date"
          },
          ro_Appt_Date: {
            field: "ro_Appt_Date",
            type: "date"
          },
          is_Alert: {
            field: "is_Alert",
            type: "string"
          },
          is_Flex_Alert: {
            field: "is_Flex_Alert",
            type: "string"
          },
          is_Warranty_Alert: {
            field: "is_Warranty_Alert",
            type: "string"
          },
          is_Contract_End: {
            field: "is_Contract_End",
            type: "string"
          },
          _MakeID: {
            field: "_MakeID",
            type: "number"
          },
          current_make_Name: {
            field: "current_Make_Name",
            type: "string"
          },
          _SaleTypeID: {
            field: "_SaleTypeID",
            type: "number"
          },
          _EntityRead: {
            field: "_EntityRead",
            type: "string"
          },
          _EntityWatched: {
            field: "_EntityWatched",
            type: "string"
          },
          _EntityScheduled: {
            field: "_EntityScheduled",
            type: "number"
          },
          new_Used: {
            field: "new_Used",
            type: "string"
          }
        }
      }
    });
    var grid = getKendoGrid();
    grid.setDataSource(gridds);
  }

  function getAlertTranslations(alertName) {
    var result = '';

    switch (alertName) {
      case 'alert':
        result = $ctrl.alertName;
        break;

      case 'flexAlert':
        result = $ctrl.flexAlertName;
        break;

      case 'warranty':
        result = $ctrl.warrantyName;
        break;

      case 'contractEnd':
        result = $ctrl.contractEndName;
        break;
    }

    return result;
  }

  function initAlertDropDown() {
    $ctrl.alertOptions = alertTypeDescriptorsEuroLite.map(function (o) {
      var rObj = {};
      rObj['id'] = o.id;
      rObj['name'] = getAlertTranslations(o.name);
      rObj['key'] = o.name;
      return rObj;
    });
  } // event handlers ---------------------------------------------------


  function onReloadGridDS() {
    initGridDataSource($ctrl.userProfile.dealerIds[0]);
  }

  function onSelectedOpportunityId(row) {
    var grid = getKendoGrid();
    grid.select(row);
    $ctrl.opportunity.id = row._EntityID;
    return true;
  }

  function categorySorting(a, b) {
    var aAlertListCount = 0;
    var bAlertListCount = 0;
    if (a.is_Alert == 'Yes') aAlertListCount++;
    if (a.is_Flex_Alert == 'Yes') aAlertListCount++;
    if (a.is_Warranty_Alert == 'Yes') aAlertListCount++;
    if (a.is_Contract_End == 'Yes') aAlertListCount++;
    if (b.is_Alert == 'Yes') bAlertListCount++;
    if (b.is_Flex_Alert == 'Yes') bAlertListCount++;
    if (b.is_Warranty_Alert == 'Yes') bAlertListCount++;
    if (b.is_Contract_End == 'Yes') bAlertListCount++;

    if (aAlertListCount == bAlertListCount == 1) {
      if (a.is_Alert == 'Yes' && b.is_Alert == 'Yes') return 0;
      if (a.is_Flex_Alert == 'Yes' && b.is_Flex_Alert == 'Yes') return 0;
      if (a.is_Contract_End == 'Yes' && b.is_Contract_End == 'Yes') return 0;
      if (a.is_Warranty_Alert == 'Yes' && b.is_Warranty_Alert == 'Yes') return 0;
      if (a.is_Alert == 'Yes') return 1;
      if (b.is_Alert == 'Yes') return -1;
      if (a.is_Flex_Alert == 'Yes') return 1;
      if (b.is_Flex_Alert == 'Yes') return -1;
      if (a.is_Contract_End == 'Yes') return 1;
      if (b.is_Contract_End == 'Yes') return -1;
      if (a.is_Warranty_Alert == 'Yes') return 1;
      if (b.is_Warranty_Alert == 'Yes') return -1;
    }

    if (aAlertListCount > bAlertListCount) return 1;else return -1;
  } //step 1 Notes


  function onActionMenuActivityNotesClick(id, row) {
    if ($ctrl.openedActivityOrNotesEntity != null) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "pleaseConfirm";
          },
          message: function message() {
            return openEuroliteNewLogActivityTranslationKey;
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "continue"; // translation key
          }
        }
      });
      modal.result.then(function () {
        closeAllDetails();
        $ctrl.openedActivityOrNotesEntity = null;
        $ctrl.isLogActivityOpen = false;
        $ctrl.isNotesOpen = false;
        onActionOpenNotes(id, row);
      }).catch(function () {
        $ctrl.stopWorkingState();
      });
    } else onActionOpenNotes(id, row);
  } //step 2 Notes


  function onActionOpenNotes(id, row) {
    $ctrl.currentTab = $ctrl.tabs.ActivityNotesList;
    $ctrl.currentGridRow = row;
    $ctrl.currentEntityId = row._EntityID; // set this to retain selection after grid reloads

    $ctrl.gridloaded = 0; // set this to recheck paging after grid loads

    if ($ctrl.isNotesOpen == false && $ctrl.openedActivityOrNotesEntity == null) {
      $ctrl.openedActivityOrNotesEntity = row._EntityID;
      $ctrl.isNotesOpen = true;
    }

    $ctrl.opportunity.id = id;
    $ctrl.opportunity.dealerId = $ctrl.userProfile.dealerIds[0];
    $ctrl.opportunity.uid = row.uid;
    $ctrl.startWorkingState();
    $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(function (activity) {
      $ctrl.activities = activity;
      opportunityService.getOwnerUserInfo($ctrl.opportunity.id).then(function (result) {
        var status;

        if (row._EntityScheduled) {
          status = opportunityStatuses.scheduled;
        } else if (row._EntitySold) {
          status = opportunityStatuses.sold;
        } else if (row._EntityClosed) {
          status = opportunityStatuses.closed;
        } else {
          status = opportunityStatuses.open;
        }

        $ctrl.currentTab = $ctrl.tabs.ActivityNotesList;
        $ctrl.opportunity.ownerUserInfo = result.data;
        $ctrl.opportunity.assignedUserId = row._OwnerUserID;
        $ctrl.opportunity.assignedUserFullName = row.full_name;
        $ctrl.opportunity.expirationDate = row.expirationDate;
        $ctrl.opportunity.status = status;
        $ctrl.lastOpenedLogActivityRow = row;
        var grid = getKendoGrid();
        grid.expandRow("tr[data-uid='" + row.uid + "']");
        grid.content.animate({
          scrollTop: grid.select().position().top
        }, 400);
        $ctrl.stopWorkingState();
      }).catch(function () {
        $ctrl.stopWorkingState();
      });
    });
  } //step 3 Notes


  function onActivityNotesClosed(row) {
    closeAllDetails();
    $ctrl.openedActivityOrNotesEntity = null;
    $ctrl.isLogActivityOpen = false;
    $ctrl.isNotesOpen = false;
    $ctrl.startWorkingState(); //var grid = getKendoGrid(); 
    //grid.bind("dataBound", selectGrid);

    onReloadGridDS();
  }

  function onActivityNotesScheduleActionComplete() {
    $ctrl.startWorkingState();
    $ctrl.opportunity.status = opportunityStatuses.open;
    $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(function (activity) {
      $ctrl.activities = activity;
      opportunityService.getOpportunityEntityOnly($ctrl.opportunity.id).then(function (opportunity) {
        $ctrl.opportunity.status = opportunity.status;
        $ctrl.stopWorkingState();
      }).catch(function () {
        $ctrl.stopWorkingState();
      });
    }).catch(function () {
      $ctrl.stopWorkingState();
    });
  }

  function onReassignComplete(user, id, row) {
    $ctrl.currentGridRow = row;
    $ctrl.currentEntityId = row._EntityID;
    $ctrl.gridloaded = 0;
    $ctrl.startWorkingState();
    opportunityService.updateAssignedUser(id, user.id).then(function (success) {
      if (success) {
        $ctrl.opportunity.assignedUserId = user.id;
        $ctrl.opportunity.assignedUserFullName = user.fullName; //var grid = getKendoGrid();
        //grid.bind("dataBound", selectGrid);

        onReloadGridDS();
      }
    }).catch(function () {
      $ctrl.stopWorkingState();
    });
  }

  function onActionMarkAsSold(opportunityId) {
    var dataMarkAsSold = {
      activityActionID: ActivityActionEnum.Log_Call,
      activityActionSubTypeID: 1,
      activityId: null,
      entityID: opportunityId,
      isFollowUpScheduleAppt: false,
      notes: "",
      resultID: activityResults.phoneCall.soldVehicle
    };
    onLogActivitySubmit(dataMarkAsSold);
  }

  function onActionMenuMarkAsSoldClick(opportunityId) {
    //step 2
    $ctrl.opportunity.id = opportunityId;
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "pleaseConfirm";
        },
        message: function message() {
          return openEuroliteMarkAsSoldTranslationKey;
        },
        submitButtonText: function submitButtonText() {
          return "confirm"; // translation key
        }
      }
    });
    modal.result.then(function () {
      onActionMarkAsSold($ctrl.opportunity.id);
    });
  } //step 1 log


  function onActionMenuLogActivityClick(id, row) {
    // user clicked submit, check if user has multiple opened edited Log activities: step 1, 2, 3
    if ($ctrl.openedActivityOrNotesEntity != null) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "pleaseConfirm";
          },
          message: function message() {
            return openEuroliteNewLogActivityTranslationKey;
          },
          submitButtonVisible: function submitButtonVisible() {
            return true;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          submitButtonText: function submitButtonText() {
            return "continue"; // translation key
          }
        }
      });
      modal.result.then(function () {
        closeAllDetails();
        $ctrl.openedActivityOrNotesEntity = null;
        $ctrl.isLogActivityOpen = false;
        $ctrl.isNotesOpen = false;
        onActionOpenLogActivity(id, row);
      });
    } else onActionOpenLogActivity(id, row);
  } //step 2 log


  function onActionOpenLogActivity(id, row) {
    $ctrl.currentGridRow = row;
    $ctrl.currentEntityId = row._EntityID;
    $ctrl.gridloaded = 0;

    if ($ctrl.isLogActivityOpen == false && $ctrl.openedActivityOrNotesEntity == null) {
      $ctrl.openedActivityOrNotesEntity = row._EntityID;
      $ctrl.isLogActivityOpen = true;
    }

    var grid = getKendoGrid();
    grid.select(row);
    $ctrl.opportunity.id = id;
    $ctrl.opportunity.uid = row.uid;
    $ctrl.startWorkingState();
    customerService.getCustomer($ctrl.opportunity.id).then(function (customer) {
      $ctrl.customer = customer;
      $ctrl.opportunity.customer = $ctrl.customer;
      opportunityService.getOwnerUserInfo($ctrl.opportunity.id).then(function (result) {
        $ctrl.currentTab = $ctrl.tabs.LogActivity;
        $ctrl.opportunity.ownerUserInfo = result.data;
        $ctrl.opportunity.assignedUserId = row._OwnerUserID;
        var hasTextingModule = userService.isDealerEnabledForModule($ctrl.opportunity.dealerId, modules.textMessaging);
        $ctrl.isTextingEnabled = hasTextingModule && ($ctrl.permissions.sendTextSales || $ctrl.permissions.sendTextService); // For now set this to false until we determine that Lite should have this function:

        $ctrl.isTextingEnabled = false;
        $ctrl.lastOpenedLogActivityRow = row;
        grid.expandRow("tr[data-uid='" + row.uid + "']");
        grid.content.animate({
          scrollTop: grid.select().position().top
        }, 400);
        $ctrl.stopWorkingState();
      }).catch(function () {
        closeAllDetails();
        $ctrl.openedActivityOrNotesEntity = null;
        $ctrl.isLogActivityOpen = false;
        $ctrl.isNotesOpen = false;
        $ctrl.stopWorkingState();
      });
    });
  } //step 3 log


  function onLogActivityComplete(result) {
    var grid = getKendoGrid();

    if (!result) {
      return;
    } // user data entry error
    // user clicked cancel and passed grid row id


    if (result.uid && result.status == 'cancel') {
      closeAllDetails();
      $ctrl.openedActivityOrNotesEntity = null;
      $ctrl.isLogActivityOpen = false;
      $ctrl.isNotesOpen = false;
      return;
    } else {
      onLogActivitySubmit(result); //step 5
    }
  } //step 4 log


  function onLogActivityCompleteMarkAsSold(result) {
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "pleaseConfirm";
        },
        message: function message() {
          return openEuroliteMarkAsSoldTranslationKey;
        },
        submitButtonText: function submitButtonText() {
          return "confirm"; // translation key
        }
      }
    });
    modal.result.then(function () {
      onLogActivitySubmit(result); //step 5
    });
  } //step 5 log


  function onLogActivitySubmit(result) {
    //step 3
    $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
    startWorkingState();
    activityService.postActivitySubmitAction(result).then(function (data) {
      if (result.isFollowUpScheduleAppt) {
        logger.success("postActivityFollowupMsg");
        $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Schedule_Appt;
        $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
      } else {
        logger.success("postActivitySuccessMsg");
        $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
      } // if vehicle no longer owns


      if (result.resultID == activityResults.phoneCall.noLongerOwns || result.resultID == activityResults.note.noLongerOwns) {
        activityService.updateCustomerDoesNotOwnVehicle(result.entityID);
      } // close panel after sumit


      var grid = getKendoGrid();
      closeAllDetails();
      $ctrl.openedActivityOrNotesEntity = null;
      $ctrl.isLogActivityOpen = false;
      $ctrl.isNotesOpen = false; //grid.bind("dataBound", selectGrid);

      onReloadGridDS();
    }).catch(function () {
      closeAllDetails();
      $ctrl.openedActivityOrNotesEntity = null;
      $ctrl.isLogActivityOpen = false;
      $ctrl.isNotesOpen = false;
      $ctrl.stopWorkingState();
    });
  }

  function onUpdateCustomer(customer, row) {
    $ctrl.startWorkingState();
    customerService.updateCustomer(customer).then(function (success) {
      if (success) {
        var homePhoneNumber = customer.homePhoneNumber && customer.homePhoneNumber.value != undefined ? customer.homePhoneNumber.value : null;
        var workPhoneNumber = customer.workPhoneNumber && customer.workPhoneNumber.value != undefined ? customer.workPhoneNumber.value : null;
        var cellPhoneNumber = customer.cellPhoneNumber && customer.cellPhoneNumber.value != undefined ? customer.cellPhoneNumber.value : null;
        var emailAddress = customer.emailAddress && customer.emailAddress.value != undefined ? customer.emailAddress.value : null;
        updateColumnInSelectedRow([customer.doNotCall, customer.doNotCall, customer.doNotEmail], [homePhoneFieldName, cellPhoneFieldName, emailFieldName], [homePhoneNumber, cellPhoneNumber, emailAddress]);
        $ctrl.stopWorkingState();
      } else {
        $ctrl.stopWorkingState();
      }
    }, function (error) {//console.log(error);
    }).catch(function () {
      $ctrl.stopWorkingState();
    });
  }

  function applyAlertFilter(alerts) {}

  function setActivities(activities) {
    $ctrl.activities = activities;
  }

  function onHeaderFilterSelect() {
    if ($ctrl.userCustomFilter && $ctrl.userCustomFilter.replace(/[ ]/g, '').length < 3) $ctrl.userCustomFilter = null;
    $ctrl.onFilterSelect();
  }

  function onAlertClick(result) {
    allOpportunityFilters = [];
    var headerFilters = headerFilterList();
    var customerQuickSearchFilters = getAllCustomerSearchFilters();

    if ($ctrl.markSelectedAlerts.indexOf(result) > -1) {
      //$ctrl.markSelectedAlerts = [];
      //result = '';
      return;
    } else $ctrl.markSelectedAlerts = [result];

    var grid = $("#grid").data("kendoGrid");

    if (result == 'alert') {
      headerFilters.push({
        "field": "is_Alert",
        "operator": "eq",
        "value": "Yes"
      });
    } else if (result == 'flexAlert') {
      headerFilters.push({
        "field": "is_Flex_Alert",
        "operator": "eq",
        "value": "Yes"
      });
    } else if (result == 'warranty') {
      headerFilters.push({
        "field": "is_Warranty_Alert",
        "operator": "eq",
        "value": "Yes"
      });
    } else if (result == 'contractEnd') {
      headerFilters.push({
        "field": "is_Contract_End",
        "operator": "eq",
        "value": "Yes"
      });
    } else {
      // Clear
      grid.dataSource.filter({});
    }

    $ctrl.alertsSelected = $ctrl.alertOptions.filter(function (a) {
      return a.key == result;
    });
    if ($ctrl.alertsSelected.length > 0) $ctrl.alertListTitle = $ctrl.alertsSelected.map(function (x) {
      return x.name;
    }).sort().join(', ');else $ctrl.alertListTitle = $ctrl.allAlertsCaption;

    if (customerQuickSearchFilters) {
      headerFilters.push({
        "logic": customerQuickSearchFilters.qtypeOperator,
        "filters": customerQuickSearchFilters.filter
      });
    }

    grid.dataSource.filter({
      "logic": "and",
      "filters": headerFilters
    });
    allOpportunityFilters = grid.dataSource.filter().filters;
    grid.setDataSource(grid.dataSource);
    grid.dataSource.read();
  }

  function headerFilterList() {
    var filterList = [];
    var allOrMakeConditions = [];
    var allOrSaleTypeConditions = [];
    var makesList = $ctrl.makesSelected.map(function (x) {
      return x.name;
    });

    if (makesList.length > -1) {
      makesList.forEach(function (item) {
        allOrMakeConditions.push({
          "field": "current_Make_Name",
          "operator": "eq",
          "value": item
        });
      });
    }

    if (!($ctrl.filterUsed && $ctrl.filterNew)) {
      if ($ctrl.filterUsed) filterList.push({
        "field": "new_Used",
        "operator": "eq",
        "value": "Used"
      });
      if ($ctrl.filterNew) filterList.push({
        "field": "new_Used",
        "operator": "eq",
        "value": "New"
      });
    }

    if ($ctrl.filterCash) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.cash
      });
    }

    if ($ctrl.filterLease) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.lease
      });
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.onePayLease
      });
    }

    if ($ctrl.filterRetail) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.retail
      });
    }

    if ($ctrl.filterBalloon) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.balloon
      });
    }

    if ($ctrl.filterUnscheduledOnly) {
      filterList.push({
        "field": "_EntityScheduled",
        "operator": "eq",
        "value": 0
      });
    }

    if ($ctrl.filterUnreadOnly) {
      filterList.push({
        "field": "_EntityRead",
        "operator": "eq",
        "value": 0
      });
    }

    if ($ctrl.filterFlaggedOnly) {
      filterList.push({
        "field": "_EntityWatched",
        "operator": "eq",
        "value": 1
      });
    }

    if (allOrMakeConditions.length > 0) {
      filterList.push({
        "logic": "or",
        "filters": allOrMakeConditions
      });
    }

    if (allOrSaleTypeConditions.length > 0) {
      filterList.push({
        "logic": "or",
        "filters": allOrSaleTypeConditions
      });
    }

    return filterList;
  }

  function onFilterSelect() {
    $ctrl.startWorkingState();
    var customerQuickSearchFilters = getAllCustomerSearchFilters();
    allFilters = [];
    var allOrMakeConditions = [];
    var allOrSaleTypeConditions = [];
    var isAlertSelectedAfter = $ctrl.alertsSelected.map(function (x) {
      return x.key;
    }).indexOf('alert') > -1;
    var isFlexAlertSelectedAfter = $ctrl.alertsSelected.map(function (x) {
      return x.key;
    }).indexOf('flexAlert') > -1;

    if (isAlertSelectedAfter && isFlexAlertSelectedAfter) {
      var index = -1;

      if ($ctrl.isAlertSelectedBefore) {
        // remove alert 
        $ctrl.isAlertSelectedBefore = !$ctrl.isAlertSelectedBefore;
        $ctrl.isFlexAlertSelectedBefore = !$ctrl.isFlexAlertSelectedBefore;
        index = $ctrl.alertsSelected.map(function (x) {
          return x.key;
        }).indexOf('alert');
        $ctrl.alertsSelected.splice(index, 1);
      } else {
        // remove flexAlert
        $ctrl.isAlertSelectedBefore = !$ctrl.isAlertSelectedBefore;
        $ctrl.isFlexAlertSelectedBefore = !$ctrl.isFlexAlertSelectedBefore;
        index = $ctrl.alertsSelected.map(function (x) {
          return x.key;
        }).indexOf('flexAlert');
        $ctrl.alertsSelected.splice(index, 1);
      }
    } else {
      $ctrl.isAlertSelectedBefore = isAlertSelectedAfter;
      $ctrl.isFlexAlertSelectedBefore = isFlexAlertSelectedAfter;
    }

    var alertsList = $ctrl.alertsSelected.map(function (x) {
      return x.key;
    });
    var makesList = $ctrl.makesSelected.map(function (x) {
      return x.name;
    });
    if ($ctrl.alertsSelected.length > 0) $ctrl.alertListTitle = $ctrl.alertsSelected.map(function (x) {
      return x.name;
    }).sort().join(', ');else $ctrl.alertListTitle = $ctrl.allAlertsCaption;
    if ($ctrl.makesSelected.length > 0) $ctrl.makeListSelected = $ctrl.makesSelected.map(function (x) {
      return x.name;
    }).sort().join(', ');else $ctrl.makeListSelected = $ctrl.allMakesCaption;

    if (makesList.length > -1) {
      makesList.forEach(function (item) {
        allOrMakeConditions.push({
          "field": "current_Make_Name",
          "operator": "eq",
          "value": item
        });
      });
    }

    if (alertsList.length > -1) {
      $ctrl.markSelectedAlerts = alertsList;
      alertsList.forEach(function (item) {
        if (item == "alert") {
          allFilters.push({
            "field": "is_Alert",
            "operator": "eq",
            "value": "Yes"
          });
        } else if (item == "flexAlert") {
          allFilters.push({
            "field": "is_Flex_Alert",
            "operator": "eq",
            "value": "Yes"
          });
        } else if (item == "warranty") {
          allFilters.push({
            "field": "is_Warranty_Alert",
            "operator": "eq",
            "value": "Yes"
          });
        } else if (item == "contractEnd") {
          allFilters.push({
            "field": "is_Contract_End",
            "operator": "eq",
            "value": "Yes"
          });
        }
      });
    }

    if (!($ctrl.filterUsed && $ctrl.filterNew)) {
      if ($ctrl.filterUsed) allFilters.push({
        "field": "new_Used",
        "operator": "eq",
        "value": "Used"
      });
      if ($ctrl.filterNew) allFilters.push({
        "field": "new_Used",
        "operator": "eq",
        "value": "New"
      });
    }

    if ($ctrl.filterCash) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.cash
      });
    }

    if ($ctrl.filterLease) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.lease
      });
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.onePayLease
      });
    }

    if ($ctrl.filterRetail) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.retail
      });
    }

    if ($ctrl.filterBalloon) {
      allOrSaleTypeConditions.push({
        "field": "_SaleTypeID",
        "operator": "eq",
        "value": saleTypes.balloon
      });
    }

    if ($ctrl.filterUnscheduledOnly) {
      allFilters.push({
        "field": "_EntityScheduled",
        "operator": "eq",
        "value": 0
      });
    }

    if ($ctrl.filterUnreadOnly) {
      allFilters.push({
        "field": "_EntityRead",
        "operator": "eq",
        "value": 0
      });
    }

    if ($ctrl.filterFlaggedOnly) {
      allFilters.push({
        "field": "_EntityWatched",
        "operator": "eq",
        "value": 1
      });
    }

    if (allOrMakeConditions.length > 0) {
      allFilters.push({
        "logic": "or",
        "filters": allOrMakeConditions
      });
    }

    if (allOrSaleTypeConditions.length > 0) {
      allFilters.push({
        "logic": "or",
        "filters": allOrSaleTypeConditions
      });
    }

    if (customerQuickSearchFilters) {
      allFilters.push({
        "logic": customerQuickSearchFilters.qtypeOperator,
        "filters": customerQuickSearchFilters.filter
      });
    }

    var grid = $("#grid").data("kendoGrid");
    grid.dataSource.filter({
      "logic": "and",
      "filters": allFilters
    });
    grid.setDataSource(grid.dataSource);
    grid.dataSource.read();
    $timeout(function () {
      $ctrl.stopWorkingState();
    }, 500);
  }

  function onGridLayoutChanges(result) {
    setUserColumnSettings(result);
    var columnsSelected = [];
    var columnsHidden = [];
    result.forEach(function (ele) {
      ele.fields.forEach(function (item) {
        if (item.isChecked) columnsSelected.push(item.name);else columnsHidden.push(item.name);
      });
    });
    var grid = $("#grid").data("kendoGrid");
    columnsHidden.forEach(function (item) {
      grid.hideColumn(item);
    });
    columnsSelected.forEach(function (item) {
      grid.showColumn(item);
    });
    $ctrl.currentGridColumns = columnsSelected;
  } // grid helpers ---------------------------------------------------


  function isLogActivityOpen(rowId) {}

  function isActivityNotesOpen(rowId) {}

  function goToState(test) {
    var grid = getKendoGrid();
    grid.expandRow("tr[data-uid='" + $ctrl.opportunity.uid + "']");
  }

  function startWorkingState() {
    $ctrl.hideWhenBusy = true;
    $ctrl.dealsheetWorking = $q.defer().promise;
  }

  function stopWorkingState() {
    $ctrl.hideWhenBusy = false;
    $ctrl.dealsheetWorking = null;
  }

  function closeAllDetails() {
    var grid = $("#grid").data("kendoGrid");
    $(".k-master-row").each(function (index) {
      grid.collapseRow(this);
    });
  }

  function getKendoGrid() {
    return $("#grid").data("kendoGrid");
  }

  function OfferTemplate(e) {
    var actionTemplate = '<div class="icon-o2o-offer"><a class="o2o-offer kendo-angularjs">';
    actionTemplate += '<icon icon="\'o2o-offer\'" class="-size-18-svg" ng-click="$ctrl.onOfferTemplateClick(dataItem)"></icon>';
    actionTemplate += '</a></div>';
    return actionTemplate;
  }

  $ctrl.openDealsheetTemplate = openDealsheetTemplate;

  function openDealsheetTemplate(e) {
    var template = "<a href='#' ng-click='$ctrl.openDealSheet(dataItem)'>" + e.full_Name + "</a>";
    return template;
  }

  $ctrl.openDealSheet = openDealSheet;

  function openDealSheet(e) {
    dealSheetService.openDealSheetLiteModal(e, null, callBackDealSheetClosed);
  }

  function callBackDealSheetClosed() {
    startWorkingState();
    onReloadGridDS();
  }

  function onOfferTemplateClick(e) {
    startWorkingState();
    var opportunity = {
      id: e._EntityID,
      type: e._EntityTypeID,
      stillOwned: e.still_Own == "Yes",
      dealerId: e._DealerID,
      paidOff: e.paid_Off == "Yes",
      currentContract: {
        saleID: e._SaleID,
        saleType: e._SaleTypeID,
        lastPaymentDate: e.last_Payment_Date,
        dealDate: e.deal_Date,
        payoffAmount: e.estimated_Payoff,
        bankID: e._BankID,
        adjustedBalance: e.balance_To_Maturity,
        //check
        term: e.deal_Term,
        paymentsRemaining: e.payment_Remaining,
        payment: e.current_Payment
      },
      replacementContract: {
        useOverrideTerm: false,
        term: e.replacement_Term
      },
      currentVehicle: {
        tradeValue: e.trade_Value,
        makeID: e._MakeID,
        trimID: e._TrimID
      },
      replacementVehicle: {
        trimID: e.replacementTrimID,
        //not given replacement_Full_Trim_Name
        makeID: e.replacementMakeID,
        //not given replacement_Make_Name
        yearID: e.replacement_Model_Year,
        IsConquest: e._SaleTypeID == 3 ? 1 : 0,
        price: e.replacement_MSRP,
        type: e.replacement_Sale_Type,
        msrp: e.replacement_MSRP,
        stockNo: e.replacement_Stock_No //odometer: e.odometer,  //unknown
        //vin: e.vin

      },
      customer: {
        address: {
          stateId: e._StateID,
          normalizedZipCode: e.normZipCode
        }
      }
    };
    legacyService.generateUpgradeProposal(opportunity).then(downloadArraybufferResponse({
      contentType: 'application/pdf',
      filename: 'UpgradeProposal_' + $ctrl.opportunity.id + '.pdf'
    })).catch(function (err) {
      alert("We encountered a problem.  Please try again later."); // todo:  eliminate alert and localize/style error message

      log.log(err);
    }).finally(function () {
      stopWorkingState();
    });
  }

  function SaleTypeTemplate(e) {
    return e.sale_Type;
  }

  function CellPhoneTemplate(e) {
    if (!e.cell_Phone) return '';
    var localePhoneFilter = $filter('localePhone');
    if (e.do_Not_Call.toLowerCase() == "yes" || e.do_Not_Call.toLowerCase() == "true") return "<span class='do-no-call'>" + localePhoneFilter(e.cell_Phone) + "</span>";
    return localePhoneFilter(e.cell_Phone);
  }

  function EmailTemplate(e) {
    if (!e.email) return '';
    var email = e.email ? e.email : '';
    if (e.do_Not_Email.toLowerCase() == "yes" || e.do_Not_Email.toLowerCase() == "true") return "<span class='do-no-call'>" + email + "</span>";
    return email;
  }

  function onFlagClicked(data) {
    $ctrl.startWorkingState();
    var entityWatchStatus = data._EntityWatched;
    var newEntityWatchStatus = !entityWatchStatus;

    if (entityWatchStatus) {
      opportunityService.markOpportunityUnwatched(data._EntityID).then(function () {
        data._EntityWatched = newEntityWatchStatus;
        var grid = getKendoGrid();
        $ctrl.refreshData(grid.dataSource.data());
        var row = $("tr[data-uid='" + data.uid + "']");
        var children = $(row).children('td[role="gridcell"]');
        var cell = children.eq(1);

        if (newEntityWatchStatus) {
          cell.children(0).children(0).removeClass("aa-icon-flag");
          cell.children(0).children(0).addClass("aa-icon-flag-filled");
        } else {
          cell.children(0).children(0).removeClass("aa-icon-flag-filled");
          cell.children(0).children(0).addClass("aa-icon-flag");
        }

        $ctrl.stopWorkingState();
      }).catch(function () {
        $ctrl.stopWorkingState();
      });
    } else {
      opportunityService.markOpportunityWatched(data._EntityID).then(function () {
        data._EntityWatched = newEntityWatchStatus;
        var grid = getKendoGrid();
        $ctrl.refreshData(grid.dataSource.data());
        var row = $("tr[data-uid='" + data.uid + "']");
        var children = $(row).children('td[role="gridcell"]');
        var cell = children.eq(1);

        if (newEntityWatchStatus) {
          cell.children(0).children(0).removeClass("aa-icon-flag");
          cell.children(0).children(0).addClass("aa-icon-flag-filled");
        } else {
          cell.children(0).children(0).removeClass("aa-icon-flag-filled");
          cell.children(0).children(0).addClass("aa-icon-flag");
        }

        $ctrl.stopWorkingState();
      }).catch(function () {
        $ctrl.stopWorkingState();
      });
    }
  }

  function getLastActionKeys(data, activityActionID) {
    switch (data) {
      case "Post Note":
        return $ctrl.postNoteActivity;

      case "Log Call":
        return $ctrl.loggedActivity;

      case "Schedule Call":
      case "Schedule Appt":
        return $ctrl.scheduledCallActivity;

      case "Reschedule":
        return $ctrl.rescheduleActivity;

      case "Next Action":
        return $ctrl.completedActivity;

      case "Reassign":
        return $ctrl.reassigned;

      case "Cancel":
        if (activityActionID == ActivityActionEnum.Cancel_Appt) return $ctrl.cancelledAppointment;else return $ctrl.cancelledCall;

      case "Expire Phone Call":
      case "Expire Appointment":
        return $ctrl.expiredActivity;

      default:
        return data;
    }
  }

  function PerformedOnTemplate(e) {
    var html = '';

    if (e.performed_On) {
      if (!e.lastActivityToolTipDesc) e.lastActivityToolTipDesc = $ctrl.loading;
      html = '<span uib-popover-html="dataItem.lastActivityToolTipDesc" popover-append-to-body="true" popover-trigger="\'mouseenter\'" popover-placement="right" ng-mouseover="$ctrl.onLastActivityMouseOver(dataItem)">' + moment(e.performed_On).format('DD/MM/YYYY') + '</span>';
    }

    return html;
  }

  function FlagTemplate(e) {
    var flagHtml = '';
    if (e._EntityWatched) flagHtml = "<icon class='icon aa-icon-flag-filled -smaller'></icon>";else flagHtml = "<icon class='icon aa-icon-flag -smaller'></icon>";
    flagHtml = '<a href="#" title="' + $ctrl.flag + '" class="nounderline" ng-click="$ctrl.onFlagClicked(dataItem)">' + flagHtml + '</a>';
    return flagHtml;
  }

  function HomePhoneTemplate(e) {
    if (!e.home_Phone) return '';
    var localePhoneFilter = $filter('localePhone');
    if (e.do_Not_Call.toLowerCase() == "yes") return "<span class='do-no-call'>" + localePhoneFilter(e.home_Phone) + "</span>";
    return localePhoneFilter(e.home_Phone);
  }

  function CategoriesTemplate(e) {
    var alertHtml = '';
    if (e.is_Alert == 'Yes') alertHtml += '<alert [alert-type-id]="100" size="small"></alert>';
    if (e.is_Flex_Alert == 'Yes') alertHtml += '<alert [alert-type-id]="200" size="small"></alert>';
    if (e.is_Warranty_Alert == 'Yes') alertHtml += '<alert [alert-type-id]="1000" size="small"></alert>';
    if (e.is_Contract_End == 'Yes') alertHtml += '<alert [alert-type-id]="600" size="small"></alert>';
    return alertHtml;
  }

  function PaymentDifferencePctTemplate(e) {
    var upOrDown = null;
    if (e.payment_Difference__ > 0) upOrDown = 'up';
    if (e.payment_Difference__ < 0) upOrDown = 'down';
    if (upOrDown != null) return "<span class='payment-change-" + upOrDown + "'>" + $ctrl.kendo.toString(e.payment_Difference__, 'n0') + '%' + "</span>";
    return '0%';
  }

  function scoreTemplate(e) {
    var rank = e._Opportunity_Rank ? e._Opportunity_Rank : '0';
    return "<span class='score'>" + rank + "</span>";
  } // used for donot call and donot email states


  function updateColumnInSelectedRow(fieldStates, fieldNames, fieldValues) {
    var grid = getKendoGrid();
    var selectedRow = grid.select();
    var rowChildren = $(selectedRow).children('td[role="gridcell"]');
    var localePhoneFilter = $filter('localePhone');

    for (var i = 0; i < grid.columns.length; i++) {
      var column = grid.columns[i];
      var indexOfMatchField = fieldNames.indexOf(column.field.toLowerCase());

      if (indexOfMatchField > -1) {
        var cell = rowChildren.eq(i);
        var renderValue = fieldValues[indexOfMatchField]; // keep format

        if (fieldNames[indexOfMatchField] == homePhoneFieldName || fieldNames[indexOfMatchField] == cellPhoneFieldName) if (renderValue != null) renderValue = localePhoneFilter(renderValue);
        if (renderValue == null) renderValue = "";
        if (fieldStates[indexOfMatchField] == true) cell.html("<span class='do-no-call'>" + renderValue + "</span>");else cell.html(renderValue);
      }
    }
  } // some kendo code reference
  // will be used in next version
  //var grid = $("#grid").data("kendoGrid");
  //var dataItem = grid.dataSource.get(2);
  //dataRow.set(fieldName,fieldValue); 
  //var grid = $("#grid").data("kendoGrid");
  //var selectedItem = grid.dataItem(grid.select());
  //selectedItem.children().eq(2).addClass('phone-number');
  //var selectedRowIndex = selectedRow.index();
  //var dataItem = grid.dataItem("tbody tr:eq("+ selectedRowIndex + ")");
  //grid.collapseRow("tr[data-uid='" + result.uid + "']");
  //var grid = getKendoGrid();
  //grid.collapseRow("tr[data-uid='" + row.uid + "']");
  //var index = $ctrl.activityNoteHistoryStack.indexOf(row.uid);
  //$ctrl.activityNoteHistoryStack.splice(index,1);
  //closeAllDetails();

}