<div class="row">
    <div class="col-md-12">
        <div class="settingTitle" translate="dsAdmin_leaseMileageDefaults"></div>
        <tooltip [titleKey]="'dsAdmin_leaseMileageDefaults'" [contentKey]="'dsAdmin_leaseMileageDefaultsTooltip'">
        </tooltip>
        <div class="settingItem">
            <div class="row">
                <div class="col-md-2 settingHeader" translate="make">
                </div>
                <div class="col-md-2 settingHeader" translate="yearlyMileage">
                </div>
                <div class="col-md-1 settingHeader" translate="mileagePenaltyLabel">
                </div>
            </div>
        </div>
        <div *ngFor="let item of dsa.dealerMakeLeaseMileage" class="row settingItem">
            <div class="col-md-2">
                {{ item.makeName }}
            </div>
            <div class="col-md-2">
                <input name="DealerMakeLeaseMileage.DefaultLeaseAnnualMiles" class="input-sm textboxMed" type="number"
                       [(ngModel)]="item.defaultLeaseAnnualMiles" />
            </div>
            <div class="col-md-1">
                <input name="DealerMakeLeaseMileage.DefaultLeasePenaltyPerMile" class="input-sm textboxMed"
                       type="number" step="0.01" [(ngModel)]="item.defaultLeasePenaltyPerMile" />
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div class="row settingTitle" translate="dsAdmin_scheduledActivityHandling"></div>
        <tooltip [titleKey]="'dsAdmin_scheduledActivityHandling'"
                 [contentKey]="'dsAdmin_scheduledActivityHandlingTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-2" translate="dsAdmin_ActivitySchedulingRange">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.ActivityFutureWindow" type="number" class="input-sm textboxSm" maxlength="3"
                       [(ngModel)]="dsa.dealerSetting.activityFutureWindow" /> {{ 'days' | translate }}
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-2" translate="dsAdmin_activityExpiration">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.ActivityExpirationWindow" type="number" class="input-sm textboxSm"
                       maxlength="3" [(ngModel)]="dsa.dealerSetting.activityExpirationWindow" /> {{ 'days' | translate }}
            </div>
        </div>
        <div *ngIf="dsa.textMessagingEnabled">
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_textSMSMessages"></div>
            <tooltip [titleKey]="'dsAdmin_textSMSMessages'" [contentKey]="'dsAdmin_textSMSMessagesTooltip'"></tooltip>
            <div *ngIf="dsa.isTextMessagingNewNumberSettingsEnabled">
                <div class="row settingItem">
                    <div class="col-md-2">
                        <label for="textMessagingAreaCode" translate="dsAdmin_textMessageAreaCode" style="padding-right: 10px;"></label>
                        <input class="input-sm textboxMed" type="number" [(ngModel)]="textMessagingAreaCode" id="textMessagingAreaCode" style="width:80px;" />
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-primary smallButton single-line-control"
                                (click)="onViewNumbers()"
                                translate="dsAdmin_viewNumbersSMS"></button>
                    </div>
                </div>
                <div class="row settingItem" *ngIf="searchReturnedLessThanTwoLocalNumbers">
                    <div class="col-md-12">
                        <div class="override-state-label single-line-control" translate="dsAdmin_searchByStateLabel">
                        </div>

                        <select name="TwilioOverrideState" class="form-control override-state single-line-control"
                                #overrideStateSelect [(ngModel)]="overrideStateCode" (change)="onOverrideStateChanged()">
                            <option value="">{{'dsAdmin_SelectArea' | translate}}</option>
                            <option *ngFor="let item of dsa.state" value="{{ item.stateAbbr }}">
                                {{ item.stateAbbr }}-{{ item.stateName }}
                            </option>
                        </select>

                        <button type="button" class="btn btn-primary smallButton single-line-control"
                                (click)="onSearchByState()" translate="search"></button>
                    </div>
                </div>
                <div class="row settingItem">
                    <div class="col-md-2">
                        <div style="float:left;margin: 5px 10px 0 0" translate="dsAdmin_Sales">
                        </div>
                        <div style="float:left">
                            <select name="TwilioSendTextSalesNumber" class="form-control"
                                    [(ngModel)]="twilioSendTextSalesNumber" (change)="onNewNumberChanged($event)"
                                    [disabled]="newTextNumbers.length == 0">
                                <option value="tbd">(Select Number)</option>
                                <option *ngFor="let item of newTextNumbers" value="{{ item }}">
                                    {{ formatPhoneNumber(item) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div style="float:left;margin: 5px 10px 0 0" translate="serviceName">
                        </div>
                        <div style="float:left">
                            <select name="TwilioSendTextServiceNumber" class="form-control"
                                    [(ngModel)]="twilioSendTextServiceNumber" (change)="onNewNumberChanged($event)"
                                    [disabled]="newTextNumbers.length == 0">
                                <option value="tbd">(Select Number)</option>
                                <option *ngFor="let item of newTextNumbers" value="{{ item }}">
                                    {{ formatPhoneNumber(item) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="!twilioNumbersValid" class="col-md-3" style="color:red;margin-top:8px"
                         translate="dsAdmin_twoSeparateNumbersError">
                    </div>
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2">
                    {{ 'dsAdmin_assignedSalesNumbers' | translate }}:
                </div>
                <div class="col-md-2">
                    {{ formatPhoneNumber(twilioSendTextSalesNumber) }}
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2">
                    {{ 'dsAdmin_assignedServiceNumbers' | translate }}:
                </div>
                <div class="col-md-2">
                    {{ formatPhoneNumber(twilioSendTextServiceNumber) }}
                </div>
            </div>
            <!--<div class="row settingItem">
                <div class="col-md-2">
                    {{ 'dsAdmin_routeVoiceCallsTo' | translate }}:
                </div>
                <div class="col-md-2">
                    <input class="input-sm textbox" type="text" [(ngModel)]="routeVoiceCallsToNumber"
                           (change)="routeVoiceCallsToNumber = formatPhoneNumber(routeVoiceCallsToNumber)"
                           style="width:150px" />
                </div>
            </div>-->
            <div class="row settingItem" style="padding-left:50px;">
                <div class="col-md-4 divider">
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-4">
                    <span style="font-weight:bold;">{{'dsAdmin_CXM_NumberProvisioning' | translate}}</span>
                </div>
            </div>
            <div *ngIf="dsa.isTextMessagingNewNumberSettingsEnabledCXM">
                <div class="row settingItem">
                    <div class="col-md-2">
                        <label for="textMessagingAreaCode" translate="dsAdmin_textMessageAreaCode" style="padding-right: 10px;"></label>
                        <input class="input-sm textboxMed" type="number" [(ngModel)]="textMessagingAreaCodeCXM" id="textMessagingAreaCodeCXM" style="width:80px;" />
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-primary smallButton single-line-control"
                                (click)="onViewNumbersCXM()"
                                translate="dsAdmin_viewNumbersSMS"></button>
                    </div>
                </div>
                <div class="row settingItem" *ngIf="searchReturnedLessThanTwoLocalNumbersCXM">
                    <div class="col-md-12">
                        <div class="override-state-label single-line-control" translate="dsAdmin_searchByStateLabel">
                        </div>

                        <select name="TwilioOverrideStateCXN" class="form-control override-state single-line-control"
                                #overrideStateSelectCXM [(ngModel)]="overrideStateCodeCXM" (change)="onOverrideStateChangedCXM()">
                            <option value="">{{'dsAdmin_SelectArea' | translate}}</option>
                            <option *ngFor="let item of dsa.state" value="{{ item.stateAbbr }}">
                                {{ item.stateAbbr }}-{{ item.stateName }}
                            </option>
                        </select>

                        <button type="button" class="btn btn-primary smallButton single-line-control"
                                (click)="onSearchByStateCXM()" translate="search"></button>
                    </div>
                </div>
                <div class="row settingItem">
                    <div class="col-md-2">
                        <div style="float:left;margin: 5px 10px 0 0" translate="dsAdmin_Sales">
                        </div>
                        <div style="float:left">
                            <select name="TwilioSendTextSalesNumberCXM" class="form-control"
                                    [(ngModel)]="twilioSendTextSalesNumberCXM" (change)="onNewNumberChangedCXM($event)"
                                    [disabled]="newTextNumbersCXM.length == 0">
                                <option value="tbd">(Select Number)</option>
                                <option *ngFor="let item of newTextNumbersCXM" value="{{ item }}">
                                    {{ formatPhoneNumber(item) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div style="float:left;margin: 5px 10px 0 0" translate="serviceName">
                        </div>
                        <div style="float:left">
                            <select name="TwilioSendTextServiceNumberCXM" class="form-control"
                                    [(ngModel)]="twilioSendTextServiceNumberCXM" (change)="onNewNumberChangedCXM($event)"
                                    [disabled]="newTextNumbersCXM.length == 0">
                                <option value="tbd">(Select Number)</option>
                                <option *ngFor="let item of newTextNumbersCXM" value="{{ item }}">
                                    {{ formatPhoneNumber(item) }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="!twilioNumbersValidCXM" class="col-md-3" style="color:red;margin-top:8px"
                         translate="dsAdmin_twoSeparateNumbersError">
                    </div>
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2">
                    {{ 'dsAdmin_assignedSalesNumbers' | translate }}:
                </div>
                <div class="col-md-2">
                    {{ formatPhoneNumber(twilioSendTextSalesNumberCXM) }}
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2">
                    {{ 'dsAdmin_assignedServiceNumbers' | translate }}:
                </div>
                <div class="col-md-2">
                    {{ formatPhoneNumber(twilioSendTextServiceNumberCXM) }}
                </div>
            </div>
            <!--<div class="row settingItem">
                <div class="col-md-2">
                    {{ 'dsAdmin_routeVoiceCallsTo' | translate }}:
                </div>
                <div class="col-md-2">
                    <input class="input-sm textbox" type="text" [(ngModel)]="routeVoiceCallsToNumberCXM"
                           (change)="routeVoiceCallsToNumberCXM = formatPhoneNumber(routeVoiceCallsToNumberCXM)"
                           style="width:150px" />
                </div>
            </div>-->
            <div *ngIf="!allTwilioNumbersUnique" class="row settingItem">
                <div class="col-md-4" style="color:red;" translate="dsAdmin_NonUniqueNumbersError">
                </div>
            </div>
        </div>
        <div *ngIf="!isDealerPandoXEnabled">
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="leadManagement"></div>
            <tooltip [titleKey]="'leadManagement'" [contentKey]="'dsAdmin_leadManagementTooltip'"></tooltip>
            <div class="settingItem">
                <label>
                    <input type="checkbox" [(ngModel)]="dsa.leadAssignOwnerAccess" /><span class="settingCheckboxText" translate="dsAdmin_enableAutomatedAssignments"></span>
                </label>
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div *ngIf="dsa.isEnableVHR">
            <div class="settingTitle" translate="dsAdmin_vehicleHistoryReports"></div>
            <tooltip [titleKey]="'dsAdmin_vehicleHistoryReports'" [contentKey]="'dsAdmin_vehicleHistoryReportsTooltip'">
            </tooltip>
            <div class="settingItem">
                <label style="float:left">
                    <input type="checkbox" [(ngModel)]="dsa.dealerSetting.enableAutoCheck" /><span class="settingCheckboxText" translate="dsAdmin_enableAutoCheckIntegration"></span>
                </label>
            </div>
            <div class="settingItem" *ngIf="dsa.dealerSetting.enableAutoCheck">
                <input name="DealerSetting.AutoCheckCustomerId" type="text"
                       [(ngModel)]="dsa.dealerSetting.autoCheckCustomerId" class="input-sm textbox" maxlength="50"
                       style="width:150px" placeholder="{{ 'dsAdmin_customerIdPlaceholder' | translate }}" />
            </div>
            <div class="settingItem">
                <div style="float:left">
                    <label style="float:left">
                        <input type="checkbox"
                               [(ngModel)]="dsa.dealerSetting.enableCarFax" /><span class="settingCheckboxText"
                                                                                    translate="dsAdmin_enableCarFaxIntegration"></span>
                    </label>
                </div>
            </div>
            <div class="settingItem" *ngIf="dsa.dealerSetting.enableCarFax && dsa.dealerSetting.carFaxVersion == '1'">
                <div style="float:left">
                    <input name="DealerSetting.CarFaxUsername" type="text"
                           [(ngModel)]="dsa.dealerSetting.carFaxUsername" class="input-sm textbox" maxlength="64"
                           style="width:150px" placeholder="{{ 'dsAdmin_carFaxIDNumber' | translate }}" />
                </div>
                <div style="float:left;margin-left:10px">
                    <input name="DealerSetting.CarFaxPassword" type="password"
                           [(ngModel)]="dsa.dealerSetting.carFaxPassword" class="input-sm textbox" maxlength="255"
                           style="width:150px" placeholder="{{ 'dsAdmin_carFaxPasswordPlaceholder' | translate }}" />
                </div>
            </div>

            <div class="settingItem" *ngIf="dsa.dealerSetting.enableCarFax && dsa.dealerSetting.carFaxVersion == '2'">
                <div style="float:left">
                    <div style="float:left;margin-left:10px">
                        <label style="float:left">
                            <input type="checkbox"
                                   [(ngModel)]="dsa.dealerSetting.carFaxAutoPurchase" /><span class="settingCheckboxText"
                                                                                              translate="dsAdmin_enableAutoPurchase"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="settingItem" *ngIf="dsa.isCanadianDealer">
                <div style="float:left">
                    <label style="float:left">
                        <input type="checkbox"
                               [(ngModel)]="dsa.dealerSetting.enableCarProof" /><span class="settingCheckboxText"
                                                                                      translate="dsAdmin_enableCarProof"></span>
                    </label>
                </div>
            </div>
            <div class="settingItem" *ngIf="dsa.dealerSetting.enableCarProof">
                <input name="DealerSetting.CarProofAccessKey" type="text"
                       [(ngModel)]="dsa.dealerSetting.carProofAccessKey" class="input-sm textbox" maxlength="255"
                       style="width:600px" placeholder="{{ 'dsAdmin_carproofAccessKey' | translate }}" />
            </div>
        </div>
        <div class="settingItem" *ngIf="!dsa.isEnableVHR">
            <button type="button" class="btn btn-primary smallButton" (click)="enableVehicleHistoryReports()"
                    translate="dsAdmin_enableVehicleHistoryReports"></button>
        </div>
        <div style="clear:both" class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_vAutoIntegration">vAuto Integration</div>
        <tooltip [titleKey]="'dsAdmin_vAutoIntegration'" [contentKey]="'dsAdmin_vAutoIntegrationTooltip'">
        </tooltip>
        <div class="settingItem">
            <input name="DealerSetting.vAutoEntityId" type="text"
                    [(ngModel)]="dsa.vAutoEntityId" class="input-sm textbox" maxlength="40"
                    style="width:150px" placeholder="{{ 'dsAdmin_vAutoEntityIdPlaceholder' | translate }}" />
        </div>
        <div style="clear:both" *ngIf="dsa.isViewSDOSettings">
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_orphanOwners"></div>
            <tooltip [titleKey]="'dsAdmin_orphanOwners'" [contentKey]="'dsAdmin_orphanOwnersTooltip'"></tooltip>
            <div class="settingItem">
                <div class="row">
                    <div class="col-md-2" translate="owner">
                    </div>
                    <div class="col-md-3">
                        <angular2-multiselect [data]="dsa.users" [(ngModel)]="selectedOwners"
                                              [settings]="ownersDropdownSettings"></angular2-multiselect>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>