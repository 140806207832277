"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var events_1 = require("../../../../modules/oidc-auth/events");
var deal_sheet_data_service_1 = require("../../../../services/deal-sheet-data.service");
var mobile_deal_sheet_data_caching_service_1 = require("../../../../services/mobile-deal-sheet-data-caching.service");
var mobile_routes_1 = require("../mobile-routes");
var MobileDealSheetComponent = /** @class */ (function () {
    function MobileDealSheetComponent(mobileDealSheetDataCachingService, dealSheetDataService, stateService, transitionService) {
        this.mobileDealSheetDataCachingService = mobileDealSheetDataCachingService;
        this.dealSheetDataService = dealSheetDataService;
        this.stateService = stateService;
        this.transitionService = transitionService;
        this.routes = mobile_routes_1.MobileRoutes;
        this.selectedNav = this.routes.vehicle;
    }
    MobileDealSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.busy = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId)
            .subscribe(function (dealSheetData) {
            events_1.externalLoggedIn$.next(); // Initializes header
            _this.showPullForward = _this.dealSheetDataService.shouldDisplayPullForward(dealSheetData.opportunity);
            _this.dealSheetData = dealSheetData;
            _this.stateService.go(_this.selectedNav);
        });
        this.transitionService.onSuccess({ to: 'mobileDealSheet.**' }, function (transition) {
            _this.selectedNav = transition.$to().name;
        });
    };
    MobileDealSheetComponent.prototype.selectNav = function (route) {
        this.selectedNav = route;
        this.stateService.go(route);
    };
    MobileDealSheetComponent.prototype.startWorkingState = function () {
        var _this = this;
        this.busy = new Promise(function (resolve) {
            _this.stopWorkingState = resolve;
        });
    };
    MobileDealSheetComponent.prototype.stopWorkingState = function () { };
    MobileDealSheetComponent.prototype.ngOnDestroy = function () {
    };
    return MobileDealSheetComponent;
}());
exports.MobileDealSheetComponent = MobileDealSheetComponent;
