<div class="activity-icon qa-activity-icon">
    <img class="svg-icon" src="../../../images/icons/icon-sms-lake.svg" />
</div>
<div class="activity-data">
    <div>
        <span>
            <strong>
                <span class="qa-activity-datetime activity-timestamp">{{activity.submittedDateTime | localeDate}} </span>
            </strong>
            <span>{{'textMessagesActivityLabel' | translate:salesOrServiceLabel}}</span>
        </span>
    </div>

    <div>
        <span class="qa-text-message-sent-by">{{'textMessageActivitySentByLabel' | translate}}: {{sentBy}}</span>
        <button class="btn btn-link qa-text-message-btn" (click)="displayMessages = !displayMessages" data-toggle="collapse"
            [attr.data-target]="'#' + activity.activityId + 'messages'">
            {{(displayMessages ? 'hideMessages' : 'viewMessages') | translate}}
        </button>
    </div>


    <div class="collapse" [id]="activity.activityId + 'messages'" *ngIf="displayMessages">
        <div>
            <div *ngFor="let textMessage of activity.textMessages" [ngClass]="{'user-message': textMessage.outgoing, 'customer-message': !textMessage.outgoing, row: true}">

                <div class="col-sm-2 column-wrap">
                    <span *ngIf="textMessage.outgoing">{{textMessage.userFirstName}}
                        {{textMessage.userLastName}}</span>
                    <span *ngIf="!textMessage.outgoing">{{textMessage.customerFirstName}}
                        {{textMessage.customerLastName}}</span>
                    <span class="sent-time">{{formatSentDateTime(textMessage.sentDateTime)}}</span>
                </div>
                <div class="col-sm-6 column-wrap" (click)="handleUpgradeProposalLinkClick($event, textMessage)">
                    <span [innerHtml]="textMessage.safeMessage"></span>
                </div>

                <div class="col-sm-2 ">
                    <div *ngIf="textMessage.hasUpgradeProposalLink">
                        <a *ngIf="!mobileView" class="btn view-upgrade-proposal-link qa-text-view-upgrade-proposal" (click)="viewUpgradeProposal(textMessage.activityId)">
                            {{('viewUpgradeProposal' | translate)}}</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>