import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { DealerSettingResponseDto, DealerSettingRequestDto } from '../../generated/models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingsService } from '../../services/dealer-settings.service';
import { DealerService } from '../../services/dealer.service';
import { AdminSettingsDealerInfoComponent } from './admin-settings-dealerinfo.component';
import { ValidationSettingDto } from '../../generated/models/validation-setting-dto';
import { AdminSettingsAdvancedComponent } from './admin-settings-advanced.component';
import { AdminSettingsCreditConvertComponent } from './admin-settings-creditconvert.component';
import { AdminSettingsGeneralComponent } from './admin-settings-general.component';
import { AdminSettingTabs } from './admin-settings-tabs';
import { AdminSettingsOpportunityComponent } from './admin-settings-opportunity.component';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'admin-settings',
    templateUrl: './admin-settings.component-ng.html'
})

export class AdminSettingsComponent implements OnInit {

    @ViewChild('dealerInfoComponent', {static: false}) asDealerInfoComponent: AdminSettingsDealerInfoComponent;
    @ViewChild('creditConvertComponent', {static: false}) asCreditConvertComponent: AdminSettingsCreditConvertComponent;
    @ViewChild('advancedComponent', {static: false}) asAdvancedComponent: AdminSettingsAdvancedComponent;
    @ViewChild('generalComponent', {static: false}) asGeneralComponent: AdminSettingsGeneralComponent;
    @ViewChild('opportunityComponent', {static: false}) asOpportunityComponent: AdminSettingsOpportunityComponent;
    bsModalRef: BsModalRef;
    public formLoaded: Boolean = false;
    public busy: Promise<any>;
    public dsa: DealerSettingResponseDto = {};
    public selectedDealerId: number;
    public dealers: any;
    public tabIndex: number = AdminSettingTabs.General;
    public tabCreditConvertCssClass: string;
    public tabProductCssClass: string;
    public disableSaveCancel: boolean = false;
    get tabs() { return AdminSettingTabs; }

    constructor(
        private changeDetector: ChangeDetectorRef,
        private dealerService: DealerService,
        public modalService: BsModalService,
        public tService: TranslateService,
        public dsService: DealerSettingsService,
        public logger: ToasterLoggerService,
        @Inject(DOCUMENT) private document) {
    }

    public ngOnInit() {
        this.dealers = this.dealerService.getSelectedDealers();
        this.selectedDealerId = this.dealers[0].id;

        var dsDealerId = localStorage.getItem('dsDealerId');

        if (dsDealerId != null && this.dealers.some((d: any) => d.id == Number(dsDealerId)))
            this.selectedDealerId = Number(dsDealerId);
    }

    public ngAfterViewInit() {
        this.busy = this.loadDealer(this.selectedDealerId).then(result => {
            // execute validation for all fields (formats and highlights fields where necessary)
            this.validateAllFieldsUpdateUI();
            this.dsa.isFormChanged = false;  // we need to set formChanged back to false here as validateAllFieldsUpdateUI will set it to true.
        });
    }

    public onTabChanged(tabIndex: number) {
        this.tabIndex = tabIndex;
        localStorage.setItem("dsTabId", String(tabIndex));
        if (this.asDealerInfoComponent && this.asDealerInfoComponent.selectedMakeId != 0) {
            this.asDealerInfoComponent.selectedMakeId = 0;
            this.asDealerInfoComponent.makeChanged();
        }
    }

    public dealerChanged() {
        this.busy = this.loadDealer(this.selectedDealerId).then(result => {
            // execute validation for all fields (formats and highlights fields where necessary)
            this.validateAllFieldsUpdateUI();
        });
    }

    private validateAllFieldsUpdateUI() {
        for (let item of this.dsa.validationSetting) {
            var target = this.document.getElementsByName(item.fieldName);

            if (target != null && target.length > 0) {
                this.onValidateAfterEntry(target[0]);
                this.onValidateDuringEntry(target[0]);
            }
        }
    }

    // validations we do on keyup.. we set attributes for the input, validate the field value, and set the 'invalid" cssClass if necessary
    public onValidateDuringEntry(target: any) {
        if (event != null && target != null) {
            var isValid: Boolean = true;

            var vs = this.dsa.validationSetting.find(v => v.fieldName == target.name);

            if (vs != null) {
                if (vs != null && vs.decimalPlaceStep)
                    target.step = vs.decimalPlaceStep;

                if (vs.maxCharacters)
                    target.maxLength = vs.maxCharacters;

                // validate the field data
                isValid = this.validateField(vs, target.value);
            }

            if (isValid)
                target.classList.remove("invalid");
            else
                target.classList.add("invalid");
        }
    }

    // validations we do on blur
    public onValidateAfterEntry(target: any) {
        if (event != null && target != null) {
            if (target.name != "dealershipInfo_DealerMake") {
                this.dsa.isFormChanged = true;
            }
            this.setValidationSettingEnabled(); // in case enabled/disabled validation settings have changed
            var vs = this.dsa.validationSetting.find(v => v.fieldName == target.name);

            if (vs != null && vs.decimalPlaces)
                target.value = parseFloat(target.value).toFixed(vs.decimalPlaces);

            if (vs != null) {
                // set min/max values for the input
                if (vs.maxValue != null) {
                    target.max = vs.maxValue;
                    if (target.value && target.value > vs.maxValue) {
                        target.value = vs.maxValue;
                        this.changeDetector.detectChanges();
                        target.classList.remove("invalid");
                    }
                }

                if (vs.minValue != null) {
                    target.min = vs.minValue;
                    if (target.value && target.value < vs.minValue) {
                        target.value = vs.minValue;
                        this.changeDetector.detectChanges();
                        target.classList.remove("invalid");
                    }
                }
            }
        }
    }

    private validateAllFields() {
        this.setValidationSettingEnabled();

        var isFormValid: boolean = true;

        // we attempt to valid all datasets here (only where validation rules exist)
        for (let propName of Object.keys(this.dsa)) {
            var value: any = (this.dsa as any)[propName];

            if (Array.isArray(value)) {              // validate arrays of objects
                if (propName != "validationSetting") {
                    for (let item of value) {
                        for (let objPropName of Object.keys(item)) {
                            var objValue: any = (item as any)[objPropName];
                            if (!this.validateFieldOnSave(propName + "." + objPropName, objValue))
                                isFormValid = false;
                        }
                    }
                }
            }
            else if (typeof value == "object")      // validate single objects
                if (value != null) {
                    for (let objPropName of Object.keys(value)) {
                        var objValue: any = (value as any)[objPropName];
                        if (!this.validateFieldOnSave(propName + "." + objPropName, objValue))
                            isFormValid = false;
                    }
                }
                else {
                    // we only care about arrays and objects, so ignore anything else
                }
        }

        if (!this.dsa.isDealerLogoUpdate && this.dsa.dealerLogoImage != null)
            this.dsa.dealerLogoImage.logoImageBinary = null;

        var fieldNameToHighlight: String = null;

        // custom validations start here
        if (!this.asGeneralComponent.twilioNumbersValid) {
            isFormValid = false;
            this.tabIndex = AdminSettingTabs.General;
        }

        if (!this.asDealerInfoComponent.customerCulturesDifferent) {
            isFormValid = false;
            this.tabIndex = AdminSettingTabs.DealerInfo;
        }

        if (!this.asOpportunityComponent.leaseContractEndRangeValid || !this.asOpportunityComponent.purchaseContractEndRangeValid) {
            isFormValid = false;
            this.tabIndex = AdminSettingTabs.Opportunity;
        }

        return isFormValid;
    }

    private setValidationSettingEnabled() {
        // disable validation when tab is hidden or feature disabled
        for (let item of this.dsa.validationSetting) {
            switch (item.pageNum) {
                case AdminSettingTabs.CreditConvert: // creditConvert
                    item.enabled = this.dsa.dealerCreditSoftPull != null && this.dsa.dealerCreditSoftPull.useCreditSoftPull && this.dsa.isCreditConvertDealerSettingEnabled;
                    break;
                case AdminSettingTabs.Product: // product
                    item.enabled = this.dsa.isProductSettingsEnabled;
                    break;
                case AdminSettingTabs.CRMLeadsDelivery: // crm leads delivery
                    item.enabled = this.dsa.dealerAdfSetting.enabled;
                    break;
            }

            if (item.fieldName == "DealerSetting.AutoCheckCustomerId")
                item.enabled = this.dsa.dealerSetting.enableAutoCheck;

            if (item.fieldName == "DealerSetting.CarFaxUsername" || item.fieldName == "DealerSetting.CarFaxPassword")
                item.enabled = this.dsa.dealerSetting.enableCarFax && this.dsa.dealerSetting.carFaxVersion == "1";

            if (item.fieldName == "DealerSetting.CarFaxAutoPurchase")
                item.enabled = this.dsa.dealerSetting.enableCarFax && this.dsa.dealerSetting.carFaxVersion == "2";

            if (item.fieldName == "DealerSetting.CarProofAccessKey")
                item.enabled = this.dsa.dealerSetting.enableCarProof;
        }
    }

    public onSave() {
        this.disableSaveCancel = true;
        this.asGeneralComponent.onSave();
        this.asCreditConvertComponent.onSave();

        if (this.validateAllFields()) {
            try {
                this.busy = this.dsService.UpdateDealerSettingAll(this.dsa as DealerSettingRequestDto).then(result => {
                    switch (result) {
                        case 0: // saved successfully
                        case 1: // no changes - not saved
                            this.logger.success(this.tService.instant("changesSaved"));

                            if (result == 0) {
                                this.busy = this.loadDealer(this.selectedDealerId).then(result => {
                                    // execute validation for all fields (formats and highlights fields where necessary)
                                    this.validateAllFieldsUpdateUI();
                                });
                            }

                            break;
                        case 5: // unable to provision text number
                            this.logger.error(this.tService.instant("dsAdmin_unableToProvisionTextNumber"));
                            break;
                        default:
                            this.logger.error(this.tService.instant("generalErrorMessageKey"));
                            break;
                    }

                    this.disableSaveCancel = false;
                });
            }
            catch (err) {
                this.logger.error(this.tService.instant("generalErrorMessageKey"));
                this.disableSaveCancel = false;
            }
        }
        else {
            this.logger.error(this.tService.instant("validationErrorResubmit"));
            this.validateAllFieldsUpdateUI();
            this.disableSaveCancel = false;
        }
    }

    public onCancel() {
        this.disableSaveCancel = true;
        this.busy = this.loadDealer(this.selectedDealerId).then(result => {
            // execute validation for all fields (formats and highlights fields where necessary)
            this.validateAllFieldsUpdateUI();
            this.logger.warning(this.tService.instant("settingsReturnedToDefault"));
            this.disableSaveCancel = false;
        });
    }

    /*  No longer needed....  use isFormChanged property if you need to determine if the form changed as below
    public disableSaveCancel() {
       // return !this.dsa.isFormChanged || this.asDealerInfoComponent.isReadOnly;

       // just leave disable/save buttons enabled for now
       return false;
    }
    */

    // on save, we don't need to update any css and we need to set the tab where the last error occurred and return validation status
    private validateFieldOnSave(path: string, value: string) {
        var vs = this.dsa.validationSetting.find(v => v.fieldName.toLowerCase() == path.toLowerCase());

        if (vs != null) {
            var isValid: Boolean = this.validateField(vs, value);
            if (!isValid)
                this.tabIndex = vs.pageNum;

            return isValid;
        }

        return true;
    }

    private validateField(vs: ValidationSettingDto, value: string): Boolean {
        var isFieldEmpty: boolean = (value == null || value.length == 0);

        if (vs.enabled) {
            if (vs.required && isFieldEmpty)
                return false;

            if (vs.maxCharacters && value != null && value.length > vs.maxCharacters)
                return false;

            if (vs.isURL && !isFieldEmpty) {
                // regex taken from legacy to ensure same behavior
                var urlRegEx = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
                if (!value.match(urlRegEx))
                    return false;
            }

            var numValue: number = Number(value);

            if (!Number.isNaN(numValue)) {
                if (vs.minValue != null && numValue < vs.minValue)
                    return false;

                if (vs.maxValue != null && numValue > vs.maxValue)
                    return false;
            }
        }

        if (vs.fieldName == "DealerSetting.LeaseContractEndRangeFrom" || vs.fieldName == "DealerSetting.LeaseContractEndRangeTo")
            return this.asOpportunityComponent.leaseContractEndRangeValid;

        if (vs.fieldName == "DealerSetting.PurchaseContractEndRangeFrom" || vs.fieldName == "DealerSetting.PurchaseContractEndTo")
            return this.asOpportunityComponent.purchaseContractEndRangeValid;

        return true;
    }

    private loadDealer(dealerId: Number) {
        this.formLoaded = false;
        return this.dsService.GetDealerSettingAll(this.selectedDealerId).then(result => {
            this.dsa = result;
            this.dsa.isFormChanged = false;
            this.formLoaded = true;
            this.changeDetector.detectChanges();
            // initialize all the components to update any special settings
            this.asDealerInfoComponent.makeChanged();
            this.asCreditConvertComponent.ngOnInit();
            this.asAdvancedComponent.ngOnInit();
            this.asGeneralComponent.ngOnInit();

            // hide the creditConvert tab when necessary  [hidden]="true" doesn't work for some reason?????
            this.tabCreditConvertCssClass = (this.dsa.isCreditConvertDealerSettingEnabled) ? "" : "hidden";
            this.tabProductCssClass = (this.dsa.isProductSettingsEnabled) ? "" : "hidden";

            // set the default tab
            var dsTabId = localStorage.getItem('dsTabId');
            if (dsTabId != null) {
                this.tabIndex = Number(dsTabId);
                if (!this.dsa.isCreditConvertDealerSettingEnabled && this.tabIndex == AdminSettingTabs.CreditConvert) // if creditConvert settings are disabled, we can't default to that tab
                    this.tabIndex = AdminSettingTabs.General;
                else if (!this.dsa.isProductSettingsEnabled && this.tabIndex == AdminSettingTabs.Product)
                    this.tabIndex = AdminSettingTabs.General;
            }

            localStorage.setItem("dsDealerId", String(this.dsa.dealerSetting.dealerID));
            this.setValidationSettingEnabled();
        });
    }
}