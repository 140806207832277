<ng-container *ngIf="isTextingEnabled">

    <div [attr.title]="tooltip" class="text-action-wrapper">
        <a *ngIf="!isShortcutButton" class="btn qa-text-btn" (click)="openDialog()"
            [ngClass]="{'disabled link-disabled-but-looks-enabled': !isTextable}">
            <span [ngClass]="{'icon-class icon -size-24 text-icon': true, 
            'sms-icon': isTextable, 
            'do-not-text-icon': !isTextable}"></span>
            <div class="caption">
                <label>{{'textActivity' | translate}}</label>
            </div>
        </a>
        <button type="button" class="btn btn-primary btn-sm shortcut-action-btn" 
            translate="sendText" 
            [attr.title]="tooltip"
            [disabled]="!isTextable"
            *ngIf="isShortcutButton" (click)="openDialog()">Send Text</button>
    </div>

    <div class="modal fade text-modal" id="textModal" tabindex="-1" role="dialog">
        <div class="modal-dialog texting-dialog" role="document">
            <div class="modal-content">
                <div class="modal-title-bar">
                    <div class="modal-title-bar-container">
                        <div class="timespan-selector pull-left">
                            <select (change)="timeSpanChanged($event.target.value)">
                                <option *ngFor="let item of dateRangeOptions" [selected]="value === last6Months" value="{{ item.value }}">{{ item.text }}</option>
                            </select>
                        </div>
                        <div *ngIf="opportunity" class="modal-title center-modal-title center">
                            <h4 class="modal-header-text">{{opportunity.customer.firstName}} {{opportunity.customer.lastName}}</h4>
                        </div>
                        
                        <div class="modal-header-buttons pull-right">
                                <div class="btn-group btn-group-sm service-sales-buttons" role="group">
                                        <button type="button"
                                            [ngClass]="{'btn btn-default': true, 'active': selectedType === authorizationKeys.SendTextSales}"
                                            (click)="toggleSalesServiceSelection(authorizationKeys.SendTextSales)" translate="text_sales"></button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-default': true, 'active': selectedType === authorizationKeys.SendTextService}"
                                            (click)="toggleSalesServiceSelection(authorizationKeys.SendTextService)" translate="text_service"></button>
            
                                    </div>
                            <span class="icon aa-icon-close-icon" data-dismiss="modal"
                                title="{{'close' | translate }}"></span>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
                <div class="modal-body" #modalBody [ngBusy]="{busy: busy, delay: 200}">
                    <div *ngFor="let textMessage of filteredMessages; let idx = index;">
                        <div id="dateLine" *ngIf="shouldDisplayDateLine(idx);">
                            <hr>
                            <span>{{textMessage.displayDate}}</span>
                        </div>
                        <div class="message">
                            <div class="text-row">
                                <div class='user-avatar no-user-avatar' *ngIf="!textMessage.outgoing"></div>

                                <div class="bubble"
                                    [ngClass]="textMessage.outgoing ? 'bubble-user' : 'bubble-customer'">
                                    <span class="message text-content" [innerHTML]="textMessage.cleanedMessage"
                                        (click)="textMessageService.downloadUpgradeProposalIfAvailable($event, textMessage)"></span>
                                </div>

                                <div class='user-avatar' *ngIf="textMessage.outgoing"
                                    [style.background-image]="getSanitizedAvatar(textMessage)"
                                    [ngClass]="{'no-user-avatar': !textMessage.avatarUrl}">
                                </div>
                            </div>

                            <div class="message-footer"
                                [ngClass]="textMessage.outgoing ? 'message-footer-user' : 'message-footer-customer'">
                                {{textMessage.messageFooter}}
                            </div>
                        </div>

                    </div>

                </div>
                <div class="modal-footer" >
                    <div><span title="{{'textMessageOptOutTooltip' | translate}}"
                            class="chat-message-attachment-svg"></span></div>
                    <textarea id="newTextMessage" #newTextMessage [rows]="getMaxRows(newTextMessage.value)" cols="60"
                        class="form-control new-text-message" [placeholder]="getNewTextMessagePlaceholder()" maxlength="500"
                        [(ngModel)]="newTextMessageValue" [disabled]="!authorizedKeys[selectedType]"></textarea>

                    <a href="#" *ngIf="shouldDisplayUpgradeProposalLink()" class="insert-upgrade-proposal" (click)="insertUpgradeProposalLink()" translate="insertUpgradeProposalLinkLabel"></a>
                    <button type="button" class="btn btn-primary btn-sm send-button"
                        (click)="sendMessage(newTextMessage.value)"
                        [disabled]="newTextMessage.value.length === 0 || newTextMessage.value.trim() === '' || !authorizedKeys[selectedType]">
                        <span class="send-icon-svg"></span><span translate="send"></span></button>

                </div>
            </div>
        </div>
    </div>

    <div class="modal fade text-modal" id="noUserNumberModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-title-bar">
                    <div class="modal-title-bar-container">
                        <div class="modal-header-buttons pull-right">
                            <span class="icon aa-icon-close-icon" (click)="cancelUserNumberConfirmation()"
                                data-dismiss="modal" title="{{'close' | translate }}"></span>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    {{'textMessageNoUserNumberWarning' | translate}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" translate="cancel"></button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="continueUserNumberConfirmation()" translate="continue"></button>
                </div>
            </div>
        </div>
    </div>
</ng-container>