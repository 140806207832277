import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DealerService } from '../../../services/dealer.service';
import { MenuService } from '../../../services/menu.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'quick-links',
    templateUrl: './quick-links.component-ng.html',
    styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit, OnDestroy {
    public modulePermissions: any;
    public isSomeDealerOneToOneEnabled: boolean;
    private siteMapReadySubscription: Subscription;

    constructor(private dealerService: DealerService,
        private menuService: MenuService) { }

    ngOnInit() {
        const moduleIds = this.menuService.moduleIds;
        if (moduleIds) {
            this.initQuickLinks(moduleIds);
        }

        // the following replaces event handler for $rootScope.$broadcast('RUN_USER_INIT.SITE_MAP_READY');
        // menu.service now casts BehaviorSubject when getting sitemap which this subscribes to
        this.siteMapReadySubscription = this.menuService.siteMapReady.subscribe((data) => {
            this.onSiteMapLoaded();
        });
    }

    private onSiteMapLoaded() {
        const moduleIds = this.menuService.moduleIds;
        this.initQuickLinks(moduleIds);
    };

    private initQuickLinks(moduleIds) {
        this.modulePermissions = this.menuService.getModulePermissions(moduleIds);
        this.isSomeDealerOneToOneEnabled = this.dealerService.getIsSomeDealerOneToOneEnabled();
    }

    ngOnDestroy() {
        this.siteMapReadySubscription.unsubscribe();
    }

}
