import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.find";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es7.object.entries";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/* injects from baggage-loader */
require('./customer-edit.component.html');

import angular from 'angular';
import _ from 'lodash';
import PricingPlanTypes from '../../constants/pricing-plan-types';
import './customer-edit.component.scss';

(function () {
  CustomerEditController.$inject = ["$timeout", "authorizationService", "contactTypes", "territories"];
  angular.module('AaApp.Opp').component('customerEdit', {
    templateUrl: '/app/components/deal-sheet/customer-edit.component.html',
    bindings: {
      customer: '<',
      dealerSettings: '<',
      permissions: '<',
      pricingPlanEnabled: '<',
      onEdit: '&'
    },
    controller: CustomerEditController
  });
  /* @ngInject */

  function CustomerEditController($timeout, authorizationService, contactTypes, territories) {
    var $ctrl = this;
    $ctrl.selectedState;
    $ctrl.editableCustomer;
    $ctrl.countryId;
    $ctrl.selectedPricingPlan;
    $ctrl.pricingPlans;
    $ctrl.isFirstNameRequired = isFirstNameRequired;
    $ctrl.isLastNameRequired = isLastNameRequired;
    $ctrl.onCustomerEdit = onCustomerEdit;
    $ctrl.onPreferredContactChanged = onPreferredContactChanged;
    $ctrl.onAlternateContactChanged = onAlternateContactChanged;
    $ctrl.updateState = updateState;
    $ctrl.updatePricingPlan = updatePricingPlan;
    $ctrl.$onInit = init;

    $ctrl.$onChanges = function $onChanges() {
      updatePhoneNumberArrays();
    };

    function init() {
      if ($ctrl.pricingPlanEnabled) {
        $ctrl.pricingPlans = Object.entries(PricingPlanTypes).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              name = _ref2[0],
              id = _ref2[1];

          return {
            id: id,
            name: name
          };
        });

        if ($ctrl.customer.pricingPlanId) {
          $ctrl.selectedPricingPlan = _.find($ctrl.pricingPlans, function (o) {
            return o.id === $ctrl.customer.pricingPlanId;
          });
        } else {
          $ctrl.selectedPricingPlan = $ctrl.pricingPlans[0];
        }
      }

      $ctrl.states = territories;
      $ctrl.countryId = getCountryId();
      $ctrl.editableCustomer = getCustomerCopy();
      $ctrl.selectedState = {
        id: $ctrl.editableCustomer.address.stateId,
        name: $ctrl.editableCustomer.address.stateName
      };
      validateInitialCustomer();
    }

    function validateInitialCustomer() {
      if (!$ctrl.editableCustomer.companyName) {
        if (!$ctrl.editableCustomer.firstName && $ctrl.editableCustomer.lastName) {
          $timeout(function () {
            $ctrl.customerEditForm.firstName.$setDirty();
            $ctrl.onCustomerEdit();
          });
        }
      }
    }

    function getCustomerCopy() {
      var cloneCustomer = {
        id: $ctrl.customer.id,
        firstName: $ctrl.customer.firstName,
        middleName: $ctrl.customer.middleName,
        lastName: $ctrl.customer.lastName,
        companyName: $ctrl.customer.companyName,
        invalidContacts: $ctrl.customer.invalidContacts.slice(),
        alternateContacts: $ctrl.customer.alternateContacts.slice(),
        doNotCall: $ctrl.customer.doNotCall,
        doNotEmail: $ctrl.customer.doNotEmail,
        doNotMail: $ctrl.customer.doNotMail,
        doNotText: $ctrl.customer.doNotText,
        isFullCustomer: $ctrl.customer.isFullCustomer,
        isAddressNotValid: $ctrl.customer.isAddressNotValid,
        address: {
          streetAddress: $ctrl.customer.address.streetAddress,
          streetAddress2: $ctrl.customer.address.streetAddress2,
          city: $ctrl.customer.address.city,
          stateId: $ctrl.customer.address.stateId,
          stateName: $ctrl.customer.address.stateName,
          zipCode: $ctrl.customer.address.zipCode,
          normalizedZipCode: $ctrl.customer.address.normalizedZipCode
        },
        pricingPlanId: $ctrl.customer.pricingPlanID,
        // Create phony records for the UI if there is currenly no value
        homePhoneNumber: $ctrl.customer.homePhoneNumber || {
          isPreferred: true,
          contactType: contactTypes.homePhone
        },
        workPhoneNumber: $ctrl.customer.workPhoneNumber || {
          isPreferred: true,
          contactType: contactTypes.workPhone
        },
        cellPhoneNumber: $ctrl.customer.cellPhoneNumber || {
          isPreferred: true,
          contactType: contactTypes.cellPhone
        },
        emailAddress: $ctrl.customer.emailAddress || {
          isPreferred: true,
          contactType: contactTypes.email
        },
        preferredCultureName: $ctrl.customer.preferredCultureName
      };
      return cloneCustomer;
    }

    function updatePhoneNumberArrays() {
      if ($ctrl.customer && $ctrl.customer.alternateContacts) {
        $ctrl.alternateHomePhones = getAlternateContacts($ctrl.customer.alternateContacts, contactTypes.homePhone);
        $ctrl.alternateWorkPhones = getAlternateContacts($ctrl.customer.alternateContacts, contactTypes.workPhone);
        $ctrl.alternateCellPhones = getAlternateContacts($ctrl.customer.alternateContacts, contactTypes.cellPhone);
        $ctrl.alternateEmailAddresses = getAlternateContacts($ctrl.customer.alternateContacts, contactTypes.email);
      } else {
        $ctrl.alternateHomePhones = [];
        $ctrl.alternateWorkPhones = [];
        $ctrl.alternateCellPhones = [];
        $ctrl.alternateEmailAddresses = [];
      }
    }

    function onPreferredContactChanged(propertyName, change) {
      $ctrl.editableCustomer[propertyName] = change.currentValue;
      updateInvalidContacts(change.currentValue);
      onCustomerEdit();
    }

    function onAlternateContactChanged(propertyName, change) {
      var alternateContacts = $ctrl[propertyName].slice(); // If the new number is marked as preferred, swap

      if (change.currentValue.isPreferred) {
        var preferredPropertyName = getPreferredPropertyName(change.currentValue.contactType);
        var preferredContact = $ctrl.editableCustomer[preferredPropertyName];

        if (preferredContact && preferredContact.value) {
          preferredContact.isPreferred = false;
          replaceOrAddContact(alternateContacts, change.previousValue, preferredContact);
        }

        $ctrl.editableCustomer[preferredPropertyName] = change.currentValue;
      } else {
        replaceOrAddContact(alternateContacts, change.previousValue, change.currentValue);
      } // Replace the array with a new value to trigger updates


      $ctrl[propertyName] = alternateContacts;
      updateInvalidContacts(change.currentValue);
      onCustomerEdit();

      function replaceOrAddContact(arr, previousValue, currentValue) {
        var ix = _.findIndex(arr, function (p) {
          return p.value == previousValue.value;
        });

        if (ix > -1) {
          arr[ix] = currentValue;
        } else {
          arr.push(currentValue);
        }
      }

      function getPreferredPropertyName(contactType) {
        switch (contactType) {
          case contactTypes.homePhone:
            return 'homePhoneNumber';

          case contactTypes.workPhone:
            return 'workPhoneNumber';

          case contactTypes.cellPhone:
            return 'cellPhoneNumber';

          case contactTypes.email:
            return 'emailAddress';
        }
      }
    }
    /**
        * Updates the customer's invalidContacts array to match a changed contact.
        * If the contact is marked as bad, it will be added to invalidContacts, if necessary.
        * If the contact is marked as not-bad, it will be removed.
        * @param {Contact} contact - The contact which has been updated.
    */


    function updateInvalidContacts(contact) {
      if (contact && contact.value && contact.isBad) {
        if (!_.includes($ctrl.editableCustomer.invalidContacts, contact.value)) {
          $ctrl.editableCustomer.invalidContacts.push(contact.value);
        }
      } else {
        $ctrl.editableCustomer.invalidContacts = $ctrl.editableCustomer.invalidContacts.filter(function (c) {
          return c !== contact.value;
        });
      }
    }

    function getAlternateContacts(contacts, contactType) {
      var uniqueContacts = [];
      contacts.forEach(function (candidate) {
        if (candidate.contactType == contactType && !candidate.isPreferred && !uniqueContacts.some(function (p) {
          return candidate.value == p.value;
        })) {
          uniqueContacts.push(candidate);
        }
      });
      return uniqueContacts;
    }

    function getCountryId() {
      return authorizationService.getUserCountryID();
    }

    function onCustomerEdit() {
      $ctrl.onEdit({
        customer: $ctrl.editableCustomer,
        valid: $ctrl.customerEditForm.$valid
      });
    }

    function updateState() {
      $ctrl.editableCustomer.address.stateId = $ctrl.selectedState.id;
      $ctrl.editableCustomer.address.stateName = $ctrl.selectedState.abbreviation;
      $ctrl.onCustomerEdit();
    }

    function updatePricingPlan() {
      $ctrl.editableCustomer.pricingPlanId = $ctrl.selectedPricingPlan.id;
      $ctrl.onCustomerEdit();
    }

    function isFirstNameRequired() {
      return false; //for now, this is never required.  #4565
      // if ($ctrl.editableCustomer && $ctrl.editableCustomer.companyName) {
      //     return $ctrl.editableCustomer.lastName ? true : false;
      // } else {
      //     return true;
      // }
    }

    function isLastNameRequired() {
      if ($ctrl.editableCustomer && $ctrl.editableCustomer.companyName) {
        return $ctrl.editableCustomer.firstName ? true : false;
      } else {
        return true;
      }
    }
  }
})();