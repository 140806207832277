
/* injects from baggage-loader */
require('./lms-transcript-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('lmsTranscriptModal', {
    templateUrl: '/app/components/reports/modals/lms-transcript-modal.component.html',
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});