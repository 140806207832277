import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChildren, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import moment = require('moment');
import { FormBuilder, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { DateFormatService } from '../../services/date-format.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DateOptions } from './date-options.interface';

@Component({
    selector: 'date-input',
    templateUrl: './date-input.component-ng.html',
    styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, OnChanges {

    @Input() defaultDate: Date;
    @Input() options: DateOptions;
    @Input() cssClass: string;
    @Input() required = 'required';
    @Output() onInput = new EventEmitter<any>();

    @ViewChild('dateInput', { static: true }) dateInput: ElementRef;

    dateFormat: string;
    nullDate: string;
    config: BsDatepickerConfig;

    dateInputForm: FormGroup;

    changedValue: any = '';

    constructor(private dateFormatService: DateFormatService,
        private formBuilder: FormBuilder,
        private renderer: Renderer2) { }

    ngOnInit() {

        this.nullDate = '';
        this.dateFormat = this.dateFormatService.getDateMomentFormatString();

        if (this.defaultDate) {
            if (this.defaultDate == null) {
                this.nullDate = 'null-date';
            } else { // this only adds a red outline around the input field when date input is blank
                this.nullDate = '';
            }
        }
        
        this.config = {
            dateInputFormat: this.dateFormat,
            containerClass: 'theme-dark-blue',
            selectFromOtherMonth: true,
            customTodayClass: 'custom-today-class',
            adaptivePosition: true
        } as BsDatepickerConfig;

        this.createForm();
    }

    createForm() {
        this.dateInputForm = this.formBuilder.group({
            date: ['', this.dateValidator()]
        });

        if (this.defaultDate) {
            this.dateInputForm.controls.date.setValue(new Date(this.defaultDate));
            this.changedValue = this.defaultDate;
        }

        this.dateInputForm.controls.date.valueChanges.subscribe((value) => {
            this.onValueChange(value);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.defaultDate) {
            if (((changes.defaultDate.currentValue && changes.defaultDate.firstChange) ||

                (changes.defaultDate.currentValue && !changes.defaultDate.previousValue && moment(changes.defaultDate.currentValue).isValid()) ||

                (changes.defaultDate.currentValue && changes.defaultDate.previousValue &&
                    moment(changes.defaultDate.currentValue).isValid() && moment(changes.defaultDate.previousValue).isValid() &&
                    changes.defaultDate.currentValue.getTime() !== changes.defaultDate.previousValue.getTime())) &&
                this.dateInputForm) {
                const m = moment(this.defaultDate);
                if (m.isValid()) {
                    this.dateInputForm.controls.date.setValue(m.toDate());
                }
            }

            if (changes.defaultDate && !changes.defaultDate.currentValue && changes.defaultDate.previousValue && this.dateInputForm) {
                this.dateInputForm.controls.date.setValue(null);
            }

            if (changes.defaultDate.currentValue == null) {
                this.nullDate = 'null-date';
            } else {
                this.nullDate = '';
            }
        }
    }

    dateValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const invalid = this.validateDate(control.value);
            return invalid ? { [invalid]: { value: control.value } } : null;
        };
    }

    validateDate(date: Date): string {

        if (date && this.options && this.options.maxDate) {
            const maxDate = moment(this.options.maxDate);
            if (moment(date).isAfter(maxDate, 'day')) {
                return 'maxDate';
            }
        }

        if (date && this.options && this.options.minDate) {
            const minDate = moment(this.options.minDate);
            if (moment(date).isBefore(minDate, 'day')) {
                return 'minDate';
            }
        }

        return null;
    }

    onValueChange(date: Date) {
        if (date === null || !isNaN(date.getTime())) {
            this.onInput.emit({ date: date, model: this.dateInputForm.controls.date });
            this.changedValue = date;
        }
    }

    onBlur() {
        if (isNaN((new Date(this.changedValue)).getTime())) { // Invalid date
            // By default the input will show "Invalid Date", so manually set it to show the actual value
            setTimeout(() => this.renderer.setProperty(this.dateInput.nativeElement, 'value', this.changedValue), 2);
            this.onInput.emit({ date: this.changedValue, model: this.dateInputForm.controls.date });
        }
    }

    onKeyup($event) {
        if ($event.currentTarget.value.toLowerCase() !== 'invalid date') {
            this.changedValue = $event.currentTarget.value;
        }
    }
}
