/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntityExternalDto } from '../models/entity-external-dto';
import { EntityReassignRequest } from '../models/entity-reassign-request';
import { CreditPrescreenStatusDto } from '../models/credit-prescreen-status-dto';
import { UpdateMileageRequest } from '../models/update-mileage-request';
import { EntityReportDto } from '../models/entity-report-dto';
import { EntityReport2Dto } from '../models/entity-report-2dto';
import { VehicleTurnPredictorDto } from '../models/vehicle-turn-predictor-dto';
import { NLPNoLongerOwnDto } from '../models/nlpno-longer-own-dto';
import { EntityScoreFeatureDto } from '../models/entity-score-feature-dto';
import { PandoXEntityGrantAccessResultDto } from '../models/pando-xentity-grant-access-result-dto';
import { EntityReassignResultDto } from '../models/entity-reassign-result-dto';
import { EntityDto } from '../models/entity-dto';
@Injectable({
  providedIn: 'root',
})
class EntityClientService extends __BaseService {
  static readonly GetExtByEntityidGETPath = '/api/Entity/GetExt/{entityId}';
  static readonly ReassignToUserIdPOSTPath = '/api/Entity/ReassignToUserId';
  static readonly CreditPrescreenRequestByEntityidPOSTPath = '/api/Entity/CreditPrescreenRequest/{entityId}';
  static readonly CreditPrescreenStatusByEntityidGETPath = '/api/Entity/CreditPrescreenStatus/{entityId}';
  static readonly MarkAsReadPOSTPath = '/api/Entity/MarkAsRead';
  static readonly CustomerDoesNotOwnVehicleByEntityidPOSTPath = '/api/Entity/CustomerDoesNotOwnVehicle/{entityId}';
  static readonly VehiclePurchasedByDealerByEntityidPOSTPath = '/api/Entity/VehiclePurchasedByDealer/{entityId}';
  static readonly VehiclePaidOffByEntityidPOSTPath = '/api/Entity/VehiclePaidOff/{entityId}';
  static readonly VehicleNotPaidOffByEntityidPOSTPath = '/api/Entity/VehicleNotPaidOff/{entityId}';
  static readonly HasWarrantyByEntityidPOSTPath = '/api/Entity/HasWarranty/{entityId}';
  static readonly SoldWarrantyByEntityidPOSTPath = '/api/Entity/SoldWarranty/{entityId}';
  static readonly UndoHasWarrantyByEntityidPOSTPath = '/api/Entity/UndoHasWarranty/{entityId}';
  static readonly UpdateMileagePOSTPath = '/api/Entity/UpdateMileage';
  static readonly MarkAsSoldByEntityidPOSTPath = '/api/Entity/MarkAsSold/{entityId}';
  static readonly WatchByEntityidPOSTPath = '/api/Entity/Watch/{entityId}';
  static readonly UnwatchByEntityidPOSTPath = '/api/Entity/Unwatch/{entityId}';
  static readonly ReopenByEntityidPOSTPath = '/api/Entity/Reopen/{entityId}';
  static readonly UnmarkSoldWarrantyByEntityidPOSTPath = '/api/Entity/UnmarkSoldWarranty/{entityId}';
  static readonly GetEntityReportByCustomerIdByCustomeridGETPath = '/api/Entity/GetEntityReportByCustomerId/{customerId}';
  static readonly GetEntityReportByDealerIdAndVinByDealeridByVinGETPath = '/api/Entity/GetEntityReportByDealerIdAndVin/{dealerId}/{vin}';
  static readonly GetEntityStatusByEntityidGETPath = '/api/Entity/GetEntityStatus/{entityId}';
  static readonly HasReviewProDealerByEntityidGETPath = '/api/Entity/HasReviewProDealer/{entityId}';
  static readonly HasGeoAlertDealerByEntityidGETPath = '/api/Entity/HasGeoAlertDealer/{entityId}';
  static readonly GetVehicleTurnPredictorByEntityidGETPath = '/api/Entity/GetVehicleTurnPredictor/{entityId}';
  static readonly GetActiveNLPNoLongerOwnByEntityidGETPath = '/api/Entity/GetActiveNLPNoLongerOwn/{entityId}';
  static readonly UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETPath = '/api/Entity/UpdateActiveNLPNoLongerOwn/{entityId}/{agreed}';
  static readonly GetEntityScoreFeatureByEntityidGETPath = '/api/Entity/GetEntityScoreFeature/{entityId}';
  static readonly ExternalGrantEntityAccessByEntityidByGrantuseridPOSTPath = '/api/Entity/ExternalGrantEntityAccess/{entityId}/{grantUserId}';
  static readonly ExternalRevokeEntityAccessByEntityidByUseridPOSTPath = '/api/Entity/ExternalRevokeEntityAccess/{entityId}/{userId}';
  static readonly ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTPath = '/api/Entity/ExternalBulkAssignEntityOwners/{actingUserId}/{ownerUserId}';
  static readonly ByIdGETPath = '/api/Entity/{id}';
  static readonly ByIdDELETEPath = '/api/Entity/{id}';
  static readonly GetManyGETPath = '/api/Entity/GetMany';
  static readonly PUTPath = '/api/Entity';
  static readonly POSTPath = '/api/Entity';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetExtByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<EntityExternalDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetExt/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityExternalDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetExtByEntityidGET(entityId: string): __Observable<EntityExternalDto> {
    return this.GetExtByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as EntityExternalDto)
    );
  }

  /**
   * @param request undefined
   */
  ReassignToUserIdPOSTResponse(request?: EntityReassignRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/ReassignToUserId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  ReassignToUserIdPOST(request?: EntityReassignRequest): __Observable<null> {
    return this.ReassignToUserIdPOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  CreditPrescreenRequestByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/CreditPrescreenRequest/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  CreditPrescreenRequestByEntityidPOST(entityId: string): __Observable<null> {
    return this.CreditPrescreenRequestByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  CreditPrescreenStatusByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<CreditPrescreenStatusDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/CreditPrescreenStatus/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreditPrescreenStatusDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  CreditPrescreenStatusByEntityidGET(entityId: string): __Observable<CreditPrescreenStatusDto> {
    return this.CreditPrescreenStatusByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as CreditPrescreenStatusDto)
    );
  }

  /**
   * @param entityId undefined
   */
  MarkAsReadPOSTResponse(entityId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entityId;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/MarkAsRead`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  MarkAsReadPOST(entityId?: string): __Observable<null> {
    return this.MarkAsReadPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  CustomerDoesNotOwnVehicleByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/CustomerDoesNotOwnVehicle/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  CustomerDoesNotOwnVehicleByEntityidPOST(entityId: string): __Observable<null> {
    return this.CustomerDoesNotOwnVehicleByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  VehiclePurchasedByDealerByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/VehiclePurchasedByDealer/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  VehiclePurchasedByDealerByEntityidPOST(entityId: string): __Observable<null> {
    return this.VehiclePurchasedByDealerByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  VehiclePaidOffByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/VehiclePaidOff/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  VehiclePaidOffByEntityidPOST(entityId: string): __Observable<null> {
    return this.VehiclePaidOffByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  VehicleNotPaidOffByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/VehicleNotPaidOff/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  VehicleNotPaidOffByEntityidPOST(entityId: string): __Observable<null> {
    return this.VehicleNotPaidOffByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  HasWarrantyByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/HasWarranty/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  HasWarrantyByEntityidPOST(entityId: string): __Observable<null> {
    return this.HasWarrantyByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  SoldWarrantyByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/SoldWarranty/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  SoldWarrantyByEntityidPOST(entityId: string): __Observable<null> {
    return this.SoldWarrantyByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  UndoHasWarrantyByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/UndoHasWarranty/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  UndoHasWarrantyByEntityidPOST(entityId: string): __Observable<null> {
    return this.UndoHasWarrantyByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   */
  UpdateMileagePOSTResponse(request?: UpdateMileageRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/UpdateMileage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  UpdateMileagePOST(request?: UpdateMileageRequest): __Observable<null> {
    return this.UpdateMileagePOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  MarkAsSoldByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/MarkAsSold/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  MarkAsSoldByEntityidPOST(entityId: string): __Observable<null> {
    return this.MarkAsSoldByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  WatchByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/Watch/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  WatchByEntityidPOST(entityId: string): __Observable<null> {
    return this.WatchByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  UnwatchByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/Unwatch/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  UnwatchByEntityidPOST(entityId: string): __Observable<null> {
    return this.UnwatchByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  ReopenByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/Reopen/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  ReopenByEntityidPOST(entityId: string): __Observable<null> {
    return this.ReopenByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  UnmarkSoldWarrantyByEntityidPOSTResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/UnmarkSoldWarranty/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  UnmarkSoldWarrantyByEntityidPOST(entityId: string): __Observable<null> {
    return this.UnmarkSoldWarrantyByEntityidPOSTResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param customerId undefined
   * @return Success
   */
  GetEntityReportByCustomerIdByCustomeridGETResponse(customerId: number): __Observable<__StrictHttpResponse<EntityReportDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetEntityReportByCustomerId/${customerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityReportDto>;
      })
    );
  }
  /**
   * @param customerId undefined
   * @return Success
   */
  GetEntityReportByCustomerIdByCustomeridGET(customerId: number): __Observable<EntityReportDto> {
    return this.GetEntityReportByCustomerIdByCustomeridGETResponse(customerId).pipe(
      __map(_r => _r.body as EntityReportDto)
    );
  }

  /**
   * @param params The `EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETParams` containing the following parameters:
   *
   * - `vin`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetEntityReportByDealerIdAndVinByDealeridByVinGETResponse(params: EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETParams): __Observable<__StrictHttpResponse<EntityReport2Dto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetEntityReportByDealerIdAndVin/${params.dealerId}/${params.vin}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityReport2Dto>;
      })
    );
  }
  /**
   * @param params The `EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETParams` containing the following parameters:
   *
   * - `vin`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetEntityReportByDealerIdAndVinByDealeridByVinGET(params: EntityClientService.GetEntityReportByDealerIdAndVinByDealeridByVinGETParams): __Observable<EntityReport2Dto> {
    return this.GetEntityReportByDealerIdAndVinByDealeridByVinGETResponse(params).pipe(
      __map(_r => _r.body as EntityReport2Dto)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetEntityStatusByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<100 | 200 | 300 | 400>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetEntityStatus/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<100 | 200 | 300 | 400>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetEntityStatusByEntityidGET(entityId: string): __Observable<100 | 200 | 300 | 400> {
    return this.GetEntityStatusByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as 100 | 200 | 300 | 400)
    );
  }

  /**
   * @param entityId undefined
   */
  HasReviewProDealerByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/HasReviewProDealer/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  HasReviewProDealerByEntityidGET(entityId: string): __Observable<null> {
    return this.HasReviewProDealerByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  HasGeoAlertDealerByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/HasGeoAlertDealer/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  HasGeoAlertDealerByEntityidGET(entityId: string): __Observable<null> {
    return this.HasGeoAlertDealerByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetVehicleTurnPredictorByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<VehicleTurnPredictorDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetVehicleTurnPredictor/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VehicleTurnPredictorDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetVehicleTurnPredictorByEntityidGET(entityId: string): __Observable<VehicleTurnPredictorDto> {
    return this.GetVehicleTurnPredictorByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as VehicleTurnPredictorDto)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetActiveNLPNoLongerOwnByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<Array<NLPNoLongerOwnDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetActiveNLPNoLongerOwn/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NLPNoLongerOwnDto>>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetActiveNLPNoLongerOwnByEntityidGET(entityId: string): __Observable<Array<NLPNoLongerOwnDto>> {
    return this.GetActiveNLPNoLongerOwnByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as Array<NLPNoLongerOwnDto>)
    );
  }

  /**
   * @param params The `EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `agreed`:
   *
   * @return Success
   */
  UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETResponse(params: EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/UpdateActiveNLPNoLongerOwn/${params.entityId}/${params.agreed}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `agreed`:
   *
   * @return Success
   */
  UpdateActiveNLPNoLongerOwnByEntityidByAgreedGET(params: EntityClientService.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams): __Observable<number> {
    return this.UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetEntityScoreFeatureByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<Array<EntityScoreFeatureDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetEntityScoreFeature/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntityScoreFeatureDto>>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetEntityScoreFeatureByEntityidGET(entityId: string): __Observable<Array<EntityScoreFeatureDto>> {
    return this.GetEntityScoreFeatureByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as Array<EntityScoreFeatureDto>)
    );
  }

  /**
   * @param params The `EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams` containing the following parameters:
   *
   * - `grantUserId`:
   *
   * - `entityId`:
   *
   * @return Success
   */
  ExternalGrantEntityAccessByEntityidByGrantuseridPOSTResponse(params: EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams): __Observable<__StrictHttpResponse<PandoXEntityGrantAccessResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/ExternalGrantEntityAccess/${params.entityId}/${params.grantUserId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PandoXEntityGrantAccessResultDto>;
      })
    );
  }
  /**
   * @param params The `EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams` containing the following parameters:
   *
   * - `grantUserId`:
   *
   * - `entityId`:
   *
   * @return Success
   */
  ExternalGrantEntityAccessByEntityidByGrantuseridPOST(params: EntityClientService.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams): __Observable<PandoXEntityGrantAccessResultDto> {
    return this.ExternalGrantEntityAccessByEntityidByGrantuseridPOSTResponse(params).pipe(
      __map(_r => _r.body as PandoXEntityGrantAccessResultDto)
    );
  }

  /**
   * @param params The `EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `entityId`:
   *
   * @return Success
   */
  ExternalRevokeEntityAccessByEntityidByUseridPOSTResponse(params: EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTParams): __Observable<__StrictHttpResponse<PandoXEntityGrantAccessResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/ExternalRevokeEntityAccess/${params.entityId}/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PandoXEntityGrantAccessResultDto>;
      })
    );
  }
  /**
   * @param params The `EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `entityId`:
   *
   * @return Success
   */
  ExternalRevokeEntityAccessByEntityidByUseridPOST(params: EntityClientService.ExternalRevokeEntityAccessByEntityidByUseridPOSTParams): __Observable<PandoXEntityGrantAccessResultDto> {
    return this.ExternalRevokeEntityAccessByEntityidByUseridPOSTResponse(params).pipe(
      __map(_r => _r.body as PandoXEntityGrantAccessResultDto)
    );
  }

  /**
   * @param params The `EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams` containing the following parameters:
   *
   * - `ownerUserId`:
   *
   * - `actingUserId`:
   *
   * - `entites`:
   *
   * @return Success
   */
  ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTResponse(params: EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams): __Observable<__StrictHttpResponse<EntityReassignResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.entites;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity/ExternalBulkAssignEntityOwners/${params.actingUserId}/${params.ownerUserId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityReassignResultDto>;
      })
    );
  }
  /**
   * @param params The `EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams` containing the following parameters:
   *
   * - `ownerUserId`:
   *
   * - `actingUserId`:
   *
   * - `entites`:
   *
   * @return Success
   */
  ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOST(params: EntityClientService.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams): __Observable<EntityReassignResultDto> {
    return this.ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTResponse(params).pipe(
      __map(_r => _r.body as EntityReassignResultDto)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: string): __Observable<__StrictHttpResponse<EntityDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: string): __Observable<EntityDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as EntityDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Entity/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: string): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<string>): __Observable<__StrictHttpResponse<Array<EntityDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entity/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntityDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<string>): __Observable<Array<EntityDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<EntityDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: EntityDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Entity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: EntityDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: EntityDto): __Observable<__StrictHttpResponse<EntityDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: EntityDto): __Observable<EntityDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as EntityDto)
    );
  }
}

module EntityClientService {

  /**
   * Parameters for GetEntityReportByDealerIdAndVinByDealeridByVinGET
   */
  export interface GetEntityReportByDealerIdAndVinByDealeridByVinGETParams {
    vin: string;
    dealerId: number;
  }

  /**
   * Parameters for UpdateActiveNLPNoLongerOwnByEntityidByAgreedGET
   */
  export interface UpdateActiveNLPNoLongerOwnByEntityidByAgreedGETParams {
    entityId: string;
    agreed: boolean;
  }

  /**
   * Parameters for ExternalGrantEntityAccessByEntityidByGrantuseridPOST
   */
  export interface ExternalGrantEntityAccessByEntityidByGrantuseridPOSTParams {
    grantUserId: number;
    entityId: string;
  }

  /**
   * Parameters for ExternalRevokeEntityAccessByEntityidByUseridPOST
   */
  export interface ExternalRevokeEntityAccessByEntityidByUseridPOSTParams {
    userId: number;
    entityId: string;
  }

  /**
   * Parameters for ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOST
   */
  export interface ExternalBulkAssignEntityOwnersByActinguseridByOwneruseridPOSTParams {
    ownerUserId: number;
    actingUserId: number;
    entites?: Array<string>;
  }
}

export { EntityClientService }
