MarkAsSoldController.$inject = ["opportunityStatuses"];

/* injects from baggage-loader */
require('./mark-as-sold.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('markAsSold', {
  templateUrl: '/app/components/deal-sheet/mark-as-sold.component.html',
  controller: MarkAsSoldController,
  bindings: {
    status: '<',
    permissions: '<',
    onChange: '&'
  }
});
/* @ngInject */

function MarkAsSoldController(opportunityStatuses) {
  var $ctrl = this;
  $ctrl.visible;
  $ctrl.enabled;
  $ctrl.$onChanges = onChanges;

  function onChanges(changes) {
    if (changes.status && changes.status.currentValue && $ctrl.permissions.editMarkAsSold) {
      $ctrl.visible = $ctrl.status != opportunityStatuses.closed;
      $ctrl.enabled = $ctrl.status == opportunityStatuses.sold;
    }
  }
}