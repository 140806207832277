import angular from 'angular';

angular.module('AaApp.Opp').constant('dateRangeTypes', {
    userInput: "UI",
    day: "D",
    month: "M",
    monthToDate: "MTD",
    quarter: "Q",
    quarterToDate: "QTD",
    yearToDate: "YTD",
    previousDay: "PD",
    previousMonth: "PM",
    previousMonthToDate: "PMTD",
    previousQuarter: "PQ",
    previousQuarterToDate: "PQTD",
    previousWeek: "PW",
    previousYearQuarter: "PYQ",
    previousYearQuarterToDate: "PYQTD",
    previousYear: "PY",
    previousYearToDate: "PYTD",
    last7Days: "L7D",
    last14Days: "L14D",
    last21Days: "L21D",
    last28Days: "L28D",
    last30Days: "L30D",
    last60Days: "L60D",
    last90Days: "L90D"
});


angular.module('AaApp.Opp').constant('deliveryDateRangeOrdinalTypes', {
    day1st: 1,
    day2nd: 2,
    day3rd: 3,
    day4th: 4,
    day5th: 5,
    day6th: 6,
    day7th: 7,
    day8th: 8,
    day9th: 9,
    day10th: 10,
    day11th: 11,
    day12th: 12,
    day13th: 13,
    day14th: 14,
    day15th: 15,
    day16th: 16,
    day17th: 17,
    day18th: 18,
    day19th: 19,
    day20th: 20,
    day21st: 21,
    day22nd: 22,
    day23rd: 23,
    day24th: 24,
    day25th: 25,
    day26th: 26,
    day27th: 27,
    day28th: 28,
    lastDayOfMonth: 31
});

angular.module('AaApp.Opp').constant('deliveryWeekDayTypes', {
    weekday1: 1,
    weekday2: 2,
    weekday3: 3,
    weekday4: 4,
    weekday5: 5,
    weekday6: 6,
    weekday7: 7
});