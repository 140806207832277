import angular from 'angular';
import opp from '../core/opportunities.module';
import moment from 'moment';
import 'moment-timezone';
import { TimeZones, TimeZonesById } from '../constants/time-zones';

angular.module(opp).filter('localeDateTime', localeDateTimeFilter);

export function localeDateTimeFilter($filter, storageService) {
    "ngInject";
    return function (date) {
        if (!date) {
            return date;
        }

        var timeZones = TimeZones;
        var timeZonesById = TimeZonesById;

        const timeZoneId = storageService.getItem('timezoneId') || timeZones.pacificStandardTime.id;

        // If left undefined, the AngularJS date filter uses the computer's
        // time zone
        let utcOffsetString = undefined;

        // If `date` is a Date, it has already been converted to local time
        // If `date` is a string, a trailing Z indicates a UTC timestamp
        const isUTCFormat = !(date instanceof Date) && date.toString().endsWith('Z');

        if (isUTCFormat) {
            const timeZone = timeZonesById.get(timeZoneId);

            if (timeZone) {

                // moment-timezone does the hard work of deciding whether DST was
                // in effect at the given DateTime.
                const isDSTAtGivenTime = moment(date).tz(timeZone.tzdbName).isDST();
                if (isDSTAtGivenTime) {
                    utcOffsetString = timeZone.daylight;
                } else {
                    utcOffsetString = timeZone.standard;
                }
            }
        }

        const cultureName = storageService.getItem('cultureName') || 'en-us';

        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                return $filter('date')(date, 'dd/MM/yyyy hh:mm a', utcOffsetString);
            case 'en-ca':
                return $filter('date')(date, 'dd/MM/yyyy h:mm a', utcOffsetString);
            case 'fr-ca':
                return $filter('date')(date, 'yyyy-MM-dd hh:mm a', utcOffsetString);
            case 'fr-fr':
                return $filter('date')(date, 'dd/MM/yyyy H:mm', utcOffsetString);
            case 'en-us':
            default:
                return $filter('date')(date, 'MM/dd/yyyy h:mm a', utcOffsetString);
        }
    }
}
