
/* injects from baggage-loader */
require('./loyalty-rebate.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('loyaltyRebate', {
    templateUrl: '/app/components/deal-sheet/contract/loyalty-rebate.component.html',
    bindings: {
        value: '<'
    }
});
