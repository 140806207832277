import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../../core/toaster-logger.service';
import { DealSheetService } from '../../../ajs-upgraded-providers';
import { NotificationService } from '../../../services/notification.service';
import { NotificationMessage } from '../../../interfaces/notification-message.interface';
import { Observable, Subject, Subscription } from 'rxjs';
import { DisplayView, RequestType, DmsSaleType } from './dms-push-enums';
import { NgxModalWrapperService } from '../../../services/ngx-modal-wrapper.service';
import { ConfirmModalComponent } from '../../modals/confirm-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SaleTypes } from '../../../constants';


@Component({
    selector: 'dms-push-modal',
    templateUrl: './dms-push-modal.component-ng.html',
    styleUrls: ['./dms-push-modal.component.scss']
})

export class DmsPushModalComponent implements OnInit, OnDestroy, AfterViewInit {
    public busy: Promise<any> | Subscription;
    private notificationSubscription: Subscription;
    public dealerId: number;
    public includeDeal: boolean;
    public customer: any;
    public states: any;
    public currentVin: string;
    public currentYear: string;
    public currentMake: string;
    public currentModel: string;
    public currentMileage: number;
    public currentTradeValue: number;
    public replacementVin: string;
    public replacementPrice: number;
    public replacementSaleType: number;
    public address1: string;
    public address2: string;
    public cityStZip: string;
    public primaryPhone: string;
    public currentView: DisplayView;
    public DisplayView = DisplayView; 
    public DmsSaleType = DmsSaleType; 
    public customerMatches: any;
    public customerSelection: any;
    public currentRequestType: RequestType
    public requestSucceeded: boolean
    public newOrUpdatedDMSCustomerID: string;
    public newOrUpdatedDMSDealID: string;
    public showCloseButton: boolean;
    public latestCreateDateUtc: Date;
    public latestDmsCustomerId: string;
    public latestDmsDealId: string;
    public latestRequestTypeId: number;
    public latestRequestType: string;
    public latestFullName: string;
    public dmsErrorMsg: string;

    @Output() onSaveChanges = new EventEmitter();

    constructor(private domSanitizer: DomSanitizer,
        public _bsModalRef: BsModalRef,
        public modalService: NgxModalWrapperService,
        public tService: TranslateService,
        private logger: ToasterLoggerService,
        private notificationService: NotificationService,
        @Inject(DealSheetService) private dealSheetService) {
    }

    private handlePushResponse(message: any) {
        if (message.data && message.data.data) {
            let result = message.data;
            this.showCloseButton = false;
            this.requestSucceeded = result.data.succeeded;

            if (result.data.matches) {
                console.log("DMS Push Customer matches returned");
                this.currentView = DisplayView.customerSelectView;
                this.customerMatches = result.data.matches;
            }
            else if (result.data.succeeded) {
                console.log("DMS Push Successful insert or update");
                switch (this.currentRequestType) {
                    case RequestType.customer:
                        this.currentView = DisplayView.customerPushedView;
                        this.showCloseButton = true;
                        this.newOrUpdatedDMSCustomerID = result.data.notes;
                        break;
                    case RequestType.fandI:
                        this.currentView = DisplayView.dealPushedView;
                        this.showCloseButton = true;
                        this.newOrUpdatedDMSDealID = result.data.notes;
                        break;
                    default:
                        this.currentView = DisplayView.customerPushedView;
                }
            }
            else {
                console.log("DMS Push Request failed. Error code: " + result.data.notes);
                this.currentView = DisplayView.errorView;
                this.dmsErrorMsg = "";

                if (result.data.notes && result.data.notes.length > 0) {
                    if (result.data.notes.indexOf('|') > -1) {
                        const parts = result.data.notes.split('|');
                        const transKey = parts[0];
                        const param1 = parts[1];
                        this.dmsErrorMsg = this.tService.instant("dmsPushError_" + transKey, { param1: param1 });
                    }
                    else {
                        this.dmsErrorMsg = this.tService.instant("dmsPushError_" + result.data.notes);
                    }
                }

                this.showCloseButton = true;
            }
            console.log(message.data);
        }
        else {
            console.log("DMS Push Request failed.");
            this.currentView = DisplayView.errorView;
            this.showCloseButton = true;
        }

        this.stopWorkingState();
    }

    public showFullAddress(customerMatch) {
        let c = customerMatch;
        let fullAddress = "";

        if (c.address1 && c.address1 != " ")
            fullAddress = fullAddress + " " + c.address1;
        if (c.address2 && c.address2 != " ")
            fullAddress = fullAddress + " " + c.address2;
        if (c.city && c.city != " ")
            fullAddress = fullAddress + " " + c.city;
        if (c.stateCode && c.stateCode != " ")
            fullAddress = fullAddress + ", " + c.stateCode;
        if (c.zipCode && c.zipCode.length > 1)
            fullAddress = fullAddress + " " + c.zipCode;

        return fullAddress;
    }

    private setAddressInfo() {
        this.address1 = null;
        this.address2 = null;
        this.cityStZip = null;

        if (this.customer) {

            if (this.customer.address.streetAddress)
                this.address1 = this.customer.address.streetAddress

            if (this.customer.address.streetAddress2)
                this.address2 = this.customer.address.streetAddress2;

            this.cityStZip = this.customer.address.city + ', ' + this.customer.address.stateAbbr + ', ' + this.customer.address.zipCode;
        }
    }

    public ngOnInit(): void {
        this._bsModalRef.hide();

        this.includeDeal = this.replacementVin != null;
        this.primaryPhone = null;
        this.customerSelection = null;
        this.newOrUpdatedDMSCustomerID = null;
        this.newOrUpdatedDMSDealID = null;
        this.showCloseButton = false;
        this.customerMatches = null;
        this.dmsErrorMsg = "";

        if (this.customer.address) {
            var state = this.states.find(s => s.id == this.customer.address.stateId);
            this.customer.address.stateAbbr = state.abbreviation;
        }

        this.setAddressInfo();

        if (this.customer.cellPhoneNumber) {
            this.primaryPhone = this.customer.cellPhoneNumber.value;
        }
        else if (this.customer.homePhoneNumber) {
            this.primaryPhone = this.customer.homePhoneNumber.value;
        }

        this.notificationSubscription = this.notificationService.newPushResponse.subscribe((message: NotificationMessage) => {
            this.handlePushResponse(message);
        });

        let getLatestPushRequest = this.buildGetLatestPushRequest();
        this.dealSheetService.dmsPushGetLatest(getLatestPushRequest)
            .then(data => {
                if (data) {
                    this.latestFullName = data.fullName;
                    this.latestCreateDateUtc = data.createDateUtc;
                    this.latestDmsCustomerId = data.dmsCustomerId;
                    this.latestDmsDealId = data.dmsDealId;
                    this.latestRequestTypeId = data.requestTypeId;
                    if (data.requestTypeId == 1) {
                        this.latestRequestType = "Deal";
                    }
                    else {
                        this.latestRequestType = "Customer";
                    }
                }
            });

        this.currentView = DisplayView.initialView;


        //this.customerMatches = [
        //    {
        //        firstName: "Mike",
        //        lastName: "Gone",
        //        dmsCustomerId: "123456234252351",
        //        phone1: 9495555460,
        //        email1: "mike@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "John",
        //        lastName: "Smith",
        //        dmsCustomerId: "123456234252352",
        //        phone1: 9495555460,
        //        email1: "john@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252353",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },

        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252354",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },

        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252310",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252311",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252312",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252313",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252314",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252315",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    }
        //];
        //this.currentView = DisplayView.customerSelectView;

    }

    public ngOnDestroy(): void {
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
        this.notificationSubscription.unsubscribe();
    }

    public processSelectedCustomer(dmsCustomerId): void {
        this.customerSelection = dmsCustomerId;
        this.confirmCustomerSelection(dmsCustomerId);
    }

    public processNewCustomer(): void {
        this.customerSelection = null;
        let parameters = {
            dmsCustomerId: null,
            forceInsertCustomer: true,
            dmsDealId: null
        }
        this.pushToDms(parameters);
    }

    private isNullorWhiteSpace(value) {
        return !(value && value.trim().length > 0);
    }

    private setBusinessName(customer) {
        let businessname = null;

        if (!this.isNullorWhiteSpace(customer.companyName)) {
            businessname = customer.companyName;
        }
        else if (this.isNullorWhiteSpace(customer.firstName) && !this.isNullorWhiteSpace(customer.lastName)) {
            businessname = customer.lastName;
        }

        return businessname;
    }

    private buildRequest(parameters): any {
        this.currentRequestType = this.includeDeal ? RequestType.fandI : RequestType.customer;
        let request;
        
        if (this.currentRequestType == RequestType.customer) {
            request = {
                ResponseMethod: 1,
                dealerId: this.dealerId,
                idReferences: [
                    {
                        type: 0,
                        referenceId: this.customer.id
                    }
                ],
                forceInsertCustomer: false,
                dmsCustomerId: null,
                businessName: this.setBusinessName(this.customer),
                firstName: this.customer.firstName,
                lastName: this.customer.lastName,
                address1: this.address1,
                address2: this.address2,
                city: this.customer.address ? this.customer.address.city : null,
                stateCode: this.customer.address ? this.customer.address.stateAbbr : null,
                zipCode: this.customer.address ? this.customer.address.zipCode : null,
                phoneNumber1: this.customer.homePhoneNumber ? this.customer.homePhoneNumber.value : null,
                phoneNumber2: this.customer.cellPhoneNumber ? this.customer.cellPhoneNumber.value : null,
                email1: this.customer.emailAddress ? this.customer.emailAddress.value : null,
                email2: null,
            };

            if (parameters) {
                request.dmsCustomerId = parameters.dmsCustomerId;
                request.forceInsertCustomer = parameters.forceInsertCustomer;
            }

        }
        else {
            let repSaleType : number;
            
            switch (this.replacementSaleType) {
                case SaleTypes.cash:
                    repSaleType = DmsSaleType.cash;
                    break;
                case SaleTypes.retail:
                    repSaleType = DmsSaleType.retail;
                    break;
                case SaleTypes.lease:
                    repSaleType = DmsSaleType.lease;
                    break;
                default:
                    repSaleType = DmsSaleType.retail;
            }

            request = {
                customer: {
                    ResponseMethod: 1,
                    dealerId: this.dealerId,
                    idReferences: [
                        {
                            type: 0,
                            referenceId: this.customer.id
                        }
                    ],
                    forceInsertCustomer: false,
                    dmsCustomerId: null,
                    businessName: this.setBusinessName(this.customer),
                    firstName: this.customer.firstName,
                    lastName: this.customer.lastName,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.customer.address ? this.customer.address.city : null,
                    stateCode: this.customer.address ? this.customer.address.stateAbbr : null,
                    zipCode: this.customer.address ? this.customer.address.zipCode : null,
                    phoneNumber1: this.customer.homePhoneNumber ? this.customer.homePhoneNumber.value : null,
                    phoneNumber2: this.customer.cellPhoneNumber ? this.customer.cellPhoneNumber.value : null,
                    email1: this.customer.emailAddress ? this.customer.emailAddress.value : null,
                    email2: null
                },
                vehicleDeal: {
                    dealNumber: null,
                    vin: this.replacementVin,
                    saleType: repSaleType,
                },
                tradeIns: [
                    {
                        vin: this.currentVin,
                        year: this.currentYear,
                        make: this.currentMake,
                        model: this.currentModel,
                        odometer: this.currentMileage == null || Number.isNaN(this.currentMileage) ? 0 : this.currentMileage,
                        allowance: this.currentTradeValue == null || Number.isNaN(this.currentTradeValue) ? 0 : this.currentTradeValue,
                    }
                ]
            };
            
            if (parameters) {
                request.customer.dmsCustomerId = parameters.dmsCustomerId;
                request.customer.forceInsertCustomer = parameters.forceInsertCustomer;
                request.dealNumber = parameters.dmsDealId;
            }

        }
        return request;
    }

    private buildGetLatestPushRequest(): any {
        let request = {
            dealerId: this.dealerId,
            idReferences: [
                {
                    type: 0,
                    referenceId: this.customer.id
                }
            ],
        };

        return request;
    }

    public startWorkingState() {
        this.busy = new Promise((resolve) => {
            this.stopWorkingState = resolve;
        });
    }

    public stopWorkingState() { }

    public pushToDms(parameters): void {
        this.currentView = DisplayView.waitingView;
        this.startWorkingState();
        let request = this.buildRequest(parameters);
        
        if (this.currentRequestType == RequestType.fandI) {
            this.dealSheetService.dmsPushPushFAndI(request)
                .then(data => {
                    if (data) {
                    }
                    else {
                        this.logger.error(this.tService.instant("generalErrorMessageKey"));
                    }
                });
        }
        else {
            this.dealSheetService.dmsPushCustomer(request)
                .then(data => {
                    if (data) {
                    }
                    else {
                        this.logger.error(this.tService.instant("generalErrorMessageKey"));
                    }
                });
        }
    }

    confirmCustomerSelection(dmsCustomerId): void {
        let customer = this.customerMatches.find(c => c.dmsCustomerId == dmsCustomerId);
        let fullName = "";
        let message = null;
        if (customer && (customer.firstName || customer.lastName)) {
            fullName = ((customer.firstName == null ? "" : customer.firstName) + " " + (customer.lastName == null ? "" : customer.lastName)).trim();
        }
        message = this.domSanitizer.bypassSecurityTrustHtml("<p>Are you sure you wish to push this customer? This push will overwrite the customer and vehicle information.</p><label class='dms-push-confirm-label'>Name:</label>" + fullName + "</br ><label class='dms-push-confirm-label'>DMS Customer ID:</label>" + dmsCustomerId);

        const initialState = {
            message: message
        };

        const bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });

        bsModalRef.content.onClosed.subscribe((result: any) => {
            if (result.confirmed)
                this.pushSelectedCustomer(dmsCustomerId);
        });
    }

    public pushSelectedCustomer(dmsCustomerId): void {

        let parameters = {
            dmsCustomerId: dmsCustomerId,
            forceInsertCustomer: false,
            dmsDealId: null
        }

        this.pushToDms(parameters);
    }

}



