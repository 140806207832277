import "core-js/modules/es6.array.find";
SearchDirectAlertModalController.$inject = ["$element", "$scope", "$sce", "legacy"];

/* injects from baggage-loader */
require('./search-direct-alert-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url";
import { ChildMessagePort } from '../../utilities/messaging';
import './search-direct-alert-modal.component.scss';
angular.module('AaApp.Opp').component('searchDirectAlertModal', {
  templateUrl: '/app/components/search/search-direct-alert-modal.component.html',
  controller: SearchDirectAlertModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function SearchDirectAlertModalController($element, $scope, $sce, legacy) {
  var $ctrl = this; //$ctrl.submit = submit;

  $ctrl.$onInit = onInit;
  var originalIframeUrl = legacy.iframeUrl + "/AlertDesk/Search/SearchDirectAlert";
  $ctrl.selectedDealerId;
  $ctrl.iframeUrl;

  function onInit() {
    var rendered = $ctrl.resolve.data.rendered;

    if ($ctrl.resolve.data.selectedDealerId === undefined) {
      $ctrl.selectedDealerId = 0;
    } else {
      $ctrl.selectedDealerId = $ctrl.resolve.data.selectedDealerId;
    }

    var searchCriteria = $ctrl.resolve.data.searchCriteria;
    var searchResultsForm = $ctrl.resolve.data.searchResultsForm;
    $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeUrl + "?dealerID=" + $ctrl.selectedDealerId);
    rendered.then(function () {
      var iframe = $element.find(".ifrmSearchDirectAlert")[0];
      var origin = getUrlComponents(originalIframeUrl).origin;
      $ctrl.messageChannel = new ChildMessagePort(iframe, origin, $scope);
      var msg = {
        type: "SEARCH_INIT_DIRECT_ALERT",
        data: {
          searchCriteria: searchCriteria,
          searchResultsForm: searchResultsForm
        }
      };
      $ctrl.messageChannel.postMessage(msg);
      $ctrl.messageChannel.subscribe(function (_ref, replyPort) {
        var data = _ref.data;

        if (data.type === "SEARCH_DIRECT_ALERT_COMPLETE") {
          $ctrl.close({
            $value: {
              url: data.data.url
            }
          });
        }
      });
    });
  }
}