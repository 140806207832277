<div id="aa-header-user" (mouseleave)="dropDownVisible = false">
    <div id="aa-header-user-name"
         class="clickable"
         (mouseenter)="dropDownVisible = true"
         (click)="dropDownVisible = !dropDownVisible"
         [ngClass]="{ active: dropDownVisible }">

        <div id="aa-header-user-avatar"
             [ngStyle]="{ 'background-image': avatarUrl ? 'url(&quot;' + avatarUrl + '&quot;)' : undefined }">
        </div>
        <span class="user-name">{{userFullName}}</span>
        <span class="aa-icon-chevron-down small-icon"></span>
    </div>

    <div id="aa-header-user-dropdown" [hidden]="!dropDownVisible">
        <ul class="user">
            <li *ngFor="let item of items" [ngSwitch]="item.type">
                <a *ngSwitchCase="'SREF'"
                   uiSref="{{item.sref}}"
                   (click)="(dropDownVisible = false) || (item.action ? item.action() : null)">
                    <div *ngIf="item.icon" style="float: right" class="icon {{item.icon}}"></div>
                    {{ item.translate | translate }}
                </a>
                <a *ngSwitchCase="'HREF'"
                   [href]="item.href"
                   [attr.target]="item.target || undefined"
                   (click)="(dropDownVisible = false) || (item.action ? item.action() : null)">
                    <div *ngIf="item.icon" style="float: right" class="icon {{item.icon}}"></div>
                    {{ item.translate | translate }}
                </a>
                <a *ngSwitchDefault
                   href="#"
                   (click)="reloadPage()">
                    <div *ngIf="item.icon" style="float: right" class="icon {{item.icon}}"></div>
                    {{ item.translate | translate }}
                </a>
                <hr *ngSwitchCase="'SEPARATOR'">
            </li>
        </ul>
    </div>
</div>