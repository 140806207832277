<div class="row type-of-call">
    <div class="col-sm-4">
        <span class="aa-padding-right label-top-buffer" translate="typeOfCall"></span>
    </div>
    <div class="col-sm-8">
        <div class="comm-tools">
            <input class="label-top-buffer qa-type-of-call-input-outbound" id="outbound" type="radio"
                (change)="onSelectionChange(selectedCallType)" (blur)="onBlur.emit()"
                value="{{activitySubTypes.outBoundCall}}" [(ngModel)]="selectedCallType"/>
            &ngsp;
            <label class="aa-padding-right" for="outbound" translate="outbound"></label>
            
        </div>

        <div class="comm-tools">
            <input class="label-top-buffer qa-type-of-call-input-inbound" id="inbound" type="radio"
                (change)="onSelectionChange(selectedCallType)" (blur)="onBlur.emit()"
                value="{{activitySubTypes.inBoundCall}}" [(ngModel)]="selectedCallType"/>
            &ngsp;
            <label class="aa-padding-right" for="inbound" translate="inbound"></label>
            
        </div>
    </div>
</div>