
/* injects from baggage-loader */
require('./deal-sheet-lite.component.html');

import angular from 'angular';
import pandoOnIconPath from '../../images/icon-pando-profile-on.svg';
import pandoOffIconPath from '../../images/icon-pando-profile-off.svg';
import './deal-sheet.scss';

import oneImage from '../../images/Capture1.png';
import twoImage from '../../images/Capture2.png';


//NOTE: This code is designed as a prototype, and requires significant cleanup.

angular.module('AaApp.Opp').component('dealSheetLite', {
    templateUrl: '/app/components/deal-sheet/deal-sheet-lite.component.html',
    controller: DealSheetLiteController,
    bindings: {
        opportunityId: '<',
        stockNo: '<',           //optional
        close: '<',             //optional
        dismiss: '<',           //optional
        vwsearch: '<',          //required, or OpportunityID is required
        resolve: '<'
    }
});



/* @ngInject */
function DealSheetLiteController(
    //angular services
    $timeout, $q, $translate, $translatePartialLoader, $scope, $window, $analytics, $state, logger, $document,
    //shared services
    dealSheetService,

    //other services
    opportunity2Service, opportunityService, vehicleService, contractService, authorizationService, globalIframeService,
    userService, dealerService, activityService, storageService, eulaModalService, customerService, $logFactory, 

    //$translate related
    localePaths, switchToNewDealSheetKey, switchToClassicDealSheetKey, dontHaveAccessWarningKey, dontHaveAccessExpiredKey,

    //constants
    legacy, dealSheetTabs, saleTypes, conquestTypes, msrpTypes, vehicleTypes, alertScriptTypes, activityRule, analytics, userProfileRoles, modules,
    submitDiscrepancySuccessMessageKey, paymentTypes, ActivityActionEnum, activityResults, tradeValueTypes, opportunityStatuses, alertTypes, entityActionAccessEnum,
    environmentVariables,

    //Tools
    _,
    mapUtils) 
    
{
    var $ctrl = this;

    var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;

    const log = $logFactory.get();

    $ctrl.showLegacy = false;
    $ctrl.closeButtonVisible = true;
    $ctrl.newWindowButtonVisible = false;
    $ctrl.vehicleTypes = vehicleTypes;
    $ctrl.isTextingEnabled = false;

    //NEW BINDINGS:
    $ctrl.dealer;
    $ctrl.paymentTypes;
    $ctrl.vw_SearchRow = $ctrl.vwsearch || { opportunityId: $ctrl.opportunityId };
    $ctrl.onChange = $ctrl.onChange;
    $ctrl.alertExists = $ctrl.vw_SearchRow._Is_Alert == "Yes";
    $ctrl.flexAlertExists = $ctrl.vw_SearchRow._Is_Flex_Alert == "Yes";
    $ctrl.contractEndAlertExists = $ctrl.vw_SearchRow._Is_Contract_End == "Yes";

    $ctrl.NewUsedCpoToID = function(vehicleType){
        vehicleType = vehicleType.toString().toLowerCase();
        if (vehicleType == "new") return 0;
        if (vehicleType == "used") return 1;
        if (vehicleType == "cpo") return 2;
        return "";
    }
    $ctrl.toSaleTypeID = function(saleType){
        saleType = saleType.toString().toLowerCase();
        if (saleType == "lease") return 1;
        if (saleType == "retail") return 2;
        if (saleType == "balloon") return 3;
        if (saleType == "cash") return 4;
        if (saleType == "onepay lease") return 5;
        if (saleType == "wholesale") return 6;
        if (saleType == "incomplete") return 7;
        return "";
    }
    $ctrl.toYesNoTypeID = function(yesNoTypeName){
        yesNoTypeName = yesNoTypeName.toString().toLowerCase();
        if (yesNoTypeName == "yes") return 1;
        if (yesNoTypeName == "no") return 0;
        return 0;
    }
    $ctrl.nullIf = function(valueIfNull, defaultValue){
        if (valueIfNull) return null;
        return defaultValue;
    }
    $ctrl.toAlertTypeList = function(vw_search)
    {
        var alerts = [];
        if (vw_search.is_Alert == "Yes") pushAlert(alerts, alertTypes.alert, 'alert');
        if (vw_search.is_Appointment == "Yes") pushAlert(alerts, alertTypes.appointment, 'appointment');
        if (vw_search.is_Contract_End == "Yes") pushAlert(alerts, alertTypes.contractEnd, 'contractEnd');
        //if (vw_search.is_Conversion_Alert == "Yes") pushAlert(alerts, alertTypes.alert, 'alert');
        if (vw_search.is_Flex_Alert == "Yes") pushAlert(alerts, alertTypes.flexAlert, 'flexAlert');
        if (vw_search.is_In_Market == "Yes") pushAlert(alerts, alertTypes.inMarket, 'inMarket');
        if (vw_search.is_In_Market_Engaged == "Yes") pushAlert(alerts, alertTypes.inMarketEngaged, 'inMarketEngaged');
        if (vw_search.is_Mileage_Alert == "Yes") pushAlert(alerts, alertTypes.mileage, 'mileage');
        if (vw_search.is_Warranty_Alert == "Yes") pushAlert(alerts, alertTypes.warranty, 'warranty');

        return alerts;
    }
    $ctrl.showIfNotNullOrEmpty = function(defaultValue, item, obj){

        if (item != null && item != "")
        {
            if (obj != null) return obj; 
            return item;
        }
        return defaultValue;
    }

    
    function pushAlert(arr, type, name){
        arr.push({
            type: type,
            typeName: name,
            translateKey: name + "RelevantInfo",
            details: $ctrl.opportunity.replacementVehicle,
            
        });
    }


    $ctrl.fuelEconomyComparison = {
        estimatedMonthlyCostDifference: $ctrl.vw_SearchRow.replacement_Monthly_Fuel_Difference
    }

    //**************************************************************************************
    //NOTE: This code is designed as a prototype, and requires significant cleanup.
    //TO clean this code up, first you must remove all of the current API calls, and
    //compare the data structure below with the required data structure.
    //Time Estimate: 1.5 days
    //**************************************************************************************
    //BIND TO OLD MODELS
    $ctrl.opportunityOverwrite = opportunityService.getOpportunityFromViewEntityId($ctrl.opportunityId, $ctrl.vw_SearchRow);

    $ctrl.permissions = {
        "activitiesLog": true,
        //"editActivity": true,
        //"editAssignedUser": true,
        //"editConquestContract": true,
        //"editContract": true,
        //"editCustomer": true,
        "editDoNotContact": true,
        "editMarkAsNotOwn": true,
        "editMarkAsPaidOff": true,
        "editMarkAsSold": true,
        //"editMarkAsWarranty": true,
        //"editMarkAsWatch": true,
        //"editMileage": true,
        //"editPayoffAmount": true,
        "editPrice": true,
        //"emailTemplate": true,
        "featureComparison": false,
        //"manageSearches": false,
        "otherVehiclesOwned": false,
        //"paymentDetails": true,
        "payments": true,
        "postActivity": true,
        //"print": true,
        //"printTemplate": true,
        //"requestCreditPrescreen": true,
        //"serviceHistory": true,
        //"vehicleHistoryReports": true,
        "viewAlertScripts": true,
        "viewChangeLog": true,
        "viewContract": true,
        "viewCustomer": true,
        "viewInventory": true,
        "viewTradeValue": true,
        "viewVehicle": true,
        //"warranty": true
    };
    
    $ctrl.vehicleTypeKeys = _.invert(vehicleTypes);
    $ctrl.saleTypes = saleTypes;
    $ctrl.saleTypeKeys = _.invert(saleTypes);
    $ctrl.alertScriptTypes = alertScriptTypes;
    $ctrl.alertScriptTypeKeys = _.invert(alertScriptTypes);
    $ctrl.msrpTypes = msrpTypes;
    $ctrl.paymentTypes = paymentTypes;
    $ctrl.conquestTypes = conquestTypes;

    $ctrl.tabs = dealSheetTabs;
    $ctrl.newDealSheetReady = false;
    $ctrl.newDealSheetVisible = dealSheetService.modalDisplayState === 'new';
    $ctrl.serviceHistory = null;
    $ctrl.activityNotification = false;
    $ctrl.userProfileRoles = userProfileRoles;
    $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
    $ctrl.tradeValueTypes = tradeValueTypes;
    $ctrl.opportunityStatuses = opportunityStatuses;
    $ctrl.eulaModalService = eulaModalService;
    $ctrl.showPullForward = false;
    $ctrl.isEmailEnabled = false;

    // methods
    $ctrl.$onInit = init;
    $ctrl.closeModal = closeModal;
    $ctrl.changeTab = changeTab;
    $ctrl.showNoRecommendedVehiclesMessage = showNoRecommendedVehiclesMessage;
    $ctrl.isDealSheetDirty = false;
    $ctrl.isDealsheetVersionChangeEnabled = legacy.isDealsheetVersionChangeEnabled;
    $ctrl.updateAssignedUser = updateAssignedUser;
    $ctrl.onTradeValueChange = onTradeValueChange;
    $ctrl.onPriceChange = onPriceChange;
    $ctrl.onWatchChange = onWatchChange;
    $ctrl.startWorkingState = startWorkingState;
    $ctrl.stopWorkingState = stopWorkingState;
    $ctrl.setReplacementVehicle = setReplacementVehicle;
    $ctrl.updateReplacementVehicleByTrim = updateReplacementVehicleByTrim;
    $ctrl.onScheduleSubmit = onScheduleSubmit;
    $ctrl.showPrintDialog = showPrintDialog;
    $ctrl.setReplacementVehicleType = setReplacementVehicleType;
    $ctrl.$onDestroy = onDestroy;
    $ctrl.getCreditConvertData = getCreditConvertData;
    $ctrl.otherVehicleHasAlerts;
    $ctrl.otherVehicleStatusClosed;
    $ctrl.submitDiscrepancy = submitDiscrepancy;
    $ctrl.setInventoryReplacementVehicle = setInventoryReplacementVehicle;
    $ctrl.setCreditPrescreenStatus = setCreditPrescreenStatus;
    $ctrl.onLogScheduleSubmit = onLogScheduleSubmit;
    $ctrl.setDefaultTab = setDefaultTab;
    $ctrl.onNoLongerOwns= onNoLongerOwns;
    $ctrl.isVehicleStatusVisible = isVehicleStatusVisible;
    $ctrl.setReplacementContractOverride = setReplacementContractOverride;
    $ctrl.applyAndOperation = applyAndOperation;
    $ctrl.getDefaultTab = getDefaultTab;
    $ctrl.LogActivityClick = LogActivityClick;
    $ctrl.updateCustomer = updateCustomer;

    $ctrl.funcKeydown = funcKeydown;

    //translations
    $translatePartialLoader.addPart(localePaths.dealSheet);
    $translatePartialLoader.addPart(localePaths.general);

    // spinner
    $ctrl.dealsheetWorking;
    $ctrl.hideWhenBusy = false;

    //customer-connect tab status
    $ctrl.isCustomerConnectOpen = false;
    $ctrl.isUserEditedTradeValue = false;

    // term override
    $ctrl.hasTermOverride = false;
    $ctrl.overrideTermInfo;

    $ctrl.mKey = 77;
    function LogActivityClick(value) {
        $ctrl.isCustomerConnectOpen = value;
    }

    function closeModal() {
        globalIframeService.postMessage({ 'task': 'opportunity_refresh' });
        $ctrl.close();
    }

    function applyAndOperation(one, two)
    {
        if (one == null || two == null) return {};
        
        var response = {};
        for(var prop in one)
        {
            if (typeof(one[prop]) == 'boolean' && typeof(two[prop]) == 'boolean')
            {
                response[prop] = one[prop] && two[prop];
            }
        }
        return response;
    }

    //This belongs on the API level.
    function checkExpiration(dealSheetExpirationDate) {
        var todayDate = (new Date()).setHours(0, 0, 0, 0);
        var expirationDate = (new Date(dealSheetExpirationDate)).setHours(0, 0, 0, 0);
        return (todayDate > expirationDate);
    }

    function errorExitWithCode(key) {
        $translate([key]).then(showWarningMessage);

        function showWarningMessage(translations) {
            logger.warning(translations[key]);
        }
        if ($ctrl.dismiss) {
            $ctrl.dismiss({ $value: 'cancel' });
        } else {
            $state.go('home');
        }
    }

    function errorExit(key) {
        return function (response) {
            errorExitWithCode(key);
        }
    }

    function init() {
        $q.all({
            'leadAssignment': $translate('leadAssignment'),
            'date': $translate('date')
        }).then(function (translation) {
            $ctrl.leadAssignmentLabel = translation.leadAssignment;
            $ctrl.dateLabel = translation.date;
        });

        opportunityService.getOpportunityFromViewEntityId($ctrl.opportunityId, $ctrl.vw_SearchRow)
            .then(function(vw_search){
                $ctrl.opportunityOverwrite = opportunityService.getOpportunityFromView(vw_search);

                if ($ctrl.opportunityId) {
                    $ctrl.dealsheetWorking = $q.defer().promise;
                    if (dealSheetService.modalDisplayState === 'new') {
                        $ctrl.isDealsheetVersionChangeEnabled = false;
                        $ctrl.changeDealSheetLabel = switchToClassicDealSheetKey;
                        $ctrl.newDealSheetVisible = true;
                        $ctrl.hideWhenBusy = true;
                        initNewDealSheet();
                    }
                    else {
                        $ctrl.newDealSheetVisible = false;
                        $ctrl.changeDealSheetLabel = switchToNewDealSheetKey;
                        $ctrl.dealsheetWorking = null;
                    }
                }
        });

        eulaModalService.isEmailEnabled().then(isEnabled => $ctrl.isEmailEnabled = isEnabled);
    }

    function initNewDealSheet() {
        $ctrl.newDealSheetReady = false;
        $ctrl.contractReady = false;
        $ctrl.opportunityDetailsReady = false;
        $ctrl.opportunity = $ctrl.opportunityOverwrite;
        return userService.getUserProfile()
            .then(function (userProfile) {
                return $q.all({
                    'dealSheetPermissions': authorizationService.getDealSheetPermissions(),
                    'userProfile': $q.resolve(userProfile),
                    'dealerSettings': dealerService.getDealerSettings($ctrl.opportunity.dealerId),
                    'users': dealerService.getUsers($ctrl.opportunity.dealerId),
                    'dealer': dealerService.getDealer($ctrl.opportunity.dealerId),
                    'saleExt': opportunityService.getSaleExtByEntityId($ctrl.opportunity.id)
                })
            })
            .then(function (data) {
                $ctrl.permissions = $ctrl.applyAndOperation($ctrl.permissions, data.dealSheetPermissions);
                $ctrl.userProfile = data.userProfile;
                $ctrl.dealerSettings = data.dealerSettings;
                $ctrl.saleExt = data.saleExt;

                $ctrl.moduleIds = storageService.getItem('moduleIds');
                $ctrl.pricingPlanEnabled = userService.isDealerEnabledForModule($ctrl.opportunity.dealerId, modules.fordAXZPlanPricing);

                $ctrl.isSltUser = data.userProfile.isSltUser;
                angular.element($window).on("keydown", $ctrl.funcKeydown);

                if ($ctrl.dealerSettings.allowExpiration && checkExpiration($ctrl.opportunity.expirationDate)) {
                    errorExitWithCode(dontHaveAccessExpiredKey);
                }

                $ctrl.opportunity.userProfile = $ctrl.userProfile;
                $ctrl.users = data.users;
                $ctrl.dealer = $ctrl.opportunity.dealer = data.dealer;
                $ctrl.newDealSheetReady = true;
                $ctrl.dealsheetWorking = null;
                
                const hasTextingModule = userService.isDealerEnabledForModule($ctrl.opportunity.dealerId, modules.textMessaging);
                $ctrl.isTextingEnabled = hasTextingModule && ($ctrl.permissions.sendTextSales || $ctrl.permissions.sendTextService);
                // For now set this to false until we determine that Lite should have this function:
                $ctrl.isTextingEnabled = false;

                $ctrl.defaultTab = $ctrl.getDefaultTab();
                $ctrl.changeTab($ctrl.defaultTab);

                if ($ctrl.opportunityOverwrite.replacementVehicle.makeID == null) //indicator that the replacement doesn't exist
                {
                    vehicleService.getAverageReplacementVehicle($ctrl.opportunity.dealerId, $ctrl.opportunity.vehicleId, $ctrl.opportunity.customer.pricingPlanId)
                        .then(setReplacementVehicle);
                }
                else{
                    updateReplacementVehicleByTrim($ctrl.opportunity.replacementVehicle);
                }

               

            })
            .catch(errorExit(dontHaveAccessWarningKey));
    }

    function funcKeydown($event) {
        if ($ctrl.userProfile.employeeUser){
            if ($event.ctrlKey && $event.keyCode == $ctrl.mKey) {
                $ctrl.isDealsheetVersionChangeEnabled = true;
            }
        }
    }

    function updateCustomer(customer) {
        $ctrl.startWorkingState();
        log.log(customer);
        customerService.updateCustomer(customer).then(function (success) {
            if (success) {
                $ctrl.opportunity.customer = customer;
                reLoadOpportunity(1000);
                $ctrl.stopWorkingState();
            }
            else {
                $ctrl.stopWorkingState();
            }
        }, function () {
            $ctrl.stopWorkingState();
        });
    }

    function updateAssignedUser(user) {
        $ctrl.startWorkingState();

        opportunityService.updateAssignedUser($ctrl.opportunity.id, user.id)
            .then(function (success) {
                if (success) {
                    $ctrl.opportunity.assignedUserId = user.id;
                    $ctrl.opportunity.assignedUserFullName = user.fullName;
                    $ctrl.stopWorkingState();
                }
            })
            .catch(function () {
                $ctrl.stopWorkingState();
            });
    }

    function setActivities(activities) {
        $ctrl.activities = activities;
    }

    function onTradeValueChange(value, logged) {
        startWorkingState();
        $ctrl.isDealSheetDirty = true;
        $ctrl.opportunity.currentVehicle.tradeValue = value;
        $ctrl.isUserEditedTradeValue = true;
        reCalculateVehicleEquity();
        reCalculateReplacementContract();
        if (logged) { opportunityService.logTradeValueChange($ctrl.opportunity.vehicleId, value); }
    }

    function reCalculateVehicleEquity() {
        var tradeValue = $ctrl.opportunity.currentVehicle.tradeValue;
        var payoffValue = $ctrl.opportunity.currentContract.payoffAmount ? $ctrl.opportunity.currentContract.payoffAmount : 0;
        $ctrl.opportunity.currentContract.equity = {
            tradeValue: tradeValue,
            equityValue: tradeValue - payoffValue
        };
    }

    function onPriceChange(value) {
        $ctrl.isDealSheetDirty = true;
        $ctrl.opportunity.replacementVehicle.price = value;
        startWorkingState();
        // log price change
        opportunityService.addPriceOverrideChangeLog($ctrl.opportunity.vehicleId, value);
        reCalculateReplacementContract();
    }

    function reCalculateReplacementContract() {
        if ($ctrl.opportunity.replacementContract && $ctrl.opportunity.replacementContract.useOverrideTerm) {
            // setup override term
            $ctrl.hasTermOverride = true;
            $ctrl.overrideTermInfo = {
                term: $ctrl.opportunity.replacementContract.term,
                saleType: $ctrl.opportunity.replacementContract.saleType,
                saleTypeName: $ctrl.opportunity.replacementContract.saleTypeName
            };
        }
        contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions).then(setReplacementContracts);
    }

    function startWorkingState() {
        $ctrl.hideWhenBusy = true;
        $ctrl.dealsheetWorking = $q.defer().promise;
    }

    function stopWorkingState() {
        $ctrl.hideWhenBusy = false;
        $ctrl.dealsheetWorking = null;
    }

    function onWatchChange(value) {
        if (value) {
            return opportunityService.markOpportunityWatched($ctrl.opportunityId)
                .then(function () {
                    $ctrl.opportunity.watched = true;
                });
        } else {
            return opportunityService.markOpportunityUnwatched($ctrl.opportunityId)
                .then(function () {
                    $ctrl.opportunity.watched = false;
                });
        }
    }

    function setReplacementVehicle(vehicle) {

        if (!vehicle) {
            $ctrl.showNoRecommendedVehiclesMessage();
            return;
        }

        $ctrl.startWorkingState();

        updateReplacementVehicleFuelCost(vehicle);
        $ctrl.featureComparison = null;

        if ($ctrl.pricingPlanEnabled && vehicle.pricingPlanID && vehicle.pricingPlanID > 0) {
            vehicle.price = vehicle.pricingPlanMsrp;
        }
        if (!vehicle.modelID) {
            vehicleService.getModelId(vehicle.trimID).then(function (modelID) {
                $ctrl.isDealSheetDirty = true;
                $ctrl.opportunity.replacementVehicle = vehicle;
                $ctrl.opportunity.replacementVehicle.modelID = modelID;
                updateReplacementVehicleImagePath();
                contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions)
                    .then(setReplacementContracts);
            });
        } else {
            $ctrl.isDealSheetDirty = true;
            $ctrl.opportunity.replacementVehicle = vehicle;
            updateReplacementVehicleImagePath();
            contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions)
                .then(setReplacementContracts);
        }
    }

    function updateReplacementVehicleImagePath() {
        if (!$ctrl.opportunity.replacementVehicle.imagePath) {
            vehicleService.getVehicleImagePath($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID)
                .then(function (imagePath) {
                    $ctrl.opportunity.replacementVehicle.imagePath = imagePath;
                });
        }
    }

    function updateReplacementVehicleFuelCost(vehicle) {
        if (vehicle && vehicle.trimID && vehicle.trimID > 0) {
            vehicleService.getFuelEconomyComparison(vehicle.trimID, $ctrl.opportunityId)
                .then(function (data) {
                    $ctrl.fuelEconomyComparison = data;
                })
                .catch(function () { $ctrl.fuelEconomyComparison = null; });
        } else {
            $ctrl.fuelEconomyComparison = null;
        }
    }

    function changeTab(tab) {
        if (tab === $ctrl.tabs.featureComparison) {
            updateReplacementVehicleFeatures($ctrl.opportunity.replacementVehicle);
        }

        $ctrl.currentTab = tab;

        var tabLabel = _.invert($ctrl.tabs)[tab];

        $analytics.eventTrack(analytics.actions.navigate, {
            category: analytics.categories.dealSheet,
            label: analytics.labels[tabLabel]
        });

        refreshTabs();
    }
    
    function refreshTabs() {
        $ctrl.tabLoadingPromise = null;

        if ($ctrl.currentTab == $ctrl.tabs.activityAndNotes) {
            if ($ctrl.activityNotification) {
                $ctrl.activityNotification = false;
                opportunityService.resetActivityNotification();
            }
            $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
        }
        if ($ctrl.currentTab == $ctrl.tabs.featureComparison) {
            if ($ctrl.defaultTab == $ctrl.tabs.featureComparison) {
                $timeout(function () {
                    $scope.$broadcast('rzSliderForceRender');
                }, 1000);
            }
            else {
                $timeout(function () {
                    $scope.$broadcast('rzSliderForceRender');
                }, 200);
            }
        }
    }

    function updateReplacementVehicleFeatures(vehicle) {
        if (vehicle && vehicle.trimID && vehicle.trimID > 0) {
            vehicleService.getFeatureComparison(vehicle.trimID, $ctrl.opportunityId)
                .then(function (data) {
                    $ctrl.featureComparison = data;
                })
                .catch(function () { $ctrl.featureComparison = null; });
        } else {
            $ctrl.featureComparison = null;
        }
    }

    function updateReplacementVehicleByTrim(trim) {

        $analytics.eventTrack(analytics.actions.changeReplacementVehicle, {
            category: analytics.categories.dealSheet,
            label: analytics.labels.trimChange
        });
        $ctrl.startWorkingState();
        $ctrl.opportunity.replacementVehicles = null;
        var trimIsValid = !(trim.yearID == "" || trim.makeID == "" || trim.modelID == "");
        if (trimIsValid)
        {
            var vehicle = {
                type: vehicleTypes.new,
                makeID: trim.makeID,
                makeName: trim.makeName,
                yearID: trim.yearID,
                modelID: trim.modelID,
                modelName: trim.modelName,
                trimID: trim.trimID,
                trimName: trim.trimName,
                trimFullName: trim.trimFullName,
                extID: trim.extID
            };
            updateReplacementVehicleFuelCost(vehicle);

            $ctrl.opportunity.replacementVehicle = vehicle;
            $ctrl.opportunity.isDealSheetDirty = true;

            if (!vehicle.imagePath) {
                vehicleService.getVehicleImagePath($ctrl.opportunity.dealerId, vehicle.trimID).then(function (imagePath) {
                    $ctrl.opportunity.replacementVehicle.imagePath = imagePath;
                    vehicleService.getTrimPrice($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID)
                        .then(setReplacementVehicleMsrp);
                });
            }
            else {
                vehicleService.getTrimPrice($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.trimID)
                    .then(setReplacementVehicleMsrp);
            }
        }
    }

    function setReplacementVehicleMsrp(msrp) {

        $ctrl.opportunity.replacementVehicle.msrp = msrp;
        $ctrl.opportunity.replacementVehicle.price = msrp;
        contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions)
            .then(setReplacementContracts);
    }

    function setReplacementContracts(data) {

        if (data && data.contracts) {
            $ctrl.opportunity.replacementContracts = data.contracts;
            if ($ctrl.hasTermOverride && $ctrl.overrideTermInfo && $ctrl.overrideTermInfo.term) {
                // get override contract from term and set to replacement...
                var overrideContract = _.find(data.contracts, function (o) { return o.term === $ctrl.overrideTermInfo.term && o.saleType === $ctrl.overrideTermInfo.saleType });
                if (overrideContract) {
                    overrideContract.useOverrideTerm = true;
                    $ctrl.opportunity.replacementContract = overrideContract;
                } else {
                    $ctrl.opportunity.replacementContract = data.defaultContract;
                }
                $ctrl.hasTermOverride = false;
            } else {
                $ctrl.opportunity.replacementContract = data.defaultContract;
            }

            if ($ctrl.opportunity.replacementContract) {
                $ctrl.opportunity.replacementContract.vehicleType = $ctrl.opportunity.replacementVehicle.type;

            }
        } else {
            $ctrl.opportunity.replacementContracts = [];
            $ctrl.opportunity.replacementContract = null;
        }
        stopWorkingState();
    }

    function setReplacementContractOverride(contract) {
        $ctrl.startWorkingState();
        // persist vehile type from current replacement contract since contract does not persist it
        if ($ctrl.opportunity.replacementContract) {
            contract.vehicleType = $ctrl.opportunity.replacementContract.vehicleType;
        } else {
            contract.vehicleType = vehicleTypes.new;
        }
        // set replacement contract
        $ctrl.opportunity.replacementContract = contract;
        // log into change log
        opportunityService.addTermOverrideChangeLog($ctrl.opportunity.vehicleId, contract.term)
            .then($ctrl.stopWorkingState, $ctrl.stopWorkingState);
    }

    function onLogScheduleSubmit(result) {

        if (!result) { return; } // user data entry error

        if (result == 'cancel') {
            // user canceled operation close accordian panel and reset Customer Connect to default
            $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
            $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
            return;
        }

        // submit user's activity changes
        $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
        startWorkingState();
        activityService.postActivitySubmitAction(result)
            .then(function (data) {
                if (result.isFollowUpScheduleAppt){
                    logger.success("postActivityFollowupMsg");
                    $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Schedule_Appt;
                    $ctrl.isCustomerConnectOpen = !$ctrl.isCustomerConnectOpen;
                }
                else {
                    logger.success("postActivitySuccessMsg");
                    $ctrl.defaultCustomerConnectAction = ActivityActionEnum.Log_Call;
                }

                // if vehicle no longer owns
                if (result.resultID == activityResults.phoneCall.noLongerOwns || result.resultID == activityResults.note.noLongerOwns){
                    $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
                    activityService.updateCustomerDoesNotOwnVehicle($ctrl.opportunityId).then(() => {
                        reLoadOpportunity();
                    });
                }
                else
                    onScheduleSubmit();

                // if vehicle paid off
                if (result.resultID == activityResults.phoneCall.vehiclePaidOff) {
                    activityService.updateVehiclePaidOff($ctrl.opportunityId)
                        .then(() => {
                            reLoadOpportunity();
                        });
                }
            });
    }

    function onNoLongerOwns() {
        startWorkingState();
        $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
        activityService.updateCustomerDoesNotOwnVehicle($ctrl.opportunityId).then(() => {
            reLoadOpportunity();
        });
    }

    function onScheduleSubmit() {
        startWorkingState();
        $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId).then(setActivities);
        opportunityService.getOpportunity($ctrl.opportunityId, $ctrl.stockIdValue, $ctrl.calculationId, $ctrl.permissions, $ctrl.moduleIds)
            .then(setOpportunityStatus)
            .catch(errorExit(dontHaveAccessWarningKey));
    }

    function setOpportunityStatus(data) {
        if (data) {
            $ctrl.opportunity.status = data.status;
            $ctrl.opportunity.alerts = data.alerts;
        }
        stopWorkingState();
    }

    function showPrintDialog() {
        activityService.runActivityRule($ctrl.opportunity.id, [activityRule.Print_Deal_Sheet]);
        if (!$ctrl.activities) {
            $ctrl.tabLoadingPromise = activityService.getActivities($ctrl.opportunity.id, $ctrl.opportunity.dealerId, $ctrl.opportunity.customerId)
            .then(function (activities) {
                $ctrl.activities = activities;
                $timeout($window.print, 250);
            });
        }
        else {
            $window.print();
        }
    }

    function setReplacementVehicleType(vehicleType) {
        $ctrl.startWorkingState();

        $ctrl.isDealSheetDirty = true;
        $ctrl.opportunity.replacementVehicle.type = vehicleType;

        vehicleService.getAdjustedInventoryVehiclePrice($ctrl.opportunity.dealerId, $ctrl.opportunity.replacementVehicle.type, $ctrl.opportunity.replacementVehicle.msrp)
            .then(function (price) {
                $ctrl.opportunity.replacementVehicle.price = price;
                contractService.getReplacementContracts($ctrl.opportunity, $ctrl.permissions)
                    .then((data) => setReplacementContracts.call($ctrl, data));
            });
    }

    function onDestroy() {
        opportunityService.resetActivityNotification();
        angular.element($window).off("keydown", $ctrl.funcKeydown);
    }

    function reLoadOpportunity(waitTime) {
        startWorkingState();
        $timeout(function () {
            opportunityService.getOpportunity($ctrl.opportunityId, $ctrl.stockNo, $ctrl.calculationId, $ctrl.permissions, $ctrl.moduleIds)
                .then(function (opportunity) {
                    setOpportunity(opportunity, $ctrl.userProfile);
                    stopWorkingState();
                }, function () {
                    stopWorkingState();
                    throw { message: "We encountered an error processing your request." };
                })
                .catch(errorExit(dontHaveAccessWarningKey));
        }, waitTime);
    }


    function setOpportunity(opportunity) {
        $ctrl.opportunity = opportunity;

        //load autoassigned data from returned service results
        $ctrl.isAutoAssignedOwner = $ctrl.opportunity.autoAssignOwner;
        if ($ctrl.opportunity.autoAssignOwnerSearch)
            $ctrl.autoOwnerLeadAssignmentToolTip = $ctrl.leadAssignmentLabel + ": " + $ctrl.opportunity.autoAssignOwnerSearch;

        $ctrl.dateSeparator = ": ";
        $ctrl.newLineSeparator = "\n";

        if ($ctrl.OriginalTradeValue == null)
            $ctrl.OriginalTradeValue = $ctrl.opportunity.currentVehicle.tradeValue;

        if ($ctrl.isSltUser) {
            var expirationDateLimit = new Date();
            expirationDateLimit.setYear(expirationDateLimit.getFullYear() + 1);

            $ctrl.showExpirationDate = new Date($ctrl.opportunity.expirationDate) < expirationDateLimit;
        }

        $ctrl.showVehicleNotPaidoff = mapUtils.hasActionAccess($ctrl.opportunity.actionAccess, entityActionAccessEnum.showVehicleNotPaidoff);
        opportunityService.addAlertsAsProperties($ctrl, $ctrl.opportunity.alerts);
        setDocumentTitle();

        $ctrl.isDealSheetDirty = false;
        $ctrl.replacementStockNo = opportunity.replacementVehicle.stockNo;
        $ctrl.activityNotification = opportunityService.activityNotification.notify && opportunityService.activityNotification.triggered;

        var programDetails = null;

        if (opportunity.replacementContract)
            programDetails = opportunity.replacementContract.programDetails;

        var isPrivateOffer = (programDetails != null && programDetails.some(p => p.isPrivateOffer));
        var creditPreApproval = opportunity.creditPreApproval;
        var isFordAlert = userService.isDealerEnabledForModule(opportunity.dealerId, modules.fordAlert);

        $ctrl.showPrivateOfferScript = (isFordAlert && isPrivateOffer && !creditPreApproval);
        $ctrl.showPrivateOfferPreApprovalScript = (isFordAlert && isPrivateOffer && creditPreApproval);
        updateReplacementVehicleFuelCost(opportunity.replacementVehicle);
    }

    function setDocumentTitle() {
        if (!$ctrl.newWindowButtonVisible && $ctrl.opportunity != null && $ctrl.opportunity.customer != null) {
            $document[0].title = ($ctrl.opportunity.customer.firstName || '') + ' '
                + ($ctrl.opportunity.customer.middleName || '') + ' '
                + ($ctrl.opportunity.customer.lastName || '');
        }
    }

    function getCreditConvertData() {
        opportunityService.getCreditSoftPullResultAsync($ctrl.permissions, $ctrl.opportunityId)
            .then(function (creditPrescreenResult) {
                $ctrl.opportunity.creditSoftPull = creditPrescreenResult;
            })

        opportunityService.getTags($ctrl.opportunityId)
            .then(function (tags) {
                $ctrl.opportunity.tags = tags;
            });
    }

    function submitDiscrepancy(discrepancy) {
        $ctrl.dealsheetWorking = opportunityService.submitDiscrepancy($ctrl.opportunity, discrepancy)
            .then(showSuccessMessage);

        function showSuccessMessage() {
            logger.success(submitDiscrepancySuccessMessageKey);
        }
    }

    function setInventoryReplacementVehicle(vehicle) {
        $ctrl.opportunity.replacementVehicles = null;
        setReplacementVehicle(vehicle);
    }

    function setCreditPrescreenStatus(status) {
        $ctrl.opportunity.creditPrescreenStatus = status;
    }

    function showNoRecommendedVehiclesMessage() {
        $ctrl.noRecommendedVehiclesMessageVisible = true;

        $timeout(function () {
            $ctrl.noRecommendedVehiclesMessageVisible = false;
        }, 5000);
    }

    function getDefaultTab() {
        var defaultTabs = storageService.getItem('defaultDealSheetTabs');
        var defaultTab;

        if (defaultTabs && defaultTabs[$ctrl.userProfile.userId]) {
            defaultTab = defaultTabs[$ctrl.userProfile.userId];
        }
        else {
            defaultTab = $ctrl.tabs.alertsAndScripts;
        }

        if (!isTabAccessible(defaultTab)) {
            defaultTab = getAccessibleTabs()[0];
        }

        return defaultTab;
    }

    function setDefaultTab() {
        var defaultTabs = storageService.getItem('defaultDealSheetTabs') || {};
        defaultTabs[$ctrl.userProfile.userId] = $ctrl.currentTab;

        storageService.setItem('defaultDealSheetTabs', defaultTabs);

        $ctrl.defaultTab = $ctrl.currentTab;
    }

    
    function getAccessibleTabs() {
        var accessibleTabs = [];
        var tabs = [
            $ctrl.tabs.alertsAndScripts,
            $ctrl.tabs.currentVehicleDetails,
            $ctrl.tabs.replacementVehicleDetails,
            $ctrl.tabs.featureComparison,
            $ctrl.tabs.activityAndNotes,
            $ctrl.tabs.serviceHistory,
            $ctrl.tabs.otherVehiclesOwned,
            $ctrl.tabs.changeLog
        ];

        for (var i = 0; i < tabs.length; i++) {
            var tab = tabs[i];

            if (isTabAccessible(tab)) {
                accessibleTabs.push(tab);
            }
        }

        return accessibleTabs;
    }

    function isTabAccessible(tab) {
        switch (tab) {
            case $ctrl.tabs.alertsAndScripts:
                return $ctrl.permissions.viewAlertScripts;
            case $ctrl.tabs.currentVehicleDetails:
                return $ctrl.permissions.viewVehicle || $ctrl.permissions.viewContract;
            case $ctrl.tabs.replacementVehicleDetails:
                return $ctrl.permissions.payments;
            case $ctrl.tabs.activityAndNotes:
                return $ctrl.permissions.activitiesLog;
            case $ctrl.tabs.serviceHistory:
                return $ctrl.permissions.serviceHistory;
            case $ctrl.tabs.otherVehiclesOwned:
                return $ctrl.userProfile.roleName != $ctrl.userProfileRoles.Sales_Associate && $ctrl.userProfile.roleName != $ctrl.userProfileRoles.Base_Sales_Associate;
            case $ctrl.tabs.changeLog:
                return $ctrl.permissions.viewChangeLog;
            default:
                return true;
        }
    }

    function isVehicleStatusVisible() {
        var availableChoices = 0;

        if ($ctrl.permissions.editMarkAsNotOwn)
            availableChoices++;
        if ($ctrl.permissions.editMarkAsPaidOff)
            availableChoices++;
        if ($ctrl.permissions.editMarkAsSold)
            availableChoices++;

        return availableChoices > 0 ? true : false;
    }
}
