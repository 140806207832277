"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./payoff.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("../../../icon/icon.component.ngfactory"));
var i4 = __importStar(require("../../../icon/icon.component"));
var i5 = __importStar(require("@angular/platform-browser"));
var i6 = __importStar(require("@angular/common"));
var i7 = __importStar(require("../../../../filters/locale-currency.pipe"));
var i8 = __importStar(require("../../../../modules/storage/storage.service"));
var i9 = __importStar(require("./payoff.component"));
var i10 = __importStar(require("../../../../services/ngx-modal-wrapper.service"));
var styles_PayoffComponent = [i0.styles];
var RenderType_PayoffComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PayoffComponent, data: {} });
exports.RenderType_PayoffComponent = RenderType_PayoffComponent;
function View_PayoffComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "edit-icon qa-payoff-edit-link"], ["href", "#"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.inputPayoffModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "icon", [["class", "icon aa-icon-core-edit-icon-000000"]], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i1.ɵdid(3, 638976, null, 0, i4.IconComponent, [i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("adjustPayoff")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_PayoffComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "field row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "field-label"], ["translate", "payoffAmount"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "field-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "qa-payoff-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayoffComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "payoffAmount"; _ck(_v, 2, 0, currVal_0); var currVal_2 = ((_co.permissions.editPayoffAmount && (_co.paymentsRemaining > 0)) && !_co.paidOff); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.value)); _ck(_v, 5, 0, currVal_1); }); }
function View_PayoffComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.LocaleCurrencyPipe, [i8.StorageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayoffComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.value != null) && (_co.saleType != _co.saleTypes.cash)); _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_PayoffComponent_0 = View_PayoffComponent_0;
function View_PayoffComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "payoff", [], null, null, null, View_PayoffComponent_0, RenderType_PayoffComponent)), i1.ɵdid(1, 114688, null, 0, i9.PayoffComponent, [i10.NgxModalWrapperService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PayoffComponent_Host_0 = View_PayoffComponent_Host_0;
var PayoffComponentNgFactory = i1.ɵccf("payoff", i9.PayoffComponent, View_PayoffComponent_Host_0, { value: "value", saleType: "saleType", paymentsRemaining: "paymentsRemaining", paidOff: "paidOff", residualAmount: "residualAmount", permissions: "permissions" }, { onPayoffChange: "onPayoffChange" }, []);
exports.PayoffComponentNgFactory = PayoffComponentNgFactory;
