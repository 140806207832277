"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var AdminRolesModalAddEditComponent = /** @class */ (function () {
    function AdminRolesModalAddEditComponent(_bsModalRef, tService, logger) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.logger = logger;
        this.parentRoleInvalid = false;
        this.roleNameInvalid = false;
        this.onSaveChanges = new core_1.EventEmitter();
    }
    AdminRolesModalAddEditComponent.prototype.ngOnInit = function () {
        if (this.newRole) {
            this.selectedRole.roleID = 0;
            this.selectedRole.roleName = '';
            this.selectedRole.parentRoleID = null;
            this.selectedRole.startWithParentRole = true;
        }
        this._bsModalRef.hide();
    };
    AdminRolesModalAddEditComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    AdminRolesModalAddEditComponent.prototype.onConfirm = function () {
        var _this = this;
        var alreadyExists = false;
        this.roleNameInvalid = false;
        this.parentRoleInvalid = false;
        this.parentRoleInvalid = (this.selectedRole.parentRoleID == null);
        if (this.selectedRole.roleName == '') {
            this.roleNameInvalid = true;
        }
        var flatRoles = [].concat.apply([], this.roleOptions);
        if (this.newRole && flatRoles.some(function (r) { return _this.selectedRole.roleName == r.roleName; })) {
            this.logger.warning(this.tService.instant("rolesadmin_roleexists"));
            alreadyExists = true;
        }
        if (!this.roleNameInvalid && !this.parentRoleInvalid && !alreadyExists) {
            this.onSaveChanges.emit({ selectedRole: this.selectedRole });
            this._bsModalRef.hide();
        }
    };
    AdminRolesModalAddEditComponent.prototype.onCancel = function () {
        this._bsModalRef.hide();
    };
    return AdminRolesModalAddEditComponent;
}());
exports.AdminRolesModalAddEditComponent = AdminRolesModalAddEditComponent;
