"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../ajs-upgraded-providers"));
var AdminTemplatesService = /** @class */ (function () {
    function AdminTemplatesService(legacyService) {
        this.legacyService = legacyService;
    }
    AdminTemplatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminTemplatesService_Factory() { return new AdminTemplatesService(i0.ɵɵinject(i1.LegacyService)); }, token: AdminTemplatesService, providedIn: "root" });
    return AdminTemplatesService;
}());
exports.AdminTemplatesService = AdminTemplatesService;
