import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.find-index";
FeatureComparisonFeaturesController.$inject = ["_", "$translatePartialLoader", "localePaths", "fuelTypes", "dealSheetTabs"];

/* injects from baggage-loader */
require('./feature-comparison-features.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('featureComparisonFeatures', {
  templateUrl: '/app/components/deal-sheet/contract/feature-comparison-features.component.html',
  controller: FeatureComparisonFeaturesController,
  bindings: {
    featureComparison: '<',
    fuelEconomyComparison: '<',
    currentVehicle: '<',
    replacementVehicle: '<',
    countryId: '<',
    defaultTab: '<',
    onSetAsDefault: '&',
    printingFeatures: '<',
    onPrintFeatures: '&'
  }
});

function FeatureComparisonFeaturesController(_, $translatePartialLoader, localePaths, fuelTypes, dealSheetTabs) {
  "ngInject";

  var $ctrl = this;
  $ctrl.fuelTypes = fuelTypes;
  $translatePartialLoader.addPart(localePaths.featureComparison);
  $ctrl.tabs = dealSheetTabs;
  $ctrl.isShowItem = isShowItem;
  $ctrl.hasAdvantage = false;
  $ctrl.prepData = prepData;
  $ctrl.$onChanges = $onChanges;
  $ctrl.rangeSelections = null;
  $ctrl.currentSelections = [null, null];
  $ctrl.currentRange = null;
  $ctrl.currentsavings = null;
  $ctrl.hasMultipleFuelTypes = false;
  $ctrl.showPrintFeaturesDialog = showPrintFeaturesDialog;
  $ctrl.slider = {
    value: 5,
    options: {
      showTicksValues: true,
      stepsArray: [],
      onChange: sliderChange
    }
  };

  function showPrintFeaturesDialog() {
    $ctrl.onPrintFeatures();
  }

  function sliderChange(sliderId, modelValue, highValue, pointerType) {
    var idx = _.findIndex($ctrl.rangeSelections, function (o) {
      return o.tickLabel == modelValue;
    });

    setCurrentSelections(idx);
  }

  function setCurrentSelections(idx) {
    $ctrl.currentsavings = null;

    if ($ctrl.fuelEconomyComparison.existingFuelEconomy) {
      $ctrl.currentSelections[0] = $ctrl.fuelEconomyComparison.existingFuelEconomy.fuelEstimateRanges[idx];
    } else {
      $ctrl.currentSelections[0] = null;
    }

    if ($ctrl.fuelEconomyComparison.replacementFuelEconomy) {
      $ctrl.currentSelections[1] = $ctrl.fuelEconomyComparison.replacementFuelEconomy.fuelEstimateRanges[idx];
    } else {
      $ctrl.currentSelections[1] = null;
    }

    $ctrl.currentRange = $ctrl.rangeSelections[idx].estimatedAnnualDistance;

    if ($ctrl.currentSelections[0] && $ctrl.currentSelections[1]) {
      var saving = $ctrl.currentSelections[0].estimatedAnnualFuelCost - $ctrl.currentSelections[1].estimatedAnnualFuelCost;

      if (saving > 0) {
        $ctrl.currentsavings = saving;
      }
    }
  }

  function setSteps(idx) {
    $ctrl.slider.options.stepsArray = [];

    _.forEach($ctrl.rangeSelections, function (val) {
      $ctrl.slider.options.stepsArray.push({
        value: val.tickLabel
      });
    });

    $ctrl.CurrentSelection = idx;
    $ctrl.slider.value = $ctrl.rangeSelections[idx].tickLabel;
    setCurrentSelections(idx);
  }

  function setupUxKeys(fuelEconomy, comparison) {
    fuelEconomy.alticon = null;
    fuelEconomy.alttransKey = null;

    if ($ctrl.hasMultipleFuelTypes && comparison) {
      // multi type logic
      switch (fuelEconomy.fuelTypeId) {
        case $ctrl.fuelTypes.cng:
        case $ctrl.fuelTypes.diesel:
        case $ctrl.fuelTypes.gas:
          // note: set icon in seperate swwitch below
          fuelEconomy.transKey = 'mpg';

          switch (comparison.fuelTypeId) {
            case $ctrl.fuelTypes.phev:
            case $ctrl.fuelTypes.electric:
              fuelEconomy.alticon = 'aa-icon-electric';
              fuelEconomy.alttransKey = 'mpge';
              break;

            case $ctrl.fuelTypes.cng:
            case $ctrl.fuelTypes.diesel:
            case $ctrl.fuelTypes.gas:
            default:
              break;
          }

          break;

        case $ctrl.fuelTypes.phev:
        case $ctrl.fuelTypes.electric:
          fuelEconomy.alticon = 'aa-icon-electric';
          fuelEconomy.alttransKey = 'mpge';
          fuelEconomy.transKey = 'mpg';

          switch (comparison.fuelTypeId) {
            case $ctrl.fuelTypes.cng:
              fuelEconomy.icon = 'aa-icon-cng';
              break;

            case $ctrl.fuelTypes.diesel:
              fuelEconomy.icon = 'aa-icon-diesel';
              break;

            case $ctrl.fuelTypes.gas:
              fuelEconomy.icon = 'aa-icon-gasoline';
              break;

            case $ctrl.fuelTypes.phev:
            case $ctrl.fuelTypes.electric:
              fuelEconomy.icon = 'aa-icon-gasoline';
              break;

            default:
              break;
          }

          break;

        default:
          break;
      } // note: 2nd switch helps eliminate duplicate inner switch code per DS ~JG


      switch (fuelEconomy.fuelTypeId) {
        case $ctrl.fuelTypes.cng:
          fuelEconomy.icon = 'aa-icon-cng';
          break;

        case $ctrl.fuelTypes.diesel:
          fuelEconomy.icon = 'aa-icon-diesel';
          break;

        case $ctrl.fuelTypes.gas:
          fuelEconomy.icon = 'aa-icon-gasoline';
          break;

        default:
          break;
      }
    } else {
      // single type logic
      switch (fuelEconomy.fuelTypeId) {
        case $ctrl.fuelTypes.cng:
          fuelEconomy.icon = 'aa-icon-cng';
          fuelEconomy.transKey = 'mpg';
          break;

        case $ctrl.fuelTypes.diesel:
          fuelEconomy.icon = 'aa-icon-diesel';
          fuelEconomy.transKey = 'mpg';
          break;

        case $ctrl.fuelTypes.phev:
          fuelEconomy.icon = 'aa-icon-electric';
          fuelEconomy.transKey = 'mpge';
          fuelEconomy.alticon = 'aa-icon-gasoline';
          fuelEconomy.alttransKey = 'mpg';
          break;

        case $ctrl.fuelTypes.electric:
          fuelEconomy.icon = 'aa-icon-electric';
          fuelEconomy.transKey = 'mpge';
          break;

        case $ctrl.fuelTypes.gas:
        default:
          fuelEconomy.icon = 'aa-icon-gasoline';
          fuelEconomy.transKey = 'mpg';
          break;
      }
    }
  }

  function $onChanges(changes) {
    if (changes && changes.featureComparison && changes.featureComparison.currentValue) {
      _.forEach(changes.featureComparison.currentValue.categories, function (o) {
        _.forEach(o.items, function (i) {
          i.existingVehicle.translate = checkData(i.existingVehicle.value);
          i.newVehicle.translate = checkData(i.newVehicle.value);
          i.isShowItem = isShowItem(i.featureId);
        });
      });
    }

    if (changes && changes.fuelEconomyComparison && changes.fuelEconomyComparison.currentValue) {
      if (changes.fuelEconomyComparison.currentValue.existingFuelEconomy && changes.fuelEconomyComparison.currentValue.replacementFuelEconomy) {
        if (changes.fuelEconomyComparison.currentValue.existingFuelEconomy.combined && changes.fuelEconomyComparison.currentValue.existingFuelEconomy.combinedEquivalent || changes.fuelEconomyComparison.currentValue.replacementFuelEconomy.combined && changes.fuelEconomyComparison.currentValue.replacementFuelEconomy.combinedEquivalent) {
          $ctrl.hasMultipleFuelTypes = true;
        } else if (changes.fuelEconomyComparison.currentValue.existingFuelEconomy.combined && changes.fuelEconomyComparison.currentValue.replacementFuelEconomy.combinedEquivalent || changes.fuelEconomyComparison.currentValue.replacementFuelEconomy.combined && changes.fuelEconomyComparison.currentValue.existingFuelEconomy.combinedEquivalent) {
          $ctrl.hasMultipleFuelTypes = true;
        } else {
          $ctrl.hasMultipleFuelTypes = false;
        }
      }

      if (changes.fuelEconomyComparison.currentValue.existingFuelEconomy) {
        setupUxKeys(changes.fuelEconomyComparison.currentValue.existingFuelEconomy, changes.fuelEconomyComparison.currentValue.replacementFuelEconomy);
      }

      if (changes.fuelEconomyComparison.currentValue.replacementFuelEconomy) {
        setupUxKeys(changes.fuelEconomyComparison.currentValue.replacementFuelEconomy, changes.fuelEconomyComparison.currentValue.existingFuelEconomy);
      }

      if (!changes.fuelEconomyComparison.currentValue.existingFuelEconomy && !changes.fuelEconomyComparison.currentValue.replacementFuelEconomy) {
        $ctrl.currentSelections[0] = null;
        $ctrl.currentSelections[1] = null;
      }

      switch (changes.fuelEconomyComparison.currentValue.eadSource) {
        case 1:
          $ctrl.rangeSelections = changes.fuelEconomyComparison.currentValue.existingFuelEconomy.fuelEstimateRanges;
          setSteps(changes.fuelEconomyComparison.currentValue.eadIndex);
          break;

        case 2:
          $ctrl.rangeSelections = changes.fuelEconomyComparison.currentValue.replacementFuelEconomy.fuelEstimateRanges;
          setSteps(changes.fuelEconomyComparison.currentValue.eadIndex);
          break;

        default:
          $ctrl.rangeSelections = null;
          break;
      }
    }
  }

  function checkData(data) {
    var rslt = false;

    switch (data) {
      case '':
      case 'S':
      case 'O':
        rslt = true;
        break;
    }

    return rslt;
  }

  function prepData(data) {
    switch (data) {
      case '':
        return 'featureComparison_notAvailableLabel';

      case 'S':
        return 'featureComparison_standardLegend';

      case 'O':
        return 'featureComparison_optionalLegend';
    }

    return data;
  }

  function isShowItem(featureId) {
    // these id's have been identified by product as features that should be hidden in the Deal Sheet UI
    switch (featureId) {
      case 76:
      case 77:
      case 80:
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
        return false;
    }

    return true;
  }
}