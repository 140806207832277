<a href="javascript:void(0)" (click)="nloInfoModal()"
   [popover]="popNloInfoTemplate" 
   placement="bottom" 
   triggers="mouseenter:mouseleave"
   container="body">
    <icon icon="'info-warn'"></icon>
</a>
<ng-template #popNloInfoTemplate>
    <span translate="nloInfoTooltip"></span>
</ng-template>
