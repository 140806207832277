AlertScriptController.$inject = ["_", "alertScriptTypes"];

/* injects from baggage-loader */
require('./alert-script.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('alertScript', {
  templateUrl: '/app/components/deal-sheet/alert-script.component.html',
  controller: AlertScriptController,
  bindings: {
    type: '<',
    culture: '<',
    opportunity: '<',
    userFullName: '<',
    isCrossMake: '<'
  }
});
/* @ngInject */

function AlertScriptController(_, alertScriptTypes) {
  var $ctrl = this;
  $ctrl.alertScriptTypes = alertScriptTypes;
  $ctrl.alertScriptTypeKeys;
  $ctrl.$onInit = init;

  function init() {
    $ctrl.alertScriptTypeKeys = _.invert(alertScriptTypes);
  }
}