"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var DealerClientService = /** @class */ (function (_super) {
    __extends(DealerClientService, _super);
    function DealerClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param dealerId undefined
     */
    DealerClientService.prototype.GetDealerVendorFlagsByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/GetDealerVendorFlags/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     */
    DealerClientService.prototype.GetDealerVendorFlagsByDealeridGET = function (dealerId) {
        return this.GetDealerVendorFlagsByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     */
    DealerClientService.prototype.GetDealerFordPilotDtoByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/GetDealerFordPilotDto/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     */
    DealerClientService.prototype.GetDealerFordPilotDtoByDealeridGET = function (dealerId) {
        return this.GetDealerFordPilotDtoByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerClientService.prototype.GetDealerFordInfoGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (dealerId != null)
            __params = __params.set('dealerId', dealerId.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Dealer/GetDealerFordInfo", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerClientService.prototype.GetDealerFordInfoGET = function (dealerId) {
        return this.GetDealerFordInfoGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param lookbackDays undefined
     */
    DealerClientService.prototype.GetPandoErrorDealersByLookbackdaysGETResponse = function (lookbackDays) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/GetPandoErrorDealers/" + lookbackDays), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param lookbackDays undefined
     */
    DealerClientService.prototype.GetPandoErrorDealersByLookbackdaysGET = function (lookbackDays) {
        return this.GetPandoErrorDealersByLookbackdaysGETResponse(lookbackDays).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
     *
     * - `year`:
     *
     * - `trimId`:
     *
     * - `modelId`:
     *
     * - `makeId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    DealerClientService.prototype.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/GetDealerAlertTolerances/" + params.dealerId + "/" + params.makeId + "/" + params.year + "/" + params.modelId + "/" + params.trimId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
     *
     * - `year`:
     *
     * - `trimId`:
     *
     * - `modelId`:
     *
     * - `makeId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    DealerClientService.prototype.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGET = function (params) {
        return this.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `alertTolerances`:
     */
    DealerClientService.prototype.UpdateDealerAlertTolerancesByDealeridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.alertTolerances;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Dealer/UpdateDealerAlertTolerances/" + params.dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `alertTolerances`:
     */
    DealerClientService.prototype.UpdateDealerAlertTolerancesByDealeridPOST = function (params) {
        return this.UpdateDealerAlertTolerancesByDealeridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerClientService.prototype.GetDealerWishListsByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/GetDealerWishLists/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    DealerClientService.prototype.GetDealerWishListsByDealeridGET = function (dealerId) {
        return this.GetDealerWishListsByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     */
    DealerClientService.prototype.FlushDealerWishListsCacheByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/FlushDealerWishListsCache/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     */
    DealerClientService.prototype.FlushDealerWishListsCacheByDealeridGET = function (dealerId) {
        return this.FlushDealerWishListsCacheByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerWishList undefined
     * @return Success
     */
    DealerClientService.prototype.SaveDealerWishListPOSTResponse = function (dealerWishList) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = dealerWishList;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Dealer/SaveDealerWishList", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerWishList undefined
     * @return Success
     */
    DealerClientService.prototype.SaveDealerWishListPOST = function (dealerWishList) {
        return this.SaveDealerWishListPOSTResponse(dealerWishList).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams` containing the following parameters:
     *
     * - `dealerWishListID`:
     *
     * - `dealerId`:
     *
     * - `take`:
     *
     * @return Success
     */
    DealerClientService.prototype.GetDealerWishListLogsByDealeridByDealerwishlistidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/GetDealerWishListLogs/" + params.dealerId + "/" + params.dealerWishListID + "/" + params.take), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams` containing the following parameters:
     *
     * - `dealerWishListID`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    DealerClientService.prototype.GetDealerWishListLogsByDealeridByDealerwishlistidGET = function (params) {
        return this.GetDealerWishListLogsByDealeridByDealerwishlistidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    DealerClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Dealer/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    DealerClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    DealerClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Dealer/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    DealerClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    DealerClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Dealer/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    DealerClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    DealerClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Dealer", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    DealerClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    DealerClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Dealer", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    DealerClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    DealerClientService.GetDealerVendorFlagsByDealeridGETPath = '/api/Dealer/GetDealerVendorFlags/{dealerId}';
    DealerClientService.GetDealerFordPilotDtoByDealeridGETPath = '/api/Dealer/GetDealerFordPilotDto/{dealerId}';
    DealerClientService.GetDealerFordInfoGETPath = '/api/Dealer/GetDealerFordInfo';
    DealerClientService.GetPandoErrorDealersByLookbackdaysGETPath = '/api/Dealer/GetPandoErrorDealers/{lookbackDays}';
    DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETPath = '/api/Dealer/GetDealerAlertTolerances/{dealerId}/{makeId}/{year}/{modelId}/{trimId}';
    DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTPath = '/api/Dealer/UpdateDealerAlertTolerances/{dealerId}';
    DealerClientService.GetDealerWishListsByDealeridGETPath = '/api/Dealer/GetDealerWishLists/{dealerId}';
    DealerClientService.FlushDealerWishListsCacheByDealeridGETPath = '/api/Dealer/FlushDealerWishListsCache/{dealerId}';
    DealerClientService.SaveDealerWishListAsyncPOSTPath = '/api/Dealer/SaveDealerWishList';
    DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETPath = '/api/Dealer/GetDealerWishListLogs/{dealerId}/{dealerWishListID}';
    DealerClientService.ByIdGETPath = '/api/Dealer/{id}';
    DealerClientService.ByIdDELETEPath = '/api/Dealer/{id}';
    DealerClientService.GetManyGETPath = '/api/Dealer/GetMany';
    DealerClientService.PUTPath = '/api/Dealer';
    DealerClientService.POSTPath = '/api/Dealer';
    DealerClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DealerClientService_Factory() { return new DealerClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: DealerClientService, providedIn: "root" });
    return DealerClientService;
}(base_service_1.BaseService));
exports.DealerClientService = DealerClientService;
