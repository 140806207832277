<div class="alert-box">
    <div class="modal-header">
        <div style="float:left">
            <h4 class="modal-title" translate="rolesAdmin_addEditCustomPermissionsSet"></h4>
        </div>
        <div style="float:right">
            <button type="button" class="close" aria-label="Close" (click)="onCancel()">
                <icon class="icon aa-icon-close-icon"></icon>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row roleSelectContainer">
            <div class="col-xs-5">
                <div style="float:left" translate="rolesadmin_similartodefaultrolelabel"></div>
                <div class="tooltipContainer">
                    <icon icon="'info'" title="" [popover]="popTemplate" popover-placement="right" triggers="mouseenter:mouseleave"></icon>
                    <ng-template #popTemplate>
                        <p translate="selectDefaultRoleTooltip"></p>
                    </ng-template>
                </div>
            </div>
            <div class="col-xs-5">
                <select class="form-control roleSelect" [ngClass]="{'fieldInvalid': parentRoleInvalid }" [(ngModel)]="selectedRole.parentRoleID">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let subItem of roleOptions[0]" [ngValue]="subItem.roleID">
                        {{ subItem.roleName }}
                    </option>
                </select>
            </div>
            <div class="col-xs-2">
                <div class="invalidField" *ngIf="parentRoleInvalid" translate="validationRequired"></div>
            </div>
        </div>
        <div class="row roleSelectContainer">
            <div class="col-xs-5" translate="rolesadmin_rolenamelabel">
            </div>
            <div class="col-xs-5">
                <div style="float:left"><input autofocus="true" type="text" class="input-sm textbox txtRoleName" [ngClass]="{'fieldInvalid': roleNameInvalid }" [(ngModel)]="selectedRole.roleName" maxlength="25" /></div>
            </div>
            <div class="col-xs-2">
                <div class="invalidField" *ngIf="roleNameInvalid" translate="validationRequired"></div>
            </div>
        </div>
        <div *ngIf="newRole && selectedRole.parentRoleID != null" class="row startWithParentContainer">
            <div class="col-xs-12">
                <label><input type="checkbox" [(ngModel)]="selectedRole.startWithParentRole" /><span class="startWithDefaultLabel" translate="rolesadmin_startwithdefaultlabel"></span></label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onCancel()" translate="cancel"></button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()" translate="submit"></button>
    </div>
</div>
