import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { StateService, TransitionService, Transition } from '@uirouter/core';
import { Observable, Subscription } from 'rxjs';
import { Activity } from '../../../../interfaces/activity.interface';
import { DealSheetData } from '../../../../interfaces/deal-sheet/deal-sheet-data.interface';
import { UserDtoExt } from '../../../../interfaces/user-dto-ext.interface';
import { externalLoggedIn$ } from '../../../../modules/oidc-auth/events';
import { DealSheetDataService } from '../../../../services/deal-sheet-data.service';
import { MobileDealSheetDataCachingService } from '../../../../services/mobile-deal-sheet-data-caching.service';
import { MobileRoutes } from '../mobile-routes';

@Component({
    selector: 'mobile-deal-sheet',
    templateUrl: './mobile-deal-sheet.component-ng.html',
    styleUrls: ['./mobile-deal-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileDealSheetComponent implements OnInit, OnDestroy {

    @Input() opportunityId: string;
    public dealSheetData: DealSheetData;
    public activityListData: Activity[];
    public users: UserDtoExt[];
    public showPullForward: boolean;
    public routes = MobileRoutes;

    public busy: Promise<any> | Observable<any> | Subscription;

    public selectedNav = this.routes.vehicle;

    constructor(private mobileDealSheetDataCachingService: MobileDealSheetDataCachingService,
        private dealSheetDataService: DealSheetDataService,
        private stateService: StateService, private transitionService: TransitionService) {
    }

    ngOnInit() {
        this.busy = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId)
            .subscribe(dealSheetData => {
                externalLoggedIn$.next(); // Initializes header
                this.showPullForward = this.dealSheetDataService.shouldDisplayPullForward(dealSheetData.opportunity);
                this.dealSheetData = dealSheetData;
                this.stateService.go(this.selectedNav);
            });

        this.transitionService.onSuccess({to: 'mobileDealSheet.**'}, (transition: Transition) => {
            this.selectedNav = transition.$to().name;
        });
    }

    public selectNav(route: string): void {
        this.selectedNav = route;
        this.stateService.go(route);
    }

    public startWorkingState() {
        this.busy = new Promise((resolve) => {
            this.stopWorkingState = resolve;
        });
    }

    public stopWorkingState() { }

    ngOnDestroy(): void {

    }
}
