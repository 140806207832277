"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var window_ref_service_1 = require("./window-ref.service");
var storage_service_1 = require("../modules/storage/storage.service");
var constants_1 = require("../constants");
var NamedLog = /** @class */ (function () {
    function NamedLog(windowRef, _name, debugEnabled) {
        if (debugEnabled === void 0) { debugEnabled = false; }
        this.windowRef = windowRef;
        this._name = _name;
        this.debugEnabled = debugEnabled;
    }
    Object.defineProperty(NamedLog.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    NamedLog.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this._logImpl('log', args);
    };
    NamedLog.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this._logImpl('info', args);
    };
    NamedLog.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this._logImpl('warn', args);
    };
    NamedLog.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this._logImpl('error', args);
    };
    NamedLog.prototype.debug = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (this.debugEnabled) {
            this._logImpl('debug', args);
        }
    };
    NamedLog.prototype._logImpl = function (level, args) {
        var console = this.windowRef.console || {};
        var noop = function (dummyMessage) { };
        var logFunction = console[level] || console['log'] || noop;
        var message = this.prependName(this.name, args);
        // Support: IE only
        // console methods don't inherit from Function.prototype in IE so we can't
        // call `logFunction.apply(console, args)` directly. Stolen from AngularJs source
        return Function.prototype.apply.call(logFunction, console, message);
    };
    NamedLog.prototype.prependName = function (name, args) {
        if (name) {
            return ["[" + name + "]"].concat(args);
        }
        else {
            return args;
        }
    };
    return NamedLog;
}());
exports.NamedLog = NamedLog;
var LogFactory = /** @class */ (function () {
    function LogFactory(windowRef, storageService) {
        this.windowRef = windowRef;
        this.debugEnabled = false;
        var debugSetInLocalStorage = storageService.getItem('debug');
        this.debugEnabled = (debugSetInLocalStorage || constants_1.Settings.environmentVariables.logDebugEnabled);
    }
    LogFactory.prototype.get = function (name) {
        return new NamedLog(this.windowRef, name, this.debugEnabled);
    };
    return LogFactory;
}());
exports.LogFactory = LogFactory;
