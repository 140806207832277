<div class="field row" *ngIf="contract?.conquestType != conquestTypes?.notConverted && permissions?.viewContract">

  <label class="field-label" for="extended-warranty" [hidden]="!(getWarrantyStatus()  
         && (getWarrantyStatus()  == 'EXTENDED' 
         || ( getWarrantyStatus() == 'EXPIRED' && vehicle?.type != vehicleTypes?.used)
         || getWarrantyStatus()   == 'ACTIVE' ))" translate="warrantyLabel"></label>

  <div [ngSwitch]="getWarrantyStatus()" class="field-data" [ngClass]="{warranty: alertExists}">

      <span *ngSwitchCase="'EXTENDED'" id="extended-warranty" translate="extendedWarranty" ></span>

      <span *ngSwitchCase="'EXPIRED'" > 
        <span id="extended-warranty" translate="warrantyExpired" 
            *ngIf="!(contract?.saleType == saleTypes?.retail && vehicle?.type == vehicleTypes?.used)">
        </span>
      </span>

      <span *ngSwitchCase="'NONE'" id="extended-warranty"></span>

      <span *ngSwitchCase="'ACTIVE'" >
          <span id="extended-warranty" class="qa-extended-warranty-value" 
                *ngIf="!(contract?.saleType == saleTypes?.retail 
                    && vehicle?.type == vehicleTypes?.used 
                    && replacementVehicleType != vehicleTypes?.new)"
                translate="warranty"
                [translateParams]= "{ months: (vehicle && vehicle.warrantyMonthsRemaining) || (contract && contract.warrantyMonthsRemaining) | localeNumber,
                    miles: (vehicle && vehicle.warrantyMilesRemaining) || (contract && contract.warrantyMilesRemaining) | localeNumber }">
          </span>
      </span>
  </div>

  <div *ngIf="!getWarrantyStatus()" class="field-data" [ngClass]="{warranty: alertExists}">

      <span *ngIf="getWarrantyStatus() === 'EXTENDED'" id="extended-warranty" translate="extendedWarranty" ></span>

      <span *ngIf="getWarrantyStatus() === 'NONE'" id="extended-warranty"></span>

      <span id="extended-warranty" class="qa-extended-warranty-value" translate="warranty"
      *ngIf="(getWarrantyStatus() === 'ACTIVE' ) && !(contract?.saleType == saleTypes?.retail 
                && vehicle?.type == vehicleTypes?.used 
                && replacementVehicleType != vehicleTypes?.new)" 
      [translateParams]= "{ months: (vehicle && vehicle.warrantyMonthsRemaining) || (contract && contract.warrantyMonthsRemaining) | localeNumber,
                miles: (vehicle && vehicle.warrantyMilesRemaining) || (contract && contract.warrantyMilesRemaining) | localeNumber }">
      </span>
  </div>

</div>
