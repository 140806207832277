import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { DealSheetData } from '../interfaces/deal-sheet/deal-sheet-data.interface';
import { publishReplay, refCount } from 'rxjs/operators';
import { DealSheetDataService } from './deal-sheet-data.service';
import { DealerService } from './dealer.service';
import { UserDtoExt } from '../interfaces/user-dto-ext.interface';
import { ActivityService } from './activity.service';
import { Activity } from '../interfaces/activity.interface';

// Refactor of methods that get an opportunity formatted for use in a dealsheet
@Injectable({ providedIn: 'root' })
export class MobileDealSheetDataCachingService {
    private dealSheetData$: Observable<DealSheetData>;
    private users$: Observable<UserDtoExt[]>;
    private activity$: Observable<Activity[]>;

    constructor(private dealsheetDataService: DealSheetDataService, private dealerService: DealerService, private activityService: ActivityService) { }

    public getDealsheetData(opportunityId: string, stockNo?: string): Observable<DealSheetData> {

        if (this.dealSheetData$) {
            return this.dealSheetData$;
        }

        this.dealSheetData$ = this.dealsheetDataService.initDealsheetData(opportunityId, stockNo).pipe(publishReplay(1), refCount());

        return this.dealSheetData$;
    }

    public getUsers(dealerId: number): Observable<UserDtoExt[]> {

        if (this.users$) {
            return this.users$;
        }

        this.users$ = from(this.dealerService.getUsers(dealerId));

        return this.users$;
    }

    public getActivities(opportunityId: string, dealerId: number, customerId: number): Observable<Activity[]> {
        if (this.activity$) {
            return this.activity$;
        }

        this.activity$ = from(this.activityService.getActivities(opportunityId, dealerId, customerId));

        return this.activity$;
    }

    public clearActivities() {
        this.activity$ = null;
    }

    public clearUsers() {
        this.users$ = null;
    }

    public clearDealSheetData() {
        this.dealSheetData$ = null;
    }
}
