/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerRateMarkUpExtDto } from '../models/dealer-rate-mark-up-ext-dto';
import { DealerRateUsedDto } from '../models/dealer-rate-used-dto';
import { DealerOverAllowanceUpdateDto } from '../models/dealer-over-allowance-update-dto';
import { DealerOverAllowanceExtDto } from '../models/dealer-over-allowance-ext-dto';
@Injectable({
  providedIn: 'root',
})
class DealerRatesClientService extends __BaseService {
  static readonly GetDealerRateMarkUpsByDealeridGETPath = '/api/DealerRates/GetDealerRateMarkUps/{dealerId}';
  static readonly UpdateDealerUsedRatesPOSTPath = '/api/DealerRates/UpdateDealerUsedRates';
  static readonly DeleteAllUsedRatesByDealerIdPOSTPath = '/api/DealerRates/DeleteAllUsedRatesByDealerId';
  static readonly UpdateDealerOverAllowancePOSTPath = '/api/DealerRates/UpdateDealerOverAllowance';
  static readonly UpdateDealerRateMarkUpsPOSTPath = '/api/DealerRates/UpdateDealerRateMarkUps';
  static readonly GetDealerOverAllowancesByDealeridGETPath = '/api/DealerRates/GetDealerOverAllowances/{dealerId}';
  static readonly GetDealerUsedRatesByDealeridGETPath = '/api/DealerRates/GetDealerUsedRates/{dealerId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerRateMarkUpsByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<DealerRateMarkUpExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerRates/GetDealerRateMarkUps/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerRateMarkUpExtDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerRateMarkUpsByDealeridGET(dealerId: number): __Observable<Array<DealerRateMarkUpExtDto>> {
    return this.GetDealerRateMarkUpsByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<DealerRateMarkUpExtDto>)
    );
  }

  /**
   * @param data undefined
   */
  UpdateDealerUsedRatesPOSTResponse(data?: Array<DealerRateUsedDto>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerRates/UpdateDealerUsedRates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  UpdateDealerUsedRatesPOST(data?: Array<DealerRateUsedDto>): __Observable<null> {
    return this.UpdateDealerUsedRatesPOSTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  DeleteAllUsedRatesByDealerIdPOSTResponse(dealerId?: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = dealerId;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerRates/DeleteAllUsedRatesByDealerId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  DeleteAllUsedRatesByDealerIdPOST(dealerId?: number): __Observable<boolean> {
    return this.DeleteAllUsedRatesByDealerIdPOSTResponse(dealerId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param data undefined
   */
  UpdateDealerOverAllowancePOSTResponse(data?: Array<DealerOverAllowanceUpdateDto>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerRates/UpdateDealerOverAllowance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  UpdateDealerOverAllowancePOST(data?: Array<DealerOverAllowanceUpdateDto>): __Observable<null> {
    return this.UpdateDealerOverAllowancePOSTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param rates undefined
   */
  UpdateDealerRateMarkUpsPOSTResponse(rates?: Array<DealerRateMarkUpExtDto>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = rates;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerRates/UpdateDealerRateMarkUps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param rates undefined
   */
  UpdateDealerRateMarkUpsPOST(rates?: Array<DealerRateMarkUpExtDto>): __Observable<null> {
    return this.UpdateDealerRateMarkUpsPOSTResponse(rates).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerOverAllowancesByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<DealerOverAllowanceExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerRates/GetDealerOverAllowances/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerOverAllowanceExtDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerOverAllowancesByDealeridGET(dealerId: number): __Observable<Array<DealerOverAllowanceExtDto>> {
    return this.GetDealerOverAllowancesByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<DealerOverAllowanceExtDto>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerUsedRatesByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<DealerRateUsedDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerRates/GetDealerUsedRates/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerRateUsedDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetDealerUsedRatesByDealeridGET(dealerId: number): __Observable<Array<DealerRateUsedDto>> {
    return this.GetDealerUsedRatesByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<DealerRateUsedDto>)
    );
  }
}

module DealerRatesClientService {
}

export { DealerRatesClientService }
