
/* injects from baggage-loader */
require('./data-entry-frame.component.html');

import angular from 'angular';

import './data-entry-frame.component.scss';

angular.module('AaApp.Opp').component('dataEntryFrame', {
    templateUrl: '/app/components/data-entry/data-entry-frame.component.html',
    controller: DataEntryFrameController,
    bindings: {
    }
});

function DataEntryFrameController() {
    "ngInject";
    const $ctrl = this;
}
