import angular from 'angular';

var name = 'AaApp.Config';
export default name;

import { APP_SETTINGS_JSON } from '../environments/app-settings';

// These variables are generated by webpack
/* eslint-disable no-undef */
var settings = APP_SETTINGS_JSON;
// settings.environmentVariables.version = __VERSION__;
/* eslint-enable no-undef */

export const appSettings = settings;

var configModule = angular.module(name, [])

for (var key in settings) {
    var value = settings[key];
    configModule.constant(key, value);
}
