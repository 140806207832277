
/* injects from baggage-loader */
require('./search-preset-selector-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('searchPresetSelectorModal', {
    templateUrl: '/app/components/search/search-preset-selector-modal.component.html',
    controller: SearchPresetSelectorModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
});

/* @ngInject */
function SearchPresetSelectorModalController() {
    var $ctrl = this;

    $ctrl.selectedDealerId;
    $ctrl.getSelectedSearchPresets;
    $ctrl.submit = submit;
    $ctrl.hasSelections;
    $ctrl.onSelection = onSelection;

    function submit() {
        var selectedSearchPresets = $ctrl.getSelectedSearchPresets();

        $ctrl.close({ $value: selectedSearchPresets });
    }

    function onSelection(hasSelections) {
        $ctrl.hasSelections = hasSelections;
    }
}
