"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".custom-search-input[_ngcontent-%COMP%]   .stock-info[_ngcontent-%COMP%]{width:400px}.custom-search-input[_ngcontent-%COMP%]   .stock-info[_ngcontent-%COMP%]   .stock-data[_ngcontent-%COMP%]{display:inline-block;white-space:nowrap;overflow:hidden;-ms-text-overflow:ellipsis;text-overflow:ellipsis}.custom-search-input[_ngcontent-%COMP%]   .stock-info[_ngcontent-%COMP%]   .stock-data.stock-no[_ngcontent-%COMP%]{width:100px}.custom-search-input[_ngcontent-%COMP%]   .stock-info[_ngcontent-%COMP%]   .stock-data.stock-full-trim[_ngcontent-%COMP%]{width:200px}.custom-search-input[_ngcontent-%COMP%]   .stock-info[_ngcontent-%COMP%]   .stock-data.stock-new-used-cpo[_ngcontent-%COMP%]{width:50px}.custom-search-input[_ngcontent-%COMP%]   .stock-info[_ngcontent-%COMP%]   .stock-data[_ngcontent-%COMP%]   .ng-scope[_ngcontent-%COMP%]{width:100%}.custom-search-input[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]::-ms-clear{display:none}"];
exports.styles = styles;
