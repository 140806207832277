import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { RoleAccessOptionDto, RoleOptionDto } from '../../generated/models';
import { AdminRoleService } from '../../services/admin-role.service';
import { ConfirmModalComponent } from '../modals/confirm-modal.component';
import { AdminRolesModalAddEditComponent } from './admin-roles-modal-addedit.component';

@Component({
    selector: 'admin-roles',
    templateUrl: './admin-roles.component-ng.html'
})

export class AdminRolesComponent {
    public moduleCategories: any;
    public rightCategories: any;
    public moduleSettings: any;
    public rightSettings: any;
    public roleOptions: any;
    public roleOptionsForSelect: any;
    public selectedRole: any;
    private previousRoleId: number;

    public accessRightChanged = false;

    bsModalRef: BsModalRef;

    constructor(
        public modalService: BsModalService,
        public roleService: AdminRoleService,
        public tService: TranslateService,
        public logger: ToasterLoggerService) {
    }

    public ngOnInit(): void {
        this.getRoleOptions();
    }

    private getRoleOptions(): any {
        return this.roleService.GetRoleOptions().then(roleOptions => {
            this.roleOptions = roleOptions;

            this.selectedRole = roleOptions[0][0];

            this.roleService.GetRoleAccessRightCategories().then(rightCategories => {
                this.rightCategories = rightCategories;
            });

            this.getRoleAccess(this.selectedRole.roleID);
        });
    }

    public onEditRole(): void {
        this.openAddEditRoleModalWithChangesCheck(false);
    }

    public onAddRole(): void {
        this.openAddEditRoleModalWithChangesCheck(true);
    }

    private setSelectedRole(roleId: number): void {
        var flatRoles = [].concat.apply([], this.roleOptions);
        this.selectedRole = flatRoles.find((r: RoleOptionDto) => r.roleID == roleId);
    }

    private getRoleName(roleId: number): any {
        var flatRoles = [].concat.apply([], this.roleOptions);
        var role = flatRoles.find((r: RoleOptionDto) => r.roleID == roleId);
        if (role != null)
            return role.roleName;
        else
            return '';
    }

    public getRoleSelectNote(): any {
        return this.tService.instant("adminRoles_customRoleBasedOn").replace("{parentRoleName}", "<span class='bolditalic'>" + this.getRoleName(this.selectedRole.parentRoleID) + "</span>");
    }

    private openAddEditRoleModalWithChangesCheck(newRole: boolean): void {
        if (this.accessRightChanged) {
            this.accessRightChanged = false;

            const initialState = {
                saveWarningMessage: false,
                messageKey: "saveWarningMessage"
            };

            this.bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });

            this.bsModalRef.content.onClosed.subscribe((result: any) => {
                if (!result.confirmed)
                    this.accessRightChanged = true;
                else
                    this.openAddEditRoleModal(newRole);
            });
        }
        else
            this.openAddEditRoleModal(newRole);
    }

    private openAddEditRoleModal(newRole: boolean): void {
        const initialState = {
            newRole: newRole,
            selectedRole: Object.assign({}, this.selectedRole), // we need to clone the role here
            roleOptions: this.roleOptions.slice()
        };
        this.bsModalRef = this.modalService.show(AdminRolesModalAddEditComponent, { initialState });

        this.bsModalRef.content.onSaveChanges.subscribe((result: any) => {
            this.roleService.RoleSave(result.selectedRole).then(roleId => {
                this.previousRoleId = roleId;
                this.getRoleOptions().then((roleOptions: RoleOptionDto[]) => {
                    // update role option and set selected to id of option just edited
                    this.setSelectedRole(roleId);

                    if (result.selectedRole.startWithParentRole && result.selectedRole.parentRoleID != null) 
                        roleId = result.selectedRole.parentRoleID;

                    this.getRoleAccess(roleId).then(result => {
                        this.saveModuleAndRightSettings().then(result => {   // save the initial role module and access right permissions
                            if (result)
                                this.logger.success(this.tService.instant("changesSaved"));
                            else
                                this.logger.error(this.tService.instant("generalErrorMessageKey"));
                        });
                    });
                });
            });
        });
    }

    public onRoleChanged(): void {
        if (this.accessRightChanged) {
            this.accessRightChanged = false;

            const initialState = {
                saveWarningMessage: false,
                messageKey: "saveWarningMessage"
            };

            this.bsModalRef = this.modalService.show(ConfirmModalComponent, { initialState });

            this.bsModalRef.content.onClosed.subscribe((result: any) => {
                if (!result.confirmed) {
                    this.accessRightChanged = true;
                    this.setSelectedRole(this.previousRoleId);
                }
                else
                    this.getRoleAccess(this.selectedRole.roleID);
            });
        }
        else {
            this.getRoleAccess(this.selectedRole.roleID);
            this.previousRoleId = this.selectedRole.roleID;
        }
    }

    private getRoleAccess(roleId: number): any {
        var promise1 = this.roleService.GetRoleModuleCategories(roleId).then(result => {
            this.moduleCategories = result;
        });

        var promise2 = this.roleService.GetRoleAccessRights(roleId).then(result => {
            this.rightSettings = result;
        });

        var promise3 = this.roleService.GetRoleModuleAccess(roleId).then(result => {
            this.moduleSettings = result;
        });

        return Promise.all([promise1, promise2, promise3]);
    }

    public cancelChanges() {
        this.getRoleAccess(this.selectedRole.roleID);
        this.logger.warning(this.tService.instant("settingsReturnedToDefault"));
    }

    public saveChanges() {
        this.saveModuleAndRightSettings().then(result => {
            if (result)
                this.logger.success(this.tService.instant("changesSaved"));
            else
                this.logger.error(this.tService.instant("generalErrorMessageKey"));
        });
    }

    private saveModuleAndRightSettings() {
        var csvRight: number[] = [];
        var csvModule: number[] = [];

        this.rightSettings.forEach(function (item: RoleAccessOptionDto) {
            if (item.allow)
                csvRight.push(item.accessOptionId);
        });

        // we also need to save the moduleCategories, but not accessRightsCategories
        this.moduleCategories.forEach(function (item: any) {
            if (item.allow)
                csvModule.push(item.accessOptionId);
        });

        this.moduleSettings.forEach(function (item: any) {
            if (item.allow)
                csvModule.push(item.accessOptionId);
        });

        return this.roleService.RoleAccessUpdate(this.selectedRole.roleID, csvModule, csvRight);
    }
}
