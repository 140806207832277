
/* injects from baggage-loader */
require('./customer-phone-list.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('customerPhoneList', {
    templateUrl: '/app/components/deal-sheet/customer-phone-list.component.html',
    bindings: {
        currentPhone: '<',
        phones: '<',
        dealerSettings: '<'
    }
});
