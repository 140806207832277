
/* injects from baggage-loader */
require('./email-unsubscribe.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('emailUnsubscribe', {
    templateUrl: '/app/components/email-unsubscribe.component.html',
    controller: EmailUnsubscribeController,
    bindings: {
        templateEmailId: '<',
        accessCode: '<'
    }
});

/* @ngInject */
function EmailUnsubscribeController(templateService) {
    var $ctrl = this;

    $ctrl.$onInit = init;
    $ctrl.unsubscribe = unsubscribe;

    function init() {
        $ctrl.emailContentPromise = templateService.getEmailSubscription($ctrl.templateEmailId, $ctrl.accessCode) 
            .then(setEmailSubscription);
    }

    function setEmailSubscription(emailSubscription) {
        $ctrl.emailSubscription = emailSubscription;
        $ctrl.emailContentPromise = null;
    }

    function unsubscribe() {
        $ctrl.emailContentPromise = templateService.disableEmailSubscription($ctrl.templateEmailId, $ctrl.accessCode)
            .then(() => {
                $ctrl.emailSubscription.doNotEmail = true; 
                $ctrl.emailContentPromise = null;
            });
    }
}