externalService.$inject = ["$q", "$http", "coreApi"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('externalService', externalService);
/* @ngInject */

function externalService($q, $http, coreApi) {
  var service = {
    getValidExternalDealsheetJWTToken: getValidExternalDealsheetJWTToken,
    getValidExternalPageJWTToken: getValidExternalPageJWTToken
  };
  return service;

  function getValidExternalDealsheetJWTToken(entityId, vHash, accesCode, dealerId) {
    return coreApi.api.Authentication.CrmLogin['{entityId}']['{vHash}']['{accesCode}']['{dealerId}'].GET({
      entityId: entityId,
      vHash: vHash,
      accesCode: accesCode,
      dealerId: dealerId
    });
  }

  function getValidExternalPageJWTToken(accesCode, dealerId) {
    return coreApi.api.Authentication.CrmLoginPage['{accesCode}']['{dealerId}'].GET({
      accesCode: accesCode,
      dealerId: dealerId
    });
  }
}