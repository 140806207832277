export const RATING_ICONS = {
    1: 'rating-1star',
    2: 'rating-2star',
    3: 'rating-3star',
    4: 'rating-4star',
    5: 'rating-5star'
};

export const RATING_TITLES = {
    1: 'rating1starsTitle',
    2: 'rating2starsTitle',
    3: 'rating3starsTitle',
    4: 'rating4starsTitle',
    5: 'rating5starsTitle'
};