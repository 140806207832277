"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CarproofComponent = /** @class */ (function () {
    function CarproofComponent() {
        this.showCarProofLink = false;
    }
    CarproofComponent.prototype.ngOnInit = function () { };
    CarproofComponent.prototype.ngOnChanges = function () {
        if (!this.permissions || !this.dealerSettings || !this.vin) {
            return;
        }
        this.showCarProofLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableCarProof;
    };
    return CarproofComponent;
}());
exports.CarproofComponent = CarproofComponent;
