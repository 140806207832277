
/* injects from baggage-loader */
require('./dashboard-opportunities.component.html');

import angular from 'angular';

import './dashboard.scss';

angular.module('AaApp.Opp').component('dashboardOpportunities', {
    templateUrl: '/app/components/dashboard/dashboard-opportunities.component.html',
    controller: DashboardOpportunitiesController,
    bindings: {
        selectedDealerId: '<',
    }
});

/* @ngInject */
function DashboardOpportunitiesController($state, $route, $rootScope, $translate, $translatePartialLoader, $sce, legacy, opportunityCountsService, userService, storageService,
    activityService, searchService, localePaths, alertTypeDescriptors, roles, saleTypes, widgetTypes, widgetTitleKeys, widgetExpandToolTipKeys,
    searchPresetService,authorizationService, modules) {

    const $ctrl = this;

    $ctrl.$onInit = $onInit;
    $ctrl.$onChanges = $onChanges;
    //$ctrl.leaseWidgetDataService = leaseWidgetDataService;
    //$ctrl.retailWidgetDataService = retailWidgetDataService;
    //$ctrl.agendaWidgetDataService = agendaWidgetDataService;
    $ctrl.newOpportunityChangesWidgetDataService = newOpportunityChangesWidgetDataService;
    //$ctrl.recentlyAssignedWidgetDataService = recentlyAssignedWidgetDataService;
    $ctrl.getOpportunityCounts = getOpportunityCounts;

    $ctrl.getWidgetColumnTypesList = getWidgetColumnTypesList;
    //$ctrl.getWidgetLink = getWidgetLink;

    $ctrl.closeCallback = closeCallback;
    $ctrl.refreshWidgets = refreshWidgets;
    $ctrl.permissions = {};

    $ctrl.leftColumnWidgets = [];
    $ctrl.rightColumnWidgets = [];

    $ctrl.displayWidgets = [];


    //TODO:  refactor this to a centralized location.
    //Configuration
    $ctrl.leaseSaleTypes = [saleTypes.lease];
    $ctrl.retailSaleTypes = [saleTypes.retail, saleTypes.balloon];

    //$ctrl.managerIndex = -1;
    $ctrl.iframeSource = $sce.trustAsResourceUrl(legacy.iframeUrl + '/Home/ContactUs');
    $ctrl.alertTypeDescriptors = alertTypeDescriptors;

    var isEuroLite = authorizationService.isEuroLite();

    //var agendaWidgetConfig = {
    //    managerWidgetOrder: 4,
    //    widgetType: widgetTypes.agenda,
    //    widgetTitleKey: widgetTitleKeys.agenda,
    //    widgetExpandToolTipKey: widgetExpandToolTipKeys.agenda,
    //    widgetLink: 'activitiesAgenda',
    //    widgetDataService: $ctrl.agendaWidgetDataService,
    //    triggerRefresh: 0,
    //};
    //var recentlyAssignedConfig = {
    //    managerWidgetOrder: 5,
    //    widgetType: widgetTypes.opportunitiesTable,
    //    widgetTitleKey: widgetTitleKeys.recentlyAssigned,
    //    widgetExpandToolTipKey: widgetExpandToolTipKeys.recentlyAssigned,
    //    widgetDataService: $ctrl.recentlyAssignedWidgetDataService,
    //    detailViewLinkDisabled: true,
    //    columnTypes: ['opportunity', 'alertBlocks', 'assignedDate'],
    //    triggerRefresh: 0,
    //};

    //var leaseWidgetConfig = {
    //    managerWidgetOrder: 1,
    //    widgetType: widgetTypes.opportunitiesTable,
    //    widgetTitleKey: widgetTitleKeys.lease,
    //    widgetExpandToolTipKey: widgetExpandToolTipKeys.lease,
    //    widgetLink: $ctrl.getWidgetLink('lease'), 
    //    columnTypes: $ctrl.getWidgetColumnTypesList('lease'),
    //    widgetDataService: $ctrl.leaseWidgetDataService,
    //    triggerRefresh: 0,
    //};

    //var retailWidgetConfig = {
    //    managerWidgetOrder: 2,
    //    widgetType: widgetTypes.opportunitiesTable,
    //    widgetTitleKey: widgetTitleKeys.retail,
    //    widgetExpandToolTipKey: widgetExpandToolTipKeys.retail,
    //    widgetLink: $ctrl.getWidgetLink('retail'), 
    //    columnTypes: $ctrl.getWidgetColumnTypesList('retail'),
    //    widgetDataService: $ctrl.retailWidgetDataService,
    //    triggerRefresh: 0,
    //};

    var newOpportunityChangesConfig = {
        managerWidgetOrder: 3,
        widgetType: widgetTypes.opportunitiesTable,
        widgetTitleKey: widgetTitleKeys.newOpportunityChanges,
        widgetExpandToolTipKey: widgetExpandToolTipKeys.newOpportunityChanges,
        widgetDataService: $ctrl.newOpportunityChangesWidgetDataService,
        columnTypes: ['opportunity', 'alertBlocks', 'responsibleUserFullName'],
        detailViewLinkDisabled: true,
        triggerRefresh: 0,
    };

    var searchPresetCountConfig = {
        managerWidgetOrder: 7,
        widgetType: widgetTypes.searchPresetCounts,
        widgetTitleKey: widgetTitleKeys.opportunitySearches,
        widgetDataService: searchPresetCountWidgetDataService,
        detailViewLinkDisabled: true,
        triggerRefresh: 0,
    };

    function $onInit() {
        $translatePartialLoader.addPart(localePaths.general);
        $translatePartialLoader.addPart(localePaths.dashboard);

        userService.getUserProfile().then(function (data) {
            if (data == undefined) {
                $state.go('login');
            }
                $ctrl.roleId = data.roleId;

            var managerRoles = [
                        roles.dealerAdmin,
                        roles.generalManager,
                        roles.salesManager,
                        roles.financeAndInsuranceManager,
                        roles.usedCarSalesManager,
            ];

            var moduleIds = storageService.getItem('moduleIds');
            if (moduleIds) {
                var searchPresentCountWidgetEnabled = moduleIds.indexOf(modules.search) > -1;
            }


            if (managerRoles.indexOf(data.roleId) >= 0) {
                if (searchPresentCountWidgetEnabled) {

                    $ctrl.displayWidgets = [
                        searchPresetCountConfig,
                        newOpportunityChangesConfig,
                        //leaseWidgetConfig,
                        //retailWidgetConfig,
                        //agendaWidgetConfig,
                        //recentlyAssignedConfig,
                    ];
                }
                else {
                    $ctrl.displayWidgets = [
                        newOpportunityChangesConfig,
                        //leaseWidgetConfig,
                        //retailWidgetConfig,
                        //agendaWidgetConfig,
                        //recentlyAssignedConfig,
                    ];
                }
            }
            else {
                if (searchPresentCountWidgetEnabled) {
                    $ctrl.displayWidgets = [
                        searchPresetCountConfig,
                        //agendaWidgetConfig,
                        newOpportunityChangesConfig,
                        //leaseWidgetConfig,
                        //retailWidgetConfig,
                        //recentlyAssignedConfig,
                    ];
                }
                else {
                    $ctrl.displayWidgets = [
                        //agendaWidgetConfig,
                        newOpportunityChangesConfig,
                        //leaseWidgetConfig,
                        //recentlyAssignedConfig,
                        //retailWidgetConfig,
                    ];
                }
            }





            if (!isEuroLite) {
                if (managerRoles.indexOf(data.roleId) >= 0) {
                    if (searchPresentCountWidgetEnabled) {
                        $ctrl.leftColumnWidgets = [
                            //leaseWidgetConfig,
                            newOpportunityChangesConfig,
                            //recentlyAssignedConfig,
                        ];
                        $ctrl.rightColumnWidgets = [
                            searchPresetCountConfig,
                            //retailWidgetConfig,
                            //agendaWidgetConfig,
                        ];
                    }
                    else {
                        $ctrl.leftColumnWidgets = [
                            //leaseWidgetConfig,
                            newOpportunityChangesConfig,
                            //recentlyAssignedConfig
                        ];

                        $ctrl.rightColumnWidgets = [
                            //retailWidgetConfig,
                            //agendaWidgetConfig,
                        ];

                    }
                }
                else {
                    if (searchPresentCountWidgetEnabled) {
                        $ctrl.leftColumnWidgets = [
                            //agendaWidgetConfig,
                            //leaseWidgetConfig,
                            newOpportunityChangesConfig,
                        ];

                        $ctrl.rightColumnWidgets = [
                            searchPresetCountConfig,
                            //recentlyAssignedConfig,
                            //retailWidgetConfig,
                        ];
                    }
                    else {
                        $ctrl.leftColumnWidgets = [
                            //agendaWidgetConfig,
                            //leaseWidgetConfig,
                            newOpportunityChangesConfig
                        ];

                        $ctrl.rightColumnWidgets = [
                            //recentlyAssignedConfig,
                            //retailWidgetConfig,
                        ];
                    }
                }
            }
            else {
                $ctrl.leftColumnWidgets = [                    
                    //leaseWidgetConfig,
                ];

                $ctrl.rightColumnWidgets = [
                    //agendaWidgetConfig,
                    //retailWidgetConfig,
                ];
            }
        });

        getOpportunityCounts(false);

        authorizationService.getDealSheetPermissions().then(function (data){
            $ctrl.permissions = data;
        });
    }


    //function getWidgetLink(type) {
    //    if (isEuroLite)
    //        return 'euroliteopportunities';

    //    switch (type) {
    //        case 'lease':
    //                return 'opportunitiesLease';
    //        case 'retail':
    //                return 'opportunitiesRetail';
    //    }
    //}


    function getWidgetColumnTypesList(type) {        
        switch (type) {
            case 'lease':
            case 'retail':
                if (isEuroLite)
                    return ['opportunity', 'alertBlocks'];
                else
                    return ['opportunity', 'alertBlocks', 'apptOrRoDate'];
            default:
                return ['opportunity', 'alertBlocks', 'apptOrRoDate'];
        }
    }

    function getOpportunityCounts(flushCache) {
        $ctrl.widgetDataPromise = opportunityCountsService.getOpportunityCounts($ctrl.selectedDealerId, flushCache).then(function (data) {
            $ctrl.opportunityCounts = data;
        });       
    }

    function $onChanges(changes) {
        if (changes.selectedDealerId && !changes.selectedDealerId.isFirstChange()) {
            refreshAllWidgets();
            getOpportunityCounts();
        }
    }

    //function leaseWidgetDataService(flushCache) {
    //    return searchService.getOpenOpportunities($ctrl.selectedDealerId, $ctrl.leaseSaleTypes, flushCache);
    //}

    //function retailWidgetDataService(flushCache) {
    //    return searchService.getOpenOpportunities($ctrl.selectedDealerId, $ctrl.retailSaleTypes, flushCache);
    //}

    //function agendaWidgetDataService(flushCache) {
    //    return activityService.getAgendaItems( $ctrl.selectedDealerId, null, flushCache);
    //}

    function newOpportunityChangesWidgetDataService(flushCache) {
        return searchService.getFreshOpportunities($ctrl.selectedDealerId, flushCache);
    }

    //function recentlyAssignedWidgetDataService(flushCache) {
    //    return searchService.getRecentlyAssignedOpportunities($ctrl.selectedDealerId, flushCache);
    //}

    function searchPresetCountWidgetDataService(hardRefresh, widgetState) {
        const maxRowsDisplayed = widgetState && widgetState.maxRowsDisplayed;
        return searchPresetService.getDealerSearchPresetLayoutCounts(hardRefresh, $ctrl.selectedDealerId, maxRowsDisplayed);
    }

    function closeCallback(type) {
        //refresh
        switch (type) {
            case widgetTypes.lease:
            case widgetTypes.retail:
            case widgetTypes.newOpportunityChanges:
                $ctrl.refreshWidgets();
                break;
            case widgetTypes.agenda:
            case widgetTypes.recentlyAssigned:
            default:
                break;
        }
    }

    function refreshAllWidgets() {
        if ($ctrl.leftColumnWidgets) {
            for (const widget of $ctrl.leftColumnWidgets) {
                widget.triggerRefresh++;
            }
        }
        if ($ctrl.rightColumnWidgets) {
            for (const widget of $ctrl.rightColumnWidgets) {
                widget.triggerRefresh++;
            }
        }
    }

    function refreshWidgets() {
        if ($ctrl.leftColumnWidgets) {
            for (var i = 0; i < $ctrl.leftColumnWidgets.length; i++) {
                if ($ctrl.leftColumnWidgets[i].widgetType === widgetTypes.agenda || $ctrl.leftColumnWidgets[i].widgetType === widgetTypes.recentlyAssigned) {
                    $ctrl.leftColumnWidgets[i].triggerRefresh++;
                }
            }
        }

        if ($ctrl.rightColumnWidgets) {
            for (var k = 0; k < $ctrl.rightColumnWidgets.length; k++) {
                if ($ctrl.rightColumnWidgets[k].widgetType === widgetTypes.agenda || $ctrl.rightColumnWidgets[k].widgetType === widgetTypes.recentlyAssigned) {
                    $ctrl.rightColumnWidgets[k].triggerRefresh++;
                }
            }
        }
    }
}
