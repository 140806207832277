"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var mobile_deal_sheet_data_caching_service_1 = require("../../../../services/mobile-deal-sheet-data-caching.service");
var deal_sheet_data_service_1 = require("../../../../services/deal-sheet-data.service");
var MobileReplacementContractComponent = /** @class */ (function () {
    function MobileReplacementContractComponent(dealSheetDataService, mobileDealSheetDataCachingService, transition) {
        this.dealSheetDataService = dealSheetDataService;
        this.mobileDealSheetDataCachingService = mobileDealSheetDataCachingService;
        this.transition = transition;
        this.showMobileView = true;
        this.opportunityId = this.transition.params().opportunityId;
    }
    MobileReplacementContractComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showPullForward = this.dealSheetDataService.shouldDisplayPullForward(this.opportunity);
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(function (dealSheetData) {
            _this.opportunity = dealSheetData.opportunity;
            _this.permissions = dealSheetData.permissions;
            _this.countryId = dealSheetData.userProfile.countryId;
            _this.availableContracts = dealSheetData.opportunity.replacementContracts;
        });
    };
    MobileReplacementContractComponent.prototype.ngOnDestroy = function () {
        this.opportunitySubscription.unsubscribe();
    };
    return MobileReplacementContractComponent;
}());
exports.MobileReplacementContractComponent = MobileReplacementContractComponent;
