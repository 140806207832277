
/* injects from baggage-loader */
require('./service-drive-offers-filter.modal.component.html');

import angular from 'angular';
import _ from 'lodash';

(function () {
    angular.module('AaApp.Opp').component('serviceDriveOffersFilterModal', {
        templateUrl: '/app/components/service-drive/modals/service-drive-offers-filter.modal.component.html',
        controller: OffersFiltersModalController,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        },
    });

    /* @ngInject */
    function OffersFiltersModalController($filter, $translate, $translatePartialLoader,
        alertTypes, dateFormatService) {
        var $ctrl = this;

        $ctrl.$onInit = init();

        $ctrl.submit = function () {
            var result = {
                dateStart: $ctrl.dateStart.date,
                selectedAlertTypes: $ctrl.selectedAlertTypes
            }
            $ctrl.close({ $value: result });
        };

        $ctrl.cancel = function () {
            $ctrl.dismiss({ $value: 'cancel' });
        };

        function init() {
            $ctrl.dateFormat = dateFormatService.getDateFormatString();
            $ctrl.dateOptions = {
                maxDate: new Date(),
                startingDay: 0,
                showWeeks: false
            }

            // modal resolves
            $ctrl.dateStart = {
                calendarClick: calendarClick,
                date: $ctrl.resolve.dateStart,
                status : { opened: false }
            };
            $ctrl.selectedAlertTypes = $ctrl.resolve.selectedAlertTypes;

            $ctrl.alertTypes = _.map(alertTypes, function (value, prop) { return { prop: prop, value: value }; });

            //$ctrl.dateStart.date = $ctrl.resolve.dateStart;
            //$ctrl.dateStart.status = { opened: false };
        }


        function calendarClick($event) {
            $ctrl.dateStart.status.opened = true;
        }

        function closeModal() {
            $ctrl.close({ $value: 'submit' });
        }

        function setSubmitActionResult(result) {

        }
    }
})();
