/**
 * @typedef {Object} UrlComponents
 * @property {string} hash      The fragment of the URL, including the '#' symbol.
 * @property {string} host      The hostname and port (if the port is non-standard).
 * @property {string} hostname  The hostname only, not including the port or protocol.
 * @property {string} origin    The protocol, hostname, and port (if the port is non-standard).
 * @property {string} password
 * @property {string} pathname  The full path of the URL, including the initial '/' symbol.
 * @property {string} port
 * @property {string} protocol  The protocol, including the trailing ':' symbol (e.g., "https:").
 * @property {string} search    The query string, including the initial '?' symbol.
 * @property {string} username
*/

/**
 * Parses a URL into its individual components.
 * @param {string} url
 * @return {UrlComponents}
*/
export function getUrlComponents(url) {
    if (typeof url != 'string') {
        throw Error("URL must be a string, got " + typeof url);
    }
    const a = document.createElement('a');
    a.setAttribute('href', url);
    return {
        hash: a.hash,
        host: a.host,
        hostname: a.hostname,
        origin: a.origin,
        password: a.password,
        pathname: a.pathname,
        port: a.port,
        protocol: a.protocol,
        search: a.search,
        username: a.username,
    };
}
