"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./customer-connect.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("ngx-bootstrap/collapse"));
var i3 = __importStar(require("@angular/animations"));
var i4 = __importStar(require("@ngx-translate/core"));
var i5 = __importStar(require("./customer-connect-actions/customer-connect-actions.component.ngfactory"));
var i6 = __importStar(require("./customer-connect-actions/customer-connect-actions.component"));
var i7 = __importStar(require("../../../services/log-action-shortcut.service"));
var i8 = __importStar(require("./customer-connect-detail/customer-connect-detail.component.ngfactory"));
var i9 = __importStar(require("./customer-connect-detail/customer-connect-detail.component"));
var i10 = __importStar(require("./customer-connect.component"));
var styles_CustomerConnectComponent = [i0.styles];
var RenderType_CustomerConnectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerConnectComponent, data: {} });
exports.RenderType_CustomerConnectComponent = RenderType_CustomerConnectComponent;
function View_CustomerConnectComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { collapse: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], [[2, "collapse", null], [2, "in", null], [2, "show", null], [1, "aria-expanded", 0], [1, "aria-hidden", 0], [2, "collapsing", null]], null, null, null, null)), i1.ɵdid(2, 8404992, [[1, 4], ["collapse", 4]], 0, i2.CollapseDirective, [i1.ElementRef, i1.Renderer2, i3.AnimationBuilder], { isAnimated: [0, "isAnimated"], collapse: [1, "collapse"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "text-center action-bar"], ["translate", "customerConnectTab"]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "customer-connect-actions", [], null, [[null, "onAction"], [null, "onCustomerEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAction" === en)) {
        var pd_0 = (_co.onActionSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCustomerEdit" === en)) {
        var pd_1 = (_co.onCustomerEdit.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_CustomerConnectActionsComponent_0, i5.RenderType_CustomerConnectActionsComponent)), i1.ɵdid(6, 638976, null, 0, i6.CustomerConnectActionsComponent, [i7.LogActionShortcutService, i1.ChangeDetectorRef], { opportunity: [0, "opportunity"], customer: [1, "customer"], permissions: [2, "permissions"], isCustomerConnectOpen: [3, "isCustomerConnectOpen"], defaultCustomerConnectAction: [4, "defaultCustomerConnectAction"], isEuroLite: [5, "isEuroLite"], isTextingEnabled: [6, "isTextingEnabled"], mobileView: [7, "mobileView"] }, { onAction: "onAction", onCustomerEdit: "onCustomerEdit" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "customer-connect-detail", [], null, [[null, "onLogScheduleSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogScheduleSubmit" === en)) {
        var pd_0 = (_co.onLogScheduleSubmit.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CustomerConnectDetailComponent_0, i8.RenderType_CustomerConnectDetailComponent)), i1.ɵdid(8, 638976, null, 0, i9.CustomerConnectDetailComponent, [], { permissions: [0, "permissions"], isCustomerConnectOpen: [1, "isCustomerConnectOpen"], action: [2, "action"], dialogParams: [3, "dialogParams"], actionTime: [4, "actionTime"], isEuroLite: [5, "isEuroLite"] }, { onLogScheduleSubmit: "onLogScheduleSubmit" })], function (_ck, _v) { var _co = _v.component; var currVal_6 = !_co.mobileView; var currVal_7 = !_co.isCustomerConnectOpen; _ck(_v, 2, 0, currVal_6, currVal_7); var currVal_8 = "customerConnectTab"; _ck(_v, 4, 0, currVal_8); var currVal_9 = _co.opportunity; var currVal_10 = _co.customer; var currVal_11 = _co.permissions; var currVal_12 = _co.isCustomerConnectOpen; var currVal_13 = _co.defaultCustomerConnectAction; var currVal_14 = _co.isEuroLite; var currVal_15 = _co.isTextingEnabled; var currVal_16 = _co.mobileView; _ck(_v, 6, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.permissions; var currVal_18 = _co.isCustomerConnectOpen; var currVal_19 = _co.action; var currVal_20 = _co.scheduleModalParamObject; var currVal_21 = _co.actionTime; var currVal_22 = _co.isEuroLite; _ck(_v, 8, 0, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isCollapse; var currVal_1 = i1.ɵnov(_v, 2).isExpanded; var currVal_2 = i1.ɵnov(_v, 2).isExpanded; var currVal_3 = i1.ɵnov(_v, 2).isExpanded; var currVal_4 = i1.ɵnov(_v, 2).isCollapsed; var currVal_5 = i1.ɵnov(_v, 2).isCollapsing; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
exports.View_CustomerConnectComponent_0 = View_CustomerConnectComponent_0;
function View_CustomerConnectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-connect", [], null, null, null, View_CustomerConnectComponent_0, RenderType_CustomerConnectComponent)), i1.ɵdid(1, 638976, null, 0, i10.CustomerConnectComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CustomerConnectComponent_Host_0 = View_CustomerConnectComponent_Host_0;
var CustomerConnectComponentNgFactory = i1.ɵccf("customer-connect", i10.CustomerConnectComponent, View_CustomerConnectComponent_Host_0, { isCustomerConnectOpen: "isCustomerConnectOpen", opportunity: "opportunity", customer: "customer", users: "users", maxScheduleFutureDays: "maxScheduleFutureDays", permissions: "permissions", defaultCustomerConnectAction: "defaultCustomerConnectAction", isEuroLite: "isEuroLite", isTextingEnabled: "isTextingEnabled", mobileView: "mobileView" }, { onLogScheduleSubmit: "onLogScheduleSubmit", onCustomerEdit: "onCustomerEdit" }, []);
exports.CustomerConnectComponentNgFactory = CustomerConnectComponentNgFactory;
