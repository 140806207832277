/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SearchDto } from '../models/search-dto';
import { SearchEntityListRequestExtDto } from '../models/search-entity-list-request-ext-dto';
import { OneToOneRequestDto } from '../models/one-to-one-request-dto';
import { SoldBy_SearchDto } from '../models/sold-by-_search-dto';
import { PrivateOfferDto } from '../models/private-offer-dto';
import { SaveSearchResponse } from '../models/save-search-response';
import { SaveSearchRequest } from '../models/save-search-request';
import { Vw_DealerCampaignRequestDto } from '../models/vw-_dealer-campaign-request-dto';
import { O2ODealerCampaignExportAudienceRequestDto } from '../models/o2odealer-campaign-export-audience-request-dto';
import { NLPNoLongerOwnResultDto } from '../models/nlpno-longer-own-result-dto';
@Injectable({
  providedIn: 'root',
})
class SearchClientService extends __BaseService {
  static readonly GetSearchByEntityIdByEntityidGETPath = '/api/Search/GetSearchByEntityId/{entityId}';
  static readonly GetOpenOpportunitiesGETPath = '/api/Search/GetOpenOpportunities';
  static readonly GetOpenOpportunitiesFlushCacheGETPath = '/api/Search/GetOpenOpportunities/FlushCache';
  static readonly GetEntitiesListCustomPOSTPath = '/api/Search/GetEntitiesListCustom';
  static readonly GetOneToOneEntitiesPOSTPath = '/api/Search/GetOneToOneEntities';
  static readonly GetFreshOpportunitiesGETPath = '/api/Search/GetFreshOpportunities';
  static readonly GetFreshOpportunitiesFlushCacheGETPath = '/api/Search/GetFreshOpportunities/FlushCache';
  static readonly GetRecentlyAssignedOpportunitiesGETPath = '/api/Search/GetRecentlyAssignedOpportunities';
  static readonly GetRecentlyAssignedOpportunitiesFlushCacheGETPath = '/api/Search/GetRecentlyAssignedOpportunities/FlushCache';
  static readonly GetSoldBySearchByDealersByDealeridGETPath = '/api/Search/GetSoldBySearchByDealers/{dealerId}';
  static readonly GetPrivateOfferInfoByDealeridByEntityidGETPath = '/api/Search/GetPrivateOfferInfo/{dealerId}/{entityId}';
  static readonly SaveSearchPOSTPath = '/api/Search/SaveSearch';
  static readonly CreateDealerCampaignExportAudiencePOSTPath = '/api/Search/CreateDealerCampaignExportAudience';
  static readonly GetDealerCampaignRequestsInWindowAsyncByDaysGETPath = '/api/Search/GetDealerCampaignRequestsInWindow/{days}';
  static readonly GetNoLongerOwnForDealerByDealeridGETPath = '/api/Search/GetNoLongerOwnForDealer/{dealerId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   */
  GetSearchByEntityIdByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetSearchByEntityId/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetSearchByEntityIdByEntityidGET(entityId: string): __Observable<null> {
    return this.GetSearchByEntityIdByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SearchClientService.GetOpenOpportunitiesGETParams` containing the following parameters:
   *
   * - `saleTypes`:
   *
   * - `flushCache`:
   */
  GetOpenOpportunitiesGETResponse(params: SearchClientService.GetOpenOpportunitiesGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.saleTypes || []).forEach(val => {if (val != null) __params = __params.append('saleTypes', val.toString())});
    if (params.flushCache != null) __params = __params.set('flushCache', params.flushCache.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetOpenOpportunities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SearchClientService.GetOpenOpportunitiesGETParams` containing the following parameters:
   *
   * - `saleTypes`:
   *
   * - `flushCache`:
   */
  GetOpenOpportunitiesGET(params: SearchClientService.GetOpenOpportunitiesGETParams): __Observable<null> {
    return this.GetOpenOpportunitiesGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param saleTypes undefined
   */
  GetOpenOpportunitiesFlushCacheGETResponse(saleTypes?: Array<1 | 2 | 3 | 4 | 6 | 7 | 8>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (saleTypes || []).forEach(val => {if (val != null) __params = __params.append('saleTypes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetOpenOpportunities/FlushCache`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param saleTypes undefined
   */
  GetOpenOpportunitiesFlushCacheGET(saleTypes?: Array<1 | 2 | 3 | 4 | 6 | 7 | 8>): __Observable<null> {
    return this.GetOpenOpportunitiesFlushCacheGETResponse(saleTypes).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  GetEntitiesListCustomPOSTResponse(request?: SearchEntityListRequestExtDto): __Observable<__StrictHttpResponse<Array<SearchDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Search/GetEntitiesListCustom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SearchDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  GetEntitiesListCustomPOST(request?: SearchEntityListRequestExtDto): __Observable<Array<SearchDto>> {
    return this.GetEntitiesListCustomPOSTResponse(request).pipe(
      __map(_r => _r.body as Array<SearchDto>)
    );
  }

  /**
   * @param request undefined
   */
  GetOneToOneEntitiesPOSTResponse(request?: OneToOneRequestDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Search/GetOneToOneEntities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  GetOneToOneEntitiesPOST(request?: OneToOneRequestDto): __Observable<null> {
    return this.GetOneToOneEntitiesPOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SearchClientService.GetFreshOpportunitiesGETParams` containing the following parameters:
   *
   * - `flushCache`:
   *
   * - `MaxResults`:
   */
  GetFreshOpportunitiesGETResponse(params: SearchClientService.GetFreshOpportunitiesGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.flushCache != null) __params = __params.set('flushCache', params.flushCache.toString());
    if (params.MaxResults != null) __params = __params.set('MaxResults', params.MaxResults.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetFreshOpportunities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SearchClientService.GetFreshOpportunitiesGETParams` containing the following parameters:
   *
   * - `flushCache`:
   *
   * - `MaxResults`:
   */
  GetFreshOpportunitiesGET(params: SearchClientService.GetFreshOpportunitiesGETParams): __Observable<null> {
    return this.GetFreshOpportunitiesGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param MaxResults undefined
   */
  GetFreshOpportunitiesFlushCacheGETResponse(MaxResults?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (MaxResults != null) __params = __params.set('MaxResults', MaxResults.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetFreshOpportunities/FlushCache`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param MaxResults undefined
   */
  GetFreshOpportunitiesFlushCacheGET(MaxResults?: number): __Observable<null> {
    return this.GetFreshOpportunitiesFlushCacheGETResponse(MaxResults).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SearchClientService.GetRecentlyAssignedOpportunitiesGETParams` containing the following parameters:
   *
   * - `flushCache`:
   *
   * - `MaxResults`:
   */
  GetRecentlyAssignedOpportunitiesGETResponse(params: SearchClientService.GetRecentlyAssignedOpportunitiesGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.flushCache != null) __params = __params.set('flushCache', params.flushCache.toString());
    if (params.MaxResults != null) __params = __params.set('MaxResults', params.MaxResults.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetRecentlyAssignedOpportunities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SearchClientService.GetRecentlyAssignedOpportunitiesGETParams` containing the following parameters:
   *
   * - `flushCache`:
   *
   * - `MaxResults`:
   */
  GetRecentlyAssignedOpportunitiesGET(params: SearchClientService.GetRecentlyAssignedOpportunitiesGETParams): __Observable<null> {
    return this.GetRecentlyAssignedOpportunitiesGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param MaxResults undefined
   */
  GetRecentlyAssignedOpportunitiesFlushCacheGETResponse(MaxResults?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (MaxResults != null) __params = __params.set('MaxResults', MaxResults.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetRecentlyAssignedOpportunities/FlushCache`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param MaxResults undefined
   */
  GetRecentlyAssignedOpportunitiesFlushCacheGET(MaxResults?: number): __Observable<null> {
    return this.GetRecentlyAssignedOpportunitiesFlushCacheGETResponse(MaxResults).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetSoldBySearchByDealersByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<SoldBy_SearchDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetSoldBySearchByDealers/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SoldBy_SearchDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetSoldBySearchByDealersByDealeridGET(dealerId: number): __Observable<Array<SoldBy_SearchDto>> {
    return this.GetSoldBySearchByDealersByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<SoldBy_SearchDto>)
    );
  }

  /**
   * @param params The `SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetPrivateOfferInfoByDealeridByEntityidGETResponse(params: SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETParams): __Observable<__StrictHttpResponse<PrivateOfferDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetPrivateOfferInfo/${params.dealerId}/${params.entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrivateOfferDto>;
      })
    );
  }
  /**
   * @param params The `SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  GetPrivateOfferInfoByDealeridByEntityidGET(params: SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETParams): __Observable<PrivateOfferDto> {
    return this.GetPrivateOfferInfoByDealeridByEntityidGETResponse(params).pipe(
      __map(_r => _r.body as PrivateOfferDto)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  SaveSearchPOSTResponse(request?: SaveSearchRequest): __Observable<__StrictHttpResponse<SaveSearchResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Search/SaveSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SaveSearchResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  SaveSearchPOST(request?: SaveSearchRequest): __Observable<SaveSearchResponse> {
    return this.SaveSearchPOSTResponse(request).pipe(
      __map(_r => _r.body as SaveSearchResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateDealerCampaignExportAudiencePOSTResponse(request?: O2ODealerCampaignExportAudienceRequestDto): __Observable<__StrictHttpResponse<Array<Vw_DealerCampaignRequestDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Search/CreateDealerCampaignExportAudience`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Vw_DealerCampaignRequestDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateDealerCampaignExportAudiencePOST(request?: O2ODealerCampaignExportAudienceRequestDto): __Observable<Array<Vw_DealerCampaignRequestDto>> {
    return this.CreateDealerCampaignExportAudiencePOSTResponse(request).pipe(
      __map(_r => _r.body as Array<Vw_DealerCampaignRequestDto>)
    );
  }

  /**
   * @param days undefined
   * @return Success
   */
  GetDealerCampaignRequestsInWindowAsyncByDaysGETResponse(days: number): __Observable<__StrictHttpResponse<Array<Vw_DealerCampaignRequestDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetDealerCampaignRequestsInWindow/${days}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Vw_DealerCampaignRequestDto>>;
      })
    );
  }
  /**
   * @param days undefined
   * @return Success
   */
  GetDealerCampaignRequestsInWindowAsyncByDaysGET(days: number): __Observable<Array<Vw_DealerCampaignRequestDto>> {
    return this.GetDealerCampaignRequestsInWindowAsyncByDaysGETResponse(days).pipe(
      __map(_r => _r.body as Array<Vw_DealerCampaignRequestDto>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetNoLongerOwnForDealerByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<NLPNoLongerOwnResultDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Search/GetNoLongerOwnForDealer/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NLPNoLongerOwnResultDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetNoLongerOwnForDealerByDealeridGET(dealerId: number): __Observable<Array<NLPNoLongerOwnResultDto>> {
    return this.GetNoLongerOwnForDealerByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<NLPNoLongerOwnResultDto>)
    );
  }
}

module SearchClientService {

  /**
   * Parameters for GetOpenOpportunitiesGET
   */
  export interface GetOpenOpportunitiesGETParams {
    saleTypes?: Array<1 | 2 | 3 | 4 | 6 | 7 | 8>;
    flushCache?: boolean;
  }

  /**
   * Parameters for GetFreshOpportunitiesGET
   */
  export interface GetFreshOpportunitiesGETParams {
    flushCache?: boolean;
    MaxResults?: number;
  }

  /**
   * Parameters for GetRecentlyAssignedOpportunitiesGET
   */
  export interface GetRecentlyAssignedOpportunitiesGETParams {
    flushCache?: boolean;
    MaxResults?: number;
  }

  /**
   * Parameters for GetPrivateOfferInfoByDealeridByEntityidGET
   */
  export interface GetPrivateOfferInfoByDealeridByEntityidGETParams {
    entityId: string;
    dealerId: number;
  }
}

export { SearchClientService }
