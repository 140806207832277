"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var constants_1 = require("../../../../constants");
var toaster_logger_service_1 = require("../../../../core/toaster-logger.service");
var activity_service_1 = require("../../../../services/activity.service");
var customer_service_1 = require("../../../../services/customer.service");
var mobile_deal_sheet_data_caching_service_1 = require("../../../../services/mobile-deal-sheet-data-caching.service");
var MobileCustomerConnectComponent = /** @class */ (function () {
    function MobileCustomerConnectComponent(activityService, toasterLoggerService, customerService, mobileDealSheetDataCachingService, transition, changeDetectorRef) {
        this.activityService = activityService;
        this.toasterLoggerService = toasterLoggerService;
        this.customerService = customerService;
        this.mobileDealSheetDataCachingService = mobileDealSheetDataCachingService;
        this.transition = transition;
        this.changeDetectorRef = changeDetectorRef;
        this.activityActionEnum = constants_1.ActivityActionEnum;
        this.defaultCustomerConnectAction = this.activityActionEnum.Log_Call;
        this.isCustomerConnectOpen = true;
        this.opportunityId = this.transition.params().opportunityId;
    }
    MobileCustomerConnectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(function (dealSheetData) {
            _this.opportunity = dealSheetData.opportunity;
            _this.permissions = dealSheetData.permissions;
            _this.usersSubscription = _this.mobileDealSheetDataCachingService.getUsers(dealSheetData.opportunity.dealerId)
                .subscribe(function (users) {
                _this.users = users;
                // users is passed down through to the upgraded angularjs components and the change is not picked up there.
                // This can probably be removed once those components are Angular 8
                _this.changeDetectorRef.detectChanges();
            });
        });
    };
    MobileCustomerConnectComponent.prototype.onLogScheduleSubmit = function (result) {
        var _this = this;
        if (!result) {
            return;
        } // user data entry error
        if (result === 'cancel') {
            return;
        }
        this.isCustomerConnectOpen = false;
        this.busy = this.activityService.postActivitySubmitAction(result)
            .then(function (data) {
            if (result.isFollowUpScheduleAppt) {
                _this.toasterLoggerService.success('postActivityFollowupMsg');
                _this.defaultCustomerConnectAction = _this.activityActionEnum.Schedule_Appt;
            }
            else {
                _this.toasterLoggerService.success('postActivitySuccessMsg');
            }
            // if vehicle no longer owns
            if (result.resultID === constants_1.ActivityResults.phoneCall.noLongerOwns || result.resultID === constants_1.ActivityResults.note.noLongerOwns) {
                return _this.activityService.updateCustomerDoesNotOwnVehicle(_this.opportunity.id).then(function () {
                });
            }
            // if vehicle paid off
            if (result.resultID === constants_1.ActivityResults.phoneCall.vehiclePaidOff) {
                return _this.activityService.updateVehiclePaidOff(_this.opportunity.id)
                    .then(function () {
                });
            }
            _this.isCustomerConnectOpen = true;
            return _this.refreshActivities();
        });
    };
    MobileCustomerConnectComponent.prototype.updateCustomer = function (customer) {
        var _this = this;
        this.busy = this.customerService.updateCustomer(customer).then(function (success) {
            if (success) {
                _this.mobileDealSheetDataCachingService.clearDealSheetData();
            }
        });
    };
    MobileCustomerConnectComponent.prototype.refreshActivities = function () {
        this.mobileDealSheetDataCachingService.clearActivities();
    };
    MobileCustomerConnectComponent.prototype.ngOnDestroy = function () {
        this.opportunitySubscription.unsubscribe();
        this.usersSubscription.unsubscribe();
    };
    return MobileCustomerConnectComponent;
}());
exports.MobileCustomerConnectComponent = MobileCustomerConnectComponent;
