"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var AnnouncementClientService = /** @class */ (function (_super) {
    __extends(AnnouncementClientService, _super);
    function AnnouncementClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    AnnouncementClientService.prototype.GetAnnouncementForUserGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Announcement/GetAnnouncementForUser", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AnnouncementClientService.prototype.GetAnnouncementForUserGET = function () {
        return this.GetAnnouncementForUserGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTParams` containing the following parameters:
     *
     * - `announcementId`:
     *
     * - `doNotDisplay`:
     */
    AnnouncementClientService.prototype.UpdateAnnouncementUserDisplayByAnnouncementidPUTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.doNotDisplay;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + ("/api/Announcement/UpdateAnnouncementUserDisplay/" + params.announcementId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTParams` containing the following parameters:
     *
     * - `announcementId`:
     *
     * - `doNotDisplay`:
     */
    AnnouncementClientService.prototype.UpdateAnnouncementUserDisplayByAnnouncementidPUT = function (params) {
        return this.UpdateAnnouncementUserDisplayByAnnouncementidPUTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    AnnouncementClientService.GetAnnouncementForUserGETPath = '/api/Announcement/GetAnnouncementForUser';
    AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTPath = '/api/Announcement/UpdateAnnouncementUserDisplay/{announcementId}';
    AnnouncementClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnnouncementClientService_Factory() { return new AnnouncementClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: AnnouncementClientService, providedIn: "root" });
    return AnnouncementClientService;
}(base_service_1.BaseService));
exports.AnnouncementClientService = AnnouncementClientService;
