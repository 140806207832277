<div *ngIf="contract?.saleType != saleTypes.cash">

  <div *ngIf="type == paymentTypes.current"
       class="alert-inline-only"
       [ngClass]="{ alert: alertExists, flex: flexAlertExists }">

      <span class="h5 qa-current-term-payment"
            *ngIf="contract?.conquestType != conquestTypes.creditConvert && contract?.term && contract?.payment"
            translate="contract"
            [translateParams] = "{months: contract?.term, amount: contract?.payment | localeCurrency }" >
      </span>

      <span class="h5 qa-current-term-payment"
            *ngIf="contract?.conquestType == conquestTypes.creditConvert && contract?.paymentMinimum && contract?.paymentMaximum"
            translate="creditConvertContract"
            [translateParams] = "{months: contract?.term, minAmount: contract?.paymentMinimum | localeCurrency, maxAmount: contract?.paymentMaximum | localeCurrency }" >
      </span>

      <span class="value qa-current-payments-remaining" *ngIf="contract?.paymentsRemaining != null && contract?.saleType != saleTypes.onePayLease"
            translate="paymentsRemaining"
            [translateParams] = "{payments: contract?.paymentsRemaining}" >
      </span>

      <span class="value qa-current-payments-remaining" *ngIf="contract?.paymentsRemaining != null && contract?.saleType == saleTypes.onePayLease"
            translate="paymentsRemainingMo"
            [translateParams] = "{payments: paymentsRemainingMo}" >
      </span>
  </div>

  <div *ngIf="type == paymentTypes.replacement && contract?.payment">
      <div *ngIf="contract?.term"
           class="value alert-inline-only"
           [ngClass]="{alert: alertExists, flex: flexAlertExists}">
          <span class="h5 qa-replacement-term-payment" 
                translate="contract" 
                [translateParams] = "{months: contract?.term, amount: contract?.payment | localeCurrency }"></span>&nbsp;
          <span class="qa-replacement-payment-difference">{{'(' + (absoluteValue(contract.paymentDifference) | localeCurrency)}}</span>&nbsp;
          <span *ngIf="contract?.paymentDifference <= 0">{{('less' | translate) + ')'}}&nbsp;</span>
          <span *ngIf="contract?.paymentDifference > 0">{{('more' | translate) + ')'}}</span>
      </div>
      <div *ngIf="shouldShowPaymentBeforeImprovement" class="strike-out">
          <span class="h5 qa-replacement-before-improvement" 
                translate="contract"
                [translateParams] = "{months: contract?.termBeforeImprovement, amount: contract?.paymentBeforeImprovement | localeCurrency }" >
          </span>
      </div>
  </div>

  <div *ngIf="permissions?.payments && !contract?.term" uib-alert [ngClass]="'alert-danger'">
      <span translate="noPaymentsAvailable"></span>
  </div>

</div>

