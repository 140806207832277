PreferredEquityController.$inject = ["saleTypes"];

/* injects from baggage-loader */
require('./preferred-equity.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('preferredEquity', {
  templateUrl: '/app/components/deal-sheet/contract/preferred-equity.component.html',
  controller: PreferredEquityController,
  bindings: {
    value: '<',
    saleType: '<',
    isEuroLite: '<'
  }
});
/* @ngInject */

function PreferredEquityController(saleTypes) {
  var $ctrl = this;
  $ctrl.$onChanges = onChanges;
  $ctrl.toolTipMessage = "";

  function onChanges() {
    if ($ctrl.saleType == saleTypes.retail) $ctrl.toolTipMessage = 'preferredEquityRetailBNPTooltip'; //"retail";

    if ($ctrl.saleType == saleTypes.lease) $ctrl.toolTipMessage = 'preferredEquityLeaseBNPTooltip'; //"lease";
  }
}