import header from './report-header-types';

export const ReportSpecs = {
    dashboardReport: {
        enterprise: {
            titleKey: 'dashboardEnterpriseReport',
            id: '07AB1B03-C3DF-49EF-BE1D-3782154ED111',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: false,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'dashboardReport',
        id: '300D9123-2B03-4F1D-9E59-0EC3860109C7',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: false,
        subscriptionEnabled: true
    },
    performanceReport: {
        titleKey: 'performanceReport',
        id: '95a222ba-3a87-4d6b-917f-3206eb656b13',
        header: header.performanceReport,
        pdfExportEnabled: true,
        excelExportEnabled: false,
        subscriptionEnabled: false
    },
    serviceDriveSalesPerformanceReport: {
        enterprise: {
            titleKey: 'serviceDriveEnterpriseReport',
            id: '89AE34BF-1B2D-4409-BD79-F172F854AB33',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'serviceDriveSalesPerformanceReport',
        id: '45F68C06-BD36-4C14-8B3D-C5975B5A1993',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true
    },
    salesGrossReport: {
        enterprise: {
            titleKey: 'salesGrossEnterpriseReport',
            id: 'F4F716B9-A622-4D4D-8D5F-286CA4009F54',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'salesGrossReport',
        id: 'F75B5259-918F-40A5-BE58-4C8C4D90C9BE',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true
    },
    soldUnitsReport: {
        enterprise: {
            titleKey: 'soldUnitsEnterpriseReport',
            id: 'F0F4BC96-37F0-4D0B-91AE-4B0E6ED45700',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'soldUnitsReport',
        id: '95914140-DCBC-4A33-89F0-5F7585677ABD',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true
    },
    inventoryReport: {
        titleKey: 'inventoryReport',
        id: '947A06D2-947A-4516-B87E-DECBF1627D56',
        header: header.dealerMakeNoSubscriptionDateSelector,
        pdfExportEnabled: false,
        excelExportEnabled: true,
        subscriptionEnabled: true,
        hideDateRange: true
    },
    certificationReport: {
        enterprise: {
            titleKey: 'certificationEnterpriseReport',
            id: 'CBCDCB2E-99E0-4284-BD2D-E3D1D33FF675',
            header: header.dealerOnlyNoSubscriptionDateSelector,
            pdfExportEnabled: false,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'certificationReport',
        id: '33807310-7EEC-41C9-B421-9FC02624ECAB',
        header: header.dealerOnlyNoSubscriptionDateSelector,
        pdfExportEnabled: false,
        excelExportEnabled: true,
        subscriptionEnabled: true,
        hideDateRange: true
    },
    creditConvertReport: {
        titleKey: 'creditConvertReport',
        id: 'FFA1E807-7BAC-4D04-B711-CE246FA664C1',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true
    },
    userActivityReport: {
        enterprise: {
            titleKey: 'userActivityEnterpriseReport',
            id: '8AB29810-08E6-4ABB-A3A8-7465C4AF398B',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'userActivityReport',
        id: '7F802F2E-C591-41AA-8796-CF89565B0FDD',
        header: header.dealerDatesPresets,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true
    },
    autoAssistantSoldUnitsReport: {
        enterprise: {
            titleKey: 'autoAssistantSoldUnitsEnterpriseReport',
            id: '63A3112B-FA5F-40E1-AF4A-5594C51B6A40',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
      titleKey: 'autoAssistantSoldUnitsReport',
      id: 'A1DCDA27-D901-4CCC-BE50-13BF401AC1DA',
      header: header.full,
      pdfExportEnabled: true,
      excelExportEnabled: true,
      subscriptionEnabled: true,
    },
    autoAssistantActivityReport: {
        titleKey: 'autoAssistantActivityReport',
        id: '71328081-6A58-432B-B1A2-BB074888D236',
        header: header.dealerDatesPresets,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true,
    },
    geoAlertSoldUnitsReport: {
        enterprise: {
            titleKey: 'geoAlertSoldUnitsEnterpriseReport',
            id: 'ffd67c1c-c963-4aaa-b7dc-8b6f60cb387c',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'geoAlertSoldUnitsReport',
        id: 'F53C3FA9-A4FB-49DD-A59C-53315E1B66E0',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true,
    },
    onetoOneSoldUnitsReport: {
        enterprise: {
            titleKey: 'onetoOneSoldUnitsEnterpriseReport',
            id: 'E068D026-F9DC-4B34-8F44-14ABF7E5B492',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'onetoOneSoldUnitsReport',
        id: 'F0C44905-30B9-4FE9-9740-F9410AE247DB',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true,
    },
    partnerExportSoldUnitsReport: {
        titleKey: 'partnerExportSoldUnitsReport',
        id: 'BC818593-8D81-4CDC-8C1A-02708B9213AE',
        header: header.full,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true,
    },
    serviceLaneTechnologyReport: {
        titleKey: 'serviceLaneTechnologyReport',
        id: 'F1AC5684-0D82-4181-9632-300C16F09916',
        header: header.dealerOnly,
        pdfExportEnabled: true,
        excelExportEnabled: false
    },
    pandoSurveyReport: {
        titleKey: 'pandoSurveyReport',
        id: 'A633D09A-9627-4568-88A0-B72AA0BC4443',
        header: header.pandoSurvey,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        isPandoReport: true
    },
    pandoUserActivityReport: {
        enterprise: {
            titleKey: 'pandoUserActivityEnterpriseReport',
            id: '2327CBF0-5E10-4415-B04D-EA282996C0DE',
            header: header.dealerDatesPresets,
            pdfExportEnabled: true,
            excelExportEnabled: true,
            subscriptionEnabled: true,
            isEnterprise: true
        },
        titleKey: 'pandoUserActivityReport',
        id: '9BF6AB7B-26CA-474D-9AC9-77A720EEF2F1',
        header: header.dealerDatesPresets,
        pdfExportEnabled: true,
        excelExportEnabled: true,
        subscriptionEnabled: true
    },
    dataCleanseReport: {
        titleKey: 'dataCleanseReport',
        id: 'CA32CD1C-3BBB-4D44-B9F8-B8661595554C',
        header: header.dealerOnlyNoSubscriptionDateSelector,
        pdfExportEnabled: false,
        excelExportEnabled: true,
        subscriptionEnabled: true,
        hideDateRange: true
    }
};

export default ReportSpecs;

export const ReportSpecsList = [];
for (let property in ReportSpecs) {
    let item = ReportSpecs[property];
    if (item.subscriptionEnabled) { ReportSpecsList.push(item); }
    if (item.enterprise && item.enterprise.subscriptionEnabled) { ReportSpecsList.push(item.enterprise); }
}

export const AllReportSpecs = [];
for (let property in ReportSpecs) {
    let item = ReportSpecs[property];
    AllReportSpecs.push(item);
    if (item.enterprise) { AllReportSpecs.push(item.enterprise); }
}

// usage samples:
// import { ReportSpecs } from './report-specs';
// import reportSpecs from './report-specs';
// import reportSpecs from '../constants/reports/report-specifications';
