
/* injects from baggage-loader */
require('./data-entry-alerts.component.html');

import angular from 'angular';
import $ from 'jquery';
import './data-entry-alerts.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';

angular.module('AaApp.Opp').component('dataEntryAlerts', {
    templateUrl: '/app/components/data-entry/data-entry-alerts.component.html',
    controller: DataEntryAlertsController,
    bindings: {
        kendo: '<',
    }
});

function DataEntryAlertsController($translate, $q, vehicleService, dealerService, userService, dataEntryService, 
    $uibModal, $document, logger, cultures, $logFactory, modules) {
    "ngInject";
    const $ctrl = this;
    const log = $logFactory.get();

    $ctrl.$onInit = init;
    $ctrl.onDealerSelect = onDealerSelect;
    $ctrl.trimSelections;
    $ctrl.setTrimSelections = setTrimSelections;
    $ctrl.showQuickAction = false;
    $ctrl.onShowQuickAction = onShowQuickAction;
    $ctrl.onHideQuickAction = onHideQuickAction;
    $ctrl.onPerform = onPerform;
    $ctrl.onClear = onClear;
    $ctrl.imposeMinMax = imposeMinMax;
    $ctrl.showDisabledTooltip = showDisabledTooltip;
    
    $ctrl.onSave = onSave;
    $ctrl.onCancel = onCancel;
    $ctrl.kendoGrid = null;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.minTolerance = 50;
    $ctrl.maxTolerance = 150;
    $ctrl.minMonth = 6;
    $ctrl.maxMonth = 99;
    //$ctrl.countryId;
    $ctrl.cultureName;

    $ctrl.alertLeaseSetTo = null;
    $ctrl.alertRetailSetTo = null;
    $ctrl.flexAlertLeaseSetTo = null;
    $ctrl.flexAlertRetailSetTo = null;
    $ctrl.flexMonthSetTo = null;

    $ctrl.isFordAlert = false;
    $ctrl.editsDisabledTooltip = null;
    $ctrl.gridPageSize = 50;

    function init() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        $ctrl.selectedDealerId = $ctrl.dealers[0].id;

        userService.getUserProfile()
            .then(function (userProfile) {
                //$ctrl.countryId = userProfile.countryId;
                $ctrl.cultureName = userProfile.cultureName;
                $ctrl.isFordAlert = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);
                getTranslations();
                initGridOptions();
                onDealerSelect();
            })
    }

    function getTranslations() {
        $q.all({
            'saveConfirmMessage': $translate('saveConfirmMessage'),
            'saveWarningMessage': $translate('saveWarningMessage'),
            'currencySymbol': $translate('currencySymbol'),
            'validationErrorMessage': $translate('validationErrorMessage'),
            'editsDisabledTooltip': $translate('fordToleranceEditsDisabled')
        }).then(function (stuff) {
            $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
            $ctrl.saveWarningMessage = stuff.saveWarningMessage;
            $ctrl.currencySymbol = stuff.currencySymbol;
            $ctrl.validationErrorMessage = stuff.validationErrorMessage;
            $ctrl.editsDisabledTooltip = stuff.editsDisabledTooltip;
        })

    }

    function showDisabledTooltip() {
        if ($ctrl.isFordAlert)
            return $ctrl.editsDisabledTooltip;
        else
            return null;
    }

    function busy(translationKey, promise) {
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function onDealerSelect(previousValue) {
        if (isDataDirty()) {

            var modal = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "pleaseConfirm";
                    },
                    message: function () {
                        return $ctrl.saveWarningMessage;
                    },
                    submitButtonVisible: function () {
                        return true;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    submitButtonText: function () {
                        return "continue"; // translation key
                    }
                }
            });

            modal.result.then(function () {
                return busy(
                    "Loading Tolerances",
                    $q.all([
                        vehicleService.getMakes($ctrl.selectedDealerId),
                        vehicleService.getYearIDs($ctrl.selectedDealerId, false),
                    ]).then((data) => {
                        $ctrl.selectedMakeId = data[0][0].makeID;
                        //$ctrl.countryId = data[0][0].countryId;
                        var currentYear = new Date().getFullYear();
                        $ctrl.selectedYearId = data[1][0];
                        if ($ctrl.selectedYearId != currentYear)
                            $ctrl.selectedYearId = currentYear;
                        $ctrl.defaults = {
                            vehicleTypes: 0,
                            makeID: data[0][0].makeID,
                            makeName: data[0][0].makeName,
                            yearID: $ctrl.selectedYearId,
                            modelID: null,
                            modelName: null,
                            trimID: null,
                            trimName: null
                        };
                        $ctrl.trimSelections = $ctrl.defaults;
                        $ctrl.isFordAlert = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);
                        return loadTolerances();
                    })
                );
            })
                .catch(function () {
                    $ctrl.selectedDealerId = previousValue;
                    return false;
                });

        }
        else
            return busy(
                "Loading Tolerances",
                $q.all([
                    vehicleService.getMakes($ctrl.selectedDealerId),
                    vehicleService.getYearIDs($ctrl.selectedDealerId, false),
                ]).then((data) => {
                    $ctrl.selectedMakeId = data[0][0].makeID;
                    //$ctrl.countryId = data[0][0].countryId;
                    var currentYear = new Date().getFullYear();
                    $ctrl.selectedYearId = data[1][0];
                    if ($ctrl.selectedYearId != currentYear)
                        $ctrl.selectedYearId = currentYear;
                    $ctrl.defaults = {
                        vehicleTypes: 0,
                        makeID: data[0][0].makeID,
                        makeName: data[0][0].makeName,
                        yearID: $ctrl.selectedYearId,
                        modelID: null,
                        modelName: null,
                        trimID: null,
                        trimName: null
                    };
                    $ctrl.trimSelections = $ctrl.defaults;
                    $ctrl.isFordAlert = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);
                    return loadTolerances();
                })
            );
    }

    function onShowQuickAction() {
        $ctrl.showQuickAction = true;

        if (!$ctrl.alertLeaseSetTo)
            $ctrl.alertLeaseSetTo = null;
        if (!$ctrl.alertRetailSetTo)
            $ctrl.alertRetailSetTo = null;
        if (!$ctrl.flexAlertLeaseSetTo)
            $ctrl.flexAlertLeaseSetTo = null;
        if (!$ctrl.flexAlertRetailSetTo)
            $ctrl.flexAlertRetailSetTo = null;
        if (!$ctrl.flexMonthSetTo)
            $ctrl.flexMonthSetTo = null;
    }

    function onHideQuickAction() {
        $ctrl.showQuickAction = false;
    }

    function clearQuickAction() {
        $ctrl.alertLeaseSetTo = null;
        $ctrl.alertRetailSetTo = null;
        $ctrl.flexAlertLeaseSetTo = null;
        $ctrl.flexAlertRetailSetTo = null;
        $ctrl.flexMonthSetTo = null;
    }

    function onPerform(columnName, setToValue) {
        if (setToValue && setToValue !== null) {
            var rows = $ctrl.kendoGrid.dataSource.data();
            rows.forEach(function (row) {
                row[columnName] = setToValue;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields[columnName] = true;
                else
                    row.dirtyFields = { columnName: true }
            });
            $ctrl.kendoGrid.refresh();
        }
    }

    function onClear(columnName) {
        var rows = $ctrl.kendoGrid.dataSource.data();
        rows.forEach(function (row) {
            row.dirty = true;

            switch (columnName) {
                case 'leaseAlertTolerance':
                    row['leaseAlertTolerance'] = row['leaseAlertToleranceDefault']
                case 'retailAlertTolerance':
                    row['retailAlertTolerance'] = row['retailAlertToleranceDefault']
                case 'leaseFlexAlertTolerance':
                    row['leaseFlexAlertTolerance'] = row['leaseFlexAlertToleranceDefault']
                case 'retailFlexAlertTolerance':
                    row['retailFlexAlertTolerance'] = row['retailFlexAlertToleranceDefault']
                case 'flexMonthsTolerance':
                    row['flexMonthsTolerance'] = row['flexMonthsToleranceDefault']
            }

            if (row.dirtyFields)
                row.dirtyFields[columnName] = true;
            else
                row.dirtyFields = { columnName: true }

        });

        $ctrl.kendoGrid.refresh();
    }

    function imposeMinMax(e) {
        if (e.target.value != "") {
            if (parseInt(e.target.value) < parseInt(e.target.min)) {
                e.target.value = e.target.min;
            }
            if (parseInt(e.target.value) > parseInt(e.target.max)) {
                e.target.value = e.target.max;
            }

            return parseInt(e.target.value);
        }
        else {
            return null;
        }
    }

    function onSave() {

        if (!isDataValid()) {
            var modal1 = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "validationError";
                    },
                    message: function () {
                        return $ctrl.validationErrorMessage;
                    },
                    submitButtonVisible: function () {
                        return false;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    closeButtonVisible: function () {
                        return true;
                    },
                    closeButtonDisabled: function () {
                        return true;
                    },
                    closeButtonText: function () {
                        return "ok"; // translation key
                    }
                }
            });

            return;
        }

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'confirmationModal',
            resolve: {
                title: function () {
                    return "readyToProcess";
                },
                message: function () {
                    return $ctrl.saveConfirmMessage;
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "continue"; // translation key
                }
            }
        });

        modal.result.then(function () {
            var rows = $ctrl.kendoGrid.dataSource._data;
            var overrides = [];
            rows.forEach(function (row) {

                // if any values equal the default set them to null in overrides
                row.leaseAlertTolerance = row.leaseAlertTolerance == row.leaseAlertToleranceDefault ? null : row.leaseAlertTolerance;
                row.retailAlertTolerance = row.retailAlertTolerance == row.retailAlertToleranceDefault ? null : row.retailAlertTolerance;
                row.leaseFlexAlertTolerance = row.leaseFlexAlertTolerance == row.leaseFlexAlertToleranceDefault ? null : row.leaseFlexAlertTolerance;
                row.retailFlexAlertTolerance = row.retailFlexAlertTolerance == row.retailFlexAlertToleranceDefault ? null : row.retailFlexAlertTolerance;
                row.flexMonthsTolerance = row.flexMonthsTolerance == row.flexMonthsToleranceDefault ? null : row.flexMonthsTolerance;

                // overrides includes all rows from grid. If all tolerance values are null in a row it should be deleted by AlertMiner
                overrides.push({
                    trimID: row.trimID,
                    leaseAlertTolerance: row.leaseAlertTolerance,
                    retailAlertTolerance: row.retailAlertTolerance,
                    leaseFlexAlertTolerance: row.leaseFlexAlertTolerance,
                    retailFlexAlertTolerance: row.retailFlexAlertTolerance,
                    flexMonthsTolerance: row.flexMonthsTolerance,
                    modifiedBy: row.modifiedBy,
                    modifiedDate: row.modifiedDate,
                    isModified: row.dirty
                });
                row.dirty = false;
            });
            if (overrides.length > 0) {
                return updateAlertTolerances(overrides);
            } else {
                logger.success("changesSaved");
                return $q.resolve();
            }
        })
            .catch(function () {
                return false;
            });
    }

    function rowHasOverride(row) {
        if ((row.leaseAlertTolerance != null && row.leaseAlertTolerance != row.leaseAlertToleranceDefault) ||
            (row.retailAlertTolerance != null && row.retailAlertTolerance != row.retailAlertToleranceDefault) ||
            (row.leaseFlexAlertTolerance != null && row.leaseFlexAlertTolerance != row.leaseFlexAlertToleranceDefault) ||
            (row.retailFlexAlertTolerance != null && row.retailFlexAlertTolerance != row.retailFlexAlertToleranceDefault) ||
            (row.flexMonthsTolerance != null && row.flexMonthsTolerance != row.flexMonthsToleranceDefault)) {
            return true;
        }
        else
            return false;
    }

    function onCancel() {
        $ctrl.kendoGrid.cancelChanges();
    }

    function updateAlertTolerances(overrides) {
        return busy(
            "Saving Tolerances",
            dealerService.UpdateDealerAlertTolerances($ctrl.selectedDealerId, overrides)
                .then(function (success) {
                    logger.success("changesSaved");
                    return loadTolerances();
                }).then(() => {

                }, function (error) {
                    log.log(error);
                })
        );
        return loadTolerances();
    }

    function setTrimSelections(selections) {

        if (isDataDirty()) {

            var modal = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "pleaseConfirm";
                    },
                    message: function () {
                        return $ctrl.saveWarningMessage;
                    },
                    submitButtonVisible: function () {
                        return true;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    submitButtonText: function () {
                        return "continue"; // translation key
                    }
                }
            });

            modal.result.then(function () {
                $ctrl.trimSelections = selections;
                return loadTolerances();
            })
                .catch(function () {
                    if (selections.makeID != $ctrl.trimSelections.makeID
                        || selections.yearID != $ctrl.trimSelections.yearID
                        || selections.modelID != $ctrl.trimSelections.modelID
                        || selections.trimID != $ctrl.trimSelections.trimID) {

                        $ctrl.defaults = {
                            vehicleTypes: 0,
                            makeID: $ctrl.trimSelections.makeID,
                            makeName: $ctrl.trimSelections.makeName,
                            yearID: $ctrl.trimSelections.yearID,
                            modelID: $ctrl.trimSelections.modelID,
                            modelName: $ctrl.trimSelections.modelName,
                            trimID: $ctrl.trimSelections.trimID,
                            trimName: $ctrl.trimSelections.trimName
                        };
                    }
                    return;
                });
        }
        else {
            $ctrl.trimSelections = selections;
            return loadTolerances();
        }
    }

    function isDataValid() {
        if (!$ctrl.kendoGrid)
            return true;
        var valid = true;
        var rows = $ctrl.kendoGrid.dataSource._data;
        //rows.forEach(function (row) {
        //    if ((row.price && !row.expirationDate || !row.price && row.expirationDate)
        //        || row.price && (row.price < $ctrl.minPrice || row.price > $ctrl.maxPrice))
        //        valid = false;
        //});
        return valid;
    }

    function isDataDirty() {
        if (!$ctrl.kendoGrid)
            return false;
        var dirty = false;
        var rows = $ctrl.kendoGrid.dataSource._data;
        for (var i = 0; i < rows.length; i++) {
            if (rows[i].dirty) {
                dirty = true;
                break;
            }
        }
        return dirty;
    }

    function initGridOptions() {
        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            navigatable: true,
            pageable: true,
            height: 580,
            columns: [
                { field: "trimFullName", title: "{{ 'trim' | translate }}", width: 110, sortable: true },
                {
                    title: "{{ 'alertsToleranceColHeader' | translate }}",
                    headerAttributes: { style: "text-align: center;" },
                    columns: [
                        {
                            field: "leaseAlertTolerance",
                            title: "{{ 'lease' | translate }}",
                            template: leaseAlertToleranceTemplate,
                            editor: toleranceEditor,
                            format: "{0:n0}",
                            width: 60,
                            attributes: { style: "text-align: right" },
                            headerAttributes: { "class": "align-center" }
                        },
                        {
                            field: "retailAlertTolerance",
                            title: "{{ 'retail' | translate }}",
                            template: retailAlertToleranceTemplate,
                            editor: toleranceEditor,
                            format: "{0:n0}",
                            width: 60,
                            attributes: { style: "text-align: right" },
                            headerAttributes: { "class": "align-center" }
                        },
                    ]
                },
                {
                    title: "{{ 'flexAlertsToleranceColHeader' | translate }}",
                    headerAttributes: { style: "text-align: center;" },
                    columns: [
                        {
                            field: "leaseFlexAlertTolerance",
                            title: "{{ 'lease' | translate }}",
                            template: leaseFlexAlertToleranceTemplate,
                            editor: toleranceEditor,
                            format: "{0:n0}",
                            width: 60,
                            attributes: { style: "text-align: right" },
                            headerAttributes: { "class": "align-center" }
                        },
                        {
                            field: "retailFlexAlertTolerance",
                            title: "{{ 'retail' | translate }}",
                            template: retailFlexAlertToleranceTemplate,
                            editor: toleranceEditor,
                            format: "{0:n0}",
                            width: 60,
                            attributes: { style: "text-align: right" },
                            headerAttributes: { "class": "align-center" }
                        },
                    ]
                },
                {
                    field: "flexMonthsTolerance",
                    title: "{{ 'flexMonthTolerance' | translate }}",
                    template: flexMonthsToleranceTemplate,
                    editor: monthEditor,
                    format: "{0:n0}",
                    width: 60,
                    attributes: { style: "text-align: right" },
                    headerAttributes: { "class": "align-center" }
                },
            ],
            editable: !$ctrl.isFordAlert,
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: true,
            reorderable: false,
            filterable: false
        }
    }

    function mockGetDealerTolerances(dealerId, makeId, year, modelId, trimId) {
        var result = [
            {
                trimID: 454559,
                trimFullName: "A 220W",

                leaseAlertToleranceOverride: null,
                retailAlertToleranceOverride: null,
                leaseFlexAlertToleranceOverride: null,
                retailFlexAlertToleranceOverride: null,
                flexMonthsToleranceOverride: null,

                leaseAlertToleranceDefault: 120,
                retailAlertToleranceDefault: 120,
                leaseFlexAlertToleranceDefault: 110,
                retailFlexAlertToleranceDefault: 110,
                flexMonthsToleranceDefault: 12,
                modifiedDate: "2019-07-15T22:44:03.313Z",
                modifiedBy: 150092819

            },
            {
                trimID: 454560,
                trimFullName: "A 220W 4MATIC",

                leaseAlertToleranceOverride: null,
                retailAlertToleranceOverride: null,
                leaseFlexAlertToleranceOverride: null,
                retailFlexAlertToleranceOverride: null,
                flexMonthsToleranceOverride: 48,

                leaseAlertToleranceDefault: 120,
                retailAlertToleranceDefault: 120,
                leaseFlexAlertToleranceDefault: 110,
                retailFlexAlertToleranceDefault: 110,
                flexMonthsToleranceDefault: 12,
                modifiedDate: "2019-07-15T22:44:03.313Z",
                modifiedBy: 150092819
            }
        ];

        return Promise.resolve(result);
    }

    function loadTolerances() {
        var makeID = $ctrl.trimSelections.makeID;
        var yearID = $ctrl.trimSelections.yearID;
        var modelID = $ctrl.trimSelections.modelID ? $ctrl.trimSelections.modelID : 0;
        var trimID = $ctrl.trimSelections.trimID ? $ctrl.trimSelections.trimID : 0;

        return busy(
            "Loading Tolerances",
            //mockGetDealerTolerances($ctrl.selectedDealerId, makeID, yearID, modelID, trimID)
            dealerService.getDealerAlertTolerances($ctrl.selectedDealerId, makeID, yearID, modelID, trimID)
                .then((dataTolerances) => {

                    $ctrl.kendoGrid = $("#gridDataEntryAlerts").data("kendoGrid");
                    $ctrl.kendoGrid.setOptions({
                        editable: !$ctrl.isFordAlert
                    });

                    $ctrl.ds = new $ctrl.kendo.data.DataSource({
                        data: dataTolerances,
                        schema: {
                            model: {
                                fields: {
                                    trimFullName: { type: "string", editable: false },
                                    leaseAlertTolerance: { type: "number", editable: true },
                                    retailAlertTolerance: { type: "number", editable: true },
                                    leaseFlexAlertTolerance: { type: "number", editable: true },
                                    retailFlexAlertTolerance: { type: "number", editable: true },
                                    flexMonthsTolerance: { type: "number", editable: true },
                                }
                            }
                        },
                        batch: true,
                        pageSize: $ctrl.gridPageSize
                    });

                    switch ($ctrl.cultureName) {
                        case cultures.enUS:
                        case cultures.enGB:
                        case cultures.enCA:
                        case cultures.frCA:
                        case cultures.frFR:
                        case cultures.esUS:
                            $ctrl.kendo.culture($ctrl.cultureName);
                            break;
                        default:
                            $ctrl.kendo.culture(cultures.enUS);
                            break;
                    }

                    $ctrl.showQuickAction = false;
                    clearQuickAction();
                })
        );
    }

    function toleranceEditor(container, options) {
        $('<input data-bind="value:' + options.field + '"/>')
            .appendTo(container)
            .kendoNumericTextBox({
                decimals: 0,
                min: $ctrl.minTolerance,
                max: $ctrl.maxTolerance,
                restrictDecimals: true,
                format: "n0"
            });
    }

    function monthEditor(container, options) {
        $('<input data-bind="value:' + options.field + '"/>')
            .appendTo(container)
            .kendoNumericTextBox({
                decimals: 0,
                min: $ctrl.minMonth,
                max: $ctrl.maxMonth,
                restrictDecimals: true,
                format: "n0"
            });
    }

    function leaseAlertToleranceTemplate(e) {

        if (!e.leaseAlertTolerance) {
            if (!e.initAlertLeaseSet) {
                e.initAlertLeaseSet = true;
                e.leaseAlertTolerance = e.leaseAlertToleranceOverride != null ? e.leaseAlertToleranceOverride : e.leaseAlertToleranceDefault;
            }
            else
                e.leaseAlertTolerance = e.leaseAlertToleranceDefault;
        }

        if (e.leaseAlertTolerance >= $ctrl.minTolerance && e.leaseAlertTolerance <= $ctrl.maxTolerance) {
            var isBold = (e.leaseAlertTolerance != e.leaseAlertToleranceDefault);
            var classes = "edit-box";
            if (isBold)
                classes = classes + " override";
            return "<div class='" + classes + "'>" + $ctrl.kendo.toString(e.leaseAlertTolerance / 100, 'p0') + "</div>";
        }
        else
            return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.leaseAlertTolerance / 100, 'p0') + "</div>";
    }

    function retailAlertToleranceTemplate(e) {
        if (!e.retailAlertTolerance) {
            if (!e.initAlertRetailSet) {
                e.initAlertRetailSet = true;
                e.retailAlertTolerance = e.retailAlertToleranceOverride != null ? e.retailAlertToleranceOverride : e.retailAlertToleranceDefault;
            }
            else
                e.retailAlertTolerance = e.retailAlertToleranceDefault;
        }

        if (e.retailAlertTolerance >= $ctrl.minTolerance && e.retailAlertTolerance <= $ctrl.maxTolerance) {
            var isBold = (e.retailAlertTolerance != e.retailAlertToleranceDefault);
            var classes = "edit-box";
            if (isBold)
                classes = classes + " override";
            return "<div class='" + classes + "'>" + $ctrl.kendo.toString(e.retailAlertTolerance / 100, 'p0') + "</div>";
        }
        else
            return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.retailAlertTolerance / 100, 'p0') + "</div>";
    }

    function leaseFlexAlertToleranceTemplate(e) {
        if (!e.leaseFlexAlertTolerance) {
            if (!e.initFlexAlertLeaseSet) {
                e.initFlexAlertLeaseSet = true;
                e.leaseFlexAlertTolerance = e.leaseFlexAlertToleranceOverride != null ? e.leaseFlexAlertToleranceOverride : e.leaseFlexAlertToleranceDefault;
            }
            else
                e.leaseFlexAlertTolerance = e.leaseFlexAlertToleranceDefault;
        }

        if (e.leaseFlexAlertTolerance >= $ctrl.minTolerance && e.leaseFlexAlertTolerance <= $ctrl.maxTolerance) {
            var isBold = (e.leaseFlexAlertTolerance != e.leaseFlexAlertToleranceDefault);
            var classes = "edit-box";
            if (isBold)
                classes = classes + " override";
            return "<div class='" + classes + "'>" + $ctrl.kendo.toString(e.leaseFlexAlertTolerance / 100, 'p0') + "</div>";
        }
        else
            return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.leaseFlexAlertTolerance / 100, 'p0') + "</div>";
    }

    function retailFlexAlertToleranceTemplate(e) {
        if (!e.retailFlexAlertTolerance) {
            if (!e.initFlexAlertRetailSet) {
                e.initFlexAlertRetailSet = true;
                e.retailFlexAlertTolerance = e.retailFlexAlertToleranceOverride != null ? e.retailFlexAlertToleranceOverride : e.retailFlexAlertToleranceDefault;
            }
            else
                e.retailFlexAlertTolerance = e.retailFlexAlertToleranceDefault;
        }

        if (e.retailFlexAlertTolerance >= $ctrl.minTolerance && e.retailFlexAlertTolerance <= $ctrl.maxTolerance) {
            var isBold = (e.retailFlexAlertTolerance != e.retailFlexAlertToleranceDefault);
            var classes = "edit-box";
            if (isBold)
                classes = classes + " override";
            return "<div class='" + classes + "'>" + $ctrl.kendo.toString(e.retailFlexAlertTolerance / 100, 'p0') + "</div>";
        }
        else
            return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.retailFlexAlertTolerance / 100, 'p0') + "</div>";
    }

    function flexMonthsToleranceTemplate(e) {
        if (!e.flexMonthsTolerance) {
            if (!e.initFlexMonthSet) {
                e.initFlexMonthSet = true;
                e.flexMonthsTolerance = e.flexMonthsToleranceOverride != null ? e.flexMonthsToleranceOverride : e.flexMonthsToleranceDefault;
            }
            else
                e.flexMonthsTolerance = e.flexMonthsToleranceDefault;
        }

        if (e.flexMonthsTolerance >= $ctrl.minMonth && e.flexMonthsTolerance <= $ctrl.maxMonth) {
            var isBold = (e.flexMonthsTolerance != e.flexMonthsToleranceDefault);
            var classes = "edit-box";
            if (isBold)
                classes = classes + " override";
            return "<div class='" + classes + "'>" + $ctrl.kendo.toString(e.flexMonthsTolerance, 'n0') + "</div>";
        }
        else
            return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.flexMonthsTolerance, 'n0') + "</div>";
    }
}
