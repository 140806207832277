SearchExportEpsilonModalController.$inject = ["$element", "$scope", "$sce", "legacy", "$logFactory", "searchService"];

/* injects from baggage-loader */
require('./search-export-epsilon-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url";
import { ChildMessagePort } from '../../utilities/messaging';
import './search-export-epsilon-modal.component.scss';
angular.module('AaApp.Opp').component('searchExportEpsilonModal', {
  templateUrl: '/app/components/search/search-export-epsilon-modal.component.html',
  controller: SearchExportEpsilonModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function SearchExportEpsilonModalController($element, $scope, $sce, legacy, $logFactory, searchService) {
  var $ctrl = this;
  var log = $logFactory.get();
  $ctrl.submit = submit;
  $ctrl.$onInit = onInit;
  $ctrl.requestsInWindow;
  $ctrl.showHistory;
  $ctrl.showError;
  $ctrl.campaignType;
  $ctrl.campaignTypeName;
  $ctrl.campaignTypeTranslateKey = campaignTypeTranslateKey;

  function campaignTypeTranslateKey(input) {
    if (input.toLowerCase() == 'call center') {
      return 'exportCampaignType_callCenter';
    } else {
      return 'exportCampaignType_' + input.toLowerCase();
    }
  }

  function onInit() {
    $ctrl.showHistory = true;
    $ctrl.showError = false;
    $ctrl.campaignType = "0";
    $ctrl.campaignTypeName = "";
    searchService.getDealerCampaignRequestsInWindow().then(function (windowData) {
      $ctrl.requestsInWindow = windowData;
    });
  }

  function submit() {
    if (isInputValid()) {
      $ctrl.close({
        $value: {
          success: true,
          campaignType: $ctrl.campaignType,
          campaignTypeName: $ctrl.campaignTypeName
        }
      });
    } else {
      $ctrl.showError = true;
    }
  }

  function isInputValid() {
    switch ($ctrl.campaignType) {
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
        return true;
    }

    return false;
  }
}