"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
require('./admin.scss');
var AdminRolesSelectorComponent = /** @class */ (function () {
    function AdminRolesSelectorComponent() {
        this.onSaveChanges = new core_1.EventEmitter();
        this.onCancelChanges = new core_1.EventEmitter();
        this.accessRightChanged = new core_1.EventEmitter();
        this.accessList = '';
        this.categories = '';
        this.hideCategoryCheckbox = false;
        this.readOnly = false;
        this.ICONS = {
            "editRights": "https://assets.autoalert.com/img/autoalert-icons/icon-permissions-edit-lake.svg ",
            "viewRights": "https://assets.autoalert.com/img/autoalert-icons/icon-permissions-view-lake.svg",
            "actions": "https://assets.autoalert.com/img/autoalert-icons/icon-check-mark-lake.svg",
            "opportunities": require("../../images/icons/alert-blocks.svg"),
            "employees": require("../../images/icons/icon-usergroup.png"),
            "alertDesk": "https://assets.autoalert.com/img/autoalert-icons/icon-alert-blocks-no-text.svg",
            "inventoryDashboard": "https://assets.autoalert.com/img/autoalert-icons/icon-pre-owned-lake.svg",
            "reports": "https://assets.autoalert.com/img/autoalert-icons/icon-AO-reports-lake.svg",
            "dataEntry": "https://assets.autoalert.com/img/autoalert-icons/icon-data-entry-lake.svg",
            "admin": "https://assets.autoalert.com/img/autoalert-icons/icon-product-system-admin-lake.svg",
            "adminRoles-miscellaneous": "https://assets.autoalert.com/img/autoalert-icons/icon-folder-lake.svg"
        };
    }
    AdminRolesSelectorComponent.prototype.onItemChecked = function (item) {
        if (!item.allow) {
            item.forEach(function (value) {
                value.allow = item.allow;
            });
        }
        this.accessRightChanged.emit(true);
    };
    AdminRolesSelectorComponent.prototype.onSubItemChecked = function (item, subitem) {
        if (subitem.allow)
            item.allow = subitem.allow;
        this.accessRightChanged.emit(true);
    };
    AdminRolesSelectorComponent.prototype.onSave = function () {
        this.accessRightChanged.emit(false);
        this.onSaveChanges.emit();
    };
    AdminRolesSelectorComponent.prototype.onCancel = function () {
        this.accessRightChanged.emit(false);
        this.onCancelChanges.emit();
    };
    AdminRolesSelectorComponent.prototype.filterSubItemsByCategoryId = function (categoryId, items) {
        if (items != null) {
            var result = items.slice(0).filter(function (i) { return i.categoryId == categoryId; });
            return result;
        }
        return [];
    };
    return AdminRolesSelectorComponent;
}());
exports.AdminRolesSelectorComponent = AdminRolesSelectorComponent;
