import { OnInit, Component, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomerWorkflowService } from '../../../services/customer-workflow.service';
import { StartListCustomerDto } from "../../../generated/models/start-list-customer-dto";
import { WorkflowTemplateData } from "../../../generated/models/workflow-template-data";
import { Top5ProcessesData } from "../../../generated/models/top-5processes-data";
import { BsModalRef } from 'ngx-bootstrap/modal';
//import { PandoForm } from '../../../interfaces/pando-form.interface';
import { PandoFormControlType } from '../../../interfaces/pando-form-control.interface';
import { PandoFormRequestDto } from '../../../generated/models/pando-form-request-dto';
import moment = require('moment');
import { PandoFormDto } from "../../../generated/models/pando-form-dto";
import { PandoFormControlDto } from "../../../generated/models/pando-form-control-dto";
import { PandoFormControlOptionDto } from "../../../generated/models/pando-form-control-option-dto";
import { Options } from 'ng5-slider';
import { LocaleDateTimePipe } from '../../../filters/locale-date-time.pipe';

@Component({
    selector: 'deal-sheet-process',
    templateUrl: './deal-sheet-process.component-ng.html',
    styleUrls: ["./deal-sheet-process.component.scss"]
})
export class DealSheetProcessComponent {
    constructor(public _bsModalRef: BsModalRef,
                public customerWorkflowService: CustomerWorkflowService,
                public tService: TranslateService,
                private localeDatetimePipe: LocaleDateTimePipe) {
    }

    @Input() entityId: string;
    @Output() onClosed = new EventEmitter();

    public formReady: boolean = false;
    public formLoaded: boolean = false;
    public startListCustomer: StartListCustomerDto;
    public selectedWorkFlowTemplate: WorkflowTemplateData;
    public workFlowTemplates: WorkflowTemplateData[];
    public top5Recent: Top5ProcessesData[];
    public busy: Promise<any>;
    public startButtonEnabled: boolean = false;
    public showLoadFormButton = false;

    public processSettings = {};
    public multiListSettings = {};
    public displayForm: boolean = false;
    public processForm: PandoFormDto = null;
    public formControlType = PandoFormControlType;
    public workflowFormAnswers: PandoFormRequestDto = null;
    public sliderOptions: Record<string, Options> = {};

    get processesAvailable(): boolean {
        return this.workFlowTemplates.length > 0;
    }

    get top5ProcessesAvailable(): boolean {
        return this.top5Recent.length > 0;
    }

    public ngOnInit(): void {
        this.processSettings = {
            start: this.tService.instant('start'),
            cancel: this.tService.instant('cancel')
        };  

        this.multiListSettings = {
            singleSelection: false,
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
            badgeShowLimit: 4,
            labelKey: "label",
            primaryKey: "value"
        };  

        this.customerWorkflowService.getStartListCustomer(this.entityId).then(result => {
            this.workFlowTemplates = result.workflowTemplates;
            this.top5Recent = result.top5Recent;

            if (this.workFlowTemplates.length > 0) {
                this.selectedWorkFlowTemplate = this.workFlowTemplates[0];
                this.workflowTemplateSelect();
            }

            this.formReady = true;
        });
    }

    public onTop5Clicked(item: Top5ProcessesData) {
        var selectedWorkflowTemplate = this.workFlowTemplates.find(t => t.id == item.workflowtemplateId);
        if (selectedWorkflowTemplate != null) {
            this.selectedWorkFlowTemplate = selectedWorkflowTemplate;
            this.workflowTemplateSelect();
        }
    }

    public workflowTemplateSelect(): void {
        this.formLoaded = false;
        this.startButtonEnabled = !this.selectedWorkFlowTemplate.completeFormOnStart;
        this.showLoadFormButton = this.selectedWorkFlowTemplate.completeFormOnStart;
        this.processForm = null;
    }

    public loadForm() {
        // get form data for selected workflow template if it has a form:
        if (this.selectedWorkFlowTemplate && this.selectedWorkFlowTemplate.startableFormTemplateId) {
            this.busy = this.customerWorkflowService.GetStartFormByStartableformtemplateid(this.selectedWorkFlowTemplate.startableFormTemplateId).then(result => {
                this.processForm = result;
                this.sliderOptions = {};

                for (let control of this.processForm.formControls) {
                    if (control.controlType === PandoFormControlType.LinearScale) {
                        let options: Options = {
                            floor: control.minValue,
                            ceil: control.maxValue,
                            getLegend: (value: number): string => {
                                switch (value) {
                                    case control.minValue:
                                        return control.minLabel === control.minValue.toString() ? "" : control.minLabel;
                                    case control.maxValue:
                                        return control.maxLabel === control.maxValue.toString() ? "" : control.maxLabel;
                                    default:
                                        return "";
                                }
                            }
                        };

                        // if the range of values is 15 or less show ticks:
                        if ((options.ceil - options.floor) <= 15) {
                            options.showTicks = true;
                        }

                        this.sliderOptions[control.formControlId] = options;
                    }
                }

                this.showLoadFormButton = false;
                this.startButtonEnabled = true;
                this.formLoaded = true;

            });

        }
    }

    public startWorkflow() {
        this.startButtonEnabled = false;

        this.busy = this.customerWorkflowService.startCustomerWorkflowByEntityidByWorkflowtemplateid(this.entityId, this.selectedWorkFlowTemplate.id, this.workflowFormAnswers).then(result => {
            var isSuccess = false;

            if (result != null && result.workflowInstanceId)
                isSuccess = true;

            this.onClosed.emit({ started: isSuccess });
            this._bsModalRef.hide();
        });
    }

    public onCancel(): void {
        this.onClosed.emit({ started: null });
        this._bsModalRef.hide();
    }

    public convertAnswers() {

        this.workflowFormAnswers = {
            formTemplateId: this.processForm.formTemplateId,
            answers: []
        }
        this.workflowFormAnswers.answers = this.processForm.formControls
            .map((control: PandoFormControlDto) => {

                let valuesList: string[] = null;
                if (control.controlType === PandoFormControlType.MultiSelectList) {
                    if (control.values && control.values.length > 0) {
                        if (control.values.length > 0) {
                            valuesList = control.values.map((selection: PandoFormControlOptionDto) => selection.value);
                        }
                        else {
                            valuesList = [];
                        }
                    }
                }

                if (control.controlType === PandoFormControlType.Date) {
                    if (control.value) {
                        const dateWithOffset = new Date(this.localeDatetimePipe.transform(new Date(control.value)));
                        control.value = moment(dateWithOffset).format();
                    }
                }

                if (control.controlType === PandoFormControlType.Time) {
                    if (control.value) {
                        control.value = moment(control.value).format('hh:mm A');
                    }
                }

                return {
                    formControlId: control.formControlId,
                    inputType: control.inputType,
                    value: control.value === undefined || (control.otherValue && control.otherValue.length > 0)  ? null : control.value,
                    values: valuesList,
                    otherValue: (control.otherValue === undefined || control.otherValue === null || control.otherValue.length === 0) ? null : control.otherValue
                };
            });

        //console.log(this.workflowFormAnswers);
    }

    public setDate(date: Date, formControlId: string) {
        let control = this.processForm.formControls.find((field: PandoFormControlDto) => field.formControlId === formControlId);
        if (control) {
            control.value = moment(date).format('MM/DD/YYYY');
        }
    }

    public setRadioSelection(formControlId: string, index: number) {
        let control = this.processForm.formControls.find((field: PandoFormControlDto) => field.formControlId === formControlId);
        if (control) {
            control.value = control.selectOptions[index].value;
        }
    }

    public submitForm(submitProcessForm: NgForm): void {
        if (submitProcessForm.valid) {
            this.startButtonEnabled = false;

            if (this.selectedWorkFlowTemplate.completeFormOnStart && this.processForm != null) {
                this.convertAnswers();
            }

            this.startWorkflow();
        }
    }
}