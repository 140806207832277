<div class="row">
    <div class="col-md-10">
        <div class="settingTitle" translate="dsAdmin_usedAlertsCalculation"></div>
        <tooltip [titleKey]="'dsAdmin_usedAlertsCalculation'" [contentKey]="'dsAdmin_usedAlertsCalculationTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-2">
                <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.enableUsedAlerts" /> {{ 'dsAdmin_enableUsedAlerts' | translate }}</label>
            </div>
            <div class="col-md-3">
                <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.usedOverNewPreference" /> {{ 'dsAdmin_preferUsedToUsedMappings' | translate }}</label>
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div *ngIf="dsa.isPreOwnedFeatureEnabled">
            <div class="settingTitle below" translate="dsAdmin_usedInventoryPriceAdjustmenet"></div>
            <tooltip [titleKey]="'dsAdmin_usedInventoryPriceAdjustmenet'" [contentKey]="'dsAdmin_usedInventoryPriceAdjustmenetTooltip'"></tooltip>
            <div class="settingItem">
                {{ 'dsAdmin_adjustUsedInventoryPriceByAmount' | translate }} <input name="DealerUsedPriceAdjust.UsedPriceAdjustPercent" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerUsedPriceAdjust.usedPriceAdjustPercent" /> {{ 'dsAdmin_andOrByAmount' | translate }} <input name="DealerUsedPriceAdjust.UsedPriceAdjustDollar" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerUsedPriceAdjust.usedPriceAdjustDollar" />
            </div>
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_usedInventoryTurnSettings"></div>
            <tooltip [titleKey]="'dsAdmin_usedInventoryTurnSettings'" [contentKey]="'dsAdmin_usedInventoryTurnSettingsTooltip'"></tooltip>
            <div class="row settingItem">
                <div class="col-md-2" translate="dsAdmin_usedInventoryTurn">
                </div>
                <div class="co-md-2">
                    <input name="DealerSetting.InventoryTurn" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.inventoryTurn" /> {{ ('days' | translate).toLowerCase() }}
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2" translate="dsAdmin_wholeSalesNoticeAge">
                </div>
                <div class="co-md-2">
                    <input name="DealerSetting.WholeSaleBegin" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.wholeSaleBegin" /> {{ ('days' | translate).toLowerCase() }}
                </div>
            </div>
            <div class="row settingItem">
                <div class="col-md-2" translate="dsAdmin_freshInventoryAge">
                </div>
                <div class="co-md-2">
                    <input name="DealerSetting.FreshInvEnd" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerSetting.freshInvEnd" /> {{ ('days' | translate).toLowerCase() }}
                </div>
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div *ngIf="dsa.isPreOwnedFeatureEnabled">
            <div class="settingTitle" translate="dsAdmin_grossProfitFormatSettings"></div>
            <tooltip [titleKey]="'dsAdmin_grossProfitFormatSettings'" [contentKey]="'dsAdmin_grossProfitFormatSettingsTooltip'"></tooltip>
            <div class="row settingItem">
                <div class="col-md-3" translate="dsAdmin_showMyTopUsedOpportunitiesBy">
                </div>
                <div class="col-md-2">
                    <select class="form-control" [(ngModel)]="dsa.dealerSetting.grossProfitFormat">
                        <option value="1">{{ 'gross' | translate }} $</option>
                        <option value="2">{{ 'gross' | translate }} %</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="dsAdmin_replacementVehicleSorting"></div>
        <tooltip [titleKey]="'dsAdmin_replacementVehicleSorting'" [contentKey]="'dsAdmin_replacementVehicleSortingTooltip'"></tooltip>
        <div class="settingItem">
            <div style="float:left">
                <select multiple="multiple" style="height: 70px;width:200px" [(ngModel)]="rvsSelectedItem">
                    <option *ngFor="let item of dsa.dealerInventoryMatchRankingField" [ngValue]="item">{{ item.inventoryMatchRankingFieldTypeName }}</option>
                </select>
            </div>
            <div style="float:left">
                <div>
                    <button type="button" class="btn-up" (click)="rvsMove('+')"></button>
                </div>
                <div>
                    <button type="button" class="btn-down" (click)="rvsMove('-')"></button>
                </div>
            </div>
        </div>
    </div>
</div>