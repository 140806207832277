import "core-js/modules/es6.array.find";
SearchReassignModalController.$inject = ["$element", "$scope", "$sce", "$uibModal", "$document", "legacy", "urlHelper"];

/* injects from baggage-loader */
require('./search-reassign-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url";
import { ChildMessagePort } from '../../utilities/messaging';
import './search-reassign-modal.component.scss';
angular.module('AaApp.Opp').component('searchReassignModal', {
  templateUrl: '/app/components/search/search-reassign-modal.component.html',
  controller: SearchReassignModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function SearchReassignModalController($element, $scope, $sce, $uibModal, $document, legacy, urlHelper) {
  var $ctrl = this;
  $ctrl.submit = submit;
  $ctrl.$onInit = onInit;
  var originalIframeUrl = legacy.iframeUrl + "/AlertDesk/Search/SearchReassign";
  $ctrl.selectedDealerId;
  $ctrl.iframeUrl;
  $ctrl.dealsheetDomain;

  function onInit() {
    var rendered = $ctrl.resolve.data.rendered;

    if ($ctrl.resolve.data.selectedDealerId === undefined) {
      $ctrl.selectedDealerId = 0;
    } else {
      $ctrl.selectedDealerId = $ctrl.resolve.data.selectedDealerId;
    }

    $ctrl.dealsheetDomain = $ctrl.resolve.data.dealsheetDomain;
    $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeUrl + "?dealerID=" + $ctrl.selectedDealerId + "&ds=" + $ctrl.dealsheetDomain);
    rendered.then(function () {
      var iframe = $element.find(".ifrmSearchReassign")[0];
      var origin = getUrlComponents(originalIframeUrl).origin;
      $ctrl.messageChannel = new ChildMessagePort(iframe, origin, $scope);
      $ctrl.messageChannel.subscribe(function (_ref, replyPort) {
        var data = _ref.data;

        if (data.type === "SEARCH_REASSIGN_SELECTED_USERS") {
          $ctrl.close({
            $value: {
              assignedUsers: data.data.assignedUsers
            }
          });
        }
      });
    });
  }

  function submit() {
    var msg = {
      type: "SEARCH_SUBMIT_REASSIGN",
      data: {
        selectedDealerId: $ctrl.selectedDealerId
      }
    };
    $ctrl.messageChannel.postMessage(msg);
  }
}