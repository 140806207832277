/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetStringAsyncRequest } from '../models/get-string-async-request';
import { KeyValuePairStringString } from '../models/key-value-pair-string-string';
import { PandoGroupDto } from '../models/pando-group-dto';
import { GetFullConversicaConversationResponseDto } from '../models/get-full-conversica-conversation-response-dto';
import { ConversicaConversationStatus } from '../models/conversica-conversation-status';
@Injectable({
  providedIn: 'root',
})
class PandoPassthroughClientService extends __BaseService {
  static readonly GetStringPOSTPath = '/api/PandoPassthrough/GetString';
  static readonly GetWorkflowTemplatesByDealeridGETPath = '/api/PandoPassthrough/GetWorkflowTemplates/{dealerId}';
  static readonly GetPandoCustomStoreGroupsByDealeridGETPath = '/api/PandoPassthrough/GetPandoCustomStoreGroups/{dealerId}';
  static readonly AutoAssistFullViewByDealeridByEntityidGETPath = '/api/PandoPassthrough/AutoAssistFullView/{dealerId}/{entityId}';
  static readonly GetConversicaConversationStatusesByDealeridByEntityidGETPath = '/api/PandoPassthrough/GetConversicaConversationStatuses/{dealerId}/{entityId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  GetStringPOSTResponse(request?: GetStringAsyncRequest): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PandoPassthrough/GetString`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  GetStringPOST(request?: GetStringAsyncRequest): __Observable<string> {
    return this.GetStringPOSTResponse(request).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetWorkflowTemplatesByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<KeyValuePairStringString>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PandoPassthrough/GetWorkflowTemplates/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<KeyValuePairStringString>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetWorkflowTemplatesByDealeridGET(dealerId: number): __Observable<Array<KeyValuePairStringString>> {
    return this.GetWorkflowTemplatesByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<KeyValuePairStringString>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetPandoCustomStoreGroupsByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<PandoGroupDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PandoPassthrough/GetPandoCustomStoreGroups/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PandoGroupDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetPandoCustomStoreGroupsByDealeridGET(dealerId: number): __Observable<Array<PandoGroupDto>> {
    return this.GetPandoCustomStoreGroupsByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<PandoGroupDto>)
    );
  }

  /**
   * @param params The `PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  AutoAssistFullViewByDealeridByEntityidGETResponse(params: PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETParams): __Observable<__StrictHttpResponse<GetFullConversicaConversationResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PandoPassthrough/AutoAssistFullView/${params.dealerId}/${params.entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFullConversicaConversationResponseDto>;
      })
    );
  }

  /**
     * @param params The `PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `dealerId`:
     *
     * @return Success List of status objects
  */
    GetConversicaConversationStatusesByDealeridByEntityidGETResponse(params: PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETParams): __Observable<__StrictHttpResponse<Array<ConversicaConversationStatus>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/PandoPassthrough/GetConversicaConversationStatuses/${params.dealerId}/${params.entityId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<ConversicaConversationStatus>>;
            })
        );
    }
   /**
   * @param params The `PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `dealerId`:
   *
   * @return Success
   */
  AutoAssistFullViewByDealeridByEntityidGET(params: PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETParams): __Observable<GetFullConversicaConversationResponseDto> {
    return this.AutoAssistFullViewByDealeridByEntityidGETResponse(params).pipe(
      __map(_r => _r.body as GetFullConversicaConversationResponseDto)
    );
  }
  /**
  * @param params The `PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETParams` containing the following parameters:
  *
  * - `entityId`:
  *
  * - `dealerId`:
  *
  * @return Success as a list of status objects
  */
    GetConversicaConversationStatusesByDealeridByEntityidGET(params: PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETParams): __Observable<Array<ConversicaConversationStatus>> {
        return this.GetConversicaConversationStatusesByDealeridByEntityidGETResponse(params).pipe(
            __map(_r => _r.body as Array<ConversicaConversationStatus>)
    );
  }
}

module PandoPassthroughClientService {

  /**
   * Parameters for AutoAssistFullViewByDealeridByEntityidGET
   */
  export interface AutoAssistFullViewByDealeridByEntityidGETParams {
    entityId: string;
    dealerId: number;
    }
  export interface GetConversicaConversationStatusesByDealeridByEntityidGETParams {
    entityId: string;
    dealerId: number;
  }
}

export { PandoPassthroughClientService }
