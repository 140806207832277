"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityResults = {
    phoneCall: {
        scheduledAppointment: 1,
        requestedCallBack: 2,
        requestedDoNotCall: 3,
        leftMessage: 4,
        noAnswer: 5,
        notInterested: 6,
        soldVehicle: 7,
        spokeToCustomer: 19,
        badPhoneNumber: 26,
        interested: 27,
        noLongerOwns: 28,
        miscellaneous: 32,
        vehiclePaidOff: 33
    },
    appointment: {
        customerDidNotShow: 8,
        showedClosed: 9,
        showedOpen: 10,
        soldVehicle: 11,
        vehiclePaidOff: 33
    },
    note: {
        scheduledAppointment: 20,
        spokeToCustomer: 21,
        upgradeProposal: 22,
        soldVehicle: 23,
        notInterested: 24,
        miscellaneous: 25,
        oneToOnePrintedOffer: 29,
        testDriveDone: 30,
        noLongerOwns: 31,
        vehiclePaidOff: 33
    }
};
