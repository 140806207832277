import angular from 'angular';

var basePath = '/app/i18n/'

angular.module('AaApp.Opp')
    .constant('localePaths', {
        activity: basePath + 'activity',
        dashboard: basePath + 'dashboard',
        dealSheet: basePath + 'deal-sheet',
        error: basePath + 'error',
        featureComparison: basePath + 'feature-comparison',
        general: basePath + 'general',
        header: basePath + 'header',
        oneToOne: basePath + 'one-to-one-activities',
        scripts: basePath + 'scripts',
        serviceDrive: basePath + 'service-drive',
        subMenu: basePath + 'sub-menu',
        recentDealSheets: basePath + 'recent-deal-sheets',
        userProfile: basePath + 'user-profile'
    });
