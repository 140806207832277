<div class="field row">
    <label class="field-label" for="price" translate="priceLabel"></label>
    <span class="field-data">
        <span id="price" class="qa-price-value" *ngIf="!permissions?.editPrice || readonly">{{ value | localeCurrency }}</span>
        <span *ngIf="permissions?.editPrice && !readonly">
            <span translate="currencySymbol"></span>
            <input [id]="price" [name]="price" class="form-control input-sm editable qa-price-value" type="text" [(ngModel)]="value" (change)="update(value)" />
            <a class="edit-icon qa-price-recalc-link" href="#" title="{{ 'recalculatedealsheet' | translate }}" alt="recalculatedealsheet" (click)="onValueChange(value)">
                <icon class="icon aa-icon-refresh"></icon>
            </a>
        </span>
    </span>
</div>

