import { Component, OnChanges, SimpleChanges, ElementRef, Injector, Input } from '@angular/core';
import { SaleTypes, ConquestTypes } from '../../../../constants';

@Component({
    selector: 'sale-type',
    templateUrl: './sale-type.component-ng.html',
    styleUrls: ['./sale-type.component.scss']
})
export class SaleTypeComponent implements OnChanges {
    @Input() value: any;
    @Input() conquestType: any;
    @Input() saleTypeOriginalLookupId: number;
    public saleTypes = SaleTypes;
    public conquestTypes = ConquestTypes;
    public saleTypeTranslateKeys = Object.keys(SaleTypes);
    public saleTypeOriginalLookupKey: string;

    constructor(elementRef: ElementRef, injector: Injector) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.saleTypeOriginalLookupId) {
            this.saleTypeOriginalLookupKey = 'saleTypeOriginalLookup' + this.saleTypeOriginalLookupId;
        }
    }

}