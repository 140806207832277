/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserSearchCountRequestOutDto } from '../models/user-search-count-request-out-dto';
import { UserSearchConditionDealerLayOutExtDto } from '../models/user-search-condition-dealer-lay-out-ext-dto';
import { ResetAutoAssignmentsDto } from '../models/reset-auto-assignments-dto';
import { UserSearchConditionDto } from '../models/user-search-condition-dto';
@Injectable({
  providedIn: 'root',
})
class UserSearchConditionClientService extends __BaseService {
  static readonly GetSearchesGETPath = '/api/UserSearchCondition/GetSearches';
  static readonly GetSearchModelByUsersearchconditionidGETPath = '/api/UserSearchCondition/GetSearchModel/{userSearchConditionId}';
  static readonly GetSearchCountsPOSTPath = '/api/UserSearchCondition/GetSearchCounts';
  static readonly FlushSearchCountsCacheByLuaeGETPath = '/api/UserSearchCondition/FlushSearchCountsCache/{luae}';
  static readonly GetDealerLayOutExtsGETPath = '/api/UserSearchCondition/GetDealerLayOutExts';
  static readonly GetActiveDealerLayOutExtsGETPath = '/api/UserSearchCondition/GetActiveDealerLayOutExts';
  static readonly ShareUserSearchConditionByUsersearchconditionidPOSTPath = '/api/UserSearchCondition/ShareUserSearchCondition/{userSearchConditionId}';
  static readonly UnshareUserSearchConditionByUsersearchconditionidPOSTPath = '/api/UserSearchCondition/UnshareUserSearchCondition/{userSearchConditionId}';
  static readonly DeleteUserSearchConditionByUsersearchconditionidPOSTPath = '/api/UserSearchCondition/DeleteUserSearchCondition/{userSearchConditionId}';
  static readonly UpdateDealerLayOutsByDealeridPOSTPath = '/api/UserSearchCondition/UpdateDealerLayOuts/{dealerId}';
  static readonly ResetAutoAssignmentsPOSTPath = '/api/UserSearchCondition/ResetAutoAssignments';
  static readonly ExecutePandoXPOSTPath = '/api/UserSearchCondition/ExecutePandoX';
  static readonly ByIdGETPath = '/api/UserSearchCondition/{id}';
  static readonly ByIdDELETEPath = '/api/UserSearchCondition/{id}';
  static readonly GetManyGETPath = '/api/UserSearchCondition/GetMany';
  static readonly PUTPath = '/api/UserSearchCondition';
  static readonly POSTPath = '/api/UserSearchCondition';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  GetSearchesGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/GetSearches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetSearchesGET(): __Observable<null> {
    return this.GetSearchesGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param userSearchConditionId undefined
   */
  GetSearchModelByUsersearchconditionidGETResponse(userSearchConditionId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/GetSearchModel/${userSearchConditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userSearchConditionId undefined
   */
  GetSearchModelByUsersearchconditionidGET(userSearchConditionId: number): __Observable<null> {
    return this.GetSearchModelByUsersearchconditionidGETResponse(userSearchConditionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   */
  GetSearchCountsPOSTResponse(request?: UserSearchCountRequestOutDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/GetSearchCounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  GetSearchCountsPOST(request?: UserSearchCountRequestOutDto): __Observable<null> {
    return this.GetSearchCountsPOSTResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param luae undefined
   */
  FlushSearchCountsCacheByLuaeGETResponse(luae: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/FlushSearchCountsCache/${luae}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param luae undefined
   */
  FlushSearchCountsCacheByLuaeGET(luae: number): __Observable<null> {
    return this.FlushSearchCountsCacheByLuaeGETResponse(luae).pipe(
      __map(_r => _r.body as null)
    );
  }
  GetDealerLayOutExtsGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/GetDealerLayOutExts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetDealerLayOutExtsGET(): __Observable<null> {
    return this.GetDealerLayOutExtsGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  GetActiveDealerLayOutExtsGETResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/GetActiveDealerLayOutExts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetActiveDealerLayOutExtsGET(): __Observable<null> {
    return this.GetActiveDealerLayOutExtsGETResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param userSearchConditionId undefined
   */
  ShareUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/ShareUserSearchCondition/${userSearchConditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userSearchConditionId undefined
   */
  ShareUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId: number): __Observable<null> {
    return this.ShareUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param userSearchConditionId undefined
   */
  UnshareUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/UnshareUserSearchCondition/${userSearchConditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userSearchConditionId undefined
   */
  UnshareUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId: number): __Observable<null> {
    return this.UnshareUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param userSearchConditionId undefined
   */
  DeleteUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/DeleteUserSearchCondition/${userSearchConditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userSearchConditionId undefined
   */
  DeleteUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId: number): __Observable<null> {
    return this.DeleteUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `layouts`:
   */
  UpdateDealerLayOutsByDealeridPOSTResponse(params: UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.layouts;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/UpdateDealerLayOuts/${params.dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTParams` containing the following parameters:
   *
   * - `dealerId`:
   *
   * - `layouts`:
   */
  UpdateDealerLayOutsByDealeridPOST(params: UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTParams): __Observable<null> {
    return this.UpdateDealerLayOutsByDealeridPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param resets undefined
   */
  ResetAutoAssignmentsPOSTResponse(resets?: ResetAutoAssignmentsDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = resets;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/ResetAutoAssignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param resets undefined
   */
  ResetAutoAssignmentsPOST(resets?: ResetAutoAssignmentsDto): __Observable<null> {
    return this.ResetAutoAssignmentsPOSTResponse(resets).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserSearchConditionClientService.ExecutePandoXPOSTParams` containing the following parameters:
   *
   * - `userSearchConditionId`:
   *
   * - `dealerId`:
   */
  ExecutePandoXPOSTResponse(params: UserSearchConditionClientService.ExecutePandoXPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userSearchConditionId != null) __params = __params.set('userSearchConditionId', params.userSearchConditionId.toString());
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition/ExecutePandoX`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserSearchConditionClientService.ExecutePandoXPOSTParams` containing the following parameters:
   *
   * - `userSearchConditionId`:
   *
   * - `dealerId`:
   */
  ExecutePandoXPOST(params: UserSearchConditionClientService.ExecutePandoXPOSTParams): __Observable<null> {
    return this.ExecutePandoXPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<UserSearchConditionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserSearchConditionDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<UserSearchConditionDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as UserSearchConditionDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/UserSearchCondition/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<UserSearchConditionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserSearchCondition/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserSearchConditionDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<UserSearchConditionDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<UserSearchConditionDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: UserSearchConditionDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/UserSearchCondition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: UserSearchConditionDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: UserSearchConditionDto): __Observable<__StrictHttpResponse<UserSearchConditionDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserSearchCondition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserSearchConditionDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: UserSearchConditionDto): __Observable<UserSearchConditionDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as UserSearchConditionDto)
    );
  }
}

module UserSearchConditionClientService {

  /**
   * Parameters for UpdateDealerLayOutsByDealeridPOST
   */
  export interface UpdateDealerLayOutsByDealeridPOSTParams {
    dealerId: number;
    layouts?: Array<UserSearchConditionDealerLayOutExtDto>;
  }

  /**
   * Parameters for ExecutePandoXPOST
   */
  export interface ExecutePandoXPOSTParams {
    userSearchConditionId?: number;
    dealerId?: number;
  }
}

export { UserSearchConditionClientService }
