import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'projected-mileage',
  templateUrl: './projected-mileage.component-ng.html',
  styleUrls: ['./projected-mileage.component.scss']
})
export class ProjectedMileageComponent implements OnInit {
  @Input() value: number;
  @Input() alertExists: boolean;

  constructor() { }

  ngOnInit() {
  }

}
