// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'preferred-equity',
//   templateUrl: './preferred-equity.component.html',
//   styleUrls: ['./preferred-equity.component.scss']
// })
// export class PreferredEquityComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'preferred-equity'
})
export class PreferredEquityComponent extends UpgradeComponent {
    @Input() value: any;
    @Input() saleType: any;
    @Input() isEuroLite: boolean;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('preferredEquity', elementRef, injector);
    }
}