import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
euroLiteGridLayoutSelectorModalController.$inject = ["$translate", "euroOpportunityGridLayout"];

/* injects from baggage-loader */
require('./euro-lite-grid-layout-selector-modal.component.html');

import angular from 'angular';
import _ from 'lodash';
angular.module('AaApp.Opp').component('euroLiteGridLayoutSelectorModal', {
  templateUrl: '/app/components/opportunities/modals/euro-lite-grid-layout-selector-modal.component.html',
  controller: euroLiteGridLayoutSelectorModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
    checkColumnSelectionLimit: '&'
  }
});
/* @ngInject */

function euroLiteGridLayoutSelectorModalController($translate, euroOpportunityGridLayout) {
  var $ctrl = this;
  $ctrl.$onInit = onInit;
  $ctrl.checkColumnSelectionLimit = checkColumnSelectionLimit;
  $ctrl.showLimitMsg = null;
  $ctrl.gridColumns = {};

  function checkColumnSelectionLimit() {
    var count = 0;
    $ctrl.gridColumns.forEach(function (ele) {
      ele.fields.forEach(function (item) {
        if (item.selectable && item.isChecked) count++;
      });
    });

    if (count < 12) {
      $ctrl.showLimitMsg = null;
      $ctrl.close({
        $value: $ctrl.gridColumns
      });
    } else {
      $translate('maxNumberOfColumnsAllowedis').then(function (successMessage) {
        $ctrl.showLimitMsg = successMessage + ' 11';
      });
    }
  }

  function onInit() {
    var gridColumns = _.cloneDeep(euroOpportunityGridLayout);

    var currentColumns = $ctrl.resolve.currentGridColumns;
    gridColumns.forEach(function (ele) {
      ele.fields.forEach(function (item) {
        item.isChecked = currentColumns.indexOf(item.name) > -1 ? true : false;
      });
    });
    $ctrl.gridColumns = gridColumns;
  }
}