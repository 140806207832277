import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.regexp.to-string";
InventorySearchController.$inject = ["vehicleService", "vehicleTypes", "saleTypes", "vehicleSelectColumns", "$filter", "toaster", "$translate"];

/* injects from baggage-loader */
require('./inventory-search.component.html');

import angular from 'angular';
import _ from 'lodash';
angular.module('AaApp.Opp').component('inventorySearch', {
  templateUrl: '/app/components/deal-sheet/vehicle/inventory-search.component.html',
  controller: InventorySearchController,
  bindings: {
    opportunity: '<',
    dealerId: '<',
    defaults: '<',
    onVehicleSelect: '&'
  }
});
/* @ngInject */

function InventorySearchController(vehicleService, vehicleTypes, saleTypes, vehicleSelectColumns, $filter, toaster, $translate) {
  var $ctrl = this;
  var localeCurrencyFilter = $filter('localeCurrency');
  $ctrl.searchPromise;
  $ctrl.vehicles;
  $ctrl.stockNo;
  $ctrl.displayColumns;
  $ctrl.selectedVehicleTypes;
  $ctrl.selectedSaleTypes = [];
  $ctrl.trimSelections;
  $ctrl.usePricingPlan;
  $ctrl.saleTypes = saleTypes;
  $ctrl.setSelectedSaleTypes = setSelectedSaleTypes;
  $ctrl.setSelectedVehicleTypes = setSelectedVehicleTypes;
  $ctrl.setTrimSelections = setTrimSelections;
  $ctrl.searchByStockNo = searchByStockNo;
  $ctrl.searchByTrim = searchByTrim;
  $ctrl.resetFilters = resetFilters;
  $ctrl.$onChanges = onChanges;
  $ctrl.termFilter = {
    min: 24,
    max: 48,
    options: {
      floor: 24,
      ceil: 96,
      step: 3
    }
  };
  $ctrl.paymentFilter = {
    min: 100,
    max: 2000,
    options: {
      floor: 50,
      ceil: 3000,
      step: 50
    }
  };

  function onChanges(changes) {
    if (changes.defaults && changes.defaults.currentValue) {
      setTrimSelections($ctrl.defaults);
      setSelectedVehicleTypes($ctrl.defaults.vehicleTypes);
      setDefaultPaymentFilters($ctrl.defaults.term, $ctrl.defaults.payment, $ctrl.defaults.saleTypes);
      setPricingPlanStatus();
    }
  }

  function setPricingPlanStatus() {
    if ($ctrl.opportunity.customer.pricingPlanId && $ctrl.opportunity.customer.pricingPlanId > 0) {
      $ctrl.usePricingPlan = true;
    } else {
      $ctrl.usePricingPlan = false;
    }
  }

  function setDefaultPaymentFilters(term, payment, saleTypes) {
    if (term) {
      $ctrl.termFilter.min = Math.max($ctrl.termFilter.options.floor, term - 12);
      $ctrl.termFilter.max = Math.min($ctrl.termFilter.options.ceil, term + 12);
    } else {
      $ctrl.termFilter.min = 24;
      $ctrl.termFilter.max = 48;
    }

    if (payment) {
      var floor = $ctrl.paymentFilter.options.floor;
      var ceil = $ctrl.paymentFilter.options.ceil;
      var step = $ctrl.paymentFilter.options.step;

      var validPayments = _.range(floor, ceil + 1, step);

      var lowerBound = findClosestValue(validPayments, payment * 0.90);
      var upperBound = findClosestValue(validPayments, payment * 1.10);
      $ctrl.paymentFilter.min = lowerBound;
      $ctrl.paymentFilter.max = upperBound;
    } else {
      $ctrl.paymentFilter.min = 250;
      $ctrl.paymentFilter.max = 750;
    }

    if (saleTypes) {
      setSelectedSaleTypes(saleTypes.slice());
    } else {
      setSelectedSaleTypes([saleTypes.lease]);
    }

    function findClosestValue(values, candidate) {
      var distances = values.map(function (value) {
        return {
          value: value,
          distance: Math.abs(candidate - value)
        };
      });
      return _.head(_.sortBy(distances, function (v) {
        return v.distance;
      }).map(function (v) {
        return v.value;
      }));
    }
  }

  function resetFilters() {
    $ctrl.vehicles = null; // Replacing the `defaults` object with an identical object triggers
    // the $onChanges event in child components so they reset themselves.

    $ctrl.defaults = _.extend({}, $ctrl.defaults);
    $ctrl.defaults.vehicleTypes = $ctrl.defaults.vehicleTypes.slice();
    setDefaultPaymentFilters($ctrl.defaults.term, $ctrl.defaults.payment, $ctrl.defaults.saleTypes);
    setSelectedSaleTypes($ctrl.defaults.saleTypes);
    setSelectedVehicleTypes($ctrl.defaults.vehicleTypes);
    setTrimSelections($ctrl.defaults);
  }

  function setSelectedVehicleTypes(vehicleTypes) {
    if (vehicleTypes.length == 0) {
      $ctrl.selectedVehicleTypes = [vehicleTypes.new, vehicleTypes.used];
    } else {
      $ctrl.selectedVehicleTypes = vehicleTypes.slice();
    }
  }

  function setSelectedSaleTypes(saleTypes) {
    if (saleTypes) {
      $ctrl.selectedSaleTypes = saleTypes.slice();
    }
  }

  function setTrimSelections(selections) {
    $ctrl.trimSelections = selections;
  }

  function searchByStockNo() {
    if ($ctrl.stockNo) {
      $ctrl.vehicles = null;
      $ctrl.searchPromise = vehicleService.getInventoryVehicle($ctrl.stockNo, $ctrl.opportunity.id, $ctrl.opportunity.customer.pricingPlanId).then(showSearchResults(false));
    }
  }

  function searchByTrim() {
    if ($ctrl.trimSelections) {
      $ctrl.vehicles = null;
      var getNewVehicles = $ctrl.selectedVehicleTypes.indexOf(vehicleTypes.new) >= 0;
      var getUsedVehicles = $ctrl.selectedVehicleTypes.indexOf(vehicleTypes.used) >= 0 || $ctrl.selectedVehicleTypes.indexOf(vehicleTypes.cpo) >= 0;
      var makeIDs = $ctrl.trimSelections.makeID ? [$ctrl.trimSelections.makeID] : [];
      var yearIDs = $ctrl.trimSelections.yearID ? [$ctrl.trimSelections.yearID] : [];
      var modelIDs = $ctrl.trimSelections.modelIDs;

      if (!modelIDs) {
        modelIDs = $ctrl.trimSelections.modelId ? [$ctrl.trimSelections.modelId] : [];
      }

      var trimIDs = $ctrl.trimSelections.trimIDs;

      if (!trimIDs) {
        trimIDs = $ctrl.trimSelections.trimID ? [$ctrl.trimSelections.trimID] : [];
      }

      var params = {
        dealerIds: [$ctrl.dealerId],
        getNewVehicles: getNewVehicles,
        getUsedVehicles: getUsedVehicles,
        makeIDs: makeIDs,
        yearIDs: yearIDs,
        modelIDs: modelIDs,
        trimIDs: trimIDs,
        saleTypes: $ctrl.paymentFiltersEnabled ? $ctrl.selectedSaleTypes : [],
        // empty means both
        minimumTermLength: $ctrl.paymentFiltersEnabled ? $ctrl.termFilter.min : null,
        maximumTermLength: $ctrl.paymentFiltersEnabled ? $ctrl.termFilter.max : null,
        minimumMonthlyPayment: $ctrl.paymentFiltersEnabled ? $ctrl.paymentFilter.min : null,
        maximumMonthlyPayment: $ctrl.paymentFiltersEnabled ? $ctrl.paymentFilter.max : null,
        pricingPlanId: $ctrl.usePricingPlan ? $ctrl.opportunity.customer.pricingPlanId : null
      };
      $ctrl.searchPromise = vehicleService.getInventoryVehicles($ctrl.opportunity, params).then(showSearchResults($ctrl.paymentFiltersEnabled));
    }
  }

  function showWarningMessage(translations) {
    toaster.pop('warning', translations.warningTitle, translations.missingInventoryTrimIDErrorMsg);
  }

  function showSearchResults(displayPaymentColumn) {
    return function showSearchResults(vehicles) {
      if (!vehicles) {
        $ctrl.vehicles = [];
        return;
      }

      if (Object.prototype.toString.call(vehicles) !== '[object Array]') {
        vehicles = [vehicles];
      }

      if (vehicles.length == 1 && vehicles[0].trimID == null) {
        $ctrl.vehicles = [];
        $translate(['warningTitle', 'missingInventoryTrimIDErrorMsg']).then(showWarningMessage);
        return;
      }

      $ctrl.vehicles = vehicles.sort(byDaysInStock);
      $ctrl.displayColumns = [vehicleSelectColumns.stockNo, vehicleSelectColumns.vehicleType, vehicleSelectColumns.trim, vehicleSelectColumns.year, vehicleSelectColumns.price, vehicleSelectColumns.mileage, vehicleSelectColumns.color, vehicleSelectColumns.options, vehicleSelectColumns.daysInStock];

      if (displayPaymentColumn) {
        $ctrl.displayColumns.push(vehicleSelectColumns.payments);
      }
    };
  }

  function byDaysInStock(vehicle1, vehicle2) {
    return vehicle2.daysInStock - vehicle1.daysInStock;
  }
}