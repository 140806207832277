"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
/**
 * Implements the Web Storage API using cookies as the underlying storage.
 *  domain - The domain under which to store the cookie. If not provided, the current page's domain is used.
 *  keyPrefix - A string to prefix cookie names with, to differentiate environments.
 */
var CookieStorage = /** @class */ (function () {
    function CookieStorage(window, domain, keyPrefix) {
        this.window = window;
        this.domain = domain;
        this.keyPrefix = keyPrefix;
        // As we access keys, we learn of their existence. We're assuming that
        // every key we access belongs to us and should be cleared together.
        this.KNOWN_KEYS = new Set();
    }
    /**
     * Retrieves and deserializes an item from the store
     */
    CookieStorage.prototype.getItem = function (key) {
        // Format: cookie1=value1; cookie2=value2
        var cookies = this.window.document.cookie.split(';');
        var prefixedKey = this.addKeyPrefix(key);
        for (var i = 0; i < cookies.length; i++) {
            var pair = cookies[i].split('=');
            var k = pair[0], value = pair[1];
            if (k.trim() === prefixedKey && value) {
                try {
                    this.KNOWN_KEYS.add(key);
                    return JSON.parse(decodeURIComponent(value.trim()));
                }
                catch (e) {
                    // fallthrough and return undefined
                }
            }
        }
    };
    CookieStorage.prototype.setItem = function (key, value) {
        var cookieSafeValue = encodeURIComponent(JSON.stringify(value));
        var cookieValue = this.makeCookieString([
            this.keyValueString(this.addKeyPrefix(key), cookieSafeValue),
            this.domainString(),
            this.pathString(),
            this.secureString(),
        ]);
        this.window.document.cookie = cookieValue;
        this.KNOWN_KEYS.add(key);
    };
    CookieStorage.prototype.removeItem = function (key) {
        var cookieValue = this.makeCookieString([
            this.keyValueString(this.addKeyPrefix(key), ''),
            this.domainString(),
            this.pathString(),
            this.expiresString('Thu, 01 Jan 1970 00:00:01 GMT')
        ]);
        this.window.document.cookie = cookieValue;
        this.KNOWN_KEYS.delete(key);
    };
    CookieStorage.prototype.onchange = function (key, callback) {
        var _this = this;
        var prevValue = this.getItem(key);
        var checkFn = function () {
            var value = _this.getItem(key);
            if (typeof prevValue !== 'undefined' && !lodash_1.isEqual(prevValue, value)) {
                callback({ key: key, value: value });
            }
            prevValue = value;
        };
        this.KNOWN_KEYS.add(key);
        var intervalHandle = this.window.setInterval(checkFn, 5000);
        return { unsubscribe: function () { return _this.window.clearInterval(intervalHandle); } };
    };
    /**
     * Removes all known keys from the store.
    */
    CookieStorage.prototype.clear = function () {
        var _this = this;
        this.KNOWN_KEYS.forEach(function (key) {
            _this.removeItem(key);
        });
    };
    CookieStorage.prototype.addKeyPrefix = function (key) {
        if (this.keyPrefix) {
            return this.keyPrefix + ":" + key;
        }
        return key;
    };
    CookieStorage.prototype.makeCookieString = function (components) {
        return components.filter(function (c) { return !!c; }).join(';');
    };
    CookieStorage.prototype.keyValueString = function (key, value) {
        return key + '=' + value;
    };
    CookieStorage.prototype.expiresString = function (date) {
        return 'expires=' + date;
    };
    CookieStorage.prototype.pathString = function () {
        return 'path=/';
    };
    CookieStorage.prototype.domainString = function () {
        if (this.domain) {
            return 'domain=' + this.domain;
        }
    };
    CookieStorage.prototype.secureString = function () {
        if (this.window.location.protocol === 'https') {
            return 'secure';
        }
    };
    return CookieStorage;
}());
exports.CookieStorage = CookieStorage;
