FinancialsBalloonSaleController.$inject = ["$q", "$timeout", "$document", "$uibModal", "monthlyPaymentAmountKey", "financedAmountKey", "residualKey", "financeFieldPromptTitleKey", "financeFieldPromptMessageKey"];

/* injects from baggage-loader */
require('./financials-balloon-sale.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('financialsBalloonSale', {
  templateUrl: '/app/components/deal-sheet/financial/financials-balloon-sale.component.html',
  controller: FinancialsBalloonSaleController,
  bindings: {
    financials: '<',
    financialForm: '<',
    reset: '<',
    checkValidation: '&'
  }
});
/* @ngInject */

function FinancialsBalloonSaleController($q, $timeout, $document, $uibModal, monthlyPaymentAmountKey, financedAmountKey, residualKey, financeFieldPromptTitleKey, financeFieldPromptMessageKey) {
  var $ctrl = this;
  $ctrl.balloonFields;
  $ctrl.promptActive;
  $ctrl.onBalloonFieldChange = onBalloonFieldChange;
  $ctrl.$onChanges = onChanges;
  $ctrl.$onInit = init;

  function onChanges(changes) {
    if (changes.reset && !changes.reset.isFirstChange()) {
      resetFinancials();
    }
  }

  function init() {
    $ctrl.balloonFields = {
      payment: 1,
      financedAmount: 2,
      residual: 3
    };
    $ctrl.promptActive = false;
    $ctrl.checkValidation();
  }

  $ctrl.calc = function () {
    _doBalloonCalc();

    $ctrl.checkValidation();
  };

  function _doBalloonCalc() {
    // if payment & amount & deferred set, no need to calc, just return
    if ($ctrl.financials.convert.balloonPayment && $ctrl.financials.convert.balloonAmount && $ctrl.financials.convert.balloonResidual) {
      return;
    } // if no term ID or apr, just return, they're required to do the following calcs...


    if (!$ctrl.financials.convert.termID || !$ctrl.financials.convert.balloonRate) {
      return;
    } // do calcs: payment, amount, residual


    if ($ctrl.financials.convert.balloonAmount && $ctrl.financials.convert.balloonResidual) {
      _balloonPaymentByAmountAprAndResidual();
    } else if ($ctrl.financials.convert.balloonPayment && $ctrl.financials.convert.balloonResidual) {
      _balloonAmountByPaymentAprAndResidual();
    } else if ($ctrl.financials.convert.balloonAmount && $ctrl.financials.convert.balloonPayment) {
      _balloonResidualByAmountAprAndPayment();
    } else {
      return;
    }
  }

  function _balloonPaymentByAmountAprAndResidual() {
    var amount = $ctrl.financials.convert.balloonAmount;
    var residual = $ctrl.financials.convert.balloonResidual;

    var complexRate = _balloonRateComplexPart();

    var power = _balloonPower();

    var payment = Math.ceil(complexRate * (amount * power - residual));
    $ctrl.financials.convert.balloonPayment = payment;
  }

  function _balloonAmountByPaymentAprAndResidual() {
    var payment = $ctrl.financials.convert.balloonPayment;
    var residual = $ctrl.financials.convert.balloonResidual;

    var complexRate = _balloonRateComplexPart();

    var power = _balloonPower();

    var amount = Math.ceil((payment / complexRate + residual) / power);
    $ctrl.financials.convert.balloonAmount = amount;
  }

  function _balloonResidualByAmountAprAndPayment() {
    var payment = $ctrl.financials.convert.balloonPayment;
    var amount = $ctrl.financials.convert.balloonAmount;

    var complexRate = _balloonRateComplexPart();

    var power = _balloonPower();

    var residual = Math.ceil(-(payment / complexRate - amount * power));
    $ctrl.financials.convert.balloonResidual = residual;
  }

  function _balloonRateComplexPart() {
    var apr = _balloonAprFromBalloonRate();

    var power = _balloonPower();

    var complexRate = apr / (power - 1);
    return complexRate;
  }

  function _balloonAprFromBalloonRate() {
    var rate = $ctrl.financials.convert.balloonRate;
    var apr = rate / 1200;
    return apr;
  }

  function _balloonPower() {
    var termId = $ctrl.financials.convert.termID;

    var apr = _balloonAprFromBalloonRate();

    var power = Math.pow(1 + apr, termId);
    return power;
  }

  function resetFinancials() {
    $ctrl.financials.convert.soldByID = null;
    $ctrl.financials.convert.selectedBank = null;
    $ctrl.financials.convert.termID = null;
    $ctrl.financials.convert.balloonPayment = null;
    $ctrl.financials.convert.balloonRate = null;
    $ctrl.financials.convert.balloonAmount = null;
    $ctrl.financials.convert.balloonResidual = null;
    $ctrl.financials.convert.cashDown = null;
    $ctrl.financials.convert.mileageAtDelivery = null;
    $ctrl.financials.convert.soldAsNew = true;
    $ctrl.financials.convert.hasExtWarranty = false;
    $ctrl.financialForm.$setPristine();
    $ctrl.checkValidation();
  }

  function onBalloonFieldChange(balloonField) {
    if ($ctrl.financials.convert.balloonRate && $ctrl.financials.convert.balloonAmount && $ctrl.financials.convert.balloonResidual && $ctrl.financials.convert.balloonPayment) {
      if (!$ctrl.promptActive) {
        recalculateBalloonFields(balloonField);
      }
    } else {
      $ctrl.calc();
    }
  }

  function recalculateBalloonFields(balloonFieldChanged) {
    var promptFields = [];

    switch (balloonFieldChanged) {
      case $ctrl.balloonFields.payment:
        promptFields.push({
          value: $ctrl.balloonFields.financedAmount,
          text: financedAmountKey
        }, {
          value: $ctrl.balloonFields.residual,
          text: residualKey
        });
        break;

      case $ctrl.balloonFields.financedAmount:
        promptFields.push({
          value: $ctrl.balloonFields.payment,
          text: monthlyPaymentAmountKey
        }, {
          value: $ctrl.balloonFields.residual,
          text: residualKey
        });
        break;

      case $ctrl.balloonFields.residual:
        promptFields.push({
          value: $ctrl.balloonFields.payment,
          text: monthlyPaymentAmountKey
        }, {
          value: $ctrl.balloonFields.financedAmount,
          text: financedAmountKey
        });
        break;

      default:
        return;
    }

    promptFieldToRecalculate(promptFields).then(recalculateBalloonField).catch(function () {
      recalculateBalloonField(balloonFieldChanged);
    });
  }

  function promptFieldToRecalculate(promptFields) {
    $ctrl.promptActive = true;
    var deferred = $q.defer();
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'selectModal',
      appendTo: parentElem,
      backdrop: 'static',
      keyboard: false,
      resolve: {
        title: function title() {
          return financeFieldPromptTitleKey;
        },
        message: function message() {
          return financeFieldPromptMessageKey;
        },
        options: function options() {
          return promptFields;
        }
      }
    });
    modalInstance.closed.then(function () {
      $ctrl.promptActive = false;
      deferred.reject();
    });
    modalInstance.result.then(function (balloonFieldSelected) {
      deferred.resolve(balloonFieldSelected);
    });
    return deferred.promise;
  }

  function recalculateBalloonField(balloonField) {
    switch (balloonField) {
      case $ctrl.balloonFields.payment:
        _balloonPaymentByAmountAprAndResidual();

        break;

      case $ctrl.balloonFields.financedAmount:
        _balloonAmountByPaymentAprAndResidual();

        break;

      case $ctrl.balloonFields.residual:
        _balloonResidualByAmountAprAndPayment();

        break;

      default:
        return;
    }

    $ctrl.checkValidation();
  }
}