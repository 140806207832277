"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealSheetTabs = {
    alertsAndScripts: 1,
    currentVehicleDetails: 2,
    replacementVehicleDetails: 3,
    activityAndNotes: 4,
    serviceHistory: 5,
    otherVehiclesOwned: 6,
    dealHistory: 9,
    featureComparison: 7,
    changeLog: 8,
};
