"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./recent-deal-sheets.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("ng-busy"));
var i3 = __importStar(require("../../deal-sheet/alerts/alerts.component-ng.ngfactory"));
var i4 = __importStar(require("../../deal-sheet/alerts/alerts.component-ng"));
var i5 = __importStar(require("../../../filters/locale-date-time.pipe"));
var i6 = __importStar(require("../../../filters/locale-date.pipe"));
var i7 = __importStar(require("../../../filters/locale-currency.pipe"));
var i8 = __importStar(require("../../../filters/locale-number.pipe"));
var i9 = __importStar(require("@ngx-translate/core"));
var i10 = __importStar(require("@angular/common"));
var i11 = __importStar(require("../../icon/icon.component.ngfactory"));
var i12 = __importStar(require("../../icon/icon.component"));
var i13 = __importStar(require("@angular/platform-browser"));
var i14 = __importStar(require("./recent-deal-sheets.component"));
var i15 = __importStar(require("../../../ajs-upgraded-providers"));
var i16 = __importStar(require("../../../services/recent-deal-sheet.service"));
var styles_RecentDealSheetsComponent = [i0.styles];
var RenderType_RecentDealSheetsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecentDealSheetsComponent, data: {} });
exports.RenderType_RecentDealSheetsComponent = RenderType_RecentDealSheetsComponent;
function View_RecentDealSheetsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "spinner"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.InstanceConfigHolderService, i2.InstanceConfigHolderService, []), i1.ɵprd(131584, null, i2.ɵa, i2.ɵa, []), i1.ɵdid(3, 409600, null, 0, i2.NgBusyDirective, [i2.ɵb, i2.InstanceConfigHolderService, i1.ComponentFactoryResolver, i2.ɵa, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i1.Renderer2, i1.Injector], { options: [0, "options"] }, null), i1.ɵpod(4, { busy: 0, delay: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.busy, 200); _ck(_v, 3, 0, currVal_0); }, null); }
function View_RecentDealSheetsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "deal-sheet-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDealSheet(_v.context.$implicit.entityId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "alerts", [], null, null, null, i3.View_AlertsComponent_0, i3.RenderType_AlertsComponent)), i1.ɵdid(4, 638976, null, 0, i4.AlertsComponent, [i5.LocaleDateTimePipe, i6.LocaleDatePipe, i7.LocaleCurrencyPipe, i8.LocaleNumberPipe, i9.TranslateService], { values: [0, "values"], showRelavantInfo: [1, "showRelavantInfo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getAlertObjects(_v.context.$implicit); var currVal_2 = false; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.clientName; _ck(_v, 2, 0, currVal_0); }); }
function View_RecentDealSheetsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "recentDealSheets header-modal-style"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "h5-modal"], ["translate", "recentDealSheets"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i9.TranslateDirective, [i9.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecentDealSheetsComponent_2)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "deal-sheet-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecentDealSheetsComponent_3)), i1.ɵdid(7, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "recentDealSheets"; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.recentDealSheets; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.recentDealSheets; _ck(_v, 7, 0, currVal_2); }, null); }
function View_RecentDealSheetsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "recentDealSheets-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleRecentDealSheets() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "icon", [["class", "icon alert-blocks-svg "]], null, null, null, i11.View_IconComponent_0, i11.RenderType_IconComponent)), i1.ɵdid(3, 638976, null, 0, i12.IconComponent, [i13.DomSanitizer], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecentDealSheetsComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.visible; _ck(_v, 5, 0, currVal_0); }, null); }
exports.View_RecentDealSheetsComponent_0 = View_RecentDealSheetsComponent_0;
function View_RecentDealSheetsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "recent-deal-sheets", [], null, null, null, View_RecentDealSheetsComponent_0, RenderType_RecentDealSheetsComponent)), i1.ɵdid(1, 49152, null, 0, i14.RecentDealSheetsComponent, [i15.DealSheetService, i16.RecentDealSheetService], null, null)], null, null); }
exports.View_RecentDealSheetsComponent_Host_0 = View_RecentDealSheetsComponent_Host_0;
var RecentDealSheetsComponentNgFactory = i1.ɵccf("recent-deal-sheets", i14.RecentDealSheetsComponent, View_RecentDealSheetsComponent_Host_0, { visible: "visible" }, { visibleChange: "visibleChange" }, []);
exports.RecentDealSheetsComponentNgFactory = RecentDealSheetsComponentNgFactory;
