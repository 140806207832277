
// components\search\searchfilter-definitions.js 

export const filterTypes = {
    "TextBox": 1,
    "CheckBoxList": 2,
    "DropdownList": 3,
    "Date": 4,
    "Text": 5,
    "Title": 6,
    "TextBoxRange": 7,
    "EmptyLine": 8,
    "Separator": 9,
    "DatepickerRange": 10,
    "MultiSelectList": 11,
    "CollapseWidget": 12,
    "CustomTitle": 13,
}

export const filterSources = {
    "Fixed": 0,
    "Tags": 1,
    "Makes": 2,
    "Years": 3,
    "AlertType": 4,
    "SoldBy": 5,
    "Owner": 6,
    "Models": 7,
    "Banks": 8,
    "Trims": 9,
    "NatRecallNos": 10,
    "MfgrRecallNos": 11,
    "RecallTypes": 12,
}

export const saveResponseCodes = {
    "Success": 0,
    "SaveSearchError": 1,
    "GeneralError": 2,
    "NameAlreadyExists": 3,
    "ShareSearchError": 4,
    "UpdateDealerlayoutError": 5
}

export const filterNames = {
    "AutoAssistantFrom": "AutoAssistantFrom",
    "AutoAssistantTo": "AutoAssistantTo",
    "BankID": "BankID",
    "Banks": "Banks",
    "Categories": "Categories",
    "CategoriesList": "CategoriesList",
    "CurrentRateFrom": "CurrentRateFrom",
    "CurrentRateTo": "CurrentRateTo",
    "CustomerAddress": "CustomerAddress",
    "CustomerEmail": "CustomerEmail",
    "emaillIsValid": "emailIsValid",
    "CustomerEngagementGroups": "CustomerEngagementGroups",
    "CustomerName": "CustomerName",
    "CustomerPhone": "CustomerPhone",
    "DealDateFrom": "DealDateFrom",
    "DealDateTo": "DealDateTo",
    "DealerID": "DealerID",
    "Dealers": "Dealers",
    "DealStatuses": "DealStatuses",
    "DealStatusID": "DealStatusID",
    "DirectAlertFrom": "DirectAlertFrom",
    "DirectAlertTo": "DirectAlertTo",
    "EndDateFrom": "EndDateFrom",
    "EndDateTo": "EndDateTo",
    "EquityFrom": "EquityFrom",
    "EquityTo": "EquityTo",
    "ExtColor": "ExtColor",
    "ExtWarranties": "ExtWarranties",
    "ExtWarrantyID": "ExtWarrantyID",
    "GeoAlertFrom": "GeoAlertFrom",
    "GeoAlertTo": "GeoAlertTo",
    "PartnerExportFrom": "PartnerExportFrom",
    "PartnerExportTo": "PartnerExportTo",
    "ServiceMarketingFrom": "ServiceMarketingFrom",
    "ServiceMarketingTo": "ServiceMarketingTo",
    "ServiceLeadManagementFrom": "ServiceLeadManagementFrom",
    "ServiceLeadManagementTo": "ServiceLeadManagementTo",
    "HasActivities": "HasActivities",
    "HasActivityDays": "HasActivityDays",
    "HasActivityID": "HasActivityID",
    "HasEngagementDays": "HasEngagementDays",
    "HasEngagementID": "HasEngagementID",
    "HasExportDays": "HasExportDays",
    "HasExportID": "HasExportID",
    "HasExports": "HasExports",
    "HasRODays": "HasRODays",
    "HasROID": "HasROID",
    "HasROs": "HasROs",
    "IntColor": "IntColor",
    "IntelMarketingFrom": "IntelMarketingFrom",
    "IntelMarketingTo": "IntelMarketingTo",
    "LayoutModuleName": "LayoutModuleName",
    "LayoutModuleTitle": "LayoutModuleTitle,",
    "LowerExisitingAPR": "LowerExistingAPR",
    "MakesList": "MakeID",
    "MappedTo": "MappedTo",
    "MappedToList": "MappedToList",
    "ModelName": "ModelName",
    "ModelsList": "Models",
    "MVO": "MVO",
    "NewRateFrom": "NewRateFrom",
    "NewRateTo": "NewRateTo",
    "OdometerFrom": "OdometerFrom",
    "OdometerTo": "OdometerTo",
    "OpportunityCreateDateFrom": "OpportunityCreateDateFrom",
    "OpportunityCreateDateTo": "OpportunityCreateDateTo",
    "OwnerIds": "OwnerIds",
    "Owners": "Owners",
    "Priorities": "Priorities",
    "PrioritiesList": "PrioritiesList",
    "PrivateOfferMax": "PrivateOfferMax",
    "PrivateOfferMin": "PrivateOfferMin",
    "RemainingFrom": "RemainingFrom",
    "RemainingTo": "RemainingTo",
    "RepairOrderDateFrom": "RepairOrderDateFrom",
    "RepairOrderDateTo": "RepairOrderDateTo",
    "Sale": "Sale",
    "SavedSearches": "SavedSearches",
    "ScoreMax": "ScoreMax",
    "ScoreMin": "ScoreMin",
    "ServiceAppointmentDateFrom": "ServiceAppointmentDateFrom",
    "ServiceAppointmentDateTo": "ServiceAppointmentDateTo",
    "ShowBalloon": "ShowBalloon",
    "ShowBlocksOnly": "ShowBlocksOnly",
    "ShowCash": "ShowCash",
    "ShowConquest": "ShowConquest",
    "ShowLease": "ShowLease",
    "ShowNative": "ShowNative",
    "ShowNewCars": "ShowNewCars",
    "ShowRetail": "ShowRetail",
    "ShowUsedCars": "ShowUsedCars",
    "SoldByName": "SoldByName",
    "SoldBys": "SoldBys",
    "Source": "Source",
    "Stages": "Stages",
    "StagesList": "StagesList",
    "Tags": "Tags",
    "TermFrom": "TermFrom",
    "TermTo": "TermTo",
    "TrimName": "TrimName",
    "UseAdvancedConditions": "UseAdvancedConditions",
    "VIN": "VIN",
    "YearsList": "Years",
    "ZipCode": "ZipCode",
    "ZipDistance": "ZipDistance",
    "ZipDistanceUnits": "ZipDistanceUnits",
    "NatRecallNo": "NatRecallNo",
    "MfgrRecallNo": "MfgrRecallNo",
    "RecallDateFrom": "RecallDateFrom",
    "RecallDateTo": "RecallDateTo",
    "VinRecallTypeID": "VinRecallTypeID",
    "ReplacementStockNo": "ReplacementStockNo",
    "IncludeVTPInSearch": "IncludeVTPInSearch",
}

export const searchFilters = [
    {
        "filterType": filterTypes.CustomTitle,
        "cellSize": 1,
        "sectionID": 0,
        "displayNameKey": "search",
        "sectionIDToCollapse": 1
    },
    {
        "displayNameKey": "searchCategories",
        "hideDisplayName": true,
        "fieldName": filterNames.Categories,
        "filterType": filterTypes.CheckBoxList,
        "availableValuesUsefieldName": true,
        "tooltipText": "searchAlertTypeTooltip",
        "availableValues": [
            {
                "name": filterNames.Categories + "1",
                "valueOverride": "alert",
                "value": false,
                "alertTypeId": 100,
            },
            {
                "name": filterNames.Categories + "2",
                "valueOverride": "flex",
                "value": false,
                "alertTypeId": 200,
            },
            {
                "name": filterNames.Categories + "3",
                "valueOverride": "inmarket",
                "value": false,
                "alertTypeId": 400,
            },
            {
                "name": filterNames.Categories + "4",
                "valueOverride": "inmarketengaged",
                "value": false,
                "alertTypeId": 500,
            },
            {
                "name": filterNames.Categories + "5",
                "valueOverride": "service",
                "value": false,
                "alertTypeId": 700,
            },
            {
                "name": filterNames.Categories + "6",
                "valueOverride": "appointment",
                "value": false,
                "alertTypeId": 800,
            },
            {
                "name": filterNames.Categories + "7",
                "valueOverride": "mileage",
                "value": false,
                "alertTypeId": 900,
            },
            {
                "name": filterNames.Categories + "8",
                "valueOverride": "warranty",
                "value": false,
                "alertTypeId": 1000,
            },
            {
                "name": filterNames.Categories + "9",
                "valueOverride": "contract-end",
                "value": false,
                "alertTypeId": 600,
            }
        ],
        "cellSize": 4,
        "sectionID": 0,
    },
    {
        "displayNameKey": "searchScore",
        "displayNameCssClass": "searchDisplayNameNarrow",
        "fieldNameMin": filterNames.ScoreMin,
        "fieldNameMax": filterNames.ScoreMax,
        "fieldName": filterNames.Score,
        "filterType": filterTypes.TextBoxRange,
        "tooltipText": "searchScoreTooltip",
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 0,
        "maxLength": 3,
    },
    {
        "displayNameKey": "searchTags",
        "displayNameCssClass": "searchDisplayNameNarrow",
        "filterSource": filterSources.Tags,
        "fieldName": filterNames.Tags,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 0,
        "availableValues": [],
        "maxLength": 256,
    },
    {
        "filterType": filterTypes.CollapseWidget,
        "sectionID": 0,
        "cellSize": 1,
        sectionIDToCollapse: 1
    },
    {
        "sectionID": 0,
        "cellSize": 12,
        "fieldCssClass": "fieldHeightSmall"
    },
    {
        "sectionID": 0,
        "cellSize": 1,
    },
    {
        "displayNameKey": "showBlocksOnly",
        "hideDisplayName": true,
        "displayNameCssClass": "searchDisplayNameMedium",
        "fieldName": filterNames.ShowBlocksOnly,
        "filterType": filterTypes.CheckBoxList,
        "availableValues": [
            {
                "name": "ShowOnlySelectedAlerts",
                "nameKey": "searchBlocksOnly",
                "value": false,
            },
        ],
        "cellSize": 3,
        "sectionID": 0,
    },
    {
        "displayNameKey": "searchSaleType",
        "displayNameCssClass": "searchDisplayNameMedium",
        "fieldName": filterNames.Sale,
        "filterType": filterTypes.CheckBoxList,
        "availableValues": [
            {
                "name": "ShowCash",
                "nameKey": "searchCash",
                "value": true,
            },
            {
                "name": "ShowLease",
                "nameKey": "searchLease",
                "value": true,
            },
            {
                "name": "ShowRetail",
                "nameKey": "searchRetail",
                "value": true,
            },
            {
                "name": "ShowBalloon",
                "nameKey": "searchBalloon",
                "value": true,
            },
            {
                "name": "ShowLeaseBuyout",
                "nameKey": "searchLeaseBuyout",
                "value": false,
                "tooltipText": "searchLeaseBuyoutTooltip"
            }
        ],
        "cellSize": 4,
        "sectionID": 0,
    },
    {
        "filterId": 2,
        "displayNameKey": "searchType",
        "displayNameCssClass": "searchDisplayNameNarrow",
        "fieldName": "Type",
        "filterType": filterTypes.CheckBoxList,
        "availableValues": [
            {
                "name": "ShowNewCars",
                "nameKey": "searchNew",
                "value": true,
            },
            {
                "name": "ShowUsedCars",
                "nameKey": "searchUsed",
                "value": true,
            }
        ],
        "cellSize": 4,
        "sectionID": 0,
    },
    //{
    //    "filterType": filterTypes.EmptyLine,
    //    "sectionID": 0,
    //    "cellSize": 1,
    //},
    {
        "filterType": filterTypes.Title,
        "sectionID": 1,
    },
    {
        "filterType": filterTypes.Separator,
        "sectionID": 1,
    },
    {
        "displayNameKey": "searchName",
        "fieldName": filterNames.CustomerName,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 1,
        "maxLength": 32,
    },
    {
        "displayNameKey": "make",
        "fieldName": filterNames.MakesList,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.Makes,
        "availableValues": [
        ],
        "cellSize": 3,
        "sectionID": 1,
    },
    {
        "displayNameKey": "searchSoldBy",
        "fieldName": filterNames.SoldBys,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.SoldBy,
        "availableValues": [
        ],
        "cellSize": 6,
        "sectionID": 1,
    },
    {
        "displayNameKey": "searchAddress",
        "fieldName": filterNames.CustomerAddress,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 1,
        "maxLength": 64,
    },
    {
        "displayNameKey": "year",
        "fieldName": filterNames.YearsList,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.Years,
        "availableValues": [
        ],
        "cellSize": 3,
        "sectionID": 1,
    },
    {
        "displayNameKey": "customerOwner",
        "fieldName": filterNames.OwnerIds,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.Owner,
        "availableValues": [
        ],
        "cellSize": 6,
        "sectionID": 1,
    },
    {
        "displayNameKey": "searchZipCode",
        "fieldNameMin": filterNames.ZipCode,
        "fieldNameMax": filterNames.ZipDistance,
        "fieldDefaultValues": ['', '0'],
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "separatorCharacter": "searchin",
        "rightCharacter": "unitofmeasure",
        "sectionID": 1,
        "maxLength": 16,
    },
    {
        "displayNameKey": "model",
        "fieldName": filterNames.ModelsList,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.Models,
        "availableValues": [
        ],
        "cellSize": 3,
        "sectionID": 1,
    },
    {
        "displayNameKey": "searchAlertPmt",
        "fieldName": filterNames.Priorities,
        "filterType": filterTypes.MultiSelectList,
        "tooltipText": "searchAlertPmtTooltip",
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.Priorities + "1",
                "nameKey": "searchLow",
                "value": "L"
            },
            {
                "name": filterNames.Priorities + "2",
                "nameKey": "searchMedium",
                "value": "M"
            },
            {
                "name": filterNames.Priorities + "3",
                "nameKey": "searchHigh",
                "value": "H"
            }
        ],
        "cellSize": 3,
        "sectionID": 1,
        "fieldDisabled": true
    },
    {
        "cellSize": 3,
        "sectionID": 1,
    },
    {
        "displayNameKey": "searchPhone",
        "fieldName": filterNames.CustomerPhone,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 1,
        "maxLength": 16,
    },
    {
        "displayNameKey": "searchTrim",
        "fieldName": filterNames.TrimName,
        "filterType": filterTypes.TextBox,
        "filterSource": filterSources.Trims,
        "cellSize": 3,
        "sectionID": 1,
        "maxLength": 64,
    },
    {
        "cellSize": 6,
        "sectionID": 1,
    },
    {
        "displayNameKey": "email",
        "fieldName": filterNames.CustomerEmail,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 1,
        "maxLength": 64,
    },
    {
        "displayNameKey": "email",
        "fieldName": filterNames.emailValid,
        "filterType": filterTypes.CheckBoxList,
        "availableValues": [
            {
                "name": "CustomerEmailValid",
                "nameKey": "emailIsValid",
                "value": false,
            }
        ],        "cellSize": 3,
        "sectionID": 1,
        "maxLength": 64,
    },
    {
        "cellSize": 6,
        "sectionID": 1,
    },
    {
        "displayNameKey": "vehicleLabel",
        "filterType": filterTypes.Title,
        "sectionID": 2,
        "sectionIDToCollapse": 2
    },
    {
        "filterId": 2,
        "displayNameKey": "searchStatus",
        "fieldName": filterNames.DealStatusID,
        "filterType": filterTypes.DropdownList,
        "availableValuesUsefieldName": true,
        "tooltipText": "searchStatusTooltip",
        "availableValues": [
            {
                "name": filterNames.DealStatusID + "1",
                "nameText": "",
                "value": "AA",
                "groupName": -1
            },
            {
                "name": filterNames.DealStatusID + "2",
                "nameKey": "searchStillOwns",
                "value": "OA",
                "selected": true,
                "groupName": -1
            },
            {
                "name": filterNames.DealStatusID + "3",
                "nameKey": "searchOwnsPaidOff",
                "value": "OP",
                "groupName": -1
            },
            {
                "name": filterNames.DealStatusID + "4",
                "nameKey": "searchOwnsNotPaidOff",
                "value": "ON",
                "groupName": -1
            },
            {
                "name": filterNames.DealStatusID + "5",
                "nameKey": "searchNoLongerOwns",
                "value": "NP",
                "groupName": -1
            },
        ],
        "cellSize": 3,
        "sectionID": 2,

    },
    {
        "displayNameKey": "searchExtColor",
        "fieldName": filterNames.ExtColor,
        "filterType": filterTypes.TextBox,
        "tooltipText": "searchExtColorTooltip",
        "cellSize": 3,
        "sectionID": 2,
    },
    {
        "displayNameKey": "searchRecentRO",
        "fieldNameMin": filterNames.HasROID,
        "fieldNameMax": filterNames.HasRODays,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "sectionID": 2,
        "separatorCharacter": "searchin",
        "tooltipText": "searchRecentROTooltip",
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.HasROID + "1",
                "nameKey": "",
                "value": "A"
            },
            {
                "name": filterNames.HasROID + "2",
                "nameKey": "searchNo",
                "value": "N"
            },
            {
                "name": filterNames.HasROID + "3",
                "nameKey": "searchHas",
                "value": "Y"
            },
        ],
        "maxLength": 4,
    },
    {
        "displayNameKey": "hotTrade",
        "hideDisplayName": true,
        "fieldName": filterNames.IncludeVTPInSearch,
        "filterType": filterTypes.CheckBoxList,
        "availableValues": [
            {
                "name": filterNames.IncludeVTPInSearch,
                "nameKey": "hotTrade",
                "value": false
            },
        ],
        "cellSize": 2,
        "sectionID": 2,
        "tooltipText": "hotTradeTooltip"
    },
    {
        "cellSize": 1,
        "sectionID": 2,
    },
    {
        "displayNameKey": "searchOdometer",
        "fieldNameMin": filterNames.OdometerFrom,
        "fieldNameMax": filterNames.OdometerTo,
        "filterType": filterTypes.TextBoxRange,
        "tooltipText": "searchOdometerTooltip",
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 2,
        "minValue": 0,
        "maxValue": 10000000,
        "maxLength": 6,
    },
    {
        "displayNameKey": "searchIntColor",
        "fieldName": filterNames.IntColor,
        "filterType": filterTypes.TextBox,
        "tooltipText": "searchIntColorTooltip",
        "cellSize": 3,
        "sectionID": 2,
        "maxLength": 32
    },
    {
        "displayNameKey": "searchRODate",
        "fieldNameMin": filterNames.RepairOrderDateFrom,
        "fieldNameMax": filterNames.RepairOrderDateTo,
        "tooltipText": "searchRODateTooltip",
        "filterType": filterTypes.DatepickerRange,
        "cellSize": 6,
        "sectionID": 2,
    },
    {
        "displayNameKey": "searchVIN",
        "fieldName": filterNames.VIN,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 2,
        "maxLength": 17,
    },
    {
        "displayNameKey": "searchWarranty",
        "fieldName": filterNames.ExtWarrantyID,
        "filterType": filterTypes.DropdownList,
        "tooltipText": "searchWarrantyTooltip",
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.ExtWarrantyID + "1",
                "nameText": "",
                "value": "A",
                "groupName": -1
            },
            {
                "name": filterNames.ExtWarrantyID + "2",
                "nameKey": "searchHasExtendedWarranty",
                "value": "Y",
                "groupName": -1
            },
            {
                "name": filterNames.ExtWarrantyID + "3",
                "nameKey": "searchHasNoExtendedWarranty",
                "value": "N",
                "groupName": -1
            },
        ],
        "cellSize": 3,
        "sectionID": 2,
    },

    {
        "displayNameKey": "searchApptDate",
        "fieldNameMin": filterNames.ServiceAppointmentDateFrom,
        "fieldNameMax": filterNames.ServiceAppointmentDateTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "searchApptDateTooltip",
        "cellSize": 6,
        "sectionID": 2,
    },
    {
        "displayNameKey": "Contract",
        "filterType": filterTypes.Title,
        "sectionID": 3,
        "sectionIDToCollapse": 3
    },
    {
        "displayNameKey": "searchTerm",
        "fieldNameMin": filterNames.TermFrom,
        "fieldNameMax": filterNames.TermTo,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 3,
        "tooltipText": "searchTermTooltip",
        "maxLength": 2
    },
    {
        "displayNameKey": "searchRate",
        "fieldNameMin": filterNames.CurrentRateFrom,
        "fieldNameMax": filterNames.CurrentRateTo,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 3,
        "tooltipText": "searchRateTooltip",
        "maxLength": 7
    },
    {
        "displayNameKey": "searchDealDate",
        "fieldNameMin": filterNames.DealDateFrom,
        "fieldNameMax": filterNames.DealDateTo,
        "filterType": filterTypes.DatepickerRange,
        "cellSize": 6,
        "sectionID": 3,
        "tooltipText": "searchDealDateTooltip"
    },
    {
        "displayNameKey": "searchRemaining",
        "fieldNameMin": filterNames.RemainingFrom,
        "fieldNameMax": filterNames.RemainingTo,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 3,
        "tooltipText": "searchRemainingTooltip",
        "maxLength": 2,
    },
    {
        "displayNameKey": "searchBank",
        "fieldName": filterNames.BankID,
        "filterType": filterTypes.DropdownList,
        "filterSource": filterSources.Banks,
        "cellSize": 3,
        "availableValues": [],
        "sectionID": 3,
    },
    {
        "displayNameKey": "searchEndDate",
        "fieldNameMin": filterNames.EndDateFrom,
        "fieldNameMax": filterNames.EndDateTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "searchEndDateTooltip",
        "cellSize": 6,
        "sectionID": 3,
    },
    {
        "displayNameKey": "Opportunity",
        "filterType": filterTypes.Title,
        "sectionID": 4,
        "sectionIDToCollapse": 4
    },
    {
        "displayNameKey": "searchSource",
        "fieldName": filterNames.Source,
        "filterType": filterTypes.CheckBoxList,
        "availableValues": [
            {
                "name": "ShowNative",
                "nameKey": "searchNative",
                "value": true,
                "tooltipText": "searchSourceNativeTooltip"
            },
            {
                "name": "ShowConquest",
                "nameKey": "searchServiceConquest",
                "value": false,
                "tooltipText": "searchSourceConquestTooltip"
            },
            {
                "name": "ShowConverted",
                "nameKey": "searchConverted",
                "value": true,
                "tooltipText": "searchSourceConvertedTooltip"
            },
        ],
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchConversion",
        "hideDisplayName": true,
        "fieldName": filterNames.Categories,
        "filterType": filterTypes.CheckBoxList,
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.Categories + "10",
                "nameKey": "searchRetailLeaseConversion",
                "valueOverride": "conversion",
                "value": false
            },
        ],
        "cellSize": 3,
        "sectionID": 4,
        "tooltipText": "searchRetailLeaseConversionTooltip"
    },
    {
        "sectionID": 4,
        "cellSize": 3,
    },
    {
        "displayNameKey": "searchEquity",
        "fieldNameMin": filterNames.EquityFrom,
        "fieldNameMax": filterNames.EquityTo,
        "filterType": filterTypes.TextBoxRange,
        "tooltipText": "searchEquityTooltip",
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 4,
        "regexFirst": "^[+-]?\\d+(\\.\\d{0}([eE](-[1-9]([0-9]*)?|[+]?\\d+))?)?$",
        "regexLast": "^[+-]?\\d+(\\.\\d{0}([eE](-[1-9]([0-9]*)?|[+]?\\d+))?)?$",
        "maxLength": 5,
    },
    {
        "displayNameKey": "searchMappedTo",
        "fieldName": filterNames.MappedTo,
        "filterType": filterTypes.MultiSelectList,
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.MappedTo + "1",
                "nameKey": "searchNew",
                "value": "N"
            },
            {
                "name": filterNames.MappedTo + "2",
                "nameKey": "searchUsed",
                "value": "U"
            },
            {
                "name": filterNames.MappedTo + "3",
                "nameKey": "searchCPO",
                "value": "C"
            },
            {
                "name": filterNames.MappedTo + "4",
                "nameKey": "searchCPOFordBlue",
                "value": "CB"
            },
            {
                "name": filterNames.MappedTo + "5",
                "nameKey": "searchCPOFordGold",
                "value": "CG"
            },
        ],
        "cellSize": 3,
        "sectionID": 4,
        "tooltipText": "searchMappedToTooltip"
    },
    {
        "displayNameKey": "searchNewAlert",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.OpportunityCreateDateFrom,
        "fieldNameMax": filterNames.OpportunityCreateDateTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "searchNewAlertTooltip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchNewRate",
        "tooltipText": "searchNewRateTooltip",
        "fieldNameMin": filterNames.NewRateFrom,
        "fieldNameMax": filterNames.NewRateTo,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "separatorCharacter": "-",
        "sectionID": 4,
        "maxLength": 7,
    },
    {
        "displayNameKey": "replacementStockNo",
        "fieldName": filterNames.ReplacementStockNo,
        "filterType": filterTypes.TextBox,
        "cellSize": 3,
        "sectionID": 4,
        "maxLength": 64,
    },
    {
        "displayNameKey": "searchOneToOne",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.DirectAlertFrom,
        "fieldNameMax": filterNames.DirectAlertTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "searchOneToOneTooltip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchStage",
        "fieldName": filterNames.Stages,
        "filterType": filterTypes.MultiSelectList,
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.Stages + "1",
                "nameKey": "searchOpen",
                "value": "O",
                "selected": true
            },
            {
                "name": filterNames.Stages + "2",
                "nameKey": "searchScheduled",
                "value": "S",
                "selected": true
            },
            {
                "name": filterNames.Stages + "3",
                "nameKey": "searchClosed",
                "value": "C"
            },
            {
                "name": filterNames.Stages + "4",
                "nameKey": "searchUnreadOnly",
                "value": "R"
            },
            {
                "name": filterNames.Stages + "5",
                "nameKey": "searchFlaggedOnly",
                "value": "F"
            },
        ],
        "cellSize": 3,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchActivity",
        "fieldNameMin": filterNames.HasActivityID,
        "fieldNameMax": filterNames.HasActivityDays,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "sectionID": 4,
        "separatorCharacter": "searchin",
        "tooltipText": "searchActivityTooltip",
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.HasActivityID + "1",
                "nameKey": "",
                "value": "A"
            },
            {
                "name": filterNames.HasActivityID + "2",
                "nameKey": "searchNo",
                "value": "N"
            },
            {
                "name": filterNames.HasActivityID + "3",
                "nameKey": "searchHas",
                "value": "Y"
            },
        ],
        "maxLength": 4
    },    
    {
        "displayNameKey": "partnerExport",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.PartnerExportFrom,
        "fieldNameMax": filterNames.PartnerExportTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "partnerExportToolTip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchPrivateOffer",
        "fieldNameMin": filterNames.PrivateOfferMin,
        "fieldNameMax": filterNames.PrivateOfferMax,
        "filterType": filterTypes.TextBoxRange,
        "tooltipText": "searchPrivateOfferTooltip",
        "cellSize": 3,
        "sectionID": 4,
        "separatorCharacter": "-",
        "regexFirst": "^[+-]?\\d+(\\.\\d{1}([eE](-[1-9]([0-9]*)?|[+]?\\d+))?)?$",
        "regexLast": "^[+-]?\\d+(\\.\\d{1}([eE](-[1-9]([0-9]*)?|[+]?\\d+))?)?$",
        "maxLength": 5,
    },
    {
        "displayNameKey": "searchExported",
        "fieldNameMin": filterNames.HasExportID,
        "fieldNameMax": filterNames.HasExportDays,
        "filterType": filterTypes.TextBoxRange,
        "cellSize": 3,
        "sectionID": 4,
        "separatorCharacter": "searchin",
        "availableValuesUsefieldName": true,
        "tooltipText": "searchExportedTooltip",
        "availableValues": [
            {
                "name": filterNames.HasExportID + "1",
                "nameKey": "",
                "value": "A"
            },
            {
                "name": filterNames.HasExportID + "2",
                "nameKey": "searchNo",
                "value": "N"
            },
            {
                "name": filterNames.HasExportID + "3",
                "nameKey": "searchHas",
                "value": "Y"
            },
        ],
        "maxLength": 4,
    },
    {
        "displayNameKey": "AutoAssistant",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.AutoAssistantFrom,
        "fieldNameMax": filterNames.AutoAssistantTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "autoAssistantToolTip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "cellSize": 3,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchMVO",
        "fieldName": filterNames.MVO,
        "filterType": filterTypes.MultiSelectList,
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.MVO + "1",
                "nameKey": "searchMVOUpgradeOffers",
                "value": "U"
            },
            {
                "name": filterNames.MVO + "2",
                "nameKey": "searchMVODowngradeOffers",
                "value": "D"
            },
            {
                "name": filterNames.MVO + "3",
                "nameKey": "searchMVOAlternateOffers",
                "value": "A"
            },
        ],
        "cellSize": 3,
        "sectionID": 4,
        "tooltipText": "searchMVOToTooltip"
    },
    {
        "displayNameKey": "geoAlert",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.GeoAlertFrom,
        "fieldNameMax": filterNames.GeoAlertTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "geoAlertToolTip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "serviceMarketing",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.ServiceMarketingFrom,
        "fieldNameMax": filterNames.ServiceMarketingTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "serviceMarketingToolTip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "serviceLeadManagement",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldNameMin": filterNames.ServiceLeadManagementFrom,
        "fieldNameMax": filterNames.ServiceLeadManagementTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "serviceLeadManagementToolTip",
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "cellSize": 6,
        "sectionID": 4,
    },
    {
        "displayNameKey": "searchEngagement",
        "displayNameCssClass": "searchDisplayNameWide2",
        "fieldName": filterNames.CustomerEngagementGroups,
        "filterType": filterTypes.MultiSelectList,
        "tooltipText": "searchEngagementTooltip",
        "fieldCssClass": "fieldWidthSmall2",
        "availableValuesUsefieldName": true,
        "availableValues": [
            {
                "name": filterNames.CustomerEngagementGroups + "1",
                "nameKey": "searchAutoAssist",
                "value": "7"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "2",
                "nameKey": "email",
                "value": "2"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "10",
                "nameKey": "geoAlert",
                "value": "9"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "9",
                "nameKey": "intelligentMarketing",
                "value": "8"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "3",
                "nameKey": "searchOneToOne",
                "value": "1"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "4",
                "nameKey": "searchReviewPro",
                "value": "5"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "5",
                "nameKey": "serviceName",
                "value": "4"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "6",
                "nameKey": "searchTextSMS",
                "value": "6"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "7",
                "nameKey": "searchWeb",
                "value": "3"
            },
            {
                "name": filterNames.CustomerEngagementGroups + "8",
                "nameKey": "searchHotEngagementsOnly",
                "value": "0"
            },
        ],
        "cellSize": 3,
        "sectionID": 4,
    },
    {
        "displayNameKey": "HasEngagementID",
        "hideDisplayName": true,
        "fieldNameMin": filterNames.HasEngagementID,
        "fieldNameMax": filterNames.HasEngagementDays,
        "filterType": filterTypes.TextBoxRange,
        "tooltipText": "searchHasEngagementsTooltip",
        "cellSize": 3,
        "sectionID": 4,
        "separatorCharacter": "searchin",
        "availableValuesUsefieldName": true,
        "maxValue": 180,
        "availableValues": [
            {
                "name": filterNames.HasEngagements + "1",
                "nameKey": "",
                "value": "A"
            },
            {
                "name": filterNames.HasEngagements + "2",
                "nameKey": "searchNo",
                "value": "N"
            },
            {
                "name": filterNames.HasEngagements + "3",
                "nameKey": "searchHas",
                "value": "Y"
            },
        ],
        "maxLength": 3
    },
    {
        "displayNameKey": "recall",
        "filterType": filterTypes.Title,
        "sectionID": 5,
        "sectionIDToCollapse": 5
    },
    {
        "displayNameKey": "searchMfgrRecallNo",
        "fieldName": filterNames.MfgrRecallNo,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.MfgrRecallNos,
        "tooltipText": "searchMfgrRecallNoTooltip",
        "availableValuesUsefieldName": true,
        "availableValues": [
        ],
        "cellSize": 3,
        "sectionID": 5,
    },
    {
        "displayNameKey": "searchNatRecallNo",
        "fieldName": filterNames.NatRecallNo,
        "filterType": filterTypes.MultiSelectList,
        "filterSource": filterSources.NatRecallNos,
        "tooltipText": "searchNatRecallNoTooltip",
        "availableValuesUsefieldName": true,
        "availableValues": [
        ],
        "cellSize": 3,
        "sectionID": 5,
    },
    {
        "displayNameKey": "recallDate",
        "fieldNameMin": filterNames.RecallDateFrom,
        "fieldNameMax": filterNames.RecallDateTo,
        "filterType": filterTypes.DatepickerRange,
        "tooltipText": "recallDateToolTip",
        "cellSize": 6,
        "sectionID": 5,
    },
    {
        "displayNameKey": "recallType",
        "fieldName": filterNames.VinRecallTypeID,
        "filterType": filterTypes.DropdownList,
        "filterSource": filterSources.RecallTypes,
        "availableValues": [],
        "cellSize": 6,
        "sectionID": 5,
    },
]