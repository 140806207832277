
/* injects from baggage-loader */
require('./watch.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('watch', {
    templateUrl: '/app/components/deal-sheet/watch.component.html',
    bindings: {
        value: '<',
        permissions: '<',
        onChange: '&'
    }
});
