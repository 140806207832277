import { Component, ElementRef, Injector, Input } from '@angular/core';
import { LocaleNumberPipe } from '../../../../filters/locale-number.pipe';

@Component({
    selector: 'allowed-mileage',
    templateUrl: './allowed-mileage.component-ng.html',
    styleUrls: ['./allowed-mileage.component.scss']
})
export class AllowedMileageComponent {
    @Input() value: any;

    constructor(elementRef: ElementRef, injector: Injector, public localeNumber: LocaleNumberPipe) {
    }

}