"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var DealHistoryComponent = /** @class */ (function () {
    function DealHistoryComponent(translateService, dealSheetService) {
        this.translateService = translateService;
        this.dealSheetService = dealSheetService;
        this.listDealHistory = [];
        this.listDealHistoryTemp = [];
        this.subscriptionAggregator = new rxjs_1.Subscription();
        this.opportunityId = '';
        this.onSetAsDefault = new core_1.EventEmitter();
        this.vehicleImageNotAvailableUrl = require('../../../images/vehicle-image-not-available.png');
    }
    DealHistoryComponent.prototype.ngOnInit = function () {
        this.setFilters();
    };
    DealHistoryComponent.prototype.ngOnDestroy = function () {
        this.subscriptionAggregator.unsubscribe();
    };
    DealHistoryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.opportunityId) {
            this.dealHistoryData = null;
        }
        if (changes.isTabSelected && changes.isTabSelected.currentValue) {
            if (!this.dealHistoryData) {
                this.alertsTranslatedText = this.translateService.instant('alerts');
                this.mappingTranslatedText = this.translateService.instant('mapping');
                this.termTranslatedText = this.translateService.instant('term');
                this.paymentTranslatedText = this.translateService.instant('payment');
                this.busy = this.getDealHistory();
                this.subscriptionAggregator.add(this.busy);
            }
        }
    };
    DealHistoryComponent.prototype.onClick = function () {
        this.onSetAsDefault.emit();
    };
    DealHistoryComponent.prototype.getDealHistory = function () {
        var _this = this;
        return rxjs_1.from(this.dealSheetService.getDealHistory(this.opportunityId))
            .subscribe(function (dealHistoryData) {
            _this.dealHistoryData = dealHistoryData;
            _this.initData(dealHistoryData);
        });
    };
    DealHistoryComponent.prototype.initData = function (data) {
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var ele = data_1[_i];
            var changeTypes = [];
            if (ele.isAlertChange)
                changeTypes.push(this.alertsTranslatedText);
            if (ele.isMappingChange)
                changeTypes.push(this.mappingTranslatedText);
            if (ele.isPaymentChange)
                changeTypes.push(this.paymentTranslatedText);
            if (ele.isTermChange)
                changeTypes.push(this.termTranslatedText);
            ele.changeTypes = changeTypes.join(", ");
        }
        var listDealHistory = data.filter(function (ele) {
            return ele.isAlertChange ||
                ele.isMappingChange ||
                ele.isPaymentChange ||
                ele.isTermChange;
        }).map(function (history) { return (__assign({}, history, { alerts: history.alerts.map(function (type) { return ({ type: type }); }) })); });
        this.listDealHistory = listDealHistory;
        this.listDealHistoryTemp = listDealHistory;
    };
    DealHistoryComponent.prototype.setFilters = function () {
        this.filters = [{
                key: 'alerts',
                moduleName: 'alerts',
                enabled: true,
                selected: true
            }, {
                key: 'mapping',
                moduleName: 'mapping',
                enabled: true,
                selected: true
            }, {
                key: 'term',
                moduleName: 'term',
                enabled: true,
                selected: true
            }, {
                key: 'payment',
                moduleName: 'payment',
                enabled: true,
                selected: true
            }];
    };
    DealHistoryComponent.prototype.updateUrl = function (event, row) {
        row.photoPath = this.vehicleImageNotAvailableUrl;
    };
    DealHistoryComponent.prototype.checkboxClicked = function () {
        var _this = this;
        this.listDealHistory = this.listDealHistoryTemp.filter(function (ele) {
            var selections = _this.filters.filter(function (i) { return i.selected; }).map(function (a) { return a.key; });
            return selections.length !== 0 && ((ele.isAlertChange && selections.includes('alerts')) ||
                (ele.isMappingChange && selections.includes('mapping')) ||
                (ele.isPaymentChange && selections.includes('payment')) ||
                (ele.isTermChange && selections.includes('term')));
        });
    };
    return DealHistoryComponent;
}());
exports.DealHistoryComponent = DealHistoryComponent;
