import angular from 'angular';

import 'bootstrap-sass';
import '../scripts/jquery-resizable.nobabel';

// AngularJS modules from npm
import 'angular-animate';
import 'angular-busy/dist/angular-busy';

import route from 'angular-route';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-ui-bootstrap';
import 'angularjs-slider';

import 'angulartics';
import 'angulartics/src/angulartics-gtm';
import uiSelect from 'ui-select';

import 'ng-sortable';

import 'angularjs-dropdown-multiselect';
import 'angular-dynamic-locale';
import uiRouter from "@uirouter/angularjs";
import { upgradeModule } from "@uirouter/angular-hybrid";

// AngularJS modules defined locally
import '../utilities/lodash';
import '../utilities/moment';
import configModule from './config.module';

// First-party modules
import oidcAuthModule from '../modules/oidc-auth';
import translations from '../modules/translations/translations.module';

import './kendo-components'

import '../scripts/init';

const moduleName = 'AaApp.Opp'
export default moduleName;

export const OpportunitiesModule = angular.module(moduleName, [
    // Our reusable cross app code modules
    'lodash',
    'moment',
    configModule,

    // First-party modules
    oidcAuthModule,
    translations,

    // 3rd Party modules
    'angularjs-dropdown-multiselect',
    'angulartics',
    'angulartics.google.tagmanager',
    'as.sortable',
    'cgBusy',
    'kendo.directives',
    'ngAnimate',
    'ngSanitize',
    'pascalprecht.translate',
    'rzModule',
    'tmh.dynamicLocale',
    'ui.bootstrap',
    route,
    uiSelect,
    uiRouter,
    upgradeModule.name

])
