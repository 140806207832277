ActivityFollowUpController.$inject = ["$translate", "$translatePartialLoader", "scheduleTitleApptKey", "scheduleTitleCallKey", "ActivityActionEnum"];

/* injects from baggage-loader */
require('./activity-follow-up-modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('activityFollowUpModal', {
  templateUrl: '/app/components/deal-sheet/modals/activity-follow-up-modal.component.html',
  controller: ActivityFollowUpController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function ActivityFollowUpController($translate, $translatePartialLoader, scheduleTitleApptKey, scheduleTitleCallKey, ActivityActionEnum) {
  var $ctrl = this;
  $ctrl.submitFollowUpClick = submitFollowUpClick;
  $ctrl.cancelClick = cancelClick;
  $ctrl.followUpActions = []; // model for display buttons

  var uiButtonsTypes = {
    scheduleCall: {
      activityId: ActivityActionEnum.Schedule_Call,
      activityActionName: scheduleTitleCallKey,
      activityIcon: "icon lg aa-icon-core-schedule-phone-call-000002"
    },
    scheduleAppt: {
      activityId: ActivityActionEnum.Schedule_Appt,
      activityActionName: scheduleTitleApptKey,
      activityIcon: "icon lg aa-icon-core-calendar-icon-000000"
    }
  };
  $ctrl.followUpActions = getFollowUpUIModel($ctrl.resolve.followUpActions);

  function getFollowUpUIModel(actions) {
    for (var i = 0; i < actions.followUpActions.length; i++) {
      if (actions.followUpActions[i].activityActionId == ActivityActionEnum.Schedule_Call) actions.followUpActions[i].displayData = uiButtonsTypes.scheduleCall;else if (actions.followUpActions[i].activityActionId == ActivityActionEnum.Schedule_Appt) actions.followUpActions[i].displayData = uiButtonsTypes.scheduleAppt;
    }

    return actions.followUpActions;
  }

  function cancelClick() {
    //$ctrl.dismiss({ $value: 'cancel' });
    $ctrl.close({
      $value: 'followup-cancel'
    });
  }

  function submitFollowUpClick(selectedActivityId) {
    $ctrl.close({
      $value: selectedActivityId
    });
  }
}