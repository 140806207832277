import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.find";
BulkTemplateWizardModalController.$inject = ["$element", "$logFactory", "$sce", "$scope"];

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* injects from baggage-loader */
require('./bulk-template-wizard-modal.component.html');

import angular from 'angular';
import { ChildMessagePort } from '../../utilities/messaging';
import { getUrlComponents } from '../../utilities/url';
import "./bulk-template-wizard-modal.component.scss";
angular.module('AaApp.Opp').component('bulkTemplateWizardModal', {
  templateUrl: '/app/components/modals/bulk-template-wizard-modal.component.html',
  controller: BulkTemplateWizardModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});

function BulkTemplateWizardModalController($element, $logFactory, $sce, $scope) {
  "ngInject";

  var $ctrl = this;
  var $log = $logFactory.get('bulkTemplateWizard');
  $ctrl.$onInit = $onInit;
  var PASSTHROUGH_MESSAGE_TYPES = ["SEARCH_PARAMETERS_REQ"];
  $ctrl.buttons = {
    "BACK": {
      translationKey: 'back',
      onClick: onButtonClicked.bind(null, "BACK")
    },
    "NEXT": {
      translationKey: 'next',
      onClick: onButtonClicked.bind(null, "NEXT")
    },
    "CLOSE": {
      translationKey: 'close',
      onClick: onButtonClicked.bind(null, "CLOSE")
    },
    "CANCEL": {
      translationKey: 'cancel',
      onClick: onButtonClicked.bind(null, "CANCEL")
    },
    "SEND_EMAIL": {
      translationKey: 'sendEmail',
      onClick: onButtonClicked.bind(null, "SEND_EMAIL")
    },
    "ARCHIVE_PDF": {
      translationKey: 'archivePdfAndClose',
      onClick: onButtonClicked.bind(null, "ARCHIVE_PDF")
    },
    "PRINT_PDF": {
      translationKey: 'printToPdf',
      onClick: onButtonClicked.bind(null, "PRINT_PDF")
    }
  };

  function $onInit() {
    $ctrl.title = $ctrl.resolve.title;
    $ctrl.trustedUrl = $sce.trustAsResourceUrl($ctrl.resolve.src);

    var _getUrlComponents = getUrlComponents($ctrl.resolve.src),
        origin = _getUrlComponents.origin;

    if ($ctrl.resolve.onRendered) {
      $ctrl.resolve.onRendered.promise.then(function () {
        var iframe = $element.find('iframe')[0];
        $ctrl.port = new ChildMessagePort(iframe, origin, $scope);
        $ctrl.port.message$.subscribe(messageHandler);

        $ctrl.$onDestroy = function () {
          $ctrl.port.close();
        };
      });
    }
  }

  function onButtonClicked(button) {
    $ctrl.port.postMessage({
      type: "DIALOG_BUTTON_CLICKED",
      data: {
        button: button
      }
    });
  }

  function getSearchGridMessagePort() {
    return $ctrl.resolve.searchGridMessagePort;
  }

  function messageHandler(message) {
    if (PASSTHROUGH_MESSAGE_TYPES.includes(message.type)) {
      var port = getSearchGridMessagePort();
      var replyPort = message.replyPort;
      var forwardedMessage = {
        type: message.type,
        data: message.data
      };
      port.postRequest(forwardedMessage).then(function (reply) {
        $log.log("forwarding", reply);
        replyPort.postMessage({
          type: reply.type,
          data: reply.data
        });
      });
    } else {
      switch (message.type) {
        case "DIALOG_TITLE":
          {
            var text = message.data.text;
            $log.debug("changing title", text);
            $ctrl.title = text;
            break;
          }

        case "DIALOG_BUTTON_CONFIGURE":
          {
            var _message$data = message.data,
                button = _message$data.button,
                state = _message$data.state;
            $log.debug("changing buttons", button, state);
            setButtonStateFromText(button, state);
            break;
          }

        case "DIALOG_BUTTON_CLICK":
          {
            var _button = message.data.button;
            $log.debug("clicking button", _button);
            onButtonClicked(_button);
            break;
          }

        case "DIALOG_CLOSE":
          {
            $log.debug("closing dialog");
            $ctrl.close();
            break;
          }

        case "ALL_DIALOG_BUTTON_LOCK_UNLOCK":
          {
            allButtonLockUnlock();
            break;
          }

        default:
          $log.debug("Unhandled message type", message);
      }
    }
  }

  function allButtonLockUnlock() {
    $ctrl.buttons.CANCEL.disabled = !$ctrl.buttons.CANCEL.disabled;
    $ctrl.buttons.ARCHIVE_PDF.disabled = !$ctrl.buttons.ARCHIVE_PDF.disabled;
    $ctrl.buttons.PRINT_PDF.disabled = !$ctrl.buttons.PRINT_PDF.disabled;
    $ctrl.buttons.SEND_EMAIL.disabled = !$ctrl.buttons.SEND_EMAIL.disabled;
  }

  function setButtonStateFromText(button, stateName) {
    var currentState = $ctrl.buttons[button];
    $ctrl.buttons[button] = getState(currentState, stateName);

    function getState(currentState, newStateName) {
      switch (newStateName.toUpperCase()) {
        case "VISIBLE":
          return _objectSpread({}, currentState, {
            visible: true
          });

        case "HIDDEN":
          return _objectSpread({}, currentState, {
            visible: false
          });

        case "ENABLED":
          return _objectSpread({}, currentState, {
            visible: true,
            disabled: false
          });

        case "DISABLED":
          return _objectSpread({}, currentState, {
            visible: true,
            disabled: true
          });
      }
    }
  }
}