"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./icon.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@angular/common"));
var i3 = __importStar(require("./icon.component"));
var i4 = __importStar(require("@angular/platform-browser"));
var styles_IconComponent = [i0.styles];
var RenderType_IconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconComponent, data: {} });
exports.RenderType_IconComponent = RenderType_IconComponent;
function View_IconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ICONS[_co.icon]; _ck(_v, 0, 0, currVal_0); }); }
function View_IconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IconComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.icon && _co.ICONS[_co.icon]); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_IconComponent_0 = View_IconComponent_0;
function View_IconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "icon", [], null, null, null, View_IconComponent_0, RenderType_IconComponent)), i1.ɵdid(1, 638976, null, 0, i3.IconComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_IconComponent_Host_0 = View_IconComponent_Host_0;
var IconComponentNgFactory = i1.ɵccf("icon", i3.IconComponent, View_IconComponent_Host_0, { icon: "icon" }, {}, []);
exports.IconComponentNgFactory = IconComponentNgFactory;
