export const TranslateKeys = {
    logCallTitleKey: 'logCallTitle',
    postNotesTitleKey: 'postNotesTitle',
    logEmailSentTitleKey: 'logEmailSentTitle',
    cancelTitleCallKey: 'cancelTitleCall',
    cancelTitleApptKey: 'cancelTitleAppt',
    completeTitleCallKey: 'completeTitleCall',
    completeTitleApptKey: 'completeTitleAppt',
    scheduleTitleCallKey: 'scheduleTitleCall',
    scheduleTitleApptKey: 'scheduleTitleAppt',
    rescheduleTitleCallKey: 'rescheduleTitleCall',
    rescheduleTitleApptKey: 'rescheduleTitleAppt',
    reassignTitleCallKey: 'reassignTitleCall',
    reassignTitleApptKey: 'reassignTitleAppt',
    scheduleAssignToErrorMsgKey: 'scheduleAssignToErrorMsg',
    recentRepairOrdersAbbreviatedKey: 'recentRepairOrdersAbbreviated',
    recentRepairOrdersKey: 'recentRepairOrders',
    SingularKey: 'Singular'
};
