<div class="modal-wrapper score-info-wrapper">
        <div class="modal-header">
            <div class="score-title">
                <h5 class="modal-title" translate="BehindScoreTitle"></h5>
            </div>
            <div class="score-float-right">
                <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                    <icon class="icon aa-icon-close-icon"></icon>
                </button>
            </div>
        </div>
        <div class="modal-body form-horizontal" [ngBusy]="{busy: busy, delay: 200}">

            <div class="form-group row" *ngIf="featuresLoaded && features.length > 0">
                <div class="col-sm-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center" translate="influence"></th>
                                <th translate="ScoreFeatureLabel"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of features">
                                <td class="text-center" [ngClass]="{'posValue': row.weight > 0, 'negValue': row.weight < 0}">{{row.posneg}}</td>
                                <td class="feature-desc" *ngIf="row.isAlert">
                                    <span class="grid-alert"><span class="fa fa{{row.alertCode}}"><span class="fa alerts-letterblock-sm">{{row.alertCodeLetter}}</span></span></span>&nbsp;{{'alertName' | translate}}
                                </td>
                                <td class="feature-desc" *ngIf="!row.isAlert">
                                    <span *ngIf="!row.isAlert">{{row.featureDescription}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="form-group row" *ngIf="featuresLoaded && features.length == 0">
                <div class="col-sm-12 no-score-data">
                    <span translate="NoScoreData"></span>
                </div>
            </div>

            <div class="form-group row note-section" *ngIf="featuresLoaded && features.length > 0">
                <div class="col-sm-12">
                    <span class="noteLabel">{{'note' | translate}}:</span>
                    <ul class="noteList">
                        <li translate="scoreNote1"></li>
                        <li translate="scoreNote2"></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="cancelModal()" translate="close"></button>
        </div>
</div>