
/* injects from baggage-loader */
require('./search-directalert.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('searchDirectAlert', {
        templateUrl: '/app/components/search/search-directalert.component.html',
        controller: SearchDirectAlertController,
        bindings: {
            url: '<',
        }
});

/* @ngInject */
function SearchDirectAlertController($sce) {
    var $ctrl = this;

    $ctrl.iframeUrl;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.iframeUrl = $sce.trustAsResourceUrl(decodeURIComponent($ctrl.url));
    }

}
