
/* injects from baggage-loader */
require('./ui-textbox-range.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('uiTextboxRange', {
    templateUrl: '/app/components/search/ui-textbox-range.component.html',
    controller: UITextBoxRange,
    bindings: {
        displayNameKey: "<",
        displayNameCssClass: "<",
        hideDisplayName : "<",
        separatorCharacter : "<",
        availableValues: '<',
        minValue: '<',
        maxValue: '<',
        fieldDefaultValues: '<',
        selectedValueFirst: "=",
        selectedValueLast: "=",
        isValidSelectedValueFirst:"=",
        isValidSelectedValueLast:"=",
        isDisabled: "<",
        rightCharacter: "<",
        triggerInit: "=",
        regexFirst: "<",
        regexLast: "<",
        maxLength: "<",
        unitOfMeasureKey: "<",
    }   
});

/* @ngInject */
function UITextBoxRange($scope, $state) {
    var $ctrl = this;
    $ctrl.selectedValueFirstCSSClass;
    $ctrl.selectedValueLastCSSClass;
    $ctrl.validate = validate;
    $ctrl.$onInit = onInit;
    
    $scope.$watch('$ctrl.triggerInit', function(newVal, oldVal, scope) {
        if ($ctrl.triggerInit) {
            $ctrl.triggerInit = false;
            onInit();
        }
    });

    $scope.$watch('$ctrl.isValidSelectedValueFirst', function(newVal, oldVal, scope) {
        SetValidationCSS();
    });

    $scope.$watch('$ctrl.isValidSelectedValueLast', function(newVal, oldVal, scope) {
        SetValidationCSS();
    });

    function onInit() {
        if (!$ctrl.displayNameCssClass)
            $ctrl.displayNameCssClass = "searchDisplayNameWide";

        if ($ctrl.availableValues) {
            $ctrl.selectedValueFirst = $ctrl.availableValues[0].value;
            $ctrl.selectedValueLast = "";
        }
        else {
            $ctrl.selectedValueFirst = "";
            $ctrl.selectedValueLast = "";
        }

        if ($ctrl.fieldDefaultValues) {
            if ($ctrl.fieldDefaultValues[0])
                $ctrl.selectedValueFirst = $ctrl.fieldDefaultValues[0];

            if ($ctrl.fieldDefaultValues[1])
                $ctrl.selectedValueLast = $ctrl.fieldDefaultValues[1];
        }

        validate();
    }

    function validate() {
        $ctrl.isValidSelectedValueFirst = true;
        $ctrl.isValidSelectedValueLast = true;

        if (!$ctrl.availableValues && !$ctrl.rightCharacter) {   // TODO: find better way to check if range validation should be done later
            $ctrl.selectedValueFirst = ($ctrl.selectedValueFirst) ? $ctrl.selectedValueFirst.replace(",", "") : null;

            $ctrl.isValid = isValid;

            if ($ctrl.minValue != null && parseFloat($ctrl.selectedValueFirst) < $ctrl.minValue) 
                $ctrl.selectedValueFirst = $ctrl.minValue;

            if ($ctrl.selectedValueFirst && $ctrl.selectedValueLast) {
                $ctrl.isValidSelectedValueFirst = (parseFloat($ctrl.selectedValueFirst) <= parseFloat($ctrl.selectedValueLast));
                $ctrl.isValidSelectedValueLast = (parseFloat($ctrl.selectedValueFirst) <= parseFloat($ctrl.selectedValueLast));
            }

            // Regex validation
            if ($ctrl.isValidSelectedValueFirst && $ctrl.selectedValueFirst && $ctrl.regexFirst) {
                var reFirst = new RegExp($ctrl.regexFirst);
                $ctrl.isValidSelectedValueFirst = reFirst.test($ctrl.selectedValueFirst);
            }
        }

        // disallow illegal characters
        var invalidCharacters = ['<'];

        var isValid = true;
        for (var c of invalidCharacters) {
            if ($ctrl.selectedValueFirst && $ctrl.selectedValueFirst.includes(c))
                $ctrl.isValidSelectedValueFirst = false;

            if ($ctrl.selectedValueLast && $ctrl.selectedValueLast.includes(c))
                $ctrl.isValidSelectedValueLast = false;
        }


        $ctrl.selectedValueLast = ($ctrl.selectedValueLast) ? $ctrl.selectedValueLast.replace(",", "") : null;

        if ($ctrl.maxValue != null && parseFloat($ctrl.selectedValueLast) > $ctrl.maxValue) 
            $ctrl.selectedValueLast = $ctrl.maxValue;

        if ($ctrl.isValidSelectedValueLast && $ctrl.selectedValueLast && $ctrl.regexLast) {
            var reLast = new RegExp($ctrl.regexLast);
            $ctrl.isValidSelectedValueLast = reLast.test($ctrl.selectedValueLast);
        }

        SetValidationCSS();
    }

    function SetValidationCSS() {
        $ctrl.selectedValueFirstCSSClass = ($ctrl.isValidSelectedValueFirst) ? "valid" : "invalid";
        $ctrl.selectedValueLastCSSClass = ($ctrl.isValidSelectedValueLast) ? "valid" : "invalid";
    }
}
