/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class ChangeLogClientService extends __BaseService {
  static readonly ByEntityidGETPath = '/api/ChangeLog/{entityId}';
  static readonly AddTermChangeLogByVehicleidByTermPOSTPath = '/api/ChangeLog/Term/{vehicleId}/{term}';
  static readonly AddPriceChangeLogByVehicleidByPricePOSTPath = '/api/ChangeLog/Price/{vehicleId}/{price}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param entityId undefined
   */
  ByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ChangeLog/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  ByEntityidGET(entityId: string): __Observable<null> {
    return this.ByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChangeLogClientService.AddTermChangeLogByVehicleidByTermPOSTParams` containing the following parameters:
   *
   * - `vehicleId`:
   *
   * - `term`:
   */
  AddTermChangeLogByVehicleidByTermPOSTResponse(params: ChangeLogClientService.AddTermChangeLogByVehicleidByTermPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ChangeLog/Term/${params.vehicleId}/${params.term}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChangeLogClientService.AddTermChangeLogByVehicleidByTermPOSTParams` containing the following parameters:
   *
   * - `vehicleId`:
   *
   * - `term`:
   */
  AddTermChangeLogByVehicleidByTermPOST(params: ChangeLogClientService.AddTermChangeLogByVehicleidByTermPOSTParams): __Observable<null> {
    return this.AddTermChangeLogByVehicleidByTermPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChangeLogClientService.AddPriceChangeLogByVehicleidByPricePOSTParams` containing the following parameters:
   *
   * - `vehicleId`:
   *
   * - `price`:
   */
  AddPriceChangeLogByVehicleidByPricePOSTResponse(params: ChangeLogClientService.AddPriceChangeLogByVehicleidByPricePOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ChangeLog/Price/${params.vehicleId}/${params.price}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChangeLogClientService.AddPriceChangeLogByVehicleidByPricePOSTParams` containing the following parameters:
   *
   * - `vehicleId`:
   *
   * - `price`:
   */
  AddPriceChangeLogByVehicleidByPricePOST(params: ChangeLogClientService.AddPriceChangeLogByVehicleidByPricePOSTParams): __Observable<null> {
    return this.AddPriceChangeLogByVehicleidByPricePOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChangeLogClientService {

  /**
   * Parameters for AddTermChangeLogByVehicleidByTermPOST
   */
  export interface AddTermChangeLogByVehicleidByTermPOSTParams {
    vehicleId: number;
    term: string;
  }

  /**
   * Parameters for AddPriceChangeLogByVehicleidByPricePOST
   */
  export interface AddPriceChangeLogByVehicleidByPricePOSTParams {
    vehicleId: number;
    price: number;
  }
}

export { ChangeLogClientService }
