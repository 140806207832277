
/* injects from baggage-loader */
require('./search-add-access-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url"
import { ChildMessagePort } from '../../utilities/messaging';
import './search-add-access-modal.component.scss';

angular.module('AaApp.Opp').component('searchAddAccessModal', {
    templateUrl: '/app/components/search/search-add-access-modal.component.html',
    controller: SearchAddAccessModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
});

/* @ngInject */
function SearchAddAccessModalController($element, $scope, $sce, $uibModal, $document, legacy, urlHelper) {
    var $ctrl = this;

    $ctrl.submit = submit;
    $ctrl.$onInit = onInit;
    const originalIframeUrl = legacy.iframeUrl + "/AlertDesk/Search/SearchAddAccess";
    $ctrl.selectedDealerId;
    $ctrl.iframeUrl;
    $ctrl.dealsheetDomain;


    function onInit() {
        var rendered = $ctrl.resolve.data.rendered;
        if ($ctrl.resolve.data.selectedDealerId === undefined) {
            $ctrl.selectedDealerId = 0;
        }
        else {
            $ctrl.selectedDealerId = $ctrl.resolve.data.selectedDealerId;
        }
        $ctrl.dealsheetDomain = $ctrl.resolve.data.dealsheetDomain;

        $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeUrl + "?dealerID=" + $ctrl.selectedDealerId + "&ds=" + $ctrl.dealsheetDomain);

        rendered.then(function () {
            const iframe = $element.find(".ifrmSearchAddAccess")[0];
            const origin = getUrlComponents(originalIframeUrl).origin;
            $ctrl.messageChannel = new ChildMessagePort(iframe, origin, $scope);

            $ctrl.messageChannel.subscribe(function ({ data }, replyPort) {
                if (data.type === "SEARCH_ADD_ACCESS_SELECTED_USERS") {
                    $ctrl.close({ $value: { assignedUsers: data.data.assignedUsers } });
                }
            })
        });
    }

    function submit() {
        var msg = {
            type: "SEARCH_SUBMIT_ADD_ACCESS",
            data: {
                selectedDealerId: $ctrl.selectedDealerId
            }
        };
        $ctrl.messageChannel.postMessage(msg);
    }
}
