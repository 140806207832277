/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReplacementSpecificDto } from '../models/replacement-specific-dto';
import { ReplacementUpDownDto } from '../models/replacement-up-down-dto';
import { ReplacementDto } from '../models/replacement-dto';
@Injectable({
  providedIn: 'root',
})
class ReplacementClientService extends __BaseService {
  static readonly GetReplacementSpecificsGETPath = '/api/Replacement/GetReplacementSpecifics';
  static readonly GetReplacementUpDownGETPath = '/api/Replacement/GetReplacementUpDown';
  static readonly SaveBulkReplacementsByDefaultdealeridPOSTPath = '/api/Replacement/SaveBulkReplacements/{defaultDealerId}';
  static readonly BulkDeletePOSTPath = '/api/Replacement/BulkDelete';
  static readonly ByIdGETPath = '/api/Replacement/{id}';
  static readonly ByIdDELETEPath = '/api/Replacement/{id}';
  static readonly GetManyGETPath = '/api/Replacement/GetMany';
  static readonly PUTPath = '/api/Replacement';
  static readonly POSTPath = '/api/Replacement';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReplacementClientService.GetReplacementSpecificsGETParams` containing the following parameters:
   *
   * - `YearId`:
   *
   * - `TrimId`:
   *
   * - `ModelId`:
   *
   * - `MakeId`:
   *
   * - `FilterByNew`:
   *
   * - `DealerId`:
   *
   * @return Success
   */
  GetReplacementSpecificsGETResponse(params: ReplacementClientService.GetReplacementSpecificsGETParams): __Observable<__StrictHttpResponse<Array<ReplacementSpecificDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.YearId != null) __params = __params.set('YearId', params.YearId.toString());
    if (params.TrimId != null) __params = __params.set('TrimId', params.TrimId.toString());
    if (params.ModelId != null) __params = __params.set('ModelId', params.ModelId.toString());
    if (params.MakeId != null) __params = __params.set('MakeId', params.MakeId.toString());
    if (params.FilterByNew != null) __params = __params.set('FilterByNew', params.FilterByNew.toString());
    if (params.DealerId != null) __params = __params.set('DealerId', params.DealerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Replacement/GetReplacementSpecifics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReplacementSpecificDto>>;
      })
    );
  }
  /**
   * @param params The `ReplacementClientService.GetReplacementSpecificsGETParams` containing the following parameters:
   *
   * - `YearId`:
   *
   * - `TrimId`:
   *
   * - `ModelId`:
   *
   * - `MakeId`:
   *
   * - `FilterByNew`:
   *
   * - `DealerId`:
   *
   * @return Success
   */
  GetReplacementSpecificsGET(params: ReplacementClientService.GetReplacementSpecificsGETParams): __Observable<Array<ReplacementSpecificDto>> {
    return this.GetReplacementSpecificsGETResponse(params).pipe(
      __map(_r => _r.body as Array<ReplacementSpecificDto>)
    );
  }

  /**
   * @param params The `ReplacementClientService.GetReplacementUpDownGETParams` containing the following parameters:
   *
   * - `YearId`:
   *
   * - `TrimId`:
   *
   * - `ModelId`:
   *
   * - `MakeId`:
   *
   * - `DealerId`:
   *
   * @return Success
   */
  GetReplacementUpDownGETResponse(params: ReplacementClientService.GetReplacementUpDownGETParams): __Observable<__StrictHttpResponse<Array<ReplacementUpDownDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.YearId != null) __params = __params.set('YearId', params.YearId.toString());
    if (params.TrimId != null) __params = __params.set('TrimId', params.TrimId.toString());
    if (params.ModelId != null) __params = __params.set('ModelId', params.ModelId.toString());
    if (params.MakeId != null) __params = __params.set('MakeId', params.MakeId.toString());
    if (params.DealerId != null) __params = __params.set('DealerId', params.DealerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Replacement/GetReplacementUpDown`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReplacementUpDownDto>>;
      })
    );
  }
  /**
   * @param params The `ReplacementClientService.GetReplacementUpDownGETParams` containing the following parameters:
   *
   * - `YearId`:
   *
   * - `TrimId`:
   *
   * - `ModelId`:
   *
   * - `MakeId`:
   *
   * - `DealerId`:
   *
   * @return Success
   */
  GetReplacementUpDownGET(params: ReplacementClientService.GetReplacementUpDownGETParams): __Observable<Array<ReplacementUpDownDto>> {
    return this.GetReplacementUpDownGETResponse(params).pipe(
      __map(_r => _r.body as Array<ReplacementUpDownDto>)
    );
  }

  /**
   * @param params The `ReplacementClientService.SaveBulkReplacementsByDefaultdealeridPOSTParams` containing the following parameters:
   *
   * - `defaultDealerId`:
   *
   * - `request`:
   *
   * @return Success
   */
  SaveBulkReplacementsByDefaultdealeridPOSTResponse(params: ReplacementClientService.SaveBulkReplacementsByDefaultdealeridPOSTParams): __Observable<__StrictHttpResponse<Array<ReplacementDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Replacement/SaveBulkReplacements/${params.defaultDealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReplacementDto>>;
      })
    );
  }
  /**
   * @param params The `ReplacementClientService.SaveBulkReplacementsByDefaultdealeridPOSTParams` containing the following parameters:
   *
   * - `defaultDealerId`:
   *
   * - `request`:
   *
   * @return Success
   */
  SaveBulkReplacementsByDefaultdealeridPOST(params: ReplacementClientService.SaveBulkReplacementsByDefaultdealeridPOSTParams): __Observable<Array<ReplacementDto>> {
    return this.SaveBulkReplacementsByDefaultdealeridPOSTResponse(params).pipe(
      __map(_r => _r.body as Array<ReplacementDto>)
    );
  }

  /**
   * @param params The `ReplacementClientService.BulkDeletePOSTParams` containing the following parameters:
   *
   * - `trimIDs`:
   *
   * - `dealerID`:
   */
  BulkDeletePOSTResponse(params: ReplacementClientService.BulkDeletePOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.trimIDs;
    if (params.dealerID != null) __params = __params.set('dealerID', params.dealerID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Replacement/BulkDelete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReplacementClientService.BulkDeletePOSTParams` containing the following parameters:
   *
   * - `trimIDs`:
   *
   * - `dealerID`:
   */
  BulkDeletePOST(params: ReplacementClientService.BulkDeletePOSTParams): __Observable<null> {
    return this.BulkDeletePOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<ReplacementDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Replacement/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReplacementDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<ReplacementDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as ReplacementDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Replacement/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<ReplacementDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Replacement/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReplacementDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<ReplacementDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<ReplacementDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: ReplacementDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Replacement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: ReplacementDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: ReplacementDto): __Observable<__StrictHttpResponse<ReplacementDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Replacement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReplacementDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: ReplacementDto): __Observable<ReplacementDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as ReplacementDto)
    );
  }
}

module ReplacementClientService {

  /**
   * Parameters for GetReplacementSpecificsGET
   */
  export interface GetReplacementSpecificsGETParams {
    YearId?: number;
    TrimId?: number;
    ModelId?: number;
    MakeId?: number;
    FilterByNew?: boolean;
    DealerId?: number;
  }

  /**
   * Parameters for GetReplacementUpDownGET
   */
  export interface GetReplacementUpDownGETParams {
    YearId?: number;
    TrimId?: number;
    ModelId?: number;
    MakeId?: number;
    DealerId?: number;
  }

  /**
   * Parameters for SaveBulkReplacementsByDefaultdealeridPOST
   */
  export interface SaveBulkReplacementsByDefaultdealeridPOSTParams {
    defaultDealerId: number;
    request?: Array<ReplacementDto>;
  }

  /**
   * Parameters for BulkDeletePOST
   */
  export interface BulkDeletePOSTParams {
    trimIDs?: Array<number>;
    dealerID?: number;
  }
}

export { ReplacementClientService }
