"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var log_action_shortcut_service_1 = require("../../../../services/log-action-shortcut.service");
var constants_1 = require("../../../../constants");
var CustomerConnectSubActionsComponent = /** @class */ (function () {
    function CustomerConnectSubActionsComponent(logActionShortcutService, changeDetectorRef) {
        var _this = this;
        this.onAction = new core_1.EventEmitter();
        this.activityActionEnum = constants_1.ActivityActionEnum;
        this.shortcutSubscription = logActionShortcutService.$logShortcutAction.subscribe(function (shortCutActivityActionEnum) {
            _this.shortCutActivityActionEnum = shortCutActivityActionEnum;
            changeDetectorRef.detectChanges();
        });
    }
    CustomerConnectSubActionsComponent.prototype.ngOnInit = function () {
    };
    CustomerConnectSubActionsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.subActionTime && changes.subActionTime.currentValue) {
            // set default radio choice
            if (this.subAction === this.activityActionEnum.Log_Call || this.subAction === this.activityActionEnum.Post_Note) {
                this.selectedLogAction = this.subAction;
            }
            else if (this.subAction === this.activityActionEnum.Schedule_Appt || this.subAction === this.activityActionEnum.Schedule_Call) {
                this.selectedScheduleAction = this.subAction;
            }
        }
    };
    CustomerConnectSubActionsComponent.prototype.ngOnDestroy = function () {
        if (this.shortcutSubscription) {
            this.shortcutSubscription.unsubscribe();
        }
    };
    return CustomerConnectSubActionsComponent;
}());
exports.CustomerConnectSubActionsComponent = CustomerConnectSubActionsComponent;
