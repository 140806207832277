
/* injects from baggage-loader */
require('./inventory-search-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('inventorySearchModal', {
    templateUrl: '/app/components/deal-sheet/modals/inventory-search-modal.component.html',
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});
