
/* injects from baggage-loader */
require('./other-vehicles-owned.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('otherVehiclesOwned', {
    templateUrl: '/app/components/deal-sheet/other-vehicles-owned.component.html',
    controller: OtherVehiclesOwnedController,
    bindings: {
        relatedOpportunities: '<',
        defaultTab: '<',
        onSelect: '&',
        onSetAsDefault: '&'
    }
});

/* @ngInject */
function OtherVehiclesOwnedController(_, opportunityTypes, saleTypes, alertIconClasses, dealSheetTabs) {
    var $ctrl = this;

    $ctrl.defaultTab;
    $ctrl.tabs;
    $ctrl.opportunityTypeTranslateKeys;
    $ctrl.saleTypeTranslateKeys;
    $ctrl.alertIconClasses

    $ctrl.$onInit = init;

    function init() {
        $ctrl.opportunityTypeTranslateKeys = _.invert(opportunityTypes);
        $ctrl.saleTypeTranslateKeys = _.invert(saleTypes);
        $ctrl.alertIconClasses = alertIconClasses;
        $ctrl.tabs = dealSheetTabs;
    }
}
