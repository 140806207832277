"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cookie_storage_service_1 = require("./cookie-storage.service");
var app_settings_1 = require("../../constants/app-settings");
var core_1 = require("@angular/core");
var window_ref_service_1 = require("../../services/window-ref.service");
function crossDomainStorageServiceFactory(window) {
    return new cookie_storage_service_1.CookieStorage(window, app_settings_1.Settings.environmentVariables.crossDomainStorageDomain, app_settings_1.Settings.environmentVariables.crossDomainStoragePrefix);
}
exports.crossDomainStorageServiceFactory = crossDomainStorageServiceFactory;
exports.CrossDomainStorageService = new core_1.InjectionToken('CrossDomainStorageService');
exports.crossDomainStorageServiceProvider = {
    provide: exports.CrossDomainStorageService,
    useFactory: crossDomainStorageServiceFactory,
    deps: [window_ref_service_1.WINDOW_REF]
};
