"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MileagePenaltyComponent = /** @class */ (function () {
    function MileagePenaltyComponent() {
    }
    MileagePenaltyComponent.prototype.ngOnInit = function () { };
    return MileagePenaltyComponent;
}());
exports.MileagePenaltyComponent = MileagePenaltyComponent;
