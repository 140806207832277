export const SaleTypes = {
    unknown: 0,
    lease: 1,
    retail: 2,
    balloon: 3,
    cash: 4,
    notused5: 5,
    onePayLease: 6,
    wholesale: 7,
    incomplete: 8
};
