"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./alerts.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../alert/alert.component-ng.ngfactory"));
var i3 = __importStar(require("../alert/alert.component-ng"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("./alerts.component-ng"));
var i6 = __importStar(require("../../../filters/locale-date-time.pipe"));
var i7 = __importStar(require("../../../filters/locale-date.pipe"));
var i8 = __importStar(require("../../../filters/locale-currency.pipe"));
var i9 = __importStar(require("../../../filters/locale-number.pipe"));
var i10 = __importStar(require("@ngx-translate/core"));
var styles_AlertsComponent = [i0.styles];
var RenderType_AlertsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertsComponent, data: {} });
exports.RenderType_AlertsComponent = RenderType_AlertsComponent;
function View_AlertsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert", [], null, null, null, i2.View_AlertComponent_0, i2.RenderType_AlertComponent)), i1.ɵdid(1, 49152, null, 0, i3.AlertComponent, [], { alertTypeId: [0, "alertTypeId"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.type; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AlertsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.message; _ck(_v, 0, 0, currVal_0); }); }
function View_AlertsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "opportunity"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showRelavantInfo; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showRelavantInfo; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AlertsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "opportunities qa-alerts-opportunities"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertsComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.values; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_AlertsComponent_0 = View_AlertsComponent_0;
function View_AlertsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerts", [], null, null, null, View_AlertsComponent_0, RenderType_AlertsComponent)), i1.ɵdid(1, 638976, null, 0, i5.AlertsComponent, [i6.LocaleDateTimePipe, i7.LocaleDatePipe, i8.LocaleCurrencyPipe, i9.LocaleNumberPipe, i10.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AlertsComponent_Host_0 = View_AlertsComponent_Host_0;
var AlertsComponentNgFactory = i1.ɵccf("alerts", i5.AlertsComponent, View_AlertsComponent_Host_0, { values: "values", showRelavantInfo: "showRelavantInfo" }, {}, []);
exports.AlertsComponentNgFactory = AlertsComponentNgFactory;
