
/* injects from baggage-loader */
require('./financial-info-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('financialInfoModal', {
    templateUrl: '/app/components/deal-sheet/modals/financial-info-modal.component.html',
    controller: FinancialInfoModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

function FinancialInfoModalController() {
    var $ctrl = this;


    $ctrl.validFinancials = false;
    $ctrl.resetCounter;
    $ctrl.reset = reset;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.resetCounter = 1;
    }

    $ctrl.validationCheck = function (isValid) {
        $ctrl.validFinancials = isValid;
    }

    function reset() {
        $ctrl.resetCounter++;
    }
}
