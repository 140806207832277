"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["customer-connect .modal-body{padding-bottom:0!important;padding-top:0!important}  customer-connect .modal-body .form-group{margin-bottom:0}  customer-connect .customer-connect-log-button .form-group{margin-left:35px;margin-right:-25px;margin-bottom:5px;padding-top:10px;float:right!important}  customer-connect .do-not-contact-options{margin-top:10px;margin-left:10px}  customer-connect .row{margin-left:0;margin-right:0}  customer-connect customer-connect-sub-actions .action-menu{margin-left:15px;margin-top:15px}  customer-connect .type-of-call{margin-top:10px}  customer-connect   .log-email{margin-top:10px}@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){  customer-connect   .timeTicker{margin-top:15%}}"];
exports.styles = styles;
