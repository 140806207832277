import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.find";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.function.name";
AdminReportSubscriptionsController.$inject = ["_", "$translate", "$q", "$filter", "dealerService", "searchPresetService", "pandoSurveyService", "roles", "$uibModal", "$document", "logger"];

/* injects from baggage-loader */
require('./admin-report-subscriptions.component.html');

import angular from 'angular';
import BusyIndicator from '../../utilities/BusyIndicator';
import reportSpecs from '../../constants/reports/report-specifications';
import './admin-report-subscriptions.component.scss';
import './admin.scss';
angular.module('AaApp.Opp').component('adminReportSubscriptions', {
  templateUrl: '/app/components/admin/admin-report-subscriptions.component.html',
  controller: AdminReportSubscriptionsController,
  bindings: {}
});

function AdminReportSubscriptionsController(_, $translate, $q, $filter, dealerService, searchPresetService, pandoSurveyService, roles, $uibModal, $document, logger) {
  "ngInject";

  var $ctrl = this;
  $ctrl.$onInit = init;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.onSave = onSave;
  $ctrl.busyIndicator = new BusyIndicator();
  $ctrl.pandoGroups = [];
  $ctrl.dealerReportSubscriptions = [];
  $ctrl.reportDealerPandoGroupSubscriptions = null;
  $ctrl.reportSubscriptions = [{
    "reportName": "salesGrossReportSubscriptionTitle",
    "reportId": reportSpecs.salesGrossReport.id.toLowerCase(),
    "subscriptionGM": false,
    "subscriptionSM": false,
    "sendToGroupID": "-1",
    "previousSendToGroupID": "-1",
    "sendToGroupIDEnabled": false,
    "previousSendToGroupIDEnabled": false,
    "reportSubscriptionId": 0,
    "showError": false
  }, {
    "reportName": "userActivityReportSubscriptionTitle",
    "reportId": reportSpecs.userActivityReport.id.toLowerCase(),
    "subscriptionGM": false,
    "subscriptionSM": false,
    "sendToGroupID": "-1",
    "previousSendToGroupID": "-1",
    "sendToGroupIDEnabled": false,
    "previousSendToGroupIDEnabled": false,
    "reportSubscriptionId": 0,
    "showError": false
  }];
  $ctrl.reportSubscriptionRoles = [{
    "title": "newGeneralManagers",
    "reportSubscriptionRoleId": roles.generalManager
  }, {
    "title": "newSalesManagers",
    "reportSubscriptionRoleId": roles.salesManager
  }];

  function init() {
    $ctrl.dealers = dealerService.getSelectedDealers();
    $ctrl.selectedDealerId = $ctrl.dealers[0].id;
    $ctrl.selectedDealerName = $ctrl.dealers[0].name;
    onDealerSelect();
  }

  function busy(translationKey, promise) {
    $ctrl.busyMessage = null;
    $translate(translationKey).then(function (translation) {
      $ctrl.busyMessage = translation;
    }, function (err) {
      $ctrl.busyMessage = translationKey;
    });
    var done = $ctrl.busyIndicator.start();
    return promise.finally(done);
  }

  function onDealerSelect(value) {
    var dealerSelected = $ctrl.dealers.find(function (ele) {
      return ele.id == $ctrl.selectedDealerId;
    });
    $ctrl.selectedDealerName = dealerSelected.name;
    return busy("Loading Data", $q.all([pandoSurveyService.getDealerAutoReportSubscription($ctrl.selectedDealerId), pandoSurveyService.getReportDealerPandoGroupSubscriptions($ctrl.selectedDealerId), searchPresetService.getPandoCustomStoreGroups($ctrl.selectedDealerId)]).then(function (data) {
      $ctrl.dealerReportSubscriptions = _.cloneDeep($ctrl.reportSubscriptions);
      var dealerAutoReportSubscription = data[0];
      $ctrl.reportDealerPandoGroupSubscriptions = data[1];
      $ctrl.pandoGroups = data[2];
      $ctrl.pandoGroups.unshift({
        "groupId": "-1",
        "groupMessagingType": 0,
        "groupName": "(Select Group)"
      });

      if (dealerAutoReportSubscription) {
        dealerAutoReportSubscription.forEach(function (element) {
          $ctrl.dealerReportSubscriptions.forEach(function (ele) {
            if (element.reportID == ele.reportId && element.roleID == roles.generalManager) ele.subscriptionGM = true;
            if (element.reportID == ele.reportId && element.roleID == roles.salesManager) ele.subscriptionSM = true;
          });
        });
      }

      if ($ctrl.reportDealerPandoGroupSubscriptions) {
        $ctrl.reportDealerPandoGroupSubscriptions.forEach(function (element) {
          $ctrl.dealerReportSubscriptions.forEach(function (ele) {
            if (element.reportId == ele.reportId) {
              ele.sendToGroupID = element.sendToGroupID;
              ele.previousSendToGroupID = element.sendToGroupID;
              ele.sendToGroupIDEnabled = element.enabled;
              ele.previousSendToGroupIDEnabled = element.enabled;
              ele.reportSubscriptionId = element.reportSubscriptionId;
            }
          });
        });
      }
    }).catch(function () {
      $ctrl.errors = true;
    }));
  }

  function onSave() {
    var error = false;
    var promiseArray = [];
    var validationError = false;
    var reportSubscriptions = [];
    var pandoReportSubscriptions = [];
    var saleGrossReportSubscription = {
      "reportId": reportSpecs.salesGrossReport.id.toLowerCase(),
      "dealerID": $ctrl.selectedDealerId,
      "makeID": -1,
      "type": 3,
      "enabled": false,
      "deliveryMethodEmail": false,
      "deliveryMethodPando": true,
      "reportSubscriptionPeriodPoint": 10,
      "subject": "AutoAlert Sales Gross Report - " + $ctrl.selectedDealerName,
      "periodicity": 3,
      "code": "PM",
      "reportSubscriptionId": 0
    };
    var userActivityReportSubscription = {
      "reportId": reportSpecs.userActivityReport.id.toLowerCase(),
      "dealerID": $ctrl.selectedDealerId,
      "makeID": -1,
      "type": 3,
      "enabled": false,
      "deliveryMethodEmail": false,
      "deliveryMethodPando": true,
      "reportSubscriptionPeriodPoint": 2,
      "subject": "AutoAlert User Activity Report - " + $ctrl.selectedDealerName,
      "periodicity": 2,
      "code": "PW",
      "reportSubscriptionId": 0
    };
    $ctrl.dealerReportSubscriptions.forEach(function (ele) {
      if (ele.sendToGroupIDEnabled && ele.sendToGroupID == "-1") {
        ele.showError = true;
        validationError = true;
      }

      if (ele.subscriptionGM) {
        reportSubscriptions.push({
          dealerID: $ctrl.selectedDealerId,
          roleID: roles.generalManager,
          reportID: ele.reportId
        });
      }

      if (ele.subscriptionSM) {
        reportSubscriptions.push({
          dealerID: $ctrl.selectedDealerId,
          roleID: roles.salesManager,
          reportID: ele.reportId
        });
      }
    });

    if (validationError) {
      logger.warning("validationErrorMessage");
      return false;
    }

    if (reportSubscriptions.length > 0) {
      var dataPromise1 = pandoSurveyService.updateDealerAutoReportSubscription(reportSubscriptions);
      promiseArray.push(dataPromise1);
    } else {
      var dataPromise2 = pandoSurveyService.removeDealerAutoReportSubscription($ctrl.selectedDealerId);
      promiseArray.push(dataPromise2);
    }

    $ctrl.dealerReportSubscriptions.forEach(function (ele) {
      if (ele.reportId == reportSpecs.salesGrossReport.id.toLowerCase()) {
        if (ele.sendToGroupIDEnabled) {
          if (ele.reportSubscriptionId == 0) {
            saleGrossReportSubscription.enabled = ele.sendToGroupIDEnabled;
            saleGrossReportSubscription.sendToGroupID = ele.sendToGroupID;
            var dataPromise3 = pandoSurveyService.addReportDealerPandoGroupSubscription(saleGrossReportSubscription);
            promiseArray.push(dataPromise3);
          } else {
            saleGrossReportSubscription.enabled = ele.sendToGroupIDEnabled;
            saleGrossReportSubscription.sendToGroupID = ele.sendToGroupID;
            saleGrossReportSubscription.reportSubscriptionId = ele.reportSubscriptionId;
            var dataPromise4 = pandoSurveyService.updateReportDealerPandoGroupSubscription(saleGrossReportSubscription);
            promiseArray.push(dataPromise4);
          }
        } else {
          if (ele.previousSendToGroupIDEnabled) {
            saleGrossReportSubscription.enabled = ele.sendToGroupIDEnabled;
            saleGrossReportSubscription.sendToGroupID = ele.previousSendToGroupID;
            saleGrossReportSubscription.reportSubscriptionId = ele.reportSubscriptionId;
            var dataPromise5 = pandoSurveyService.updateReportDealerPandoGroupSubscription(saleGrossReportSubscription);
            promiseArray.push(dataPromise5);
          }
        }
      } else if (ele.reportId == reportSpecs.userActivityReport.id.toLowerCase()) {
        if (ele.sendToGroupIDEnabled) {
          if (ele.reportSubscriptionId == 0) {
            userActivityReportSubscription.enabled = ele.sendToGroupIDEnabled;
            userActivityReportSubscription.sendToGroupID = ele.sendToGroupID;
            var dataPromise6 = pandoSurveyService.addReportDealerPandoGroupSubscription(userActivityReportSubscription);
            promiseArray.push(dataPromise6);
          } else {
            userActivityReportSubscription.enabled = ele.sendToGroupIDEnabled;
            userActivityReportSubscription.sendToGroupID = ele.sendToGroupID;
            userActivityReportSubscription.reportSubscriptionId = ele.reportSubscriptionId;
            var dataPromise7 = pandoSurveyService.updateReportDealerPandoGroupSubscription(userActivityReportSubscription);
            promiseArray.push(dataPromise7);
          }
        } else {
          if (ele.previousSendToGroupIDEnabled) {
            userActivityReportSubscription.enabled = ele.sendToGroupIDEnabled;
            userActivityReportSubscription.sendToGroupID = ele.previousSendToGroupID;
            userActivityReportSubscription.reportSubscriptionId = ele.reportSubscriptionId;
            var dataPromise8 = pandoSurveyService.updateReportDealerPandoGroupSubscription(userActivityReportSubscription);
            promiseArray.push(dataPromise8);
          }
        }
      }
    });
    $q.all(promiseArray).then(function (dataArray) {}).then(function () {
      onDealerSelect();
      logger.success("changesSaved");
    }).catch(function (errorResponse) {
      onDealerSelect();
      logger.error("Error");
    });
  }
}