
import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { Customer } from '../../../interfaces/customer.interface';

@Directive({
    selector: 'customer-name'
})
export class CustomerNameComponent extends UpgradeComponent {
    @Input() customer: Customer;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('customerName', elementRef, injector);
    }
}