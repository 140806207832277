
/* injects from baggage-loader */
require('./confirmation-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('confirmationModal', {
    templateUrl: '/app/components/deal-sheet/modals/confirmation-modal.component.html',
    controller: confirmationModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

function confirmationModalController() {

    var $ctrl = this;

    if ($ctrl.resolve.closeButtonVisible === undefined)
        $ctrl.resolve.closeButtonVisible = true;

    if ($ctrl.resolve.closeButtonText === undefined)
        $ctrl.resolve.closeButtonText = "cancel";

    if ($ctrl.resolve.submitButtonVisible === undefined)
        $ctrl.resolve.submitButtonVisible = true;

    if ($ctrl.resolve.submitButtonDisabled === undefined)
        $ctrl.resolve.submitButtonDisabled = false;

    if ($ctrl.resolve.submitButtonText === undefined)
        $ctrl.resolve.submitButtonText = "yes";

    if ($ctrl.resolve.headerTextClass === undefined) {
        $ctrl.resolve.headerTextClass = 'modal-header-text';
    }
}

