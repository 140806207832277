/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserProfileDto } from '../models/user-profile-dto';
import { UserProfilePatch } from '../models/user-profile-patch';
import { UserProfileExtendedDto } from '../models/user-profile-extended-dto';
import { TimeZoneDto } from '../models/time-zone-dto';
@Injectable({
  providedIn: 'root',
})
class UserProfileClientService extends __BaseService {
  static readonly PUTPath = '/api/UserProfile';
  static readonly POSTPath = '/api/UserProfile';
  static readonly UpdatePATCHPath = '/api/UserProfile';
  static readonly ExtendedGETPath = '/api/UserProfile/Extended';
  static readonly GetCulturesGETPath = '/api/UserProfile/GetCultures';
  static readonly GetTimeZonesGETPath = '/api/UserProfile/GetTimeZones';
  static readonly ByIdGETPath = '/api/UserProfile/{id}';
  static readonly ByIdDELETEPath = '/api/UserProfile/{id}';
  static readonly GetManyGETPath = '/api/UserProfile/GetMany';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: UserProfileDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/UserProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: UserProfileDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: UserProfileDto): __Observable<__StrictHttpResponse<UserProfileDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/UserProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: UserProfileDto): __Observable<UserProfileDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as UserProfileDto)
    );
  }

  /**
   * @param userProfile undefined
   */
  UpdatePATCHResponse(userProfile?: UserProfilePatch): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = userProfile;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/UserProfile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param userProfile undefined
   */
  UpdatePATCH(userProfile?: UserProfilePatch): __Observable<null> {
    return this.UpdatePATCHResponse(userProfile).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  ExtendedGETResponse(): __Observable<__StrictHttpResponse<UserProfileExtendedDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserProfile/Extended`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileExtendedDto>;
      })
    );
  }
  /**
   * @return Success
   */
  ExtendedGET(): __Observable<UserProfileExtendedDto> {
    return this.ExtendedGETResponse().pipe(
      __map(_r => _r.body as UserProfileExtendedDto)
    );
  }

  /**
   * @return Success
   */
  GetCulturesGETResponse(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserProfile/GetCultures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCulturesGET(): __Observable<Array<string>> {
    return this.GetCulturesGETResponse().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @return Success
   */
  GetTimeZonesGETResponse(): __Observable<__StrictHttpResponse<Array<TimeZoneDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserProfile/GetTimeZones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TimeZoneDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTimeZonesGET(): __Observable<Array<TimeZoneDto>> {
    return this.GetTimeZonesGETResponse().pipe(
      __map(_r => _r.body as Array<TimeZoneDto>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<UserProfileDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserProfile/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<UserProfileDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as UserProfileDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/UserProfile/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<UserProfileDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserProfile/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserProfileDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<UserProfileDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<UserProfileDto>)
    );
  }
}

module UserProfileClientService {
}

export { UserProfileClientService }
