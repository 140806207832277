
/* injects from baggage-loader */
require('./print-upgrade-proposal.component.html');

import angular from 'angular';
import { downloadArraybufferResponse } from '../../utilities/http';

angular.module('AaApp.Opp').component('printUpgradeProposal', {
    templateUrl: '/app/components/deal-sheet/print-upgrade-proposal.component.html',
    controller: PrintUpgradeProposalController,
    bindings: {
        opportunity: '<',
        onPrint: '&'
    }
});

/* @ngInject */
function PrintUpgradeProposalController(saleTypes, legacyService, $window, translateErrorKeys, $translate) {
    var $ctrl = this;

    $ctrl.iPromiseIamThinking = null;
    $ctrl.saleTypes = saleTypes;
    $ctrl.getUpgradeProposal = getUpgradeProposal;

    function getUpgradeProposal() {
        $ctrl.iPromiseIamThinking = legacyService.generateUpgradeProposal($ctrl.opportunity)
            .then(downloadArraybufferResponse({
                contentType: 'application/pdf',
                filename: 'UpgradeProposal_' + $ctrl.opportunity.id + '.pdf',
            }))
            .catch(function (err) {
                var translationKey = null;

                switch(err.message) {
                    case "popupBlockerEnabled":
                        translationKey = translateErrorKeys.popupBlockerEnabledErrorMessage;
                        break;
                    default:
                        translationKey = translateErrorKeys.generalErrorMessageKey;
                        break;
                }

                if (translationKey != null) {
                    $translate(translationKey).then((response) => {
                        alert(response);
                    });
                }
                    
            })
            .finally(function () {
                $ctrl.onPrint();
            });
    }

}
