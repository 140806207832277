
/* injects from baggage-loader */
require('./reports-container.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('reportsContainer', {
        templateUrl: '/app/components/reports/reports-container.component.html',
        controller: reportsContainer,
        bindings: {
            kendo: '<',
            moduleIds: '<',
            selectedDealerIds: '<',
        }
});

/* @ngInject */
function reportsContainer(_, $timeout, $window, $transitions, reportsMenu) {
    var $ctrl = this;

    $ctrl.reportsMenuEnabled;
    $ctrl.reportsMenu = reportsMenu;
    $ctrl.reportsMenuVisible = true;
    $ctrl.reportsToggleMenu = reportsToggleMenu;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.reportsMenuEnabled = true;
        $transitions.onSuccess({}, function (transition) {
            $ctrl.reportsMenuEnabled = transition.to().name.indexOf('legacy') < 0;
        });
    }

    // Doing this to force a 'resize' event to be triggered so the report charts get redrawn
    function reportsToggleMenu() {
        $ctrl.reportsMenuVisible = !$ctrl.reportsMenuVisible;
        var event = null;
        try {
            event = new Event('resize');
        }
        catch (error) { // IE
            event = document.createEvent("Event");
            event.initEvent("resize", false, false);
        }
        $timeout(function () {
            $window.dispatchEvent(event);
        }, 100);
    }

}
