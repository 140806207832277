
/* injects from baggage-loader */
require('./log-call-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('logCallModal', {
    templateUrl: '/app/components/deal-sheet/modals/log-call-modal.component.html',
    controller: LogCallModal,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function LogCallModal(activityService, logCallTitleKey) {
    var $ctrl = this;
    $ctrl.prepareData = 0;
    $ctrl.activity = null;
    $ctrl.validCustomer = true;
    $ctrl.onLogCallEdit = onLogCallEdit;
    $ctrl.onSubmit = onSubmit;
    $ctrl.$onInit = init;
    $ctrl.logCallTitleKey = logCallTitleKey;
    $ctrl.LogCallError = false;

    function init() {
        if ($ctrl.resolve && $ctrl.resolve.customer) {
            $ctrl.customer = $ctrl.resolve.customer;
        }
    }

    function onLogCallEdit(data) {
        $ctrl.activity = data;
    }

    function onSubmit() {
        // process what needs to be done then fire close event;
        if (!$ctrl.activity) {
            // toggle binding and notify component to display error message
            $ctrl.LogCallError = !$ctrl.LogCallError;
            return;
        }

        $ctrl.submitting = activityService.postActivitySubmitAction($ctrl.activity)
            .then(function (data) {
                data.activityId = $ctrl.activity.activityActionID;
                data.activityResult = $ctrl.activity.resultID;
                $ctrl.submitting = null;
                $ctrl.activity = null;
                $ctrl.close({ $value: data });// pass follow-up actions to caller
            })
            .catch(function () {
                $ctrl.submitting = null;
                $ctrl.activity = null;
            });
    }

}
