import "core-js/modules/es6.array.find";
ContractSelectController.$inject = ["saleTypes", "dealerCountries", "_"];

/* injects from baggage-loader */
require('./contract-select.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('contractSelect', {
  templateUrl: '/app/components/deal-sheet/contract/contract-select.component.html',
  controller: ContractSelectController,
  bindings: {
    countryId: '<',
    contracts: '<',
    defaultTerm: '<',
    alertExists: '<',
    flexAlertExists: '<',
    permissions: '<',
    existingVehicleSaleType: '<',
    replacementContract: '<',
    showMobileView: '<',
    onSelect: '&',
    onOverride: '&'
  }
});
/* @ngInject */

function ContractSelectController(saleTypes, dealerCountries, _) {
  var $ctrl = this;
  $ctrl.offsetActive = false;
  $ctrl.refreshLinkActive = false;
  $ctrl.saleTypes = saleTypes;
  $ctrl.dealerCountries = dealerCountries;
  $ctrl.setSelectedContract = setSelectedContract;
  $ctrl.anyContractsAreAlert = anyContractsAreAlert;
  $ctrl.updateDealsheetTerm = updateDealsheetTerm;
  $ctrl.$onChanges = onChanges;

  function onChanges() {
    // if a contract has an override term and we currently aren't showing a refresh
    // link or a starred term offset then select the offset conract and star it
    if ($ctrl.contracts) {
      if ($ctrl.contracts.some(function (o) {
        return o.useOverrideTerm === true;
      }) && !($ctrl.offsetActive === true || $ctrl.refreshLinkActive === true)) {
        $ctrl.selectedContract = _.find($ctrl.contracts, function (o) {
          return o.useOverrideTerm === true;
        });
        $ctrl.offsetActive = true;
        $ctrl.refreshLinkActive = true;
      }
    }
  }

  function setSelectedContract(contract) {
    if (!$ctrl.showMobileView && contract && contract.payment) {
      if ($ctrl.refreshLinkActive && contract == $ctrl.selectedContract) {
        return;
      } else {
        $ctrl.offsetActive = false;
        $ctrl.refreshLinkActive = true;
        $ctrl.selectedContract = contract;
        $ctrl.onSelect({
          contract: contract
        });
      }
    }
  }

  function anyContractsAreAlert() {
    if ($ctrl.contracts) {
      return $ctrl.contracts.some(function (contract) {
        return contract.isAlert;
      });
    }
  }

  function updateDealsheetTerm(contract) {
    $ctrl.offsetActive = true;
    $ctrl.onOverride({
      contract: contract
    });
  }
}