
import { APP_SETTINGS_JSON } from '../environments/app-settings';

export const Settings: Settings = APP_SETTINGS_JSON;

// Added to global by webpack
declare var __VERSION__: string;
if (Settings.environmentVariables) {
    Settings.environmentVariables.version = __VERSION__;
}

export interface Settings {
    tagManager: { containerId: string };
    environmentVariables: EnvironmentVariables;
    apiUrls: ApiUrls;
    oidc: OidcSettings;
    legacy: Legacy;    
}

interface EnvironmentVariables {
    version: string;
    crossDomainStorageDomain: string;
    crossDomainStoragePrefix: string;
    employeeDefaultDealer: EmployeeDefaultDealer;
    environment: string;
    logDebugEnabled: boolean;
    defaultInMemoryCacheTimeoutSeconds: number;
    notificationServiceEnabled?: boolean;
    pandoInboxEnabled: boolean;
    angularProdMode: boolean;
    opportunityServiceAng8Enabled: boolean;
    fordOASISUrl: string;
}

export interface EmployeeDefaultDealer {
    dealerId: number;
    dealerGroupId: number;
}

export interface ApiUrls {
    alertMiner: string;
    reportMiner: string;
    motofuze: string;
    pandoInboxUrl: string;
    pandoDealSheetUrl: string;
}

export interface OidcSettings {
    urls: string[];
    portalUrl: string;
    opportunitiesOrigin: string;
    basePath: string;
    clientId: string;
    responseType: string;
    scope: string;
    redirectUri: string;
    logoutUri: string;
    advanceRefresh: number;
}

export interface Legacy {
    isDealsheetVersionChangeEnabled: boolean;
    iframeUrl: string;
    protocol: string;
}
