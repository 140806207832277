<img *ngIf="showAutoCheckLink && readonly" src="../../../images/AutoCheck.png" height="30" />


<a *ngIf="showAutoCheckLink && !readonly" href="javascript:void(0)"
    uiSref = "autoCheckReport" 
    [uiParams] = "{ opportunityId: opportunityId, vin: vin }" 
    target="_blank"
    class="qa-autocheck-link">
    <img src="../../../images/AutoCheck.png" height="30" />
</a>
