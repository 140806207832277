"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./carfax.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@uirouter/angular/lib/directives/uiSref"));
var i3 = __importStar(require("@uirouter/core"));
var i4 = __importStar(require("@angular/common"));
var i5 = __importStar(require("./carfax.component"));
var styles_CarfaxComponent = [i0.styles];
var RenderType_CarfaxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarfaxComponent, data: {} });
exports.RenderType_CarfaxComponent = RenderType_CarfaxComponent;
function View_CarfaxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "../../../images/carfax.png"]], null, null, null, null, null))], null, null); }
function View_CarfaxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "qa-carfax-link"], ["href", "javascript:void(0)"], ["target", "_blank"], ["uiSref", "carFaxReport"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { opportunityId: 0, vin: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "../../../images/carfax.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "carFaxReport"; var currVal_1 = _ck(_v, 3, 0, _co.opportunityId, _co.vin); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CarfaxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarfaxComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarfaxComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showCarFaxLink && _co.readonly); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showCarFaxLink && !_co.readonly); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_CarfaxComponent_0 = View_CarfaxComponent_0;
function View_CarfaxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "carfax", [], null, null, null, View_CarfaxComponent_0, RenderType_CarfaxComponent)), i1.ɵdid(1, 638976, null, 0, i5.CarfaxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CarfaxComponent_Host_0 = View_CarfaxComponent_Host_0;
var CarfaxComponentNgFactory = i1.ɵccf("carfax", i5.CarfaxComponent, View_CarfaxComponent_Host_0, { permissions: "permissions", dealerSettings: "dealerSettings", opportunityId: "opportunityId", vin: "vin", readonly: "readonly" }, {}, []);
exports.CarfaxComponentNgFactory = CarfaxComponentNgFactory;
