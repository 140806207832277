import { Component, OnInit, Input } from '@angular/core';
import { ProgramSourceTypes } from '../../../../constants';


 @Component({
   selector: 'factory-rebate',
   templateUrl: './factory-rebate.component-ng.html',
   styleUrls: ['./factory-rebate.component.scss']
 })
 export class FactoryRebateComponent implements OnInit {
     @Input() value: any;
     @Input() programSourceId: number;
     public programSourceTypes = ProgramSourceTypes;

   constructor() { }

   ngOnInit() {
   }

 }
