"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Named ActivityActionEnum to match the name in c# AutoAlert.AlertMiner.ServiceContract
exports.ActivityActionEnum = {
    Post_Note: 1,
    Log_Call: 2,
    Schedule_Call: 3,
    Schedule_Appt: 4,
    Phone_Call_Completed: 5,
    Appointment_Completed: 6,
    Expire_Phone_Call: 7,
    Expire_Appointment: 8,
    Email_Sent: 9,
    Reschedule_Call: 10,
    Reschedule_Appt: 11,
    Reassign_Call: 12,
    Reassign_Appt: 13,
    Cancel_Call: 14,
    Cancel_Appt: 15,
    Pushed_To_Compass: 16,
    Email_Template_Sent: 17,
    Manual_Push_To_CRM: 18
};
