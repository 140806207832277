
/* injects from baggage-loader */
require('./lease-mileage.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('leaseMileage', {
    templateUrl: '/app/components/deal-sheet/contract/lease-mileage.component.html',
    bindings: {
        allowedMileage: '<',
        estimatedMaturityMileage: '<',
        mileageAtDelivery: '<',
        alertExists: '<'
    }
});
