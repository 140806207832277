
<div>
    <div [ngBusy]="{busy: this.setLimitPromise, delay: 200}" style="margin-top: 0px;"></div>
    <table class="table" *ngIf="this.widgetData?.presetCounts?.length > 0">
        <thead>
            <tr>
                <th translate="lblSearchName" class="left"></th>
                <th translate="lblDateCreated" class="left"></th>
                <th translate="lblExpires" class="left"></th>
                <th translate="total"></th>
                <th translate="worked"></th>
                <th translate="notWorked"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let searchPreset of this.widgetData?.presetCounts | slice:0:this.widgetData.maxRowsDisplayed">
                <td class="left">
                    <icon class="-brightblue" *ngIf="searchPreset.type == 'Common'" icon="'common-search'"></icon>
                    <icon class="-brightblue" *ngIf="searchPreset.type == 'Dealer'" icon="'dealer-search'"></icon>
                    <icon class="-brightblue" *ngIf="searchPreset.type == 'Tag'" icon="'tag-search'"></icon>
                    <span [innerHtml]=" "></span>
                    <span [textContent]="searchPreset.searchConditionName" class="search-condition-name"></span>
                </td>
                <td class="left">
                    <span>{{searchPreset.insertDateUtc | localeDate}}</span>
                </td>
                <td class="left">
                    <span>{{searchPreset.searchActiveEndDate | localeDate}}</span>
                </td>
                <td>
                    <span class="count cursor"
                          [textContent]="searchPreset.totalCount"
                          uiSref="search.form"
                          [uiParams]="{
                            presetId: searchPreset.userSearchConditionID,
                            recentActivity: null,
                            dealerId: this.selectedDealerId
                            }">
                    </span>
                </td>
                <td [ngClass]="{'search-preset-count-na': searchPreset.activityCount == -1}">
                    <span class="count cursor" [textContent]="searchPreset.activityCount"
                          *ngIf="searchPreset.activityCount != -1"
                          uiSref="search.form"
                          [uiParams]="{
                            presetId: searchPreset.userSearchConditionID,
                            recentActivity: 1,
                            dealerId: this.selectedDealerId
                            }">
                    </span>
                    <span class="nocount"
                          *ngIf="searchPreset.activityCount == -1"
                          [popover]="popTemplate"
                          placement="left" containerClass="search-preset-count-tooltip" triggers="mouseenter:mouseleave">n/a<span style="font-size:13px">*</span></span>
                    <ng-template #popTemplate>
                        <p translate="presetNoActivityMsg"></p>
                    </ng-template>
                </td>
                <td [ngClass]="{'search-preset-count-na': searchPreset.noActivityCount == -1}">
                    <div class="count cursor"
                         *ngIf="searchPreset.noActivityCount != -1"
                         uiSref="search.form"
                         [uiParams]="{
                            presetId: searchPreset.userSearchConditionID,
                            recentActivity: 0,
                            dealerId: this.selectedDealerId
                            }">
                        <span [textContent]="searchPreset.noActivityCount"></span>
                    </div>
                    <div class="nocount" *ngIf="searchPreset.noActivityCount == -1">
                        <span [popover]="popTemplate"
                              placement="left" containerClass="search-preset-count-tooltip"
                              triggers="mouseenter:mouseleave">n/a<span style="font-size:13px">*</span></span>
                        <ng-template #popTemplate>
                            <p translate="presetHasActivityMsg"></p>
                        </ng-template>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <span *ngIf="this.widgetData?.presetCounts && this.widgetData.presetCounts.length == 0" translate="noInformationAvailable"></span>

    <button class="transparent pull-right"
            *ngIf="this.widgetData?.maxRowsDisplayed < this.maxRowLimit && (this.widgetData?.unusedSearchPresets?.length > 0 || this.widgetData?.searchPresets?.length > this.widgetData?.maxRowsDisplayed)"
            (click)="this.increaseLimit()">
        <span class="action" translate="showMoreSearches"></span>
    </button>
</div>

