import angular from 'angular';

angular.module('AaApp.Opp').constant('reportCategories', {
    opportunities: 1,
    social: 2,
    campaigns: 3,
    communication: 4,
    onetoone: 5,
    performance: 6,
    autoassistant: 7,
    geoalert: 8,
    beta: 9,
    partnerExport: 10
});
