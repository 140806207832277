import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UserService } from './../../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingResponseDto, DealerBankRateTierDto } from '../../generated/models';

@Component({
    selector: 'admin-settings-programs',
    templateUrl: './admin-settings-programs.component-ng.html'
})

export class AdminSettingsProgramsComponent {

    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};
    @Output() onSaveChanges = new EventEmitter();

    public perUnitLabelKey: String;

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public userService: UserService,
        public logger: ToasterLoggerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit() {
        this.perUnitLabelKey = this.tService.instant((this.dsa.isMetric) ? 'PerLiterLabel' : 'PerGallonLabel');
    }

    handleLeasePenaltyFeeChanged(item: DealerBankRateTierDto): void {

        if (item.leasePenaltyFee < 0) {
            item.leasePenaltyFee = 0;
            return;
        }

        if (item.leasePenaltyFee > 5) {
            item.leasePenaltyFee = 5;
            return;
        }
    }

    public getResidualMileageOptions(bankID: Number) {
        const rmo = this.dsa.residualMileageOptions.find(r => r.bankID === bankID);

        const options = [];

        if (rmo != null) {

            options.push({
                value: rmo.residualMileage10kLabel, text: this.formatNumber(rmo.residualMileage10kLabel)
            });

            options.push({
                value: rmo.residualMileage12kLabel, text: this.formatNumber(rmo.residualMileage12kLabel)
            });

            options.push({
                value: rmo.residualMileage15kLabel, text: this.formatNumber(rmo.residualMileage15kLabel)
            });
        }

        return options;
    }

    private formatNumber(num: Number) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}
