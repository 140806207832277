import { tokenExpired$, tokenMissing$, unauthorized$ } from '../../modules/oidc-auth/events';
import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, Subject, of } from 'rxjs';
import { LogFactory, NamedLog } from '../log-factory.service';

import { map, catchError } from 'rxjs/operators';
import { TokenService } from '../../modules/oidc-auth/token.service';
import { StorageService } from '../../modules/storage/storage.service';
import { AuthService } from '../../ajs-upgraded-providers';
import { ToasterLoggerService as LoggerService } from '../../core/toaster-logger.service';
import { HttpInterceptorOptionsService } from './http-interceptor-options.service';

@Injectable()
export class NgOidcHttpInterceptor implements HttpInterceptor {
    private log: NamedLog;

    constructor(logFactory: LogFactory, @Inject(AuthService) private authService, private tokenService: TokenService,
        private loggerService: LoggerService,
        private storageService: StorageService,
        private httpInterceptorOptionsService: HttpInterceptorOptionsService) {
        this.log = logFactory.get('oidcHttpInterceptor');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = req;
        if (this.urlMatchesConfig(req.url)) {
            let appendBearer = false;

            if (this.authService.config.enableRequestChecks) {
                // Only append token when it's valid.
                if (this.tokenService.hasToken()) {
                    if (this.tokenService.hasValidToken()) {
                        appendBearer = true;
                    } else {
                        this.broadcast(tokenExpired$, { request: req });
                    }
                } else {
                    this.broadcast(tokenMissing$, { request: req });
                }
            } else {
                appendBearer = this.tokenService.hasToken();
            }

            if (appendBearer) {
                const token = this.tokenService.getTokenByType('access');
                newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + token),
                    withCredentials: true
                });
            }
        } 
             
        // do something on success
        return next.handle(newReq)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse && (<HttpResponse<any>>event).status > 400) {
                        return this.handleResponse(event);
                    }

                    return event;
                }
                ),
                catchError((err: any, caught) => {
                    if (err instanceof HttpErrorResponse) {
                        return this.handleResponseError(err);
                    }

                    return throwError(err);
                })
            );

        
    }

    private handleResponse(response: HttpResponse<any>): any {
        
        if (this.urlMatchesConfig(response.url)) {
            // Proactively check if the token will expire soon
            this.authService.validateExpirity();
        }

        return response;
    }

    private handleResponseError(httpErrorResponse: HttpErrorResponse) {
        if (httpErrorResponse.status === 401) {
            if (!this.tokenService.hasToken()) {
                // There was probably no token attached, because there is none
                this.broadcast(tokenMissing$, { response: httpErrorResponse.error });
            } else if (!this.tokenService.hasValidToken()) {
                // Seems the token is not valid anymore
                this.broadcast(tokenExpired$, { response: httpErrorResponse.error });
            } else if (httpErrorResponse.error === 'User is disabled' ||
                httpErrorResponse.error === 'User is locked' ||
                httpErrorResponse.error === 'IP Address is not valid') {
                this.broadcast(unauthorized$, { response: httpErrorResponse.error });
            } else if (httpErrorResponse.url && httpErrorResponse.url.indexOf('LoggedInUser') > -1) {
                this.broadcast(unauthorized$);
            }
        } else {
            // TODO this should be moved to its own interceptor since it doesn't have anything to do with OIDC handling
            if (!this.httpInterceptorOptionsService.disableErrorHandlerSetting) { // "disableErrorHandlerSetting = true" means handle error manually
                if (httpErrorResponse.status !== -1 && this.isErrorEndpoint(httpErrorResponse)) {
                    if (this.storageService.getItem('userProfile')) {

                        if (this.loggerService) {
                            if (httpErrorResponse && httpErrorResponse.error && httpErrorResponse.error.error && httpErrorResponse.error.error.translationKey) {
                                this.loggerService.genericError(httpErrorResponse.error.error.translationKey, httpErrorResponse, 'responseError');
                            } else {
                                const message = (httpErrorResponse.error) ? httpErrorResponse.error.message : httpErrorResponse.message;
                                this.loggerService.genericError('responseError with API call',
                                    message, 'oidcHttpInterceptor:responseError');
                            }

                        } else {
                            this.log.error('responseError with API call', httpErrorResponse.error.message, 'oidcHttpInterceptor:responseError');
                        }
                    }
                }
            }
        }

        return throwError(httpErrorResponse);
    }

    private urlMatchesConfig(url: string) {
        return this.authService.config.urls && this.authService.config.urls.some(u => url.startsWith(u));
    }

    private broadcast(event: Subject<any>, data?: any) {
        event.next(data);
    }

    private isErrorEndpoint(response: HttpErrorResponse) {
        const excludedEndpoints = ['CreditPrescreenRequest'];

        for (let i = 0; i < excludedEndpoints.length; i++) {
            if (response.url && response.url.indexOf(excludedEndpoints[i]) > -1) {
                return false;
            }
        }

        return true;
    }
}
