<div class="row poweredby-blackbook">
    <div class="col-sm-5 col-xs-7">
        <label *ngIf="tradeSourceId === tradeValueTypes.blackBookTradeValue && tradeValueIsManual === false">{{'poweredbyBlackBook' | translate}}</label>
        <label *ngIf="tradeSourceId === tradeValueTypes.galvesTradeValue && tradeValueIsManual === false">{{'poweredbyGalves' | translate}}</label>
        <label *ngIf="tradeValueIsManual === true">{{'tradeValueManual' | translate}}</label>
    </div>
  <div class="col-sm-7 col-xs-5">
      <div *ngIf="tradeValueIsManual === true">
          <label>{{tradeValueInsertDt}}</label>
      </div>
      <div *ngIf="tradeSourceId === tradeValueTypes.blackBookTradeValue && tradeValueIsManual === false">
          <img class="qa-trade-source-logo blackbook-us-logo" *ngIf="!isCanada()" src="images/blackbook.png" />
          <img class="black-book-canada qa-trade-source-logo" *ngIf="isCanada()" src="images/black-book-canada.png" />
      </div>
      <img class="galves qa-trade-source-logo" *ngIf="tradeSourceId === tradeValueTypes.galvesTradeValue && tradeValueIsManual === false" src="images/galves-market-data.png" />
  </div>
</div>