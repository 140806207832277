import { Settings } from "../constants";
import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpHeaders, HttpParams, HttpBackend } from "@angular/common/http";
import { LogFactory, NamedLog } from "./log-factory.service";
import { StorageService } from "../modules/storage/storage.service";

const CONFIG = {
    enabled: Settings.environmentVariables.pandoInboxEnabled,
    url: Settings.apiUrls.motofuze,
    pandoInboxUrl: Settings.apiUrls.pandoInboxUrl,
    motofuzeToken: null,
    tokenPromise: null
};

@Injectable({
    providedIn: 'root'
})
export class CommunicationApiService {
    private log: NamedLog;
    private httpClient: HttpClient;

    constructor(logFactory: LogFactory, private storageService: StorageService, httpBackend: HttpBackend) {
        this.log = logFactory.get("communicationApi");
        this.httpClient = new HttpClient(httpBackend);
    }

    public getPandoInboxConfig(): {} {
        return { ...CONFIG };
    }

    public getCurrentUser(): any {
        return this.requestWithToken('users/currentuser')
            .then(function (response) {
                return response.body.Data;
            }, function (error) {
                //console.log(error);
                return null;
            });
    }

    public getGlobalFrameConfig(): any {
        return this.requestWithToken('users/globalframeconfig')
            .then(function (response) {
                return response.body.Data;
            }, function (error) {
                return null;
            });
    }

    public get(path, request: any): Promise<any> {
        return this.requestWithToken(path, { ...request, method: 'GET' });
    }

    public put(path, request): Promise<any> {
        return this.requestWithToken(path, { ...request, method: 'PUT' });
    }

    public post(path, request): Promise<any> {
        return this.requestWithToken(path, { ...request, method: 'POST' });
    }

    public delete(path, request): Promise<any> {
        return this.requestWithToken(path, { ...request, method: 'DELETE' });
    }

    private requestWithToken(path, request?: any): Promise<any> {
        if (!CONFIG.url) {
            return Promise.reject(new Error('MotoFuze API has not been configured'));
        }

        const fetchTokenAndRequest = (request: any) => {
            let tokenPromise = CONFIG.tokenPromise;
            if (!tokenPromise) {
                tokenPromise = CONFIG.tokenPromise = tradeAccessTokenForMotofuzeToken()
                    .then(token => {
                        CONFIG.tokenPromise = null;
                        return token;
                    }, err => {
                        CONFIG.tokenPromise = null;
                        throw err;
                    });
            }

            return tokenPromise.then((motofuzeToken): Promise<any> => {
                CONFIG.motofuzeToken = motofuzeToken;


                return this.httpClient.request<any>(request.method, request.url, {
                    withCredentials: false,
                    headers: new HttpHeaders({ 'Authorization': "Bearer " + motofuzeToken }),
                    responseType: 'json',
                    observe: 'response',
                    body: request.data
                }).toPromise();
            })
        }

        const tradeAccessTokenForMotofuzeToken = (): Promise<any> => {
            const accessToken = this.storageService.getItem('accessToken');
            if (!accessToken) {
                return Promise.reject(new Error("No AccessToken available"));
            }

            if (!CONFIG.url) {
                return Promise.reject(new Error('MotoFuze API has not been configured'));
            }

            const url = CONFIG.url + 'account/ObtainLocalAccessToken';

            // Creating our own client with the standard http backend allows us to skip the interceptors
            // https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
            // This request fails if we include our standard 'no-cache' headers

            return this.httpClient.request<any>('GET', url, {
                withCredentials: false,
                params: new HttpParams({
                    fromObject: {
                        'provider': 'sso',
                        'externalAccessToken': accessToken,
                    }
                }),
                responseType: 'json',
                observe: 'response'
            }).toPromise()
                .then(response => {
                    if (response && response.body && response.body['access_token']) {
                        return response.body['access_token'];
                    } else {
                        this.log.warn("Unexpected response for ObtainLocalAccessToken", JSON.stringify(response));
                        throw new Error("Unable to get the MotoFuze access token.");
                    }
                }, function (error) {
                    //console.log(error);
                    return null;
                });
        }

        if (request == null) {
            request = { method: "GET", url: "", data: "" };
        }

        const url = CONFIG.url + path;
        request.url = url;
        const motofuzeToken = CONFIG.motofuzeToken;
        if (!motofuzeToken) {
            // If we don't have a saved token, fetch the token then make a
            // single request.
            return fetchTokenAndRequest(request);

        } else {
            // Make one request with the saved token, and if it fails
            // unauthorized, fetch a new token and re-request.
            
            return this.httpClient.request<any>(request.method, url, {
                withCredentials: false,         
                headers: new HttpHeaders({ 'Authorization': "Bearer " + motofuzeToken }),      
                responseType: 'json',
                observe: 'response', 
                body: request.data
            }).toPromise()
                .catch(response => {
                    if (response.status == 401) {
                        // If unauthorized, fetch a new token and retry once
                        return fetchTokenAndRequest(request);
                    } else {
                        throw request;
                    }
                });
        }
    }
}
