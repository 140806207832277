import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import _ from 'lodash';
import { difference } from 'lodash';
import { map } from 'rxjs/operators';
import { ResetAutoAssignmentsDto, UserSearchConditionDealerLayOutExtDto, PandoGroupDto } from '../generated/models';
import { UserSearchConditionClientService, PandoPassthroughClientService } from '../generated/services';
import { HttpInterceptorOptionsService } from './http/http-interceptor-options.service';
import { CookieStorage } from '../modules/storage/cookie-storage.service';
import { CrossDomainStorageService } from '../modules/storage/cross-domain-storage.service';

@Injectable({
    providedIn: 'root'
})
export class SearchPresetService {
    public readonly defaultRowLimit: number = 5;
    public readonly maxRowLimit: number = 50;

    constructor(
        private userSearchConditionClientService: UserSearchConditionClientService,
        private pandoPassthroughClientService: PandoPassthroughClientService,
        @Inject(CrossDomainStorageService) private crossDomainStorageService: CookieStorage,
        private httpInterceptorOptionsService: HttpInterceptorOptionsService) { }

    public getSearchPresets(): Promise<any> {
        return this.userSearchConditionClientService.GetSearchesGET().toPromise();
    }

    public getSearchModel(userSearchConditionId: number): Promise<any> {
        return this.userSearchConditionClientService.GetSearchModelByUsersearchconditionidGET(userSearchConditionId).toPromise();
    }

    public getSearchPreset(userSearchConditionId: number): Promise<any> {
        return this.userSearchConditionClientService.ByIdGET(userSearchConditionId).toPromise();
    }

    public deleteSearchPreset(userSearchConditionId: number): Promise<null> {
        return this.userSearchConditionClientService.DeleteUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId).toPromise();
    }

    public shareSearchPreset(userSearchConditionId: number): Promise<null> {
        return this.userSearchConditionClientService.ShareUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId).toPromise();
    }

    public unshareSearchPreset(userSearchConditionId: number): Promise<null> {
        return this.userSearchConditionClientService.UnshareUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId).toPromise();
    }

    public getDealerSearchPresetLayouts(selectedDealerId: number): Promise<UserSearchConditionDealerLayOutExtDto[]> {
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        }
        return this.userSearchConditionClientService.GetDealerLayOutExtsGET().toPromise();
    }

    public getActiveDealerSearchPresetLayouts(selectedDealerId: number): Promise<UserSearchConditionDealerLayOutExtDto[]> {
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        }
        return this.userSearchConditionClientService.GetActiveDealerLayOutExtsGET().toPromise();
    }

    public async getDealerSearchPresetLayoutCounts(hardRefresh: boolean, selectedDealerId: number, maxRowsDisplayed: number) {
        maxRowsDisplayed = maxRowsDisplayed || this.defaultRowLimit;
        if (selectedDealerId == null) {
            const selectedDealers = this.crossDomainStorageService.getItem('selectedDealers');
            if (selectedDealers.dealers.length > 1) {
                // The dealer dropdown is present but "All Dealerships" is
                // selected, don't bother getting layouts/counts because this
                // widget isn't supported anyway
                return { presetCounts: [], maxRowsDisplayed };
            }
        }

        const layouts = await this.getActiveDealerSearchPresetLayouts(selectedDealerId);

        const availableSearchPresets = this.mapGetResponse(layouts as UserSearchConditionDealerLayOutExtDto[]);

        if (availableSearchPresets.length == 0) {
            return {
                searchPresets: [],
                unusedSearchPresets: [],
                presetCounts: [],
                maxRowsDisplayed,
            };
        }

        availableSearchPresets.sort(this.byOrder);

        // When doing a hardRefresh, we want to refresh all counts, even if they're not going to be displayed.
        const searchPresets = hardRefresh
            ? availableSearchPresets.slice(0, this.maxRowLimit)
            : availableSearchPresets.slice(0, maxRowsDisplayed);

        const widgetData = await this.getSearchPresetCounts(searchPresets, hardRefresh);

        return {
            ...widgetData,
            unusedSearchPresets: difference(availableSearchPresets, searchPresets).sort(this.byOrder),
            maxRowsDisplayed,
        };

    }

    private mapGetResponse(layouts: UserSearchConditionDealerLayOutExtDto[]) {
        if (Array.isArray(layouts)) {
            return layouts;
        }
        return [] as UserSearchConditionDealerLayOutExtDto[];
    }

    public getSearchPresetCounts(searchPresets: Array<UserSearchConditionDealerLayOutExtDto>, hardRefresh: boolean = false): Promise<any> {
        const observable = this.userSearchConditionClientService.GetSearchCountsPOST({ hardRefresh: hardRefresh, layouts: searchPresets.sort(this.byOrder) });
        return observable
            .pipe(
            map(response => {
                return { searchPresets: searchPresets, presetCounts: response };
                })
            ).toPromise();
    }

    private byOrder(searchPreset1: UserSearchConditionDealerLayOutExtDto, searchPreset2: UserSearchConditionDealerLayOutExtDto) {
        return searchPreset1.order - searchPreset2.order;
    }

    public updateDealerSearchPresetLayouts(dealerId: number, searchPresetLayouts: Array<UserSearchConditionDealerLayOutExtDto>): Promise<boolean> {
        return this.userSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTResponse({ dealerId: dealerId, layouts: searchPresetLayouts })
            .pipe(
                map(response => resolveLayoutUpdate(response))
            ).toPromise();

        function resolveLayoutUpdate(response: HttpResponse<any>) {
            return response.status === 204;
        }
    }

    public getPandoCustomStoreGroups(dealerId: number): Promise<PandoGroupDto[]> {
        this.httpInterceptorOptionsService.disableErrorHandler();
        return this.pandoPassthroughClientService.GetPandoCustomStoreGroupsByDealeridGET(dealerId).toPromise();
    }

    public updateAutoAssignments(assignments: ResetAutoAssignmentsDto): Promise<boolean> {
        return this.userSearchConditionClientService.ResetAutoAssignmentsPOST(assignments).toPromise();
    }

    public executePandoXSearch(dealerId: number, userSearchConditionId: number): Promise<null> {
        return this.userSearchConditionClientService.ExecutePandoXPOST({dealerId, userSearchConditionId} as UserSearchConditionClientService.ExecutePandoXPOSTParams).toPromise();
    }
}
