
/* injects from baggage-loader */
require('./html-textarea.component.html');

import angular from 'angular'
import { OpportunitiesModule } from '../core/opportunities.module'
import '../scripts/redactor'

OpportunitiesModule.component('htmlTextarea', {
    templateUrl: '/app/components/html-textarea.component.html',
    controller: HtmlTextareaController,
    bindings: {
        textValue: '@',
        onEdit: '&'
    }
})

function HtmlTextareaController($element, apiUrls, logger) {
    "ngInject";

    var $ctrl = this;
    $ctrl.$onChanges = $onChanges;
    $ctrl.onUpdate = onUpdate;

    function $onChanges(changes) {
        if (changes.textValue) {
            let redactor = getRedactor();
            redactor.set($ctrl.textValue);
        }
    }

    function onUpdate() {
        let redactor = getRedactor();
        var data = redactor.get();
        if (data != "")
            $ctrl.onEdit({action:data});
    }

    function getRedactor() {
        return $ctrl.redactor = $ctrl.redactor || createRedactor();
    }
    
    function createRedactor() {        
        const textArea = $element.find('.editor').redactor({
            linebreaks: true,
            buttons: ['html', 'formatting', 'bold', 'italic', 'image', 'unorderedlist', 'orderedlist', 'outdent', 'indent', 'table', 'link', 'alignment', 'horizontalrule'],
            plugins: ['button', 'layout', 'fontsize', 'fontfamily', 'fontcolor'],            
            imageUpload: apiUrls.alertMiner + '/api/FileUpload/UploadImage',
            changeCallback: 
                function () {
                    $ctrl.textValue = this.get();
                    $ctrl.onEdit({action:$ctrl.textValue});
                },
            uploadErrorCallback:
                function (data) {                    
                    logger.warning("uploadFailedErrorMsg");
                }
        });

        return textArea.data('redactor');
    }
}
