
/* injects from baggage-loader */
require('./fuel-difference.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('fuelDifference', {
    templateUrl: '/app/components/deal-sheet/vehicle/fuel-difference.component.html',
    controller: FuelDifferenceController,
    bindings: {
        fuelEconomyComparison: '<',
        onClick: '&'
    }
});

/* @ngInject */
function FuelDifferenceController() {
    var $ctrl = this;

    $ctrl.costDiffClass = costDiffClass;
    $ctrl.costDiffHasValue = costDiffHasValue;

    function costDiffHasValue() {
        return $ctrl.fuelEconomyComparison && $ctrl.fuelEconomyComparison.estimatedMonthlyCostDifference !== null;
    }

    function costDiffClass() {
        if (costDiffHasValue()) {
            return $ctrl.fuelEconomyComparison.estimatedMonthlyCostDifference === 0 ? 'base-fuel' :
            $ctrl.fuelEconomyComparison.estimatedMonthlyCostDifference > 0 ? 'down-fuel' : 'up-fuel';
        }
        return 'base-fuel';
    }
}
