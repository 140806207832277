import angular from 'angular';

angular.module('AaApp.Opp').filter('localeDateTimeWithDay', localeDateTimeWithDay);

localeDateTimeWithDay.$inject = ['$filter', 'storageService'];

/* @ngInject */
function localeDateTimeWithDay($filter, storageService) {
    return function (date) {
        var savedCultureName = storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        var format = '';
        var isToday = isDateToday(date);

        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                format = isToday ? "'Today at' hh:mm a" : "EEEE, dd MMM yyyy 'at' hh:mm a";
                break;
            case 'fr-ca':
            case 'fr-fr':
                format = isToday ? "'Aujourd''hui à' hh:mm a" : "EEEE, d MMM yyyy 'à' hh:mm a";
                break;
            case 'en-us':
            case 'en-ca':
            default:
                format = isToday ? "'Today at' h:mm a" : "EEEE, d MMM yyyy 'at' h:mm a";
                break;
        }
        return $filter('date')(date, format);
    }

    function isDateToday(date) {
        var now = new Date();
        var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        var testDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var ret = today - testDate;
        return ret === 0;
    }

}
