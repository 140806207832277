"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./tagged-customers.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@angular/common"));
var i3 = __importStar(require("@ngx-translate/core"));
var i4 = __importStar(require("../../icon/icon.component.ngfactory"));
var i5 = __importStar(require("../../icon/icon.component"));
var i6 = __importStar(require("@angular/platform-browser"));
var i7 = __importStar(require("./tagged-customers.component"));
var i8 = __importStar(require("../../../ajs-upgraded-providers"));
var i9 = __importStar(require("../../../services/opportunity2.service"));
var i10 = __importStar(require("@uirouter/core"));
var styles_TaggedCustomersComponent = [i0.styles];
var RenderType_TaggedCustomersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaggedCustomersComponent, data: {} });
exports.RenderType_TaggedCustomersComponent = RenderType_TaggedCustomersComponent;
function View_TaggedCustomersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "tag-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "tag-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["translate", "programsAndIncentives"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "placeholder": 0 }), i1.ɵdid(6, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "tag-container tagTypeTwo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToOppSearch(_v.context.$implicit.tagID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "tag-int"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "description-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "tag-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, (_v.context.index != 0)); _ck(_v, 4, 0, currVal_0); var currVal_1 = "programsAndIncentives"; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.count; _ck(_v, 10, 0, currVal_2); var currVal_3 = _v.context.$implicit.tagText; _ck(_v, 13, 0, currVal_3); }); }
function View_TaggedCustomersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "tag-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "tag-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["translate", "customTags"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "placeholder": 0 }), i1.ɵdid(6, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "tag-container tagTypeOne"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToOppSearch(_v.context.$implicit.tagID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "tag-int"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "description-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "tag-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, (_v.context.index != 0)); _ck(_v, 4, 0, currVal_0); var currVal_1 = "customTags"; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.count; _ck(_v, 10, 0, currVal_2); var currVal_3 = _v.context.$implicit.tagText; _ck(_v, 13, 0, currVal_3); }); }
function View_TaggedCustomersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "panel panel-default dark"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "panel-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["translate", "taggedOpportunities"]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "cursor dashboard-widget-header-item pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "icon", [["class", "icon aa-icon-refresh"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(6, 638976, null, 0, i5.IconComponent, [i6.DomSanitizer], null, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "panel-body"], ["style", "position:relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["cg-busy", "{promise:widgetDataPromise,backdrop:false}"], ["class", "cg-busy-widget-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["style", "position:relative"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedCustomersComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedCustomersComponent_2)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "taggedOpportunities"; _ck(_v, 3, 0, currVal_1); _ck(_v, 6, 0); var currVal_3 = _co.tagDataType2; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.tagDataType1; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.tagDataType1.length && !_co.tagDataType2.length); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform("refreshContent")), ""); _ck(_v, 5, 0, currVal_2); }); }
exports.View_TaggedCustomersComponent_0 = View_TaggedCustomersComponent_0;
function View_TaggedCustomersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tagged-customers", [], null, null, null, View_TaggedCustomersComponent_0, RenderType_TaggedCustomersComponent)), i1.ɵdid(1, 638976, null, 0, i7.TaggedCustomersComponent, [i8.OpportunityService, i9.Opportunity2Service, i10.StateService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TaggedCustomersComponent_Host_0 = View_TaggedCustomersComponent_Host_0;
var TaggedCustomersComponentNgFactory = i1.ɵccf("tagged-customers", i7.TaggedCustomersComponent, View_TaggedCustomersComponent_Host_0, { selectedDealerId: "selectedDealerId" }, {}, []);
exports.TaggedCustomersComponentNgFactory = TaggedCustomersComponentNgFactory;
