import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StorageService } from '../modules/storage/storage.service';

@Pipe({
    name: 'localeDate'
})
export class LocaleDatePipe extends DatePipe implements PipeTransform {

    constructor(private storageService: StorageService) {
        super('en-us');
    }
    transform(date: any, args?: any): any {
        const savedCultureName = this.storageService.getItem('cultureName');
        const cultureName = savedCultureName ? savedCultureName : 'en-us';

        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                return super.transform(date, 'dd/MM/yyyy');
            case 'en-ca':
                return super.transform(date, 'dd/MM/yyyy');
            case 'fr-fr':
                return super.transform(date, 'dd/MM/yyyy');
            case 'fr-ca':
                return super.transform(date, 'yyyy-MM-dd');
            case 'en-us':
            default:
                return super.transform(date, 'MM/dd/yyyy');
        }

    }
}
