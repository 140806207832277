
import { Injectable, Inject } from '@angular/core';
import {
    HttpEvent, HttpHandler, HttpRequest, HttpInterceptor
} from '@angular/common/http';
import { Settings } from '../../constants/app-settings';

import { Observable } from 'rxjs';
import { StorageService } from '../../modules/storage/storage.service';
import { CookieStorage } from '../../modules/storage/cookie-storage.service';
import { CrossDomainStorageService } from '../../modules/storage/cross-domain-storage.service';
import { HttpInterceptorOptionsService } from './http-interceptor-options.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class NgSelectedDealersInterceptor implements HttpInterceptor {

    readonly URL_PREFIXES_TO_MODIFY: string[] = [];
    readonly CONFIG = {
        employeeDefaultDealer: undefined
    };

    constructor(private storageService: StorageService,
        private httpInterceptorOptionsService: HttpInterceptorOptionsService, 
        @Inject(CrossDomainStorageService) private crossDomainStorageService: CookieStorage) {
        if (Settings.environmentVariables.employeeDefaultDealer) {
            this.CONFIG.employeeDefaultDealer = Settings.environmentVariables.employeeDefaultDealer;

            this.URL_PREFIXES_TO_MODIFY.push(Settings.apiUrls.alertMiner);
            this.URL_PREFIXES_TO_MODIFY.push(Settings.apiUrls.reportMiner);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Only continue if the request URL matches a configured prefix
        if (!this.URL_PREFIXES_TO_MODIFY.some(prefix => req.url.startsWith(prefix))) {
            return next.handle(req);
        }

        const selectedDealers = this.getSelectedDealers();

        let selectedDealerGroupId = selectedDealers && selectedDealers.dealerGroupId ? selectedDealers.dealerGroupId : null;
        let selectedDealerIds = selectedDealers && selectedDealers.dealerIds ? selectedDealers.dealerIds : null;

        let overrideDealerIds = this.httpInterceptorOptionsService.overrideDealerIdsSetting;
        
        // Employee users default to MBDEMO
        // This block should only be hit for employees.
        if (!selectedDealerGroupId || !selectedDealerIds) {
            selectedDealerGroupId = this.CONFIG.employeeDefaultDealer.dealerGroupId;
            selectedDealerIds = [this.CONFIG.employeeDefaultDealer.dealerId];
        }

        const newHeaders = {};

        newHeaders['dealers'] = selectedDealerIds.join(',');
        newHeaders['dealergroupid'] = selectedDealerGroupId;

        if (overrideDealerIds && overrideDealerIds.length > 0) {
            newHeaders['dealerIdOverride'] = overrideDealerIds.join(',');
        }

        // Requests are immutable so we have to clone if we want to change them
        const newReq = req.clone({setHeaders: newHeaders});

        return next.handle(newReq);
    }

    private getSelectedDealers() {
        let selectedDealers = this.storageService.getItem('impersonatingSelectedDealers');

        if (selectedDealers != null) {
            const userProfile = this.storageService.getItem('userProfile');

            if (userProfile && userProfile.employeeUser) {
                this.storageService.removeItem('impersonatingSelectedDealers');
            }
        } else {
            selectedDealers = this.crossDomainStorageService.getItem('selectedDealers');
        }

        if (selectedDealers && selectedDealers.dealers && selectedDealers.dealers.length > 0) {
            selectedDealers.dealerIds = selectedDealers.dealers.map(dealer => dealer.id);
        }

        return selectedDealers;
    }
}
