MileageController.$inject = ["vehicleTypes"];

/* injects from baggage-loader */
require('./mileage.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('mileage', {
  templateUrl: '/app/components/deal-sheet/vehicle/mileage.component.html',
  controller: MileageController,
  bindings: {
    value: '<',
    vehicleType: '<'
  }
});
/* @ngInject */

function MileageController(vehicleTypes) {
  var $ctrl = this;
  $ctrl.vehicleTypes = vehicleTypes;
}