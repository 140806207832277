
/* injects from baggage-loader */
require('./search-preset-settings-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('searchPresetSettingsModal', {
    templateUrl: '/app/components/search/search-preset-settings-modal.component.html',
    controller: SearchPresetSettingsModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
});

/* @ngInject */
function SearchPresetSettingsModalController($uibModal, $document) {
    var $ctrl = this;

    $ctrl.getSelections;
    $ctrl.submit = submit;
    $ctrl.hasSelections;
    $ctrl.onSelection = onSelection;

    function submit() {
        var selections = $ctrl.getSelections();

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'confirmationModal',
            resolve: {
                title: function () {
                    return "Please Confirm";
                },
                message: function () {
                    return "Are you sure you want to clear the selected auto-assignments from the entire portfolio? This action cannot be undone.";
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "confirm"; // translation key
                }
            }
        });

        modal.result.then(function () {
            $ctrl.close({ $value: { clearOwnership: selections.clearOwnership, clearAccess: selections.clearAccess } });
        });

    }

    function onSelection(hasSelections) {
        $ctrl.hasSelections = hasSelections;
    }
}
