"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./payment-difference.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../../../../filters/locale-currency.pipe"));
var i3 = __importStar(require("../../../../../modules/storage/storage.service"));
var i4 = __importStar(require("@ngx-translate/core"));
var i5 = __importStar(require("./payment-difference.component"));
var styles_PaymentDifferenceComponent = [i0.styles];
var RenderType_PaymentDifferenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentDifferenceComponent, data: {} });
exports.RenderType_PaymentDifferenceComponent = RenderType_PaymentDifferenceComponent;
function View_PaymentDifferenceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LocaleCurrencyPipe, [i3.StorageService]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-xs-5 no-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, [" (", " ", ") "])), i1.ɵppd(4, 1), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPaymentClass(); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.Math.abs(_co.paymentDifference))); var currVal_2 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 5).transform(_co.getPaymentDifferenceLabel())); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
exports.View_PaymentDifferenceComponent_0 = View_PaymentDifferenceComponent_0;
function View_PaymentDifferenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "payment-difference", [], null, null, null, View_PaymentDifferenceComponent_0, RenderType_PaymentDifferenceComponent)), i1.ɵdid(1, 114688, null, 0, i5.PaymentDifferenceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PaymentDifferenceComponent_Host_0 = View_PaymentDifferenceComponent_Host_0;
var PaymentDifferenceComponentNgFactory = i1.ɵccf("payment-difference", i5.PaymentDifferenceComponent, View_PaymentDifferenceComponent_Host_0, { vehicle: "vehicle", hasPaymentsPermission: "hasPaymentsPermission" }, {}, []);
exports.PaymentDifferenceComponentNgFactory = PaymentDifferenceComponentNgFactory;
