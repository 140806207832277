import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';

@Component({
  selector: 'carproof',
  templateUrl: './carproof.component-ng.html',
  styleUrls: ['./carproof.component.scss']
})
export class CarproofComponent implements OnInit, OnChanges {
  @Input() permissions: DealSheetPermissions;
  @Input() dealerSettings: any;
  @Input() opportunityId: string;
  @Input() vin: string;
  @Input() readonly: boolean;
  public showCarProofLink = false;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    if (!this.permissions || !this.dealerSettings || !this.vin) {
      return;
    }

    this.showCarProofLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableCarProof;
  }
}
