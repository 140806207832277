import { Inject, Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, flatMap, map, take } from 'rxjs/operators';
import { LegacyService } from '../ajs-upgraded-providers';
import { SendTextWithUpgradeProposalResponse, TextableStatusModel, TextMessageDto, RoleDto } from '../generated/models';
import { RoleClientService } from '../generated/services';

@Injectable()
export class AdminRoleService {
    constructor(@Inject(LegacyService) private legacyService,
        private roleClientService: RoleClientService) {
    }

    GetRoleOptions(): Promise<any> {
        return this.roleClientService.GetRoleOptionsGET().toPromise();
    }

    GetRoleModuleAccess(roleId: number) {
        return this.roleClientService.GetRoleModuleAccessByRoleidGET(roleId).toPromise();
    }

    GetRoleAccessRights(roleId: number) {
        return this.roleClientService.GetRoleAccessRightsByRoleidGET(roleId).toPromise();
    }

    GetRoleAccessRightCategories() {
        return this.roleClientService.GetRoleAccessRightCategoriesGET().toPromise();
    }

    GetRoleModuleCategories(roleId: number) {
        return this.roleClientService.GetRoleModuleCategoriesByRoleidGET(roleId).toPromise();
    }

    RoleAccessUpdate(roleId: number, moduleIdList: any, accessRightIdList: any) {
        return this.roleClientService.RoleAccessUpdateByRoleidGET({ roleId: roleId, moduleIdList: moduleIdList, accessRightIdList: accessRightIdList }).toPromise();
    }

    RoleSave(role: RoleDto) {
        return this.roleClientService.AddEditRolePOST(role).toPromise();
    }
}
