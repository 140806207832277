/**
 * Maintains a promise that will be unresolved when "busy" and resolved when "not busy".
 * Set the busy status by calling start(), then call the returned function when done.
 *
 * @example
 *     const indicator = new BusyIndicator();
 *     const done = indicator.start();
 *     doSomeWork().finally(done);
 *
 * @property {Promise} promise - The promise to pass to cgBusy, indicating this BusyIndicator is active
*/
export default class BusyIndicator {
    constructor() {
        this._stack = [];
    }

    /**
     * Ensures this BusyIndicator will be "busy" until the returned function is called.
     * @returns {Function} - Call this function to indicate you are no longer busy.
     */
    start(...args) {
        // The arguments are used as an identity, so we know when we are
        // popping "this" operation from the stack.
        this._stack.push(args);

        if (this._stack.length == 1) {
            // This is the promise that cgBusy should monitor
            this.promise = new Promise((resolve) => {
                this._resolve = resolve;
            });
        }

        return () => this._stop(args);
    }

    _stop(args) {
        const ix = this._stack.indexOf(args);
        if (ix > -1) {
            this._stack.splice(ix, 1);
            if (this._stack.length == 0) {
                this._resolve();
            }
        }
    }

    /**
     * Forces the BusyIndicator to move to the "not busy" state.
     * This is not normally required.
     */
    cancel() {
        const shouldResolve = this._stack.length > 0;
        this._stack = [];

        if (shouldResolve) {
            this._resolve();
        }
    }
}
