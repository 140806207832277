import angular from 'angular';
import { Modules } from '../constants/modules';
import { ConfigureCommonItemsForModule } from './common.config';
import ConfigReportRoutes from './report-routes.config';
import _ from 'lodash';
import { importKendo } from './report-routes.config';
const appErrorPrefix = '[AutoAlert Opportunities] ';
import { MobileDealSheetComponent } from '../components/deal-sheet/mobile/mobile-deal-sheet/mobile-deal-sheet.component'
import { CustomerInfoComponent } from '../components/deal-sheet/mobile/customer-info/customer-info.component';
import { MobileActivitiesComponent } from '../components/deal-sheet/mobile/mobile-activities/mobile-activities.component';
import { MobileVehiclesComponent } from '../components/deal-sheet/mobile/mobile-vehicles/mobile-vehicles.component';
import { MobileReplacementContractComponent } from '../components/deal-sheet/mobile/mobile-replacement-contract/mobile-replacement-contract.component';
import { MobileRoutes } from '../components/deal-sheet/mobile/mobile-routes';
import { MobileCustomerConnectComponent } from '../components/deal-sheet/mobile/mobile-customer-connect/mobile-customer-connect.component';

const modules = Modules;

angular.module('AaApp.Opp').value('cgBusyDefaults', {
    message: 'Loading...',
    delay: 250
});

angular.module('AaApp.Opp').value('config', { appErrorPrefix });
const config = { appErrorPrefix };

ConfigureCommonItemsForModule(angular.module('AaApp.Opp'))

angular.module('AaApp.Opp')
    .config(Configure)
    .config(ConfigureApi)
    .config(ConfigureDefaultDealers)
    .config(ConfigLogger)
    .config(ConfigRoutes)
    .config(ConfigReportRoutes)
    .config(ConfigTranslation)
    .config(ConfigDynamicLocaleProvider)
    .config(ConfigureDealerHeaders)
    .config(ConfigureOidcInterceptor)
    .config(ConfigWhatFix);


function ConfigLogger($logProvider, environmentVariables) {
    "ngInject";
    const debugSetInLocalStorage = localStorage.getItem('debug');
    $logProvider.debugEnabled(debugSetInLocalStorage || environmentVariables.logDebugEnabled);
}

function Configure($analyticsProvider, exceptionHandlerProvider) {
    "ngInject";
    $analyticsProvider.virtualPageviews(false);

    // Configure the common exception handler
    exceptionHandlerProvider.configure(config.appErrorPrefix);
}

function ConfigureApi(
    apiUrls,
    coreApiProvider,
    selectedDealersInjectorProvider,
    dmsPushApiProvider
) {
    "ngInject";
    selectedDealersInjectorProvider.addUrl(apiUrls.reportMiner);
    coreApiProvider.configure({ url: apiUrls.alertMiner });   
    dmsPushApiProvider.configure({ url: apiUrls.dmsPushApi });
}

function ConfigureDefaultDealers(environmentVariables, selectedDealersInjectorProvider) {
    "ngInject";
    selectedDealersInjectorProvider.configure({
        employeeDefaultDealer: environmentVariables.employeeDefaultDealer,
    });
}

function ConfigRoutes($locationProvider, $stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider, legacy, routerStates) {
    "ngInject";
    $locationProvider.html5Mode(true);
    $urlMatcherFactoryProvider.strictMode(false);

    for (let routerState of routerStates) {
        var state = createLegacyState(routerState);
        $stateProvider.state(state);        
    }

    function createLegacyState(state) {
        if (state.legacyUrl) {
            return {
                ...state,
                component: 'aaIframe',
                onEnter: function () {
                    //hide footer for legacy pages as legacy has its own footer
                    document.getElementById('aa-footer').style.display = "none";
                },
                resolve: {
                    src: function ($transition$) {
                        "ngInject";

                        var dealerId = $transition$.params().dealerId;
                        return legacy.iframeUrl + state.legacyUrl.replace("{dealerId}", (dealerId == null) ? '' : dealerId);
                    }
                }
            }
        } else {
            return {...state};
        }
    }

    $urlRouterProvider.otherwise(function ($injector, $location) {
        if (!$location.path().match(/auth\/callback/)) {
            var $state = $injector.get('$state');
            $state.go('notFound');
        }
    });

    $stateProvider.state({
        name: 'carfaxCallback',
        url: '/cfcallback?code&state',
        component: 'carfaxCallback',
        resolve: {
            code: function ($transition$) {
                "ngInject";
                return $transition$.params().code;
            },
            state: function ($transition$) {
                "ngInject";
                return $transition$.params().state;
            }
        }
    });

    $stateProvider.state({
        name: 'carfaxAuthorize',
        url: '/carfax-authorize?dealerId&entityId&vin',
        component: 'carfaxAuthorize',
        resolve: {
            dealerId: function ($transition$) {
                "ngInject";
                return $transition$.params().dealerId;
            },
            entityId: function ($transition$) {
                "ngInject";
                return $transition$.params().entityId;
            },
            vin: function ($transition$) {
                "ngInject";
                return $transition$.params().vin;
            }
        }
    });

    $stateProvider.state({
        name: 'home',
        url: '/',
        template: '<p></p>',
        controller: function ($state, logger, menuService) {
            "ngInject";

            menuService.getHomeRoute()
                .then(homeRoute => {
                    if (homeRoute) {
                        $state.go(homeRoute);
                    }
                });
        },
    });

    $stateProvider.state({
        name: 'alertDesk',
        url: '/alert-desk',
        resolve: {
            moduleIds: function (dealerService, authorizationService) {
                "ngInject";
                const selectedDealerIds = dealerService.getSelectedDealerIds();
                return authorizationService.getModuleIds(selectedDealerIds);
            },
        },
        controller: function ($transition$, $state, dealerService, menuService) {
            "ngInject";
            // If '/alert-desk' is our final URL, then this chooses where to
            // redirect
            if ($transition$.to().name === 'alertDesk') {
                const dealerIds = dealerService.getSelectedDealerIds();
                return menuService.getSiteMap(dealerIds)
                    .then(siteMap => {
                        // Pick the first subMenuItem inside the alertDesk menu. If
                        // the alertDesk menu has no subMenuItems, then pick the
                        // first subMenuItem from the first menu.
                        const alertDeskMenu = siteMap.filter(m => m.id == 'alertDesk').shift();
                        if (alertDeskMenu && alertDeskMenu.subMenuItems.length) {
                            const firstAuthorizedAlertDeskRoute = alertDeskMenu.subMenuItems.slice().shift()
                            $state.go(firstAuthorizedAlertDeskRoute.id);
                        } else {
                            const firstSubMenuItem = _.flatMap(siteMap, m => m.subMenuItems).shift();
                            $state.go(firstSubMenuItem.id);
                        }
                    })
            }
        }
    });

    $stateProvider.state({
        name: 'dashboard',
        parent: 'alertDesk',
        url: '/dashboard',
        component: 'dashboard',
        abstract: true,
    });

    $stateProvider.state({
        name: 'dashboard.opportunities',
        url: '',
        component: 'dashboardOpportunities',
        onEnter: function (moduleIds) {
            "ngInject";
            if (!moduleIds.includes(modules.dashboard)) {                
                throw new Error('not authorized');
            }
        },
        resolve: {
            selectedDealerId: function ($transition$) {
                "ngInject";
                return $transition$.params().selectedDealerId;
            }
        }
    });

    $stateProvider.state({
        name: 'dashboard.manager',
        url: '/manager',
        component: 'dashboardManager',
        onEnter: function (moduleIds) {
            "ngInject";
            if (!moduleIds.includes(modules.managerDashboard)) {
                throw new Error('not authorized');
            }
        },
        resolve: {
            selectedDealerId: function ($transition$) {
                "ngInject";
                return $transition$.params().selectedDealerId;
            }
        }
    });

    $stateProvider.state({
        name: 'dealSheets',
        url: '/alert-desk/deal-sheets/{dealSheetParams}',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl + legacyUrlMappings['dealSheets'] + $transition$.params().dealSheetParams;
            }
        }
    });

    $stateProvider.state({
        name: 'dealSheetLite',
        url: '/alert-desk/deal-sheet-lite/{entityId}',
        component: 'dealSheetLite',
        resolve: {
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().entityId;
            },
            stockNo: function ($transition$) {
                "ngInject";
                return $transition$.params().stockNo;
            },
            siteMap: function (menuService, dealerService) {
                "ngInject";
                // This should be refactored. Although the component
                // doesn't ask for this binding directly, we need to call
                // getModuleIds and getAccessRightIds once to to get the
                // data in localStorage.
                var selectedDealerIds = dealerService.getSelectedDealerIds()
                return menuService.getSiteMap(selectedDealerIds);
            },
        }
    });


    $stateProvider.state({
        name: 'dealSheet',
        url: '/alert-desk/deal-sheet/{opportunityId}?calculationType&stockNo&showLegacy',
        component: 'dealSheet',
        resolve: {
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().opportunityId;
            },
            calculationType: function ($transition$) {
                "ngInject";
                return $transition$.params().calculationType;
            },
            stockNo: function ($transition$) {
                "ngInject";
                return $transition$.params().stockNo;
            },
            showLegacy: function ($transition$) {
                "ngInject";
                return $transition$.params().showLegacy;
            },
            siteMap: function (menuService, dealerService) {
                "ngInject";
                // This should be refactored. Although the component
                // doesn't ask for this binding directly, we need to call
                // getModuleIds and getAccessRightIds once to to get the
                // data in localStorage.
                var selectedDealerIds = dealerService.getSelectedDealerIds()
                return menuService.getSiteMap(selectedDealerIds);
            },
        }
    });

    $stateProvider.state({
        name: 'dealSheetExternal',
        url: '/external/dealsheet?e&v&c&d',
        component: 'dealSheet',
        resolve: {
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().e;
            },
            moduleIds: function (dealerService, menuService, storageService) {
                "ngInject";
                var selectedDealerIds = dealerService.getSelectedDealerIds()
                return menuService.getSiteMap(selectedDealerIds)
                    .then(function (results) {
                        // This should be refactored. We only need moduleIds,
                        // but we depend on the siteMap and accessRightIds to
                        // be populated. The call to getSiteMap does everything.
                        return storageService.getItem('moduleIds');
                    });
            }
        }
    });

    $stateProvider.state({
        name: 'mobileDealSheet',
        url: '/mobile/deal-sheet/{opportunityId}',
        component: MobileDealSheetComponent,
        resolve: {
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().opportunityId;
            }
        }
    });

    $stateProvider.state({
        name: MobileRoutes.contact,
        url: '/contact',
        component: CustomerInfoComponent
    });

    $stateProvider.state({
        name: MobileRoutes.activity,
        url: '/activities',
        component: MobileActivitiesComponent
    });

    $stateProvider.state({
        name: MobileRoutes.vehicle,
        url: '/vehicle',
        component: MobileVehiclesComponent
    });

    $stateProvider.state({
        name: MobileRoutes.replacementDetails,
        url: '/replacement-details',
        component: MobileReplacementContractComponent
    });

    $stateProvider.state({
        name: MobileRoutes.customerConnect,
        url: '/customer-connect',
        component: MobileCustomerConnectComponent
    });
    
    $stateProvider.state({
        name: 'pageExternal',
        url: '/external/page?c&pg&d&t&e',
        template: '<p></p>',
        controller: function ($state, logger, menuService, $transition$) {
            "ngInject";
            var route = "opportunities";
            switch ($transition$.params().pg) {
                case "alert-desk":
                    route = "alertDesk";
                    break;
                case "alert-desk/opportunities":
                    route = "opportunities";
                    break;
                case "alert-desk/activities":
                    route = "activities";
                    break;
                case "alert-desk/search2":
                    route = "search2";
                    break;
                case "/alert-desk/search":
                    route = "searchPresets";
                    break;
                case "alert-desk/conquests":
                    route = "conquests";
                    break;
                case "alert-desk/pre-owned-manager":
                    route = "preOwnedManager";
                    break;
                case "alert-desk/finance-insurance-manager":
                    route = "financeInsuranceManager";
                    break;
                case "inventory-dashboard":
                    route = "inventoryDashboard";
                    break;
                case "mobile/deal-sheet":
                    route = "mobileDealSheet"
                    $state.go(route, {opportunityId: $transition$.params().e});
                    return;
                    break;
            }
            $state.go(route);
        }
    });

    $stateProvider.state({
        name: 'login',
        url: '/login',
        component: 'login'
    });

    $stateProvider.state({
        name: 'loginLanding',
        url: '/login-landing',
        template: '<p></p>',
        controller: function ($state, $window, logger, menuService, userService, menu) {
            "ngInject";
            userService.getUserProfile()
                .then((profile) => {
                    if (profile.isSltUser) {
                        var communicationMenuItem = _.find(menu, { name: 'communication' });
                        var redirectUrl = communicationMenuItem ? communicationMenuItem.url : '/';

                        $window.location.replace(redirectUrl);
                    }
                    else if (menuService.siteMap) {
                        $state.go('login');
                    }
                });
        }
    });

    $stateProvider.state({
        name: 'loginRedirectToSSO',
        url: '/login-sso-redirect',
        template: '<p></p>'
    });

    $stateProvider.state({
        name: 'notFound',
        url: '/not-found',
        template: '<p></p>',
        controller: function ($state, $translate, $timeout, logger, warningTitleKey, pageNotFoundKey) {
            "ngInject";
            $timeout(function () {
                $translate([warningTitleKey, pageNotFoundKey]).then(showWarningMessage);

                function showWarningMessage(translations) {
                    logger.warning(
                        translations.pageNotFound,
                        null,
                        translations.warningTitle
                    );
                }

                $state.go('home');
            }, 100);
        }
    });

    $stateProvider.state({
        name: 'euroliteopportunities',
        url: '/alert-desk/europe/opportunities/',
        access: 'public',
        component: 'euroLiteOpportunities',
            resolve: {
                kendo: importKendo,
                opportunityId: function ($transition$) {
                    "ngInject";
                    return $transition$._targetState._params.opportunityId;
                },
                dealerIds :  function (dealerService) {
                    "ngInject";
                    return dealerService.getSelectedDealerIds();
                },
                filter: function ($transition$) {
                    "ngInject";
                    return $transition$._targetState._params.filter;
                }
            },
    });

    $stateProvider.state({
        name: 'opportunities',
        url: '/alert-desk/opportunities?saleTypes',
        access: 'public',
        component: 'aaIframe',
        onEnter: function () {
            //hide footer for legacy pages as legacy has its own footer
            document.getElementById('aa-footer').style.display = "none";
        },
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                var queryParams = $transition$.params().saleTypes;
                var queryParamString = '';
                if (queryParams) {
                    if ((queryParams instanceof Array) && queryParams.length > 1) {
                        queryParamString += '?saleTypes=';
                        queryParamString += queryParams.join('&saleTypes=');
                    }
                    else {
                        queryParamString += '?saletypes=' + queryParams;
                    }
                }

                return legacy.iframeUrl + legacyUrlMappings['opportunities'] + queryParamString;
            }
        }
    });

    $stateProvider.state({
        name: 'preOwnedManagerFindABuyer',
        url: '/alert-desk/pre-owned-manager/find-a-buyer?activeTab&submitSearch&isExactMatch&isShowUsed&hasOpp&sortColumnName&stockNo&inventorySortColumnName&ageVehicleGroup&trim&modelType&make&year&ownerFilter',
        access: 'public',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl
                    + legacyUrlMappings['preOwnedManagerFindABuyer']
                    + ($transition$.params().activeTab ? '?activeTab=' + $transition$.params().activeTab : '?activeTab=1')
                    + ($transition$.params().submitSearch ? '&submitSearch=' + $transition$.params().submitSearch : '')
                    + ($transition$.params().isExactMatch ? '&isExactMatch=' + $transition$.params().isExactMatch : '')
                    + ($transition$.params().isShowUsed ? '&isShowUsed=' + $transition$.params().isShowUsed : '')
                    + ($transition$.params().hasOpp ? '&hasOpp=' + $transition$.params().hasOpp + $transition$.params().hasOpp : '')
                    + ($transition$.params().sortColumnName ? '&sortColumnName=' + $transition$.params().sortColumnName : '')
                    + ($transition$.params().stockNo ? '&stockNo=' + $transition$.params().stockNo : '')
                    + ($transition$.params().inventorySortColumnName ? '&inventorySortColumnName=' + $transition$.params().inventorySortColumnName : '')
                    + ($transition$.params().ageVehicleGroup ? '&ageVehicleGroup=' + $transition$.params().ageVehicleGroup : '')
                    + ($transition$.params().trim ? '&trim=' + $transition$.params().trim : '')
                    + ($transition$.params().modelType ? '&modelType=' + $transition$.params().modelType : '')
                    + ($transition$.params().make ? '&make=' + $transition$.params().make : '')
                    + ($transition$.params().year ? '&year=' + $transition$.params().year : '')
                    + ($transition$.params().ownerFilter ? '&ownerFilter=' + $transition$.params().ownerFilter : '');
            }
        }
    });

    $stateProvider.state({
        access: 'public',
        name: 'errorUnauthorized',
        url: '/error/unauthorized?errorState',
        component: 'errorUnauthorized'
    });

    $stateProvider.state({
        name: 'quickSearch',
        url: '/alert-desk/quick-search/{searchText}',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl + legacyUrlMappings['quickSearch'] + $transition$.params().searchText;
            }
        }
    });

    $stateProvider.state({
        name: 'register',
        url: '/register/{dealerCode}?c=',
        access: 'public',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl + legacyUrlMappings['register'] + $transition$.params().dealerCode + '?c=' + $transition$.params().c;
            }
        }
    });

    registerAlertDesk($stateProvider);

    function registerAlertDesk($stateProvider) {

        $stateProvider.state({
            name: 'search',
            url: '/search',
            parent: 'alertDesk',
            access: 'public',
            component: 'searchContainer',
            abstract: true,
        });

        $stateProvider.state({
            name: 'search.form',
            url: '',
            access: 'public',
            component: 'searchForm',
            params: {
                dealerId: { type: 'int', value: null },
                sectionStates: { type: 'any', value: null },
                presetId: { type: 'int', value: null },
                tagId: { type: 'int', value: null },
                recentActivity: { type: 'any', value: null },
            },
            resolve: {
                kendo: importKendo,
                selectedDealerId: function ($transition$) {
                    "ngInject";
                    return $transition$.params().selectedDealerId;
                },
                activity: function ($transition$) {
                    "ngInject";
                    return $transition$.params().recentActivity;
                },
                dealerId: function ($transition$) {
                    "ngInject";
                    return $transition$.params().dealerId;
                },
                sectionStates: function ($transition$) {
                    "ngInject";
                    return $transition$.params().sectionStates;
                },
                presetId: function ($transition$) {
                    "ngInject";
                    return $transition$.params().presetId;
                },
                tagId: function ($transition$) {
                    "ngInject";
                    return $transition$.params().tagId;
                },
            }
        });

        $stateProvider.state({
            name: 'search.manage',
            url: '/manage-presets',
            access: 'public',
            component: 'searchManagePresets',
            params: {
                dealerId: { type: 'int', value: null },
                selectedDealerId: { type: 'int', value: null },
            },
            resolve: {
                kendo: importKendo,
                dealerId: function ($transition$) {
                    "ngInject";
                    return $transition$.params().dealerId;
                },
                selectedDealerId: function ($transition$) {
                    "ngInject";
                    return $transition$.params().selectedDealerId;
                },
            }
        });
    }

    $stateProvider.state({
        name: 'securityNewAccountActivate',
        url: '/security/newaccount/activate/?hid&c&sh',
        access: 'public',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl
                    + legacyUrlMappings['securityNewAccountActivate']
                    + '/?hid=' + encodeURIComponent($transition$.params().hid)
                    + '&c=' + encodeURIComponent($transition$.params().c)
                    + '&sh=' + encodeURIComponent($transition$.params().sh);
            }
        }
    });

    $stateProvider.state({
        name: 'legacyActivities',
        url: '/AlertDesk/Activities',
        component: 'aaIframe',
        resolve: {
            src: function (legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl + legacyUrlMappings['activities'];
            }
        }
    });

    $stateProvider.state({
        name: 'legacyUnsubscribe',
        access: 'public',
        url: '/Security/User/Unsubscribe?e',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl
                    + legacyUrlMappings['legacyUnsubscribe']
                    + '/?e=' + encodeURIComponent($transition$.params().e);
            }
        }
    });

    $stateProvider.state({
        name: 'legacyNotification',
        url: '/Security/User/Notification',
        component: 'aaIframe',
        resolve: {
            src: function ($transition$, legacy, legacyUrlMappings) {
                "ngInject";
                return legacy.iframeUrl
                    + legacyUrlMappings['legacyNotification'];
            }
        }
    });


    $stateProvider.state({
        name: 'autoCheckReport',
        url: '/alert-desk/auto-check/{opportunityId}/{vin}',
        component: 'thirdPartyReport',
        resolve: {
            reportType: function () {
                return 'autoCheck'
            },
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().opportunityId
            },
            vin: function ($transition$) {
                "ngInject";
                return $transition$.params().vin;
            }
        }
    });

    $stateProvider.state({
        name: 'carFaxReport',
        url: '/alert-desk/car-fax/{opportunityId}/{vin}',
        component: 'thirdPartyReport',
        resolve: {
            reportType: function () {
                return 'carFax'
            },
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().opportunityId
            },
            vin: function ($transition$) {
                "ngInject";
                return $transition$.params().vin;
            }
        }
    });

    $stateProvider.state({
        name: 'templateEmailPreview',
        url: '/alert-desk/template-email-preview/{opportunityId}/{templateId}/{replMakeId}',
        component: 'templateEmailPreview',
        data: {
            hideFooter: true,
        },
        resolve: {
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().opportunityId
            },
            templateId: function ($transition$) {
                "ngInject";
                return $transition$.params().templateId
            },
            replMakeId: function ($transition$) {
                "ngInject";
                return $transition$.params().replMakeId
            }
        }
    });


    $stateProvider.state({
        name: 'carProofReport',
        url: '/alert-desk/car-proof/{opportunityId}/{vin}',
        component: 'thirdPartyReport',
        resolve: {
            reportType: function () {
                return 'carProof'
            },
            opportunityId: function ($transition$) {
                "ngInject";
                return $transition$.params().opportunityId;
            },
            vin: function ($transition$) {
                "ngInject";
                return $transition$.params().vin;
            }
        }
    });

    $stateProvider.state({
        parent: 'user',
        name: 'userChangePassword',
        url: '/change-password',
        component: 'userChangePassword'
    });

    $stateProvider.state({
        name: 'serviceDriveOffers',
        url: '/alert-desk/service-drive/offers',
        component: 'serviceDriveOffersComponent'
    });

    //$stateProvider.state({
    //    name: 'wishList',
    //    //parent: 'alertDesk',
    //    access: 'public',
    //    url: '/alert-desk/wish-list',
    //    component: 'wishListComponent'
    //});

    $stateProvider.state({
        name: 'email',
        access: 'public',
        url: '/email/{id}?code',
        component: 'templateEmail',
        resolve: {
            templateEmailId: function ($transition$) {
                "ngInject";
                return $transition$.params().id;
            },
            accessCode: function ($transition$) {
                "ngInject";
                return $transition$.params().code;
            }
        }
    });

    $stateProvider.state({
        name: 'emailUnsubscribe',
        access: 'public',
        url: '/email/unsubscribe/{id}?code',
        component: 'emailUnsubscribe',
        resolve: {
            templateEmailId: function ($transition$) {
                "ngInject";
                return $transition$.params().id;
            },
            accessCode: function ($transition$) {
                "ngInject";
                return $transition$.params().code;
            }
        }
    });

    $stateProvider.state({
        name: 'searchDirectAlert',
        url: '/alert-desk/directalert?url',
        component: 'searchDirectAlert',
        resolve: {
            url: function ($transition$) {
                "ngInject";
                return $transition$.params().url;
            }
        }
    });     
}

function ConfigTranslation($translateProvider, $translatePartialLoaderProvider, localePaths) {
    "ngInject";
    $translateProvider.fallbackLanguage('en-US');
    $translateProvider.preferredLanguage('en-US');
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    $translateProvider.useLoaderCache('$translationCache');

    for (var property in localePaths) {
        $translatePartialLoaderProvider.addPart(localePaths[property]);
    }
}

function ConfigDynamicLocaleProvider(tmhDynamicLocaleProvider) {
    "ngInject";
    tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.7.5/angular-locale_{{locale}}.js');
}

function ConfigWhatFix(environmentVariables) {
    "ngInject";

    if (typeof environmentVariables.whatFixUrl == 'string') {
        const whatFixScript = document.createElement('script');
        whatFixScript.type = 'text/javascript';
        whatFixScript.async = true;
        whatFixScript.src = environmentVariables.whatFixUrl;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(whatFixScript, s);
    }
}

function ConfigureDealerHeaders($httpProvider) {
    "ngInject";
    $httpProvider.interceptors.push('selectedDealersInjector');
}

function ConfigureOidcInterceptor($httpProvider) {
    "ngInject";

    // Add the interceptor that adds the Authorization header
    $httpProvider.interceptors.push('oidcHttpInterceptor');
}

