import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';

@Component({
  selector: 'auto-check',
  templateUrl: './auto-check.component-ng.html',
  styleUrls: ['./auto-check.component.scss']
})
export class AutoCheckComponent implements OnInit, OnChanges {
  @Input() permissions: DealSheetPermissions;
  @Input() dealerSettings: any;
  @Input() opportunityId: string;
  @Input() vin: string;
  @Input() readonly: boolean;
  public showAutoCheckLink = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.permissions || !this.dealerSettings || !this.vin) {
      return;
    }

    this.showAutoCheckLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableAutoCheck;
  }

}


