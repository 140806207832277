<form [formGroup]="dateInputForm">
    <span class="input-group datepicker-right-align">
        <input name="date" formControlName="date"
               class="form-control unstyled date-type {{nullDate}} {{cssClass}}"
               bsDatepicker
               [bsConfig]="config"
               [required]="required"
               autocomplete="off"
               #datePicker="bsDatepicker"
               [maxDate]="options?.maxDate"
               [minDate]="options?.minDate"
               #dateInput
               (blur)="onBlur()"
               (keyup)="onKeyup($event)"
               />
        <span class="input-group-btn">
            <button type="button" class="btn btn-primary calandar" (click)="datePicker.show()">
                <icon class="icon sm aa-icon-core-calendar-icon-000000"></icon>
            </button>
        </span>
    </span>
</form>