export const AlertTypeDescriptors = [
    {
        'id': '100',
        'name': 'alert',
        'countName': 'alertCount',
        'cssClass': 'faalert',
        'uiRouterStateName': 'opportunityAlert',
        'translateKey': 'faalertletter'
    }, {
        'id': '200',
        'name': 'flexAlert',
        'countName': 'flexAlertCount',
        'cssClass': 'faflexalert',
        'uiRouterStateName': 'opportunityFlex',
        'translateKey': 'flexAlertLetter'
    }, {
        'id': '400',
        'name': 'inMarket',
        'countName': 'inMarketAlertCount',
        'cssClass': 'fainmarket',
        'uiRouterStateName': 'opportunity',
        'translateKey': 'inMarketLetter'
    }, {
        'id': '500',
        'name': 'inMarketEngaged',
        'countName': 'inMarketEngagedAlertCount',
        'cssClass': 'fainmarketengaged',
        'uiRouterStateName': 'opportunity',
        'translateKey': 'inMarketEngagedLetter'
    }, {
        'id': '700',
        'name': 'service',
        'countName': 'serviceAlertCount',
        'cssClass': 'faservice',
        'uiRouterStateName': 'opportunityService',
        'translateKey': 'serviceLetter'
    }, {
        'id': '800',
        'name': 'appointment',
        'countName': 'appointmentAlertCount',
        'cssClass': 'faappointment',
        'uiRouterStateName': 'opportunityAppointment',
        'translateKey': 'serviceAppointmentLetter'
    }, {
        'id': '900',
        'name': 'mileage',
        'countName': 'mileageAlertCount',
        'cssClass': 'famileage',
        'uiRouterStateName': 'opportunity',
        'translateKey': 'mileageLetter'
    }, {
        'id': '1000',
        'name': 'warranty',
        'countName': 'warrantyAlertCount',
        'cssClass': 'fawarranty',
        'uiRouterStateName': 'opportunity',
        'translateKey': 'warrantyLetter'
    }, {
        'id': '600',
        'name': 'contractEnd',
        'countName': 'contractEndAlert',
        'cssClass': 'facontractend',
        'uiRouterStateName': 'opportunity',
        'translateKey': 'contractEndLetter'
    }
];

export const AlertTypeDescriptorsEuroLite = [
    {
        'id': '100',
        'name': 'alert',
        'countName': 'alertCount',
        'cssClass': 'faalert',
        'uiRouterStateName': 'opportunityAlert',
        'key': 'alertName',
        'translateKey': 'faalertletter'
    }, {
        'id': '200',
        'name': 'flexAlert',
        'countName': 'flexAlertCount',
        'cssClass': 'faflexalert',
        'uiRouterStateName': 'opportunityFlex',
        'key': 'flexAlertName',
        'translateKey': 'flexAlertLetter'
    }, {
        'id': '600',
        'name': 'contractEnd',
        'countName': 'contractEndAlert',
        'cssClass': 'facontractend',
        'uiRouterStateName': 'opportunity',
        'key': 'contractEndName',
        'translateKey': 'contractEndLetter'
    }
];
