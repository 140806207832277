import { Component, Input } from '@angular/core';

@Component({
    selector: 'vehicle-image',
    templateUrl: './vehicle-image.component-ng.html',
    styleUrls: ['./vehicle-image.component.scss']
})
export class VehicleImageComponent {
    @Input() url: string;
    private vehicleImageNotAvailableUrl = require('../../../../images/vehicle-image-not-available.png');

    // on error of image src load default fallback image
    updateUrl() {
        this.url = this.vehicleImageNotAvailableUrl;
    }

}