"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("@ngx-translate/core"));
var i2 = __importStar(require("../icon/icon.component.ngfactory"));
var i3 = __importStar(require("../icon/icon.component"));
var i4 = __importStar(require("@angular/platform-browser"));
var i5 = __importStar(require("./admin-settings-vhr-disclaimer.component"));
var i6 = __importStar(require("ngx-bootstrap/modal"));
var i7 = __importStar(require("../../services/user.service"));
var i8 = __importStar(require("../../core/toaster-logger.service"));
var styles_AdminSettingsVHRDisclaimerComponent = [];
var RenderType_AdminSettingsVHRDisclaimerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminSettingsVHRDisclaimerComponent, data: {} });
exports.RenderType_AdminSettingsVHRDisclaimerComponent = RenderType_AdminSettingsVHRDisclaimerComponent;
function View_AdminSettingsVHRDisclaimerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "modal-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "alert-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["style", "float:left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"], ["translate", "disclaimer"]], null, null, null, null, null)), i0.ɵdid(5, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["style", "float:right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "icon", [["class", "icon aa-icon-close-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(9, 638976, null, 0, i3.IconComponent, [i4.DomSanitizer], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "vhrDisclaimer"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["translate", "cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["translate", "ok"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(17, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "disclaimer"; _ck(_v, 5, 0, currVal_0); _ck(_v, 9, 0); var currVal_2 = "cancel"; _ck(_v, 15, 0, currVal_2); var currVal_3 = "ok"; _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("dsAdmin_vehicleHistoryReportsDisclaimer")); _ck(_v, 11, 0, currVal_1); }); }
exports.View_AdminSettingsVHRDisclaimerComponent_0 = View_AdminSettingsVHRDisclaimerComponent_0;
function View_AdminSettingsVHRDisclaimerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "admin-settings-vhr-disclaimer", [], null, null, null, View_AdminSettingsVHRDisclaimerComponent_0, RenderType_AdminSettingsVHRDisclaimerComponent)), i0.ɵdid(1, 49152, null, 0, i5.AdminSettingsVHRDisclaimerComponent, [i6.BsModalService, i1.TranslateService, i7.UserService, i8.ToasterLoggerService, i6.BsModalRef], null, null)], null, null); }
exports.View_AdminSettingsVHRDisclaimerComponent_Host_0 = View_AdminSettingsVHRDisclaimerComponent_Host_0;
var AdminSettingsVHRDisclaimerComponentNgFactory = i0.ɵccf("admin-settings-vhr-disclaimer", i5.AdminSettingsVHRDisclaimerComponent, View_AdminSettingsVHRDisclaimerComponent_Host_0, {}, { acceptDisclaimer: "acceptDisclaimer" }, []);
exports.AdminSettingsVHRDisclaimerComponentNgFactory = AdminSettingsVHRDisclaimerComponentNgFactory;
