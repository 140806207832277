
/* injects from baggage-loader */
require('./color.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('color', {
    templateUrl: '/app/components/deal-sheet/vehicle/color.component.html',
    bindings: {
        interior: '<',
        exterior: '<'
    }
});
