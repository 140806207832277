
/* injects from baggage-loader */
require('./edit-replacements-advanced-modal.component.html');

import angular from 'angular';
import './edit-upgrade-downgrade-multi-items-modal.component.scss';
import { forEach } from '@uirouter/core';

angular.module('AaApp.Opp').component('editReplacementsAdvancedModal', {
    templateUrl: '/app/components/data-entry/replacement/edit-replacements-advanced-modal.component.html',
    controller: editReplacementsAdvancedModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

function editReplacementsAdvancedModalController() {
    var $ctrl = this;

    //$ctrl.resolve is sent by the caller of this directive, and can be any JS object.
    //  I'm using it to enable DI and pass input parameters.
    $ctrl.row = $ctrl.resolve.message.row;
    $ctrl.selectedDealerId = $ctrl.resolve.message.selectedDealerId;
    $ctrl.save = $ctrl.resolve.message.save;

    $ctrl.setTrimSelections = setTrimSelections;
    $ctrl.closeWrapper = closeWrapper;
    $ctrl.invalidReplacementYear = false;
    
    $ctrl.defaults = {
        vehicleTypes: 0,
        makeID: $ctrl.row.replacementMakeID,
        makeName: $ctrl.row.replacementMakeName,
        yearID: $ctrl.row.replacementYearID,
        modelID: $ctrl.row.replacementModelID,
        modelName: $ctrl.row.replacementModelName,
        trimID: $ctrl.row.newTrimID,
        trimName: $ctrl.row.replacementTrimName
    };
    
    function setTrimSelections(selections) {
        $ctrl.defaults = selections;
        if ($ctrl.defaults.yearID < $ctrl.row.existingYearID) {
            $ctrl.invalidReplacementYear = true;
        } else {
            $ctrl.invalidReplacementYear = false;
        }
        $ctrl.resolve.submitButtonDisabled = $ctrl.defaults.trimID === $ctrl.row.newTrimID || $ctrl.invalidReplacementYear;
    }

    function closeWrapper() {
        var rowState = {
            row: $ctrl.row,
            replacementTrim: $ctrl.defaults
        }
        $ctrl.save(rowState);
        return $ctrl.close();
    }
         
    if ($ctrl.resolve.closeButtonVisible === undefined)
        $ctrl.resolve.closeButtonVisible = true;

    if ($ctrl.resolve.closeButtonText === undefined)
        $ctrl.resolve.closeButtonText = "cancel";

    if ($ctrl.resolve.submitButtonVisible === undefined)
        $ctrl.resolve.submitButtonVisible = true;

    if ($ctrl.resolve.submitButtonDisabled === undefined)
        $ctrl.resolve.submitButtonDisabled = false;

    if ($ctrl.resolve.submitButtonText === undefined)
        $ctrl.resolve.submitButtonText = "yes";

}

