export interface NotificationMessage {
    eventType: EventType;
    eventTime: Date;
    data: NotificationData;
}

export interface NotificationData {
    entityID?: string;
    dealerID?: number;
    engagementTimeUtc: Date;
    type: EngagementEventTypeEnum;
    directUsers: Object[];
    customerID: number;
}

export enum EventType {
    engagement = 0
}

export enum EngagementEventTypeEnum {
    newServiceAppointment = 1,
    inService = 2,
    emailOpened = 3,
    emailReplied = 4,
    pURLView = 5,
    pURLLeadSubmission = 6,
    websiteEngaged = 7,
    websiteLeadSubmission = 8,
    onlineServiceReview = 9,
    onlineSalesReview = 10,
    textMessageReplied = 11
}
