CreditInquiriesController.$inject = ["creditPrescreenStatuses", "creditPrescreenResultsKey", "noCreditPrescreenDataKey"];

/* injects from baggage-loader */
require('./credit-inquiries.component.html');

import angular from 'angular';
import { DealerCountries as dealerCountries } from '../../constants/dealer-countries';
angular.module('AaApp.Opp').component('creditInquiries', {
  templateUrl: '/app/components/deal-sheet/credit-inquiries.component.html',
  controller: CreditInquiriesController,
  bindings: {
    creditPrescreen: '<',
    creditPrescreenStatus: '<',
    creditPreApproval: '<',
    customerName: '<',
    permissions: '<',
    dealerSettings: '<',
    countryId: '<'
  }
});
/* @ngInject */

function CreditInquiriesController(creditPrescreenStatuses, creditPrescreenResultsKey, noCreditPrescreenDataKey) {
  var $ctrl = this;
  $ctrl.creditPrescreenResultsKey;
  $ctrl.noCreditPrescreenDataKey;
  $ctrl.creditPrescreenStatuses;
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;

  function init() {
    $ctrl.creditPrescreenResultsKey = creditPrescreenResultsKey;
    $ctrl.noCreditPrescreenDataKey = noCreditPrescreenDataKey;
    $ctrl.creditPrescreenStatuses = creditPrescreenStatuses;
  }

  function onChanges(changes) {
    if (changes.creditPreApproval && changes.creditPreApproval.currentValue) {
      var goodThroughDate = new Date($ctrl.creditPreApproval.goodThruDate);
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      $ctrl.creditPreApprovalValid = $ctrl.countryId == dealerCountries.usa && goodThroughDate >= today;
      $ctrl.creditPreQualifiedValid = $ctrl.countryId == dealerCountries.canada && goodThroughDate >= today;
    }
  }
}