"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./display-modal.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("../../icon/icon.component.ngfactory"));
var i4 = __importStar(require("../../icon/icon.component"));
var i5 = __importStar(require("@angular/platform-browser"));
var i6 = __importStar(require("./display-modal.component"));
var i7 = __importStar(require("ngx-bootstrap/modal"));
var styles_DisplayModalComponent = [i0.styles];
var RenderType_DisplayModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisplayModalComponent, data: {} });
exports.RenderType_DisplayModalComponent = RenderType_DisplayModalComponent;
function View_DisplayModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { iframe: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "alert-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["style", "float:left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title"], ["translate", "preview"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["style", "float:right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "icon", [["class", "icon aa-icon-close-icon"]], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i1.ɵdid(9, 638976, null, 0, i4.IconComponent, [i5.DomSanitizer], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["frame", 1]], null, 0, "iframe", [["class", "ifrmContainer"], ["style", "border: none;width: 100%;height: 680px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["translate", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "preview"; _ck(_v, 5, 0, currVal_0); _ck(_v, 9, 0); var currVal_1 = "close"; _ck(_v, 14, 0, currVal_1); }, null); }
exports.View_DisplayModalComponent_0 = View_DisplayModalComponent_0;
function View_DisplayModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "display-modal", [], null, null, null, View_DisplayModalComponent_0, RenderType_DisplayModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.DisplayModalComponent, [i7.BsModalRef, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DisplayModalComponent_Host_0 = View_DisplayModalComponent_Host_0;
var DisplayModalComponentNgFactory = i1.ɵccf("display-modal", i6.DisplayModalComponent, View_DisplayModalComponent_Host_0, { htmlContent: "htmlContent" }, { onClosed: "onClosed" }, []);
exports.DisplayModalComponentNgFactory = DisplayModalComponentNgFactory;
