/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LastActivityDetailsExtDto } from '../models/last-activity-details-ext-dto';
import { EntityMailHistoryDto } from '../models/entity-mail-history-dto';
import { ActivityHistoryCombinedDto } from '../models/activity-history-combined-dto';
@Injectable({
  providedIn: 'root',
})
class ActivityHistoryClientService extends __BaseService {
  static readonly GetExtByEntityIdByEntityidGETPath = '/api/ActivityHistory/GetExtByEntityId/{entityId}';
  static readonly GetLastActivityByEntityIdByEntityidGETPath = '/api/ActivityHistory/GetLastActivityByEntityId/{entityId}';
  static readonly GetOneToOneMailHistoryByByEntityidGETPath = '/api/ActivityHistory/GetOneToOneMailHistoryBy/{entityId}';
  static readonly GetSLMEmailOfferHistoryByByEntityidGETPath = '/api/ActivityHistory/GetSLMEmailOfferHistoryBy/{entityId}';
  static readonly GetExtActivityHistoryByByEntityidByDealeridGETPath = '/api/ActivityHistory/GetExtActivityHistoryBy/{entityId}/{dealerId}';
  static readonly GetEmailConversationHistoryByByEntityidGETPath = '/api/ActivityHistory/GetEmailConversationHistoryBy/{entityId}';
  static readonly GetCustomerEngagementHistoryByByEntityidGETPath = '/api/ActivityHistory/GetCustomerEngagementHistoryBy/{entityId}';
  static readonly GetCombinedActivityHistoryGETPath = '/api/ActivityHistory/GetCombinedActivityHistory';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ActivityHistoryClientService.GetExtByEntityIdByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `count`:
   */
  GetExtByEntityIdByEntityidGETResponse(params: ActivityHistoryClientService.GetExtByEntityIdByEntityidGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetExtByEntityId/${params.entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ActivityHistoryClientService.GetExtByEntityIdByEntityidGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `count`:
   */
  GetExtByEntityIdByEntityidGET(params: ActivityHistoryClientService.GetExtByEntityIdByEntityidGETParams): __Observable<null> {
    return this.GetExtByEntityIdByEntityidGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetLastActivityByEntityIdByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<LastActivityDetailsExtDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetLastActivityByEntityId/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LastActivityDetailsExtDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetLastActivityByEntityIdByEntityidGET(entityId: string): __Observable<LastActivityDetailsExtDto> {
    return this.GetLastActivityByEntityIdByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as LastActivityDetailsExtDto)
    );
  }

  /**
   * @param entityId undefined
   */
  GetOneToOneMailHistoryByByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetOneToOneMailHistoryBy/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetOneToOneMailHistoryByByEntityidGET(entityId: string): __Observable<null> {
    return this.GetOneToOneMailHistoryByByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetSLMEmailOfferHistoryByByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<Array<EntityMailHistoryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetSLMEmailOfferHistoryBy/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntityMailHistoryDto>>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetSLMEmailOfferHistoryByByEntityidGET(entityId: string): __Observable<Array<EntityMailHistoryDto>> {
    return this.GetSLMEmailOfferHistoryByByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as Array<EntityMailHistoryDto>)
    );
  }

  /**
   * @param params The `ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `dealerId`:
   */
  GetExtActivityHistoryByByEntityidByDealeridGETResponse(params: ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetExtActivityHistoryBy/${params.entityId}/${params.dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETParams` containing the following parameters:
   *
   * - `entityId`:
   *
   * - `dealerId`:
   */
  GetExtActivityHistoryByByEntityidByDealeridGET(params: ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETParams): __Observable<null> {
    return this.GetExtActivityHistoryByByEntityidByDealeridGETResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  GetEmailConversationHistoryByByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetEmailConversationHistoryBy/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetEmailConversationHistoryByByEntityidGET(entityId: string): __Observable<null> {
    return this.GetEmailConversationHistoryByByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param entityId undefined
   */
  GetCustomerEngagementHistoryByByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetCustomerEngagementHistoryBy/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entityId undefined
   */
  GetCustomerEngagementHistoryByByEntityidGET(entityId: string): __Observable<null> {
    return this.GetCustomerEngagementHistoryByByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ActivityHistoryClientService.GetCombinedActivityHistoryGETParams` containing the following parameters:
   *
   * - `opportunityId`:
   *
   * - `dealerId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  GetCombinedActivityHistoryGETResponse(params: ActivityHistoryClientService.GetCombinedActivityHistoryGETParams): __Observable<__StrictHttpResponse<ActivityHistoryCombinedDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.opportunityId != null) __params = __params.set('opportunityId', params.opportunityId.toString());
    if (params.dealerId != null) __params = __params.set('dealerId', params.dealerId.toString());
    if (params.customerId != null) __params = __params.set('customerId', params.customerId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ActivityHistory/GetCombinedActivityHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActivityHistoryCombinedDto>;
      })
    );
  }
  /**
   * @param params The `ActivityHistoryClientService.GetCombinedActivityHistoryGETParams` containing the following parameters:
   *
   * - `opportunityId`:
   *
   * - `dealerId`:
   *
   * - `customerId`:
   *
   * @return Success
   */
  GetCombinedActivityHistoryGET(params: ActivityHistoryClientService.GetCombinedActivityHistoryGETParams): __Observable<ActivityHistoryCombinedDto> {
    return this.GetCombinedActivityHistoryGETResponse(params).pipe(
      __map(_r => _r.body as ActivityHistoryCombinedDto)
    );
  }
}

module ActivityHistoryClientService {

  /**
   * Parameters for GetExtByEntityIdByEntityidGET
   */
  export interface GetExtByEntityIdByEntityidGETParams {
    entityId: string;
    count?: number;
  }

  /**
   * Parameters for GetExtActivityHistoryByByEntityidByDealeridGET
   */
  export interface GetExtActivityHistoryByByEntityidByDealeridGETParams {
    entityId: string;
    dealerId: number;
  }

  /**
   * Parameters for GetCombinedActivityHistoryGET
   */
  export interface GetCombinedActivityHistoryGETParams {
    opportunityId?: string;
    dealerId?: number;
    customerId?: number;
  }
}

export { ActivityHistoryClientService }
