"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("./open-process-modal.component"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("../../../services/ngx-modal-wrapper.service"));
var i4 = __importStar(require("../../../core/toaster-logger.service"));
var i5 = __importStar(require("@angular/common"));
var styles_OpenProcessModalComponent = [];
var RenderType_OpenProcessModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OpenProcessModalComponent, data: {} });
exports.RenderType_OpenProcessModalComponent = RenderType_OpenProcessModalComponent;
function View_OpenProcessModalComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
exports.View_OpenProcessModalComponent_0 = View_OpenProcessModalComponent_0;
function View_OpenProcessModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "open-process-modal", [], null, null, null, View_OpenProcessModalComponent_0, RenderType_OpenProcessModalComponent)), i0.ɵdid(1, 573440, null, 0, i1.OpenProcessModalComponent, [i2.TranslateService, i3.NgxModalWrapperService, i4.ToasterLoggerService, i5.DOCUMENT], null, null)], null, null); }
exports.View_OpenProcessModalComponent_Host_0 = View_OpenProcessModalComponent_Host_0;
var OpenProcessModalComponentNgFactory = i0.ɵccf("open-process-modal", i1.OpenProcessModalComponent, View_OpenProcessModalComponent_Host_0, { openModal: "openModal", entityId: "entityId" }, { openModalChanged: "openModalChanged" }, []);
exports.OpenProcessModalComponentNgFactory = OpenProcessModalComponentNgFactory;
