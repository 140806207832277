"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../../../core/toaster-logger.service");
var MileageEditModalComponent = /** @class */ (function () {
    function MileageEditModalComponent(_bsModalRef, tService, logger) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.logger = logger;
        this.onSaveChanges = new core_1.EventEmitter();
    }
    MileageEditModalComponent.prototype.ngOnInit = function () {
        this._bsModalRef.hide();
        this.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 0,
            showWeeks: false
        };
        var date = new Date();
        this.status = { opened: false };
        this.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 0,
            showWeeks: false
        };
        this.minDate = date;
        var dueDate = new Date();
        dueDate.setDate(date.getDate() + (5 / (24 * 60))); // add 5 min to current time
        this.dateModel = {
            valid: true
        };
        this.model = {
            date: dueDate,
            time: new Date(1, 1, 2000, dueDate.getHours(), dueDate.getMinutes()),
            mileage: ''
        };
    };
    MileageEditModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    MileageEditModalComponent.prototype.saveMileage = function (mileageForm) {
        if (mileageForm.valid) {
            this.onSaveChanges.emit({ mileage: this.mileage, readOnDate: this.model.date });
            this._bsModalRef.hide();
        }
    };
    MileageEditModalComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
    };
    MileageEditModalComponent.prototype.setDate = function (date, model) {
        this.model.date = date;
        this.dateModel = model;
    };
    return MileageEditModalComponent;
}());
exports.MileageEditModalComponent = MileageEditModalComponent;
