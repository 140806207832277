"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../../../core/toaster-logger.service");
var VautoModalComponent = /** @class */ (function () {
    function VautoModalComponent(_bsModalRef, tService, logger, dealSheetService) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.logger = logger;
        this.dealSheetService = dealSheetService;
        this.hasLatestAppraisal = false;
        this.onSaveChanges = new core_1.EventEmitter();
    }
    VautoModalComponent.prototype.ngOnInit = function () {
        this._bsModalRef.hide();
        this.getLatestAppraisal(this.dealerId, this.vehicle.vin);
        if (this.vehicle.estimatedCurrentMileage) {
            this.estimatedMileage = this.vehicle.estimatedCurrentMileage;
        }
        else {
            this.estimatedMileage = null;
        }
        if (this.customer.cellPhoneNumber) {
            this.primaryPhone = this.customer.cellPhoneNumber.value;
        }
        else if (this.customer.homePhoneNumber) {
            this.primaryPhone = this.customer.homePhoneNumber.value;
        }
        else {
            this.primaryPhone = null;
        }
        this.getCustomerAddress();
        this.getFullTrim();
    };
    VautoModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    VautoModalComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
    };
    VautoModalComponent.prototype.sendAppraisal = function () {
        this.onSaveChanges.emit();
        this._bsModalRef.hide();
    };
    VautoModalComponent.prototype.getLatestAppraisal = function (dealerId, vin) {
        var _this = this;
        return this.dealSheetService.getLatestAppraisal(dealerId, vin)
            .then(function (data) {
            if (data && data.appraisal) {
                _this.hasLatestAppraisal = true;
                _this.appraisalId = data.appraisal.id;
                _this.deepLink = data.deepLink;
                _this.createDate = data.appraisal.created;
            }
            else {
                _this.hasLatestAppraisal = false;
            }
        });
    };
    VautoModalComponent.prototype.getCustomerAddress = function () {
        var address1 = '';
        var address2 = '';
        if (this.customer) {
            if (this.customer.address.streetAddress)
                address1 = this.customer.address.streetAddress;
            else
                address1 = '';
            if (this.customer.address.streetAddress2)
                address2 = ' ' + this.customer.address.streetAddress2;
            else
                address2 = ' ';
            this.fullAddress = address1 + address2 + ', ' + this.customer.address.city + ', ' + this.customer.address.stateName + ', ' + this.customer.address.normalizedZipCode;
        }
    };
    VautoModalComponent.prototype.getFullTrim = function () {
        this.fullTrim = this.vehicle.yearID + " " + this.vehicle.makeName + " " + this.vehicle.trimFullName;
    };
    return VautoModalComponent;
}());
exports.VautoModalComponent = VautoModalComponent;
