"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["icon.aa-icon-star-icon-off[_ngcontent-%COMP%], icon.aa-icon-star-icon-on[_ngcontent-%COMP%]{padding-right:5px}"];
exports.styles = styles;
