/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RoleOptionDto } from '../models/role-option-dto';
import { RoleAccessOptionDto } from '../models/role-access-option-dto';
import { RoleDto } from '../models/role-dto';
@Injectable({
  providedIn: 'root',
})
class RoleClientService extends __BaseService {
  static readonly GetRoleOptionsGETPath = '/api/Role/GetRoleOptions';
  static readonly GetRoleAccessRightsByRoleidGETPath = '/api/Role/GetRoleAccessRights/{roleId}';
  static readonly GetRoleModuleAccessByRoleidGETPath = '/api/Role/GetRoleModuleAccess/{roleId}';
  static readonly GetRoleModuleCategoriesByRoleidGETPath = '/api/Role/GetRoleModuleCategories/{roleId}';
  static readonly GetRoleAccessRightCategoriesGETPath = '/api/Role/GetRoleAccessRightCategories';
  static readonly RoleAccessUpdateByRoleidGETPath = '/api/Role/RoleAccessUpdate/{roleId}';
  static readonly AddEditRolePOSTPath = '/api/Role/AddEditRole';
  static readonly ByIdGETPath = '/api/Role/{id}';
  static readonly ByIdDELETEPath = '/api/Role/{id}';
  static readonly GetManyGETPath = '/api/Role/GetMany';
  static readonly PUTPath = '/api/Role';
  static readonly POSTPath = '/api/Role';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetRoleOptionsGETResponse(): __Observable<__StrictHttpResponse<Array<Array<RoleOptionDto>>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/GetRoleOptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Array<RoleOptionDto>>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRoleOptionsGET(): __Observable<Array<Array<RoleOptionDto>>> {
    return this.GetRoleOptionsGETResponse().pipe(
      __map(_r => _r.body as Array<Array<RoleOptionDto>>)
    );
  }

  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleAccessRightsByRoleidGETResponse(roleId: number): __Observable<__StrictHttpResponse<Array<RoleAccessOptionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/GetRoleAccessRights/${roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoleAccessOptionDto>>;
      })
    );
  }
  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleAccessRightsByRoleidGET(roleId: number): __Observable<Array<RoleAccessOptionDto>> {
    return this.GetRoleAccessRightsByRoleidGETResponse(roleId).pipe(
      __map(_r => _r.body as Array<RoleAccessOptionDto>)
    );
  }

  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleModuleAccessByRoleidGETResponse(roleId: number): __Observable<__StrictHttpResponse<Array<RoleAccessOptionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/GetRoleModuleAccess/${roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoleAccessOptionDto>>;
      })
    );
  }
  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleModuleAccessByRoleidGET(roleId: number): __Observable<Array<RoleAccessOptionDto>> {
    return this.GetRoleModuleAccessByRoleidGETResponse(roleId).pipe(
      __map(_r => _r.body as Array<RoleAccessOptionDto>)
    );
  }

  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleModuleCategoriesByRoleidGETResponse(roleId: number): __Observable<__StrictHttpResponse<Array<RoleAccessOptionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/GetRoleModuleCategories/${roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoleAccessOptionDto>>;
      })
    );
  }
  /**
   * @param roleId undefined
   * @return Success
   */
  GetRoleModuleCategoriesByRoleidGET(roleId: number): __Observable<Array<RoleAccessOptionDto>> {
    return this.GetRoleModuleCategoriesByRoleidGETResponse(roleId).pipe(
      __map(_r => _r.body as Array<RoleAccessOptionDto>)
    );
  }

  /**
   * @return Success
   */
  GetRoleAccessRightCategoriesGETResponse(): __Observable<__StrictHttpResponse<Array<RoleAccessOptionDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/GetRoleAccessRightCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoleAccessOptionDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRoleAccessRightCategoriesGET(): __Observable<Array<RoleAccessOptionDto>> {
    return this.GetRoleAccessRightCategoriesGETResponse().pipe(
      __map(_r => _r.body as Array<RoleAccessOptionDto>)
    );
  }

  /**
   * @param params The `RoleClientService.RoleAccessUpdateByRoleidGETParams` containing the following parameters:
   *
   * - `roleId`:
   *
   * - `moduleIdList`:
   *
   * - `accessRightIdList`:
   *
   * @return Success
   */
  RoleAccessUpdateByRoleidGETResponse(params: RoleClientService.RoleAccessUpdateByRoleidGETParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.moduleIdList || []).forEach(val => {if (val != null) __params = __params.append('moduleIdList', val.toString())});
    (params.accessRightIdList || []).forEach(val => {if (val != null) __params = __params.append('accessRightIdList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/RoleAccessUpdate/${params.roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `RoleClientService.RoleAccessUpdateByRoleidGETParams` containing the following parameters:
   *
   * - `roleId`:
   *
   * - `moduleIdList`:
   *
   * - `accessRightIdList`:
   *
   * @return Success
   */
  RoleAccessUpdateByRoleidGET(params: RoleClientService.RoleAccessUpdateByRoleidGETParams): __Observable<boolean> {
    return this.RoleAccessUpdateByRoleidGETResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param role undefined
   * @return Success
   */
  AddEditRolePOSTResponse(role?: RoleDto): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = role;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Role/AddEditRole`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param role undefined
   * @return Success
   */
  AddEditRolePOST(role?: RoleDto): __Observable<number> {
    return this.AddEditRolePOSTResponse(role).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<RoleDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RoleDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<RoleDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as RoleDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Role/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<RoleDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Role/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoleDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<RoleDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<RoleDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: RoleDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Role`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: RoleDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: RoleDto): __Observable<__StrictHttpResponse<RoleDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Role`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RoleDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: RoleDto): __Observable<RoleDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as RoleDto)
    );
  }
}

module RoleClientService {

  /**
   * Parameters for RoleAccessUpdateByRoleidGET
   */
  export interface RoleAccessUpdateByRoleidGETParams {
    roleId: number;
    moduleIdList?: Array<number>;
    accessRightIdList?: Array<number>;
  }
}

export { RoleClientService }
