"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("./../../services/user.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var AdminSettingsProgramsComponent = /** @class */ (function () {
    function AdminSettingsProgramsComponent(modalService, tService, userService, logger, changeDetectorRef) {
        this.modalService = modalService;
        this.tService = tService;
        this.userService = userService;
        this.logger = logger;
        this.changeDetectorRef = changeDetectorRef;
        this.dsa = {};
        this.onSaveChanges = new core_1.EventEmitter();
    }
    AdminSettingsProgramsComponent.prototype.ngOnInit = function () {
        this.perUnitLabelKey = this.tService.instant((this.dsa.isMetric) ? 'PerLiterLabel' : 'PerGallonLabel');
    };
    AdminSettingsProgramsComponent.prototype.handleLeasePenaltyFeeChanged = function (item) {
        if (item.leasePenaltyFee < 0) {
            item.leasePenaltyFee = 0;
            return;
        }
        if (item.leasePenaltyFee > 5) {
            item.leasePenaltyFee = 5;
            return;
        }
    };
    AdminSettingsProgramsComponent.prototype.getResidualMileageOptions = function (bankID) {
        var rmo = this.dsa.residualMileageOptions.find(function (r) { return r.bankID === bankID; });
        var options = [];
        if (rmo != null) {
            options.push({
                value: rmo.residualMileage10kLabel, text: this.formatNumber(rmo.residualMileage10kLabel)
            });
            options.push({
                value: rmo.residualMileage12kLabel, text: this.formatNumber(rmo.residualMileage12kLabel)
            });
            options.push({
                value: rmo.residualMileage15kLabel, text: this.formatNumber(rmo.residualMileage15kLabel)
            });
        }
        return options;
    };
    AdminSettingsProgramsComponent.prototype.formatNumber = function (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };
    return AdminSettingsProgramsComponent;
}());
exports.AdminSettingsProgramsComponent = AdminSettingsProgramsComponent;
