
/* injects from baggage-loader */
require('./edit-upgrade-downgrade-multi-items-modal.component.html');

import angular from 'angular';
import './edit-upgrade-downgrade-multi-items-modal.component.scss';
import arrowIconPath from '../../../images/icon-arrow-right-charcoal.svg';

angular.module('AaApp.Opp').component('editUpgradeDowngradeMultiItemsModal', {
    templateUrl: '/app/components/data-entry/replacement/edit-upgrade-downgrade-multi-items-modal.component.html',
    controller: editUpgradeDowngradeMultiItemsModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

//these controllers do not support dependency injection.
function editUpgradeDowngradeMultiItemsModalController() {

    var $ctrl = this;

    //$ctrl.resolve is sent by the caller of this directive, and can be any JS object.
    //  I'm using it to pass DI objects and input parameters.
    $ctrl.row = $ctrl.resolve.message.row;
    $ctrl.selectedDealerId = $ctrl.resolve.message.selectedDealerId;
    $ctrl.dataReplacementAdvanced = $ctrl.replacements = $ctrl.resolve.message.dataReplacementAdvanced;
    $ctrl.save = $ctrl.resolve.message.save;
    $ctrl.setTrimSelections = setTrimSelections;
    $ctrl.closeWrapper = closeWrapper;
    $ctrl.arrowIconPath = arrowIconPath;
    $ctrl.isError = false;
    $ctrl._ = $ctrl.resolve.message._;
    $ctrl.mapToItself = [];
    
    $ctrl.defaults = {
        vehicleTypes: 0,
        makeID: $ctrl.row.baseMakeID,
        makeName: $ctrl.row.baseMakeName,
        yearID: $ctrl.row.baseYearID,
        modelID: $ctrl.row.baseModelID,
        modelName: $ctrl.row.baseModelName,
        trimID: $ctrl.row.baseTrimID,
        trimName: $ctrl.row.baseTrimName
    };
    
    function setTrimSelections(selections) {
        $ctrl.defaults = selections;
        $ctrl.mapToItself = [];
        for (var i = 0; i < $ctrl.dataReplacementAdvanced.length; i++) {
            var item = $ctrl.dataReplacementAdvanced[i];
            if (item.existingYearID > selections.yearID) {
                $ctrl.mapToItself.push(item);
            }
        }
        $ctrl.replacements = $ctrl._.filter($ctrl.dataReplacementAdvanced, function (item) {
            return !$ctrl._.includes($ctrl.mapToItself, item);
        });

        $ctrl.isError = $ctrl.mapToItself.length > 0;

        $ctrl.resolve.submitButtonDisabled = $ctrl.defaults.trimID === $ctrl.row.baseTrimID;
    }

    $ctrl.resolve.submitButtonText = $ctrl.resolve.message.translations.previewChanges;
    $ctrl.isValidated = false;

    function closeWrapper() {
        if (!$ctrl.isValidated) {
            $ctrl.resolve.submitButtonText = "submit"
            $ctrl.isValidated = true;
            return;
        }

        var rowState = {
            replacements: $ctrl.replacements,
            mapToItself: $ctrl.mapToItself,
            row: $ctrl.row,
            replacementTrim: $ctrl.defaults
        }
        $ctrl.save(rowState);

        //The CLOSE() event should be called asyncronously, after the SAVE feature is successful.
        return $ctrl.close();
    }
         
    if ($ctrl.resolve.closeButtonVisible === undefined)
        $ctrl.resolve.closeButtonVisible = true;

    if ($ctrl.resolve.closeButtonText === undefined)
        $ctrl.resolve.closeButtonText = "cancel";

    if ($ctrl.resolve.submitButtonVisible === undefined)
        $ctrl.resolve.submitButtonVisible = true;

    if ($ctrl.resolve.submitButtonDisabled === undefined)
        $ctrl.resolve.submitButtonDisabled = false;

    if ($ctrl.resolve.submitButtonText === undefined)
        $ctrl.resolve.submitButtonText = "yes";

}

