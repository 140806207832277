// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'mileage',
//   templateUrl: './mileage.component.html',
//   styleUrls: ['./mileage.component.scss']
// })
// export class MileageComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }


import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'mileage'
})
export class MileageComponent extends UpgradeComponent {
    @Input() value: number;
    @Input() vehicleType: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('mileage', elementRef, injector);
    }
}