import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactTypes, CustomerCultures } from '../constants';
import { DoNotCallStagingResponseDto, DoNotEmailStagingResponseDto, CustomerMarkDoNotCallDto,
    CustomerMarkDoNotEmailDto } from '../generated/models';
import { CustomerAdditionalContactDto } from '../generated/models/customer-additional-contact-dto';
import { CustomerDto } from '../generated/models/customer-dto';
import { CustomerPatch } from '../generated/models/customer-patch';
import { CustomerActivityHistoryClientService, CustomerClientService, CustomerContactHistoryClientService } from '../generated/services';
import { CustomerContact } from '../interfaces/customer-contact.interface';
import { Customer } from '../interfaces/customer.interface';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    constructor(private customerClient: CustomerClientService,
        private customerActivityHistoryClient: CustomerActivityHistoryClientService,
        private customerContactHistoryClient: CustomerContactHistoryClientService) { }

    public getCustomerActivities(entityId: string) {
        return this.customerActivityHistoryClient.GetCustomerActivityHistoryByEntityidGET(entityId).toPromise();
    }

    public getCustomer(entityId: string): Promise<Customer> {
        return forkJoin(
            this.customerClient.ByEntityByEntityidGET(entityId),
            this.customerContactHistoryClient.GetCustomerAdditionalContactsByEntityidGET(entityId)
        )
            .pipe(
                map(([customer, customerAdditionalContact]) => {
                    return this.resolveCustomer(customer, customerAdditionalContact);
                })
            ).toPromise();
    }

    public getCustomerName(entityId: string): Promise<Customer> {
        return this.customerClient.GetNameOnlyByEntityidGET(entityId)
            .pipe(map(result => {
                return this.resolveCustomer(result, null);
            })).toPromise();
    }

    public stageDoNotEmailList(emailList: string[]): Observable<DoNotEmailStagingResponseDto> {
        return this.customerClient.InsertDoNotEmailStagingPOST(emailList);
    }

    public stageDoNotCallList(phoneNumberList: string[]): Observable<DoNotCallStagingResponseDto> {
        return this.customerClient.InsertDoNotCallStagingPOST(phoneNumberList);
    }

    public finishDoNotEmailList(sessionId: string) {
        return this.customerClient.CustomerMarkDoNotEmailPOST({sessionID: sessionId, hypothetical: false} as CustomerMarkDoNotEmailDto);
    }

    public finishDoNotCallList(sessionId: string) {
        return this.customerClient.CustomerMarkDoNotCallPOST({sessionID: sessionId, hypothetical: false} as CustomerMarkDoNotCallDto);
    }

    public getCustomerCultureByCode(code: string) {
        if (code == null)
            return null;

        var cultureObj = CustomerCultures.find(function (culture) {
            return culture.name.toLowerCase() == code.toLowerCase();
        });
        return cultureObj != null ? cultureObj.displayName : null;
    }

    private resolveCustomer(customer: CustomerDto, contactHistory: CustomerAdditionalContactDto[]): Customer {
        const invalidContacts = customer.invalidContacts ? customer.invalidContacts.split(',') : [];

        const createPreferredContact = (value, contactType, doNotCallStatusTypeId?): CustomerContact => {
            if (value) {
                return {
                    contactType: contactType,
                    value: value,
                    isBad: _.includes(invalidContacts, value),
                    isPreferred: true,
                    doNotCallStatusTypeId: doNotCallStatusTypeId
                } as CustomerContact;
            }
        };

        const createAlternateContacts = () => {
            const alternateContacts = [];

            const contactExistsInCollection = (contact) => {
                return _.some(alternateContacts, function (p) {
                    return p.contactType === contact.contactID
                        && p.value === contact.contactValue;
                });
            };

            const contactIsAPreferredContact = (contact) => {
                switch (contact.contactID) {
                    case ContactTypes.homePhone: return customer.homePhone === contact.contactValue;
                    case ContactTypes.workPhone: return customer.workPhone === contact.contactValue;
                    case ContactTypes.cellPhone: return customer.cellPhone === contact.contactValue;
                    case ContactTypes.email: return customer.email === contact.contactValue;
                }
            };

            if (contactHistory) {
                contactHistory.forEach(function (contact) {
                    if (!contactExistsInCollection(contact) && !contactIsAPreferredContact(contact)) {
                        alternateContacts.push({
                            contactType: contact.contactID,
                            value: contact.contactValue,
                            isBad: _.includes(invalidContacts, contact.contactValue),
                            doNotCallStatusTypeId: contact.doNotCallStatusTypeId
                        });
                    }
                });
            }

            return alternateContacts;
        };

        const resolvedCustomer = {
            id: customer.customerID,
            isFullCustomer: customer.isFullCustomer,
            isAddressNotValid: customer.isAddressNotValid,
            firstName: customer.firstName,
            middleName: customer.middleName,
            lastName: customer.lastName,
            fullName: customer.fullName,
            companyName: customer.companyName,
            homePhoneNumber: createPreferredContact(customer.homePhone, ContactTypes.homePhone, customer.homePhone_DoNotCallStatusTypeId),
            workPhoneNumber: createPreferredContact(customer.workPhone, ContactTypes.workPhone, customer.workPhone_DoNotCallStatusTypeId),
            cellPhoneNumber: createPreferredContact(customer.cellPhone, ContactTypes.cellPhone, customer.cellPhone_DoNotCallStatusTypeId),
            emailAddress: createPreferredContact(customer.email, ContactTypes.email, invalidContacts),
            alternateContacts: createAlternateContacts(),
            doNotCall: customer.doNotCall,
            doNotEmail: customer.doNotEmail,
            doNotMail: customer.doNotMail,
            doNotText: customer.doNotText,
            address: {
                streetAddress: customer.address,
                streetAddress2: customer.address2,
                city: customer.city,
                stateId: customer.stateID,
                stateName: customer.state,
                zipCode: customer.zipCode,
                normalizedZipCode: customer.normalizedZipCode
            },
            pricingPlanId: customer.pricingPlanID,
            // We preserve this array so we can restore them when updating
            // the customer.
            invalidContacts: invalidContacts,
            preferredCultureName: customer.preferredCultureName,
            dealerID: customer.dealerID
        };

        return resolvedCustomer;
    }

    updateCustomer(customer): Promise<boolean> {
        const getUpdateCustomerRequest = (): CustomerPatch => {
            const homePhoneNumber = customer.homePhoneNumber && customer.homePhoneNumber.value !== undefined ? customer.homePhoneNumber.value : null;
            const workPhoneNumber = customer.workPhoneNumber && customer.workPhoneNumber.value !== undefined ? customer.workPhoneNumber.value : null;
            const cellPhoneNumber = customer.cellPhoneNumber && customer.cellPhoneNumber.value !== undefined ? customer.cellPhoneNumber.value : null;
            const emailAddress = customer.emailAddress && customer.emailAddress.value !== undefined ? customer.emailAddress.value : null;

            return {
                customerID: customer.id,
                firstName: customer.firstName,
                middleName: customer.middleName,
                lastName: customer.lastName,
                companyName: customer.companyName,
                address: customer.address.streetAddress,
                address2: customer.address.streetAddress2,
                city: customer.address.city,
                stateID: customer.address.stateId,
                state: customer.address.stateName,
                zipCode: customer.address.zipCode,
                homePhone: homePhoneNumber,
                workPhone: workPhoneNumber,
                cellPhone: cellPhoneNumber,
                invalidContacts: customer.invalidContacts ? customer.invalidContacts.join(',') : null,
                email: emailAddress,
                doNotCall: customer.doNotCall,
                doNotEmail: customer.doNotEmail,
                doNotMail: customer.doNotMail,
                fullName: getFullName(),
                pricingPlanID: customer.pricingPlanId,
                doNotText: customer.doNotText,
                preferredCultureName: customer.preferredCultureName
            };
        };

        const getFullName = () => {
            let fullName = customer.firstName;

            if (customer.middleName) {
                fullName += ' ' + customer.middleName;
            }

            if (customer.lastName) {
                fullName += ' ' + customer.lastName;
            }

            return fullName;
        };

        const request = getUpdateCustomerRequest();

        return this.customerClient.UpdatePATCHResponse(request)
            .pipe(
                map((response) => resolveCustomerUpdate(response))
            ).toPromise();

        function resolveCustomerUpdate(response: HttpResponse<any>) {
            return response.status === 204;
        }
    }
}
