"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var UserSearchConditionClientService = /** @class */ (function (_super) {
    __extends(UserSearchConditionClientService, _super);
    function UserSearchConditionClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    UserSearchConditionClientService.prototype.GetSearchesGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/UserSearchCondition/GetSearches", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    UserSearchConditionClientService.prototype.GetSearchesGET = function () {
        return this.GetSearchesGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.GetSearchModelByUsersearchconditionidGETResponse = function (userSearchConditionId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/UserSearchCondition/GetSearchModel/" + userSearchConditionId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.GetSearchModelByUsersearchconditionidGET = function (userSearchConditionId) {
        return this.GetSearchModelByUsersearchconditionidGETResponse(userSearchConditionId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    UserSearchConditionClientService.prototype.GetSearchCountsPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/UserSearchCondition/GetSearchCounts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    UserSearchConditionClientService.prototype.GetSearchCountsPOST = function (request) {
        return this.GetSearchCountsPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param luae undefined
     */
    UserSearchConditionClientService.prototype.FlushSearchCountsCacheByLuaeGETResponse = function (luae) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/UserSearchCondition/FlushSearchCountsCache/" + luae), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param luae undefined
     */
    UserSearchConditionClientService.prototype.FlushSearchCountsCacheByLuaeGET = function (luae) {
        return this.FlushSearchCountsCacheByLuaeGETResponse(luae).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    UserSearchConditionClientService.prototype.GetDealerLayOutExtsGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/UserSearchCondition/GetDealerLayOutExts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    UserSearchConditionClientService.prototype.GetDealerLayOutExtsGET = function () {
        return this.GetDealerLayOutExtsGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    UserSearchConditionClientService.prototype.GetActiveDealerLayOutExtsGETResponse = function () {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/UserSearchCondition/GetActiveDealerLayOutExts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    UserSearchConditionClientService.prototype.GetActiveDealerLayOutExtsGET = function () {
        return this.GetActiveDealerLayOutExtsGETResponse().pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.ShareUserSearchConditionByUsersearchconditionidPOSTResponse = function (userSearchConditionId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/UserSearchCondition/ShareUserSearchCondition/" + userSearchConditionId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.ShareUserSearchConditionByUsersearchconditionidPOST = function (userSearchConditionId) {
        return this.ShareUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.UnshareUserSearchConditionByUsersearchconditionidPOSTResponse = function (userSearchConditionId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/UserSearchCondition/UnshareUserSearchCondition/" + userSearchConditionId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.UnshareUserSearchConditionByUsersearchconditionidPOST = function (userSearchConditionId) {
        return this.UnshareUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.DeleteUserSearchConditionByUsersearchconditionidPOSTResponse = function (userSearchConditionId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/UserSearchCondition/DeleteUserSearchCondition/" + userSearchConditionId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param userSearchConditionId undefined
     */
    UserSearchConditionClientService.prototype.DeleteUserSearchConditionByUsersearchconditionidPOST = function (userSearchConditionId) {
        return this.DeleteUserSearchConditionByUsersearchconditionidPOSTResponse(userSearchConditionId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `layouts`:
     */
    UserSearchConditionClientService.prototype.UpdateDealerLayOutsByDealeridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.layouts;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/UserSearchCondition/UpdateDealerLayOuts/" + params.dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `layouts`:
     */
    UserSearchConditionClientService.prototype.UpdateDealerLayOutsByDealeridPOST = function (params) {
        return this.UpdateDealerLayOutsByDealeridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param resets undefined
     */
    UserSearchConditionClientService.prototype.ResetAutoAssignmentsPOSTResponse = function (resets) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = resets;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/UserSearchCondition/ResetAutoAssignments", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param resets undefined
     */
    UserSearchConditionClientService.prototype.ResetAutoAssignmentsPOST = function (resets) {
        return this.ResetAutoAssignmentsPOSTResponse(resets).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserSearchConditionClientService.ExecutePandoXPOSTParams` containing the following parameters:
     *
     * - `userSearchConditionId`:
     *
     * - `dealerId`:
     */
    UserSearchConditionClientService.prototype.ExecutePandoXPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.userSearchConditionId != null)
            __params = __params.set('userSearchConditionId', params.userSearchConditionId.toString());
        if (params.dealerId != null)
            __params = __params.set('dealerId', params.dealerId.toString());
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/UserSearchCondition/ExecutePandoX", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserSearchConditionClientService.ExecutePandoXPOSTParams` containing the following parameters:
     *
     * - `userSearchConditionId`:
     *
     * - `dealerId`:
     */
    UserSearchConditionClientService.prototype.ExecutePandoXPOST = function (params) {
        return this.ExecutePandoXPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    UserSearchConditionClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/UserSearchCondition/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    UserSearchConditionClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    UserSearchConditionClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/UserSearchCondition/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    UserSearchConditionClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    UserSearchConditionClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/UserSearchCondition/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    UserSearchConditionClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    UserSearchConditionClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/UserSearchCondition", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    UserSearchConditionClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    UserSearchConditionClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/UserSearchCondition", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    UserSearchConditionClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    UserSearchConditionClientService.GetSearchesGETPath = '/api/UserSearchCondition/GetSearches';
    UserSearchConditionClientService.GetSearchModelByUsersearchconditionidGETPath = '/api/UserSearchCondition/GetSearchModel/{userSearchConditionId}';
    UserSearchConditionClientService.GetSearchCountsPOSTPath = '/api/UserSearchCondition/GetSearchCounts';
    UserSearchConditionClientService.FlushSearchCountsCacheByLuaeGETPath = '/api/UserSearchCondition/FlushSearchCountsCache/{luae}';
    UserSearchConditionClientService.GetDealerLayOutExtsGETPath = '/api/UserSearchCondition/GetDealerLayOutExts';
    UserSearchConditionClientService.GetActiveDealerLayOutExtsGETPath = '/api/UserSearchCondition/GetActiveDealerLayOutExts';
    UserSearchConditionClientService.ShareUserSearchConditionByUsersearchconditionidPOSTPath = '/api/UserSearchCondition/ShareUserSearchCondition/{userSearchConditionId}';
    UserSearchConditionClientService.UnshareUserSearchConditionByUsersearchconditionidPOSTPath = '/api/UserSearchCondition/UnshareUserSearchCondition/{userSearchConditionId}';
    UserSearchConditionClientService.DeleteUserSearchConditionByUsersearchconditionidPOSTPath = '/api/UserSearchCondition/DeleteUserSearchCondition/{userSearchConditionId}';
    UserSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTPath = '/api/UserSearchCondition/UpdateDealerLayOuts/{dealerId}';
    UserSearchConditionClientService.ResetAutoAssignmentsPOSTPath = '/api/UserSearchCondition/ResetAutoAssignments';
    UserSearchConditionClientService.ExecutePandoXPOSTPath = '/api/UserSearchCondition/ExecutePandoX';
    UserSearchConditionClientService.ByIdGETPath = '/api/UserSearchCondition/{id}';
    UserSearchConditionClientService.ByIdDELETEPath = '/api/UserSearchCondition/{id}';
    UserSearchConditionClientService.GetManyGETPath = '/api/UserSearchCondition/GetMany';
    UserSearchConditionClientService.PUTPath = '/api/UserSearchCondition';
    UserSearchConditionClientService.POSTPath = '/api/UserSearchCondition';
    UserSearchConditionClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserSearchConditionClientService_Factory() { return new UserSearchConditionClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: UserSearchConditionClientService, providedIn: "root" });
    return UserSearchConditionClientService;
}(base_service_1.BaseService));
exports.UserSearchConditionClientService = UserSearchConditionClientService;
