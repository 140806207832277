"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["label[_ngcontent-%COMP%]{color:#444a50}.categoriesContainer[_ngcontent-%COMP%]{margin-top:5px;float:left;width:225px;margin-left:20px}.accessRightContainer[_ngcontent-%COMP%]{margin-top:5px;margin-left:15px}.accessRight[_ngcontent-%COMP%]{margin-top:5px}.actionButtonContainer[_ngcontent-%COMP%]{clear:both;height:35px}.saveButton[_ngcontent-%COMP%]{margin-left:10px}.categoryImage[_ngcontent-%COMP%]{width:20px;height:20px}.close[_ngcontent-%COMP%]{color:#fff}"];
exports.styles = styles;
