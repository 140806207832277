import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Settings } from '../constants/app-settings';
import { TranslateErrorKeys } from '../constants/translate-error-keys';
import { LogFactory } from '../services/log-factory.service';

@Injectable()
export class ToasterLoggerService {
  
    constructor(private logFactory: LogFactory, private translate: TranslateService, private toasterService: ToasterService) {

    }
   
    public genericError(message: string, data?: any, title?: string) {
        if (message != 'carFaxUnauthorizedException') {
            if (Settings.environmentVariables.environment === "production"
                || Settings.environmentVariables.environment === "staging" && Settings.environmentVariables.logDebugEnabled === false) {
                var translations = this.translate.instant([TranslateErrorKeys.generalErrorTitleKey, TranslateErrorKeys.generalErrorMessageKey]);
                message = translations[TranslateErrorKeys.generalErrorMessageKey];
                title = translations[TranslateErrorKeys.generalErrorTitleKey];
            }
        }

        this.error(message, data, title);
    }

    public error(message: string, data?: any, title?: string) {

        if (!title) title = "";
        if (!data) data = "";
        if (!message) {
            message = TranslateErrorKeys.generalErrorMessageKey;
            title = TranslateErrorKeys.generalErrorTitleKey;
        }

        this.translate.get([message, title]).subscribe((result) => {
            this.toasterService.pop({
                 type: 'warning', //'error',
                 title: result[title],
                 body: result[message]
            });
            this.logFactory.get('error').error('Error: ' + result[message], data);
        });

    }

    public info(message: string, data?: any, title?: string) {

        if (!title) title = "";
        if (!data) data = "";
        if (!message) {
            message = TranslateErrorKeys.generalErrorMessageKey;
            title = TranslateErrorKeys.generalErrorTitleKey;
        }

        this.translate.get([message, title]).subscribe((result) => {
            this.toasterService.pop({
                type: 'info',
                title: result[title],
                body: result[message]
            });
            this.logFactory.get('info').info('Info: ' + result[message], data);
        });
    }

    public success(message: string, data?: any, title?: string) {

        if (!title) title = "";
        if (!data) data = "";
        if (!message) {
            message = TranslateErrorKeys.generalErrorMessageKey;
            title = TranslateErrorKeys.generalErrorTitleKey;
        }

        this.translate.get([message, title]).subscribe((result) => {
            this.toasterService.pop({
                type: 'success',
                title: result[title],
                body: result[message]
            });
            this.logFactory.get('log').log('Success: ' + result[message], data);
            this.debug(result[message]);

        });
    }

    public warning(message: string, data?: any, title?: string) {

        if (!title) title = "";
        if (!data) data = "";
        if (!message) {
            message = TranslateErrorKeys.generalErrorMessageKey;
            title = TranslateErrorKeys.generalErrorTitleKey;
        }

        this.translate.get([message, title]).subscribe((result) => {
            this.toasterService.pop({
                type: 'warning',
                title: result[title],
                body: result[message],
                timeout: 10000
            });
            this.logFactory.get('warn').warn('Warning: ' + result[message], data);
        });
    }



    public debug(message: string) {
        if (!message) {
            var translations = this.translate.instant([TranslateErrorKeys.generalErrorTitleKey, TranslateErrorKeys.generalErrorMessageKey]);
            message = translations[TranslateErrorKeys.generalErrorMessageKey];
        }

        this.logFactory.get('debug').debug(message);
    }

    public log(message: string) {
        if (!message) {
            var translations = this.translate.instant([TranslateErrorKeys.generalErrorTitleKey, TranslateErrorKeys.generalErrorMessageKey]);
            message = translations[TranslateErrorKeys.generalErrorMessageKey];
        }

        this.logFactory.get('log').log(message);
    }

}



