"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var MinDirective = /** @class */ (function () {
    function MinDirective() {
    }
    MinDirective.prototype.validate = function (control) {
        return forms_1.Validators.min(this.min)(control);
    };
    return MinDirective;
}());
exports.MinDirective = MinDirective;
