import angular from 'angular';
import 'angular-translate';
import 'angular-translate-loader-partial';
import { currentUserCulture$ } from '../../core/translation';

const moduleName = 'AaApp.Translations';
export default moduleName;

var translationModule = angular.module(moduleName, ['pascalprecht.translate'])

translationModule.config(AddTranslations)

import en_CA from "../../i18n/en-CA.json";
import en_GB from "../../i18n/en-GB.json";
import en_US from "../../i18n/en-US.json";
import es_US from "../../i18n/es-US.json";
import fr_CA from "../../i18n/fr-CA.json";
import fr_FR from "../../i18n/fr-FR.json";

AddTranslations.$inject = ['$translateProvider']
function AddTranslations($translateProvider) {
    "ngInject";

    $translateProvider.translations("en-CA", en_CA);
    $translateProvider.translations("en-GB", en_GB);
    $translateProvider.translations("en-US", en_US);
    $translateProvider.translations("es-US", es_US);
    $translateProvider.translations("fr-CA", fr_CA);
    $translateProvider.translations("fr-FR", fr_FR);

    // Update ngx-translate's culture when the current user culture changes
    currentUserCulture$.subscribe(cultureName => $translateProvider.use(cultureName));
}
