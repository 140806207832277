export const PandoXRuleCategory = {
    Sales: 1,
    Service: 2,
    InventoryAcquisition: 3,
    Conquest: 4
}

export const PandoXRuleCategoryReverse = {
    1: 'Sales',
    2: 'Service',
    3: 'InventoryAcquisition',
    4: 'Conquest'
}