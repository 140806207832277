import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SafePipe } from '../../../filters/safe.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as srcDoc from 'srcdoc-polyfill';

@Component({
  selector: 'display-modal',
  templateUrl: './display-modal.component-ng.html',
  styleUrls: ['./display-modal.component.scss']
})

export class DisplayModalComponent implements OnInit {
    @Input() htmlContent: string;
    @Output() onClosed = new EventEmitter();
    @ViewChild('frame', {static: true}) public iframe: ElementRef;

    public safeHtml: SafeHtml;    

    constructor(public _bsModalRef: BsModalRef, protected sanitizer: DomSanitizer) {  }

    public ngOnInit(): void {
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.htmlContent);
        // TO Support Edge, IE as they do not support iframe/srcDoc following polyfill was used
        srcDoc.set(this.iframe.nativeElement, this.safeHtml);
    }

    public onCancel(): void {
        this.onClosed.emit({ confirmed: false });
        this._bsModalRef.hide();
    }

}
