"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var storage_service_1 = require("../modules/storage/storage.service");
var LocalePhonePipe = /** @class */ (function () {
    function LocalePhonePipe(storageService) {
        this.storageService = storageService;
    }
    LocalePhonePipe.prototype.transform = function (localePhone) {
        if (!localePhone) {
            return localePhone;
        }
        var savedCultureName = this.storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();
        if (cultureName === 'fr-fr') {
            return this.formatEurope(localePhone);
        }
        if (cultureName === 'en-gb') {
            return;
        }
        localePhone = localePhone + '';
        var numbers = localePhone.replace(/\D/g, '');
        // If the phone number is exactly 10 digits, assume it should be in NANP format.
        if (numbers.length === 10) {
            var char = { 0: '(', 3: ') ', 6: '-' };
            numbers = numbers.slice(0, 10);
            var formattedNumber = '';
            for (var i = 0; i < numbers.length; i++) {
                formattedNumber += (char[i] || '') + numbers[i];
            }
            return formattedNumber;
        }
        else {
            // If we can't recognize the format, return the value unformatted.
            return localePhone;
        }
    };
    LocalePhonePipe.prototype.formatEurope = function (phoneNumber) {
        var formatted = '';
        // if phone number has an odd length, the leading digit should stand alone.
        if (phoneNumber.length % 2 === 1) {
            formatted += '0' + phoneNumber[0] + ' ';
            phoneNumber = phoneNumber.substring(1);
        }
        for (var i = 0; i < phoneNumber.length; i++) {
            if (i % 2 === 0 && i !== 0) {
                formatted += ' ';
            }
            formatted += phoneNumber[i];
        }
        return formatted;
    };
    return LocalePhonePipe;
}());
exports.LocalePhonePipe = LocalePhonePipe;
