"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerActivityTypes = {
    autoAssistCommSent: 1,
    autoAssistNewHotLead: 2,
    geoAlertCampaign: 4,
    geoAlertActive: 5,
    autoAssistCustomerReplied: 8,
    geoAlertAd: 9,
    facebookAdded: 13,
    facebookVerified: 14,
    autofiDigitalRetailing: 16,
    autofiDigitalRetailingCreditApp: 17,
    autofiDigitalRetailingApptSched: 18,
};
