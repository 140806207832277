"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CashDownComponent = /** @class */ (function () {
    function CashDownComponent() {
    }
    CashDownComponent.prototype.ngOnInit = function () {
    };
    return CashDownComponent;
}());
exports.CashDownComponent = CashDownComponent;
