"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var platform_browser_1 = require("@angular/platform-browser");
var core_1 = require("@angular/core");
var AdaptivePositionPipe = /** @class */ (function () {
    function AdaptivePositionPipe(dom) {
        this.dom = dom;
    }
    AdaptivePositionPipe.prototype.transform = function (input, boldText) {
        return this.highlightQuery(input, boldText);
    };
    AdaptivePositionPipe.prototype.highlightQuery = function (str, query) {
        query = query.join(' ');
        if (str.toLocaleLowerCase().indexOf(query) > -1) {
            var left_str = str.substring(0, str.toLocaleLowerCase().indexOf(query));
            var right_str = str.substring(str.substring(0, str.toLocaleLowerCase().indexOf(query)).length + query.length, str.lenght);
            return left_str + '<strong>' + query + '</strong>' + right_str;
        }
        else
            return str;
    };
    return AdaptivePositionPipe;
}());
exports.AdaptivePositionPipe = AdaptivePositionPipe;
