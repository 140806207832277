"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var TrimClientService = /** @class */ (function (_super) {
    __extends(TrimClientService, _super);
    function TrimClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param modelId undefined
     * @return Success
     */
    TrimClientService.prototype.GetTrimsByModelIdByModelidGETResponse = function (modelId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Trim/GetTrimsByModelId/" + modelId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param modelId undefined
     * @return Success
     */
    TrimClientService.prototype.GetTrimsByModelIdByModelidGET = function (modelId) {
        return this.GetTrimsByModelIdByModelidGETResponse(modelId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param trimId undefined
     * @return Success
     */
    TrimClientService.prototype.GetTrimExtIdByTrimidGETResponse = function (trimId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Trim/GetTrimExtId/" + trimId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param trimId undefined
     * @return Success
     */
    TrimClientService.prototype.GetTrimExtIdByTrimidGET = function (trimId) {
        return this.GetTrimExtIdByTrimidGETResponse(trimId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
     *
     * - `newTrimId`:
     *
     * - `entityId`:
     */
    TrimClientService.prototype.GetFuelEconomyComparisonByNewtrimidByEntityidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Trim/GetFuelEconomyComparison/" + params.newTrimId + "/" + params.entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
     *
     * - `newTrimId`:
     *
     * - `entityId`:
     */
    TrimClientService.prototype.GetFuelEconomyComparisonByNewtrimidByEntityidGET = function (params) {
        return this.GetFuelEconomyComparisonByNewtrimidByEntityidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
     *
     * - `newTrimId`:
     *
     * - `entityId`:
     */
    TrimClientService.prototype.GetFeatureComparisonByNewtrimidByEntityidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Trim/GetFeatureComparison/" + params.newTrimId + "/" + params.entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETParams` containing the following parameters:
     *
     * - `newTrimId`:
     *
     * - `entityId`:
     */
    TrimClientService.prototype.GetFeatureComparisonByNewtrimidByEntityidGET = function (params) {
        return this.GetFeatureComparisonByNewtrimidByEntityidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TrimClientService.GetFeatureComparisonGETParams` containing the following parameters:
     *
     * - `trimId`:
     *
     * - `dealerId`:
     */
    TrimClientService.prototype.GetFeatureComparisonGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (params.trimId || []).forEach(function (val) { if (val != null)
            __params = __params.append('trimId', val.toString()); });
        if (params.dealerId != null)
            __params = __params.set('dealerId', params.dealerId.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Trim/GetFeatureComparison", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TrimClientService.GetFeatureComparisonGETParams` containing the following parameters:
     *
     * - `trimId`:
     *
     * - `dealerId`:
     */
    TrimClientService.prototype.GetFeatureComparisonGET = function (params) {
        return this.GetFeatureComparisonGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param modelIds undefined
     */
    TrimClientService.prototype.GetTrimsByModelsPOSTResponse = function (modelIds) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = modelIds;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Trim/GetTrimsByModels", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param modelIds undefined
     */
    TrimClientService.prototype.GetTrimsByModelsPOST = function (modelIds) {
        return this.GetTrimsByModelsPOSTResponse(modelIds).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    TrimClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Trim/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    TrimClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    TrimClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Trim/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    TrimClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    TrimClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Trim/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    TrimClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    TrimClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Trim", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    TrimClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    TrimClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Trim", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    TrimClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    TrimClientService.GetTrimsByModelIdByModelidGETPath = '/api/Trim/GetTrimsByModelId/{modelId}';
    TrimClientService.GetTrimExtIdByTrimidGETPath = '/api/Trim/GetTrimExtId/{trimId}';
    TrimClientService.GetFuelEconomyComparisonByNewtrimidByEntityidGETPath = '/api/Trim/GetFuelEconomyComparison/{newTrimId}/{entityId}';
    TrimClientService.GetFeatureComparisonByNewtrimidByEntityidGETPath = '/api/Trim/GetFeatureComparison/{newTrimId}/{entityId}';
    TrimClientService.GetFeatureComparisonGETPath = '/api/Trim/GetFeatureComparison';
    TrimClientService.GetTrimsByModelsPOSTPath = '/api/Trim/GetTrimsByModels';
    TrimClientService.ByIdGETPath = '/api/Trim/{id}';
    TrimClientService.ByIdDELETEPath = '/api/Trim/{id}';
    TrimClientService.GetManyGETPath = '/api/Trim/GetMany';
    TrimClientService.PUTPath = '/api/Trim';
    TrimClientService.POSTPath = '/api/Trim';
    TrimClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrimClientService_Factory() { return new TrimClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: TrimClientService, providedIn: "root" });
    return TrimClientService;
}(base_service_1.BaseService));
exports.TrimClientService = TrimClientService;
