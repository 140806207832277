import angular from 'angular';

angular.module('AaApp.Opp').filter('elapsedTime', elapsedTimeFilter);

elapsedTimeFilter.$inject = ['$filter'];

/* @ngInject */
function elapsedTimeFilter($filter) {
    return function (seconds) {

        var date = new Date(0, 0, 0, 0, 0, 0, 0);
        date.setSeconds(seconds);

        return $filter('date')(date, 'HH:mm:ss');
    }
}


