PricingPlanClientController.$inject = ["_", "$translate"];

/* injects from baggage-loader */
require('./pricing-plan-client.component.html');

import angular from 'angular';
import PricingPlanTypes from '../../constants/pricing-plan-types';
var pricingActive = 'pricingActive';
angular.module('AaApp.Opp').component('pricingPlanClient', {
  templateUrl: '/app/components/deal-sheet/pricing-plan-client.component.html',
  controller: PricingPlanClientController,
  bindings: {
    customer: '<',
    dealerSettings: '<',
    permissions: '<',
    pricingPlan: '<',
    onCustomerEdit: '&'
  }
});
/* @ngInject */

function PricingPlanClientController(_, $translate) {
  var $ctrl = this;
  $ctrl.planTitle;
  $ctrl.showPricingPlan;

  $ctrl.$onChanges = function () {
    if ($ctrl.pricingPlan && $ctrl.customer && $ctrl.customer.pricingPlanId && $ctrl.customer.pricingPlanId > 0) {
      $ctrl.showPricingPlan = true;

      var planTypes = _.invert(PricingPlanTypes);

      var plan = planTypes[$ctrl.customer.pricingPlanId];
      $translate([plan, pricingActive]).then(function (result) {
        $ctrl.planTitle = result[plan] + ' ' + result[pricingActive];
      });
    } else {
      $ctrl.showPricingPlan = false;
    }
  };
}