"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityMailHistorySource = {
    NVQ: 1,
    One2One: 2,
    SKG: 3,
    IntelligentMarketing: 4,
    FordCreditMailers: 5,
    SLM: 6,
    ServiceMarketing: 9,
    PartnerExport: 10
};
