import { Injectable } from '@angular/core';
import { StorageService } from '../modules/storage/storage.service';
@Injectable({
    providedIn: 'root'
})
export class DateFormatService {
    constructor(private storageService: StorageService) { }
    getDateTimeFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'dd/MM/yyyy hh:mm a';
            case 'en-ca':
                return 'dd/MM/yyyy h:mm a';
            case 'fr-ca':
                return 'yyyy-MM-dd hh:mm a';
            case 'fr-fr':
                return 'dd/MM/yyyy H:mm';
            case 'en-us':
            default:
                return 'MM/dd/yyyy h:mm a';
        }
    }

    getDateTimeMomentFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();

        return `${this.getDateMomentFormatString(thisCultureName)} ${this.getTimeMomentFormatString(thisCultureName)}`;
    }

    getDateMomentFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'DD/MM/YYYY';
            case 'en-ca':
                return 'DD/MM/YYYY';
            case 'fr-ca':
                return 'DD/MM/YYYY';
            case 'fr-fr':
                return 'DD/MM/YYYY';
            case 'en-us':
            default:
                return 'MM/DD/YYYY';
        }
    }

    getTimeMomentFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'hh:mm A';
            case 'en-ca':
                return 'hh:mm A';
            case 'fr-ca':
                return 'hh:mm A';
            case 'fr-fr':
                return 'H:mm';
            case 'en-us':
            default:
                return 'hh:mm A';
        }
    }


    getDateFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'dd/MM/yyyy';
            case 'en-ca':
                return 'dd/MM/yyyy';
            case 'fr-ca':
                return 'yyyy-MM-dd';
            case 'fr-fr':
                return 'dd/MM/yyyy';
            case 'en-us':
            default:
                return 'MM/dd/yyyy';
        }
    }
    // These are the alternate input formats supported by the datePicker.  This
    // allows the input of dates with a single digit month or date without having
    // to provide a leading zero.
    getAlternateDateFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'd!/M!/yyyy';
            case 'en-ca':
                return 'd!/M!/yyyy';
            case 'fr-ca':
                return 'yyyy-M!-d!';
            case 'fr-fr':
                return 'd!/M!/yyyy';
            case 'en-us':
            default:
                return 'M!/d!/yyyy';
        }
    }
    getTimeFormatString(cultureName?: string): string {
        const thisCultureName = cultureName || this.getCultureFromStorage();
        switch (thisCultureName.toLowerCase()) {
            case 'en-gb':
                return 'hh:mm a';
            case 'en-ca':
                return 'h:mm a';
            case 'fr-ca':
                return 'hh:mm a';
            case 'en-us':
            default:
                return 'h:mm a';
        }
    }
    getCultureFromStorage(): string {
        return this.storageService.getItem('cultureName') || 'en-us';
    }
}
