"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var toaster_logger_service_1 = require("../core/toaster-logger.service");
var CustomerWorkflowService = /** @class */ (function () {
    function CustomerWorkflowService(customerWorkflowClient, toaster) {
        this.customerWorkflowClient = customerWorkflowClient;
        this.toaster = toaster;
    }
    CustomerWorkflowService.prototype.getStartListCustomer = function (entityId) {
        return this.customerWorkflowClient.GetStartListCustomerByEntityidGET(entityId).toPromise();
    };
    CustomerWorkflowService.prototype.GetStartFormByStartableformtemplateid = function (startableFormTemplateId) {
        return this.customerWorkflowClient.GetStartFormByStartableformtemplateidGET(startableFormTemplateId).toPromise();
        //.pipe(map(result => {
        //    return this.resolvePandoForm(result);
        //})).toPromise();
    };
    //private resolvePandoForm(pandoForm: PandoFormDto): PandoForm {
    //    const resolvedForm = {
    //        formTitle: pandoForm.formTitle,
    //        formTemplateId: pandoForm.formTemplateId,
    //        formControls: pandoForm.formControls,
    //    };
    //    return resolvedForm;
    //}
    CustomerWorkflowService.prototype.startCustomerWorkflowByEntityidByWorkflowtemplateid = function (entityId, workflowTemplateId, worflowForm) {
        var params = {
            workflowTemplateId: workflowTemplateId,
            entityId: entityId,
            form: worflowForm
        };
        return this.customerWorkflowClient.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOST(params).toPromise();
    };
    return CustomerWorkflowService;
}());
exports.CustomerWorkflowService = CustomerWorkflowService;
