"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../../core/toaster-logger.service");
var notification_service_1 = require("../../../services/notification.service");
var rxjs_1 = require("rxjs");
var dms_push_enums_1 = require("./dms-push-enums");
var ngx_modal_wrapper_service_1 = require("../../../services/ngx-modal-wrapper.service");
var confirm_modal_component_1 = require("../../modals/confirm-modal.component");
var platform_browser_1 = require("@angular/platform-browser");
var constants_1 = require("../../../constants");
var DmsPushModalComponent = /** @class */ (function () {
    function DmsPushModalComponent(domSanitizer, _bsModalRef, modalService, tService, logger, notificationService, dealSheetService) {
        this.domSanitizer = domSanitizer;
        this._bsModalRef = _bsModalRef;
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.notificationService = notificationService;
        this.dealSheetService = dealSheetService;
        this.DisplayView = dms_push_enums_1.DisplayView;
        this.DmsSaleType = dms_push_enums_1.DmsSaleType;
        this.onSaveChanges = new core_1.EventEmitter();
    }
    DmsPushModalComponent.prototype.handlePushResponse = function (message) {
        if (message.data && message.data.data) {
            var result = message.data;
            this.showCloseButton = false;
            this.requestSucceeded = result.data.succeeded;
            if (result.data.matches) {
                console.log("DMS Push Customer matches returned");
                this.currentView = dms_push_enums_1.DisplayView.customerSelectView;
                this.customerMatches = result.data.matches;
            }
            else if (result.data.succeeded) {
                console.log("DMS Push Successful insert or update");
                switch (this.currentRequestType) {
                    case dms_push_enums_1.RequestType.customer:
                        this.currentView = dms_push_enums_1.DisplayView.customerPushedView;
                        this.showCloseButton = true;
                        this.newOrUpdatedDMSCustomerID = result.data.notes;
                        break;
                    case dms_push_enums_1.RequestType.fandI:
                        this.currentView = dms_push_enums_1.DisplayView.dealPushedView;
                        this.showCloseButton = true;
                        this.newOrUpdatedDMSDealID = result.data.notes;
                        break;
                    default:
                        this.currentView = dms_push_enums_1.DisplayView.customerPushedView;
                }
            }
            else {
                console.log("DMS Push Request failed. Error code: " + result.data.notes);
                this.currentView = dms_push_enums_1.DisplayView.errorView;
                this.dmsErrorMsg = "";
                if (result.data.notes && result.data.notes.length > 0) {
                    if (result.data.notes.indexOf('|') > -1) {
                        var parts = result.data.notes.split('|');
                        var transKey = parts[0];
                        var param1 = parts[1];
                        this.dmsErrorMsg = this.tService.instant("dmsPushError_" + transKey, { param1: param1 });
                    }
                    else {
                        this.dmsErrorMsg = this.tService.instant("dmsPushError_" + result.data.notes);
                    }
                }
                this.showCloseButton = true;
            }
            console.log(message.data);
        }
        else {
            console.log("DMS Push Request failed.");
            this.currentView = dms_push_enums_1.DisplayView.errorView;
            this.showCloseButton = true;
        }
        this.stopWorkingState();
    };
    DmsPushModalComponent.prototype.showFullAddress = function (customerMatch) {
        var c = customerMatch;
        var fullAddress = "";
        if (c.address1 && c.address1 != " ")
            fullAddress = fullAddress + " " + c.address1;
        if (c.address2 && c.address2 != " ")
            fullAddress = fullAddress + " " + c.address2;
        if (c.city && c.city != " ")
            fullAddress = fullAddress + " " + c.city;
        if (c.stateCode && c.stateCode != " ")
            fullAddress = fullAddress + ", " + c.stateCode;
        if (c.zipCode && c.zipCode.length > 1)
            fullAddress = fullAddress + " " + c.zipCode;
        return fullAddress;
    };
    DmsPushModalComponent.prototype.setAddressInfo = function () {
        this.address1 = null;
        this.address2 = null;
        this.cityStZip = null;
        if (this.customer) {
            if (this.customer.address.streetAddress)
                this.address1 = this.customer.address.streetAddress;
            if (this.customer.address.streetAddress2)
                this.address2 = this.customer.address.streetAddress2;
            this.cityStZip = this.customer.address.city + ', ' + this.customer.address.stateAbbr + ', ' + this.customer.address.zipCode;
        }
    };
    DmsPushModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._bsModalRef.hide();
        this.includeDeal = this.replacementVin != null;
        this.primaryPhone = null;
        this.customerSelection = null;
        this.newOrUpdatedDMSCustomerID = null;
        this.newOrUpdatedDMSDealID = null;
        this.showCloseButton = false;
        this.customerMatches = null;
        this.dmsErrorMsg = "";
        if (this.customer.address) {
            var state = this.states.find(function (s) { return s.id == _this.customer.address.stateId; });
            this.customer.address.stateAbbr = state.abbreviation;
        }
        this.setAddressInfo();
        if (this.customer.cellPhoneNumber) {
            this.primaryPhone = this.customer.cellPhoneNumber.value;
        }
        else if (this.customer.homePhoneNumber) {
            this.primaryPhone = this.customer.homePhoneNumber.value;
        }
        this.notificationSubscription = this.notificationService.newPushResponse.subscribe(function (message) {
            _this.handlePushResponse(message);
        });
        var getLatestPushRequest = this.buildGetLatestPushRequest();
        this.dealSheetService.dmsPushGetLatest(getLatestPushRequest)
            .then(function (data) {
            if (data) {
                _this.latestFullName = data.fullName;
                _this.latestCreateDateUtc = data.createDateUtc;
                _this.latestDmsCustomerId = data.dmsCustomerId;
                _this.latestDmsDealId = data.dmsDealId;
                _this.latestRequestTypeId = data.requestTypeId;
                if (data.requestTypeId == 1) {
                    _this.latestRequestType = "Deal";
                }
                else {
                    _this.latestRequestType = "Customer";
                }
            }
        });
        this.currentView = dms_push_enums_1.DisplayView.initialView;
        //this.customerMatches = [
        //    {
        //        firstName: "Mike",
        //        lastName: "Gone",
        //        dmsCustomerId: "123456234252351",
        //        phone1: 9495555460,
        //        email1: "mike@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "John",
        //        lastName: "Smith",
        //        dmsCustomerId: "123456234252352",
        //        phone1: 9495555460,
        //        email1: "john@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252353",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252354",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252310",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252311",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252312",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252313",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252314",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    },
        //    {
        //        firstName: "Mary",
        //        lastName: "Hopkins",
        //        dmsCustomerId: "123456234252315",
        //        phone1: 9495555460,
        //        email1: "mary@autoalert.com",
        //        address1: "123 Elm St",
        //        city: "Irvine",
        //        stateCode: "CA",
        //        zipCode: 92630
        //    }
        //];
        //this.currentView = DisplayView.customerSelectView;
    };
    DmsPushModalComponent.prototype.ngOnDestroy = function () {
    };
    DmsPushModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    DmsPushModalComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
        this.notificationSubscription.unsubscribe();
    };
    DmsPushModalComponent.prototype.processSelectedCustomer = function (dmsCustomerId) {
        this.customerSelection = dmsCustomerId;
        this.confirmCustomerSelection(dmsCustomerId);
    };
    DmsPushModalComponent.prototype.processNewCustomer = function () {
        this.customerSelection = null;
        var parameters = {
            dmsCustomerId: null,
            forceInsertCustomer: true,
            dmsDealId: null
        };
        this.pushToDms(parameters);
    };
    DmsPushModalComponent.prototype.isNullorWhiteSpace = function (value) {
        return !(value && value.trim().length > 0);
    };
    DmsPushModalComponent.prototype.setBusinessName = function (customer) {
        var businessname = null;
        if (!this.isNullorWhiteSpace(customer.companyName)) {
            businessname = customer.companyName;
        }
        else if (this.isNullorWhiteSpace(customer.firstName) && !this.isNullorWhiteSpace(customer.lastName)) {
            businessname = customer.lastName;
        }
        return businessname;
    };
    DmsPushModalComponent.prototype.buildRequest = function (parameters) {
        this.currentRequestType = this.includeDeal ? dms_push_enums_1.RequestType.fandI : dms_push_enums_1.RequestType.customer;
        var request;
        if (this.currentRequestType == dms_push_enums_1.RequestType.customer) {
            request = {
                ResponseMethod: 1,
                dealerId: this.dealerId,
                idReferences: [
                    {
                        type: 0,
                        referenceId: this.customer.id
                    }
                ],
                forceInsertCustomer: false,
                dmsCustomerId: null,
                businessName: this.setBusinessName(this.customer),
                firstName: this.customer.firstName,
                lastName: this.customer.lastName,
                address1: this.address1,
                address2: this.address2,
                city: this.customer.address ? this.customer.address.city : null,
                stateCode: this.customer.address ? this.customer.address.stateAbbr : null,
                zipCode: this.customer.address ? this.customer.address.zipCode : null,
                phoneNumber1: this.customer.homePhoneNumber ? this.customer.homePhoneNumber.value : null,
                phoneNumber2: this.customer.cellPhoneNumber ? this.customer.cellPhoneNumber.value : null,
                email1: this.customer.emailAddress ? this.customer.emailAddress.value : null,
                email2: null,
            };
            if (parameters) {
                request.dmsCustomerId = parameters.dmsCustomerId;
                request.forceInsertCustomer = parameters.forceInsertCustomer;
            }
        }
        else {
            var repSaleType = void 0;
            switch (this.replacementSaleType) {
                case constants_1.SaleTypes.cash:
                    repSaleType = dms_push_enums_1.DmsSaleType.cash;
                    break;
                case constants_1.SaleTypes.retail:
                    repSaleType = dms_push_enums_1.DmsSaleType.retail;
                    break;
                case constants_1.SaleTypes.lease:
                    repSaleType = dms_push_enums_1.DmsSaleType.lease;
                    break;
                default:
                    repSaleType = dms_push_enums_1.DmsSaleType.retail;
            }
            request = {
                customer: {
                    ResponseMethod: 1,
                    dealerId: this.dealerId,
                    idReferences: [
                        {
                            type: 0,
                            referenceId: this.customer.id
                        }
                    ],
                    forceInsertCustomer: false,
                    dmsCustomerId: null,
                    businessName: this.setBusinessName(this.customer),
                    firstName: this.customer.firstName,
                    lastName: this.customer.lastName,
                    address1: this.address1,
                    address2: this.address2,
                    city: this.customer.address ? this.customer.address.city : null,
                    stateCode: this.customer.address ? this.customer.address.stateAbbr : null,
                    zipCode: this.customer.address ? this.customer.address.zipCode : null,
                    phoneNumber1: this.customer.homePhoneNumber ? this.customer.homePhoneNumber.value : null,
                    phoneNumber2: this.customer.cellPhoneNumber ? this.customer.cellPhoneNumber.value : null,
                    email1: this.customer.emailAddress ? this.customer.emailAddress.value : null,
                    email2: null
                },
                vehicleDeal: {
                    dealNumber: null,
                    vin: this.replacementVin,
                    saleType: repSaleType,
                },
                tradeIns: [
                    {
                        vin: this.currentVin,
                        year: this.currentYear,
                        make: this.currentMake,
                        model: this.currentModel,
                        odometer: this.currentMileage == null || Number.isNaN(this.currentMileage) ? 0 : this.currentMileage,
                        allowance: this.currentTradeValue == null || Number.isNaN(this.currentTradeValue) ? 0 : this.currentTradeValue,
                    }
                ]
            };
            if (parameters) {
                request.customer.dmsCustomerId = parameters.dmsCustomerId;
                request.customer.forceInsertCustomer = parameters.forceInsertCustomer;
                request.dealNumber = parameters.dmsDealId;
            }
        }
        return request;
    };
    DmsPushModalComponent.prototype.buildGetLatestPushRequest = function () {
        var request = {
            dealerId: this.dealerId,
            idReferences: [
                {
                    type: 0,
                    referenceId: this.customer.id
                }
            ],
        };
        return request;
    };
    DmsPushModalComponent.prototype.startWorkingState = function () {
        var _this = this;
        this.busy = new Promise(function (resolve) {
            _this.stopWorkingState = resolve;
        });
    };
    DmsPushModalComponent.prototype.stopWorkingState = function () { };
    DmsPushModalComponent.prototype.pushToDms = function (parameters) {
        var _this = this;
        this.currentView = dms_push_enums_1.DisplayView.waitingView;
        this.startWorkingState();
        var request = this.buildRequest(parameters);
        if (this.currentRequestType == dms_push_enums_1.RequestType.fandI) {
            this.dealSheetService.dmsPushPushFAndI(request)
                .then(function (data) {
                if (data) {
                }
                else {
                    _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                }
            });
        }
        else {
            this.dealSheetService.dmsPushCustomer(request)
                .then(function (data) {
                if (data) {
                }
                else {
                    _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                }
            });
        }
    };
    DmsPushModalComponent.prototype.confirmCustomerSelection = function (dmsCustomerId) {
        var _this = this;
        var customer = this.customerMatches.find(function (c) { return c.dmsCustomerId == dmsCustomerId; });
        var fullName = "";
        var message = null;
        if (customer && (customer.firstName || customer.lastName)) {
            fullName = ((customer.firstName == null ? "" : customer.firstName) + " " + (customer.lastName == null ? "" : customer.lastName)).trim();
        }
        message = this.domSanitizer.bypassSecurityTrustHtml("<p>Are you sure you wish to push this customer? This push will overwrite the customer and vehicle information.</p><label class='dms-push-confirm-label'>Name:</label>" + fullName + "</br ><label class='dms-push-confirm-label'>DMS Customer ID:</label>" + dmsCustomerId);
        var initialState = {
            message: message
        };
        var bsModalRef = this.modalService.show(confirm_modal_component_1.ConfirmModalComponent, { initialState: initialState });
        bsModalRef.content.onClosed.subscribe(function (result) {
            if (result.confirmed)
                _this.pushSelectedCustomer(dmsCustomerId);
        });
    };
    DmsPushModalComponent.prototype.pushSelectedCustomer = function (dmsCustomerId) {
        var parameters = {
            dmsCustomerId: dmsCustomerId,
            forceInsertCustomer: false,
            dmsDealId: null
        };
        this.pushToDms(parameters);
    };
    return DmsPushModalComponent;
}());
exports.DmsPushModalComponent = DmsPushModalComponent;
