import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { AlertTypeDescriptors, AlertTypes } from '../../../constants';
import { LocaleCurrencyPipe } from '../../../filters/locale-currency.pipe';
import { LocaleDateTimePipe } from '../../../filters/locale-date-time.pipe';
import { LocaleDatePipe } from '../../../filters/locale-date.pipe';
import { LocaleNumberPipe } from '../../../filters/locale-number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from '../../../interfaces/alert.interface';

@Component({
    selector: 'alerts',
    templateUrl: './alerts.component-ng.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnChanges {

    @Input() values: Array<Alert>;
    @Input() showRelavantInfo: any;

    alertNames = _.invert(AlertTypes);

    private alertTypeDescriptors = AlertTypeDescriptors;
    private alertTypes = AlertTypes;

    constructor(private localeDateTime: LocaleDateTimePipe, private localeDate: LocaleDatePipe,
        private localeCurrency: LocaleCurrencyPipe, private localeNumber: LocaleNumberPipe, private translateService: TranslateService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showRelavantInfo && changes.showRelavantInfo.currentValue !== null) {

            // Sometimes the showRelevantInfo input comes over as a string
            if (typeof changes.showRelavantInfo.currentValue === 'string') {
                // tslint:disable-next-line:triple-equals
                this.showRelavantInfo = (changes.showRelavantInfo.currentValue.toLowerCase() == 'true');
            }
        }

        if (changes.values && changes.values.currentValue) {
            if (this.showRelavantInfo) {
                this.updateAlerts();
                
            }
        }
    }

    alertStyle(alertValue: any): string {
        if (alertValue && alertValue.details && alertValue.type) {
            return 'fa fa' + this.alertNames[alertValue.type].toLowerCase();
        } else {
            return 'fa fa' + this.alertNames[alertValue].toLowerCase();
        }
    }

    getAlertLetterKey(alertValue: any): string {
        const key = _.find(this.alertTypeDescriptors, function (item) {
            if (item === alertValue) { return true; }
            if (alertValue && item.id === alertValue.toString()) { return true; }
            if (alertValue && alertValue.type && item.id === alertValue.type.toString()) { return true; }
        });
        if (key == null || key.translateKey == null) {
            return null;
        }
        return key.translateKey;
    }

    updateAlerts(): void {
        for (let i = 0; i < this.values.length; i++) {
            const alert = this.values[i];

            switch (alert.type) {
                case this.alertTypes.alert:
                case this.alertTypes.flexAlert:
                    this.setAlertLocaleData(alert);
                    break;
                case this.alertTypes.service:
                    this.setServiceAlertLocaleData(alert);
                    break;
                case this.alertTypes.appointment:
                    this.setAppointmentAlertLocaleData(alert);
                    break;
                case this.alertTypes.contractEnd:
                    this.setContractEndAlertLocaleData(alert);
                    break;
                case this.alertTypes.mileage:
                    this.setMileageAlertLocaleData(alert);
                    break;
                case this.alertTypes.warranty:
                    this.setWarrantyAlertLocaleData(alert);
                    break;
                default:
                    break;
            }

            this.setAlertMessage(alert);
        }
    }

    setAlertLocaleData(alert: Alert): void {
        alert.localeData = {
            year: alert.details.yearID,
            make: alert.details.makeName,
            model: alert.details.modelName,
            trim: alert.details.trimName,
            term: alert.details.contractTerm,
            amount: this.localeCurrency.transform(alert.details.payment),
            difference: this.localeCurrency.transform(Math.abs(alert.details.paymentDifferenceAmount)),
            differenceLabel: alert.details.paymentDifferenceAmount > 0 ? 'more' : 'less'
        };
    }

    setServiceAlertLocaleData(alert: any): void {
        alert.localeData = {
            repairOrderDate: alert.details.openDate != null ? this.localeDate.transform(alert.details.openDate) : "",
            total: alert.details.eighteenMonthServiceCount
        };
    }

    setAppointmentAlertLocaleData(alert: any): void {
        alert.localeData = {
            appointmentDate: alert.details.appointmentDate != null ? this.localeDateTime.transform(alert.details.appointmentDate) : ""
        };
    }

    setContractEndAlertLocaleData(alert: any): void {
        alert.localeData = {
            made: alert.details.paymentsMade,
            remaining: alert.details.paymentsRemaining
        };
    }

    setMileageAlertLocaleData(alert: any): void {
        alert.localeData = {
            projectedMileage: this.localeNumber.transform(alert.details.estimatedMaturityMileage),
            currentPenalty: this.localeCurrency.transform(alert.details.currentMileagePenalty),
            projectedPenalty: this.localeCurrency.transform(alert.details.estimatedMileagePenalty)
        };
    }

    setWarrantyAlertLocaleData(alert) {
        alert.localeData = {
            months: alert.details.warrantyMonthsRemaining,
            miles: this.localeNumber.transform(alert.details.warrantyMilesRemaining)
        };
    }

    // We're performing the translation here instead of in the template because Angular's {{...}} is encoding
    // &nbsp; to &#160, which is the numeric representation of a non-blank space.  This was causing &#160
    // to be displayed literally on the page instead of a non-blank space.  We're using the ng-bind-html to inject
    // alert.message into the template so the double-encoding doesn't occur.
    // Example: <span ... ng-bind-html="alert.message"></span>
    setAlertMessage(alert: any) {
        const alertLetterKey = this.getAlertLetterKey(alert);
        let translateKey = '';
        if (alert && alert.translateKey) {
            translateKey = alert.translateKey;
        }

        this.translateService.get([translateKey, alertLetterKey], alert.localeData)
            .subscribe((translationSet) => {
                alert.message =
                    '<span class="alerts-letterblock ' + this.alertStyle(alert.type) +
                    '" title="' + translationSet[alert.translateKey] + '">' + translationSet[alertLetterKey] + '</span>';
            }, (translationId) => {
                alert.message = '<span class="alerts-letterblock ' + this.alertStyle(alert.type) + '" title="' + translationId + '"></span>';
            });
    }
}
