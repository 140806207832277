"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var activity_client_service_1 = require("./services/activity-client.service");
exports.ActivityClientService = activity_client_service_1.ActivityClientService;
var activity_history_client_service_1 = require("./services/activity-history-client.service");
exports.ActivityHistoryClientService = activity_history_client_service_1.ActivityHistoryClientService;
var announcement_client_service_1 = require("./services/announcement-client.service");
exports.AnnouncementClientService = announcement_client_service_1.AnnouncementClientService;
var cache_management_client_service_1 = require("./services/cache-management-client.service");
exports.CacheManagementClientService = cache_management_client_service_1.CacheManagementClientService;
var change_log_client_service_1 = require("./services/change-log-client.service");
exports.ChangeLogClientService = change_log_client_service_1.ChangeLogClientService;
var credit_soft_pull_result_entity_del_client_service_1 = require("./services/credit-soft-pull-result-entity-del-client.service");
exports.CreditSoftPullResultEntityDelClientService = credit_soft_pull_result_entity_del_client_service_1.CreditSoftPullResultEntityDelClientService;
var customer_client_service_1 = require("./services/customer-client.service");
exports.CustomerClientService = customer_client_service_1.CustomerClientService;
var customer_activity_history_client_service_1 = require("./services/customer-activity-history-client.service");
exports.CustomerActivityHistoryClientService = customer_activity_history_client_service_1.CustomerActivityHistoryClientService;
var customer_contact_history_client_service_1 = require("./services/customer-contact-history-client.service");
exports.CustomerContactHistoryClientService = customer_contact_history_client_service_1.CustomerContactHistoryClientService;
var customer_workflow_client_service_1 = require("./services/customer-workflow-client.service");
exports.CustomerWorkflowClientService = customer_workflow_client_service_1.CustomerWorkflowClientService;
var dealer_client_service_1 = require("./services/dealer-client.service");
exports.DealerClientService = dealer_client_service_1.DealerClientService;
var dealer_asset_client_service_1 = require("./services/dealer-asset-client.service");
exports.DealerAssetClientService = dealer_asset_client_service_1.DealerAssetClientService;
var dealer_price_client_service_1 = require("./services/dealer-price-client.service");
exports.DealerPriceClientService = dealer_price_client_service_1.DealerPriceClientService;
var dealer_rates_client_service_1 = require("./services/dealer-rates-client.service");
exports.DealerRatesClientService = dealer_rates_client_service_1.DealerRatesClientService;
var dealer_rebate_client_service_1 = require("./services/dealer-rebate-client.service");
exports.DealerRebateClientService = dealer_rebate_client_service_1.DealerRebateClientService;
var dealer_search_client_service_1 = require("./services/dealer-search-client.service");
exports.DealerSearchClientService = dealer_search_client_service_1.DealerSearchClientService;
var dealer_setting_client_service_1 = require("./services/dealer-setting-client.service");
exports.DealerSettingClientService = dealer_setting_client_service_1.DealerSettingClientService;
var dealer_smtp_setting_client_service_1 = require("./services/dealer-smtp-setting-client.service");
exports.DealerSmtpSettingClientService = dealer_smtp_setting_client_service_1.DealerSmtpSettingClientService;
var dealer_tradein_client_service_1 = require("./services/dealer-tradein-client.service");
exports.DealerTradeinClientService = dealer_tradein_client_service_1.DealerTradeinClientService;
var dealer_upgrade_proposal_setting_client_service_1 = require("./services/dealer-upgrade-proposal-setting-client.service");
exports.DealerUpgradeProposalSettingClientService = dealer_upgrade_proposal_setting_client_service_1.DealerUpgradeProposalSettingClientService;
var deal_sheet_client_service_1 = require("./services/deal-sheet-client.service");
exports.DealSheetClientService = deal_sheet_client_service_1.DealSheetClientService;
var deskit_client_service_1 = require("./services/deskit-client.service");
exports.DeskitClientService = deskit_client_service_1.DeskitClientService;
var discrepancy_report_client_service_1 = require("./services/discrepancy-report-client.service");
exports.DiscrepancyReportClientService = discrepancy_report_client_service_1.DiscrepancyReportClientService;
var email_conversation_client_service_1 = require("./services/email-conversation-client.service");
exports.EmailConversationClientService = email_conversation_client_service_1.EmailConversationClientService;
var entity_client_service_1 = require("./services/entity-client.service");
exports.EntityClientService = entity_client_service_1.EntityClientService;
var entity_opportunity_client_service_1 = require("./services/entity-opportunity-client.service");
exports.EntityOpportunityClientService = entity_opportunity_client_service_1.EntityOpportunityClientService;
var eula_client_service_1 = require("./services/eula-client.service");
exports.EulaClientService = eula_client_service_1.EulaClientService;
var eula_history_client_service_1 = require("./services/eula-history-client.service");
exports.EulaHistoryClientService = eula_history_client_service_1.EulaHistoryClientService;
var ext_customer_activity_client_service_1 = require("./services/ext-customer-activity-client.service");
exports.ExtCustomerActivityClientService = ext_customer_activity_client_service_1.ExtCustomerActivityClientService;
var ford_credit_approval_client_service_1 = require("./services/ford-credit-approval-client.service");
exports.FordCreditApprovalClientService = ford_credit_approval_client_service_1.FordCreditApprovalClientService;
var inventory_client_service_1 = require("./services/inventory-client.service");
exports.InventoryClientService = inventory_client_service_1.InventoryClientService;
var log_client_service_1 = require("./services/log-client.service");
exports.LogClientService = log_client_service_1.LogClientService;
var make_client_service_1 = require("./services/make-client.service");
exports.MakeClientService = make_client_service_1.MakeClientService;
var messaging_client_service_1 = require("./services/messaging-client.service");
exports.MessagingClientService = messaging_client_service_1.MessagingClientService;
var model_client_service_1 = require("./services/model-client.service");
exports.ModelClientService = model_client_service_1.ModelClientService;
var pando_claim_client_service_1 = require("./services/pando-claim-client.service");
exports.PandoClaimClientService = pando_claim_client_service_1.PandoClaimClientService;
var pando_passthrough_client_service_1 = require("./services/pando-passthrough-client.service");
exports.PandoPassthroughClientService = pando_passthrough_client_service_1.PandoPassthroughClientService;
var print_offer_batch_client_service_1 = require("./services/print-offer-batch-client.service");
exports.PrintOfferBatchClientService = print_offer_batch_client_service_1.PrintOfferBatchClientService;
var replacement_client_service_1 = require("./services/replacement-client.service");
exports.ReplacementClientService = replacement_client_service_1.ReplacementClientService;
var role_client_service_1 = require("./services/role-client.service");
exports.RoleClientService = role_client_service_1.RoleClientService;
var sale_client_service_1 = require("./services/sale-client.service");
exports.SaleClientService = sale_client_service_1.SaleClientService;
var search_client_service_1 = require("./services/search-client.service");
exports.SearchClientService = search_client_service_1.SearchClientService;
var tag_client_service_1 = require("./services/tag-client.service");
exports.TagClientService = tag_client_service_1.TagClientService;
var text_message_client_service_1 = require("./services/text-message-client.service");
exports.TextMessageClientService = text_message_client_service_1.TextMessageClientService;
var trim_client_service_1 = require("./services/trim-client.service");
exports.TrimClientService = trim_client_service_1.TrimClientService;
var trim_alternate_offer_client_service_1 = require("./services/trim-alternate-offer-client.service");
exports.TrimAlternateOfferClientService = trim_alternate_offer_client_service_1.TrimAlternateOfferClientService;
var user_client_service_1 = require("./services/user-client.service");
exports.UserClientService = user_client_service_1.UserClientService;
var user_module_access_client_service_1 = require("./services/user-module-access-client.service");
exports.UserModuleAccessClientService = user_module_access_client_service_1.UserModuleAccessClientService;
var user_profile_client_service_1 = require("./services/user-profile-client.service");
exports.UserProfileClientService = user_profile_client_service_1.UserProfileClientService;
var user_search_condition_client_service_1 = require("./services/user-search-condition-client.service");
exports.UserSearchConditionClientService = user_search_condition_client_service_1.UserSearchConditionClientService;
var vehicle_final_trade_value_temp_change_client_service_1 = require("./services/vehicle-final-trade-value-temp-change-client.service");
exports.VehicleFinalTradeValueTempChangeClientService = vehicle_final_trade_value_temp_change_client_service_1.VehicleFinalTradeValueTempChangeClientService;
var vehicle_user_trade_value_client_service_1 = require("./services/vehicle-user-trade-value-client.service");
exports.VehicleUserTradeValueClientService = vehicle_user_trade_value_client_service_1.VehicleUserTradeValueClientService;
