"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var storage_service_1 = require("../../../../modules/storage/storage.service");
var date_format_service_1 = require("../../../../services/date-format.service");
var user_service_1 = require("../../../../services/user.service");
var moment = require("moment");
var CustomerConnectScheduleActionComponent = /** @class */ (function () {
    function CustomerConnectScheduleActionComponent(dateFormatService, storageService, userService) {
        this.storageService = storageService;
        this.userService = userService;
        this.onLogScheduleSubmit = new core_1.EventEmitter();
        this.activityActionEnum = constants_1.ActivityActionEnum;
        this.editedActivityAssignToUser = null;
        this.validatedAssignee = {
            isValid: false,
            index: -1
        };
        this.ownerId = null;
        this.owner = null;
        this.userList = [];
        this.activity = null;
        // owner info
        this.soldById = null;
        this.soldByName = null;
        this.soldByStatus = null;
        // setup time picker
        this.mytime = new Date();
        this.hstep = 1;
        this.mstep = 5;
        this.status = { opened: false };
        this.model = {
            scheduleDueDate: new Date(),
            time: new Date(),
            notes: '',
            user: -1,
            sendReminder: false,
            userChoice: 'other'
        };
        this.scheduleTypeAction = this.subAction; // dialog type
        this.dateOptions = {};
        // setup ui display object type for custom dialogbox
        this.scheduleType = {
            scheduleCall: {
                activityId: this.activityActionEnum.Schedule_Call,
                description: constants_1.TranslateKeys.scheduleTitleCallKey,
                IsAssignToVisible: true,
                IsDateVisible: true,
                IsTimeVisible: true,
                IsReminderVisible: true
            },
            scheduleAppt: {
                activityId: constants_1.ActivityActionEnum.Schedule_Appt,
                description: constants_1.TranslateKeys.scheduleTitleApptKey,
                IsAssignToVisible: true,
                IsDateVisible: true,
                IsTimeVisible: true,
                IsReminderVisible: true
            },
            rescheduleCall: {
                activityId: constants_1.ActivityActionEnum.Reschedule_Call,
                description: constants_1.TranslateKeys.rescheduleTitleCallKey,
                IsAssignToVisible: false,
                IsDateVisible: true,
                IsTimeVisible: true,
                IsReminderVisible: true
            },
            rescheduleAppt: {
                activityId: constants_1.ActivityActionEnum.Reschedule_Appt,
                description: constants_1.TranslateKeys.rescheduleTitleApptKey,
                IsAssignToVisible: false,
                IsDateVisible: true,
                IsTimeVisible: true,
                IsReminderVisible: true
            },
            reassignCall: {
                activityId: constants_1.ActivityActionEnum.Reassign_Call,
                description: constants_1.TranslateKeys.reassignTitleCallKey,
                IsAssignToVisible: true,
                IsDateVisible: false,
                IsTimeVisible: false,
                IsReminderVisible: false
            },
            reassignAppt: {
                activityId: constants_1.ActivityActionEnum.Reassign_Appt,
                description: constants_1.TranslateKeys.reassignTitleApptKey,
                IsAssignToVisible: true,
                IsDateVisible: false,
                IsTimeVisible: false,
                IsReminderVisible: false
            }
        };
        this.dateFormat = dateFormatService.getDateFormatString();
    }
    CustomerConnectScheduleActionComponent.prototype.ngOnInit = function () {
        this.initModels();
        this.currentLoginUserProfile = this.storageService.getItem(constants_1.StorageKeys.UserProfile);
    };
    CustomerConnectScheduleActionComponent.prototype.initModels = function () {
        this.editedActivityAssignToUser = null;
        this.currentLoginUserProfile = this.storageService.getItem(constants_1.StorageKeys.UserProfile);
        this.validatedAssignee = {
            isValid: false,
            index: -1
        };
        var savedCultureName = this.storageService.getItem(constants_1.StorageKeys.CultureName);
        var cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();
        // get's passed via resolve
        this.ownerId = null;
        this.owner = null;
        this.userList = [];
        this.activity = null;
        // owner info
        this.soldById = null;
        this.soldByName = null;
        this.soldByStatus = null;
        // setup time picker
        this.mytime = new Date();
        this.hstep = 1;
        this.mstep = 5;
        this.ismeridian = (cultureName === 'fr-fr') ? false : true;
        this.status = { opened: false };
        var now = new Date();
        var dueDate = this.dateAddDays(now, (5 / (24 * 60))); // add 5 min to current time
        this.model = {
            scheduleDueDate: dueDate,
            time: dueDate,
            notes: '',
            user: -1,
            sendReminder: false,
            userChoice: 'other'
        };
    };
    CustomerConnectScheduleActionComponent.prototype.dateAddDays = function (date, daysToAdd) {
        var newDate = new Date(date);
        newDate.setDate(date.getDate() + daysToAdd);
        return newDate;
    };
    // Disable weekend selection
    CustomerConnectScheduleActionComponent.prototype.disabled = function (data) {
        var date = data.date, mode = data.mode;
        return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    };
    CustomerConnectScheduleActionComponent.prototype.calendarClick = function ($event) {
        this.status.opened = true;
    };
    CustomerConnectScheduleActionComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.subAction && this.dialogParams) {
            if (this.isSltUser == null) {
                this.userService.getUserProfile()
                    .then(function (userProfile) {
                    _this.isSltUser = userProfile.isSltUser;
                    _this.updateModel();
                });
            }
            else {
                this.updateModel();
            }
        }
    };
    CustomerConnectScheduleActionComponent.prototype.updateModel = function () {
        this.opportunityId = this.dialogParams.opportunityId;
        this.dealerId = this.dialogParams.dealerId;
        this.scheduleTypeAction = this.subAction; // dialog type
        this.maxFutureDays = this.dialogParams.maxScheduleFutureDays;
        this.ownerUserInfo = this.dialogParams.ownerUserInfoObject;
        var now = new Date();
        var maxDate;
        if (this.isSltUser) {
            var maxSltScheduleDays = 30;
            maxDate = this.dialogParams.expirationDate ?
                new Date(this.dialogParams.expirationDate) :
                moment().add(maxSltScheduleDays, 'days').toDate();
        }
        else {
            maxDate = this.dateAddDays(now, this.maxFutureDays);
        }
        this.dateOptions = {
            minDate: now,
            maxDate: maxDate
        };
        if (this.dialogParams.usersObject && this.dialogParams.usersObject.length) {
            if (this.userList.length > 0) {
                this.userList = [];
            }
            for (var i = 0; i < this.dialogParams.usersObject.length; i++) {
                // Must create a new array and not reference the binding
                // as it can change the parent array. filter and add only user's with type id 1 that is client
                if (this.dialogParams.usersObject[i].userTypeId === 1) {
                    this.userList.push(this.dialogParams.usersObject[i]);
                }
            }
            // sort it to ascending order by fullName
            this.userList.sort(this.dynamicSort('fullName'));
        }
        // get passed owner id
        this.ownerId = this.dialogParams.dealSheetOwnerId;
        // if activity is passed from parent
        if (this.dialogParams.activityObject) {
            this.activity = this.dialogParams.activityObject;
        }
        this.setupScheduleDialogUX();
    };
    CustomerConnectScheduleActionComponent.prototype.setupScheduleDialogUX = function () {
        switch (this.scheduleTypeAction) {
            case constants_1.ActivityActionEnum.Schedule_Call:
                // setup dialogUX
                this.currentScheduleType = this.scheduleType.scheduleCall;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.model.user = -1;
                break;
            case constants_1.ActivityActionEnum.Schedule_Appt:
                this.currentScheduleType = this.scheduleType.scheduleAppt;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.model.user = -1;
                break;
            case constants_1.ActivityActionEnum.Reschedule_Call:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.rescheduleCall;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                }
                else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
            case constants_1.ActivityActionEnum.Reschedule_Appt:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.rescheduleAppt;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                }
                else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
            case constants_1.ActivityActionEnum.Reassign_Call:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.reassignCall;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                }
                else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
            case constants_1.ActivityActionEnum.Reassign_Appt:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.reassignAppt;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                }
                else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
        }
        //        this.dialogTitle = this.currentScheduleType.description;
        this.getAssignToSelectedState();
    };
    // based on rules of WEBUI-4465 ownerId is replaced with soldByid
    CustomerConnectScheduleActionComponent.prototype.getValidOwnerId = function (currentOwnerId) {
        currentOwnerId = this.getValidatedAssignToUserObject(currentOwnerId, this.userList).id;
        var userId = -1;
        if (this.ownerUserInfo) {
            if (currentOwnerId === -1 && this.ownerUserInfo.soldByUserId == null && this.ownerUserInfo.soldByUserFullName == null) {
                return -1;
            }
            if (currentOwnerId === -1 && this.ownerUserInfo.soldByUserId == null && this.ownerUserInfo.soldByUserFullName != null) {
                userId = this.getUserIdbyName(this.ownerUserInfo.soldByUserFullName, this.userList);
                return userId;
            }
            if (currentOwnerId === -1 && this.ownerUserInfo.soldByUserId != null) {
                return this.ownerUserInfo.soldByUserId;
            }
            if (currentOwnerId !== -1 && this.ownerUserInfo.ownerUserEnabled === false && this.ownerUserInfo.soldByUserId != null) {
                return this.ownerUserInfo.soldByUserId;
            }
        }
        return currentOwnerId;
    };
    CustomerConnectScheduleActionComponent.prototype.getUserIdbyName = function (userName, userList) {
        for (var i = 0; i < userList.length; i++) {
            if (userList[i].fullName.toLowerCase() === userName.toLowerCase()) {
                return userList[i].id;
            }
        }
        return -1;
    };
    CustomerConnectScheduleActionComponent.prototype.getValidatedAssignToUserObject = function (userID, userList) {
        var resultObj = {
            isValid: false,
            index: -1,
            fullName: null,
            id: -1
        };
        for (var i = 0; i < userList.length; i++) {
            if (userList[i].id === userID) {
                resultObj.isValid = true;
                resultObj.index = i;
                resultObj.fullName = userList[i].fullName;
                resultObj.id = userList[i].id;
                return resultObj;
            }
        }
        return resultObj;
    };
    CustomerConnectScheduleActionComponent.prototype.getAssignToSelectedState = function () {
        if (this.currentLoginUserProfile && !this.currentLoginUserProfile.employeeUser) {
            this.model.userChoice = 'self';
            if (this.currentLoginUserProfile.userId === this.owner.id) {
                this.owner.isValid = false;
            }
            return;
        }
        if (this.owner && this.owner.isValid) {
            this.model.userChoice = 'owner';
            return;
        }
        this.model.userChoice = 'other';
        return;
    };
    // sort a list object based on property
    CustomerConnectScheduleActionComponent.prototype.dynamicSort = function (property) {
        var sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    };
    CustomerConnectScheduleActionComponent.prototype.onSubmit = function () {
        this.postActivity();
    };
    CustomerConnectScheduleActionComponent.prototype.postActivity = function () {
        var assignToUserId = -1;
        switch (this.model.userChoice) {
            case 'self':
                assignToUserId = this.currentLoginUserProfile.userId;
                break;
            case 'owner':
                assignToUserId = this.owner.id;
                break;
            case 'other':
                assignToUserId = this.model.user;
                break;
        }
        if (!assignToUserId) {
            this.errorMessage = constants_1.TranslateKeys.scheduleAssignToErrorMsgKey;
            this.onLogScheduleSubmit.emit(null);
            return;
        }
        if (assignToUserId === -1) {
            this.errorMessage = constants_1.TranslateKeys.scheduleAssignToErrorMsgKey;
            this.onLogScheduleSubmit.emit(null);
            return;
        }
        var dateTime = new Date(this.model.scheduleDueDate.getFullYear(), this.model.scheduleDueDate.getMonth(), this.model.scheduleDueDate.getDate(), this.model.time.getHours(), this.model.time.getMinutes(), this.model.time.getSeconds());
        var recordId = null;
        if (this.activity) {
            if (this.currentScheduleType.activityId !== constants_1.ActivityActionEnum.Schedule_Call &&
                this.currentScheduleType.activityId !== constants_1.ActivityActionEnum.Schedule_Appt) {
                recordId = this.activity.activityId;
            }
        }
        var activity = {
            entityID: this.opportunityId,
            activityActionID: this.currentScheduleType.activityId,
            notes: this.model.notes,
            assignTo: assignToUserId,
            dueDate: moment(dateTime).format('YYYY-MM-DD[T]HH:mm:ss'),
            sendReminder: this.model.sendReminder,
            activityId: recordId,
            activityActionSubTypeID: null,
            isFollowUpScheduleAppt: false,
            uid: null
        };
        if (!activity.notes) {
            activity.notes = '';
        }
        if (this.dialogParams.uid) {
            activity.uid = this.dialogParams.uid;
        }
        // send activity to dealsheet
        this.onLogScheduleSubmit.emit(activity);
        this.initModels();
    };
    CustomerConnectScheduleActionComponent.prototype.onCancel = function () {
        if (this.dialogParams.uid) {
            this.onLogScheduleSubmit.emit({ uid: this.dialogParams.uid, status: 'cancel' });
        }
        else {
            this.onLogScheduleSubmit.emit('cancel');
        }
        this.initModels();
    };
    CustomerConnectScheduleActionComponent.prototype.setScheduleDueDate = function (date, model) {
        this.model.scheduleDueDate = date;
        this.scheduleDueDateModel = model;
    };
    CustomerConnectScheduleActionComponent.prototype.usersTrackBy = function (index, obj) {
        if (!obj) {
            return null;
        }
        return obj.id;
    };
    return CustomerConnectScheduleActionComponent;
}());
exports.CustomerConnectScheduleActionComponent = CustomerConnectScheduleActionComponent;
