"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var token_service_1 = require("../../../modules/oidc-auth/token.service");
var user_service_1 = require("../../../services/user.service");
var core_2 = require("@ngx-translate/core");
var ImpersonationStatusComponent = /** @class */ (function () {
    function ImpersonationStatusComponent(authService, userService, tokenService, tService) {
        this.authService = authService;
        this.userService = userService;
        this.tokenService = tokenService;
        this.tService = tService;
    }
    ImpersonationStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        var impersonatingUsername = this.tokenService.getImpersonatingUsername();
        if (impersonatingUsername) {
            this.userService.getUser(impersonatingUsername).then(function (response) {
                _this.impersonatingUserFullName = response.fullName;
            });
        }
    };
    ImpersonationStatusComponent.prototype.ngOnChanges = function (changes) {
        if (changes.userFullName && !changes.userFullName.isFirstChange()) {
            this.impersonationStatus = this.tService
                .instant('viewingAsUser', { impersonatingUser: this.impersonatingUserFullName, impersonatedUser: this.userFullName });
        }
    };
    ImpersonationStatusComponent.prototype.logOut = function () {
        this.authService.signOut();
    };
    return ImpersonationStatusComponent;
}());
exports.ImpersonationStatusComponent = ImpersonationStatusComponent;
