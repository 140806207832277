"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var mobile_deal_sheet_data_caching_service_1 = require("../../../../services/mobile-deal-sheet-data-caching.service");
var MobileActivitiesComponent = /** @class */ (function () {
    function MobileActivitiesComponent(stateService, mobileDealSheetDataCachingService, transition) {
        this.stateService = stateService;
        this.mobileDealSheetDataCachingService = mobileDealSheetDataCachingService;
        this.transition = transition;
        this.opportunityId = this.transition.params().opportunityId;
    }
    MobileActivitiesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.startWorkingState();
        this.opportunitySubscription = this.mobileDealSheetDataCachingService.getDealsheetData(this.opportunityId).subscribe(function (dealSheetData) {
            _this.opportunity = dealSheetData.opportunity;
            _this.permissions = dealSheetData.permissions;
            _this.status = dealSheetData.opportunity.status;
            _this.assignedUserFullName = dealSheetData.opportunity.assignedUserFullName;
            _this.assignedUserId = dealSheetData.opportunity.assignedUserId;
            _this.ownerUserInfo = dealSheetData.opportunity.ownerUserInfo;
            _this.hasReviewPro = dealSheetData.hasReviewPro;
            _this.hasGeoAlert = dealSheetData.hasGeoAlert;
            _this.fullName = dealSheetData.opportunity.customer.fullName;
            _this.activitiesSubscription = _this.mobileDealSheetDataCachingService.getActivities(_this.opportunityId, dealSheetData.opportunity.dealerId, dealSheetData.opportunity.customerId)
                .subscribe(function (activities) {
                _this.activities = activities;
                _this.stopWorkingState();
            });
            _this.usersSubscription = _this.mobileDealSheetDataCachingService.getUsers(dealSheetData.opportunity.dealerId).subscribe(function (users) { return _this.users = users; });
        });
    };
    MobileActivitiesComponent.prototype.handleLogActivityNavigation = function () {
        this.stateService.go('mobileDealSheet.customer-connect');
    };
    MobileActivitiesComponent.prototype.startWorkingState = function () {
        var _this = this;
        this.busy = new Promise(function (resolve) {
            _this.stopWorkingState = resolve;
        });
    };
    MobileActivitiesComponent.prototype.stopWorkingState = function () { };
    MobileActivitiesComponent.prototype.ngOnDestroy = function () {
        this.opportunitySubscription.unsubscribe();
        this.activitiesSubscription.unsubscribe();
        this.usersSubscription.unsubscribe();
    };
    return MobileActivitiesComponent;
}());
exports.MobileActivitiesComponent = MobileActivitiesComponent;
