export enum DisplayView {
    initialView = 1,
    customerSelectView = 2,
    customerPushedView = 3,
    dealPushedView = 4,
    errorView = 5,
    waitingView = 6
}

export enum RequestType {
    customer = 0,
    fandI = 1
}

export enum DmsSaleType {
    cash = 0,
    lease = 1,
    retail = 2
}
