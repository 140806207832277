"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MoneyFactorComponent = /** @class */ (function () {
    function MoneyFactorComponent() {
    }
    MoneyFactorComponent.prototype.ngOnInit = function () {
    };
    return MoneyFactorComponent;
}());
exports.MoneyFactorComponent = MoneyFactorComponent;
