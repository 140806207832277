"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var constants_1 = require("../../constants");
var dealer_service_1 = require("../../services/dealer.service");
var dealer_settings_service_1 = require("../../services/dealer-settings.service");
var admin_settings_vhr_disclaimer_component_1 = require("./admin-settings-vhr-disclaimer.component");
var AdminSettingsGeneralComponent = /** @class */ (function () {
    function AdminSettingsGeneralComponent(modalService, tService, dealerService, dealerSettingService) {
        this.modalService = modalService;
        this.tService = tService;
        this.dealerService = dealerService;
        this.dealerSettingService = dealerSettingService;
        this.dsa = {};
        this.onValidateField = new core_1.EventEmitter();
        this.isDealerPandoXEnabled = false;
        this.newTextNumbers = [];
        this.newTextNumbersCXM = [];
        this.twilioNumbersValid = true;
        this.twilioNumbersValidCXM = true;
        this.allTwilioNumbersUnique = true;
        this.selectedOwners = [];
        this.ownersDropdownSettings = {};
        this.overrideStateCode = null;
        this.overrideStateCodeCXM = null;
        this.searchReturnedLessThanTwoLocalNumbers = false;
        this.searchReturnedLessThanTwoLocalNumbersCXM = false;
        this.localNumberSearchExecuted = false;
        this.localNumberSearchExecutedCXM = false;
        this.textMessagingAreaCode = null;
        this.textMessagingAreaCodeCXM = null;
        this.Modules = constants_1.Modules;
        this.AccessRight = constants_1.AccessRights;
        this.placeHolderNumberText = 'tbd';
    }
    AdminSettingsGeneralComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ownersDropdownSettings = {
            singleSelection: false,
            text: this.tService.instant('dsAdmin_selectOwners'),
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
            badgeShowLimit: 2,
            labelKey: "fullName",
            primaryKey: "userID"
        };
        this.twilioSendTextSalesNumber = this.getTwilioNumber(this.AccessRight.SendTextSales);
        this.twilioSendTextServiceNumber = this.getTwilioNumber(this.AccessRight.SendTextService);
        this.twilioSendTextSalesNumberCXM = this.getTwilioNumber(this.AccessRight.SendTextSalesCXM);
        this.twilioSendTextServiceNumberCXM = this.getTwilioNumber(this.AccessRight.SendTextServiceCXM);
        //this.routeVoiceCallsToNumber = this.formatPhoneNumber(this.dsa.dealerSetting.textRouterVoiceNumber);
        //this.routeVoiceCallsToNumberCXM = this.formatPhoneNumber(this.dsa.dealerSetting.textRouterVoiceNumberCXM);
        this.dealerService.getIsDealerPandoXEnabled(this.dsa.dealer.dealerID).then(function (result) { return _this.isDealerPandoXEnabled = result; });
        this.selectedOwners = this.dsa.dealerOrphanOwner.map(function (obj) {
            var user = _this.dsa.users.find(function (u) { return u.userID == obj.userID; });
            if (user != null)
                return user;
        }).filter(Boolean);
    };
    AdminSettingsGeneralComponent.prototype.getTwilioNumber = function (accessRightId) {
        var result = null;
        if (this.dsa.dealerTwilioPhoneNumber)
            result = this.dsa.dealerTwilioPhoneNumber.find(function (p) { return p.accessRightId == accessRightId; });
        return (result != null) ? result.twilioPhoneNumber : this.placeHolderNumberText;
    };
    AdminSettingsGeneralComponent.prototype.unformatPhoneNumber = function (source) {
        if (source) {
            var endResult = "";
            var phoneText = source;
            for (var i = 0; i < phoneText.length; i++) {
                var code = phoneText.charCodeAt(i);
                if (code >= 48 && code <= 57)
                    endResult += phoneText.charAt(i);
            }
            if (endResult.length == 10)
                return "+1" + endResult;
            return source;
        }
        else
            return null;
    };
    AdminSettingsGeneralComponent.prototype.formatPhoneNumber = function (source) {
        try {
            if (source == this.placeHolderNumberText)
                return this.tService.instant(this.placeHolderNumberText).toUpperCase();
            var endResult = "";
            if (source.length > 9) {
                var phoneText = source.replace("+1", "");
                for (var i = 0; i < phoneText.length; i++) {
                    var code = phoneText.charCodeAt(i);
                    if (code >= 48 && code <= 57)
                        endResult += phoneText.charAt(i);
                }
            }
            if (endResult.length == 10) {
                return "(" + endResult.substring(0, 3) + ") " + endResult.substring(3, 6) + "-" + endResult.substring(6, 10);
            }
        }
        catch (err) { }
        return source;
    };
    AdminSettingsGeneralComponent.prototype.enableVehicleHistoryReports = function () {
        var _this = this;
        this.bsModalRef = this.modalService.show(admin_settings_vhr_disclaimer_component_1.AdminSettingsVHRDisclaimerComponent);
        this.bsModalRef.content.acceptDisclaimer.subscribe(function (result) {
            _this.dsa.isEnableVHR = true;
        });
    };
    AdminSettingsGeneralComponent.prototype.onOverrideStateChanged = function () {
        this.newTextNumbers = [];
        this.overrideStateSelect.nativeElement.classList.remove("invalid");
    };
    AdminSettingsGeneralComponent.prototype.onOverrideStateChangedCXM = function () {
        this.newTextNumbersCXM = [];
        this.overrideStateSelectCXM.nativeElement.classList.remove("invalid");
    };
    AdminSettingsGeneralComponent.prototype.allNumbersUnique = function () {
        var isUnique = true;
        if (this.dsa.isTextMessagingNewNumberSettingsEnabled && this.dsa.isTextMessagingNewNumberSettingsEnabledCXM) {
            if ((this.twilioSendTextSalesNumberCXM != this.placeHolderNumberText && this.twilioSendTextSalesNumber == this.twilioSendTextSalesNumberCXM)
                || (this.twilioSendTextServiceNumberCXM != this.placeHolderNumberText && this.twilioSendTextSalesNumber == this.twilioSendTextServiceNumberCXM))
                isUnique = false;
            else if ((this.twilioSendTextSalesNumberCXM != this.placeHolderNumberText && this.twilioSendTextServiceNumber == this.twilioSendTextSalesNumberCXM)
                || (this.twilioSendTextServiceNumberCXM != this.placeHolderNumberText && this.twilioSendTextServiceNumber == this.twilioSendTextServiceNumberCXM))
                isUnique = false;
        }
        return isUnique;
    };
    AdminSettingsGeneralComponent.prototype.onNewNumberChanged = function () {
        if (this.twilioSendTextSalesNumber == this.placeHolderNumberText && this.twilioSendTextServiceNumber == this.placeHolderNumberText) {
            this.dsa.twilioSendTextSalesNumber = null;
            this.dsa.twilioSendTextServiceNumber = null;
            this.twilioNumbersValid = true;
            this.allTwilioNumbersUnique = true;
        }
        else if (this.twilioSendTextSalesNumber == this.placeHolderNumberText || this.twilioSendTextServiceNumber == this.placeHolderNumberText)
            this.twilioNumbersValid = false;
        else if (this.twilioSendTextSalesNumber == this.twilioSendTextServiceNumber)
            this.twilioNumbersValid = false;
        else {
            this.dsa.twilioSendTextSalesNumber = this.twilioSendTextSalesNumber;
            this.dsa.twilioSendTextServiceNumber = this.twilioSendTextServiceNumber;
            this.twilioNumbersValid = true;
        }
        this.allTwilioNumbersUnique = true;
        if (this.twilioNumbersValid) {
            this.allTwilioNumbersUnique = this.allNumbersUnique();
        }
        if (!(this.twilioNumbersValid && this.allTwilioNumbersUnique)) {
            this.dsa.twilioSendTextSalesNumber = null;
            this.dsa.twilioSendTextServiceNumber = null;
        }
    };
    AdminSettingsGeneralComponent.prototype.onNewNumberChangedCXM = function () {
        if (this.twilioSendTextSalesNumberCXM == this.placeHolderNumberText && this.twilioSendTextServiceNumberCXM == this.placeHolderNumberText) {
            this.dsa.twilioSendTextSalesNumberCXM = null;
            this.dsa.twilioSendTextServiceNumberCXM = null;
            this.twilioNumbersValidCXM = true;
            this.allTwilioNumbersUnique = true;
        }
        else if (this.twilioSendTextSalesNumberCXM == this.placeHolderNumberText || this.twilioSendTextServiceNumberCXM == this.placeHolderNumberText)
            this.twilioNumbersValidCXM = false;
        else if (this.twilioSendTextSalesNumberCXM == this.twilioSendTextServiceNumberCXM)
            this.twilioNumbersValidCXM = false;
        else {
            this.dsa.twilioSendTextSalesNumberCXM = this.twilioSendTextSalesNumberCXM;
            this.dsa.twilioSendTextServiceNumberCXM = this.twilioSendTextServiceNumberCXM;
            this.twilioNumbersValidCXM = true;
        }
        this.allTwilioNumbersUnique = true;
        if (this.twilioNumbersValidCXM) {
            this.allTwilioNumbersUnique = this.allNumbersUnique();
        }
        if (!(this.twilioNumbersValidCXM && this.allTwilioNumbersUnique)) {
            this.dsa.twilioSendTextSalesNumberCXM = null;
            this.dsa.twilioSendTextServiceNumberCXM = null;
        }
    };
    AdminSettingsGeneralComponent.prototype.onViewNumbers = function (isStateSearch) {
        var _this = this;
        if (isStateSearch === void 0) { isStateSearch = false; }
        this.newTextNumbers = [];
        var stateCode = null;
        if (isStateSearch)
            stateCode = this.overrideStateCode;
        this.dealerSettingService.GetTextNumbers(this.dsa.dealerSetting.dealerID, stateCode, this.textMessagingAreaCode).then(function (result) {
            _this.newTextNumbers = result;
            _this.searchReturnedLessThanTwoLocalNumbers = (_this.newTextNumbers.length < 2);
            if (_this.overrideStateCode == null || _this.overrideStateCode.length === 0) {
                _this.localNumberSearchExecuted = true;
            }
            _this.dsa.twilioSendTextServiceNumber = null;
            _this.dsa.twilioSendTextSalesNumber = null;
            _this.twilioSendTextSalesNumber = _this.placeHolderNumberText;
            _this.twilioSendTextServiceNumber = _this.placeHolderNumberText;
        });
    };
    AdminSettingsGeneralComponent.prototype.onViewNumbersCXM = function (isStateSearch) {
        var _this = this;
        if (isStateSearch === void 0) { isStateSearch = false; }
        this.newTextNumbersCXM = [];
        var stateCode = null;
        if (isStateSearch)
            stateCode = this.overrideStateCodeCXM;
        this.dealerSettingService.GetTextNumbers(this.dsa.dealerSetting.dealerID, stateCode, this.textMessagingAreaCodeCXM).then(function (result) {
            _this.newTextNumbersCXM = result;
            _this.searchReturnedLessThanTwoLocalNumbersCXM = (_this.newTextNumbersCXM.length < 2);
            if (_this.overrideStateCodeCXM == null || _this.overrideStateCodeCXM.length === 0) {
                _this.localNumberSearchExecutedCXM = true;
            }
            _this.dsa.twilioSendTextServiceNumberCXM = null;
            _this.dsa.twilioSendTextSalesNumberCXM = null;
            _this.twilioSendTextSalesNumberCXM = _this.placeHolderNumberText;
            _this.twilioSendTextServiceNumberCXM = _this.placeHolderNumberText;
        });
    };
    AdminSettingsGeneralComponent.prototype.onSearchByState = function () {
        if (this.overrideStateCode == null || this.overrideStateCode.length === 0) {
            this.overrideStateSelect.nativeElement.classList.add("invalid");
        }
        else {
            this.onViewNumbers(true);
        }
    };
    AdminSettingsGeneralComponent.prototype.onSearchByStateCXM = function () {
        if (this.overrideStateCodeCXM == null || this.overrideStateCodeCXM.length === 0) {
            this.overrideStateSelectCXM.nativeElement.classList.add("invalid");
        }
        else {
            this.onViewNumbersCXM(true);
        }
    };
    AdminSettingsGeneralComponent.prototype.onSave = function () {
        // No longer supporting voice number
        //this.dsa.dealerSetting.textRouterVoiceNumber = this.unformatPhoneNumber(this.routeVoiceCallsToNumber);
        //this.dsa.dealerSetting.textRouterVoiceNumberCXM = this.unformatPhoneNumber(this.routeVoiceCallsToNumberCXM);
        this.dsa.dealerOrphanOwner = this.selectedOwners.map(function (obj) {
            // dealerID and typeID are filled by the api
            var rObj = {};
            rObj.userID = obj.userID;
            return rObj;
        });
    };
    return AdminSettingsGeneralComponent;
}());
exports.AdminSettingsGeneralComponent = AdminSettingsGeneralComponent;
