import { Component, ViewChild, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter, ElementRef, NgModule } from '@angular/core';
import { DealerSettingsService } from '../../services/dealer-settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingResponseDto } from '../../generated/models';
import { AdminSettingsModalSendTestLeadComponent } from './admin-settings-modal-sendtestlead.component';
import { StorageService } from '../../modules/storage/storage.service';

@Component({
    selector: 'admin-settings-crmleadsdelivery',
    templateUrl: './admin-settings-crmleadsdelivery.component-ng.html'
})

export class AdminSettingsCrmLeadsDeliveryComponent {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};
    public isEmployee: boolean = false;

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
        private dsService: DealerSettingsService,
        private storageService: StorageService
    ) {
        const userProfile = storageService.getItem('userProfile');
        if (userProfile) {
            this.isEmployee = userProfile.employeeUser;
        }
    }

    public ngOnInit() {

    }

    public sendTestLead() {
        this.bsModalRef = this.modalService.show(AdminSettingsModalSendTestLeadComponent);
        this.bsModalRef.content.sendTestLead.subscribe((result: any) => {
            this.dsService.SendTestAdfEmail({ dealerId: this.dsa.dealerSetting.dealerID, email: result.email, source: result.source });
            this.logger.success(this.tService.instant("testLeadSent"));
        });
    }

    public onDeliverAllOpportunitiesChanged(): void {
        this.dsa.dealerAdfSetting.deliverOpportunityCreditConvert = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityUpgrade = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityContractEnd = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityMileage = this.dsa.dealerAdfSetting.deliverAllOpportunities;
        this.dsa.dealerAdfSetting.deliverOpportunityWarranty = this.dsa.dealerAdfSetting.deliverAllOpportunities;
    }
}
