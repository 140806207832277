
/* injects from baggage-loader */
require('./view-customer-email-sent-modal.component.html');

import angular from 'angular';
(function () {
    angular.module('AaApp.Opp').component('viewCustomerEmailSentModal', {
        templateUrl: '/app/components/deal-sheet/modals/view-customer-email-sent-modal.component.html',
        controller: ViewCustomerEmailSentModalController,
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&'
        }
    });

    /* ngInject */
    function ViewCustomerEmailSentModalController() {
        var $ctrl = this;

    }
})();