import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.promise.finally";
DataEntryRatesOverAllowanceController.$inject = ["$translate", "$q", "dataEntryService", "storageService", "$uibModal", "$document", "cultures", "logger", "$logFactory"];

/* injects from baggage-loader */
require('./data-entry-rates-over-allowance.component.html');

import angular from 'angular';
import $ from 'jquery';
import BusyIndicator from '../../utilities/BusyIndicator';
angular.module('AaApp.Opp').component('dataEntryRatesOverAllowance', {
  templateUrl: '/app/components/data-entry/data-entry-rates-over-allowance.component.html',
  controller: DataEntryRatesOverAllowanceController,
  bindings: {
    kendo: '<',
    dealerId: '<',
    onNotifyUnsavedData: '&'
  }
});

function DataEntryRatesOverAllowanceController($translate, $q, dataEntryService, storageService, $uibModal, $document, cultures, logger, $logFactory) {
  "ngInject";

  var $ctrl = this;
  var log = $logFactory.get();
  var cultureName = storageService.getItem('cultureName') || 'en-US';
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;
  $ctrl.onDealerSelect = onDealerSelect;
  $ctrl.trimSelections;
  $ctrl.onSave = onSave;
  $ctrl.onCancel = onCancel;
  $ctrl.kendoGrid = getKendoGrid;
  $ctrl.busyIndicator = new BusyIndicator();
  $ctrl.min = 80;
  $ctrl.max = 200;

  function onChanges(changes) {
    if (changes && changes.dealerId) {
      if (changes.dealerId.currentValue) {
        $ctrl.selectedDealerId = changes.dealerId.currentValue;
        getTranslations();
        onDealerSelect();
      }
    }
  }

  function init() {}

  function getTranslations() {
    $q.all({
      'saveConfirmMessage': $translate('saveConfirmMessage'),
      'saveWarningMessage': $translate('saveWarningMessage'),
      'validationErrorMessage': $translate('validationErrorMessage'),
      'lease': $translate('lease'),
      'retail': $translate('retail'),
      'balloon': $translate('balloon'),
      'expireOn': $translate('expireOn'),
      'tier': $translate('tier'),
      'bankMake': $translate('bankMake')
    }).then(function (stuff) {
      $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
      $ctrl.saveWarningMessage = stuff.saveWarningMessage;
      $ctrl.validationErrorMessage = stuff.validationErrorMessage;
      $ctrl.lease = stuff.lease;
      $ctrl.retail = stuff.retail;
      $ctrl.balloon = stuff.balloon;
      $ctrl.expireOn = stuff.expireOn;
      $ctrl.tier = stuff.tier;
      $ctrl.bankMake = stuff.bankMake;
      initGridOptions();
    });
  }

  function busy(translationKey, promise) {
    $ctrl.busyMessage = null;
    $translate(translationKey).then(function (translation) {
      $ctrl.busyMessage = translation;
    }, function (err) {
      $ctrl.busyMessage = translationKey;
    });
    var done = $ctrl.busyIndicator.start();
    return promise.finally(done);
  }

  function onDealerSelect(previousValue) {
    return loadOverAllowance();
  }

  function onSave() {
    if (!isDataValid()) {
      var modal1 = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return "validationError";
          },
          message: function message() {
            return $ctrl.validationErrorMessage;
          },
          submitButtonVisible: function submitButtonVisible() {
            return false;
          },
          submitButtonDisabled: function submitButtonDisabled() {
            return false;
          },
          closeButtonVisible: function closeButtonVisible() {
            return true;
          },
          closeButtonDisabled: function closeButtonDisabled() {
            return true;
          },
          closeButtonText: function closeButtonText() {
            return "ok";
          }
        }
      });
      return;
    }

    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: {
        title: function title() {
          return "readyToProcess";
        },
        message: function message() {
          return $ctrl.saveConfirmMessage;
        },
        submitButtonVisible: function submitButtonVisible() {
          return true;
        },
        submitButtonDisabled: function submitButtonDisabled() {
          return false;
        },
        submitButtonText: function submitButtonText() {
          return "continue";
        }
      }
    });
    modal.result.then(function () {
      var rows = $ctrl.kendoGrid().dataSource.data();
      var dirtyRows = [];
      rows.forEach(function (row) {
        if (row.dirty) {
          dirtyRows.push({
            id: row.id,
            tier: row.tier,
            bankId: row.bankId,
            bankName: row.bankName,
            leaseAllowance: Math.round(row.lease),
            isLeaseAllowanceChanged: row.dirtyFields.lease == true,
            retailAllowance: Math.round(row.retail),
            isRetailAllowanceChanged: row.dirtyFields.retail == true,
            balloonAllowance: Math.round(row.balloon),
            isBalloonAllowanceChanged: row.dirtyFields.balloon == true,
            makeID: row.makeID,
            makeName: row.makeName
          });
          row.dirty = false;
        }
      });

      if (dirtyRows.length > 0) {
        return updateDealerOverAllowance(dirtyRows);
      } else {
        logger.success("changesSaved");
        return $q.resolve();
      }
    }).catch(function () {
      return false;
    });
  }

  function onCancel() {
    $ctrl.kendoGrid().cancelChanges();
    $ctrl.onNotifyUnsavedData({
      result: {
        tab: "overAllowance",
        value: false
      }
    });
  }

  function updateDealerOverAllowance(overAllowance) {
    return busy("Saving", dataEntryService.updateDealerOverAllowance(overAllowance).then(function (success) {
      logger.success("changesSaved");
      $ctrl.onNotifyUnsavedData({
        result: {
          tab: "overAllowance",
          value: false
        }
      });
      return loadOverAllowance();
    }).then(function () {}, function (error) {
      log.log(error);
    }));
  }

  function isDataValid() {
    if (!$ctrl.kendoGrid()) return true;
    var valid = true;
    var rows = $ctrl.kendoGrid().dataSource.data();
    rows.forEach(function (row) {
      if (row.lease && row.lease < $ctrl.min || row.lease && row.lease > $ctrl.max || row.retail && row.retail < $ctrl.min || row.retail && row.retail > $ctrl.max || row.balloon && row.balloon < $ctrl.min || row.balloon && row.balloon > $ctrl.max) valid = false;
    });
    return valid;
  }

  function isDataDirty() {
    if (!$ctrl.kendoGrid()) return false;
    var dirty = false;
    var rows = $ctrl.kendoGrid().dataSource.data();
    rows.forEach(function (row) {
      if (row.dirty) dirty = true;
    });
    return dirty;
  }

  function initGridOptions() {
    $ctrl.gridOptions = {
      dataSource: $ctrl.ds,
      navigatable: true,
      pageable: false,
      height: 330,
      columns: [{
        field: "bankName",
        template: BankMakeTemplate,
        title: $ctrl.bankMake,
        width: 100,
        sortable: {
          compare: bankMakeSorting
        },
        editable: false
      }, {
        field: "tier",
        title: $ctrl.tier,
        width: 10,
        sortable: true
      }, {
        field: "lease",
        template: LeaseTemplate,
        title: $ctrl.lease,
        editor: allowanceEditor,
        width: 50,
        sortable: true,
        headerAttributes: {
          "class": "align-right"
        }
      }, {
        field: "retail",
        template: RetailTemplate,
        title: $ctrl.retail,
        editor: allowanceEditor,
        width: 50,
        sortable: true,
        headerAttributes: {
          "class": "align-right"
        }
      }, {
        field: "balloon",
        template: BalloonTemplate,
        title: $ctrl.balloon,
        editor: allowanceEditor,
        width: 50,
        sortable: true,
        headerAttributes: {
          "class": "align-right"
        }
      }],
      sortable: {
        mode: "single",
        allowUnsort: false
      },
      resizable: false,
      reorderable: true,
      filterable: false,
      editable: "incell",
      cellClose: function cellClose(e) {
        if (isDataDirty()) {
          $ctrl.onNotifyUnsavedData({
            result: {
              tab: "overAllowance",
              value: true
            }
          });
        }
      }
    };
  }

  function allowanceEditor(container, options) {
    $('<input data-bind="value:' + options.field + '"/>').appendTo(container).kendoNumericTextBox({
      decimals: 0,
      min: $ctrl.min,
      max: $ctrl.max,
      restrictDecimals: true,
      format: "n0"
    });
  }

  function bankMakeSorting(a, b) {
    if (a.bankName > b.bankName) return 1;else return -1;
  }

  function LeaseTemplate(e) {
    if (!e.lease) {
      if (!e.initLeaseSet) {
        e.initLeaseSet = true;
        e.lease = e.leaseAllowance != null ? e.leaseAllowance : e.leaseAllowanceBankDefault;
      } else e.lease = e.leaseAllowanceBankDefault;
    }

    var isBold = e.lease != e.leaseAllowanceBankDefault;

    if (e.lease >= $ctrl.min && e.lease <= $ctrl.max) {
      if (isBold) return "<div class='edit-box'><b>" + $ctrl.kendo.toString(e.lease, 'n0') + "%</b></div>";else return "<div class='edit-box'>" + $ctrl.kendo.toString(e.lease, 'n0') + "%</div>";
    } else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.lease, 'n0') + "%</div>";
  }

  function RetailTemplate(e) {
    if (!e.retail) {
      if (!e.initRetailSet) {
        e.initRetailSet = true;
        e.retail = e.retailAllowance != null ? e.retailAllowance : e.retailAllowanceBankDefault;
      } else e.retail = e.retailAllowanceBankDefault;
    }

    var isBold = e.retail != e.retailAllowanceBankDefault;
    if (e.retail >= $ctrl.min && e.retail <= $ctrl.max) {
      if (isBold) return "<div class='edit-box'><b>" + $ctrl.kendo.toString(e.retail, 'n0') + "%</b></div>";else return "<div class='edit-box'>" + $ctrl.kendo.toString(e.retail, 'n0') + "%</div>";
    } else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.retail, 'n0') + "%</div>";
  }

  function BalloonTemplate(e) {
    if (!e.balloon) {
      if (!e.initBalloonSet) {
        e.initBalloonSet = true;
        e.balloon = e.balloonAllowance != null ? e.balloonAllowance : e.balloonAllowanceBankDefault;
      } else e.balloon = e.balloonAllowanceBankDefault;
    }

    var isBold = e.balloon != e.balloonAllowanceBankDefault;
    if (e.balloon >= $ctrl.min && e.balloon <= $ctrl.max) {
      if (isBold) return "<div class='edit-box'><b>" + $ctrl.kendo.toString(e.balloon, 'n0') + "%</b></div>";else return "<div class='edit-box'>" + $ctrl.kendo.toString(e.balloon, 'n0') + "%</div>";
    } else return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.balloon, 'n0') + "%</div>";
  }

  function BankMakeTemplate(e) {
    return e.bankName + " - " + e.makeName;
  }

  function processData(overAllowanceData) {
    var temp = [];

    if (overAllowanceData.length > 0) {
      overAllowanceData.forEach(function (item) {
        temp.push({
          "tier": "T1+",
          "id": item.id,
          "bankId": item.bankId,
          "bankName": item.bankName,
          "makeId": item.makeId,
          "makeName": item.makeName,
          "retailAllowance": item.t1pRetailAllowance,
          "leaseAllowance": item.t1pLeaseAllowance,
          "balloonAllowance": item.t1pBalloonAllowance,
          "retailAllowanceBankDefault": item.t1pRetailAllowanceBankDefault,
          "leaseAllowanceBankDefault": item.t1pLeaseAllowanceBankDefault,
          "balloonAllowanceBankDefault": item.t1pBalloonAllowanceBankDefault
        });
        temp.push({
          "tier": "T1",
          "id": item.id,
          "bankId": item.bankId,
          "bankName": item.bankName,
          "makeId": item.makeId,
          "makeName": item.makeName,
          "retailAllowance": item.t1RetailAllowance,
          "leaseAllowance": item.t1LeaseAllowance,
          "balloonAllowance": item.t1BalloonAllowance,
          "retailAllowanceBankDefault": item.t1RetailAllowanceBankDefault,
          "leaseAllowanceBankDefault": item.t1LeaseAllowanceBankDefault,
          "balloonAllowanceBankDefault": item.t1BalloonAllowanceBankDefault
        });
        temp.push({
          "tier": "T2",
          "id": item.id,
          "bankId": item.bankId,
          "bankName": item.bankName,
          "makeId": item.makeId,
          "makeName": item.makeName,
          "retailAllowance": item.t2RetailAllowance,
          "leaseAllowance": item.t2LeaseAllowance,
          "balloonAllowance": item.t2BalloonAllowance,
          "retailAllowanceBankDefault": item.t2RetailAllowanceBankDefault,
          "leaseAllowanceBankDefault": item.t2LeaseAllowanceBankDefault,
          "balloonAllowanceBankDefault": item.t2BalloonAllowanceBankDefault
        });
      });
    }

    return temp;
  }

  function loadOverAllowance() {
    return busy("Loading", dataEntryService.getDealerOverAllowances($ctrl.selectedDealerId).then(function (data) {
      var overAllowanceData = processData(data);
      $ctrl.ds = new $ctrl.kendo.data.DataSource({
        data: overAllowanceData,
        schema: {
          model: {
            fields: {
              id: {
                type: "string",
                editable: false
              },
              bankName: {
                type: "string",
                editable: false
              },
              tier: {
                type: "string",
                editable: false
              },
              lease: {
                type: "number",
                editable: true
              },
              retail: {
                type: "number",
                editable: true
              },
              balloon: {
                type: "number",
                editable: true
              },
              bankID: {
                type: "string",
                editable: false
              },
              makeID: {
                type: "string",
                editable: false
              },
              makeName: {
                type: "string",
                editable: false
              }
            }
          }
        },
        batch: true,
        pageSize: 50
      });

      switch (cultureName) {
        case cultures.enUS:
        case cultures.enGB:
        case cultures.enCA:
        case cultures.frCA:
        case cultures.esUS:
          $ctrl.kendo.culture(cultureName);
          break;

        default:
          $ctrl.kendo.culture(cultures.enUS);
          break;
      }

      $("#k-datepicker").kendoDatePicker.widget.fn.options.min = new Date(); //$ctrl.kendoGrid = $("#gridDataEntryOverAllowance").data("kendoGrid");
    }));
  }

  function getKendoGrid() {
    return $("#gridDataEntryOverAllowance").data("kendoGrid");
  }
}