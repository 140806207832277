<div class="field row" *ngIf="value != null && permissions?.viewTradeValue">
    <label class="field-label" for="trade-in-value" translate="tradeValue"></label>

    <span id="trade-in-value" class="field-data qa-trade-in-value" *ngIf="readonly">{{ value | localeCurrency }}</span>

    <span class="field-data" *ngIf="!readonly">

        <span translate="currencySymbol"></span>
        <input [id]="inputName" [name]="inputName" type="text" class="form-control input-sm editable qa-trade-value-input" [(ngModel)]="value" (change)="update(value)"
               (keypress)="tradeOnKeyPress($event)" />
        <a class="edit-icon qa-trade-value-refresh-link" href="#" title="{{ 'recalculatedealsheet' | translate }}"
           (click)="onValueChange(value)">
            <icon class="icon aa-icon-refresh"></icon>
        </a>
        <a class="edit-icon" href="#" title="{{ 'savemanualtradevalue' | translate }}"
           (click)="onStoreTradeValue(value)">
            <icon class="icon save-manual-trade-value"></icon>
        </a>
        <span class="adjustment qa-trade-value-mileage-adjustment" *ngIf="showAdjustment && -mileageAdjustment > 0" translate="mileageAdjustment"
              [translateParams]="{ adjustment: -mileageAdjustment | localeCurrency }"></span>
    </span>
</div>
