import { Subject } from "rxjs";
import { map, filter } from "rxjs/operators";
import { delay, TimeoutError } from "./promises";

export const READY = "ready";
export const WAITING = "waiting";

/**
 * Implements a publish/subscribe channel with an iframe.
 *
 * Protocol:
 *
 *     1. The iframe loads a page and the 'load' event is emitted. The iframe
 *        sends a message:
 *        { type: "MESSAGE_PORT_REQ" }
 *
 *     2. The parent page creates a MessageChannel and posts the MessagePort to
 *        the child iframe using window.postMessage:
 *
 *        iframe.postMessage({ type: "MESSAGE_PORT" }, "*", [msgChannel.port2])
 *
 *     3. The child iframe posts an ack message ON THE PORT:
 *
 *        { type: "MESSAGE_PORT_ACK" }
 *
 *     4. The parent and child now communicate exclusively through the
 *        MessageChannel.
 *
 *     5. If the child is unloading, it sends MESSAGE_PORT_FIN. This puts this
 *        object back into the waiting state.
 *
 *     Messages are sent through the message channel with the following schema:
 *
 *         interface Message {
 *           type: string;
 *           data: any;
 *         }
 *
 *     Example:
 *
 *         {
 *           type: "AUTOALERT_IFRAME_READY",
 *           data: {
 *             timestamp: "2018-01-01T12:00:00Z"
 *           },
 *         }
 */
export class ChildMessagePort {
    /**
     * @param iframeDomElement - The DOM element of the IFRAME we want to message
     * @param iframeOrigin     - 
     * @param $scope           - (optional) the scope, used to call scope.$apply() after notifying subscribers
     */
    constructor(iframeDomElement, iframeOrigin, $scope) {
        this._iframe = iframeDomElement;        // the iframe to communicate with
        this._origin = iframeOrigin;            // the expected origin of messages from the iframe
        this._$scope = $scope;                  // allows us to call scope.$apply()
        this._event$ = new Subject();
        this._status$ = new Subject();
        this._generateId = (() => {
            let i = 0;
            return () => i++;
        })();

        this._goToWaitingState();

        // Send message port if we receive a request
        window.addEventListener('message', event => this._messagePortRequestHandler(event))

        // Send the port immediately and again every time the page navigates
        this._iframe.addEventListener('load', event => this._iframeLoadHandler(event));
        this._sendMessagePortToChild();
    }

    // public methods

    /**
     * Gets an observable that fires when a message is received.
     * @returns Observable<Message>
     */
    get message$() {
        return this._event$.pipe(
            filter(event => typeof event.data == 'object'),
            map(event => {
                const data = event.data;
                const id = data.id;
                data.replyPort = {
                    postMessage: (msg) => {
                        const reply = { ...msg, responseForId: id };
                        this.postMessage(reply)
                    }
                }
                return data;
            })
        );
    }

    /**
     * Gets an observable that fires when connection events occur.
     */
    get status$() {
        return this._status$.asObservable();
    }

    /**
     * Returns "ready" if we have a message port ready to send, else "waiting".
     */
    get state() {
        return this._port ? READY : WAITING;
    }

    /**
     * Sends an object to the iframe.
     */
    postMessage(data) {
        if (this.state == READY) {
            this._port.postMessage(data);
        } else {
            this._messagesAwaitingPort.push(data);
        }
    }

    /**
     * Sends an object to the iframe and expects a response
     * @param {Message} data - The message to send, must be an object
     * @param options.timeout - Timeout in milliseconds
     * @returns Promise
     */
    postRequest(data, options = {}) {
        if (typeof data != 'object') {
            throw Error("data must be a Message object");
        }
        const id = this._generateId();
        const timeout = options.timeout || 10000;
        data.id = id;

        this.postMessage(data);

        return new Promise((resolve, reject) => {
            // Resolve when we receive a response
            const sub = this.message$.pipe(
                filter(m => m.responseForId === id)
            ).subscribe(msg => {
                resolve(msg);
                sub.unsubscribe();
                clearTimeout(timeoutId);
            });

            // Reject if we don't receive a response in time
            const timeoutId = delay(10000).then(() => {
                sub.unsubscribe();
                reject(new TimeoutError());
            });
        });
    }

    /**
     * Subscribes a callback to messages sent by the iframe.
     * @param {Function<Event, ()>} callback
     * @returns Subscription
     */
    subscribe(callback) {
        if (this._$scope) {
            const callbackWithApply = data => this._$scope.$apply(() => callback(data));
            return this._event$.subscribe(callbackWithApply);
        } else {
            return this._event$.subscribe(callback);
        }
    }

    close() {
        this._status$.next({ type: "CLOSED" });
        this._status$.complete();
        this._event$.complete();
        if (this.state == READY) {
            this._port.close();
        }
    }

    // private methods

    _iframeLoadHandler(event) {
        if (this.state == WAITING) {
            this._sendMessagePortToChild();
        }
    }

    _goToWaitingState() {
        this._status$.next({ type: "WAITING" });
        if (this._port) {
            this._port.close();
            this._port = null;
        }
        if (this._messagesAwaitingPort == null) {
            this._messagesAwaitingPort = [];
        }
    }

    _goToReadyState(port) {
        this._status$.next({ type: "READY" });
        this._port = port;
        this._port.addEventListener('message', event => this._messagePortMessageListener(event));

        // Send any awaiting messages
        if (this._messagesAwaitingPort) {
            for (let message of this._messagesAwaitingPort) {
                this.postMessage(message);
            }
        }
        this._messagesAwaitingPort = null;
    }

    _messagePortRequestHandler(event) {
        // If we're in the ready state, this message isn't for us
        if (event.source === this._iframe.contentWindow) {
            if (event.data && event.data.type == "MESSAGE_PORT_REQ") {
                this._sendMessagePortToChild();
            }
        }
    }

    _sendMessagePortToChild() {
        if (this._iframe.contentWindow == null) {
            // If there's no window, we can't post to it. But we'll receive the
            // load event and resend it then.
            return
        }

        // Create a MessageChannel, keep port1 for ourselves and send port2 to
        // the iframe
        const channel = new MessageChannel();
        channel.port1.start();

        // If the IFRAME responds with an ACK on the port, it understood the
        // MESSAGE_PORT message and will send all future messages down the port
        // instead of posting them to our window.
        const ackHandler = (event) => {
            if (event.data && event.data.type == "MESSAGE_PORT_ACK") {
                this._status$.next({ type: "RECEIVED_MESSAGE_PORT_ACK" });
                this._goToReadyState(channel.port1);
                channel.port1.removeEventListener('message', ackHandler);
            }
        }
        channel.port1.addEventListener('message', ackHandler);

        // Send the newly-minted MessagePort to the IFRAME.
        this._status$.next({ type: "SENDING_MESSAGE_PORT" });
        this._iframe.contentWindow.postMessage({ type: "MESSAGE_PORT" }, "*", [channel.port2]);
    }

    _messagePortMessageListener(event) {
        if (event && event.data && event.data.type == "MESSAGE_PORT_FIN") {
            this._status$.next({ type: "RECEIVED_MESSAGE_PORT_FIN" });
            this._goToWaitingState();
        } else {
            this._notifySubscribers(event);
        }
    }

    _notifySubscribers(event) {
        this._event$.next(event);
    }
}

function parseJsonSafe(maybeString) {
    if (typeof maybeString != 'string') {
        return maybeString;
    }

    try {
        return JSON.parse(maybeString);
    } catch (e) {
        return maybeString;
    }
}
