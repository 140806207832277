import angular from 'angular';

angular.module('AaApp.Opp')
        .constant('duplicateSubscriptionKey', 'duplicateSubscriptionMessage')
        .constant('reportSubscriptionSubjectKey', 'reportSubscriptionSubject')
        .constant('reportSubscriptionEnterpriseSubjectKey', 'reportSubscriptionEnterpriseSubject')
        .constant('allMakesPreselectedKey', 'allMakesPreselected')
        .constant('optInConfirmationMessageKey', 'optInConfirmationMessage')
        .constant('optInConfirmationTitleKey', 'optInConfirmationTitle')
        .constant('submitDiscrepancySuccessMessageKey', 'submitDiscrepancySuccessMessage')
        .constant('currentVehicleKey', 'currentVehicle')
        .constant('paymentsKey', 'payments')
        .constant('announcementKey', 'announcement')
        .constant('financeFieldPromptTitleKey', 'financeFieldPromptTitle')
        .constant('financeFieldPromptMessageKey', 'financeFieldPromptMessage')
        .constant('monthlyPaymentAmountKey', 'monthlyPaymentAmount')
        .constant('financedAmountKey', 'financedAmount')
        .constant('aprKey', 'apr')
        .constant('residualKey', 'financialBalloonResidual')
        .constant('creditPrescreenErrorKey', 'creditPrescreenError')
        .constant('creditPrescreenResultsKey', 'preScreenResults')
        .constant('noCreditPrescreenDataKey', 'noCreditPrescreenData')
        .constant('reopenKey', 'reopen')
        .constant('reopenDealSheetConfirmationKey', 'reopenDealSheetConfirmation')
        .constant('markAsSoldHeaderKey', 'markAsSoldHeader')
        .constant('markAsSoldConfirmationKey', 'markAsSoldConfirmation')
        .constant('activityPostedKey', 'activityPosted')
        .constant('successKey', 'success')
        .constant('generalNaKey', 'naMessage')
        .constant('logEmailSentTitleKey', 'logEmailSentTitle')
        .constant('postNotesTitleKey', 'postNotesTitle')
        .constant('logCallTitleKey', 'logCallTitle')
        .constant('scheduleTitleApptKey', 'scheduleTitleAppt')
        .constant('scheduleTitleCallKey', 'scheduleTitleCall')
        .constant('switchToNewDealSheetKey', 'switchToNew')
        .constant('switchToClassicDealSheetKey', 'switchToClassic')
        .constant('tagExpirationKey', 'tagExpiration')
        .constant('tagNoExpirationKey', 'tagNoExpiration')
        .constant('recentRepairOrdersKey', 'recentRepairOrders')
        .constant('recentRepairOrdersAbbreviatedKey', 'recentRepairOrdersAbbreviated')
        .constant('singularKey', 'Singular')
        .constant('serviceAppointmentShortLabelKey', 'serviceAppointmentShortLabel')
        .constant('serviceAppointmentLongLabelKey', 'serviceAppointmentLongLabel')
        .constant('rescheduleTitleCallKey', 'rescheduleTitleCall')
        .constant('rescheduleTitleApptKey', 'rescheduleTitleAppt')
        .constant('reassignTitleCallKey', 'reassignTitleCall')
        .constant('reassignTitleApptKey', 'reassignTitleAppt')
        .constant('cancelTitleCallKey', 'cancelTitleCall')
        .constant('cancelTitleApptKey', 'cancelTitleAppt')
        .constant('completeTitleCallKey', 'completeTitleCall')
        .constant('completeTitleApptKey', 'completeTitleAppt')
        .constant('scheduleAssignToErrorMsgKey', 'scheduleAssignToErrorMsg')
        .constant('upgradeProposalKey', 'upgradeProposal')
        .constant('widgetTitleKeys', {
            'lease': 'leaseWidgetTitle',
            'retail': 'retailWidgetTitle',
            'agenda': 'agendaWidgetTitle',
            'newOpportunityChanges': 'newOpportunityChangesWidgetTitle',
            'recentlyAssigned': 'recentlyAssignedWidgetTitle',
            'opportunitySearches': 'opportunitySearches'
        })
        .constant('widgetExpandToolTipKeys', {
            'lease': 'expandlease',
            'retail': 'expandretail',
            'agenda': 'expandagenda',
            'newOpportunityChanges': 'expandnewOpportunityChanges',
            'recentlyAssigned': 'expandrecentlyAssigned',
        })
        .constant('makeModelYearWarningTitleKey', 'makeModelYearWarningTitle')
        .constant('makeModelYearWarningKey', 'makeModelYearWarning')
        .constant('dontHaveAccessWarningKey', 'dontHaveAccessWarning')
        .constant('dontHaveAccessExpiredKey', 'dontHaveAccessExpired')
        .constant('invalidMergeFieldErrorMsgKey', 'invalidMergeFieldErrorMsg')
        .constant('blankTemplateWarningKey', 'blankTemplateWarning')
        .constant('sendingEmailKey', 'sendingEmail')
        .constant('generatingPDFKey', 'generatingPDF')
        .constant('emailDisabledKey', 'emailDisabledWarning')
        .constant('doNotEmailCheckedKey', 'doNotEmailCheckedWarning')

