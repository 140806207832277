"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrimPricePlanSource = {
    autoAlertPrice: 0,
    dealerPrice: 1,
    aZPlan: 2,
    xPlan: 3,
    employeePricing: 4
};
exports.default = exports.TrimPricePlanSource;
