
/* injects from baggage-loader */
require('./unauthorized.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('errorUnauthorized', {
    templateUrl: '/app/components/error/unauthorized.component.html',
    controller: ErrorUnauthorizedController,
    bindings: {
        reason: '<'
    }
});

/* @ngInject */
function ErrorUnauthorizedController(_, $q, $stateParams, $translate, $translatePartialLoader, storageService,
    localePaths, logger, translateErrorKeys, warningTitleKey) {
    var $ctrl = this;

    $translatePartialLoader.addPart(localePaths.error);

    let cultureName = storageService.getItem('cultureName') || $translate.use();
    $translate.use(cultureName).then(resolveErrorTranslations);

    function resolveErrorTranslations() {
        var translateObject = {
            title: translateErrorKeys.generalErrorTitleKey,
            reason: translateErrorKeys.unauthorizedAppErrorKey
        };

        if ($stateParams && $stateParams.errorState) {
            switch ($stateParams.errorState) {
                case 'disabled':
                    translateObject.reason = translateErrorKeys.unauthorizedDisabledUserKey;
                    break;
                case 'locked':
                    translateObject.reason = translateErrorKeys.unauthorizedLockedUserKey;
                    break;
                case 'invalidIPAddress':
                    translateObject.reason = translateErrorKeys.invalidIPAddress;
                    break;
                case 'noUserProfile':
                    translateObject.reason = translateErrorKeys.unauthorizedNoUserProfileKey;
                    break;
                case 'marketingSplash': // if EULA is declined or if we need to redirect user to marketing splash
                    return;
                default:
                    break;
            }
        }

        var translateArray = _.values(translateObject);
        $translate(translateArray).then(resolveTranslations(translateObject));
    }

    function resolveTranslations(translateObject) {
        return function showWarningMessage(translations) {
            logger.warning(
                translations[translateObject.reason],
                null,
                translations[translateObject.title]
                );
        }
    }
}
