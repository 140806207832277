import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.string.starts-with";
CarfaxAuthorizeController.$inject = ["vehicleService", "$window"];

/* injects from baggage-loader */
import angular from 'angular';
angular.module('AaApp.Opp').component('carfaxAuthorize', {
  template: '<div style="color:white;padding:15px;text-align: center;width:100%">{{$ctrl.message}}</div>',
  controller: CarfaxAuthorizeController,
  bindings: {
    dealerId: '<',
    entityId: '<',
    vin: '<'
  }
});
/* @ngInject */

function CarfaxAuthorizeController(vehicleService, $window) {
  var $ctrl = this;
  $ctrl.message = "";

  $ctrl.$onInit = function () {
    getCarFaxReport(this.dealerId, this.entityId, this.vin);
  };

  function getCarFaxReport(dealerId, entityId, vin) {
    vehicleService.getCarFaxReportUrl(dealerId, entityId, vin).then(function (carfaxUrl) {
      if (carfaxUrl.startsWith("ERROR:")) {
        $ctrl.message = carfaxUrl;
      } else {
        $window.location.replace(carfaxUrl);
      }
    }).catch(function () {});
  }
}