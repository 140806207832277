<div class="container mobile-deal-sheet-wrapper">
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="customerNameContainer">
            <customer-name [customer]="dealSheetData?.opportunity.customer"></customer-name>
        </div>
        <div class="scoreContainer">
            <div class="scoreLeft">
            </div>
            <div class="score">
                {{ dealSheetData?.opportunity.entityScore }}
            </div>
        </div>
        <ul class="nav nav-justified navbar-nav">
            <li [ngClass]="{'active': selectedNav === routes.vehicle || selectedNav === routes.replacementDetails}"
                (click)="selectNav(routes.vehicle)">
                <a href="#">{{'opportunity' | translate}}</a>
            </li>
            <li [ngClass]="{'active': selectedNav === routes.activity || selectedNav === routes.customerConnect}"
                (click)="selectNav(routes.activity)">
                <a href="#">{{'activities' | translate}}</a>
            </li>
            <li [ngClass]="{'active': selectedNav === routes.contact}" (click)="selectNav(routes.contact)">
                <a href="#">{{'Contact' | translate}}</a>
            </li>
        </ul>
    </nav>
    <div class="container nav-content">
        <div class="row">
            <div [ngBusy]="busy"></div>
            <ui-view></ui-view>
        </div>
    </div>
</div>