"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CacheFetchOptions;
(function (CacheFetchOptions) {
    CacheFetchOptions[CacheFetchOptions["forceFetch"] = 0] = "forceFetch";
})(CacheFetchOptions = exports.CacheFetchOptions || (exports.CacheFetchOptions = {}));
/**
 * A simple helper to maintain an in memory cache of an object that is provided via a promise
 *
 * @example
 * // Store this object somewhere permanently
 * private cachedUser = new ObjectCache<User>();
 *
 * @example
 * // Get the value, or fetch it from the source
 * this.cachedUser.getOrFetch(myDtoId, () => fetchUser(myDtoId)).then(user => DoSomething(user))
 *
 * @example
 * // If the fetchValue returns an Observable, the result is an observable
 * this.cachedUser.getOrFetch(myDtoId, () => fetchUser(myDtoId)).subscribe(user => DoSomething(user))
 *
 */
var ObjectCache = /** @class */ (function () {
    function ObjectCache(options) {
        this.cachedItems = new Map();
        this.cacheOptions = options;
    }
    ObjectCache.prototype.getOrFetch = function (key, callBack, cacheFetchOptions) {
        if (key == null || key == undefined) {
            throw new Error('Cache requires a non-null, non-undefined key. key = ' + key);
        }
        if (!this.cachedItems.has(key) || (cacheFetchOptions !== null && cacheFetchOptions !== undefined && cacheFetchOptions === CacheFetchOptions.forceFetch)) {
            return this.fetchAndCache(key, callBack);
        }
        var cachedItem = this.cachedItems.get(key);
        if (this.cacheOptions) {
            if (this.cacheOptions.cacheAgeLimitSeconds) {
                var secondsDifference = (new Date().getTime() - cachedItem.cacheTimestamp.getTime()) / 1000;
                if (secondsDifference >= this.cacheOptions.cacheAgeLimitSeconds) {
                    return this.fetchAndCache(key, callBack);
                }
            }
        }
        return Promise.resolve(this.cachedItems.get(key).value);
    };
    ObjectCache.prototype.invalidateCacheForAllKeys = function () {
        this.cachedItems.clear();
    };
    ObjectCache.prototype.invalidateCacheForKey = function (key) {
        if (this.cachedItems.has(key)) {
            this.cachedItems.delete(key);
        }
    };
    ObjectCache.prototype.fetchAndCache = function (key, callBack) {
        var _this = this;
        var cacheItem = function (key, item) {
            var timeStamp = new Date();
            _this.cachedItems.set(key, { value: item, cacheTimestamp: timeStamp });
        };
        return callBack()
            .then(function (result) {
            cacheItem(key, result);
            return result;
        });
    };
    return ObjectCache;
}());
exports.ObjectCache = ObjectCache;
