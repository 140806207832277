DealSheetModalController.$inject = ["$rootScope", "globalIframeService"];

/* injects from baggage-loader */
require('./deal-sheet-modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('dealSheetModal', {
  templateUrl: '/app/components/deal-sheet/modals/deal-sheet-modal.component.html',
  controller: DealSheetModalController,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function DealSheetModalController($rootScope, globalIframeService) {
  var $ctrl = this;
  $ctrl.$onInit = $onInit;

  function $onInit() {
    var subscription = globalIframeService.subscribe(messageHandler);

    $ctrl.$onDestroy = function () {
      return subscription.unsubscribe();
    };
  } // this is called from legacy popup deal sheet not the angular deal sheet.


  function messageHandler(_ref) {
    var data = _ref.data;

    if (data['DEAL_SHEET_CLOSE']) {
      $ctrl.close();
    }
  }
}