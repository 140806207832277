import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'contract-end',
  templateUrl: './contract-end.component-ng.html',
  styleUrls: ['./contract-end.component.scss']
 })
export class ContractEndComponent implements OnInit {

  @Input() value: number;
  @Input() alertExists: boolean;

 constructor() { }

   ngOnInit() {
   }

 }
