"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var PandoPassthroughClientService = /** @class */ (function (_super) {
    __extends(PandoPassthroughClientService, _super);
    function PandoPassthroughClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param request undefined
     * @return Success
     */
    PandoPassthroughClientService.prototype.GetStringPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/PandoPassthrough/GetString", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    PandoPassthroughClientService.prototype.GetStringPOST = function (request) {
        return this.GetStringPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    PandoPassthroughClientService.prototype.GetWorkflowTemplatesByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/PandoPassthrough/GetWorkflowTemplates/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    PandoPassthroughClientService.prototype.GetWorkflowTemplatesByDealeridGET = function (dealerId) {
        return this.GetWorkflowTemplatesByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    PandoPassthroughClientService.prototype.GetPandoCustomStoreGroupsByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/PandoPassthrough/GetPandoCustomStoreGroups/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    PandoPassthroughClientService.prototype.GetPandoCustomStoreGroupsByDealeridGET = function (dealerId) {
        return this.GetPandoCustomStoreGroupsByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    PandoPassthroughClientService.prototype.AutoAssistFullViewByDealeridByEntityidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/PandoPassthrough/AutoAssistFullView/" + params.dealerId + "/" + params.entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
       * @param params The `PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETParams` containing the following parameters:
       *
       * - `entityId`:
       *
       * - `dealerId`:
       *
       * @return Success List of status objects
    */
    PandoPassthroughClientService.prototype.GetConversicaConversationStatusesByDealeridByEntityidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/PandoPassthrough/GetConversicaConversationStatuses/" + params.dealerId + "/" + params.entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
    * @param params The `PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETParams` containing the following parameters:
    *
    * - `entityId`:
    *
    * - `dealerId`:
    *
    * @return Success
    */
    PandoPassthroughClientService.prototype.AutoAssistFullViewByDealeridByEntityidGET = function (params) {
        return this.AutoAssistFullViewByDealeridByEntityidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
    * @param params The `PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETParams` containing the following parameters:
    *
    * - `entityId`:
    *
    * - `dealerId`:
    *
    * @return Success as a list of status objects
    */
    PandoPassthroughClientService.prototype.GetConversicaConversationStatusesByDealeridByEntityidGET = function (params) {
        return this.GetConversicaConversationStatusesByDealeridByEntityidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    PandoPassthroughClientService.GetStringPOSTPath = '/api/PandoPassthrough/GetString';
    PandoPassthroughClientService.GetWorkflowTemplatesByDealeridGETPath = '/api/PandoPassthrough/GetWorkflowTemplates/{dealerId}';
    PandoPassthroughClientService.GetPandoCustomStoreGroupsByDealeridGETPath = '/api/PandoPassthrough/GetPandoCustomStoreGroups/{dealerId}';
    PandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGETPath = '/api/PandoPassthrough/AutoAssistFullView/{dealerId}/{entityId}';
    PandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGETPath = '/api/PandoPassthrough/GetConversicaConversationStatuses/{dealerId}/{entityId}';
    PandoPassthroughClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PandoPassthroughClientService_Factory() { return new PandoPassthroughClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: PandoPassthroughClientService, providedIn: "root" });
    return PandoPassthroughClientService;
}(base_service_1.BaseService));
exports.PandoPassthroughClientService = PandoPassthroughClientService;
