"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./vehicle-image.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("./vehicle-image.component"));
var styles_VehicleImageComponent = [i0.styles];
var RenderType_VehicleImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VehicleImageComponent, data: {} });
exports.RenderType_VehicleImageComponent = RenderType_VehicleImageComponent;
function View_VehicleImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.updateUrl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }); }
exports.View_VehicleImageComponent_0 = View_VehicleImageComponent_0;
function View_VehicleImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vehicle-image", [], null, null, null, View_VehicleImageComponent_0, RenderType_VehicleImageComponent)), i1.ɵdid(1, 49152, null, 0, i2.VehicleImageComponent, [], null, null)], null, null); }
exports.View_VehicleImageComponent_Host_0 = View_VehicleImageComponent_Host_0;
var VehicleImageComponentNgFactory = i1.ɵccf("vehicle-image", i2.VehicleImageComponent, View_VehicleImageComponent_Host_0, { url: "url" }, {}, []);
exports.VehicleImageComponentNgFactory = VehicleImageComponentNgFactory;
