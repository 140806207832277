
/* injects from baggage-loader */
require('./change-log.component.html');

import angular from 'angular';
import PricingPlanTypes from '../../constants/pricing-plan-types';
import ChangeLogType from '../../constants/change-log-type';
import _ from 'lodash';

(function () {
    angular.module('AaApp.Opp').component('changeLog', {
        templateUrl: '/app/components/deal-sheet/change-log.component.html',
        controller: ChangeLogController,
        bindings: {
            opportunityId: '<',
            defaultTab: '<',
            onSetAsDefault: '&',
            currentTab: '<'
        }
    });

    /* @ngInject */
    function ChangeLogController(environmentVariables, opportunityService, opportunity2Service, $filter, $translate, dealSheetTabs) {
        var $ctrl = this;

        var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;

        $ctrl.modules = [];
        $ctrl.masterChangeLogs = [];
        $ctrl.selectedChangeLogs = [];
        $ctrl.$onChanges = $onChanges;
        $ctrl.refreshChangeLogs = refreshChangeLogs;
        $ctrl.changeLogLoadingPromise = null;

        $ctrl.$onInit = function () {
            $ctrl.tabs = dealSheetTabs;

            _setModules().then(function (modules) {
                $ctrl.modules = modules;
            });
        }

        $ctrl.filterSelectedLogs = function () {
            var selected = $ctrl.modules
                .filter(function (o) { return o.selected && o.enabled })
                .map(function (s) { return s.moduleName });
            var filteredLogs = $ctrl.masterChangeLogs.filter(function (o) {
                return selected.indexOf(o.module) > -1
            });
            $ctrl.selectedChangeLogs = filteredLogs;
        }

        function $onChanges(changes) {
            if (changes.opportunityId) {
                this.masterChangeLogs = [];
                this.selectedChangeLogs = [];
            }

            if (!changes || !changes.currentTab || changes.currentTab.currentValue !== dealSheetTabs.changeLog) { return; }

            if (!$ctrl.masterChangeLogs || $ctrl.masterChangeLogs.length === 0) {
                _getChangeLogs().then(_setModuleAccess);
            }

        }

        function refreshChangeLogs() {
            $ctrl.changeLogLoadingPromise = null;
            $ctrl.changeLogLoadingPromise = _getChangeLogs().then(_setModuleAccess);
        }

        function _getChangeLogs() {
            $ctrl.changeLogLoadingPromise = opportunityService.getChangeLogs($ctrl.opportunityId).then(formatChangeLogData);

            function formatChangeLogData(changeLogs) {

                for (var i = 0; i < changeLogs.length; i++) {
                    if (changeLogs[i].data) {
                        changeLogs[i].action += ': ' + changeLogs[i].data;
                    }
                    if (changeLogs[i].appendDate) {
                        var appendedDate = $filter('localeDate')(changeLogs[i].appendDate);
                        changeLogs[i].action += ' ' + appendedDate;
                    }

                    if (changeLogs[i].type == ChangeLogType.pricing) {
                        var planTypes = _.invert(PricingPlanTypes);
                        var plan = planTypes[changeLogs[i].action];
                        changeLogs[i].action = $translate.instant('axzPlanPricingHistory', { pricingPlan: $translate.instant(plan) });
                    }
                }
                $ctrl.masterChangeLogs = changeLogs;
            }
            return $ctrl.changeLogLoadingPromise;
        }

        function _setModuleAccess() {
            if ($ctrl.masterChangeLogs && $ctrl.masterChangeLogs.length > 0) {
                for (var i = 0; i < $ctrl.modules.length; i++) {
                    var isEnabled = _.some($ctrl.masterChangeLogs, ['module', $ctrl.modules[i].moduleName]);
                    $ctrl.modules[i].enabled = isEnabled;
                }
                $ctrl.filterSelectedLogs();
            }
        }

        function _setModules() {
            return $translate(['opportunityLogs', 'activityLogs', 'contactLogs', 'accessLogs', 'exportLogs'])
                .then(function (translations) {
                    return [{
                        key: 'opportunityLogs',
                        moduleName: translations['opportunityLogs'],
                        module: 'Opportunity',
                        enabled: true,
                        selected: true
                    }, {
                        key: 'activityLogs',
                        moduleName: translations['activityLogs'],
                        module: 'Activity',
                        enabled: true,
                        selected: true
                    }, {
                        key: 'contactLogs',
                        moduleName: translations['contactLogs'],
                        module: 'Contacts',
                        enabled: true,
                        selected: true
                    }, {
                        key: 'accessLogs',
                        moduleName: translations['accessLogs'],
                        module: 'Access',
                        enabled: true,
                        selected: true
                    }, {
                        key: 'exportLogs',
                        moduleName: translations['exportLogs'],
                        module: 'Export',
                        enabled: true,
                        selected: true
                    }];
                });
        }

    }
})();
