"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var forms_1 = require("@angular/forms");
var date_format_service_1 = require("../../services/date-format.service");
var DateInputComponent = /** @class */ (function () {
    function DateInputComponent(dateFormatService, formBuilder, renderer) {
        this.dateFormatService = dateFormatService;
        this.formBuilder = formBuilder;
        this.renderer = renderer;
        this.required = 'required';
        this.onInput = new core_1.EventEmitter();
        this.changedValue = '';
    }
    DateInputComponent.prototype.ngOnInit = function () {
        this.nullDate = '';
        this.dateFormat = this.dateFormatService.getDateMomentFormatString();
        if (this.defaultDate) {
            if (this.defaultDate == null) {
                this.nullDate = 'null-date';
            }
            else { // this only adds a red outline around the input field when date input is blank
                this.nullDate = '';
            }
        }
        this.config = {
            dateInputFormat: this.dateFormat,
            containerClass: 'theme-dark-blue',
            selectFromOtherMonth: true,
            customTodayClass: 'custom-today-class',
            adaptivePosition: true
        };
        this.createForm();
    };
    DateInputComponent.prototype.createForm = function () {
        var _this = this;
        this.dateInputForm = this.formBuilder.group({
            date: ['', this.dateValidator()]
        });
        if (this.defaultDate) {
            this.dateInputForm.controls.date.setValue(new Date(this.defaultDate));
            this.changedValue = this.defaultDate;
        }
        this.dateInputForm.controls.date.valueChanges.subscribe(function (value) {
            _this.onValueChange(value);
        });
    };
    DateInputComponent.prototype.ngOnChanges = function (changes) {
        if (changes.defaultDate) {
            if (((changes.defaultDate.currentValue && changes.defaultDate.firstChange) ||
                (changes.defaultDate.currentValue && !changes.defaultDate.previousValue && moment(changes.defaultDate.currentValue).isValid()) ||
                (changes.defaultDate.currentValue && changes.defaultDate.previousValue &&
                    moment(changes.defaultDate.currentValue).isValid() && moment(changes.defaultDate.previousValue).isValid() &&
                    changes.defaultDate.currentValue.getTime() !== changes.defaultDate.previousValue.getTime())) &&
                this.dateInputForm) {
                var m = moment(this.defaultDate);
                if (m.isValid()) {
                    this.dateInputForm.controls.date.setValue(m.toDate());
                }
            }
            if (changes.defaultDate && !changes.defaultDate.currentValue && changes.defaultDate.previousValue && this.dateInputForm) {
                this.dateInputForm.controls.date.setValue(null);
            }
            if (changes.defaultDate.currentValue == null) {
                this.nullDate = 'null-date';
            }
            else {
                this.nullDate = '';
            }
        }
    };
    DateInputComponent.prototype.dateValidator = function () {
        var _this = this;
        return function (control) {
            var _a;
            var invalid = _this.validateDate(control.value);
            return invalid ? (_a = {}, _a[invalid] = { value: control.value }, _a) : null;
        };
    };
    DateInputComponent.prototype.validateDate = function (date) {
        if (date && this.options && this.options.maxDate) {
            var maxDate = moment(this.options.maxDate);
            if (moment(date).isAfter(maxDate, 'day')) {
                return 'maxDate';
            }
        }
        if (date && this.options && this.options.minDate) {
            var minDate = moment(this.options.minDate);
            if (moment(date).isBefore(minDate, 'day')) {
                return 'minDate';
            }
        }
        return null;
    };
    DateInputComponent.prototype.onValueChange = function (date) {
        if (date === null || !isNaN(date.getTime())) {
            this.onInput.emit({ date: date, model: this.dateInputForm.controls.date });
            this.changedValue = date;
        }
    };
    DateInputComponent.prototype.onBlur = function () {
        var _this = this;
        if (isNaN((new Date(this.changedValue)).getTime())) { // Invalid date
            // By default the input will show "Invalid Date", so manually set it to show the actual value
            setTimeout(function () { return _this.renderer.setProperty(_this.dateInput.nativeElement, 'value', _this.changedValue); }, 2);
            this.onInput.emit({ date: this.changedValue, model: this.dateInputForm.controls.date });
        }
    };
    DateInputComponent.prototype.onKeyup = function ($event) {
        if ($event.currentTarget.value.toLowerCase() !== 'invalid date') {
            this.changedValue = $event.currentTarget.value;
        }
    };
    return DateInputComponent;
}());
exports.DateInputComponent = DateInputComponent;
