"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular2_toaster_1 = require("angular2-toaster");
var NgxToaster = /** @class */ (function () {
    function NgxToaster(toasterService) {
        this.configToaster = new angular2_toaster_1.ToasterConfig({
            positionClass: 'toast-top-right',
            animation: 'fade',
            showCloseButton: true,
            timeout: 5000 // 5 seconds is the default for all toaster duration.
        });
        this.toasterService = toasterService;
    }
    // test button event handler
    NgxToaster.prototype.popToast = function () {
        var toastSuccess = { type: 'success', body: 'Success Toast Body', };
        var toastInfo = { type: 'info', body: 'Info Toast Body', };
        var toastError = { type: 'error', body: 'Error Toast Body', };
        var toastWarning = { type: 'warning', body: 'Warning Toast Body', };
        this.toasterService.pop(toastSuccess);
        this.toasterService.pop(toastInfo);
        this.toasterService.pop(toastError);
        this.toasterService.pop(toastWarning);
    };
    return NgxToaster;
}());
exports.NgxToaster = NgxToaster;
