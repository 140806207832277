import angular from 'angular';

angular.module('AaApp.Opp').constant('analytics', {
    actions: {
        navigate: 'Navigation',
        changeReplacementVehicle: 'Change Replacement'
    },
    categories: {
        dealSheet: 'Deal Sheet'
    },
    labels: {
        trimChange: 'Change Trim',
        inventoryRecommendation: 'Suggested Vehicle',
        inventorySearch: 'In-Stock Search',
        inventoryBestMatch: 'In-Stock Best Match',
        alertsAndScripts: 'Alerts & Scripts',
        currentVehicleDetails: 'Existing Vehicle Details',
        replacementVehicleDetails: 'Replacement Vehicle Details',
        activityAndNotes: 'Activity & Notes',
        serviceHistory: 'Service History',
        otherVehiclesOwned: 'Other Vehicles Owned',
        featureComparison: 'Feature Comparison'
    }
});
