import angular from 'angular';

angular.module('AaApp.Opp').factory('googleService', googleService);

/* @ngInject */
function googleService($q, coreApi, $filter, $http, googleApi) {
    var service = {
        getCoordinates : getCoordinates,
        getGoogle : getGoogle,
        isLoaded: false
    };


    return service;
    
    function getCoordinates(address){
        var deferred = $q.defer();

        //Google needs to use the xhr request because it sends the response body to GET request in the OPTIONS response (from CORS).  it never actually sends a "success" code to the OPTIONS request, so the GET request is never actually sent.
        function GoogleXhrRequest() {  
            var xhr = new XMLHttpRequest();
            xhr.onload = function(data){
                var response = JSON.parse(xhr.responseText);
                deferred.resolve(response);
            };
            xhr.onerror = function(error){
                deferred.reject(error);
            };
            xhr.open('GET', 'https://maps.googleapis.com/maps/api/geocode/json?key='+googleApi.key+'&address=' + encodeURIComponent(address));
            xhr.send();
        }
               

        $q.all([
            GoogleXhrRequest()
        ]);

        return deferred.promise;

    }

    function getGoogle(){
        return loadScript("https://maps.googleapis.com/maps/api/js?key="+googleApi.key);
    }

    
    function loadScript(url)
    {
        if (!service.isLoaded){
            // Adding the script tag to the head as suggested before
            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;

            service.promise = new Promise(function(resolve, reject){
                // Then bind the event to the callback function.
                // There are several events for cross browser compatibility.
                script.onreadystatechange = ()=> resolve(window.google);
                script.onload = ()=> resolve(window.google);

                // Fire the loading
                head.appendChild(script);
            });
        }
        service.isLoaded = true;
        return service.promise;
    }

}

//let scriptLoaded = loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyAQoXpF4ANaLzdEDRQ2e5nZHMa1Otgk_Q4");
//export function getGoogle(){
//    return scriptLoaded;
//}

//function loadScript(url)
//{
//    // Adding the script tag to the head as suggested before
//    var head = document.getElementsByTagName('head')[0];
//    var script = document.createElement('script');
//    script.type = 'text/javascript';
//    script.src = url;

//    return new Promise(function(resolve, reject){
//        // Then bind the event to the callback function.
//        // There are several events for cross browser compatibility.
//        script.onreadystatechange = ()=> resolve(window.google);
//        script.onload = ()=> resolve(window.google);

//        // Fire the loading
//        head.appendChild(script);
//    });
//}
