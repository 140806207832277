
/* injects from baggage-loader */

import angular from 'angular';

import aaBadgeImagePath from '../../images/alertminerbadge.png';
import aaNoTrimImagePath from '../../images/vehicle-image-not-available.png';
import aaSLMImagePath from '../../images/logo-service-lead-management.svg';
import aaRepProImagePath from '../../images/logo-reputation-pro.svg';
import aaPandoImagePath from '../../images/logo-pando.svg';
import aaOneToOneImagePath from '../../images/logo-one-to-one.svg';

angular.module('AaApp.Opp').component('reportRender', {
    controller: ReportRenderController,
    bindings: {
        reportData: '<',
        headerData: '<',
        reportLink: '<',
        passOptionalParameter: '<',
        openLmsTranscriptModal: '<'
},
    template: '<div ng-bind-html="$ctrl.trustedReportData" compile-template></div>'
});

/* @ngInject */
function ReportRenderController($sce) {
    var $ctrl = this;
    $ctrl.badgeImagePath = aaBadgeImagePath;
    $ctrl.noTrimImagePath = aaNoTrimImagePath;
    $ctrl.slmImagePath = aaSLMImagePath;
    $ctrl.repProImagePath = aaRepProImagePath;
    $ctrl.pandoImagePath = aaPandoImagePath;
    $ctrl.oneToOneImagePath = aaOneToOneImagePath;
    $ctrl.trustedReportData = null;

    $ctrl.$onChanges = function () {
        if ($ctrl.reportData) {
            $ctrl.trustedReportData = $sce.trustAsHtml($ctrl.reportData);
        }
    }

}
