
/* injects from baggage-loader */
require('./feature-comparison-fuel.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('featureComparisonFuel', {
    templateUrl: '/app/components/deal-sheet/contract/feature-comparison-fuel.component.html',
    controller: FeatureComparisonFuelController,
    bindings: {
        fuelEconomy: '<',
        showMultiFuel: '<'
    }
});

function FeatureComparisonFuelController() {
    var $ctrl = this;

}
