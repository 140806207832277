"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var search_preset_service_1 = require("../../../services/search-preset.service");
var SearchPresetCountsComponent = /** @class */ (function () {
    function SearchPresetCountsComponent(searchPresetService) {
        this.searchPresetService = searchPresetService;
        this.widgetStateChange = new core_1.EventEmitter();
        this.onRunDateChanged = new core_1.EventEmitter();
        this.defaultRowLimit = 5;
        this.maxRowLimit = 50;
    }
    SearchPresetCountsComponent.prototype.ngOnInit = function () {
    };
    SearchPresetCountsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.widgetData && changes.widgetData.currentValue) {
            if (this.widgetData.presetCounts && this.widgetData.presetCounts.length > 0) {
                this.widgetData.presetCounts.sort(this.byOrder);
                this.onRunDateChanged.emit(this.widgetData.presetCounts[0].runDate);
            }
            if (this.widgetData.maxRowsDisplayed == null) {
                this.widgetData.maxRowsDisplayed = this.defaultRowLimit;
            }
        }
        if (changes.selectedDealerId && changes.selectedDealerId.currentValue) {
            if (this.widgetData == null) {
                this.widgetData = {};
            }
            this.widgetData.maxRowsDisplayed = this.defaultRowLimit;
            ;
        }
    };
    SearchPresetCountsComponent.prototype.increaseLimit = function () {
        var _this = this;
        var newMaxRowsDisplayed = this.maxRowLimit;
        if (this.widgetData == null) {
            this.widgetData = {};
        }
        this.widgetState = { maxRowsDisplayed: newMaxRowsDisplayed };
        this.widgetStateChange.emit(this.widgetState);
        var _a = this.widgetData, searchPresets = _a.searchPresets, unusedSearchPresets = _a.unusedSearchPresets, presetCounts = _a.presetCounts;
        var maxRemainingRows = Math.max(this.defaultRowLimit, newMaxRowsDisplayed - searchPresets.length);
        var searchPresetsToQuery = unusedSearchPresets.slice(0, maxRemainingRows);
        this.setLimitPromise =
            this.searchPresetService.getSearchPresetCounts(searchPresetsToQuery, false)
                .then(function (widgetData) {
                _this.widgetData = {
                    searchPresets: searchPresets.concat(searchPresetsToQuery),
                    unusedSearchPresets: lodash_1.difference(unusedSearchPresets, searchPresetsToQuery),
                    presetCounts: presetCounts.concat(widgetData.presetCounts),
                    maxRowsDisplayed: newMaxRowsDisplayed,
                };
            });
    };
    SearchPresetCountsComponent.prototype.byOrder = function (searchPreset1, searchPreset2) {
        return searchPreset1.order - searchPreset2.order;
    };
    return SearchPresetCountsComponent;
}());
exports.SearchPresetCountsComponent = SearchPresetCountsComponent;
