"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var communication_api_service_1 = require("../services/communication-api.service");
var toaster_logger_service_1 = require("../core/toaster-logger.service");
var PandoCommunicationService = /** @class */ (function () {
    function PandoCommunicationService(communicationApi, toaster) {
        this.communicationApi = communicationApi;
        this.toaster = toaster;
    }
    PandoCommunicationService.prototype.getPandoInboxConfig = function () {
        return this.communicationApi.getPandoInboxConfig();
    };
    PandoCommunicationService.prototype.getMotofuzeCurrentUser = function () {
        return this.communicationApi.getCurrentUser();
    };
    PandoCommunicationService.prototype.getGlobalFrameConfig = function () {
        return this.communicationApi.getGlobalFrameConfig();
    };
    PandoCommunicationService.prototype.getUnreadMessageCount = function (isAutoAlertUser) {
        return this.communicationApi.get("users/appbundle", null)
            .then(function (response) {
            if (response && response.status == 200 &&
                response.body &&
                response.body.Data &&
                response.body.Data.hasOwnProperty('TotalBadgeCount')) {
                var unreadmsgCount = response.body.Data.TotalBadgeCount;
                return { msgcount: unreadmsgCount };
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    PandoCommunicationService.prototype.getMessageVendorInfo = function (isAutoAlertUser) {
        return this.communicationApi.get("message/vendor", null)
            .then(function (response) {
            if (response && response.status == 200) {
                return response.body.Data;
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    PandoCommunicationService.prototype.getChatStaff = function (isAutoAlertUser) {
        return this.communicationApi.get("chat/staff", null)
            .then(function (response) {
            if (response && response.status == 200) {
                return response.body.Data;
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    PandoCommunicationService.prototype.getAvailabilityReasons = function (isAutoAlertUser) {
        return this.communicationApi.get("users/availabilityreasons", null)
            .then(function (response) {
            if (response && response.status == 200) {
                return response.body.Data;
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    PandoCommunicationService.prototype.setAvailable = function (isAutoAlertUser) {
        return this.communicationApi.put("users/clockin", null)
            .then(function (response) {
            if (response && response.status == 200) {
                return response.body.Data;
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    PandoCommunicationService.prototype.setUnavailable = function (isAutoAlertUser, message, reasonId) {
        return this.communicationApi.put("users/clockout", {
            data: {
                Notes: message,
                AvailabilityReasonId: reasonId,
            }
        })
            .then(function (response) {
            if (response && response.status == 200) {
                return response.body.Data;
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    PandoCommunicationService.prototype.setBusy = function (isAutoAlertUser, minutes, message, reasonId) {
        return this.communicationApi.put("users/busy/" + minutes, {
            data: {
                Notes: message,
                AvailabilityReasonId: reasonId,
            }
        })
            .then(function (response) {
            if (response && response.status == 200) {
                return response.body.Data;
            }
            return null;
        }, function (error) {
            return null;
        });
    };
    return PandoCommunicationService;
}());
exports.PandoCommunicationService = PandoCommunicationService;
