
/* injects from baggage-loader */
require('./alert-relevant-info.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('alertRelevantInfo', {
    templateUrl: '/app/components/deal-sheet/alert-relevant-info.component.html',
    controller: AlertRelevantInfoController,
    bindings: {
        alert: '<'
    }
});

/* @ngInject */
function AlertRelevantInfoController(alertTypes) {
    var $ctrl = this;

    $ctrl.alertTypes = alertTypes;
    $ctrl.absoluteValue = Math.abs;
}
