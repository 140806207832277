"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./mobile-replacement-contract.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../contract/replacement-contracts/replacement-contracts.component"));
var i3 = __importStar(require("./mobile-replacement-contract.component"));
var i4 = __importStar(require("../../../../services/deal-sheet-data.service"));
var i5 = __importStar(require("../../../../services/mobile-deal-sheet-data-caching.service"));
var i6 = __importStar(require("@uirouter/core"));
var styles_MobileReplacementContractComponent = [i0.styles];
var RenderType_MobileReplacementContractComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileReplacementContractComponent, data: {} });
exports.RenderType_MobileReplacementContractComponent = RenderType_MobileReplacementContractComponent;
function View_MobileReplacementContractComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "replacement-contracts", [], null, null, null, null, null)), i1.ɵdid(1, 999424, null, 0, i2.ReplacementContractsComponent, [i1.ElementRef, i1.Injector], { countryId: [0, "countryId"], opportunity: [1, "opportunity"], availableContracts: [2, "availableContracts"], permissions: [3, "permissions"], showPullForward: [4, "showPullForward"], showMobileView: [5, "showMobileView"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countryId; var currVal_1 = _co.opportunity; var currVal_2 = _co.availableContracts; var currVal_3 = _co.permissions; var currVal_4 = _co.showPullForward; var currVal_5 = _co.showMobileView; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
exports.View_MobileReplacementContractComponent_0 = View_MobileReplacementContractComponent_0;
function View_MobileReplacementContractComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-replacement-contract", [], null, null, null, View_MobileReplacementContractComponent_0, RenderType_MobileReplacementContractComponent)), i1.ɵdid(1, 245760, null, 0, i3.MobileReplacementContractComponent, [i4.DealSheetDataService, i5.MobileDealSheetDataCachingService, i6.Transition], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileReplacementContractComponent_Host_0 = View_MobileReplacementContractComponent_Host_0;
var MobileReplacementContractComponentNgFactory = i1.ɵccf("mobile-replacement-contract", i3.MobileReplacementContractComponent, View_MobileReplacementContractComponent_Host_0, {}, {}, []);
exports.MobileReplacementContractComponentNgFactory = MobileReplacementContractComponentNgFactory;
