import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivityActionEnum } from '../constants';

@Injectable({
    providedIn: 'root'
})
export class LogActionShortcutService {

    constructor() { }

    private logShortcutSubject = new Subject<number | null>();
    public $logShortcutAction = this.logShortcutSubject.asObservable();


    public logCall() {
        this.logShortcutSubject.next(ActivityActionEnum.Log_Call);
    }

    public logNote() {
        this.logShortcutSubject.next(ActivityActionEnum.Post_Note);
    }

    public scheduleAppt() {
        this.logShortcutSubject.next(ActivityActionEnum.Schedule_Appt);
    }

    public reset() {
        this.logShortcutSubject.next(null);
    }
}
