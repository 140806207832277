import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '../../../services/customer.service';
import { CustomerCultures } from '../../../constants';

@Component({
    selector: 'customer-culture-select',
    templateUrl: './customer-culture-select.component-ng.html',
    styleUrls: ['./customer-culture-select.component.scss']
})
export class CustomerCultureSelectComponent implements OnInit, OnChanges {
    @Input() preferredCultureName: string;
    @Input() selectLabelKey: string;
    @Input() includeEmptyEntry: boolean = true;
    @Input() selectedDealerId: number;
    @Input() isDisabled: boolean = false;
    @Output() preferredCultureNameChange = new EventEmitter<string>();
    public selectedCultureName: string;
    public selectCultureLabel: string;
    public selectCultureEmptyValueText: string;
    public customerCultures: Array<any>;

    constructor(public tService: TranslateService) {
    }

    ngOnInit() {
        this.customerCultures = CustomerCultures;
        this.selectCultureEmptyValueText = this.tService.instant('selectLanguage');
        this.selectedCultureName = this.preferredCultureName ? this.preferredCultureName : "";
        if (this.selectLabelKey) { // only define label if key passed in. This determines if label is displayed.
            this.selectCultureLabel = this.tService.instant(this.selectLabelKey);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedDealerId && !changes.selectedDealerId.isFirstChange()) {
            this.selectedCultureName = this.preferredCultureName ? this.preferredCultureName : "";
        }
        if (changes.preferredCultureName && !changes.preferredCultureName.isFirstChange()) {
            this.selectedCultureName = this.preferredCultureName ? this.preferredCultureName : "";
            this.preferredCultureNameChange.emit(this.selectedCultureName == "" ? null : this.selectedCultureName);
        }
    }

    cultureChange(event: any) {
        this.preferredCultureNameChange.emit(event.target.value == "" ? null : event.target.value);
    }
}
