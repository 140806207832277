
/* injects from baggage-loader */
require('./opportunity-block-horizontal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('opportunityBlockHorizontal', {
    templateUrl: '/app/components/opportunities/opportunity-block-horizontal.component.html',
    controller: opportunityBlockHorizontalController,
    bindings: {
        markSelectedAlerts: '<',
        alertName: '@',
        cssClass: '@',
        onClick: '&',
        translateKey: '@'
    }
});


/* @ngInject */
function opportunityBlockHorizontalController() {
    var $ctrl = this;    

    $ctrl.$onInit = $onInit;
    $ctrl.$onChanges = $onChanges;
    $ctrl.onAlertSelect = onAlertSelect;
    $ctrl.selected = false;

    function $onChanges(changes){
        if (changes.markSelectedAlerts && changes.markSelectedAlerts.currentValue){
            
            if ($ctrl.markSelectedAlerts.length > 0) 
            {
                if ($ctrl.markSelectedAlerts.indexOf($ctrl.alertName) > -1)
                    $ctrl.selected = true;
                else
                    $ctrl.selected = false;
            }
            else
                $ctrl.selected = false;

        }
    }


    function onAlertSelect(alert) {
        $ctrl.onClick({result: alert});
    }

    function $onInit() {
        $ctrl.selected = false;
    }
}