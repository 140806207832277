<ul class="tags">
    <li class="tag" *ngFor="let tag of values">
        <span title="{{(tag.expirationDate ? 'tagExpiration' : 'tagNoExpiration') | translate: tag}}">{{tag.text}}</span>
    </li>
    <li class="recallItem" *ngIf="hasRecallNotice">
        <div [popover]="popTemplate"
             placement="auto" containerClass="popover"
             outsideClick="true">
             <icon class="recall-tag-svg" style="width:125px; height:18px"></icon>
        </div>

        <ng-template #popTemplate>
            <div>
                <div class="vinRecall">
                    
                    <div class="row">
                        <div class="col-md-8"><h3 style="margin-top:0;">{{'recallInfo' | translate}}</h3></div>
                        <div class="col-md-4 text-right">
                            <button *ngIf="recallNotificationList.length > 0 && isFordAlert && (makeName == 'Ford' || makeName == 'Lincoln')" class="btn btn-primary" (click)="openOasisLink()"
                                    [popover]="popOasisTemplate" placement="left" triggers="mouseenter:mouseleave"
                                    translate="fordOasis"></button>
                            <ng-template #popOasisTemplate>
                                <p [innerHTML]="fordOasisTooltip | translate" class="oasisTooltip" translate="fordOasisTooltip"></p>
                            </ng-template>
                        </div>
                    </div>

                    <div class="title" style="padding-top: 0;" *ngIf="recallNotificationList.length > 1">{{'multipleRecalls' | translate}}</div>

                    <div *ngIf="recallNotificationList.length == 1" class="recall-single-wrapper">
                        <div class="header-container">
                            <div class="header flexitem" style="padding-right: 10px;" *ngIf="recallNotificationList[0].recallNumber_mfr != null">
                                <span class="titleMainLabel">{{'recallNumberMfr' | translate}}</span>&nbsp;<span class="titleMainBody">{{ recallNotificationList[0].recallNumber_mfr }}</span>
                            </div>
                            <div class="header flexitem" *ngIf="recallNotificationList[0].recallNumber_Nhtsa != null">
                                <span class="titleMainLabel">{{'recallNumberNhtsa' | translate}}</span>&nbsp;<span class="titleMainBody">{{ recallNotificationList[0].recallNumber_Nhtsa }}</span>
                            </div>
                        </div>
                        <div class="header" *ngIf="recallNotificationList[0].vinRecallDate != null"><span class="titleMainLabel">{{'recallDate' | translate}}</span>&nbsp;<span class="titleMainBody">{{recallNotificationList[0].vinRecallDate | localeDate}}</span></div>
                        <div class="header" *ngIf="recallNotificationList[0].isRemedyAvailable != null">
                            <span class="titleMainLabel">{{'isRecallRemedyAvailable' | translate}}</span>&nbsp;<span class="titleMainBody">{{ recallNotificationList[0].isRemedyAvailable ? ('yes' | translate) : ('no' | translate) }}</span>
                        </div>
                        <div><span class="title">{{'recallDescription' | translate}}</span>&nbsp;<span class="body">{{recallNotificationList[0].description}}</span></div>
                        <div><span class="title">{{'recallSafetyRisk' | translate}}</span>&nbsp;<span class="body">{{recallNotificationList[0].safetyRisk}}</span></div>
                        <div><span class="title">{{'recallRemedy' | translate}}</span>&nbsp;<span class="body">{{recallNotificationList[0].remedy}}</span></div>
                    </div>

                    <div *ngIf="recallNotificationList.length > 1" class="recall-list-wrapper">
                        <ul>
                            <li *ngFor="let recallInformation of recallNotificationList">
                                <div class="header-container">
                                    <div class="header flexitem" style="padding-right: 10px;" *ngIf="recallInformation.recallNumber_mfr != null">
                                        <span class="titleMainLabel">{{'recallNumberMfr' | translate}}</span>&nbsp;<span class="titleMainBody">{{ recallInformation.recallNumber_mfr }}</span>
                                    </div>
                                    <div class="header flexitem" *ngIf="recallInformation.recallNumber_Nhtsa != null">
                                        <span class="titleMainLabel">{{'recallNumberNhtsa' | translate}}</span>&nbsp;<span class="titleMainBody">{{ recallInformation.recallNumber_Nhtsa }}</span>
                                    </div>
                                </div>
                                <div class="header" *ngIf="recallInformation.vinRecallDate != null"><span class="titleMainLabel">{{'recallDate' | translate}}</span>&nbsp;<span class="titleMainBody">{{recallInformation.vinRecallDate | localeDate}}</span></div>
                                <div class="header" *ngIf="recallInformation.isRemedyAvailable != null">
                                    <span class="titleMainLabel">{{'isRecallRemedyAvailable' | translate}}</span>&nbsp;<span class="titleMainBody">{{ recallInformation.isRemedyAvailable ? ('yes' | translate) : ('no' | translate) }}</span>
                                </div>
                                <div><span class="title">{{'recallDescription' | translate}}</span>&nbsp;<span class="body">{{recallInformation.description}}</span></div>
                                <div><span class="title">{{'recallSafetyRisk' | translate}}</span>&nbsp;<span class="body">{{recallInformation.safetyRisk}}</span></div>
                                <div><span class="title">{{'recallRemedy' | translate}}</span>&nbsp;<span class="body">{{recallInformation.remedy}}</span></div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>

