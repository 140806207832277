"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./mileage-penalty.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@angular/common"));
var i3 = __importStar(require("@ngx-translate/core"));
var i4 = __importStar(require("../../../../filters/locale-currency.pipe"));
var i5 = __importStar(require("../../../../modules/storage/storage.service"));
var i6 = __importStar(require("./mileage-penalty.component"));
var styles_MileagePenaltyComponent = [i0.styles];
var RenderType_MileagePenaltyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MileagePenaltyComponent, data: {} });
exports.RenderType_MileagePenaltyComponent = RenderType_MileagePenaltyComponent;
function View_MileagePenaltyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "field-data"], ["id", "mileage-penalty"], ["translate", "currentMileagePenalty"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { mileage: 0 }), i1.ɵdid(4, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵppd(5, 1), i1.ɵpod(6, { amount: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "field-data"; var currVal_1 = _ck(_v, 3, 0, _co.alertExists); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "currentMileagePenalty"; var currVal_3 = _ck(_v, 6, 0, i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.current))); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_MileagePenaltyComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "field-data"], ["id", "mileage-penalty"], ["translate", "currentProjectedMileagePenalty"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { mileage: 0 }), i1.ɵdid(4, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵppd(5, 1), i1.ɵppd(6, 1), i1.ɵpod(7, { current: 0, projected: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "field-data"; var currVal_1 = _ck(_v, 3, 0, _co.alertExists); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "currentProjectedMileagePenalty"; var currVal_3 = _ck(_v, 7, 0, i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.current)), i1.ɵunv(_v, 4, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _co.projected))); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_MileagePenaltyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "field row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "field-label"], ["for", "mileage-penalty"], ["translate", "mileagePenaltyLabel"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MileagePenaltyComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MileagePenaltyComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mileagePenaltyLabel"; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.current != null) && (_co.projected == null)); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.current != null) && (_co.projected != null)); _ck(_v, 6, 0, currVal_2); }, null); }
function View_MileagePenaltyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.LocaleCurrencyPipe, [i5.StorageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MileagePenaltyComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.projected > 0) || (_co.current > 0)); _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_MileagePenaltyComponent_0 = View_MileagePenaltyComponent_0;
function View_MileagePenaltyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mileage-penalty", [], null, null, null, View_MileagePenaltyComponent_0, RenderType_MileagePenaltyComponent)), i1.ɵdid(1, 114688, null, 0, i6.MileagePenaltyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MileagePenaltyComponent_Host_0 = View_MileagePenaltyComponent_Host_0;
var MileagePenaltyComponentNgFactory = i1.ɵccf("mileage-penalty", i6.MileagePenaltyComponent, View_MileagePenaltyComponent_Host_0, { current: "current", projected: "projected", alertExists: "alertExists" }, {}, []);
exports.MileagePenaltyComponentNgFactory = MileagePenaltyComponentNgFactory;
