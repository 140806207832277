import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DealerSettingResponseDto, DealerAssignOwnerDto, UserDto } from '../../generated/models';
import { Modules, AccessRights } from '../../constants';
import { DealerService } from '../../services/dealer.service';
import { DealerSettingsService } from '../../services/dealer-settings.service';
import { AdminSettingsVHRDisclaimerComponent } from './admin-settings-vhr-disclaimer.component';

@Component({
    selector: 'admin-settings-general',
    templateUrl: './admin-settings-general.component-ng.html'
})
export class AdminSettingsGeneralComponent implements OnInit {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};
    @Output() onValidateField = new EventEmitter();
    @ViewChild('overrideStateSelect', {static: false}) overrideStateSelect: ElementRef;
    @ViewChild('overrideStateSelectCXM', { static: false }) overrideStateSelectCXM: ElementRef;
    public twilioSendTextSalesNumber: string;
    public twilioSendTextServiceNumber: string;
    //public routeVoiceCallsToNumber: string;
    //public routeVoiceCallsToNumberCXM: string;
    public twilioSendTextSalesNumberCXM: string;
    public twilioSendTextServiceNumberCXM: string;
    public isDealerPandoXEnabled = false;
    public newTextNumbers = [];
    public newTextNumbersCXM = [];
    public twilioNumbersValid: Boolean = true;
    public twilioNumbersValidCXM: Boolean = true;
    public allTwilioNumbersUnique: Boolean = true;
    public selectedOwners: any = [];
    public ownersDropdownSettings = {};
    public overrideStateCode: string = null;
    public overrideStateCodeCXM: string = null;
    public searchReturnedLessThanTwoLocalNumbers = false;
    public searchReturnedLessThanTwoLocalNumbersCXM = false;
    public localNumberSearchExecuted = false;
    public localNumberSearchExecutedCXM = false;
    public textMessagingAreaCode: number = null;
    public textMessagingAreaCodeCXM: number = null;
    
    public Modules: any = Modules;
    
    private AccessRight = AccessRights;

    readonly placeHolderNumberText = 'tbd';

    constructor(
        private modalService: BsModalService,
        private tService: TranslateService,
        private dealerService: DealerService,
        private dealerSettingService: DealerSettingsService) {
    }

    public ngOnInit() {
        this.ownersDropdownSettings = {
            singleSelection: false,
            text: this.tService.instant('dsAdmin_selectOwners'),
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
            badgeShowLimit: 2,
            labelKey: "fullName",
            primaryKey: "userID"
        };  

        this.twilioSendTextSalesNumber = this.getTwilioNumber(this.AccessRight.SendTextSales);
        this.twilioSendTextServiceNumber = this.getTwilioNumber(this.AccessRight.SendTextService);
        this.twilioSendTextSalesNumberCXM = this.getTwilioNumber(this.AccessRight.SendTextSalesCXM);
        this.twilioSendTextServiceNumberCXM = this.getTwilioNumber(this.AccessRight.SendTextServiceCXM);
        //this.routeVoiceCallsToNumber = this.formatPhoneNumber(this.dsa.dealerSetting.textRouterVoiceNumber);
        //this.routeVoiceCallsToNumberCXM = this.formatPhoneNumber(this.dsa.dealerSetting.textRouterVoiceNumberCXM);
        this.dealerService.getIsDealerPandoXEnabled(this.dsa.dealer.dealerID).then(result => this.isDealerPandoXEnabled = result);

        this.selectedOwners = this.dsa.dealerOrphanOwner.map(obj => {
            var user = this.dsa.users.find(u => u.userID == obj.userID);
            if (user != null)
                return user;
        }).filter(Boolean);
    }

    public getTwilioNumber(accessRightId: number) {
        var result = null;
        if (this.dsa.dealerTwilioPhoneNumber)
            result = this.dsa.dealerTwilioPhoneNumber.find(p => p.accessRightId == accessRightId);

        return (result != null) ? result.twilioPhoneNumber : this.placeHolderNumberText;
    }

    public unformatPhoneNumber(source: string): string {
        if (source) {
            var endResult = "";
            var phoneText = source;
            for (var i = 0; i < phoneText.length; i++) {
                var code = phoneText.charCodeAt(i);
                if (code >= 48 && code <= 57) endResult += phoneText.charAt(i);
            }

            if (endResult.length == 10)
                return "+1" + endResult;

            return source;
        }
        else
            return null;
    }

    public formatPhoneNumber(source: string): string {
        try {
            if (source == this.placeHolderNumberText)
                return this.tService.instant(this.placeHolderNumberText).toUpperCase();

            var endResult = "";
            if (source.length > 9) {
                var phoneText = source.replace("+1", "");
                for (var i = 0; i < phoneText.length; i++) {
                    var code = phoneText.charCodeAt(i);
                    if (code >= 48 && code <= 57) endResult += phoneText.charAt(i);
                }
            }
            if (endResult.length == 10) {
                return "(" + endResult.substring(0, 3) + ") " + endResult.substring(3, 6) + "-" + endResult.substring(6, 10);
            }
        } catch (err) { }
        return source;
    }

    public enableVehicleHistoryReports(): void {

        this.bsModalRef = this.modalService.show(AdminSettingsVHRDisclaimerComponent);
        this.bsModalRef.content.acceptDisclaimer.subscribe(result => {
            this.dsa.isEnableVHR = true;
        });
    }

    public onOverrideStateChanged(): void {
        this.newTextNumbers = [];
        this.overrideStateSelect.nativeElement.classList.remove("invalid");
    }
    public onOverrideStateChangedCXM(): void {
        this.newTextNumbersCXM = [];
        this.overrideStateSelectCXM.nativeElement.classList.remove("invalid");
    }

    private allNumbersUnique(): boolean {
        let isUnique = true;

        if (this.dsa.isTextMessagingNewNumberSettingsEnabled && this.dsa.isTextMessagingNewNumberSettingsEnabledCXM) {
            if ((this.twilioSendTextSalesNumberCXM != this.placeHolderNumberText && this.twilioSendTextSalesNumber == this.twilioSendTextSalesNumberCXM)
                || (this.twilioSendTextServiceNumberCXM != this.placeHolderNumberText && this.twilioSendTextSalesNumber == this.twilioSendTextServiceNumberCXM))
                isUnique = false;
            else if ((this.twilioSendTextSalesNumberCXM != this.placeHolderNumberText && this.twilioSendTextServiceNumber == this.twilioSendTextSalesNumberCXM)
                || (this.twilioSendTextServiceNumberCXM != this.placeHolderNumberText && this.twilioSendTextServiceNumber == this.twilioSendTextServiceNumberCXM))
                isUnique = false;
        }
        return isUnique;
    }

    public onNewNumberChanged(): void {
        if (this.twilioSendTextSalesNumber == this.placeHolderNumberText && this.twilioSendTextServiceNumber == this.placeHolderNumberText) {
            this.dsa.twilioSendTextSalesNumber = null;
            this.dsa.twilioSendTextServiceNumber = null;
            this.twilioNumbersValid = true;
            this.allTwilioNumbersUnique = true;
        }
        else if (this.twilioSendTextSalesNumber == this.placeHolderNumberText || this.twilioSendTextServiceNumber == this.placeHolderNumberText)
            this.twilioNumbersValid = false;
        else if (this.twilioSendTextSalesNumber == this.twilioSendTextServiceNumber)
            this.twilioNumbersValid = false;
        else {
            this.dsa.twilioSendTextSalesNumber = this.twilioSendTextSalesNumber;
            this.dsa.twilioSendTextServiceNumber = this.twilioSendTextServiceNumber;
            this.twilioNumbersValid = true;
        }

        this.allTwilioNumbersUnique = true;
        if (this.twilioNumbersValid) {
            this.allTwilioNumbersUnique = this.allNumbersUnique();
        }

        if (!(this.twilioNumbersValid && this.allTwilioNumbersUnique)) {
            this.dsa.twilioSendTextSalesNumber = null;
            this.dsa.twilioSendTextServiceNumber = null;
        }
    }

    public onNewNumberChangedCXM(): void {
        if (this.twilioSendTextSalesNumberCXM == this.placeHolderNumberText && this.twilioSendTextServiceNumberCXM == this.placeHolderNumberText) {
            this.dsa.twilioSendTextSalesNumberCXM = null;
            this.dsa.twilioSendTextServiceNumberCXM = null;
            this.twilioNumbersValidCXM = true;
            this.allTwilioNumbersUnique = true;
        }
        else if (this.twilioSendTextSalesNumberCXM == this.placeHolderNumberText || this.twilioSendTextServiceNumberCXM == this.placeHolderNumberText)
            this.twilioNumbersValidCXM = false;
        else if (this.twilioSendTextSalesNumberCXM == this.twilioSendTextServiceNumberCXM)
            this.twilioNumbersValidCXM = false;
        else {
            this.dsa.twilioSendTextSalesNumberCXM = this.twilioSendTextSalesNumberCXM;
            this.dsa.twilioSendTextServiceNumberCXM = this.twilioSendTextServiceNumberCXM;
            this.twilioNumbersValidCXM = true;
        }

        this.allTwilioNumbersUnique = true;
        if (this.twilioNumbersValidCXM) {
            this.allTwilioNumbersUnique = this.allNumbersUnique();
        }

        if (!(this.twilioNumbersValidCXM && this.allTwilioNumbersUnique)) {
            this.dsa.twilioSendTextSalesNumberCXM = null;
            this.dsa.twilioSendTextServiceNumberCXM = null;
        }
    }

    public onViewNumbers(isStateSearch: boolean = false): void {

        this.newTextNumbers = [];
        let stateCode = null;

        if (isStateSearch) stateCode = this.overrideStateCode;

        this.dealerSettingService.GetTextNumbers(this.dsa.dealerSetting.dealerID, stateCode, this.textMessagingAreaCode).then(result => {
            this.newTextNumbers = result;
            this.searchReturnedLessThanTwoLocalNumbers = (this.newTextNumbers.length < 2);

            if (this.overrideStateCode == null || this.overrideStateCode.length === 0) {
                this.localNumberSearchExecuted = true;
            }

            this.dsa.twilioSendTextServiceNumber = null;
            this.dsa.twilioSendTextSalesNumber = null;
            this.twilioSendTextSalesNumber = this.placeHolderNumberText;
            this.twilioSendTextServiceNumber = this.placeHolderNumberText;
        });
    }

    public onViewNumbersCXM(isStateSearch: boolean = false): void {

        this.newTextNumbersCXM = [];
        let stateCode = null;

        if (isStateSearch) stateCode = this.overrideStateCodeCXM;

        this.dealerSettingService.GetTextNumbers(this.dsa.dealerSetting.dealerID, stateCode, this.textMessagingAreaCodeCXM).then(result => {
            this.newTextNumbersCXM = result;
            this.searchReturnedLessThanTwoLocalNumbersCXM = (this.newTextNumbersCXM.length < 2);

            if (this.overrideStateCodeCXM == null || this.overrideStateCodeCXM.length === 0) {
                this.localNumberSearchExecutedCXM = true;
            }

            this.dsa.twilioSendTextServiceNumberCXM = null;
            this.dsa.twilioSendTextSalesNumberCXM = null;
            this.twilioSendTextSalesNumberCXM = this.placeHolderNumberText;
            this.twilioSendTextServiceNumberCXM = this.placeHolderNumberText;
        });
    }

    public onSearchByState(): void {
        if (this.overrideStateCode == null || this.overrideStateCode.length === 0) {
            this.overrideStateSelect.nativeElement.classList.add("invalid");
        } else {
            this.onViewNumbers(true);
        }
    }
    public onSearchByStateCXM(): void {
        if (this.overrideStateCodeCXM == null || this.overrideStateCodeCXM.length === 0) {
            this.overrideStateSelectCXM.nativeElement.classList.add("invalid");
        } else {
            this.onViewNumbersCXM(true);
        }
    }

    public onSave(): void {
        // No longer supporting voice number
        //this.dsa.dealerSetting.textRouterVoiceNumber = this.unformatPhoneNumber(this.routeVoiceCallsToNumber);
        //this.dsa.dealerSetting.textRouterVoiceNumberCXM = this.unformatPhoneNumber(this.routeVoiceCallsToNumberCXM);

        this.dsa.dealerOrphanOwner = this.selectedOwners.map((obj: UserDto) => {
            // dealerID and typeID are filled by the api
            var rObj: DealerAssignOwnerDto = {};
            rObj.userID = obj.userID;
            return rObj;
        });
    }
}