"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var ActivityHistoryClientService = /** @class */ (function (_super) {
    __extends(ActivityHistoryClientService, _super);
    function ActivityHistoryClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `ActivityHistoryClientService.GetExtByEntityIdByEntityidGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `count`:
     */
    ActivityHistoryClientService.prototype.GetExtByEntityIdByEntityidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetExtByEntityId/" + params.entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ActivityHistoryClientService.GetExtByEntityIdByEntityidGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `count`:
     */
    ActivityHistoryClientService.prototype.GetExtByEntityIdByEntityidGET = function (params) {
        return this.GetExtByEntityIdByEntityidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    ActivityHistoryClientService.prototype.GetLastActivityByEntityIdByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetLastActivityByEntityId/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    ActivityHistoryClientService.prototype.GetLastActivityByEntityIdByEntityidGET = function (entityId) {
        return this.GetLastActivityByEntityIdByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    ActivityHistoryClientService.prototype.GetOneToOneMailHistoryByByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetOneToOneMailHistoryBy/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    ActivityHistoryClientService.prototype.GetOneToOneMailHistoryByByEntityidGET = function (entityId) {
        return this.GetOneToOneMailHistoryByByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    ActivityHistoryClientService.prototype.GetSLMEmailOfferHistoryByByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetSLMEmailOfferHistoryBy/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    ActivityHistoryClientService.prototype.GetSLMEmailOfferHistoryByByEntityidGET = function (entityId) {
        return this.GetSLMEmailOfferHistoryByByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `dealerId`:
     */
    ActivityHistoryClientService.prototype.GetExtActivityHistoryByByEntityidByDealeridGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetExtActivityHistoryBy/" + params.entityId + "/" + params.dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `dealerId`:
     */
    ActivityHistoryClientService.prototype.GetExtActivityHistoryByByEntityidByDealeridGET = function (params) {
        return this.GetExtActivityHistoryByByEntityidByDealeridGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    ActivityHistoryClientService.prototype.GetEmailConversationHistoryByByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetEmailConversationHistoryBy/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    ActivityHistoryClientService.prototype.GetEmailConversationHistoryByByEntityidGET = function (entityId) {
        return this.GetEmailConversationHistoryByByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    ActivityHistoryClientService.prototype.GetCustomerEngagementHistoryByByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/ActivityHistory/GetCustomerEngagementHistoryBy/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    ActivityHistoryClientService.prototype.GetCustomerEngagementHistoryByByEntityidGET = function (entityId) {
        return this.GetCustomerEngagementHistoryByByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ActivityHistoryClientService.GetCombinedActivityHistoryGETParams` containing the following parameters:
     *
     * - `opportunityId`:
     *
     * - `dealerId`:
     *
     * - `customerId`:
     *
     * @return Success
     */
    ActivityHistoryClientService.prototype.GetCombinedActivityHistoryGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.opportunityId != null)
            __params = __params.set('opportunityId', params.opportunityId.toString());
        if (params.dealerId != null)
            __params = __params.set('dealerId', params.dealerId.toString());
        if (params.customerId != null)
            __params = __params.set('customerId', params.customerId.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/ActivityHistory/GetCombinedActivityHistory", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ActivityHistoryClientService.GetCombinedActivityHistoryGETParams` containing the following parameters:
     *
     * - `opportunityId`:
     *
     * - `dealerId`:
     *
     * - `customerId`:
     *
     * @return Success
     */
    ActivityHistoryClientService.prototype.GetCombinedActivityHistoryGET = function (params) {
        return this.GetCombinedActivityHistoryGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    ActivityHistoryClientService.GetExtByEntityIdByEntityidGETPath = '/api/ActivityHistory/GetExtByEntityId/{entityId}';
    ActivityHistoryClientService.GetLastActivityByEntityIdByEntityidGETPath = '/api/ActivityHistory/GetLastActivityByEntityId/{entityId}';
    ActivityHistoryClientService.GetOneToOneMailHistoryByByEntityidGETPath = '/api/ActivityHistory/GetOneToOneMailHistoryBy/{entityId}';
    ActivityHistoryClientService.GetSLMEmailOfferHistoryByByEntityidGETPath = '/api/ActivityHistory/GetSLMEmailOfferHistoryBy/{entityId}';
    ActivityHistoryClientService.GetExtActivityHistoryByByEntityidByDealeridGETPath = '/api/ActivityHistory/GetExtActivityHistoryBy/{entityId}/{dealerId}';
    ActivityHistoryClientService.GetEmailConversationHistoryByByEntityidGETPath = '/api/ActivityHistory/GetEmailConversationHistoryBy/{entityId}';
    ActivityHistoryClientService.GetCustomerEngagementHistoryByByEntityidGETPath = '/api/ActivityHistory/GetCustomerEngagementHistoryBy/{entityId}';
    ActivityHistoryClientService.GetCombinedActivityHistoryGETPath = '/api/ActivityHistory/GetCombinedActivityHistory';
    ActivityHistoryClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityHistoryClientService_Factory() { return new ActivityHistoryClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: ActivityHistoryClientService, providedIn: "root" });
    return ActivityHistoryClientService;
}(base_service_1.BaseService));
exports.ActivityHistoryClientService = ActivityHistoryClientService;
