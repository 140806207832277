<div class="row">
    <div class="col-md-12">
        <div class="settingTitle" translate="dsAdmin_crmLeadsDelivery"></div>
        <tooltip class="crmLeadsDelivery" [titleKey]="'dsAdmin_crmLeadsDelivery'" [contentKey]="'dsAdmin_crmLeadsDeliveryTooltip'"></tooltip>
        <div class="settingItem row">
            <div class="col-md-3" style="margin-top:5px">
                <label><input name="DealerAdfSetting.Enabled" type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.enabled" /> {{ 'dsAdmin_deliverLeadsToCRM' | translate }}</label>
            </div>
            <div class="col-md-2">
                <button *ngIf="isEmployee" type="button" class="btn btn-primary saveButton" style="font-size:12px" (click)="sendTestLead()" translate="sendTestLead"></button>
            </div>
        </div>
        <div *ngIf="dsa.dealerAdfSetting.enabled">
            <div class="settingItem row">
                <div style="float:left;margin:5px 0 0 12px" translate="dsAdmin_deliveryLeadsWithSourceAs"></div>
                <div style="float:left;margin-left:5px">
                    <select class="form-control" style="width:200px" [(ngModel)]="dsa.dealerAdfSetting.deliverWithOpportunity">
                        <option [ngValue]="false">AutoAlert</option>
                        <option [ngValue]="true">AutoAlert-&lt;{{ 'dsAdmin_opportunityName' | translate }}&gt;</option>
                    </select>
                </div>
                <div style="clear:both;margin: 20px 0 0 20px" *ngIf="dsa.dealerAdfSetting.deliverWithOpportunity">
                    <div style="margin:15px 0 8px 0;float:left" translate="dsAdmin_leadsSourceOpportunityNameMessage"></div>
                    <tooltip class="crmLeadOpportunity" [titleKey]="''" [contentKey]="'dsAdmin_leadOpportunityTooltip'"></tooltip>
                    <div style="clear:both">
                        <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.deliverAllOpportunities" (change)="onDeliverAllOpportunitiesChanged($event)" /> {{ 'all' | translate }}</label></div>
                        <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.deliverOpportunityCreditConvert" /> AutoAlert-{{ 'creditConvert' | translate }}</label></div>
                        <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.deliverOpportunityUpgrade" /> AutoAlert-{{ 'dsAdmin_upgradeAorFBlock' | translate }}</label></div>
                        <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.deliverOpportunityContractEnd" /> AutoAlert-{{ 'contractEndName' | translate }}</label></div>
                        <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.deliverOpportunityMileage" /> AutoAlert-{{ 'mileageLabel' | translate }}</label></div>
                        <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.deliverOpportunityWarranty" /> AutoAlert-{{ 'warrantyName' | translate }}</label></div>
                    </div>
                </div>
            </div>
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_sendLeadsImmediatelyWhen"></div>
            <div class="settingItem">
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.autoSendOpportunityCreated" /> {{ 'dsAdmin_autoSendOpportunityCreated' | translate }}</label></div>
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.autoSendOpportunityUnread" /> {{ 'dsAdmin_autoSendOpportunityUnread' | translate }}</label></div>
                <div style="float:left"><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.autoSendVinScan" /> {{ 'dsAdmin_autoSendVinScan' | translate}}</label></div>
                <div style="margin-top:-10px"><tooltip [titleKey]="'dsAdmin_autoSendVinScan'" [contentKey]="'dsAdmin_autoSendVinScanTooltip'"></tooltip></div>
                <div style="clear:both"><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.autoSendServiceAppointment" /> {{ 'dsAdmin_autoSendServiceAppointment' | translate }}</label></div>
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.autoSendServiceAlert" /> {{ 'dsAdmin_autoSendServiceAlert' | translate }}</label></div>
            </div>
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_sendLeadsToCRMOnActivity"></div>
            <tooltip [titleKey]="'dsAdmin_sendLeadsToCRMOnActivity'" [contentKey]="'dsAdmin_sendLeadsToCRMOnActivityTooltip'"></tooltip>
            <div class="settingItem">
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.notePosted" /> {{ 'dsAdmin_notePosted' | translate }}</label></div>
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.phoneCallLogged" /> {{ 'dsAdmin_phoneCallLogged' | translate }}</label></div>
                <div translate="dsAdmin_phoneAppointmentDealSheetIs"></div>
                <div class="settingItem">
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.phoneCallScheduled" /> {{ 'ACTIVITY.SCHEDULED' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.phoneCallCompleted" /> {{ 'ACTIVITY.COMPLETED' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.phoneCallRescheduled" /> {{ 'rescheduled' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.phoneCallReassigned" /> {{ 'ACTIVITY.REASSIGNED' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.phoneCallCancelled" /> {{ 'cancelled' | translate }}</label></div>
                </div>
                <div translate="dsAdmin_appointmentOnAnyDealSheetIs"></div>
                <div class="settingItem">
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.appointmentScheduled" /> {{ 'ACTIVITY.SCHEDULED' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.appointmentCompleted" /> {{ 'ACTIVITY.COMPLETED' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.appointmentRescheduled" /> {{ 'rescheduled' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.appointmentReassigned" /> {{ 'ACTIVITY.REASSIGNED' | translate }}</label></div>
                    <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.appontmentCancelled" /> {{ 'cancelled' | translate }}</label></div>
                </div>
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.emailSent" /> {{ 'dsAdmin_whenEmailSentToCustomers' | translate }}</label></div>
                <div style="float:left;margin:5px 10px 0 0" translate="dsAdmin_sendLeadToCRM"></div>
                <div>
                    <select class="form-control" style="width:200px" [(ngModel)]="dsa.dealerAdfSetting.delayMinutes">
                        <option value="0" translate="immediately"></option>
                        <option value="15">15 {{ 'minutes' | translate }}</option>
                        <option value="60">1 {{ 'hour' | translate }}</option>
                        <option value="-1" translate="endOfDay"></option>
                    </select>
                </div>
            </div>
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_emailUrlFrequencyControl"></div>
            <tooltip [titleKey]="'dsAdmin_emailUrlFrequencyControl'" [contentKey]="'dsAdmin_emailUrlFrequencyControlTooltip'"></tooltip>
            <div class="settingItem">
                {{ 'dsAdmin_crmEmails' | translate }} <input type="text" class="input-sm textbox" style="width:400px" [(ngModel)]="dsa.dealerAdfSetting.crmEmail" />
            </div>
            <div class="settingItem">
                <label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.includeDealSheetUrl" /> {{ 'dsAdmin_includeDealSheetURLInNotes' | translate }}</label>
            </div>
            <div class="settingItem">
                {{ 'dsAdmin_leadDuplicationControlInDays' | translate }} <input name="DealerAdfSetting.LeadDuplicationControlInDays" type="number" class="input-sm textboxSm" [(ngModel)]="dsa.dealerAdfSetting.leadDuplicationControlInDays" required /> {{ ('days' | translate).toLowerCase() }}
            </div>
        </div>

        <div *ngIf="dsa.isFordAlert">
            <div class="sectionSpacer"></div>
            <div class="settingTitle" translate="dsAdmin_sendLeadsFordDirectWhen"></div>
            <div class="settingItem">
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.fordDirectAppointment" /> {{ 'dsAdmin_fordDirectAppointment' | translate }}</label></div>
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.fordDirectO2OPurlLead" /> {{ 'dsAdmin_fordDirectO2OPurlLead' | translate }}</label></div>
                <div><label><input type="checkbox" [(ngModel)]="dsa.dealerAdfSetting.fordDirectBatch" /> {{ 'dsAdmin_fordDirectBatch' | translate }}</label></div>
            </div>
        </div>
    </div>
</div>