localeNumberFilter.$inject = ["storageService"];
// Don't upgrade this to Angular 7 
// Angular has a built in pipe for formatting by culture
// https://angular.io/api/common/DecimalPipe
import angular from 'angular';
angular.module('AaApp.Opp').filter('localeNumber', localeNumberFilter);
localeNumberFilter.$inject = ['storageService'];
/* @ngInject */

function localeNumberFilter(storageService) {
  return function (number, fractionDigits) {
    if (isNumber(number)) {
      number = parseFloat(number);
      var savedCultureName = storageService.getItem('cultureName');
      var cultureName = savedCultureName ? savedCultureName : 'en-us';

      if (typeof fractionDigits === 'number') {
        return number.toLocaleString(cultureName, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        });
      } else {
        return number.toLocaleString(cultureName);
      }
    } else {
      return number;
    }
  };

  function isNumber(obj) {
    return !isNaN(parseFloat(obj));
  }
}