"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var dealer_settings_service_1 = require("../../services/dealer-settings.service");
var dealer_service_1 = require("../../services/dealer.service");
var admin_settings_dealerinfo_component_1 = require("./admin-settings-dealerinfo.component");
var admin_settings_advanced_component_1 = require("./admin-settings-advanced.component");
var admin_settings_creditconvert_component_1 = require("./admin-settings-creditconvert.component");
var admin_settings_general_component_1 = require("./admin-settings-general.component");
var admin_settings_tabs_1 = require("./admin-settings-tabs");
var admin_settings_opportunity_component_1 = require("./admin-settings-opportunity.component");
var AdminSettingsComponent = /** @class */ (function () {
    function AdminSettingsComponent(changeDetector, dealerService, modalService, tService, dsService, logger, document) {
        this.changeDetector = changeDetector;
        this.dealerService = dealerService;
        this.modalService = modalService;
        this.tService = tService;
        this.dsService = dsService;
        this.logger = logger;
        this.document = document;
        this.formLoaded = false;
        this.dsa = {};
        this.tabIndex = admin_settings_tabs_1.AdminSettingTabs.General;
        this.disableSaveCancel = false;
    }
    Object.defineProperty(AdminSettingsComponent.prototype, "tabs", {
        get: function () { return admin_settings_tabs_1.AdminSettingTabs; },
        enumerable: true,
        configurable: true
    });
    AdminSettingsComponent.prototype.ngOnInit = function () {
        this.dealers = this.dealerService.getSelectedDealers();
        this.selectedDealerId = this.dealers[0].id;
        var dsDealerId = localStorage.getItem('dsDealerId');
        if (dsDealerId != null && this.dealers.some(function (d) { return d.id == Number(dsDealerId); }))
            this.selectedDealerId = Number(dsDealerId);
    };
    AdminSettingsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.busy = this.loadDealer(this.selectedDealerId).then(function (result) {
            // execute validation for all fields (formats and highlights fields where necessary)
            _this.validateAllFieldsUpdateUI();
            _this.dsa.isFormChanged = false; // we need to set formChanged back to false here as validateAllFieldsUpdateUI will set it to true.
        });
    };
    AdminSettingsComponent.prototype.onTabChanged = function (tabIndex) {
        this.tabIndex = tabIndex;
        localStorage.setItem("dsTabId", String(tabIndex));
        if (this.asDealerInfoComponent && this.asDealerInfoComponent.selectedMakeId != 0) {
            this.asDealerInfoComponent.selectedMakeId = 0;
            this.asDealerInfoComponent.makeChanged();
        }
    };
    AdminSettingsComponent.prototype.dealerChanged = function () {
        var _this = this;
        this.busy = this.loadDealer(this.selectedDealerId).then(function (result) {
            // execute validation for all fields (formats and highlights fields where necessary)
            _this.validateAllFieldsUpdateUI();
        });
    };
    AdminSettingsComponent.prototype.validateAllFieldsUpdateUI = function () {
        for (var _i = 0, _a = this.dsa.validationSetting; _i < _a.length; _i++) {
            var item = _a[_i];
            var target = this.document.getElementsByName(item.fieldName);
            if (target != null && target.length > 0) {
                this.onValidateAfterEntry(target[0]);
                this.onValidateDuringEntry(target[0]);
            }
        }
    };
    // validations we do on keyup.. we set attributes for the input, validate the field value, and set the 'invalid" cssClass if necessary
    AdminSettingsComponent.prototype.onValidateDuringEntry = function (target) {
        if (event != null && target != null) {
            var isValid = true;
            var vs = this.dsa.validationSetting.find(function (v) { return v.fieldName == target.name; });
            if (vs != null) {
                if (vs != null && vs.decimalPlaceStep)
                    target.step = vs.decimalPlaceStep;
                if (vs.maxCharacters)
                    target.maxLength = vs.maxCharacters;
                // validate the field data
                isValid = this.validateField(vs, target.value);
            }
            if (isValid)
                target.classList.remove("invalid");
            else
                target.classList.add("invalid");
        }
    };
    // validations we do on blur
    AdminSettingsComponent.prototype.onValidateAfterEntry = function (target) {
        if (event != null && target != null) {
            if (target.name != "dealershipInfo_DealerMake") {
                this.dsa.isFormChanged = true;
            }
            this.setValidationSettingEnabled(); // in case enabled/disabled validation settings have changed
            var vs = this.dsa.validationSetting.find(function (v) { return v.fieldName == target.name; });
            if (vs != null && vs.decimalPlaces)
                target.value = parseFloat(target.value).toFixed(vs.decimalPlaces);
            if (vs != null) {
                // set min/max values for the input
                if (vs.maxValue != null) {
                    target.max = vs.maxValue;
                    if (target.value && target.value > vs.maxValue) {
                        target.value = vs.maxValue;
                        this.changeDetector.detectChanges();
                        target.classList.remove("invalid");
                    }
                }
                if (vs.minValue != null) {
                    target.min = vs.minValue;
                    if (target.value && target.value < vs.minValue) {
                        target.value = vs.minValue;
                        this.changeDetector.detectChanges();
                        target.classList.remove("invalid");
                    }
                }
            }
        }
    };
    AdminSettingsComponent.prototype.validateAllFields = function () {
        this.setValidationSettingEnabled();
        var isFormValid = true;
        // we attempt to valid all datasets here (only where validation rules exist)
        for (var _i = 0, _a = Object.keys(this.dsa); _i < _a.length; _i++) {
            var propName = _a[_i];
            var value = this.dsa[propName];
            if (Array.isArray(value)) { // validate arrays of objects
                if (propName != "validationSetting") {
                    for (var _b = 0, value_1 = value; _b < value_1.length; _b++) {
                        var item = value_1[_b];
                        for (var _c = 0, _d = Object.keys(item); _c < _d.length; _c++) {
                            var objPropName = _d[_c];
                            var objValue = item[objPropName];
                            if (!this.validateFieldOnSave(propName + "." + objPropName, objValue))
                                isFormValid = false;
                        }
                    }
                }
            }
            else if (typeof value == "object") // validate single objects
                if (value != null) {
                    for (var _e = 0, _f = Object.keys(value); _e < _f.length; _e++) {
                        var objPropName = _f[_e];
                        var objValue = value[objPropName];
                        if (!this.validateFieldOnSave(propName + "." + objPropName, objValue))
                            isFormValid = false;
                    }
                }
                else {
                    // we only care about arrays and objects, so ignore anything else
                }
        }
        if (!this.dsa.isDealerLogoUpdate && this.dsa.dealerLogoImage != null)
            this.dsa.dealerLogoImage.logoImageBinary = null;
        var fieldNameToHighlight = null;
        // custom validations start here
        if (!this.asGeneralComponent.twilioNumbersValid) {
            isFormValid = false;
            this.tabIndex = admin_settings_tabs_1.AdminSettingTabs.General;
        }
        if (!this.asDealerInfoComponent.customerCulturesDifferent) {
            isFormValid = false;
            this.tabIndex = admin_settings_tabs_1.AdminSettingTabs.DealerInfo;
        }
        if (!this.asOpportunityComponent.leaseContractEndRangeValid || !this.asOpportunityComponent.purchaseContractEndRangeValid) {
            isFormValid = false;
            this.tabIndex = admin_settings_tabs_1.AdminSettingTabs.Opportunity;
        }
        return isFormValid;
    };
    AdminSettingsComponent.prototype.setValidationSettingEnabled = function () {
        // disable validation when tab is hidden or feature disabled
        for (var _i = 0, _a = this.dsa.validationSetting; _i < _a.length; _i++) {
            var item = _a[_i];
            switch (item.pageNum) {
                case admin_settings_tabs_1.AdminSettingTabs.CreditConvert: // creditConvert
                    item.enabled = this.dsa.dealerCreditSoftPull != null && this.dsa.dealerCreditSoftPull.useCreditSoftPull && this.dsa.isCreditConvertDealerSettingEnabled;
                    break;
                case admin_settings_tabs_1.AdminSettingTabs.Product: // product
                    item.enabled = this.dsa.isProductSettingsEnabled;
                    break;
                case admin_settings_tabs_1.AdminSettingTabs.CRMLeadsDelivery: // crm leads delivery
                    item.enabled = this.dsa.dealerAdfSetting.enabled;
                    break;
            }
            if (item.fieldName == "DealerSetting.AutoCheckCustomerId")
                item.enabled = this.dsa.dealerSetting.enableAutoCheck;
            if (item.fieldName == "DealerSetting.CarFaxUsername" || item.fieldName == "DealerSetting.CarFaxPassword")
                item.enabled = this.dsa.dealerSetting.enableCarFax && this.dsa.dealerSetting.carFaxVersion == "1";
            if (item.fieldName == "DealerSetting.CarFaxAutoPurchase")
                item.enabled = this.dsa.dealerSetting.enableCarFax && this.dsa.dealerSetting.carFaxVersion == "2";
            if (item.fieldName == "DealerSetting.CarProofAccessKey")
                item.enabled = this.dsa.dealerSetting.enableCarProof;
        }
    };
    AdminSettingsComponent.prototype.onSave = function () {
        var _this = this;
        this.disableSaveCancel = true;
        this.asGeneralComponent.onSave();
        this.asCreditConvertComponent.onSave();
        if (this.validateAllFields()) {
            try {
                this.busy = this.dsService.UpdateDealerSettingAll(this.dsa).then(function (result) {
                    switch (result) {
                        case 0: // saved successfully
                        case 1: // no changes - not saved
                            _this.logger.success(_this.tService.instant("changesSaved"));
                            if (result == 0) {
                                _this.busy = _this.loadDealer(_this.selectedDealerId).then(function (result) {
                                    // execute validation for all fields (formats and highlights fields where necessary)
                                    _this.validateAllFieldsUpdateUI();
                                });
                            }
                            break;
                        case 5: // unable to provision text number
                            _this.logger.error(_this.tService.instant("dsAdmin_unableToProvisionTextNumber"));
                            break;
                        default:
                            _this.logger.error(_this.tService.instant("generalErrorMessageKey"));
                            break;
                    }
                    _this.disableSaveCancel = false;
                });
            }
            catch (err) {
                this.logger.error(this.tService.instant("generalErrorMessageKey"));
                this.disableSaveCancel = false;
            }
        }
        else {
            this.logger.error(this.tService.instant("validationErrorResubmit"));
            this.validateAllFieldsUpdateUI();
            this.disableSaveCancel = false;
        }
    };
    AdminSettingsComponent.prototype.onCancel = function () {
        var _this = this;
        this.disableSaveCancel = true;
        this.busy = this.loadDealer(this.selectedDealerId).then(function (result) {
            // execute validation for all fields (formats and highlights fields where necessary)
            _this.validateAllFieldsUpdateUI();
            _this.logger.warning(_this.tService.instant("settingsReturnedToDefault"));
            _this.disableSaveCancel = false;
        });
    };
    /*  No longer needed....  use isFormChanged property if you need to determine if the form changed as below
    public disableSaveCancel() {
       // return !this.dsa.isFormChanged || this.asDealerInfoComponent.isReadOnly;

       // just leave disable/save buttons enabled for now
       return false;
    }
    */
    // on save, we don't need to update any css and we need to set the tab where the last error occurred and return validation status
    AdminSettingsComponent.prototype.validateFieldOnSave = function (path, value) {
        var vs = this.dsa.validationSetting.find(function (v) { return v.fieldName.toLowerCase() == path.toLowerCase(); });
        if (vs != null) {
            var isValid = this.validateField(vs, value);
            if (!isValid)
                this.tabIndex = vs.pageNum;
            return isValid;
        }
        return true;
    };
    AdminSettingsComponent.prototype.validateField = function (vs, value) {
        var isFieldEmpty = (value == null || value.length == 0);
        if (vs.enabled) {
            if (vs.required && isFieldEmpty)
                return false;
            if (vs.maxCharacters && value != null && value.length > vs.maxCharacters)
                return false;
            if (vs.isURL && !isFieldEmpty) {
                // regex taken from legacy to ensure same behavior
                var urlRegEx = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
                if (!value.match(urlRegEx))
                    return false;
            }
            var numValue = Number(value);
            if (!Number.isNaN(numValue)) {
                if (vs.minValue != null && numValue < vs.minValue)
                    return false;
                if (vs.maxValue != null && numValue > vs.maxValue)
                    return false;
            }
        }
        if (vs.fieldName == "DealerSetting.LeaseContractEndRangeFrom" || vs.fieldName == "DealerSetting.LeaseContractEndRangeTo")
            return this.asOpportunityComponent.leaseContractEndRangeValid;
        if (vs.fieldName == "DealerSetting.PurchaseContractEndRangeFrom" || vs.fieldName == "DealerSetting.PurchaseContractEndTo")
            return this.asOpportunityComponent.purchaseContractEndRangeValid;
        return true;
    };
    AdminSettingsComponent.prototype.loadDealer = function (dealerId) {
        var _this = this;
        this.formLoaded = false;
        return this.dsService.GetDealerSettingAll(this.selectedDealerId).then(function (result) {
            _this.dsa = result;
            _this.dsa.isFormChanged = false;
            _this.formLoaded = true;
            _this.changeDetector.detectChanges();
            // initialize all the components to update any special settings
            _this.asDealerInfoComponent.makeChanged();
            _this.asCreditConvertComponent.ngOnInit();
            _this.asAdvancedComponent.ngOnInit();
            _this.asGeneralComponent.ngOnInit();
            // hide the creditConvert tab when necessary  [hidden]="true" doesn't work for some reason?????
            _this.tabCreditConvertCssClass = (_this.dsa.isCreditConvertDealerSettingEnabled) ? "" : "hidden";
            _this.tabProductCssClass = (_this.dsa.isProductSettingsEnabled) ? "" : "hidden";
            // set the default tab
            var dsTabId = localStorage.getItem('dsTabId');
            if (dsTabId != null) {
                _this.tabIndex = Number(dsTabId);
                if (!_this.dsa.isCreditConvertDealerSettingEnabled && _this.tabIndex == admin_settings_tabs_1.AdminSettingTabs.CreditConvert) // if creditConvert settings are disabled, we can't default to that tab
                    _this.tabIndex = admin_settings_tabs_1.AdminSettingTabs.General;
                else if (!_this.dsa.isProductSettingsEnabled && _this.tabIndex == admin_settings_tabs_1.AdminSettingTabs.Product)
                    _this.tabIndex = admin_settings_tabs_1.AdminSettingTabs.General;
            }
            localStorage.setItem("dsDealerId", String(_this.dsa.dealerSetting.dealerID));
            _this.setValidationSettingEnabled();
        });
    };
    return AdminSettingsComponent;
}());
exports.AdminSettingsComponent = AdminSettingsComponent;
