
/* injects from baggage-loader */
require('./facebook-photo.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('facebookPhoto', {
    templateUrl: '/app/components/deal-sheet/facebook-photo.component.html',
    controller: FacebookPhotoController,
    bindings: {
        email: '<'
    }
});

/* @ngInject */
function FacebookPhotoController(userService) {
    var $ctrl = this;

    $ctrl.facebookUserId;
    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if (changes.email && changes.email.currentValue) {
            userService.getFacebookUserId($ctrl.email)
                .then(setFacebookUserId);
        }
    }

    function setFacebookUserId(userId) {
        $ctrl.facebookUserId = userId;
    }
}
