export const EngagementEventGroups = {
    one2one: 1,
    email: 2,
    web: 3,
    service: 4,
    reviews: 5,
    text: 6,
    autoAssistant: 7,
    intelligentMarketing: 8,
    geoAlert: 9
};

export const EngagementEventTypes = {
    newServiceAppointment: 1,
    inService: 2,
    emailOpened: 3,
    emailReplied: 4,
    purlView: 5,
    purlLeadSubmission: 6,
    websiteEngaged: 7,
    websiteLeadSubmission: 8,
    onlineServiceReview: 9,
    onlineSalesReview: 10,
    textMessageReplied: 11,
    customerRequestedContact:12,
    purlViewIM: 13,
    purlLeadSubmissionIM: 14,
    activeGeoAlert: 15
}

export const EngagementEventGroupsByType = {
    [EngagementEventTypes.newServiceAppointment]: EngagementEventGroups.service,
    [EngagementEventTypes.inService]: EngagementEventGroups.service,
    [EngagementEventTypes.emailOpened]: EngagementEventGroups.email,
    [EngagementEventTypes.emailReplied]: EngagementEventGroups.email,
    [EngagementEventTypes.purlView]: EngagementEventGroups.one2one,
    [EngagementEventTypes.purlLeadSubmission]: EngagementEventGroups.one2one,
    [EngagementEventTypes.websiteEngaged]: EngagementEventGroups.web,
    [EngagementEventTypes.websiteLeadSubmission]: EngagementEventGroups.web,
    [EngagementEventTypes.onlineServiceReview]: EngagementEventGroups.reviews,
    [EngagementEventTypes.onlineSalesReview]: EngagementEventGroups.reviews,
    [EngagementEventTypes.textMessageReplied]: EngagementEventGroups.text,
    [EngagementEventTypes.customerRequestedContact]: EngagementEventGroups.autoAssistant,
    [EngagementEventTypes.purlViewIM]: EngagementEventGroups.intelligentMarketing,
    [EngagementEventTypes.purlLeadSubmissionIM]: EngagementEventGroups.intelligentMarketing,
    [EngagementEventTypes.activeGeoAlert]: EngagementEventGroups.geoAlert
};
