import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.sort";
opportunityService.$inject = ["$filter", "$q", "alertTypes", "conquestTypes", "contractService", "coreApi", "customerService", "dealSheetService", "dealerService", "discrepancyTypes", "mapUtils", "modules", "opportunityStatuses", "opportunityTypes", "userService", "vehicleService", "vehicleTypes", "fordFBATypes"];
import angular from 'angular';
angular.module('AaApp.Opp').factory('opportunityService', opportunityService);
/* @ngInject */

function opportunityService($filter, $q, alertTypes, conquestTypes, contractService, coreApi, customerService, dealSheetService, dealerService, discrepancyTypes, mapUtils, modules, opportunityStatuses, opportunityTypes, userService, vehicleService, vehicleTypes, fordFBATypes) {
  var service = {
    getOpportunity: getOpportunity,
    addAlertsAsProperties: addAlertsAsProperties,
    updateAssignedUser: updateAssignedUser,
    setAlertDetails: setAlertDetails,
    logTradeValueChange: logTradeValueChange,
    getOpportunitiesByCustomerId: getOpportunitiesByCustomerId,
    getOpportunitiesByVin: getOpportunitiesByVin,
    getCreditSoftPullResultAsync: getCreditSoftPullResultAsync,
    markOpportunityWatched: markOpportunityWatched,
    markOpportunityUnwatched: markOpportunityUnwatched,
    resetActivityNotification: resetActivityNotification,
    reopenOpportunity: reopenOpportunity,
    activityNotification: {
      triggered: false,
      notify: false
    },
    submitCreditPrescreenRequest: submitCreditPrescreenRequest,
    getCreditPrescreenStatus: getCreditPrescreenStatus,
    getTags: getTags,
    getChangeLogs: getChangeLogs,
    submitDiscrepancy: submitDiscrepancy,
    getTagsPivotForDealers: getTagsPivotForDealers,
    addTermOverrideChangeLog: addTermOverrideChangeLog,
    addPriceOverrideChangeLog: addPriceOverrideChangeLog,
    getPandoClaim: getPandoClaim,
    hasReviewProDealer: hasReviewProDealer,
    hasGeoAlertDealer: hasGeoAlertDealer,
    getOwnerUserInfo: getOwnerUserInfo,
    getOpportunityEntityOnly: getOpportunityEntityOnly,
    getAllSaleTypeLocalization: getAllSaleTypeLocalization,
    getSaleExtByEntityId: getSaleExtByEntityId,
    getOpportunityFromView: getOpportunityFromView,
    getOpportunityFromViewEntityId: getOpportunityFromViewEntityId,
    getVehicleTurnPredictor: getVehicleTurnPredictor,
    getActiveNLPNoLongerOwn: getActiveNLPNoLongerOwn,
    updateActiveNLPNoLongerOwn: updateActiveNLPNoLongerOwn
  };
  return service; // Temporary due to Euro-lite demo timeconstraint - Refactor in next release.

  function getAllSaleTypeLocalization() {
    return coreApi.api.Sale.GetAllSaleTypeLocalization.GET().then(function (response) {
      return response.data ? response.data : [];
    });
  }

  function getSaleExtByEntityId(entityId) {
    return coreApi.api.Sale.GetSaleExtByEntityId['{entityId}'].GET({
      entityId: entityId
    }).then(function (response) {
      return response.data;
    });
  }

  function getVehicleTurnPredictor(entityId) {
    return coreApi.api.Entity.GetVehicleTurnPredictor['{entityId}'].GET({
      entityId: entityId
    }).then(function (response) {
      return response.data;
    });
  }

  function getActiveNLPNoLongerOwn(entityId) {
    return coreApi.api.Entity.GetActiveNLPNoLongerOwn['{entityId}'].GET({
      entityId: entityId
    }).then(function (response) {
      //response.hasData = true;
      return response.data;
    });
  }

  function updateActiveNLPNoLongerOwn(entityId, agreed) {
    return coreApi.api.Entity.UpdateActiveNLPNoLongerOwn.POST({
      "entityId": entityId,
      "agreed": agreed
    });
  }

  function getOpportunity(opportunityId, stockNo, calculationType, dsPermissions, moduleIds) {
    return coreApi.api.Entity.GetExt['{entityId}'].GET({
      entityId: opportunityId
    }).then(resolveDealerSettings(stockNo, calculationType, dsPermissions));

    function resolveDealerSettings(stockNo, calculationType, dsPermissions) {
      return function resolveDealerSettingsClosure(response) {
        return dealerService.getDealerSettings(response.data.dealerID).then(resolveOpportunity(stockNo, response, dsPermissions));
      };
    }

    function resolveOpportunity(stockNo, response, dsPermissions) {
      return function resolveOpportunityClosure(settings) {
        if (settings.allowExpiration && isExpired(response.data.expirationDate)) {
          throw new Error("Deal sheet has expired");
        }

        var status;

        if (response.data.scheduled) {
          status = opportunityStatuses.scheduled;
        } else if (response.data.isMarkedAsSold) {
          status = opportunityStatuses.sold;
        } else if (response.data.closed) {
          status = opportunityStatuses.closed;
        } else {
          status = opportunityStatuses.open;
        }

        var opportunity = {
          id: response.data.entityID,
          actionAccess: response.data.actionAccess,
          keyId: response.data.entityKeyID,
          dealerId: response.data.dealerID,
          vehicleId: response.data.vehicleID,
          customerId: response.data.customerID,
          type: response.data.entityTypeID,
          hasExtendedWarranty: response.data.hasWarranty,
          status: status,
          stillOwned: response.data.stillOwn == null ? true : response.data.stillOwn,
          paidOff: response.data.paidOff,
          watched: response.data.watched,
          assignedUserId: response.data.ownerUserID,
          isMarkedAsSold: response.data.isMarkedAsSold,
          expirationDate: response.data.expirationDate,
          entityScore: response.data.entityScore,
          autoAssignAccessUserName: response.data.autoAssignAccessUserName,
          autoAssignOwner: response.data.autoAssignOwner,
          autoAssignAccessUser: response.data.autoAssignAccessUser,
          autoAssignAccessSearch: response.data.autoAssignAccessSearch,
          autoAssignAccessDate: response.data.autoAssignAccessDate,
          autoAssignOwnerDate: response.data.autoAssignOwnerDate,
          autoAssignOwnerSearch: response.data.autoAssignOwnerSearch,
          dealerSettings: settings,
          actionChecked: response.data.actionChecked,
          onOrder: response.data.onOrder == null ? false : response.data.onOrder
        };

        if (service.activityNotification.triggered === false) {
          service.activityNotification.triggered = true;
          coreApi.api.Activity.GetNewActivityNotifications['{entityId}'].GET({
            entityId: opportunity.id
          }).then(resolveActivityNotification);
        }

        getNonEssentialOpportunityData(opportunity, dsPermissions, moduleIds);
        if (dsPermissions && dsPermissions.viewCustomer) return customerService.getCustomer(opportunity.id).then(resturnResults);else return customerService.getCustomerName(opportunity.id).then(resturnResults);

        function isExpired(dealSheetExpirationDate) {
          var todayDate = new Date().setHours(0, 0, 0, 0);
          var expirationDate = new Date(dealSheetExpirationDate).setHours(0, 0, 0, 0);
          return todayDate > expirationDate;
        }

        function resturnResults(customer) {
          return $q.all({
            'customer': customer,
            'currentVehicle': vehicleService.getCurrentVehicle(opportunity.vehicleId),
            'currentContract': contractService.getCurrentContract(opportunity.id, opportunity.type),
            'replacementVehicles': vehicleService.getReplacementVehicles(opportunity.id, stockNo, customer.pricingPlanId),
            'tags': getTags(opportunity.id),
            'fullName': coreApi.api.User.FullNameByEntity['{entityId}'].GET({
              entityId: opportunity.id
            })
          }).then(resolveOpportunityDetails(opportunity, dsPermissions));
        }
      };
    }

    function getNonEssentialOpportunityData(opportunity, dsPermissions, moduleIds) {
      // WEBUI-7833 Non-essential api calls are made here (moved out of $q.all):
      coreApi.api.User.GetEntityUserInfo['{entityId}'].GET({
        entityId: opportunity.id
      }).then(function (data) {
        opportunity.ownerUserInfo = data.data;
      });
      getCreditSoftPullResultAsync(dsPermissions, opportunity.id).then(function (data) {
        opportunity.creditSoftPull = data;
      });
      getCreditPrescreenStatus(opportunity.id, moduleIds).then(function (data) {
        opportunity.creditPrescreenStatus = data;
      });
      var isFordAlertEnabled = userService.isDealerEnabledForModule(opportunity.dealerId, modules.fordAlert);

      if (isFordAlertEnabled) {
        getCreditPreApprovalAsync(opportunity.id).then(function (data) {
          opportunity.creditPreApproval = data;
        });
      } else {
        opportunity.creditPreApproval = null;
      }

      getPandoClaim(opportunity.id).then(function (data) {
        opportunity.pandoClaim = data;
      });
    }

    function resolveActivityNotification(response) {
      if (response.data && service.activityNotification) {
        service.activityNotification.notify = response.data.notification;
      }
    }

    function resolveOpportunityDetails(opportunity, permissions) {
      return function resolveOpportunityDetailsClosure(data) {
        opportunity.currentContract = data.currentContract;
        opportunity.currentVehicle = data.currentVehicle;
        opportunity.currentVehicle.type = opportunity.currentContract.vehicleType;
        opportunity.currentContract.hasExtendedWarranty = opportunity.hasExtendedWarranty;
        opportunity.currentContract.paidOff = opportunity.paidOff;

        if (opportunity.currentContract.paidOff) {
          opportunity.currentContract.paymentsRemaining = 0;
          opportunity.currentContract.payoffAmount = 0;
        }

        opportunity.replacementVehicles = data.replacementVehicles;
        opportunity.replacementVehicle = opportunity.replacementVehicles.standardVehicle;
        opportunity.customer = data.customer;
        opportunity.tags = data.tags;
        opportunity.assignedUserFullName = data.fullName.data;
        coreApi.api.Entity.MarkAsRead.POST("'" + opportunity.id + "'");

        if (opportunity.type == opportunityTypes.conquest) {
          opportunity.currentContract.conquestType = isCreditConvertOpportunity(opportunity) ? conquestTypes.creditConvert : conquestTypes.notConverted;
        } else if (opportunity.type == opportunityTypes.converted) {
          opportunity.currentContract.conquestType = conquestTypes.converted;
        }

        var tradeValue = opportunity.stillOwned === null || opportunity.stillOwned === true ? opportunity.currentVehicle.tradeValue : 0;
        var equityValue = opportunity.currentContract.payoffAmount ? opportunity.currentContract.payoffAmount : 0;

        function Equity() {
          this.tradeValue = tradeValue, this.equityValue = this.tradeValue - equityValue;
        }

        opportunity.currentContract.equity = new Equity();
        return coreApi.api.EntityOpportunity.GetOpportunitiesForEntity['{entityId}'].GET({
          entityId: opportunityId
        }).then(resolveAlerts).then(resolveOpportunityAlerts(opportunity)).then(function () {
          return contractService.getReplacementContracts(opportunity, permissions).then(resolveOpportunityReplacementContracts(opportunity));
        });

        function resolveAlerts(response) {
          var alerts = [];

          for (var i = 0; i < response.data.length; i++) {
            var alert = response.data[i];
            alerts.push({
              type: alert.alertType,
              typeName: getAlertTypeName(alert.alertType)
            });
          }

          return alerts.sort(byType);
        }

        function resolveOpportunityAlerts(opportunity) {
          return function resolveOpportunityAlertsClosure(alerts) {
            opportunity.alerts = alerts;
            return opportunity;
          };
        }
      };
    }
  }

  function getOpportunityEntityOnly(opportunityId) {
    return coreApi.api.Entity.GetExt['{entityId}'].GET({
      entityId: opportunityId
    }).then(resolveOpportunityEntityOnly);

    function resolveOpportunityEntityOnly(response) {
      var status;

      if (response.data.scheduled) {
        status = opportunityStatuses.scheduled;
      } else if (response.data.isMarkedAsSold) {
        status = opportunityStatuses.sold;
      } else if (response.data.closed) {
        status = opportunityStatuses.closed;
      } else {
        status = opportunityStatuses.open;
      }

      var opportunity = {
        id: response.data.entityID,
        actionAccess: response.data.actionAccess,
        keyId: response.data.entityKeyID,
        dealerId: response.data.dealerID,
        vehicleId: response.data.vehicleID,
        customerId: response.data.customerID,
        type: response.data.entityTypeID,
        hasExtendedWarranty: response.data.hasWarranty,
        status: status,
        stillOwned: response.data.stillOwn == null ? true : response.data.stillOwn,
        paidOff: response.data.paidOff,
        watched: response.data.watched,
        assignedUserId: response.data.ownerUserID,
        isMarkedAsSold: response.data.isMarkedAsSold,
        expirationDate: response.data.expirationDate,
        entityScore: response.data.entityScore,
        actionChecked: response.data.actionChecked
      };
      return opportunity;
    }
  }

  function getTags(opportunityId) {
    return coreApi.api.Tag.GetTagsForEntity['{entityId}'].GET({
      entityId: opportunityId
    }).then(mapTags);

    function mapTags(response) {
      var tags = [];

      for (var i = 0; i < response.data.length; i++) {
        var tag = response.data[i];
        tags.push({
          text: tag.tagText,
          expirationDate: tag.tagExpireDate
        });
      }

      return tags;
    }
  }

  function getTagsPivotForDealers(selectedDealerId, flushCache) {
    return coreApi.api.Tag.GetTagsPivotForDealers.GET({
      flushCache: flushCache
    }, {
      overrideDealerIds: [selectedDealerId]
    }).then(function (data) {
      return data.data;
    });
  }

  function resetActivityNotification() {
    if (service.activityNotification) {
      service.activityNotification.triggered = false;
      service.activityNotification.notify = false;
    }
  }

  function getOpportunitiesByCustomerId(customerId) {
    return coreApi.api.Entity.GetEntityReportByCustomerId['{customerId}'].GET({
      customerId: customerId
    }) // /api/Entity/GetEntityReportByCustomerId/{customerId}
    .then(mapOpportunities);

    function mapOpportunities(response) {
      var opportunities = [];

      for (var i = 0; i < response.data.length; i++) {
        var opportunity = response.data[i];

        if (opportunity) {
          opportunities.push({
            id: opportunity.entityID,
            type: opportunity.entityTypeID,
            alerts: opportunity.alerts,
            stillOwned: opportunity.stillOwn == null ? true : opportunity.stillOwn,
            assignedUserFullName: opportunity.ownerFullName,
            status: opportunity.closed,
            opportunityRank: opportunity.opportunityRank,
            entityScore: opportunity.entityScore,
            currentVehicle: {
              type: opportunity.soldAsNew ? vehicleTypes.new : vehicleTypes.used,
              makeName: opportunity.makeName,
              yearID: opportunity.yearID == 0 ? '' : opportunity.yearID,
              modelName: opportunity.modelName,
              trimName: opportunity.trimName,
              trimFullName: mapUtils.getVehicleTrimFullName(opportunity),
              lastServiceDate: opportunity.lastServiceDate,
              vin: opportunity.vin
            },
            currentContract: {
              saleType: opportunity.saleTypeID,
              startDate: opportunity.dealDate,
              soldByFullName: opportunity.soldByFullName
            }
          });
        }
      }

      return opportunities;
    }
  }

  function getOpportunitiesByVin(dealerId, vin) {
    return coreApi.api.Entity.GetEntityReportByDealerIdAndVin['{dealerId}']['{vin}'].GET({
      dealerId: dealerId,
      vin: vin
    }).then(mapOpportunitiesByVin);

    function mapOpportunitiesByVin(response) {
      var data = response.data;
      var opportunities = [];

      for (var i = 0; i < data.length; i++) {
        opportunities.push({
          id: data[i].entityID,
          customerFullName: data[i].customerFullName,
          alerts: data[i].alerts
        });
      }

      return opportunities;
    }
  }

  function getOwnerUserInfo(opportunityId) {
    return coreApi.api.User.GetEntityUserInfo['{entityId}'].GET({
      entityId: opportunityId
    });
  }

  function addAlertsAsProperties(target, alerts) {
    clearAlerts(target);

    for (var i = 0; i < alerts.length; i++) {
      var alert = alerts[i];

      for (var alertType in alertTypes) {
        if (alertTypes.hasOwnProperty(alertType)) {
          if (alert.type == alertTypes[alertType]) {
            if (alert.typeName.toLowerCase().indexOf('alert') !== -1) {
              target[alert.typeName + 'Exists'] = true;
            } else {
              target[alert.typeName + 'AlertExists'] = true;
            }
          }
        }
      }
    }
  }

  function updateAssignedUser(opportunityId, userId) {
    return coreApi.api.Entity.ReassignToUserId.POST({
      "entityId": opportunityId,
      "assignToUserId": userId
    });
  }

  function markOpportunityWatched(opportunityId) {
    return coreApi.api.Entity.Watch['{entityId}'].POST({}, {
      entityId: opportunityId
    });
  }

  function markOpportunityUnwatched(opportunityId) {
    return coreApi.api.Entity.Unwatch['{entityId}'].POST({}, {
      entityId: opportunityId
    });
  }

  function reopenOpportunity(opportunityId) {
    return coreApi.api.Entity.Reopen['{entityId}'].POST({}, {
      entityId: opportunityId
    });
  }

  function submitCreditPrescreenRequest(opportunityId, permissions, moduleIds, dealerSettings) {
    if (permissions.requestCreditPrescreen && moduleIds.indexOf(modules.creditConvert) > -1 && dealerSettings.useCreditSoftPull) {
      return coreApi.api.Entity.CreditPrescreenRequest['{entityId}'].POST({}, {
        entityId: opportunityId
      });
    } else {
      var deferred = $q.defer();
      deferred.resolve(false);
      return deferred.promise;
    }
  }

  function getCreditPrescreenStatus(opportunityId, moduleIds) {
    if (moduleIds.indexOf(modules.creditConvert) > -1) {
      return coreApi.api.Entity.CreditPrescreenStatus['{entityId}'].GET({
        entityId: opportunityId
      }).then(resolveData, function () {});
    } else {
      return $q.resolve(null);
    }
  }

  function resolveData(response) {
    return response.data != '' ? response.data : null;
  }

  function getCreditSoftPullResultAsync(permissions, opportunityId) {
    if (permissions && permissions.requestCreditPrescreen) {
      return coreApi.api.CreditSoftPullResultEntityDel['{entityId}'].GET({
        entityId: opportunityId
      }).then(function (result) {
        return result.data;
      }, function () {});
    } else {
      return $q.resolve(undefined);
    }
  }

  function getCreditPreApprovalAsync(opportunityId) {
    return coreApi.api.FordCreditApproval.FordCreditApprovalByEntityId['{entityId}'].GET({
      entityId: opportunityId
    }).then(function (result) {
      return result.data;
    }, function () {});
  }

  function setAlertDetails(alerts, currentVehicle, currentContract, replacementVehicle, replacementContract) {
    for (var i = 0; i < alerts.length; i++) {
      var alert = alerts[i];

      switch (alert.type) {
        case alertTypes.alert:
        case alertTypes.flexAlert:
          setFlexAlertDetails(alert, replacementVehicle, replacementContract);
          break;

        case alertTypes.service:
          setServiceAlertDetails(alert, currentVehicle, currentContract);
          break;

        case alertTypes.appointment:
          setAppointmentAlertDetails(alert, currentVehicle);
          break;

        case alertTypes.contractEnd:
          setContractEndAlertDetails(alert, currentContract);
          break;

        case alertTypes.mileage:
          setMileageAlertDetails(alert, currentContract);
          break;

        case alertTypes.warranty:
          setWarrantyAlertDetails(alert, currentVehicle, currentContract);
          break;

        default:
          break;
      }

      alert.translateKey = getAlertTranslateKey(alert);
    }
  }

  function getAlertTranslateKey(alert) {
    if (alert.details && !alert.details.payment && (alert.type == alertTypes.alert || alert.type == alertTypes.flexAlert == alertTypes.flexAlert)) {
      return alert.typeName + 'RelevantInfoNoPayment';
    }

    return alert.typeName + 'RelevantInfo';
  }

  function isCreditConvertOpportunity(opportunity) {
    return containsTag(opportunity, 'CreditConvert');
  }

  function containsTag(opportunity, tagText) {
    if (opportunity && opportunity.tags) {
      return opportunity.tags.some(function (tag) {
        return tag.text === tagText;
      });
    }
  }

  function resolveOpportunityReplacementContracts(opportunity) {
    return function resolveOpportunityReplacementContractsClosure(data) {
      opportunity.replacementContracts = data.contracts;
      opportunity.replacementContract = data.defaultContract;

      if (opportunity.replacementContract) {
        opportunity.replacementContract.vehicleType = opportunity.replacementVehicle.type;
      }

      setAlertDetails(opportunity.alerts, opportunity.currentVehicle, opportunity.currentContract, opportunity.replacementVehicle, opportunity.replacementContract);
      return opportunity;
    };
  }

  function byType(alert1, alert2) {
    if (alert1.type > alert2.type) {
      return 1;
    } else if (alert1.type < alert2.type) {
      return -1;
    } else {
      return 0;
    }
  }

  function setFlexAlertDetails(alert, vehicle, contract) {
    alert.details = {
      yearID: vehicle.yearID,
      makeName: vehicle.makeName,
      modelName: vehicle.modelName,
      trimName: vehicle.trimName,
      contractTerm: contract ? contract.term : null,
      payment: contract ? contract.payment : null,
      paymentDifferenceAmount: contract ? contract.paymentDifference : null
    };
  }

  function setServiceAlertDetails(alert, vehicle) {
    alert.details = {
      openDate: vehicle.openDate,
      eighteenMonthServiceCount: vehicle.eighteenMonthServiceCount
    };
  }

  function setAppointmentAlertDetails(alert, vehicle) {
    alert.details = {
      appointmentDate: vehicle.appointmentDate
    };
  }

  function setContractEndAlertDetails(alert, contract) {
    alert.details = {
      paymentsMade: contract.paymentsMade,
      paymentsRemaining: contract.paymentsRemaining
    };
  }

  function setMileageAlertDetails(alert, contract) {
    alert.details = {
      estimatedMaturityMileage: contract.estimatedMaturityMileage,
      currentMileagePenalty: contract.currentMileagePenalty,
      estimatedMileagePenalty: contract.estimatedMileagePenalty
    };
  }

  function setWarrantyAlertDetails(alert, vehicle, contract) {
    alert.details = {
      warrantyMonthsRemaining: vehicle.warrantyMonthsRemaining ? vehicle.warrantyMonthsRemaining : contract.warrantyMonthsRemaining,
      warrantyMilesRemaining: vehicle.warrantyMilesRemaining ? vehicle.warrantyMilesRemaining : contract.warrantyMilesRemaining
    };
  }

  function getAlertTypeName(alertType) {
    switch (alertType) {
      case alertTypes.alert:
        return 'alert';

      case alertTypes.flexAlert:
        return 'flexAlert';

      case alertTypes.inMarket:
        return 'inMarket';

      case alertTypes.inMarketEngaged:
        return 'inMarketEngaged';

      case alertTypes.contractEnd:
        return 'contractEnd';

      case alertTypes.service:
        return 'service';

      case alertTypes.appointment:
        return 'appointment';

      case alertTypes.mileage:
        return 'mileage';

      case alertTypes.warranty:
        return 'warranty';

      default:
        return '';
    }
  }

  function clearAlerts(target) {
    target.alertExists = null;
    target.flexAlertExists = null;
    target.serviceAlertExists = null;
    target.appointmentAlertExists = null;
    target.mileageAlertExists = null;
    target.contractEndAlertExists = null;
    target.warrantyAlertExists = null;
  }

  function logTradeValueChange(vehicleId, tradeValue) {
    return coreApi.api.VehicleFinalTradeValueTempChange.LogTradeValueChange.POST({
      VehicleID: vehicleId,
      TradeValue: tradeValue
    });
  }

  function getChangeLogs(opportunityId) {
    return coreApi.api.ChangeLog['{entityId}'].GET({
      entityId: opportunityId
    }).then(resolveGetChangeLogs);

    function resolveGetChangeLogs(response) {
      if (!response || !response.data) {
        return null;
      }

      return response.data;
    }
  }

  function submitDiscrepancy(opportunity, discrepancy) {
    var request = {
      entityId: opportunity.id,
      reportableFieldId: discrepancy.type,
      reportableExpectedValue: discrepancy.expectedValue,
      dealerId: opportunity.dealerId,
      dealerName: opportunity.dealer.name,
      customerFullName: opportunity.customer.fullName,
      currentVehicleVin: opportunity.currentVehicle.vin,
      reportDiscrepancyVehicle: {
        yearID: opportunity.replacementVehicle.yearID,
        fullTrimName: opportunity.replacementVehicle.makeName + " " + opportunity.replacementVehicle.trimFullName,
        localePrice: $filter('localeCurrency')(opportunity.replacementVehicle.price)
      },
      dealSheetUrl: dealSheetService.getDealSheetUrl(opportunity.id)
    };

    if (discrepancy.multiple) {
      request.ReportableCurrentValues = discrepancy.currentValue;
    } else {
      if (discrepancy.type == discrepancyTypes.tradeValue || discrepancy.type == discrepancyTypes.payoffAmount) {
        request.reportableCurrentValue = $filter("localeCurrency")(discrepancy.currentValue);
      } else {
        request.reportableCurrentValue = discrepancy.currentValue;
      }
    }

    return coreApi.api.DiscrepancyReport.ReportDiscrepancy.POST(request);
  }

  function addTermOverrideChangeLog(vehicleId, term) {
    return coreApi.api.ChangeLog.Term['{vehicleId}']['{term}'].POST({}, {
      vehicleId: vehicleId,
      term: term
    });
  }

  function addPriceOverrideChangeLog(vehicleId, price) {
    return coreApi.api.ChangeLog.Price['{vehicleId}']['{price}'].POST({}, {
      vehicleId: vehicleId,
      price: price
    });
  }

  function getPandoClaim(entityId) {
    return coreApi.api.PandoClaim.ByEntity['{entityId}'].GET({
      entityId: entityId
    }).then(function (results) {
      return results.data;
    });
  }

  function hasReviewProDealer(entityId) {
    return coreApi.api.Entity.HasReviewProDealer['{entityId}'].GET({
      entityId: entityId
    }).then(function (results) {
      return results.data;
    }, function () {
      return false;
    });
  }

  function hasGeoAlertDealer(entityId) {
    return coreApi.api.Entity.HasGeoAlertDealer['{entityId}'].GET({
      entityId: entityId
    }).then(function (results) {
      return results.data;
    }, function () {
      return false;
    });
  }

  function showIfNotNullOrEmpty(defaultValue, item, obj) {
    if (item != null && item != "") {
      if (obj != null) return obj;
      return item;
    }

    return defaultValue;
  }

  function toYesNoTypeID(yesNoTypeName) {
    if (yesNoTypeName == null) return 0;
    yesNoTypeName = yesNoTypeName.toString().toLowerCase();
    if (yesNoTypeName == "yes") return 1;
    if (yesNoTypeName == "no") return 0;
    return 0;
  }

  function toBoolean(yesNoTypeName) {
    if (yesNoTypeName == null) return false;
    yesNoTypeName = yesNoTypeName.toString().toLowerCase();
    if (yesNoTypeName == "yes") return true;
    if (yesNoTypeName == "no") return false;
    return false;
  }

  function nullIf(valueIfNull, defaultValue) {
    if (valueIfNull) return null;
    return defaultValue;
  }

  function defaultValue(value, defaultValue) {
    if (value == null) return defaultValue;
    return value;
  }

  function toSaleTypeID(saleType) {
    if (saleType == null) return "";
    saleType = saleType.toString().toLowerCase();
    if (saleType == "lease") return 1;
    if (saleType == "retail") return 2;
    if (saleType == "balloon") return 3;
    if (saleType == "cash") return 4;
    if (saleType == "onepay lease") return 5;
    if (saleType == "wholesale") return 6;
    if (saleType == "incomplete") return 7;
    return "";
  }

  function NewUsedCpoToID(vehicleType) {
    if (vehicleType == null) return "";
    vehicleType = vehicleType.toString().toLowerCase();
    if (vehicleType == "new") return 0;
    if (vehicleType == "used") return 1;
    if (vehicleType == "cpo") return 2;
    return "";
  }

  function toAlertTypeList(vw_search, replacementVehicle) {
    replacementVehicle.payment = vw_search.replacement_Payment;
    var alerts = [];
    if (vw_search.is_Alert == "Yes") pushAlert(alerts, alertTypes.alert, 'alert', replacementVehicle);
    if (vw_search.is_Appointment == "Yes") pushAlert(alerts, alertTypes.appointment, 'appointment', replacementVehicle);
    if (vw_search.is_Contract_End == "Yes") pushAlert(alerts, alertTypes.contractEnd, 'contractEnd', replacementVehicle); //if (vw_search.is_Conversion_Alert == "Yes") pushAlert(alerts, alertTypes.alert, 'alert');

    if (vw_search.is_Flex_Alert == "Yes") pushAlert(alerts, alertTypes.flexAlert, 'flexAlert', replacementVehicle);
    if (vw_search.is_In_Market == "Yes") pushAlert(alerts, alertTypes.inMarket, 'inMarket', replacementVehicle);
    if (vw_search.is_In_Market_Engaged == "Yes") pushAlert(alerts, alertTypes.inMarketEngaged, 'inMarketEngaged', replacementVehicle);
    if (vw_search.is_Mileage_Alert == "Yes") pushAlert(alerts, alertTypes.mileage, 'mileage', replacementVehicle);
    if (vw_search.is_Warranty_Alert == "Yes") pushAlert(alerts, alertTypes.warranty, 'warranty', replacementVehicle);
    return alerts;
  }

  function pushAlert(arr, type, name, replacementVehicle) {
    arr.push({
      type: type,
      typeName: name,
      translateKey: name + "RelevantInfo",
      details: replacementVehicle //terrible.  needs refactor.

    });
  }

  function getOpportunityFromViewEntityId(entityId, vw_SearchRow) {
    var deferred = $q.defer();
    var promise = deferred.promise;

    if (vw_SearchRow == null || vw_SearchRow["_EntityID"] == null) {
      entityId = vw_SearchRow == null ? entityId : vw_SearchRow.opportunityId;
      coreApi.api.Search.GetSearchByEntityId['{entityId}'].GET({
        entityId: entityId
      }).then(function (data) {
        deferred.resolve(data.data);
      });
      return promise;
    } else {
      return $q.resolve(vw_SearchRow);
    }
  }

  function getOpportunityFromView(vw_SearchRow) {
    if (vw_SearchRow == null) {
      return;
    }

    var opportunity = {
      id: vw_SearchRow._EntityID,
      vehicleId: vw_SearchRow._VehicleID,
      type: vw_SearchRow._EntityTypeID,
      stillOwned: toBoolean(vw_SearchRow.still_Own),
      paidOff: toBoolean(vw_SearchRow.paid_Off),
      dealerId: vw_SearchRow._DealerID,
      customer: {
        fullName: vw_SearchRow.full_Name,
        address: {
          streetAddress: vw_SearchRow.address,
          streetAddress2: vw_SearchRow.address_2,
          stateId: vw_SearchRow._StateID,
          normalizedZipCode: vw_SearchRow.zip_Code,
          zipCode: vw_SearchRow.zip_Code,
          city: vw_SearchRow.city,
          stateName: vw_SearchRow.state
        },
        pricingPlanId: null,
        //disables UseEmployeePricing
        alternateContacts: [],
        cellPhoneNumber: showIfNotNullOrEmpty(null, vw_SearchRow.cell_Phone, {
          "contactType": 4,
          "value": vw_SearchRow.cell_Phone,
          "isBad": false,
          "isPreferred": true,
          "doNotCallStatusTypeId": 4
        }),
        "companyName": "",
        "doNotCall": toBoolean(vw_SearchRow.do_Not_Call),
        "doNotEmail": toBoolean(vw_SearchRow.do_Not_Email),
        "doNotMail": toBoolean(vw_SearchRow.do_Not_Mail),
        "emailAddress": showIfNotNullOrEmpty(null, vw_SearchRow.email, {
          "contactType": 5,
          "value": vw_SearchRow.email,
          "isBad": vw_SearchRow.email != "",
          "isPreferred": vw_SearchRow.email != ""
        }),
        "firstName": vw_SearchRow.first_Name,
        "homePhoneNumber": showIfNotNullOrEmpty(null, vw_SearchRow.home_Phone, {
          "contactType": 2,
          "value": vw_SearchRow.home_Phone,
          "isBad": false,
          "isPreferred": true,
          "doNotCallStatusTypeId": 4
        }),
        "id": vw_SearchRow._CustomerID,
        "invalidContacts": [],
        "isAddressNotValid": false,
        "isFullCustomer": true,
        "lastName": vw_SearchRow.last_Name,
        "middleName": vw_SearchRow.middle_Name,
        "workPhoneNumber": showIfNotNullOrEmpty(null, vw_SearchRow.work_Phone, {
          "contactType": 3,
          "value": vw_SearchRow.work_Phone,
          "isBad": false,
          "isPreferred": true,
          "doNotCallStatusTypeId": 4
        })
      },
      //"dealer": {
      //    "name": "MB Demo",
      //    "displayText": "MB Demo (MBDEMO)"
      //},
      entityScore: vw_SearchRow._Opportunity_Rank,
      assignedUserId: vw_SearchRow._OwnerUserID,
      assignedUserFullName: vw_SearchRow._Customer_Owner,
      //alerts: toAlertTypeList(vw_SearchRow),
      permissions: {
        viewContract: true
      },
      currentContract: {
        adjustedBalance: vw_SearchRow.estimated_Payoff - vw_SearchRow.totalPenalty,
        saleType: vw_SearchRow._SaleTypeID,
        moneyFactor: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.rate : null,
        payment: vw_SearchRow.current_Payment,
        //1790,
        soldByName: vw_SearchRow.sold_By_Name,
        //"THOMAS CARNEVALE"
        vehicleType: NewUsedCpoToID(vw_SearchRow.new_Used),
        paymentsMade: vw_SearchRow.payments_Made,
        //41,
        paymentsRemaining: vw_SearchRow.payment_Remaining,
        //7,
        //"cashPaid": null,
        allowedMileage: vw_SearchRow.lease_Total_Allowed_Miles,
        //48000, //N/A
        //"estimatedMaturityMileage": 51700,
        //"currentMileagePenalty": 0,
        //"estimatedMileagePenalty": 900,
        //"preferredEquity": 5350,
        //"balanceToMaturity": 13125,
        //"dispositionFee": 595,
        entityID: vw_SearchRow._EntityID,
        //"hasWarranty": false,
        bankName: vw_SearchRow.bank_Name,
        //"bankUrl": "https://new.mb-advantage.com",
        //"isCreditConvert": true,
        //"paymentMinimum": 1700,
        //"paymentMaximum": 1750,
        payoffAmount: vw_SearchRow.estimated_Payoff,
        //57550,
        //"payoffGoodThroughDate": null,
        lastTouchedDate: vw_SearchRow.last_Payment_Date,
        //How Necessary is this?  Required by <customer-contact>
        saleID: vw_SearchRow._SaleID,
        //60924823,
        //"insertDate": "2015-04-25T01:25:57.84Z",
        //"dealerID": 1167,
        //"customerID": 38827071,
        //"vehicleID": 89569084,
        //"soldAsNew": true,
        //"mileageAtDelivery": 8,
        //"bankID": 48,
        dmsDealId: vw_SearchRow.dmS_Deal_ID,
        //"soldByID": 314698,
        term: vw_SearchRow.deal_Term,
        dealDate: vw_SearchRow.deal_Date,
        //"firstPaymentDate": "2015-04-18T00:00:00",
        lastPaymentDate: vw_SearchRow.last_Payment_Date,
        //"2019-04-18T00:00:00",
        //"leaseBasePayment": 1646,
        //"leaseTotalPayment": 1790,
        capitalCost: vw_SearchRow.lease_Net_Cap_Cost,
        residualAmount: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.lease_Residual : null,
        //45807,
        //"leaseAnnualMiles": null,
        //"retailPayment": 0,
        financedAmount: vw_SearchRow.purchase_Amount_Financed,
        //"balloonPayment": null,
        cashDown: vw_SearchRow.cash_Down,
        //1495,
        apr: vw_SearchRow._SaleTypeID == 1 ? null : vw_SearchRow.rate,
        //  null, 
        //"isNative": true,
        //"boughtExtWarranty": false,
        //"hasExtendedWarranty": false,
        paidOff: toYesNoTypeID(vw_SearchRow.paid_Off),
        //false
        //"equity": {
        //  "tradeValue": 10, // 62900,
        //  "equityValue": 5350
        //}
        financedOrCapCost: vw_SearchRow._SaleTypeID == 1 ? vw_SearchRow.lease_Net_Cap_Cost : vw_SearchRow.purchase_Amount_Financed
      },
      currentVehicle: {
        openDate: vw_SearchRow.openDate,
        tradeValue: vw_SearchRow.trade_Value,
        trimID: vw_SearchRow._TrimID,
        yearID: vw_SearchRow._YearID,
        makeID: vw_SearchRow._MakeID,
        //imagePath: "https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
        //"imagePath": "../../../images/Capture1.png",
        //imagePath: oneImage,
        type: NewUsedCpoToID(vw_SearchRow.new_Used),
        //pricingPlanMsrp: vw_SearchRow.
        //"addModelToTrim": false,
        //"appointmentDate": "2018-05-04T12:00:00",
        //"dealerID": 1167,
        //"displayCodes": false,
        //"eighteenMonthServiceCount": 3,
        //"estimatedCurrentMileage": 44200,
        //"estimatedMilesPerMonth": 1140,
        //"extColorID": null,
        //"exteriorColor": "DESIGNO GRAPHITE",
        //"intColorID": null,
        //"interiorColor": null,
        //"lastOdometerReadingDate": "2018-05-04T15:24:00",
        "makeName": vw_SearchRow.current_Make_Name,
        //"mfcModelCode": null,
        "modelName": vw_SearchRow.current_Model_Name,
        //"modelNo": "E63SP",
        //"odometer": 38750,
        //"replacementTrimID": 422662,
        //"tradeMileageAdjustment": -2400,
        //"tradeSourceID": 1,
        "trimFullName": defaultValue(vw_SearchRow.current_Model_Name, "") + ' ' + defaultValue(vw_SearchRow.current_Trim_Name, ""),
        "trimName": defaultValue(vw_SearchRow.current_Trim_Name, ""),
        //"vehicleID": 89569084,
        //"viN8Key": "WDDHH7GB_F",
        "vin": vw_SearchRow.vin //"vrn": null,
        //"vrnLastUpdateDate": "2016-06-27T14:45:00",
        //"warrantyMilesRemaining": 5800,
        //"warrantyMonthsRemaining": 7

      },
      replacementVehicle: {
        paymentDifferenceAmount: vw_SearchRow.replacement_Payment - vw_SearchRow.current_Payment,
        type: NewUsedCpoToID(vw_SearchRow.replacement_New_Used),
        //0,
        stockNo: vw_SearchRow.replacement_Stock_No,
        //null,
        //"odometer": 0,
        //"vin": null,
        msrp: vw_SearchRow.replacement_MSRP,
        price: vw_SearchRow.replacement_MSRP,
        //is not part of vw_search, MSRP is part of vw_search.
        trimID: vw_SearchRow.replacementTrimID,
        //"daysInStock": null,
        //"interiorColor": null,
        //"exteriorColor": null,
        //"countryID": 4,
        //"countryName": "France",
        makeID: vw_SearchRow.replacementMakeID,
        makeName: vw_SearchRow.replacement_Make_Name,
        //"Mercedes-Benz",
        //modelID: vw_SearchRow.replacementModelID, //replacementModelID needs to be added to vw_search
        modelName: vw_SearchRow.replacement_Model_Name,
        yearID: vw_SearchRow.replacement_Model_Year,
        //2018,
        trimName: vw_SearchRow.replacement_Trim_Name,
        //"rawTrimName": null,
        //"mfcModelCode": "E43W4",
        //"extID": "7778055",
        //"extOptionId": "0",
        //"extFuelTypeId": 1059,
        //"cityMPG": 19,
        //"cityMPGe": null,
        //"highwayMPG": 25,
        //"highwayMPGe": null,
        //"combinedMPG": 21,
        //"batteryRange": null,
        trimFullName: vw_SearchRow.replacement_Full_Trim_Name //"E 43 AMG",
        //"imagePath": vw_SearchRow.imagePath, //"https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
        //"imagePath": "https://media.chromedata.com/MediaGallery/media/Mjk4MTk1Xk1lZGlhIEdhbGxlcnk/Sn49u8QXXzwYZTjrZnCR0T8Wpzp-bEBtPwoDyPdoT4Zo-WVTyU0mgsvV07a55ZhB/2018MBCBN0001_320_07.png",
        //imagePath: twoImage,
        //"vehicleID": 89569084,
        //"oldMakeID": 51,
        //"oldTrimID": 389466,
        //"newMakeID": 51,
        //"newTrimID": 422648,
        //"isValid": 1,
        //"isConquest": 0,
        //"newVehiclePrice": 72595

      },
      replacementContract: {
        keyID: vw_SearchRow._EntityKeyID,
        saleType: toSaleTypeID(vw_SearchRow.replacement_Sale_Type),
        // 1,
        //"saleTypeName": "Lease",
        //"bankID": 48,
        //"bankName": "Mercedes-Benz Financial",
        term: vw_SearchRow.replacement_Term,
        //"termBeforeImprovement": null,
        apr: toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 2 ? vw_SearchRow.replacement_Rate : null,
        //null,
        moneyFactor: toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 1 ? vw_SearchRow.replacement_Rate : null,
        //0.00386,
        //"standardRebate": 0,
        //"loyaltyRebate": 0,
        //"conquestRebate": 0,
        //"cashOrAprRebate": 1000,
        //"cashOrApr": true,
        //"isValueAdded": false,
        //"pullForwardAmount": 5370,
        //"specialLoyaltyValue": 0,
        isOverAllowance: toYesNoTypeID(vw_SearchRow.is_Over_Allowance),
        //false,
        financedAmount: nullIf(toSaleTypeID(vw_SearchRow.replacement_Sale_Type) == 1, vw_SearchRow.replacement_Loan_Amount),
        //null,
        //"residualAmount": 35571.55,
        preferredEquity: vw_SearchRow.preferred_Equity,
        //5350,
        payment: vw_SearchRow.replacement_Payment,
        //1157,
        paymentDifference: vw_SearchRow.replacement_Payment - vw_SearchRow.current_Payment,
        //-633,
        //"capitalCost": 67512,
        //"paymentBeforeImprovement": null,
        //"salesTax": 8.75,
        //"isClosestTerm": true,
        isAlert: toYesNoTypeID(vw_SearchRow.is_Alert),
        //1,
        isFlexAlert: toYesNoTypeID(vw_SearchRow.is_Flex_Alert),
        // 1,
        isConversionAlert: toYesNoTypeID(vw_SearchRow.is_Conversion_Alert) //0,
        //"factoryRebate": 1000,
        //"dealerRebate": 0,
        //"cashBack": 0,
        //"adjustedCashDown": 0,
        //"isCapDownReduced": false,
        //"maxCapDown": null,
        //"isReplacementPayment": true,
        //"programSourceID": 2,

      }
    };
    opportunity.alerts = toAlertTypeList(vw_SearchRow, opportunity.replacementVehicle);
    return opportunity;
  }
}