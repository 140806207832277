/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EulaHistoryDto } from '../models/eula-history-dto';
@Injectable({
  providedIn: 'root',
})
class EulaHistoryClientService extends __BaseService {
  static readonly GetStatusGETPath = '/api/EulaHistory/GetStatus';
  static readonly PostResponseByEulaidByEularesponsetypeidPOSTPath = '/api/EulaHistory/PostResponse/{eulaId}/{eulaResponseTypeId}';
  static readonly ByIdGETPath = '/api/EulaHistory/{id}';
  static readonly ByIdDELETEPath = '/api/EulaHistory/{id}';
  static readonly GetManyGETPath = '/api/EulaHistory/GetMany';
  static readonly PUTPath = '/api/EulaHistory';
  static readonly POSTPath = '/api/EulaHistory';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetStatusGETResponse(): __Observable<__StrictHttpResponse<0 | 1 | 2 | 3>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EulaHistory/GetStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<0 | 1 | 2 | 3>;
      })
    );
  }
  /**
   * @return Success
   */
  GetStatusGET(): __Observable<0 | 1 | 2 | 3> {
    return this.GetStatusGETResponse().pipe(
      __map(_r => _r.body as 0 | 1 | 2 | 3)
    );
  }

  /**
   * @param params The `EulaHistoryClientService.PostResponseByEulaidByEularesponsetypeidPOSTParams` containing the following parameters:
   *
   * - `eulaResponseTypeId`:
   *
   * - `eulaId`:
   */
  PostResponseByEulaidByEularesponsetypeidPOSTResponse(params: EulaHistoryClientService.PostResponseByEulaidByEularesponsetypeidPOSTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EulaHistory/PostResponse/${params.eulaId}/${params.eulaResponseTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EulaHistoryClientService.PostResponseByEulaidByEularesponsetypeidPOSTParams` containing the following parameters:
   *
   * - `eulaResponseTypeId`:
   *
   * - `eulaId`:
   */
  PostResponseByEulaidByEularesponsetypeidPOST(params: EulaHistoryClientService.PostResponseByEulaidByEularesponsetypeidPOSTParams): __Observable<null> {
    return this.PostResponseByEulaidByEularesponsetypeidPOSTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<EulaHistoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EulaHistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EulaHistoryDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<EulaHistoryDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as EulaHistoryDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EulaHistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<EulaHistoryDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EulaHistory/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EulaHistoryDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<EulaHistoryDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<EulaHistoryDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: EulaHistoryDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EulaHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: EulaHistoryDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: EulaHistoryDto): __Observable<__StrictHttpResponse<EulaHistoryDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EulaHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EulaHistoryDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: EulaHistoryDto): __Observable<EulaHistoryDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as EulaHistoryDto)
    );
  }
}

module EulaHistoryClientService {

  /**
   * Parameters for PostResponseByEulaidByEularesponsetypeidPOST
   */
  export interface PostResponseByEulaidByEularesponsetypeidPOSTParams {
    eulaResponseTypeId: 1 | 2 | 3;
    eulaId: number;
  }
}

export { EulaHistoryClientService }
