"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cookie_storage_service_1 = require("./cookie-storage.service");
var window_ref_service_1 = require("../../services/window-ref.service");
var StorageService = /** @class */ (function () {
    function StorageService(window) {
        var _this = this;
        this.window = window;
        // These are stored in a cookie rather than in localStorage because we
        // want these to go away when the browser windows are closed. If we
        // used sessionStorage, they would not be shared when opening a new tab
        // while another tab is still open.
        this.COOKIE_KEYS = [
            'idToken',
            'accessToken'
        ];
        this.LOCAL_STORAGE_KEYS = [
            'referrerUrl',
            'defaultDealSheetTabs',
            'euroliteUserColumnSettings',
            'debug'
        ];
        // LocalStorage changes
        this.STORAGE_SUBSCRIPTIONS = [];
        // localStorage is shared among all browser tabs and is never cleared
        // automatically.
        this.localStorageContainer = new StorageContainer(this.window.localStorage, this.STORAGE_SUBSCRIPTIONS);
        // sessionStorage is shared only with windows opened by this window, and is
        // cleared automatically when the windows are closed.
        this.sessionStorageContainer = new StorageContainer(this.window.sessionStorage, this.STORAGE_SUBSCRIPTIONS);
        // session cookies are shared with all windows and are cleared when all
        // browser windows are closed.
        this.cookieStorageContainer = new cookie_storage_service_1.CookieStorage(this.window);
        this.prevOnStorage = this.window.onstorage;
        this.window.onstorage = function (args) {
            if (_this.prevOnStorage) {
                try {
                    _this.prevOnStorage.apply(_this, args);
                }
                catch (e) { /* fallthrough */ }
            }
            _this.STORAGE_SUBSCRIPTIONS.forEach(function (cb) { cb.apply(_this, args); });
        };
    }
    StorageService.prototype.getItem = function (key) {
        var keyStorage = this.getStorageForKey(key);
        return keyStorage.getItem(key);
    };
    StorageService.prototype.setItem = function (key, value) {
        return this.getStorageForKey(key).setItem(key, value);
    };
    StorageService.prototype.removeItem = function (key) {
        return this.getStorageForKey(key).removeItem(key);
    };
    StorageService.prototype.clear = function () {
        var _this = this;
        // Reports and Eurolite Opportunities, store some data in localStorage, so we should avoid clearing
        // the whole storage. Instead, we'll just delete known key names.
        this.LOCAL_STORAGE_KEYS
            .filter(function (key) { return key !== 'defaultDealSheetTabs' && key !== 'euroliteUserColumnSettings'; })
            .forEach(function (key) {
            _this.localStorageContainer.removeItem(key);
        });
        // Cookies and sessionStorage get cleared regularly anyway, so these
        // should be safe to clear.
        this.window.sessionStorage.clear();
        this.COOKIE_KEYS.forEach(function (key) { return _this.cookieStorageContainer.removeItem(key); });
    };
    StorageService.prototype.onchange = function (key, cb) {
        return this.getStorageForKey(key).onchange(key, cb);
    };
    StorageService.prototype.getStorageForKey = function (key) {
        if (this.COOKIE_KEYS.indexOf(key) > -1) {
            return this.cookieStorageContainer;
        }
        else {
            return (this.LOCAL_STORAGE_KEYS.indexOf(key) > -1 || key.startsWith("local"))
                ? this.localStorageContainer
                : this.sessionStorageContainer;
        }
    };
    return StorageService;
}());
exports.StorageService = StorageService;
/**
 * Uses the Web Storage API to store structured values.
 * storage - The underlying storage to wrap.
 */
var StorageContainer = /** @class */ (function () {
    function StorageContainer(storage, storageSubscriptionsArray) {
        this.storage = storage;
        this.storageSubscriptionsArray = storageSubscriptionsArray;
    }
    StorageContainer.prototype.getItem = function (key) {
        var serialized = this.storage.getItem(key);
        if (serialized) {
            try {
                serialized = JSON.parse(serialized, this.reviver);
            }
            catch (e) { /* fallthrough */ }
        }
        return serialized;
    };
    StorageContainer.prototype.setItem = function (key, value) {
        var serialized = JSON.stringify(value);
        return this.storage.setItem(key, serialized);
    };
    StorageContainer.prototype.removeItem = function (key) {
        return this.storage.removeItem(key);
    };
    StorageContainer.prototype.onchange = function (key, cb) {
        var wrappedCb = function onchangeCallback(evt) {
            if (key === key) {
                cb({
                    key: key,
                    value: evt.newValue,
                });
            }
        };
        this.storageSubscriptionsArray.push(wrappedCb);
        return {
            unsubscribe: function () {
                this.storageSubscriptionsArray = this.storageSubscriptionsArray.filter(function (item) { return item !== wrappedCb; });
            }
        };
    };
    StorageContainer.prototype.reviver = function (key, value) {
        var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.000?Z$/;
        if (typeof value === 'string' && dateFormat.test(value)) {
            return new Date(value);
        }
        return value;
    };
    return StorageContainer;
}());
exports.StorageContainer = StorageContainer;
