"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./mobile-customer-connect.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("ng-busy"));
var i3 = __importStar(require("../../customer-connect/customer-connect.component.ngfactory"));
var i4 = __importStar(require("../../customer-connect/customer-connect.component"));
var i5 = __importStar(require("./mobile-customer-connect.component"));
var i6 = __importStar(require("../../../../services/activity.service"));
var i7 = __importStar(require("../../../../core/toaster-logger.service"));
var i8 = __importStar(require("../../../../services/customer.service"));
var i9 = __importStar(require("../../../../services/mobile-deal-sheet-data-caching.service"));
var i10 = __importStar(require("@uirouter/core"));
var styles_MobileCustomerConnectComponent = [i0.styles];
var RenderType_MobileCustomerConnectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileCustomerConnectComponent, data: {} });
exports.RenderType_MobileCustomerConnectComponent = RenderType_MobileCustomerConnectComponent;
function View_MobileCustomerConnectComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.InstanceConfigHolderService, i2.InstanceConfigHolderService, []), i1.ɵprd(131584, null, i2.ɵa, i2.ɵa, []), i1.ɵdid(3, 409600, null, 0, i2.NgBusyDirective, [i2.ɵb, i2.InstanceConfigHolderService, i1.ComponentFactoryResolver, i2.ɵa, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i1.Renderer2, i1.Injector], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "customer-connect", [], null, [[null, "onLogScheduleSubmit"], [null, "onCustomerEdit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogScheduleSubmit" === en)) {
        var pd_0 = (_co.onLogScheduleSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onCustomerEdit" === en)) {
        var pd_1 = (_co.updateCustomer($event.$value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CustomerConnectComponent_0, i3.RenderType_CustomerConnectComponent)), i1.ɵdid(5, 638976, null, 0, i4.CustomerConnectComponent, [i1.ChangeDetectorRef], { isCustomerConnectOpen: [0, "isCustomerConnectOpen"], opportunity: [1, "opportunity"], customer: [2, "customer"], users: [3, "users"], maxScheduleFutureDays: [4, "maxScheduleFutureDays"], permissions: [5, "permissions"], defaultCustomerConnectAction: [6, "defaultCustomerConnectAction"], isEuroLite: [7, "isEuroLite"], isTextingEnabled: [8, "isTextingEnabled"], mobileView: [9, "mobileView"] }, { onLogScheduleSubmit: "onLogScheduleSubmit", onCustomerEdit: "onCustomerEdit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.busy; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isCustomerConnectOpen; var currVal_2 = _co.opportunity; var currVal_3 = ((_co.opportunity == null) ? null : _co.opportunity.customer); var currVal_4 = _co.users; var currVal_5 = ((_co.opportunity == null) ? null : ((_co.opportunity.dealerSettings == null) ? null : _co.opportunity.dealerSettings.maxScheduleFutureDays)); var currVal_6 = _co.permissions; var currVal_7 = _co.defaultCustomerConnectAction; var currVal_8 = false; var currVal_9 = false; var currVal_10 = true; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
exports.View_MobileCustomerConnectComponent_0 = View_MobileCustomerConnectComponent_0;
function View_MobileCustomerConnectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-customer-connect", [], null, null, null, View_MobileCustomerConnectComponent_0, RenderType_MobileCustomerConnectComponent)), i1.ɵdid(1, 245760, null, 0, i5.MobileCustomerConnectComponent, [i6.ActivityService, i7.ToasterLoggerService, i8.CustomerService, i9.MobileDealSheetDataCachingService, i10.Transition, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileCustomerConnectComponent_Host_0 = View_MobileCustomerConnectComponent_Host_0;
var MobileCustomerConnectComponentNgFactory = i1.ɵccf("mobile-customer-connect", i5.MobileCustomerConnectComponent, View_MobileCustomerConnectComponent_Host_0, {}, {}, []);
exports.MobileCustomerConnectComponentNgFactory = MobileCustomerConnectComponentNgFactory;
