
/* injects from baggage-loader */
require('./report-subscription-list.component.html');

import angular from 'angular';
import { ReportSpecsList } from '../../constants/reports/report-specifications';
import _ from 'lodash';

angular.module('AaApp.Opp').component('reportSubscriptionList', {
    templateUrl: '/app/components/reports/report-subscription-list.component.html',
    controller: ReportSubscriptionListController,
    bindings: {
        subscriptions: '<',
        onSubscriptionToggle: '&',
        onSubscriptionEdit: '&',
        onSubscriptionDelete: '&'
    }
});

/* @ngInject */
function ReportSubscriptionListController(dateRangeTypes, reportSubscriptionPeriods, $translate) {
    var $ctrl = this;

    $ctrl.dateRangeTypes;
    $ctrl.subscriptionPeriodTranslateKeys;
    $ctrl.$onChanges = onChanges;
    $ctrl.$onInit = init;

    $ctrl.translateCodes = [];

    function init() {
        $ctrl.dateRangeTranslateKeys = _.invert(dateRangeTypes);
        $ctrl.subscriptionPeriodTranslateKeys = _.invert(reportSubscriptionPeriods);

        for (const subscription of $ctrl.subscriptions) {
            if (subscription.code == dateRangeTypes.day)
                subscription.translateCode = dateRangeTypes.previousDay;
            else
                subscription.translateCode = subscription.code;
        }
    }

    function onChanges(changes) {
        if (changes.subscriptions && changes.subscriptions.currentValue) {
            for (var i = 0; i < $ctrl.subscriptions.length; i++) {
                var subscription = $ctrl.subscriptions[i];
                //replace "Enterprise" to quickly and easily use the associated non-enterprise report version titleKey.
                var report = _.find(ReportSpecsList, (item) => item.id.toLowerCase() == subscription.reportId);
                if (!report)
                    continue;
                subscription.translateKey = report.titleKey.replace("Enterprise", "");
                subscription.hideDateRange = report.hideDateRange;
            }
        }
    }
}