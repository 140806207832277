"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ResidualComponent = /** @class */ (function () {
    function ResidualComponent() {
    }
    ResidualComponent.prototype.ngOnInit = function () {
    };
    return ResidualComponent;
}());
exports.ResidualComponent = ResidualComponent;
