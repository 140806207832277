
/* injects from baggage-loader */
require('./log-call.component.html');

import angular from 'angular';
import _ from 'lodash';

angular.module('AaApp.Opp').component('logCall', {
    templateUrl: '/app/components/deal-sheet/log-call.component.html',
    controller: LogCallController,
    bindings: {
        opportunityId: '<',
        permissions: '<',
        notesType: '<',
        resolve: '<',
        close: '&',
        dismiss: '&',
        onLogCallEdit: '&',
        isReset: '<',
        isError: '<'
    }
});

/* @ngInject */
function LogCallController($filter, 
    logEmailSentTitleKey,
    postNotesTitleKey,
    logCallTitleKey,
    cancelTitleCallKey,
    cancelTitleApptKey,
    completeTitleCallKey,
    completeTitleApptKey,
    ActivityActionEnum, activityResultDescriptors) {
    var $ctrl = this;

    $ctrl.$onChanges = onChanges;


    $ctrl.onEdit = onEdit;
    $ctrl.isLogEmail = false;
    $ctrl.activity = null;
    $ctrl.onTypeOfCallSelect = onTypeOfCallSelect;
    $ctrl.removeFromActivityResults = removeFromActivityResults;
    $ctrl.errorMessage = "";

    // ActionResultSetID  mapped to setID of json AutoAlert/API/activity-results.json
    var callActionResultSetID = 1;
    var apptActionResultSetID = 2;
    var noteActionResultSetID = 3;
    var emailActionResultSetID = -1;

    // models for display
    var noteObject = {
        call: { activityId: ActivityActionEnum.Log_Call, description: logCallTitleKey, resultType: callActionResultSetID, resultLabelKey: "logCallResult", isCallBoundTypeVisible: true },
        note: { activityId: ActivityActionEnum.Post_Note, description: postNotesTitleKey, resultType: noteActionResultSetID, resultLabelKey: "postNotesResult", isCallBoundTypeVisible: false },
        email: { activityId: ActivityActionEnum.Email_Sent, description: logEmailSentTitleKey, resultType: emailActionResultSetID, resultLabelKey: "result", isCallBoundTypeVisible: false },
        cancelCallActivity: { activityId: ActivityActionEnum.Cancel_Call, description: cancelTitleCallKey, resultType: emailActionResultSetID, resultLabelKey: "result", isCallBoundTypeVisible: false },
        cancelApptActivity: { activityId: ActivityActionEnum.Cancel_Appt, description: cancelTitleApptKey, resultType: emailActionResultSetID, resultLabelKey: "result", isCallBoundTypeVisible: false },
        completeCallActivity: { activityId: ActivityActionEnum.Phone_Call_Completed, description: completeTitleCallKey, resultType: callActionResultSetID, resultLabelKey: "logCallResult", isCallBoundTypeVisible: false },
        completeApptActivity: { activityId: ActivityActionEnum.Appointment_Completed, description: completeTitleApptKey, resultType: apptActionResultSetID, resultLabelKey: "resultOfAppointment", isCallBoundTypeVisible: false }
    }

    // model for UI
    $ctrl.activityResultsList = {};
    $ctrl.status = { opened: false };
    $ctrl.model = { notes: '', resultID: "-1", callBoundType: null };
    $ctrl.open = function ($event) {
        $ctrl.status.opened = true;
    };


    function onTypeOfCallSelect(data) {
        $ctrl.model.callBoundType = data;        
    }

    function onChanges(changes) {

        if (changes.isReset && !changes.isReset.isFirstChange()) {
            $ctrl.model = { notes: '', resultID: "-1", callBoundType: $ctrl.model.callBoundType };
            $ctrl.errorMessage = "";
        }

        if (changes.isError && !changes.isError.isFirstChange()) {
            $ctrl.errorMessage = "postnotesResultErrorMsg";
        }



        if ($ctrl.resolve) {
            $ctrl.opportunityId = $ctrl.resolve.items.opportunityId;
            $ctrl.dealerId = $ctrl.resolve.items.dealerId;
            $ctrl.notesType = $ctrl.resolve.items.modalType;
            if ($ctrl.resolve.items.activityObject)
                $ctrl.activity = $ctrl.resolve.items.activityObject;

        }
        switch ($ctrl.notesType) {
            case ActivityActionEnum.Post_Note:
                $ctrl.currentNoteType = noteObject.note;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
            case ActivityActionEnum.Log_Call:
                $ctrl.currentNoteType = noteObject.call;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
            case ActivityActionEnum.Email_Sent:
                $ctrl.currentNoteType = noteObject.email;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                $ctrl.isLogEmail = true;
                break;
            case ActivityActionEnum.Cancel_Call:
                $ctrl.currentNoteType = noteObject.cancelCallActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                $ctrl.isLogEmail = true;
                break;
            case ActivityActionEnum.Cancel_Appt:
                $ctrl.currentNoteType = noteObject.cancelApptActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                $ctrl.isLogEmail = true;
                break;
            case ActivityActionEnum.Phone_Call_Completed:
                $ctrl.currentNoteType = noteObject.completeCallActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
            case ActivityActionEnum.Appointment_Completed:
                $ctrl.currentNoteType = noteObject.completeApptActivity;
                $ctrl.dialogTitle = $ctrl.currentNoteType.description;
                setActivityResults();
                break;
        }


    }

    // set default selection and get drop down list
    function setActivityResults() {
        $ctrl.activityResultsList = $filter('filter')(activityResultDescriptors, { setID: $ctrl.currentNoteType.resultType });

        if ($ctrl.permissions) {
            $ctrl.removeFromActivityResults($ctrl.permissions.editMarkAsSold, 7);
            $ctrl.removeFromActivityResults($ctrl.permissions.editMarkAsSold, 11);
            $ctrl.removeFromActivityResults($ctrl.permissions.editMarkAsSold, 23);
            $ctrl.removeFromActivityResults($ctrl.permissions.editMarkAsPaidOff, 33);
            $ctrl.removeFromActivityResults($ctrl.permissions.editMarkAsNotOwn, 28);
            $ctrl.removeFromActivityResults($ctrl.permissions.editMarkAsNotOwn, 31);
        }

        $ctrl.model.resultID = -1 + "";
    }

    function removeFromActivityResults(hasPermission, activityResultId) {
        if (!hasPermission) {
            var index = _.findIndex($ctrl.activityResultsList, (item) => item.activityResultID == activityResultId);
            if (index > -1) {
                $ctrl.activityResultsList.splice(index, 1);
            }
        }
    }

    function onEdit() {
        if ($ctrl.model.resultID <= -1) {
            switch ($ctrl.notesType) {
                case ActivityActionEnum.Post_Note:
                case ActivityActionEnum.Log_Call:
                case ActivityActionEnum.Phone_Call_Completed:
                case ActivityActionEnum.Appointment_Completed:
                    $ctrl.onLogCallEdit({ $value: null });
                    return;
            }
        }

        //usSpinnerService.spin('post-notes-spinner');
        var recordId = null;
        if ($ctrl.activity)
            recordId = $ctrl.activity.activityId;

        // model to save
        var activity = {
            entityID: $ctrl.opportunityId,
            activityActionID: $ctrl.currentNoteType.activityId,
            notes: $ctrl.model.notes,
            resultID: $ctrl.model.resultID,
            activityId: recordId,
            activityActionSubTypeID: $ctrl.model.callBoundType
        }

        if (!activity.notes)
            activity.notes = '';

        if (activity.resultID == -1)
            activity.resultID = null;

        //fire event with activity that needs to be submitted
        $ctrl.onLogCallEdit({ $value: activity });
    }

}
