"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var events_1 = require("../../modules/oidc-auth/events");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var log_factory_service_1 = require("../log-factory.service");
var operators_1 = require("rxjs/operators");
var token_service_1 = require("../../modules/oidc-auth/token.service");
var storage_service_1 = require("../../modules/storage/storage.service");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var http_interceptor_options_service_1 = require("./http-interceptor-options.service");
var NgOidcHttpInterceptor = /** @class */ (function () {
    function NgOidcHttpInterceptor(logFactory, authService, tokenService, loggerService, storageService, httpInterceptorOptionsService) {
        this.authService = authService;
        this.tokenService = tokenService;
        this.loggerService = loggerService;
        this.storageService = storageService;
        this.httpInterceptorOptionsService = httpInterceptorOptionsService;
        this.log = logFactory.get('oidcHttpInterceptor');
    }
    NgOidcHttpInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var newReq = req;
        if (this.urlMatchesConfig(req.url)) {
            var appendBearer = false;
            if (this.authService.config.enableRequestChecks) {
                // Only append token when it's valid.
                if (this.tokenService.hasToken()) {
                    if (this.tokenService.hasValidToken()) {
                        appendBearer = true;
                    }
                    else {
                        this.broadcast(events_1.tokenExpired$, { request: req });
                    }
                }
                else {
                    this.broadcast(events_1.tokenMissing$, { request: req });
                }
            }
            else {
                appendBearer = this.tokenService.hasToken();
            }
            if (appendBearer) {
                var token = this.tokenService.getTokenByType('access');
                newReq = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + token),
                    withCredentials: true
                });
            }
        }
        // do something on success
        return next.handle(newReq)
            .pipe(operators_1.map(function (event) {
            if (event instanceof http_1.HttpResponse && event.status > 400) {
                return _this.handleResponse(event);
            }
            return event;
        }), operators_1.catchError(function (err, caught) {
            if (err instanceof http_1.HttpErrorResponse) {
                return _this.handleResponseError(err);
            }
            return rxjs_1.throwError(err);
        }));
    };
    NgOidcHttpInterceptor.prototype.handleResponse = function (response) {
        if (this.urlMatchesConfig(response.url)) {
            // Proactively check if the token will expire soon
            this.authService.validateExpirity();
        }
        return response;
    };
    NgOidcHttpInterceptor.prototype.handleResponseError = function (httpErrorResponse) {
        if (httpErrorResponse.status === 401) {
            if (!this.tokenService.hasToken()) {
                // There was probably no token attached, because there is none
                this.broadcast(events_1.tokenMissing$, { response: httpErrorResponse.error });
            }
            else if (!this.tokenService.hasValidToken()) {
                // Seems the token is not valid anymore
                this.broadcast(events_1.tokenExpired$, { response: httpErrorResponse.error });
            }
            else if (httpErrorResponse.error === 'User is disabled' ||
                httpErrorResponse.error === 'User is locked' ||
                httpErrorResponse.error === 'IP Address is not valid') {
                this.broadcast(events_1.unauthorized$, { response: httpErrorResponse.error });
            }
            else if (httpErrorResponse.url && httpErrorResponse.url.indexOf('LoggedInUser') > -1) {
                this.broadcast(events_1.unauthorized$);
            }
        }
        else {
            // TODO this should be moved to its own interceptor since it doesn't have anything to do with OIDC handling
            if (!this.httpInterceptorOptionsService.disableErrorHandlerSetting) { // "disableErrorHandlerSetting = true" means handle error manually
                if (httpErrorResponse.status !== -1 && this.isErrorEndpoint(httpErrorResponse)) {
                    if (this.storageService.getItem('userProfile')) {
                        if (this.loggerService) {
                            if (httpErrorResponse && httpErrorResponse.error && httpErrorResponse.error.error && httpErrorResponse.error.error.translationKey) {
                                this.loggerService.genericError(httpErrorResponse.error.error.translationKey, httpErrorResponse, 'responseError');
                            }
                            else {
                                var message = (httpErrorResponse.error) ? httpErrorResponse.error.message : httpErrorResponse.message;
                                this.loggerService.genericError('responseError with API call', message, 'oidcHttpInterceptor:responseError');
                            }
                        }
                        else {
                            this.log.error('responseError with API call', httpErrorResponse.error.message, 'oidcHttpInterceptor:responseError');
                        }
                    }
                }
            }
        }
        return rxjs_1.throwError(httpErrorResponse);
    };
    NgOidcHttpInterceptor.prototype.urlMatchesConfig = function (url) {
        return this.authService.config.urls && this.authService.config.urls.some(function (u) { return url.startsWith(u); });
    };
    NgOidcHttpInterceptor.prototype.broadcast = function (event, data) {
        event.next(data);
    };
    NgOidcHttpInterceptor.prototype.isErrorEndpoint = function (response) {
        var excludedEndpoints = ['CreditPrescreenRequest'];
        for (var i = 0; i < excludedEndpoints.length; i++) {
            if (response.url && response.url.indexOf(excludedEndpoints[i]) > -1) {
                return false;
            }
        }
        return true;
    };
    return NgOidcHttpInterceptor;
}());
exports.NgOidcHttpInterceptor = NgOidcHttpInterceptor;
