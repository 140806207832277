
    export const QuickSearchUrl = '/AlertDesk/QuickSearch/Show?Query=';
    export const DealSheetUrl = '/AlertDesk/DealSheet/Show?e=';
    export const SearchPresetUrl = '/AlertDesk/Search/Index?ConditionID=';
    export const UserProfileUrl = '/Security/User/Profile';
    export const SubMenuLocalizationPath = '/app/i18n/sub-menu';
    export const WarningTitleKey = 'warningTitle';
    export const QuickSearchWarningKey = 'quickSearchWarning';
    export const QuickSearchInvalidCharacterWarningKey = 'quickSearchInvalidCharacterWarning';
    export const PageNotFoundKey = 'pageNotFound';
