InventorySearchActionController.$inject = ["$q", "$analytics", "analytics"];

/* injects from baggage-loader */
require('./inventory-search-action.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('inventorySearchAction', {
  templateUrl: '/app/components/deal-sheet/vehicle/inventory-search-action.component.html',
  controller: InventorySearchActionController,
  bindings: {
    opportunity: '<',
    onSelect: '&'
  }
});
/* @ngInject */

function InventorySearchActionController($q, $analytics, analytics) {
  var $ctrl = this;
  $ctrl.defaults;
  $ctrl.onVehicleSelect = onVehicleSelect;
  $ctrl.getDefaults = getDefaults;

  function getDefaults() {
    $ctrl.defaults = {
      saleTypes: [$ctrl.opportunity.currentContract.saleType],
      vehicleTypes: [$ctrl.opportunity.replacementVehicle.type],
      makeID: $ctrl.opportunity.replacementVehicle.makeID,
      makeName: $ctrl.opportunity.replacementVehicle.makeName,
      yearID: $ctrl.opportunity.replacementVehicle.yearID,
      modelID: $ctrl.opportunity.replacementVehicle.modelID,
      modelName: $ctrl.opportunity.replacementVehicle.modelName,
      trimID: $ctrl.opportunity.replacementVehicle.trimID,
      trimName: $ctrl.opportunity.replacementVehicle.trimName,
      term: $ctrl.opportunity.currentContract.term,
      payment: $ctrl.opportunity.currentContract.payment
    };
    return $q.resolve(true);
  }

  function onVehicleSelect(vehicle) {
    $analytics.eventTrack(analytics.actions.changeReplacementVehicle, {
      category: analytics.categories.dealSheet,
      label: analytics.labels.inventorySearch
    });
    $ctrl.onSelect({
      vehicle: vehicle
    });
  }
}