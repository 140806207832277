"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ProjectedMileageComponent = /** @class */ (function () {
    function ProjectedMileageComponent() {
    }
    ProjectedMileageComponent.prototype.ngOnInit = function () {
    };
    return ProjectedMileageComponent;
}());
exports.ProjectedMileageComponent = ProjectedMileageComponent;
