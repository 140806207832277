
/* injects from baggage-loader */
require('./login.component.html');

import angular from 'angular';
import { ChildMessagePort } from '../utilities/messaging';
import { getUrlComponents } from '../utilities/url';

angular.module('AaApp.Opp').component('login', {
    templateUrl: '/app/components/login.component.html',
    controller: LoginController
});

/* @ngInject */
function LoginController($element, $sce, $scope, globalIframeService, legacy, legacyUrlMappings, userService) {
    var $ctrl = this;

    $ctrl.src;
    $ctrl.$onInit = $onInit;

    function $onInit() {
        userService.logUserLogin();

        const loginUrl = legacy.iframeUrl + legacyUrlMappings['logIn'];
        $ctrl.src = $sce.trustAsResourceUrl(loginUrl);

        const iframe = $element.find('iframe')[0];
        const { origin } = getUrlComponents(loginUrl);
        const port = new ChildMessagePort(iframe, origin);
        globalIframeService.setCurrentMessagePort(port, $scope);

        $ctrl.$onDestroy = () => port.close();
    }
}
