
/* injects from baggage-loader */
require('./search-manage-presets.component.html');

import angular from 'angular';
import $ from 'jquery';
import BusyIndicator from '../../utilities/BusyIndicator';
import { PandoXRuleCategory, PandoXRuleCategoryReverse } from '../../constants';

import pencilIconPath from '../../images/icon-pencil-lake.svg';
import trashIconPath from '../../images/icon-trash-lake.svg';
import upDownArrowIconPath from '../../images/icon-arrow-up-down-lake.svg';
import warningSignPath from '../../images/icon-warning-lemon.svg';

angular.module('AaApp.Opp').component('searchManagePresets', {
    templateUrl: '/app/components/search/search-manage-presets.component.html',
    controller: SearchManagePresets,
    bindings: {
        selectedDealerId: '=',
        dealerId: '<',
        kendo: '<',
    }
});

function SearchManagePresets(_, $translate, $q, dealerService, searchPresetService, pandoSurveyService, templateService, userService,
    smtpProviderTypes, modules, $uibModal, $document, $filter, toaster, messageTypes, successKey, logger) {
    "ngInject";
    const $ctrl = this;

    $ctrl.$onInit = init;
    $ctrl.onDealerSelect = onDealerSelect;
    $ctrl.kendoGrid = null;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.ds = null;

    $ctrl.pandoXRuleCategory = PandoXRuleCategory;
    $ctrl.pandoXRuleCategoryReverse = PandoXRuleCategoryReverse;

    $ctrl.pandoXRuleCategoryArray = [];
    for (let category in $ctrl.pandoXRuleCategory) {
        $ctrl.pandoXRuleCategoryArray.push({
            CategoryName: $translate.instant(category),
            CategoryId: $ctrl.pandoXRuleCategory[category]
        });
    }

    $ctrl.isDealerPandoXEnabled = false;
    $ctrl.leadAssignOwnerAccessEnabled = false;
    $ctrl.pandoWorkflowTemplates = null;
    $ctrl.searchPresetData = null;
    $ctrl.pandoGroups = null;
    $ctrl.saveSelectedSearchPresets = saveSelectedSearchPresets;
    $ctrl.savePresetSettings = savePresetSettings;
    $ctrl.errors = false;
    $ctrl.readyToDisplay = false;
    $ctrl.templates;
    $ctrl.emailDisabled = false;
    $ctrl.hasTemplateErrors = false;
    $ctrl.hasActiveTemplate = false;

    function init() {
        if ($ctrl.dealerId) {
            $ctrl.selectedDealerId = $ctrl.dealerId;
        }

        $ctrl.dealers = dealerService.getSelectedDealers();
        $ctrl.emailDisabled = false;
        $ctrl.hasTemplateErrors = false;
        $ctrl.hasActiveTemplate = false;
        getTranslations();
    }

    function getTranslations() {
        $q.all({
            'srchPreClearInputsBtn': $translate('srchPreClearInputsBtn'),
            'srchPreMissingGroup': $translate('srchPreMissingGroup'),
            'srchPreMissingWorkflow': $translate('srchPreMissingWorkflow'),
            'srchPreDeleteTitle': $translate('srchPreDeleteTitle'),
            'srchPreDeleteMsg': $translate('srchPreDeleteMsg'),
            'srchPreClearInputsTitle': $translate('srchPreClearInputsTitle'),
            'srchPreClearInputsMsg': $translate('srchPreClearInputsMsg'),
            'srchPreClearAutoMsg': $translate('srchPreClearAutoMsg'),
            'srchPreDataErrorMsg': $translate('srchPreDataErrorMsg'),
            'srchPreSelectDealership': $translate('srchPreSelectDealership'),
            'srchPreSelectGroup': $translate('srchPreSelectGroup'),
            'srchPreSelectProcess': $translate('srchPreSelectProcess'),
            'srchPreSelectTemplate': $translate('srchPreSelectTemplate'),
            'srchPreGroupLabel': $translate('srchPreGroupLabel'),
            'srchPandoXPopupText': $translate('srchPandoXPopupText'),
            'srchPreExecutePandoXLabel': $translate('srchPreExecutePandoXLabel'),
            'srchPreCategoryLabel': $translate('srchPreCategoryLabel'),
            'srchPreSelectCategory': $translate('srchPreSelectCategory'),
            'srchPreExecutePandoXTooltip': $translate('srchPreExecutePandoXTooltip'),
        }).then(function (trans) {
            $ctrl.srchPreClearInputsBtn = trans.srchPreClearInputsBtn;
            $ctrl.srchPreMissingGroup = trans.srchPreMissingGroup;
            $ctrl.srchPreMissingWorkflow = trans.srchPreMissingWorkflow;
            $ctrl.srchPreDeleteTitle = trans.srchPreDeleteTitle;
            $ctrl.srchPreDeleteMsg = trans.srchPreDeleteMsg;
            $ctrl.srchPreClearInputsTitle = trans.srchPreClearInputsTitle;
            $ctrl.srchPreClearInputsMsg = trans.srchPreClearInputsMsg;
            $ctrl.srchPreClearAutoMsg = trans.srchPreClearAutoMsg;
            $ctrl.srchPreDataErrorMsg = trans.srchPreDataErrorMsg;
            $ctrl.srchPreSelectGroup = trans.srchPreSelectGroup;
            $ctrl.srchPreSelectProcess = trans.srchPreSelectProcess;
            $ctrl.srchPreSelectTemplate = trans.srchPreSelectTemplate;
            $ctrl.srchPreGroupLabel = trans.srchPreGroupLabel;
            $ctrl.srchPandoXPopupText = trans.srchPandoXPopupText;
            $ctrl.srchPreExecutePandoXLabel = trans.srchPreExecutePandoXLabel;
            $ctrl.srchPreCategoryLabel = trans.srchPreCategoryLabel;
            $ctrl.srchPreSelectCategory = trans.srchPreSelectCategory;
            $ctrl.srchPreExecutePandoXTooltip = trans.srchPreExecutePandoXTooltip;

            if ($ctrl.dealers.length > 1) {
                $ctrl.dealers.unshift({ id: 0, name: trans.srchPreSelectDealership });
            }

            onDealerSelect();
            renderGridToolbar();

            $ctrl.pandoXRuleCategoryArray.unshift({
                CategoryName: $ctrl.srchPreSelectCategory,
                CategoryId: "-1"
            });
        })
    }

    function onDealerSelect() {
        try {
            $ctrl.leadAssignOwnerAccessEnabled = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.leadAssignOwnerAccess);
        } catch (e) {
            $ctrl.leadAssignOwnerAccessEnabled = false;
        }

        if ($ctrl.selectedDealerId > 0) {
            populateData();
        }
    }

    function byTemplateName(object1, object2) {
        if (object1.templateName.toLowerCase() < object2.templateName.toLowerCase())
            return -1;
        if (object1.templateName.toLowerCase() > object2.templateName.toLowerCase())
            return 1;
        return 0;
    }

    function populateData() {

        return busy(
            "srchPreLoadingData",
            $q.all([
                templateService.getNonEmptyTemplatesForDealerId($ctrl.selectedDealerId),
                templateService.getDealerOutboundEmailProviderInfo($ctrl.selectedDealerId),
                searchPresetService.getPandoCustomStoreGroups($ctrl.selectedDealerId),
                dealerService.getIsDealerPandoXEnabled($ctrl.selectedDealerId)
            ]).then((data) => {
                var groups = data[2];
                groups.unshift({ groupId: "-1", groupName: $ctrl.srchPreSelectGroup });
                $ctrl.pandoGroups = groups.map(item => ({
                    GroupName: item.groupName,
                    GroupId: item.groupId === null ? "-1" : item.groupId
                }));                

                var templates = data[0].sort(byTemplateName);
                templates.unshift({ id: "-1", templateName: $ctrl.srchPreSelectTemplate, templateSubject: "" });
                $ctrl.templates = templates.map(item => ({
                    TemplateName: item.templateName,
                    TemplateSubject: item.templateSubject,
                    TemplateId: item.id === null ? "-1" : item.id,
                }));

                var smtpMethod = data[1];
                $ctrl.emailDisabled = smtpMethod === smtpProviderTypes.none;

                $ctrl.isDealerPandoXEnabled = data[3];

                // Dealers with PandoX cannot use lead assignment
                if ($ctrl.isDealerPandoXEnabled) {
                    $ctrl.leadAssignOwnerAccessEnabled = false;
                }
            })
                .then(() => {
                    if (!$ctrl.isDealerPandoXEnabled) {
                        return pandoSurveyService.getWorkflowTemplates($ctrl.selectedDealerId);
                    }

                    return Promise.resolve();
                })
                .then(workflows => {
                    if (!workflows) {
                        workflows = [];
                    }

                    workflows.unshift({ key: "-1", value: $ctrl.srchPreSelectProcess, disabled: true });
                    $ctrl.pandoWorkflowTemplates = workflows.map(item => ({
                        WorkflowInstanceId: item.key === null ? "-1" : item.key,
                        WorkflowName: item.value
                    }));

                    initDataSource();
                    initGridOptions();

                    // we need to force a header refresh since we have dynamic elements in the header.  As far as we can tell, setOptions is the only way to do this with kendo
                    var grid = $("#gridManageSearches").data("kendoGrid");
                    if (grid != null)
                        grid.setOptions($ctrl.gridOptions);
                })
                .catch(function () {
                    var groups = [];
                    groups.unshift({ groupId: "-1", groupName: $ctrl.srchPreSelectGroup });

                    $ctrl.pandoGroups = groups.map(item => ({
                        GroupName: item.groupName,
                        GroupId: item.groupId === null ? "-1" : item.groupId
                    }));

                    var workflows = [];
                    workflows.unshift({ key: "-1", value: $ctrl.srchPreSelectProcess, disabled: true });
                    $ctrl.pandoWorkflowTemplates = workflows.map(item => ({
                        WorkflowInstanceId: item.key === null ? "-1" : item.key,
                        WorkflowName: item.value
                    }));

                    var templates = [];
                    templates.unshift({ id: "-1", templateName: $ctrl.srchPreSelectTemplate, templateSubject: "" });
                    $ctrl.templates = templates.map(item => ({
                        TemplateName: item.templateName,
                        TemplateSubject: item.templateSubject,
                        TemplateId: item.id === null ? "-1" : item.id,
                    }));

                    initDataSource();
                    initGridOptions();
                })
        );
    }

    function getGroupName(groupId) {
        var group = _.find($ctrl.pandoGroups, { 'GroupId': groupId });
        return group ? group.GroupName : $ctrl.srchPreMissingGroup;
    }

    function getWorkflowName(templateId) {
        var template = _.find($ctrl.pandoWorkflowTemplates, { 'WorkflowInstanceId': templateId });
        return template ? template.WorkflowName : $ctrl.srchPreMissingWorkflow;
    }

    function byOrder(searchPreset1, searchPreset2) {
        return searchPreset1.order - searchPreset2.order;
    }

    function getSearchPresets() {
        return searchPresetService.getDealerSearchPresetLayouts($ctrl.selectedDealerId).then(function (data) {
            data.sort(byOrder);
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            
            $ctrl.searchPresetData = data.map(item => {

                var workflowName = "";
                var workflowInstanceId = "-1";
                var workflowMax = null;
                if (item.workflowTemplateId !== null) {
                    workflowName = getWorkflowName(item.workflowTemplateId);
                    workflowInstanceId = item.workflowTemplateId;
                    workflowMax = item.workflowQuantity;
                }

                var ownerGroupName = "";
                var ownerGroupId = "-1";
                var ownerMax = null;
                if (item.ownerOverrideGroupId !== null) {
                    ownerGroupName = getGroupName(item.ownerOverrideGroupId);
                    ownerGroupId = item.ownerOverrideGroupId;
                    ownerMax = item.ownerOverrideQuantity;
                }

                var accessGroupName = "";
                var accessGroupId = "-1";
                var accessMax = null;

                if (item.accessGrantGroupId !== null) {
                    accessGroupName = getGroupName(item.accessGrantGroupId);
                    accessGroupId = item.accessGrantGroupId;
                    accessMax = item.accessGrantQuantity;
                }

                var pandoXCategoryName = "";
                var pandoXCategoryId = "-1";
                var pandoXRuleQuantity = null;

                if (item.ruleCategoryID !== null) {
                    pandoXCategoryName = $ctrl.pandoXRuleCategoryReverse[item.ruleCategoryID];
                    pandoXCategoryId = item.ruleCategoryID;
                    pandoXRuleQuantity = item.pandoXRuleQuantity;
                }

                var ownerTemplate = _.find($ctrl.templates, { TemplateId: item.ownerOverrideTemplateID });
                var accessTemplate = _.find($ctrl.templates, { TemplateId: item.accessGrantTemplateID });

                return {
                    Id: getKendoIdFromUserConditionId(item.userSearchConditionID),
                    Name: item.searchConditionName,
                    Order: item.order,
                    Owner: { GroupName: ownerGroupName, GroupId: ownerGroupId },
                    OwnerTemplate: { TemplateName: ownerTemplate == null ? "" : ownerTemplate.TemplateName, TemplateId: item.ownerOverrideTemplateID === null ? "-1" : item.ownerOverrideTemplateID },
                    MaxOwnerEntries: ownerMax,
                    Access: { GroupName: accessGroupName, GroupId: accessGroupId },
                    AccessTemplate: { TemplateName: accessTemplate == null ? "" : accessTemplate.TemplateName, TemplateId: item.accessGrantTemplateID === null ? "-1" : item.accessGrantTemplateID },
                    MaxAccessEntries: accessMax,
                    AssignedWorkflow: { WorkflowName: workflowName, WorkflowInstanceId: workflowInstanceId },
                    MaxAssignEntries: workflowMax,
                    PandoX: { CategoryName: pandoXCategoryName, CategoryId: pandoXCategoryId },
                    PandoXRuleQuantity: pandoXRuleQuantity,
                    IsPriority: item.isPriority,
                    SearchActiveStartDate: item.searchActiveStartDate,
                    SearchActiveEndDate: item.searchActiveEndDate,
                    InsertDateUtc: item.insertDateUtc
                }
            });
            return $ctrl.searchPresetData;
        });

    }

    function busy(translationKey, promise) {
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    const kendoUserConditionIdSpacer = 100;
    function getKendoIdFromUserConditionId(userConditionId) {
        return userConditionId + kendoUserConditionIdSpacer;
    }

    function getUserConditionIdFromKendoId(kendoId) {
        // necessary to prevent Id=0. Kendo treats as new record.
        return kendoId - kendoUserConditionIdSpacer;
    }

    function updatePresets(e) {
        var alldata = $('#gridManageSearches').data('kendoGrid').dataSource.data();
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        var allrows = alldata.map(p => (
            {
                dealerID: $ctrl.selectedDealerId,
                userSearchConditionID: getUserConditionIdFromKendoId(p.Id),
                order: p.Order,
                workflowTemplateId: p.AssignedWorkflow.WorkflowInstanceId === "-1" ? null : p.AssignedWorkflow.WorkflowInstanceId,
                workflowQuantity: p.MaxAssignEntries,
                pandoIntegrationEnabled: p.AssignedWorkflow.WorkflowInstanceId === "-1" ? false : true,
                ownerOverrideGroupId: (p.Owner === null || p.Owner.GroupId === "-1") ? null : p.Owner.GroupId,
                ownerOverrideQuantity: p.MaxOwnerEntries,
                ownerOverrideTemplateId: (p.OwnerTemplate === null || p.OwnerTemplate.TemplateId === "-1") ? null : p.OwnerTemplate.TemplateId,
                accessGrantGroupId: (p.Access === null || p.Access.GroupId === "-1") ? null : p.Access.GroupId,
                accessGrantQuantity: p.MaxAccessEntries,
                accessGrantTemplateId: (p.AccessTemplate === null || p.AccessTemplate.TemplateId === "-1") ? null : p.AccessTemplate.TemplateId,

                ruleCategoryID: (p.PandoX === null || p.PandoX.CategoryId === "-1") ? null : p.PandoX.CategoryId,
                pandoXRuleQuantity: p.PandoXRuleQuantity,
                isPriority: p.IsPriority,
                searchActiveStartDate: p.SearchActiveStartDate,
                searchActiveEndDate: p.SearchActiveEndDate,
                insertDateUtc: p.InsertDateUtc

            }));

        searchPresetService.updateDealerSearchPresetLayouts($ctrl.selectedDealerId, allrows)
            .then(success => {
                if (success) {
                    if (e) e.success();
                }
                else {
                    if (e) e.error("XHR response", "status code", "Error updating preset layouts.");
                }
            })
    }

    function initDataSource() {
        $ctrl.ds = new $ctrl.kendo.data.DataSource({
            schema: {
                model: {
                    id: "Id",
                    fields: {
                        Id: { type: "number", editable: false },
                        Order: { type: "number", editable: true },
                        Name: { type: "string", editable: false },
                        Owner: { defaultValue: { GroupId: "-1", GroupName: "" }, editable: true },
                        OwnerTemplate: { defaultValue: { TemplateId: "-1", TemplateName: "" }, editable: true },
                        MaxOwnerEntries: { type: "number", editable: true },
                        Access: { defaultValue: { GroupId: "-1", GroupName: "" }, editable: true },
                        AccessTemplate: { defaultValue: { TemplateId: "-1", TemplateName: "" }, editable: true },
                        MaxAccessEntries: { type: "number", format: "{0:n0}", editable: true },
                        AssignedWorkflow: { defaultValue: { WorkflowInstanceId: "-1", WorkflowName: "" }, editable: true },
                        MaxAssignEntries: { type: "number", format: "{0:n0}", editable: true },
                        IsPriority: { type: "boolean", editable: false },
                        PandoX: { defaultValue: { PandoXCategoryId: "-1", PandoXCategoryName: "" }, editable: true },
                        PandoXRuleQuantity: { type: "number", format: "{0:n0}", editable: true },
                        SearchActiveStartDate: { type: "date", editable: true },
                        SearchActiveEndDate: { type: "date", editable: true },
                        InsertDateUtc: { type: "date", editable: false },
                    }
                }
            },
            sort: { field: "Order", dir: "asc" },
            change: onChange,
            transport: {
                read: function (e) {
                    getSearchPresets()
                        .then(searchList => {
                            e.success(searchList);
                        })
                },
                update: function (e) {
                    updatePresets(e);
                },
                create: function (e) {
                    e.success();
                },
                destroy: function (e) {
                    reOrderData();
                    updatePresets(e);
                }
            },
            error: function (e) {
                alert("Status: " + e.status + "; Error message: " + e.errorThrown);
            }
        });

    }

    function reOrderData() {
        var data = $('#gridManageSearches').data('kendoGrid').dataSource.data();
        for (var i = 0; i < data.length; i++) {
            var row = data[i];
            row.Order = i + 1;
        }
    }

    function onChange(e) {
        var grid = $("#gridManageSearches").data("kendoGrid");

        if (e.items.length === 0)
            return;

        var dataItem = grid.dataSource.getByUid(e.items[0].uid);

        var today = new Date();
        today.setHours(0, 0, 0, 0);
        
        if (e.action == "itemchange") {
            if (e.field === "Owner") {
                var owner = e.items[0].Owner;
                if (owner === null || owner.GroupId === "-1") {
                    dataItem.set("MaxOwnerEntries", null);
                }
                else {
                    dataItem.set("MaxOwnerEntries", 10);
                }
            }

            if (e.field === "Access") {
                var access = e.items[0].Access;
                if (access === null || access.GroupId === "-1") {
                    dataItem.set("MaxAccessEntries", null);
                }
                else {
                    dataItem.set("MaxAccessEntries", 10);
                }
            }

            if (e.field === "AssignedWorkflow") {
                var workflow = e.items[0].AssignedWorkflow;
                if (workflow === null || workflow.WorkflowInstanceId === "-1") {
                    dataItem.set("MaxAssignEntries", null);
                }
                else {
                    dataItem.set("MaxAssignEntries", 10);
                }
            }

            if (e.field === "PandoX") {
                var pandoX = e.items[0].PandoX;
                if (pandoX === null || pandoX.CategoryId === "-1") {
                    dataItem.set("PandoXRuleQuantity", null);
                }
                else {
                    dataItem.set("PandoXRuleQuantity", 10);
                }
            }
        }
    }

    function onRowBound() {
        setTimeout(function () {
            $(".k-grid-update span").removeClass("k-icon").removeClass("k-i-check");   //.addClass("btn btn-success");
            $(".k-grid-cancel span").removeClass("k-icon").removeClass("k-i-cancel");   //.addClass("btn btn-danger");
            $(".k-grid-cancel").css("margin-left", "5px");
            CheckForTemplateErrors();
        }, 1);
    }

    function onDataBound() {
        AddDragDrop();
        ShowHideColumns();
        CheckForTemplateErrors();
        $ctrl.readyToDisplay = true;
    }

    // this function loops over all presets to see if any have template errors
    function CheckForTemplateErrors() {
        var data = $('#gridManageSearches').data('kendoGrid').dataSource.data();
        $ctrl.hasActiveTemplate = false;
        $ctrl.hasTemplateErrors = false;
        for (var i = 0; i < data.length; i++) {
            var row = data[i];
            var template = null;
            if (row.Owner.GroupName.length > 0 && row.Owner.GroupId != "-1" && row.OwnerTemplate.TemplateId != null && row.OwnerTemplate.TemplateId != "-1") {
                $ctrl.hasActiveTemplate = true;
                template = _.find($ctrl.templates, { TemplateId: row.OwnerTemplate.TemplateId });
                if (template != null) {
                    $ctrl.hasTemplateErrors = template.TemplateSubject.length === 0;
                }
            }
            if (row.Access.GroupName.length > 0 && row.Access.GroupId != "-1" && row.AccessTemplate.TemplateId != null && row.AccessTemplate.TemplateId != "-1") {
                $ctrl.hasActiveTemplate = true;
                template = _.find($ctrl.templates, { TemplateId: row.AccessTemplate.TemplateId });
                if (template != null) {
                    $ctrl.hasTemplateErrors = template.TemplateSubject.length === 0;
                }
            }
            if ($ctrl.hasTemplateErrors)
                break;
        }
    }

    function HasTemplateSubject(templateId) {
        var data = $('#gridManageSearches').data('kendoGrid').dataSource.data();
        var hasSubject = false;
        var template = _.find($ctrl.templates, { TemplateId: templateId });
        if (template != null) {
            hasSubject = template.TemplateSubject.length > 0;
        }
        return hasSubject;
    }

    function ShowHideColumns() {
        var grid = $('#gridManageSearches').data('kendoGrid');
        if (grid != undefined && grid != null) {
            if ($ctrl.leadAssignOwnerAccessEnabled === true) {
                grid.showColumn("Owner");
                grid.showColumn("MaxOwnerEntries");
                grid.showColumn("Access");
                grid.showColumn("MaxAccessEntries");
            }
            else {
                grid.hideColumn("Owner");
                grid.hideColumn("MaxOwnerEntries");
                grid.hideColumn("Access");
                grid.hideColumn("MaxAccessEntries");
            }

            if ($ctrl.isDealerPandoXEnabled) {
                grid.showColumn("PandoX");
                grid.showColumn("PandoXRuleQuantity");
            } else {
                grid.hideColumn("PandoX");
                grid.hideColumn("PandoXRuleQuantity");
            }
        }
    }

    function maxEntriesEditor(container, options) {
        $('<input name="' + options.field + '"/>')
            .appendTo(container)
            .kendoNumericTextBox({
                min: 0,
                max: 100,
                step: 1,
                format: "n0",
                decimals: 0
            });
    }

    function renderStartDateColumn(data) {
        var output = "";
        
        if (data.SearchActiveStartDate == null)
            return "";

        var textColor = "auto";
        if (startDateExpired(data.SearchActiveStartDate)) {
            textColor = "red";
        }
        var localeDateFilter = $filter('localeDate');
        var localeDate = localeDateFilter(data.SearchActiveStartDate);
        output += '<span style="color:' + textColor + ';">' + localeDate + '</span>';

        return output;
    }

    function renderEndDateColumn(data) {
        var output = "";
        
        if (data.SearchActiveEndDate == null)
            return "";

        var textColor = "auto";
        if (endDateExpired(data.SearchActiveEndDate)) {
            textColor = "red";
        }
        
        var localeDateFilter = $filter('localeDate');
        var localeDate = localeDateFilter(data.SearchActiveEndDate);
        output += '<span style="color:' + textColor + ';">' + localeDate + '</span>';

        return output;
    }


    function renderOwnerColumn(data) {
        var output = "";

        if (data.Owner.GroupName.length > 0 && data.Owner.GroupId != "-1") {
            output += '<div style="display:inline-block;min-width:42px;color: black;">' + $ctrl.srchPreGroupLabel + ': </div>';
            output += '<span>' + data.Owner.GroupName + '</span>';
            if (data.OwnerTemplate.TemplateName.length > 0 && data.OwnerTemplate.TemplateId != "-1") {
                var errorCodes = "";
                var hasSubject = HasTemplateSubject(data.OwnerTemplate.TemplateId);
                if (!hasSubject) {
                    errorCodes = "1";
                }
                if ($ctrl.emailDisabled) {
                    if (!hasSubject) errorCodes += ", "
                    errorCodes += "2"
                }

                output += '<div><div style="display:inline-block;min-width:42px;color: black;">';
                if (errorCodes.length > 0)
                    output += '<img src="' + warningSignPath + '" style="height:14px;" />';

                output += '<icon class="icon aa-icon-core-email-icon-000000" style="color:black;top:3px;"></icon>:&nbsp;&nbsp;</div>';
                output += '<span>' + data.OwnerTemplate.TemplateName + '</span>';

                if (errorCodes.length > 0)
                    output += " <sup>" + errorCodes + "</sup>";
            }
        }
        return output;
    }

    function renderAccessColumn(data) {
        var output = "";

        if (data.Access.GroupName.length > 0 && data.Access.GroupId != "-1") {
            var errorCodes = "";
            var hasSubject = HasTemplateSubject(data.AccessTemplate.TemplateId);
            if (!hasSubject) {
                errorCodes = "1";
            }
            if ($ctrl.emailDisabled) {
                if (!hasSubject) errorCodes += ", "
                errorCodes += "2"
            }

            output += '<div style="display:inline-block;min-width:42px;color: black;">' + $ctrl.srchPreGroupLabel + ': </div>';
            output += '<span>' + data.Access.GroupName + '</span>';
            if (data.AccessTemplate.TemplateName.length > 0 && data.AccessTemplate.TemplateId != "-1") {
                output += '<div><div style="display:inline-block;min-width:42px;color: black;">';
                if (errorCodes.length > 0)
                    output += '<img src="' + warningSignPath + '" style="height:14px;" />';
                output += '<icon class="icon aa-icon-core-email-icon-000000" style="color:black;top:3px;"></icon>:&nbsp;&nbsp;</div > ';
                output += '<span>' + data.AccessTemplate.TemplateName + '</span>';

                if (errorCodes.length > 0)
                    output += " <sup>" + errorCodes + "</sup>";
            }
        }
        return output;
    }

    function renderWorkflowColumn(data) {
        var output = "";

        if (data.AssignedWorkflow.WorkflowName.length > 0 && data.AssignedWorkflow.WorkflowInstanceId != "-1") {
            output = data.AssignedWorkflow.WorkflowName;
        }
        return output;
    }

    function renderPandoXProcessInitColumn(data) {
        var output = "";

        if (data.PandoX.CategoryName && data.PandoX.CategoryName.length > 0 &&
            data.PandoX.CategoryId && data.PandoX.CategoryId != "-1") {
            output += '<div style="display:inline-block;min-width:42px;color: black;">' + $ctrl.srchPreCategoryLabel + ': &nbsp;&nbsp;</div>';
            output += '<span>' + $translate.instant(data.PandoX.CategoryName) + '</span>';
        }
        return output;
    }

    function ownerDropdown_change(e) {
        var value = this.value();

        if (value != "-1")
            $('#OwnerTemplateWrapper').show();
        else
            $('#OwnerTemplateWrapper').hide();
        CheckForTemplateErrors();
    }

    function accessDropdown_change(e) {
        var value = this.value();

        if (value != "-1")
            $('#AccessTemplateWrapper').show();
        else
            $('#AccessTemplateWrapper').hide();
        CheckForTemplateErrors();
    }

    function templateDropdown_change(e) {
        CheckForTemplateErrors();
    }

    function ownerEditor(container, options) {
        $('<div style="display:inline-block;min-width:42px;color: black;padding-right:5px;">' + $ctrl.srchPreGroupLabel + ': </div>').appendTo(container);
        $('<input id="' + options.field + '" name="' + options.field + '" style="width:80%" />')
            .appendTo(container);

        $("#" + options.field).kendoDropDownList({
            autoBind: true,
            dataTextField: "GroupName",
            dataValueField: "GroupId",
            dataSource: $ctrl.pandoGroups,
            change: ownerDropdown_change
        });

        var templateHtml = '<div id="OwnerTemplateWrapper" style="margin-top: 8px;"><div style="display:inline-block;min-width:42px;color: black; padding-right: 5px;"><icon class="icon aa-icon-core-email-icon-000000" style="color:black;top:3px;"></icon>:&nbsp;&nbsp;</div>'
        templateHtml += '<input id="OwnerTemplate" name="OwnerTemplate" style="width:80%" />';
        templateHtml += '</div >';

        $(templateHtml).appendTo(container);

        $("#OwnerTemplate").kendoDropDownList({
            autoBind: true,
            dataTextField: "TemplateName",
            dataValueField: "TemplateId",
            dataSource: $ctrl.templates,
            change: templateDropdown_change
        });

        if (options.model.Owner.GroupId != "-1") {
            $('#OwnerTemplateWrapper').show();
        }
        else {
            $('#OwnerTemplateWrapper').hide();
        }

    }

    function accessEditor(container, options) {
        $('<div style="display:inline-block;min-width:42px;color: black;padding-right:5px;">' + $ctrl.srchPreGroupLabel + ': </div>').appendTo(container);
        $('<input id="' + options.field + '" name="' + options.field + '" style="width:80%" />')
            .appendTo(container);

        $("#" + options.field).kendoDropDownList({
            autoBind: true,
            dataTextField: "GroupName",
            dataValueField: "GroupId",
            dataSource: $ctrl.pandoGroups,
            change: accessDropdown_change
        });

        var templateHtml = '<div id="AccessTemplateWrapper" style="margin-top: 8px;"><div style="display:inline-block;min-width:42px;color: black; padding-right: 5px;"><icon class="icon aa-icon-core-email-icon-000000" style="color:black;top:3px;"></icon>:&nbsp;&nbsp;</div>'
        templateHtml += '<input id="AccessTemplate" name="AccessTemplate" style="width:80%" />';
        templateHtml += '</div >';

        $(templateHtml).appendTo(container);

        $("#AccessTemplate").kendoDropDownList({
            autoBind: true,
            dataTextField: "TemplateName",
            dataValueField: "TemplateId",
            dataSource: $ctrl.templates,
            change: templateDropdown_change
        });

        if (options.model.Access.GroupId != "-1") {
            $('#AccessTemplateWrapper').show();
        }
        else {
            $('#AccessTemplateWrapper').hide();
        }

    }

    function pandoXEditor(container, options) {
        $('<div style="display:inline-block;min-width:42px;color: black;padding-right:5px;">' + $ctrl.srchPreCategoryLabel + ': </div>').appendTo(container);
        $('<input id="' + options.field + '" name="' + options.field + '" style="width:80%" />')
            .appendTo(container);

        $("#" + options.field).kendoDropDownList({
            autoBind: true,
            dataTextField: "CategoryName",
            dataValueField: "CategoryId",
            dataSource: $ctrl.pandoXRuleCategoryArray
        });
    }

    function workflowDropDownEditor(container, options) {
        $('<input name="' + options.field + '" />')
            .appendTo(container)
            .kendoDropDownList({
                autoBind: true,
                dataTextField: "WorkflowName",
                dataValueField: "WorkflowInstanceId",
                dataSource: $ctrl.pandoWorkflowTemplates
            });
    }

    function initGridOptions() {
        var sixthColumn = { field: "AssignedWorkflow", title: "Process Assignment", width: "15%", editor: workflowDropDownEditor, template: function (dataItem) { return renderWorkflowColumn(dataItem); } };
        var seventhColumn = { field: "MaxAssignEntries", title: "Daily Max", width: "5%", editor: maxEntriesEditor, headerAttributes: { style: "text-align: center;" }, attributes: { style: "text-align: center;" } };

        if ($ctrl.isDealerPandoXEnabled) {
            sixthColumn = {
                field: "PandoX",
                headerTemplate: "Inbox Process Initiation <icon icon=\"'info'\" class='info -small' popover-append-to-body='true' uib-popover='{{$ctrl.srchPandoXPopupText}}' popover-class='popover' popover-placement='top-right' popover-trigger=\"'mouseenter'\"></icon>",
                width: "5%",
                editor: pandoXEditor,
                template: function (dataItem) {
                    return renderPandoXProcessInitColumn(dataItem);
                }
            };
            seventhColumn = { field: "PandoXRuleQuantity", title: "Max / Run", width: "3%", editor: maxEntriesEditor, headerAttributes: { style: "text-align: center;" }, attributes: { style: "text-align: center;" } };
        }

        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            navigatable: false,
            pageable: false,
            scrollable: false,
            resizable: true,
            //height: 370,
            columns: [
                {
                    field: "Name", title: "Name", width: "20%", sortable: false, attributes: { style: "cursor: pointer;" },
                    template: "<img class='svg-icon xsm' src='" + upDownArrowIconPath + "' /></a> #= Name #"
                },
                {
                    field: "SearchActiveStartDate",
                    title: "Start Date",
                    template: function (dataItem) { return renderStartDateColumn(dataItem); },
                    format: "{0:d}",
                    width: "10",
                },
                {
                    field: "SearchActiveEndDate",
                    title: "End Date",
                    template: function (dataItem) { return renderEndDateColumn(dataItem); },
                    format: "{0:d}",
                    width: "10",
                },
                { hidden: !$ctrl.leadAssignOwnerAccessEnabled, field: "Owner", title: "Owner", width: "20%", editor: ownerEditor, template: function (dataItem) { return renderOwnerColumn(dataItem); } },
                { hidden: !$ctrl.leadAssignOwnerAccessEnabled, field: "MaxOwnerEntries", title: "Daily Max", width: "5%", editor: maxEntriesEditor, headerAttributes: { style: "text-align: center;" }, attributes: { style: "text-align: center;" } },
                { hidden: !$ctrl.leadAssignOwnerAccessEnabled, field: "Access", title: "Access", width: "20%", editor: accessEditor, template: function (dataItem) { return renderAccessColumn(dataItem); } },
                { hidden: !$ctrl.leadAssignOwnerAccessEnabled, field: "MaxAccessEntries", title: "Daily Max", width: "5%", editor: maxEntriesEditor, headerAttributes: { style: "text-align: center;" }, attributes: { style: "text-align: center;" } },
                sixthColumn,
                seventhColumn,
                {
                    command:
                        [{ name: "edit", template: "<a class='k-button k-grid-edit' href=''><img class='svg-icon xsm' src='" + pencilIconPath + "' /></a>" },
                        {
                            name: "Delete",
                            template: "<a class='k-button k-grid-Delete' style='margin-left: 5px;' href=''><img class='svg-icon xsm' src='" + trashIconPath + "' /></a>",
                            click: function (e) {
                                e.preventDefault(); //prevent page scroll reset
                                var tr = $(e.target).closest("tr"); //get the row for deletion
                                var data = this.dataItem(tr); //get the row data so it can be referred later

                                var modal = $uibModal.open({
                                    appendTo: angular.element($document[0].querySelector('#aa-app')),
                                    animation: true,
                                    component: 'confirmationModal',
                                    resolve: {
                                        title: function () {
                                            return $ctrl.srchPreDeleteTitle;
                                        },
                                        message: function () {
                                            return $ctrl.srchPreDeleteMsg;
                                        },
                                        submitButtonVisible: function () {
                                            return true;
                                        },
                                        submitButtonDisabled: function () {
                                            return false;
                                        },
                                        submitButtonText: function () {
                                            return "confirm"; // translation key
                                        }
                                    }
                                });

                                modal.result.then(function () {
                                    var grid = $("#gridManageSearches").data("kendoGrid");
                                    grid.dataSource.remove(data)  //prepare a "destroy" request
                                    grid.dataSource.sync()  //actually send the request (might be ommited if the autoSync option is enabled in the dataSource)
                                });
                            }
                        },
                        {
                            name: "Execute",
                            template: `<a class='k-button k-grid-Execute' ${(!$ctrl.isDealerPandoXEnabled) ? "hidden" : ""} href='' title="${$ctrl.srchPreExecutePandoXTooltip}">${$ctrl.srchPreExecutePandoXLabel}</a>`,
                            click: function (e) {
                                e.preventDefault();
                                var tr = $(e.target).closest("tr"); //get the row for deletion
                                var data = this.dataItem(tr); //get the row data so it can be referred later
                                executePandoXSearch(data);

                                // Disable the execute button to limit users accidentally submitting many search executions
                                $(e.target).addClass('k-state-disabled');
                            },
                            visible: function (dataItem) {
                                var today = new Date();
                                today.setHours(0, 0, 0, 0);
                                var startExpired = dataItem.SearchActiveStartDate != null && dataItem.SearchActiveStartDate > today;
                                var endExpired = dataItem.SearchActiveEndDate != null && dataItem.SearchActiveEndDate < today;

                                return (dataItem.PandoX
                                    && dataItem.PandoX.CategoryName
                                    && dataItem.PandoXRuleQuantity
                                    && dataItem.PandoXRuleQuantity > 0
                                    && !startExpired
                                    && !endExpired
                                );
                            }
                        }],
                    title: "Actions",
                    width: "3%",
                    headerAttributes: { style: "text-align: center;" }
                },
            ],
            editable: "inline",
            edit: onRowBound,
            dataBound: onDataBound,
            cancel: onRowBound,
            filterable: false
        };
    }

    function startDateExpired(startDate) {
        if (startDate == null)
            return false;

        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return startDate > today;
    }

    function endDateExpired(endDate) {
        if (endDate == null)
            return false;

        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return endDate < today;
    }

    function executePandoXSearch(data) {
        searchPresetService.executePandoXSearch($ctrl.selectedDealerId, getUserConditionIdFromKendoId(data.Id))
            .then(() => {
                const executeSuccessKey = 'srchPreExecutePandoXSuccess';
                $translate([executeSuccessKey]).then(function (translations) {
                    toaster.pop({
                        type: messageTypes.success,
                        title: '',
                        body: translations[executeSuccessKey],
                        showCloseButton: false,
                    });
                });
            });
    }

    function renderGridToolbar() {
        var element = $("#grid-toolbar");

        element.kendoToolBar({
            resizable: false,
            items: [
                {
                    type: "button",
                    attributes: { style: "float: right; margin-top: 2px; margin-right: 5px; font-size: 12px;" },
                    text: $ctrl.srchPreClearInputsBtn,
                    click: function (e) {
                        var modal = $uibModal.open({
                            appendTo: angular.element($document[0].querySelector('#aa-app')),
                            animation: true,
                            component: 'confirmationModal',
                            resolve: {
                                title: function () {
                                    return $ctrl.srchPreClearInputsTitle;
                                },
                                message: function () {
                                    return $ctrl.srchPreClearInputsMsg;
                                },
                                submitButtonVisible: function () {
                                    return true;
                                },
                                submitButtonDisabled: function () {
                                    return false;
                                },
                                submitButtonText: function () {
                                    return "confirm"; // translation key
                                }
                            }
                        });

                        modal.result.then(function () {
                            var data = $('#gridManageSearches').data('kendoGrid').dataSource.data();

                            for (var i = 0; i < data.length; i++) {
                                var row = data[i];

                                row.Owner.GroupId = "-1";
                                row.Owner.GroupName = "";
                                row.OwnerTemplate.TemplateId = "-1";
                                row.OwnerTemplate.TemplateName = "";
                                row.MaxOwnerEntries = null;

                                row.Access.GroupId = "-1";
                                row.Access.GroupName = "";
                                row.AccessTemplate.TemplateId = "-1";
                                row.AccessTemplate.TemplateName = "";
                                row.MaxAccessEntries = null;

                                row.PandoX.CategoryId = "-1";
                                row.PandoX.CategoryName = "";
                                row.PandoXRuleQuantity = null;

                                row.AssignedWorkflow.WorkflowInstanceId = "-1";
                                row.AssignedWorkflow.WorkflowName = "";
                                row.MaxAssignEntries = null;
                                row.SearchActiveStartDate = null;
                                row.SearchActiveEndDate = null;
                            }

                            updatePresets();
                            $('#gridManageSearches').data('kendoGrid').refresh();
                        });
                    }
                }
            ]
        });
    }

    function savePresetSettings(settings) {
        var clearOwnership = settings.clearOwnership,
            clearAccess = settings.clearAccess;

        if (!clearOwnership && !clearAccess)
            return;

        searchPresetService.updateAutoAssignments({ dealerID: $ctrl.selectedDealerId, resetOwnerAutoAssignments: clearOwnership, resetAccessAutoAssignments: clearAccess })
            .then(success => {
                if (success) {
                    $translate([successKey]).then(function (translations) {
                        toaster.pop({
                            type: messageTypes.success,
                            title: translations[successKey],
                            body: $ctrl.srchPreClearAutoMsg,
                            bodyOutputType: 'trustedHtml',
                            showCloseButton: false,
                        });
                    })
                }
            })
    }

    function saveSelectedSearchPresets(searchPresets) {
        var grid = $("#gridManageSearches").data("kendoGrid");
        var data = grid.dataSource.data();

        var maxOrder = 0;
        if (data.length > 0) {
            maxOrder = Math.max.apply(Math, data.map(function (presets) { return presets.Order; }))
        }

        for (let i = 0; i < searchPresets.selectedSearchPresets.length; i++) {
            var searchPreset = searchPresets.selectedSearchPresets[i];
            data.push({
                Id: getKendoIdFromUserConditionId(searchPreset.userSearchConditionID),
                Name: searchPreset.searchConditionName,
                Order: ++maxOrder,
                Owner: { GroupName: "", GroupId: "-1" },
                OwnerTemplate: { TemplateName: "", TemplateId: "-1" },
                MaxOwnerEntries: null,
                Access: { GroupName: "", GroupId: "-1" },
                AccessTemplate: { TemplateName: "", TemplateId: "-1" },
                MaxAccessEntries: null,
                PandoX: { CategoryName: "", CategoryId: "-1" },
                PandoXRuleQuantity: null,
                AssignedWorkflow: { WorkflowName: "", WorkflowInstanceId: "-1" },
                MaxAssignEntries: null,
                IsPriority: searchPreset.isPriority,
                SearchActiveStartDate: searchPreset.searchActiveStartDate,
                SearchActiveEndDate: searchPreset.searchActiveEndDate,
            });
        }
        updatePresets();
        grid.refresh();
    }

    function hintElement(element) { // Customize the hint
        var grid = $("#gridManageSearches").data("kendoGrid"),
            table = grid.table.clone(),
            wrapperWidth = grid.wrapper.width(),
            wrapper = $("<div class='preset-grid-container'><div class='k-grid k-widget'></div></div>").width(wrapperWidth),
            hint;

        table.find("thead").remove(); // Remove Grid's header from the hint
        table.find("tbody").empty(); // Remove the existing rows from the hint
        table.wrap(wrapper); // Wrap the table
        table.append(element.clone().removeAttr("id")); // Append the dragged element

        hint = table.parent().parent(); // Get the wrapper
        return hint; // Return the hint element
    }

    function AddDragDrop() {
        var grid = $("#gridManageSearches").data("kendoGrid");

        if (grid === undefined) return;

        grid.table.kendoSortable({
            hint: hintElement,
            cursor: "move",
            placeholder: function (element) {
                return element.clone().addClass("k-state-hover").css("opacity", 0.65);
            },
            container: "#gridManageSearches tbody",
            filter: ">tbody > tr:not(.k-grid-edit-row)",
            change: function (e) {
                var grid = $("#gridManageSearches").data("kendoGrid"),
                    oldIndex = e.oldIndex, // The old position
                    newIndex = e.newIndex, // The new position
                    view = grid.dataSource.view(),
                    dataItem = grid.dataSource.getByUid(e.item.data("uid")); // Retrieve the moved dataItem

                dataItem.Order = newIndex + 1; // Update the order
                dataItem.dirty = true;

                // Shift the order of the records
                if (oldIndex < newIndex) {
                    for (var i = oldIndex + 1; i <= newIndex; i++) {
                        view[i].Order--;
                        view[i].dirty = true;
                    }
                } else {
                    for (var j = oldIndex - 1; j >= newIndex; j--) {
                        view[j].Order++;
                        view[j].dirty = true;
                    }
                }

                grid.dataSource.sync();
            }
        });
    }
}
