"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventType;
(function (EventType) {
    EventType[EventType["engagement"] = 0] = "engagement";
})(EventType = exports.EventType || (exports.EventType = {}));
var EngagementEventTypeEnum;
(function (EngagementEventTypeEnum) {
    EngagementEventTypeEnum[EngagementEventTypeEnum["newServiceAppointment"] = 1] = "newServiceAppointment";
    EngagementEventTypeEnum[EngagementEventTypeEnum["inService"] = 2] = "inService";
    EngagementEventTypeEnum[EngagementEventTypeEnum["emailOpened"] = 3] = "emailOpened";
    EngagementEventTypeEnum[EngagementEventTypeEnum["emailReplied"] = 4] = "emailReplied";
    EngagementEventTypeEnum[EngagementEventTypeEnum["pURLView"] = 5] = "pURLView";
    EngagementEventTypeEnum[EngagementEventTypeEnum["pURLLeadSubmission"] = 6] = "pURLLeadSubmission";
    EngagementEventTypeEnum[EngagementEventTypeEnum["websiteEngaged"] = 7] = "websiteEngaged";
    EngagementEventTypeEnum[EngagementEventTypeEnum["websiteLeadSubmission"] = 8] = "websiteLeadSubmission";
    EngagementEventTypeEnum[EngagementEventTypeEnum["onlineServiceReview"] = 9] = "onlineServiceReview";
    EngagementEventTypeEnum[EngagementEventTypeEnum["onlineSalesReview"] = 10] = "onlineSalesReview";
    EngagementEventTypeEnum[EngagementEventTypeEnum["textMessageReplied"] = 11] = "textMessageReplied";
})(EngagementEventTypeEnum = exports.EngagementEventTypeEnum || (exports.EngagementEventTypeEnum = {}));
