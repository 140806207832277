
/* injects from baggage-loader */
require('./select-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('selectModal', {
    templateUrl: '/app/components/deal-sheet/modals/select-modal.component.html',
    controller: SelectModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function SelectModalController() {
    var $ctrl = this;

    $ctrl.selection
    $ctrl.onSelect = onSelect;

    function onSelect(selection) {
        $ctrl.selection = selection;
    }
}
