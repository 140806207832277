import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VehicleService } from '../../../../ajs-upgraded-providers';
import { ToasterLoggerService } from '../../../../core/toaster-logger.service';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { MileageEditModalComponent } from '../mileage-edit-modal/mileage-edit-modal.component';
import { NgxModalWrapperService } from '../../../../services/ngx-modal-wrapper.service';

@Component({
    selector: 'mileage-edit-action',
    templateUrl: './mileage-edit-action.component-ng.html',
    styleUrls: ['./mileage-edit-action.component.scss']
})
export class MileageEditActionComponent {
    @Input() recordedMileage: number;
    @Input() recordedDate: any;
    @Input() estimatedMileage: number;
    @Input() permissions: DealSheetPermissions;
    @Input() opportunityId: string;
    @Output() onMileageValueChange = new EventEmitter<any>();
    @Output() startWorkingState = new EventEmitter<any>();

    bsModalRef: BsModalRef;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
        @Inject(VehicleService) private vehicleService) {
    }

    public editMileageModal(mileage: number, readOnDate: Date) {
        this.bsModalRef = this.modalService.show(MileageEditModalComponent);

        this.bsModalRef.content.onSaveChanges.subscribe((result: any) => {
            this.startWorkingState.emit();
            this.vehicleService.updateMileAge(this.opportunityId, result.mileage, result.readOnDate)
                .then(data => {
                    this.onMileageValueChange.emit({ $event: { mileageValue: result } });
                });
        });
    }
}
