"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".log-action-shortcuts[_ngcontent-%COMP%]{float:left;padding-left:10px}.log-action-shortcuts[_ngcontent-%COMP%]     .shortcut-action-btn{background:#192c54;margin-left:5px}.log-action-shortcuts[_ngcontent-%COMP%]     .shortcut-action-btn:hover{color:#c1c2c4!important;background:-webkit-gradient(linear,left top,left bottom,from(#192c54),to(#192c54));background:linear-gradient(to bottom,#192c54 0,#192c54 100%);background:-webkit-gradient(linear,left top,left bottom,from(#192c54),to(#00548f));background:linear-gradient(#192c54,#00548f)}.log-action-shortcuts[_ngcontent-%COMP%]     .email-template-action-wrapper{display:inline}.log-action-shortcuts[_ngcontent-%COMP%]     .email-template-action-wrapper .shortcut-action-btn{margin-left:0}.log-action-shortcuts[_ngcontent-%COMP%]     .text-action-wrapper{display:inline}.log-action-shortcuts[_ngcontent-%COMP%]     .text-action-wrapper .shortcut-action-btn{margin-left:0}"];
exports.styles = styles;
