EmailTemplateActionController.$inject = ["$q", "legacyService", "eulaModalService", "featureToggleService", "templateService", "translateErrorKeys", "$translate", "logger"];

/* injects from baggage-loader */
require('./email-template-action.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('emailTemplateAction', {
  templateUrl: '/app/components/deal-sheet/email-template-action.component.html',
  controller: EmailTemplateActionController,
  bindings: {
    opportunity: '<',
    permissions: '<',
    onComplete: '&',
    isEuroLite: '<',
    isShortcutButton: '<'
  }
});
/* @ngInject */

function EmailTemplateActionController($q, legacyService, eulaModalService, featureToggleService, templateService, translateErrorKeys, $translate, logger) {
  var $ctrl = this;
  $ctrl.getUrl = getUrl;
  $ctrl.checkEULA = checkEULA;
  $ctrl.$onChanges = onChanges;
  $ctrl.email = null;

  function onChanges(changes) {
    if (changes.opportunity && changes.opportunity.customer && changes.opportunity.customer.emailAddress) $ctrl.email = $ctrl.opportunity.customer.emailAddress.value;
  }

  function checkEULA() {
    return $q.all([eulaModalService.validateEula(), templateService.validateMaxEmailsPerDay($ctrl.opportunity.id)]).then(function (response) {
      var acceptedEula = response[0];
      var templateServiceResponse = response[1]; //Shouldn't there be a warning message for EULA not accepted?

      if (!templateServiceResponse.data.success) {
        $translate([translateErrorKeys.maxEmailsExceeded], {
          maxEmailsPerEntityPerDay: templateServiceResponse.data.maxEmailsPerEntityPerDay
        }).then(function (translation) {
          logger.warning(translation.maxEmailsExceeded, null, null);
        });
      }

      if ($ctrl.opportunity.customer.doNotEmail) {
        logger.warning("doNotEmailCheckedWarning");
      }

      return acceptedEula && templateServiceResponse.data.success && !$ctrl.opportunity.customer.doNotEmail;
    });
  }

  function getUrl() {
    return eulaModalService.validateEula().then(function (accepted) {
      if (accepted) {
        $ctrl.url = legacyService.getPrintTemplateUrl($ctrl.opportunity);
        return true;
      } else {
        return false;
      }
    });
  }
}