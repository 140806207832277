import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeZones, TimeZonesById } from '../constants/time-zones';
import moment from 'moment-timezone';
import { StorageService } from '../modules/storage/storage.service';

@Pipe({
    name: 'localeDateTime'
})
export class LocaleDateTimePipe extends DatePipe implements PipeTransform {

    constructor(private storageService: StorageService) {
        super('en-US');
    }

    transform(date: any, args?: any): any {

        const timeZoneId = this.storageService.getItem('timezoneId') || TimeZones.pacificStandardTime.id;

        let utcOffsetString;


        // If `date` is a Date, it has already been converted to local time
        // If `date` is a string, a trailing Z indicates a UTC timestamp
        const isUTCFormat = !(date instanceof Date) && date.toString().endsWith('Z');

        if (isUTCFormat) {
            const timeZone = TimeZonesById.get(timeZoneId);

            if (timeZone) {
                // moment-timezone does the hard work of deciding whether DST was
                // in effect at the given DateTime.
                const isDSTAtGivenTime = moment(date).tz(timeZone.tzdbName).isDST();

                if (isDSTAtGivenTime) {
                    utcOffsetString = timeZone.daylight;
                } else {
                    utcOffsetString = timeZone.standard;
                }
            }
        }

        const cultureName = this.storageService.getItem('cultureName') || 'en-us';

        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                return super.transform(date, 'dd/MM/yyyy hh:mm a', utcOffsetString);
            case 'en-ca':
                return super.transform(date, 'dd/MM/yyyy h:mm a', utcOffsetString);
            case 'fr-ca':
                return super.transform(date, 'yyyy-MM-dd hh:mm a', utcOffsetString);
            case 'fr-fr':
                return super.transform(date, 'dd/MM/yyyy H:mm', utcOffsetString);
            case 'en-us':
            default:
                return super.transform(date, 'MM/dd/yyyy h:mm a', utcOffsetString);
        }
    }


}
