"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./customer-connect-sub-detail.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../customer-connect-log-action/customer-connect-log-action.component.ngfactory"));
var i3 = __importStar(require("../customer-connect-log-action/customer-connect-log-action.component"));
var i4 = __importStar(require("../../../../services/log-action-shortcut.service"));
var i5 = __importStar(require("ngx-bootstrap/modal"));
var i6 = __importStar(require("../customer-connect-schedule-action/customer-connect-schedule-action.component.ngfactory"));
var i7 = __importStar(require("../customer-connect-schedule-action/customer-connect-schedule-action.component"));
var i8 = __importStar(require("../../../../services/date-format.service"));
var i9 = __importStar(require("../../../../modules/storage/storage.service"));
var i10 = __importStar(require("../../../../services/user.service"));
var i11 = __importStar(require("@angular/common"));
var i12 = __importStar(require("./customer-connect-sub-detail.component"));
var styles_CustomerConnectSubDetailComponent = [i0.styles];
var RenderType_CustomerConnectSubDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerConnectSubDetailComponent, data: {} });
exports.RenderType_CustomerConnectSubDetailComponent = RenderType_CustomerConnectSubDetailComponent;
function View_CustomerConnectSubDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-connect-log-action", [], null, [[null, "onLogScheduleSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogScheduleSubmit" === en)) {
        var pd_0 = (_co.onLogScheduleSubmit.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomerConnectLogActionComponent_0, i2.RenderType_CustomerConnectLogActionComponent)), i1.ɵdid(1, 638976, null, 0, i3.CustomerConnectLogActionComponent, [i4.LogActionShortcutService, i5.BsModalService], { subAction: [0, "subAction"], permissions: [1, "permissions"], dialogParams: [2, "dialogParams"], isEuroLite: [3, "isEuroLite"] }, { onLogScheduleSubmit: "onLogScheduleSubmit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subAction; var currVal_1 = _co.permissions; var currVal_2 = _co.dialogParams; var currVal_3 = _co.isEuroLite; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_CustomerConnectSubDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-connect-schedule-action", [], null, [[null, "onLogScheduleSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLogScheduleSubmit" === en)) {
        var pd_0 = (_co.onLogScheduleSubmit.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_CustomerConnectScheduleActionComponent_0, i6.RenderType_CustomerConnectScheduleActionComponent)), i1.ɵdid(1, 638976, null, 0, i7.CustomerConnectScheduleActionComponent, [i8.DateFormatService, i9.StorageService, i10.UserService], { subAction: [0, "subAction"], permissions: [1, "permissions"], dialogParams: [2, "dialogParams"] }, { onLogScheduleSubmit: "onLogScheduleSubmit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subAction; var currVal_1 = _co.permissions; var currVal_2 = _co.dialogParams; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CustomerConnectSubDetailComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerConnectSubDetailComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerConnectSubDetailComponent_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.subAction === _co.ActivityActionEnum.Log_Call) || (_co.subAction === _co.ActivityActionEnum.Post_Note)); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.subAction === _co.ActivityActionEnum.Schedule_Appt) || (_co.subAction === _co.ActivityActionEnum.Schedule_Call)); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_CustomerConnectSubDetailComponent_0 = View_CustomerConnectSubDetailComponent_0;
function View_CustomerConnectSubDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-connect-sub-detail", [], null, null, null, View_CustomerConnectSubDetailComponent_0, RenderType_CustomerConnectSubDetailComponent)), i1.ɵdid(1, 114688, null, 0, i12.CustomerConnectSubDetailComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CustomerConnectSubDetailComponent_Host_0 = View_CustomerConnectSubDetailComponent_Host_0;
var CustomerConnectSubDetailComponentNgFactory = i1.ɵccf("customer-connect-sub-detail", i12.CustomerConnectSubDetailComponent, View_CustomerConnectSubDetailComponent_Host_0, { permissions: "permissions", subAction: "subAction", dialogParams: "dialogParams", isEuroLite: "isEuroLite" }, { onAction: "onAction", onLogScheduleSubmit: "onLogScheduleSubmit" }, []);
exports.CustomerConnectSubDetailComponentNgFactory = CustomerConnectSubDetailComponentNgFactory;
