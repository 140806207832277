import { Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { PaymentTypes, ConquestTypes, SaleTypes } from '../../../../constants';

@Component({
  selector: 'payments',
  templateUrl: './payments.component-ng.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnChanges {
    @Input() type: any;
    @Input() contract: any;
    @Input() permissions: DealSheetPermissions;
    @Input() alertExists: any;
    @Input() flexAlertExists: any;

    public paymentTypes = PaymentTypes;
    public conquestTypes = ConquestTypes;
    public saleTypes = SaleTypes;
    public absoluteValue = Math.abs;
    public shouldShowPaymentBeforeImprovement: boolean;
    public paymentsRemainingMo: any;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
      // Only display this if the improvement is at least 5 CurrencyBucks
      this.shouldShowPaymentBeforeImprovement =
            this.contract && this.contract.paymentBeforeImprovement - this.contract.payment >= 5;

      if (this.contract && this.contract.lastPaymentDate) {
        const lastPaymentDate = new Date(this.contract.lastPaymentDate);
        const lastPaymentMonths = (lastPaymentDate.getFullYear() * 12) + lastPaymentDate.getMonth();
        const now = new Date();
        const currentDateMonths = (now.getFullYear() * 12) + now.getMonth();
        this.paymentsRemainingMo = lastPaymentMonths - currentDateMonths;
        if (this.paymentsRemainingMo < 0) {
          this.paymentsRemainingMo = 0;
        }
      }
    }
}
