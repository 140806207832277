"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var HttpInterceptorOptionsService = /** @class */ (function () {
    function HttpInterceptorOptionsService() {
        this._disableErrorHandler = false;
        this._overrideDealerIds = [];
    }
    HttpInterceptorOptionsService.prototype.disableErrorHandler = function () {
        this._disableErrorHandler = true;
    };
    Object.defineProperty(HttpInterceptorOptionsService.prototype, "disableErrorHandlerSetting", {
        get: function () {
            var returnValue = this._disableErrorHandler;
            this._disableErrorHandler = false;
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    HttpInterceptorOptionsService.prototype.setOverrideDealerIds = function (overrideDealerIds) {
        this._overrideDealerIds = overrideDealerIds;
    };
    Object.defineProperty(HttpInterceptorOptionsService.prototype, "overrideDealerIdsSetting", {
        get: function () {
            var returnValue = this._overrideDealerIds;
            this._overrideDealerIds = [];
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    HttpInterceptorOptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorOptionsService_Factory() { return new HttpInterceptorOptionsService(); }, token: HttpInterceptorOptionsService, providedIn: "root" });
    return HttpInterceptorOptionsService;
}());
exports.HttpInterceptorOptionsService = HttpInterceptorOptionsService;
