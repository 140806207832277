<span class="recentDealSheets-container">
    <span (click)="toggleRecentDealSheets()">
        <icon class="icon alert-blocks-svg "></icon>
    </span>
    <span class="recentDealSheets header-modal-style" *ngIf="visible">
        <h5 class="h5-modal" translate="recentDealSheets"></h5>
        <div class="spinner" [ngBusy]="{busy: busy, delay: 200}" *ngIf="!recentDealSheets"></div>
        <ul class="deal-sheet-list">
            <li class="deal-sheet-item" *ngFor="let dealSheet of recentDealSheets">
                <a href="#" (click)="openDealSheet(dealSheet.entityId)">{{dealSheet.clientName}}</a>
                <alerts [values]="getAlertObjects(dealSheet)" [showRelavantInfo]="false"></alerts>
            </li>
        </ul>
    </span>
</span>

