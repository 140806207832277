import angular from 'angular';

angular.module('AaApp.Opp').directive('phoneNumber', phoneNumberDirective);

/* @ngInject */
function phoneNumberDirective($filter) {
    var localePhoneFilter = $filter('localePhone')
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function toDigits(text) {
                if (text) {
                    return text.replace(/\D/g, '');
                }
                else {
                    return '';
                }
            }

            function toFormatted(text) {
                if (text) {
                    return localePhoneFilter(text);
                }
            }

            ngModelCtrl.$parsers.push(toDigits);
            ngModelCtrl.$formatters.push(toFormatted);

            // Update the UI after the user types a new value
            element.bind('blur', function () {
                var formatted = localePhoneFilter(ngModelCtrl.$modelValue);
                element.val(formatted);
            });
        }
    };
}
