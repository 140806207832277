
/* injects from baggage-loader */
require('./credit-prescreen-spinner.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('creditPrescreenSpinner', {
    templateUrl: '/app/components/deal-sheet/credit-prescreen-spinner.component.html',
    controller: CreditPrescreenSpinnerController
});

function CreditPrescreenSpinnerController() {
    // This is a dummy component, just necessary to get the build system to
    // compile the 'credit-prescreen-spinner.component.html' template,
    // which is used by the creditPrescreen component.
}
