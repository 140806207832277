localeMoneyFactorFilter.$inject = ["storageService"];
import angular from 'angular';
angular.module('AaApp.Opp').filter('localeMoneyFactor', localeMoneyFactorFilter);
localeMoneyFactorFilter.$inject = ['storageService'];
/* @ngInject */

function localeMoneyFactorFilter(storageService) {
  return function (number) {
    if (typeof number == 'number') {
      var savedCultureName = storageService.getItem('cultureName');
      var cultureName = savedCultureName ? savedCultureName : 'en-us';
      return number.toLocaleString(cultureName, {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5
      });
    }
  };
}