import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.promise";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
RunPostMessageInit.$inject = ["$auth", "$document", "$logFactory", "$state", "$translate", "$uibModal", "announcementKey", "authorizationService", "dealSheetService", "dealerService", "eulaModalService", "globalIframeService", "legacyUrlMappings", "logger", "menu", "menuService", "storageService", "tokenService", "urlHelper", "userService"];
RunUiRouterTransitionAuthorization.$inject = ["$translate", "warningTitleKey", "translateErrorKeys", "$transitions", "$state", "userService", "logger"];
RunUiRouterTransitionsInit.$inject = ["_", "externalService", "$auth", "$location", "$state", "$transitions", "$rootScope", "menu", "oidc", "tokenService", "storageService", "loggedInDataInitializationService"];
RunUiRouterTransitionSpinner.$inject = ["$transitions", "$rootScope", "$timeout", "$q"];
runUserInit.$inject = ["$auth", "$interval", "$rootScope", "notificationService", "tokenService", "loggedInDataInitializationService"];
RunInitMessageListener.$inject = ["$state", "coreApi", "dealerService", "globalIframeService"];
Run.$inject = ["$auth", "$http", "$location", "$rootScope", "$state", "$transitions", "$translate", "$translatePartialLoader", "$window", "externalService", "globalIframeService", "localePaths", "logger", "menu", "oidc", "storageService"];
import $ from 'jquery';
import angular from 'angular';
import _ from 'lodash';
import { removeQuotes, resizeHeader, getPortalSubPath } from '../scripts/init';
import { silentRefreshFailed$, tokenMissing$, unauthorized$, externalLoggedIn$ } from '../modules/oidc-auth/events';
import { importKendo } from './report-routes.config';
import { getUrlComponents } from '../utilities/url';
import { appSettings } from './config.module';
import { sendSSOToken } from '../services/legacy.service';
angular.module('AaApp.Opp').run(Run).run(RunInitMessageListener).run(RunUiRouterTransitionSpinner).run(RunUiRouterTransitionsInit).run(RunUiRouterTransitionAuthorization).run(RunPostMessageInit).run(runUserInit).run(lazyLoadKendo);
/* @ngInject */

function Run($auth, $http, $location, $rootScope, $state, $transitions, $translate, $translatePartialLoader, $window, externalService, globalIframeService, localePaths, logger, menu, oidc, storageService) {
  silentRefreshFailed$.subscribe(function () {
    $auth.signIn();
  });
  $rootScope.$on('$translatePartialLoaderStructureChanged', function () {
    var cultureName = storageService.getItem('cultureName');

    if (cultureName) {
      $translate.use(cultureName);
    }
  });
  $.Redactor.opts.$http = $http; // error text always needs to be initialized

  $translatePartialLoader.addPart(localePaths.error);
  localRedirectToOpportunitiesUrlInit();
  errorUnauthorizedInit();
  iframeBackwardsCompatibilityInit();

  function localRedirectToOpportunitiesUrlInit() {
    // handles the Portal URL -- redirects to itself (but with Opportunities.autoalert.com url)
    if (window.location.protocol + '//' + window.location.host != oidc.logoutUri && !(!$auth.isAuthenticated() && window.location.href.indexOf("external") > -1)) {
      window.location.href = oidc.logoutUri;
    }
  }

  function errorUnauthorizedInit() {
    unauthorized$.subscribe(function (data) {
      if ($state.current.name == 'errorUnauthorized') {
        return;
      }

      if (data && data.response) {
        if (data.response === 'User is disabled') {
          $state.go('errorUnauthorized', {
            errorState: 'disabled'
          });
        } else if (data.response === 'User is locked') {
          $state.go('errorUnauthorized', {
            errorState: 'locked'
          });
        } else if (data.response === 'IP Address is not valid') {
          $state.go('errorUnauthorized', {
            errorState: 'invalidIPAddress'
          });
        }
      } else {
        $state.go('errorUnauthorized');
      }
    });
  }

  function iframeBackwardsCompatibilityInit() {
    $rootScope.$on('RUN_USER_INIT.REPOSITION_UI_VIEW', function () {
      repositionUiView();
    });

    $window.onresize = function () {
      repositionUiView(); //for iframe

      resizeHeader($state.$current, globalIframeService);
    };

    $transitions.onSuccess({}, function () {
      angular.element("#header-app").width('100%');
      repositionUiView();
    });
    $transitions.onEnter({}, function () {
      repositionUiView();
    });

    function repositionUiView() {
      // todo: remove if condition when iframes are removed
      if (angular.element('#aa-app')[0] && (!angular.element('#autoalertiframe').length || angular.element('#autoalertiframe')[0].className.indexOf('hidden-iframe'))) {
        // to have the ui-view right below the header.
        //  needed as long as we want the header to be always fixed to top of page
        var headerHeight = angular.element('#aa-app')[0].offsetHeight;
        angular.element("#ui-view").css({
          'margin-top': headerHeight + 'px'
        }); // for iframe

        angular.element('#aa-app')[0].removeAttribute('style');
      }
    }
  }
}

function RunInitMessageListener($state, coreApi, dealerService, globalIframeService) {
  "ngInject";

  globalIframeService.subscribe(handleMessage);
  resizeHeader($state.$current, globalIframeService);

  function handleMessage(_ref, replyPort) {
    var data = _ref.data;
    var state = $state.current;

    if (data['IFRAME_SIZE_H']) {
      resizeHeader($state.$current, replyPort);
    } else if (data['IFRAME_SCROLL_TOP'] != null) {
      resizeHeader($state.$current, replyPort);
    } else if (data['GET_DEALER_IDS']) {
      // AA requesting dealer-ids
      sendDealerIds();
    } else if (data['SECURITY_LOGIN']) {
      window.location.replace('/');
    } else if (data['DEAL_SHEET_CLOSE']) {
      // this is called from legacy popup deal sheet not the angular deal sheet.
      replyPort.postMessage({
        'task': 'opportunity_refresh'
      });
    }

    function sendDealerIds() {
      var dealerIds = dealerService.getSelectedDealerIds();
      replyPort.postMessage({
        'task': 'change_dealer',
        'dealer_ids': dealerIds
      });
    }
  }
}
/* @ngInject */


function runUserInit($auth, $interval, $rootScope, notificationService, tokenService, loggedInDataInitializationService) {
  if ($auth.isAuthenticated()) {
    $interval(checkToken, 3000);

    if (tokenService.isAutoAlertUser()) {
      //for testing/debugging //todo: use environment variable...
      var accessToken = tokenService.getTokenByType('access');

      if (accessToken) {
        notificationService.connect();
        loggedInDataInitializationService.initializeData().then(getUserProfileComplete);
      }
    }
  } else {// storageService.clear();
  }

  function checkToken() {
    var impersonating = tokenService.getImpersonatingUsername();

    if (impersonating) {
      if (!tokenService.hasValidToken()) {
        $auth.signOut();
      }
    } else {
      $auth.validateExpirity();
    }
  }

  function getUserProfileComplete() {
    // we now have BehaviorSubject('SITE_MAP_READY') in menu.service that will replace this broadcast when 
    // all components listening for this are converted.
    $rootScope.$broadcast('RUN_USER_INIT.SITE_MAP_READY');
  }
}
/**
 * Adds event listeners that add/remove a spinner so the user knows
 * long-running transitions are in progress.
 */


function RunUiRouterTransitionSpinner($transitions, $rootScope, $timeout, $q) {
  "ngInject";

  var transitionsInProgressCount = 0;
  $transitions.onStart({}, function (trans) {
    var alreadyCompleted = false;
    transitionsInProgressCount += 1;
    $timeout(function () {
      if (!alreadyCompleted && transitionsInProgressCount) {
        $rootScope.globalBusyPromise = $q.defer();
      }
    }, 100);
    trans.promise.then(done, done);

    function done() {
      alreadyCompleted = true;
      transitionsInProgressCount -= 1;

      if (transitionsInProgressCount == 0) {
        $rootScope.globalBusyPromise = null;
      }
    }
  });
}
/* @ngInject */


function RunUiRouterTransitionsInit(_, externalService, $auth, $location, $state, $transitions, $rootScope, menu, oidc, tokenService, storageService, loggedInDataInitializationService) {
  tokenMissing$.subscribe(function () {
    // We make some API calls no matter what (like LoggedInUser,
    // DealerSearch, UserModuleAccess, etc.). If we're on a page that
    // doesn't require the user to be signed in, then we must ensure we
    // don't forward to SSO.
    if (isOnExternalDealSheetRoute()) return;
    if ($state.current.access == 'public') return;
    saveReferrerAndSignIn();
  });
  $transitions.onBefore({}, function (trans) {
    if (trans.from().name === 'errorUnauthorized' && trans.to().name !== 'errorUnauthorized') {
      return trans.router.stateService.target('errorUnauthorized');
    }

    if (trans.to().name === 'loginRedirectToSSO') {
      return;
    }

    var isProcessingAuthCallback = $location.path().indexOf('/auth/callback') > -1;
    var referrerHost = storageService.getItem('referrerHost');
    var portalHost = getUrlComponents(oidc.portalUrl).hostname;

    if (trans.to().access !== 'public' && !isProcessingAuthCallback && !$auth.isAuthenticated()) {
      if (isOnExternalDealSheetRoute()) {
        var url = $location.url();
        getExternalPageJWT().then(function (tokenResponse) {
          if (tokenResponse.nonCrmToken) {
            var isExternalToken = true;
            tokenService.saveToken('access', tokenResponse.data, isExternalToken);
            return loggedInDataInitializationService.initializeData();
          }

          $auth.signIn(url, tokenResponse.data);
          return Promise.resolve();
        }).catch(function (e) {
          $auth.signIn();
          return Promise.resolve();
        });
      } else {
        saveReferrerAndSignIn();
      }
    } else if ($auth.isAuthenticated() && referrerHost == portalHost && !tokenService.isAutoAlertUser() && tokenService.isMotoFuzeUser()) {
      // User is only a Motofuze user, so we just send them to Social
      var socialMenuItem = _.find(menu, {
        name: 'social'
      });

      var redirectUrl = socialMenuItem ? socialMenuItem.url : '/';
      window.location.replace(redirectUrl);
      return trans.router.stateService.target('loginRedirectToSSO');
    } else if ($auth.isAuthenticated()) {
      if (trans.to().access !== 'public') {
        var hasValidToken = tokenService.hasValidToken();
        var isAutoAlertUser = tokenService.isAutoAlertUser();

        if (hasValidToken && !isAutoAlertUser) {
          return trans.router.stateService.target('errorUnauthorized');
        }
      }
    }
  }); //there is also a $transitions.onSuccess in iframeBackwardsCompatibilityInit section below. Grouped there with other similar components.

  function saveReferrerAndSignIn() {
    //only if the referrer is not the SSO server, set the referrerOrigin
    // referrerOrigin is used after a successful login with SSO to navigate to page user was originally trying to go to
    var oidcOrigin = getUrlComponents(oidc.basePath).origin;
    var referrerOrigin = getUrlComponents(document.referrer).origin;

    if (referrerOrigin != oidcOrigin) {
      storageService.setItem('referrerUrl', referrerOrigin);
    }

    $auth.signIn();
  }

  function isOnExternalDealSheetRoute() {
    return $location.path().indexOf('external') > -1;
  }

  function getExternalPageJWT() {
    var queryString = window.location.search.substring(1);
    var hashString = window.location.hash.substring(1);
    var fragments = {};
    fragments = parseQueryString(queryString, hashString);

    if (fragments.t && fragments.pg) {
      return Promise.resolve({
        data: fragments.t,
        nonCrmToken: true
      });
    } else if (fragments.e && fragments.v) {
      return externalService.getValidExternalDealsheetJWTToken(fragments.e, fragments.v, fragments.c, fragments.d);
    } else if (fragments.pg) {
      return externalService.getValidExternalPageJWTToken(fragments.c, fragments.d);
    }
  }
}
/* @ngInject */


function RunUiRouterTransitionAuthorization($translate, warningTitleKey, translateErrorKeys, $transitions, $state, userService, logger) {
  $transitions.onBefore({}, function (transition) {
    var inaccessibleStates = ['alertDesk', 'dashboard.opportunities', 'dashboard.manager', 'search', //'conquests',  for WEBUI-5904
    'preOwnedManager', 'serviceManager', 'financeInsuranceManager', 'inventoryDashboard', 'preOwnedManagerFindABuyer', 'searchPresets', 'searchManagePresets'];

    if (inaccessibleStates.indexOf(transition.to().name) > -1) {
      return userService.getUserProfile().then(function (userProfile) {
        if (userProfile.isSltUser) {
          return $translate([warningTitleKey, translateErrorKeys.unauthorizedPageErrorKey]).then(function (translations) {
            logger.warning(translateErrorKeys.unauthorizedPageErrorKey, null, translations.warningTitle);
            return false;
          });
        } else {
          return true;
        }
      });
    } else {
      return true;
    }
  });
}
/* @ngInject */


function RunPostMessageInit($auth, $document, $logFactory, $state, $translate, $uibModal, announcementKey, authorizationService, dealSheetService, dealerService, eulaModalService, globalIframeService, legacyUrlMappings, logger, menu, menuService, storageService, tokenService, urlHelper, userService) {
  var $log = $logFactory.get('run');
  var impersonateModalInstance = null; // Reports send messages using window.postMessage()

  window.addEventListener('message', function (event) {
    $log.debug("received window message", event.data);
    var data = safeParseJSON(event.data);

    if (data['DEAL_SHEET_FROM_REPORT']) {
      var url = data['DEAL_SHEET_FROM_REPORT'];
      dealSheetService.activeDealSheet = url + "&snav=true";
      var dealsheetId = urlHelper.queryString(url).e;

      if (authorizationService.isEuroLite()) {
        dealSheetService.openDealSheetLiteModal(null, dealsheetId);
        return;
      }

      dealSheetService.openDealSheetModal(dealsheetId, url);
    }

    function safeParseJSON(maybeJsonString) {
      if (typeof maybeJsonString != 'string') {
        return maybeJsonString;
      }

      try {
        return JSON.parse(maybeJsonString);
      } catch (e) {
        return maybeJsonString;
      }
    }
  });
  globalIframeService.subscribe(function (_ref2, replyPort) {
    var data = _ref2.data;

    if (data['GET_SSO_ID_TOKEN']) {
      if (!$auth.isAuthenticated() && $state.current.access != 'public') {
        $auth.signIn();
      }

      sendSSOToken(dealerService, tokenService, replyPort);
      var legacyReadyStatus = storageService.getItem('legacyReadyState'); // the following block synchronizes default dealer moduleid settings with legacy

      if (!legacyReadyStatus) {
        var dealerIds = dealerService.getSelectedDealerIds();
        authorizationService.getModuleIds(dealerIds);
        storageService.setItem('legacyReadyState', 'ready');
      }
    } else if (data['DEAL_SHEET_URL']) {
      var url = data['DEAL_SHEET_URL'];
      dealSheetService.activeDealSheet = url + "&snav=true";
      var dealsheetId = urlHelper.queryString(url).e;

      if (authorizationService.isEuroLite()) {
        dealSheetService.openDealSheetLiteModal(null, dealsheetId);
        return;
      }

      dealSheetService.openDealSheetModal(dealsheetId, url);
    } else if (data['MANAGE_NOTIFICAION']) {
      var redirectUrl = window.location.origin + legacyUrlMappings['legacyNotification'];
      window.location.replace(redirectUrl);
    } else if (data['DEAL_SHEET_NEW_WINDOW']) {
      launchNewDealSheetTab(dealSheetService.activeDealSheet);
    } else if (data['SSOLANDING_AUTHENTICATED_REDIRECT']) {
      (function () {
        var redirectUrl = data['SSOLANDING_AUTHENTICATED_REDIRECT'];

        var moduleId = _.invert(legacyUrlMappings)[redirectUrl];

        var authorizedForThisModuleId = false;

        if (menuService.siteMap) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = menuService.siteMap[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var topLevelMenu = _step.value;

              if (topLevelMenu.id == moduleId) {
                authorizedForThisModuleId = true;
                break;
              }

              if (topLevelMenu.subMenuItems) {
                var subMenuHasModuleId = topLevelMenu.subMenuItems.some(function (mi) {
                  return mi.id == moduleId;
                });

                if (subMenuHasModuleId) {
                  authorizedForThisModuleId = true;
                  break;
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        }

        if (!authorizedForThisModuleId) {
          // Find a state they are allowed to transition to
          menuService.getHomeRoute().then(function (homeRoute) {
            $state.go(homeRoute || 'home');
          });
        }
      })();
    } else if (data['SSOLANDING_FAILED']) {
      // This shouldn't happen but at least it prevents the user from getting stuck
      $log.warn("Received SSOLANDING_FAILED message from Classic", data);
      menuService.getHomeRoute().then(function (homeRoute) {
        $state.go(homeRoute || 'home');
      });
    } else if (data['AUTOALERT_SESSION']) {
      // get asp.net
      // ASPXAUTH
      // ASP.NET_Sessionid
      var sessionObj = data['AUTOALERT_SESSION'];
      storageService.setItem('legacyA', sessionObj.a);
      storageService.setItem('legacyID', sessionObj.id);
    } else if (data['POST_LOGIN_REDIRECT_PORTAL_HOME']) {
      eulaModalService.validateEulaOnStart().then(function (result) {
        showAnnouncement();
        menuService.redirectToUserHome();
      });
    } else if (data['PANDO_INTEGRATION_UNAUTHORIZED']) {
      //redirect to Marketing Splash Page
      var socialMenuItem = _.find(menu, {
        name: 'social'
      });

      var _redirectUrl = socialMenuItem ? socialMenuItem.url : '/';

      window.location.replace(_redirectUrl);
    } else if (data['PANDO_INTEGRATION_UNHANDLED_ERROR']) {
      logger.genericError('An unhandled error occurred inside the iframe');
      $state.go('home');
    } else if (data['AUTOALERT_IMPERSONATE_USER']) {
      var impersonateUsername = data['AUTOALERT_IMPERSONATE_USER'];
      var impersonatedFullName = data['AUTOALERT_IMPERSONATED_FULLNAME'];
      var impersonatingFullName = data['AUTOALERT_IMPERSONATING_FULLNAME'];
      userService.getUser(impersonateUsername).then(function (user) {
        if (user.userTypeID === 6) {
          $translate('impersonateUserNotAuthorizedMsg').then(function (impersonateUserNotAuthorizedMsg) {
            var resolve = {
              title: function title() {
                return "impersonateUserTitle";
              },
              message: function message() {
                return impersonateUserNotAuthorizedMsg;
              },
              submitButtonVisible: function submitButtonVisible() {
                return false;
              },
              closeButtonText: function closeButtonText() {
                return 'close';
              }
            };
            showConfirmImpersonateModal($auth, null, resolve);
          });
        } else {
          $translate('impersonateUserMsg', {
            impersonatedUser: impersonatedFullName,
            impersonatingUser: impersonatingFullName
          }).then(function (impersonateUserMsg) {
            var resolve = {
              title: function title() {
                return "impersonateUserTitle";
              },
              message: function message() {
                return impersonateUserMsg;
              }
            };
            showConfirmImpersonateModal($auth, impersonateUsername, resolve);
          });
        }
      });
    } else if (data['LEGACY_PAGE_REDIRECT']) {
      var legeacyRedirect = data['LEGACY_PAGE_REDIRECT'];
      $state.go(legeacyRedirect.state, legeacyRedirect.params);
    }
  });

  function showConfirmImpersonateModal($auth, impersonateUsername, resolve) {
    if (impersonateModalInstance === 'confirmationModal') return;
    impersonateModalInstance = 'confirmationModal';
    var modal = $uibModal.open({
      appendTo: angular.element($document[0].querySelector('#aa-app')),
      animation: true,
      component: 'confirmationModal',
      resolve: resolve
    });
    modal.result.then(function () {
      impersonateModalInstance = null;
      $auth.impersonate("/alert-desk", impersonateUsername);
    }, function () {
      impersonateModalInstance = null;
    });
  }

  function showAnnouncement() {
    userService.getAnnouncementForUser().then(showAnnouncementModal);

    function showAnnouncementModal(announcement) {
      if (announcement && announcement.message) {
        var modal = $uibModal.open({
          appendTo: angular.element($document[0].querySelector('#aa-app')),
          animation: true,
          component: 'announcementModal',
          resolve: {
            title: function title() {
              return announcementKey;
            },
            message: function message() {
              return announcement.message;
            },
            filePath: function filePath() {
              return announcement.filePath;
            }
          }
        });
        userService.updateAnnouncementUserDisplay(announcement.id, false);
        modal.result.then(function (doNotDisplay) {
          if (doNotDisplay) {
            userService.updateAnnouncementUserDisplay(announcement.id, doNotDisplay);
          }
        });
      }
    }
  }

  function launchNewDealSheetTab(url) {
    url = url.replace('/AlertDesk/DealSheet/Show?', '/');
    var newwindowUrl = document.location.protocol + "//" + document.location.host + getPortalSubPath() + url;
    window.open(newwindowUrl, "_blank");
  }
}

function parseQueryString(queryString, hashString) {
  var data = {};

  if (queryString === null) {
    return data;
  }

  splitAndDecodeUrl(queryString, "&", data);
  splitAndDecodeUrl(hashString, ";", data);
  return data;
}

function splitAndDecodeUrl(stringToSplitAndDecode, delimeter, objectToAddTo) {
  var pair, separatorIndex, escapedKey, escapedValue, key, value;
  var pairs = stringToSplitAndDecode.split(delimeter);

  for (var i = 0; i < pairs.length; i++) {
    pair = pairs[i];
    separatorIndex = pair.indexOf("=");

    if (separatorIndex === -1) {
      escapedKey = pair;
      escapedValue = null;
    } else {
      escapedKey = pair.substr(0, separatorIndex);
      escapedValue = pair.substr(separatorIndex + 1);
    }

    key = decodeURIComponent(escapedKey);
    value = decodeURIComponent(escapedValue);
    if (key.substr(0, 1) === '/') key = key.substr(1);
    objectToAddTo[key] = value;
  }
}
/**
 * Begins downloading kendo-ui in the background. This is so it will
 * probably be loaded before the user actually clicks on the reports tab.
 * @see https://webpack.js.org/guides/code-splitting/#dynamic-imports
 */


function lazyLoadKendo() {
  importKendo();
}