import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
PortalFooterController.$inject = ["dealerService", "environmentVariables", "$location"];

/* injects from baggage-loader */
require('./portal-footer.component.html');

import angular from 'angular';
import { ProductTypes } from '../constants';
angular.module('AaApp.Opp').component('portalFooter', {
  templateUrl: '/app/components/portal-footer.component.html',
  controller: PortalFooterController,
  bindings: {
    year: '<'
  }
});
/* @ngInject */

function PortalFooterController(dealerService, environmentVariables, $location) {
  var $ctrl = this;
  $ctrl.$onInit = $onInit;
  $ctrl.year;
  $ctrl.isEuroLite = false;
  $ctrl.isFooterVisible = true;

  function $onInit() {
    $ctrl.year = new Date().getFullYear();
    var dealerIDs = dealerService.getSelectedDealerIds();

    if (Array.isArray(dealerIDs) && dealerIDs.length) {
      dealerService.getDealerSettings(dealerIDs[0]).then(function (settings) {
        if (settings.productID === ProductTypes.euroLite) $ctrl.isEuroLite = true;
      }).catch(function (err) {});
    }

    var url = $location.url();

    if (url.includes('mobile') && url.includes('deal-sheet') || url.includes('cfcallback') || url.includes('carfax-authorize')) {
      $ctrl.isFooterVisible = false;
    }
  }
}