/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmailConversationDetailDto } from '../models/email-conversation-detail-dto';
import { AlertMinerMailOpenedDto } from '../models/alert-miner-mail-opened-dto';
import { EmailConversationDto } from '../models/email-conversation-dto';
@Injectable({
  providedIn: 'root',
})
class EmailConversationClientService extends __BaseService {
  static readonly RecordEmailConversationPOSTPath = '/api/EmailConversation/RecordEmailConversation';
  static readonly RecordEmailReadPOSTPath = '/api/EmailConversation/RecordEmailRead';
  static readonly GetEmailBodyByIdGETPath = '/api/EmailConversation/GetEmailBody/{id}';
  static readonly ByIdGETPath = '/api/EmailConversation/{id}';
  static readonly ByIdDELETEPath = '/api/EmailConversation/{id}';
  static readonly GetManyGETPath = '/api/EmailConversation/GetMany';
  static readonly PUTPath = '/api/EmailConversation';
  static readonly POSTPath = '/api/EmailConversation';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param emailConversation undefined
   */
  RecordEmailConversationPOSTResponse(emailConversation?: EmailConversationDetailDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = emailConversation;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailConversation/RecordEmailConversation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param emailConversation undefined
   */
  RecordEmailConversationPOST(emailConversation?: EmailConversationDetailDto): __Observable<null> {
    return this.RecordEmailConversationPOSTResponse(emailConversation).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param alertMinerMailOpened undefined
   */
  RecordEmailReadPOSTResponse(alertMinerMailOpened?: AlertMinerMailOpenedDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = alertMinerMailOpened;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailConversation/RecordEmailRead`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param alertMinerMailOpened undefined
   */
  RecordEmailReadPOST(alertMinerMailOpened?: AlertMinerMailOpenedDto): __Observable<null> {
    return this.RecordEmailReadPOSTResponse(alertMinerMailOpened).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetEmailBodyByIdGETResponse(id: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailConversation/GetEmailBody/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetEmailBodyByIdGET(id: number): __Observable<string> {
    return this.GetEmailBodyByIdGETResponse(id).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<EmailConversationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailConversation/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmailConversationDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<EmailConversationDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as EmailConversationDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/EmailConversation/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<EmailConversationDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmailConversation/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmailConversationDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<EmailConversationDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<EmailConversationDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: EmailConversationDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/EmailConversation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: EmailConversationDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: EmailConversationDto): __Observable<__StrictHttpResponse<EmailConversationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/EmailConversation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmailConversationDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: EmailConversationDto): __Observable<EmailConversationDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as EmailConversationDto)
    );
  }
}

module EmailConversationClientService {
}

export { EmailConversationClientService }
