
/* injects from baggage-loader */
require('./customer-engagement-widget.component.html');

import angular from 'angular';
import {OpportunitiesModule} from '../../core/opportunities.module'

OpportunitiesModule.component('customerEngagementWidget', {
    templateUrl: '/app/components/header/customer-engagement-widget.component.html',
    controller: CustomerEngagementWidgetController,
    bindings: {
        onCustomerEngagementClicked: '&',
    }
});

function CustomerEngagementWidgetController($logFactory, $timeout, $uibModal, customerEngagementService) {
    "ngInject";

    const $ctrl = this;
    const $log = $logFactory.get("customerEngagementWidget");

    $ctrl.hoursBack = 24;
    $ctrl.filters = {};
    $ctrl.engagements = [];
    $ctrl.newEngagementsCount = 0;
    $ctrl.lastViewedDate;

    $ctrl.$onInit = $onInit;
    $ctrl.show = show;
    $ctrl.onHoursBackChanged = onHoursBackChanged;

    function $onInit() {
        $timeout(checkForNewEngagements);

        const subscription =
            customerEngagementService.newEngagements
            .subscribe(evt => {
                const engagementTime = new Date(evt.data.engagementTimeUtc);
                if (!$ctrl.lastViewedDate || engagementTime > $ctrl.lastViewedDate) {
                    $log.debug("incremented count");
                    $ctrl.newEngagementsCount = $ctrl.newEngagementsCount + 1;
                }
            });

        this.$onDestroy = () => subscription.unsubscribe();
    }

    function checkForNewEngagements() {
        customerEngagementService.getCustomerEngagement($ctrl.hoursBack)
            .then(({ lastViewedDate, engagements }) => {
                $log.debug("getCustomerEngagement:", { lastViewedDate, engagements });
                $ctrl.lastViewedDate = lastViewedDate;
                $ctrl.engagements = sortEngagementsByDateDescending(engagements);
                $ctrl.newEngagementsCount = getNewEngagementsCount($ctrl.engagements);
            })

        function getNewEngagementsCount(engagements) {
            return engagements
                .filter(e => e.lastViewedCount > 0)
                .reduce((total, engagement) => total + engagement.lastViewedCount, 0);
        }
    }

    function show() {
        // Tell the server we've viewed everything, then mark it all as viewed
        // on our end
        customerEngagementService.markCustomerEngagementsViewed()
            .then(() => {
                $ctrl.newEngagementsCount = 0
                customerEngagementService.getCustomerEngagementLastViewed()
                .then((lastViewed) => {
                    $ctrl.lastViewedDate = new Date(lastViewed.lastViewedDateUTC);
                    $log.debug("new last viewed:", $ctrl.lastViewedDate);
                });
            });

        $uibModal.open({
            component: 'customerEngagementWidgetWindow',
            size: 'mdsm', // medium-small
            resolve: {
                engagements: () => $ctrl.engagements,
                hoursBack: () => $ctrl.hoursBack,
                onHoursBackChanged: () => onHoursBackChanged,
                onCustomerEngagementClicked: () => $ctrl.onCustomerEngagementClicked
            }
        })
    }

    function onHoursBackChanged({ engagements, hoursBack }) {
        $ctrl.engagements = engagements;
        $ctrl.hoursBack = hoursBack;
    }
}

export function sortEngagementsByDateDescending(engagements) {
    return engagements.slice().sort(function (a, b) {
        // subtracting Dates gives you the difference in milliseconds
        return b.eventDate - a.eventDate;
    });
}
