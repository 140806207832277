<div class="modal-wrapper">
    <div class="alert-box">
        <div class="modal-header">
            <div style="float:left">
                <h4 class="modal-title" translate="disclaimer"></h4>
            </div>
            <div style="float:right">
                <button type="button" class="close" aria-label="Close" (click)="cancel()">
                    <icon class="icon aa-icon-close-icon"></icon>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="vhrDisclaimer" [innerHtml]="'dsAdmin_vehicleHistoryReportsDisclaimer' | translate"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="cancel()" translate="cancel"></button>
            <button type="submit" class="btn btn-primary" (click)="ok()" translate="ok"></button>
        </div>
    </div>
</div>
