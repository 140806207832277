import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { DealerSettingsService } from '../../services/dealer-settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerMakeLeaseMileageDto, DealerSettingDto, DealerTwilioPhoneNumberDto, DealerSettingResponseDto, ModelDto, TrimDto } from '../../generated/models';
import { AdminSettingsDealerInfoComponent } from './admin-settings-dealerinfo.component';

@Component({
    selector: 'admin-settings-advanced',
    templateUrl: './admin-settings-advanced.component-ng.html'
})

export class AdminSettingsAdvancedComponent {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};

    public selectedMakeId: number;
    public selectedYear: number;
    public selectedModelId: number;
    public models: ModelDto[];
    public trims: TrimDto[];
    public selfRegistrationLink: string;

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
        private dsService: DealerSettingsService) {
    }

    public ngOnInit() {
        this.UpdateSelfRegisrtationLink();
        this.dsService.GetTrim(this.dsa.dealerSetting.defaultTrimID).then(trim => {
            this.dsService.GetTrims(trim.modelID).then(trims => {
                this.trims = trims;
                this.dsService.GetModel(trim.modelID).then(model => {
                    this.dsService.GetModelsByMakeYear(model.makeID, model.yearID).then(models => {
                        this.models = models;
                        this.selectedModelId = trim.modelID;
                        this.selectedMakeId = model.makeID;
                        this.selectedYear = model.yearID;
                    });
                });
            });
        });
    }

    public updateModelsTrims() {
        this.dsService.GetModelsByMakeYear(this.selectedMakeId, this.selectedYear).then((models: ModelDto[]) => {
            if (models && models.length > 0) {
                this.models = models;
                this.selectedModelId = models[0].modelID;
                this.updateTrims();
            }
            else {
                this.models = null;
                this.trims = null;
            }
        });
    }

    public updateTrims() {
        this.dsService.GetTrims(this.selectedModelId).then((trims: TrimDto[]) => {
            if (trims && trims.length > 0) {
                this.trims = trims;
                this.dsa.dealerSetting.defaultTrimID = trims[0].trimID;
            }
            else {
                this.trims = null;
            }
        });
    }

    public GetTradeInSource() {
        if (this.dsa.dealerSetting.extTradeValueSourceID == 0) {
            return 'Source: AutoAlert';
        }
        else if (this.dsa.dealerSetting.extTradeValueSourceID == 1) {
            switch (this.dsa.dealerSetting.extTradeValueTier) {
                case 1:
                    return 'dsAdmin_sourceBBClean';
                case 2:
                    return 'dsAdmin_sourceBBAverage';
                case 3:
                    return 'dsAdmin_sourceBBRough';

            }
        }
        else if (this.dsa.dealerSetting.extTradeValueSourceID == 2) {
            return 'dsAdmin_sourceGalves';
        }
    }

    UpdateSelfRegisrtationLink() {
        this.selfRegistrationLink = window.location.origin + '/register/' + this.dsa.dealer.dealerCode + '?c=' + this.dsa.dealerSetting.registrationCode;
    }
}