"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("./../../services/user.service");
var customer_service_1 = require("./../../services/customer.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var models_1 = require("../../generated/models");
var storage_service_1 = require("../../modules/storage/storage.service");
var AdminSettingsDealerInfoComponent = /** @class */ (function () {
    function AdminSettingsDealerInfoComponent(modalService, tService, userService, logger, customerService, storageService) {
        this.modalService = modalService;
        this.tService = tService;
        this.userService = userService;
        this.logger = logger;
        this.customerService = customerService;
        this.storageService = storageService;
        this.dsa = {};
        this.selectedMakeId = 0;
        this.customerCulturesDifferent = true;
        this.isReadOnly = false;
        this.isEmployee = false;
        var userProfile = storageService.getItem('userProfile');
        if (userProfile) {
            this.isEmployee = userProfile.employeeUser;
        }
    }
    AdminSettingsDealerInfoComponent.prototype.selectedMake = function () {
        var _this = this;
        return this.dsa.dealerMakeFranchiseInfo.find(function (s) { return s.makeID == _this.selectedMakeId; });
    };
    AdminSettingsDealerInfoComponent.prototype.ngOnInit = function () {
        this.defaultPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.defaultCultureName);
        this.secondaryPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.secondaryCultureName);
        this.isReadOnly = false; // set to true if non-default Make selected
    };
    AdminSettingsDealerInfoComponent.prototype.ngOnChanges = function (changes) {
        if (changes.dsa) {
            this.customerCulturesDifferent = (this.dsa.dealerSetting.defaultCultureName !== this.dsa.dealerSetting.secondaryCultureName);
        }
        if (changes.dsa && !changes.dsa.isFirstChange()) {
            if (changes.dsa.currentValue.dealer && changes.dsa.previousValue.dealer
                && changes.dsa.currentValue.dealer.dealerID != changes.dsa.previousValue.dealer.dealerID) {
                this.defaultPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.defaultCultureName);
                this.secondaryPreferredLanguage = this.customerService.getCustomerCultureByCode(this.dsa.dealerSetting.secondaryCultureName);
            }
        }
    };
    AdminSettingsDealerInfoComponent.prototype.cultureChange = function () {
        this.customerCulturesDifferent = true;
        if (this.dsa.dealerSetting.defaultCultureName == this.dsa.dealerSetting.secondaryCultureName) {
            this.customerCulturesDifferent = false;
        }
    };
    AdminSettingsDealerInfoComponent.prototype.updateImage = function (logoImageBinary, fileExtension) {
        if (fileExtension != null)
            this.dealerImageSrc = 'data:image/' + fileExtension.toLowerCase().substring(1) + ';base64,' + logoImageBinary;
        else
            this.dealerImageSrc = null;
    };
    AdminSettingsDealerInfoComponent.prototype.uploadFile = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            var fileExtension = file.name.substr(file.name.lastIndexOf('.'));
            var reader = new FileReader();
            reader.readAsDataURL(file); // read file as data url
            reader.onload = function (event) {
                _this.dealerImageSrc = reader.result;
                _this.dsa.dealerLogoImage.logoImageBinary = _this.dealerImageSrc.split('base64,')[1];
                _this.dsa.dealerLogoImage.fileExtension = fileExtension.toUpperCase();
            };
            this.dsa.isDealerLogoUpdate = true;
            this.fileInput.nativeElement.value = ''; // to allow another upload if necessary
        }
    };
    AdminSettingsDealerInfoComponent.prototype.deleteFile = function () {
        this.dsa.dealerLogoImage.logoImageBinary = null;
        this.dsa.dealerLogoImage.fileExtension = null;
        this.dealerImageSrc = null;
        this.dsa.isDealerLogoUpdate = true;
        this.fileInput.nativeElement.value = ''; // to allow another upload if necessary
    };
    AdminSettingsDealerInfoComponent.prototype.makeChanged = function () {
        var _this = this;
        if (this.selectedMakeId == 0) {
            this.address1 = null;
            this.address2 = this.dsa.dealerMakeFranchiseAddress.find(function (d) { return d.makeID == _this.selectedMakeId; });
            this.isReadOnly = false;
        }
        else {
            this.address1 = this.dsa.dealerMakeFranchiseAddress.find(function (d) { return d.makeID == _this.selectedMakeId && d.displayOrder == 1; });
            this.address2 = this.dsa.dealerMakeFranchiseAddress.find(function (d) { return d.makeID == _this.selectedMakeId && d.displayOrder == 2; });
            this.isReadOnly = true;
        }
        if (this.selectedMake())
            this.updateImage(this.selectedMake().logoImageBinary, this.selectedMake().logoFileExtension);
        else if (this.dsa.dealerLogoImage)
            this.updateImage(this.dsa.dealerLogoImage.logoImageBinary, this.dsa.dealerLogoImage.fileExtension);
    };
    return AdminSettingsDealerInfoComponent;
}());
exports.AdminSettingsDealerInfoComponent = AdminSettingsDealerInfoComponent;
