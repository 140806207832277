"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var operators_1 = require("rxjs/operators");
var services_1 = require("../generated/services");
var http_interceptor_options_service_1 = require("./http/http-interceptor-options.service");
var cookie_storage_service_1 = require("../modules/storage/cookie-storage.service");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../generated/services/user-search-condition-client.service"));
var i2 = __importStar(require("../generated/services/pando-passthrough-client.service"));
var i3 = __importStar(require("../modules/storage/cross-domain-storage.service"));
var i4 = __importStar(require("./http/http-interceptor-options.service"));
var SearchPresetService = /** @class */ (function () {
    function SearchPresetService(userSearchConditionClientService, pandoPassthroughClientService, crossDomainStorageService, httpInterceptorOptionsService) {
        this.userSearchConditionClientService = userSearchConditionClientService;
        this.pandoPassthroughClientService = pandoPassthroughClientService;
        this.crossDomainStorageService = crossDomainStorageService;
        this.httpInterceptorOptionsService = httpInterceptorOptionsService;
        this.defaultRowLimit = 5;
        this.maxRowLimit = 50;
    }
    SearchPresetService.prototype.getSearchPresets = function () {
        return this.userSearchConditionClientService.GetSearchesGET().toPromise();
    };
    SearchPresetService.prototype.getSearchModel = function (userSearchConditionId) {
        return this.userSearchConditionClientService.GetSearchModelByUsersearchconditionidGET(userSearchConditionId).toPromise();
    };
    SearchPresetService.prototype.getSearchPreset = function (userSearchConditionId) {
        return this.userSearchConditionClientService.ByIdGET(userSearchConditionId).toPromise();
    };
    SearchPresetService.prototype.deleteSearchPreset = function (userSearchConditionId) {
        return this.userSearchConditionClientService.DeleteUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId).toPromise();
    };
    SearchPresetService.prototype.shareSearchPreset = function (userSearchConditionId) {
        return this.userSearchConditionClientService.ShareUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId).toPromise();
    };
    SearchPresetService.prototype.unshareSearchPreset = function (userSearchConditionId) {
        return this.userSearchConditionClientService.UnshareUserSearchConditionByUsersearchconditionidPOST(userSearchConditionId).toPromise();
    };
    SearchPresetService.prototype.getDealerSearchPresetLayouts = function (selectedDealerId) {
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        }
        return this.userSearchConditionClientService.GetDealerLayOutExtsGET().toPromise();
    };
    SearchPresetService.prototype.getActiveDealerSearchPresetLayouts = function (selectedDealerId) {
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId]);
        }
        return this.userSearchConditionClientService.GetActiveDealerLayOutExtsGET().toPromise();
    };
    SearchPresetService.prototype.getDealerSearchPresetLayoutCounts = function (hardRefresh, selectedDealerId, maxRowsDisplayed) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedDealers, layouts, availableSearchPresets, searchPresets, widgetData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        maxRowsDisplayed = maxRowsDisplayed || this.defaultRowLimit;
                        if (selectedDealerId == null) {
                            selectedDealers = this.crossDomainStorageService.getItem('selectedDealers');
                            if (selectedDealers.dealers.length > 1) {
                                // The dealer dropdown is present but "All Dealerships" is
                                // selected, don't bother getting layouts/counts because this
                                // widget isn't supported anyway
                                return [2 /*return*/, { presetCounts: [], maxRowsDisplayed: maxRowsDisplayed }];
                            }
                        }
                        return [4 /*yield*/, this.getActiveDealerSearchPresetLayouts(selectedDealerId)];
                    case 1:
                        layouts = _a.sent();
                        availableSearchPresets = this.mapGetResponse(layouts);
                        if (availableSearchPresets.length == 0) {
                            return [2 /*return*/, {
                                    searchPresets: [],
                                    unusedSearchPresets: [],
                                    presetCounts: [],
                                    maxRowsDisplayed: maxRowsDisplayed,
                                }];
                        }
                        availableSearchPresets.sort(this.byOrder);
                        searchPresets = hardRefresh
                            ? availableSearchPresets.slice(0, this.maxRowLimit)
                            : availableSearchPresets.slice(0, maxRowsDisplayed);
                        return [4 /*yield*/, this.getSearchPresetCounts(searchPresets, hardRefresh)];
                    case 2:
                        widgetData = _a.sent();
                        return [2 /*return*/, __assign({}, widgetData, { unusedSearchPresets: lodash_1.difference(availableSearchPresets, searchPresets).sort(this.byOrder), maxRowsDisplayed: maxRowsDisplayed })];
                }
            });
        });
    };
    SearchPresetService.prototype.mapGetResponse = function (layouts) {
        if (Array.isArray(layouts)) {
            return layouts;
        }
        return [];
    };
    SearchPresetService.prototype.getSearchPresetCounts = function (searchPresets, hardRefresh) {
        if (hardRefresh === void 0) { hardRefresh = false; }
        var observable = this.userSearchConditionClientService.GetSearchCountsPOST({ hardRefresh: hardRefresh, layouts: searchPresets.sort(this.byOrder) });
        return observable
            .pipe(operators_1.map(function (response) {
            return { searchPresets: searchPresets, presetCounts: response };
        })).toPromise();
    };
    SearchPresetService.prototype.byOrder = function (searchPreset1, searchPreset2) {
        return searchPreset1.order - searchPreset2.order;
    };
    SearchPresetService.prototype.updateDealerSearchPresetLayouts = function (dealerId, searchPresetLayouts) {
        return this.userSearchConditionClientService.UpdateDealerLayOutsByDealeridPOSTResponse({ dealerId: dealerId, layouts: searchPresetLayouts })
            .pipe(operators_1.map(function (response) { return resolveLayoutUpdate(response); })).toPromise();
        function resolveLayoutUpdate(response) {
            return response.status === 204;
        }
    };
    SearchPresetService.prototype.getPandoCustomStoreGroups = function (dealerId) {
        this.httpInterceptorOptionsService.disableErrorHandler();
        return this.pandoPassthroughClientService.GetPandoCustomStoreGroupsByDealeridGET(dealerId).toPromise();
    };
    SearchPresetService.prototype.updateAutoAssignments = function (assignments) {
        return this.userSearchConditionClientService.ResetAutoAssignmentsPOST(assignments).toPromise();
    };
    SearchPresetService.prototype.executePandoXSearch = function (dealerId, userSearchConditionId) {
        return this.userSearchConditionClientService.ExecutePandoXPOST({ dealerId: dealerId, userSearchConditionId: userSearchConditionId }).toPromise();
    };
    SearchPresetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchPresetService_Factory() { return new SearchPresetService(i0.ɵɵinject(i1.UserSearchConditionClientService), i0.ɵɵinject(i2.PandoPassthroughClientService), i0.ɵɵinject(i3.CrossDomainStorageService), i0.ɵɵinject(i4.HttpInterceptorOptionsService)); }, token: SearchPresetService, providedIn: "root" });
    return SearchPresetService;
}());
exports.SearchPresetService = SearchPresetService;
