"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./log-action-shortcuts.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("../email-template-action/email-template-action.component"));
var i4 = __importStar(require("../text-action/text-action.component.ngfactory"));
var i5 = __importStar(require("../text-action/text-action.component"));
var i6 = __importStar(require("@angular/platform-browser"));
var i7 = __importStar(require("../../../services/user.service"));
var i8 = __importStar(require("../../../core/toaster-logger.service"));
var i9 = __importStar(require("../../../services/notification.service"));
var i10 = __importStar(require("../../../services/authorization.service"));
var i11 = __importStar(require("../../../services/text-message.service"));
var i12 = __importStar(require("../../../filters/locale-date-time.pipe"));
var i13 = __importStar(require("@angular/common"));
var i14 = __importStar(require("../../../ajs-upgraded-providers"));
var i15 = __importStar(require("./log-action-shortcuts.component"));
var i16 = __importStar(require("../../../services/log-action-shortcut.service"));
var styles_LogActionShortcutsComponent = [i0.styles];
var RenderType_LogActionShortcutsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogActionShortcutsComponent, data: {} });
exports.RenderType_LogActionShortcutsComponent = RenderType_LogActionShortcutsComponent;
function View_LogActionShortcutsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "log-action-shortcuts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary btn-sm shortcut-action-btn"], ["translate", "logACall"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logCall() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Log a Call"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary btn-sm shortcut-action-btn"], ["translate", "logANote"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logNote() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Log a Note"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-primary btn-sm shortcut-action-btn"], ["translate", "scheduleAppt"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scheduleAppt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Schedule Appt"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "email-template-action", [["isShortcutButton", "true"]], null, null, null, null, null)), i1.ɵdid(11, 999424, null, 0, i3.EmailTemplateActionComponent, [i1.ElementRef, i1.Injector], { opportunity: [0, "opportunity"], permissions: [1, "permissions"], isEuroLite: [2, "isEuroLite"], isShortcutButton: [3, "isShortcutButton"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "text-action", [["isCustomerConnectOpen", "true"], ["isShortcutButton", "true"]], null, null, null, i4.View_TextActionComponent_0, i4.RenderType_TextActionComponent)), i1.ɵdid(13, 9158656, null, 0, i5.TextActionComponent, [i6.DomSanitizer, i7.UserService, i8.ToasterLoggerService, i9.NotificationService, i10.AuthorizationService, i1.Renderer2, i11.TextMessageService, i2.TranslateService, i12.LocaleDateTimePipe, i1.ChangeDetectorRef, i13.DOCUMENT, i14.EulaModalService], { opportunity: [0, "opportunity"], isTextingEnabled: [1, "isTextingEnabled"], isCustomerConnectOpen: [2, "isCustomerConnectOpen"], isShortcutButton: [3, "isShortcutButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "logACall"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "logANote"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "scheduleAppt"; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.opportunity; var currVal_4 = _co.permissions; var currVal_5 = false; var currVal_6 = "true"; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.opportunity; var currVal_8 = _co.isTextingEnabled; var currVal_9 = "true"; var currVal_10 = "true"; _ck(_v, 13, 0, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
function View_LogActionShortcutsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogActionShortcutsComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissions.postActivity; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_LogActionShortcutsComponent_0 = View_LogActionShortcutsComponent_0;
function View_LogActionShortcutsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "log-action-shortcuts", [], null, null, null, View_LogActionShortcutsComponent_0, RenderType_LogActionShortcutsComponent)), i1.ɵdid(1, 114688, null, 0, i15.LogActionShortcutsComponent, [i16.LogActionShortcutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LogActionShortcutsComponent_Host_0 = View_LogActionShortcutsComponent_Host_0;
var LogActionShortcutsComponentNgFactory = i1.ɵccf("log-action-shortcuts", i15.LogActionShortcutsComponent, View_LogActionShortcutsComponent_Host_0, { isTextingEnabled: "isTextingEnabled", permissions: "permissions", opportunity: "opportunity" }, { logShortcut: "logShortcut" }, []);
exports.LogActionShortcutsComponentNgFactory = LogActionShortcutsComponentNgFactory;
