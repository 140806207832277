ContractDatesController.$inject = ["saleTypes"];

/* injects from baggage-loader */
require('./contract-dates.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('contractDates', {
  templateUrl: '/app/components/deal-sheet/contract/contract-dates.component.html',
  controller: ContractDatesController,
  bindings: {
    saleType: '<',
    startDate: '<',
    endDate: '<'
  }
});
/* @ngInject */

function ContractDatesController(saleTypes) {
  var $ctrl = this;
  $ctrl.saleTypes = saleTypes;
}