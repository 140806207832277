<div [ngBusy]="{busy: busy, delay: 200}">
    <div class="admin-settings-wrapper">
        <div class="row">
            <div class="col-md-9">
                <div class="page-title" translate="dsAdmin_dealershipSettings">
                </div>
            </div>
            <div *ngIf="dealers && dealers.length > 1" class="col-md-3">
                <div style="float:right">
                    <div style="float:left;margin-top:5px" translate="dealerships">
                    </div>
                    <div style="float:left;margin-left:5px">
                        <select class="dealer-dropdown-style form-control"
                                [(ngModel)]="selectedDealerId" (change)="dealerChanged()">
                            <option *ngFor="let item of dealers" value="{{ item.id }}">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider">
        </div>
        <div *ngIf="!formLoaded" class="adminRoleTabContainer" style="height:500px">
        </div>
        <div *ngIf="formLoaded" class="adminRoleTabContainer" (keyup)="onValidateDuringEntry($event.target)" (click)="onValidateDuringEntry($event.target)" (change)="onValidateAfterEntry($event.target)">
            <tabset>
                <tab (selectTab)="onTabChanged(tabs.General)" [active]="tabIndex == tabs.General" heading="{{ 'generalTabTitle' | translate }}">
                    <admin-settings-general [dsa]="dsa"
                                            #generalComponent>
                    </admin-settings-general>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.Programs)" [active]="tabIndex == tabs.Programs" heading="{{ 'programsTabTitle' | translate }}">
                    <admin-settings-programs [dsa]="dsa">
                    </admin-settings-programs>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.Opportunity)" [active]="tabIndex == tabs.Opportunity" heading="{{ 'opportunityTabTitle' | translate }}">
                    <admin-settings-opportunity [dsa]="dsa"
                                                 #opportunityComponent>
                    </admin-settings-opportunity>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.Inventory)" [active]="tabIndex == tabs.Inventory" heading="{{ 'inventoryTabTitle' | translate }}">
                    <admin-settings-inventory [dsa]="dsa">
                    </admin-settings-inventory>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.DealerInfo)" [active]="tabIndex == tabs.DealerInfo" heading="{{ 'dealerInfoTabTitle' | translate }}">
                    <admin-settings-dealerinfo [dsa]="dsa"
                                               #dealerInfoComponent>
                    </admin-settings-dealerinfo>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.Advanced)" [active]="tabIndex == tabs.Advanced" heading="{{ 'advancedTabTitle' | translate }}">
                    <admin-settings-advanced [dsa]="dsa"
                                             #advancedComponent>
                    </admin-settings-advanced>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.CreditConvert)" [customClass]="tabCreditConvertCssClass" [active]="tabIndex == tabs.CreditConvert" heading="{{ 'creditConvertTabTitle' | translate }}">
                    <admin-settings-creditconvert [dsa]="dsa"
                                                  #creditConvertComponent>
                    </admin-settings-creditconvert>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.Product)" [customClass]="tabProductCssClass" [active]="tabIndex == tabs.Product" heading="{{ 'productTabTitle' | translate }}">
                    <admin-settings-product [dsa]="dsa">
                    </admin-settings-product>
                </tab>
                <tab (selectTab)="onTabChanged(tabs.CRMLeadsDelivery)" [active]="tabIndex == tabs.CRMLeadsDelivery" heading="{{ 'crmLeadsDeliveryTabTitle' | translate }}">
                    <admin-settings-crmleadsdelivery [dsa]="dsa">
                    </admin-settings-crmleadsdelivery>
                </tab>
            </tabset>
            <div class="actionButtonContainer">
                <div style="float:right;margin-top:10px">
                    <div style="float:left;margin-right:10px">
                        <button type="button" class="btn btn-primary saveButton" (click)="onCancel()" translate="cancel" [disabled]="disableSaveCancel"></button>
                    </div>
                    <div style="float:left">
                        <button type="button" class="btn btn-primary saveButton" (click)="onSave()" translate="saveChanges" [disabled]="disableSaveCancel"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
