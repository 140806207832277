
/* injects from baggage-loader */
require('./trim-select.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('trimSelect', {
    templateUrl: '/app/components/deal-sheet/vehicle/trim-select.component.html',
    controller: TrimSelectController,
    bindings: {
        dealerId: '<',
        defaults: '<',
        showBlankOptions: '<',
        showBlankModelTrimOptions: '<',
        showAllMakes: '<',
        showAllYears: '<',
        onSelect: '&',
        showWarningMessage: '<',
        showDefaultYears: '<',
        yearLimit: '<',
        showYearAsterisk: '<'
    }
});

/* @ngInject */
function TrimSelectController(_, $q, $filter, vehicleService) {
    var $ctrl = this;

    $ctrl.selectedMake;
    $ctrl.selectedYear;
    $ctrl.selectedModel;
    $ctrl.selectedTrim;
    $ctrl.makes;
    $ctrl.yearIDs;
    $ctrl.models;
    $ctrl.trims;
    $ctrl.updateModels = updateModels;
    $ctrl.updateTrims = updateTrims;
    $ctrl.onSelectionChange = onSelectionChange;
    $ctrl.$onChanges = onChanges;
    $ctrl.isValidSelection = isValidSelection;
   

    function onChanges(changes) {
        if ($ctrl.dealerId && $ctrl.defaults) { 
            $ctrl.selectedMake = { makeID: $ctrl.defaults.makeID, makeName: $ctrl.defaults.makeName };
            $ctrl.selectedModel = { modelID: $ctrl.defaults.modelID, modelName: $ctrl.defaults.modelName };
            $ctrl.selectedTrim = { trimID: $ctrl.defaults.trimID, trimName: $ctrl.defaults.trimName };
            $ctrl.selectedYear = $ctrl.defaults.yearID;
            $q.all([
                vehicleService.getMakes($ctrl.showAllMakes ? null : [$ctrl.dealerId]),
                vehicleService.getModels($ctrl.defaults.makeID, $ctrl.defaults.yearID),
                vehicleService.getYearIDs($ctrl.dealerId, $ctrl.showDefaultYears, $ctrl.yearLimit),
                vehicleService.getTrims([$ctrl.defaults.modelID])
            ]).then((data) => {
                $ctrl.makes = data[0].sort(byMakeName);
                $ctrl.models = data[1].sort(byModelName);

                $ctrl.yearIDs = data[2].sort(descending);

                if(!$ctrl.yearLimit<255) {
                    $ctrl.yearIDs.splice($ctrl.yearLimit, $ctrl.yearIDs.length - $ctrl.yearLimit);
                }
                if ($ctrl.yearIDs.indexOf($ctrl.selectedYear) < 0) {
                    // If the "allowable" years doesn't include the
                    // currently-selected year, add it (because it's probably
                    // the replacement vehicle's year)
                    $ctrl.yearIDs.unshift($ctrl.selectedYear);
                }

                $ctrl.trims = data[3].sort(byTrimName);

                //Set blank for all
                if ($ctrl.showBlankOptions) {
                    $ctrl.makes.unshift({
                        makeID: "",
                        makeName: ""
                    });
                    $ctrl.models.unshift({
                        modelID: "",
                        modelName: ""
                    });
                    $ctrl.yearIDs.unshift("");
                    $ctrl.trims.unshift({
                        trimID: "",
                        trimName: ""
                    });
                }

                //Set blank for Model Trim only
                if ($ctrl.showBlankModelTrimOptions) {
                    $ctrl.models.unshift({
                        modelID: "",
                        modelName: ""
                    });
                    if (!$ctrl.selectedModel.modelID)
                        $ctrl.selectedModel = $ctrl.models[0];
                    $ctrl.trims.unshift({
                        trimID: "",
                        trimName: ""
                    });
                    if (!$ctrl.selectedTrim.trimID)
                        $ctrl.selectedTrim = $ctrl.trims[0];
                }
            });
        }
    }

    function updateModels() {
        if (!$ctrl.selectedMake)
            return;
        if ($ctrl.selectedMake.makeID && $ctrl.selectedYear) {
            vehicleService.getModels($ctrl.selectedMake.makeID, $ctrl.selectedYear).then(setModels);
        }
        else if ($ctrl.selectedMake.makeID && !$ctrl.selectedYear) {
            vehicleService.getModelsByMakeId($ctrl.selectedMake.makeID).then(setModels);
        }
        else {
            setModels(null);
        }
    }

    function updateTrims() {
        if ($ctrl.selectedModel && $ctrl.selectedModel.modelID) {
            var modelIDs = getModelIds($ctrl.models, $ctrl.selectedModel.modelName);

            vehicleService.getTrims(modelIDs).then(setTrims);
        }
        else {
            setTrims(null);
        }
    }

    function getModelIds(models, modelName) {
        var ids = [];

        if (modelName) {
            models = models.filter(filterByModelName(modelName));

            for (var i = 0; i < models.length; i++) {
                ids.push(models[i].modelID);
            }
        }

        return ids;
    }

    function filterByModelName(modelName) {
        return function (model) {
            return model.modelName == modelName;
        };
    }

    function getTrimIds(trims, trimName) {
        var ids = [];

        if (trimName) {
            trims = trims.filter(filterByTrimName(trimName));

            for (var i = 0; i < trims.length; i++) {
                ids.push(trims[i].trimID);
            }
        }

        return ids;
    }

    function filterByTrimName(trimName) {
        return function (trim) {
            return trim.trimName == trimName;
        };
    }

    function onSelectionChange() {
        $ctrl.onSelect({
            selections: {
                makeID: $ctrl.selectedMake.makeID,
                makeName: $ctrl.selectedMake.makeName,
                modelID: $ctrl.selectedModel.modelID,
                modelIDs: getModelIds($ctrl.models, $ctrl.selectedModel.modelName),
                modelName: $ctrl.selectedModel.modelName,
                yearID: $ctrl.selectedYear,
                trimName: $ctrl.selectedTrim.trimName,
                trimID: $ctrl.selectedTrim.trimID,
                trimIDs: getTrimIds($ctrl.trims, $ctrl.selectedTrim.trimName),
                extID: $ctrl.selectedTrim.extID,
                trimFullName: $ctrl.selectedTrim.trimFullName
            }
        });
    }

    function setModels(models) {
        if (models && models.length > 0) {
            $ctrl.models = models;
            $ctrl.models.sort(byModelName);

            if ($ctrl.showBlankOptions || $ctrl.showBlankModelTrimOptions) {
                $ctrl.models.unshift({
                    modelID: "",
                    modelName: ""
                });
            }
        }
        else {
            $ctrl.models = [{
                modelID: "",
                modelName: ""
            }];
        }

        $ctrl.selectedModel = $ctrl.models[0];
        updateTrims();
    }

    function setTrims(trims) {
        if (trims && trims.length > 0) {
            $ctrl.trims = trims.sort(byTrimName);

            if ($ctrl.showBlankOptions || $ctrl.showBlankModelTrimOptions) {
                $ctrl.trims.unshift({
                    trimID: "",
                    trimName: ""
                });
            }

        }
        else {
            $ctrl.trims = [{
                trimID: "",
                trimName: ""
            }];
        }

        $ctrl.selectedTrim = $ctrl.trims[0];
        onSelectionChange();
    }

    function descending(number1, number2) {
        return number2 - number1;
    }

    function byMakeName(object1, object2) {
        if (object1.makeName < object2.makeName)
            return -1;
        if (object1.makeName > object2.makeName)
            return 1;
        return 0;
    }

    function byModelName(object1, object2) {
        if (object1.modelName < object2.modelName)
            return -1;
        if (object1.modelName > object2.modelName)
            return 1;
        return 0;
    }

    function byTrimName(object1, object2) {
        if (object1.trimName < object2.trimName)
            return -1;
        if (object1.trimName > object2.trimName)
            return 1;
        return 0;
    }

    function isValidSelection() {
        var yearSelectionIsValid = $ctrl.selectedYear == "";
        var isValidSelection = (
            yearSelectionIsValid
            ||
            !($ctrl.makes && $ctrl.makes.length == 1 && $ctrl.makes[0].makeID == ""
            || $ctrl.models && $ctrl.models.length == 1 && $ctrl.models[0].modelID == ""
            || $ctrl.trims && $ctrl.trims.length == 1 && $ctrl.trims[0].trimID == "")
        );
        return isValidSelection;
    }
}
