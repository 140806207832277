import angular from 'angular';
import moduleName from './module';
import {
    unauthorized$,
    loggedIn$,
    loggedOut$,
    silentRefreshFailed$,
    silentRefreshSucceeded$,
    silentRefreshTokenUnchanged$,
    tokenExpiresSoon$,
    silentRefreshStarted$,
    silentRefreshTimeout$,
} from './events';

// These event names are used when thunking an event from an iframe to the
// parent window.
const eventPrefix = 'oidcauth:';
const unauthorizedEvent = eventPrefix + 'unauthorized';
const silentRefreshSuceededEvent = eventPrefix + 'silentRefreshSucceded';
const silentRefreshFailedEvent = eventPrefix + 'silentRefreshFailed';
const silentRefreshTokenUnchangedEvent = eventPrefix + 'silentRefreshTokenUnchanged';

/**
 * thunk events are used when a page running in an iframe requires a message to
 * be posted to the parent window.
 */
const thunkEventName = eventPrefix + "thunk";

angular.module(moduleName).provider("$auth", function () {

    // Default configuration
    const config = {
        basePath: null,
        clientId: null,
        urls: [],
        responseType: 'id_token',
        scope: "openid profile",
        redirectUri: (window.location.origin || window.location.protocol + '//' + window.location.host) + window.location.pathname + '#/auth/callback/',
        logoutUri: (window.location.origin || window.location.protocol + '//' + window.location.host) + window.location.pathname + '#/auth/clear',
        state: "",
        authorizationEndpoint: 'connect/authorize',
        revocationEndpoint: 'connect/revocation',
        endSessionEndpoint: 'connect/endsession',
        advanceRefresh: 30 * 1000, //30 s * ms/sec   // this is actually is set from appSettings.*.json default is 15 min that is 900000ms
        enableRequestChecks: true,
        stickToLastKnownIdp: false,
        isDotNetClientApp: false,
        isExternal: false
    };

    return {


        // Service configuration
        configure: function (params) {
            angular.extend(config, params);
        },

        // Service itself
        $get: function ($logFactory, $document, storageService, crossDomainStorageService, $location, $window, tokenService) {
            "ngInject";
            const $log = $logFactory.get("oidc-angular");

            $window.addEventListener('message', function (event) {
                const message = event.data[thunkEventName];
                if (message) {
                    const { name, args } = message;

                    switch (name) {
                        case unauthorizedEvent:
                            unauthorized$.next(args);
                            break;
                        case silentRefreshSuceededEvent:
                            silentRefreshSucceeded$.next(args);
                            break;
                        case silentRefreshFailedEvent:
                            silentRefreshFailed$.next(args);
                            break;
                        case silentRefreshTokenUnchangedEvent:
                            silentRefreshTokenUnchanged$.next(args);
                            break;
                    }
                }
            });

            if (storageService.getItem('logoutActive')) {
                storageService.removeItem('logoutActive');
                tokenService.clearTokens();
            }

            var isProcessingAuthCallback = $location.path().indexOf('/auth/callback') > -1;

            if (!isProcessingAuthCallback && storageService.getItem('refreshRunning')) {
                storageService.removeItem('refreshRunning');
            }

            return {
                config: config,

                handleSignInCallback(data) {
                    if (!data && window.location.hash.indexOf("#") === 0) {
                        data = window.location.hash.substr(16)
                    }

                    var fragments = {}
                    if (data) {
                        fragments = parseQueryString(data);
                    } else {
                        throw Error("Unable to process callback. No data given!");
                    }

                    var err = parseErrorFromFragments(fragments);
                    var id_token = fragments['id_token'];
                    var access_token = fragments['access_token'];
                    var state = fragments['state'];

                    if (state === 'refresh') {
                        handleSilentRefreshCallback(err, id_token, access_token);
                    } else {
                        handleImplicitFlowCallback(err, id_token, access_token);
                    }

                    function parseErrorFromFragments(fragments) {
                        var error = fragments['error'];
                        if (error) {
                            // If fragments contains the property 'error', then
                            // the whole object is an error object with error,
                            // error_description, error_uri, and state.
                            // See: http://openid.net/specs/openid-connect-core-1_0.html#AuthError
                            return fragments;
                        }
                    }
                },

                handleSignOutCallback() {
                    storageService.removeItem('logoutActive');

                    tokenService.clearTokens();
                    window.location = '/';

                    loggedOut$.next({});
                },

                validateExpirity() {
                    if (!tokenService.hasToken()) return;
                    if (storageService.getItem('refreshRunning')) return;

                    // If the token will have expired X minutes from now, then
                    // we need to do a silent refresh.
                    var trialExpirationTime = Date.now() + config.advanceRefresh;
                    var shouldRefreshToken =
                        (!tokenService.hasValidToken() ||
                        !tokenService.hasValidToken(trialExpirationTime)) &&
                        !tokenService.hasExternalToken();

                    if (shouldRefreshToken) {
                        tokenExpiresSoon$.next({});
                        trySilentRefresh(); // this refreshes the token.
                    }
                },

                isAuthenticated() {
                    return tokenService.hasValidToken();
                },

                isAuthenticatedIn(milliseconds) {
                    return tokenService.hasValidToken() && tokenIsValidAt(new Date().getTime() + milliseconds);
                },

                signIn(localRedirectPath, crmToken) {
                    
                    startImplicitFlow(localRedirectPath, crmToken);
                },

                impersonate(localRedirectPath, impersonateUsername) {
                    
                    var selectedDealers = crossDomainStorageService.getItem('selectedDealers');
                    storageService.clear();
                    storageService.setItem('impersonatingSelectedDealers', selectedDealers);

                    crossDomainStorageService.clear();

                    startImplicitFlow(localRedirectPath, null, impersonateUsername);
                },

                signOut() {
                    startLogout();
                },

                silentRefresh() {
                    trySilentRefresh();
                }

            };

            /**
             * Thunks a message from an iframe to the parent window.
             * When we're in the silent-refresh iframe, we can't just call
             * event$.next(args), because the parent window won't receive it.
             * Instead, we post it to our parent window.
             *
             * If the current window is the top-most window, the message will be
             * received by this window.
             */
            function broadcastToParent(name, ...args) {
                var msg = {};
                msg[thunkEventName] = { name, args };
                window.parent.postMessage(msg, '*')
            }

            function createLoginUrl(nonce, state, allowLoginForm, crmTokenValue, impersonateUsername, endImpersonation) {

                var hasPathDelimiter = config.basePath.endsWith('/');
                var appendChar = (hasPathDelimiter) ? '' : '/';

                var currentClaims = tokenService.getClaimsByTokenType('access');
                if (currentClaims) {
                    var idpClaimValue = currentClaims["idp"];
                }
                var baseUrl = config.basePath + appendChar;

                var url = baseUrl + config.authorizationEndpoint
                    + "?response_type="
                    + encodeURIComponent(config.responseType)
                    + "&client_id="
                    + encodeURIComponent(config.clientId)
                    // + encodeURIComponent('resourceownerclient')
                    + "&state="
                    + encodeURIComponent(state || config.state)
                    + "&scope="
                    + encodeURIComponent(config.scope)
                    + "&nonce="
                    + encodeURIComponent(nonce)
                    + "&redirect_uri="
                    + encodeURIComponent(config.redirectUri);

                if (allowLoginForm === false) {
                    url = url + "&prompt=none";

                    // See: http://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
                    var idToken = tokenService.getTokenByType('id');
                    if (idToken) {
                        url = url + "&id_token_hint=" + encodeURIComponent(idToken)
                    }
                }

                if (impersonateUsername || endImpersonation) {
                    url = url + "&prompt=login";
                }

                url = url + "&acr_values=";
                if (config.stickToLastKnownIdp && idpClaimValue) {
                    url = url + encodeURIComponent("idp:" + idpClaimValue + " ") ;
                }

                // external changes
                if (crmTokenValue) {
                    url = url + encodeURIComponent("crmToken:" + crmTokenValue);
                }

                if (impersonateUsername) {
                    url = url + encodeURIComponent("impersonate_aa_username:" + impersonateUsername);
                }

                if (endImpersonation) {
                    url = url + encodeURIComponent("endImpersonation:true");
                }

                $log.debug("final url: " + url);
                return url;
            }

            function createLogoutUrl(state) {
                var idToken = tokenService.getTokenByType('id');

                var hasPathDelimiter = config.basePath.endsWith('/');
                var appendChar = (hasPathDelimiter) ? '' : '/';

                var baseUrl = config.basePath + appendChar;
                var url = baseUrl + config.endSessionEndpoint
                    + "?id_token_hint="
                    + encodeURIComponent(idToken)
                    + "&post_logout_redirect_uri="
                    + encodeURIComponent(config.logoutUri)
                    + "&state="
                    + encodeURIComponent(state || config.state)
                    + "&r=" + Math.random();
                return url;
            }

            function startImplicitFlow(localRedirectPath, crmToken, impersonateUsername, endImpersonation) {
                //**************************
                // External param validation is complete.
                // Now create authorize url add external param: crmToken: acrValue is JWT token
                //************************
                storageService.setItem('referrerHost', $location.$$host);

                const redirectPathToSave = typeof localRedirectPath === 'undefined' ? $location.url() : localRedirectPath;
                storageService.setItem('localRedirectPath', redirectPathToSave);
                var nonce = generateNonce();
                storageService.setItem('loginNonce', nonce);
                var url = createLoginUrl(nonce, null, null, crmToken, impersonateUsername, endImpersonation);

                $window.location.href = url;
            }

            function startLogout() {

                var url;
                if (config.isDotNetClientApp) {
                    url = "/logout.aspx";
                    //handleMvcSigout();
                } else {
                    url = createLogoutUrl(); //use when standalone oidc client (i.e. JS Prototype)
                }

                var impersonating = tokenService.getImpersonatingUsername();
                var selectedDealers = storageService.getItem('impersonatingSelectedDealers');
                storageService.clear();
                if (selectedDealers) {
                    storageService.setItem('impersonatingSelectedDealers', selectedDealers);
                }

                crossDomainStorageService.clear();

                if (impersonating) {
                    startImplicitFlow('/alert-desk', null, null, true);
                }
                else {
                    window.location.replace(url);
                }
            }

            function handleImplicitFlowCallback(err, idToken, accessToken) {
                if (err) {
                    $log.debug('handleImplicitFlowCallback', err.error, err.error_description, err.error_uri)
                    return;
                } else if (!idToken || !accessToken) {
                    $log.debug('handleImplicitFlowCallback', 'no error message, but missing a token', idToken, accessToken);
                    return;
                }

                var newIdTokenClaims = tokenService.convertToClaims(idToken);
                if (!nonceIsValid(newIdTokenClaims)) {
                    silentRefreshFailed$
                    broadcastToParent(silentRefreshFailedEvent, { error_description: 'Token has unexpected nonce' });
                    return;
                }

                tokenService.saveToken('id', idToken);
                tokenService.saveToken('access', accessToken);

                loggedIn$.next({});
                return true;
            }

            function nonceIsValid(idTokenClaims) {
                if (!idTokenClaims) return false;
                if (!idTokenClaims.nonce) return false;

                // Cannot validate the nonce until the issue commented in
                // generateNonce() is solved. See that comment for details.
                return true;
            }

            function handleSilentRefreshCallback(err, newIdToken, newAccessToken) {

                // This method executes inside an IFRAME, so we must use
                // broadcastToParent() instad of $rootScope.$broadcast()

                storageService.removeItem('refreshRunning');

                // If silent refresh fails, trigger a full authentication cycle
                if (err) {
                    silentRefreshFailed$
                    broadcastToParent(silentRefreshFailedEvent, err);
                    return;
                } else if (!newIdToken || !newAccessToken) {
                    broadcastToParent(silentRefreshFailedEvent, { error_description: 'Token missing from response' });
                    return;
                }

                var newIdTokenClaims = tokenService.convertToClaims(newIdToken);
                if (!nonceIsValid(newIdTokenClaims)) {
                    broadcastToParent(silentRefreshFailedEvent, { error_description: 'Token has unexpected nonce' });
                    return;
                }

                var currentClaims = tokenService.getClaimsByTokenType('access');
                var newClaims = tokenService.convertToClaims(newAccessToken);

                if (currentClaims.exp && newClaims.exp && newClaims.exp > currentClaims.exp) {
                    tokenService.saveToken('id', newIdToken);
                    tokenService.saveToken('access', newAccessToken);

                    var expiresAtMSec = newClaims.exp * 1000;

                    broadcastToParent(silentRefreshSuceededEvent);
                } else {
                    // If the token is still valid, ignore this result for now
                    if (currentClaims.exp * 1000 > Date.now()) {
                        broadcastToParent(silentRefreshTokenUnchangedEvent);
                    } else {
                       broadcastToParent(silentRefreshFailedEvent, { error_description: "Unable to acquire a refreshed token" });
                    }
                }
            }

            function trySilentRefresh() {
                if (storageService.getItem('refreshRunning')) {
                    return;
                }

                storageService.setItem('refreshRunning', true);
                try {
                    silentRefreshStarted$.next();

                    var nonce = generateNonce();
                    storageService.setItem('loginNonce', nonce);
                    var url = createLoginUrl(nonce, 'refresh', false);

                    var html = "<iframe src='" + url + "' height='400' width='100%' id='oauthFrame' style='display:none;visibility:hidden;'></iframe>";
                    var elem = angular.element(html);

                    $document.find("body").append(elem);

                    setTimeout(function () {
                        if (storageService.getItem('refreshRunning')) {
                            silentRefreshTimeout$.next();
                            storageService.removeItem('refreshRunning')
                        }

                        $document.find("#oauthFrame").remove();
                    }, 4000);
                } catch (err) {
                    // If the computer went to sleep while displaying the
                    // page, the call to body.append(iframe) was throwing
                    // ERR_NETWORK_IO_SUSPENDED, and we never scheduled the
                    // removal of the refreshRunning flag. That prevented
                    // future requests from trying a silent refresh, even
                    // after the network became available.

                    storageService.removeItem('refreshRunning');

                    // I'm tempted to fire silentRefreshFailedEvent here,
                    // but if we do, the failure will force a full
                    // authentication cycle, which is what we're trying to
                    // avoid in the first place.
                    // If we add this, if any subscribers to that event
                    // call $auth.signIn(), the full auth cycle will be
                    // triggered.
                }
            }

            function handleMvcSigout() {
                tokenService.clearTokens();
                loggedOut$.next();
            }

            function tokenIsValidAt(date) {
                var claims = tokenService.getClaimsByTokenType('access');

                var expiresAtMSec = claims.exp * 1000;

                if (date <= expiresAtMSec) {
                    return true;
                }

                return false;
            }

            /** Generates a nonce from the browser's CRNG */
            function generateNonce(bits) {
                // Production has two entry points:
                //   * portal.autoalert.com
                //   * opportunities.autoalert.com
                //
                // If the user visits portal.autoalert.com, the nonce will
                // get put into that domain's sessionStoarge. Then, when
                // SSO redirects them to communication.autoalert.com, the
                // expected nonce will not be available.
                // That must be solved before we can replace this with the
                // previous implementation.
                return "dummynonce";
            }
        }
    };
});

/* Helpers & Polyfills */
function parseQueryString(queryString) {
    var data = {}, pairs, pair, separatorIndex, escapedKey, escapedValue, key, value;

    if (queryString === null) {
        return data;
    }

    pairs = queryString.split("&");

    for (var i = 0; i < pairs.length; i++) {
        pair = pairs[i];
        separatorIndex = pair.indexOf("=");

        if (separatorIndex === -1) {
            escapedKey = pair;
            escapedValue = null;
        } else {
            escapedKey = pair.substr(0, separatorIndex);
            escapedValue = pair.substr(separatorIndex + 1);
        }

        key = decodeURIComponent(escapedKey);
        value = decodeURIComponent(escapedValue);

        if (key.substr(0, 1) === '/')
            key = key.substr(1);

        data[key] = value;
    }

    return data;
}
