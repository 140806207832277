import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.search";
DashboardOpportunitiesController.$inject = ["$state", "$route", "$rootScope", "$translate", "$translatePartialLoader", "$sce", "legacy", "opportunityCountsService", "userService", "storageService", "activityService", "searchService", "localePaths", "alertTypeDescriptors", "roles", "saleTypes", "widgetTypes", "widgetTitleKeys", "widgetExpandToolTipKeys", "searchPresetService", "authorizationService", "modules"];

/* injects from baggage-loader */
require('./dashboard-opportunities.component.html');

import angular from 'angular';
import './dashboard.scss';
angular.module('AaApp.Opp').component('dashboardOpportunities', {
  templateUrl: '/app/components/dashboard/dashboard-opportunities.component.html',
  controller: DashboardOpportunitiesController,
  bindings: {
    selectedDealerId: '<'
  }
});
/* @ngInject */

function DashboardOpportunitiesController($state, $route, $rootScope, $translate, $translatePartialLoader, $sce, legacy, opportunityCountsService, userService, storageService, activityService, searchService, localePaths, alertTypeDescriptors, roles, saleTypes, widgetTypes, widgetTitleKeys, widgetExpandToolTipKeys, searchPresetService, authorizationService, modules) {
  var $ctrl = this;
  $ctrl.$onInit = $onInit;
  $ctrl.$onChanges = $onChanges; //$ctrl.leaseWidgetDataService = leaseWidgetDataService;
  //$ctrl.retailWidgetDataService = retailWidgetDataService;
  //$ctrl.agendaWidgetDataService = agendaWidgetDataService;

  $ctrl.newOpportunityChangesWidgetDataService = newOpportunityChangesWidgetDataService; //$ctrl.recentlyAssignedWidgetDataService = recentlyAssignedWidgetDataService;

  $ctrl.getOpportunityCounts = getOpportunityCounts;
  $ctrl.getWidgetColumnTypesList = getWidgetColumnTypesList; //$ctrl.getWidgetLink = getWidgetLink;

  $ctrl.closeCallback = closeCallback;
  $ctrl.refreshWidgets = refreshWidgets;
  $ctrl.permissions = {};
  $ctrl.leftColumnWidgets = [];
  $ctrl.rightColumnWidgets = [];
  $ctrl.displayWidgets = []; //TODO:  refactor this to a centralized location.
  //Configuration

  $ctrl.leaseSaleTypes = [saleTypes.lease];
  $ctrl.retailSaleTypes = [saleTypes.retail, saleTypes.balloon]; //$ctrl.managerIndex = -1;

  $ctrl.iframeSource = $sce.trustAsResourceUrl(legacy.iframeUrl + '/Home/ContactUs');
  $ctrl.alertTypeDescriptors = alertTypeDescriptors;
  var isEuroLite = authorizationService.isEuroLite(); //var agendaWidgetConfig = {
  //    managerWidgetOrder: 4,
  //    widgetType: widgetTypes.agenda,
  //    widgetTitleKey: widgetTitleKeys.agenda,
  //    widgetExpandToolTipKey: widgetExpandToolTipKeys.agenda,
  //    widgetLink: 'activitiesAgenda',
  //    widgetDataService: $ctrl.agendaWidgetDataService,
  //    triggerRefresh: 0,
  //};
  //var recentlyAssignedConfig = {
  //    managerWidgetOrder: 5,
  //    widgetType: widgetTypes.opportunitiesTable,
  //    widgetTitleKey: widgetTitleKeys.recentlyAssigned,
  //    widgetExpandToolTipKey: widgetExpandToolTipKeys.recentlyAssigned,
  //    widgetDataService: $ctrl.recentlyAssignedWidgetDataService,
  //    detailViewLinkDisabled: true,
  //    columnTypes: ['opportunity', 'alertBlocks', 'assignedDate'],
  //    triggerRefresh: 0,
  //};
  //var leaseWidgetConfig = {
  //    managerWidgetOrder: 1,
  //    widgetType: widgetTypes.opportunitiesTable,
  //    widgetTitleKey: widgetTitleKeys.lease,
  //    widgetExpandToolTipKey: widgetExpandToolTipKeys.lease,
  //    widgetLink: $ctrl.getWidgetLink('lease'), 
  //    columnTypes: $ctrl.getWidgetColumnTypesList('lease'),
  //    widgetDataService: $ctrl.leaseWidgetDataService,
  //    triggerRefresh: 0,
  //};
  //var retailWidgetConfig = {
  //    managerWidgetOrder: 2,
  //    widgetType: widgetTypes.opportunitiesTable,
  //    widgetTitleKey: widgetTitleKeys.retail,
  //    widgetExpandToolTipKey: widgetExpandToolTipKeys.retail,
  //    widgetLink: $ctrl.getWidgetLink('retail'), 
  //    columnTypes: $ctrl.getWidgetColumnTypesList('retail'),
  //    widgetDataService: $ctrl.retailWidgetDataService,
  //    triggerRefresh: 0,
  //};

  var newOpportunityChangesConfig = {
    managerWidgetOrder: 3,
    widgetType: widgetTypes.opportunitiesTable,
    widgetTitleKey: widgetTitleKeys.newOpportunityChanges,
    widgetExpandToolTipKey: widgetExpandToolTipKeys.newOpportunityChanges,
    widgetDataService: $ctrl.newOpportunityChangesWidgetDataService,
    columnTypes: ['opportunity', 'alertBlocks', 'responsibleUserFullName'],
    detailViewLinkDisabled: true,
    triggerRefresh: 0
  };
  var searchPresetCountConfig = {
    managerWidgetOrder: 7,
    widgetType: widgetTypes.searchPresetCounts,
    widgetTitleKey: widgetTitleKeys.opportunitySearches,
    widgetDataService: searchPresetCountWidgetDataService,
    detailViewLinkDisabled: true,
    triggerRefresh: 0
  };

  function $onInit() {
    $translatePartialLoader.addPart(localePaths.general);
    $translatePartialLoader.addPart(localePaths.dashboard);
    userService.getUserProfile().then(function (data) {
      if (data == undefined) {
        $state.go('login');
      }

      $ctrl.roleId = data.roleId;
      var managerRoles = [roles.dealerAdmin, roles.generalManager, roles.salesManager, roles.financeAndInsuranceManager, roles.usedCarSalesManager];
      var moduleIds = storageService.getItem('moduleIds');

      if (moduleIds) {
        var searchPresentCountWidgetEnabled = moduleIds.indexOf(modules.search) > -1;
      }

      if (managerRoles.indexOf(data.roleId) >= 0) {
        if (searchPresentCountWidgetEnabled) {
          $ctrl.displayWidgets = [searchPresetCountConfig, newOpportunityChangesConfig];
        } else {
          $ctrl.displayWidgets = [newOpportunityChangesConfig];
        }
      } else {
        if (searchPresentCountWidgetEnabled) {
          $ctrl.displayWidgets = [searchPresetCountConfig, //agendaWidgetConfig,
          newOpportunityChangesConfig];
        } else {
          $ctrl.displayWidgets = [//agendaWidgetConfig,
          newOpportunityChangesConfig];
        }
      }

      if (!isEuroLite) {
        if (managerRoles.indexOf(data.roleId) >= 0) {
          if (searchPresentCountWidgetEnabled) {
            $ctrl.leftColumnWidgets = [//leaseWidgetConfig,
            newOpportunityChangesConfig];
            $ctrl.rightColumnWidgets = [searchPresetCountConfig];
          } else {
            $ctrl.leftColumnWidgets = [//leaseWidgetConfig,
            newOpportunityChangesConfig];
            $ctrl.rightColumnWidgets = [//retailWidgetConfig,
              //agendaWidgetConfig,
            ];
          }
        } else {
          if (searchPresentCountWidgetEnabled) {
            $ctrl.leftColumnWidgets = [//agendaWidgetConfig,
            //leaseWidgetConfig,
            newOpportunityChangesConfig];
            $ctrl.rightColumnWidgets = [searchPresetCountConfig];
          } else {
            $ctrl.leftColumnWidgets = [//agendaWidgetConfig,
            //leaseWidgetConfig,
            newOpportunityChangesConfig];
            $ctrl.rightColumnWidgets = [//recentlyAssignedConfig,
              //retailWidgetConfig,
            ];
          }
        }
      } else {
        $ctrl.leftColumnWidgets = [//leaseWidgetConfig,
        ];
        $ctrl.rightColumnWidgets = [//agendaWidgetConfig,
          //retailWidgetConfig,
        ];
      }
    });
    getOpportunityCounts(false);
    authorizationService.getDealSheetPermissions().then(function (data) {
      $ctrl.permissions = data;
    });
  } //function getWidgetLink(type) {
  //    if (isEuroLite)
  //        return 'euroliteopportunities';
  //    switch (type) {
  //        case 'lease':
  //                return 'opportunitiesLease';
  //        case 'retail':
  //                return 'opportunitiesRetail';
  //    }
  //}


  function getWidgetColumnTypesList(type) {
    switch (type) {
      case 'lease':
      case 'retail':
        if (isEuroLite) return ['opportunity', 'alertBlocks'];else return ['opportunity', 'alertBlocks', 'apptOrRoDate'];

      default:
        return ['opportunity', 'alertBlocks', 'apptOrRoDate'];
    }
  }

  function getOpportunityCounts(flushCache) {
    $ctrl.widgetDataPromise = opportunityCountsService.getOpportunityCounts($ctrl.selectedDealerId, flushCache).then(function (data) {
      $ctrl.opportunityCounts = data;
    });
  }

  function $onChanges(changes) {
    if (changes.selectedDealerId && !changes.selectedDealerId.isFirstChange()) {
      refreshAllWidgets();
      getOpportunityCounts();
    }
  } //function leaseWidgetDataService(flushCache) {
  //    return searchService.getOpenOpportunities($ctrl.selectedDealerId, $ctrl.leaseSaleTypes, flushCache);
  //}
  //function retailWidgetDataService(flushCache) {
  //    return searchService.getOpenOpportunities($ctrl.selectedDealerId, $ctrl.retailSaleTypes, flushCache);
  //}
  //function agendaWidgetDataService(flushCache) {
  //    return activityService.getAgendaItems( $ctrl.selectedDealerId, null, flushCache);
  //}


  function newOpportunityChangesWidgetDataService(flushCache) {
    return searchService.getFreshOpportunities($ctrl.selectedDealerId, flushCache);
  } //function recentlyAssignedWidgetDataService(flushCache) {
  //    return searchService.getRecentlyAssignedOpportunities($ctrl.selectedDealerId, flushCache);
  //}


  function searchPresetCountWidgetDataService(hardRefresh, widgetState) {
    var maxRowsDisplayed = widgetState && widgetState.maxRowsDisplayed;
    return searchPresetService.getDealerSearchPresetLayoutCounts(hardRefresh, $ctrl.selectedDealerId, maxRowsDisplayed);
  }

  function closeCallback(type) {
    //refresh
    switch (type) {
      case widgetTypes.lease:
      case widgetTypes.retail:
      case widgetTypes.newOpportunityChanges:
        $ctrl.refreshWidgets();
        break;

      case widgetTypes.agenda:
      case widgetTypes.recentlyAssigned:
      default:
        break;
    }
  }

  function refreshAllWidgets() {
    if ($ctrl.leftColumnWidgets) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $ctrl.leftColumnWidgets[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var widget = _step.value;
          widget.triggerRefresh++;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    if ($ctrl.rightColumnWidgets) {
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = $ctrl.rightColumnWidgets[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _widget = _step2.value;
          _widget.triggerRefresh++;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }
  }

  function refreshWidgets() {
    if ($ctrl.leftColumnWidgets) {
      for (var i = 0; i < $ctrl.leftColumnWidgets.length; i++) {
        if ($ctrl.leftColumnWidgets[i].widgetType === widgetTypes.agenda || $ctrl.leftColumnWidgets[i].widgetType === widgetTypes.recentlyAssigned) {
          $ctrl.leftColumnWidgets[i].triggerRefresh++;
        }
      }
    }

    if ($ctrl.rightColumnWidgets) {
      for (var k = 0; k < $ctrl.rightColumnWidgets.length; k++) {
        if ($ctrl.rightColumnWidgets[k].widgetType === widgetTypes.agenda || $ctrl.rightColumnWidgets[k].widgetType === widgetTypes.recentlyAssigned) {
          $ctrl.rightColumnWidgets[k].triggerRefresh++;
        }
      }
    }
  }
}