/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserModuleAccessDto } from '../models/user-module-access-dto';
import { UserModuleDealerDto } from '../models/user-module-dealer-dto';
@Injectable({
  providedIn: 'root',
})
class UserModuleAccessClientService extends __BaseService {
  static readonly GETPath = '/api/UserModuleAccess';
  static readonly GetModuleAccessByModuleidGETPath = '/api/UserModuleAccess/GetModuleAccess/{moduleId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GETResponse(): __Observable<__StrictHttpResponse<UserModuleAccessDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserModuleAccess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserModuleAccessDto>;
      })
    );
  }
  /**
   * @return Success
   */
  GET(): __Observable<UserModuleAccessDto> {
    return this.GETResponse().pipe(
      __map(_r => _r.body as UserModuleAccessDto)
    );
  }

  /**
   * @param moduleId undefined
   * @return Success
   */
  GetModuleAccessByModuleidGETResponse(moduleId: number): __Observable<__StrictHttpResponse<UserModuleDealerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/UserModuleAccess/GetModuleAccess/${moduleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserModuleDealerDto>;
      })
    );
  }
  /**
   * @param moduleId undefined
   * @return Success
   */
  GetModuleAccessByModuleidGET(moduleId: number): __Observable<UserModuleDealerDto> {
    return this.GetModuleAccessByModuleidGETResponse(moduleId).pipe(
      __map(_r => _r.body as UserModuleDealerDto)
    );
  }
}

module UserModuleAccessClientService {
}

export { UserModuleAccessClientService }
