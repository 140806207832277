"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./mileage-edit-action.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("../../../icon/icon.component.ngfactory"));
var i4 = __importStar(require("../../../icon/icon.component"));
var i5 = __importStar(require("@angular/platform-browser"));
var i6 = __importStar(require("@angular/common"));
var i7 = __importStar(require("../../../../filters/locale-number.pipe"));
var i8 = __importStar(require("../../../../modules/storage/storage.service"));
var i9 = __importStar(require("../../../../filters/locale-date.pipe"));
var i10 = __importStar(require("./mileage-edit-action.component"));
var i11 = __importStar(require("../../../../services/ngx-modal-wrapper.service"));
var i12 = __importStar(require("../../../../core/toaster-logger.service"));
var i13 = __importStar(require("../../../../ajs-upgraded-providers"));
var styles_MileageEditActionComponent = [i0.styles];
var RenderType_MileageEditActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MileageEditActionComponent, data: {} });
exports.RenderType_MileageEditActionComponent = RenderType_MileageEditActionComponent;
function View_MileageEditActionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "edit-icon qa-mileage-edit-action-link"], ["href", "#"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editMileageModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "icon", [["class", "icon aa-icon-core-edit-icon-000000"]], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i1.ɵdid(3, 638976, null, 0, i4.IconComponent, [i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("editMileageToolTip")), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_MileageEditActionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "field row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "field-label"], ["for", "mileage"], ["translate", "mileageLabel"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "span", [["class", "field-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "span", [["class", "qa-vehicle-mileage"], ["id", "mileage"], ["translate", "mileageSummary"]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵppd(6, 1), i1.ɵppd(7, 1), i1.ɵppd(8, 1), i1.ɵpod(9, { estimate: 0, actual: 1, recordedDate: 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MileageEditActionComponent_2)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mileageLabel"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "mileageSummary"; var currVal_2 = _ck(_v, 9, 0, (i1.ɵunv(_v, 5, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.estimatedMileage)) || ""), i1.ɵunv(_v, 5, 1, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _co.recordedMileage)), i1.ɵunv(_v, 5, 1, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 1), _co.recordedDate))); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.permissions.editMileage; _ck(_v, 11, 0, currVal_3); }, null); }
function View_MileageEditActionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.LocaleNumberPipe, [i8.StorageService]), i1.ɵpid(0, i9.LocaleDatePipe, [i8.StorageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MileageEditActionComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recordedMileage != null); _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_MileageEditActionComponent_0 = View_MileageEditActionComponent_0;
function View_MileageEditActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mileage-edit-action", [], null, null, null, View_MileageEditActionComponent_0, RenderType_MileageEditActionComponent)), i1.ɵdid(1, 49152, null, 0, i10.MileageEditActionComponent, [i11.NgxModalWrapperService, i2.TranslateService, i12.ToasterLoggerService, i13.VehicleService], null, null)], null, null); }
exports.View_MileageEditActionComponent_Host_0 = View_MileageEditActionComponent_Host_0;
var MileageEditActionComponentNgFactory = i1.ɵccf("mileage-edit-action", i10.MileageEditActionComponent, View_MileageEditActionComponent_Host_0, { recordedMileage: "recordedMileage", recordedDate: "recordedDate", estimatedMileage: "estimatedMileage", permissions: "permissions", opportunityId: "opportunityId" }, { onMileageValueChange: "onMileageValueChange", startWorkingState: "startWorkingState" }, []);
exports.MileageEditActionComponentNgFactory = MileageEditActionComponentNgFactory;
