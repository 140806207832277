"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VehicleImageComponent = /** @class */ (function () {
    function VehicleImageComponent() {
        this.vehicleImageNotAvailableUrl = require('../../../../images/vehicle-image-not-available.png');
    }
    // on error of image src load default fallback image
    VehicleImageComponent.prototype.updateUrl = function () {
        this.url = this.vehicleImageNotAvailableUrl;
    };
    return VehicleImageComponent;
}());
exports.VehicleImageComponent = VehicleImageComponent;
