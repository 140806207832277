
/* injects from baggage-loader */
require('./credit-prescreen.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('creditPrescreen', {
    templateUrl: '/app/components/deal-sheet/credit-prescreen.component.html',
    controller: CreditPrescreenController,
    bindings: {
        opportunityId: '<',
        status: '<',
        permissions: '<',
        moduleIds: '<',
        dealerSettings: '<',
        onPrescreen: '&',
        onStatusChange: '&'
    }
});

/* @ngInject */
function CreditPrescreenController($q, $translate, $timeout, logger, modules, warningTitleKey, creditPrescreenErrorKey, opportunityService, creditPrescreenStatuses, environmentVariables, opportunity2Service) {
    var $ctrl = this;

    var opportunityService = environmentVariables.opportunityServiceAng8Enabled ? opportunity2Service : opportunityService;

    $ctrl.submitPromise;
    $ctrl.submitted;
    $ctrl.completed;
    $ctrl.enabled;
    $ctrl.submit = submit;
    $ctrl.$onChanges = onChanges;
    $ctrl.startPrescreenTime;
    $ctrl.currentPrescreenTime;
    const CHECK_STATUS_TIMEOUT = 30000;
    const CHECK_STATUS_INTERVAL = 5000;

    function onChanges(changes) {
        if ((changes.permissions || changes.moduleIds || changes.dealerSettings)
            && $ctrl.permissions && $ctrl.moduleIds && $ctrl.dealerSettings) {

            var enabled = $ctrl.permissions.requestCreditPrescreen
                && $ctrl.moduleIds.indexOf(modules.creditConvert) > -1
                && $ctrl.dealerSettings.useCreditSoftPull;

            if (enabled) {
                if ($ctrl.status) {
                    if (($ctrl.status.status != null
                        && $ctrl.status.status != creditPrescreenStatuses.error)
                        && getDateDifferenceInDays(new Date($ctrl.status.requestDate), new Date()) < 30) {

                        $ctrl.submitted = true;
                        $ctrl.completed = true;
                    }
                    else if ($ctrl.status.status == creditPrescreenStatuses.error) {
                        showErrorMessage();
                    }
                    else if ($ctrl.status.status == creditPrescreenStatuses.processing) {
                        $ctrl.submitted = true;
                        $ctrl.completed = false;
                        var deferred = $q.defer();
                        $ctrl.submitPromise = deferred.promise;

                        checkStatus(deferred);
                    }
                }

                $ctrl.enabled = true;
            }
        }
    }

    function submit() {
        var deferred = $q.defer();
        $ctrl.submitPromise = deferred.promise;
        $ctrl.submitted = true;

        $ctrl.count = 0;
        opportunityService.submitCreditPrescreenRequest($ctrl.opportunityId, $ctrl.permissions, $ctrl.moduleIds, $ctrl.dealerSettings)
            .then(function (success) {
                if (success) {
                    $ctrl.startPrescreenTime = new Date();
                    checkStatus(deferred);
                }
                else {
                    $ctrl.submitted = false;
                    deferred.reject("unsuccessful");
                    showErrorMessage();
                }
            })
            .catch(function (error) {
                $ctrl.submitted = false;
                deferred.reject(error);

                if (error && error.data && error.data[0] && error.data[0].errorMessage) {
                    showErrorMessage(error.data[0].errorMessage);
                }
                else {
                    showErrorMessage();
                }
            });
    }

    function checkStatus(deferred) {
        $ctrl.currentPrescreenTime = new Date();
        let elapsedTime = $ctrl.currentPrescreenTime - $ctrl.startPrescreenTime;

        if (elapsedTime > CHECK_STATUS_TIMEOUT) {
            $timeout.cancel(deferred);
            $ctrl.submitted = true;
            $ctrl.completed = true;

            $translate('creditPrescreenResponseTimeError').then(function (message) {
                showErrorMessage(message);
            });
            return;
        }

        opportunityService.getCreditPrescreenStatus($ctrl.opportunityId, $ctrl.moduleIds)
            .then(function (result) {
                if (result && (result.status == creditPrescreenStatuses.creditDataReceived || result.status == creditPrescreenStatuses.financialDataReceived)) {
                    deferred.resolve();
                    $ctrl.completed = true;
                    $ctrl.onPrescreen();
                }
                else if (result && result.status == creditPrescreenStatuses.noDataReceived) {
                    deferred.resolve();
                    $ctrl.completed = true;
                    $ctrl.onStatusChange({ status: result });
                }
                else if (result && result.status == creditPrescreenStatuses.error) {
                    $ctrl.submitted = false;
                    showErrorMessage();
                }
                else {
                    $timeout(function () {
                        checkStatus(deferred);
                    }, CHECK_STATUS_INTERVAL);
                }
            });
    }

    function getDateDifferenceInDays(date1, date2) {
        var millisecondsPerDay = 1000 * 60 * 60 * 24;
        var utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        var utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

        return Math.floor((utcDate2 - utcDate1) / millisecondsPerDay);
    }

    function showErrorMessage(message) {
        if (message) {
            logger.warning(message, null, warningTitleKey);
        }
        else {
            logger.warning(creditPrescreenErrorKey, null, warningTitleKey);
        }
    }
}
