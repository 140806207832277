import "core-js/modules/es6.array.sort";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es7.object.values";
CustomerEngagementWidgetWindowController.$inject = ["customerEngagementService", "$uibModal", "$filter", "dealerService"];

/* injects from baggage-loader */
require('./customer-engagement-widget-window.component.html');

import { OpportunitiesModule } from '../../core/opportunities.module';
import moment from 'moment';
import { EngagementEventTypes, EngagementEventGroups } from '../../constants/engagement-event-types.constant';
import { sortEngagementsByDateDescending } from './customer-engagement-widget.component';
import { AssetTypes } from '../../constants';
OpportunitiesModule.component('customerEngagementWidgetWindow', {
  templateUrl: '/app/components/header/customer-engagement-widget-window.component.html',
  controller: CustomerEngagementWidgetWindowController,
  bindings: {
    resolve: '<',
    // { engagements, hoursBack, onHoursBackChanged, onCustomerEngagementClicked }
    close: '&'
  }
});

function CustomerEngagementWidgetWindowController(customerEngagementService, $uibModal, $filter, dealerService) {
  "ngInject";

  var $ctrl = this;
  var assetTypes = AssetTypes;
  $ctrl.hoursBackOptions = [24, 48, 72];
  $ctrl.typeFilters = {};
  $ctrl.$onChanges = $onChanges;
  $ctrl.applyFilters = applyFilters;
  $ctrl.engagementClicked = engagementClicked;
  $ctrl.highEngagementClicked = highEngagementClicked;
  $ctrl.iconClassForEngagement = iconClassForEngagement;
  $ctrl.iconNameForEngagement = iconNameForEngagement;
  $ctrl.descriptionForEngagement = descriptionForEngagement;
  $ctrl.setHoursBack = setHoursBack;
  $ctrl.$onInit = init; // Engagements are already sorted by eventDate so set this as the current sortBy field

  $ctrl.sortBy = "eventDate";
  $ctrl.sortEngagements = sortEngagements;
  $ctrl.showReviewPro = false;
  $ctrl.filteredItems = [];
  $ctrl.pagedItems = [];
  $ctrl.maxPageSize = 10;
  $ctrl.maxMinArrows = "false";
  $ctrl.onPagedItems = onPagedItems;
  $ctrl.onPagerChange = onPagerChange;
  $ctrl.iconClassForReviewRating = iconClassForReviewRating;
  $ctrl.engagementEventGroups = EngagementEventGroups;
  $ctrl.sortEngagements_StandAlone = sortEngagements_StandAlone;
  $ctrl.resetPage = false;
  $ctrl.onResetComplete = onResetComplete;
  $ctrl.lastViewedDate;
  var $translateFilter = $filter('translate');

  function init() {
    dealerService.getIsAssetEnabledForDealer(assetTypes.geoAlert).then(function (geoAlertEnabled) {
      $ctrl.showGeoAlert = geoAlertEnabled;
    });
    dealerService.getIsAssetEnabledForDealer(assetTypes.reviewBuilderPro).then(function (reviewProEnabled) {
      $ctrl.showReviewPro = reviewProEnabled;
    });
    var subscription = customerEngagementService.newEngagements.subscribe(function (evt) {
      var engagementTime = new Date(evt.data.engagementTimeUtc);

      if (!$ctrl.lastViewedDate || engagementTime > $ctrl.lastViewedDate) {
        setHoursBack();
      }
    });

    this.$onDestroy = function () {
      return subscription.unsubscribe();
    };
  }

  function $onChanges(changes) {
    if (changes.resolve) {
      applyFilters();
      setHoursBack();
    }
  }

  function engagementClicked(engagement) {
    $ctrl.resolve.onCustomerEngagementClicked({
      engagement: engagement
    });
  }

  function highEngagementClicked(engagement, $event) {
    $event.stopPropagation();
    var dateFrom = getDateHoursBack($ctrl.resolve.hoursBack);
    engagement.workingPromise = customerEngagementService.getCustomerEngagementsByEntity(engagement.opportunityId, dateFrom, true).then(function (_engagements) {
      _engagements = _engagements.map(function (engagement) {
        return {
          description: $translateFilter(descriptionForEngagement(engagement)),
          eventDate: engagement.eventDate,
          engagementEventGroupId: engagement.engagementEventGroupId,
          reviewRating: engagement.reviewRating,
          ratingIcon: engagement.ratingIcon,
          ratingTitle: engagement.ratingTitle
        };
      });
      $uibModal.open({
        component: 'customerEngagementsByEntityModal',
        size: 'sm',
        // small
        resolve: {
          customerFullName: function customerFullName() {
            return engagement.customerFullName;
          },
          engagements: function engagements() {
            return _engagements;
          }
        }
      });
    });
  }

  function iconNameForEngagement(engagement) {
    switch (engagement.engagementEventGroupId) {
      case EngagementEventGroups.one2one:
        return 'o2o-offer';

      case EngagementEventGroups.web:
        return 'web';

      case EngagementEventGroups.reviews:
        return 'reviews';

      case EngagementEventGroups.text:
        return 'sms';

      case EngagementEventGroups.autoAssistant:
        return 'auto-assistant';
    }
  }

  function iconClassForEngagement(engagement) {
    switch (engagement.engagementEventGroupId) {
      case EngagementEventGroups.email:
        return 'aa-icon-core-email-icon-000000';

      case EngagementEventGroups.service:
        return 'aa-icon-service-drive';

      case EngagementEventGroups.intelligentMarketing:
        return 'im-offer-arrow-icon-blue';

      case EngagementEventGroups.geoAlert:
        return 'geo-alert-icon-blue';
    }
  }

  function descriptionForEngagement(engagement) {
    switch (engagement.engagementEventTypeId) {
      case EngagementEventTypes.newServiceAppointment:
        return 'newServiceAppointment';

      case EngagementEventTypes.inService:
        return 'inService';

      case EngagementEventTypes.emailOpened:
        return 'emailOpened';

      case EngagementEventTypes.emailReplied:
        return 'emailReplied';

      case EngagementEventTypes.textMessageReplied:
        return 'textMessageReply';

      case EngagementEventTypes.websiteEngaged:
        return 'websiteEngaged';

      case EngagementEventTypes.websiteLeadSubmission:
        return 'websiteLeadSubmission';

      case EngagementEventTypes.purlView:
        return 'purlView';

      case EngagementEventTypes.purlLeadSubmission:
        return 'purlLeadSubmission';

      case EngagementEventTypes.onlineServiceReview:
        return 'onlineServiceReview';

      case EngagementEventTypes.onlineSalesReview:
        return 'onlineSalesReview';

      case EngagementEventTypes.customerRequestedContact:
        return 'repliedToAutoAssistant';

      case EngagementEventTypes.purlViewIM:
        return 'purlView';

      case EngagementEventTypes.purlLeadSubmissionIM:
        return 'purlLeadSubmission';

      case EngagementEventTypes.activeGeoAlert:
        return 'geoAlertCustomer';
    }
  }

  function setHoursBack() {
    var hoursBack = parseInt($ctrl.resolve.hoursBack);
    $ctrl.workingPromise = customerEngagementService.getCustomerEngagement(hoursBack).then(function (_ref) {
      var lastViewedDate = _ref.lastViewedDate,
          engagements = _ref.engagements;
      engagements = sortEngagementsByDateDescending(engagements);
      $ctrl.lastViewedDate = lastViewedDate;
      $ctrl.resolve.engagements = engagements;
      $ctrl.resolve.onHoursBackChanged({
        engagements: engagements,
        hoursBack: hoursBack
      });
      applyFilters();
    });
  }

  function applyFilters() {
    $ctrl.filteredEngagements = getFilteredEngagements();
    sortEngagements($ctrl.sortBy); // for pagination

    $ctrl.filteredItems = $ctrl.filteredEngagements;
    onFilteredItems($ctrl.filteredItems);
  }

  function getFilteredEngagements() {
    return $ctrl.resolve.engagements.filter(filter);

    function filter(record) {
      return record && record.eventDate >= getDateHoursBack($ctrl.resolve.hoursBack) && (!shouldFilterByType() || filterByType(record)) && (!shouldFilterByHighEngagement() || record.highEngagement);
    }

    function shouldFilterByType() {
      // Filter only if at least one filter is enabled
      return Object.values($ctrl.typeFilters).some(function (v) {
        return v;
      });
    }

    function shouldFilterByHighEngagement() {
      return $ctrl.hotFilter;
    }

    function filterByType(record) {
      return $ctrl.typeFilters.one2one && (record.one2oneCount || record.intelligentMarketingCount) || $ctrl.typeFilters.email && record.emailCount || $ctrl.typeFilters.email && record.textMessageCount || $ctrl.typeFilters.email && record.autoAssistantCount || $ctrl.typeFilters.web && record.webCount || $ctrl.typeFilters.service && record.serviceCount || $ctrl.typeFilters.reviews && record.reviewCount || $ctrl.typeFilters.geoAlert && record.geoAlertCount;
    }
  }

  function getDateHoursBack(hoursBack) {
    return moment().subtract(hoursBack, 'hours').toDate();
  }

  function sortEngagements_StandAlone(field) {
    sortEngagements(field);
    $ctrl.filteredItems = $ctrl.filteredEngagements;
    $ctrl.resetPage = true;
  }

  function sortEngagements(field) {
    // Sort the data in descending order
    $ctrl.filteredEngagements.sort(function (a, b) {
      return b[field] - a[field];
    });
  } // modal pager control events


  function onPagerChange($value) {
    $ctrl.pager = $value;
    $ctrl.currentPage = $value.currentPage;
    $ctrl.totalPages = $value.totalPages;
  }

  function setPage(page) {
    $ctrl.resetPage = true;
  }

  function onResetComplete() {
    $ctrl.resetPage = false;
  }

  function onPagedItems($value) {
    $ctrl.pagedItems = $value;
  }

  function onFilteredItems($value) {
    $ctrl.filteredItems = $value;
  }

  function iconClassForReviewRating(onlineReviewRating) {
    return 'rating-' + onlineReviewRating + 'star';
  }
}