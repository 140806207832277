"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var ActivityClientService = /** @class */ (function (_super) {
    __extends(ActivityClientService, _super);
    function ActivityClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param request undefined
     */
    ActivityClientService.prototype.RunActivityRulePOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Activity/RunActivityRule", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    ActivityClientService.prototype.RunActivityRulePOST = function (request) {
        return this.RunActivityRulePOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param submission undefined
     * @return Success
     */
    ActivityClientService.prototype.SubmitActionPOSTResponse = function (submission) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = submission;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Activity/SubmitAction", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param submission undefined
     * @return Success
     */
    ActivityClientService.prototype.SubmitActionPOST = function (submission) {
        return this.SubmitActionPOSTResponse(submission).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ActivityClientService.GetAgendaItemsGETParams` containing the following parameters:
     *
     * - `take`:
     *
     * - `flushCache`:
     *
     * @return Success
     */
    ActivityClientService.prototype.GetAgendaItemsGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.take != null)
            __params = __params.set('take', params.take.toString());
        if (params.flushCache != null)
            __params = __params.set('flushCache', params.flushCache.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Activity/GetAgendaItems", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ActivityClientService.GetAgendaItemsGETParams` containing the following parameters:
     *
     * - `take`:
     *
     * - `flushCache`:
     *
     * @return Success
     */
    ActivityClientService.prototype.GetAgendaItemsGET = function (params) {
        return this.GetAgendaItemsGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ActivityClientService.GetAgendaItemsWithEntityDataGETParams` containing the following parameters:
     *
     * - `viewAllUsers`:
     *
     * - `take`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * - `activityTypes`:
     *
     * @return Success
     */
    ActivityClientService.prototype.GetAgendaItemsWithEntityDataGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.viewAllUsers != null)
            __params = __params.set('viewAllUsers', params.viewAllUsers.toString());
        if (params.take != null)
            __params = __params.set('take', params.take.toString());
        if (params.dateTo != null)
            __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null)
            __params = __params.set('dateFrom', params.dateFrom.toString());
        (params.activityTypes || []).forEach(function (val) { if (val != null)
            __params = __params.append('activityTypes', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Activity/GetAgendaItemsWithEntityData", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ActivityClientService.GetAgendaItemsWithEntityDataGETParams` containing the following parameters:
     *
     * - `viewAllUsers`:
     *
     * - `take`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * - `activityTypes`:
     *
     * @return Success
     */
    ActivityClientService.prototype.GetAgendaItemsWithEntityDataGET = function (params) {
        return this.GetAgendaItemsWithEntityDataGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ActivityClientService.GetActivitiesForEntityPOSTParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `activityTypes`:
     *
     * - `activityStatuses`:
     */
    ActivityClientService.prototype.GetActivitiesForEntityPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.entityId != null)
            __params = __params.set('entityId', params.entityId.toString());
        (params.activityTypes || []).forEach(function (val) { if (val != null)
            __params = __params.append('activityTypes', val.toString()); });
        (params.activityStatuses || []).forEach(function (val) { if (val != null)
            __params = __params.append('activityStatuses', val.toString()); });
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Activity/GetActivitiesForEntity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ActivityClientService.GetActivitiesForEntityPOSTParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `activityTypes`:
     *
     * - `activityStatuses`:
     */
    ActivityClientService.prototype.GetActivitiesForEntityPOST = function (params) {
        return this.GetActivitiesForEntityPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     */
    ActivityClientService.prototype.GetNewActivityNotificationsByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Activity/GetNewActivityNotifications/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    ActivityClientService.prototype.GetNewActivityNotificationsByEntityidGET = function (entityId) {
        return this.GetNewActivityNotificationsByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    ActivityClientService.prototype.AddUpgradeProposalActivityNotePOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Activity/UpgradeProposal", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    ActivityClientService.prototype.AddUpgradeProposalActivityNotePOST = function (request) {
        return this.AddUpgradeProposalActivityNotePOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    ActivityClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Activity/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    ActivityClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    ActivityClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Activity/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    ActivityClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    ActivityClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Activity/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    ActivityClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    ActivityClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Activity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    ActivityClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    ActivityClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Activity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    ActivityClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    ActivityClientService.RunActivityRulePOSTPath = '/api/Activity/RunActivityRule';
    ActivityClientService.SubmitActionPOSTPath = '/api/Activity/SubmitAction';
    ActivityClientService.GetAgendaItemsGETPath = '/api/Activity/GetAgendaItems';
    ActivityClientService.GetAgendaItemsWithEntityDataGETPath = '/api/Activity/GetAgendaItemsWithEntityData';
    ActivityClientService.GetActivitiesForEntityPOSTPath = '/api/Activity/GetActivitiesForEntity';
    ActivityClientService.GetNewActivityNotificationsByEntityidGETPath = '/api/Activity/GetNewActivityNotifications/{entityId}';
    ActivityClientService.AddUpgradeProposalActivityNotePOSTPath = '/api/Activity/UpgradeProposal';
    ActivityClientService.ByIdGETPath = '/api/Activity/{id}';
    ActivityClientService.ByIdDELETEPath = '/api/Activity/{id}';
    ActivityClientService.GetManyGETPath = '/api/Activity/GetMany';
    ActivityClientService.PUTPath = '/api/Activity';
    ActivityClientService.POSTPath = '/api/Activity';
    ActivityClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityClientService_Factory() { return new ActivityClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: ActivityClientService, providedIn: "root" });
    return ActivityClientService;
}(base_service_1.BaseService));
exports.ActivityClientService = ActivityClientService;
