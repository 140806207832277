import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { DealSheetService } from '../../../ajs-upgraded-providers';
import { ActivityResultTypeDescriptors, ActivityStatusTypes, ActivityIconClasses } from '../../../constants';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { AuthorizationService } from '../../../services/authorization.service';

@Component({
    selector: 'dashboard-agenda',
    templateUrl: './dashboard-agenda.component-ng.html',
    styleUrls: ['./dashboard-agenda.component.scss']
})
export class DashboardAgendaComponent implements OnInit {
    private activityResultTypeDescriptors = ActivityResultTypeDescriptors;
    private activityStatusTypes = ActivityStatusTypes;
    @Input() widgetData: any;
    @Output() dealSheetClosed = new EventEmitter<any>();
    public activityIconClasses = ActivityIconClasses;
    //public activityResult: string;

    constructor(public tService: TranslateService,
        private authorizationService: AuthorizationService,
        @Inject(DealSheetService) private dealSheetService) {
    }

    ngOnInit() {
    }

    openDealSheet(entityId) {
        const isEuroLite = this.authorizationService.isEuroLite();

        if (!isEuroLite)
            this.dealSheetService.openDealSheetModal(entityId, null, this.dealSheetClose);
        else
            this.dealSheetService.openDealSheetLiteModal(null, entityId);
    }

    getResult(item) {
        if (item.activityResultID) {
            const activityResultType = _.find(this.activityResultTypeDescriptors, function (o) {
                return o.activityResultID === item.activityResultID;
            });
            return this.tService.instant(activityResultType.activityResultNameTranslateKey);
        }
        else if (item.activityStatusID) {
            var activityStatusTypeName = (_.invert(this.activityStatusTypes))[item.activityStatusID];
            return this.tService.instant('ACTIVITY.' + activityStatusTypeName.toUpperCase());
        }
    }

    dealSheetClose = (opId: string) => {
        this.dealSheetClosed.emit({ opId: opId });
    }
}
