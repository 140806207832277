"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var customer_workflow_service_1 = require("../../../services/customer-workflow.service");
var modal_1 = require("ngx-bootstrap/modal");
//import { PandoForm } from '../../../interfaces/pando-form.interface';
var pando_form_control_interface_1 = require("../../../interfaces/pando-form-control.interface");
var moment = require("moment");
var locale_date_time_pipe_1 = require("../../../filters/locale-date-time.pipe");
var DealSheetProcessComponent = /** @class */ (function () {
    function DealSheetProcessComponent(_bsModalRef, customerWorkflowService, tService, localeDatetimePipe) {
        this._bsModalRef = _bsModalRef;
        this.customerWorkflowService = customerWorkflowService;
        this.tService = tService;
        this.localeDatetimePipe = localeDatetimePipe;
        this.onClosed = new core_1.EventEmitter();
        this.formReady = false;
        this.formLoaded = false;
        this.startButtonEnabled = false;
        this.showLoadFormButton = false;
        this.processSettings = {};
        this.multiListSettings = {};
        this.displayForm = false;
        this.processForm = null;
        this.formControlType = pando_form_control_interface_1.PandoFormControlType;
        this.workflowFormAnswers = null;
        this.sliderOptions = {};
    }
    Object.defineProperty(DealSheetProcessComponent.prototype, "processesAvailable", {
        get: function () {
            return this.workFlowTemplates.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DealSheetProcessComponent.prototype, "top5ProcessesAvailable", {
        get: function () {
            return this.top5Recent.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    DealSheetProcessComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.processSettings = {
            start: this.tService.instant('start'),
            cancel: this.tService.instant('cancel')
        };
        this.multiListSettings = {
            singleSelection: false,
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
            badgeShowLimit: 4,
            labelKey: "label",
            primaryKey: "value"
        };
        this.customerWorkflowService.getStartListCustomer(this.entityId).then(function (result) {
            _this.workFlowTemplates = result.workflowTemplates;
            _this.top5Recent = result.top5Recent;
            if (_this.workFlowTemplates.length > 0) {
                _this.selectedWorkFlowTemplate = _this.workFlowTemplates[0];
                _this.workflowTemplateSelect();
            }
            _this.formReady = true;
        });
    };
    DealSheetProcessComponent.prototype.onTop5Clicked = function (item) {
        var selectedWorkflowTemplate = this.workFlowTemplates.find(function (t) { return t.id == item.workflowtemplateId; });
        if (selectedWorkflowTemplate != null) {
            this.selectedWorkFlowTemplate = selectedWorkflowTemplate;
            this.workflowTemplateSelect();
        }
    };
    DealSheetProcessComponent.prototype.workflowTemplateSelect = function () {
        this.formLoaded = false;
        this.startButtonEnabled = !this.selectedWorkFlowTemplate.completeFormOnStart;
        this.showLoadFormButton = this.selectedWorkFlowTemplate.completeFormOnStart;
        this.processForm = null;
    };
    DealSheetProcessComponent.prototype.loadForm = function () {
        var _this = this;
        // get form data for selected workflow template if it has a form:
        if (this.selectedWorkFlowTemplate && this.selectedWorkFlowTemplate.startableFormTemplateId) {
            this.busy = this.customerWorkflowService.GetStartFormByStartableformtemplateid(this.selectedWorkFlowTemplate.startableFormTemplateId).then(function (result) {
                _this.processForm = result;
                _this.sliderOptions = {};
                var _loop_1 = function (control) {
                    if (control.controlType === pando_form_control_interface_1.PandoFormControlType.LinearScale) {
                        var options = {
                            floor: control.minValue,
                            ceil: control.maxValue,
                            getLegend: function (value) {
                                switch (value) {
                                    case control.minValue:
                                        return control.minLabel === control.minValue.toString() ? "" : control.minLabel;
                                    case control.maxValue:
                                        return control.maxLabel === control.maxValue.toString() ? "" : control.maxLabel;
                                    default:
                                        return "";
                                }
                            }
                        };
                        // if the range of values is 15 or less show ticks:
                        if ((options.ceil - options.floor) <= 15) {
                            options.showTicks = true;
                        }
                        _this.sliderOptions[control.formControlId] = options;
                    }
                };
                for (var _i = 0, _a = _this.processForm.formControls; _i < _a.length; _i++) {
                    var control = _a[_i];
                    _loop_1(control);
                }
                _this.showLoadFormButton = false;
                _this.startButtonEnabled = true;
                _this.formLoaded = true;
            });
        }
    };
    DealSheetProcessComponent.prototype.startWorkflow = function () {
        var _this = this;
        this.startButtonEnabled = false;
        this.busy = this.customerWorkflowService.startCustomerWorkflowByEntityidByWorkflowtemplateid(this.entityId, this.selectedWorkFlowTemplate.id, this.workflowFormAnswers).then(function (result) {
            var isSuccess = false;
            if (result != null && result.workflowInstanceId)
                isSuccess = true;
            _this.onClosed.emit({ started: isSuccess });
            _this._bsModalRef.hide();
        });
    };
    DealSheetProcessComponent.prototype.onCancel = function () {
        this.onClosed.emit({ started: null });
        this._bsModalRef.hide();
    };
    DealSheetProcessComponent.prototype.convertAnswers = function () {
        var _this = this;
        this.workflowFormAnswers = {
            formTemplateId: this.processForm.formTemplateId,
            answers: []
        };
        this.workflowFormAnswers.answers = this.processForm.formControls
            .map(function (control) {
            var valuesList = null;
            if (control.controlType === pando_form_control_interface_1.PandoFormControlType.MultiSelectList) {
                if (control.values && control.values.length > 0) {
                    if (control.values.length > 0) {
                        valuesList = control.values.map(function (selection) { return selection.value; });
                    }
                    else {
                        valuesList = [];
                    }
                }
            }
            if (control.controlType === pando_form_control_interface_1.PandoFormControlType.Date) {
                if (control.value) {
                    var dateWithOffset = new Date(_this.localeDatetimePipe.transform(new Date(control.value)));
                    control.value = moment(dateWithOffset).format();
                }
            }
            if (control.controlType === pando_form_control_interface_1.PandoFormControlType.Time) {
                if (control.value) {
                    control.value = moment(control.value).format('hh:mm A');
                }
            }
            return {
                formControlId: control.formControlId,
                inputType: control.inputType,
                value: control.value === undefined || (control.otherValue && control.otherValue.length > 0) ? null : control.value,
                values: valuesList,
                otherValue: (control.otherValue === undefined || control.otherValue === null || control.otherValue.length === 0) ? null : control.otherValue
            };
        });
        //console.log(this.workflowFormAnswers);
    };
    DealSheetProcessComponent.prototype.setDate = function (date, formControlId) {
        var control = this.processForm.formControls.find(function (field) { return field.formControlId === formControlId; });
        if (control) {
            control.value = moment(date).format('MM/DD/YYYY');
        }
    };
    DealSheetProcessComponent.prototype.setRadioSelection = function (formControlId, index) {
        var control = this.processForm.formControls.find(function (field) { return field.formControlId === formControlId; });
        if (control) {
            control.value = control.selectOptions[index].value;
        }
    };
    DealSheetProcessComponent.prototype.submitForm = function (submitProcessForm) {
        if (submitProcessForm.valid) {
            this.startButtonEnabled = false;
            if (this.selectedWorkFlowTemplate.completeFormOnStart && this.processForm != null) {
                this.convertAnswers();
            }
            this.startWorkflow();
        }
    };
    return DealSheetProcessComponent;
}());
exports.DealSheetProcessComponent = DealSheetProcessComponent;
