"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var constants_1 = require("../constants");
var i0 = __importStar(require("@angular/core"));
var LogActionShortcutService = /** @class */ (function () {
    function LogActionShortcutService() {
        this.logShortcutSubject = new rxjs_1.Subject();
        this.$logShortcutAction = this.logShortcutSubject.asObservable();
    }
    LogActionShortcutService.prototype.logCall = function () {
        this.logShortcutSubject.next(constants_1.ActivityActionEnum.Log_Call);
    };
    LogActionShortcutService.prototype.logNote = function () {
        this.logShortcutSubject.next(constants_1.ActivityActionEnum.Post_Note);
    };
    LogActionShortcutService.prototype.scheduleAppt = function () {
        this.logShortcutSubject.next(constants_1.ActivityActionEnum.Schedule_Appt);
    };
    LogActionShortcutService.prototype.reset = function () {
        this.logShortcutSubject.next(null);
    };
    LogActionShortcutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogActionShortcutService_Factory() { return new LogActionShortcutService(); }, token: LogActionShortcutService, providedIn: "root" });
    return LogActionShortcutService;
}());
exports.LogActionShortcutService = LogActionShortcutService;
