import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.find";
ReportSubscriptionEditController.$inject = ["$q", "$translate", "dealerService", "reportService", "storageService", "dateRangeTypes", "reportSubscriptionPeriods", "reportSubscriptionSubjectKey", "reportSubscriptionEnterpriseSubjectKey", "userService", "deliveryDateRangeOrdinalTypes", "deliveryWeekDayTypes"];

/* injects from baggage-loader */
require('./report-subscription-edit.component.html');

import angular from 'angular';
import _ from 'lodash';
import reportSpecifications from '../../constants/reports/report-specifications';
import reportHeaderTypes from '../../constants/reports/report-header-types';
angular.module('AaApp.Opp').component('reportSubscriptionEdit', {
  templateUrl: '/app/components/reports/report-subscription-edit.component.html',
  controller: ReportSubscriptionEditController,
  bindings: {
    subscription: '<',
    validate: '='
  }
});
/* @ngInject */

function ReportSubscriptionEditController($q, $translate, dealerService, reportService, storageService, dateRangeTypes, reportSubscriptionPeriods, reportSubscriptionSubjectKey, reportSubscriptionEnterpriseSubjectKey, userService, deliveryDateRangeOrdinalTypes, deliveryWeekDayTypes) {
  var $ctrl = this;
  $ctrl.dealers;
  $ctrl.dateRangeTranslateKeys;
  $ctrl.subscriptionPeriods;
  $ctrl.editableSubscription;
  $ctrl.selectedReportId;
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;
  $ctrl.updateSubscriptionPeriodPoint = updateSubscriptionPeriodPoint;
  $ctrl.updateSubscriptionSubject = updateSubscriptionSubject;
  $ctrl.setDealerMakeSelections = setDealerMakeSelections;
  $ctrl.onReportSelect = onReportSelect;
  $ctrl.getReportSpec = getReportSpec;
  $ctrl.setReportId = setReportId;
  $ctrl.onDeliveryEmail = onDeliveryEmail;
  $ctrl.onDeliveryPando = onDeliveryPando;
  $ctrl.isEmailValid;
  $ctrl.showEmailInvalidMsg;
  var emailDeliveryMethod = 0;
  var pandoDeliveryMethod = 1;

  function init() {
    $ctrl.previousDay = dateRangeTypes.previousDay;
    $ctrl.subscriptionPeriods = reportSubscriptionPeriods;
    $ctrl.dateRangeTranslateKeys = _.invert(dateRangeTypes);
    $ctrl.deliveryDateRangeOrdinalTypeKeys = _.invert(deliveryDateRangeOrdinalTypes);
    $ctrl.deliveryWeekDayTypeKeys = _.invert(deliveryWeekDayTypes);
    $ctrl.validate = validate;
    $ctrl.dateRanges = [{
      translateKey: dateRangeTypes.previousDay,
      value: dateRangeTypes.day
    }, //This entry is the only reason this has to be done this way.
    {
      translateKey: dateRangeTypes.previousWeek,
      value: dateRangeTypes.previousWeek
    }, {
      translateKey: dateRangeTypes.previousMonth,
      value: dateRangeTypes.previousMonth
    }, {
      translateKey: dateRangeTypes.previousQuarter,
      value: dateRangeTypes.previousQuarter
    }, {
      translateKey: dateRangeTypes.last7Days,
      value: dateRangeTypes.last7Days
    }, {
      translateKey: dateRangeTypes.last14Days,
      value: dateRangeTypes.last14Days
    }, {
      translateKey: dateRangeTypes.last28Days,
      value: dateRangeTypes.last28Days
    }, {
      translateKey: dateRangeTypes.last30Days,
      value: dateRangeTypes.last30Days
    }, {
      translateKey: dateRangeTypes.monthToDate,
      value: dateRangeTypes.monthToDate
    }, {
      translateKey: dateRangeTypes.quarterToDate,
      value: dateRangeTypes.quarterToDate
    }, {
      translateKey: dateRangeTypes.yearToDate,
      value: dateRangeTypes.yearToDate
    }];
    $ctrl.dateRangesWeekly = [deliveryWeekDayTypes.weekday1, deliveryWeekDayTypes.weekday2, deliveryWeekDayTypes.weekday3, deliveryWeekDayTypes.weekday4, deliveryWeekDayTypes.weekday5, deliveryWeekDayTypes.weekday6, deliveryWeekDayTypes.weekday7];
    $ctrl.dateRangesMonthly = [deliveryDateRangeOrdinalTypes.day1st, deliveryDateRangeOrdinalTypes.day2nd, deliveryDateRangeOrdinalTypes.day3rd, deliveryDateRangeOrdinalTypes.day4th, deliveryDateRangeOrdinalTypes.day5th, deliveryDateRangeOrdinalTypes.day6th, deliveryDateRangeOrdinalTypes.day7th, deliveryDateRangeOrdinalTypes.day8th, deliveryDateRangeOrdinalTypes.day9th, deliveryDateRangeOrdinalTypes.day10th, deliveryDateRangeOrdinalTypes.day11th, deliveryDateRangeOrdinalTypes.day12th, deliveryDateRangeOrdinalTypes.day13th, deliveryDateRangeOrdinalTypes.day14th, deliveryDateRangeOrdinalTypes.day15th, deliveryDateRangeOrdinalTypes.day16th, deliveryDateRangeOrdinalTypes.day17th, deliveryDateRangeOrdinalTypes.day18th, deliveryDateRangeOrdinalTypes.day19th, deliveryDateRangeOrdinalTypes.day20th, deliveryDateRangeOrdinalTypes.day21st, deliveryDateRangeOrdinalTypes.day22nd, deliveryDateRangeOrdinalTypes.day23rd, deliveryDateRangeOrdinalTypes.day24th, deliveryDateRangeOrdinalTypes.day25th, deliveryDateRangeOrdinalTypes.day26th, deliveryDateRangeOrdinalTypes.day27th, deliveryDateRangeOrdinalTypes.day28th, deliveryDateRangeOrdinalTypes.lastDayOfMonth];
    $ctrl.reports = getAuthorizedReportSpecifications();
    $ctrl.selectedReportId = $ctrl.editableSubscription.reportId;
    $ctrl.editableSubscription.dateRange = _.find($ctrl.dateRanges, function (item) {
      return item.value == $ctrl.editableSubscription.code;
    });
    var allTranslations = [];

    for (var i = 0; i < $ctrl.reports.length; i++) {
      var report = $ctrl.reports[i];
      allTranslations.push($translate(report.titleKey));

      if (report.enterprise && report.enterprise.id.toLowerCase() == $ctrl.editableSubscription.reportId.toLowerCase()) {
        $ctrl.selectedReportId = report.id.toLowerCase();
      }
    }

    validateEnterpriseDealerships();
    $q.all(allTranslations).then(function (translatedNames) {
      for (var i = 0; i < $ctrl.reports.length; i++) {
        $ctrl.reports[i].nameByCulture = translatedNames[i];
      }

      $ctrl.reports.sort(byReportTranslatedName);
    });
  }

  function byReportTranslatedName(object1, object2) {
    if (object1.nameByCulture.toLowerCase() < object2.nameByCulture.toLowerCase()) return -1;
    if (object1.nameByCulture.toLowerCase() > object2.nameByCulture.toLowerCase()) return 1;
    return 0;
  }

  function onChanges(changes) {
    if (changes.subscription && changes.subscription.currentValue) {
      if (!$ctrl.dealers) {
        var showAllDealers = true; //TBD later.  This will need to be set when other reports are in the list that do NOT have an enterprise report version.

        $ctrl.dealers = dealerService.getSelectedDealers(showAllDealers);
      }

      if (!$ctrl.reports) {
        $ctrl.reports = getAuthorizedReportSpecifications();
      }

      $ctrl.currentLoginUserProfile = storageService.getItem('userProfile'); //reset email error flags

      $ctrl.isEmailValid = true;
      $ctrl.showEmailInvalidMsg = false;

      if (!$ctrl.currentLoginUserProfile.email) {
        $ctrl.isEmailValid = false;
      }

      setEditableSubscription($ctrl.subscription);
      enableMakeSelect();
      enableDateRangeSelect();
    }
  }

  function enableDateRangeSelect() {
    var headerType = $ctrl.getReportSpec($ctrl.editableSubscription.reportId).header;
    $ctrl.showDateRangeSelector = headerType != reportHeaderTypes.dealerOnlyNoSubscriptionDateSelector && headerType != reportHeaderTypes.dealerMakeNoSubscriptionDateSelector;
  }

  function enableMakeSelect() {
    var headerType = $ctrl.getReportSpec($ctrl.editableSubscription.reportId).header;
    $ctrl.showMakes = headerType != reportHeaderTypes.dealerOnly && headerType != reportHeaderTypes.dealerDatesPresets && headerType != reportHeaderTypes.dealerOnlyNoSubscriptionDateSelector;
  }

  function getAuthorizedReportSpecifications() {
    var moduleIds = storageService.getItem('moduleIds');
    var authorizedReportsMenu = reportService.getAuthorizedReports(moduleIds);
    var authorizedReportSpecifications = getAuthorizedReportSpecificationsHelper(authorizedReportsMenu);
    return authorizedReportSpecifications;
  }

  function getAuthorizedReportSpecificationsHelper(authorizedReportsMenu) {
    var reportSpecsList = [];

    for (var report in reportSpecifications) {
      var reportSpec = reportSpecifications[report];

      if (reportSpec.subscriptionEnabled) {
        var index = _.findIndex(authorizedReportsMenu, {
          id: reportSpec.titleKey
        });

        if (index > -1) {
          reportSpecsList.push(reportSpec);
        }
      }
    }

    return reportSpecsList;
  }

  function onDeliveryEmail() {
    if ($ctrl.subscriptionEditForm.deliveryMethodEmail.$dirty) {
      if ($ctrl.editableSubscription.deliveryMethods.indexOf(emailDeliveryMethod) == -1) {
        $ctrl.editableSubscription.deliveryMethods.push(emailDeliveryMethod);
        enableEmailValidation();
      } else {
        var indexEmail = $ctrl.editableSubscription.deliveryMethods.indexOf(emailDeliveryMethod);
        if (indexEmail > -1) $ctrl.editableSubscription.deliveryMethods.splice(indexEmail, 1);
        disableEmailValidation();
      }
    }
  }

  function onDeliveryPando() {
    if ($ctrl.subscriptionEditForm.deliveryMethodPando.$dirty) {
      if ($ctrl.editableSubscription.deliveryMethods.indexOf(pandoDeliveryMethod) == -1) {
        $ctrl.editableSubscription.deliveryMethods.push(pandoDeliveryMethod);
        disableEmailValidation();
      } else {
        var pandoIndex = $ctrl.editableSubscription.deliveryMethods.indexOf(pandoDeliveryMethod);
        if (pandoIndex > -1) $ctrl.editableSubscription.deliveryMethods.splice(pandoIndex, 1);
      }
    }
  }

  function enableEmailValidation() {
    if (!$ctrl.currentLoginUserProfile.email) {
      $ctrl.isEmailValid = false;
      $ctrl.showEmailInvalidMsg = true;
      $ctrl.subscriptionEditForm.deliveryMethodEmail.$invalid = true;
    }
  }

  function disableEmailValidation() {
    if (!$ctrl.currentLoginUserProfile.email) {
      var indexEmail = $ctrl.editableSubscription.deliveryMethods.indexOf(emailDeliveryMethod);
      if (indexEmail > -1) $ctrl.editableSubscription.deliveryMethods.splice(indexEmail, 1);
      $ctrl.editableSubscription.deliveryMethodEmail = false;
      $ctrl.isEmailValid = true;
      $ctrl.showEmailInvalidMsg = false;
    }
  }

  function addDefaultSubscriptionDateRangeCode() {
    var headerType = $ctrl.getReportSpec($ctrl.editableSubscription.reportId).header;

    if (headerType == reportHeaderTypes.dealerOnlyNoSubscriptionDateSelector || headerType == reportHeaderTypes.dealerMakeNoSubscriptionDateSelector) {
      $ctrl.editableSubscription.code = dateRangeTypes.yearToDate;
    }
  }

  function setEditableSubscription(subscription) {
    $ctrl.isExistingSubscription = subscription.reportSubscriptionId != null;
    $ctrl.editableSubscription = _.cloneDeep(subscription);
    $ctrl.editableSubscription.reportId = $ctrl.editableSubscription.reportId.toLowerCase();
    addDefaultSubscriptionDateRangeCode();

    if (!subscription.type) {
      var userSubscriptionType = 1;
      $ctrl.editableSubscription.type = userSubscriptionType;
    }

    if (subscription.enabled == null) {
      $ctrl.editableSubscription.enabled = true;
    }

    var dealer = _.find($ctrl.dealers, {
      'id': subscription.dealerID
    });

    if (!dealer) {
      $ctrl.editableSubscription.dealerID = $ctrl.dealers[0].id;
    } //defaults


    $ctrl.deliveryDayOfTheWeek = 2;
    $ctrl.deliveryDayOfTheMonth = 10;

    if ($ctrl.isExistingSubscription && subscription.deliveryMethods) {
      // UPDATE
      $ctrl.editableSubscription.deliveryMethods = [];

      if (subscription.deliveryMethods.indexOf(emailDeliveryMethod) > -1) {
        $ctrl.editableSubscription.deliveryMethodEmail = true;
        $ctrl.editableSubscription.deliveryMethods.push(emailDeliveryMethod);
      }

      if (subscription.deliveryMethods.indexOf(pandoDeliveryMethod) > -1) {
        $ctrl.editableSubscription.deliveryMethodPando = true;
        $ctrl.editableSubscription.deliveryMethods.push(pandoDeliveryMethod);
      }

      if ($ctrl.subscription.periodicity == reportSubscriptionPeriods.weekly) {
        $ctrl.deliveryDayOfTheWeek = $ctrl.subscription.reportSubscriptionPeriodPoint;
      } else if ($ctrl.subscription.periodicity == reportSubscriptionPeriods.monthly) {
        $ctrl.deliveryDayOfTheMonth = $ctrl.subscription.reportSubscriptionPeriodPoint;
      }
    } else {
      // INSERT
      $ctrl.editableSubscription.deliveryMethods = [];
      $ctrl.editableSubscription.deliveryMethodEmail = false;
      $ctrl.editableSubscription.deliveryMethodPando = false;
    }

    updateSubscriptionPeriodPoint();
    updateSubscriptionSubject();
  }

  function validate() {
    var headerType = $ctrl.getReportSpec($ctrl.editableSubscription.reportId).header;

    if (headerType != reportHeaderTypes.dealerOnlyNoSubscriptionDateSelector && headerType != reportHeaderTypes.dealerMakeNoSubscriptionDateSelector) {
      $ctrl.subscriptionEditForm.dateRange.$setDirty();
    }

    $ctrl.subscriptionEditForm.deliverySchedule.$setDirty();
    $ctrl.subscriptionEditForm.deliveryMethodEmail.$setDirty();
    $ctrl.subscriptionEditForm.deliveryMethodPando.$setDirty();
    $ctrl.editableSubscription.code = $ctrl.editableSubscription.dateRange.value;
    var edited = $ctrl.subscription.reportId != $ctrl.editableSubscription.reportId || $ctrl.subscription.dealerID != $ctrl.editableSubscription.dealerID || $ctrl.subscription.makeID != $ctrl.editableSubscription.makeID || $ctrl.subscription.periodicity != $ctrl.editableSubscription.periodicity || $ctrl.subscription.code != $ctrl.editableSubscription.code || $ctrl.subscription.deliveryMethods.length != $ctrl.editableSubscription.deliveryMethods.length || $ctrl.subscription.deliveryMethods.length > 0 && $ctrl.subscription.deliveryMethods[0] != $ctrl.editableSubscription.deliveryMethods[0] || $ctrl.subscription.reportSubscriptionPeriodPoint != $ctrl.editableSubscription.reportSubscriptionPeriodPoint;
    return {
      valid: $ctrl.subscriptionEditForm.$valid && $ctrl.isEmailValid,
      subscription: $ctrl.editableSubscription,
      edited: edited
    };
  }

  function updateSubscriptionPeriodPoint() {
    var point;

    if ($ctrl.editableSubscription.periodicity == reportSubscriptionPeriods.weekly) {
      var weeklyDeliveryDay = $ctrl.deliveryDayOfTheWeek;
      point = weeklyDeliveryDay;
    } else if ($ctrl.editableSubscription.periodicity == reportSubscriptionPeriods.monthly) {
      var monthlyDeliveryDay = $ctrl.deliveryDayOfTheMonth;
      point = monthlyDeliveryDay;
    } else if ($ctrl.editableSubscription.periodicity == reportSubscriptionPeriods.daily) {
      var dailyDeliveryDay = 0;
      point = dailyDeliveryDay;
    }

    $ctrl.editableSubscription.reportSubscriptionPeriodPoint = point;
  }

  function getReportSpec(reportId) {
    reportId = reportId.toLowerCase();

    for (var i = 0; i < $ctrl.reports.length; i++) {
      var report = $ctrl.reports[i];

      if (report.enterprise && report.enterprise.id.toLowerCase() == reportId) {
        return report.enterprise;
      }

      if (report.id.toLowerCase() == reportId) {
        return report;
      }
    }
  }

  function updateSubscriptionSubject() {
    var reportSpecs = $ctrl.getReportSpec($ctrl.editableSubscription.reportId);

    var dealerName = _.find($ctrl.dealers, function (dealer) {
      return dealer.id == $ctrl.editableSubscription.dealerID;
    }).name;

    var up = userService.getUserProfile().then(function (userProfile) {
      var subjectPickerResultKey = reportSpecs.isEnterprise ? reportSubscriptionEnterpriseSubjectKey : reportSubscriptionSubjectKey;
      $translate(reportSpecs.titleKey).then(function (reportTitle) {
        $translate(subjectPickerResultKey, {
          reportName: reportTitle,
          dealerName: dealerName,
          dealerDisplayText: userProfile.dealerDisplayText
        }).then(function (subscriptionSubject) {
          $ctrl.editableSubscription.subject = subscriptionSubject;
        });
      });
    });
  }

  function setReportId(dealerId) {
    if (dealerId == 0) {
      var config = $ctrl.getReportSpec($ctrl.selectedReportId);
      if (config.enterprise) $ctrl.editableSubscription.reportId = config.enterprise.id;
    } else {
      $ctrl.editableSubscription.reportId = $ctrl.selectedReportId;
    }
  }

  function setDealerMakeSelections(dealerId, makeId) {
    $ctrl.editableSubscription.dealerID = dealerId;
    $ctrl.editableSubscription.makeID = makeId;
    $ctrl.setReportId(dealerId);
    enableMakeSelect();
    updateSubscriptionSubject();
  }

  function onReportSelect() {
    validateEnterpriseDealerships();
    $ctrl.setReportId($ctrl.editableSubscription.dealerID);
    enableMakeSelect();
    enableDateRangeSelect();
    updateSubscriptionSubject();
    addDefaultSubscriptionDateRangeCode();
  }

  function validateEnterpriseDealerships() {
    var config = $ctrl.getReportSpec($ctrl.selectedReportId);
    var reportHasEnterpriseVersion = config && config.enterprise || config.isEnterprise ? true : false;
    $ctrl.dealers = dealerService.getSelectedDealers(reportHasEnterpriseVersion);

    if (!$ctrl.isExistingSubscription) {
      //if it's a ADD Subscription dialog {
      $ctrl.editableSubscription.dealerID = $ctrl.dealers[0].id;
      if (reportHasEnterpriseVersion && $ctrl.dealers.length > 1) $ctrl.showMakes = false;
    }
  }
}