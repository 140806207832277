import { Inject, Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import { ToasterLoggerService } from '../core/toaster-logger.service';
import { Modules, SubMenu, TranslateErrorKeys } from '../constants';
import { WarningTitleKey } from '../core/constants';
import { MenuItem } from '../interfaces/menu-item.interface';
import { StorageService } from '../modules/storage/storage.service';
import { UserService } from './user.service';
import { DealerService } from './dealer.service';
import { AuthorizationService } from './authorization.service';
import { DealerSettingsService } from './dealer-settings.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private _siteMap: MenuItem[];
    private _moduleIds: number[] = [];
    private readonly siteMapReadySubject = new ReplaySubject<any>(1);  // could also use BehaviorSubject but that requires initial value which we are not using here
    public get siteMap() { return this._siteMap; }
    public get moduleIds() { return this._moduleIds; }
    public siteMapReady: Observable<any>;

    constructor(private translateService: TranslateService,
        private stateService: StateService,
        private storageService: StorageService,
        private authorizationService: AuthorizationService,
        private logger: ToasterLoggerService,
        private userService: UserService,
        private dealerService: DealerService,
        private dealerSettingsService: DealerSettingsService) {
        this.siteMapReady = this.siteMapReadySubject.asObservable();
    }

    public getModulePermissions(moduleIds: number[]) {
        return {
            opportunities: moduleIds.indexOf(Modules.opportunities) > -1,
            activities: moduleIds.indexOf(Modules.activities) > -1,
            search: moduleIds.indexOf(Modules.search) > -1,
            financeInsuranceManager: moduleIds.indexOf(Modules.financeInsuranceManager) > -1,
            preOwnedManager: moduleIds.indexOf(Modules.preOwnedManager) > -1,
            serviceManager: moduleIds.indexOf(Modules.serviceManager) > -1,
            conquests: moduleIds.indexOf(Modules.conquests) > -1,
            wishlist: moduleIds.indexOf(Modules.wishlist) > -1
        };
    }

    public getSiteMap(dealerIds: number[]): Promise<MenuItem[]> {
        if (!dealerIds) {
            this.siteMapReadySubject.next();
            return Promise.resolve(null);
        }

        const currentSiteMap = this.siteMap;
        if (currentSiteMap) {
            this.siteMapReadySubject.next();
            return Promise.resolve(currentSiteMap);
        } else {
            // getAccessRights is not used here, its only called here in order to get the accessRights into sessionStorage
            // at app startup. Don't remove it.
            return Promise.all([this.authorizationService.getModuleIds(dealerIds),
            this.authorizationService.getAccessRightIds(dealerIds)])
                .then(result => this.resolveSiteMap(result[0]));
        }
    }

    private async resolveSiteMap(moduleIds: number[]): Promise<MenuItem[]> {
        const siteMap = SubMenu;

        return this.loadMenu(siteMap, moduleIds)
            .then(() => {
                this._siteMap = siteMap;
                this._moduleIds = moduleIds;

                if (this.stateService.current.name === 'loginLanding') {
                    this.stateService.go('login', {}, { reload: true });
                } else if (this.stateService.current.name === 'home') {
                    this.stateService.go('home', {}, { reload: true });
                }
                this.siteMapReadySubject.next();
                return siteMap;
            });
    }

    public async getHomeRoute(): Promise<string> {
        if (this.siteMap) {
            return this.userService.getUserProfile()
                .then((userProfile: any) => {
                    if (userProfile.isSltUser) {
                        return 'opportunities';
                    } else {
                        return this.siteMap[0].id;
                    }
                });
        } else {
            return null;
        }
    }

    public redirectToUserHome(): void {
        if (this.stateService.current.name === 'login') {
            const localRedirect = this.storageService.getItem('localRedirectPath');

            if (localRedirect && localRedirect !== '/') {
                this.storageService.removeItem('localRedirectPath');
                const menuItem = this.findMenuItemByUrl(localRedirect.substr(1));


                if (menuItem) {
                    this.stateService.go(menuItem.id);
                } else {
                    // not authorized
                    if (localRedirect.indexOf('deal-sheet') > -1 ||
                        localRedirect.indexOf('reports') > -1 ||
                        localRedirect.toLowerCase().indexOf('activities') > -1
                        || localRedirect.indexOf('external/dealsheet') > -1 || localRedirect.indexOf('external/page') > -1
                        || localRedirect.toLowerCase().indexOf('/security/user/notification') > -1
                        || localRedirect.toLowerCase().indexOf('/alert-desk/dashboard/manager') > -1) {
                        window.location.assign(localRedirect);
                    } else {
                        this.translateService.get([TranslateErrorKeys.unauthorizedPageErrorKey, WarningTitleKey])
                            .subscribe((translations) => {
                                this.logger.warning(
                                    translations[TranslateErrorKeys.unauthorizedPageErrorKey],
                                    null,
                                    translations[WarningTitleKey]
                                );
                                this.stateService.go('home', {}, { reload: true });
                            });
                    }

                }
            } else {
                this.stateService.go('home', {}, { reload: true });
            }
        }
    }

    private findMenuItemByUrl(url: string): MenuItem {
        let menuItem = _.find(this.siteMap, { 'url': url }) as MenuItem;

        if (!menuItem) {
            for (let i = 0; i < this.siteMap.length; i++) {
                menuItem = _.find(this.siteMap[i].subMenuItems, { 'url': url }) as MenuItem;
                if (menuItem) { return menuItem; }
            }
        }

        return menuItem;
    }

    private async loadMenu(siteMap: MenuItem[], moduleIds: number[]): Promise<any> {
        this.removeInaccessibleTopMenuItemsSync(siteMap, moduleIds);
        this.removeInaccessibleSubMenuItemsSync(siteMap, moduleIds);
        this.removeServiceDriveOfferMenuIfDisabledSync(siteMap);
        this.setDealerSettingsLink(siteMap);

        siteMap = await this.removeInaccessibleSltTopMenuItems(siteMap);
        siteMap = await this.disableInaccessibleSltTopMenuItems(siteMap);
        siteMap = await this.disableInaccessibleSltSubMenuItems(siteMap);

        const dataEntry = _.find(siteMap, { 'id': 'dataEntry' });
        if (dataEntry) {
            dataEntry.disabled = true;
        }

        return Promise.resolve(null);
    }

    private removeInaccessibleTopMenuItemsSync(siteMap: MenuItem[], moduleIds: number[]): void {
        const indicesToRemove = new Array();

        for (let i = 0; i < siteMap.length; i++) {
            const menuItem = siteMap[i];
            let accessible = menuItem.authorize ? false : true;

            if (!accessible) {
                for (let j = 0; j < moduleIds.length; j++) {
                    const moduleId = moduleIds[j];

                    if (menuItem.moduleIds.includes(moduleId)) {
                        accessible = true;
                    }
                }
            }

            if (!accessible || menuItem.disabled) {
                indicesToRemove.push(i);
            }
        }

        for (let k = indicesToRemove.length - 1; k >= 0; k--) {
            siteMap.splice(indicesToRemove[k], 1);
        }
    }

    private removeInaccessibleSubMenuItemsSync(siteMap: MenuItem[], moduleIds: number[]): void {
        const allIndicesToRemove = [[]];

        for (let i = 0; i < siteMap.length; i++) {
            const menuItem = siteMap[i];
            const indicesToRemove = new Array();

            if (menuItem.subMenuItems) {

                for (let j = 0; j < menuItem.subMenuItems.length; j++) {
                    const subMenuItem = menuItem.subMenuItems[j];
                    let accessible = subMenuItem.authorize ? false : true;

                    if (!accessible) {
                        for (let k = 0; k < moduleIds.length; k++) {
                            const moduleId = moduleIds[k];

                            if (subMenuItem.moduleIds.includes(moduleId)) {
                                accessible = true;
                            }
                        }
                    }

                    if (!accessible || subMenuItem.disabled) {
                        indicesToRemove.push(j);
                    }
                }
            }

            allIndicesToRemove.push(indicesToRemove);
        }

        allIndicesToRemove.splice(0, 1);

        for (let l = 0; l < allIndicesToRemove.length; l++) {
            const subIndicesToRemove = allIndicesToRemove[l];

            if (subIndicesToRemove) {
                for (let m = subIndicesToRemove.length - 1; m >= 0; m--) {
                    siteMap[l].subMenuItems.splice(subIndicesToRemove[m], 1);
                }
            }
        }
    }

    private removeServiceDriveOfferMenuIfDisabledSync(siteMap: MenuItem[]) {
        if (this.dealerService.getIsSomeDealerOneToOneEnabled()) {
            return;
        }
        this.removeSubMenuItemById(siteMap, 'serviceDriveOffers');
    }

    private setDealerSettingsLink(siteMap: MenuItem[]) {

        this.dealerSettingsService.UseLegacyDealerSettingsPage().then((useLegacyDealerSettings) => {

            //Remove portal link
            if (useLegacyDealerSettings) {
                this.removeSubMenuItemById(siteMap, 'newSettings');
            }
            else { //Remove legacy link
                this.removeSubMenuItemById(siteMap, 'settings');
            }
        });
    }

    private removeSubMenuItemById(siteMap: MenuItem[], id: string) {
        _.forEach(siteMap, (subMenu) => {
            const index = _.findIndex(subMenu.subMenuItems, { 'id': id });
            if (index > -1) {
                subMenu.subMenuItems.splice(index, 1);
                return false;
            }
        });
    }

    private async removeInaccessibleSltTopMenuItems(siteMap: MenuItem[]): Promise<MenuItem[]> {
        return this.userService.getUserProfile()
            .then((userProfile: any) => {
                if (userProfile.isSltUser) {
                    const preOwnedIndex = _.findIndex(siteMap, { 'id': 'preOwned' });

                    if (preOwnedIndex >= 0) {
                        siteMap.splice(preOwnedIndex, 1);
                    }
                }

                return siteMap;
            });
    }

    private async disableInaccessibleSltTopMenuItems(siteMap: MenuItem[]): Promise<MenuItem[]> {
        return this.userService.getUserProfile()
            .then((userProfile: any) => {
                if (userProfile.isSltUser) {
                    const alertDesk = _.find(siteMap, { 'id': 'alertDesk' });

                    if (alertDesk) {
                        alertDesk.disabled = true;
                    }
                }

                return siteMap;
            });
    }

    private async disableInaccessibleSltSubMenuItems(siteMap: MenuItem[]): Promise<MenuItem[]> {
        return this.userService.getUserProfile()
            .then((userProfile: any) => {
                if (userProfile.isSltUser) {

                    const accessibleSubMenuItems = ['opportunities', 'activities', 'serviceDriveOffers', 'conquests'];
                    const alertDeskMenu = _.find(siteMap, { 'id': 'alertDesk' });

                    if (alertDeskMenu && alertDeskMenu.subMenuItems) {
                        for (let i = 0; i < alertDeskMenu.subMenuItems.length; i++) {
                            const subMenuItem = alertDeskMenu.subMenuItems[i];

                            if (accessibleSubMenuItems.indexOf(subMenuItem.id) < 0) {
                                subMenuItem.disabled = true;
                            }
                        }
                    }
                }

                return siteMap;
            });
    }
}
