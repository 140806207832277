import "core-js/modules/es6.promise";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
HeaderWrapperController.$inject = ["$route", "$auth", "$location", "$window", "$rootScope", "$scope", "$state", "$translate", "$translatePartialLoader", "$transitions", "authorizationKeys", "environmentVariables", "headerLocalizationPath", "localePaths", "logger", "menu", "modules", "dealerCountries", "authorizationService", "crossDomainStorageService", "dealSheetService", "dealerService", "globalIframeService", "legacyService", "menuService", "storageService", "tokenService", "userService", "vehicleService"];

/* injects from baggage-loader */
require('./header-wrapper.component.html');

import angular from 'angular';
import _ from 'lodash';
import { RejectType } from '@uirouter/core';
import { resizeHeader, showAutoAlertiFrame } from '../scripts/init';
import { loggedIn$, externalLoggedIn$ } from '../modules/oidc-auth/events';
import defaultLogoPath from '../images/autoalert-logo.png';
import fordLogoPath from '../images/autoalert-logo-ford.png';
import lincolnLogoPath from '../images/autoalert-logo-lincoln.png';
import { ProductTypes as productTypes } from '../constants';
import fordCanadaLogoPath from '../images/autoalert-logo-ford-canada.png';
import lincolnCanadaLogoPath from '../images/autoalert-logo-lincoln-canada.png';
angular.module('AaApp.Opp').component('headerWrapper', {
  templateUrl: '/app/components/header-wrapper.component.html',
  controller: HeaderWrapperController
});
/* @ngInject */

function HeaderWrapperController( // AngularJS stuff
$route, $auth, $location, $window, $rootScope, $scope, $state, $translate, $translatePartialLoader, $transitions, // Configuration
authorizationKeys, environmentVariables, headerLocalizationPath, localePaths, logger, menu, modules, dealerCountries, // Services
authorizationService, crossDomainStorageService, dealSheetService, dealerService, globalIframeService, legacyService, menuService, storageService, tokenService, userService, vehicleService) {
  var $ctrl = this;
  $ctrl.state = $state;
  $ctrl.hasInventoryAccess = false;
  $ctrl.isEuroLite = false;
  $ctrl.isHeaderVisible = false;
  $ctrl.isAuthenticated = false;
  $ctrl.dealerListDisabled = false;
  $ctrl.onDealersChanged = onDealersChanged;
  $ctrl.onCustomerEngagementClicked = onCustomerEngagementClicked;
  $ctrl.logoPath;
  $ctrl.version = environmentVariables.version;
  $ctrl.$onInit = $onInit;
  $ctrl.$onDestroy = $onDestroy;
  $ctrl.showSubmenu;
  $ctrl.productTypes = productTypes;
  $ctrl.userProfileMenuItems = getFilteredUserMenu();
  externalLoggedIn$.subscribe(function (e) {
    $onInit();
  });
  loggedIn$.subscribe(function (e) {
    $onInit();
  });
  $transitions.onEnter({}, function (transition) {
    disableDealerList(transition.to().name);
  });

  function disableDealerList(stateName) {
    var isDealSheetPage = stateName.toLowerCase().indexOf('dealsheet') > -1;
    $ctrl.dealerListDisabled = isDealSheetPage;
  }

  function onCustomerEngagementClicked(engagement) {
    dealSheetService.openDealSheetModal(engagement.opportunityId);
  }

  function onDealersChanged(dealerGroupId, dealerIds, dealers, dealerDisplayText) {
    dealerService.updateSelectedDealers(dealers, dealerGroupId, dealerDisplayText); // Don't display the "dealer changed" message in this window.

    try {
      $ctrl.selectedDealerIdsSubscription.unsubscribe();
    } catch (e) {
      logger.debug(e);
    } // The page will refresh upon receipt of the message Legacy sends
    // in response to this call.


    legacyService.sendChangeDealerMessageToIframe(dealerIds);
  }

  $ctrl.isProcessingAuthCallback = $location.path().indexOf('/auth/callback') > -1;
  if ($ctrl.isProcessingAuthCallback) return;

  function $onInit() {
    $ctrl.disposeSiteMapLoaded = $rootScope.$on('RUN_USER_INIT.SITE_MAP_READY', function onSiteMapLoaded() {
      $ctrl.hasInventoryAccess = authorizationService.isAuthorized(authorizationKeys.Inventory);
      updateSelectedDealers();
      $ctrl.isEuroLite = authorizationService.isEuroLite(); // Start this call immediately in case we need it

      var availableMakesPromise = vehicleService.getMakes($ctrl.dealerIds || []);
      userService.getUserProfile().then(function getUserProfileComplete(userProfile) {
        getLogoPath(userProfile.dealerModules, availableMakesPromise, userProfile.countryId).then(function (logoPath) {
          return $ctrl.logoPath = logoPath;
        });
        $ctrl.userProfile = userProfile;
        $ctrl.isEulaAccepted = userProfile.eulaAcceptedDate != null;
        var dealerIDs = dealerService.getSelectedDealerIds();
        if (Array.isArray(dealerIDs) && dealerIDs.length) dealerService.getDealerSettings(dealerIDs[0]).then(function (settings) {
          $ctrl.productId = settings.productID;
          $ctrl.userProfileMenuItems = getFilteredUserMenu($ctrl.isAuthenticated, $ctrl.userProfile);
        });
        $translatePartialLoader.addPart(headerLocalizationPath);
        $translatePartialLoader.addPart(localePaths.general);
        resizeHeader($state.$current, globalIframeService);
        $rootScope.$broadcast('RUN_USER_INIT.REPOSITION_UI_VIEW');

        if (userProfile.walkmeSegments && userProfile.walkmeSegments.length > 0) {
          window.AA_WalkMe_Segments = {};
          userProfile.walkmeSegments.forEach(function (segment) {
            window.AA_WalkMe_Segments[segment.SegmentId] = segment.SegmentValue;
          });
        }

        $ctrl.isCoreReady = true;
        $ctrl.menu = menu;
      });
    });
    $transitions.onSuccess({}, function (transition) {
      setSelectedTab();
      $ctrl.userProfileMenuItems = getFilteredUserMenu($ctrl.isAuthenticated, $ctrl.userProfile);
    });
    $ctrl.IsVisible = true;
    $ctrl.recentDealSheetsVisible = false;
    $ctrl.menu = menu;
    $ctrl.isReportTabEnabled = false;
    setSelectedTab();
    $ctrl.searchPresetsVisible = false;
    var isPublicState = $state.current.access == 'public'; // always false at this point in the cycle

    $ctrl.isAuthenticated = $auth.isAuthenticated();
    $ctrl.userProfileMenuItems = getFilteredUserMenu($ctrl.isAuthenticated, $ctrl.userProfile);
    $ctrl.isHeaderVisible = (isPublicState || $ctrl.isAuthenticated) && $ctrl.IsVisible == true;
    $ctrl.isEulaAccepted = false;
    var url = $location.url();
    var hiddenHeaderUrls = ["auto-check", "car-fax", "carfax-authorize", "cfcallback", "car-proof", "template-email-preview", "mobile/deal-sheet"];

    for (var _i = 0; _i < hiddenHeaderUrls.length; _i++) {
      var urlPortion = hiddenHeaderUrls[_i];

      if (url.includes(urlPortion)) {
        $ctrl.isHeaderVisible = false;
        break;
      }
    }

    if (!$ctrl.isHeaderVisible) $ctrl.logoPath = defaultLogoPath;
    disableDealerList($state.$current.name);
    $ctrl.selectedDealerIdsSubscription = crossDomainStorageService.onchange('selectedDealers', function () {
      updateSelectedDealers();
    });

    if (url.includes("/email/") && url.includes("code=")) {
      // for Customer Unsubscribe and View In Browser sent emails
      $ctrl.isHeaderVisible = false;
    }

    function setSelectedTab() {
      $ctrl.selectedTab = $state.includes('reportsContainer') ? 'reports' : 'opportunities';

      if ($ctrl.selectedTab == 'reports') {
        $ctrl.showSubmenu = false;
      } else {
        $ctrl.showSubmenu = true;
      }
    } // check if slt user and page is conquests


    if ($location.url().indexOf("conquests") > -1 && storageService.getItem('userProfile').isSltUser) $ctrl.isQuickSearchVisible = false;else $ctrl.isQuickSearchVisible = true;
  }

  function $onDestroy() {
    $ctrl.disposeSiteMapLoaded();

    if ($ctrl.selectedDealerIdsSubscription) {
      $ctrl.selectedDealerIdsSubscription.unsubscribe();
    }
  }

  function updateSelectedDealers() {
    $ctrl.dealerGroupId = dealerService.getSelectedDealerGroupId();
    $ctrl.dealerIds = dealerService.getSelectedDealerIds();
    $ctrl.dealers = dealerService.getSelectedDealers();
    $ctrl.dealerDisplayText = dealerService.getDealerDisplayText();
  }

  function getFilteredUserMenu(isAuthenticated, userProfile) {
    var allItems = [{
      type: $state.current.name != 'userProfile' ? 'SREF' : '',
      translate: 'myProfile',
      sref: 'userProfile'
    }, {
      type: 'SEPARATOR'
    }];

    if (isAuthenticated != undefined && !isAuthenticated) {
      allItems.push({
        type: 'HREF',
        translate: 'login',
        icon: 'aa-icon-login',
        href: '#',
        action: function action() {
          legacyService.sendLogOutMessage();
          $auth.signIn();
        }
      });
    } else {
      allItems.push({
        type: 'HREF',
        translate: 'logout',
        icon: 'aa-icon-logout',
        href: '#',
        action: function action() {
          $auth.signOut();
        }
      });
    }

    if ($ctrl.productId && $ctrl.productId == productTypes.euroLite) {
      allItems = _.filter(allItems, function (item) {
        return item.translate != 'requestCallBack';
      });
    }

    if (isAuthenticated && userProfile && userProfile.userFullName) {
      return allItems;
    } else {
      // Remove My Profile
      return allItems.slice(1);
    }
  }

  function getLogoPath(moduleIds, availableMakesPromise, countryId) {
    if (!moduleIds || moduleIds.length === 0) {
      return Promise.resolve(defaultLogoPath);
    }

    var hasFordAlertModule = moduleIds.some(function (o) {
      return o.moduleID === modules.fordAlert;
    });

    if (!hasFordAlertModule) {
      return Promise.resolve(defaultLogoPath);
    }

    return availableMakesPromise.then(function (availableMakes) {
      // A dealer is a "Lincoln-only" dealer if they:
      //   1. Have the FordAlert module
      //   2. Have Lincoln as a DealerMake
      //   3. Do NOT have Ford as a DealerMake
      var fordMakeIds = environmentVariables.fordMakeIds || [];
      var lincolnMakeIds = environmentVariables.lincolnMakeIds || [];
      var isLincolnDealer = availableMakes.some(function (m) {
        return lincolnMakeIds.includes(m.makeID);
      });
      var isFordDealer = availableMakes.some(function (m) {
        return fordMakeIds.includes(m.makeID);
      });
      var fordlogo = fordLogoPath;
      var lincolnlogo = lincolnLogoPath; // if canada then update logo images

      if (countryId == dealerCountries.canada) {
        fordlogo = fordCanadaLogoPath;
        lincolnlogo = lincolnCanadaLogoPath;
      }

      return isLincolnDealer && !isFordDealer ? lincolnlogo : fordlogo;
    });
  }

  $scope.$watch('$ctrl.isCoreReady', function () {
    var cultureName = storageService.getItem('cultureName');

    if (cultureName) {
      $translate.use(cultureName);
    }
  });
  globalIframeService.subscribe(function (_ref, replyPort) {
    var data = _ref.data;

    if (data['AUTOALERT_IFRAME_LOADED']) {
      // AA is ready show iframe
      $ctrl.IsVisible = true;
      $ctrl.isHeaderVisible = true; //todo: iframe code ---- remove when iframe is gone!

      showAutoAlertiFrame($state.$current);
      resizeHeader($state.$current, replyPort);
    } else if (data['EULA_ACCEPT']) {
      $ctrl.isEulaAccepted = true;
      storageService.setItem('userProfile', null);
      userService.getUserProfile().then(function (userProfile) {// no need to update profile since we have already set $ctrl.isEulaAccepted to true;
        // we need to call this so next time user logs in he gets data from updated DB not the cache.
      });
    } else if (data['EULA_DECLINE']) {
      $ctrl.isEulaAccepted = false;
      $state.go('errorUnauthorized', {
        errorState: 'marketingSplash'
      });
    } else if (data['PROFILE_UPDATED']) {
      storageService.setItem('userProfile', null); // getUserProfile(true) means force reload from the server

      userService.getUserProfile(true).then(function (profile) {
        if (profile) if ($ctrl.userProfile) $ctrl.userProfile.userFullName = profile.userFullName;
      });
    }
  });
}