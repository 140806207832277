
    <customer-connect-sub-actions [permissions]="permissions"
                                  [subAction]="action"
                                  [subActionTime]="actionTime"
                                  (onAction)="onActionSelect($event.action)">
    </customer-connect-sub-actions>
    <customer-connect-sub-detail 
                                 [permissions]="permissions"
                                 [subAction]="subAction"
                                 [dialogParams]="dialogParams"
                                 (onLogScheduleSubmit)="onLogScheduleSubmit.emit($event)"
                                 [isEuroLite]="isEuroLite">
    </customer-connect-sub-detail>

