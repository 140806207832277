"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickSearchUrl = '/AlertDesk/QuickSearch/Show?Query=';
exports.DealSheetUrl = '/AlertDesk/DealSheet/Show?e=';
exports.SearchPresetUrl = '/AlertDesk/Search/Index?ConditionID=';
exports.UserProfileUrl = '/Security/User/Profile';
exports.SubMenuLocalizationPath = '/app/i18n/sub-menu';
exports.WarningTitleKey = 'warningTitle';
exports.QuickSearchWarningKey = 'quickSearchWarning';
exports.QuickSearchInvalidCharacterWarningKey = 'quickSearchInvalidCharacterWarning';
exports.PageNotFoundKey = 'pageNotFound';
