import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxModalWrapperService } from '../../../services/ngx-modal-wrapper.service';
import { ScoreInfoModalComponent } from '..//score-info-modal/score-info-modal.component';

@Component({
    selector: 'score-info',
    templateUrl: './score-info.component-ng.html',
    styleUrls: ['./score-info.component.scss']
})
export class ScoreInfoComponent implements OnInit {
    @Input() entityId: string;
    bsModalRef: BsModalRef;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService) { }

    ngOnInit() {
    }

    public scoreInfoModal() {
        const initialState = {
            entityId: this.entityId,
        };

        this.bsModalRef = this.modalService.show(ScoreInfoModalComponent, { initialState, class: 'score-info-modal' });
    }

}
