import angular from 'angular';
import $ from 'jquery';
import { roundWithPrecision } from '../utilities/math';

const NumberLinkFunction = makeNumberLinkFunction({ precision: 0 })

angular.module('AaApp.Opp').directive('numberInput', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            precision: '<',
            addonText: '<',
            addonTextWidth: '<',
        },
        link: NumberLinkFunction,
    }
})

export function makeNumberLinkFunction(options) {
    return function NumberInputLink($scope, $element, $attributes, $ngModelCtrl) {
        if (!$element.is('input'))
            return;

        if ($scope.addonText) {
            wrapElementInInputGroup($element);
            const addon = $(`<span class="input-group-addon">${$scope.addonText}</span>`);
            if ($scope.addonTextWidth) {
                addon.css('width', $scope.addonTextWidth);
            }
            $element.after(addon);
        } else {
            wrapElement($element, '<div>');
        }

        $element.addClass('form-control')

        $ngModelCtrl.$parsers = [function ($viewValue) {
            if ($viewValue == null || $viewValue == "")
                return null;

            if (typeof $viewValue == 'string') {
                // Remove everything except digits and decimal separators
                $viewValue = $viewValue.replace(/[^\d.,]*/g, "");
            }

            const precision = getPrecision(options, $scope);
            const rounded = roundWithPrecision($viewValue, precision);
            if (rounded != null && !isNaN(rounded)) {
                return rounded;
            } else {
                return null;
            }
        }];

        // Ensures class="ng-invalid" is added appropriately
        $ngModelCtrl.$validators.isNumber = function (modelValue, viewValue) {
            return typeof modelValue == 'number'
                && !isNaN(modelValue);
        }

        $element.on('change', function () {
            // If the user entered an invalid value, the input element retains
            // the invalid value. This resets the input element so it contains
            // the current $modelValue.

            let newViewValue;
            if (typeof $ngModelCtrl.$modelValue == 'number') {
                // Cannot use grouping because having the group separator
                // character (usually comma) in the number fails when we try to
                // parse it.
                const formatter = new Intl.NumberFormat(undefined, { useGrouping: false });
                newViewValue = formatter.format($ngModelCtrl.$modelValue);
            } else {
                newViewValue = $ngModelCtrl.$modelValue;
            }

            $ngModelCtrl.$setViewValue(newViewValue);
            $ngModelCtrl.$render();
        })
    }
}

function getPrecision(options, $scope) {
    if ($scope.precision != null) {
        return $scope.precision;
    } else {
        return options.precision || 0;
    }
}

export function wrapElementInInputGroup($element) {
    return wrapElement($element, '<div class="input-group">');
}

function wrapElement($element, spec) {
    if ($element.length != 1)
        throw new Error("Only one element to wrap is supported");

    $element.wrap(spec);
    const $wrapper = $element.parent();

    const classList = Array.from($element[0].classList);
    for (const className of classList) {
        if (className.startsWith('col-')) {
            $element.removeClass(className);
            $wrapper.addClass(className);
        }
    }

    return $wrapper;
}
