import "core-js/modules/es6.promise";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.function.name";
import reportSpecs from '../constants/reports/report-specifications';
_ngInjectExport.$inject = ["$stateProvider", "reportsMenu"];
export default function _ngInjectExport($stateProvider, reportsMenu) {
  'ngInject';

  $stateProvider.state({
    name: 'reportsContainer',
    url: '/reports',
    component: 'reportsContainer',
    resolve: {
      kendo: function kendo() {
        return importKendo();
      },
      moduleIds: ["dealerService", "menuService", "storageService", function moduleIds(dealerService, menuService, storageService) {
        "ngInject";

        var selectedDealerIds = dealerService.getSelectedDealerIds();
        return menuService.getSiteMap(selectedDealerIds).then(function () {
          // This should be refactored. We only need moduleIds,
          // but we depend on the siteMap and accessRightIds to
          // be populated. The call to getSiteMap does everything.
          return storageService.getItem('moduleIds');
        });
      }],
      selectedDealerIds: ["dealerService", function selectedDealerIds(dealerService) {
        "ngInject";

        var selectedDealerIds = dealerService.getSelectedDealerIds();
        return selectedDealerIds;
      }]
    }
  });

  for (var i = 0; i < reportsMenu.length; i++) {
    var report = reportsMenu[i];

    if (!report.betaOnly && report.id) {
      var _state = createLegacyState(report.id, report.url);

      $stateProvider.state(_state);
    }

    if (report.beta && report.id) {
      var _state2 = createBetaState(report.id, report.url);

      $stateProvider.state(_state2);
    }
  }

  var state = createBetaReportState('betaReports', null);
  $stateProvider.state(state);

  function createBetaState(name, url) {
    return {
      parent: 'reportsContainer',
      name: name,
      url: url,
      component: 'reports',
      params: {
        optionalParams: null
      },
      resolve: {
        thisReportSpec: function thisReportSpec() {
          return reportSpecs[name];
        }
      }
    };
  }

  function createBetaReportState(name, url) {
    return {
      parent: 'reportsContainer',
      name: name,
      url: '/beta?id',
      component: 'betaReports',
      params: {
        id: {
          value: '' //userSelect: {
          //    value: ''
          //}

        }
      }
    };
  }

  function createLegacyState(name, url) {
    return {
      parent: 'reportsContainer',
      name: 'legacy' + name,
      url: '/legacy' + url,
      component: 'aaIframe',
      resolve: {
        src: ["legacy", "legacyUrlMappings", function src(legacy, legacyUrlMappings) {
          'ngInject';

          return legacy.iframeUrl + legacyUrlMappings[name];
        }]
      }
    };
  }
}
export function importKendo() {
  // Lazy-load kendo-ui so it doesn't have to be downloaded immediately
  // See: https://webpack.js.org/guides/code-splitting/#dynamic-imports
  var components = [import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.core'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.data'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.popup'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.list'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.autocomplete'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.button'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.buttongroup'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.combobox'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.dropdownlist'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.userevents'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.selectable'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.calendar'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.datepicker'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.numerictextbox'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.binder'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.filtermenu'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.menu'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.columnmenu'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.draganddrop'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.groupable'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.filtercell'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.pager'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.reorderable'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.resizable'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.columnsorter'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.sortable'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.grid'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.listview'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.multiselect'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.toolbar'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.color'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.drawing'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.dataviz.core'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.dataviz.themes'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.dataviz.chart'), import(
  /* webpackChunkName: "kendo-ui" */
  '@progress/kendo-ui/js/kendo.dropdowntree')];
  return Promise.all(components).then(function (completedComponents) {
    // We just need to return a handle to kendo.core
    var kendo = completedComponents[0];
    return kendo;
  });
}