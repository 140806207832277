
/* injects from baggage-loader */
require('./lms-transcript.component.html');

import angular from 'angular';

    angular.module('AaApp.Opp').component('lmsTranscript', {
        templateUrl: '/app/components/reports/lms-transcript.component.html',
        controller: LmsTranscriptController,
        bindings: {
            transcript :'<'
        }
    });

    /* @ngInject */
    function LmsTranscriptController() {
        var $ctrl = this;
        $ctrl.today = new Date();
    }
