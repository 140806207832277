<div class="field row" *ngIf="recordedMileage != null">
    <label class="field-label" for="mileage" translate="mileageLabel"></label>
    <span class="field-data">
        <span id="mileage" class="qa-vehicle-mileage" translate="mileageSummary"
              [translateParams]="{ estimate: (this.estimatedMileage | localeNumber) || '', actual: this.recordedMileage | localeNumber, recordedDate: this.recordedDate | localeDate }"></span>

        <a class="edit-icon qa-mileage-edit-action-link" *ngIf="permissions.editMileage" href="#" (click)="editMileageModal()" title="{{'editMileageToolTip' | translate}}">
            <icon class="icon aa-icon-core-edit-icon-000000"></icon>
        </a>
    </span>
</div>
