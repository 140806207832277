"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./sale-type.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("@angular/common"));
var i4 = __importStar(require("./sale-type.component"));
var styles_SaleTypeComponent = [i0.styles];
var RenderType_SaleTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaleTypeComponent, data: {} });
exports.RenderType_SaleTypeComponent = RenderType_SaleTypeComponent;
function View_SaleTypeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "value text-uppercase qa-sale-type"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.saleTypeTranslateKeys[_co.value], ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SaleTypeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "value text-uppercase qa-sale-type"], ["translate", "conquest"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "conquest"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SaleTypeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" - "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "qa-sale-type-original"]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.saleTypeOriginalLookupKey, ""); _ck(_v, 4, 0, currVal_0); }, null); }
function View_SaleTypeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaleTypeComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaleTypeComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaleTypeComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.value && (_co.conquestType != _co.conquestTypes.notConverted)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.value && ((_co.conquestType == _co.conquestTypes.notConverted) || (_co.conquestType == _co.conquestTypes.creditConvert))); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.saleTypeOriginalLookupId > 0); _ck(_v, 5, 0, currVal_2); }, null); }
exports.View_SaleTypeComponent_0 = View_SaleTypeComponent_0;
function View_SaleTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sale-type", [], null, null, null, View_SaleTypeComponent_0, RenderType_SaleTypeComponent)), i1.ɵdid(1, 573440, null, 0, i4.SaleTypeComponent, [i1.ElementRef, i1.Injector], null, null)], null, null); }
exports.View_SaleTypeComponent_Host_0 = View_SaleTypeComponent_Host_0;
var SaleTypeComponentNgFactory = i1.ɵccf("sale-type", i4.SaleTypeComponent, View_SaleTypeComponent_Host_0, { value: "value", conquestType: "conquestType", saleTypeOriginalLookupId: "saleTypeOriginalLookupId" }, {}, []);
exports.SaleTypeComponentNgFactory = SaleTypeComponentNgFactory;
