"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var locale_number_pipe_1 = require("../../../../filters/locale-number.pipe");
var AnnualPercentageRateComponent = /** @class */ (function () {
    function AnnualPercentageRateComponent(elementRef, injector, localeNumber) {
        this.localeNumber = localeNumber;
    }
    return AnnualPercentageRateComponent;
}());
exports.AnnualPercentageRateComponent = AnnualPercentageRateComponent;
