"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var platform_browser_1 = require("@angular/platform-browser");
var ModalComponent = /** @class */ (function () {
    function ModalComponent(translateService, sanitizer) {
        this.translateService = translateService;
        this.sanitizer = sanitizer;
        this.onClose = new core_1.EventEmitter();
        this.onSubmit = new core_1.EventEmitter();
    }
    ModalComponent.prototype.ngOnInit = function () {
        if (this.closeButtonVisible === undefined) {
            this.closeButtonVisible = true;
        }
        if (this.includeTitleBar === undefined) {
            this.includeTitleBar = true;
        }
    };
    ModalComponent.prototype.ngOnChanges = function (changes) {
        if (changes.headerTitle && changes.headerTitle.currentValue) {
            var title = this.translateService.instant(changes.headerTitle.currentValue);
            this.translatedHeaderTitle = this.sanitizer.bypassSecurityTrustHtml(title);
        }
        if (changes.headerClass && changes.headerClass.firstChange) {
            if (changes.headerClass && changes.headerClass.currentValue) {
                this.headerTextClass = "modal-header-text " + this.headerTextClass;
            }
        }
    };
    return ModalComponent;
}());
exports.ModalComponent = ModalComponent;
