export class TimeoutError extends Error {
    constructor(message = "Operation timed out", ...args) {
        super(message, ...args);
    }
}

/**
 * Creates a promise that resolves after the specified duration (in milliseconds).
 */
export function delay(timeout) {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}

export function timeout(promise, timeout) {
    const t = delay().then(() => {
        throw new TimeoutError();
    });
    return Promise.race([promise, t]);
}
