/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerFordInfoDto } from '../models/dealer-ford-info-dto';
import { DealerAlertToleranceExtDto } from '../models/dealer-alert-tolerance-ext-dto';
import { DealerAlertToleranceUpdateExtDto } from '../models/dealer-alert-tolerance-update-ext-dto';
import { DealerWishListExtDto } from '../models/dealer-wish-list-ext-dto';
import { DealerWishListLogExtDto } from '../models/dealer-wish-list-log-ext-dto';
import { DealerDto } from '../models/dealer-dto';
@Injectable({
    providedIn: 'root',
})
class DealerClientService extends __BaseService {
    static readonly GetDealerVendorFlagsByDealeridGETPath = '/api/Dealer/GetDealerVendorFlags/{dealerId}';
    static readonly GetDealerFordPilotDtoByDealeridGETPath = '/api/Dealer/GetDealerFordPilotDto/{dealerId}';
    static readonly GetDealerFordInfoGETPath = '/api/Dealer/GetDealerFordInfo';
    static readonly GetPandoErrorDealersByLookbackdaysGETPath = '/api/Dealer/GetPandoErrorDealers/{lookbackDays}';
    static readonly GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETPath = '/api/Dealer/GetDealerAlertTolerances/{dealerId}/{makeId}/{year}/{modelId}/{trimId}';
    static readonly UpdateDealerAlertTolerancesByDealeridPOSTPath = '/api/Dealer/UpdateDealerAlertTolerances/{dealerId}';
    static readonly GetDealerWishListsByDealeridGETPath = '/api/Dealer/GetDealerWishLists/{dealerId}';
    static readonly FlushDealerWishListsCacheByDealeridGETPath = '/api/Dealer/FlushDealerWishListsCache/{dealerId}';
    static readonly SaveDealerWishListAsyncPOSTPath = '/api/Dealer/SaveDealerWishList';
    static readonly GetDealerWishListLogsByDealeridByDealerwishlistidGETPath = '/api/Dealer/GetDealerWishListLogs/{dealerId}/{dealerWishListID}';
    static readonly ByIdGETPath = '/api/Dealer/{id}';
    static readonly ByIdDELETEPath = '/api/Dealer/{id}';
    static readonly GetManyGETPath = '/api/Dealer/GetMany';
    static readonly PUTPath = '/api/Dealer';
    static readonly POSTPath = '/api/Dealer';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param dealerId undefined
     */
    GetDealerVendorFlagsByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetDealerVendorFlags/${dealerId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param dealerId undefined
     */
    GetDealerVendorFlagsByDealeridGET(dealerId: number): __Observable<null> {
        return this.GetDealerVendorFlagsByDealeridGETResponse(dealerId).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param dealerId undefined
     */
    GetDealerFordPilotDtoByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetDealerFordPilotDto/${dealerId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param dealerId undefined
     */
    GetDealerFordPilotDtoByDealeridGET(dealerId: number): __Observable<null> {
        return this.GetDealerFordPilotDtoByDealeridGETResponse(dealerId).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param dealerId undefined
     * @return Success
     */
    GetDealerFordInfoGETResponse(dealerId?: number): __Observable<__StrictHttpResponse<Array<DealerFordInfoDto>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (dealerId != null) __params = __params.set('dealerId', dealerId.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetDealerFordInfo`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<DealerFordInfoDto>>;
            })
        );
    }
    /**
     * @param dealerId undefined
     * @return Success
     */
    GetDealerFordInfoGET(dealerId?: number): __Observable<Array<DealerFordInfoDto>> {
        return this.GetDealerFordInfoGETResponse(dealerId).pipe(
            __map(_r => _r.body as Array<DealerFordInfoDto>)
        );
    }

    /**
     * @param lookbackDays undefined
     */
    GetPandoErrorDealersByLookbackdaysGETResponse(lookbackDays: number): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetPandoErrorDealers/${lookbackDays}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param lookbackDays undefined
     */
    GetPandoErrorDealersByLookbackdaysGET(lookbackDays: number): __Observable<null> {
        return this.GetPandoErrorDealersByLookbackdaysGETResponse(lookbackDays).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
     *
     * - `year`:
     *
     * - `trimId`:
     *
     * - `modelId`:
     *
     * - `makeId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params: DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams): __Observable<__StrictHttpResponse<Array<DealerAlertToleranceExtDto>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;





        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetDealerAlertTolerances/${params.dealerId}/${params.makeId}/${params.year}/${params.modelId}/${params.trimId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<DealerAlertToleranceExtDto>>;
            })
        );
    }
    /**
     * @param params The `DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams` containing the following parameters:
     *
     * - `year`:
     *
     * - `trimId`:
     *
     * - `modelId`:
     *
     * - `makeId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGET(params: DealerClientService.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams): __Observable<Array<DealerAlertToleranceExtDto>> {
        return this.GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETResponse(params).pipe(
            __map(_r => _r.body as Array<DealerAlertToleranceExtDto>)
        );
    }

    /**
     * @param params The `DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `alertTolerances`:
     */
    UpdateDealerAlertTolerancesByDealeridPOSTResponse(params: DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.alertTolerances;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Dealer/UpdateDealerAlertTolerances/${params.dealerId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `alertTolerances`:
     */
    UpdateDealerAlertTolerancesByDealeridPOST(params: DealerClientService.UpdateDealerAlertTolerancesByDealeridPOSTParams): __Observable<null> {
        return this.UpdateDealerAlertTolerancesByDealeridPOSTResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param dealerId undefined
     * @return Success
     */
    GetDealerWishListsByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<DealerWishListExtDto>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetDealerWishLists/${dealerId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<DealerWishListExtDto>>;
            })
        );
    }
    /**
     * @param dealerId undefined
     * @return Success
     */
    GetDealerWishListsByDealeridGET(dealerId: number): __Observable<Array<DealerWishListExtDto>> {
        return this.GetDealerWishListsByDealeridGETResponse(dealerId).pipe(
            __map(_r => _r.body as Array<DealerWishListExtDto>)
        );
    }

    /**
     * @param dealerId undefined
     */
    FlushDealerWishListsCacheByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/FlushDealerWishListsCache/${dealerId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param dealerId undefined
     */
    FlushDealerWishListsCacheByDealeridGET(dealerId: number): __Observable<null> {
        return this.FlushDealerWishListsCacheByDealeridGETResponse(dealerId).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param dealerWishList undefined
     * @return Success
     */
    SaveDealerWishListPOSTResponse(dealerWishList?: DealerWishListExtDto): __Observable<__StrictHttpResponse<DealerWishListExtDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = dealerWishList;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Dealer/SaveDealerWishList`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<DealerWishListExtDto>;
            })
        );
    }
    /**
     * @param dealerWishList undefined
     * @return Success
     */
    SaveDealerWishListPOST(dealerWishList?: DealerWishListExtDto): __Observable<DealerWishListExtDto> {
        return this.SaveDealerWishListPOSTResponse(dealerWishList).pipe(
            __map(_r => _r.body as DealerWishListExtDto)
        );
    }

    /**
     * @param params The `DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams` containing the following parameters:
     *
     * - `dealerWishListID`:
     *
     * - `dealerId`:
     *
     * - `take`:
     *
     * @return Success
     */
    GetDealerWishListLogsByDealeridByDealerwishlistidGETResponse(params: DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams): __Observable<__StrictHttpResponse<Array<DealerWishListLogExtDto>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetDealerWishListLogs/${params.dealerId}/${params.dealerWishListID}/${params.take}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<DealerWishListLogExtDto>>;
            })
        );
    }
    /**
     * @param params The `DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams` containing the following parameters:
     *
     * - `dealerWishListID`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    GetDealerWishListLogsByDealeridByDealerwishlistidGET(params: DealerClientService.GetDealerWishListLogsByDealeridByDealerwishlistidGETParams): __Observable<Array<DealerWishListLogExtDto>> {
        return this.GetDealerWishListLogsByDealeridByDealerwishlistidGETResponse(params).pipe(
            __map(_r => _r.body as Array<DealerWishListLogExtDto>)
        );
    }

    /**
     * @param id undefined
     * @return Success
     */
    ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<DealerDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/${id}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<DealerDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    ByIdGET(id: number): __Observable<DealerDto> {
        return this.ByIdGETResponse(id).pipe(
            __map(_r => _r.body as DealerDto)
        );
    }

    /**
     * @param id undefined
     */
    ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/Dealer/${id}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param id undefined
     */
    ByIdDELETE(id: number): __Observable<null> {
        return this.ByIdDELETEResponse(id).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param ids undefined
     * @return Success
     */
    GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<DealerDto>>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        (ids || []).forEach(val => { if (val != null) __params = __params.append('ids', val.toString()) });
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/Dealer/GetMany`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Array<DealerDto>>;
            })
        );
    }
    /**
     * @param ids undefined
     * @return Success
     */
    GetManyGET(ids?: Array<number>): __Observable<Array<DealerDto>> {
        return this.GetManyGETResponse(ids).pipe(
            __map(_r => _r.body as Array<DealerDto>)
        );
    }

    /**
     * @param data undefined
     */
    PUTResponse(data?: DealerDto): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = data;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/Dealer`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param data undefined
     */
    PUT(data?: DealerDto): __Observable<null> {
        return this.PUTResponse(data).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param data undefined
     * @return Success
     */
    POSTResponse(data?: DealerDto): __Observable<__StrictHttpResponse<DealerDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = data;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/Dealer`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<DealerDto>;
            })
        );
    }
    /**
     * @param data undefined
     * @return Success
     */
    POST(data?: DealerDto): __Observable<DealerDto> {
        return this.POSTResponse(data).pipe(
            __map(_r => _r.body as DealerDto)
        );
    }
}

module DealerClientService {

    /**
     * Parameters for GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGET
     */
    export interface GetDealerAlertTolerancesByDealeridByMakeidByYearByModelidByTrimidGETParams {
        year: number;
        trimId: number;
        modelId: number;
        makeId: number;
        dealerId: number;
    }

    /**
     * Parameters for UpdateDealerAlertTolerancesByDealeridPOST
     */
    export interface UpdateDealerAlertTolerancesByDealeridPOSTParams {
        dealerId: number;
        alertTolerances?: Array<DealerAlertToleranceUpdateExtDto>;
    }

    /**
     * Parameters for GetDealerWishListLogsByDealeridByDealerwishlistidGET
     */
    export interface GetDealerWishListLogsByDealeridByDealerwishlistidGETParams {
        dealerWishListID: number;
        dealerId: number;
        take: number;
    }
}

export { DealerClientService }
