TemplateService.$inject = ["$location", "coreApi", "storageService", "previewTemplateEmailUrlPath", "oidc"];
import angular from 'angular';
import { OpportunitiesModule } from '../core/opportunities.module';
import { saveAs } from 'file-saver';
OpportunitiesModule.factory('templateService', TemplateService);
/* @ngInject */

function TemplateService($location, coreApi, storageService, previewTemplateEmailUrlPath, oidc) {
  var service = {
    getTemplatesForDealerId: getTemplatesForDealerId,
    getNonEmptyTemplatesForDealerId: getNonEmptyTemplatesForDealerId,
    validateMaxEmailsPerDay: validateMaxEmailsPerDay,
    getMergedTemplate: getMergedTemplate,
    getSentEmailContent: getSentEmailContent,
    getEmailPreviewContent: getEmailPreviewContent,
    getEmailSubscription: getEmailSubscription,
    disableEmailSubscription: disableEmailSubscription,
    createPdf: createPdf,
    downloadPdf: downloadPdf,
    archivePdf: archivePdf,
    getDealerOutboundEmailProviderInfo: getDealerOutboundEmailProviderInfo,
    storePreviewTemplate: storePreviewTemplate,
    retrievePreviewTemplate: retrievePreviewTemplate,
    getTemplateEmailPreviewUrl: getTemplateEmailPreviewUrl,
    deleteTemplate: deleteTemplate,
    sendEmail: sendEmail,
    getTemplateEmailPreview: getTemplateEmailPreview,
    getTemplatePdfPreview: getTemplatePdfPreview
  };
  return service;

  function deleteTemplate(templateId) {
    return coreApi.api.Template.DeleteTemplate['{templateId}'].DELETE({
      templateId: templateId
    }).then(function (response) {
      return response.status === 204;
    });
  }

  function getTemplateEmailPreviewUrl(opportunityId, templateId, replMakeId) {
    var emailPreviewUrl = oidc.opportunitiesOrigin + previewTemplateEmailUrlPath + opportunityId + "/" + templateId + "/" + replMakeId;
    return emailPreviewUrl;
  }

  function storePreviewTemplate(previewTemplate) {
    storageService.removeItem('previewTemplate');
    storageService.setItem('previewTemplate', previewTemplate);
  }

  function retrievePreviewTemplate() {
    var previewTemplate = storageService.getItem('previewTemplate');
    return previewTemplate;
  }

  function sendEmail(outboundTemplate, parameters) {
    var sendEmailRequest = {
      EmailRequest: outboundTemplate,
      ProposalRequest: parameters
    };
    return coreApi.api.TemplateOutboundEmail.SendEmailWithAttachment.POST(sendEmailRequest);
  }

  function getDealerOutboundEmailProviderInfo(dealerId) {
    return coreApi.api.DealerSetting.GetDealerOutboundEmailProviderInfo['{dealerId}'].GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function getTemplatesForDealerId(dealerId) {
    assert(dealerId, "dealerId must be provided");
    return coreApi.api.Template.GetTemplatesForDealerId.GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data.map(function (t) {
        return {
          id: t.templateID,
          templateName: t.templateName,
          templateSubject: t.templateSubject
        };
      });
    });
  }

  function getNonEmptyTemplatesForDealerId(dealerId) {
    assert(dealerId, "dealerId must be provided");
    return coreApi.api.Template.GetNonEmptyTemplatesForDealerId.GET({
      dealerId: dealerId
    }).then(function (response) {
      return response.data.map(function (t) {
        return {
          id: t.templateID,
          templateName: t.templateName,
          templateSubject: t.templateSubject,
          createdBy: t.createdBy,
          createDate: t.createDate,
          createdByFullName: t.createdByFullName,
          isSystemTemplate: t.isSystemTemplate,
          isActiveInLeadManagement: t.isActiveInLeadManagement
        };
      });
    });
  } //ValidateMaxEmailsPerDay/{entityId}


  function validateMaxEmailsPerDay(entityId) {
    assert(entityId, "entityId must be provided");
    return coreApi.api.TemplateOutboundEmail.ValidateMaxEmailsPerDay["{entityId}"].GET({
      entityId: entityId
    });
  }

  function getMergedTemplate(_ref) {
    var opportunityId = _ref.opportunityId,
        templateId = _ref.templateId,
        replacementVehicle = _ref.replacementVehicle;
    assert(templateId, "templateId must be provided");
    assert(opportunityId, "opportunityId must be provided");
    return coreApi.api.Template.MergeTemplate['{templateId}'].POST({
      entityId: opportunityId,
      replacementVehicle: replacementVehicle
    }, {
      templateId: templateId
    }).then(function (response) {
      return response.data;
    });
  }

  function getSentEmailContent(templateEmailId, accessCode) {
    return coreApi.api.TemplateOutboundEmail.GetSentEmailContent['{templateOutboundEmailId}']['{accessCode}'].GET({
      templateOutboundEmailId: templateEmailId,
      accessCode: accessCode
    }).then(function (response) {
      return response.data;
    });
  }

  function getEmailPreviewContent(opportunityId, templateId, templateContent, replMakeId) {
    return coreApi.api.TemplateOutboundEmail.GenerateEmailPreview.POST({
      entityId: opportunityId,
      templateId: templateId,
      ContentHtml: templateContent,
      replMakeId: replMakeId
    }).then(function (response) {
      return response.data;
    });
  }

  function getTemplateEmailPreview(templateId, dealerId) {
    return coreApi.api.Template.TemplateEmailPreview['{templateId}']['{dealerId}'].GET({
      templateId: templateId,
      dealerId: dealerId
    }).then(function (response) {
      return response.data;
    });
  }

  function getTemplatePdfPreview(templateId, dealerId) {
    return coreApi.api.Template.TemplatePdfPreview['{templateId}']['{dealerId}'].GET({
      templateId: templateId,
      dealerId: dealerId
    }, {
      responseType: 'blob'
    }).then(function (response) {
      return saveAs(response.data, "".concat('autoalert_' + templateId || 'document', ".pdf"));
    });
  }

  function getEmailSubscription(templateEmailId, accessCode) {
    return coreApi.api.TemplateOutboundEmail.GetEmailSubscription['{templateOutboundEmailId}']['{accessCode}'].GET({
      templateOutboundEmailId: templateEmailId,
      accessCode: accessCode
    }).then(function (response) {
      return response.data;
    });
  }

  function disableEmailSubscription(templateEmailId, accessCode) {
    return coreApi.api.TemplateOutboundEmail.DisableEmailSubscription['{templateOutboundEmailId}']['{accessCode}'].POST(null, {
      templateOutboundEmailId: templateEmailId,
      accessCode: accessCode
    });
  }

  function createPdf(opportunityId, template, contentHtml, templateLetterheadImageUrl) {
    return coreApi.api.TemplateOutput.CreatePdf.POST({
      entityId: opportunityId,
      templateId: template.id,
      templateName: template.templateName,
      contentHtml: contentHtml,
      templateLetterheadImageUrl: templateLetterheadImageUrl
    }).then(function (response) {
      return {
        id: response.data.templateOutputID,
        templateId: response.data.templateID,
        templateName: response.data.templateName,
        opportunityId: response.data.entityID,
        isArchived: response.data.isArchived
      };
    });
  }

  function downloadPdf(templateOutputId, downloadName) {
    return coreApi.api.TemplateOutput.DownloadPdf['{templateOutputId}'].GET({
      templateOutputId: templateOutputId
    }, {
      responseType: 'blob'
    }).then(function (response) {
      saveAs(response.data, "".concat(downloadName || 'document', ".pdf"));
    });
  }

  function archivePdf(templateOutputId) {
    return coreApi.api.TemplateOutput.ArchivePdf.POST({
      templateOutputId: templateOutputId
    });
  }

  function assert(condition, message) {
    if (!condition) {
      throw new Error(message);
    }
  }
}