"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
exports.unauthorized$ = new rxjs_1.Subject();
exports.tokenExpired$ = new rxjs_1.Subject();
exports.tokenMissing$ = new rxjs_1.Subject();
exports.tokenExpiresSoon$ = new rxjs_1.Subject();
exports.loggedIn$ = new rxjs_1.Subject();
exports.externalLoggedIn$ = new rxjs_1.Subject();
exports.loggedOut$ = new rxjs_1.Subject();
exports.silentRefreshStarted$ = new rxjs_1.Subject();
exports.silentRefreshSucceeded$ = new rxjs_1.Subject();
exports.silentRefreshFailed$ = new rxjs_1.Subject();
exports.silentRefreshTokenUnchanged$ = new rxjs_1.Subject();
exports.silentRefreshTimeout$ = new rxjs_1.Subject();
