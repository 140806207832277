import { Component, Input, OnInit, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { AuthService } from '../../../ajs-upgraded-providers';
import { TokenService } from '../../../modules/oidc-auth/token.service';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'impersonation-status',
    templateUrl: './impersonation-status.component-ng.html',
    styleUrls: ['./impersonation-status.component.scss']
})
export class ImpersonationStatusComponent implements OnInit, OnChanges {
    @Input() userFullName: string;
    impersonatingUserFullName: string;
    impersonationStatus: string;

    constructor(@Inject(AuthService) private authService,
        private userService: UserService,
        private tokenService: TokenService,
        public tService: TranslateService) { }

    ngOnInit() {
        const impersonatingUsername: string = this.tokenService.getImpersonatingUsername();
        if (impersonatingUsername) {
            this.userService.getUser(impersonatingUsername).then(response => {
                this.impersonatingUserFullName = response.fullName;
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userFullName && !changes.userFullName.isFirstChange()) {
            this.impersonationStatus = this.tService
                .instant('viewingAsUser', { impersonatingUser: this.impersonatingUserFullName, impersonatedUser: this.userFullName });
        }
    }

    logOut() {
        this.authService.signOut();
    }
}
