
/* injects from baggage-loader */
require('./pando-survey-select.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('pandoSurveySelect', {
    templateUrl: '/app/components/reports/pando-survey-select.component.html',
    controller: PandoSurveySelectController,
    bindings: {
        defaultSurveyFamilyId: '<',
        defaultSurveyId: '<',
        showSurveySelectors: '<',
        onSelect: '&',
        onSetReportState: '&'
    }
});

/* @ngInject */
function PandoSurveySelectController(_, $timeout, pandoSurveyService) {
    var $ctrl = this;

    $ctrl.selectedSurveyFamilyId;
    $ctrl.selectedSurveyId;
    $ctrl.surveyFamilies;
    $ctrl.surveys;
    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;
    $ctrl.updateSurveys = updateSurveys;
    $ctrl.onSurveyFamilySurveySelect = onSurveyFamilySurveySelect;
    $ctrl.setReportState = setReportState;

    // reportState:
    // 0 = Report Unavailable
    // 1 = Report Available but no Survey Data available
    // 2 = Report Available and Survey Data available
    var REPORT_UNAVAILABLE = 0;
    var REPORT_AVAILABLE_WITH_NO_DATA = 1;
    var REPORT_AVAILABLE_WITH_DATA = 2;
    $ctrl.reportState;

    function init() {
        pandoSurveyService.getAccessibleSurveys()
            .then((surveyFamilies) => {
                if (surveyFamilies) {
                    if (surveyFamilies.length > 0) {
                        $ctrl.surveyFamilies = getSimpleSurveyFamilies(surveyFamilies);
                        if (!$ctrl.selectedSurveyFamilyId) {
                            $ctrl.selectedSurveyFamilyId = $ctrl.surveyFamilies[0].id;
                        }
                        $ctrl.surveys = getSimpleSurveys(surveyFamilies, $ctrl.selectedSurveyFamilyId);
                        $ctrl.selectedSurveyId = $ctrl.surveys[0].id;
                        setReportState(REPORT_AVAILABLE_WITH_DATA);
                    } else {
                        setReportState(REPORT_AVAILABLE_WITH_NO_DATA);
                    }
                } else {
                    setReportState(REPORT_UNAVAILABLE);
                }
            })
            .catch((response) => {
                setReportState(REPORT_UNAVAILABLE);
            })
    }

    function onChanges(changes) {
        if (changes.defaultSurveyFamilyId && changes.defaultSurveyFamilyId.currentValue !== $ctrl.selectedSurveyFamilyId) {
            $ctrl.selectedSurveyFamilyId = $ctrl.defaultSurveyFamilyId;
            updateSurveys($ctrl.defaultSurveyId);
        }
    }

    function updateSurveys(defaultSurveyId) {
        pandoSurveyService.getAccessibleSurveys()
            .then((surveyFamilies) => {
                if (surveyFamilies && surveyFamilies.length > 0) {
                    $ctrl.surveys = getSimpleSurveys(surveyFamilies, $ctrl.selectedSurveyFamilyId);

                    if (defaultSurveyId) {
                        $ctrl.selectedSurveyId = defaultSurveyId;
                    }
                    else {
                        $ctrl.selectedSurveyId = $ctrl.surveys[0].id;
                        onSurveyFamilySurveySelect();
                    }
                    setReportState(REPORT_AVAILABLE_WITH_DATA);
                } else {
                    setReportState(REPORT_AVAILABLE_WITH_NO_DATA);
                }
            })
            .catch((response) => {
                setReportState(REPORT_UNAVAILABLE);
            })
    }

    function getSimpleSurveyFamilies(surveyFamilies) {
        if (surveyFamilies && surveyFamilies.length > 0) {
            return surveyFamilies.map(function (o) {
                return { id: o.ID, name: o.DisplayName }
            })
        } else {
            return [];
        }
    }

    function getSimpleSurveys(surveyFamilies, surveyFamilyId) {
        if (surveyFamilies && surveyFamilies.length > 0) {
            var surveyFamily = _.find(surveyFamilies, (o) => { return o.ID === surveyFamilyId });
            return surveyFamily.Surveys.map(function (o) {
                return { id: o.ID, name: o.DisplayName, startDate: o.SurveyStartDT, endDate: o.SurveyEndDT }
            })
        } else {
            return [];
        }
    }

    function setReportState(state) {
        $ctrl.reportState = state;
        $ctrl.onSetReportState({ state: state });
    }

    function onSurveyFamilySurveySelect() {
        $ctrl.onSelect({
            surveyFamilyId: $ctrl.selectedSurveyFamilyId,
            surveyId: $ctrl.selectedSurveyId,
                surveys: $ctrl.surveys,
                state: $ctrl.reportState
        });
    }
}
