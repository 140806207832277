
/* injects from baggage-loader */
require('./report-subscriptions-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('reportSubscriptionsModal', {
    templateUrl: '/app/components/reports/report-subscriptions-modal.component.html',
    controller: ReportSubscriptionsModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function ReportSubscriptionsModalController(reportSubscriptionViewTypes) {
    var $ctrl = this;

    $ctrl.cancelSubscriptionEdit;
    $ctrl.addSubscription;
    $ctrl.updateSubscription;
    $ctrl.subscriptionCount;
    $ctrl.viewType;
    $ctrl.viewTypes;
    $ctrl.$onInit = init;
    $ctrl.onCancel = onCancel;

    function init() {
        $ctrl.viewTypes = reportSubscriptionViewTypes;
    }

    function onCancel() {
        if ($ctrl.viewType != $ctrl.viewTypes.list && $ctrl.subscriptionCount > 0) {
            $ctrl.cancelSubscriptionEdit();
        }
        else {
            $ctrl.dismiss();
        }
    }
}
