"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_settings_1 = require("../../constants/app-settings");
var storage_service_1 = require("../../modules/storage/storage.service");
var cookie_storage_service_1 = require("../../modules/storage/cookie-storage.service");
var http_interceptor_options_service_1 = require("./http-interceptor-options.service");
/** Pass untouched request through to the next request handler. */
var NgSelectedDealersInterceptor = /** @class */ (function () {
    function NgSelectedDealersInterceptor(storageService, httpInterceptorOptionsService, crossDomainStorageService) {
        this.storageService = storageService;
        this.httpInterceptorOptionsService = httpInterceptorOptionsService;
        this.crossDomainStorageService = crossDomainStorageService;
        this.URL_PREFIXES_TO_MODIFY = [];
        this.CONFIG = {
            employeeDefaultDealer: undefined
        };
        if (app_settings_1.Settings.environmentVariables.employeeDefaultDealer) {
            this.CONFIG.employeeDefaultDealer = app_settings_1.Settings.environmentVariables.employeeDefaultDealer;
            this.URL_PREFIXES_TO_MODIFY.push(app_settings_1.Settings.apiUrls.alertMiner);
            this.URL_PREFIXES_TO_MODIFY.push(app_settings_1.Settings.apiUrls.reportMiner);
        }
    }
    NgSelectedDealersInterceptor.prototype.intercept = function (req, next) {
        // Only continue if the request URL matches a configured prefix
        if (!this.URL_PREFIXES_TO_MODIFY.some(function (prefix) { return req.url.startsWith(prefix); })) {
            return next.handle(req);
        }
        var selectedDealers = this.getSelectedDealers();
        var selectedDealerGroupId = selectedDealers && selectedDealers.dealerGroupId ? selectedDealers.dealerGroupId : null;
        var selectedDealerIds = selectedDealers && selectedDealers.dealerIds ? selectedDealers.dealerIds : null;
        var overrideDealerIds = this.httpInterceptorOptionsService.overrideDealerIdsSetting;
        // Employee users default to MBDEMO
        // This block should only be hit for employees.
        if (!selectedDealerGroupId || !selectedDealerIds) {
            selectedDealerGroupId = this.CONFIG.employeeDefaultDealer.dealerGroupId;
            selectedDealerIds = [this.CONFIG.employeeDefaultDealer.dealerId];
        }
        var newHeaders = {};
        newHeaders['dealers'] = selectedDealerIds.join(',');
        newHeaders['dealergroupid'] = selectedDealerGroupId;
        if (overrideDealerIds && overrideDealerIds.length > 0) {
            newHeaders['dealerIdOverride'] = overrideDealerIds.join(',');
        }
        // Requests are immutable so we have to clone if we want to change them
        var newReq = req.clone({ setHeaders: newHeaders });
        return next.handle(newReq);
    };
    NgSelectedDealersInterceptor.prototype.getSelectedDealers = function () {
        var selectedDealers = this.storageService.getItem('impersonatingSelectedDealers');
        if (selectedDealers != null) {
            var userProfile = this.storageService.getItem('userProfile');
            if (userProfile && userProfile.employeeUser) {
                this.storageService.removeItem('impersonatingSelectedDealers');
            }
        }
        else {
            selectedDealers = this.crossDomainStorageService.getItem('selectedDealers');
        }
        if (selectedDealers && selectedDealers.dealers && selectedDealers.dealers.length > 0) {
            selectedDealers.dealerIds = selectedDealers.dealers.map(function (dealer) { return dealer.id; });
        }
        return selectedDealers;
    };
    return NgSelectedDealersInterceptor;
}());
exports.NgSelectedDealersInterceptor = NgSelectedDealersInterceptor;
