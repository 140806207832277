import { Injectable } from "@angular/core";
import { PandoPassthroughClientService, ExtCustomerActivityClientService } from "../generated/services";
import { GetFullConversicaConversationResponseDto } from "../generated/models";
import { ConversicaConversationStatus } from "../generated/models/conversica-conversation-status";

@Injectable({
    providedIn: 'root'
})
export class AutoAssistantService {

    constructor(
        private pandoPassthroughClientService: PandoPassthroughClientService,
        private extCustomerActivityClientService: ExtCustomerActivityClientService) {

    }

    endCommunication(entityId: string): Promise<number> {
        return this.extCustomerActivityClientService.AddEndConversationEventRecordByEntityidGET(entityId).toPromise();
    }

    isAutoAssistantConversationOpen(entityId: string): Promise<boolean> {
        return this.extCustomerActivityClientService.IsAutoAssistantConversationOpenByEntityidGET(entityId).toPromise();
    }

    getAutoAssistFullView(dealerId: number, entityId: string): Promise<GetFullConversicaConversationResponseDto> {
        return this.pandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGET({ dealerId, entityId }).toPromise();
    }

    getConversicaConversationStatuses(dealerId: number, entityId: string): Promise<Array<ConversicaConversationStatus>> {
        return this.pandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGET({ dealerId, entityId }).toPromise();
    }
}
