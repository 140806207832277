/* global window document */
import $ from 'jquery'

if (!RedactorPlugins) var RedactorPlugins = {};

window.RedactorPlugins.layout = {
  init: function () {
    var buttonName = 'layout';
    var dropdown = {};
    dropdown.insert_column_1 = { title: "1 Column", func: 'layoutButtonClick' };
    dropdown.insert_column_2 = { title: "2 Columns", func: 'layoutButtonClick' };
    dropdown.insert_column_3 = { title: "3 Columns", func: 'layoutButtonClick' };
    dropdown.separator_drop2 = { name: 'separator' };
    dropdown.delete_layout = { title: "Delete Layout", func: 'layoutDelete' };
    var mybutton = this.buttonAdd(buttonName, 'Layout', null, dropdown);
    this.buttonTagToActiveState(buttonName, 'table.redactor-layout-table')
    
    this.$editor.on('mouseup.redactor-plugin-layout keyup.redactor-plugin-layout', $.proxy(function () {
      var btn = this.buttonGet(buttonName);
      if (btn.hasClass('redactor_act')) {
        this.buttonInactive('table');
      }
    }, this));    
  },
  layoutButtonClick: function (buttonName) {
    var columnCount = parseInt(buttonName.charAt(buttonName.length - 1));
    this.selectionSave();
    this.layoutInsert(columnCount);
  },
  layoutInsert: function (columnCount) {
    this.bufferSet(false);
    var invisibileSpace = '&#x200b';
    var width = Math.ceil(628.00 / columnCount);
    var className = "col-" + columnCount
    var tableId = parseInt(Math.floor(Math.random() * 99999));
    var table = '<table cellspacing="0" cellpadding="0" id="table' + tableId + '" class="redactor-layout-table"><tbody><tr>';
    for (var col = 0; col < columnCount; col++) {
      table += '<td class="' + className + '">' + this.opts.invisibleSpace + '</td>';
    }
    table += '</tr></tbody></table>';
    this.selectionRestore();
    this.insertHtmlAdvanced(table, true);
    this.selectionRestore();
  },
  layoutDelete: function () {    
    var $table = $(this.getParent()).closest('table.redactor-layout-table');
    if (!this.isParentRedactor($table)) return false;
    if ($table.length == 0) return false;

    this.bufferSet();

    $table.remove();
    this.sync();
  }
};