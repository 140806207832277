import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'money-factor',
  templateUrl: './money-factor.component-ng.html',
  styleUrls: ['./money-factor.component.scss']
})
export class MoneyFactorComponent implements OnInit {
    @Input() value: any;
    constructor() { }

      ngOnInit() {
      }
}
