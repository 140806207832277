
export const CustomerProcessStatuses = {
    notSet: 0,
    waiting: 10,
    new: 50,
    inProgress: 100,
    inProgressExternal: 101,
    overdue: 150,
    cancelled: 900,
    autoCancelled: 901,
    success: 1000,
};
