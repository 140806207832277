"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Carfaxv2Component = /** @class */ (function () {
    function Carfaxv2Component(vehicleService) {
        this.vehicleService = vehicleService;
        this.showCarFaxLink = false;
    }
    Carfaxv2Component.prototype.ngOnInit = function () {
    };
    Carfaxv2Component.prototype.ngOnChanges = function (changes) {
        if (!this.permissions || !this.dealerSettings || !this.vin || !this.dealerId) {
            return;
        }
        this.showCarFaxLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableCarFax;
    };
    Carfaxv2Component.prototype.logOut = function () {
        this.vehicleService.carFaxLogOut()
            .then(function (data) {
            var carfaxLogoutUrl = "https://auth.carfax.com/v2/logout";
            window.open(carfaxLogoutUrl, '_blank');
        })
            .catch(function () {
        });
    };
    return Carfaxv2Component;
}());
exports.Carfaxv2Component = Carfaxv2Component;
