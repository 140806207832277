import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.find";
ReportSubscriptionsController.$inject = ["reportService", "reportSubscriptionViewTypes", "duplicateSubscriptionKey", "logger"];

/* injects from baggage-loader */
require('./report-subscriptions.component.html');

import angular from 'angular';
import { AllReportSpecs } from '../../constants/reports/report-specifications';
import _ from 'lodash';
angular.module('AaApp.Opp').component('reportSubscriptions', {
  templateUrl: '/app/components/reports/report-subscriptions.component.html',
  controller: ReportSubscriptionsController,
  bindings: {
    defaults: '<',
    viewType: '<',
    addSubscription: '=',
    updateSubscription: '=',
    cancelSubscriptionEdit: '=',
    onSubscriptionsLoad: '&',
    onViewTypeChange: '&'
  }
});
/* @ngInject */

function ReportSubscriptionsController(reportService, reportSubscriptionViewTypes, duplicateSubscriptionKey, logger) {
  var $ctrl = this;
  $ctrl.validateSubscriptionEditForm;
  $ctrl.viewTypes;
  $ctrl.editableSubscription;
  $ctrl.subscriptions;
  $ctrl.$onInit = init;
  $ctrl.editSubscription = editSubscription;
  $ctrl.toggleSubscription = toggleSubscription;
  $ctrl.deleteSubscription = deleteSubscription;

  function init() {
    $ctrl.viewTypes = reportSubscriptionViewTypes;
    $ctrl.addSubscription = addSubscription;
    $ctrl.updateSubscription = updateSubscription;
    $ctrl.cancelSubscriptionEdit = cancelSubscriptionEdit;
    loadSubscriptions();
  }

  function loadSubscriptions() {
    reportService.getSubscriptions().then(setSubscriptions);
  }

  function getReportSpec() {}

  function setSubscriptions(subscriptions) {
    $ctrl.subscriptions = subscriptions;

    for (var i = 0; i < $ctrl.subscriptions.length; i++) {
      var subscription = $ctrl.subscriptions[i];

      var reportSpec = _.find(AllReportSpecs, function (item) {
        return item.id.toLowerCase() == subscription.reportId;
      });

      subscription.translateKey = reportSpec.titleKey.replace("Enterprise", "");
      subscription.isEnterprise = reportSpec.isEnterprise;
      subscription.showMake = true;

      if (reportSpec.isEnterprise) {
        //show "All Dealerships" instead of the dealerName if this is an enterprise report. (this should eventually come from reportSpec instead of JS code)
        subscription.dealerName = "All Dealerships";
        subscription.dealerID = 0;
        subscription.showMake = false;
      } //hide "make" from view if "All Dealerships" is selected.  (this should eventually come from reportSpec instead of JS code)


      switch (reportSpec.titleKey) {
        case "userActivityReport":
        case "certificationReport":
        case "certificationEnterpriseReport":
        case "pandoUserActivityReport":
          subscription.showMake = false;
          break;
      }
    }

    $ctrl.editableSubscription = null;
    $ctrl.onSubscriptionsLoad({
      count: subscriptions.length
    });
    var viewType = subscriptions.length == 0 ? $ctrl.viewTypes.add : $ctrl.viewTypes.list;
    $ctrl.onViewTypeChange({
      viewType: viewType
    });
  }

  function cancelSubscriptionEdit() {
    $ctrl.editableSubscription = null;
    $ctrl.onViewTypeChange({
      viewType: $ctrl.viewTypes.list
    });
  }

  function editSubscription(subscription) {
    $ctrl.editableSubscription = subscription;
    $ctrl.onViewTypeChange({
      viewType: $ctrl.viewTypes.edit
    });
  }

  function addSubscription() {
    var validationResult = $ctrl.validateSubscriptionEditForm();

    if (validationResult.valid) {
      if (isDuplicateSubscription(validationResult.subscription)) {
        logger.warning(duplicateSubscriptionKey);
      } else {
        reportService.addSubscription(validationResult.subscription).then(loadSubscriptions);
      }
    }
  }

  function updateSubscription() {
    var validationResult = $ctrl.validateSubscriptionEditForm();
    var isUpdateSubscription = true;

    if (validationResult.valid) {
      if (validationResult.edited) {
        if (isDuplicateSubscription(validationResult.subscription, isUpdateSubscription)) {
          logger.warning(duplicateSubscriptionKey);
        } else {
          reportService.updateSubscription(validationResult.subscription).then(loadSubscriptions);
        }
      } else {
        $ctrl.onViewTypeChange({
          viewType: $ctrl.viewTypes.list
        });
      }
    }
  }

  function toggleSubscription(subscriptionId, enabled) {
    if (enabled) {
      reportService.enableSubscription(subscriptionId).then(loadSubscriptions);
    } else {
      reportService.disableSubscription(subscriptionId).then(loadSubscriptions);
    }
  }

  function deleteSubscription(subscriptionId) {
    reportService.deleteSubscription(subscriptionId).then(loadSubscriptions);
  }

  function isDuplicateSubscription(subscription, isUpdateSubscription) {
    var duplicateSubscription = null;

    if (isUpdateSubscription) {
      // UPDATE Subscription
      duplicateSubscription = _.find($ctrl.subscriptions, {
        'reportId': subscription.reportId.toLowerCase(),
        'dealerID': subscription.dealerID,
        'makeID': subscription.makeID,
        'code': subscription.code,
        'periodicity': subscription.periodicity,
        'deliveryMethods': subscription.deliveryMethods,
        'reportSubscriptionPeriodPoint': subscription.reportSubscriptionPeriodPoint
      }); // user has only updated delivery method options

      if (duplicateSubscription) if (subscription.deliveryMethods.length != duplicateSubscription.deliveryMethods.length) return false;

      if (!duplicateSubscription) {
        duplicateSubscription = _.find($ctrl.subscriptions, {
          'reportId': subscription.reportId.toLowerCase(),
          'dealerID': subscription.dealerID,
          'makeID': subscription.makeID,
          'code': subscription.code
        });

        if (duplicateSubscription) {
          // it's the same record but with diffrent optional params
          if (subscription.reportSubscriptionId == duplicateSubscription.reportSubscriptionId) {
            var isDeliveryduplicateSubscription = _.find([duplicateSubscription], {
              'periodicity': subscription.periodicity,
              'deliveryMethods': subscription.deliveryMethods,
              'reportSubscriptionPeriodPoint': subscription.reportSubscriptionPeriodPoint
            });

            if (isDeliveryduplicateSubscription) return true;else return false;
          }
        }

        if (duplicateSubscription) return true;else return false;
      }

      return duplicateSubscription != null;
    } else {
      // ADD Subscription
      // WEBUI-6902 Removed Delivery method and schedule params from duplicate search criteria for ADD
      duplicateSubscription = _.find($ctrl.subscriptions, {
        'reportId': subscription.reportId.toLowerCase(),
        'dealerID': subscription.dealerID,
        'makeID': subscription.makeID,
        'code': subscription.code
      });
    }

    return duplicateSubscription != null;
  }
}