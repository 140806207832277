"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".avilabilityBottomPadding[_ngcontent-%COMP%]{margin-bottom:8px}.availabilityIcon[_ngcontent-%COMP%]{width:20px;height:20px}label[_ngcontent-%COMP%]{margin-left:5px;vertical-align:top}textarea[_ngcontent-%COMP%]{width:100%}#availabilityPanelNgx[_ngcontent-%COMP%]{border-radius:8px!important;overflow:hidden;padding-top:0;padding-bottom:0;color:#555}#availabilityPanel[_ngcontent-%COMP%]{width:300px;height:auto;padding:15px 15px 0}#busynumber[_ngcontent-%COMP%]{width:65px}.busyFor[_ngcontent-%COMP%]{margin-right:5px}"];
exports.styles = styles;
