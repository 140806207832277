import { Component, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter  } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';

@Component({
    selector: 'tooltip',
    templateUrl: './tooltip-ng.html'
})

export class TooltipComponent {
    @Input() titleKey: string = null;
    @Input() contentKey: string = null;

    constructor(public popoverModule: PopoverModule) {
    }
}