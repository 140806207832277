/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DeskitQuotingPushResponseDto } from '../models/deskit-quoting-push-response-dto';
import { DeskitQuotingPushDto } from '../models/deskit-quoting-push-dto';
@Injectable({
  providedIn: 'root',
})
class DeskitClientService extends __BaseService {
  static readonly PushToDeskitPOSTPath = '/api/Deskit/PushToDeskit';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  PushToDeskitPOSTResponse(request?: DeskitQuotingPushDto): __Observable<__StrictHttpResponse<DeskitQuotingPushResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Deskit/PushToDeskit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeskitQuotingPushResponseDto>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  PushToDeskitPOST(request?: DeskitQuotingPushDto): __Observable<DeskitQuotingPushResponseDto> {
    return this.PushToDeskitPOSTResponse(request).pipe(
      __map(_r => _r.body as DeskitQuotingPushResponseDto)
    );
  }
}

module DeskitClientService {
}

export { DeskitClientService }
