import { Component, OnInit, Input } from '@angular/core';

 @Component({
   selector: 'residual',
   templateUrl: './residual.component-ng.html',
   styleUrls: ['./residual.component.scss']
 })
 export class ResidualComponent implements OnInit {
     @Input() value: number;

   constructor() { }

   ngOnInit() {
   }

 }
