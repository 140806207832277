<div class="row">
    <div class="col-md-10">
        <div class="settingTitle" translate="dsAdmin_dataViewSettings"></div>
        <tooltip [titleKey]="'dsAdmin_dataViewSettings'" [contentKey]="'dsAdmin_dataViewSettingsTooltip'"></tooltip>
        <div class="settingItem">
            <div class="row">
                <div class="col-md-2" translate="dsAdmin_viewServiceAppointment"></div>
                <div class="col-md-2">
                    <div style="float:left">
                        <input name="DealerSDOSetting.ViewSvcApptDays" type="number" class="input-sm textboxSm" [disabled]="!dsa.isSDOChangeExpiration" [(ngModel)]="dsa.dealerSDOSetting.viewSvcApptDays" /> {{ 'days' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="settingItem">
            <label><input [disabled]="!dsa.isSDOChangeExpiration" type="checkbox" [(ngModel)]="dsa.dealerSetting.allowExpiration" /> {{ 'dsAdmin_allowExpiration' | translate }}</label>
        </div>
    </div>
</div>
