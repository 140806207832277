import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToasterLoggerService } from '../../../core/toaster-logger.service';
import { NgxModalWrapperService } from '../../../services/ngx-modal-wrapper.service';
import { DealSheetProcessComponent } from './deal-sheet-process.component';

@Component({
    selector: 'open-process-modal',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenProcessModalComponent {
    constructor(public tService: TranslateService,
        public modalService: NgxModalWrapperService,
        public logger: ToasterLoggerService,
        @Inject(DOCUMENT) private document, ) {
    }

    @Input() openModal: boolean;
    @Output() openModalChanged = new EventEmitter<boolean>();
    @Input() entityId: string;

    bsModalRef: BsModalRef;

    public ngOnChanges(changes): void {
        if (this.openModal) {
            const initialState = {
                entityId: this.entityId
            };

            this.bsModalRef = this.modalService.show(DealSheetProcessComponent, { initialState });

            this.bsModalRef.content.onClosed.subscribe((result: any) => {
                if (result.started != null) {
                    if (result.started) {
                        this.logger.success(this.tService.instant("processSuccessfullyInitiated"));
                    } else {
                        this.logger.error(this.tService.instant("generalErrorMessageKey"));
                    }
                }

                this.openModalChanged.emit(false);
            });

            this.openModalChanged.emit(false);
        }
    }
}
