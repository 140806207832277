"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var locale_date_pipe_1 = require("../../../../filters/locale-date.pipe");
var locale_date_time_pipe_1 = require("../../../../filters/locale-date-time.pipe");
var locale_time_pipe_1 = require("../../../../filters/locale-time.pipe");
var core_2 = require("@ngx-translate/core");
var ServiceAppointmentComponent = /** @class */ (function () {
    function ServiceAppointmentComponent(localeDate, localeDateTime, localeTime, translateService) {
        this.localeDate = localeDate;
        this.localeDateTime = localeDateTime;
        this.localeTime = localeTime;
        this.translateService = translateService;
        this.serviceAppointment = "";
    }
    ServiceAppointmentComponent.prototype.ngOnInit = function () {
        this.idUnique = 'service-appointment' + (Date.now() + (Math.floor(Math.random() * 1000))).toString();
    };
    ServiceAppointmentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.date && !changes.date.isFirstChange() && changes.date.currentValue !== null && changes.date.currentValue !== undefined) {
            if (this.abbreviate) {
                var serviceAppointmentDateTime = this.localeDateTime.transform(this.date);
                this.serviceAppointment = this.translateService.instant('serviceAppointmentShortText', { date: serviceAppointmentDateTime });
            }
            else {
                var serviceAppointmentDate = this.localeDate.transform(this.date);
                var serviceAppointmentTime = this.localeTime.transform(this.date);
                this.serviceAppointment = this.translateService.instant('serviceAppointmentLongText', { date: serviceAppointmentDate, time: serviceAppointmentTime });
            }
        }
    };
    return ServiceAppointmentComponent;
}());
exports.ServiceAppointmentComponent = ServiceAppointmentComponent;
