"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PandoXRuleCategory = {
    Sales: 1,
    Service: 2,
    InventoryAcquisition: 3,
    Conquest: 4
};
exports.PandoXRuleCategoryReverse = {
    1: 'Sales',
    2: 'Service',
    3: 'InventoryAcquisition',
    4: 'Conquest'
};
