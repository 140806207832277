"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var SearchClientService = /** @class */ (function (_super) {
    __extends(SearchClientService, _super);
    function SearchClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param entityId undefined
     */
    SearchClientService.prototype.GetSearchByEntityIdByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Search/GetSearchByEntityId/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     */
    SearchClientService.prototype.GetSearchByEntityIdByEntityidGET = function (entityId) {
        return this.GetSearchByEntityIdByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `SearchClientService.GetOpenOpportunitiesGETParams` containing the following parameters:
     *
     * - `saleTypes`:
     *
     * - `flushCache`:
     */
    SearchClientService.prototype.GetOpenOpportunitiesGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (params.saleTypes || []).forEach(function (val) { if (val != null)
            __params = __params.append('saleTypes', val.toString()); });
        if (params.flushCache != null)
            __params = __params.set('flushCache', params.flushCache.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Search/GetOpenOpportunities", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `SearchClientService.GetOpenOpportunitiesGETParams` containing the following parameters:
     *
     * - `saleTypes`:
     *
     * - `flushCache`:
     */
    SearchClientService.prototype.GetOpenOpportunitiesGET = function (params) {
        return this.GetOpenOpportunitiesGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param saleTypes undefined
     */
    SearchClientService.prototype.GetOpenOpportunitiesFlushCacheGETResponse = function (saleTypes) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (saleTypes || []).forEach(function (val) { if (val != null)
            __params = __params.append('saleTypes', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Search/GetOpenOpportunities/FlushCache", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param saleTypes undefined
     */
    SearchClientService.prototype.GetOpenOpportunitiesFlushCacheGET = function (saleTypes) {
        return this.GetOpenOpportunitiesFlushCacheGETResponse(saleTypes).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    SearchClientService.prototype.GetEntitiesListCustomPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Search/GetEntitiesListCustom", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    SearchClientService.prototype.GetEntitiesListCustomPOST = function (request) {
        return this.GetEntitiesListCustomPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    SearchClientService.prototype.GetOneToOneEntitiesPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Search/GetOneToOneEntities", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    SearchClientService.prototype.GetOneToOneEntitiesPOST = function (request) {
        return this.GetOneToOneEntitiesPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `SearchClientService.GetFreshOpportunitiesGETParams` containing the following parameters:
     *
     * - `flushCache`:
     *
     * - `MaxResults`:
     */
    SearchClientService.prototype.GetFreshOpportunitiesGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.flushCache != null)
            __params = __params.set('flushCache', params.flushCache.toString());
        if (params.MaxResults != null)
            __params = __params.set('MaxResults', params.MaxResults.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Search/GetFreshOpportunities", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `SearchClientService.GetFreshOpportunitiesGETParams` containing the following parameters:
     *
     * - `flushCache`:
     *
     * - `MaxResults`:
     */
    SearchClientService.prototype.GetFreshOpportunitiesGET = function (params) {
        return this.GetFreshOpportunitiesGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param MaxResults undefined
     */
    SearchClientService.prototype.GetFreshOpportunitiesFlushCacheGETResponse = function (MaxResults) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (MaxResults != null)
            __params = __params.set('MaxResults', MaxResults.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Search/GetFreshOpportunities/FlushCache", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param MaxResults undefined
     */
    SearchClientService.prototype.GetFreshOpportunitiesFlushCacheGET = function (MaxResults) {
        return this.GetFreshOpportunitiesFlushCacheGETResponse(MaxResults).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `SearchClientService.GetRecentlyAssignedOpportunitiesGETParams` containing the following parameters:
     *
     * - `flushCache`:
     *
     * - `MaxResults`:
     */
    SearchClientService.prototype.GetRecentlyAssignedOpportunitiesGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (params.flushCache != null)
            __params = __params.set('flushCache', params.flushCache.toString());
        if (params.MaxResults != null)
            __params = __params.set('MaxResults', params.MaxResults.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Search/GetRecentlyAssignedOpportunities", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `SearchClientService.GetRecentlyAssignedOpportunitiesGETParams` containing the following parameters:
     *
     * - `flushCache`:
     *
     * - `MaxResults`:
     */
    SearchClientService.prototype.GetRecentlyAssignedOpportunitiesGET = function (params) {
        return this.GetRecentlyAssignedOpportunitiesGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param MaxResults undefined
     */
    SearchClientService.prototype.GetRecentlyAssignedOpportunitiesFlushCacheGETResponse = function (MaxResults) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (MaxResults != null)
            __params = __params.set('MaxResults', MaxResults.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Search/GetRecentlyAssignedOpportunities/FlushCache", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param MaxResults undefined
     */
    SearchClientService.prototype.GetRecentlyAssignedOpportunitiesFlushCacheGET = function (MaxResults) {
        return this.GetRecentlyAssignedOpportunitiesFlushCacheGETResponse(MaxResults).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    SearchClientService.prototype.GetSoldBySearchByDealersByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Search/GetSoldBySearchByDealers/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    SearchClientService.prototype.GetSoldBySearchByDealersByDealeridGET = function (dealerId) {
        return this.GetSoldBySearchByDealersByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    SearchClientService.prototype.GetPrivateOfferInfoByDealeridByEntityidGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Search/GetPrivateOfferInfo/" + params.dealerId + "/" + params.entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETParams` containing the following parameters:
     *
     * - `entityId`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    SearchClientService.prototype.GetPrivateOfferInfoByDealeridByEntityidGET = function (params) {
        return this.GetPrivateOfferInfoByDealeridByEntityidGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    SearchClientService.prototype.SaveSearchPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Search/SaveSearch", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    SearchClientService.prototype.SaveSearchPOST = function (request) {
        return this.SaveSearchPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    SearchClientService.prototype.CreateDealerCampaignExportAudiencePOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Search/CreateDealerCampaignExportAudience", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    SearchClientService.prototype.CreateDealerCampaignExportAudiencePOST = function (request) {
        return this.CreateDealerCampaignExportAudiencePOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param days undefined
     * @return Success
     */
    SearchClientService.prototype.GetDealerCampaignRequestsInWindowAsyncByDaysGETResponse = function (days) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Search/GetDealerCampaignRequestsInWindow/" + days), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param days undefined
     * @return Success
     */
    SearchClientService.prototype.GetDealerCampaignRequestsInWindowAsyncByDaysGET = function (days) {
        return this.GetDealerCampaignRequestsInWindowAsyncByDaysGETResponse(days).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    SearchClientService.prototype.GetNoLongerOwnForDealerByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Search/GetNoLongerOwnForDealer/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    SearchClientService.prototype.GetNoLongerOwnForDealerByDealeridGET = function (dealerId) {
        return this.GetNoLongerOwnForDealerByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    SearchClientService.GetSearchByEntityIdByEntityidGETPath = '/api/Search/GetSearchByEntityId/{entityId}';
    SearchClientService.GetOpenOpportunitiesGETPath = '/api/Search/GetOpenOpportunities';
    SearchClientService.GetOpenOpportunitiesFlushCacheGETPath = '/api/Search/GetOpenOpportunities/FlushCache';
    SearchClientService.GetEntitiesListCustomPOSTPath = '/api/Search/GetEntitiesListCustom';
    SearchClientService.GetOneToOneEntitiesPOSTPath = '/api/Search/GetOneToOneEntities';
    SearchClientService.GetFreshOpportunitiesGETPath = '/api/Search/GetFreshOpportunities';
    SearchClientService.GetFreshOpportunitiesFlushCacheGETPath = '/api/Search/GetFreshOpportunities/FlushCache';
    SearchClientService.GetRecentlyAssignedOpportunitiesGETPath = '/api/Search/GetRecentlyAssignedOpportunities';
    SearchClientService.GetRecentlyAssignedOpportunitiesFlushCacheGETPath = '/api/Search/GetRecentlyAssignedOpportunities/FlushCache';
    SearchClientService.GetSoldBySearchByDealersByDealeridGETPath = '/api/Search/GetSoldBySearchByDealers/{dealerId}';
    SearchClientService.GetPrivateOfferInfoByDealeridByEntityidGETPath = '/api/Search/GetPrivateOfferInfo/{dealerId}/{entityId}';
    SearchClientService.SaveSearchPOSTPath = '/api/Search/SaveSearch';
    SearchClientService.CreateDealerCampaignExportAudiencePOSTPath = '/api/Search/CreateDealerCampaignExportAudience';
    SearchClientService.GetDealerCampaignRequestsInWindowAsyncByDaysGETPath = '/api/Search/GetDealerCampaignRequestsInWindow/{days}';
    SearchClientService.GetNoLongerOwnForDealerByDealeridGETPath = '/api/Search/GetNoLongerOwnForDealer/{dealerId}';
    SearchClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchClientService_Factory() { return new SearchClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: SearchClientService, providedIn: "root" });
    return SearchClientService;
}(base_service_1.BaseService));
exports.SearchClientService = SearchClientService;
