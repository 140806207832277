    
<button #anchor (click)="onToggle()" class="calendar-anchor-button qa-calendar-button">{{ defaultStartDate | kendoDate: manualForcedDateFormat}} - {{ defaultEndDate | kendoDate : manualForcedDateFormat }}</button>
    <kendo-popup class="wrapper kendo-angularjs" [anchor]="anchor" [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }" [popupAlign]="{ horizontal: 'right', vertical: 'top' }"  *ngIf="show">
        <div class='calendar-date-range-popup'>
            <div class="row">
                <div class="col-md-10">
                    <kendo-multiviewcalendar 
                        kendoDateRangeSelection 
                        [selectionRange]="range" 
                        (selectionRangeChange)="handleSelectionRange($event)" 
                        [activeRangeEnd]="activeRangeEnd"
                        [min]="minStartDate">
                        <ng-template kendoCalendarMonthCellTemplate let-date>
                            <div (touchend)="dateSelected(date)">
                                <span [ngClass]="isFutureDate(date)">{{date.getDate()}}</span>
                            </div>
                        </ng-template>
                    </kendo-multiviewcalendar>
                    <div class="calendar-row">
                        <div class="col-md-1"></div>
                        <div class="col-md-2"><button class="btn btn-sm btn-primary qa-report-date-cancel" (click)="onCancel()">Cancel</button></div>
                        <div class="col-md-3"><kendo-dateinput [format]= "manualForcedDateFormat"  [class.selected]="isDurationError || isFromDtGreaterThanToDt" [(ngModel)]="range.start" [min]="minStartDate" [max]="maxEndDate" #dateStartModel="ngModel" (valueChange)="onStartChange($event)"></kendo-dateinput></div>
                        <div class="col-md-1"><span class="calendar-horizontal-line"></span></div>
                        <div class="col-md-3"><kendo-dateinput [format]= "manualForcedDateFormat" [class.selected]="isDurationError || isFromDtGreaterThanToDt" [(ngModel)]="range.end"  [min]="minStartDate" [max]="maxEndDate" #dateEndModel="ngModel" (valueChange)="onEndChange($event)"></kendo-dateinput></div>
                        <div class="col-md-2"><button  [disabled]="dateEndModel.errors || dateStartModel.errors || isDurationError || isDurationNullError || isFromDtGreaterThanToDt"  class="btn btn-sm btn-primary qa-report-date-update" (click)="onUpdate()" >Update</button></div>
                    </div>
                    <div class="calendar-row">
                        <div class="col-md-12 error" *ngIf="isDurationError" translate>{{'maxDateRange'}}</div>
                        <div class="col-md-12 error" *ngIf="isFromDtGreaterThanToDt" translate>{{'fromDtGreaterThanToDt'}}</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="btn-group">
                        <div *ngFor="let dateRangeType of dateRangeTypes">
                            <ng-container *ngIf="dateRangeType != 'UI'">
                                <a class="btn qa-report-date-range-button" [class.selected]="dateRangeType == this.currentPreselect" href="#" (click)="onPresetSelect(dateRangeType)" translate> {{dateRangeType == 'D' ? 'yesterday' : dateRangeType == 'UI' ? '' : dateRangeType }}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </kendo-popup>


