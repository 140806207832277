import { Injectable } from '@angular/core';
import { DealerRatesClientService, DealerRebateClientService, DealerPriceClientService, DealerTradeinClientService, ReplacementClientService, TrimAlternateOfferClientService } from '../generated/services';
import { StrictHttpResponse } from '../generated/strict-http-response';
import { DealerRateUsedDto, DealerOverAllowanceUpdateDto, DealerRateMarkUpExtDto, DealerOverAllowanceExtDto, DealerRebateExtDto, DealerPriceExtDto, DealerPriceDto, DealerTradeinExtDto, ReplacementSpecificDto, TrimAlternateOfferDto, ReplacementDto } from '../generated/models';

@Injectable({
    providedIn: 'root'
})
export class DataEntryService {

    constructor(private dealerRatesClientService: DealerRatesClientService,
        private dealerRebateClientService: DealerRebateClientService,
        private dealerPriceClientService: DealerPriceClientService,
        private dealerTradeInClientService: DealerTradeinClientService,
        private replacementClientService: ReplacementClientService,
        private trimAlternateOfferClientService: TrimAlternateOfferClientService) { }

    public deleteAllUsedRatesByDealerId(dealerId: number): Promise<boolean> {
        return this.dealerRatesClientService.DeleteAllUsedRatesByDealerIdPOSTResponse(dealerId).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    }

    public getDealerUsedRates(dealerId: number): Promise<DealerRateUsedDto[]> {
        return this.dealerRatesClientService.GetDealerUsedRatesByDealeridGET(dealerId).toPromise();
    }

    public updateDealerUsedRates(usedRates: DealerRateUsedDto[]): Promise<boolean> {
        return this.dealerRatesClientService.UpdateDealerUsedRatesPOSTResponse(usedRates).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    }


    public updateDealerOverAllowance(overAllowance: DealerOverAllowanceUpdateDto[]): Promise<boolean> {
        return this.dealerRatesClientService.UpdateDealerOverAllowancePOSTResponse(overAllowance).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    }

    public updateDealerRateMarkUps(rates: DealerRateMarkUpExtDto[]): Promise<boolean> {
        return this.dealerRatesClientService.UpdateDealerRateMarkUpsPOSTResponse(rates).toPromise()
            .then(this.resolveDealerDataEntryUpdate);
    }

    public getDealerOverAllowances(dealerId: number): Promise<DealerOverAllowanceExtDto[]> {
        return this.dealerRatesClientService.GetDealerOverAllowancesByDealeridGET(dealerId).toPromise();
    }

    public getRateMarkUps(dealerId: number): Promise<DealerRateMarkUpExtDto[]> {
        return this.dealerRatesClientService.GetDealerRateMarkUpsByDealeridGET(dealerId).toPromise();
    }

    public getDealerRebates(dealerId: number, makeId: number, year: number, modelId: number, trimId: number): Promise<DealerRebateExtDto[]> {
        const params = { dealerId, makeId, year, modelId, trimId };
        return this.dealerRebateClientService.GetDealerRebatesByDealeridByMakeidByYearByModelidByTrimidGET(params).toPromise();
    }

    public updateDealerRebates(rebates: DealerRebateExtDto[]): Promise<null> {
        return this.dealerRebateClientService.UpdateDealerRebatesPATCH(rebates).toPromise();
    }

    public getDealerPrices(dealerId: number, makeId: number, year: number, modelId: number, trimId: number): Promise<DealerPriceExtDto[]> {
        const params = { dealerId, makeId, year, modelId, trimId };
        return this.dealerPriceClientService.GetDealerPricesByDealeridByMakeidByYearByModelidByTrimidGET(params).toPromise();
    }

    public updateDealerPrices(prices: DealerPriceDto[]): Promise<DealerPriceDto[]> {
        return this.dealerPriceClientService.UpdateDealerPricesPATCH(prices).toPromise();        
    }

    private resolveDealerDataEntryUpdate(response: StrictHttpResponse<any>): boolean {
        return response.status == 204;
    }

    // Dealer Trade Ins
    public updateDealerTradeins(tradeIns: DealerTradeinExtDto[]): Promise<DealerTradeinExtDto[]> {
        return this.dealerTradeInClientService.UpdateDealerTradeinsPATCH(tradeIns).toPromise();        
    }

    public getDealerTradeIns(dealerId: number, makeId: number, yearId: number, modelId: number, trimId: number): Promise<DealerTradeinExtDto[]> {
        const params = { dealerId, makeId, yearId, modelId, trimId };
        return this.dealerTradeInClientService.GetDealerTradeinsByDealeridByMakeidByYearidByModelidByTrimidGET(params).toPromise();
    }

    public getReplacementSpecifics(dealerId: number, filterByNew, makeId: number, yearId: number, modelId: number, trimId: number): Promise<ReplacementSpecificDto[]> {
        if (modelId == 0) modelId = null;
        if (trimId == 0) trimId = null;

        const params = { DealerId: dealerId, FilterByNew: filterByNew, MakeId: makeId, YearId: yearId, ModelId: modelId, TrimId: trimId };
        return this.replacementClientService.GetReplacementSpecificsGET(params).toPromise();
    }

    public getReplacementUpDown(dealerId: number, makeId: number, yearId: number, modelId: number, trimId: number) {
        if (modelId == 0) modelId = null;
        if (trimId == 0) trimId = null;

        const params = { DealerId: dealerId, MakeId: makeId, YearId: yearId, ModelId: modelId, TrimId: trimId };
        return this.replacementClientService.GetReplacementUpDownGET(params).toPromise();
    }

    public deleteTrimAlternateOffer(dealerID: number, baseTrimID: number, level: number): Promise<null> {
        const params = {dealerID, baseTrimID, level};
        return this.trimAlternateOfferClientService.ByDealeridByBasetrimidByLevelDELETE(params).toPromise();
    }

    public updateTrimAlternateOffer(dealerId: number, baseTrimId: number, level: number, alternateTrimID: number, retailTolerance: number, leaseTolerance: number): Promise<null> {
        const trimAlertnateOfferDto: TrimAlternateOfferDto = {
            dealerID: dealerId,
            baseTrimID: baseTrimId,
            level: level,
            alternateTrimID: alternateTrimID,
            retailTolerance: retailTolerance,
            leaseTolerance: leaseTolerance
        };
        return this.trimAlternateOfferClientService.PUT(trimAlertnateOfferDto).toPromise();
    }

    public createTrimAlternateOffer(dealerId: number, baseTrimId: number, level: number, alternateTrimID: number, retailTolerance: number, leaseTolerance: number): Promise<TrimAlternateOfferDto> {
        const trimAlertnateOfferDto: TrimAlternateOfferDto = {
            dealerID: dealerId,
            baseTrimID: baseTrimId,
            level: level,
            alternateTrimID: alternateTrimID,
            retailTolerance: retailTolerance,
            leaseTolerance: leaseTolerance
        };

        return this.trimAlternateOfferClientService.POST(trimAlertnateOfferDto).toPromise();        
    }

    public saveBulkReplacements(request: ReplacementDto[], dealerId: number): Promise<ReplacementDto[]> {

        const params: ReplacementClientService.SaveBulkReplacementsByDefaultdealeridPOSTParams = {
            defaultDealerId: dealerId,
            request: request
        };

        return this.replacementClientService.SaveBulkReplacementsByDefaultdealeridPOST(params).toPromise();
    }

    public bulkDelete(trimIds: number[], dealerID: number, isUpgrade: boolean): Promise<null> {
        var level = isUpgrade ? 100 : -100;

        const params: TrimAlternateOfferClientService.BulkDeletePOSTParams = {
            dealerID: dealerID,
            level: level,
            trimIDs: trimIds
        };

        return this.trimAlternateOfferClientService.BulkDeletePOST(params).toPromise();
    }

    public bulkReplacementDelete(trimIds: number[], dealerID: number): Promise<null> {
        const params: ReplacementClientService.BulkDeletePOSTParams = {
            dealerID: dealerID,
            trimIDs: trimIds
        };

        return this.replacementClientService.BulkDeletePOST(params).toPromise();
    }


    public bulkRemoveMappings(trimIds: number[], dealerID: number, isUpgrade: boolean): Promise<null> {
        var level = isUpgrade ? 100 : -100;
        
        const params: TrimAlternateOfferClientService.BulkDeletePOSTParams = {
            dealerID: dealerID,
            level: level,
            trimIDs: trimIds
        };

        return this.trimAlternateOfferClientService.BulkRemoveMappingsPOST(params).toPromise();
    }
}
