
/* injects from baggage-loader */
require('./warranty-actions.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('warrantyActions', {
    templateUrl: '/app/components/deal-sheet/contract/warranty-actions.component.html',
    controller: WarrantyActionsController,
    bindings: {
        opportunity: '<',
        //vehicle: '<',
        //permissions: '<',
        renderAsButton: '<',
        refreshOpportunity: '&',
        startWorkingState: '&',
        stopWorkingState: '&'
    }
});

function WarrantyActionsController(mapUtils, vehicleService, activityService, activityRule, entityActionAccessEnum) {
    "ngInject";
    var $ctrl = this;
    $ctrl.$onChanges = onChanges;
    $ctrl.onHasWarranty = onHasWarranty;
    $ctrl.onDoesntHaveWarranty = onDoesntHaveWarranty;
    $ctrl.onSoldWarranty = onSoldWarranty;
    $ctrl.onUndoSoldWarranty = onUndoSoldWarranty;

    $ctrl.showHasWarranty = false;
    $ctrl.showDoesntHaveWarranty = false;
    $ctrl.showSoldWarranty = false;
    $ctrl.showUndoSoldWarranty = false;

    function onChanges(changes) {
        if (changes.opportunity && changes.opportunity.currentValue) {

            $ctrl.showHasWarranty = mapUtils.hasActionAccess(changes.opportunity.currentValue.actionAccess, entityActionAccessEnum.showHasWarranty);
            $ctrl.showSoldWarranty = mapUtils.hasActionAccess(changes.opportunity.currentValue.actionAccess, entityActionAccessEnum.showSoldWarranty);
            $ctrl.showDoesntHaveWarranty = mapUtils.hasActionAccess(changes.opportunity.currentValue.actionAccess, entityActionAccessEnum.showDoesntHaveWarranty);
            $ctrl.showUndoSoldWarranty = mapUtils.hasActionAccess(changes.opportunity.currentValue.actionAccess, entityActionAccessEnum.showUndoSoldWarranty);
        }
    }

    function onHasWarranty() {
        $ctrl.startWorkingState();
        activityService.updateWarrantyStatus($ctrl.opportunity.id).then(
            function () {
                $ctrl.refreshOpportunity();
            },
            function () {
                throw { message: "We encountered an error processing your request." };
            });
    }

    function onDoesntHaveWarranty() {
        $ctrl.startWorkingState();
        activityService.runActivityRule($ctrl.opportunity.id, [activityRule.Unmark_as_Has_Warranty]).then(
            function () {
                $ctrl.refreshOpportunity();
            },
            function () {
                throw { message: "We encountered an error processing your request." };
            });
    }

    function onSoldWarranty() {
        $ctrl.startWorkingState();
        activityService.updateSoldWarranty($ctrl.opportunity.id).then(
            function () {
                $ctrl.refreshOpportunity();
            },
            function () {
                throw { message: "We encountered an error processing your request." };
            });
    }

    function onUndoSoldWarranty() {
        $ctrl.startWorkingState();
        activityService.runActivityRule($ctrl.opportunity.id, [activityRule.Unmark_as_Has_Warranty, activityRule.Unmark_as_Warranty_Sold]).then(
            function () {
                $ctrl.refreshOpportunity();
            },
            function (error) {
                throw { message: "We encountered an error processing your request." };
            });
    }
}
