
/* injects from baggage-loader */
require('./update-mileage.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('updateMileage', {
    templateUrl: '/app/components/deal-sheet/modals/update-mileage.component.html',
    controller: UpdateMileageController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function UpdateMileageController($translate, $translatePartialLoader) {
    var $ctrl = this;

    $ctrl.dateModel;
    $ctrl.submitClick = submitClick;
    $ctrl.cancelClick = cancelClick;
    $ctrl.calendarClick = calendarClick;
    $ctrl.setDate = setDate;

    initModel();

    function initModel() {
        Date.prototype.addDays = function (days) {
            var dat = new Date(this.valueOf());
            dat.setDate(dat.getDate() + days);
            return dat;
        }
        var date = new Date();
        $ctrl.status = { opened: false };
        $ctrl.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 0,
            showWeeks: false
        };
        $ctrl.minDate = date;
        
        var dueDate = date.addDays(5 / (24 * 60));// add 5 min to current time
        $ctrl.model = {
            date: dueDate,
            time: new Date(1, 1, 2000, dueDate.getHours(), dueDate.getMinutes()),
            mileage: ''
        }
    }

    function calendarClick($event) {
        $ctrl.status.opened = true;
    }

    function cancelClick() {
        $ctrl.dismiss({ $value: 'cancel' });
    }

    function submitClick() {
        $ctrl.close({ $value: { mileage: $ctrl.model.mileage, date: $ctrl.model.date } });
    }

    function setDate(date, model) {
        $ctrl.model.date = date;
        $ctrl.dateModel = model;

        $ctrl.mileageEditForm.$setValidity('custom', $ctrl.dateModel.valid)
    }
}

