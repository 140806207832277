import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { ActivityActionEnum, StorageKeys, TranslateKeys } from '../../../../constants';
import { EntityUserInfoDto } from '../../../../generated/models';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { UserProfile } from '../../../../interfaces/user-profile.interface';
import { StorageService } from '../../../../modules/storage/storage.service';
import { DateFormatService } from '../../../../services/date-format.service';
import { UserService } from '../../../../services/user.service';
import { ScheduleModalParam } from '../ScheduleModalParam.interface';
import moment = require('moment');

interface ScheduleType {
    scheduleCall: Schedule;
    scheduleAppt: Schedule;
    rescheduleCall: Schedule;
    rescheduleAppt: Schedule;
    reassignCall: Schedule;
    reassignAppt: Schedule;
}

interface Schedule {
    activityId: number;
    description: string;
    IsAssignToVisible: boolean;
    IsDateVisible: boolean;
    IsTimeVisible: boolean;
    IsReminderVisible: boolean;
}

@Component({
    selector: 'customer-connect-schedule-action',
    templateUrl: './customer-connect-schedule-action.component-ng.html',
    styleUrls: ['./customer-connect-schedule-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerConnectScheduleActionComponent implements OnInit, OnChanges {

    @Input() subAction: number;
    @Input() permissions: DealSheetPermissions;
    @Input() dialogParams: ScheduleModalParam;
    @Output() onLogScheduleSubmit = new EventEmitter<any>();

    public activityActionEnum = ActivityActionEnum;
    public isSltUser: boolean;
    public scheduleDueDateModel: any;
    public dateFormat: string;
    public editedActivityAssignToUser = null;
    public currentLoginUserProfile: UserProfile;
    public validatedAssignee = {
        isValid: false,
        index: -1
    };

    ownerId = null;
    owner = null;
    userList = [];
    activity = null;

    // owner info
    soldById = null;
    soldByName = null;
    soldByStatus = null;

    // setup time picker
    mytime = new Date();
    hstep = 1;
    mstep = 5;
    ismeridian: boolean;

    status = { opened: false };

    model = {
        scheduleDueDate: new Date(),
        time: new Date(),
        notes: '',
        user: -1,
        sendReminder: false,
        userChoice: 'other'
    };

    opportunityId: string;
    dealerId: number;
    scheduleTypeAction = this.subAction; // dialog type
    maxFutureDays: number;
    ownerUserInfo: EntityUserInfoDto;

    dateOptions = {};

    errorMessage: string;

    // setup ui display object type for custom dialogbox
    public scheduleType = {
        scheduleCall: {
            activityId: this.activityActionEnum.Schedule_Call,
            description: TranslateKeys.scheduleTitleCallKey,
            IsAssignToVisible: true,
            IsDateVisible: true,
            IsTimeVisible: true,
            IsReminderVisible: true
        } as Schedule,
        scheduleAppt: {
            activityId: ActivityActionEnum.Schedule_Appt,
            description: TranslateKeys.scheduleTitleApptKey,
            IsAssignToVisible: true,
            IsDateVisible: true,
            IsTimeVisible: true,
            IsReminderVisible: true
        } as Schedule,
        rescheduleCall: {
            activityId: ActivityActionEnum.Reschedule_Call,
            description: TranslateKeys.rescheduleTitleCallKey,
            IsAssignToVisible: false,
            IsDateVisible: true,
            IsTimeVisible: true,
            IsReminderVisible: true
        } as Schedule,
        rescheduleAppt: {
            activityId: ActivityActionEnum.Reschedule_Appt,
            description: TranslateKeys.rescheduleTitleApptKey,
            IsAssignToVisible: false,
            IsDateVisible: true,
            IsTimeVisible: true,
            IsReminderVisible: true
        } as Schedule,
        reassignCall: {
            activityId: ActivityActionEnum.Reassign_Call,
            description: TranslateKeys.reassignTitleCallKey,
            IsAssignToVisible: true,
            IsDateVisible: false,
            IsTimeVisible: false,
            IsReminderVisible: false
        },
        reassignAppt: {
            activityId: ActivityActionEnum.Reassign_Appt,
            description: TranslateKeys.reassignTitleApptKey,
            IsAssignToVisible: true,
            IsDateVisible: false,
            IsTimeVisible: false,
            IsReminderVisible: false
        } as Schedule
    } as ScheduleType;

    currentScheduleType: Schedule;

    constructor(dateFormatService: DateFormatService,
        private storageService: StorageService,
        private userService: UserService) {
        this.dateFormat = dateFormatService.getDateFormatString();
    }

    ngOnInit() {
        this.initModels();
        this.currentLoginUserProfile = this.storageService.getItem(StorageKeys.UserProfile);
    }

    initModels() {
        this.editedActivityAssignToUser = null;
        this.currentLoginUserProfile = this.storageService.getItem(StorageKeys.UserProfile);
        this.validatedAssignee = {
            isValid: false,
            index: -1
        };

        const savedCultureName = this.storageService.getItem(StorageKeys.CultureName);
        let cultureName = savedCultureName ? savedCultureName : 'en-us';
        cultureName = cultureName.toLowerCase();

        // get's passed via resolve
        this.ownerId = null;
        this.owner = null;
        this.userList = [];
        this.activity = null;

        // owner info
        this.soldById = null;
        this.soldByName = null;
        this.soldByStatus = null;

        // setup time picker
        this.mytime = new Date();
        this.hstep = 1;
        this.mstep = 5;
        this.ismeridian = (cultureName === 'fr-fr') ? false : true;

        this.status = { opened: false };

        const now = new Date();

        const dueDate = this.dateAddDays(now, (5 / (24 * 60))); // add 5 min to current time

        this.model = {
            scheduleDueDate: dueDate,
            time: dueDate, // new Date(1, 1, 2000, dueDate.getHours(), dueDate.getMinutes()),
            notes: '',
            user: -1,
            sendReminder: false,
            userChoice: 'other'
        };
    }

    dateAddDays(date: Date, daysToAdd: number): Date {
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + daysToAdd);
        return newDate;
    }

    // Disable weekend selection
    disabled(data) {
        const date = data.date,
            mode = data.mode;
        return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    calendarClick($event) {
        this.status.opened = true;
    }

    ngOnChanges() {
        if (this.subAction && this.dialogParams) {
            if (this.isSltUser == null) {
                this.userService.getUserProfile()
                    .then(userProfile => {
                        this.isSltUser = userProfile.isSltUser;
                        this.updateModel();
                    });
            } else {
                this.updateModel();
            }
        }
    }

    updateModel() {

        this.opportunityId = this.dialogParams.opportunityId;
        this.dealerId = this.dialogParams.dealerId;
        this.scheduleTypeAction = this.subAction; // dialog type
        this.maxFutureDays = this.dialogParams.maxScheduleFutureDays;
        this.ownerUserInfo = this.dialogParams.ownerUserInfoObject;

        const now = new Date();
        let maxDate: Date;

        if (this.isSltUser) {
            const maxSltScheduleDays = 30;
            maxDate = this.dialogParams.expirationDate ?
                new Date(this.dialogParams.expirationDate) :
                moment().add(maxSltScheduleDays, 'days').toDate();
        } else {
            maxDate = this.dateAddDays(now, this.maxFutureDays);
        }

        this.dateOptions = {
            minDate: now,
            maxDate: maxDate
        };

        if (this.dialogParams.usersObject && this.dialogParams.usersObject.length) {
            if (this.userList.length > 0) { this.userList = []; }
            for (let i = 0; i < this.dialogParams.usersObject.length; i++) {
                // Must create a new array and not reference the binding
                // as it can change the parent array. filter and add only user's with type id 1 that is client
                if (this.dialogParams.usersObject[i].userTypeId === 1) {
                    this.userList.push(this.dialogParams.usersObject[i]);
                }
            }
            // sort it to ascending order by fullName
            this.userList.sort(this.dynamicSort('fullName'));
        }

        // get passed owner id
        this.ownerId = this.dialogParams.dealSheetOwnerId;

        // if activity is passed from parent
        if (this.dialogParams.activityObject) {
            this.activity = this.dialogParams.activityObject;
        }

        this.setupScheduleDialogUX();
    }

    setupScheduleDialogUX() {

        switch (this.scheduleTypeAction) {
            case ActivityActionEnum.Schedule_Call:
                // setup dialogUX
                this.currentScheduleType = this.scheduleType.scheduleCall;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.model.user = -1;
                break;
            case ActivityActionEnum.Schedule_Appt:
                this.currentScheduleType = this.scheduleType.scheduleAppt;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.model.user = -1;
                break;
            case ActivityActionEnum.Reschedule_Call:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.rescheduleCall;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                } else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
            case ActivityActionEnum.Reschedule_Appt:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.rescheduleAppt;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                } else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
            case ActivityActionEnum.Reassign_Call:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.reassignCall;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                } else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
            case ActivityActionEnum.Reassign_Appt:
                // activity is passed from parent
                this.currentScheduleType = this.scheduleType.reassignAppt;
                this.ownerId = this.getValidOwnerId(this.ownerId);
                this.owner = this.getValidatedAssignToUserObject(this.ownerId, this.userList);
                this.editedActivityAssignToUser = this.getValidatedAssignToUserObject(this.activity.assignedUserID, this.userList);
                if (!this.editedActivityAssignToUser.isValid) {
                    this.model.user = -1;
                } else {
                    this.model.user = this.editedActivityAssignToUser.id;
                }
                break;
        }

        //        this.dialogTitle = this.currentScheduleType.description;
        this.getAssignToSelectedState();
    }

    // based on rules of WEBUI-4465 ownerId is replaced with soldByid
    getValidOwnerId(currentOwnerId) {
        currentOwnerId = this.getValidatedAssignToUserObject(currentOwnerId, this.userList).id;
        let userId = -1;

        if (this.ownerUserInfo) {

            if (currentOwnerId === -1 && this.ownerUserInfo.soldByUserId == null && this.ownerUserInfo.soldByUserFullName == null) {
                return -1;
            }

            if (currentOwnerId === -1 && this.ownerUserInfo.soldByUserId == null && this.ownerUserInfo.soldByUserFullName != null) {
                userId = this.getUserIdbyName(this.ownerUserInfo.soldByUserFullName, this.userList);
                return userId;
            }

            if (currentOwnerId === -1 && this.ownerUserInfo.soldByUserId != null) {
                return this.ownerUserInfo.soldByUserId;
            }

            if (currentOwnerId !== -1 && this.ownerUserInfo.ownerUserEnabled === false && this.ownerUserInfo.soldByUserId != null) {
                return this.ownerUserInfo.soldByUserId;
            }
        }

        return currentOwnerId;
    }

    getUserIdbyName(userName: string, userList): number {
        for (let i = 0; i < userList.length; i++) {
            if (userList[i].fullName.toLowerCase() === userName.toLowerCase()) {
                return userList[i].id;
            }
        }
        return -1;
    }

    getValidatedAssignToUserObject(userID, userList): any {
        const resultObj = {
            isValid: false,
            index: -1,
            fullName: null,
            id: -1
        };

        for (let i = 0; i < userList.length; i++) {
            if (userList[i].id === userID) {
                resultObj.isValid = true;
                resultObj.index = i;
                resultObj.fullName = userList[i].fullName;
                resultObj.id = userList[i].id;
                return resultObj;
            }
        }
        return resultObj;
    }

    getAssignToSelectedState() {

        if (this.currentLoginUserProfile && !this.currentLoginUserProfile.employeeUser) {
            this.model.userChoice = 'self';
            if (this.currentLoginUserProfile.userId === this.owner.id) {
                this.owner.isValid = false;
            }

            return;
        }

        if (this.owner && this.owner.isValid) {
            this.model.userChoice = 'owner';
            return;
        }

        this.model.userChoice = 'other';
        return;
    }

    // sort a list object based on property
    dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    }

    onSubmit() {
        this.postActivity();
    }

    postActivity() {
        let assignToUserId = -1;
        switch (this.model.userChoice) {
            case 'self':
                assignToUserId = this.currentLoginUserProfile.userId;
                break;
            case 'owner':
                assignToUserId = this.owner.id;
                break;
            case 'other':
                assignToUserId = this.model.user;
                break;
        }

        if (!assignToUserId) {
            this.errorMessage = TranslateKeys.scheduleAssignToErrorMsgKey;
            this.onLogScheduleSubmit.emit(null);
            return;
        }

        if (assignToUserId === -1) {
            this.errorMessage = TranslateKeys.scheduleAssignToErrorMsgKey;
            this.onLogScheduleSubmit.emit(null);
            return;
        }


        const dateTime = new Date(this.model.scheduleDueDate.getFullYear(), this.model.scheduleDueDate.getMonth(), this.model.scheduleDueDate.getDate(),
            this.model.time.getHours(), this.model.time.getMinutes(), this.model.time.getSeconds());

        let recordId = null;
        if (this.activity) {
            if (this.currentScheduleType.activityId !== ActivityActionEnum.Schedule_Call &&
                this.currentScheduleType.activityId !== ActivityActionEnum.Schedule_Appt) {
                recordId = this.activity.activityId;
            }
        }

        const activity = {
            entityID: this.opportunityId,
            activityActionID: this.currentScheduleType.activityId,
            notes: this.model.notes,
            assignTo: assignToUserId, // this.model.user,
            dueDate: moment(dateTime).format('YYYY-MM-DD[T]HH:mm:ss'), // in this case, we need to send the local time to the DB   - Brad O. 4/23/2018
            sendReminder: this.model.sendReminder,
            activityId: recordId,
            activityActionSubTypeID: null,
            isFollowUpScheduleAppt: false,
            uid: null
        };

        if (!activity.notes) {
            activity.notes = '';
        }

        if (this.dialogParams.uid) {
            activity.uid = this.dialogParams.uid;
        }

        // send activity to dealsheet
        this.onLogScheduleSubmit.emit(activity);
        this.initModels();
    }

    onCancel() {

        if (this.dialogParams.uid) {
            this.onLogScheduleSubmit.emit({ uid: this.dialogParams.uid, status: 'cancel' });
        } else {
            this.onLogScheduleSubmit.emit('cancel');
        }

        this.initModels();
    }

    setScheduleDueDate(date, model) {
        this.model.scheduleDueDate = date;
        this.scheduleDueDateModel = model;
    }

    usersTrackBy(index, obj) {
        if (!obj) { return null; }
        return obj.id;
    }

}
