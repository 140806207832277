import angular from 'angular';

angular.module('AaApp.Opp').constant('activityActionNameTypes', {
    postNote: 1,
    logCall: 2,
    scheduleCall: 3,
    scheduleAppt: 4,
    expirePhoneCall: 7,
    expireAppointment: 8,
    emailSent: 9,
    reschedule: 10,
    reassign: 12,
    cancel: 14,
    pushToCompass: 16,
    emailTemplateSent: 17,
    manualPushToCRM: 18,
    pandoProcessClaimed: 19,
    pandoProcessCompleted: 20
});
