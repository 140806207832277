"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var authorization_service_1 = require("../../../../services/authorization.service");
var MoreVehicleOffersComponent = /** @class */ (function () {
    function MoreVehicleOffersComponent(authorizationService) {
        this.authorizationService = authorizationService;
        // define eventOUT signature
        this.select = new core_1.EventEmitter();
        this.vehicleImageNotAvailableUrl = require('../../../../images/vehicle-image-not-available.png');
        this.selectedVehicle = { selectedVehicleType: 'standardVehicle', alternateVehicleIndex: 0 };
    }
    MoreVehicleOffersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedVehicleObject = this.standardVehicle;
        this.authorizationService.getDealSheetPermissions()
            .then(function (dealSheetPermissions) {
            _this.hasPaymentsPermission = dealSheetPermissions.payments;
        });
        if (this.replacementContract) {
            this.overrideShowPayment = true;
            this.standardVehicle.existingPayment = 0;
            this.standardVehicle.payment = this.replacementContract.paymentDifference;
        }
        else {
            this.overrideShowPayment = false;
        }
    };
    MoreVehicleOffersComponent.prototype.clickHandler = function (chosenVehicleType, alternateVehicleIndex, vehicle) {
        this.selectedVehicle = { selectedVehicleType: chosenVehicleType, alternateVehicleIndex: alternateVehicleIndex };
        this.select.emit(this.selectedVehicle);
        this.selectedVehicleObject = vehicle;
    };
    // on error of image src load default fallback image
    MoreVehicleOffersComponent.prototype.updateUrl = function (event, vehicle) {
        vehicle.imagePath = this.vehicleImageNotAvailableUrl;
    };
    MoreVehicleOffersComponent.prototype.getSelectedAttribute = function (vehicleType, index) {
        if (index === void 0) { index = 0; }
        if (this.selectedVehicle &&
            this.selectedVehicle.selectedVehicleType === vehicleType) {
            var selectedIndex = this.selectedVehicle.alternateVehicleIndex;
            if (selectedIndex !== undefined && selectedIndex !== null) {
                return (this.selectedVehicle.alternateVehicleIndex === index);
            }
            return true;
        }
        return false;
    };
    MoreVehicleOffersComponent.prototype.getContainerClass = function () {
        return 'row mvos-more-container1';
    };
    return MoreVehicleOffersComponent;
}());
exports.MoreVehicleOffersComponent = MoreVehicleOffersComponent;
