
/* injects from baggage-loader */
require('./search-export-modal.component.html');

import angular from 'angular';
import { getUrlComponents } from "../../utilities/url"
import { ChildMessagePort } from '../../utilities/messaging';
import './search-export-modal.component.scss';

angular.module('AaApp.Opp').component('searchExportModal', {
    templateUrl: '/app/components/search/search-export-modal.component.html',
    controller: SearchExportModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
});

/* @ngInject */
function SearchExportModalController($element, $scope, $sce, legacy, $logFactory) {
    var $ctrl = this;

    const log = $logFactory.get();

    $ctrl.submit = submit;
    $ctrl.$onInit = onInit;
    const originalIframeExportUrl = legacy.iframeUrl + "/AlertDesk/Search/Export";
    $ctrl.selectedDealerId;
    $ctrl.iframeUrl;


    function onInit() {
        var rendered = $ctrl.resolve.renderedPromise.rendered;
        if ($ctrl.resolve.renderedPromise.selectedDealerId === undefined) {
            $ctrl.selectedDealerId = 0;
        }
        else {
            $ctrl.selectedDealerId = $ctrl.resolve.renderedPromise.selectedDealerId;
        }
        $ctrl.iframeUrl = $sce.trustAsResourceUrl(originalIframeExportUrl + "?lm=Export&dealerID=" + $ctrl.selectedDealerId + "&fa=" + $ctrl.resolve.isFordAlert);

        rendered.then(function () {
            const iframeExport = $element.find(".ifrmSearchExport")[0];
            const originExport = getUrlComponents(originalIframeExportUrl).origin;
            $ctrl.messageChannelExport = new ChildMessagePort(iframeExport, originExport, $scope);

            const subscription = $ctrl.messageChannelExport.subscribe(function ({ data }, replyPort) {
                if (data.type === "EXPORT_SAVE_LAYOUT_COMPLETED") { 
                    log.log("EXPORT_SAVE_LAYOUT_COMPLETED message received in Portal.");
                    $ctrl.close({ $value: { exportLayout: data.data.layoutData } });
                }
            })
        });
    }

    function submit() {
        var msg = {
            type: "EXPORT_SAVE_LAYOUT",
            data: {
                selectedDealerId: $ctrl.selectedDealerId
            }
        };
        $ctrl.messageChannelExport.postMessage(msg);
    }
}
