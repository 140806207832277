
/* injects from baggage-loader */
require('./dashboard-manager.component.html');

import angular from 'angular';
import moment from 'moment-timezone';
import { ApiActivityTypes } from '../../constants';
import { TimeZonesById, guessBrowserTimeZone } from '../../constants/time-zones';
import { widgetTypes } from '../../constants/widget-types';
import { EngagementEventGroups } from '../../constants/engagement-event-types.constant';

import './dashboard.scss';

angular.module('AaApp.Opp').component('dashboardManager', {
    templateUrl: '/app/components/dashboard/dashboard-manager.component.html',
    controller: DashboardManagerController,
    bindings: { 
        selectedDealerId : "<",
        userId: "<"
    }
});

function DashboardManagerController(activityService, storageService, 
    customerEngagementService, authorizationService, searchService, environmentVariables, dealerService, assetTypes, saleTypes) {
    "ngInject";

    const $ctrl = this;
    const MAX_ROWS = environmentVariables.managerDashboardCustomerEngagementMaxRows || 50;

    $ctrl.showHotLeadsOnly = false;
    $ctrl.showNotWorkedOnly = false;
    $ctrl.permissions = {};
    $ctrl.$onChanges = $onChanges;

    $ctrl.closeCallback = closeCallback;
    $ctrl.refreshWidgets = refreshWidgets;
    $ctrl.hotLeadsOnlyChanged = filtersChanged;
    $ctrl.NotWorkedChanged = filtersChanged;
    $ctrl.$onInit = init;
    $ctrl.showReviewPro = false;
    $ctrl.showGeoAlert = false;
    $ctrl.getWidgetColumnTypesList = getWidgetColumnTypesList;
    $ctrl.getWidgetLink = getWidgetLink;
    $ctrl.moveWidgetToTop = moveWidgetToTop;
    $ctrl.setFavoriteWidget = setFavoriteWidget;
    $ctrl.saveCollapseState = saveCollapseState;


    //const standardColumnTypes = ['customerEngagement', 'opportunity', 'alertBlocks'];
    const reviewProColumnTypes = ['customerEngagement', 'opportunity', 'reviewRating'];
    const emailTextOneToOneColumnTypes = ['customerEngagement', 'opportunity', 'groupEngagement', 'alertBlocks', 'emailtext'];
    const serviceColumnTypes = ['customerEngagement', 'opportunity', 'service', 'alertBlocks'];
    const webColumnTypes = ['customerEngagement', 'opportunity', 'web', 'alertBlocks'];
    const geoAlertColumnTypes = ['customerEngagement', 'opportunity', 'geoAlert'];
    const upComingApptColumnTypes = ['customerEngagement', 'opportunity', 'alertBlocks', 'upcoming'];

    var isEuroLite = authorizationService.isEuroLite();

    //TODO:  refactor this to a centralized location.
    //Configuration
    $ctrl.leaseSaleTypes = [saleTypes.lease];
    $ctrl.retailSaleTypes = [saleTypes.retail, saleTypes.balloon];


    async function setUpWidgets() {

        $ctrl.widgets = [
            {
                widgetDefaultOrder: 1,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'retailWidgetTitle',
                widgetExpandToolTipKey: 'expandretail',
                widgetLink: $ctrl.getWidgetLink('retail'),
                columnTypes: $ctrl.getWidgetColumnTypesList('retail'),
                widgetDataService: retailWidgetDataService,
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 2,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'leaseWidgetTitle',
                widgetExpandToolTipKey: 'expandlease',
                widgetLink: $ctrl.getWidgetLink('lease'),
                columnTypes: $ctrl.getWidgetColumnTypesList('lease'),
                widgetDataService: leaseWidgetDataService,
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 3,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'emailText',
                widgetExpandToolTipKey: 'email',
                icon: 'email-sms',
                highEngagementDescription: 'emailTextHighEngagementDescription',
                detailViewLinkDisabled: true,
                columnTypes: emailTextOneToOneColumnTypes,
                widgetDataService:
                    (force) => customerEngagementService.getCustomerEngagementByGroup($ctrl.selectedDealerId, {
                        engagementEventGroupIds: [EngagementEventGroups.email, EngagementEventGroups.text, EngagementEventGroups.autoAssistant],
                        take: MAX_ROWS,
                        hotLeadsOnly: $ctrl.showHotLeadsOnly,
                        notWorkedOnly: $ctrl.showNotWorkedOnly,
                        dealerId: $ctrl.selectedDealerId,
                    }).then(mappedResponse => {
                        return mappedResponse.sort((a, b) => {
                            return new Date(b.eventDate) - new Date(a.eventDate);
                        })
                    }),
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 4,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'upcomingAppointments',
                widgetExpandToolTipKey: 'upcomingAppointments',
                icon: 'aa-icon-core-calendar-icon-000000',
                highEngagementDescription: 'todaysAppointments',
                detailViewLinkDisabled: true,
                columnTypes: upComingApptColumnTypes,
                widgetDataService: getUpcomingAppointments,
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 5,
                widgetType: widgetTypes.agenda,
                widgetTitleKey: 'agendaWidgetTitle',
                widgetExpandToolTipKey: 'expandagenda',
                widgetLink: 'activitiesAgenda',
                widgetDataService: agendaWidgetDataService,
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 6,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'recentlyAssignedWidgetTitle',
                widgetExpandToolTipKey: 'expandrecentlyAssigned',
                widgetDataService: recentlyAssignedWidgetDataService,
                detailViewLinkDisabled: true,
                columnTypes: ['opportunity', 'alertBlocks', 'assignedDate'],
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 7,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'service',
                widgetExpandToolTipKey: 'service',
                icon: 'aa-icon-service-drive',
                highEngagementDescription: 'serviceHighEngagementDescription',
                detailViewLinkDisabled: true,
                columnTypes: serviceColumnTypes,
                widgetDataService:
                    (force) => customerEngagementService.getCustomerEngagementByGroup($ctrl.selectedDealerId, {
                        engagementEventGroupIds: [EngagementEventGroups.service],
                        take: MAX_ROWS,
                        hotLeadsOnly: $ctrl.showHotLeadsOnly,
                        notWorkedOnly: $ctrl.showNotWorkedOnly,
                        dealerId: $ctrl.selectedDealerId,
                    }),
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 8,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'oneToOne',
                widgetExpandToolTipKey: 'oneToOne',
                icon: 'marketing-mailer-icon-white',
                highEngagementDescription: 'o2oHighEngagementDescription',
                detailViewLinkDisabled: true,
                columnTypes: emailTextOneToOneColumnTypes,
                widgetDataService:
                    (force) => customerEngagementService.getCustomerEngagementByGroup($ctrl.selectedDealerId, {
                        engagementEventGroupIds: [EngagementEventGroups.one2one, EngagementEventGroups.intelligentMarketing],
                        take: MAX_ROWS,
                        hotLeadsOnly: $ctrl.showHotLeadsOnly,
                        notWorkedOnly: $ctrl.showNotWorkedOnly,
                        dealerId: $ctrl.selectedDealerId,
                    }),
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
            {
                widgetDefaultOrder: 9,
                widgetType: widgetTypes.opportunitiesTable,
                widgetTitleKey: 'web',
                widgetExpandToolTipKey: 'web',
                icon: 'web',
                highEngagementDescription: 'webHighEngagementDescription',
                detailViewLinkDisabled: true,
                columnTypes: webColumnTypes,
                widgetDataService:
                    (force) => customerEngagementService.getCustomerEngagementByGroup($ctrl.selectedDealerId, {
                        engagementEventGroupIds: [EngagementEventGroups.web],
                        take: MAX_ROWS,
                        hotLeadsOnly: $ctrl.showHotLeadsOnly,
                        notWorkedOnly: $ctrl.showNotWorkedOnly,
                        dealerId: $ctrl.selectedDealerId,
                    }),
                triggerRefresh: 0,
                isFavorite: false,
                isCollapsed: false,
            },
        ];

        $ctrl.showReviewPro = await dealerService.getIsAssetEnabledForDealer(assetTypes.reviewBuilderPro, $ctrl.selectedDealerId);
        if ($ctrl.showReviewPro === true) {
            $ctrl.widgets.push(
                {
                    widgetDefaultOrder: 10,
                    widgetType: widgetTypes.opportunitiesTable,
                    widgetTitleKey: 'reviews',
                    widgetExpandToolTipKey: '',
                    icon: 'reviews',
                    highEngagementDescription: '',
                    detailViewLinkDisabled: true,
                    columnTypes: reviewProColumnTypes,
                    widgetDataService:
                        (force) => customerEngagementService.getCustomerEngagementByGroup($ctrl.selectedDealerId, {
                            engagementEventGroupIds: [EngagementEventGroups.reviews],
                            take: MAX_ROWS,
                            hotLeadsOnly: 0,
                            notWorkedOnly: $ctrl.showNotWorkedOnly,
                            dealerId: $ctrl.selectedDealerId,
                        }),
                    triggerRefresh: 0,
                    isFavorite: false,
                    isCollapsed: false,
                }
            );
        }

        $ctrl.showGeoAlert = await dealerService.getIsAssetEnabledForDealer(assetTypes.geoAlert, $ctrl.selectedDealerId);
        if ($ctrl.showGeoAlert === true) {
            $ctrl.widgets.push(
                {
                    widgetDefaultOrder: 11,
                    widgetType: widgetTypes.opportunitiesTable,
                    widgetTitleKey: 'geoAlert',
                    widgetExpandToolTipKey: 'geoAlert',
                    icon: 'geo-alert-icon-white',
                    highEngagementDescription: 'geoAlertTypeActive',
                    detailViewLinkDisabled: true,
                    columnTypes: geoAlertColumnTypes,
                    widgetDataService:
                        (force) => customerEngagementService.getCustomerEngagementByGroup($ctrl.selectedDealerId, {
                            engagementEventGroupIds: [EngagementEventGroups.geoAlert],
                            take: MAX_ROWS,
                            hotLeadsOnly: $ctrl.showHotLeadsOnly,
                            notWorkedOnly: $ctrl.showNotWorkedOnly,
                            dealerId: $ctrl.selectedDealerId,
                        }),
                    triggerRefresh: 0,
                    isFavorite: false,
                    isCollapsed: false,
                }
            );
        }

        // Get favorites from localstorage and re-order:
        showFavorites();

        showCollapseState();
    }

    function showCollapseState() {
        var widgetStr = storageService.getItem('localPriorityCollapseState_' + $ctrl.userId);
        if (widgetStr != null) {
            var widgetList = widgetStr.split(",");

            widgetList.forEach(function (widget) {
                var widgetSetting = widget.split(":");
                var index = widgetSetting[0];
                var collapseState = widgetSetting[1];
                var widgetIndex = _.findIndex($ctrl.widgets, { widgetDefaultOrder: parseInt(index) });
                if (widgetIndex >= 0) {
                    $ctrl.widgets[widgetIndex].isCollapsed = collapseState == "1" ? true : false;
                }
            });
        }
    }

    function showFavorites() {
        var favoritesStr = storageService.getItem('localPriorityDashFavs_' + $ctrl.userId);
        if (favoritesStr != null) {

            $ctrl.widgets.sort(byDefaultOrder);

            var favorites = favoritesStr.split(",");

            for (var i = favorites.length - 1; i >= 0; i--) {
                var index = _.findIndex($ctrl.widgets, { widgetDefaultOrder: parseInt(favorites[i]) });
                if (index > 0) {
                    $ctrl.widgets[index].isFavorite = true;
                    moveWidgetToTop(index);
                }
            }
        }
    }

    function byDefaultOrder(object1, object2) {
        if (object1.widgetDefaultOrder < object2.widgetDefaultOrder)
            return -1;
        if (object1.widgetDefaultOrder > object2.widgetDefaultOrder)
            return 1;
        return 0;
    }

    function setFavoriteWidget(index, isOn) {
        // get index from order field:
        var widgetIndex = _.findIndex($ctrl.widgets, { widgetDefaultOrder: index });

        if (!isOn) {
            $ctrl.widgets[widgetIndex].isFavorite = false;
        }
        else {
            $ctrl.widgets[widgetIndex].isFavorite = true;
            moveWidgetToTop(widgetIndex);
        }

        saveFavorites();
        showFavorites();
    }


    function saveCollapseState(index, isCollapsed) {
        var collapseState = "";
        
        var widgetIndex = _.findIndex($ctrl.widgets, { widgetDefaultOrder: index });
        $ctrl.widgets[widgetIndex].isCollapsed = isCollapsed;

        $ctrl.widgets.forEach(function (widget) {
            collapseState = collapseState + "," + widget.widgetDefaultOrder + ":" + (widget.isCollapsed ? "1" : "0");
        });
        storageService.setItem('localPriorityCollapseState_' + $ctrl.userId, collapseState.substring(1));
    }


    function saveFavorites() {
        var favorites = "";
        $ctrl.widgets.forEach(function (widget) {
            if (widget.isFavorite) {
                favorites = favorites + "," + widget.widgetDefaultOrder;
            }
        });
        storageService.setItem('localPriorityDashFavs_' + $ctrl.userId, favorites.substring(1));
    }

    function moveWidgetToTop(from) {
        $ctrl.widgets.splice(0, 0, $ctrl.widgets.splice(from, 1)[0]);
    }

    function init() {
        authorizationService.getDealSheetPermissions().then(function (data){
            $ctrl.permissions = data;
        });
    }

    function $onChanges(changes) {
        if (changes.selectedDealerId) {
            setUpWidgets()
                .then(result => {
                    if (!changes.selectedDealerId.isFirstChange()) {
                        refreshWidgets();
                    }
                });
        }
    }

    function closeCallback(type) {
        //refresh
        switch (type) {
            case widgetTypes.lease:
            case widgetTypes.retail:
            case widgetTypes.newOpportunityChanges:
                $ctrl.refreshWidgets();
                break;
            case widgetTypes.agenda:
            case widgetTypes.recentlyAssigned:
            default:
                break;
        }
    }

    function refreshWidgets() {
        for (const widget of $ctrl.widgets) {
            widget.triggerRefresh++;
        }
    }

    function filtersChanged() {
        refreshWidgets();
    }

    function getWidgetLink(type) {
        if (isEuroLite)
            return 'euroliteopportunities';

        switch (type) {
            case 'lease':
                return 'opportunitiesLease';
            case 'retail':
                return 'opportunitiesRetail';
        }
    }

    function getWidgetColumnTypesList(type) {
        switch (type) {
            case 'lease':
                if (isEuroLite)
                    return ['opportunity', 'alertBlocks'];
                else
                    return ['opportunity', 'alertBlocks', 'apptOrRoDate', 'lease'];
            case 'retail':
                if (isEuroLite)
                    return ['opportunity', 'alertBlocks'];
                else
                    return ['opportunity', 'alertBlocks', 'apptOrRoDate', 'retail'];
            default:
                return ['opportunity', 'alertBlocks', 'apptOrRoDate'];
        }
    }

    function leaseWidgetDataService(flushCache) {
        return searchService.getOpenOpportunities($ctrl.selectedDealerId, $ctrl.leaseSaleTypes, flushCache);
    }

    function retailWidgetDataService(flushCache) {
        return searchService.getOpenOpportunities($ctrl.selectedDealerId, $ctrl.retailSaleTypes, flushCache);
    }

    function recentlyAssignedWidgetDataService(flushCache) {
        return searchService.getRecentlyAssignedOpportunities($ctrl.selectedDealerId, flushCache);
    }

    function agendaWidgetDataService(flushCache) {
        return activityService.getAgendaItems($ctrl.selectedDealerId, null, flushCache);
    }

    function getUpcomingAppointments() {
        // This ought to be the _dealer's_ time zone, not the user's, but we
        // don't have that info. We fall back to the browser's time zone if the
        // profile's time zone is not set.
        const timeZone = TimeZonesById[storageService.getItem('timezoneId')] || guessBrowserTimeZone();

        const now = moment();
        const startOfToday = now.clone().tz(timeZone.tzdbName).startOf('day');
        const dateTo = startOfToday.clone().add(30, 'days');

        return activityService.getAgendaItemsWithEntityData({
            dateFrom: startOfToday.toDate(),
            dateTo: dateTo.toDate(),
            activityTypes: [ ApiActivityTypes.appointment ],
            take: MAX_ROWS,
        }).then(appts => appts.map(appt => ({
            ...appt,
            // Faking the highEngagement flag is easier than editing
            // dashboardOpportunitiesTable so it understands "today"
            highEngagement: moment(appt.dueDate).isSame(now, 'day'),
        }))).then(appts => {
            if ($ctrl.showHotLeadsOnly) {
                return appts.filter(appt => appt.highEngagement)
            } else {
                return appts;
            }
        })
    }
}
