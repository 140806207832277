import { Subject } from 'rxjs';

export const unauthorized$ = new Subject();
export const tokenExpired$ = new Subject();
export const tokenMissing$ = new Subject();
export const tokenExpiresSoon$ = new Subject();

export const loggedIn$ = new Subject();
export const externalLoggedIn$ = new Subject();
export const loggedOut$ = new Subject();

export const silentRefreshStarted$ = new Subject();
export const silentRefreshSucceeded$ = new Subject();
export const silentRefreshFailed$ = new Subject();
export const silentRefreshTokenUnchanged$ = new Subject();
export const silentRefreshTimeout$ = new Subject();
