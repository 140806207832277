<div class="row">
    <div class="col-md-11">
        <div *ngIf="dsa.isCreditConvertAdmin">
            <div class="settingTitle" translate="creditConvert"></div>
            <tooltip [titleKey]="'creditConvert'" [contentKey]="'dsAdmin_creditConvertTooltip'"></tooltip>
            <div class="settingItem">
                <label><input name="DealerCreditSoftPull.UseCreditSoftPull" type="checkbox" [(ngModel)]="dsa.dealerCreditSoftPull.useCreditSoftPull" /> {{ 'dsAdmin_useCreditConvertFeature' | translate }}</label>
            </div>
        </div>
        <div *ngIf="dsa.dealerCreditSoftPull.useCreditSoftPull">
            <div *ngIf="dsa.isCreditConvertAdmin || dsa.isCreditConvertEditor">
                <div class="sectionSpacer"></div>
                <div class="settingTitle" translate="dsAdmin_creditConvertAssignments"></div>
                <tooltip [titleKey]="'dsAdmin_creditConvertAssignments'" [contentKey]="'dsAdmin_creditConvertAssignmentsTooltip'"></tooltip>
                <div class="row settingItem">
                    <div class="col-md-3" style="margin-top:5px" translate="owner">
                    </div>
                    <div class="col-md-3">
                        <angular2-multiselect [data]="dsa.users" [(ngModel)]="selectedOwners"
                                              [settings]="ownersDropdownSettings"
                                              (onSelect)="onMultiSelectChanged()"
                                              (onDeSelect)="onMultiSelectChanged()"
                                              (onSelectAll)="onMultiSelectChanged()"
                                              (onDeSelectAll)="onMultiSelectChanged()"></angular2-multiselect>
                    </div>
                </div>
            </div>
            <div *ngIf="dsa.isCreditConvertAdmin || dsa.isCreditConvertEditor">
                <div class="sectionSpacer"></div>
                <div class="settingTitle" translate="dsAdmin_creditConvertFilters"></div>
                <tooltip class="creditConvertFilters" [titleKey]="'dsAdmin_creditConvertFilters'" [contentKey]="'dsAdmin_creditConvertFiltersTooltip'"></tooltip>
                <div class="row settingItem">
                    <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_includeSpecificYears">
                    </div>
                    <div class="col-md-3">
                        <angular2-multiselect [data]="years"
                                              [(ngModel)]="selectedYears"
                                              [settings]="specificYearsSettings"
                                              (onSelect)="onMultiSelectChanged()"
                                              (onDeSelect)="onMultiSelectChanged()"
                                              (onSelectAll)="onMultiSelectChanged()"
                                              (onDeSelectAll)="onMultiSelectChanged()"></angular2-multiselect>
                    </div>
                </div>
                <div class="row settingItem">
                    <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_mileageLessOrEqual">
                    </div>
                    <div class="col-md-1">
                        <select class="form-control" [(ngModel)]="dsa.dealerCreditSoftPull.creditSoftPullMileageFilter">
                            <option [ngValue]="null"></option>
                            <option value="15000">15,000</option>
                            <option value="30000">30,000</option>
                            <option value="45000">45,000</option>
                            <option value="60000">60,000</option>
                            <option value="75000">75,000</option>
                            <option value="90000">90,000</option>
                            <option value="100000">100,000</option>
                            <option value="125000">125,000</option>
                            <option value="150000">150,000</option>
                        </select>
                    </div>
                </div>
                <div class="row settingItem">
                    <div class="col-md-3" style="margin-top:5px" translate="dsAdmin_excludeIfProcessed">
                    </div>
                    <div class="col-md-1">
                        <select class="form-control" [(ngModel)]="dsa.dealerCreditSoftPull.creditSoftPullProcessedFilter">
                            <option [ngValue]="null"></option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="90">90</option>
                            <option value="120">120</option>
                            <option value="150">150</option>
                            <option value="180">180</option>
                        </select>
                    </div>
                    <div class="col-md-1" translate="days" style="margin-top:5px">
                    </div>
                </div>
                <div class="settingItem">
                    <label><input type="checkbox" [(ngModel)]="dsa.dealerCreditSoftPull.ignoreFiltersOnPredictiveRepull" /> {{ 'dsAdmin_ignoreFiltersForRepull' | translate }}</label>
                </div>
            </div>
            <div *ngIf="dsa.isCreditConvertAdmin">
                <div class="sectionSpacer"></div>
                <div class="settingTitle" translate="dsAdmin_newFeaturePilotControl"></div>
                <tooltip [titleKey]="'dsAdmin_newFeaturePilotControl'" [contentKey]="'dsAdmin_newFeaturePilotControlTooltip'"></tooltip>
                <div class="row settingItem">
                    <div class="col-md-3">
                        <div>
                            <label><input type="checkbox" [(ngModel)]="dsa.dealerCreditSoftPull.enableRealTimeScan" /></label> {{ 'dsAdmin_enableRealTimeScan' | translate }}
                        </div>
                        <div>
                            <label><input type="checkbox" [(ngModel)]="dsa.dealerCreditSoftPull.enableLeaseAlerts" /></label> {{ 'dsAdmin_enableLeaseAlerts' | translate }}
                        </div>
                        <div>
                            <label><input type="checkbox" [(ngModel)]="dsa.dealerCreditSoftPull.processFirstTimeConquests" /></label> {{ 'dsAdmin_processFirstTimeConquests' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

