"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportCampaignTypes = {
    notSet: 0,
    sales: 1,
    service: 2,
    conquest: 3,
    callCenter: 4,
    other: 5,
};
