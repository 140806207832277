/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AnnouncementDto } from '../models/announcement-dto';
@Injectable({
  providedIn: 'root',
})
class AnnouncementClientService extends __BaseService {
  static readonly GetAnnouncementForUserGETPath = '/api/Announcement/GetAnnouncementForUser';
  static readonly UpdateAnnouncementUserDisplayByAnnouncementidPUTPath = '/api/Announcement/UpdateAnnouncementUserDisplay/{announcementId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAnnouncementForUserGETResponse(): __Observable<__StrictHttpResponse<AnnouncementDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Announcement/GetAnnouncementForUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnnouncementDto>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAnnouncementForUserGET(): __Observable<AnnouncementDto> {
    return this.GetAnnouncementForUserGETResponse().pipe(
      __map(_r => _r.body as AnnouncementDto)
    );
  }

  /**
   * @param params The `AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTParams` containing the following parameters:
   *
   * - `announcementId`:
   *
   * - `doNotDisplay`:
   */
  UpdateAnnouncementUserDisplayByAnnouncementidPUTResponse(params: AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.doNotDisplay;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Announcement/UpdateAnnouncementUserDisplay/${params.announcementId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTParams` containing the following parameters:
   *
   * - `announcementId`:
   *
   * - `doNotDisplay`:
   */
  UpdateAnnouncementUserDisplayByAnnouncementidPUT(params: AnnouncementClientService.UpdateAnnouncementUserDisplayByAnnouncementidPUTParams): __Observable<null> {
    return this.UpdateAnnouncementUserDisplayByAnnouncementidPUTResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AnnouncementClientService {

  /**
   * Parameters for UpdateAnnouncementUserDisplayByAnnouncementidPUT
   */
  export interface UpdateAnnouncementUserDisplayByAnnouncementidPUTParams {
    announcementId: number;
    doNotDisplay?: boolean;
  }
}

export { AnnouncementClientService }
