import { Component, OnInit, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { EngagementEventTypes, EngagementEventGroups } from '../../../constants/engagement-event-types.constant';
import { DealSheetService } from '../../../ajs-upgraded-providers';
import { Alert } from '../../../interfaces/alert.interface';

@Component({
    selector: 'dashboard-opportunities-table',
    templateUrl: './dashboard-opportunities-table.component-ng.html',
    styleUrls: ['./dashboard-opportunities-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardOpportunitiesTableComponent implements OnInit, OnChanges {
    @Input() widgetData: Array<any>;
    @Input() widgetTitleKey: string;
    @Input() columnTypes: any;
    @Input() isEuroLite = false;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onCloseCallback = new EventEmitter<any>();

    public sortedWidgetData: any;
    public engagementEventTypes = EngagementEventTypes;
    public engagementEventGroups = EngagementEventGroups;

    constructor(@Inject(DealSheetService) private dealSheetService) { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.widgetData && changes.widgetData.currentValue) {
            this.sortedWidgetData = this.widgetData.sort((item1, item2) => {
                if (item1.assignedDate < item2.assignedDate) {
                    return -1;
                } else if (item1.assignedDate > item2.assignedDate) {
                    return 1;
                } else {
                    return 0;
                }
            });

            for (const opportunity of this.sortedWidgetData) {
                if (opportunity.alerts) {
                    opportunity.detailedAlerts = opportunity.alerts.map((a: number) => {
                        return {
                            type: a
                        } as Alert;
                    });
                }
            }
        }
    }

    openDealSheet(entityId: string): void {
        if (!this.isEuroLite) {
            this.dealSheetService.openDealSheetModal(entityId, null, this._dealsheetClose);
        } else {
            this.dealSheetService.openDealSheetLiteModal(null, entityId);
        }
    }

    // Use fat arrow definition to capture the correct this context because this is called as a callback function
    _dealsheetClose = (opId: string) => {
        this.onCloseCallback.emit({ opId: opId });
    }

    iconClassForReviewRating(onlineReviewRating: string): string {
        return 'rating-' + onlineReviewRating + 'star';
    }
}
