import angular from 'angular';
import opportunities from '../core/opportunities.module';

angular.module(opportunities).factory('featureToggleService', FeatureToggleService);

function FeatureToggleService(featureToggles, dealerService) {
    "ngInject";

    return {
        customerEngagementWidget: customerEngagementWidget,
        useFakeCustomerEngagementData: useFakeCustomerEngagementData,
    };

    function customerEngagementWidget() {
        return calculateFeatureToggle(featureToggles.enableCustomerEngagementWidget);
    }

    function useFakeCustomerEngagementData() {
        return calculateFeatureToggle(featureToggles.useFakeCustomerEngagementData);
    }

    function calculateFeatureToggle(feature) {
        // falsy means the feature is off
        if (!feature)
            return false;

        // Literal 'true' means the feature is on for all dealers
        if (feature === true)
            return true;

        // If it's an array, it is a list of dealer IDs that should have the
        // feature enabled.
        if (Array.isArray(feature)) {
            const selectedDealers = dealerService.getSelectedDealerIds() || [];
            for (const selectedDealerId of selectedDealers) {
                if (feature.includes(selectedDealerId)) {
                    return true;
                }
            }
        }

        return false;
    }
}
