import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.replace";
TemplateEmailController.$inject = ["$q", "$sce", "templateService", "activityService"];

/* injects from baggage-loader */
require('./template-email.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('templateEmail', {
  templateUrl: '/app/components/template-email.component.html',
  controller: TemplateEmailController,
  bindings: {
    templateEmailId: '<',
    accessCode: '<',
    isPreview: '<',
    emailConversationItemId: '<'
  }
});
/* @ngInject */

function TemplateEmailController($q, $sce, templateService, activityService) {
  var $ctrl = this;
  $ctrl.emailContentPromise;
  $ctrl.emailBodyContentHtml;
  $ctrl.$onInit = init;
  $ctrl.dealerUserPreviewClassName = "";
  /*
    This component is used for 3 options;
  1) Used by customer to view email sent by dealer user via public page
  2) Used by dealer user to view sent email to customer in portal
  3) Used by dealer user to view email conversations. (emailConversationItemId is valid )
    */

  function init() {
    if ($ctrl.isPreview) {
      $ctrl.dealerUserPreviewClassName = " dealer-user-preview";
    }

    if ($ctrl.accessCode) {
      $ctrl.emailContentPromise = templateService.getSentEmailContent($ctrl.templateEmailId, $ctrl.accessCode).then(setEmailContent);
    } else getEmailConversationDetails();
  }

  function getEmailConversationDetails() {
    $ctrl.emailContentPromise = activityService.getEmailConversation($ctrl.emailConversationItemId).then(setEmailConversationDetails);
  }

  function setEmailConversationDetails(emailDetails) {
    if (emailDetails) {
      var sentEmailObject = {
        emailBodyContentHtml: emailDetails
      };
      setEmailContent(sentEmailObject);
    } else {
      return $q.resolve(false);
    }
  }

  function setEmailContent(sentEmailObject) {
    // cleanup legacy jquery references from raw html
    sentEmailObject.emailBodyContentHtml = sentEmailObject.emailBodyContentHtml.replace('<script src="/Scripts/jquery-1.8.3.min.js" type="text/javascript"></script>', '');

    if (sentEmailObject.dealerAddress != null) {
      sentEmailObject.primaryAddress = {
        address1: sentEmailObject.dealerAddress.dealerAddress,
        address2: sentEmailObject.dealerAddress.dealerAddress2,
        city: sentEmailObject.dealerAddress.dealerCity,
        state: sentEmailObject.dealerAddress.dealerState,
        zipCode: sentEmailObject.dealerAddress.dealerZipCode
      };
    } else {
      sentEmailObject.primaryAddress = null;
    } // if dealer-user is previewing sent emails


    if ($ctrl.isPreview) {
      // if it's rendering for dealer user then remove legacy header and footer
      var addScriptToRemoveLegacyHeaderandFooter = "<script>";

      if (!$ctrl.emailConversationItemId) {
        // for 1st sent email preview in portal: cleanu-up else viewed by client keep urls as is
        addScriptToRemoveLegacyHeaderandFooter += "$('#dealer-info').hide();";
        addScriptToRemoveLegacyHeaderandFooter += "$('#unsubscribe').hide();";
      } else {
        // for all conversation old email preview in portal: remove active hyper links and reformat header and footer bg color
        addScriptToRemoveLegacyHeaderandFooter += "$('tr:first').css('background-color', '#f2f2f2');";
        addScriptToRemoveLegacyHeaderandFooter += "$('#view-in-browser-link').attr('href', '#');";
        addScriptToRemoveLegacyHeaderandFooter += "$('#unsubscribe a').attr('href', '#');";
        addScriptToRemoveLegacyHeaderandFooter += "$('table').width('100%');";
      } // add common cleanup script to remove


      addScriptToRemoveLegacyHeaderandFooter += "$('.email-wrapper-section.email-only-content').hide();";
      addScriptToRemoveLegacyHeaderandFooter += "$('#open-track').attr('src', '').remove();";
      addScriptToRemoveLegacyHeaderandFooter += "$('#customer-track').attr('src', '').remove();";
      addScriptToRemoveLegacyHeaderandFooter += "</script>";
    }

    $ctrl.sentEmailObject = sentEmailObject; // if header image is already present in email body then remove it.

    if (sentEmailObject.emailBodyContentHtml.search(sentEmailObject.emailHeaderPath) > -1) $ctrl.sentEmailObject.emailHeaderPath = null;
    $ctrl.emailBodyContentHtml = $sce.trustAsHtml(sentEmailObject.emailBodyContentHtml + addScriptToRemoveLegacyHeaderandFooter);
    $ctrl.emailContentPromise = null;
    return $q.resolve(true);
  }
}