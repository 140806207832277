/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ModelDto } from '../models/model-dto';
import { VehicleMakesYearsRequestDto } from '../models/vehicle-makes-years-request-dto';
@Injectable({
  providedIn: 'root',
})
class ModelClientService extends __BaseService {
  static readonly GetModelsByMakeYearByMakeidByYearidGETPath = '/api/Model/GetModelsByMakeYear/{makeId}/{yearId}';
  static readonly GetModelsByMakeByMakeidGETPath = '/api/Model/GetModelsByMake/{makeId}';
  static readonly GetModelsByMakesYearsPOSTPath = '/api/Model/GetModelsByMakesYears';
  static readonly ByIdGETPath = '/api/Model/{id}';
  static readonly ByIdDELETEPath = '/api/Model/{id}';
  static readonly GetManyGETPath = '/api/Model/GetMany';
  static readonly PUTPath = '/api/Model';
  static readonly POSTPath = '/api/Model';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ModelClientService.GetModelsByMakeYearByMakeidByYearidGETParams` containing the following parameters:
   *
   * - `yearId`:
   *
   * - `makeId`:
   *
   * @return Success
   */
  GetModelsByMakeYearByMakeidByYearidGETResponse(params: ModelClientService.GetModelsByMakeYearByMakeidByYearidGETParams): __Observable<__StrictHttpResponse<Array<ModelDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Model/GetModelsByMakeYear/${params.makeId}/${params.yearId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ModelDto>>;
      })
    );
  }
  /**
   * @param params The `ModelClientService.GetModelsByMakeYearByMakeidByYearidGETParams` containing the following parameters:
   *
   * - `yearId`:
   *
   * - `makeId`:
   *
   * @return Success
   */
  GetModelsByMakeYearByMakeidByYearidGET(params: ModelClientService.GetModelsByMakeYearByMakeidByYearidGETParams): __Observable<Array<ModelDto>> {
    return this.GetModelsByMakeYearByMakeidByYearidGETResponse(params).pipe(
      __map(_r => _r.body as Array<ModelDto>)
    );
  }

  /**
   * @param makeId undefined
   * @return Success
   */
  GetModelsByMakeByMakeidGETResponse(makeId: number): __Observable<__StrictHttpResponse<Array<ModelDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Model/GetModelsByMake/${makeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ModelDto>>;
      })
    );
  }
  /**
   * @param makeId undefined
   * @return Success
   */
  GetModelsByMakeByMakeidGET(makeId: number): __Observable<Array<ModelDto>> {
    return this.GetModelsByMakeByMakeidGETResponse(makeId).pipe(
      __map(_r => _r.body as Array<ModelDto>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  GetModelsByMakesYearsPOSTResponse(request?: VehicleMakesYearsRequestDto): __Observable<__StrictHttpResponse<Array<ModelDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Model/GetModelsByMakesYears`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ModelDto>>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  GetModelsByMakesYearsPOST(request?: VehicleMakesYearsRequestDto): __Observable<Array<ModelDto>> {
    return this.GetModelsByMakesYearsPOSTResponse(request).pipe(
      __map(_r => _r.body as Array<ModelDto>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<ModelDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Model/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<ModelDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as ModelDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Model/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<ModelDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Model/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ModelDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<ModelDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<ModelDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: ModelDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Model`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: ModelDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: ModelDto): __Observable<__StrictHttpResponse<ModelDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Model`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: ModelDto): __Observable<ModelDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as ModelDto)
    );
  }
}

module ModelClientService {

  /**
   * Parameters for GetModelsByMakeYearByMakeidByYearidGET
   */
  export interface GetModelsByMakeYearByMakeidByYearidGETParams {
    yearId: number;
    makeId: number;
  }
}

export { ModelClientService }
