"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dealer_settings_service_1 = require("../../services/dealer-settings.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_1 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var AdminSettingsCreditConvertComponent = /** @class */ (function () {
    function AdminSettingsCreditConvertComponent(modalService, tService, logger, dsService) {
        this.modalService = modalService;
        this.tService = tService;
        this.logger = logger;
        this.dsService = dsService;
        this.dsa = {};
        this.years = [];
        this.dropdownList = [];
        this.selectedOwners = [];
        this.selectedYears = [];
        this.ownersDropdownSettings = {};
        this.specificYearsSettings = {};
    }
    AdminSettingsCreditConvertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ownersDropdownSettings = {
            singleSelection: false,
            text: this.tService.instant('dsAdmin_selectOwners'),
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
            badgeShowLimit: 2,
            labelKey: "fullName",
            primaryKey: "userID"
        };
        this.specificYearsSettings = {
            badgeShowLimit: 4,
            singleSelection: false,
            text: this.tService.instant('dsAdmin_selectYears'),
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
        };
        this.years = this.dsa.creditConvertFilterYears.map(function (obj) {
            var rObj = {};
            rObj.id = obj;
            rObj.itemName = obj;
            return rObj;
        });
        this.selectedOwners = this.dsa.dealerCreditSoftPullOwner.map(function (obj) {
            var user = _this.dsa.users.find(function (u) { return u.userID == obj.userID; });
            if (user != null)
                return user;
        }).filter(Boolean);
        if (this.dsa.dealerCreditSoftPull.creditSoftPullYearFilter) {
            var selectedYearArray = this.dsa.dealerCreditSoftPull.creditSoftPullYearFilter.split(',');
            this.selectedYears = selectedYearArray.map(function (obj) {
                var rObj = {};
                rObj.id = Number(obj);
                rObj.itemName = Number(obj);
                return rObj;
            });
        }
    };
    AdminSettingsCreditConvertComponent.prototype.onMultiSelectChanged = function () {
        this.dsa.isFormChanged = true;
    };
    AdminSettingsCreditConvertComponent.prototype.onSave = function () {
        this.dsa.dealerCreditSoftPullOwner = this.selectedOwners.map(function (obj) {
            // dealerID and typeID are filled by the api
            var rObj = {};
            rObj.userID = obj.userID;
            return rObj;
        });
        this.dsa.dealerCreditSoftPull.creditSoftPullYearFilter = this.selectedYears.map(function (e) { return e.id; }).join(',');
    };
    return AdminSettingsCreditConvertComponent;
}());
exports.AdminSettingsCreditConvertComponent = AdminSettingsCreditConvertComponent;
