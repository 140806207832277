"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./capital-cost.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("../../../../filters/locale-currency.pipe"));
var i4 = __importStar(require("../../../../modules/storage/storage.service"));
var i5 = __importStar(require("@angular/common"));
var i6 = __importStar(require("./capital-cost.component"));
var styles_CapitalCostComponent = [i0.styles];
var RenderType_CapitalCostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CapitalCostComponent, data: {} });
exports.RenderType_CapitalCostComponent = RenderType_CapitalCostComponent;
function View_CapitalCostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "field row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "field-label"], ["translate", "capitalCostLabel"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "field-data qa-capital-cost-value"]], [[8, "textContent", 0]], null, null, null, null)), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_0 = "capitalCostLabel"; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.value)); _ck(_v, 3, 0, currVal_1); }); }
function View_CapitalCostComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.LocaleCurrencyPipe, [i4.StorageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CapitalCostComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.value != null); _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_CapitalCostComponent_0 = View_CapitalCostComponent_0;
function View_CapitalCostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "capital-cost", [], null, null, null, View_CapitalCostComponent_0, RenderType_CapitalCostComponent)), i1.ɵdid(1, 114688, null, 0, i6.CapitalCostComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CapitalCostComponent_Host_0 = View_CapitalCostComponent_Host_0;
var CapitalCostComponentNgFactory = i1.ɵccf("capital-cost", i6.CapitalCostComponent, View_CapitalCostComponent_Host_0, { value: "value" }, {}, []);
exports.CapitalCostComponentNgFactory = CapitalCostComponentNgFactory;
