import { Inject, Injectable } from '@angular/core';
import { CookieStorage } from './cookie-storage.service';
import { WINDOW_REF, WindowWrapper } from '../../services/window-ref.service';
import { StorageInterface } from './storage.interface';

@Injectable()
export class StorageService {
    constructor(@Inject(WINDOW_REF) private window: WindowWrapper) {

        this.window.onstorage = (args) => {

            if (this.prevOnStorage) {
                try {
                    this.prevOnStorage.apply(this, args);
                } catch (e) { /* fallthrough */ }
            }

            this.STORAGE_SUBSCRIPTIONS.forEach((cb) => { cb.apply(this, args); });
        };
    }

    // These are stored in a cookie rather than in localStorage because we
    // want these to go away when the browser windows are closed. If we
    // used sessionStorage, they would not be shared when opening a new tab
    // while another tab is still open.
    private COOKIE_KEYS = [
        'idToken',
        'accessToken'
    ];

    private LOCAL_STORAGE_KEYS = [
        'referrerUrl',
        'defaultDealSheetTabs',
        'euroliteUserColumnSettings',
        'debug'
    ];

    // LocalStorage changes
    private STORAGE_SUBSCRIPTIONS = [];

    // localStorage is shared among all browser tabs and is never cleared
    // automatically.
    private localStorageContainer: StorageInterface = new StorageContainer(this.window.localStorage, this.STORAGE_SUBSCRIPTIONS);

    // sessionStorage is shared only with windows opened by this window, and is
    // cleared automatically when the windows are closed.
    private sessionStorageContainer: StorageInterface = new StorageContainer(this.window.sessionStorage, this.STORAGE_SUBSCRIPTIONS);

    // session cookies are shared with all windows and are cleared when all
    // browser windows are closed.
    private cookieStorageContainer: StorageInterface = new CookieStorage(this.window);

    private prevOnStorage = this.window.onstorage;

    getItem(key: string): any {
        const keyStorage = this.getStorageForKey(key);
        return keyStorage.getItem(key);
    }

    setItem(key: string, value: any): void {
        return this.getStorageForKey(key).setItem(key, value);
    }

    removeItem(key: string) {
        return this.getStorageForKey(key).removeItem(key);
    }

    clear() {
        // Reports and Eurolite Opportunities, store some data in localStorage, so we should avoid clearing
        // the whole storage. Instead, we'll just delete known key names.
        this.LOCAL_STORAGE_KEYS
            .filter(key => key !== 'defaultDealSheetTabs' && key !== 'euroliteUserColumnSettings')
            .forEach((key) => {
                this.localStorageContainer.removeItem(key);
            });

        // Cookies and sessionStorage get cleared regularly anyway, so these
        // should be safe to clear.
        this.window.sessionStorage.clear();
        this.COOKIE_KEYS.forEach((key) => this.cookieStorageContainer.removeItem(key));
    }

    onchange(key, cb) {
        return this.getStorageForKey(key).onchange(key, cb);
    }

    getStorageForKey(key: string): StorageInterface {
        if (this.COOKIE_KEYS.indexOf(key) > -1) {
            return this.cookieStorageContainer;
        } else {
            return (this.LOCAL_STORAGE_KEYS.indexOf(key) > -1 || key.startsWith("local"))
                ? this.localStorageContainer
                : this.sessionStorageContainer;
        }
    }
}

/**
 * Uses the Web Storage API to store structured values.
 * storage - The underlying storage to wrap.
 */
export class StorageContainer implements StorageInterface {
    private storage: any;
    storageSubscriptionsArray: Array<Function>;

    constructor(storage, storageSubscriptionsArray: Array<Function>) {
        this.storage = storage;
        this.storageSubscriptionsArray = storageSubscriptionsArray;
    }

    getItem(key: string): any {
        let serialized = this.storage.getItem(key);
        if (serialized) {
            try {
                serialized = JSON.parse(serialized, this.reviver);
            } catch (e) { /* fallthrough */ }
        }
        return serialized;
    }


    setItem(key: string, value: any): void {
        const serialized = JSON.stringify(value);
        return this.storage.setItem(key, serialized);
    }

    removeItem(key: string): void {
        return this.storage.removeItem(key);
    }

    onchange(key: string, cb: ({}) => void): {} {
        const wrappedCb = function onchangeCallback(evt) {
            if (key === key) {
                cb({
                    key: key,
                    value: evt.newValue,
                });
            }
        };

        this.storageSubscriptionsArray.push(wrappedCb);
        return {
            unsubscribe: function () {
                this.storageSubscriptionsArray = this.storageSubscriptionsArray.filter(function (item) { return item !== wrappedCb; });
            }
        };
    }

    reviver(key, value) {
        const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.000?Z$/;

        if (typeof value === 'string' && dateFormat.test(value)) {
            return new Date(value);
        }

        return value;
    }
}
