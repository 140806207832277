import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'user-settings',
    templateUrl: './user-settings.component-ng.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

    @Input() items: Array<any>;
    @Input() userFullName: string;
    public dropDownVisible: boolean = false;
    public avatarUrl: string;

    constructor() { }

    ngOnInit() { }

    reloadPage() {
        window.location.reload();
    }
}