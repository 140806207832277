import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.search";
VehicleSelectController.$inject = ["_", "vehicleTypes", "saleTypes", "vehicleSelectColumns", "$window"];

/* injects from baggage-loader */
require('./vehicle-select.component.html');

import angular from 'angular';
import PricingPlanTypes from '../../../constants/pricing-plan-types';
angular.module('AaApp.Opp').component('vehicleSelect', {
  templateUrl: '/app/components/deal-sheet/vehicle/vehicle-select.component.html',
  controller: VehicleSelectController,
  bindings: {
    vehicles: '<',
    displayColumns: '<',
    pricingPlanId: '<',
    onSelect: '&'
  }
});
/* @ngInject */

function VehicleSelectController(_, vehicleTypes, saleTypes, vehicleSelectColumns, $window) {
  var $ctrl = this;
  var userAgent = $window.navigator.userAgent; // if ipad browser than no need to add scroll bars.

  $ctrl.scrollBarClassName = userAgent.toLowerCase().search("ipad") > -1 ? "" : "instock-result";
  $ctrl.saleTypes = saleTypes;
  $ctrl.vehicleSelectColumns = vehicleSelectColumns;
  $ctrl.displayColumnExists = displayColumnExists;
  $ctrl.getSortIconClassesForColumn = getSortIconClassesForColumn;
  $ctrl.getSortIconClassesForColumnType = getSortIconClassesForColumnType;
  $ctrl.toggleOrderByColumn = toggleOrderByColumn;
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges; // These are the sorting keys passed to the orderBy filter

  $ctrl.orderableColumns = {
    stockNo: 'stockNo',
    vehicleType: 'type',
    trim: 'trimName',
    year: 'yearID',
    price: 'price',
    mileage: 'odometer',
    color: function color(v) {
      return v.exteriorColor + v.interiorColor;
    },
    options: 'options',
    daysInStock: 'daysInStock',
    new_used_cpo: 'new_used_cpo'
  }; // Defaults

  $ctrl.orderByColumn = $ctrl.orderableColumns.daysInStock;
  $ctrl.orderByDescending = true;

  function onChanges(changes) {
    if (changes.vehicles && changes.vehicles.currentValue) {
      $ctrl.instockVehicles = _.cloneDeep($ctrl.vehicles);
      $ctrl.instockVehicles = addCustomeSortField($ctrl.instockVehicles);
    }
  }

  function init() {
    $ctrl.instockVehicles = _.cloneDeep($ctrl.vehicles);
    $ctrl.vehicleTypeTranslateKeys = _.invert(vehicleTypes);
    $ctrl.pricingPlanTranslateKeys = _.invert(PricingPlanTypes);

    _.each($ctrl.pricingPlanTranslateKeys, function (key, i) {
      if (key.indexOf('xPlan') != -1) $ctrl.pricingPlanTranslateKeys[i] = 'xPlan';
    });

    if (!$ctrl.displayColumns || $ctrl.displayColumns.length == 0) {
      $ctrl.displayColumns = [vehicleSelectColumns.stockNo, vehicleSelectColumns.vehicleType, vehicleSelectColumns.trim, vehicleSelectColumns.year, vehicleSelectColumns.price, vehicleSelectColumns.mileage, vehicleSelectColumns.color, vehicleSelectColumns.options, vehicleSelectColumns.daysInStock];
    }
  }

  function addCustomeSortField(instockVehicles) {
    if (instockVehicles) {
      instockVehicles.forEach(function (ele) {
        ele.new_used_cpo = ele._IsNew ? 2 : ele.isCPO ? 1 : 3;
      });
    }

    return instockVehicles;
  }

  function displayColumnExists(column) {
    return $ctrl.displayColumns.indexOf(column) > -1;
  }

  function toggleOrderByColumn(column) {
    if (column === $ctrl.orderableColumns.vehicleType) {
      if ($ctrl.orderByColumn === $ctrl.orderableColumns.new_used_cpo) {
        $ctrl.orderByDescending = !$ctrl.orderByDescending;
      } else {
        $ctrl.orderByColumn = $ctrl.orderableColumns.new_used_cpo;
      }
    } else if ($ctrl.orderByColumn === column) {
      // If the column is already used for ordering, reverse the direction
      $ctrl.orderByDescending = !$ctrl.orderByDescending;
    } else {
      $ctrl.orderByColumn = column; // DaysInStock should sort "oldest first" (descending), all others are ascending

      $ctrl.orderByDescending = column === $ctrl.orderableColumns.daysInStock;
    }
  }

  function getSortIconClassesForColumnType(column) {
    return {
      'invisible': $ctrl.orderByColumn !== $ctrl.orderableColumns.new_used_cpo,
      'sorted-ascending': !$ctrl.orderByDescending,
      'sorted-descending': $ctrl.orderByDescending
    };
  }

  function getSortIconClassesForColumn(column) {
    return {
      'invisible': $ctrl.orderByColumn !== column,
      'sorted-ascending': !$ctrl.orderByDescending,
      'sorted-descending': $ctrl.orderByDescending
    };
  }
}