import angular from 'angular';

angular.module('AaApp.Opp').filter('localeDecimal', localeDecimalFilter);

localeDecimalFilter.$inject = ['storageService'];

function localeDecimalFilter(storageService) {
    return function (number) {
        if (typeof number == 'number') {
            var savedCultureName = storageService.getItem('cultureName');
            var cultureName = savedCultureName ? savedCultureName : 'en-us';

            return number.toLocaleString(cultureName, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    }
}
