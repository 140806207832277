"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./text-message-activity.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("@angular/common"));
var i4 = __importStar(require("../../../filters/locale-date.pipe"));
var i5 = __importStar(require("../../../modules/storage/storage.service"));
var i6 = __importStar(require("./text-message-activity.component"));
var i7 = __importStar(require("@angular/platform-browser"));
var i8 = __importStar(require("../../../ajs-upgraded-providers"));
var i9 = __importStar(require("../../../services/text-message.service"));
var i10 = __importStar(require("../../../filters/locale-date-time.pipe"));
var styles_TextMessageActivityComponent = [i0.styles];
var RenderType_TextMessageActivityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextMessageActivityComponent, data: {} });
exports.RenderType_TextMessageActivityComponent = RenderType_TextMessageActivityComponent;
function View_TextMessageActivityComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.userFirstName; var currVal_1 = _v.parent.context.$implicit.userLastName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TextMessageActivityComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.customerFirstName; var currVal_1 = _v.parent.context.$implicit.customerLastName; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TextMessageActivityComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "btn view-upgrade-proposal-link qa-text-view-upgrade-proposal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewUpgradeProposal(_v.parent.parent.context.$implicit.activityId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("viewUpgradeProposal")); _ck(_v, 1, 0, currVal_0); }); }
function View_TextMessageActivityComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextMessageActivityComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.mobileView; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TextMessageActivityComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "user-message": 0, "customer-message": 1, row: 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "col-sm-2 column-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextMessageActivityComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextMessageActivityComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "sent-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "col-sm-6 column-wrap"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleUpgradeProposalLinkClick($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-sm-2 "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextMessageActivityComponent_5)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.outgoing, !_v.context.$implicit.outgoing, true); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.outgoing; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_v.context.$implicit.outgoing; _ck(_v, 8, 0, currVal_2); var currVal_5 = _v.context.$implicit.hasUpgradeProposalLink; _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.formatSentDateTime(_v.context.$implicit.sentDateTime); _ck(_v, 10, 0, currVal_3); var currVal_4 = _v.context.$implicit.safeMessage; _ck(_v, 12, 0, currVal_4); }); }
function View_TextMessageActivityComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "collapse"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextMessageActivityComponent_2)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.activity.textMessages; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activity.activityId + "messages"); _ck(_v, 0, 0, currVal_0); }); }
function View_TextMessageActivityComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.LocaleDatePipe, [i5.StorageService]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "activity-icon qa-activity-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "svg-icon"], ["src", "../../../images/icons/icon-sms-lake.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "activity-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "qa-activity-datetime activity-timestamp"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["class", "qa-text-message-sent-by"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ": ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-link qa-text-message-btn"], ["data-toggle", "collapse"]], [[1, "data-target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.displayMessages = !_co.displayMessages) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextMessageActivityComponent_1)), i1.ɵdid(21, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.displayMessages; _ck(_v, 21, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.activity.submittedDateTime)); _ck(_v, 8, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("textMessagesActivityLabel", _co.salesOrServiceLabel)); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("textMessageActivitySentByLabel")); var currVal_3 = _co.sentBy; _ck(_v, 15, 0, currVal_2, currVal_3); var currVal_4 = (("#" + _co.activity.activityId) + "messages"); _ck(_v, 17, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform((_co.displayMessages ? "hideMessages" : "viewMessages"))); _ck(_v, 18, 0, currVal_5); }); }
exports.View_TextMessageActivityComponent_0 = View_TextMessageActivityComponent_0;
function View_TextMessageActivityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "aa-text-message-activity", [], null, null, null, View_TextMessageActivityComponent_0, RenderType_TextMessageActivityComponent)), i1.ɵdid(1, 770048, null, 0, i6.TextMessageActivityComponent, [i7.DomSanitizer, i2.TranslateService, i8.LegacyService, i9.TextMessageService, i10.LocaleDateTimePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TextMessageActivityComponent_Host_0 = View_TextMessageActivityComponent_Host_0;
var TextMessageActivityComponentNgFactory = i1.ɵccf("aa-text-message-activity", i6.TextMessageActivityComponent, View_TextMessageActivityComponent_Host_0, { activity: "activity", mobileView: "mobileView" }, {}, []);
exports.TextMessageActivityComponentNgFactory = TextMessageActivityComponentNgFactory;
