import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'dealer-rebate',
    templateUrl: './dealer-rebate.component-ng.html',
    styleUrls: ['./dealer-rebate.component.scss']
})
export class DealerRebateComponent implements OnInit {
    @Input() value: number;

    constructor() { }

    ngOnInit() {
    }

}
