import { Component, OnInit, Input, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToasterLoggerService } from '../../../core/toaster-logger.service';
import { DmsPushModalComponent } from '../dms-push-modal/dms-push-modal.component';
import { NgxModalWrapperService } from '../../../services/ngx-modal-wrapper.service';

@Component({
    selector: 'dmsPush',
    templateUrl: './dms-push.component-ng.html',
    styleUrls: ['./dms-push.component.scss']
})
export class DmsPushComponent {
    @Input() dealerId: number;
    @Input() customer: any;
    @Input() states: any;
    @Input() currentVin: string;
    @Input() currentTradeValue: number;
    @Input() currentYear: string;
    @Input() currentMake: string;
    @Input() currentModel: string;
    @Input() currentMileage: number;
    @Input() replacementVin: string;
    @Input() replacementPrice: number;
    @Input() replacementSaleType: number;
    bsModalRef: BsModalRef;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService,
        public logger: ToasterLoggerService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    public dmsPushModal() {

        const initialState = {
            dealerId: this.dealerId,
            customer: this.customer,
            states: this.states,
            currentVin: this.currentVin,
            currentTradeValue: this.currentTradeValue,
            replacementVin: this.replacementVin,
            replacementPrice: this.replacementPrice,
            replacementSaleType: this.replacementSaleType,
            currentYear: this.currentYear,
            currentMake: this.currentMake,
            currentModel: this.currentModel,
            currentMileage: this.currentMileage,
        };

        this.bsModalRef = this.modalService.show(DmsPushModalComponent, { initialState, class: 'dms-push-modal' });

        this.bsModalRef.content.onSaveChanges.subscribe((result: any) => {

        });
    }

}
