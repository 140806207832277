
/* injects from baggage-loader */
require('./beta-reports.component.html');

import angular from 'angular';
import './reports.scss';
import './reports.css';

angular.module('AaApp.Opp').component('betaReports', {
    templateUrl: '/app/components/reports/beta-reports.component.html',
    controller: BetaReportsController,
});

/* @ngInject */
function BetaReportsController(_, $state, $sce, $location, reportService, tokenService, apiUrls, storageService, userService) {
    var $ctrl = this;
    $ctrl.reportsEnabled = false;
    $ctrl.reportEnabled = true;
    $ctrl.reportId;
    $ctrl.reportName = "";
    $ctrl.viewUrl = "";
    $ctrl.reportIsFavorite = false;
    $ctrl.reportPromise;
    $ctrl.reportSpecification;
    $ctrl.tableauUrl;
    $ctrl.$onInit = init;
    $ctrl.$onChanges = onChanges;
    $ctrl.tableauToken;
    $ctrl.accountPlatformIds;

    if ($state && $state.params) {
        if ($state.params.id) {
            $ctrl.reportId = $state.params.id;
        }
    }

    function onChanges(changes) {
        if (changes) {
            getReportDetails();
        }
    }

    function init() {
        $ctrl.reportEnabled = true;
        var moduleIds = storageService.getItem('moduleIds');
        var authorizedReports = reportService.getAuthorizedReports(moduleIds)
        $ctrl.reportsEnabled = authorizedReports.length > 0;
    }

    function getReportDetails() {
        userService.getUserProfile()
            .then(async (userProfile) => {
                if (userProfile && userProfile.accountPlatformIds && userProfile.accountPlatformIds.length > 0) {
                    $ctrl.accountPlatformIds = userProfile.accountPlatformIds.join(',');
                    $ctrl.reportingPlatformIds = $ctrl.accountPlatformIds;
                }
                reportService.getBetaReportList()
                    .then((results) => {
                        if (results && results.Success == true && results.Data && results.Data.length > 0) {
                            var reports = results.Data;
                            var currentBetaReport = _.find(reports, { Id: $ctrl.reportId });
                            if (currentBetaReport) {
                                $ctrl.reportName = currentBetaReport.Name;
                                $ctrl.reportIsFavorite = currentBetaReport.IsFavorite;
                                $ctrl.viewUrl = currentBetaReport.ViewUrl;
                                setReportUrl(currentBetaReport.IsEnterpriseReport);
                            }
                            else {
                                $ctrl.reportEnabled = false;
                            }
                        }
                        else {
                            $ctrl.reportEnabled = false;
                        }
                    });
            });
    }

    function setReportUrl(isEnterpriseReport) {
        reportService.getTableauToken(isEnterpriseReport)
            .then((results) => {
                if (results && results.Success == true) {
                    $ctrl.tableauToken = results.Data;
                    var url = apiUrls.betaReportsUrl + $ctrl.viewUrl;
                    $ctrl.tableauUrl = $sce.trustAsResourceUrl(url);
                }
            });

    }
}