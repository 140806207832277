OpenNewWindowController.$inject = ["$window", "dealSheetService"];

/* injects from baggage-loader */
require('./open-new-window.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('openNewWindow', {
  templateUrl: '/app/components/deal-sheet/open-new-window.component.html',
  controller: OpenNewWindowController,
  bindings: {
    opportunityId: '<',
    openLite: '<'
  }
});
/* @ngInject */

function OpenNewWindowController($window, dealSheetService) {
  var $ctrl = this;
  $ctrl.getDealSheetUrl = dealSheetService.getDealSheetUrl;
  $ctrl.openInNewWindow = openInNewWindow;

  function openInNewWindow() {
    var newWindowUrl = "";

    if ($ctrl.openLite) {
      newWindowUrl = dealSheetService.getDealSheetLiteUrl($ctrl.opportunityId);
    } else {
      newWindowUrl = dealSheetService.getDealSheetUrl($ctrl.opportunityId);
    }

    $window.open(newWindowUrl, '_blank');
  }
}