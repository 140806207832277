import { CookieStorage } from './cookie-storage.service';
import { Settings } from '../../constants/app-settings';
import { FactoryProvider, InjectionToken } from '@angular/core';
import { WINDOW_REF, WindowWrapper } from '../../services/window-ref.service';

export function crossDomainStorageServiceFactory(window: WindowWrapper) {
    return new CookieStorage(window,
        Settings.environmentVariables.crossDomainStorageDomain,
        Settings.environmentVariables.crossDomainStoragePrefix);
}

export const CrossDomainStorageService = new InjectionToken('CrossDomainStorageService');
export const crossDomainStorageServiceProvider: FactoryProvider = {
    provide: CrossDomainStorageService,
    useFactory: crossDomainStorageServiceFactory,
    deps: [WINDOW_REF]
};
