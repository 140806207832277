AddFinancialInfoController.$inject = ["$timeout", "opportunityTypes"];

/* injects from baggage-loader */
require('./add-financial-info.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('addFinancialInfo', {
  templateUrl: '/app/components/deal-sheet/financial/add-financial-info.component.html',
  controller: AddFinancialInfoController,
  bindings: {
    financials: '<',
    reset: '<',
    onCheck: '&'
  }
});
/* @ngInject */

function AddFinancialInfoController($timeout, opportunityTypes) {
  var $ctrl = this;

  $ctrl.$onInit = function () {
    _initDateOptions();

    _preProcessAssignTo();
  };

  $ctrl.calendarClick = function ($event) {
    $ctrl.status.opened = true;
  }; // We're wrapping this in a Angular $timeout to insure the validation is performed in the next
  // event loop no matter what.  There were times when the model values and input values weren't
  // synchronized causing inconsistent validation results.


  $ctrl.checkValidation = function () {
    $timeout(function () {
      $ctrl.onCheck({
        isValid: $ctrl.financialForm.$valid && $ctrl.financialForm.$dirty
      });
    });
  };

  function _initDateOptions() {
    Date.prototype.addDays = function (days) {
      var dat = new Date(this.valueOf());
      dat.setDate(dat.getDate() + days);
      return dat;
    };

    var date = new Date();
    $ctrl.status = {
      opened: false
    };
    $ctrl.dateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 0,
      showWeeks: false
    };
    $ctrl.minDate = date;
    $ctrl.maxDate = date.addDays(45);

    if (!$ctrl.financials.convert.dealDate || $ctrl.financials.convert.dealDate == '0001-01-01T00:00:00') {
      $ctrl.financials.convert.dealDate = date;
    } else {
      $ctrl.financials.convert.dealDate = new Date($ctrl.financials.convert.dealDate);
    }

    if ($ctrl.financials.opportunityType == opportunityTypes.conquest) {
      $ctrl.financials.convert.dealDate = null;
      date = null;
    }
  } // We're doing this to see if we need to perform any kind of preprocessing of the Assign To.  There
  // are 2 use cases where we need to do something.
  // 1. When the assignedUserFullName is null or empty.  This is considered an orphaned user and we're
  //    going to set $ctrl.financials.convert.soldByID = null to force an invalid Assign to on the form.
  // 2. When the currently assigned user id $ctrl.financials.convert.soldByID does not exist in the list
  //    of users $ctrl.financials.users.  This too is considered an orphaned user, but with a non-blank
  //    assignedUserFullName we're assuming the user has been disabled (enabled = 0).  In this case we're
  //    going to add this user to $ctrl.financials.users so it shows up as selected on the form.


  function _preProcessAssignTo() {
    var assignedUserFullName = $ctrl.financials.assignedUserFullName;
    var users = $ctrl.financials.users; // force user to select user if originalOwnerUserID is null (orphan case) 
    // or if originalOwnerUserID == defaultUserID which also indicates an orphan case
    // or the soldByID == defaultUserID indicating that the current owner is disabled and soldByID was set to defaultuserID

    if (assignedUserFullName === null || assignedUserFullName.trim() === '' || $ctrl.financials.convert.originalOwnerUserID == null || $ctrl.financials.convert.originalOwnerUserID == $ctrl.financials.convert.defaultUserID || $ctrl.financials.convert.soldByID == $ctrl.financials.convert.defaultUserID) {
      $ctrl.financials.convert.soldByID = null;
      return;
    }

    var assignedUserID = $ctrl.financials.convert.soldByID;

    for (var i = 0; i < users.length; i++) {
      if (users[i].id === assignedUserID) return;
    }

    users.push({
      id: assignedUserID,
      fullName: assignedUserFullName
    });
  }
}