"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var PayoffInputModalComponent = /** @class */ (function () {
    function PayoffInputModalComponent(_bsModalRef, tService) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.showAmountMsg = false;
        this.minValidValue = 1;
        this.onSaveChanges = new core_1.EventEmitter();
    }
    PayoffInputModalComponent.prototype.ngOnInit = function () {
        this._bsModalRef.hide();
        var minDate = new Date();
        var maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + PayoffInputModalComponent.payoffGoodThroughDays);
        this.dateOptions = {
            formatYear: 'yy',
            minDate: minDate,
            maxDate: maxDate,
            startingDay: 0,
            showWeeks: false
        };
        if (this.minPayoff && typeof this.minPayoff === 'number' && this.minPayoff > 0) {
            this.minValidValue = Math.ceil(this.minPayoff);
        }
        else {
            this.minValidValue = 1;
        }
    };
    PayoffInputModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    PayoffInputModalComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
    };
    PayoffInputModalComponent.prototype.setGoodThroughDate = function (date, model) {
        this.goodThroughDateModel = model;
        this.goodThroughDate = date;
    };
    PayoffInputModalComponent.prototype.setShowAmountMsg = function () {
        this.showAmountMsg = !this.payoffForm.controls['payoffAmount'].pristine && !this.payoffForm.controls['payoffAmount'].valid;
    };
    PayoffInputModalComponent.prototype.savePayoff = function (payoffForm) {
        if (payoffForm.valid) {
            this.onSaveChanges.emit({ payoffAmount: this.payoffAmount, goodThroughDate: this.goodThroughDate });
            this._bsModalRef.hide();
        }
    };
    PayoffInputModalComponent.payoffGoodThroughDays = 15;
    return PayoffInputModalComponent;
}());
exports.PayoffInputModalComponent = PayoffInputModalComponent;
