<div class="action-menu"
    *ngIf="subAction === activityActionEnum.Log_Call || subAction === activityActionEnum.Post_Note">
    <div class="row">
        <div class="comm-tools"
            *ngIf="shortCutActivityActionEnum === null || shortCutActivityActionEnum === activityActionEnum.Log_Call">
            <input class="label-top-buffer" id="logCallActivity" type="radio"
                (change)="onAction.emit({action: activityActionEnum.Log_Call})" [value]="activityActionEnum.Log_Call"
                [(ngModel)]="selectedLogAction" name="logCallLogNote" />&nbsp;
            <icon class="icon lg aa-icon-core-phone-icon-000000"></icon>&nbsp;
            <label class="aa-padding-right" for="logCallActivity" translate="logCallActivity"></label>
        </div>
        <div class="comm-tools wide-tools"
            *ngIf="shortCutActivityActionEnum === null || shortCutActivityActionEnum === activityActionEnum.Post_Note">
            <input class="label-top-buffer" id="logNoteActivity" [checked]="subAction === activityActionEnum.Post_Note"
                type="radio" (change)="onAction.emit({action: activityActionEnum.Post_Note})"
                [value]="activityActionEnum.Post_Note" [(ngModel)]="selectedLogAction" name="logCallLogNote" />&nbsp;
            <icon class="icon lg aa-icon-core-takenote-icon-000000"></icon>&nbsp;
            <label class="aa-padding-right" for="logNoteActivity" translate="logNoteActivity"></label>
        </div>
    </div>
</div>
<div class="action-menu"
    *ngIf="subAction === activityActionEnum.Schedule_Appt || subAction === activityActionEnum.Schedule_Call">
    <div class="row">
        <div class="comm-tools">
            <input class="label-top-buffer" id="scheduleAppointmentActivity" type="radio"
                (change)="onAction.emit({action: activityActionEnum.Schedule_Appt})"
                [value]="activityActionEnum.Schedule_Appt" [(ngModel)]="selectedScheduleAction" />&nbsp;
            <icon class="icon lg aa-icon-core-calendar-icon-000000"></icon>&nbsp;
            <label class="aa-padding-right" for="scheduleAppointmentActivity"
                translate="scheduleAppointmentActivity"></label>
        </div>
        <div class="comm-tools">
            <input class="label-top-buffer" id="scheduleCallActivity" type="radio"
                (change)="onAction.emit({action: activityActionEnum.Schedule_Call})"
                [value]="activityActionEnum.Schedule_Call" [(ngModel)]="selectedScheduleAction" />&nbsp;
            <icon class="icon lg aa-icon-core-schedule-phone-call-000002"></icon>&nbsp;
            <label class="aa-padding-right" for="scheduleCallActivity" translate="scheduleCallActivity"></label>
        </div>
    </div>
</div>