import angular from "angular";
import moduleName from "./module"
import { downgradeInjectable } from '@angular/upgrade/static';

import { StorageService } from "./storage.service";
import { CrossDomainStorageService } from "./cross-domain-storage.service";

export default moduleName;

angular.module(moduleName)
    .factory("storageService", downgradeInjectable(StorageService))
    .factory("crossDomainStorageService", downgradeInjectable(CrossDomainStorageService))
