"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.EngagementEventGroups = {
    one2one: 1,
    email: 2,
    web: 3,
    service: 4,
    reviews: 5,
    text: 6,
    autoAssistant: 7,
    intelligentMarketing: 8,
    geoAlert: 9
};
exports.EngagementEventTypes = {
    newServiceAppointment: 1,
    inService: 2,
    emailOpened: 3,
    emailReplied: 4,
    purlView: 5,
    purlLeadSubmission: 6,
    websiteEngaged: 7,
    websiteLeadSubmission: 8,
    onlineServiceReview: 9,
    onlineSalesReview: 10,
    textMessageReplied: 11,
    customerRequestedContact: 12,
    purlViewIM: 13,
    purlLeadSubmissionIM: 14,
    activeGeoAlert: 15
};
exports.EngagementEventGroupsByType = (_a = {},
    _a[exports.EngagementEventTypes.newServiceAppointment] = exports.EngagementEventGroups.service,
    _a[exports.EngagementEventTypes.inService] = exports.EngagementEventGroups.service,
    _a[exports.EngagementEventTypes.emailOpened] = exports.EngagementEventGroups.email,
    _a[exports.EngagementEventTypes.emailReplied] = exports.EngagementEventGroups.email,
    _a[exports.EngagementEventTypes.purlView] = exports.EngagementEventGroups.one2one,
    _a[exports.EngagementEventTypes.purlLeadSubmission] = exports.EngagementEventGroups.one2one,
    _a[exports.EngagementEventTypes.websiteEngaged] = exports.EngagementEventGroups.web,
    _a[exports.EngagementEventTypes.websiteLeadSubmission] = exports.EngagementEventGroups.web,
    _a[exports.EngagementEventTypes.onlineServiceReview] = exports.EngagementEventGroups.reviews,
    _a[exports.EngagementEventTypes.onlineSalesReview] = exports.EngagementEventGroups.reviews,
    _a[exports.EngagementEventTypes.textMessageReplied] = exports.EngagementEventGroups.text,
    _a[exports.EngagementEventTypes.customerRequestedContact] = exports.EngagementEventGroups.autoAssistant,
    _a[exports.EngagementEventTypes.purlViewIM] = exports.EngagementEventGroups.intelligentMarketing,
    _a[exports.EngagementEventTypes.purlLeadSubmissionIM] = exports.EngagementEventGroups.intelligentMarketing,
    _a[exports.EngagementEventTypes.activeGeoAlert] = exports.EngagementEventGroups.geoAlert,
    _a);
