<div class="modal-wrapper">
        <div class="alert-box">
            <div class="modal-header">
                <div style="float:left">
                    <h5 class="modal-title" translate="dmsPushDialogTitle"></h5>
                </div>
                <div style="float:right">
                    <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
                        <icon class="icon aa-icon-close-icon"></icon>
                    </button>
                </div>
            </div>
            <div [ngBusy]="{busy: busy, delay: 200}" class="modal-body form-horizontal">

                <!-- Initial page showing customer/vehicle data  -->
                <ng-container *ngIf="currentView == DisplayView.initialView">
                    <div class="row intro">
                        <div class="col-sm-12">
                            <p translate="dmsPushDialogInitialHeader1"></p>
                            <p *ngIf="!replacementVin" translate="dmsPushDialogInitialHeader2"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">

                            <div class="row">
                                <label class="col-sm-12 sectionHeader" translate="dmsCustomerLabel"></label>
                            </div>
                            <div class="row dms-row">
                                <label class="col-sm-4" translate="dmsNameLabel"></label>
                                <div class="col-sm-8 bold">
                                    <span>{{customer.fullName}}</span>
                                </div>
                            </div>
                            <div class="row dms-row">
                                <label class="col-sm-4" translate="dmsAddressLabel"></label>
                                <div class="col-sm-8 bold">
                                    <span>{{address1}}</span><br />
                                    <span *ngIf="address2">{{address2}}<br /></span>
                                    <span>{{cityStZip}}</span>
                                </div>
                            </div>
                            <div class="row dms-row">
                                <label class="col-sm-4" translate="dmsPrimaryPhoneLabel"></label>
                                <div class="col-sm-8 bold">
                                    <span>{{primaryPhone | localePhone}}</span>
                                </div>
                            </div>
                            <div class="row dms-row">
                                <label class="col-sm-4" translate="dmsEmailAddressLabel"></label>
                                <div class="col-sm-8 bold">
                                    <span>{{customer.emailAddress?.value}}</span>
                                </div>
                            </div>
                            <ng-container *ngIf="latestCreateDateUtc">
                                <div class="row" style="margin-top:25px;">
                                    <label class="col-sm-12 sectionHeader" translate="dmsLatestPush"></label>
                                </div>
                                <div class="row dms-row">
                                    <div class="col-sm-12">
                                        <span style="font-weight:bold;">{{latestCreateDateUtc | localeDateTime }}</span>&nbsp;
                                        <span>{{latestRequestType}} <span translate="dmsPushBy"></span> {{latestFullName}}</span>
                                        <span *ngIf="latestDmsCustomerId"><br><span translate="dmsCustomerIdLabel"></span>: {{latestDmsCustomerId}}</span>
                                        <span *ngIf="latestDmsDealId"><br><span translate="dmsDealIdLabel"></span>: {{latestDmsDealId}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-sm-6">

                            <div class="row dms-row" [ngClass]="{'disabled-deal': !replacementVin}">
                                <div class="col-sm-12">
                                    <label class="bold"><input type="checkbox" [disabled]="!replacementVin" [(ngModel)]="includeDeal" />&nbsp;&nbsp;<span translate="dmsIncludeDealLabel"></span></label>
                                </div>
                            </div>
                            <div [ngClass]="{'disabled-deal': (includeDeal === false || !replacementVin)}">
                                <div class="row dms-row">
                                    <label class="col-sm-12 sectionHeader" translate="dmsVOILabel"></label>
                                </div>
                                <div class="row dms-row">
                                    <label class="col-sm-4" translate="dmsVinLabel"></label>
                                    <div class="col-sm-8 bold">
                                        <span>{{replacementVin == null ? 'N/A' : replacementVin}}</span>
                                    </div>
                                </div>
                                <div class="row dms-row">
                                    <label class="col-sm-4" translate="dmsSellingPriceLabel"></label>
                                    <div class="col-sm-8 bold">
                                        <span>{{replacementPrice | localeCurrency}}</span>
                                    </div>
                                </div>
                                <div class="row" style="margin-top:25px;">
                                    <label class="col-sm-12 sectionHeader" translate="dmsTradeVehicleLabel"></label>
                                </div>
                                <div class="row dms-row">
                                    <label class="col-sm-4" translate="dmsVinLabel"></label>
                                    <div class="col-sm-8 bold">
                                        <span>{{currentVin}}</span>
                                    </div>
                                </div>
                                <div class="row dms-row">
                                    <label class="col-sm-4" translate="dmsValueLabel"></label>
                                    <div class="col-sm-8 bold">
                                        <span>{{currentTradeValue | localeCurrency}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>





                </ng-container>

                <!-- Multiple customer select view  -->
                <ng-container *ngIf="currentView == DisplayView.customerSelectView">
                    <div class="row intro">
                        <div class="col-sm-9">
                            <p *ngIf="customerMatches.length > 1"><span translate="dmsCustomerMatchTextMulti" [translateParams]="{ customerMatchesCount: this.customerMatches.length | localeNumber }"></span></p>
                            <p *ngIf="customerMatches.length == 1"><span translate="dmsCustomerMatchTextOne"></span></p>
                        </div>
                        <div class="col-sm-3 text-right" style="padding-right: 60px;">
                            <button type="button" class="btn btn-primary" (click)="processNewCustomer()" translate="dmsAddNewButtonText"></button>
                        </div>
                    </div>
                    <div class="row dms-customer-list">
                        <div class="col-sm-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th translate="dmsNameLabel"></th>
                                        <th translate="dmsIdLabel"></th>
                                        <th translate="dmsPhoneLabel"></th>
                                        <th translate="dmsEmailLabel"></th>
                                        <th translate="dmsAddressLabel"></th>
                                        <th class="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of customerMatches" [class.highlighted]="row.dmsCustomerId === customerSelection">
                                        <td class="highlight">{{row.firstName}} {{row.lastName}}</td>
                                        <td>{{row.dmsCustomerId}}</td>
                                        <td>{{((row.phoneNumber1 == null) ? row.phoneNumber2 : row.phoneNumber1) | localePhone}}</td>
                                        <td>{{row.email1}}</td>
                                        <td>{{showFullAddress(row)}}</td>
                                        <td class="text-center">
                                            <button *ngIf="currentView == DisplayView.customerSelectView" type="button" class="btn btn-primary btn-sm" (click)="processSelectedCustomer(row.dmsCustomerId)" translate="dmsSelectButtonText"></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </ng-container>

                <!-- New or update Customer view  -->
                <ng-container *ngIf="currentView == DisplayView.customerPushedView">
                    <div class="row intro">
                        <div class="col-sm-12">
                            <span translate="dmsCustomerAddedText"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label class="dms-colon-suffix dms-id-label" translate="dmsCustomerIdLabel"></label> <span>{{newOrUpdatedDMSCustomerID}}</span>
                        </div>
                    </div>
                </ng-container>

                <!-- New or update Deal view  -->
                <ng-container *ngIf="currentView == DisplayView.dealPushedView">
                    <div class="row intro">
                        <div class="col-sm-12">
                            <span translate="dmsDealAddedText"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label class="dms-colon-suffix dms-id-label" translate="dmsDeal_IdLabel"></label> <span>{{newOrUpdatedDMSDealID}}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentView == DisplayView.errorView">
                    <div class="row intro">
                        <div class="col-sm-12">
                            <span translate="dmsErrorMessageText"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            {{dmsErrorMsg}}
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentView == DisplayView.waitingView">
                    <div class="row intro">
                        <div class="col-sm-12">
                            <span translate="dmsPleaseWaitText"></span>
                        </div>
                    </div>
                </ng-container>

            </div>
            <div class="modal-footer">
                <button *ngIf="!showCloseButton" type="button" class="btn btn-primary" (click)="cancelModal()" translate="cancel"></button>
                <button *ngIf="showCloseButton" type="button" class="btn btn-primary" (click)="cancelModal()" translate="close"></button>
                <button *ngIf="currentView == DisplayView.initialView" type="button" class="btn btn-primary" (click)="pushToDms()" translate="dmsPushButtonText"></button>
            </div>
        </div>
</div>