import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'capital-cost',
  templateUrl: './capital-cost.component-ng.html',
  styleUrls: ['./capital-cost.component.scss']
})
export class CapitalCostComponent implements OnInit {

  @Input() value: number;

  constructor() { }

  ngOnInit() {
  }

}
