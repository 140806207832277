"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var CustomerClientService = /** @class */ (function (_super) {
    __extends(CustomerClientService, _super);
    function CustomerClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param entityId undefined
     * @return Success
     */
    CustomerClientService.prototype.ByEntityByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Customer/ByEntity/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    CustomerClientService.prototype.ByEntityByEntityidGET = function (entityId) {
        return this.ByEntityByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    CustomerClientService.prototype.GetNameOnlyByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Customer/NameByEntity/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    CustomerClientService.prototype.GetNameOnlyByEntityidGET = function (entityId) {
        return this.GetNameOnlyByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerMarkDoNotEmailPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Customer/Customer_Mark_DoNotEmail", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerMarkDoNotEmailPOST = function (request) {
        return this.CustomerMarkDoNotEmailPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param sessionID undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerGetDoNotEmailBySessionidGETResponse = function (sessionID) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Customer/Customer_Get_DoNotEmail/" + sessionID), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param sessionID undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerGetDoNotEmailBySessionidGET = function (sessionID) {
        return this.CustomerGetDoNotEmailBySessionidGETResponse(sessionID).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerMarkDoNotCallPOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Customer/Customer_Mark_DoNotCall", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerMarkDoNotCallPOST = function (request) {
        return this.CustomerMarkDoNotCallPOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param sessionID undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerGetDoNotCallBySessionidGETResponse = function (sessionID) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Customer/Customer_Get_DoNotCall/" + sessionID), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param sessionID undefined
     * @return Success
     */
    CustomerClientService.prototype.CustomerGetDoNotCallBySessionidGET = function (sessionID) {
        return this.CustomerGetDoNotCallBySessionidGETResponse(sessionID).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    CustomerClientService.prototype.PUTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('PUT', this.rootUrl + "/api/Customer", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    CustomerClientService.prototype.PUT = function (data) {
        return this.PUTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    CustomerClientService.prototype.POSTResponse = function (data) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = data;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Customer", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    CustomerClientService.prototype.POST = function (data) {
        return this.POSTResponse(data).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param customerProfile undefined
     */
    CustomerClientService.prototype.UpdatePATCHResponse = function (customerProfile) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = customerProfile;
        var req = new http_1.HttpRequest('PATCH', this.rootUrl + "/api/Customer", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param customerProfile undefined
     */
    CustomerClientService.prototype.UpdatePATCH = function (customerProfile) {
        return this.UpdatePATCHResponse(customerProfile).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `CustomerClientService.UpdateCustomerByUpdatinguseridPOSTParams` containing the following parameters:
     *
     * - `updatingUserId`:
     *
     * - `customerProfile`:
     */
    CustomerClientService.prototype.UpdateCustomerByUpdatinguseridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.customerProfile;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Customer/UpdateCustomer/" + params.updatingUserId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `CustomerClientService.UpdateCustomerByUpdatinguseridPOSTParams` containing the following parameters:
     *
     * - `updatingUserId`:
     *
     * - `customerProfile`:
     */
    CustomerClientService.prototype.UpdateCustomerByUpdatinguseridPOST = function (params) {
        return this.UpdateCustomerByUpdatinguseridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param emailaddresslist undefined
     * @return Success
     */
    CustomerClientService.prototype.InsertDoNotEmailStagingPOSTResponse = function (emailaddresslist) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = emailaddresslist;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Customer/Insert_DoNotEmailStaging", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param emailaddresslist undefined
     * @return Success
     */
    CustomerClientService.prototype.InsertDoNotEmailStagingPOST = function (emailaddresslist) {
        return this.InsertDoNotEmailStagingPOSTResponse(emailaddresslist).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param phoneNumberList undefined
     * @return Success
     */
    CustomerClientService.prototype.InsertDoNotCallStagingPOSTResponse = function (phoneNumberList) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = phoneNumberList;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Customer/Insert_DoNotCallStaging", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param phoneNumberList undefined
     * @return Success
     */
    CustomerClientService.prototype.InsertDoNotCallStagingPOST = function (phoneNumberList) {
        return this.InsertDoNotCallStagingPOSTResponse(phoneNumberList).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param customerPreferredLanguage undefined
     * @return Success
     */
    CustomerClientService.prototype.UpdatePreferredLanguagePOSTResponse = function (customerPreferredLanguage) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = customerPreferredLanguage;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Customer/UpdatePreferredLanguage", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param customerPreferredLanguage undefined
     * @return Success
     */
    CustomerClientService.prototype.UpdatePreferredLanguagePOST = function (customerPreferredLanguage) {
        return this.UpdatePreferredLanguagePOSTResponse(customerPreferredLanguage).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    CustomerClientService.prototype.ByIdGETResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Customer/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    CustomerClientService.prototype.ByIdGET = function (id) {
        return this.ByIdGETResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    CustomerClientService.prototype.ByIdDELETEResponse = function (id) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('DELETE', this.rootUrl + ("/api/Customer/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    CustomerClientService.prototype.ByIdDELETE = function (id) {
        return this.ByIdDELETEResponse(id).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    CustomerClientService.prototype.GetManyGETResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        (ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Customer/GetMany", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    CustomerClientService.prototype.GetManyGET = function (ids) {
        return this.GetManyGETResponse(ids).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    CustomerClientService.ByEntityByEntityidGETPath = '/api/Customer/ByEntity/{entityId}';
    CustomerClientService.GetNameOnlyByEntityidGETPath = '/api/Customer/NameByEntity/{entityId}';
    CustomerClientService.CustomerMarkDoNotEmailPOSTPath = '/api/Customer/Customer_Mark_DoNotEmail';
    CustomerClientService.CustomerGetDoNotEmailBySessionidGETPath = '/api/Customer/Customer_Get_DoNotEmail/{sessionID}';
    CustomerClientService.CustomerMarkDoNotCallPOSTPath = '/api/Customer/Customer_Mark_DoNotCall';
    CustomerClientService.CustomerGetDoNotCallBySessionidGETPath = '/api/Customer/Customer_Get_DoNotCall/{sessionID}';
    CustomerClientService.PUTPath = '/api/Customer';
    CustomerClientService.POSTPath = '/api/Customer';
    CustomerClientService.UpdatePATCHPath = '/api/Customer';
    CustomerClientService.UpdateCustomerByUpdatinguseridPOSTPath = '/api/Customer/UpdateCustomer/{updatingUserId}';
    CustomerClientService.InsertDoNotEmailStagingPOSTPath = '/api/Customer/Insert_DoNotEmailStaging';
    CustomerClientService.InsertDoNotCallStagingPOSTPath = '/api/Customer/Insert_DoNotCallStaging';
    CustomerClientService.UpdatePreferredLanguagePOSTPath = '/api/Customer/UpdatePreferredLanguage';
    CustomerClientService.ByIdGETPath = '/api/Customer/{id}';
    CustomerClientService.ByIdDELETEPath = '/api/Customer/{id}';
    CustomerClientService.GetManyGETPath = '/api/Customer/GetMany';
    CustomerClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerClientService_Factory() { return new CustomerClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: CustomerClientService, providedIn: "root" });
    return CustomerClientService;
}(base_service_1.BaseService));
exports.CustomerClientService = CustomerClientService;
