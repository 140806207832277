"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./confirmation-modal.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("../../../modals/modal/modal.component.ngfactory"));
var i3 = __importStar(require("../../../modals/modal/modal.component"));
var i4 = __importStar(require("@ngx-translate/core"));
var i5 = __importStar(require("@angular/platform-browser"));
var i6 = __importStar(require("./confirmation-modal.component"));
var i7 = __importStar(require("ngx-bootstrap/modal"));
var styles_ConfirmationModalComponent = [i0.styles];
var RenderType_ConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationModalComponent, data: {} });
exports.RenderType_ConfirmationModalComponent = RenderType_ConfirmationModalComponent;
function View_ConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "modal", [], null, [[null, "onClose"], [null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } if (("onSubmit" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(1, 638976, null, 0, i3.ModalComponent, [i4.TranslateService, i5.DomSanitizer], { headerTitle: [0, "headerTitle"], submitButtonVisible: [1, "submitButtonVisible"], submitButtonDisabled: [2, "submitButtonDisabled"], submitButtonText: [3, "submitButtonText"], closeButtonText: [4, "closeButtonText"], headerTextClass: [5, "headerTextClass"] }, { onClose: "onClose", onSubmit: "onSubmit" }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.submitButtonVisible; var currVal_2 = _co.submitButtonDisabled; var currVal_3 = _co.submitButtonText; var currVal_4 = _co.closeButtonText; var currVal_5 = _co.headerTextClass; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.message, ""); _ck(_v, 4, 0, currVal_6); }, null); }
exports.View_ConfirmationModalComponent_0 = View_ConfirmationModalComponent_0;
function View_ConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirmation-modal", [], null, null, null, View_ConfirmationModalComponent_0, RenderType_ConfirmationModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConfirmationModalComponent, [i7.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ConfirmationModalComponent_Host_0 = View_ConfirmationModalComponent_Host_0;
var ConfirmationModalComponentNgFactory = i1.ɵccf("confirmation-modal", i6.ConfirmationModalComponent, View_ConfirmationModalComponent_Host_0, {}, {}, []);
exports.ConfirmationModalComponentNgFactory = ConfirmationModalComponentNgFactory;
