"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var locale_date_pipe_1 = require("../../../../filters/locale-date.pipe");
var core_2 = require("@ngx-translate/core");
var RepairOrdersComponent = /** @class */ (function () {
    function RepairOrdersComponent(localeDate, translateService) {
        this.localeDate = localeDate;
        this.translateService = translateService;
        this.recentRepairOrder = "";
    }
    RepairOrdersComponent.prototype.ngOnInit = function () {
        this.idUnique = 'repair-orders' + (Date.now() + (Math.floor(Math.random() * 1000))).toString();
    };
    RepairOrdersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.date && !changes.date.isFirstChange() && changes.date.currentValue !== null) {
            var repairOrderDate = this.localeDate.transform(this.date);
            if (changes.total && changes.total.currentValue !== null) {
                var translateKey = this.getTranslateKey();
                this.recentRepairOrder = this.translateService.instant(translateKey, { latestDate: repairOrderDate, total: this.total });
            }
            else {
                this.recentRepairOrder = repairOrderDate;
            }
        }
    };
    RepairOrdersComponent.prototype.getTranslateKey = function () {
        var key;
        if (this.abbreviate) {
            key = constants_1.TranslateKeys.recentRepairOrdersAbbreviatedKey;
        }
        else {
            key = constants_1.TranslateKeys.recentRepairOrdersKey;
            if (this.total === 1) {
                key += constants_1.TranslateKeys.SingularKey;
            }
        }
        return key;
    };
    return RepairOrdersComponent;
}());
exports.RepairOrdersComponent = RepairOrdersComponent;
