"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
// Temporary holding bucket for angularjs services that are needed in angular. We should upgrade these for real asap.
// CoreApiService
exports.CoreApiService = new core_1.InjectionToken('CoreApiService');
function coreApiServiceFactory(i) {
    return i.get('coreApi');
}
exports.coreApiServiceFactory = coreApiServiceFactory;
exports.coreApiServiceProvider = {
    provide: exports.CoreApiService,
    useFactory: coreApiServiceFactory,
    deps: ['$injector']
};
// LegacyService
exports.LegacyService = new core_1.InjectionToken('LegacyService');
function legacyServiceFactory(i) {
    return i.get('legacyService');
}
exports.legacyServiceFactory = legacyServiceFactory;
exports.legacyServiceProvider = {
    provide: exports.LegacyService,
    useFactory: legacyServiceFactory,
    deps: ['$injector']
};
// Auth
exports.AuthService = new core_1.InjectionToken('AuthService');
function authServiceFactory(i) {
    return i.get('$auth');
}
exports.authServiceFactory = authServiceFactory;
exports.authServiceProvider = {
    provide: exports.AuthService,
    useFactory: authServiceFactory,
    deps: ['$injector']
};
// OpportunityService
exports.OpportunityService = new core_1.InjectionToken('OpportunityService');
function opportunityServiceFactory(i) {
    return i.get('opportunityService');
}
exports.opportunityServiceFactory = opportunityServiceFactory;
exports.opportunityServiceProvider = {
    provide: exports.OpportunityService,
    useFactory: opportunityServiceFactory,
    deps: ['$injector']
};
// DealSheetService
exports.DealSheetService = new core_1.InjectionToken('DealSheetService');
function dealSheetServiceFactory(i) {
    return i.get('dealSheetService');
}
exports.dealSheetServiceFactory = dealSheetServiceFactory;
exports.dealSheetServiceProvider = {
    provide: exports.DealSheetService,
    useFactory: dealSheetServiceFactory,
    deps: ['$injector']
};
// RecentDealSheetService
exports.RecentDealSheetService = new core_1.InjectionToken('RecentDealSheetService');
function recentDealSheetServiceFactory(i) {
    return i.get('recentDealSheetService');
}
exports.recentDealSheetServiceFactory = recentDealSheetServiceFactory;
exports.recentDealSheetServiceProvider = {
    provide: exports.RecentDealSheetService,
    useFactory: recentDealSheetServiceFactory,
    deps: ['$injector']
};
// TemplateService
exports.TemplateService = new core_1.InjectionToken('TemplateService');
function templateServiceFactory(i) {
    return i.get('templateService');
}
exports.templateServiceFactory = templateServiceFactory;
exports.templateServiceProvider = {
    provide: exports.TemplateService,
    useFactory: templateServiceFactory,
    deps: ['$injector']
};
//$rootScope
exports.$rootScope = new core_1.InjectionToken('$rootScope');
function rootScopeFactory(i) {
    return i.get('$rootScope');
}
exports.rootScopeFactory = rootScopeFactory;
exports.rootScopeProvider = {
    provide: exports.$rootScope,
    useFactory: rootScopeFactory,
    deps: ['$injector']
};
// EulaService
exports.EulaModalService = new core_1.InjectionToken('EulaModalService');
function eulaModalServiceFactory(i) {
    return i.get('eulaModalService');
}
exports.eulaModalServiceFactory = eulaModalServiceFactory;
exports.eulaModalServiceProvider = {
    provide: exports.EulaModalService,
    useFactory: eulaModalServiceFactory,
    deps: ['$injector']
};
// VehicleService
exports.VehicleService = new core_1.InjectionToken('VehicleService');
function vehicleServiceFactory(i) {
    return i.get('vehicleService');
}
exports.vehicleServiceFactory = vehicleServiceFactory;
exports.vehicleServiceProvider = {
    provide: exports.VehicleService,
    useFactory: vehicleServiceFactory,
    deps: ['$injector']
};
// ContractService
exports.ContractService = new core_1.InjectionToken('ContractService');
function contractServiceFactory(i) {
    return i.get('contractService');
}
exports.contractServiceFactory = contractServiceFactory;
exports.contractServiceProvider = {
    provide: exports.ContractService,
    useFactory: contractServiceFactory,
    deps: ['$injector']
};
// MapUtilService
exports.MapUtilsService = new core_1.InjectionToken('MapUtilsService');
function mapUtilsServiceFactory(i) {
    return i.get('mapUtils');
}
exports.mapUtilsServiceFactory = mapUtilsServiceFactory;
exports.mapUtilsServiceProvider = {
    provide: exports.MapUtilsService,
    useFactory: mapUtilsServiceFactory,
    deps: ['$injector']
};
// MapUtilService
exports.TmhDynamicLocale = new core_1.InjectionToken('TmhDynamicLocale');
function tmhDynamicLocaleFactory(i) {
    return i.get('tmhDynamicLocale');
}
exports.tmhDynamicLocaleFactory = tmhDynamicLocaleFactory;
exports.tmhDynamicLocaleProvider = {
    provide: exports.TmhDynamicLocale,
    useFactory: tmhDynamicLocaleFactory,
    deps: ['$injector']
};
