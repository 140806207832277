FinancialsRetailSaleController.$inject = ["$q", "$timeout", "$document", "$uibModal", "monthlyPaymentAmountKey", "financedAmountKey", "aprKey", "financeFieldPromptTitleKey", "financeFieldPromptMessageKey"];

/* injects from baggage-loader */
require('./financials-retail-sale.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('financialsRetailSale', {
  templateUrl: '/app/components/deal-sheet/financial/financials-retail-sale.component.html',
  controller: FinancialsRetailSaleController,
  bindings: {
    financials: '<',
    financialForm: '<',
    reset: '<',
    checkValidation: '&'
  }
});
/* @ngInject */

function FinancialsRetailSaleController($q, $timeout, $document, $uibModal, monthlyPaymentAmountKey, financedAmountKey, aprKey, financeFieldPromptTitleKey, financeFieldPromptMessageKey) {
  var $ctrl = this;
  $ctrl.retailFields;
  $ctrl.promptActive;
  $ctrl.onRetailFieldChange = onRetailFieldChange;
  $ctrl.$onChanges = onChanges;
  $ctrl.$onInit = init;

  function onChanges(changes) {
    if (changes.reset && !changes.reset.isFirstChange()) {
      resetFinancials();
    }
  }

  function init() {
    $ctrl.retailFields = {
      payment: 1,
      financedAmount: 2,
      apr: 3
    };
    $ctrl.promptActive = false;
    $ctrl.checkValidation();
  }

  $ctrl.calcPurchaseRate = function () {
    $ctrl.financials.convert.purchaseRate = null;
    $ctrl.calc();
  };

  $ctrl.calc = function () {
    _doRetailCalc();

    $ctrl.checkValidation();
  };

  function _doRetailCalc() {
    if (!$ctrl.financials.convert.termID) {
      return;
    }

    if ($ctrl.financials.convert.purchaseAmount && $ctrl.financials.convert.purchaseRate && !$ctrl.financials.convert.purchasePayment) {
      _retailPaymentByRateAndAmount();
    } else if ($ctrl.financials.convert.purchaseAmount && $ctrl.financials.convert.purchasePayment && !$ctrl.financials.convert.purchaseRate) {
      _retailRateByAmountAndPayment();
    } else if ($ctrl.financials.convert.purchaseRate && $ctrl.financials.convert.purchasePayment && !$ctrl.financials.convert.purchaseAmount) {
      _retailAmountByRateAndPayment();
    } else {
      return;
    }
  }

  function _retailRateByAmountAndPayment() {
    var termId = $ctrl.financials.convert.termID;
    var payment = $ctrl.financials.convert.purchasePayment;
    var amount = $ctrl.financials.convert.purchaseAmount;
    var rl = -0.001 / 1200;
    var rr = 30.00 / 1200;
    var iterationCount = 20; // max iteration count

    for (var i = 0; i <= iterationCount; i++) {
      if (i == iterationCount) {
        return '';
      }

      var rm = (rr + rl) / 2;
      var amt_l = payment / (Math.pow(1 + rl, termId) * rl) * (Math.pow(1 + rl, termId) - 1);
      var amt_r = payment / (Math.pow(1 + rr, termId) * rr) * (Math.pow(1 + rr, termId) - 1);
      var amt_m = payment / (Math.pow(1 + rm, termId) * rm) * (Math.pow(1 + rm, termId) - 1);

      if (amount <= amt_l && amount >= amt_m) {
        rr = rm;
      } else {
        rl = rm;
      }

      if (Math.abs(amount - amt_m) < .5) {
        break;
      }
    }

    if (rl < 0) {
      rl = 0;
      rr = 0;
    }

    var result = Math.floor((rr + rl) / 2 * 120000) / 100;
    $ctrl.financials.convert.purchaseRate = result;
  }

  function _retailAmountByRateAndPayment() {
    var termId = $ctrl.financials.convert.termID;
    var payment = $ctrl.financials.convert.purchasePayment;

    var apr = _getAprFromPurchaseRate();

    var power = Math.pow(1 + apr, termId);
    var result;

    if (apr > 0) {
      result = Math.ceil(payment / (apr * power / (power - 1)));
    } else {
      result = Math.ceil(payment * termId);
    }

    $ctrl.financials.convert.purchaseAmount = result;
  }

  function _retailPaymentByRateAndAmount() {
    var termId = $ctrl.financials.convert.termID;
    var amount = $ctrl.financials.convert.purchaseAmount;

    var apr = _getAprFromPurchaseRate();

    var power = Math.pow(1 + apr, termId);
    var result;

    if (apr > 0) {
      result = Math.ceil(amount * (apr * power / (power - 1)));
    } else {
      result = Math.ceil(amount / termId);
    }

    $ctrl.financials.convert.purchasePayment = result;
  }

  function _getAprFromPurchaseRate() {
    var rate = $ctrl.financials.convert.purchaseRate;
    var apr = rate / 1200;
    return apr;
  }

  function resetFinancials() {
    $ctrl.financials.convert.soldByID = null;
    $ctrl.financials.convert.selectedBank = null;
    $ctrl.financials.convert.termID = null;
    $ctrl.financials.convert.purchasePayment = null;
    $ctrl.financials.convert.purchaseRate = null;
    $ctrl.financials.convert.purchaseAmount = null;
    $ctrl.financials.convert.cashDown = null;
    $ctrl.financials.convert.mileageAtDelivery = null;
    $ctrl.financials.convert.soldAsNew = true;
    $ctrl.financials.convert.hasExtWarranty = false;
    $ctrl.financialForm.$setPristine();
    $ctrl.checkValidation();
  }

  function onRetailFieldChange(retailField) {
    if ($ctrl.financials.convert.purchaseAmount && $ctrl.financials.convert.purchaseRate && $ctrl.financials.convert.purchasePayment) {
      if (!$ctrl.promptActive) {
        recalculateRetailFields(retailField);
      }
    } else {
      $ctrl.calc();
    }
  }

  function recalculateRetailFields(retailFieldChanged) {
    var promptFields = [];

    switch (retailFieldChanged) {
      case $ctrl.retailFields.payment:
        promptFields.push({
          value: $ctrl.retailFields.financedAmount,
          text: financedAmountKey
        }, {
          value: $ctrl.retailFields.apr,
          text: aprKey
        });
        break;

      case $ctrl.retailFields.financedAmount:
        promptFields.push({
          value: $ctrl.retailFields.payment,
          text: monthlyPaymentAmountKey
        }, {
          value: $ctrl.retailFields.apr,
          text: aprKey
        });
        break;

      case $ctrl.retailFields.apr:
        promptFields.push({
          value: $ctrl.retailFields.payment,
          text: monthlyPaymentAmountKey
        }, {
          value: $ctrl.retailFields.financedAmount,
          text: financedAmountKey
        });
        break;

      default:
        return;
    }

    promptFieldToRecalculate(promptFields).then(recalculateRetailField).catch(function () {
      recalculateRetailField(retailFieldChanged);
    });
  }

  function promptFieldToRecalculate(promptFields) {
    $ctrl.promptActive = true;
    var deferred = $q.defer();
    var parentElem = angular.element($document[0].querySelector('#aa-app'));
    var modalInstance = $uibModal.open({
      animation: true,
      component: 'selectModal',
      appendTo: parentElem,
      backdrop: 'static',
      keyboard: false,
      resolve: {
        title: function title() {
          return financeFieldPromptTitleKey;
        },
        message: function message() {
          return financeFieldPromptMessageKey;
        },
        options: function options() {
          return promptFields;
        }
      }
    });
    modalInstance.closed.then(function () {
      $ctrl.promptActive = false;
      deferred.reject();
    });
    modalInstance.result.then(function (retailFieldSelected) {
      deferred.resolve(retailFieldSelected);
    });
    return deferred.promise;
  }

  function recalculateRetailField(retailField) {
    switch (retailField) {
      case $ctrl.retailFields.payment:
        _retailPaymentByRateAndAmount();

        break;

      case $ctrl.retailFields.financedAmount:
        _retailAmountByRateAndPayment();

        break;

      case $ctrl.retailFields.apr:
        $ctrl.financials.convert.purchaseRate = null;

        _retailRateByAmountAndPayment();

        break;

      default:
        return;
    }

    $ctrl.checkValidation();
  }
}