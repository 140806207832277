import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SaleTypes } from '../../../../constants';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';
import { NgxModalWrapperService } from '../../../../services/ngx-modal-wrapper.service';
import { PayoffInputModalComponent } from '../payoff-input-modal/payoff-input-modal.component';

@Component({
    selector: 'payoff',
    templateUrl: './payoff.component-ng.html',
    styleUrls: ['./payoff.component.scss']
})
export class PayoffComponent implements OnInit {
    @Input() value: number;
    @Input() saleType: any;
    @Input() paymentsRemaining: any;
    @Input() paidOff: any;
    @Input() residualAmount: number;
    @Input() permissions: DealSheetPermissions;
    @Output() onPayoffChange = new EventEmitter<any>();

    bsModalRef: BsModalRef;
    public saleTypes = SaleTypes;

    constructor(
        public modalService: NgxModalWrapperService,
        public tService: TranslateService) { }

    ngOnInit() {
    }

    public inputPayoffModal() {
        const initialState = {
            minPayoff: this.residualAmount,
        };

        this.bsModalRef = this.modalService.show(PayoffInputModalComponent, { initialState });

        this.bsModalRef.content.onSaveChanges.subscribe((result: any) => {
            this.onPayoffChange.emit(result);
        });
    }

}
