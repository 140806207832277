
/* injects from baggage-loader */
require('./search-preset-selector.component.html');

import angular from 'angular';
import _ from 'lodash';
import BusyIndicator from '../../utilities/BusyIndicator';
import './search-manage-presets.component.scss';


angular.module('AaApp.Opp').component('searchPresetSelector', {
    templateUrl: '/app/components/search/search-preset-selector.component.html',
    controller: SearchPresetSelectorController,
    bindings: {
        selectedDealerId: '<',
        getSelections: '=',
        onSelection: '&'
    }
});

/* @ngInject */
function SearchPresetSelectorController($q, $translate, $timeout, searchPresetTypes, searchPresetService, dealerService, pandoSurveyService, tokenService, userService, modules) {
    var $ctrl = this;

    $ctrl.cachedAvailableSearchPresets;
    $ctrl.cachedSelectedSearchPresets;
    $ctrl.searchPresetTypeTranslateKeys;
    $ctrl.searchPresetTypes;
    $ctrl.availableSearchPresets;
    $ctrl.selectedSearchPresets;
    $ctrl.$onInit = init;
    $ctrl.filterAvailableSearchPresets = filterAvailableSearchPresets;
    $ctrl.searchPresetTypeList = null;
    $ctrl.filterSelectedPresets = filterSelectedPresets;
    $ctrl.searchPresetsSelections = [];
    $ctrl.hasSelections = false;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.isFordAlert = false;

    const maxNonPrioritySearch = 20;

    function init() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        if ($ctrl.selectedDealerId == null) $ctrl.selectedDealerId = $ctrl.dealers[0].id;

        $ctrl.getSelections = getSelections;
        $ctrl.cachedSelectedSearchPresets = [];
        $ctrl.searchPresetTypes = searchPresetTypes;

        $ctrl.searchPresetTypeTranslateKeys = _.invert(searchPresetTypes);
        onDealerSelect();
    }

    function filterSelectedPresets(searchPreset) {
        var selected = $ctrl.availableSearchPresets
             .filter(function (o) { return o.selected });

        var nonPrioritySelections = $ctrl.selectedSearchPresets.concat(selected).filter(obj => { return obj.isPriority === false });

        if (nonPrioritySelections.length > maxNonPrioritySearch) {
            $ctrl.errorMessageVisible = true;
            $timeout(() => $ctrl.errorMessageVisible = false, 3000);
            searchPreset.selected = false;
        }
        else {
            $ctrl.errorMessageVisible = false;
        }

        $ctrl.searchPresetsSelections = $ctrl.availableSearchPresets
            .filter(function (o) { return o.selected });
        $ctrl.hasSelections = ($ctrl.searchPresetsSelections.length > 0);
        onSelection();
    }

    function onSelection() {
        $ctrl.onSelection({ hasSelections: $ctrl.hasSelections });
    }

    function byOrder(searchPreset1, searchPreset2) {
        return searchPreset1.order - searchPreset2.order;
    }

    function bySearchConditionName(object1, object2) {
        if (object1.searchConditionName < object2.searchConditionName)
            return -1;
        if (object1.searchConditionName > object2.searchConditionName)
            return 1;
        return 0;
    }

    function getSelections() {

        if ($ctrl.searchPresetsSelections === undefined)
        {
            return {
                dealerId: $ctrl.selectedDealerId,
                selectedSearchPresets: []
            };
        }

        return {
            dealerId: $ctrl.selectedDealerId,
            selectedSearchPresets: $ctrl.searchPresetsSelections.sort(bySearchConditionName)
        };
    }

    function busy(translationKey, promise) {
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function onDealerSelect() {
        $ctrl.availableSearchPresets = null;
        $ctrl.selectedSearchPresets = null;

        if ($ctrl.selectedDealerId) {

            $ctrl.isFordAlert = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.fordAlert);

            if ($ctrl.cachedAvailableSearchPresets && $ctrl.cachedSelectedSearchPresets) {
                filterSearchPresets();
            }
            else {

                return busy(
                    "Loading Searches", 
                    $q.all({
                        allpresets: searchPresetService.getSearchPresets(),
                        previouslyselected: searchPresetService.getDealerSearchPresetLayouts($ctrl.selectedDealerId)
                    }).then(setSearchPresets)
                );
            }
        }
    }

    function setSearchPresets(searchPresets) {
        if ($ctrl.cachedSelectedSearchPresets.length == 0) {
            $ctrl.cachedSelectedSearchPresets = searchPresets.previouslyselected;
        }
        if (!$ctrl.cachedAvailableSearchPresets) {
            $ctrl.cachedAvailableSearchPresets = searchPresets.allpresets;
        }
        filterSearchPresets();
    }

    function filterSearchPresets() {
        $ctrl.selectedSearchPresets = $ctrl.cachedSelectedSearchPresets.filter(preset => preset.dealerID == $ctrl.selectedDealerId).sort(byOrder);

        $ctrl.availableSearchPresets = _.differenceBy($ctrl.cachedAvailableSearchPresets, $ctrl.selectedSearchPresets, 'userSearchConditionID');

        $ctrl.searchPresetTypeList = [];

        if ($ctrl.availableSearchPresets.some(p => p.userSearchConditionGroupType == $ctrl.searchPresetTypes.dealer && p.dealerID == $ctrl.selectedDealerId))        
            $ctrl.searchPresetTypeList.push($ctrl.searchPresetTypes.dealer);

        if ($ctrl.availableSearchPresets.some(p => p.userSearchConditionGroupType == $ctrl.searchPresetTypes.common))        
            $ctrl.searchPresetTypeList.push($ctrl.searchPresetTypes.common);


        if ($ctrl.availableSearchPresets.some(p => p.userSearchConditionGroupType == $ctrl.searchPresetTypes.tag && p.dealerID == $ctrl.selectedDealerId))        
            $ctrl.searchPresetTypeList.push($ctrl.searchPresetTypes.tag);
    }

    function filterAvailableSearchPresets(searchPreset) {
        return searchPreset.userSearchConditionGroupType == $ctrl.searchPresetTypes.common
            || (searchPreset.userSearchConditionGroupType == $ctrl.searchPresetTypes.dealer && searchPreset.dealerID == $ctrl.selectedDealerId)
            || (searchPreset.userSearchConditionGroupType == $ctrl.searchPresetTypes.tag && searchPreset.dealerID == $ctrl.selectedDealerId);
    }

}
