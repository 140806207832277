import { Component, ViewChild, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter, ElementRef, NgModule } from '@angular/core';
import { DealerSettingsService } from '../../services/dealer-settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerSettingResponseDto, DealerAssignOwnerDto, UserDto } from '../../generated/models';

@Component({
    selector: 'admin-settings-creditconvert',
    templateUrl: './admin-settings-creditconvert.component-ng.html'
})

export class AdminSettingsCreditConvertComponent {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};

    public years: any = [];
    public dropdownList: [] = [];
    public selectedOwners: any = [];
    public selectedYears: any = [];
    public ownersDropdownSettings = {};
    public specificYearsSettings = {};

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public logger: ToasterLoggerService,
        private dsService: DealerSettingsService
    ) {
    }

    public ngOnInit() {
        this.ownersDropdownSettings = {
            singleSelection: false,
            text: this.tService.instant('dsAdmin_selectOwners'),
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,
            badgeShowLimit: 2,
            labelKey: "fullName",
            primaryKey: "userID"
        };     

        this.specificYearsSettings = {
            badgeShowLimit: 4,
            singleSelection: false,
            text: this.tService.instant('dsAdmin_selectYears'),
            selectAllText: this.tService.instant('selectAll'),
            enableSearchFilter: false,

        };   
       
        this.years = this.dsa.creditConvertFilterYears.map(obj => {
            var rObj: any = {};
            rObj.id = obj;
            rObj.itemName = obj;
            return rObj;
        });

        this.selectedOwners = this.dsa.dealerCreditSoftPullOwner.map(obj => {
            var user = this.dsa.users.find(u => u.userID == obj.userID);
            if (user != null)
                return user;
        }).filter(Boolean);

        if (this.dsa.dealerCreditSoftPull.creditSoftPullYearFilter) {
            var selectedYearArray = this.dsa.dealerCreditSoftPull.creditSoftPullYearFilter.split(',');
            this.selectedYears = selectedYearArray.map(obj => {
                var rObj: any = {};
                rObj.id = Number(obj);
                rObj.itemName = Number(obj);
                return rObj;
            });
        }
    }

    public onMultiSelectChanged() {
        this.dsa.isFormChanged = true;
    }

    public onSave() {
        this.dsa.dealerCreditSoftPullOwner = this.selectedOwners.map((obj: UserDto) => {
            // dealerID and typeID are filled by the api
            var rObj: DealerAssignOwnerDto = {};
            rObj.userID = obj.userID; 
            return rObj;
        });

        this.dsa.dealerCreditSoftPull.creditSoftPullYearFilter = this.selectedYears.map((e: any) => e.id).join(',');
    }
}