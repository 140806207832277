"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("./trade-in-value.component.scss.shim.ngstyle"));
var i1 = __importStar(require("@angular/core"));
var i2 = __importStar(require("@ngx-translate/core"));
var i3 = __importStar(require("@angular/forms"));
var i4 = __importStar(require("../../../icon/icon.component.ngfactory"));
var i5 = __importStar(require("../../../icon/icon.component"));
var i6 = __importStar(require("@angular/platform-browser"));
var i7 = __importStar(require("@angular/common"));
var i8 = __importStar(require("../../../../filters/locale-currency.pipe"));
var i9 = __importStar(require("../../../../modules/storage/storage.service"));
var i10 = __importStar(require("./trade-in-value.component"));
var i11 = __importStar(require("../../../../generated/services/vehicle-user-trade-value-client.service"));
var styles_TradeInValueComponent = [i0.styles];
var RenderType_TradeInValueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TradeInValueComponent, data: {} });
exports.RenderType_TradeInValueComponent = RenderType_TradeInValueComponent;
function View_TradeInValueComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "field-data qa-trade-in-value"], ["id", "trade-in-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.value)); _ck(_v, 1, 0, currVal_0); }); }
function View_TradeInValueComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "adjustment qa-trade-value-mileage-adjustment"], ["translate", "mileageAdjustment"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵppd(2, 1), i1.ɵpod(3, { adjustment: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mileageAdjustment"; var currVal_1 = _ck(_v, 3, 0, i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), (0 - _co.mileageAdjustment)))); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TradeInValueComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "span", [["class", "field-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["translate", "currencySymbol"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["class", "form-control input-sm editable qa-trade-value-input"], ["type", "text"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "keypress"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.value = $event) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (_co.update(_co.value) !== false);
        ad = (pd_5 && ad);
    } if (("keypress" === en)) {
        var pd_6 = (_co.tradeOnKeyPress($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["class", "edit-icon qa-trade-value-refresh-link"], ["href", "#"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onValueChange(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "icon", [["class", "icon aa-icon-refresh"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(12, 638976, null, 0, i5.IconComponent, [i6.DomSanitizer], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [["class", "edit-icon"], ["href", "#"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onStoreTradeValue(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 1, "icon", [["class", "icon save-manual-trade-value"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(16, 638976, null, 0, i5.IconComponent, [i6.DomSanitizer], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TradeInValueComponent_4)), i1.ɵdid(18, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "currencySymbol"; _ck(_v, 2, 0, currVal_0); var currVal_9 = _co.inputName; var currVal_10 = _co.value; _ck(_v, 6, 0, currVal_9, currVal_10); _ck(_v, 12, 0); _ck(_v, 16, 0); var currVal_13 = (_co.showAdjustment && ((0 - _co.mileageAdjustment) > 0)); _ck(_v, 18, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.inputName; var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("recalculatedealsheet")), ""); _ck(_v, 9, 0, currVal_11); var currVal_12 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("savemanualtradevalue")), ""); _ck(_v, 13, 0, currVal_12); }); }
function View_TradeInValueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "field row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "field-label"], ["for", "trade-in-value"], ["translate", "tradeValue"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TradeInValueComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TradeInValueComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tradeValue"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.readonly; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.readonly; _ck(_v, 6, 0, currVal_2); }, null); }
function View_TradeInValueComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.LocaleCurrencyPipe, [i9.StorageService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TradeInValueComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.value != null) && ((_co.permissions == null) ? null : _co.permissions.viewTradeValue)); _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_TradeInValueComponent_0 = View_TradeInValueComponent_0;
function View_TradeInValueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "trade-in-value", [], null, null, null, View_TradeInValueComponent_0, RenderType_TradeInValueComponent)), i1.ɵdid(1, 49152, null, 0, i10.TradeInValueComponent, [i1.ElementRef, i1.Injector, i11.VehicleUserTradeValueClientService], null, null)], null, null); }
exports.View_TradeInValueComponent_Host_0 = View_TradeInValueComponent_Host_0;
var TradeInValueComponentNgFactory = i1.ɵccf("trade-in-value", i10.TradeInValueComponent, View_TradeInValueComponent_Host_0, { vehicle: "vehicle", value: "value", showAdjustment: "showAdjustment", mileageAdjustment: "mileageAdjustment", permissions: "permissions", inputName: "inputName", readonly: "readonly" }, { tradeValueChange: "tradeValueChange" }, []);
exports.TradeInValueComponentNgFactory = TradeInValueComponentNgFactory;
