"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".modal-header[_ngcontent-%COMP%]{background:-webkit-gradient(linear,left top,right top,from(#172751),to(#1f8bd5)) fixed;background:linear-gradient(to right,#172751,#1f8bd5) fixed;color:#fff;border-radius:5px 5px 0 0;text-align:left;margin:0;display:block;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;padding:10px}.modal-header[_ngcontent-%COMP%]   .close[_ngcontent-%COMP%]{color:#fff;opacity:1}.startWithParentContainer[_ngcontent-%COMP%]{margin-top:10px}"];
exports.styles = styles;
