import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
ActivityListController.$inject = ["opportunityStatuses", "ActivityActionEnum", "activityStatusTypes", "localePaths", "$translatePartialLoader", "dealSheetService", "dealSheetTabs"];

/* injects from baggage-loader */
require('./activity-list.component.html');

import angular from 'angular';
import { ActivityTypes as activityTypes } from '../../constants';
angular.module('AaApp.Opp').component('activityList', {
  templateUrl: '/app/components/deal-sheet/activity-list.component.html',
  controller: ActivityListController,
  bindings: {
    activities: '<',
    permissions: '<',
    status: '<',
    users: '<',
    opportunityId: '<',
    onScheduleSubmit: '&',
    assignedUserFullName: '<',
    assignedUserId: '<',
    maxScheduleFutureDays: '<',
    ownerUserInfo: '<',
    defaultTab: '<',
    expirationDate: '<',
    hasReviewPro: '<',
    hasGeoAlert: '<',
    hasAutoAssist: '<',
    onSetAsDefault: '&',
    isEuroLite: '<',
    fullName: '<',
    onRefresh: '&',
    mobileView: '<',
    dealerId: '<'
  }
});
/* @ngInject */

function ActivityListController(opportunityStatuses, ActivityActionEnum, activityStatusTypes, localePaths, $translatePartialLoader, dealSheetService, dealSheetTabs) {
  var $ctrl = this;
  $ctrl.tabs = dealSheetTabs;
  $ctrl.activityTypes = activityTypes;
  $ctrl.opportunityStatuses = opportunityStatuses;
  $ctrl.nextAction = nextAction;
  $ctrl.rescheduleAction = rescheduleAction;
  $ctrl.reassignAction = reassignAction;
  $ctrl.cancelAction = cancelAction;
  $ctrl.$onInit = onInit;
  $ctrl.$onChanges = onChanges;
  $ctrl.latestScheduledActivity;
  $ctrl.scheduleModalParamObject = null;
  $ctrl.isCall = isCall;
  $ctrl.isAppointment = isAppointment;
  $translatePartialLoader.addPart(localePaths.oneToOne);

  function onChanges(changes) {
    $ctrl.scheduleModalParamObject = dealSheetService.getModalParamObject(null, $ctrl.opportunityId, null, $ctrl.assignedUserId, $ctrl.maxScheduleFutureDays, $ctrl.users, null, $ctrl.ownerUserInfo ? $ctrl.ownerUserInfo : null, $ctrl.expirationDate);

    if (changes.hasReviewPro && changes.hasReviewPro.currentValue) {
      enableModule('reviewProGroup');
    }

    if (changes.hasGeoAlert && changes.hasGeoAlert.currentValue) {
      enableModule('geoAlertGroup');
    }

    if (changes.activities && changes.activities.currentValue) {
      $ctrl.latestScheduledActivity = getLatestScheduledActivity();

      if ($ctrl.activities) {
        $ctrl.masterActivities = [];
        $ctrl.filteredActivities = [];

        for (var i = 0; i < $ctrl.activities.length; i++) {
          if ($ctrl.activities[i].type == $ctrl.activityTypes.reviewProServiceRating || $ctrl.activities[i].type == $ctrl.activityTypes.reviewProSalesRating) {
            enableModule('reviewProGroup');
          }

          if ($ctrl.activities[i].type == $ctrl.activityTypes.geoAlertActive || $ctrl.activities[i].type == $ctrl.activityTypes.geoAlertCampaign) {
            enableModule('geoAlertGroup');
          }

          $ctrl.masterActivities.push($ctrl.activities[i]);
          $ctrl.masterActivities[i].moduleGroupName = getModuleGroupName($ctrl.activities[i].type);
          $ctrl.masterActivities[i].listId = 5000 + i;
        }

        $ctrl.filterSelectedLogs();
      }
    }
  }

  function enableModule(key) {
    var foundModule = $ctrl.modules.filter(function (m) {
      return m.key === key;
    })[0];

    if (foundModule) {
      foundModule.enabled = true;
    }
  }

  function getFilteredActivities() {
    var activeModuleGroupNames = $ctrl.modules.filter(function (m) {
      return m.selected;
    }).map(function (m) {
      return m.key;
    }); // If no filters are enabled, include everything

    if (activeModuleGroupNames.length == 0) {
      return $ctrl.masterActivities.slice();
    }

    return $ctrl.masterActivities.filter(function (activity) {
      return activeModuleGroupNames.includes(activity.moduleGroupName);
    });
  }

  function modalClick(modalType) {
    $ctrl.scheduleModalParamObject.modalType = modalType;
    $ctrl.scheduleModalParamObject.activityObject = $ctrl.latestScheduledActivity;
    dealSheetService.openScheduleModal($ctrl.scheduleModalParamObject, onScheduleCallApptSubmit);
  }

  function onScheduleCallApptSubmit() {
    $ctrl.onScheduleSubmit();
  }

  function nextAction() {
    modalClick(getCompleteActionType($ctrl.latestScheduledActivity.activityActionId));
  }

  function rescheduleAction() {
    modalClick(getRescheduleActionType($ctrl.latestScheduledActivity.activityActionId));
  }

  function reassignAction() {
    modalClick(getReAssignActionType($ctrl.latestScheduledActivity.activityActionId));
  }

  function cancelAction() {
    modalClick(getCancelActionType($ctrl.latestScheduledActivity.activityActionId));
  } // based on $ctrl.latestScheduledActivity.activityActionId remap dialog action type for Reschedule


  function getRescheduleActionType(sourceActionType) {
    switch (sourceActionType) {
      case ActivityActionEnum.Schedule_Call:
      case ActivityActionEnum.Reschedule_Call:
      case ActivityActionEnum.Reassign_Call:
        return ActivityActionEnum.Reschedule_Call;

      case ActivityActionEnum.Schedule_Appt:
      case ActivityActionEnum.Reschedule_Appt:
      case ActivityActionEnum.Reassign_Appt:
        return ActivityActionEnum.Reschedule_Appt;
    }
  } // based on $ctrl.latestScheduledActivity.activityActionId remap dialog action type for Reassign


  function getReAssignActionType(sourceActionType) {
    switch (sourceActionType) {
      case ActivityActionEnum.Schedule_Call:
      case ActivityActionEnum.Reschedule_Call:
      case ActivityActionEnum.Reassign_Call:
        return ActivityActionEnum.Reassign_Call;

      case ActivityActionEnum.Schedule_Appt:
      case ActivityActionEnum.Reschedule_Appt:
      case ActivityActionEnum.Reassign_Appt:
        return ActivityActionEnum.Reassign_Appt;
    }
  } // based on $ctrl.latestScheduledActivity.activityActionId remap dialog action type for Cancel


  function getCancelActionType(sourceActionType) {
    switch (sourceActionType) {
      case ActivityActionEnum.Schedule_Appt:
      case ActivityActionEnum.Reschedule_Appt:
      case ActivityActionEnum.Reassign_Appt:
        return ActivityActionEnum.Cancel_Appt;

      case ActivityActionEnum.Schedule_Call:
      case ActivityActionEnum.Reschedule_Call:
      case ActivityActionEnum.Reassign_Call:
        return ActivityActionEnum.Cancel_Call;
    }
  } // based on $ctrl.latestScheduledActivity.activityActionId remap dialog action type for Complete


  function getCompleteActionType(sourceActionType) {
    switch (sourceActionType) {
      case ActivityActionEnum.Schedule_Appt:
      case ActivityActionEnum.Reschedule_Appt:
      case ActivityActionEnum.Reassign_Appt:
        return ActivityActionEnum.Appointment_Completed;

      case ActivityActionEnum.Schedule_Call:
      case ActivityActionEnum.Reschedule_Call:
      case ActivityActionEnum.Reassign_Call:
        return ActivityActionEnum.Phone_Call_Completed;
    }
  }

  function isCall(sourceActionType) {
    switch (sourceActionType) {
      case ActivityActionEnum.Schedule_Call:
      case ActivityActionEnum.Reschedule_Call:
      case ActivityActionEnum.Reassign_Call:
        return true;
    }

    return false;
  }

  function isAppointment(sourceActionType) {
    switch (sourceActionType) {
      case ActivityActionEnum.Schedule_Appt:
      case ActivityActionEnum.Reschedule_Appt:
      case ActivityActionEnum.Reassign_Appt:
        return true;
    }

    return false;
  }

  function getLatestScheduledActivity() {
    var scheduledActivities = [];

    for (var i = 0; i < $ctrl.activities.length; i++) {
      var activity = $ctrl.activities[i];

      if (activity.activityStatusId != activityStatusTypes.completed) {
        if (activityScheduled(activity)) {
          scheduledActivities.push(activity);
        }
      }
    }

    scheduledActivities.sort(bySubmittedDateTime);
    return scheduledActivities.length > 0 ? scheduledActivities[scheduledActivities.length - 1] : null;
  } // sort criteria


  function bySubmittedDateTime(activity1, activity2) {
    return new Date(activity1.submittedDateTime).getTime() - new Date(activity2.submittedDateTime).getTime();
  }

  function activityScheduled(activity) {
    switch (activity.type) {
      case ActivityActionEnum:
      case $ctrl.activityTypes.reschedulePhoneCall:
      case $ctrl.activityTypes.schedulePhoneCall:
      case $ctrl.activityTypes.reassignPhoneCall:
      case $ctrl.activityTypes.scheduleAppointment:
      case $ctrl.activityTypes.rescheduleAppointment:
      case $ctrl.activityTypes.reassignAppointment:
        return true;

      default:
        return false;
    }
  }

  $ctrl.modules = [];
  $ctrl.masterActivities = [];
  $ctrl.filteredActivities = [];

  function onInit() {
    $ctrl.modules = _setModules();
  }

  $ctrl.resetFilter = function () {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = $ctrl.modules[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var module = _step.value;
        module.selected = false; // $ctrl.modules[i].iconClass = $ctrl.modules[i].iconClass.replace(" icon-white", "");
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    $ctrl.filteredActivities = getFilteredActivities();
  };

  $ctrl.filterSelectedLogs = function () {
    $ctrl.filteredActivities = getFilteredActivities();
  };

  function _setModules() {
    return [{
      key: 'appointmentGroup',
      moduleName: 'appointmentGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-core-calendar-icon-000000'
    }, {
      key: 'phoneGroup',
      moduleName: 'phoneGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-core-phone-icon-000000'
    }, {
      key: 'notesGroup',
      moduleName: 'notesGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-core-takenote-icon-000000'
    }, {
      key: 'printGroup',
      moduleName: 'printGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-core-print-icon-000000'
    }, {
      key: 'mailGroup',
      moduleName: 'mailGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm',
      iconName: 'email-sms'
    }, {
      key: 'crmGroup',
      moduleName: 'crmGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-core-send-icon-000000'
    }, {
      key: 'oneToOneGroup',
      moduleName: 'oneToOneGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-1-2-1-icon-46'
    }, {
      key: 'pandoGroup',
      moduleName: 'pandoGroup',
      enabled: true,
      selected: false,
      iconClass: 'icon sm aa-icon-pandoicon-1'
    }, {
      key: 'webGroup',
      moduleName: 'web',
      enabled: true,
      selected: false,
      iconClass: 'icon sm',
      iconName: 'web'
    }, {
      key: 'reviewProGroup',
      moduleName: 'reviewPro',
      enabled: false,
      selected: false,
      iconClass: 'icon sm',
      iconName: 'reviews'
    }, {
      key: 'geoAlertGroup',
      moduleName: 'geoAlert',
      enabled: false,
      selected: false,
      iconClass: 'icon ',
      iconName: 'geoalert'
    }];
  }

  function getModuleGroupName(activityType) {
    switch (activityType) {
      case activityTypes.logNote:
      case activityTypes.logCrmProspectAlert:
      case activityTypes.logCrmProspectStatus:
      case activityTypes.logCrmRemark:
      case activityTypes.logCrmShowroomRemark:
      case activityTypes.logCrmCustomerWillBeBack:
      case activityTypes.autofiDigitalRetailing:
      case activityTypes.autofiDigitalRetailingCreditApp:
      case activityTypes.autofiDigitalRetailingApptSched:
      case activityTypes.export:
      case activityTypes.appraisal:
      case activityTypes.dmsPush:
        return 'notesGroup';

      case activityTypes.logPhoneCall:
      case activityTypes.completePhoneCall:
      case activityTypes.expirePhoneCall:
      case activityTypes.logCrmInboundCall:
      case activityTypes.logCrmOutboundCall:
        return 'phoneGroup';

      case activityTypes.scheduleAppointment:
      case activityTypes.rescheduleAppointment:
      case activityTypes.reassignAppointment:
      case activityTypes.completeAppointment:
      case activityTypes.expireAppointment:
      case activityTypes.cancelAppointment:
        return 'appointmentGroup';

      case activityTypes.cancelPhoneCall:
      case activityTypes.schedulePhoneCall:
      case activityTypes.reschedulePhoneCall:
      case activityTypes.reassignPhoneCall:
        return 'phoneGroup';

      case activityTypes.printTemplate:
        return 'printGroup';

      case activityTypes.printOfferLetter:
        return 'printGroup';

      case activityTypes.sendOneToOneMail:
      case activityTypes.sendOneToOneEmail:
      case activityTypes.purlView:
      case activityTypes.purlSubmit:
      case activityTypes.sendIntelMrktMail:
      case activityTypes.sendIntelMrktEmail:
      case activityTypes.purlViewIntelMrkt:
      case activityTypes.purlSubmitIntelMrkt:
      case activityTypes.facebookAdded:
      case activityTypes.facebookVerified:
      case activityTypes.sendFordCreditMailers:
      case activityTypes.sendServiceMarketingMail:
      case activityTypes.sendServiceMarketingEmail:
        return 'oneToOneGroup';

      case activityTypes.logCrmOutboundEmail:
      case activityTypes.logCrmInboundEmail:
      case activityTypes.sendCrmLetter:
      case activityTypes.sendEmailTemplate:
      case activityTypes.sendEmail:
      case activityTypes.emailReply:
      case activityTypes.emailReplyCustomer:
      case activityTypes.emailReplyDealer:
      case activityTypes.textMessage:
      case activityTypes.autoAssistNewHotLead:
      case activityTypes.autoAssistCommSent:
      case activityTypes.autoAssistCustomerReplied:
      case activityTypes.sendCrmTextMessage:
      case activityTypes.crmInboundTextMessage:
      case activityTypes.crmOutboundTextMessage:
        return 'mailGroup';

      case activityTypes.pushToCrm:
      case activityTypes.sendCrmReminder:
        return 'crmGroup';

      case activityTypes.sentSLMEmailOffer:
      case activityTypes.pandoProcess:
      case activityTypes.customerPandoProcess:
        return 'pandoGroup';

      case activityTypes.websiteEngaged:
      case activityTypes.websiteLeadSubmission:
        return 'webGroup';

      case activityTypes.reviewProServiceRating:
      case activityTypes.reviewProSalesRating:
        return 'reviewProGroup';

      case activityTypes.geoAlertActive:
      case activityTypes.geoAlertCampaign:
      case activityTypes.geoAlertAd:
        return 'geoAlertGroup';

      default:
        return '';
    }
  }
}