"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("./../../services/user.service");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var toaster_logger_service_1 = require("../../core/toaster-logger.service");
var AdminSettingsVHRDisclaimerComponent = /** @class */ (function () {
    function AdminSettingsVHRDisclaimerComponent(modalService, tService, userService, logger, _bsModalRef) {
        this.modalService = modalService;
        this.tService = tService;
        this.userService = userService;
        this.logger = logger;
        this._bsModalRef = _bsModalRef;
        this.acceptDisclaimer = new core_1.EventEmitter();
    }
    AdminSettingsVHRDisclaimerComponent.prototype.cancel = function () {
        this._bsModalRef.hide();
    };
    AdminSettingsVHRDisclaimerComponent.prototype.ok = function () {
        this._bsModalRef.hide();
        this.acceptDisclaimer.emit();
    };
    return AdminSettingsVHRDisclaimerComponent;
}());
exports.AdminSettingsVHRDisclaimerComponent = AdminSettingsVHRDisclaimerComponent;
