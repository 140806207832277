"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var deal_sheet_data_service_1 = require("./deal-sheet-data.service");
var dealer_service_1 = require("./dealer.service");
var activity_service_1 = require("./activity.service");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("./deal-sheet-data.service"));
var i2 = __importStar(require("./dealer.service"));
var i3 = __importStar(require("./activity.service"));
// Refactor of methods that get an opportunity formatted for use in a dealsheet
var MobileDealSheetDataCachingService = /** @class */ (function () {
    function MobileDealSheetDataCachingService(dealsheetDataService, dealerService, activityService) {
        this.dealsheetDataService = dealsheetDataService;
        this.dealerService = dealerService;
        this.activityService = activityService;
    }
    MobileDealSheetDataCachingService.prototype.getDealsheetData = function (opportunityId, stockNo) {
        if (this.dealSheetData$) {
            return this.dealSheetData$;
        }
        this.dealSheetData$ = this.dealsheetDataService.initDealsheetData(opportunityId, stockNo).pipe(operators_1.publishReplay(1), operators_1.refCount());
        return this.dealSheetData$;
    };
    MobileDealSheetDataCachingService.prototype.getUsers = function (dealerId) {
        if (this.users$) {
            return this.users$;
        }
        this.users$ = rxjs_1.from(this.dealerService.getUsers(dealerId));
        return this.users$;
    };
    MobileDealSheetDataCachingService.prototype.getActivities = function (opportunityId, dealerId, customerId) {
        if (this.activity$) {
            return this.activity$;
        }
        this.activity$ = rxjs_1.from(this.activityService.getActivities(opportunityId, dealerId, customerId));
        return this.activity$;
    };
    MobileDealSheetDataCachingService.prototype.clearActivities = function () {
        this.activity$ = null;
    };
    MobileDealSheetDataCachingService.prototype.clearUsers = function () {
        this.users$ = null;
    };
    MobileDealSheetDataCachingService.prototype.clearDealSheetData = function () {
        this.dealSheetData$ = null;
    };
    MobileDealSheetDataCachingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileDealSheetDataCachingService_Factory() { return new MobileDealSheetDataCachingService(i0.ɵɵinject(i1.DealSheetDataService), i0.ɵɵinject(i2.DealerService), i0.ɵɵinject(i3.ActivityService)); }, token: MobileDealSheetDataCachingService, providedIn: "root" });
    return MobileDealSheetDataCachingService;
}());
exports.MobileDealSheetDataCachingService = MobileDealSheetDataCachingService;
