import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.array.sort";
import "core-js/modules/web.dom.iterable";
SaleTypeSelect.$inject = ["_", "saleTypes"];

/* injects from baggage-loader */
require('./sale-type-select.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('saleTypeSelect', {
  templateUrl: '/app/components/deal-sheet/contract/sale-type-select.component.html',
  controller: SaleTypeSelect,
  bindings: {
    'defaults': '<',
    options: '<',
    onSelect: '&'
  }
});
/* @ngInject */

function SaleTypeSelect(_, saleTypes) {
  var $ctrl = this;
  $ctrl.selections = {};
  $ctrl.saleTypes = saleTypes;
  $ctrl.saleTypeTranslateKeys;
  $ctrl.onSelectionChange = onSelectionChange;
  $ctrl.$onInit = init;
  $ctrl.$onChanges = onChanges;

  function init() {
    $ctrl.saleTypeTranslateKeys = _.invert(saleTypes);
  }

  function onChanges(changes) {
    if (changes.defaults && changes.defaults.currentValue) {
      $ctrl.selections = {};
      $ctrl.defaults.forEach(function (saleType) {
        $ctrl.selections[saleType] = true;
      });
    }

    if (changes.options && changes.options.currentValue) {
      $ctrl.options.sort(bySaleType);
    }
  }

  function bySaleType(option1, option2) {
    if (option1 == $ctrl.saleTypes.retail) {
      return -1;
    }

    if (option2 == $ctrl.saleTypes.retail) {
      return 1;
    }

    return option1 - option2;
  }

  function onSelectionChange() {
    var selectedSaleTypes = [];

    for (var property in $ctrl.selections) {
      if ($ctrl.selections.hasOwnProperty(property) && $ctrl.selections[property] == true) {
        selectedSaleTypes.push(Number(property));
      }
    }

    $ctrl.onSelect({
      saleTypes: selectedSaleTypes
    });
  }
}