
/* injects from baggage-loader */
require('./send-email-template-modal.component.html');

import angular from 'angular'
import { OpportunitiesModule } from '../../../core/opportunities.module'
import _ from 'lodash';
import { downloadArraybufferResponse } from '../../../utilities/http';

OpportunitiesModule.component('sendEmailTemplateModal', {
    templateUrl: '/app/components/deal-sheet/modals/send-email-template-modal.component.html',
    controller: SendEmailTemplateModalController,
    bindings: {
        close: '&',
        resolve: '<',
        dismiss: '&'
    }
});

/* @ngInject*/
function SendEmailTemplateModalController(
    $logFactory,
    $q,
    $sce,
    $translate,
    $uibModal,
    $window,
    contractService,
    invalidMergeFieldErrorMsgKey,
    legacyService,
    logger,
    messageTypes,
    smtpProviderTypes,
    templateEmail,
    templateService,
    toaster,
    translateErrorKeys,
    warningTitleKey
) {
    const $ctrl = this;
    const $log = $logFactory.get('emailTemplateModal');

    $ctrl.templates = [];
    $ctrl.template;
    $ctrl.templateBody;
    $ctrl.templateLetterheadImageUrl;
    $ctrl.toggleEditButtonKey = "editEmailTemplate";
    $ctrl.isEditableTemplate = false;

    $ctrl.$onInit = $onInit;
    $ctrl.$onChanges = $onChanges;
    $ctrl.changeTemplate = changeTemplate;
    $ctrl.downloadPdf = downloadPdf;
    $ctrl.archivePdf = archivePdf;
    $ctrl.onResultSendTestEmail = onResultSendTestEmail;
    $ctrl.onResultSendEmail = onResultSendEmail;
    $ctrl.getTemplateEmailContent = getTemplateEmailContent;
    $ctrl.emailDisabled = false;
    $ctrl.canSendEmail = true;
    $ctrl.disabledEmailTitle = "";
    $ctrl.doNotEmail;
    $ctrl.toast;
    $ctrl.dealerId;
    $ctrl.onEdit = onEdit;
    $ctrl.toggleEditTemplate = toggleEditTemplate;
    $ctrl.previewTemplate = previewTemplate;
    $ctrl.attachProposal = false;
    $ctrl.attachmentGenerationResponse = {};
    $ctrl.vehicleTypes = [];
    $ctrl.replMakeId;
    $ctrl.atForFromAddr = "at";

    function $onChanges(changes) {
        if (changes.resolve) {
            $ctrl.templateBody = $ctrl.resolve.value;
        }
    }

    function previewTemplate() {
        var templateBody = $ctrl.templateBody;
        if (templateBody == null)
            templateBody = "";

        templateService.storePreviewTemplate(templateBody);

        var newWindow = $window.open('about:blank');
        newWindow.location = templateService.getTemplateEmailPreviewUrl($ctrl.resolve.opportunity.id, $ctrl.template.id, $ctrl.replMakeId);
    }

    function toggleEditTemplate() {
        $ctrl.isEditableTemplate = !$ctrl.isEditableTemplate;
        if ($ctrl.isEditableTemplate)
            $ctrl.toggleEditButtonKey = "cancelEditEmailTemplate";
        else
            $ctrl.toggleEditButtonKey = "editEmailTemplate";
    }

    function $onInit() {
        
        $ctrl.emailDisabled = false;
        $ctrl.canSendEmail = true;
        if ($ctrl.toast) {
            toaster.clear($ctrl.toast);
        }

        $ctrl.dealerId = $ctrl.resolve.opportunity.dealerId;      
        $ctrl.replMakeId = $ctrl.resolve.opportunity.replacementVehicle.makeID;

        this.workingPromise = templateService.getTemplatesForDealerId($ctrl.dealerId)
            .then(templates => {
                this.templates = templates;
                if (templates) {
                    if ($ctrl.resolve.defaultTemplateId) {
                        $ctrl.template = _.find(templates, {id: $ctrl.resolve.defaultTemplateId});
                        toggleEditTemplate();
                    }
                    setDealerFromAddress();
                }
            })

        getTranslations();
    }

    function getTranslations() {
        $q.all({
            'new': $translate('new'),
            'used': $translate('used'),
            'cpo': $translate('cpo'),
            'atForFromAddr': $translate('atForFromAddr'),
        }).then(function (translations) {
            $ctrl.vehicleTypes[0] = translations.new;
            $ctrl.vehicleTypes[1] = translations.used;
            $ctrl.vehicleTypes[2] = translations.cpo;
            $ctrl.atForFromAddr = translations.atForFromAddr;
        });
    }

    function changeTemplate() {
        const templateId = $ctrl.template.id;
        const opportunityId = $ctrl.resolve.opportunity.id;

        var replacement = $ctrl.resolve.opportunity.replacementVehicle;
        var vehicleColor = "";
        if (replacement.exteriorColor != null && replacement.interiorColor === null) {
            vehicleColor = replacement.exteriorColor;
        }
        else if (replacement.interiorColor != null && replacement.exteriorColor === null) {
            vehicleColor = ' / ' + replacement.interiorColor;
        }
        else if (replacement.exteriorColor != null && replacement.interiorColor != null) {
            vehicleColor = replacement.exteriorColor + ' / ' + replacement.interiorColor;
        }

        var replacementVehicle = {
            selectedMake: replacement.makeName,
            selectedModel: replacement.modelName,
            selectedYear: replacement.yearID,
            selectedTrim: replacement.trimName,
            selectedFullTrim: replacement.trimFullName,
            selectedNewUsed: $ctrl.vehicleTypes[replacement.type],
            selectedPrice: replacement.price,
            selectedMileage: replacement.odometer,
            selectedColor: vehicleColor,
            selectedStockNo: replacement.stockNo
        }

        $ctrl.workingPromise = templateService.getMergedTemplate({ opportunityId: opportunityId, templateId: templateId, replacementVehicle: replacementVehicle })
            .then(template => {
                if (template.invalidMergeFields.length > 0) {
                    var invalidFlds = "";
                    for (var i = 0; i < template.invalidMergeFields.length; i++) {
                        invalidFlds += "<br>" + template.invalidMergeFields[i];
                    }
                    $translate([warningTitleKey, invalidMergeFieldErrorMsgKey]).then(function (translations) {
                        toaster.pop({
                            type: messageTypes.warning,
                            title: translations.warningTitle,
                            body: translations.invalidMergeFieldErrorMsg + invalidFlds,
                            timeout: 0,
                            bodyOutputType: 'trustedHtml',
                            showCloseButton: true,

                        });
                    })
                }
                $ctrl.templateBody = template.templateBody;
                $ctrl.templateSubject = _.find($ctrl.templates, { id: templateId }).templateSubject;
                $ctrl.template.invalidMergeFields = template.invalidMergeFields;                
                $ctrl.templateLetterheadImageUrl = template.templateLetterheadImageUrl;
                $ctrl.readOnlyText =  $sce.trustAsHtml($ctrl.templateBody); 
            })
    }

    function downloadPdf() {
        if (typeof $ctrl.templateOutputId == 'number') {
            throw new Error("Email template has not been filled.");
        }
        const opportunityId = $ctrl.resolve.opportunity.id;
        logger.success("generatingPDF");

        return templateService.createPdf(opportunityId, $ctrl.template, $ctrl.templateBody, $ctrl.templateLetterheadImageUrl)
            .then(t => {
                $ctrl.templateOutput = t;
                return templateService.downloadPdf($ctrl.templateOutput.id, $ctrl.templateOutput.templateName);
            })
            .then(() => { $ctrl.readyToArchive = true });
    }

    function archivePdf() {
        return templateService.archivePdf($ctrl.templateOutput.id)
            .then(() => $ctrl.dismiss());
    }

    function onResultSendTestEmail(edit) {
        onSendEmail(edit.subject,"" , $ctrl.emailFromAddress, $ctrl.templateBody, true, edit.emailTo);
    }

    function onResultSendEmail(edit){
        onSendEmail(edit.subject, edit.emailTo, $ctrl.emailFromAddress, $ctrl.templateBody, false,"");
    }

    function checkCanSendEmail() { 
        if ($ctrl.emailDisabled) {
            logger.warning("emailDisabledWarning");
            $ctrl.canSendEmail = false;
            return;
        }

        if ($ctrl.resolve.opportunity) {
            $ctrl.doNotEmail = $ctrl.resolve.opportunity.customer.doNotEmail;
            if ($ctrl.doNotEmail) {
                logger.warning("doNotEmailCheckedWarning");
                $ctrl.canSendEmail = false;
            }
        }
    }

    function setDealerFromAddress() {
        templateService.getDealerOutboundEmailProviderInfo($ctrl.dealerId)
            .then((result) => {
                $ctrl.emailFromAddress = result == smtpProviderTypes.custom? $ctrl.resolve.opportunity.userProfile.email : templateEmail.mailFrom;
                $ctrl.emailDisabled = result === smtpProviderTypes.none;
                checkCanSendEmail();
            });
    }

    async function onSendEmail(subject, to, from, body, isTestEmail, testTo) {

        if (typeof body === 'undefined' || body === null || body === '') {
            logger.warning("blankTemplateWarning");
        } else {

            let upgradeProposalCalcParameters = null;
            if ($ctrl.attachProposal) {
                upgradeProposalCalcParameters = await legacyService.getCalculationParameters($ctrl.resolve.opportunity);
            }
            var outboundTemplateInfo = {
                userId:$ctrl.resolve.opportunity.userProfile.userId,
                entityID: $ctrl.resolve.opportunity.id,
                templateId: $ctrl.template.id,
                templateName: $ctrl.template.templateName,
                isTestEmail: isTestEmail,
                testToAddress: testTo,
                toAddress: to,
                subject:subject,
                contentHtml:body,
                maxEmailsPerEntityPerDay: 0,
                replMakeId: $ctrl.replMakeId
            };

            logger.success("sendingEmail");
            try {
                const emailResponse = await templateService.sendEmail(outboundTemplateInfo, upgradeProposalCalcParameters);

                logger.success("emailSuccessMessage");
            }
            catch (response) {

                if (response.data.error.translationKey) {
                    $translate(response.data.error.translationKey, response.data.error.translationParameters)
                        .then((message) => logger.warning(message, null, "emailErrorMessage"))
                        .catch(err => $log.error(err));
                } else {
                    logger.warning(response.data.error.message, null, "emailErrorMessage");
                }

            }

        }
    }


    function onEdit(action){
        $ctrl.templateBody = action;
        $ctrl.readOnlyText =  $sce.trustAsHtml($ctrl.templateBody);
    }

    function getTemplateEmailContent() {
        return $ctrl.workingPromise = templateService.getEmailPreviewContent($ctrl.resolve.opportunity.id, $ctrl.template.id, $ctrl.templateBody, $ctrl.replMakeId)
            .then(setTemplateEmailContent);
    }

    function setTemplateEmailContent(content) {
        $ctrl.templateEmailContent = content;

        return true;
    }
}
