import angular from 'angular';

angular.module('AaApp.Opp').constant('territories', [
    {
        "id": 0,
        "countryId": 0,
        "abbreviation": "DS",
        "name": "Default"
    },
    {
        "id": 2,
        "countryId": 1,
        "abbreviation": "AL",
        "name": "Alabama"
    },
    {
        "id": 3,
        "countryId": 1,
        "abbreviation": "AK",
        "name": "Alaska"
    },
    {
        "id": 4,
        "countryId": 1,
        "abbreviation": "AS",
        "name": "American Samoa"
    },
    {
        "id": 5,
        "countryId": 1,
        "abbreviation": "AZ",
        "name": "Arizona"
    },
    {
        "id": 6,
        "countryId": 1,
        "abbreviation": "AR",
        "name": "Arkansas"
    },
    {
        "id": 7,
        "countryId": 1,
        "abbreviation": "CA",
        "name": "California"
    },
    {
        "id": 8,
        "countryId": 1,
        "abbreviation": "CO",
        "name": "Colorado"
    },
    {
        "id": 9,
        "countryId": 1,
        "abbreviation": "CT",
        "name": "Connecticut"
    },
    {
        "id": 10,
        "countryId": 1,
        "abbreviation": "DE",
        "name": "Delaware"
    },
    {
        "id": 11,
        "countryId": 1,
        "abbreviation": "DC",
        "name": "District Of Columbia"
    },
    {
        "id": 12,
        "countryId": 1,
        "abbreviation": "FM",
        "name": "Federated States of Micronesia"
    },
    {
        "id": 13,
        "countryId": 1,
        "abbreviation": "FL",
        "name": "Florida"
    },
    {
        "id": 14,
        "countryId": 1,
        "abbreviation": "GA",
        "name": "Georgia"
    },
    {
        "id": 15,
        "countryId": 1,
        "abbreviation": "GU",
        "name": "Guam"
    },
    {
        "id": 16,
        "countryId": 1,
        "abbreviation": "HI",
        "name": "Hawaii"
    },
    {
        "id": 17,
        "countryId": 1,
        "abbreviation": "ID",
        "name": "Idaho"
    },
    {
        "id": 18,
        "countryId": 1,
        "abbreviation": "IL",
        "name": "Illinois"
    },
    {
        "id": 19,
        "countryId": 1,
        "abbreviation": "IN",
        "name": "Indiana"
    },
    {
        "id": 20,
        "countryId": 1,
        "abbreviation": "IA",
        "name": "Iowa"
    },
    {
        "id": 21,
        "countryId": 1,
        "abbreviation": "KS",
        "name": "Kansas"
    },
    {
        "id": 22,
        "countryId": 1,
        "abbreviation": "KY",
        "name": "Kentucky"
    },
    {
        "id": 23,
        "countryId": 1,
        "abbreviation": "LA",
        "name": "Louisiana"
    },
    {
        "id": 24,
        "countryId": 1,
        "abbreviation": "ME",
        "name": "Maine"
    },
    {
        "id": 25,
        "countryId": 1,
        "abbreviation": "MH",
        "name": "Marshall Islands"
    },
    {
        "id": 26,
        "countryId": 1,
        "abbreviation": "MD",
        "name": "Maryland"
    },
    {
        "id": 27,
        "countryId": 1,
        "abbreviation": "MA",
        "name": "Massachusetts"
    },
    {
        "id": 28,
        "countryId": 1,
        "abbreviation": "MI",
        "name": "Michigan"
    },
    {
        "id": 29,
        "countryId": 1,
        "abbreviation": "MN",
        "name": "Minnesota"
    },
    {
        "id": 30,
        "countryId": 1,
        "abbreviation": "MS",
        "name": "Mississippi"
    },
    {
        "id": 31,
        "countryId": 1,
        "abbreviation": "MO",
        "name": "Missouri"
    },
    {
        "id": 32,
        "countryId": 1,
        "abbreviation": "MT",
        "name": "Montana"
    },
    {
        "id": 33,
        "countryId": 1,
        "abbreviation": "NE",
        "name": "Nebraska"
    },
    {
        "id": 34,
        "countryId": 1,
        "abbreviation": "NV",
        "name": "Nevada"
    },
    {
        "id": 35,
        "countryId": 1,
        "abbreviation": "NH",
        "name": "New Hampshire"
    },
    {
        "id": 36,
        "countryId": 1,
        "abbreviation": "NJ",
        "name": "New Jersey"
    },
    {
        "id": 37,
        "countryId": 1,
        "abbreviation": "NM",
        "name": "New Mexico"
    },
    {
        "id": 38,
        "countryId": 1,
        "abbreviation": "NY",
        "name": "New York"
    },
    {
        "id": 39,
        "countryId": 1,
        "abbreviation": "NC",
        "name": "North Carolina"
    },
    {
        "id": 40,
        "countryId": 1,
        "abbreviation": "ND",
        "name": "North Dakota"
    },
    {
        "id": 41,
        "countryId": 1,
        "abbreviation": "MP",
        "name": "Northern Mariana Islands"
    },
    {
        "id": 42,
        "countryId": 1,
        "abbreviation": "OH",
        "name": "Ohio"
    },
    {
        "id": 43,
        "countryId": 1,
        "abbreviation": "OK",
        "name": "Oklahoma"
    },
    {
        "id": 44,
        "countryId": 1,
        "abbreviation": "OR",
        "name": "Oregon"
    },
    {
        "id": 45,
        "countryId": 1,
        "abbreviation": "PW",
        "name": "Palau"
    },
    {
        "id": 46,
        "countryId": 1,
        "abbreviation": "PA",
        "name": "Pennsylvania"
    },
    {
        "id": 47,
        "countryId": 1,
        "abbreviation": "PR",
        "name": "Puerto Rico"
    },
    {
        "id": 48,
        "countryId": 1,
        "abbreviation": "RI",
        "name": "Rhode Island"
    },
    {
        "id": 49,
        "countryId": 1,
        "abbreviation": "SC",
        "name": "South Carolina"
    },
    {
        "id": 50,
        "countryId": 1,
        "abbreviation": "SD",
        "name": "South Dakota"
    },
    {
        "id": 51,
        "countryId": 1,
        "abbreviation": "TN",
        "name": "Tennessee"
    },
    {
        "id": 52,
        "countryId": 1,
        "abbreviation": "UT",
        "name": "Utah"
    },
    {
        "id": 53,
        "countryId": 1,
        "abbreviation": "VT",
        "name": "Vermont"
    },
    {
        "id": 54,
        "countryId": 1,
        "abbreviation": "VI",
        "name": "Virgin Islands"
    },
    {
        "id": 55,
        "countryId": 1,
        "abbreviation": "VA",
        "name": "Virginia"
    },
    {
        "id": 56,
        "countryId": 1,
        "abbreviation": "WA",
        "name": "Washington"
    },
    {
        "id": 57,
        "countryId": 1,
        "abbreviation": "WV",
        "name": "West Virginia"
    },
    {
        "id": 58,
        "countryId": 1,
        "abbreviation": "WI",
        "name": "Wisconsin"
    },
    {
        "id": 59,
        "countryId": 1,
        "abbreviation": "WY",
        "name": "Wyoming"
    },
    {
        "id": 60,
        "countryId": 1,
        "abbreviation": "TX",
        "name": "Texas"
    },
    {
        "id": 61,
        "countryId": 2,
        "abbreviation": "QC",
        "name": "Quebec"
    },
    {
        "id": 63,
        "countryId": 1,
        "abbreviation": "UNK",
        "name": "Unknown"
    },
    {
        "id": 64,
        "countryId": 2,
        "abbreviation": "UNK",
        "name": "Unknown"
    },
    {
        "id": 65,
        "countryId": 2,
        "abbreviation": "ON",
        "name": "Ontario"
    },
    {
        "id": 66,
        "countryId": 2,
        "abbreviation": "NS",
        "name": "Nova Scotia"
    },
    {
        "id": 68,
        "countryId": 2,
        "abbreviation": "NB",
        "name": "New Brunswick"
    },
    {
        "id": 69,
        "countryId": 2,
        "abbreviation": "MB",
        "name": "Manitoba"
    },
    {
        "id": 70,
        "countryId": 2,
        "abbreviation": "BC",
        "name": "British Columbia"
    },
    {
        "id": 71,
        "countryId": 2,
        "abbreviation": "PE",
        "name": "Prince Edward Island"
    },
    {
        "id": 72,
        "countryId": 2,
        "abbreviation": "SK",
        "name": "Saskatchewan"
    },
    {
        "id": 73,
        "countryId": 2,
        "abbreviation": "AB",
        "name": "Alberta"
    },
    {
        "id": 75,
        "countryId": 2,
        "abbreviation": "NL",
        "name": "Newfoundland and Labrador"
    },
    {
        "id": 77,
        "countryId": 3,
        "abbreviation": "ENG",
        "name": "England"
    },
    {
        "id": 78,
        "countryId": 2,
        "abbreviation": "NT",
        "name": "NorthWest Territories"
    },
    {
        "id": 79,
        "countryId": 2,
        "abbreviation": "NU",
        "name": "Nunavut"
    },
    {
        "id": 80,
        "countryId": 2,
        "abbreviation": "YT",
        "name": "Yukon"
    }
]);
