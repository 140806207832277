CustomerEditModal.$inject = ["$uibModal", "$document", "optInConfirmationMessageKey", "optInConfirmationTitleKey", "$scope"];

/* injects from baggage-loader */
require('./customer-edit-modal.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('customerEditModal', {
  templateUrl: '/app/components/deal-sheet/modals/customer-edit-modal.component.html',
  controller: CustomerEditModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
});
/* @ngInject */

function CustomerEditModal($uibModal, $document, optInConfirmationMessageKey, optInConfirmationTitleKey, $scope) {
  var $ctrl = this;
  $ctrl.originalCustomer;
  $ctrl.customer;
  $ctrl.validCustomer = true;
  $ctrl.onCustomerEdit = onCustomerEdit;
  $ctrl.submit = submit;
  $ctrl.$onInit = init;

  function init() {
    if ($ctrl.resolve && $ctrl.resolve.customer) {
      $ctrl.originalCustomer = $ctrl.resolve.customer;
      $ctrl.customer = $ctrl.resolve.customer;
    }
  }

  function onCustomerEdit(customer, valid) {
    $ctrl.customer = customer;
    $ctrl.validCustomer = valid;
  }

  function submit() {
    if ($ctrl.originalCustomer.doNotCall && !$ctrl.customer.doNotCall || $ctrl.originalCustomer.doNotEmail && !$ctrl.customer.doNotEmail || $ctrl.originalCustomer.doNotMail && !$ctrl.customer.doNotMail) {
      var modal = $uibModal.open({
        appendTo: angular.element($document[0].querySelector('#aa-app')),
        animation: true,
        component: 'confirmationModal',
        resolve: {
          title: function title() {
            return optInConfirmationTitleKey;
          },
          message: function message() {
            return optInConfirmationMessageKey;
          }
        }
      });
      modal.result.then(function () {
        $ctrl.close({
          $value: $ctrl.customer
        });
      });
    } else {
      $ctrl.close({
        $value: $ctrl.customer
      });
    }
  }
}