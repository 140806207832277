
/* injects from baggage-loader */
require('./pull-forward.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('pullForward', {
    templateUrl: '/app/components/deal-sheet/contract/pull-forward.component.html',
    bindings: {
        value: '<'
    }
});
