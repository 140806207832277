import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";
import { Activity } from "../../../interfaces/activity.interface";
import { DealSheetPermissions } from "../../../interfaces/deal-sheet/deal-sheet-permissions.interface";
import { UserInfo } from "../../../interfaces/user-info.interface";

// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'activity-list',
//   templateUrl: './activity-list.component.html',
//   styleUrls: ['./activity-list.component.scss']
// })
// export class ActivityListComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }


@Directive({
    selector: 'activity-list'
})
export class ActivityListComponent extends UpgradeComponent {
    @Input() activities: Activity[];
    @Input() permissions: DealSheetPermissions;
    @Input() status: any;
    @Input() users: any;
    @Input() opportunityId: string;
    @Output() onScheduleSubmit = new EventEmitter<any>();
    @Input() assignedUserFullName: string;
    @Input() assignedUserId: any;
    @Input() maxScheduleFutureDays: number;
    @Input() ownerUserInfo: UserInfo;
    @Input() defaultTab: any;
    @Input() expirationDate: any;
    @Input() hasReviewPro: boolean;
    @Input() hasGeoAlert: boolean;
    @Output() onSetAsDefault = new EventEmitter<any>();
    @Input() isEuroLite: boolean;
    @Input() fullName: string;
    @Output() onRefresh = new EventEmitter<any>();
    @Input() mobileView: boolean;
    @Input() dealerId: number;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('activityList', elementRef, injector);
    }
}
