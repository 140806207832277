"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DefaultTabActionComponent = /** @class */ (function () {
    function DefaultTabActionComponent() {
        this.onAction = new core_1.EventEmitter();
    }
    DefaultTabActionComponent.prototype.ngOnInit = function () {
    };
    DefaultTabActionComponent.prototype.onClickMarkAsDefault = function () {
        this.onAction.emit();
    };
    return DefaultTabActionComponent;
}());
exports.DefaultTabActionComponent = DefaultTabActionComponent;
