<vehicle-image *ngIf="vehicle && vehicle.imagePath && countryId != dealerCountries?.france" [url]="vehicle.imagePath" 
    [ngClass]="{'oasis': isFordAlert && (vehicle?.makeName == 'Ford' || vehicle?.makeName == 'Lincoln')}"></vehicle-image>
<div class="trim-info">
    <div [ngClass]="vehicleTypeKeys[vehicle?.type]">
        <span *ngIf="countryId != dealerCountries?.france">
            <span class="h5 type-label text-uppercase bold qa-vehicle-type">{{vehicleTypeKeys[vehicle?.type] | translate}}&nbsp;</span>
            <span class="h5 type-label bold" *ngIf="isReplacement">:&nbsp;</span>
            <span class="h5 type-label bold planLabelTransparent"
                *ngIf="(vehicle?.pricingPlanMsrp && pricingPlanId && pricingPlanId > 0) || vehicle?.trimPlanPriceSourceId === trimPricePlanSource?.employeePricing">{{ planTranslationKey | translate }}&nbsp;</span>
            <span class="h5 type-label bold qa-vehicle-stock-no" *ngIf="isReplacement && vehicle?.stockNo">{{vehicle?.stockNo}}&nbsp;</span>
            <span class="h5 type-label bold" *ngIf="isReplacement && !vehicle?.stockNo">{{averageTranslateKey | translate}}</span>
        </span>
        <span *ngIf="countryId == dealerCountries?.france">
            <span class="h5 type-label text-uppercase bold qa-vehicle-type">{{vehicleTypeKeys[vehicle?.type] | translate}}&nbsp;</span>
            <span class="h5 type-label bold planLabelTransparent" *ngIf="vehicle?.pricingPlanMsrp && pricingPlanId && pricingPlanId > 0">{{ planTranslationKey | translate }}&nbsp;</span>
            <span class="h5 type-label bold qa-vehicle-stock-no" *ngIf="isReplacement && vehicle?.stockNo">{{vehicle?.stockNo}}</span>
        </span>
    </div>
    <span *ngIf="countryId != dealerCountries?.france">
        <div *ngIf="vehicle?.yearID && vehicle?.makeName">
            <span class="value qa-vehicle-year">{{vehicle?.yearID}}&nbsp;</span>
            <span class="value qa-vehicle-make-name">{{vehicle?.makeName}}</span>
        </div>
        <div>
            <span class="value qa-vehicle-trim-fullname">{{vehicle?.trimFullName}}</span>
        </div>
    </span>
    <div *ngIf="countryId == dealerCountries?.france">
        <span class="value qa-vehicle-make-name">{{vehicle?.makeName}}&nbsp;</span>
        <span class="value qa-vehicle-trim-fullname">{{vehicle?.trimFullName}}&nbsp;</span>
        <span class="value qa-vehicle-year">{{vehicle?.yearID}}</span>
    </div>
    <ng-container *ngIf="vehicle && vehicle.vin && !isReplacement">
        <div class="vehicle-vin" *ngIf="!(isFordAlert && (vehicle.makeName == 'Ford' || vehicle.makeName == 'Lincoln'))">
            <span style="padding-right:8px;">{{vehicle.vin}}</span>
        </div>
        <div class="vehicle-vin" style="clear:left;" *ngIf="isFordAlert && (vehicle?.makeName == 'Ford' || vehicle?.makeName == 'Lincoln')">
            <span style="padding-right:8px;">{{vehicle.vin}}</span>
            <button class="btn btn-primary btn-sm" (click)="openOasisLink()"
                    [popover]="popOasisTemplate" placement="right" triggers="mouseenter:mouseleave"
                    translate="fordOasis"></button>
            <ng-template #popOasisTemplate>
                <p [innerHTML]="fordOasisTooltip | translate" class="oasisTooltip" translate="fordOasisTooltip"></p>
            </ng-template>
        </div>
    </ng-container>
    <ng-container *ngIf="vehicle && vehicle.vin && isReplacement && vehicle.stockNo">
        <div class="vehicle-vin">
            <span style="padding-right:8px;">{{vehicle.vin}}</span>
        </div>
    </ng-container>
</div>
