contractService.$inject = ["$q", "alertTypes", "coreApi", "conquestTypes", "saleTypes", "vehicleTypes", "opportunityTypes"];
import angular from 'angular';
import { defaultTo } from '../utilities/utils';
angular.module('AaApp.Opp').factory('contractService', contractService);
/* @ngInject */

function contractService($q, alertTypes, coreApi, conquestTypes, saleTypes, vehicleTypes, opportunityTypes) {
  var service = {
    getCalculationParameters: getCalculationParameters,
    getCurrentContract: getCurrentContract,
    getReplacementContracts: getReplacementContracts,
    updatePayoff: updatePayoff,
    undoVehiclePaidOff: undoVehiclePaidOff
  };
  return service;

  function getCurrentContract(opportunityId, opportunityType) {
    if (opportunityType != opportunityTypes.conquest) {
      return coreApi.api.Sale.GetSaleExtByEntityId['{entityId}'].GET({
        entityId: opportunityId
      }).then(resolveData);
    } else {
      return coreApi.api.CreditSoftPullResultEntityKeep.GetSaleInfoByEntityId['{entityId}'].GET({
        entityId: opportunityId
      }).then(resolveData);
    }
  }

  function getReplacementContracts(opportunity, permissions) {
    if (!permissions.payments) {
      // If the user doesn't have access to view contracts, just
      // return an empty response. The UI will detect this case
      // by checking for the appropriate permission.
      return $q.resolve({
        contracts: []
      });
    }

    return getCalculationParameters(opportunity).then(function (parameters) {
      return coreApi.api.DealSheet.CalculatePayments.POST(parameters);
    }).then(function (response) {
      return response.data;
    });
  }

  function updatePayoff(opportunityId, payoffAmount, goodThroughDate) {
    return coreApi.api.Sale.AddUpdateSalePayoff.POST({
      entityID: opportunityId,
      payOffAmount: payoffAmount,
      payOffGoodThru: goodThroughDate
    }).then(function () {
      return true;
    });
  }

  function undoVehiclePaidOff(opportunityId) {
    return coreApi.api.Entity.VehicleNotPaidOff['{entityId}'].POST({}, {
      entityId: opportunityId
    });
  }

  function getCalculationParameters(opportunity) {
    return $q.all([coreApi.api.BalanceToMaturity['{entityId}'].GET({
      entityId: opportunity.id,
      saleId: opportunity.currentContract.saleID
    }), coreApi.api.SalePayoffEstimate['{saleId}'].GET({
      saleId: opportunity.currentContract.saleID
    }), coreApi.api.CreditSoftPullResultEntityKeep.GetSaleInfoByEntityId['{entityId}'].GET({
      entityId: opportunity.id
    })]).then(function (response) {
      var balanceToMaturity = response[0].data;
      var salePayoffEstimate = response[1].data;
      var creditConvertSaleData = response[2].data;
      var stillOwnsVehicle = opportunity.type == 3 || opportunity.stillOwned == true;
      var finalTradeValue = defaultTo(function () {
        return opportunity.currentVehicle.tradeValue;
      }, 0) * stillOwnsVehicle;
      var adjustedBalance = defaultTo(function () {
        return salePayoffEstimate.adjustedBalance;
      }, 0) * stillOwnsVehicle;
      var isConquest = opportunity.replacementVehicle.IsConquest == 1 ? true : false;
      var isCreditConvert = creditConvertSaleData != "" && opportunity.type == opportunityTypes.conquest;
      var stillOwn = isCreditConvert ? true : opportunity.stillOwned != null ? opportunity.stillOwned : true;
      var paidOff = opportunity.paidOff != null ? opportunity.paidOff : false;
      var maturityDate;

      if (!opportunity.currentContract.saleType) {
        maturityDate = new Date(2000, 0, 1);
      } else if (opportunity.currentContract.saleType != saleTypes.lease) {
        maturityDate = opportunity.currentContract.lastPaymentDate ? opportunity.currentContract.lastPaymentDate : opportunity.currentContract.dealDate;
      } else {
        maturityDate = new Date(opportunity.currentContract.lastPaymentDate);
        maturityDate.setMonth(maturityDate.getMonth() - 1);
      } //in alphabetical order.


      var parameters = {
        adjustedBalance: adjustedBalance ? adjustedBalance : 0,
        balanceToMaturity: (balanceToMaturity != null ? balanceToMaturity.balanceToMaturity : 0) || 0,
        canBTBeApplied: balanceToMaturity.canBeApplied,
        canBTDispositionFeeBeForgiven: balanceToMaturity.canDispositionFeeBeForgiven,
        cashDown: 0,
        dealerID: opportunity.dealerId,
        dispositionFee: balanceToMaturity.dispositionFee,
        entityId: opportunity.id,
        entityTypeId: opportunity.type,
        finalTradeValue: finalTradeValue,
        interestRate: null,
        isConquest: isConquest,
        isCPO: opportunity.replacementVehicle.type == vehicleTypes.cpo,
        isUsed: opportunity.replacementVehicle.type == vehicleTypes.used,
        keyID: opportunity.keyId,
        leaseRate: null,
        leaseSalesTax: null,
        msrp: opportunity.replacementVehicle.msrp,
        newMakeID: opportunity.replacementVehicle.makeID,
        newTrimID: opportunity.replacementVehicle.trimID,
        newTrimYearID: opportunity.replacementVehicle.yearID,
        newVehiclePrice: opportunity.replacementVehicle.price,
        oldBankID: opportunity.currentContract.bankID,
        oldMakeID: opportunity.currentVehicle.makeID ? opportunity.currentVehicle.makeID : 0,
        oldMaturityDate: maturityDate,
        oldMonthlyPayment: opportunity.currentContract.payment ? opportunity.currentContract.payment : 0,
        oldPaymentsRemaining: opportunity.currentContract.paymentsRemaining,
        oldSaleTypeID: opportunity.currentContract.saleType,
        oldTermID: opportunity.currentContract.term,
        oldTrimID: opportunity.currentVehicle.trimID ? opportunity.currentVehicle.trimID : 0,
        payOffForward: opportunity.currentContract.payoffAmount,
        purchaseSalesTax: null,
        residualPercentage: null,
        saleID: opportunity.currentContract.saleID,
        stateID: opportunity.customer.address.stateId,
        stillOwnAndNotPaidOff: stillOwn && !paidOff,
        tier: null,
        totalPenalty: salePayoffEstimate.totalPenalty,
        useBalloon: true,
        usePullForward: balanceToMaturity.usePullForward,
        useSubsidy: null,
        zipCode: opportunity.customer.address.normalizedZipCode,
        pricingPlanId: opportunity.customer.pricingPlanId
      };

      if (opportunity.type == opportunityTypes.conquest) {
        parameters.saleID = null;
        parameters.oldBankID = 0;
        parameters.cashDown = 0;
        parameters.oldMaturityDate = new Date(2000, 0, 1);
        parameters.oldTermID = 1;
        parameters.oldPaymentsRemaining = 0;
        parameters.oldMonthlyPayment = 0;
      }

      if (isCreditConvert) {
        parameters.adjustedBalance = creditConvertSaleData.adjustedBalance ? creditConvertSaleData.adjustedBalance : 0;
        parameters.oldBankID = creditConvertSaleData.bankID;
        parameters.oldMonthlyPayment = creditConvertSaleData.payment ? creditConvertSaleData.payment : 0;
        parameters.oldPaymentsRemaining = creditConvertSaleData.paymentsRemaining;
        parameters.oldSaleTypeID = creditConvertSaleData.saleType;
        parameters.oldTermID = creditConvertSaleData.term;

        if (creditConvertSaleData.saleType == saleTypes.lease && creditConvertSaleData.lastPaymentDate) {
          var oldMaturityDate = new Date(creditConvertSaleData.lastPaymentDate);
          oldMaturityDate.setMonth(maturityDate.getMonth() - 1);
          parameters.oldMaturityDate = oldMaturityDate;
        } else {
          parameters.oldMaturityDate = opportunity.currentContract.endDate ? opportunity.currentContract.endDate : new Date(2000, 0, 1);
        }
      }

      return parameters;
    });
  }

  function resolveData(response) {
    return response.data ? response.data : {};
  }
}