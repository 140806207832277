import { groupBy } from 'lodash';
import { map } from 'rxjs/operators';
import { dateFromLocalString, dateFromUtcString } from '../utilities/datetime';
import moment from 'moment';
import { Injectable, Inject } from '@angular/core';
import { EngagementEventTypes } from '../constants/engagement-event-types.constant';
import {
    ActivityTypes, ApiActivityTypes, ApiActivityStatuses, ApiActivityActions, ApiCrmActivityTypes,
    CustomerActivityTypes, Settings, EmailFromTypes, CustomerProcessStatuses, ExportCampaignTypes
} from '../constants';
import { ActivityClientService, EntityClientService, ActivityHistoryClientService, EmailConversationClientService } from '../generated/services';
import { ActivityActionTargetEntityRequest } from '../generated/models/activity-action-target-entity-request';
import {
    LastActivityDetailsExtDto,
    //ActivityExtendedDto,
    ActivityExtendedWithVwSearchDto,
    ActivityWithEntityDto,
    ActivityActionSubmissionModel,
    ActivityActionResponseModel,
    ActivityHistoryCombinedDto,
    ActivityHistoryExtDto
} from '../generated/models';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Activity } from '../interfaces/activity.interface';
import { EntityMailHistorySource } from '../constants/entity-mail-history-source';
import { MailFormat } from '../constants/mail-format';
import { HttpInterceptorOptionsService } from './http/http-interceptor-options.service';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ActivityService {
    private printedTemplatesPath = '/AlertDesk/DealSheet/ArchivedPdf?filePath=';
    private emailTemplatePath = '/email/';
    private emailTemplateSentPreviewParam = '&prev=1';
    private apiActivityTypes = ApiActivityTypes;
    private activityTypes = ActivityTypes;
    private apiActivityStatuses = ApiActivityStatuses;
    private apiActivityActions = ApiActivityActions;
    private apiCrmActivityTypes = ApiCrmActivityTypes;
    private customerActivityTypes = CustomerActivityTypes;
    private settings = Settings;
    private emailFromTypes = EmailFromTypes;
    private customerProcessStatuses = CustomerProcessStatuses;
    private exportCampaignTypes = ExportCampaignTypes;

    constructor(private activityHistoriesClientService: ActivityHistoryClientService,
        private activityClientService: ActivityClientService,
        private entityClientService: EntityClientService,
        private emailConversationClientService: EmailConversationClientService,
        private httpClient: HttpClient, 
        private httpInterceptorOptionsService: HttpInterceptorOptionsService,
        private tService: TranslateService) { }

    public runActivityRule(entityId: string, activityRules: ActivityActionTargetEntityRequest['activityRules']): Promise<any> {
        return this.activityClientService.RunActivityRulePOST({ entityId: entityId, activityRules: activityRules }).toPromise();
    }

    public getActivities(opportunityId: string, dealerId: number, customerId: number): Promise<Activity[]> {
        const params = {
            opportunityId: opportunityId,
            dealerId: dealerId,
            customerId: customerId
        } as ActivityHistoryClientService.GetCombinedActivityHistoryGETParams;

        return this.activityHistoriesClientService.GetCombinedActivityHistoryGET(params)
            .pipe(
                map(response => this.resolveActivities(response))
            ).toPromise();
    }

    private resolveActivities(response: ActivityHistoryCombinedDto): Activity[] {

        const activityHistories = response.activityHistories || [];
        const templateOutputs = response.templateOutputs || [];
        const printOffers = response.printOffers || [];
        const oneToOneActivities = response.oneToOneActivities || [];
        const externalCrmData = response.externalCrmData || [];
        const emailConversations = response.emailConversations || [];
        const customerEngagements = response.customerEngagements || [];
        const customerActivities = response.customerActivities || [];
        const customerProcesses = response.customerProcesses || [];
        const exportHistories = response.exportHistories || [];
        const appraisalHistories = response.appraisalHistories || [];
        const dmsPushHistories = response.dmsPushHistories || [];

        const textMessages = response.textMessages;
        const activities: Activity[] = [];

        const getFileUrlFromActivity = (activity: ActivityHistoryExtDto) => {
            if (activity.templateOutboundEmailID) {
                return this.settings.oidc.opportunitiesOrigin
                    + this.emailTemplatePath
                    + activity.templateOutboundEmailID
                    + '?code='
                    + activity.accessCode
                    + this.emailTemplateSentPreviewParam;
            }
        };

        /**
         * Adds /i to the end of a O2O URL so the tracking
         * counters don't count it as an open when we display it.
         */
        const ensureNonTrackingO2OUrl = (url: string) => {
            if (!url) // validate url is not empty/null or undefined
                return null;

            if (typeof url === 'string' && !url.endsWith('/i')) {
                return url + '/i';
            }

            return url;
        };

        for (let i = 0; i < exportHistories.length; i++) {
            const activity = exportHistories[i];

            let campaignTypeName = (_.invert(this.exportCampaignTypes))[activity.exportCampaignTypeID];
            let exportCampaignType = this.tService.instant('exportCampaignType_' + campaignTypeName);

            activities.push({
                type: this.activityTypes.export,
                submittedDateTime: new Date(activity.exportDate),
                submittedByUserFullName: activity.fullName,
                exportCampaignType: exportCampaignType,
                exportProvider: activity.providerName,
            } as Activity);
        }

        for (let i = 0; i < activityHistories.length; i++) {
            const activity = activityHistories[i];
            if (activity.activityTypeID === this.apiActivityTypes.note ||
                activity.activityTypeID === this.apiActivityTypes.phoneCall ||
                activity.activityTypeID === this.apiActivityTypes.appointment ||
                activity.activityTypeID === this.apiActivityTypes.crm ||
                activity.activityTypeID === this.apiActivityTypes.email) {
                activities.push({
                    activityId: activity.activityID,
                    type: this.getActivityType(activity),
                    submittedByUserFullName: activity.insertUserFullName,
                    submittedDateTime: new Date(activity.insertDate),
                    notes: activity.notes,
                    result: activity.activityResultID,
                    scheduledDateTime: activity.dueDate && dateFromLocalString(activity.dueDate),
                    assignedUserFullName: activity.responsibleUserFullName,
                    assignedUserID: activity.responsibleUserID,
                    activityActionId: activity.activityActionID,
                    sendReminder: activity.sendReminder,
                    // --email template data
                    emailSubject: activity.emailSubject,
                    firstOpenDate: activity.firstOpenDate,
                    lastOpenDate: activity.lastOpenDate,
                    totalCount: activity.openCount,
                    recordingUrl: activity.recordingUrl || '',
                    fileUrl: getFileUrlFromActivity(activity),
                    activityHistoryStatusId: activity.activityStatusID,
                    activityStatusId: activity.activityActivityStatusID,
                    activityActionSubTypeID: activity.activityActionSubTypeID,
                    templateEmailId: activity.templateOutboundEmailID,
                    accessCode: activity.accessCode,
                    upgradeProposalIsAvailable: activity.upgradeProposalIsAvailable,
                } as Activity);
            }

            if (activity.activityTypeID === this.apiActivityTypes.pandoProcess) {

                activities.push({
                    type: this.getActivityType(activity),
                    submittedDateTime: new Date(activity.insertDate),
                    notes: activity.notes,
                    assignedUserFullName: activity.responsibleUserFullName,
                    assignedUserID: activity.responsibleUserID,
                    activityId: activity.activityID,
                    activityActionId: activity.activityActionID,

                    activityHistoryStatusId: activity.activityStatusID,
                    activityStatusId: activity.activityActivityStatusID,
                    activityActionSubTypeID: activity.activityActionSubTypeID
                } as Activity);
            }
        }

        for (let j = 0; j < templateOutputs.length; j++) {
            const template = templateOutputs[j];

            activities.push({
                type: this.activityTypes.printTemplate,
                submittedByUserFullName: template.fullName,
                submittedDateTime: new Date(template.generateDate),
                templateName: template.templateName,
                templateOutputId: template.templateOutputID,
                usesNewStorage: template.usesNewStorage,
                fileUrl: this.settings.legacy.iframeUrl + this.printedTemplatesPath + template.filePath
            } as Activity);
        }

        for (let k = 0; k < printOffers.length; k++) {
            const printOffer = printOffers[k];

            activities.push({
                type: this.activityTypes.printOfferLetter,
                submittedByUserFullName: printOffer.generatedByUserFullName,
                submittedDateTime: new Date(printOffer.generateDate),
                fileUrl: printOffer.printUrl
            } as Activity);
        }



        for (let l = 0; l < oneToOneActivities.length; l++) {
            var oneToOneActivity = oneToOneActivities[l];

            let oneToOneType: number;

            if (oneToOneActivity.sourceID == EntityMailHistorySource.NVQ
                || oneToOneActivity.sourceID == EntityMailHistorySource.One2One
                || oneToOneActivity.sourceID == EntityMailHistorySource.SKG
            ) {
                // Filter One 2 One SourceID 1,2,3
                if (oneToOneActivity.mailFormatID === MailFormat.Mail) {
                    oneToOneType = this.activityTypes.sendOneToOneMail;
                } else if (oneToOneActivity.mailFormatID === MailFormat.Email) {
                    oneToOneType = this.activityTypes.sendOneToOneEmail;
                }
            }
            else if (oneToOneActivity.sourceID === EntityMailHistorySource.IntelligentMarketing) {
                // Filter Intelligent Marketing SourceID 4 is only IM
                if (oneToOneActivity.mailFormatID === MailFormat.Mail) {
                    oneToOneType = this.activityTypes.sendIntelMrktMail;
                } else if (oneToOneActivity.mailFormatID === MailFormat.Email) {
                    oneToOneType = this.activityTypes.sendIntelMrktEmail;
                }
            }
            else if ((oneToOneActivity.sourceID === EntityMailHistorySource.FordCreditMailers)) {
                // Filter SourceID 5 is Ford Credit Mailers => mail sent on a by-law rule to non O2O dealers (WEBUI-8901 & WEBUI-8902)
                oneToOneType = this.activityTypes.sendFordCreditMailers;
            }
            else if (oneToOneActivity.sourceID === EntityMailHistorySource.ServiceMarketing) {
                if (oneToOneActivity.mailFormatID === MailFormat.Mail) {
                    oneToOneType = this.activityTypes.sendServiceMarketingMail;
                } else if (oneToOneActivity.mailFormatID === MailFormat.Email) {
                    oneToOneType = this.activityTypes.sendServiceMarketingEmail;
                }
                oneToOneActivity.pUrl = null;  // WEBUI-9831 SM should not display a pUrl link
            }
            else if (oneToOneActivity.sourceID === EntityMailHistorySource.SLM) {
                if (oneToOneActivity.mailFormatID === MailFormat.Mail) {
                    continue;
                } else if (oneToOneActivity.mailFormatID === MailFormat.Email) {
                    oneToOneType = this.activityTypes.sentSLMEmailOffer;
                }
            }
            else if (oneToOneActivity.sourceID === EntityMailHistorySource.PartnerExport) {
                if (oneToOneActivity.mailFormatID === MailFormat.Email) {
                    continue;
                } else if (oneToOneActivity.mailFormatID === MailFormat.Mail) {
                    oneToOneType = this.activityTypes.sendPartnerExportMail;

                    // For now we are stuffing Parter Export only data into the pUrl field
                    if (oneToOneActivity.pUrl != null) {
                        var strArray = oneToOneActivity.pUrl.split("|");
                        if (strArray.length == 2) {
                            oneToOneActivity.preferredPartner = strArray[0];
                            oneToOneActivity.messageCreativeType = strArray[1];
                        }
                    }
                    oneToOneActivity.pUrl = null; // WEBUI-10324 Partner Export does not display a pUrl link
                }
            }

            activities.push({
                type: oneToOneType,
                submittedDateTime: oneToOneActivity.utcDateSent ? new Date(oneToOneActivity.utcDateSent) : null,
                oneToOneKey: oneToOneActivity.mailServiceProviderID + '_' + oneToOneActivity.mailFormatID + '_' + oneToOneActivity.mailCode,
                pUrl: oneToOneType == this.activityTypes.sendPartnerExportMail ? oneToOneActivity.pUrl : ensureNonTrackingO2OUrl(oneToOneActivity.pUrl),
                preferredPartner: oneToOneActivity.preferredPartner,
                additionalData: oneToOneActivity.messageCreativeType ? this.tService.instant("partnerExportMessage", { messageCreativeType: oneToOneActivity.messageCreativeType }) : null
            } as Activity);
        }

        for (let p = 0; p < customerProcesses.length; p++) {
            let customerProcess = customerProcesses[p];
            let processStatusCodeName = (_.invert(this.customerProcessStatuses))[customerProcess.status];
            let status = this.tService.instant('customerProcessStatus_' + processStatusCodeName);

            activities.push({
                type: this.activityTypes.customerPandoProcess,
                submittedDateTime: customerProcess.startDateUtc ? new Date(customerProcess.startDateUtc) : null,
                templateName: customerProcess.processTemplateName,
                processTitle: customerProcess.processTitle,
                submittedByUserFullName: customerProcess.requestorFirstLast,
                assignedUserFullName: customerProcess.lastAssignedToFirstLast,
                requestorAvatarUrl: customerProcess.requestorAvatarUrl,
                lastAssignedToAvatarUrl: customerProcess.lastAssignedToAvatarUrl,
                customerProcessStatus: status,
                customerProcessStatusCode: customerProcess.status,
                elapsedSeconds: customerProcess.elapsedSeconds,
                lastActivityDateTime: customerProcess.lastActivityDateUtc
            } as Activity);
        }

        // customer activities (handle autoAssistCustomerReplied events separately)
        customerActivities.forEach((custActivity) => {
            let typeId = -1;
            switch (custActivity.customerActivityEventID) {
                case this.customerActivityTypes.autoAssistCommSent:
                    typeId = this.activityTypes.autoAssistCommSent;
                    break;
                case this.customerActivityTypes.autoAssistNewHotLead:
                    typeId = this.activityTypes.autoAssistNewHotLead;
                    break;
                case this.customerActivityTypes.geoAlertActive:
                    typeId = this.activityTypes.geoAlertActive;
                    break;
                case this.customerActivityTypes.geoAlertCampaign:
                    typeId = this.activityTypes.geoAlertCampaign;
                    break;
                case this.customerActivityTypes.geoAlertAd:
                    typeId = this.activityTypes.geoAlertAd;
                    break;
                case this.customerActivityTypes.facebookAdded:
                    typeId = this.activityTypes.facebookAdded;
                    break;
                case this.customerActivityTypes.facebookVerified:
                    typeId = this.activityTypes.facebookVerified;
                    break;
                case this.customerActivityTypes.autofiDigitalRetailing:
                    typeId = this.activityTypes.autofiDigitalRetailing;
                    break;
                case this.customerActivityTypes.autofiDigitalRetailingCreditApp:
                    typeId = this.activityTypes.autofiDigitalRetailingCreditApp;
                    break;
                case this.customerActivityTypes.autofiDigitalRetailingApptSched:
                    typeId = this.activityTypes.autofiDigitalRetailingApptSched;
                    break;
            }
            if (typeId !== -1) {
                activities.push({
                    type: typeId,
                    submittedDateTime: new Date(custActivity.activityDateUTC),
                    messageText: custActivity.eventDetails,
                } as Activity);
            }
        });

        // AutoAssist autoAssistCustomerReplied activities
        const autoAssistActivities = customerActivities
            .filter(custActivity => custActivity.customerActivityEventID === this.customerActivityTypes.autoAssistCustomerReplied)
            .map(custActivity => ({
                activityDateUTC: custActivity.activityDateUTC,
                campaignId: custActivity.campaignID,
                eventDetails: custActivity.eventDetails
            }));

        const activitiesByCampaign = groupBy(autoAssistActivities, (act) => {
            return act.campaignId;
        });

        for (const [campaignId, activitesPerCampaign] of Object.entries(activitiesByCampaign)) {
            activitesPerCampaign.sort((activity1: any, activity2: any) => {
                const time1 = moment.utc(activity1.activityDateUTC, 'YYYY-MM-DD[T]HH:mm[Z]');
                const time2 = moment.utc(activity2.activityDateUTC, 'YYYY-MM-DD[T]HH:mm[Z]');
                const isAfter = time2.isAfter(time1);
                return isAfter ? 1 : -1;
            });

            activities.push({
                type: this.activityTypes.autoAssistCustomerReplied,
                // Take the most recent event
                submittedDateTime: new Date(activitesPerCampaign[0].activityDateUTC),
                messageText: activitesPerCampaign[0].eventDetails
            } as Activity);
        }



        const ungroupedctivities = customerEngagements
            .map((engagement) => {
                switch (engagement.engagementEventTypeID) {
                    case EngagementEventTypes.purlView:
                        return {
                            type: this.activityTypes.purlView,
                            submittedDateTime: engagement.eventDateUTC ? new Date(engagement.eventDateUTC) : null,
                            pUrl: ensureNonTrackingO2OUrl(engagement.purl),
                        };
                    case EngagementEventTypes.purlViewIM:
                        return {
                            type: this.activityTypes.purlViewIntelMrkt,
                            submittedDateTime: engagement.eventDateUTC ? new Date(engagement.eventDateUTC) : null,
                            pUrl: ensureNonTrackingO2OUrl(engagement.purl),
                        };
                    case EngagementEventTypes.purlLeadSubmission:
                        return {
                            type: this.activityTypes.purlSubmit,
                            submittedDateTime: engagement.eventDateUTC ? new Date(engagement.eventDateUTC) : null,
                            pUrl: ensureNonTrackingO2OUrl(engagement.purl),
                        };
                    case EngagementEventTypes.purlLeadSubmissionIM:
                        return {
                            type: this.activityTypes.purlSubmitIntelMrkt,
                            submittedDateTime: engagement.eventDateUTC ? new Date(engagement.eventDateUTC) : null,
                            pUrl: ensureNonTrackingO2OUrl(engagement.purl),
                        };
                    case EngagementEventTypes.onlineServiceReview:
                        return {
                            type: this.activityTypes.reviewProServiceRating,
                            submittedDateTime: engagement.eventDateUTC ? new Date(engagement.eventDateUTC) : null,
                            pUrl: engagement.purl,
                            reviewRating: engagement.reviewRating
                        };
                    case EngagementEventTypes.onlineSalesReview:
                        return {
                            type: this.activityTypes.reviewProSalesRating,
                            submittedDateTime: engagement.eventDateUTC ? new Date(engagement.eventDateUTC) : null,
                            pUrl: engagement.purl,
                            reviewRating: engagement.reviewRating
                        };
                }
            })
            .filter(item => item !== undefined);

        activities.push.apply(activities, ungroupedctivities);

        // websiteEngaged
        const webEngagementActivities = (() => {

            interface EngagementHistoryWorkDto {
                eventDateUTC?: Date;
                purl?: string;
                notes?: string;
            }

            const viewDates = customerEngagements
                .filter(engagement => engagement.engagementEventTypeID === EngagementEventTypes.websiteEngaged)
                .map(engagement => ({
                    eventDateUTC: engagement.eventDateUTC ? dateFromUtcString(engagement.eventDateUTC) : null,
                    notes: engagement.notes,
                    purl: engagement.purl
                }) as EngagementHistoryWorkDto);
            
            // Group the website engagements by day, then return the
            // total number of views
            const grouped = groupBy(viewDates, date => date.eventDateUTC ? date.eventDateUTC.toDateString() : null);
            
            return Object.entries(grouped)
                .map(([day, dates]) => {
                    const datesArray = dates.map((d)=> {
                        return d.eventDateUTC
                    });
                    const firstViewedDate = datesArray.reduce((acc, d) => acc < d ? acc : d);
                    const lastViewedDate = datesArray.reduce((acc, d) => acc > d ? acc : d);
                    return {
                        type: this.activityTypes.websiteEngaged,
                        submittedDateTime: lastViewedDate,
                        firstViewedDate: firstViewedDate,
                        lastViewedDate: lastViewedDate,
                        viewCount: dates.length,
                        items: dates
                    };
                });
        })();

        activities.push.apply(activities, webEngagementActivities);

        // website lead submission
        const webLeadSubmissionActivities = customerEngagements
            .filter(engagement => engagement.engagementEventTypeID === EngagementEventTypes.websiteLeadSubmission)
            .map(engagement => ({
                type: this.activityTypes.websiteLeadSubmission,
                submittedDateTime: engagement.eventDateUTC ? dateFromUtcString(engagement.eventDateUTC) : null,
            }));

        activities.push.apply(activities, webLeadSubmissionActivities);

        if (externalCrmData) {
            for (let n = 0; n < externalCrmData.length; n++) {
                const template = externalCrmData[n];
                activities.push({
                    type: this.getExternalActivityType(template.activityType + ''),
                    submittedByUserFullName: template.performedByFullName,
                    submittedDateTime: template.activityDate ? new Date(template.activityDate) : null,
                    emailSubject: template.subject,
                    emailTo: template.toEmailAddress,
                    emailFrom: template.fromEmailAddress,
                    notes: template.notes,
                    contactPhone: template.contactedPhone,
                } as Activity);
            }
        }

        for (let p = 0; p < emailConversations.length; p++) {
            const emailConversation = emailConversations[p];

            let emailConversationType: number;
            let emailConversationSubmittedBy: string;
            if (emailConversation.emailFrom === this.emailFromTypes.None) {
                emailConversationType = this.activityTypes.emailReply;
                emailConversationSubmittedBy = emailConversation.fromName;
            } else if (emailConversation.emailFrom === this.emailFromTypes.Customer) {
                emailConversationType = this.activityTypes.emailReplyCustomer;
                emailConversationSubmittedBy = emailConversation.customerName;
            } else if (emailConversation.emailFrom === this.emailFromTypes.User) {
                emailConversationType = this.activityTypes.emailReplyDealer;
                emailConversationSubmittedBy = emailConversation.userFullName;
            }

            activities.push({
                type: emailConversationType,
                emailConversationItemId: emailConversation.emailConversationItemId,
                submittedByUserFullName: emailConversationSubmittedBy,
                emailConversationSubject: emailConversation.subject,
                submittedDateTime: new Date(emailConversation.sentDateTime),
                firstOpenDate: emailConversation.emailFirstOpenedDateTime,
                lastOpenDate: emailConversation.emailLastOpenedDateTime,
                totalCount: emailConversation.emailOpenCount
            } as Activity);
        }

        // Appraisals
        for (let r = 0; r < appraisalHistories.length; r++) {
            const appraisalHistory = appraisalHistories[r];

            activities.push({
                type: this.activityTypes.appraisal,
                appraisalId: appraisalHistory.appraisalID,
                appraisalUrl: appraisalHistory.appraisalLink,
                submittedByUserFullName: appraisalHistory.fullName,
                submittedDateTime: new Date(appraisalHistory.insertDateUTC),
            } as Activity);
        }

        // Dms push activites
        for (let r = 0; r < dmsPushHistories.length; r++) {
            const dmsPushHistory = dmsPushHistories[r];

            activities.push({
                type: this.activityTypes.dmsPush,
                dmsCustomerId: dmsPushHistory.dmsCustomerId,
                dmsDealId: dmsPushHistory.dmsDealId,
                submittedByUserFullName: dmsPushHistory.fullName,
                submittedDateTime: new Date(dmsPushHistory.createDateUtc),
                dmsRequestTypeId: dmsPushHistory.requestTypeId
            } as Activity);
        }


        // Text messages
        const textMessagesByDay = groupBy(textMessages, (tm) => {
            return `${moment(tm.sentDateTime).startOf('day')}|{${tm.dealerPhoneAccessRightID}`;
        });

        for (let [groupKey, textMessagesPerDay] of Object.entries(textMessagesByDay)) {
            textMessagesPerDay = textMessagesPerDay.sort((textMessage1, textMessage2) => {
                const isAfter = moment(textMessage2.sentDateTime).isAfter(textMessage1.sentDateTime);
                return isAfter ? -1 : 1;
            });

            activities.push({
                activityId: textMessagesPerDay[0].messageId,
                type: this.activityTypes.textMessage,
                // We take the latest text message so that the activity will show up in the right place in the activity list
                submittedDateTime: new Date(textMessagesPerDay[0].sentDateTime),
                textMessages: textMessagesPerDay
            } as Activity);
        }

        const bySubmittedDateTime = (activity1, activity2) => {
            const time2 = new Date(activity2.submittedDateTime || activity2.submittedDate).getTime();
            const time1 = new Date(activity1.submittedDateTime || activity1.submittedDate).getTime();
            return time2 - time1;
        };

        activities.sort(bySubmittedDateTime);

        return activities;
    }

    getLastActivityDetails(opportunityId: string): Promise<LastActivityDetailsExtDto> {
        return this.activityHistoriesClientService.GetLastActivityByEntityIdByEntityidGET(opportunityId).toPromise();
    }

    getAgendaItems(selectedDealerId: number, take?: number, flushCache?: boolean): Promise<ActivityExtendedWithVwSearchDto[]> {
        let params: ActivityClientService.GetAgendaItemsGETParams = {};

        params.flushCache = flushCache;
        params.take = take;
        
        if (selectedDealerId !== null && selectedDealerId !== undefined) {
            this.httpInterceptorOptionsService.setOverrideDealerIds([selectedDealerId])
        }

        return this.activityClientService.GetAgendaItemsGET(params).toPromise();
    }

    getAgendaItemsWithEntityData(params: ActivityClientService.GetAgendaItemsWithEntityDataGETParams): Promise<ActivityWithEntityDto[]> {
        return this.activityClientService.GetAgendaItemsWithEntityDataGET(params).toPromise();
    }

    postActivitySubmitAction(activity: ActivityActionSubmissionModel): Promise<ActivityActionResponseModel> {
        return this.activityClientService.SubmitActionPOST(activity).toPromise();
    }

    updateWarrantyStatus(opportunityId: string): Promise<null> {
        return this.entityClientService.HasWarrantyByEntityidPOST(opportunityId).toPromise();
    }

    updateSoldWarranty(opportunityId: string): Promise<null> {
        return this.entityClientService.SoldWarrantyByEntityidPOST(opportunityId).toPromise();
    }

    updateCustomerDoesNotOwnVehicle(opportunityId: string): Promise<null> {
        return this.entityClientService.CustomerDoesNotOwnVehicleByEntityidPOST(opportunityId).toPromise();
    }

    updateVehiclePaidOff(opportunityId: string): Promise<null> {
        return this.entityClientService.VehiclePaidOffByEntityidPOST(opportunityId).toPromise();
    }

    updateVehiclePurchasedByDealer(opportunityId: string): Promise<null> {
        return this.entityClientService.VehiclePurchasedByDealerByEntityidPOST(opportunityId).toPromise();
    }

    getEmailConversation(emailConversationItemId: number): Promise<any> {
        return this.emailConversationClientService.GetEmailBodyByIdGET(emailConversationItemId).toPromise();
    }

    getActivityType(activity) {
        if (activity.activityTypeID === ApiActivityTypes.phoneCall) {
            let type = this.activityTypes.logPhoneCall;

            switch (activity.activityStatusID) {
                case this.apiActivityStatuses.scheduled:
                    type = this.activityTypes.schedulePhoneCall;
                    break;
                case this.apiActivityStatuses.completed:
                    type = activity.dueDate ? this.activityTypes.completePhoneCall : this.activityTypes.logPhoneCall;
                    break;
                case this.apiActivityStatuses.uncompleted:
                    type = this.activityTypes.expirePhoneCall;
                    break;
            }

            switch (activity.activityActionID) {
                case this.apiActivityActions.reschedulePhoneCall:
                    type = this.activityTypes.reschedulePhoneCall;
                    break;
                case this.apiActivityActions.reassignPhoneCall:
                    type = this.activityTypes.reassignPhoneCall;
                    break;
                case this.apiActivityActions.cancelPhoneCall:
                    type = this.activityTypes.cancelPhoneCall;
                    break;
            }

            return type;
        }

        if (activity.activityTypeID === ApiActivityTypes.appointment) {
            let type = this.activityTypes.scheduleAppointment;

            switch (activity.activityStatusID) {
                case this.apiActivityStatuses.completed:
                    type = this.activityTypes.completeAppointment;
                    break;
                case this.apiActivityStatuses.uncompleted:
                    type = this.activityTypes.expireAppointment;
                    break;
            }

            switch (activity.activityActionID) {
                case this.apiActivityActions.rescheduleAppointment:
                    type = this.activityTypes.rescheduleAppointment;
                    break;
                case this.apiActivityActions.reassignAppointment:
                    type = this.activityTypes.reassignAppointment;
                    break;
                case this.apiActivityActions.cancelAppointment:
                    type = this.activityTypes.cancelAppointment;
                    break;
            }

            return type;
        }

        if (activity.activityTypeID === ApiActivityTypes.crm) {
            return this.activityTypes.pushToCrm;
        }

        if (activity.activityTypeID === ApiActivityTypes.email) {
            let type;
            switch (activity.activityActionID) {
                case this.apiActivityActions.sendEmailTemplate:
                    type = this.activityTypes.sendEmailTemplate;
                    break;
                case this.apiActivityActions.sendEmail:
                    type = this.activityTypes.sendEmail;
                    break;
            }
            return type;
        }

        if (activity.activityTypeID === ApiActivityTypes.pandoProcess) {
            return this.activityTypes.pandoProcess;
        }

        return activity.activityTypeID;
    }

    getExternalActivityType(key: string) {
        key = key.toLowerCase();
        let resultType = -1;
        switch (key) {
            case this.apiCrmActivityTypes.beBack:
                resultType = this.activityTypes.logCrmCustomerWillBeBack;
                break;
            case this.apiCrmActivityTypes.inboundCall:
                resultType = this.activityTypes.logCrmInboundCall;
                break;
            case this.apiCrmActivityTypes.inboundEmail:
                resultType = this.activityTypes.logCrmInboundEmail;
                break;
            case this.apiCrmActivityTypes.letter:
                resultType = this.activityTypes.sendCrmLetter;
                break;
            case this.apiCrmActivityTypes.outboundCall:
                resultType = this.activityTypes.logCrmOutboundCall;
                break;
            case this.apiCrmActivityTypes.outboundEmail:
                resultType = this.activityTypes.logCrmOutboundEmail;
                break;
            case this.apiCrmActivityTypes.prospectAlert:
                resultType = this.activityTypes.logCrmProspectAlert;
                break;
            case this.apiCrmActivityTypes.prospectStatus:
                resultType = this.activityTypes.logCrmProspectStatus;
                break;
            case this.apiCrmActivityTypes.remark:
                resultType = this.activityTypes.logCrmRemark;
                break;
            case this.apiCrmActivityTypes.remarkShowroom:
                resultType = this.activityTypes.logCrmShowroomRemark;
                break;
            case this.apiCrmActivityTypes.reminder:
                resultType = this.activityTypes.sendCrmReminder;
                break;
            case this.apiCrmActivityTypes.textMessage:
                resultType = this.activityTypes.sendCrmTextMessage;
                break;
            case this.apiCrmActivityTypes.inboundTextMessage:
                resultType = this.activityTypes.crmInboundTextMessage;
                break;
            case this.apiCrmActivityTypes.outboundTextMessage:
                resultType = this.activityTypes.crmOutboundTextMessage;
                break;
        }
        return resultType;
    }
}
