"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var platform_browser_1 = require("@angular/platform-browser");
var core_1 = require("@angular/core");
var SafePipe = /** @class */ (function () {
    function SafePipe(dom) {
        this.dom = dom;
    }
    SafePipe.prototype.transform = function (url) {
        return this.dom.bypassSecurityTrustResourceUrl(url);
    };
    return SafePipe;
}());
exports.SafePipe = SafePipe;
