"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PriceComponent = /** @class */ (function () {
    function PriceComponent(elementRef, injector) {
        this.priceChange = new core_1.EventEmitter();
    }
    PriceComponent.prototype.ngOnInit = function () {
    };
    PriceComponent.prototype.ngOnChanges = function (changes) {
        if (changes.value) {
            this.value = Math.floor(this.value);
        }
    };
    PriceComponent.prototype.update = function (source) {
        this.validate(source);
    };
    // vlidate input to number only
    PriceComponent.prototype.validate = function (source) {
        if (source == '') {
            this.value = 0;
            return;
        }
        if (!source) {
            this.value = 0;
            return;
        }
        source = source + '';
        source = source.trim();
        source = source.replace(/[^0-9]/g, '');
        this.value = parseInt(source);
    };
    PriceComponent.prototype.onValueChange = function (value) {
        this.validate(value);
        this.priceChange.emit({ price: this.value });
    };
    return PriceComponent;
}());
exports.PriceComponent = PriceComponent;
