"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var modal_1 = require("ngx-bootstrap/modal");
var ConfirmModalComponent = /** @class */ (function () {
    function ConfirmModalComponent(_bsModalRef, tService) {
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.onClosed = new core_1.EventEmitter();
    }
    ConfirmModalComponent.prototype.ngOnInit = function () {
    };
    ConfirmModalComponent.prototype.onContinue = function () {
        this.onClosed.emit({ confirmed: true });
        this._bsModalRef.hide();
    };
    ConfirmModalComponent.prototype.onCancel = function () {
        this.onClosed.emit({ confirmed: false });
        this._bsModalRef.hide();
    };
    return ConfirmModalComponent;
}());
exports.ConfirmModalComponent = ConfirmModalComponent;
