"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../../constants");
var PaymentsComponent = /** @class */ (function () {
    function PaymentsComponent() {
        this.paymentTypes = constants_1.PaymentTypes;
        this.conquestTypes = constants_1.ConquestTypes;
        this.saleTypes = constants_1.SaleTypes;
        this.absoluteValue = Math.abs;
    }
    PaymentsComponent.prototype.ngOnInit = function () {
    };
    PaymentsComponent.prototype.ngOnChanges = function (changes) {
        // Only display this if the improvement is at least 5 CurrencyBucks
        this.shouldShowPaymentBeforeImprovement =
            this.contract && this.contract.paymentBeforeImprovement - this.contract.payment >= 5;
        if (this.contract && this.contract.lastPaymentDate) {
            var lastPaymentDate = new Date(this.contract.lastPaymentDate);
            var lastPaymentMonths = (lastPaymentDate.getFullYear() * 12) + lastPaymentDate.getMonth();
            var now = new Date();
            var currentDateMonths = (now.getFullYear() * 12) + now.getMonth();
            this.paymentsRemainingMo = lastPaymentMonths - currentDateMonths;
            if (this.paymentsRemainingMo < 0) {
                this.paymentsRemainingMo = 0;
            }
        }
    };
    return PaymentsComponent;
}());
exports.PaymentsComponent = PaymentsComponent;
