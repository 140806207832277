
/* injects from baggage-loader */
require('./dashboard-widget.component.html');

import angular from 'angular';
import _ from 'lodash';

(function () {
    angular.module('AaApp.Opp').component('dashboardWidget', {
        templateUrl: '/app/components/dashboard/dashboard-widget.component.html',
        controller: DashboardWidgetController,
        transclude: {
            'widgetHeaderIcon': '?widgetHeaderIcon',
            'widgetPreamble': '?widgetPreamble',
        },
        bindings: {
            widgetType: '<',
            widgetDefaultOrder: '<',
            isFavorite: '<',
            widgetLink: '<',
            widgetTitleKey: '<',
            widgetExpandText: '<',
            widgetDataService: '<',
            widgetExpandToolTipKey: '<',
            columnTypes: '<',
            detailViewLinkDisabled: '<',
            triggerRefresh: '<',
            roleId: '<',
            permissions: '<',
            selectedDealerId: '<',
            onCloseCallback: '&',
            showFavorites: '<',
            onSetFavorite: '&',
            isCollapsable: '<',
            isCollapsed: '<',
            onToggleCollapse: '&',
        }
    });

    /* @ngInject */
    function DashboardWidgetController($q, $state, widgetTypes, searchPresetService, userService, roles, tokenService, modules, authorizationService, dealerService, assetTypes) {
        const $ctrl = this;

        $ctrl.showOpportunitySearches;
        $ctrl.searchPresetLimit;
        $ctrl.dealerId;
        $ctrl.availablePresets;
        $ctrl.selectedPresets;
        $ctrl.refresh = refresh;
        $ctrl.saveSelectedSearchPresets = saveSelectedSearchPresets;
        $ctrl.pandoWorkflowIntegrationEnabled = userService.isModuleEnabledForAnyDealer(modules.pandoWorkflowIntegration) && tokenService.isMotoFuzeUser();
        $ctrl.isEuroLite = authorizationService.isEuroLite();
        $ctrl.pandoXEnabled = false;
        $ctrl.setFavorite = setFavorite;
        $ctrl.toggleCollapse = toggleCollapse;

        $ctrl.widgetTypes = widgetTypes;

        // widgetState an object that the widget itself can manage, and we will
        // pass into the widgetDataService function. Most widgets will not use
        // it, but searchPresetCounts needs it to keep track of how many items
        // are shown.
        $ctrl.widgetState = null;

        const isEuroLite = authorizationService.isEuroLite();


        $ctrl.$onInit = function () {
            $ctrl.roles = roles;
            $ctrl.searchPresetLimit = 5;

            userService.getUserProfile()
                .then(userProfile => {
                    $ctrl.isEmployeeUser = userProfile.employeeUser;
                    $ctrl.dealerId = userProfile.dealerIds[0];
                    refresh(false);
                });

            $ctrl.leadAssignOwnerAccessEnabled = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.leadAssignOwnerAccess);
        }

        $ctrl.$onChanges = function (changes) {
            if (changes.triggerRefresh && $ctrl.triggerRefresh > 0) { 
                refresh(false); 
            }

            if ($ctrl.isCollapsable && changes.isCollapsed && $ctrl.isCollapsed == false) {
                refresh(false);
            }

            if (changes.selectedDealerId) { 
                $ctrl.leadAssignOwnerAccessEnabled = userService.isDealerEnabledForModule($ctrl.selectedDealerId, modules.leadAssignOwnerAccess);

                dealerService.getIsAssetEnabledForDealer(assetTypes.pandoX, $ctrl.selectedDealerId)
                    .then(isEnabled => {$ctrl.pandoXEnabled = isEnabled;})
            }
        }

        $ctrl.gotoState = function () {            
            if (!isEuroLite)
                $state.go($ctrl.widgetLink, { dealerId: $ctrl.selectedDealerId });
            else {
                var filter = { "type": $ctrl.widgetTitleKey };
                $state.go($ctrl.widgetLink, { filter: filter });
            }

        }

        function refresh(hardRefresh) {

            if ($ctrl.isCollapsable) {
                if (hardRefresh && $ctrl.isCollapsed) {
                    $ctrl.isCollapsed = false;
                }

                if ($ctrl.isCollapsed) {  // don't make call to get data
                    return;
                }
            }

            $ctrl.showOpportunitySearched = ($ctrl.widgetType == $ctrl.widgetTypes.searchPresetCounts 
                                             && ($ctrl.selectedDealerId != null));
            $ctrl.widgetData = null;
            $ctrl.widgetDataPromise = $ctrl.widgetDataService(hardRefresh, $ctrl.widgetState).then(function (data) {
                $ctrl.widgetData = data;
            });
            return $ctrl.widgetDataPromise;
        }

        $ctrl.runDateChange = function (runDate) {
          $ctrl.runDate = runDate;
        }

        $ctrl.closeCallback = function (opId) {
            var type = $ctrl.widgetType;
            if (!type) { return; }
            refresh(false).then(function () {
                if (type === widgetTypes.agenda || type === widgetTypes.recentlyAssigned) { return; }
                if ($ctrl.widgetData) {
                    var dsIndex = -1;
                    dsIndex = _.findIndex($ctrl.widgetData, function (o) { return o.entityID === opId });
                    if (dsIndex === -1) {
                        $ctrl.onCloseCallback({ type: type });
                    }
                }
            });
        }

        function saveSelectedSearchPresets(searchPresets) {
            searchPresetService.updateDealerSearchPresetLayouts(searchPresets.dealerId, searchPresets.selectedSearchPresets)
                .then(success => {
                    if (success) {
                        refresh(false);
                    }
                })
        }

        function setFavorite(widgetDefaultOrder) {
            $ctrl.isFavorite = !$ctrl.isFavorite;
            $ctrl.onSetFavorite({ index: widgetDefaultOrder, isOn: $ctrl.isFavorite })
        }

        function toggleCollapse(widgetDefaultOrder) {
            $ctrl.isCollapsed = !$ctrl.isCollapsed;
            $ctrl.onToggleCollapse({ index: widgetDefaultOrder, isCollapsed: $ctrl.isCollapsed });
        }

    }
})();
