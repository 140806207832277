"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var base_service_1 = require("../base-service");
var alertminer_api_configuration_1 = require("../alertminer-api-configuration");
var operators_1 = require("rxjs/operators");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../alertminer-api-configuration"));
var i2 = __importStar(require("@angular/common/http"));
var TagClientService = /** @class */ (function (_super) {
    __extends(TagClientService, _super);
    function TagClientService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param entityId undefined
     * @return Success
     */
    TagClientService.prototype.GetTagsForEntityByEntityidGETResponse = function (entityId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Tag/GetTagsForEntity/" + entityId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param entityId undefined
     * @return Success
     */
    TagClientService.prototype.GetTagsForEntityByEntityidGET = function (entityId) {
        return this.GetTagsForEntityByEntityidGETResponse(entityId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param flushCache undefined
     * @return Success
     */
    TagClientService.prototype.GetTagsPivotForDealersGETResponse = function (flushCache) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        if (flushCache != null)
            __params = __params.set('flushCache', flushCache.toString());
        var req = new http_1.HttpRequest('GET', this.rootUrl + "/api/Tag/GetTagsPivotForDealers", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param flushCache undefined
     * @return Success
     */
    TagClientService.prototype.GetTagsPivotForDealersGET = function (flushCache) {
        return this.GetTagsPivotForDealersGETResponse(flushCache).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETParams` containing the following parameters:
     *
     * - `tagText`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    TagClientService.prototype.IsTagActivePast6MonthsByDealeridByTagtextGETResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Tag/IsTagActivePast6Months/" + params.dealerId + "/" + params.tagText), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @param params The `TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETParams` containing the following parameters:
     *
     * - `tagText`:
     *
     * - `dealerId`:
     *
     * @return Success
     */
    TagClientService.prototype.IsTagActivePast6MonthsByDealeridByTagtextGET = function (params) {
        return this.IsTagActivePast6MonthsByDealeridByTagtextGETResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    TagClientService.prototype.GetTagsForDealersByDealeridGETResponse = function (dealerId) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        var req = new http_1.HttpRequest('GET', this.rootUrl + ("/api/Tag/GetTagsForDealers/" + dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param dealerId undefined
     * @return Success
     */
    TagClientService.prototype.GetTagsForDealersByDealeridGET = function (dealerId) {
        return this.GetTagsForDealersByDealeridGETResponse(dealerId).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param request undefined
     */
    TagClientService.prototype.GetActiveTagsInDateRangePOSTResponse = function (request) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + "/api/Tag/GetActiveTagsInDateRange", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     */
    TagClientService.prototype.GetActiveTagsInDateRangePOST = function (request) {
        return this.GetActiveTagsInDateRangePOSTResponse(request).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `request`:
     */
    TagClientService.prototype.GetActiveTagsInDateRangeForDealerByDealeridPOSTResponse = function (params) {
        var __params = this.newParams();
        var __headers = new http_1.HttpHeaders();
        var __body = null;
        __body = params.request;
        var req = new http_1.HttpRequest('POST', this.rootUrl + ("/api/Tag/GetActiveTagsInDateRangeForDealer/" + params.dealerId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(operators_1.filter(function (_r) { return _r instanceof http_1.HttpResponse; }), operators_1.map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTParams` containing the following parameters:
     *
     * - `dealerId`:
     *
     * - `request`:
     */
    TagClientService.prototype.GetActiveTagsInDateRangeForDealerByDealeridPOST = function (params) {
        return this.GetActiveTagsInDateRangeForDealerByDealeridPOSTResponse(params).pipe(operators_1.map(function (_r) { return _r.body; }));
    };
    TagClientService.GetTagsForEntityByEntityidGETPath = '/api/Tag/GetTagsForEntity/{entityId}';
    TagClientService.GetTagsPivotForDealersGETPath = '/api/Tag/GetTagsPivotForDealers';
    TagClientService.IsTagActivePast6MonthsByDealeridByTagtextGETPath = '/api/Tag/IsTagActivePast6Months/{dealerId}/{tagText}';
    TagClientService.GetTagsForDealersByDealeridGETPath = '/api/Tag/GetTagsForDealers/{dealerId}';
    TagClientService.GetActiveTagsInDateRangePOSTPath = '/api/Tag/GetActiveTagsInDateRange';
    TagClientService.GetActiveTagsInDateRangeForDealerByDealeridPOSTPath = '/api/Tag/GetActiveTagsInDateRangeForDealer/{dealerId}';
    TagClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagClientService_Factory() { return new TagClientService(i0.ɵɵinject(i1.AlertminerApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: TagClientService, providedIn: "root" });
    return TagClientService;
}(base_service_1.BaseService));
exports.TagClientService = TagClientService;
