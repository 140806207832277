ActionCheckboxController.$inject = ["opportunityStatuses", "vehicleActions"];

/* injects from baggage-loader */
require('./action-checkbox.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('actionCheckbox', {
  templateUrl: '/app/components/deal-sheet/action-checkbox.component.html',
  controller: ActionCheckboxController,
  bindings: {
    entityId: '<',
    displayNlo: '<',
    nloData: '<',
    status: '<',
    permissions: '<',
    action: '<',
    checkedAction: '<',
    onChange: '&',
    onNlo: '&'
  }
});
/* @ngInject */

function ActionCheckboxController(opportunityStatuses, vehicleActions) {
  var $ctrl = this;
  $ctrl.visible;
  $ctrl.enabled;
  $ctrl.disabled;
  $ctrl.$onChanges = onChanges;
  $ctrl.vehicleActions = vehicleActions;
  $ctrl.onMarkAsNLO = onMarkAsNLO;

  function onChanges(changes) {
    if (changes.status && changes.status.currentValue && $ctrl.permissions.editMarkAsSold) {
      $ctrl.visible = $ctrl.status != opportunityStatuses.closed;
      $ctrl.disabled = $ctrl.status == opportunityStatuses.sold || $ctrl.status == opportunityStatuses.closed;
    }

    if (changes.checkedAction && changes.checkedAction.currentValue) {
      if ($ctrl.action == $ctrl.vehicleActions.purchasedByDealer) {
        $ctrl.enabled = $ctrl.checkedAction == 72;
      }

      if ($ctrl.action == $ctrl.vehicleActions.doesNotOwnVehicle) {
        $ctrl.enabled = $ctrl.checkedAction == 26;
      }
    }
  }

  function onMarkAsNLO(e) {
    $ctrl.onNlo({
      markAsNLO: e.markAsNLO
    });
  }
}