"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FinancedAmountComponent = /** @class */ (function () {
    function FinancedAmountComponent() {
    }
    FinancedAmountComponent.prototype.ngOnInit = function () {
    };
    return FinancedAmountComponent;
}());
exports.FinancedAmountComponent = FinancedAmountComponent;
