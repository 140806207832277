"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
/**
 * Global configuration for alertminer-api services
 */
var AlertminerApiConfiguration = /** @class */ (function () {
    function AlertminerApiConfiguration() {
        this.rootUrl = '';
    }
    AlertminerApiConfiguration.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertminerApiConfiguration_Factory() { return new AlertminerApiConfiguration(); }, token: AlertminerApiConfiguration, providedIn: "root" });
    return AlertminerApiConfiguration;
}());
exports.AlertminerApiConfiguration = AlertminerApiConfiguration;
