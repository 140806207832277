import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class NgContentTypeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const method = req.method.toUpperCase();
        let newReq = null;

        if (method === 'POST' || 
            method === 'PUT' ||
            method === 'PATCH' ||            
            method === 'DELETE') {
            newReq = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            })
        }

        return next.handle(newReq || req);
    }
}