"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var ngx_modal_wrapper_service_1 = require("../../../services/ngx-modal-wrapper.service");
var score_info_modal_component_1 = require("..//score-info-modal/score-info-modal.component");
var ScoreInfoComponent = /** @class */ (function () {
    function ScoreInfoComponent(modalService, tService) {
        this.modalService = modalService;
        this.tService = tService;
    }
    ScoreInfoComponent.prototype.ngOnInit = function () {
    };
    ScoreInfoComponent.prototype.scoreInfoModal = function () {
        var initialState = {
            entityId: this.entityId,
        };
        this.bsModalRef = this.modalService.show(score_info_modal_component_1.ScoreInfoModalComponent, { initialState: initialState, class: 'score-info-modal' });
    };
    return ScoreInfoComponent;
}());
exports.ScoreInfoComponent = ScoreInfoComponent;
