type FetchCallback<T> = () => Promise<T>;

export interface CacheOptions {
    cacheAgeLimitSeconds?: number
}

export enum CacheFetchOptions {
    forceFetch
}

interface CacheItem<T> {
    value: T;
    cacheTimestamp?: Date;
}

/**
 * A simple helper to maintain an in memory cache of an object that is provided via a promise
 * 
 * @example
 * // Store this object somewhere permanently
 * private cachedUser = new ObjectCache<User>();
 * 
 * @example
 * // Get the value, or fetch it from the source
 * this.cachedUser.getOrFetch(myDtoId, () => fetchUser(myDtoId)).then(user => DoSomething(user))
 * 
 * @example
 * // If the fetchValue returns an Observable, the result is an observable
 * this.cachedUser.getOrFetch(myDtoId, () => fetchUser(myDtoId)).subscribe(user => DoSomething(user))
 * 
 */
export class ObjectCache<T> {
    constructor(options?: CacheOptions) {
        this.cacheOptions = options;
    }

    private cachedItems = new Map<any, CacheItem<T>>();
    private cacheOptions: CacheOptions;

    getOrFetch(key: any, callBack: FetchCallback<T>, cacheFetchOptions?: CacheFetchOptions): Promise<T> {
        if (key == null || key == undefined) {
            throw new Error('Cache requires a non-null, non-undefined key. key = ' + key);
        }

        if (!this.cachedItems.has(key) || (cacheFetchOptions !== null && cacheFetchOptions !== undefined && cacheFetchOptions === CacheFetchOptions.forceFetch)) {
            return this.fetchAndCache(key, callBack);
        }

        const cachedItem = this.cachedItems.get(key);

        if (this.cacheOptions) {
            if (this.cacheOptions.cacheAgeLimitSeconds) {
                const secondsDifference = (new Date().getTime() - cachedItem.cacheTimestamp.getTime()) / 1000;
                                
                if (secondsDifference >= this.cacheOptions.cacheAgeLimitSeconds) {
                    return this.fetchAndCache(key, callBack);
                }
            }
        }

        return Promise.resolve(this.cachedItems.get(key).value);
    }

    invalidateCacheForAllKeys(): void {
        this.cachedItems.clear();
    }

    invalidateCacheForKey(key: any): void {
        if (this.cachedItems.has(key)) {
            this.cachedItems.delete(key);
        }
    }

    private fetchAndCache(key: any, callBack: FetchCallback<T>): Promise<T> {

        const cacheItem = (key: any, item: T): void => {
            const timeStamp = new Date();
            this.cachedItems.set(key, { value: item, cacheTimestamp: timeStamp });
        }

        return callBack()
            .then(result => {
                cacheItem(key, result);
                return result;
            });
    }
}