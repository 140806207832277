export const cultures = [
    {
        code: 'en-US',
        currencyPrecision: 2,
        currencySymbol: '$',
        currencySymbolBefore: true,
        percentSymbolBefore: false,
    },
    {
        code: 'en-CA',
        currencyPrecision: 2,
        currencySymbol: '$',
        currencySymbolBefore: true,
        percentSymbolBefore: false,
    },
    {
        code: 'en-GB',
        currencyPrecision: 2,
        currencySymbol: '£',
        currencySymbolBefore: true,
        percentSymbolBefore: false,
    },
    {
        code: 'fr-CA',
        currencyPrecision: 2,
        currencySymbol: '$',
        currencySymbolBefore: false,
        percentSymbolBefore: false,
    },
    {
        code: 'fr-FR',
        currencyPrecision: 2,
        currencySymbol: '€',
        currencySymbolBefore: false,
        percentSymbolBefore: false,
    },
]

export function getCultureByCode(code) {
    code = (code || 'en-us').toLowerCase();
    return culturesByLowerCaseCode[code];
}

const culturesByLowerCaseCode =
    toMap(cultures, c => c.code.toLowerCase())

function toMap(arr, keySelector) {
    const obj = {};
    for (const value of arr) {
        const key = keySelector(value);
        obj[key] = value;
    }
    return obj;
}
