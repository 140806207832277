import angular from 'angular'; 
import _ from 'lodash';

export default 'lodash';

(function () {
    'use strict';

    angular.module('lodash', [])
        .factory('_', function () { return _; });
})();
