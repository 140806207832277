
/* injects from baggage-loader */
require('./log-call-action.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('logCallAction', {
    templateUrl: '/app/components/deal-sheet/log-call-action.component.html',
    controller: LogCallActionController,
    bindings: {
        opportunityId: '<',
        assignedUserId: '<',
        ownerUserInfo: '<',
        maxScheduleFutureDays: '<',
        users: '<',
        permissions: '<',
        onStart: '&',
        onSuccess: '&',
        onError: '&',
        onNoLongerOwns: '&'
    }
});

/* @ngInject */
function LogCallActionController($document, $translate, $uibModal, toaster, activityService, dealSheetService, messageTypes, successKey, activityPostedKey, ActivityActionEnum, activityResults) {
    var $ctrl = this;

    $ctrl.activity;
    $ctrl.logCallError;
    $ctrl.resetLogCall;
    $ctrl.ActivityActionEnum = ActivityActionEnum;
    $ctrl.setActivity = setActivity;
    $ctrl.submitLogCall = submitLogCall;
    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if ($ctrl.opportunityId && $ctrl.maxScheduleFutureDays && $ctrl.users && $ctrl.ownerUserInfo) {
            $ctrl.scheduleModalParamObject = dealSheetService.getModalParamObject(null, $ctrl.opportunityId, null, $ctrl.assignedUserId, $ctrl.maxScheduleFutureDays,
                $ctrl.users, null, $ctrl.ownerUserInfo);
        }
    }

    function submitLogCall() {
        if (!$ctrl.activity) {
            $ctrl.logCallError = !$ctrl.logCallError;
            return;
        }

        var activityResultSold = 7;
        if ($ctrl.activity.resultID == activityResultSold) {
            $ctrl.onStart();
        }

        activityService.postActivitySubmitAction($ctrl.activity)
            .then(function (returnedData) {

                if (returnedData == 'followup-cancel') {
                    onScheduleCallApptSubmit();
                    return;
                }
                else if (returnedData != 'cancel' || returnedData != 'submit') {
                    if (returnedData.followUpActions.length > 1) {
                        openFollowUpActions(returnedData)
                    }
                    else if (returnedData.followUpActions.length > 0) {
                        $ctrl.scheduleModalParamObject.modalType = returnedData.followUpActions[0].activityActionId;
                        dealSheetService.openScheduleModal($ctrl.scheduleModalParamObject, onScheduleCallApptSubmit);
                    }
                    else {

                        if (returnedData.activityId == ActivityActionEnum.Schedule_Call || returnedData.activityId == ActivityActionEnum.Schedule_Appt)
                            onScheduleCallApptSubmit();
                        else if ($ctrl.activity.resultID == activityResultSold) {
                            $ctrl.resetLogCall = true;
                            $ctrl.onSuccess();
                        }
                        else {
                            if ($ctrl.activity.resultID == activityResults.phoneCall.noLongerOwns || $ctrl.activity.resultID == activityResults.note.noLongerOwns) {
                                $ctrl.onNoLongerOwns();
                            }
                            $ctrl.resetLogCall = true;
                            $translate([successKey, activityPostedKey]).then(function (translations) {
                                toaster.pop({
                                    type: messageTypes.success,
                                    title: translations[successKey],
                                    body: translations[activityPostedKey]
                                });
                            })
                            $ctrl.activity = null;
                        }
                    }
                }
            })
            .catch(function () {
                $ctrl.onError();
                $ctrl.activity = null;
            });
    }

    function onScheduleCallApptSubmit(returnKey) {
        if (returnKey == 'onVehicleSold') {
            $ctrl.resetLogCall = true;
            $ctrl.onSuccess();
        }
        else {
            $ctrl.resetLogCall = true;
            $translate([successKey, activityPostedKey]).then(function (translations) {
                toaster.pop({
                    type: messageTypes.success,
                    title: translations[successKey],
                    body: translations[activityPostedKey]
                });
            })
            $ctrl.onSuccess();
        }
    }

    function openFollowUpActions(followUpActions) {
        var parentElem = angular.element($document[0].querySelector('#aa-app'));
        var modalInstance = $uibModal.open({
            animation: true,
            component: "activityFollowUpModal",
            appendTo: parentElem,
            resolve: {
                followUpActions: function () {
                    return followUpActions;
                }
            }
        });

        modalInstance.result.then(function (modalType) {
            if (modalType != 'followup-cancel') {
                $ctrl.scheduleModalParamObject.modalType = modalType;
                dealSheetService.openScheduleModal($ctrl.scheduleModalParamObject, onScheduleCallApptSubmit);
            }
            else {
                onScheduleCallApptSubmit();
            }
        });
    }

    function setActivity(activity) {
        $ctrl.activity = activity;
    }
}
