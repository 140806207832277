import angular from 'angular';
import { OpportunitiesModule } from './opportunities.module';

OpportunitiesModule.provider('exceptionHandler', function ExceptionHandlerProvider() {
    /* jshint validthis:true */
    this.config = {
        appErrorPrefix: undefined
    };

    this.configure = function (appErrorPrefix) {
        this.config.appErrorPrefix = appErrorPrefix;
    };

    this.$get = function () {
        return { config: this.config };
    };
});

OpportunitiesModule.config(function ($provide) {
    "ngInject";

    $provide.decorator('$exceptionHandler', function ExceptionHandlerDecorator($delegate, $injector, exceptionHandler) {
        "ngInject";
        return function (exception, cause) {
            const logger = $injector.get('logger');
            const coreApi = $injector.get('coreApi');

            if (shouldIgnoreError(exception)) {
                return;
            }

            // Error#message is not writeable, so we have to make a new error
            if (exception.message) {
                const appErrorPrefix = exceptionHandler.config.appErrorPrefix || '';
                exception = new AppError(appErrorPrefix + exception.message, exception);
            }

            $delegate(exception, cause);

            if ( exception.stack || cause) {
                const concatenatedException = `Exception: ${exception.stack}\n\nCause: ${cause}`;
                coreApi.api.Log.Log.POST(angular.toJson(concatenatedException.substring(0, 1000)));
            }            

            const errorData = { exception, cause };
            logger.genericError(exception.message, errorData);

        };

        function shouldIgnoreError(exception) {
            if (exception instanceof MustBeCaughtNoToastError) {
                return true;
            }

            //remove template errors when it happens due to page redirect (support firefox)
            // reference https://github.com/angular/angular.js/issues/14219
            if (exception.message && exception.message.startsWith('[$compile:tpload]')) {
                return true;
            }

            return false;
        }
    });
});

function AppError(message, error) {
    this.name = 'AppError';
    this.message = message;
    this.error = error;
}

/**
 * When thrown inside a promise callback, this error will not be logged by the
 * global $exceptionHandler. This is to avoid showing an unnecessary toast
 * message.
 *
 * If this error is thrown and not caught by application code, the error will
 * go unhandled.
 */
export function MustBeCaughtNoToastError(message) {
    if (!(this instanceof MustBeCaughtNoToastError))
        return new MustBeCaughtNoToastError(message);
    this.message = message;
}
