import "core-js/modules/es6.array.find";
LoginController.$inject = ["$element", "$sce", "$scope", "globalIframeService", "legacy", "legacyUrlMappings", "userService"];

/* injects from baggage-loader */
require('./login.component.html');

import angular from 'angular';
import { ChildMessagePort } from '../utilities/messaging';
import { getUrlComponents } from '../utilities/url';
angular.module('AaApp.Opp').component('login', {
  templateUrl: '/app/components/login.component.html',
  controller: LoginController
});
/* @ngInject */

function LoginController($element, $sce, $scope, globalIframeService, legacy, legacyUrlMappings, userService) {
  var $ctrl = this;
  $ctrl.src;
  $ctrl.$onInit = $onInit;

  function $onInit() {
    userService.logUserLogin();
    var loginUrl = legacy.iframeUrl + legacyUrlMappings['logIn'];
    $ctrl.src = $sce.trustAsResourceUrl(loginUrl);
    var iframe = $element.find('iframe')[0];

    var _getUrlComponents = getUrlComponents(loginUrl),
        origin = _getUrlComponents.origin;

    var port = new ChildMessagePort(iframe, origin);
    globalIframeService.setCurrentMessagePort(port, $scope);

    $ctrl.$onDestroy = function () {
      return port.close();
    };
  }
}