"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var alertMiner = "https://opportunities-api.autoalert.com";
var reportMiner = "https://reportminer.autoalert.com";
var dmsPushApi = "https://cxmintegration.autoalert.com";
exports.default = {
    "environmentVariables": {
        "environment": "production",
        "crossDomainStorageDomain": "autoalert.com",
        "logDebugEnabled": false,
        "pandoInboxEnabled": true,
        "pandoDealSheetEnabled": true,
        "pandoDealSheetProcessEnabled": true,
        "customerEngagementUpdateInterval": 600,
        "managerDashboardCustomerEngagementMaxRows": 50,
        "autoRewardsUrl": "https://www.flexstatonline.net/WebEnrollment/SuperSearch.aspx?%64={0}&%6C={1}&%70={2}&ln={3}&fn={4}",
        "fordMakeIds": [100, 326],
        "lincolnMakeIds": [101, 340],
        "whatFixUrl": "https://cdn.whatfix.com/prod/b3d0c6ab-8f0e-4f4d-988a-b4c0374428e0/initiator/initiator.nocache.js",
        "employeeDefaultDealer": {
            "dealerId": 1167,
            "dealerGroupId": 8844,
        },
        "showPerformanceReportDayOfMonth": 1,
        "defaultInMemoryCacheTimeoutSeconds": 900,
        "angularProdMode": true,
        "opportunityServiceAng8Enabled": true,
        "smartVincentUrl": "https://www.smartvincent.dealerconnection.com/smartvincent/svaccess.do?vin={0}&source=autoalert&market={1}&language={2}",
        "fordOASISUrl": "http://www.proservicetech.com/transport/?flavor=dealers&link=506&param={0}"
    },
    "googleApi": {
        "key": "AIzaSyCt623PLYGKlpAWvfSPH0xYUppwPIRR3aU"
    },
    "featureToggles": {
        // Set feature toggles to 'true' to enable for all dealers,
        // or provide an array of dealer IDs to enable for selected dealers
        "enableCustomerEngagementWidget": true,
    },
    "apiUrls": {
        "alertMiner": alertMiner,
        "reportMiner": reportMiner,
        "dmsPushApi": dmsPushApi,
        "motofuze": "https://fs-api.autoalert.com/",
        "pandoInboxUrl": "https://inbox.autoalert.com/",
        "pandoDealSheetUrl": "https://connect.autoalert.com/",
        "tableauApi": "https://pando-api.autoalert.com/pando/analytics/tableau/",
        "betaReportsUrl": "https://analyticspro.autoalert.com/views/",
    },
    "oidc": {
        "urls": [
            alertMiner,
            reportMiner,
            dmsPushApi
        ],
        "portalUrl": "https://opportunities.autoalert.com",
        "opportunitiesOrigin": "https://opportunities.autoalert.com",
        "basePath": "https://login.autoalert.com/identity",
        "clientId": "Portal",
        "responseType": "code id_token token",
        "scope": "openid profile aauser opp_api",
        "redirectUri": "https://opportunities.autoalert.com/identity.html#/auth/callback/",
        "logoutUri": "https://opportunities.autoalert.com",
        "advanceRefresh": 900000
    },
    "templateEmail": {
        "mailFrom": "notifications@aamailer.com"
    },
    "menu": [
        {
            "name": "opportunities",
            "order": 1,
            "url": "/"
        },
        {
            "name": "social",
            "order": 2,
            "url": "https://social.autoalert.com"
        },
        {
            "name": "campaigns",
            "order": 3,
            "url": "https://campaigns.autoalert.com"
        },
        {
            "name": "communication",
            "order": 4,
            "url": "https://communication.autoalert.com"
        },
        {
            "name": "reports",
            "order": 5,
            "url": "/reports/dashboard"
        }
    ],
    "legacy": {
        "isDealsheetVersionChangeEnabled": false,
        "iframeUrl": "//dealer-contentonly.autoalert.com",
        "protocol": "https:"
    },
    "tagManager": {
        "containerId": "GTM-WV7FRRR"
    },
    "whoson": {
        "baseUrl": "https://autoalert.whoson.com",
        "linkId": "whoson_chat_link"
    }
};
