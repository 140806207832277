import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
UIDropdownList.$inject = ["$scope", "$translate"];

/* injects from baggage-loader */
require('./ui-dropdownlist.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('uiDropdownList', {
  templateUrl: '/app/components/search/ui-dropdownlist.component.html',
  controller: UIDropdownList,
  bindings: {
    fieldName: "<",
    displayNameKey: '<',
    displayNameCssClass: '<',
    values: '<',
    availableGroupNames: '<',
    value: '=',
    multiSelect: '<',
    includeDefaultOption: '<',
    isDisabled: "<",
    triggerInit: "=",
    onChanged: "&"
  }
});
/* @ngInject */

function UIDropdownList($scope, $translate) {
  var $ctrl = this;
  $ctrl.multiSelect = $ctrl.multiSelect ? $ctrl.multiSelect : false;
  $ctrl.$onInit = onInit;
  $ctrl.onSelectionChanged = onSelectionChanged;
  $ctrl.settingsDropdown = {
    dropdownDisable: true,
    template: '{{option.nameText}}',
    smartButtonTextConverter: function smartButtonTextConverter(itemText, option) {
      return option.nameText;
    },
    checkBoxes: true,
    smartButtonMaxItems: 5,
    showCheckAll: true,
    showUncheckAll: true,
    scrollable: true,
    idProperty: 'value'
  };
  $scope.$watch('$ctrl.triggerInit', function (newVal, oldVal, scope) {
    if ($ctrl.triggerInit) {
      $ctrl.triggerInit = false;
      onInit();
    }
  });
  $scope.$watch('$ctrl.values', function (newVal, oldVal, scope) {
    if ($ctrl.values) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = $ctrl.values[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var val = _step.value;

          if (val.nameKey) {
            val.nameText = $translate.instant(val.nameKey);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }
  });

  function onInit() {
    if (!$ctrl.displayNameCssClass) $ctrl.displayNameCssClass = "searchDisplayNameWide";

    if (!$ctrl.multiSelect && $ctrl.values.length > 0) {
      $ctrl.value = $ctrl.values[0].value;

      for (var ssav in $ctrl.values) {
        if ($ctrl.values[ssav].selected) {
          $ctrl.value = $ctrl.values[ssav].value;
        }
      }
    }

    if ($ctrl.multiSelect) {
      $ctrl.value = [];

      for (var msav in $ctrl.values) {
        if ($ctrl.values[msav].selected) {
          $ctrl.value.push($ctrl.values[msav]);
        }
      }
    }
  }

  function onSelectionChanged() {
    $ctrl.onChanged({
      selectedValues: $ctrl.value
    });
  }
}