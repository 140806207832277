import "core-js/modules/es6.array.find";
IframeController.$inject = ["$auth", "$element", "$sce", "$state", "$scope", "globalIframeService", "tokenService", "$location", "storageService"];

/* injects from baggage-loader */
require('./iframe.component.html');

import angular from 'angular';
import { ChildMessagePort } from '../utilities/messaging';
import { getUrlComponents } from '../utilities/url';
angular.module('AaApp.Opp').component('aaIframe', {
  templateUrl: '/app/components/iframe.component.html',
  controller: IframeController,
  bindings: {
    src: '<'
  }
});
/* @ngInject */

function IframeController($auth, $element, $sce, $state, $scope, globalIframeService, tokenService, $location, storageService) {
  var $ctrl = this;
  $ctrl.$onInit = $onInit;
  $ctrl.iframeShouldScroll = 'yes';
  $ctrl.iframeWidth = '100%';
  angular.element("#ui-view").css({
    'margin-top': '0px'
  }); // if this page is not a new report-tab report hide scroll

  if ($location.url().indexOf('-report') <= -1) angular.element('#aa-app')[0].style.overflowY = "hidden";

  if (isMobileiOS()) {
    $ctrl.iframeShouldScroll = 'no';
    $ctrl.iframeWidth = getMobileWidth();
  }

  var isAuthenticated = $auth.isAuthenticated();
  var isAutoAlertUser = tokenService.isAutoAlertUser();

  if (isAuthenticated && !isAutoAlertUser) {
    $state.go('errorUnauthorized');
  } // if logged in user verifiy the user has signed EULA.if not return to Accept/decline EULA screen. only allow 'user/profile' path


  if ($auth.isAuthenticated()) {
    var userProfile = storageService.getItem('userProfile');

    if (userProfile) {
      if (userProfile.eulaAcceptedDate == null || userProfile.eulaAcceptedDate == undefined) if ($location.url().indexOf('user/profile') <= -1) $state.go('login');
    }
  }

  var originalUrl = $ctrl.src;
  $ctrl.src = $sce.trustAsResourceUrl($ctrl.src);

  function $onInit() {
    var iframe = $element.find('iframe')[0];

    var _getUrlComponents = getUrlComponents(originalUrl),
        origin = _getUrlComponents.origin;

    var port = new ChildMessagePort(iframe, origin);
    globalIframeService.setCurrentMessagePort(port, $scope);

    $ctrl.$onDestroy = function () {
      return port.close();
    };
  }

  function isMobileiOS() {
    var isTouchDevice = 'ontouchstart' in document.documentElement;
    var isOrientationValid = window.orientation != undefined;
    var isAppleDevice = navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isTouchDevice && isOrientationValid && isAppleDevice) return true;else return false;
  }

  function getMobileWidth() {
    var orientation = window.orientation;

    switch (orientation) {
      case 0:
      case 180:
        return window.screen.width + "px";

      case 90:
      case -90:
        return window.screen.height + "px";
    }
  }

  function readDeviceOrientation() {
    $ctrl.iframeWidth = getMobileWidth();
  }

  if (isMobileiOS()) window.onorientationchange = readDeviceOrientation;
}