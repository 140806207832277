<form name="scheduleForm" #scheduleForm="ngForm">
    <div class="form-horizontal row">
        <div class="col-sm-12 col-md-5 modal-body">
            <div class="form-group" [hidden]="!currentScheduleType?.IsDateVisible">
                <label class="col-sm-3 label-top-buffer" for="duedate" translate="scheduleDueDate"></label>
                <div class="col-xs-12 col-sm-6 col-md-9">
                    <date-input [defaultDate]="model.scheduleDueDate" [options]="dateOptions"
                        (onInput)="setScheduleDueDate($event.date, $event.model)"></date-input>
                    <div *ngIf="scheduleDueDateModel?.errors?.minDate || scheduleDueDateModel?.errors?.maxDate">
                        <div class="error" translate="dateOutOfRange"></div>
                    </div>
                    <div
                        *ngIf="scheduleDueDateModel?.invalid && !scheduleDueDateModel?.errors?.minDate && !scheduleDueDateModel?.errors?.maxDate">
                        <div class="error" translate="invalidDateFormat"></div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="col-sm-3 timeTicker" [hidden]="!currentScheduleType?.IsTimeVisible" for="duetime"
                    translate="scheduleDueTime"></label>
                <div class="col-sm-9 row">
                    <div class="col-sm-6">
                        <timepicker [(ngModel)]="model.time" [hourStep]="hstep" [minuteStep]="mstep" name="time"
                            [showMeridian]="ismeridian"></timepicker>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group timeTicker">
                            <input class="label-top-buffer" id="scheduleSendReminder"
                                [hidden]="!currentScheduleType?.IsReminderVisible" type="checkbox"
                                [(ngModel)]="model.sendReminder" name="sendReminder">&nbsp;
                            <label for="scheduleSendReminder" [hidden]="!currentScheduleType?.IsReminderVisible"
                                translate="scheduleSendReminder"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" [hidden]="!currentScheduleType?.IsAssignToVisible">
                <label class="col-sm-3 label-top-buffer" for="sel1" translate="scheduleAssignTo"></label>
                <div class="col-sm-9">

                    <a class="btn btn-default qa-assign-to-self-btn" [ngClass]="{'display-none': currentLoginUserProfile?.employeeUser}"
                        (click)="errorMessage=''" [(ngModel)]="model.userChoice" btnRadio="self" name="userChoice">
                        <label translate="asignToSelf"></label><span>{{currentLoginUserProfile?.userFullName}}</span>
                    </a>

                    <a class="btn btn-default qa-assign-to-owner-btn" [ngClass]="{'display-none': !owner?.isValid}"
                        (click)="errorMessage=''" [(ngModel)]="model.userChoice" btnRadio="owner" name="userChoice">
                        <label translate="asignToOwner"></label><span>{{owner?.fullName}}</span>
                    </a>

                    <a class="btn btn-default qa-assign-to-other-user-btn" (click)="errorMessage=''"
                        [(ngModel)]="model.userChoice" btnRadio="other" name="userChoice">
                        <label translate="asignToOtherUser"></label>
                    </a>

                    <div class="top-buffer">
                        <select id="sel1" class="form-control" [(ngModel)]="model.user"
                            [ngClass]="{'display-none': model?.userChoice !== 'other'}" name="userSelect">
                            <option value="-1" translate="pleaseChoose"></option>
                            <option *ngFor="let option of userList; trackBy: usersTrackBy" value="{{option.id}}">
                                {{option.fullName}}</option>
                        </select>
                    </div>
                    <div class="error ng-binding label-top-buffer" translate="{{errorMessage}}"></div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-7 modal-body">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <span class="label-top-buffer" translate="notes"></span>
                        <textarea class="form-control" rows="8" [(ngModel)]="model.notes" name="notes"></textarea>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 modal-body">
                    <div class="bottom-column-schedule">
                        <div class="customer-connect-log-button">
                            <div class="form-group">
                                <button class="btn btn-primary ng-scope qa-customer-connect-schedule-submit-btn"
                                    (click)="onSubmit()" translate="submit" [disabled]="scheduleForm.invalid">Submit</button>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary ng-scope qa-customer-connect-schedule-cancel-btn"
                                    (click)="onCancel()" translate="cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>