/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DealerAssetDto } from '../models/dealer-asset-dto';
@Injectable({
  providedIn: 'root',
})
class DealerAssetClientService extends __BaseService {
  static readonly GetDealerAssetsGETPath = '/api/DealerAsset/GetDealerAssets';
  static readonly GetByDealerByDealeridGETPath = '/api/DealerAsset/GetByDealer/{dealerId}';
  static readonly ByIdGETPath = '/api/DealerAsset/{id}';
  static readonly ByIdDELETEPath = '/api/DealerAsset/{id}';
  static readonly GetManyGETPath = '/api/DealerAsset/GetMany';
  static readonly PUTPath = '/api/DealerAsset';
  static readonly POSTPath = '/api/DealerAsset';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetDealerAssetsGETResponse(): __Observable<__StrictHttpResponse<Array<DealerAssetDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerAsset/GetDealerAssets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerAssetDto>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDealerAssetsGET(): __Observable<Array<DealerAssetDto>> {
    return this.GetDealerAssetsGETResponse().pipe(
      __map(_r => _r.body as Array<DealerAssetDto>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetByDealerByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<DealerAssetDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerAsset/GetByDealer/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerAssetDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetByDealerByDealeridGET(dealerId: number): __Observable<Array<DealerAssetDto>> {
    return this.GetByDealerByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<DealerAssetDto>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<DealerAssetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerAsset/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DealerAssetDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<DealerAssetDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as DealerAssetDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/DealerAsset/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<DealerAssetDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/DealerAsset/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DealerAssetDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<DealerAssetDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<DealerAssetDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: DealerAssetDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/DealerAsset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: DealerAssetDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: DealerAssetDto): __Observable<__StrictHttpResponse<DealerAssetDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/DealerAsset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DealerAssetDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: DealerAssetDto): __Observable<DealerAssetDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as DealerAssetDto)
    );
  }
}

module DealerAssetClientService {
}

export { DealerAssetClientService }
