import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { UserService } from './../../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../core/toaster-logger.service';
import { DealerMakeLeaseMileageDto, DealerSettingDto, DealerTwilioPhoneNumberDto, DealerSettingResponseDto } from '../../generated/models';

@Component({
    selector: 'admin-settings-inventory',
    templateUrl: './admin-settings-inventory.component-ng.html'
})

export class AdminSettingsInventoryComponent {
    bsModalRef: BsModalRef;
    @Input() dsa: DealerSettingResponseDto = {};
    @Output() onSaveChanges = new EventEmitter();

    public rvsSelectedItem: any;

    constructor(
        public modalService: BsModalService,
        public tService: TranslateService,
        public userService: UserService,
        public logger: ToasterLoggerService,
    ) {
    }

    public rvsMove(direction) {
        if (this.rvsSelectedItem && this.rvsSelectedItem.length == 1) {
            var index = this.dsa.dealerInventoryMatchRankingField.indexOf(this.rvsSelectedItem[0]);
            var newIndex = 0;

            if (direction == '+')
                newIndex = index - 1;
            else
                newIndex = index + 1;

            if (newIndex < 0 || newIndex > this.dsa.dealerInventoryMatchRankingField.length - 1)
                return;

            var temp = this.dsa.dealerInventoryMatchRankingField[newIndex];
            this.dsa.dealerInventoryMatchRankingField[newIndex] = this.dsa.dealerInventoryMatchRankingField[index];
            this.dsa.dealerInventoryMatchRankingField[index] = temp;

            var order = 1;

            for (var item of this.dsa.dealerInventoryMatchRankingField)
                item.order = order++;
        }

        this.dsa.dealerInventoryMatchRankingField;
    }
}