import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
minDateDirective.$inject = ["storageService", "cultures"];
import angular from 'angular';
angular.module('AaApp.Opp').directive('minDate', minDateDirective);
/* @ngInject */

function minDateDirective(storageService, cultures) {
  return {
    require: 'ngModel',
    link: function link(scope, elm, attrs, ctrl) {
      var validateDateRange = function validateDateRange(input) {
        var minDate = attrs.minDate.replace('"', '').replace('"', '');
        var isValidInput = isValidDate(input);
        var isValidMinDate = isValidDate(minDate);

        if (!isValidInput || !isValidMinDate) {
          ctrl.$setValidity('minDate', false);
        } else {
          var inputDate;

          if (Object.prototype.toString.call(input) === '[object Date]') {
            inputDate = input;
          } else {
            inputDate = createDateFromString(input);
          }

          minDate = new Date(minDate);
          inputDate.setHours(0, 0, 0, 0);
          minDate.setHours(0, 0, 0, 0);

          if (inputDate < minDate) {
            ctrl.$setValidity('minDate', false);
          } else {
            ctrl.$setValidity('minDate', true);
          }
        }

        return input;
      };

      ctrl.$parsers.unshift(validateDateRange);
      ctrl.$formatters.push(validateDateRange);
      attrs.$observe('minDate', function () {
        validateDateRange(ctrl.$viewValue);
      });
    }
  };

  function isValidDate(dateStr) {
    if (dateStr == undefined) return false;

    if (Object.prototype.toString.call(new Date(dateStr)) !== '[object Date]') {
      return false;
    }

    return true;
  }

  function createDateFromString(dateString) {
    var culture = storageService.getItem('cultureName');
    var dateParts;

    switch (culture) {
      case cultures.enCA:
        dateParts = dateString.split('/');
        return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

      case cultures.frCA:
        dateParts = dateString.split('-');
        return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      case cultures.enUS:
      default:
        dateParts = dateString.split('/');
        return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
    }
  }
}