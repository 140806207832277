import angular from 'angular';
import { roundWithPrecision } from '../utilities/math';
import { getCultureByCode } from '../utilities/culture';
import { makeNumberLinkFunction, wrapElementInInputGroup } from './number-input.directive';

angular.module('AaApp.Opp').directive('currencyInput', function (storageService) {
    "ngInject";

    const cultureName = storageService.getItem('cultureName') || 'en-US';
    const culture = getCultureByCode(cultureName);

    const currencyPrecision = culture.currencyPrecision;
    const currencySymbol = culture.currencySymbol;

    const NumberLinkFunction = makeNumberLinkFunction({ precision: currencyPrecision })

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            precision: '<',
            addonText: '<',
        },
        link: function CurrencyInputLinkFunction($scope, $element, $attributes, $ngModelCtrl) {
            if (!$scope.addonText) {
                $scope.addonText = currencySymbol;
            }

            NumberLinkFunction($scope, $element, $attributes, $ngModelCtrl);
        },
    }
})
