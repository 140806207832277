import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToasterLoggerService } from '../../../../core/toaster-logger.service';

@Component({
    selector: 'mileage-edit-modal',
    templateUrl: './mileage-edit-modal.component-ng.html',
    styleUrls: ['./mileage-edit-modal.component.scss']
})

export class MileageEditModalComponent implements OnInit, AfterViewInit {
    public mileage: number;
    public dateOptions: any;
    public dateModel: any;
    public model: any;
    @ViewChild('mileageForm', { static: false }) mileageForm: NgForm;
    @Output() onSaveChanges = new EventEmitter();
    private minDate: Date;
    private status: any;

    constructor(
        public _bsModalRef: BsModalRef,
        public tService: TranslateService,
        private logger: ToasterLoggerService) {
    }

    public ngOnInit(): void {
        this._bsModalRef.hide();

        this.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 0,
            showWeeks: false
        };

        let date = new Date();
        this.status = { opened: false };
        this.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 0,
            showWeeks: false
        };
        this.minDate = date;

        let dueDate: Date = new Date();
        dueDate.setDate(date.getDate() + (5 / (24 * 60)) );// add 5 min to current time

        this.dateModel = {
            valid: true
        }

        this.model = {
            date: dueDate,
            time: new Date(1, 1, 2000, dueDate.getHours(), dueDate.getMinutes()),
            mileage: ''
        }
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public saveMileage(mileageForm: NgForm): void {
        if (mileageForm.valid) {
            this.onSaveChanges.emit({ mileage: this.mileage, readOnDate: this.model.date });
            this._bsModalRef.hide();
        }
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
    }

    public setDate(date, model) {
        this.model.date = date;
        this.dateModel = model;

    }
}

