"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CarfaxComponent = /** @class */ (function () {
    function CarfaxComponent() {
        this.showCarFaxLink = false;
    }
    CarfaxComponent.prototype.ngOnInit = function () {
    };
    CarfaxComponent.prototype.ngOnChanges = function () {
        if (!this.permissions || !this.dealerSettings || !this.vin) {
            return;
        }
        this.showCarFaxLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableCarFax;
    };
    return CarfaxComponent;
}());
exports.CarfaxComponent = CarfaxComponent;
