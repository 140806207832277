"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var constants_1 = require("../../../constants");
var TypeOfCallComponent = /** @class */ (function () {
    function TypeOfCallComponent() {
        this.onBlur = new core_1.EventEmitter();
        this.onTypeSelect = new core_1.EventEmitter();
        this.activitySubTypes = constants_1.ActivitySubTypes;
    }
    TypeOfCallComponent.prototype.ngOnInit = function () {
        this.selectedCallType = this.activitySubTypes.outBoundCall.toString();
        this.onTypeSelect.emit(this.selectedCallType); // send default choice to parent       
    };
    TypeOfCallComponent.prototype.onSelectionChange = function (data) {
        this.onTypeSelect.emit(data);
    };
    return TypeOfCallComponent;
}());
exports.TypeOfCallComponent = TypeOfCallComponent;
