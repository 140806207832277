localeDateFilter.$inject = ["$filter", "storageService"];
import angular from 'angular';
angular.module('AaApp.Opp').filter('localeDate', localeDateFilter);
localeDateFilter.$inject = ['$filter', 'storageService'];
/* @ngInject */

function localeDateFilter($filter, storageService) {
  return function (date) {
    var savedCultureName = storageService.getItem('cultureName');
    var cultureName = savedCultureName ? savedCultureName : 'en-us';

    switch (cultureName.toLowerCase()) {
      case 'en-gb':
        return $filter('date')(date, 'dd/MM/yyyy');

      case 'en-ca':
        return $filter('date')(date, 'dd/MM/yyyy');

      case 'fr-fr':
        return $filter('date')(date, 'dd/MM/yyyy');

      case 'fr-ca':
        return $filter('date')(date, 'yyyy-MM-dd');

      case 'en-us':
      default:
        return $filter('date')(date, 'MM/dd/yyyy');
    }
  };
}