"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../generated/services/pando-passthrough-client.service"));
var i2 = __importStar(require("../generated/services/ext-customer-activity-client.service"));
var AutoAssistantService = /** @class */ (function () {
    function AutoAssistantService(pandoPassthroughClientService, extCustomerActivityClientService) {
        this.pandoPassthroughClientService = pandoPassthroughClientService;
        this.extCustomerActivityClientService = extCustomerActivityClientService;
    }
    AutoAssistantService.prototype.endCommunication = function (entityId) {
        return this.extCustomerActivityClientService.AddEndConversationEventRecordByEntityidGET(entityId).toPromise();
    };
    AutoAssistantService.prototype.isAutoAssistantConversationOpen = function (entityId) {
        return this.extCustomerActivityClientService.IsAutoAssistantConversationOpenByEntityidGET(entityId).toPromise();
    };
    AutoAssistantService.prototype.getAutoAssistFullView = function (dealerId, entityId) {
        return this.pandoPassthroughClientService.AutoAssistFullViewByDealeridByEntityidGET({ dealerId: dealerId, entityId: entityId }).toPromise();
    };
    AutoAssistantService.prototype.getConversicaConversationStatuses = function (dealerId, entityId) {
        return this.pandoPassthroughClientService.GetConversicaConversationStatusesByDealeridByEntityidGET({ dealerId: dealerId, entityId: entityId }).toPromise();
    };
    AutoAssistantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AutoAssistantService_Factory() { return new AutoAssistantService(i0.ɵɵinject(i1.PandoPassthroughClientService), i0.ɵɵinject(i2.ExtCustomerActivityClientService)); }, token: AutoAssistantService, providedIn: "root" });
    return AutoAssistantService;
}());
exports.AutoAssistantService = AutoAssistantService;
