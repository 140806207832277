"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AutoCheckComponent = /** @class */ (function () {
    function AutoCheckComponent() {
        this.showAutoCheckLink = false;
    }
    AutoCheckComponent.prototype.ngOnInit = function () {
    };
    AutoCheckComponent.prototype.ngOnChanges = function () {
        if (!this.permissions || !this.dealerSettings || !this.vin) {
            return;
        }
        this.showAutoCheckLink = this.permissions.vehicleHistoryReports && this.dealerSettings.enableAutoCheck;
    };
    return AutoCheckComponent;
}());
exports.AutoCheckComponent = AutoCheckComponent;
