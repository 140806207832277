import { Modules } from './modules';
import { MenuItem } from '../interfaces/menu-item.interface';

export const SubMenu: MenuItem[] = [
    {
        'id': 'alertDesk',
        'moduleIds': [1],
        'displayName': 'alertDesk',
        'authorize': true,
        'imgUrl': '/Content/images/icons/16/Task Done 16 n p.png',
        'baseUrl': 'alert-desk',
        'url': 'alert-desk',
        'subMenuItems': [
            {
                'id': 'dashboard.opportunities',
                'moduleIds': [Modules.dashboard],
                'displayName': 'dashboard',
                'authorize': true,
                'url': 'alert-desk/dashboard'
            },
            {
                'id': 'euroliteopportunities',
                'moduleIds': [84],
                'displayName': 'euroliteopportunities',
                'authorize': true,
                'url': 'alert-desk/europe/opportunities'
            },
            {
                'id': 'opportunities',
                'moduleIds': [3],
                'displayName': 'opportunities',
                'authorize': true,
                'url': 'alert-desk/opportunities'
            },
            {
                'id': 'activities',
                'moduleIds': [5],
                'displayName': 'activities',
                'authorize': true,
                'url': 'alert-desk/activities'
            },
            {
                'id': 'search.form',
                'moduleIds': [4],
                'displayName': 'search',
                'authorize': true,
                'url': 'alert-desk/search'
            },
            {
                'id': 'conquests',
                'moduleIds': [29],
                'displayName': 'conquests',
                'authorize': true,
                'url': 'alert-desk/conquests'
            },
            {
                'id': 'preOwnedManager',
                'moduleIds': [32],
                'displayName': 'preOwnedManager',
                'authorize': true,
                'url': 'alert-desk/pre-owned-manager'
            },
            {
                'id': 'serviceManager',
                'moduleIds': [33],
                'displayName': 'serviceManager',
                'authorize': true,
                'url': 'alert-desk/service-manager'
            },
            {
                'id': 'serviceDriveOffers',
                'moduleIds': [33],
                'displayName': 'serviceDriveOffers',
                'authorize': true,
                'url': 'alert-desk/service-drive/offers'
            },
            {
                'id': 'financeInsuranceManager',
                'moduleIds': [41],
                'displayName': 'financeInsuranceManager',
                'authorize': true,
                'url': 'alert-desk/finance-insurance-manager'
            },
            {
                'id': 'wishList',
                'moduleIds': [95],
                'displayName': 'inventoryWishlist',
                'authorize': true,
                'url': 'alert-desk/wish-list'
            },
            {
                'id': 'potentialNLO',
                'moduleIds': [95],
                'displayName': 'nlo_MenuLabel',
                'authorize': true,
                'url': 'alert-desk/ai-manager'
            }
        ]
    },
    {
        'id': 'inventoryDashboard',
        'moduleIds': [51],
        'displayName': 'inventory',
        'authorize': true,
        'imgUrl': '/Content/images/icons/16/Task Done 16 n p.png',
        'baseUrl': 'inventory-dashboard',
        'url': 'inventory-dashboard'
    },
    {
        'id': 'dataEntry',
        'moduleIds': [14],
        'displayName': 'dataEntry',
        'authorize': true,
        'imgUrl': '/Content/images/icons/16/Add Database Record 16 n p.png',
        'baseUrl': 'data-entry',
        'url': '#',
        'subMenuItems': [
            {
                'id': 'dataEntry.prices',
                'moduleIds': [15],
                'displayName': 'prices',
                'authorize': true,
            },
            {
                'id': 'dataEntry.rates',
                'moduleIds': [31],
                'displayName': 'rates',
                'authorize': true,
            },
            {
                'id': 'dataEntry.rebates',
                'moduleIds': [16],
                'displayName': 'rebates',
                'authorize': true,
            },
            {
                'id': 'dataEntry.tradeIns',
                'moduleIds': [17],
                'displayName': 'tradeIns',
                'authorize': true,
            },
            {
                'id': 'dataEntry.alerts',
                'moduleIds': [93],
                'displayName': 'alertTolerances',
                'authorize': true,
            },
            {
                'id': 'dataEntry.replacements',
                'moduleIds': [18],
                'displayName': 'replacements',
                'authorize': true
            }
        ]
    },
    {
        'id': 'admin',
        'moduleIds': [19],
        'displayName': 'admin',
        'authorize': true,
        'imgUrl': '/Content/images/icons/16/Options 2 16 n p.png',
        'baseUrl': 'admin',
        'url': '#',
        'subMenuItems': [
            {
                'id': 'settings',
                'moduleIds': [20],
                'displayName': 'settings',
                'authorize': true,
                'url': 'admin/settings'
            },
            {
                'id': 'newSettings',
                'moduleIds': [20],
                'displayName': 'settings',
                'authorize': true,
                'url': 'admin/dealer-settings'
            }, 
            {
                'id': 'users',
                'moduleIds': [21],
                'displayName': 'users',
                'authorize': true,
                'url': 'admin/users'
            },
            {
                'id': 'roles',
                'moduleIds': [22],
                'displayName': 'roles',
                'authorize': true,
                'url': 'admin/roles'
            },
            {
                'id': 'notifications',
                'moduleIds': [23],
                'displayName': 'notifications',
                'authorize': true,
                'url': 'admin/notifications'
            },
            {
                'id': 'adminReportSubscriptions',
                'moduleIds': [23],
                'displayName': 'reportSubscriptions',
                'authorize': true,
            },
            {
                'id': 'pandoIntegration',
                'moduleIds': [73],
                'displayName': 'pandoIntegration',
                'authorize': true
            },
            {
                'id': 'doNotCallEmailList',
                'moduleIds': [70, 30],
                'displayName': 'doNotEmailCallPageTitle',
                'authorize': true,
                'url': 'admin/do-not-call-email-list'
            },
            {
                'id': 'outreachTemplates',
                'moduleIds': [44],
                'displayName': 'outreachTemplates',
                'authorize': true,
                'url': 'admin/outreach-templates'
            },
            {
                'id': 'creditConvertEstimator',
                'moduleIds': [46],
                'displayName': 'creditConvertEstimator',
                'authorize': true,
                'url': 'admin/creditconvert'
            },
            {
                'id': 'accessRights',
                'moduleIds': [68],
                'displayName': 'accessRights',
                'authorize': true,
                'url': 'admin/access-rights',
                '-roleId': '1'
            },
            {
                'id': 'employees',
                'moduleIds': [42],
                'displayName': 'employees',
                'authorize': true,
                'url': 'admin/employees'
            }
        ]
    },
    {
        'id': 'help',
        'moduleIds': [24],
        'displayName': 'help',
        'authorize': false,
        'imgUrl': '/Content/images/icons/16/Help Blue 16 n p.png',
        'baseUrl': 'about',
        'url': 'about/contact-us',
        'subMenuItems': [
            {
                'id': 'contactUs',
                'moduleIds': [26],
                'displayName': 'contactUs',
                'authorize': false,
                'url': 'about/contact-us'
            },
            {
                'id': 'about',
                'moduleIds': [25],
                'displayName': 'about',
                'authorize': false,
                'url': 'about'
            }
        ]
    }
];
