import './polyfills';

import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';
setAngularJSGlobal(angular);

import 'zone.js';
import 'reflect-metadata';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';

import { UIRouter, UrlService } from '@uirouter/core';
import { NgZone, enableProdMode } from '@angular/core';
import { Settings } from './constants';

// Using AngularJS config block, call `deferIntercept()`.
// This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
angular.module('AaApp.Opp').config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);


if (Settings.environmentVariables.angularProdMode) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(platformRef => {

        // Intialize the Angular Module
        // get() the UIRouter instance from DI to initialize the router
        const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

        // Instruct UIRouter to listen to URL changes
        function startUIRouter() {
            urlService.listen();
            urlService.sync();
        }


        platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
    });
