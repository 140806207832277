<div class="form-horizontal row">
    <div class="col-sm-12 col-md-4 modal-body">
        <div *ngIf="currentNoteType?.isCallBoundTypeVisible" class="form-group">
            <div class="row">
                <type-of-call (onTypeSelect)="onTypeOfCallSelect($event)"></type-of-call>
            </div>
        </div>
        <div class="form-group log-email" *ngIf="!isLogEmail">
            <div class="row">
                <div class="col-sm-12">
                    <div *ngIf="!isLogEmail">
                        <span [translate]="currentNoteType?.resultLabelKey"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 ">
                    <div *ngFor="let option of activityResultsListColumn1">
                        <input (click)="errorMessage=''" class="label-top-buffer"
                            id="note{{option.activityResultID}}" type="radio"
                            [value]="option.activityResultID" [(ngModel)]="model.resultID" name="activityResults"/>&nbsp;
                        <label class="aa-padding-right" for="note{{option.activityResultID}}"
                            [translate]="option.translateKey"></label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div *ngFor="let option of activityResultsListColumn2">
                        <input (click)="errorMessage=''" class="label-top-buffer"
                            id="note{{option.activityResultID}}" type="radio"
                            [value]="option.activityResultID" [(ngModel)]="model.resultID" name="activityResults"/>&nbsp;
                        <label class="aa-padding-right" for="note{{option.activityResultID}}"
                            [translate]="option.translateKey"></label>
                    </div>
                </div> 
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <span class="error ng-binding label-top-buffer pull-left" [translate]="errorMessage"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-8 modal-body">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group">
                    <span class="label-top-buffer" translate="notes"></span>
                    <textarea class="form-control" rows="8" [(ngModel)]="model.notes"></textarea>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 modal-body">
                <div class="bottom-column">
                    <div class="form-group">
                        <input type="checkbox" class="label-top-buffer" id="scheduleAppointmentActivity"
                            [(ngModel)]="model.setScheduleAppt" />&nbsp;
                        <label for="scheduleAppointmentActivity" translate="scheduleCallorAppointmentActivity"></label>
                    </div>
                    <div class="customer-connect-log-button">
                        <div class="form-group">
                            <a class="btn btn-primary ng-scope qa-customer-connect-log-submit-btn"
                                (click)="onSubmit()" translate="submit">Submit</a>
                        </div>
                        <div class="form-group">
                            <a class="btn btn-primary ng-scope qa-customer-connect-log-cancel-btn"
                                (click)="onCancel()" translate="cancel">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>