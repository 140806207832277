import angular from 'angular';

export const legacyUrlMappings = {
    // these are used throughout the app
    logIn: '/SSO/SSOLogin?returnUrl=/Security/User/LandingPage',
    dealSheets: '/AlertDesk/DealSheet/Show?',
    opportunities: '/AlertDesk/Opportunity',
    preOwnedManagerFindABuyer: '/AlertDesk/PreOwnedManager/Index',
    quickSearch: '/AlertDesk/QuickSearch/Show?Query=',
    register: '/register/',
    searchPresets: '/AlertDesk/Search/Index?ConditionID=',
    searchTags: '/AlertDesk/Search/Index?TagID={tagid}&d={dealerid}',
    securityNewAccountActivate: '/security/newAccount/activate',
    activities: '/AlertDesk/Activities/Index',
    legacyUnsubscribe: '/Security/User/Unsubscribe',
    legacyNotification: '/Security/User/Notification',

    // These can be moved
    alertDesk: '/Home/ContactUs',
    dashboard: '/Home/ContactUs',
    opportunitiesAlert: '/AlertDesk/Opportunity/alert?d={dealerId}',
    opportunitiesAppointment: '/AlertDesk/Opportunity/appointment?d={dealerId}',
    opportunitiesService: '/AlertDesk/Opportunity/service?d={dealerId}',
    opportunitiesFlexAlert: '/AlertDesk/Opportunity/flex?d={dealerId}',
    opportunitiesContractEnd: '/AlertDesk/Opportunity/contract-end?d={dealerId}',
    opportunitiesInMarket: '/AlertDesk/Opportunity/inmarket?d={dealerId}',
    opportunitiesInMarketEngaged: '/AlertDesk/Opportunity/inmarketengaged?d={dealerId}',
    opportunitiesMileage: '/AlertDesk/Opportunity/mileage?d={dealerId}',
    opportunitiesWarranty: '/AlertDesk/Opportunity/warranty?d={dealerId}',
    opportunitiesLease: '/AlertDesk/Opportunity?saletypes=lease&opportunityType=allops&d={dealerId}',
    opportunitiesRetail: '/AlertDesk/Opportunity?saletypes=retail&saletypes=balloon&opportunityType=allops&d={dealerId}',
    activitiesAgenda: '/AlertDesk/Activities/Index?d={dealerId}#agenda',
    search: '/AlertDesk/Search/Index',
    conquests: '/AlertDesk/Conquest',
    preOwnedManager: '/AlertDesk/PreOwnedManager',
    serviceManager: '/AlertDesk/ServiceManager',
    financeInsuranceManager: '/WorkSheets/WorkSheet/#Standard',
    inventoryDashboard: '/Preowned/PreownedHomePage/',
    summaryReport: '/Reporting/Summary',
    soldUnitsReport: '/Reporting/SoldUnits',
    soldUnitsDetailReport: '/Reporting/SoldDetailed',
    expressRoiReport: '/Reporting/SDOROIReport',
    tradeInsReport: '/Reporting/TradeIns',
    oneToOneReport: '/Reporting/DirectAlert',
    userActivityReport: '/Reporting/UserActivity',
    activityReport: '/Reporting/Activities',
    emailTemplateReport: '/Reporting/UserEmailTemplateActivity',
    opportunitiesReport: '/Reporting/Opportunities',
    inventoryReport: '/Reporting/Inventory',
    certificationReport: '/Reporting/Certification',
    webinarAttendanceReport: '/Reporting/WebinarAttendance',
    profitCenterUtilizationReport: '/ProfitCenter/Pcu',
    creditConvertReport: '/Reporting/CreditConvertProcessed',
    vinScanUtilizationReport: '/Reporting/VinScanUtilization',
    tradeIns: '/DataEntry/TradeIns/Edit',
    userProfile: '/Security/User/Profile',
    settings: '/Settings/DealerSettings/Config',
    users: '/Security/UserManagement/ListUsers',
    // roles: '/Security/Role/ManageRoles',
    notifications: '/Settings/NotificationSettings/Config',
    pandoIntegration: '/Settings/PandoSettings/Config',    
    creditConvertEstimator: '/CreditConvert/Estimate',
    accessRights: '/Settings/DealerAccessRights/Config',
    employees: '/Security/EmployeeManagement/ListEmployees',
    about: '/Home/About',
    help: '/Home/ContactUs',
    contactUs: '/Home/ContactUs',
};

angular.module('AaApp.Opp').constant('legacyUrlMappings', legacyUrlMappings)
