
/* injects from baggage-loader */
require('./sub-menu.component.html');

import angular from 'angular';
import { resizeHeader } from '../../scripts/init';

angular.module('AaApp.Opp').component('subMenu', {
    templateUrl: '/app/components/header/sub-menu.component.html',
    controller: SubMenuController,
    bindings: {
    }
});

/* @ngInject */
function SubMenuController($rootScope, $state, $translatePartialLoader, $transitions, subMenuUnauthorized, globalIframeService, menuService, subMenuLocalizationPath, dealerService) {
    var $ctrl = this;

    $ctrl.currentState;
    $ctrl.isActiveMenuItem = isActiveMenuItem;
    $ctrl.$onDestroy = $onDestroy;
    $ctrl.$onInit = $onInit;
    $ctrl.removeClick = removeClick;
    $ctrl.navToMenuItem = navToMenuItem;
    $ctrl.showHideSubnav;

    $translatePartialLoader.addPart(subMenuLocalizationPath);

    function $onDestroy() {
        $ctrl.dispose();
    }

    function $onInit() {
        $ctrl.siteMap = subMenuUnauthorized;
        $ctrl.dispose = $rootScope.$on('RUN_USER_INIT.SITE_MAP_READY', function siteMapReady(userProfile) {
            var dealerIds = dealerService.getSelectedDealerIds();
            menuService.getSiteMap(dealerIds).then(setSiteMap);
        });

        $transitions.onSuccess({}, function (transition) {
            $ctrl.currentState = transition.to();
        });
    }

    function setSiteMap(siteMap) {
        $ctrl.siteMap = siteMap;
        resizeHeader($state.$current, globalIframeService);
        $rootScope.$broadcast('RUN_USER_INIT.REPOSITION_UI_VIEW');
    }

    function removeClick(e) {
        var removeCurrentTarget = document.getElementsByClassName("clicked");
        var wrappedResult = angular.element(removeCurrentTarget);
        wrappedResult.removeClass("clicked");
    }

    function navToMenuItem(location, event) {
        if (location == '#') {
            return;
        } else {
            var currentTarget = angular.element(event.target).parent().parent();

            if (currentTarget.hasClass('dropdown-menu')) {
                currentTarget.addClass("clicked");
            } else {
                var dropDownElement = document.getElementsByClassName("dropdown-menu");
                var wrappeddropDownElement = angular.element(dropDownElement);
                wrappeddropDownElement.addClass("clicked");
            }
        }
    }

    function isActiveMenuItem(menuItem) {
        if ($ctrl.currentState) {
            if (menuItem.baseUrl == 'reports') {
                return $ctrl.currentState.url.startsWith('/' + menuItem.baseUrl + '/');
            }
            else {
                return $state.includes(menuItem.id) // matches states like "dataEntry.prices"
                    || $ctrl.currentState.url.startsWith('/' + menuItem.baseUrl); // matches states like "rates" that don't have a parent state
            }
        }
    }
}
