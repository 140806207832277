
/* injects from baggage-loader */
require('./credit-pre-qualified-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('creditPreQualifiedModal', {
    templateUrl: '/app/components/deal-sheet/modals/credit-pre-qualified-modal.component.html',
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
    }
});
