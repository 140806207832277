
/* injects from baggage-loader */
require('./data-entry-rebates.component.html');

import angular from 'angular';
import $ from 'jquery';
import './data-entry-rebates.component.scss';
import BusyIndicator from '../../utilities/BusyIndicator';

angular.module('AaApp.Opp').component('dataEntryRebates', {
    templateUrl: '/app/components/data-entry/data-entry-rebates.component.html',
    controller: DataEntryRebatesController,
    bindings: {
        kendo: '<',
    }
});

function DataEntryRebatesController($translate, $q, $filter, vehicleService, dealerService, dataEntryService, 
    storageService, $uibModal, $document, saleTypes, cultures, logger, $logFactory) {
    "ngInject";
    const $ctrl = this;
    const log = $logFactory.get();

    const cultureName = storageService.getItem('cultureName') || 'en-US';

    $ctrl.$onInit = init;
    $ctrl.onDealerSelect = onDealerSelect;
    $ctrl.trimSelections;
    $ctrl.setTrimSelections = setTrimSelections;
    $ctrl.showQuickAction = false;
    $ctrl.onShowQuickAction = onShowQuickAction;
    $ctrl.onHideQuickAction = onHideQuickAction;
    $ctrl.onPerform = onPerform;
    $ctrl.onClear = onClear;
    $ctrl.onSave  = onSave;
    $ctrl.onCancel  = onCancel;
    $ctrl.kendoGrid = null;
    $ctrl.busyIndicator = new BusyIndicator();
    $ctrl.quickActionForOptions = [];
    $ctrl.rebateFor = null;

    $ctrl.min = 100;
    $ctrl.max = 50000;

    $ctrl.setLeaseColumn = setLeaseColumn;
    $ctrl.setRetailColumn = setRetailColumn;
    $ctrl.setBalloonColumn = setBalloonColumn;


    function init() {
        $ctrl.dealers = dealerService.getSelectedDealers();
        $ctrl.selectedDealerId = $ctrl.dealers[0].id;

        $ctrl.dateOptions = {
            minDate: new Date(),
            startingDay: 0,
            showWeeks: false
        }

        $ctrl.quickActionForOptions = [
               {id: '0', name: 'allSaleTypes'},
               {id: '1', name: 'lease'},
               {id: '2', name: 'retail'},
               {id: '3', name: 'balloon'}
        ];

        $ctrl.rebateFor = $ctrl.quickActionForOptions[0].id;

        getTranslations();        

        onDealerSelect();
    }

    function getTranslations(){
        $q.all({
            'saveConfirmMessage': $translate('saveConfirmMessage'),
            'saveWarningMessage': $translate('saveWarningMessage'),
            'validationErrorMessage': $translate('validationErrorMessage'),            
            'lease': $translate('lease'),
            'retail': $translate('retail'),
            'balloon': $translate('balloon'),
            'expireOn': $translate('expireOn'),
            'trim': $translate('trim'),
            'currencySymbol': $translate('currencySymbol')
        }).then(function (stuff) {
            $ctrl.saveConfirmMessage = stuff.saveConfirmMessage;
            $ctrl.saveWarningMessage = stuff.saveWarningMessage;
            $ctrl.validationErrorMessage = stuff.validationErrorMessage;
            $ctrl.lease = stuff.lease;
            $ctrl.retail = stuff.retail;
            $ctrl.balloon = stuff.balloon;
            $ctrl.expireOn = stuff.expireOn;
            $ctrl.trim = stuff.trim;
            $ctrl.currencySymbol = stuff.currencySymbol;
            initGridOptions();
        });
    }

    function busy(translationKey, promise) {
        $ctrl.busyMessage = null;
        $translate(translationKey)
            .then(translation => {
                $ctrl.busyMessage = translation;
            }, err => {
                $ctrl.busyMessage = translationKey;
            });

        const done = $ctrl.busyIndicator.start();
        return promise.finally(done);
    }

    function onDealerSelect(previousValue) {
        if (isDataDirty()) {

            var modal = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "pleaseConfirm";
                    },
                    message: function () {
                        return $ctrl.saveWarningMessage;
                    },
                    submitButtonVisible: function () {
                        return true;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    submitButtonText: function () {
                        return "continue";
                    }
                }
            });

            modal.result.then(function () {
                return busy(
                    "Loading Rebates", 
                    $q.all([
                        vehicleService.getMakes($ctrl.selectedDealerId),
                        vehicleService.getYearIDs($ctrl.selectedDealerId, false),
                    ]).then((data) => {
                        $ctrl.selectedMakeId =  data[0][0].makeID;
                        $ctrl.selectedYearId = data[1][0];
                        $ctrl.defaults = {
                            vehicleTypes: 0,
                            makeID: data[0][0].makeID,
                            makeName: data[0][0].makeName,
                            yearID: data[1][0],
                            modelID: null,
                            modelName: null,
                            trimID: null,
                            trimName: null
                        };
                        $ctrl.trimSelections = $ctrl.defaults;
                        return loadRebates();
                    })
                );
            })
            .catch(function () {
                $ctrl.selectedDealerId = previousValue;
                return false;
            });

        }
        else
            return busy(
                "Loading Rebates", 
                $q.all([
                    vehicleService.getMakes($ctrl.selectedDealerId),
                    vehicleService.getYearIDs($ctrl.selectedDealerId, false),
                ]).then((data) => {
                    $ctrl.selectedMakeId =  data[0][0].makeID;
                    $ctrl.selectedYearId = data[1][0];
                    $ctrl.defaults = {
                        vehicleTypes: 0,
                        makeID: data[0][0].makeID,
                        makeName: data[0][0].makeName,
                        yearID: data[1][0],
                        modelID: null,
                        modelName: null,
                        trimID: null,
                        trimName: null
                    };
                    $ctrl.trimSelections = $ctrl.defaults;
                    return loadRebates();
                })
            );
    }

    function onShowQuickAction() {
        $ctrl.showQuickAction = true;
    }

    function onHideQuickAction() {
        $ctrl.showQuickAction = false;
    }

    function clearQuickAction() {
        $ctrl.rebateFor = $ctrl.quickActionForOptions[0].id;
        $ctrl.expireSetTo = null;
        $ctrl.rebateSetAmount = null;       
    }

    function setLeaseColumn(row, clearFlag)
    {
        if (clearFlag) {
            if (row.leaseRebate) {
                row.leaseRebate = null;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields.leaseRebate = true;
                else
                    row.dirtyFields = { "leaseRebate": true }
            }

            if (row.leaseExpirationDate) {
                row.leaseExpirationDate = null;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields.leaseExpirationDate = true;
                else
                    row.dirtyFields = { "leaseExpirationDate": true }
            }

            return;
        }

        if (!(row.leaseRebate && !($ctrl.rebateSetAmount !== null))) 
        {
            row.leaseRebate = $ctrl.rebateSetAmount;
            row.dirty = true;
            if (row.dirtyFields)
                row.dirtyFields.leaseRebate = true;
            else
                row.dirtyFields = { "leaseRebate": true };
        }

        if ($ctrl.expireSetTo) {
            row.leaseExpirationDate = new Date($ctrl.expireSetTo);
            row.dirty = true;
            if (row.dirtyFields)
                row.dirtyFields.leaseExpirationDate = true;
            else
                row.dirtyFields = { "leaseExpirationDate": true }
        }
    }


    function setRetailColumn(row, clearFlag)
    {        
        if (clearFlag) {
            if (row.retailRebate) {
                row.retailRebate = null;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields.retailRebate = true;
                else
                    row.dirtyFields = { "retailRebate": true }
            }

            if (row.retailExpirationDate) {
                row.retailExpirationDate = null;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields.retailExpirationDate = true;
                else
                    row.dirtyFields = { "retailExpirationDate": true }
            }

            return;
        }


        if (!(row.retailRebate && !($ctrl.rebateSetAmount !== null))) {
            row.retailRebate = $ctrl.rebateSetAmount;
            row.dirty = true;
            if (row.dirtyFields)
                row.dirtyFields.retailRebate = true;
            else
                row.dirtyFields = { "retailRebate": true };
        }

        if ($ctrl.expireSetTo) {
            row.retailExpirationDate = new Date($ctrl.expireSetTo);
            row.dirty = true;
            if (row.dirtyFields)
                row.dirtyFields.retailExpirationDate = true;
            else
                row.dirtyFields = { "retailExpirationDate": true }
        }                        
    }

    function setBalloonColumn(row, clearFlag)
    {
        if (clearFlag) {
            if (row.balloonRebate) {
                row.balloonRebate = null;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields.balloonRebate = true;
                else
                    row.dirtyFields = { "balloonRebate": true }
            }

            if (row.balloonExpirationDate) {
                row.balloonExpirationDate = null;
                row.dirty = true;
                if (row.dirtyFields)
                    row.dirtyFields.balloonExpirationDate = true;
                else
                    row.dirtyFields = { "balloonExpirationDate": true }
            }

            return;
        }

        if (!(row.balloonRebate && !($ctrl.rebateSetAmount !== null))) {
            row.balloonRebate = $ctrl.rebateSetAmount;
            row.dirty = true;
            if (row.dirtyFields)
                row.dirtyFields.balloonRebate = true;
            else
                row.dirtyFields = { "balloonRebate": true };
        }

        if ($ctrl.expireSetTo) {
            row.balloonExpirationDate = new Date($ctrl.expireSetTo);
            row.dirty = true;
            if (row.dirtyFields)
                row.dirtyFields.balloonExpirationDate = true;
            else
                row.dirtyFields = { "balloonExpirationDate": true }
        }
    }


    function onPerform() {
        if ($ctrl.rebateFor && $ctrl.rebateFor > -1){
            var rows = $ctrl.kendoGrid.dataSource.data();
            rows.forEach(function (row){
                switch (parseInt($ctrl.rebateFor)) {
                    case saleTypes.lease:
                        $ctrl.setLeaseColumn(row, false);
                        break;
                    case saleTypes.retail:
                        $ctrl.setRetailColumn(row, false);
                        break;
                    case saleTypes.balloon:
                        $ctrl.setBalloonColumn(row, false);
                        break;
                    default:
                        $ctrl.setLeaseColumn(row, false);
                        $ctrl.setRetailColumn(row, false);
                        $ctrl.setBalloonColumn(row, false);
                }
            });
            $ctrl.kendoGrid.refresh();
        }
    }

    function onClear() {
        var rows = $ctrl.kendoGrid.dataSource.data();
        rows.forEach(function (row){
            switch (parseInt($ctrl.rebateFor)) {
                case saleTypes.lease:
                    $ctrl.setLeaseColumn(row, true);
                    break;
                case saleTypes.retail:
                    $ctrl.setRetailColumn(row, true);
                    break;
                case saleTypes.balloon:
                    $ctrl.setBalloonColumn(row, true);
                    break;
                default:
                    $ctrl.setLeaseColumn(row, true);
                    $ctrl.setRetailColumn(row, true);
                    $ctrl.setBalloonColumn(row, true);
            }
        });
        $ctrl.kendoGrid.refresh();
    }




    function onSave()  {
        if (!isDataValid()) {
            var modal1 = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "validationError";
                    },
                    message: function () {
                        return $ctrl.validationErrorMessage;
                    },
                    submitButtonVisible: function () {
                        return false;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    closeButtonVisible: function () {
                        return true;
                    },
                    closeButtonDisabled: function () {
                        return true;
                    },
                    closeButtonText: function () {
                        return "ok";
                    }
                }
            });

            return;
        }

        var modal = $uibModal.open({
            appendTo: angular.element($document[0].querySelector('#aa-app')),
            animation: true,
            component: 'confirmationModal',
            resolve: {
                title: function () {
                    return "readyToProcess";
                },
                message: function () {
                    return $ctrl.saveConfirmMessage;
                },
                submitButtonVisible: function () {
                    return true;
                },
                submitButtonDisabled: function () {
                    return false;
                },
                submitButtonText: function () {
                    return "continue";
                }
            }
        });
        
        modal.result.then(function () {
            var rows = $ctrl.kendoGrid.dataSource.data();
            var dirtyRows = [];
            rows.forEach(function (row) {
                if (row.dirty) {
                    dirtyRows.push({
                        dealerID: row.dealerID,
                        trimID: row.trimID,
                        leaseDealerRebateID: row.leaseDealerRebateID, 
                        leaseSaleTypeID: row.leaseSaleTypeID, 
                        leaseRebate: row.leaseRebate, 
                        leaseExpirationDate: row.leaseExpirationDate, 
                        retailDealerRebateID: row.retailDealerRebateID,
                        retailSaleTypeID: row.retailSaleTypeID,
                        retailRebate: row.retailRebate,
                        retailExpirationDate: row.retailExpirationDate,	
                        balloonDealerRebateID: row.balloonDealerRebateID,
                        balloonSaleTypeID: row.balloonSaleTypeID,
                        balloonRebate: row.balloonRebate,
                        balloonExpirationDate: row.balloonExpirationDate,
                    });
                    row.dirty = false;                    
                }
            });
            if (dirtyRows.length > 0) {
                return updateDealerRebates(dirtyRows);
            } else {
                logger.success("changesSaved");
                return $q.resolve();
            }
        })
        .catch(function () {
            return false;
        });      
    }

    function onCancel() {
        //$ctrl.kendoGrid.dataSource.cancelChanges();
        $ctrl.kendoGrid.cancelChanges();
    }

    function updateDealerRebates(rebates){
        return busy(
            "Saving Rebates",
            dataEntryService.updateDealerRebates(rebates)
            .then(function (success) {
                logger.success("changesSaved");
                return loadRebates();
            }).then(() => {
            
            }, function (error) {
                log.log(error);
            })
        );
    }

    function setTrimSelections(selections) {
        if (isDataDirty()) {
            
            var modal = $uibModal.open({
                appendTo: angular.element($document[0].querySelector('#aa-app')),
                animation: true,
                component: 'confirmationModal',
                resolve: {
                    title: function () {
                        return "pleaseConfirm";
                    },
                    message: function () {
                        return $ctrl.saveWarningMessage;
                    },
                    submitButtonVisible: function () {
                        return true;
                    },
                    submitButtonDisabled: function () {
                        return false;
                    },
                    submitButtonText: function () {
                        return "continue";
                    }
                }
            });

            modal.result.then(function () {
                $ctrl.trimSelections = selections;
                return loadRebates();
            })
            .catch(function () {
                if (selections.makeID != $ctrl.trimSelections.makeID 
                    || selections.yearID != $ctrl.trimSelections.yearID
                    || selections.modelID != $ctrl.trimSelections.modelID
                    || selections.trimID != $ctrl.trimSelections.trimID) {

                    $ctrl.defaults = {
                        vehicleTypes: 0,
                        makeID: $ctrl.trimSelections.makeID,
                        makeName: $ctrl.trimSelections.makeName,
                        yearID: $ctrl.trimSelections.yearID,
                        modelID: $ctrl.trimSelections.modelID,
                        modelName: $ctrl.trimSelections.modelName,
                        trimID: $ctrl.trimSelections.trimID,
                        trimName: $ctrl.trimSelections.trimName
    
                    };
                }
                return;
            });
        }
        else {
            $ctrl.trimSelections = selections;
            return loadRebates();
        }
    }

    function isDataValid() {
        if (!$ctrl.kendoGrid) 
            return true;
        var valid = true;
        var rows = $ctrl.kendoGrid.dataSource.data();
        rows.forEach(function (row) {
            if ((row.leaseRebate && !row.leaseExpirationDate) || (!row.leaseRebate && row.leaseExpirationDate) ||
               (row.retailRebate && !row.retailExpirationDate) || (!row.retailRebate && row.retailExpirationDate) ||
               (row.balloonRebate && !row.balloonExpirationDate) || (!row.balloonRebate && row.balloonExpirationDate) ||
                (row.leaseRebate && row.leaseRebate < $ctrl.min) || (row.leaseRebate && row.leaseRebate > $ctrl.max) ||
                (row.retailRebate && row.retailRebate < $ctrl.min) || (row.retailRebate && row.retailRebate > $ctrl.max) ||
                (row.balloonRebate && row.balloonRebate < $ctrl.min) || (row.balloonRebate && row.balloonRebate > $ctrl.max))
                valid = false;
        });
        return valid;
    }

    function isDataDirty() {
        if (!$ctrl.kendoGrid) 
            return false;
        var dirty = false;
        var rows = $ctrl.kendoGrid.dataSource.data();
        rows.forEach(function (row) {
            if (row.dirty)
                dirty = true;
        });
        return dirty;
    }

    function initGridOptions(){

        $ctrl.gridOptions = {
            dataSource: $ctrl.ds,
            navigatable: true,
            pageable: true,
            height: 580,
            columns: [
                { field: "trimFullName", title: $ctrl.trim, width: 100 },
                { field: "leaseRebate", title: $ctrl.lease +" ("+$ctrl.currencySymbol+")", template: leaseRebateTemplate, format: "{0:c0}", width: 50 },
                { field: "leaseExpirationDate", title: $ctrl.expireOn, template: leaseExpirationDateTemplate, width: 50},
                { field: "retailRebate", title: $ctrl.retail + " (" + $ctrl.currencySymbol +")", template: retailRebateTemplate, format: "{0:c0}", width: 50 },
                { field: "retailExpirationDate", title: $ctrl.expireOn, template: retailExpirationDateTemplate, width: 50},
                { field: "balloonRebate", title: $ctrl.balloon + " (" + $ctrl.currencySymbol +")", template: balloonRebateTemplate, format: "{0:c0}", width: 50 },
                { field: "balloonExpirationDate", title: $ctrl.expireOn, template: balloonExpirationDateTemplate, width: 50}],
            editable: true,
            sortable: {
                mode: "single",
                allowUnsort: false
            },
            resizable: true,
            reorderable: true,
            filterable: false
        };

    }
    
    function loadRebates() {
        var makeID = $ctrl.trimSelections.makeID;
        var yearID = $ctrl.trimSelections.yearID;
        var modelID = $ctrl.trimSelections.modelID ? $ctrl.trimSelections.modelID : 0;
        var trimID = $ctrl.trimSelections.trimID ? $ctrl.trimSelections.trimID : 0;

        return busy(
            "Loading Rebates",
            dataEntryService.getDealerRebates($ctrl.selectedDealerId, makeID, yearID, modelID, trimID)
            .then((dataRebates) => {                
                $ctrl.ds = new $ctrl.kendo.data.DataSource({
                    data: dataRebates,        
                    schema: {
                        model: {
                            fields: {
                                trimFullName: { type: "string", editable: false},
                                leaseRebate: { type: "number" , editable: true},
                                leaseExpirationDate: { type: "date", editable: true },
                                retailRebate: { type: "number" , editable: true},
                                retailExpirationDate: { type: "date", editable: true },
                                balloonRebate: { type: "number" , editable: true},
                                balloonExpirationDate: { type: "date", editable: true },
                                dealerID: { type: "string", editable: false},
                                trimID: { type: "string", editable: false},
                                leaseDealerRebateID: { type: "string", editable: false}, 
                                leaseSaleTypeID: { type: "string", editable: false},
                                retailDealerRebateID: { type: "string", editable: false},
                                retailSaleTypeID: { type: "string", editable: false},
                                balloonDealerRebateID: { type: "string", editable: false},
                                balloonSaleTypeID: { type: "string", editable: false},
                            }
                        }
                    },
                    batch: true,
                    pageSize: 50
                }); 
                switch (cultureName){
                    case cultures.enUS:
                    case cultures.enGB:
                    case cultures.enCA:
                    case cultures.frCA:
                    case cultures.esUS:
                    case cultures.frFR:
                        $ctrl.kendo.culture(cultureName); 
                        break;
                    default:
                        $ctrl.kendo.culture(cultures.enUS); 
                        break;                
                }

                $("#k-datepicker").kendoDatePicker.widget.fn.options.min = new Date();                 

                $ctrl.showQuickAction = false;
                $ctrl.kendoGrid = $("#gridDataEntryRebates").data("kendoGrid");

                clearQuickAction();            
            })
        );
    }

    function leaseRebateTemplate(e) {
        // Validation Error Indicator
        if (!e.leaseExpirationDate) {            
            var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
            var rowChildren = $(row.children('td[role="gridcell"]'));
            var cell = rowChildren.eq(2);
            if (e.leaseRebate) {
                cell.html("<div class='edit-box-error'></div>");
            }
            else {
                cell.html("<div class='edit-box'></div>");
            }
        }

        if (e.leaseRebate !== null) {
            if (e.leaseRebate >= $ctrl.min && e.leaseRebate <= $ctrl.max)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.leaseRebate, 'n0') + "</div>";  
            else
                return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.leaseRebate, 'n0') + "</div>";
        }
        else if (e.leaseExpirationDate) {
            return "<div class='edit-box-error'></div>";
        }
        else {
            return "<div class='edit-box'></div>";
        }        
    }

    function leaseExpirationDateTemplate(e) {
        if (!e.leaseRebate) {            
            var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
            var rowChildren = $(row.children('td[role="gridcell"]'));
            var cell = rowChildren.eq(1);
            if (e.leaseExpirationDate) {
                cell.html("<div class='edit-box-error'></div>");
            }
            else {
                cell.html("<div class='edit-box'></div>");
            }
        }

        var expirationDate = e.leaseExpirationDate ? e.leaseExpirationDate : '';
        var today = new Date();
        today.setHours(0,0,0,0);        
        if (expirationDate) {            
            var localeDateFilter = $filter('localeDate');
            var localeExpirationDate = localeDateFilter(expirationDate);
            if (expirationDate < today )        
                return "<div class='expire-date edit-box'>" + localeExpirationDate +  "</div>";
            else
                return "<div class='edit-box'>" + localeExpirationDate +  "</div>";
        }
        else if (e.leaseRebate) {
            return  "<div class='edit-box-error'></div>";
        }
        else {
            return  "<div class='edit-box'></div>";
        }
    }


    function retailRebateTemplate(e) {
        // Validation Error Indicator
        if (!e.retailExpirationDate) {            
            var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
            var rowChildren = $(row.children('td[role="gridcell"]'));
            var cell = rowChildren.eq(4);
            if (e.retailRebate) {
                cell.html("<div class='edit-box-error'></div>");
            }
            else {
                cell.html("<div class='edit-box'></div>");
            }
        }

        if (e.retailRebate !== null) {
            if (e.retailRebate >= $ctrl.min && e.retailRebate <= $ctrl.max)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.retailRebate, 'n0') + "</div>";  
            else
                return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.retailRebate, 'n0') + "</div>";
        }
        else if (e.retailExpirationDate) {
            return "<div class='edit-box-error'></div>";
        }
        else {
            return "<div class='edit-box'></div>";
        }        
    }

    function retailExpirationDateTemplate(e) {
        if (!e.retailRebate) {            
            var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
            var rowChildren = $(row.children('td[role="gridcell"]'));
            var cell = rowChildren.eq(3);
            if (e.retailExpirationDate) {
                cell.html("<div class='edit-box-error'></div>");
            }
            else {
                cell.html("<div class='edit-box'></div>");
            }
        }

        var expirationDate = e.retailExpirationDate ? e.retailExpirationDate : '';
        var today = new Date();
        today.setHours(0,0,0,0);
        if (expirationDate) {
            var localeDateFilter = $filter('localeDate');
            var localeExpirationDate = localeDateFilter(expirationDate);
            if (expirationDate < today )        
                return "<div class='expire-date edit-box'>" + localeExpirationDate +  "</div>";
            else
                return "<div class='edit-box'>" + localeExpirationDate +  "</div>";
        }
        else if (e.retailRebate) {
            return  "<div class='edit-box-error'></div>";
        }
        else {
            return  "<div class='edit-box'></div>";
        }
    }


    function balloonRebateTemplate(e) {
        // Validation Error Indicator
        if (!e.balloonExpirationDate) {            
            var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
            var rowChildren = $(row.children('td[role="gridcell"]'));
            var cell = rowChildren.eq(6);
            if (e.balloonRebate) {
                cell.html("<div class='edit-box-error'></div>");
            }
            else {
                cell.html("<div class='edit-box'></div>");
            }
        }

        if (e.balloonRebate !== null) {
            if (e.balloonRebate >= $ctrl.min && e.balloonRebate <= $ctrl.max)
                return "<div class='edit-box'>" + $ctrl.kendo.toString(e.balloonRebate, 'n0') + "</div>";  
            else
                return "<div class='edit-box-error'>" + $ctrl.kendo.toString(e.balloonRebate, 'n0') + "</div>";              
        }
        else if (e.balloonExpirationDate) {
            return "<div class='edit-box-error'></div>";
        }
        else {
            return "<div class='edit-box'></div>";
        }        
    }

    function balloonExpirationDateTemplate(e) {
        if (!e.balloonRebate) {
            var grid = $("#gridDataEntryPrices").data("kendoGrid");
            var row = $ctrl.kendoGrid.tbody.find("tr[data-uid='" + e.uid + "']");
            var rowChildren = $(row.children('td[role="gridcell"]'));
            var cell = rowChildren.eq(5);
            if (e.balloonExpirationDate) {
                cell.html("<div class='edit-box-error'></div>");
            }
            else {
                cell.html("<div class='edit-box'></div>");
            }
        }

        var expirationDate = e.balloonExpirationDate ? e.balloonExpirationDate : '';
        var today = new Date();
        today.setHours(0,0,0,0);
        if (expirationDate) {
            var localeDateFilter = $filter('localeDate');
            var localeExpirationDate = localeDateFilter(expirationDate);
            if (expirationDate < today )        
                return "<div class='expire-date edit-box'>" + localeExpirationDate +  "</div>";
            else
                return "<div class='edit-box'>" + localeExpirationDate +  "</div>";
        }
        else if (e.balloonRebate) {
            return  "<div class='edit-box-error'></div>";
        }
        else {
            return  "<div class='edit-box'></div>";
        }
    }

}
