<div [collapse]="!isCustomerConnectOpen" #collapse="bs-collapse" [isAnimated]="!mobileView">
        
    <div class="text-center action-bar"  translate="customerConnectTab"></div>
        <customer-connect-actions [opportunity]="opportunity"
                                  [customer]="customer"
                                  [permissions]="permissions"
                                  [isCustomerConnectOpen]="isCustomerConnectOpen"
                                  [defaultCustomerConnectAction]="defaultCustomerConnectAction"
                                  (onAction)="onActionSelect($event)"                                  
                                  (onCustomerEdit)="onCustomerEdit.emit($event)"
                                  [isEuroLite]="isEuroLite"
                                  [isTextingEnabled]="isTextingEnabled"
                                  [mobileView]="mobileView">
        </customer-connect-actions>
        <customer-connect-detail [permissions]="permissions"
                                 [isCustomerConnectOpen]="isCustomerConnectOpen"
                                 [action]="action"
                                 [actionTime]="actionTime"
                                 [dialogParams]="scheduleModalParamObject"
                                 (onLogScheduleSubmit)="onLogScheduleSubmit.emit($event)"
                                 [isEuroLite]="isEuroLite">
        </customer-connect-detail>
</div>
