"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".customer-culture-wrapper[_ngcontent-%COMP%]{display:inline-block;width:100%}.customer-culture-wrapper[_ngcontent-%COMP%]   select[_ngcontent-%COMP%]:disabled{background-color:#ebebe4}"];
exports.styles = styles;
