import { Component, ElementRef, Injector, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DealSheetPermissions } from '../../../../interfaces/deal-sheet/deal-sheet-permissions.interface';

@Component({
    selector: 'price',
    templateUrl: './price.component-ng.html',
    styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit, OnChanges {
    @Input() value: number;
    @Input() permissions: DealSheetPermissions;
    @Input() readonly: boolean;
    @Output() priceChange = new EventEmitter<any>();

    constructor(elementRef: ElementRef, injector: Injector) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.value = Math.floor(this.value);
        }
    }

    public update(source) {
        this.validate(source);
    }

    // vlidate input to number only
    private validate(source) {
        if (source == '') { this.value = 0; return; }
        if (!source) { this.value = 0; return; }
        source = source + '';
        source = source.trim();
        source = source.replace(/[^0-9]/g, '');
        this.value = parseInt(source);
    }

    public onValueChange(value) {
        this.validate(value);
        this.priceChange.emit({ price: this.value });
    }

}
