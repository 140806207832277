"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var core_2 = require("@ngx-translate/core");
var opportunity2_service_1 = require("../../../services/opportunity2.service");
var ScoreInfoModalComponent = /** @class */ (function () {
    function ScoreInfoModalComponent(_bsModalRef, tService, opportunity2Service) {
        var _this = this;
        this._bsModalRef = _bsModalRef;
        this.tService = tService;
        this.opportunity2Service = opportunity2Service;
        this.featuresLoaded = false;
        this.translations = {};
        this.MAX_RECORDS = 8;
        this.translationKeys = [
            'faalertletter',
            'flexAlertLetter',
            'inMarketLetter',
            'inMarketEngagedLetter',
            'contractEndLetter',
            'serviceLetter',
            'serviceAppointmentLetter',
            'mileageLetter',
            'warrantyLetter',
        ];
        this.dataSource = [
            { id: "100", featureId: "006", alerts: "Alert", code: 'alert', description: 'Alert', translateKey: 'faalertletter' },
            { id: "200", featureId: "", alerts: "Flex", code: 'flexalert', description: 'Flex', translateKey: 'flexAlertLetter' },
            { id: "400", featureId: "", alerts: "In Market", code: 'inmarket', description: 'In Market', translateKey: 'inMarketLetter' },
            { id: "500", featureId: "019", alerts: "Engaged", code: 'inmarketengaged', description: 'Engaged', translateKey: 'inMarketEngagedLetter' },
            { id: "600", featureId: "022", alerts: "Contract End", code: 'contractend', description: 'Contract End', translateKey: 'contractEndLetter' },
            { id: "700", featureId: "018", alerts: "Service", code: 'service', description: 'Service', translateKey: 'serviceLetter' },
            { id: "800", featureId: "030", alerts: "Pending Service", code: 'appointment', description: 'Pending Service', translateKey: 'serviceAppointmentLetter' },
            { id: "900", featureId: "028", alerts: "Mileage", code: 'mileage', description: 'Mileage', translateKey: 'mileageLetter' },
            { id: "1000", featureId: "024", alerts: "Warranty", code: 'warranty', description: 'Warranty', translateKey: 'warrantyLetter' }
        ];
        this.tService.get(this.translationKeys)
            .subscribe(function (translations) {
            _this.translations = translations;
        });
    }
    ScoreInfoModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._bsModalRef.hide();
        this.busy = this.opportunity2Service.getEntityScoreFeature(this.entityId)
            .then(function (data) {
            _this.features = data.slice(0, _this.MAX_RECORDS).sort(_this.byImportanceRank);
            _this.features.forEach(function (item) {
                var alert = null;
                if (item.featureID != "") {
                    alert = _this.getByFeatureId(item.featureID);
                }
                if (alert !== null) {
                    item.isAlert = true;
                    item.alertCode = alert.code;
                    item.alertCodeLetter = _this.translations[alert.translateKey];
                }
                else {
                    item.isAlert = false;
                    var transKey = 'scoreFeature_' + item.featureID;
                    var description = _this.tService.instant(transKey);
                    if (description && description != transKey) {
                        item.featureDescription = description;
                    }
                    else {
                        item.featureDescription = "";
                    }
                }
                item.posneg = "";
                if (item.weight > 0) {
                    item.posneg = "+";
                }
                else if (item.weight < 0) {
                    item.posneg = "-";
                }
            });
            _this.featuresLoaded = true;
        });
    };
    ScoreInfoModalComponent.prototype.ngAfterViewInit = function () {
        this._bsModalRef.hide();
    };
    ScoreInfoModalComponent.prototype.cancelModal = function () {
        this._bsModalRef.hide();
    };
    ScoreInfoModalComponent.prototype.byImportanceRank = function (feature1, feature2) {
        return feature1.importanceRank - feature2.importanceRank;
    };
    ScoreInfoModalComponent.prototype.getByFeatureId = function (id) {
        var alert = this.dataSource.find(function (item) { return item.featureId == id; });
        if (typeof (alert) === 'undefined')
            return null;
        return alert;
    };
    return ScoreInfoModalComponent;
}());
exports.ScoreInfoModalComponent = ScoreInfoModalComponent;
