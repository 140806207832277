import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { DealHistoryDto } from '../../../interfaces/deal-history-dto.interface';
import { TranslateService } from '@ngx-translate/core';
import { DealSheetService } from '../../../ajs-upgraded-providers';
import { Observable, Subject, Subscription, from } from 'rxjs';

@Component({
    selector: 'aa-deal-history',
    templateUrl: './deal-history.component-ng.html',
    styleUrls: ['./deal-history.component.scss']
})
export class DealHistoryComponent implements OnChanges, OnInit, OnDestroy {
    public filters: any;
    public listDealHistory: Array<DealHistoryDto> = [];
    public listDealHistoryTemp: Array<DealHistoryDto> = [];
    private subscriptionAggregator = new Subscription();
    public busy: Subscription;


    private alertsTranslatedText: string;
    private mappingTranslatedText: string;
    private termTranslatedText: string;
    private paymentTranslatedText: string;
    private dealHistoryData: any;

    @Input() isTabSelected: boolean;
    @Input() defaultTab: number;
    @Input() currentTab: number;
    @Input() opportunityId: any = '';

    @Output() onSetAsDefault = new EventEmitter<any>();

    constructor(private translateService: TranslateService,
        @Inject(DealSheetService) private dealSheetService) { }

    public vehicleImageNotAvailableUrl = require('../../../images/vehicle-image-not-available.png');

    ngOnInit() {
        this.setFilters();
    }

    ngOnDestroy(): void {
        this.subscriptionAggregator.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.opportunityId) {
            this.dealHistoryData = null;
        }

        if (changes.isTabSelected && changes.isTabSelected.currentValue) {
            if (!this.dealHistoryData) {
                this.alertsTranslatedText = this.translateService.instant('alerts');
                this.mappingTranslatedText = this.translateService.instant('mapping');
                this.termTranslatedText = this.translateService.instant('term');
                this.paymentTranslatedText = this.translateService.instant('payment');
                this.busy = this.getDealHistory();
                this.subscriptionAggregator.add(this.busy);
            }
        }
    }

    onClick(): void {
        this.onSetAsDefault.emit();
    }

    getDealHistory(): Subscription {
        return from(this.dealSheetService.getDealHistory(this.opportunityId))
            .subscribe(dealHistoryData => {
                this.dealHistoryData = dealHistoryData;
                this.initData(dealHistoryData);
            });
    }


    private initData(data) {
        for (const ele of data) {
            let changeTypes = [];
            if (ele.isAlertChange) changeTypes.push(this.alertsTranslatedText);
            if (ele.isMappingChange) changeTypes.push(this.mappingTranslatedText);
            if (ele.isPaymentChange) changeTypes.push(this.paymentTranslatedText);
            if (ele.isTermChange) changeTypes.push(this.termTranslatedText);

            ele.changeTypes = changeTypes.join(", ");
        }

        let listDealHistory = data.filter(ele =>
            ele.isAlertChange ||
            ele.isMappingChange ||
            ele.isPaymentChange ||
            ele.isTermChange
        ).map(history => ({
            ...history,
            alerts: history.alerts.map(type => ({ type })),
        }));

        this.listDealHistory = listDealHistory;
        this.listDealHistoryTemp = listDealHistory;
    }

    private setFilters() {
        this.filters = [{
            key: 'alerts',
            moduleName: 'alerts',
            enabled: true,
            selected: true
        }, {
            key: 'mapping',
            moduleName: 'mapping',
            enabled: true,
            selected: true
        }, {
            key: 'term',
            moduleName: 'term',
            enabled: true,
            selected: true
        }, {
            key: 'payment',
            moduleName: 'payment',
            enabled: true,
            selected: true
        }];

    }

    updateUrl(event, row: DealHistoryDto) {
        row.photoPath = this.vehicleImageNotAvailableUrl;
    }

    checkboxClicked() {
        this.listDealHistory = this.listDealHistoryTemp.filter(ele => {
            let selections = this.filters.filter(i => i.selected).map(a => a.key);

            return selections.length !== 0 && (
                (ele.isAlertChange && selections.includes('alerts')) ||
                (ele.isMappingChange && selections.includes('mapping')) ||
                (ele.isPaymentChange && selections.includes('payment')) ||
                (ele.isTermChange && selections.includes('term'))
            );
        });
    }

}
