import angular from 'angular';

angular.module('AaApp.Opp')
    .constant('doNotCallStatusTypes', {
        notProcessed: 0,
        invalidNumber: 1,
        notSubscribed: 2,
        onDoNotCallList: 3,
        offDoNotCallList: 4
    })
