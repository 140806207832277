import angular from 'angular';

angular.module('AaApp.Opp').factory('searchService', searchService);

/* @ngInject */
function searchService($q, $timeout, coreApi) {
    var service = {
        getFreshOpportunities: getFreshOpportunities,
        getOneToOneEntities: getOneToOneEntities,
        getOpenOpportunities: getOpenOpportunities,
        getRecentlyAssignedOpportunities: getRecentlyAssignedOpportunities,
        getEntitiesListCustom: getEntitiesListCustom,
        getTagsForDealers: getTagsForDealers,
        getSoldBySearchByDealers: getSoldBySearchByDealers,
        getPrivateOfferInfo: getPrivateOfferInfo,
        getBankSelect: getBankSelect,
        saveSearch: saveSearch,
        getDealerCampaignRequestsInWindow: getDealerCampaignRequestsInWindow,
        createDealerCampaignExportAudience: createDealerCampaignExportAudience,
        getNoLongerOwnForDealer: getNoLongerOwnForDealer
    };

    return service;

    function getOneToOneEntities(params) {
        return coreApi.api.Search.GetOneToOneEntities.POST(params)
            .then(function (response) {
                return response.data;
            });
    }

    function getEntitiesListCustom(params) {
        return coreApi.api.Search.GetEntitiesListCustom.POST(params)
            .then(function (response) {
                return response.data;
            });
    }

    function getOpenOpportunities(selectedDealerId, saleTypes, flushCache) {
        return coreApi.api.Search.GetOpenOpportunities.GET({ saleTypes: saleTypes, flushCache: flushCache }, { overrideDealerIds: [selectedDealerId] })
            .then(function (response) {
                return response.data;
            });
    }

    function getFreshOpportunities(selectedDealerId, flushCache) {
        return coreApi.api.Search.GetFreshOpportunities.GET({ flushCache }, { overrideDealerIds: [selectedDealerId] })
            .then(function (response) {
                return response.data;
            });
    }

    function getRecentlyAssignedOpportunities(selectedDealerId, flushCache) {
        return coreApi.api.Search.GetRecentlyAssignedOpportunities.GET({ flushCache: flushCache, maxResults: 20 }, { overrideDealerIds: [selectedDealerId] })
            .then(function (response) {
                return response.data;
            });
    }

    function getTagsForDealers(dealerId) {
        return coreApi.api.Tag.GetTagsForDealers['{dealerId}'].GET({ dealerId: dealerId })
            .then(function (response) {
                return response.data;
            });
    }

    function getSoldBySearchByDealers(dealerId) {
        return coreApi.api.Search.GetSoldBySearchByDealers['{dealerId}'].GET({ dealerId: dealerId })
            .then(function (response) {
                return response.data;
            });
    }

    function getPrivateOfferInfo(dealerId, entityId) {
        return coreApi.api.Search.GetPrivateOfferInfo['{dealerId}']['{entityId}'].GET({ dealerId: dealerId, entityId: entityId })
            .then(function (response) {
                return response.data;
            });
    }

    function getBankSelect(dealerId) {
        return coreApi.api.Bank.GetBankSelect['{dealerId}'].GET({ dealerId: dealerId })
            .then(function (response) {
                return response.data;
            });
    }

    function saveSearch(dealerId, searchName, autoShareSearch, autoShareSearchPosition, searchModel, assignPandoXRule,
        pandoXRuleQuantity,
        executePandoXImmediately,
        pandoXRuleCategoryId,
        searchActiveStartDate,
        searchActiveEndDate) {
        var request = { 
            DealerID: dealerId, 
            SearchName: searchName, 
            AutoShareSearch: autoShareSearch, 
            AutoShareSearchPosition: autoShareSearchPosition, 
            SearchModel: searchModel,
            AssignPandoXRule: assignPandoXRule,
            RuleCategoryId: pandoXRuleCategoryId,
            PandoXRuleQuantity: pandoXRuleQuantity,
            ExecutePandoXImmediately: executePandoXImmediately,
            SearchActiveStartDate: searchActiveStartDate,
            SearchActiveEndDate: searchActiveEndDate,
        };

        return coreApi.api.Search.SaveSearch.POST(request)
            .then(function (response) {
                return response.data;
            });
    }

    function createDealerCampaignExportAudience(searchModel, campaignType, dealerIds, campaignTypeName) {
        var request = {
            Search: searchModel,
            ExportCampaignTypeID: campaignType,
            DealerIds: dealerIds,
            CampaignTypeDescription: campaignTypeName
        };
        return coreApi.api.Search.CreateDealerCampaignExportAudience.POST(request)
            .then(function (response) {
                return response.data;
            });
    }

    function getDealerCampaignRequestsInWindow() {
        return coreApi.api.Search.GetDealerCampaignRequestsInWindow['{days}'].GET({ days: 30 })
            .then(function (response) {
                return response.data;
            });
    }

    function getNoLongerOwnForDealer(dealerId) {
        return coreApi.api.Search.GetNoLongerOwnForDealer['{dealerId}'].GET({ dealerId: dealerId })
            .then(function (response) {
                return response.data;
            });
    }
}
