"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
var http_1 = require("@angular/common/http");
var log_factory_service_1 = require("./log-factory.service");
var storage_service_1 = require("../modules/storage/storage.service");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("./log-factory.service"));
var i2 = __importStar(require("../modules/storage/storage.service"));
var i3 = __importStar(require("@angular/common/http"));
var CONFIG = {
    enabled: constants_1.Settings.environmentVariables.pandoInboxEnabled,
    url: constants_1.Settings.apiUrls.motofuze,
    pandoInboxUrl: constants_1.Settings.apiUrls.pandoInboxUrl,
    motofuzeToken: null,
    tokenPromise: null
};
var CommunicationApiService = /** @class */ (function () {
    function CommunicationApiService(logFactory, storageService, httpBackend) {
        this.storageService = storageService;
        this.log = logFactory.get("communicationApi");
        this.httpClient = new http_1.HttpClient(httpBackend);
    }
    CommunicationApiService.prototype.getPandoInboxConfig = function () {
        return __assign({}, CONFIG);
    };
    CommunicationApiService.prototype.getCurrentUser = function () {
        return this.requestWithToken('users/currentuser')
            .then(function (response) {
            return response.body.Data;
        }, function (error) {
            //console.log(error);
            return null;
        });
    };
    CommunicationApiService.prototype.getGlobalFrameConfig = function () {
        return this.requestWithToken('users/globalframeconfig')
            .then(function (response) {
            return response.body.Data;
        }, function (error) {
            return null;
        });
    };
    CommunicationApiService.prototype.get = function (path, request) {
        return this.requestWithToken(path, __assign({}, request, { method: 'GET' }));
    };
    CommunicationApiService.prototype.put = function (path, request) {
        return this.requestWithToken(path, __assign({}, request, { method: 'PUT' }));
    };
    CommunicationApiService.prototype.post = function (path, request) {
        return this.requestWithToken(path, __assign({}, request, { method: 'POST' }));
    };
    CommunicationApiService.prototype.delete = function (path, request) {
        return this.requestWithToken(path, __assign({}, request, { method: 'DELETE' }));
    };
    CommunicationApiService.prototype.requestWithToken = function (path, request) {
        var _this = this;
        if (!CONFIG.url) {
            return Promise.reject(new Error('MotoFuze API has not been configured'));
        }
        var fetchTokenAndRequest = function (request) {
            var tokenPromise = CONFIG.tokenPromise;
            if (!tokenPromise) {
                tokenPromise = CONFIG.tokenPromise = tradeAccessTokenForMotofuzeToken()
                    .then(function (token) {
                    CONFIG.tokenPromise = null;
                    return token;
                }, function (err) {
                    CONFIG.tokenPromise = null;
                    throw err;
                });
            }
            return tokenPromise.then(function (motofuzeToken) {
                CONFIG.motofuzeToken = motofuzeToken;
                return _this.httpClient.request(request.method, request.url, {
                    withCredentials: false,
                    headers: new http_1.HttpHeaders({ 'Authorization': "Bearer " + motofuzeToken }),
                    responseType: 'json',
                    observe: 'response',
                    body: request.data
                }).toPromise();
            });
        };
        var tradeAccessTokenForMotofuzeToken = function () {
            var accessToken = _this.storageService.getItem('accessToken');
            if (!accessToken) {
                return Promise.reject(new Error("No AccessToken available"));
            }
            if (!CONFIG.url) {
                return Promise.reject(new Error('MotoFuze API has not been configured'));
            }
            var url = CONFIG.url + 'account/ObtainLocalAccessToken';
            // Creating our own client with the standard http backend allows us to skip the interceptors
            // https://stackoverflow.com/questions/46469349/how-to-make-an-angular-module-to-ignore-http-interceptor-added-in-a-core-module
            // This request fails if we include our standard 'no-cache' headers
            return _this.httpClient.request('GET', url, {
                withCredentials: false,
                params: new http_1.HttpParams({
                    fromObject: {
                        'provider': 'sso',
                        'externalAccessToken': accessToken,
                    }
                }),
                responseType: 'json',
                observe: 'response'
            }).toPromise()
                .then(function (response) {
                if (response && response.body && response.body['access_token']) {
                    return response.body['access_token'];
                }
                else {
                    _this.log.warn("Unexpected response for ObtainLocalAccessToken", JSON.stringify(response));
                    throw new Error("Unable to get the MotoFuze access token.");
                }
            }, function (error) {
                //console.log(error);
                return null;
            });
        };
        if (request == null) {
            request = { method: "GET", url: "", data: "" };
        }
        var url = CONFIG.url + path;
        request.url = url;
        var motofuzeToken = CONFIG.motofuzeToken;
        if (!motofuzeToken) {
            // If we don't have a saved token, fetch the token then make a
            // single request.
            return fetchTokenAndRequest(request);
        }
        else {
            // Make one request with the saved token, and if it fails
            // unauthorized, fetch a new token and re-request.
            return this.httpClient.request(request.method, url, {
                withCredentials: false,
                headers: new http_1.HttpHeaders({ 'Authorization': "Bearer " + motofuzeToken }),
                responseType: 'json',
                observe: 'response',
                body: request.data
            }).toPromise()
                .catch(function (response) {
                if (response.status == 401) {
                    // If unauthorized, fetch a new token and retry once
                    return fetchTokenAndRequest(request);
                }
                else {
                    throw request;
                }
            });
        }
    };
    CommunicationApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommunicationApiService_Factory() { return new CommunicationApiService(i0.ɵɵinject(i1.LogFactory), i0.ɵɵinject(i2.StorageService), i0.ɵɵinject(i3.HttpBackend)); }, token: CommunicationApiService, providedIn: "root" });
    return CommunicationApiService;
}());
exports.CommunicationApiService = CommunicationApiService;
