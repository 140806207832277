
/* injects from baggage-loader */
require('./service-detail-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('serviceDetailModal', {
    templateUrl: '/app/components/deal-sheet/modals/service-detail-modal.component.html',
    controller: ServiceDetailModal,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function ServiceDetailModal() {
    var $ctrl = this;

    $ctrl.$onInit = init;

    function init() {
        $ctrl.roId = $ctrl.resolve.roId;
        $ctrl.resolve.getServiceLineDetails($ctrl.resolve.dealerId, $ctrl.resolve.roId).then(function (data) {
            $ctrl.tableData = data;
        });
    }
}
