export function downloadArraybufferResponse({ contentType, filename }) {
    return function downloadArraybufferResponse(httpResponse) {
        const data = new Blob([httpResponse.data], { type: contentType });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(data, filename);
        } else {
            const urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
            const fileUrl = urlCreator.createObjectURL(data);

            if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                var reader = new FileReader();

                reader.onload = function(e){
                    window.location.href = reader.result;
                }
                reader.readAsDataURL(data);
            } else {
                var open = window.open(fileUrl, "_blank");

                if (open == null) {
                    throw new Error("popupBlockerEnabledErrorMessage");
                }
            }
        }
    }
}
