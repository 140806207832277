"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityTypes = {
    logNote: 1,
    logPhoneCall: 2,
    schedulePhoneCall: 3,
    reschedulePhoneCall: 4,
    reassignPhoneCall: 5,
    completePhoneCall: 6,
    expirePhoneCall: 7,
    cancelPhoneCall: 8,
    scheduleAppointment: 9,
    rescheduleAppointment: 10,
    reassignAppointment: 11,
    completeAppointment: 12,
    expireAppointment: 13,
    cancelAppointment: 14,
    printTemplate: 15,
    printOfferLetter: 16,
    pushToCrm: 17,
    sendOneToOneEmail: 18,
    sendOneToOneMail: 19,
    sendEmailTemplate: 20,
    logCrmProspectStatus: 21,
    logCrmProspectAlert: 22,
    logCrmRemark: 23,
    logCrmShowroomRemark: 24,
    logCrmCustomerWillBeBack: 25,
    logCrmInboundCall: 26,
    logCrmOutboundCall: 27,
    logCrmInboundEmail: 28,
    logCrmOutboundEmail: 29,
    sendCrmLetter: 30,
    sendCrmTextMessage: 31,
    sendCrmReminder: 32,
    sendEmail: 33,
    emailReply: 34,
    emailReplyCustomer: 35,
    emailReplyDealer: 36,
    pandoProcess: 37,
    purlView: 38,
    purlSubmit: 39,
    websiteEngaged: 40,
    websiteLeadSubmission: 41,
    reviewProServiceRating: 42,
    reviewProSalesRating: 43,
    sentSLMEmailOffer: 44,
    textMessage: 45,
    geoAlertActive: 46,
    geoAlertCampaign: 47,
    autoAssistCommSent: 48,
    autoAssistNewHotLead: 49,
    autoAssistCustomerReplied: 50,
    geoAlertAd: 51,
    purlViewIntelMrkt: 52,
    purlSubmitIntelMrkt: 53,
    sendIntelMrktEmail: 54,
    sendIntelMrktMail: 55,
    customerPandoProcess: 56,
    facebookAdded: 57,
    facebookVerified: 58,
    autofiDigitalRetailing: 59,
    autofiDigitalRetailingCreditApp: 60,
    autofiDigitalRetailingApptSched: 61,
    crmInboundTextMessage: 62,
    crmOutboundTextMessage: 63,
    sendFordCreditMailers: 64,
    export: 65,
    appraisal: 66,
    dmsPush: 67,
    sendServiceMarketingEmail: 68,
    sendServiceMarketingMail: 69,
    sendPartnerExportMail: 70
};
