import angular from 'angular';

angular.module('AaApp.Opp').factory('exception', exception);

/* @ngInject */
function exception(logger) {
    var service = {
        catcher: catcher
    };
    return service;

    function catcher(message) {
        return function (reason) {
            logger.genericError(message, reason);
        };
    }
}
