/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AlertminerApiConfiguration as __Configuration } from '../alertminer-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CoreApiPrincipal } from '../models/core-api-principal';
import { UserDto } from '../models/user-dto';
import { UserExtDto } from '../models/user-ext-dto';
import { EntityUserInfoDto } from '../models/entity-user-info-dto';
import { UserDealerModel } from '../models/user-dealer-model';
import { CheckUserIPResultDto } from '../models/check-user-ipresult-dto';
@Injectable({
  providedIn: 'root',
})
class UserClientService extends __BaseService {
  static readonly LoggedInUserGETPath = '/api/User/LoggedInUser';
  static readonly CurrentUserGETPath = '/api/User/CurrentUser';
  static readonly GetUserByUsernameGETPath = '/api/User/GetUser/{username}';
  static readonly GetEnabledUsersForDealerByDealeridGETPath = '/api/User/GetEnabledUsersForDealer/{dealerId}';
  static readonly GetEnabledClientUsersExtByDealerByDealeridGETPath = '/api/User/GetEnabledClientUsersExtByDealer/{dealerId}';
  static readonly GetUserFullNameByUseridGETPath = '/api/User/FullName/{userId}';
  static readonly GetUserFullNameByEntityByEntityidGETPath = '/api/User/FullNameByEntity/{entityId}';
  static readonly GetEntityUserInfoByEntityidGETPath = '/api/User/GetEntityUserInfo/{entityId}';
  static readonly LogUserLoginPOSTPath = '/api/User/LogUserLogin';
  static readonly FacebookUserIdByEmailaddressGETPath = '/api/User/FacebookUserId/{emailAddress}';
  static readonly GetUserDealersGETPath = '/api/User/GetUserDealers';
  static readonly GetUserIsMetricByCountryidGETPath = '/api/User/GetUserIsMetric/{countryId}';
  static readonly CheckUserIPGETPath = '/api/User/CheckUserIP';
  static readonly IsWhiteSalePartnerGETPath = '/api/User/IsWhiteSalePartner';
  static readonly ByIdGETPath = '/api/User/{id}';
  static readonly ByIdDELETEPath = '/api/User/{id}';
  static readonly GetManyGETPath = '/api/User/GetMany';
  static readonly PUTPath = '/api/User';
  static readonly POSTPath = '/api/User';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  LoggedInUserGETResponse(): __Observable<__StrictHttpResponse<CoreApiPrincipal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/LoggedInUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CoreApiPrincipal>;
      })
    );
  }
  /**
   * @return Success
   */
  LoggedInUserGET(): __Observable<CoreApiPrincipal> {
    return this.LoggedInUserGETResponse().pipe(
      __map(_r => _r.body as CoreApiPrincipal)
    );
  }

  /**
   * @return Success
   */
  CurrentUserGETResponse(): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/CurrentUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * @return Success
   */
  CurrentUserGET(): __Observable<UserDto> {
    return this.CurrentUserGETResponse().pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * @param username undefined
   * @return Success
   */
  GetUserByUsernameGETResponse(username: string): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetUser/${username}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * @param username undefined
   * @return Success
   */
  GetUserByUsernameGET(username: string): __Observable<UserDto> {
    return this.GetUserByUsernameGETResponse(username).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetEnabledUsersForDealerByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<UserDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetEnabledUsersForDealer/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetEnabledUsersForDealerByDealeridGET(dealerId: number): __Observable<Array<UserDto>> {
    return this.GetEnabledUsersForDealerByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<UserDto>)
    );
  }

  /**
   * @param dealerId undefined
   * @return Success
   */
  GetEnabledClientUsersExtByDealerByDealeridGETResponse(dealerId: number): __Observable<__StrictHttpResponse<Array<UserExtDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetEnabledClientUsersExtByDealer/${dealerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserExtDto>>;
      })
    );
  }
  /**
   * @param dealerId undefined
   * @return Success
   */
  GetEnabledClientUsersExtByDealerByDealeridGET(dealerId: number): __Observable<Array<UserExtDto>> {
    return this.GetEnabledClientUsersExtByDealerByDealeridGETResponse(dealerId).pipe(
      __map(_r => _r.body as Array<UserExtDto>)
    );
  }

  /**
   * @param userId undefined
   * @return Success
   */
  GetUserFullNameByUseridGETResponse(userId: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/FullName/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param userId undefined
   * @return Success
   */
  GetUserFullNameByUseridGET(userId: number): __Observable<string> {
    return this.GetUserFullNameByUseridGETResponse(userId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetUserFullNameByEntityByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/FullNameByEntity/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetUserFullNameByEntityByEntityidGET(entityId: string): __Observable<string> {
    return this.GetUserFullNameByEntityByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param entityId undefined
   * @return Success
   */
  GetEntityUserInfoByEntityidGETResponse(entityId: string): __Observable<__StrictHttpResponse<EntityUserInfoDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetEntityUserInfo/${entityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EntityUserInfoDto>;
      })
    );
  }
  /**
   * @param entityId undefined
   * @return Success
   */
  GetEntityUserInfoByEntityidGET(entityId: string): __Observable<EntityUserInfoDto> {
    return this.GetEntityUserInfoByEntityidGETResponse(entityId).pipe(
      __map(_r => _r.body as EntityUserInfoDto)
    );
  }
  LogUserLoginPOSTResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User/LogUserLogin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  LogUserLoginPOST(): __Observable<null> {
    return this.LogUserLoginPOSTResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param emailAddress undefined
   * @return Success
   */
  FacebookUserIdByEmailaddressGETResponse(emailAddress: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/FacebookUserId/${emailAddress}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param emailAddress undefined
   * @return Success
   */
  FacebookUserIdByEmailaddressGET(emailAddress: string): __Observable<string> {
    return this.FacebookUserIdByEmailaddressGETResponse(emailAddress).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  GetUserDealersGETResponse(): __Observable<__StrictHttpResponse<UserDealerModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetUserDealers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDealerModel>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUserDealersGET(): __Observable<UserDealerModel> {
    return this.GetUserDealersGETResponse().pipe(
      __map(_r => _r.body as UserDealerModel)
    );
  }

  /**
   * @param countryId undefined
   * @return Success
   */
  GetUserIsMetricByCountryidGETResponse(countryId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetUserIsMetric/${countryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param countryId undefined
   * @return Success
   */
  GetUserIsMetricByCountryidGET(countryId: number): __Observable<boolean> {
    return this.GetUserIsMetricByCountryidGETResponse(countryId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `UserClientService.CheckUserIPGETParams` containing the following parameters:
   *
   * - `userName`:
   *
   * - `isMobile`:
   *
   * - `ipAddress`:
   *
   * - `dealerIds`:
   *
   * @return Success
   */
  CheckUserIPGETResponse(params: UserClientService.CheckUserIPGETParams): __Observable<__StrictHttpResponse<CheckUserIPResultDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userName != null) __params = __params.set('userName', params.userName.toString());
    if (params.isMobile != null) __params = __params.set('isMobile', params.isMobile.toString());
    if (params.ipAddress != null) __params = __params.set('ipAddress', params.ipAddress.toString());
    (params.dealerIds || []).forEach(val => {if (val != null) __params = __params.append('dealerIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/CheckUserIP`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckUserIPResultDto>;
      })
    );
  }
  /**
   * @param params The `UserClientService.CheckUserIPGETParams` containing the following parameters:
   *
   * - `userName`:
   *
   * - `isMobile`:
   *
   * - `ipAddress`:
   *
   * - `dealerIds`:
   *
   * @return Success
   */
  CheckUserIPGET(params: UserClientService.CheckUserIPGETParams): __Observable<CheckUserIPResultDto> {
    return this.CheckUserIPGETResponse(params).pipe(
      __map(_r => _r.body as CheckUserIPResultDto)
    );
  }

  /**
   * @return Success
   */
  IsWhiteSalePartnerGETResponse(): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/IsWhiteSalePartner`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @return Success
   */
  IsWhiteSalePartnerGET(): __Observable<boolean> {
    return this.IsWhiteSalePartnerGETResponse().pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ByIdGETResponse(id: number): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ByIdGET(id: number): __Observable<UserDto> {
    return this.ByIdGETResponse(id).pipe(
      __map(_r => _r.body as UserDto)
    );
  }

  /**
   * @param id undefined
   */
  ByIdDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/User/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ByIdDELETE(id: number): __Observable<null> {
    return this.ByIdDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGETResponse(ids?: Array<number>): __Observable<__StrictHttpResponse<Array<UserDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/User/GetMany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserDto>>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetManyGET(ids?: Array<number>): __Observable<Array<UserDto>> {
    return this.GetManyGETResponse(ids).pipe(
      __map(_r => _r.body as Array<UserDto>)
    );
  }

  /**
   * @param data undefined
   */
  PUTResponse(data?: UserDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  PUT(data?: UserDto): __Observable<null> {
    return this.PUTResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  POSTResponse(data?: UserDto): __Observable<__StrictHttpResponse<UserDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/User`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserDto>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  POST(data?: UserDto): __Observable<UserDto> {
    return this.POSTResponse(data).pipe(
      __map(_r => _r.body as UserDto)
    );
  }
}

module UserClientService {

  /**
   * Parameters for CheckUserIPGET
   */
  export interface CheckUserIPGETParams {
    userName?: string;
    isMobile?: boolean;
    ipAddress?: string;
    dealerIds?: Array<number>;
  }
}

export { UserClientService }
