"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var locale_currency_pipe_1 = require("../../../../filters/locale-currency.pipe");
var CashPaidComponent = /** @class */ (function () {
    function CashPaidComponent(elementRef, injector, localeCurrency) {
        this.localeCurrency = localeCurrency;
    }
    return CashPaidComponent;
}());
exports.CashPaidComponent = CashPaidComponent;
