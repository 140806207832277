
/* injects from baggage-loader */
require('./customer-engagements-by-entity-modal.component.html');

import angular from 'angular';
import {OpportunitiesModule} from '../../core/opportunities.module'
import { EngagementEventTypes, EngagementEventGroups } from '../../constants/engagement-event-types.constant';

OpportunitiesModule.component('customerEngagementsByEntityModal', {
    templateUrl: '/app/components/header/customer-engagements-by-entity-modal.component.html',
    controller: CustomerEngagementsByEntityModalController,
    bindings: {
        resolve: '<', // { engagements, customerFullName }
        close: '&',
    },
});

function CustomerEngagementsByEntityModalController(customerEngagementService) {
    "ngInject";
    const $ctrl = this;

    $ctrl.iconClassForReviewRating = iconClassForReviewRating;
    $ctrl.engagementEventGroups = EngagementEventGroups;

    function iconClassForReviewRating(onlineReviewRating) {
        return 'rating-' + onlineReviewRating + 'star';
    }

}
