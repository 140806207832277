
/* injects from baggage-loader */
require('./hot-trade-details-modal.component.html');

import angular from 'angular';
import './hot-trade-details-modal.component.scss';

angular.module('AaApp.Opp').component('hotTradeDetailsModal', {
    templateUrl: '/app/components/deal-sheet/modals/hot-trade-details-modal.component.html',
    controller: HotTradeDetailsModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

function HotTradeDetailsModalController() {
    var $ctrl = this;
    $ctrl.titleText;
    $ctrl.hotTrade;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.titleText = getTitle($ctrl.resolve.renderedPromise.vehicle);
        $ctrl.hotTrade = $ctrl.resolve.renderedPromise.hotTrade;
    }

    $ctrl.validationCheck = function (isValid) {
        $ctrl.validFinancials = isValid;
    }

    function getTitle(vehicle) {        
        return vehicle.yearID + '&nbsp;' + vehicle.makeName + '&nbsp;' + vehicle.trimFullName;
    }
}
