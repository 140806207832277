export const ApiActivityActions = {
    postNote: 1,
    logPhoneCall: 2,
    schedulePhoneCall: 3,
    scheduleAppointment: 4,
    completePhoneCall: 5,
    completeAppointment: 6,
    expirePhoneCall: 7,
    expireAppointment: 8,
    sendEmail: 9,
    reschedulePhoneCall: 10,
    rescheduleAppointment: 11,
    reassignPhoneCall: 12,
    reassignAppointment: 13,
    cancelPhoneCall: 14,
    cancelAppointment: 15,
    sendToCompass: 16,
    sendEmailTemplate: 17,
    sendToCrm: 18
};
