import angular from 'angular';

angular.module('AaApp.Opp').filter('localeTime', localeTimeFilter);

localeTimeFilter.$inject = ['$filter', 'storageService'];

/* @ngInject */
function localeTimeFilter($filter, storageService) {
    return function (date) {
        var savedCultureName = storageService.getItem('cultureName');
        var cultureName = savedCultureName ? savedCultureName : 'en-us';

        switch (cultureName.toLowerCase()) {
            case 'en-gb':
                return $filter('date')(date, 'hh:mm a');
            case 'en-ca':
                return $filter('date')(date, 'h:mm a');
            case 'fr-ca':
                return $filter('date')(date, 'hh:mm a');
            case 'fr-fr':
                return $filter('date')(date, 'HH:mm');
            case 'en-us':
            default:
                return $filter('date')(date, 'h:mm a');
        }
    }
}
