"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var trade_value_types_1 = require("../../../../constants/trade-value-types");
var dealer_countries_1 = require("../../../../constants/dealer-countries");
var services_1 = require("../../../../generated/services");
var TradeSourceLogoComponent = /** @class */ (function () {
    function TradeSourceLogoComponent(elementRef, injector, vehicleUserTradeValueClientService) {
        this.vehicleUserTradeValueClientService = vehicleUserTradeValueClientService;
        this.tradeValueTypes = trade_value_types_1.TradeValueTypes;
        this.dealerCountries = dealer_countries_1.DealerCountries;
        this.tradeValueIsManual = false;
        this.tradeValueInsertDt = '';
        this.saveVehicleId = 0;
    }
    TradeSourceLogoComponent.prototype.ngOnInit = function () { };
    TradeSourceLogoComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.vehicle && this.vehicle.vehicleID != this.saveVehicleId) {
            this.tradeValueIsManual = false;
            this.saveVehicleId = this.vehicle.vehicleID;
            this.vehicleUserTradeValueClientService.GetVehicleUserTradeValue(this.vehicle.vehicleID).toPromise().then(function (vutv) {
                if (vutv && vutv.tradeValue > 0) {
                    var expirationDt = Date.parse(vutv.expirationDt);
                    if (isNaN(expirationDt) == false) {
                        var expDt = new Date(expirationDt).getTime();
                        var currDt = Date.now();
                        if (expDt > currDt) {
                            _this.tradeValueIsManual = true;
                            var date = vutv.insertDt.split('T')[0];
                            var dateParts = date.split('-');
                            _this.tradeValueInsertDt = dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0];
                        }
                    }
                }
            });
        }
    };
    TradeSourceLogoComponent.prototype.isCanada = function () {
        return this.countryId === this.dealerCountries.canada;
    };
    return TradeSourceLogoComponent;
}());
exports.TradeSourceLogoComponent = TradeSourceLogoComponent;
