"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".modal-header[_ngcontent-%COMP%]{background:-webkit-gradient(linear,left top,right top,from(#172751),to(#1f8bd5)) fixed;background:linear-gradient(to right,#172751,#1f8bd5) fixed;color:#fff;border-radius:5px 5px 0 0;text-align:left;margin:0;display:block;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;padding:10px}.invalidField[_ngcontent-%COMP%]{color:Red;margin-left:5px}.roleSelectContainer[_ngcontent-%COMP%]{margin:10px 0}.startWithDefaultLabel[_ngcontent-%COMP%]{margin-left:6px}.startWithParentContainer[_ngcontent-%COMP%]{margin-top:10px}.fieldInvalid[_ngcontent-%COMP%]{border-color:red}.close[_ngcontent-%COMP%]{color:#fff;opacity:1}.popoverHeader[_ngcontent-%COMP%]{font-weight:700}.tooltipContainer[_ngcontent-%COMP%]{float:left;margin-left:5px}.txtRoleName[_ngcontent-%COMP%]{width:200px}.roleSelect[_ngcontent-%COMP%]{width:200px;height:32px}label[_ngcontent-%COMP%]{color:#444a50}"];
exports.styles = styles;
