
/* injects from baggage-loader */
require('./deal-sheet-vci-eula-modal.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('dealSheetVciEulaModal', {
    templateUrl: '/app/components/deal-sheet/deal-sheet-vci-eula-modal.component.html',
    controller: DealSheetVciEulaModalController,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    }
});

/* @ngInject */
function DealSheetVciEulaModalController() {
    var $ctrl = this;

    $ctrl.doNotShow;
    $ctrl.$onInit = init;

    function init() {
        $ctrl.doNotShow = false;
    }
}

