import angular from 'angular';

angular.module('AaApp.Opp').filter('trim', trimFilter);

/* @ngInject */
function trimFilter() {
    return function (input, chars) {
        var trim = chars || '\\s';

        if (typeof input != 'string') {
            return input;
        }

        return input.replace(new RegExp('^' + trim + '+|' + trim + '+$', 'g'), '');
    };
}
