import { Inject, Injectable } from '@angular/core';
import { catchError, flatMap, map, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PandoGroupInfo } from '../interfaces/pando-group-info.interface';
import { StartListCustomerDto } from "../generated/models/start-list-customer-dto";
import { CustomerWorkflowClientService } from "../generated/services";
import { ToasterLoggerService } from '../core/toaster-logger.service';
import { PandoFormRequestDto } from "../generated/models/pando-form-request-dto";
import { PandoFormDto } from "../generated/models/pando-form-dto";
import { PandoForm } from'../interfaces/pando-form.interface';


@Injectable()
export class CustomerWorkflowService {
    constructor(public customerWorkflowClient: CustomerWorkflowClientService, public toaster: ToasterLoggerService) {

    }

    public getStartListCustomer(entityId: string): Promise<StartListCustomerDto> {
        return this.customerWorkflowClient.GetStartListCustomerByEntityidGET(entityId).toPromise();
    }

    public GetStartFormByStartableformtemplateid(startableFormTemplateId: string): Promise<PandoFormDto> {
        return this.customerWorkflowClient.GetStartFormByStartableformtemplateidGET(startableFormTemplateId).toPromise();

            //.pipe(map(result => {
            //    return this.resolvePandoForm(result);
            //})).toPromise();

    }

    //private resolvePandoForm(pandoForm: PandoFormDto): PandoForm {

    //    const resolvedForm = {

    //        formTitle: pandoForm.formTitle,
    //        formTemplateId: pandoForm.formTemplateId,
    //        formControls: pandoForm.formControls,
    //    };

    //    return resolvedForm;
    //}





    public startCustomerWorkflowByEntityidByWorkflowtemplateid(entityId: string, workflowTemplateId: string, worflowForm?: PandoFormRequestDto): Promise<any> {

        const params: CustomerWorkflowClientService.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOSTParams = {
            workflowTemplateId: workflowTemplateId,
            entityId: entityId,
            form: worflowForm
        };

        return this.customerWorkflowClient.StartCustomerWorkflowByEntityidByWorkflowtemplateidPOST(params).toPromise();   
    }
}
