<span *ngIf="widgetData && widgetData.length == 0" translate="noInformationAvailable"></span>
<table class="table table-hover" *ngIf="widgetData && widgetData.length > 0">
    <thead>
        <tr *ngIf="widgetTitleKey == 'newOpportunityChangesWidgetTitle'">
            <th translate="score"></th>
            <th translate="clientName"></th>
            <th translate="assignedTo"></th>
            <th translate="lblDateChanged"></th>
            <th translate="opportunities"></th>
            <th translate="lblLastMarketedDate"></th>
            <th translate="lblLastContactDate"></th>
            <th translate="lblLastRO"></th>
            <th translate="lblCurrentYear"></th>
            <th translate="lblCurrentModel"></th>
            <th translate="lblPaymentDifference"></th>
            <th translate="lblCurrentPayment"></th>
            <th translate="customerZipCode"></th>
        </tr>

        <tr *ngIf="widgetTitleKey != 'newOpportunityChangesWidgetTitle'">
            <th *ngIf="columnTypes.includes('customerEngagement') && !columnTypes.includes('reviewRating')" translate="hot"></th>
            <th *ngIf="columnTypes.includes('opportunity') && !columnTypes.includes('reviewRating') && !columnTypes.includes('geoAlert')" translate="score"></th>
            <th *ngIf="columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate')" translate="lblTransactionType"></th>
            <th *ngIf="columnTypes.includes('groupEngagement')" translate="type"></th>
            <th *ngIf="columnTypes.includes('reviewRating')" translate="reviewRating"></th>
            <th *ngIf="columnTypes.includes('reviewRating')" translate="reviewType"></th>
            <th *ngIf="columnTypes.includes('customerEngagement')" translate="date"></th>
            <th *ngIf="columnTypes.includes('upcoming')" translate="time"></th>
            <th *ngIf="columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate')" translate="lblLastRO"></th>
            <th *ngIf="columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert')" translate="lblPurchaseDate"></th>
            <th *ngIf="columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate') || widgetTitleKey == 'oneToOne'" translate="lblLastMarketedDate"></th>

            <th *ngIf="columnTypes.includes('customerEngagement')" translate="owner"></th>

            <th *ngIf="columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert')" translate="advisorLable"></th>

            <th *ngIf="columnTypes.includes('opportunity')" translate="clientName"></th>

            <th *ngIf="columnTypes.includes('apptOrRoDate')" translate="lastRO"></th>
            <th *ngIf="columnTypes.includes('responsibleUserFullName')" translate="assignedTo"></th>
            <th *ngIf="columnTypes.includes('assignedDate')" translate="assignedOnDate"></th>

            <th *ngIf="columnTypes.includes('alertBlocks') && widgetTitleKey != 'upcomingAppointments'" translate="opportunities"></th>

            <th *ngIf="columnTypes.includes('retail')" translate="lblTransactionType"></th>
            <th *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')" translate="lblPaymentsRemaining"></th>
            <th *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')" translate="lblLastMarketedDate"></th>
            <th *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')" translate="owner"></th>
            <th *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')" translate="advisorLable"></th>

            <th *ngIf="columnTypes.includes('opportunity')" translate="lblCurrentYear"></th>
            <th *ngIf="columnTypes.includes('opportunity')" translate="lblCurrentModel"></th>

            <th *ngIf="columnTypes.includes('web') || columnTypes.includes('assignedDate')" translate="lblReplacementYear"></th>
            <th *ngIf="columnTypes.includes('web') || columnTypes.includes('assignedDate')" translate="lblReplacementModel"></th>

            <th *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')" translate="mileageLabel"></th>
            <th *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')" translate="customerZipCode"></th>

            <th *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext') || columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate')" translate="mileageLabel"></th>
            <th *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext') || columnTypes.includes('web') || columnTypes.includes('web')" translate="lblTransactionType"></th>
            <th *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext')" translate="lblCurrentPayment"></th>
            <th *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext')" translate="lblReplacementPayment"></th>
            <th *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext') || columnTypes.includes('reviewRating')" translate="lblLastRO"></th>

            <th *ngIf="columnTypes.includes('web')" translate="lblPaymentDifference"></th>

            <th *ngIf="columnTypes.includes('reviewRating')" translate="customerEmail"></th>
            <th *ngIf="columnTypes.includes('reviewRating')" translate="phoneGroup"></th>
            <th *ngIf="columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert')" translate="customerZipCode"></th>
            <th *ngIf="columnTypes.includes('assignedDate')" translate="lblAssignedBy"></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="widgetTitleKey == 'newOpportunityChangesWidgetTitle'">
            <tr class="cursor" *ngFor="let opportunity of sortedWidgetData "
                [ngClass]="{'light': opportunity.worked === true}"
                (click)="openDealSheet(opportunity.entityID || opportunity.opportunityId);">

                <td *ngIf="columnTypes.includes('opportunity')" class="entityscore">
                    <span *ngIf="opportunity.opportunityRank !== undefined">{{opportunity.opportunityRank}}</span>
                    <span *ngIf="opportunity.entityScore !== undefined">{{opportunity.entityScore}}</span>
                </td>
                <td *ngIf="columnTypes.includes('opportunity')">
                    {{opportunity.customerFullName}}&nbsp;
                    <span *ngIf="opportunity.totalCount > 1" class="engagementcount"
                          [translateParams]="{totalCount: opportunity.totalCount}" translate="engagementCount"></span>
                </td>

                <td *ngIf="columnTypes.includes('responsibleUserFullName')">{{opportunity.responsibleUserFullName}}</td>
                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.dateChanged | localeDate}}</td>
                <td *ngIf="columnTypes.includes('alertBlocks')">
                    <alerts [values]="opportunity.detailedAlerts" [showRelavantInfo]="false"></alerts>
                </td>
                <td>{{opportunity.lastMarketedDate | localeDate}}</td>
                <td>{{opportunity.performedOn | localeDate}}</td>
                <td>{{opportunity.openDate | localeDate}}</td>

                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.yearID}}</td>
                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.modelName}}</td>
                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.paymentDifference == null ? "" : opportunity.paymentDifference | localeCurrency}}</td>
                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.currentPayment == 0 || opportunity.currentPayment == null ? "" : opportunity.currentPayment | localeCurrency}}</td>
                <td>{{opportunity.zipCode}}</td>
            </tr>
        </ng-container>
        <ng-container *ngIf="widgetTitleKey != 'newOpportunityChangesWidgetTitle'">
            <tr class="cursor" *ngFor="let opportunity of sortedWidgetData "
                [ngClass]="{'light': opportunity.worked === true}"
                (click)="openDealSheet(opportunity.entityID || opportunity.opportunityId);">

                <td *ngIf="columnTypes.includes('customerEngagement') && !columnTypes.includes('reviewRating')">
                    <icon icon="hot-flame" class="-size-18-svg -brightblue" *ngIf="opportunity.highEngagement"></icon>
                </td>

                <td *ngIf="columnTypes.includes('opportunity') && !columnTypes.includes('reviewRating') && !columnTypes.includes('geoAlert')" class="entityscore">
                    <span *ngIf="opportunity.opportunityRank !== undefined">{{opportunity.opportunityRank}}</span>
                    <span *ngIf="opportunity.entityScore !== undefined">{{opportunity.entityScore}}</span>
                </td>

                <td *ngIf="columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate')">{{opportunity.new_Used}}</td>

                <td *ngIf="columnTypes.includes('groupEngagement')">

                    <icon class="icon aa-icon-core-email-icon-000000 -size-18 -brightblue"
                          *ngIf="opportunity.engagementEventGroupID == engagementEventGroups.email"></icon>
                    <icon icon="sms" class="-size-18-svg -brightblue"
                          *ngIf="opportunity.engagementEventGroupID == engagementEventGroups.text"></icon>
                    <icon icon="auto-assistant" class="-size-18-svg -brightblue"
                          *ngIf="opportunity.engagementEventGroupID == engagementEventGroups.autoAssistant"></icon>
                    <icon icon="o2o-offer" class="-size-18-svg -brightblue"
                          *ngIf="opportunity.engagementEventGroupID == engagementEventGroups.one2one"></icon>
                    <icon class="im-offer-arrow-icon-blue -brightblue"
                          *ngIf="opportunity.engagementEventGroupID == engagementEventGroups.intelligentMarketing"></icon>
                </td>
                <td *ngIf="columnTypes.includes('reviewRating')">
                    <icon title="{{opportunity.ratingTitle | translate}}" [icon]="opportunity.ratingIcon"
                          class="-brightblue -size-rating40-svg">
                    </icon>
                </td>
                <td *ngIf="columnTypes.includes('reviewRating')">
                    <span *ngIf="opportunity.engagementEventTypeID === engagementEventTypes.onlineServiceReview">{{'serviceReview' | translate}}</span>
                    <span *ngIf="opportunity.engagementEventTypeID === engagementEventTypes.onlineSalesReview">{{'salesReview' | translate}}</span>
                </td>
                <td *ngIf="columnTypes.includes('customerEngagement')">
                    <span *ngIf="opportunity.eventDate">{{opportunity.eventDate | localeDate}}</span>
                    <span *ngIf="opportunity.appointmentDate">{{opportunity.appointmentDate | localeDate}}</span>
                    <span *ngIf="opportunity.dueDate">{{opportunity.dueDate | localeDate}}</span>
                </td>
                <td *ngIf="columnTypes.includes('upcoming')">
                    <span *ngIf="opportunity.eventDate">{{opportunity.eventDate | localeTime}}</span>
                    <span *ngIf="opportunity.appointmentDate">{{opportunity.appointmentDate | localeTime}}</span>
                    <span *ngIf="opportunity.dueDate">{{opportunity.dueDate | localeTime}}</span>
                </td>

                <td *ngIf="columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate')">{{opportunity.openDate | localeDate}}</td>

                <td *ngIf="columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert')">{{opportunity.deal_Date | localeDate}}</td>
                <td *ngIf="columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate') || widgetTitleKey == 'oneToOne'">{{opportunity.lastMarketedDate | localeDate}}</td>

                <td *ngIf="columnTypes.includes('customerEngagement')">{{opportunity.ownerFullName}}</td>

                <td *ngIf="columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert')">{{opportunity.serviceAdvisorName}}</td>

                <td *ngIf="columnTypes.includes('opportunity')">
                    {{opportunity.customerFullName}}&nbsp;
                    <span *ngIf="opportunity.totalCount > 1" class="engagementcount"
                          [translateParams]="{totalCount: opportunity.totalCount}" translate="engagementCount"></span>
                </td>

                <td *ngIf="columnTypes.includes('apptOrRoDate')">
                    <span class="value"
                          *ngIf="opportunity.isAppointment && !opportunity.isService">{{opportunity.roAppointmentDate | localeDate}}</span>
                    <span class="value"
                          *ngIf="!(opportunity.isAppointment && !opportunity.isService)">{{opportunity.roDate | localeDate}}</span>
                </td>
                <td *ngIf="columnTypes.includes('responsibleUserFullName')">{{opportunity.responsibleUserFullName}}</td>
                <td *ngIf="columnTypes.includes('assignedDate')">{{opportunity.assignedDate | localeDateTime}}</td>

                <td *ngIf="columnTypes.includes('alertBlocks') && widgetTitleKey != 'upcomingAppointments'">
                    <alerts [values]="opportunity.detailedAlerts" [showRelavantInfo]="false"></alerts>
                </td>

                <td *ngIf="columnTypes.includes('retail')">{{opportunity.newUsed}}</td>
                <td *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')">{{opportunity.paymentRemaining | localeNumber}}</td>
                <td *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')">{{opportunity.lastMarketedDate | localeDate}}</td>
                <td *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')">{{opportunity.responsibleUserFullName}}</td>
                <td *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')">{{opportunity.serviceAdvisorName}}</td>

                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.yearID}}</td>
                <td *ngIf="columnTypes.includes('opportunity')">{{opportunity.modelName}}</td>

                <td *ngIf="columnTypes.includes('web') || columnTypes.includes('assignedDate')">{{opportunity.replacement_Model_Year}}</td>
                <td *ngIf="columnTypes.includes('web') || columnTypes.includes('assignedDate')">{{opportunity.replacement_Model_Name}}</td>

                <td *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')">{{opportunity.estimatedCurrentMileage | localeNumber}}</td>
                <td *ngIf="columnTypes.includes('retail') || columnTypes.includes('lease')">{{opportunity.zipCode}}</td>

                <td *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext') || columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert') || columnTypes.includes('assignedDate')">{{opportunity.estimated_Current_Mileage | localeNumber}}</td>
                <td *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext') || columnTypes.includes('web')">{{opportunity.new_Used}}</td>
                <td *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext')">{{opportunity.current_Payment == 0 || opportunity.current_Payment == null ? "" : opportunity.current_Payment | localeCurrency}}</td>
                <td *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext')">{{opportunity.replacement_Payment == 0 || opportunity.replacement_Payment == null ? "" : opportunity.replacement_Payment | localeCurrency}}</td>
                <td *ngIf="columnTypes.includes('service') || columnTypes.includes('emailtext') || columnTypes.includes('reviewRating')">{{opportunity.openDate | localeDate}}</td>

                <td *ngIf="columnTypes.includes('web')">{{opportunity.payment_Difference == null ? "" : opportunity.payment_Difference | localeCurrency}}</td>

                <td *ngIf="columnTypes.includes('reviewRating')" title="{{opportunity.email}}">{{opportunity.email}}</td>
                <td *ngIf="columnTypes.includes('reviewRating')">{{opportunity.home_Phone | localePhone}}</td>
                <td *ngIf="columnTypes.includes('reviewRating') || columnTypes.includes('geoAlert')">{{opportunity.zip_Code}}</td>
                <td *ngIf="columnTypes.includes('assignedDate')">{{opportunity.assignedBy}}</td>
            </tr>
        </ng-container>
    </tbody>
</table>