<img *ngIf="showCarProofLink && readonly" src="../../../images/carfaxcanada.png" class="carproof-logo"/>
<a *ngIf="showCarProofLink && !readonly" 
  href="javascript:void(0)" 
  uiSref = "carProofReport" 
  [uiParams] = "{ opportunityId: opportunityId, vin: vin }" 
  target="_blank" 
  class="qa-carproof-link">
    <img src="../../../images/carfaxcanada.png" class="carproof-logo"/>
</a>

