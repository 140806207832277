
/* injects from baggage-loader */
require('./search-preset-settings.component.html');

import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import './search-manage-presets.component.scss';

angular.module('AaApp.Opp').component('searchPresetSettings', {
    templateUrl: '/app/components/search/search-preset-settings.component.html',
    controller: SearchPresetSettingsController,
    bindings: {
        getSelections: '=',
        onSelection: '&',
    }
});

/* @ngInject */
function SearchPresetSettingsController($q, $translate, $timeout, searchPresetTypes, searchPresetService, dealerService, pandoSurveyService, tokenService, userService, modules) {
    var $ctrl = this;

    $ctrl.$onInit = init;
    $ctrl.clearOwnership;
    $ctrl.clearAccess;
    $ctrl.getSelections = getSelections;
    $ctrl.selectionsChanged = selectionsChanged;

    function init() {
        $ctrl.hasSelections = false;
    }

    function getSelections() {

        return {
            clearOwnership: $ctrl.clearOwnership === undefined ? false : $ctrl.clearOwnership, 
            clearAccess: $ctrl.clearAccess === undefined ? false : $ctrl.clearAccess
        };
    }

    function selectionsChanged() {
        $ctrl.hasSelections = ($ctrl.clearOwnership === true || $ctrl.clearAccess === true);
        onSelection();
    }

    function onSelection() {
        $ctrl.onSelection({ hasSelections: $ctrl.hasSelections });
    }

}
