// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'fuel-difference',
//   templateUrl: './fuel-difference.component.html',
//   styleUrls: ['./fuel-difference.component.scss']
// })
// export class FuelDifferenceComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }


import { Directive, ElementRef, Injector, Input, Output, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'fuel-difference'
})
export class FuelDifferenceComponent extends UpgradeComponent {
    @Input() fuelEconomyComparison: any;
    @Output() onClick = new EventEmitter<any>();

    constructor(elementRef: ElementRef, injector: Injector) {
        super('fuelDifference', elementRef, injector);
    }
}