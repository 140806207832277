import { Component, ElementRef, Injector, Input } from '@angular/core';
import { LocaleNumberPipe } from '../../../../filters/locale-number.pipe';

@Component({
    selector: 'annual-percentage-rate',
    templateUrl: './annual-percentage-rate.component-ng.html',
    styleUrls: ['./annual-percentage-rate.component.scss']
})
export class AnnualPercentageRateComponent {
    @Input() value: any;

    constructor(elementRef: ElementRef, injector: Injector, public localeNumber: LocaleNumberPipe) {
    }
}