"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modules = {
    dashboard: 2,
    opportunities: 3,
    search: 4,
    activities: 5,
    conquests: 29,
    preOwnedManager: 32,
    serviceManager: 33,
    financeInsuranceManager: 41,
    creditConvert: 48,
    pandoIntegration: 73,
    fordAlert: 74,
    dealerPrincipalDashboard: 78,
    reports: 9,
    fordAXZPlanPricing: 80,
    pandoWorkflowIntegration: 82,
    managerDashboard: 83,
    euroliteOpportunities: 84,
    dataCleanse: 85,
    oneToOneSoldUnitsReport: 86,
    leadAssignOwnerAccess: 87,
    portalSearchPage: 88,
    textMessaging: 89,
    doNotEmail: 70,
    doNotCall: 30,
    intelligentMarketingSoldUnitsReport: 90,
    autoAssistantSoldUnitsReport: 92,
    geoAlertSoldUnitsReport: 91,
    wishlist: 95
};
