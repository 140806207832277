rateTypeSelectController.$inject = ["vehicleTypes"];

/* injects from baggage-loader */
require('./rate-type-select.component.html');

import angular from 'angular';
angular.module('AaApp.Opp').component('rateTypeSelect', {
  templateUrl: '/app/components/deal-sheet/contract/rate-type-select.component.html',
  controller: rateTypeSelectController,
  bindings: {
    'default': '<',
    onSelect: '&'
  }
});
/* @ngInject */

function rateTypeSelectController(vehicleTypes) {
  var $ctrl = this;
  $ctrl.$onChanges = onChanges;
  $ctrl.vehicleTypes = vehicleTypes;
  $ctrl.onSelectionChange = onSelectionChange;

  function onChanges(changes) {
    if (changes.default && changes.default.currentValue) {
      $ctrl.selectedRateType = $ctrl.default;
    }
  }

  function onSelectionChange() {
    if ($ctrl.onSelect) {
      $ctrl.onSelect({
        rateType: $ctrl.selectedRateType
      });
    }
  }
}