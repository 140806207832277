<div class="row">
    <div class="col-md-10">
        <div class="settingTitle" translate="dsAdmin_bankRatesTierResiduals"></div>
        <tooltip [titleKey]="'dsAdmin_bankRatesTierResiduals'" [contentKey]="'dsAdmin_bankRatesTierResidualsTooltip'"></tooltip>
        <div *ngFor="let item of dsa.dealerBankRateTier" class="row settingItem">
            <div class="col-md-2" style="margin-top:3px">
                {{ item.makeName }} / {{ item.bankName }}
            </div>
            <div class="col-md-1" style="margin-top:3px">
                <label><input [disabled]="dsa.isFordAlert" [title]="dsa.isFordAlert ? ('dsAdmin_valueSetByFormProgram' | translate) : ''" name="tier{{ item.dealerMakeRegionID }}" type="radio" (change)="item.tier = 0" [checked]="item.tier == 0" /> {{ item.tier0 }}</label>
            </div>
            <div class="col-md-1" style="margin-top:3px">
                <label><input [disabled]="dsa.isFordAlert" [title]="dsa.isFordAlert ? ('dsAdmin_valueSetByFormProgram' | translate) : ''" name="tier{{ item.dealerMakeRegionID }}" type="radio" (change)="item.tier = 1" [checked]="item.tier == 1" /> {{ item.tier1 }}</label>
            </div>
            <div class="col-md-1" style="margin-top:3px">
                <label><input [disabled]="dsa.isFordAlert" [title]="dsa.isFordAlert ? ('dsAdmin_valueSetByFormProgram' | translate) : ''" name="tier{{ item.dealerMakeRegionID }}" type="radio" (change)="item.tier = 2" [checked]="item.tier == 2" /> {{ item.tier2 }}</label>
            </div>
            <div class="col-md-2">
                <div style="float:left;margin: 3px 5px 0 0" translate="residualMileage"></div>
                <div style="float:left">
                    <select class="form-control" [(ngModel)]="item.residualMileage">
                        <option *ngFor="let rmo of getResidualMileageOptions(item.bankID)" value="{{ rmo.value }}">{{ rmo.text }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3" style="margin-top: 4px;">           
                {{ 'dsAdmin_mileagePenaltyPerMileLabel' | translate }} <input [(ngModel)]="item.leasePenaltyFee" (change)="handleLeasePenaltyFeeChanged(item);" class="input-sm textboxMed" [disabled]="dsa.isFordAlert && [100,101,326,340].includes(item.makeID)" type="number"/> {{ 'permile' | translate }}
            </div>
        </div>
        <div class="settingItem">
            <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.useResidualMileageOverride" /> {{ 'dsAdmin_residualMileageOverride' | translate }}</label>
        </div>
        <div class="settingItem" *ngIf="dsa.dealerSetting.useResidualMileageOverride">
            {{ 'dsAdmin_usedResidualMileageOverrideMessage' | translate }} $ <input name="DealerSetting.ResidualCentsPerMile" [disabled]="true" type="number" class="input-sm textbox" style="width:80px" [(ngModel)]="dsa.dealerSetting.residualCentsPerMile" /> {{ 'permile' | translate }}
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="salestax"></div>
        <tooltip [titleKey]="'salestax'" [contentKey]="'dsAdmin_salestaxTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-3">
                {{ 'dsAdmin_leaseSalesTaxPercent' | translate }} <input name="DealerSetting.LeaseSalesTax" type="number" class="input-sm textboxMed" style="width:80px" [(ngModel)]="dsa.dealerSetting.leaseSalesTax" />
            </div>
            <div class="col-md-3">
                {{ 'dsAdmin_PurchaseSalesTaxPercent' | translate }} <input name="DealerSetting.PurchaseSalesTax" type="number" class="input-sm textboxMed" style="width:80px" [(ngModel)]="dsa.dealerSetting.purchaseSalesTax" />
            </div>
        </div>
        <div class="settingItem" *ngIf="!dsa.dealerSetting.useSalesTax" translate="dsAdmin_SalesTaxDisabledMessage">
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="documentFees"></div>
        <tooltip [titleKey]="'documentFees'" [contentKey]="'dsAdmin_documentFeesTooltip'"></tooltip>
        <div class="settingItem">
            {{ 'documentpreparationFees' | translate }} <input name="DealerSetting.DocFee" type="number" class="input-sm textbox" style="width:90px" [(ngModel)]="dsa.dealerSetting.docFee" />
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="rates"></div>
        <tooltip [titleKey]="'rates'" [contentKey]="'dsAdmin_ratesTooltip'"></tooltip>
        <div class="settingItem">
            <label><input type="checkbox" [(ngModel)]="dsa.dealerSetting.enableDealerUsedRates" /> {{ 'dsAdmin_useDealerSpecificUsedCarRates' | translate }}</label>
        </div>
        <div class="sectionSpacer"></div>
        <div class="settingTitle" translate="fuelcosts"></div>
        <tooltip [titleKey]="'fuelcosts'" [contentKey]="'dsAdmin_fuelcostsTooltip'"></tooltip>
        <div class="row settingItem">
            <div class="col-md-1" translate="gasoline">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.GasolinePricePerGallon" type="number" class="input-sm textboxMed" [(ngModel)]="dsa.dealerSetting.gasolinePricePerGallon" /> {{ perUnitLabelKey }}
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-1" translate="diesel">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.DieselPricePerGallon" type="number" class="input-sm textboxMed" [(ngModel)]="dsa.dealerSetting.dieselPricePerGallon" /> {{ perUnitLabelKey }}
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-1" translate="cng">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.CngPricePerGallon" type="number" class="input-sm textboxMed" [(ngModel)]="dsa.dealerSetting.cngPricePerGallon" /> {{ perUnitLabelKey }}
            </div>
        </div>
        <div class="row settingItem">
            <div class="col-md-1" translate="electricity">
            </div>
            <div class="col-md-2">
                <input name="DealerSetting.ElectricityPricePerkWh" type="number" class="input-sm textboxMed" [(ngModel)]="dsa.dealerSetting.electricityPricePerkWh" /> {{ 'PerKWhLabel' | translate }}
            </div>
        </div>
    </div>
</div>
