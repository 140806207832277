import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, SimpleChanges } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Opportunity2Service } from '../../../services/opportunity2.service';
import { EntityScoreFeatureDto } from '../../../generated/models/entity-score-feature-dto';

@Component({
    selector: 'score-info-modal',
    templateUrl: './score-info-modal.component-ng.html',
    styleUrls: ['./score-info-modal.component.scss']
})

export class ScoreInfoModalComponent implements OnInit, AfterViewInit {
    @Input() entityId: string;

    public busy: Promise<any>;
    public features: any[];
    public featuresLoaded: boolean = false;
    public buyRate: number;
    private translations: any = {};

    private readonly MAX_RECORDS: number = 8;

    private readonly translationKeys = [
        'faalertletter',
        'flexAlertLetter',
        'inMarketLetter',
        'inMarketEngagedLetter',
        'contractEndLetter',
        'serviceLetter',
        'serviceAppointmentLetter',
        'mileageLetter',
        'warrantyLetter',
    ];

    private dataSource: any[] = [
        { id: "100", featureId: "006", alerts: "Alert", code: 'alert', description: 'Alert', translateKey: 'faalertletter' },
        { id: "200", featureId: "", alerts: "Flex", code: 'flexalert', description: 'Flex', translateKey: 'flexAlertLetter' },
        { id: "400", featureId: "", alerts: "In Market", code: 'inmarket', description: 'In Market', translateKey: 'inMarketLetter' },
        { id: "500", featureId: "019", alerts: "Engaged", code: 'inmarketengaged', description: 'Engaged', translateKey: 'inMarketEngagedLetter' },
        { id: "600", featureId: "022", alerts: "Contract End", code: 'contractend', description: 'Contract End', translateKey: 'contractEndLetter' },
        { id: "700", featureId: "018", alerts: "Service", code: 'service', description: 'Service', translateKey: 'serviceLetter' },
        { id: "800", featureId: "030", alerts: "Pending Service", code: 'appointment', description: 'Pending Service', translateKey: 'serviceAppointmentLetter' },
        { id: "900", featureId: "028", alerts: "Mileage", code: 'mileage', description: 'Mileage', translateKey: 'mileageLetter' },
        { id: "1000", featureId: "024", alerts: "Warranty", code: 'warranty', description: 'Warranty', translateKey: 'warrantyLetter' }
    ];

    constructor(
        public _bsModalRef: BsModalRef,
        private tService: TranslateService,
        private opportunity2Service: Opportunity2Service) {

        this.tService.get(this.translationKeys)
            .subscribe(translations => {
                this.translations = translations;
            });
    }

    public ngOnInit(): void {
        this._bsModalRef.hide();

        this.busy = this.opportunity2Service.getEntityScoreFeature(this.entityId)
            .then((data) => {
                this.features = data.slice(0, this.MAX_RECORDS).sort(this.byImportanceRank);

                this.features.forEach(item => {
                    let alert = null;
                    if (item.featureID != "") {
                        alert = this.getByFeatureId(item.featureID);
                    }
                    if (alert !== null) {
                        item.isAlert = true;
                        item.alertCode = alert.code;
                        item.alertCodeLetter = this.translations[alert.translateKey];
                    }
                    else {
                        item.isAlert = false;
                        let transKey = 'scoreFeature_' + item.featureID;
                        let description = this.tService.instant(transKey);

                        if (description && description != transKey) {
                            item.featureDescription = description;
                        }
                        else {
                            item.featureDescription = "";
                        }
                    }

                    item.posneg = "";
                    if (item.weight > 0) {
                        item.posneg = "+";
                    }
                    else if (item.weight < 0) {
                        item.posneg = "-";
                    }

                });

                this.featuresLoaded = true;

            });
    }

    public ngAfterViewInit(): void {
        this._bsModalRef.hide();
    }

    public cancelModal(): void {
        this._bsModalRef.hide();
    }

    private byImportanceRank(feature1: any, feature2: any) {
        return feature1.importanceRank - feature2.importanceRank;
    }


    private getByFeatureId(id): any {
        var alert = this.dataSource.find(item => item.featureId == id);
        if (typeof (alert) === 'undefined') return null;
        return alert;
    }

}

