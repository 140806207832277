import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CustomerContact } from '../../../../interfaces/customer-contact.interface';

@Component({
    selector: 'mobile-customer-email',
    templateUrl: './mobile-customer-email.component-ng.html',
    styleUrls: ['./mobile-customer-email.component.scss', '../customer-info/customer-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileCustomerEmailComponent implements OnInit {

    @Input() doNotEmail: boolean;
    @Input() email: CustomerContact;
    @Input() type: string;

    constructor() { }

    ngOnInit() {
    }
}
