"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modal_1 = require("ngx-bootstrap/modal");
var component_loader_1 = require("ngx-bootstrap/component-loader");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("ngx-bootstrap/component-loader"));
var NgxModalWrapperService = /** @class */ (function (_super) {
    __extends(NgxModalWrapperService, _super);
    function NgxModalWrapperService(renderFactory, clr) {
        var _this = _super.call(this, renderFactory, clr) || this;
        _this.renderFactory = renderFactory;
        _this.clr = clr;
        _this.isModalStacked = false;
        _this.onHide.subscribe(function () {
            if (_this.isModalStacked) {
                document.body.classList.add('modal-open');
            }
        });
        return _this;
    }
    NgxModalWrapperService.prototype.show = function (content, config) {
        this.isModalStacked = document.body.classList.contains('modal-open');
        var modalRef = _super.prototype.show.call(this, content, config);
        if (this.isModalStacked && this.modalsCount === 1) {
            var backdrop = document.querySelector('bs-modal-backdrop');
            backdrop.setAttribute('style', 'z-index: 1070');
        }
        if (this.isModalStacked) {
            // the following fixes issues with ngx-bootstrap modal showing behind deal sheet if deal sheet
            // was opened by engagement widget which is a ui-bootstrap modal:
            var containerList = document.querySelectorAll('modal-container');
            containerList.forEach(function (item) {
                item.setAttribute('style', 'display:block;z-index: 1070');
            });
        }
        return modalRef;
    };
    NgxModalWrapperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NgxModalWrapperService_Factory() { return new NgxModalWrapperService(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i1.ComponentLoaderFactory)); }, token: NgxModalWrapperService, providedIn: "root" });
    return NgxModalWrapperService;
}(modal_1.BsModalService));
exports.NgxModalWrapperService = NgxModalWrapperService;
