<div class="row contact-info">
    <div class="col-xs-2">
        <span class="icon-class icon -size-24 phone-icon"></span>
    </div>
    <div class="col-xs-10">
        <div class="row" *ngIf="phone?.value">
            <a *ngIf="!phone?.isBad && !doNotCall" href="{{'tel:' + phone?.value}}">{{phone?.value | localePhone}}</a>
            <span *ngIf="phone?.isBad && !doNotCall" class="strike-out">{{phone?.value | localePhone}}</span>
            <span *ngIf="doNotCall" class="strike-out" style="color:red">{{phone?.value | localePhone}}</span>
        </div>
        <div class="row">
            <span translate="{{type}}"></span>
        </div>
    </div>
</div>