<div [ngBusy]="{busy: busy, delay: 200}" class="deal-history">
    <h5 class="tab-header" translate="dealHistory90days"></h5>
    <default-tab-action [defaultTab]="defaultTab" [currentTab]="currentTab" (onAction)="onClick()"></default-tab-action>
    <hr />
    <div *ngIf="listDealHistoryTemp.length == 0" translate="noDealHistory90days"></div>
    <div *ngIf="listDealHistoryTemp.length > 0">
        <div>
            <span *ngFor="let filter of filters">
                <span *ngIf="filter.enabled" class="change-log-selection">
                    <input id="{{filter.moduleName}}" type="checkbox" class="qa-change-log-selection-{{filter.moduleName}}"
                           [(ngModel)]="filter.selected"
                           (change)="checkboxClicked()" />
                    <label for="{{filter.moduleName}}" translate="{{filter.key}}"></label>
                </span>
            </span>
        </div>
        <hr />
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th translate="typeOfChange"></th>
                        <th translate="dateTime"></th>
                        <th translate="alerts"></th>
                        <th translate="replacementMapping"></th>
                        <th translate="term"></th>
                        <th>
                            <span translate="payment"></span><icon icon="'info'" class="-small -brightblue -white" title="{{'paymentToolTipDealHistory' | translate}}"></icon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of listDealHistory">
                        <td>{{ row.changeTypes }}</td>
                        <td>{{ row.date | localeDateTime }}</td>
                        <td>
                            <alerts [values]="row.alerts" [showRelavantInfo]="false"></alerts>
                        </td>
                        <td>
                            <div class="mapping-details-container" *ngIf="row.photoPath">
                                <img [src]="row.photoPath" (error)="updateUrl($event, row)" /> {{row.yearID}} {{ row.trimFullName }}
                            </div>
                            <div class="mapping-details-container" *ngIf="!row.photoPath">
                                <img [src]="vehicleImageNotAvailableUrl" (error)="updateUrl($event, row)" /><span translate="naAbbrMessage"></span>
                            </div>
                        </td>
                        <td>{{ (row.replacementTerm ? row.replacementTerm : ('naAbbrMessage' | translate)  ) }}</td>
                        <td>{{ row.replacementPayment ? (row.replacementPayment | localeCurrency) : ('naAbbrMessage' | translate) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>