"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("@ngx-translate/core"));
var i2 = __importStar(require("./icon/icon.component.ngfactory"));
var i3 = __importStar(require("ngx-bootstrap/popover"));
var i4 = __importStar(require("ngx-bootstrap/component-loader"));
var i5 = __importStar(require("ngx-bootstrap/positioning"));
var i6 = __importStar(require("./icon/icon.component"));
var i7 = __importStar(require("@angular/platform-browser"));
var i8 = __importStar(require("./tooltip.component"));
var styles_TooltipComponent = [];
var RenderType_TooltipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipComponent, data: {} });
exports.RenderType_TooltipComponent = RenderType_TooltipComponent;
function View_TooltipComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h6", [], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.titleKey, ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.contentKey)); _ck(_v, 2, 0, currVal_1); }); }
function View_TooltipComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tooltipContainer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, "icon", [["icon", "'info'"], ["popover-placement", "right"], ["style", "padding-left:3px"], ["title", ""], ["triggers", "mouseenter:mouseleave"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(2, 212992, null, 0, i3.PopoverDirective, [i3.PopoverConfig, i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i4.ComponentLoaderFactory, i5.PositioningService], { popover: [0, "popover"], triggers: [1, "triggers"], containerClass: [2, "containerClass"] }, null), i0.ɵdid(3, 638976, null, 0, i6.IconComponent, [i7.DomSanitizer], { icon: [0, "icon"] }, null), (_l()(), i0.ɵand(0, [["popTemplate", 2]], null, 0, null, View_TooltipComponent_1))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4); var currVal_1 = "mouseenter:mouseleave"; var currVal_2 = "popoverContainer"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "'info'"; _ck(_v, 3, 0, currVal_3); }, null); }
exports.View_TooltipComponent_0 = View_TooltipComponent_0;
function View_TooltipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tooltip", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i0.ɵdid(1, 49152, null, 0, i8.TooltipComponent, [i3.PopoverModule], null, null)], null, null); }
exports.View_TooltipComponent_Host_0 = View_TooltipComponent_Host_0;
var TooltipComponentNgFactory = i0.ɵccf("tooltip", i8.TooltipComponent, View_TooltipComponent_Host_0, { titleKey: "titleKey", contentKey: "contentKey" }, {}, []);
exports.TooltipComponentNgFactory = TooltipComponentNgFactory;
