import { Component, OnInit } from '@angular/core';
import { DoNotCallStagingResponseDto, DoNotEmailStagingResponseDto } from '../../../generated/models';
import { CustomerService } from '../../../services/customer.service';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../../modules/storage/storage.service';
import { Modules } from '../../../constants';

@Component({
    selector: 'do-not-call-email',
    templateUrl: './do-not-call-email.component-ng.html',
    styleUrls: ['./do-not-call-email.component.scss']
})
export class DoNotCallEmailComponent {
    public readonly doNotCallType = 'donotcall';
    public readonly doNotEmailType = 'donotemail';

    public readonly selectTypeStep = 'SelectType';
    public readonly uploadFileStep = 'UploadFile';
    public readonly selectColumnStep = 'SelectColumn';
    public readonly confirmStep = 'ConfirmMatches';
    public readonly finishStep = 'FinishStep';
    public readonly okStep = 'OkStep';

    public readonly rowsSampleSize = 20;

    public steps = [this.selectTypeStep, this.uploadFileStep, this.selectColumnStep, this.confirmStep, this.finishStep, this.okStep];

    public currentStepIndex = 0;
    public listOf = 'phone numbers';
    public typeLabel = 'Do Not Call';
    public matchedMessage = '';
    public uploadMessage = '';

    private nextLabelNext = 'Next';
    private nextLabelFinish = 'Finish';
    private nextLabelOk = 'OK';

    public nextLabel = this.nextLabelNext;
    public fileName: string;
    public errorMessage: string;
    public statusMessage: string;
    public parsedCsv: string[][];
    public columnSelectMessage: string;
    public confirmMessage: string;
    public confirmList: DoNotCallStagingResponseDto | DoNotEmailStagingResponseDto = {};
    public typeSelected = this.doNotCallType;
    public busy: Subscription;

    private columnHoveredIndex: number = null;
    private selectedColumnIndex: number = null;
    private sampleSizeMessage = 'Only the first 20 are shown.';

    private readonly translationKeys = [
        'doNotEmailCallColumnTooltip',
        'doNotEmailCallMatchedRecords',
        'doNotEmailCallListOfPhoneNumbers',
        'doNotEmailCallListOfEmails',
        'doNotEmailCallTypeLabelEmail',
        'doNotEmailCallTypeLabelCall',
        'doNotEmailCallFileError',
        'doNotEmailCallNoItemsConfirmMessage',
        'next',
        'finish',
        'ok',
        'doNotEmailCallPageTitle',
        'doNotCallEmailSelectFileTypeLabel',
        'doNotCallEmailStep1Label',
        'doNotCallEmailStep2Label',
        'doNotCallEmailStep3Label',
        'upload',
        'doNotEmailCallColumnNameLabel',
        'cancel',
        'back'
    ];
    public translations: any = {};
    public hasDoNotCallModule = false;
    public hasDoNotEmailModule = false;


    constructor(private customerService: CustomerService, private translateService: TranslateService, storageService: StorageService) {
        translateService.get(this.translationKeys)
            .subscribe(translations => {
                this.translations = translations;

                this.listOf = this.translations.doNotEmailCallListOfPhoneNumbers;
                this.typeLabel = this.translations.doNotEmailCallTypeLabelCall;

                this.nextLabelNext = this.translations.next;
                this.nextLabelFinish = this.translations.finish;
                this.nextLabelOk = this.translations.ok;
            });

        const moduleIds = storageService.getItem('moduleIds');
        if (moduleIds) {
            this.hasDoNotCallModule = (moduleIds.indexOf(Modules.doNotCall) > -1);
            this.hasDoNotEmailModule = (moduleIds.indexOf(Modules.doNotEmail) > -1);
        }

        translateService.get('doNotEmailCallMatchedRecordsLimitedNumber', {limitedNumber: this.rowsSampleSize})
            .subscribe(sampleSizeTranslation => {this.sampleSizeMessage = sampleSizeTranslation; });
    }

    next() {
        if (this.currentStepIndex < this.steps.length - 1) {
            this.currentStepIndex++;
            this.handleNextStep();
        }
    }

    back() {
        if (this.currentStepIndex > 0) {
            this.currentStepIndex--;
            this.handlePreviousStep();
        }
    }

    cancel() {
        this.currentStepIndex = 0;
        this.handlePreviousStep();
    }

    shouldDisplayCancelBackButton() {
        return (this.currentStepIndex > 0 && this.currentStepIndex < this.steps.length - 2);
    }

    startColumnHover(columnIndex: number): void {
        this.columnHoveredIndex = columnIndex;
    }

    endColumnHover(columnIndex: number): void {
        if (this.columnHoveredIndex === columnIndex) { this.columnHoveredIndex = null; }
    }

    getColumnClass(columnIndex: number): string {
        let style = '';
        if (this.selectedColumnIndex === columnIndex) {
            style = 'selected';
        } else if (this.columnHoveredIndex === columnIndex) {
            style = 'hover';
        } else {
            style = '';
        }

        return style;
    }

    selectColumn(columnIndex: number) {
        this.selectedColumnIndex = columnIndex;
    }

    selectType(type: 'donotcall' | 'donotemail') {
        this.typeSelected = type;

        if (this.typeSelected === this.doNotEmailType) {
            this.listOf = this.translations.doNotEmailCallListOfEmails;
            this.typeLabel = this.translations.doNotEmailCallTypeLabelEmail;
        } else {
            this.listOf = this.translations.doNotEmailCallListOfPhoneNumbers;
            this.typeLabel = this.translations.doNotEmailCallTypeLabelCall;
        }
    }

    upload(file: File): void {

        this.fileName = file.name;
        const eightMB = 83886080;

        if ((file.name.lastIndexOf('.csv') < file.name.length - 5) ||
            (file.size > eightMB)) {

            this.errorMessage = this.translations.doNotEmailCallFileError;
            return;
        } else {
            this.errorMessage = '';
        }

        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = () => {
            const csv = reader.result as string;

            this.parsedCsv = this.parseCsv(csv);
            this.next();

            let columnSelectMessageTranslationKey: string;

            // Since we only have one column we don't need the user to select it, go straight to uploading it
            if (this.parsedCsv && this.parsedCsv.length > 0 && this.parsedCsv[0].length === 1) {
                columnSelectMessageTranslationKey = 'doNotEmailCallOneColumnMessage';
                this.selectColumn(0);
            } else {
                columnSelectMessageTranslationKey = 'doNotEmailCallMultipleColumnMessage';
            }

            this.matchedMessage = this.translateService
                .instant('doNotEmailCallMatchedRecords', { numberOfMatches: this.parsedCsv.length - 1 });

            if (this.parsedCsv.length > this.rowsSampleSize + 1) {
                this.matchedMessage += ' ' + this.sampleSizeMessage;
            }

            this.columnSelectMessage = this.translateService.instant(columnSelectMessageTranslationKey, { typeLabel: this.typeLabel, type: this.listOf });
        };
    }

    getNextDisabledStatus(): boolean {
        if (this.steps[this.currentStepIndex] === this.selectColumnStep) {
            return (this.selectedColumnIndex === null);
        }

        if (this.steps[this.currentStepIndex] === this.uploadFileStep) {
            return (!this.parsedCsv || (this.parsedCsv && this.parsedCsv.length === 0));
        }

        if (this.steps[this.currentStepIndex] === this.confirmStep) {
            return (!this.confirmList || !this.confirmList.customerMatchCount || this.confirmList.customerMatchCount === 0);
        }

        return false;
    }

    private resetFileInfo(): void {
        if (this.selectTypeStep === this.steps[this.currentStepIndex]) {
            this.errorMessage = '';
            this.fileName = '';
            this.parsedCsv = [];
            this.selectedColumnIndex = null;
            this.confirmList = {};
        }
    }

    private handleNextStep(): void {
        if (this.steps[this.currentStepIndex] === this.uploadFileStep) {
            this.uploadMessage = this.translateService.instant('doNotCallEmailUploadMessage', { type: this.listOf, typeLabel: this.typeLabel });
        }

        if (this.steps[this.currentStepIndex] === this.confirmStep) {
            this.uploadSelectedColumn();
        }

        if (this.steps[this.currentStepIndex] === this.finishStep) {
            this.finish();
        }

        if (this.steps[this.currentStepIndex] === this.okStep) {
            this.nextLabel = this.nextLabelNext;
            this.cancel();
        }
    }

    private handlePreviousStep(): void {
        if (this.steps[this.currentStepIndex] === this.selectColumnStep) {
            this.confirmList = {};
            this.errorMessage = '';
            this.nextLabel = this.nextLabelNext;
            return;
        }

        if (this.steps[this.currentStepIndex] === this.uploadFileStep) {
            this.nextLabel = this.nextLabelNext;
            this.resetFileInfo();

            return;
        }

        if (this.steps[this.currentStepIndex] === this.selectTypeStep) {
            this.nextLabel = this.nextLabelNext;
            this.resetFileInfo();

            this.selectType((this.hasDoNotCallModule) ? this.doNotCallType : this.doNotEmailType);
            this.statusMessage = '';
            return;
        }
    }

    private uploadSelectedColumn() {
        const selectedValues: string[] = [];

        for (const row of this.parsedCsv) {
            selectedValues.push(row[this.selectedColumnIndex]);
        }

        let observable;
        if (this.typeSelected === this.doNotEmailType) {
            observable = this.customerService.stageDoNotEmailList(selectedValues);
        } else {
            observable = this.customerService.stageDoNotCallList(selectedValues);
        }

        this.busy = observable
            .subscribe((result: DoNotEmailStagingResponseDto | DoNotCallStagingResponseDto) => {
                this.nextLabel = this.nextLabelFinish;

                if (result.customerMatchCount === 0) {
                    this.confirmMessage = this.translations.doNotEmailCallNoItemsConfirmMessage;
                    this.errorMessage = this.translateService.instant('doNotEmailCallNoItemsErrorMessage', { type: this.listOf });
                } else {
                    this.confirmMessage = this.translateService
                        .instant('doNotEmailCallMatchedConfirmMessage', { type: this.listOf, finish: this.nextLabelFinish, typeLabel: this.typeLabel });
                    this.confirmList = result;
                    this.matchedMessage = this.translateService
                        .instant('doNotEmailCallMatchedRecords', { numberOfMatches: this.confirmList.customerMatchCount });

                    if (this.confirmList.customerMatchCount > this.rowsSampleSize) {
                        this.matchedMessage += ' ' + this.sampleSizeMessage;
                    }
                }
            });
    }
    private finish() {
        let observable: Observable<number>;
        if (this.typeSelected === this.doNotEmailType) {
            observable = this.customerService.finishDoNotEmailList(this.confirmList.sessionID);
        } else {
            observable = this.customerService.finishDoNotCallList(this.confirmList.sessionID);
        }

        this.busy = observable
            .subscribe(() => {
                this.nextLabel = this.nextLabelOk;
                this.statusMessage = this.translateService.instant('doNotEmailCallFinishStatusMessage', { typeLabel: this.typeLabel });
            });
    }

    private parseCsv(csv: string): string[][] {
        if (csv.length <= 0) { return []; }

        const lines = csv.split('\n');

        const result: string[][] = [];
        for (const line of lines) {
            const columns = line.split(',');
            result.push(columns);
        }

        return result;
    }
}
