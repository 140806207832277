"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var services_1 = require("../generated/services");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../generated/services/deal-sheet-client.service"));
var RecentDealSheetService = /** @class */ (function () {
    function RecentDealSheetService(dealSheetClientService) {
        this.dealSheetClientService = dealSheetClientService;
    }
    RecentDealSheetService.prototype.getRecentDealSheets = function () {
        return this.dealSheetClientService.UserDealSheetHistoryGET().toPromise();
    };
    RecentDealSheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecentDealSheetService_Factory() { return new RecentDealSheetService(i0.ɵɵinject(i1.DealSheetClientService)); }, token: RecentDealSheetService, providedIn: "root" });
    return RecentDealSheetService;
}());
exports.RecentDealSheetService = RecentDealSheetService;
