import { TranslateService } from '@ngx-translate/core';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';

import en_CA from '../i18n/en-CA.json';
import en_GB from '../i18n/en-GB.json';
import en_US from '../i18n/en-US.json';
import es_US from '../i18n/es-US.json';
import fr_CA from '../i18n/fr-CA.json';
import fr_FR from '../i18n/fr-FR.json';

import { BehaviorSubject } from 'rxjs';

/** An observable that emits the currently-configured culture for the user. */
export const currentUserCulture$ = new BehaviorSubject<string>('en-US');

export function configureTranslation(translate: TranslateService, intlService: IntlService) {
    translate.setDefaultLang('en-US');
    translate.use('en-US');

    translate.setTranslation('en-CA', en_CA);
    translate.setTranslation('en-GB', en_GB);
    translate.setTranslation('en-US', en_US);
    translate.setTranslation('es-US', es_US);
    translate.setTranslation('fr-CA', fr_CA);
    translate.setTranslation('fr-FR', fr_FR);



    // Update angular-translate's culture when the current user culture changes
    currentUserCulture$.subscribe(cultureName => {
        translate.use(cultureName);
        (<CldrIntlService>intlService).localeId = cultureName;
    });
}
