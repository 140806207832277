<div class="admin-templates-edit-modal">
    <div class="modal-header">
        <div style="float:left">
            <h4 class="modal-title" translate="editTemplate"></h4>
        </div>
        <div style="float:right">
            <button type="button" class="close" aria-label="Close" (click)="onCancel()">
                <icon class="icon aa-icon-close-icon"></icon>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <iframe class="ifrmContainer" style="border: none;width: 100%;height: 680px" [src]="url | safe"></iframe>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onCancel()" translate="close"></button>        
    </div>
</div>
