"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("../services/user.service");
var dealer_service_1 = require("../services/dealer.service");
var menu_service_1 = require("../services/menu.service");
var angular_1 = require("@uirouter/angular");
var toaster_logger_service_1 = require("./toaster-logger.service");
var translation_1 = require("./translation");
var i0 = __importStar(require("@angular/core"));
var i1 = __importStar(require("../services/user.service"));
var i2 = __importStar(require("../services/dealer.service"));
var i3 = __importStar(require("../services/menu.service"));
var i4 = __importStar(require("../ajs-upgraded-providers"));
var i5 = __importStar(require("@uirouter/angular"));
var i6 = __importStar(require("./toaster-logger.service"));
var LoggedInDataInitializationService = /** @class */ (function () {
    function LoggedInDataInitializationService(userService, dealerService, menuService, tmhDynamicLocale, stateService, loggerService) {
        this.userService = userService;
        this.dealerService = dealerService;
        this.menuService = menuService;
        this.tmhDynamicLocale = tmhDynamicLocale;
        this.stateService = stateService;
        this.loggerService = loggerService;
    }
    LoggedInDataInitializationService.prototype.initializeData = function () {
        var _this = this;
        return this.userService.getUserProfile()
            .then(function (userProfile) { return _this.getUserProfileComplete(userProfile); }, function () { return _this.getUserProfileFailed; });
    };
    LoggedInDataInitializationService.prototype.getUserProfileComplete = function (userProfile) {
        var _this = this;
        var countryCode = userProfile.cultureName.substr(0, 2);
        this.tmhDynamicLocale.set(countryCode);
        return this.setSelectedDealers(userProfile)
            .then(function () {
            return _this.dealerService
                .getDealersOneToOneStatus()
                .catch(function (e) { return _this.loggerService.debug(e); })
                .then(function () {
                translation_1.currentUserCulture$.next(userProfile.cultureName);
                // Do not use userProfile.dealerIds here, because we might
                // be an employee user who has changed their dealer
                // context.
                var dealerIds = _this.dealerService.getSelectedDealerIds();
                if (dealerIds) {
                    return _this.menuService.getSiteMap(dealerIds);
                }
            });
        });
    };
    LoggedInDataInitializationService.prototype.setSelectedDealers = function (userProfile) {
        var _this = this;
        // Employee users should retain their selected dealers between login sessions.
        // (Assuming different employee users aren't using the same browser).
        // All other users must reset to their profile's list of dealers.
        var isDealerUser = !userProfile.employeeUser;
        if (isDealerUser || !this.dealerService.getSelectedDealers()) {
            if (userProfile.dealerIds.length == 1) {
                this.dealerService.updateSelectedDealers([
                    {
                        id: userProfile.dealerIds[0],
                        name: userProfile.dealerDisplayText
                    }
                ], userProfile.dealerGroupId, userProfile.dealerDisplayText);
                return Promise.resolve();
            }
            else {
                return this.dealerService.getDealers()
                    .then(function (dealers) {
                    var selectedDealers = dealers.map(function (o) {
                        return {
                            id: o.dealerID,
                            name: o.displayText
                        };
                    });
                    return _this.dealerService.updateSelectedDealers(selectedDealers, userProfile.dealerGroupId, userProfile.dealerDisplayText);
                });
            }
        }
        return Promise.resolve();
    };
    LoggedInDataInitializationService.prototype.getUserProfileFailed = function () {
        this.stateService.go('errorUnauthorized', { errorState: 'noUserProfile' });
    };
    LoggedInDataInitializationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggedInDataInitializationService_Factory() { return new LoggedInDataInitializationService(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.DealerService), i0.ɵɵinject(i3.MenuService), i0.ɵɵinject(i4.TmhDynamicLocale), i0.ɵɵinject(i5.StateService), i0.ɵɵinject(i6.ToasterLoggerService)); }, token: LoggedInDataInitializationService, providedIn: "root" });
    return LoggedInDataInitializationService;
}());
exports.LoggedInDataInitializationService = LoggedInDataInitializationService;
