
/* injects from baggage-loader */
require('./personalized-offer.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('personalizedOffer', {
    templateUrl: '/app/components/deal-sheet/contract/personalized-offer.component.html',
    bindings: {
        programDetails: '<'
    },
    controller: PersonalizedOfferController
});

/* @ngInject */
function PersonalizedOfferController() {
    var $ctrl = this;
}
