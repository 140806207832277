
/* injects from baggage-loader */
require('./financials-cash-sale.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('financialsCashSale', {
    templateUrl: '/app/components/deal-sheet/financial/financials-cash-sale.component.html',
    controller: FinancialsCashSaleController,
    bindings: {
        financials: '<',
        financialForm: '<',
        reset: '<',
        checkValidation: '&'
    }
});

/* @ngInject */
function FinancialsCashSaleController($timeout) {
    var $ctrl = this;

    $ctrl.$onChanges = onChanges;

    function onChanges(changes) {
        if (changes.reset && !changes.reset.isFirstChange()) {
            resetFinancials();
        }
    }

    $ctrl.$onInit = function () {
        $ctrl.checkValidation();
    };

    function resetFinancials() {
        $ctrl.financials.convert.soldByID = null;
        $ctrl.financials.convert.cashAmount = null;
        $ctrl.financials.convert.mileageAtDelivery = null;
        $ctrl.financials.convert.soldAsNew = true;
        $ctrl.financials.convert.hasExtWarranty = false;
        $ctrl.financialForm.$setPristine();

        $ctrl.checkValidation();
    }
}
