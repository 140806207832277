"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var static_1 = require("@angular/upgrade/static");
// import { Component, OnInit } from '@angular/core';
// @Component({
//   selector: 'activity-list',
//   templateUrl: './activity-list.component.html',
//   styleUrls: ['./activity-list.component.scss']
// })
// export class ActivityListComponent implements OnInit {
//   constructor() { }
//   ngOnInit() {
//   }
// }
var ActivityListComponent = /** @class */ (function (_super) {
    __extends(ActivityListComponent, _super);
    function ActivityListComponent(elementRef, injector) {
        var _this = _super.call(this, 'activityList', elementRef, injector) || this;
        _this.onScheduleSubmit = new core_1.EventEmitter();
        _this.onSetAsDefault = new core_1.EventEmitter();
        _this.onRefresh = new core_1.EventEmitter();
        return _this;
    }
    return ActivityListComponent;
}(static_1.UpgradeComponent));
exports.ActivityListComponent = ActivityListComponent;
