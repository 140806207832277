
/* injects from baggage-loader */
require('./customer-email-list.component.html');

import angular from 'angular';

angular.module('AaApp.Opp').component('customerEmailList', {
    templateUrl: '/app/components/deal-sheet/customer-email-list.component.html',
    bindings: {
        permissions: '<',
        currentEmail: '<',
        emails: '<',
        opportunity: '<',
        countryId: '<',
        openDate: '<',
        lastTouchedDate: '<',
        doNotEmail: '<',
        onEmailSend: '&'
    }
});
