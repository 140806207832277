"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var pando_communication_service_1 = require("../../../services/pando-communication.service");
var toaster_logger_service_1 = require("../../../core/toaster-logger.service");
var PandoAvailabilityComponent = /** @class */ (function () {
    function PandoAvailabilityComponent(communicationService, translateService, toaster) {
        this.communicationService = communicationService;
        this.translateService = translateService;
        this.toaster = toaster;
        this.availability = null;
        this.chatStaff = { Availability: null };
        this.availabilityReasons = {};
        this.error = false;
        this.busyNote = '';
        this.busySelectedOption = null;
        this.busyForMinutes = 15;
        this.unavailableNote = '';
        this.unavailableSelectedOption = null;
        this.isDropdownVisible = false;
        this.currentStateImage = "../../../images/timeclock-available.svg";
        this.userAvailability = { Available: 10, Busy: 20, NotAvailable: 100 };
        this.modelUserSelection = this.userAvailability.NotAvailable;
        this.errorMsgKey = '';
    }
    PandoAvailabilityComponent.prototype.ngOnInit = function () {
        this.getAvailabilityReasons();
        this.getChatStaff();
        //// block click event so drop down can stay open when clicked inside the panel not inside a button
        $('#availabilityPanelNgx').on("click", function (e) {
            if (e && e.target) {
                e.stopPropagation();
            }
        });
    };
    PandoAvailabilityComponent.prototype.ngOnChanges = function () {
        if (this.chatStaff && this.chatStaff.Availability != null)
            this.chatStaff.Availability = this.availability; // binding from pando-inbox rtc msg
    };
    PandoAvailabilityComponent.prototype.trackByBusyReasonsById = function (index, data) {
        if (!data)
            return null;
        return data.Id;
    };
    PandoAvailabilityComponent.prototype.trackByUnavailableReasonsById = function (index, data) {
        if (!data)
            return null;
        return data.Id;
    };
    PandoAvailabilityComponent.prototype.getAvailabilityReasons = function () {
        var _this = this;
        return this.communicationService.getAvailabilityReasons(this.isAutoAlertUser)
            .then(function (reasons) {
            if (reasons) {
                _this.availabilityReasons = reasons;
                _this.busyReasons = reasons.BusyReasons;
                _this.unavailableReasons = reasons.UnavailableReasons;
            }
        })
            .catch(function (e) { _this.handleError(e); });
    };
    PandoAvailabilityComponent.prototype.getChatStaff = function () {
        var _this = this;
        if (this.isAutoAlertUser) {
            return this.communicationService.getChatStaff(this.isAutoAlertUser)
                .then(function (chatStaff) {
                if (chatStaff && chatStaff.Availability) {
                    _this.chatStaff = chatStaff;
                    _this.modelUserSelection = _this.chatStaff.Availability.toString();
                }
            })
                .catch(function (e) { _this.handleError(e); });
        }
        else {
            // if PandoUser
            return;
        }
    };
    PandoAvailabilityComponent.prototype.setAvailable = function () {
        var _this = this;
        if (!this.chatStaff) {
            return;
        }
        return this.communicationService.setAvailable(this.isAutoAlertUser)
            .then(function (chatStaff) {
            if (chatStaff && chatStaff.Availability) {
                _this.isDropdownVisible = false;
                _this.chatStaff = chatStaff;
                _this.modelUserSelection = _this.chatStaff.Availability.toString();
                _this.resetUI();
            }
        })
            .catch(function (e) { _this.handleError(e); });
    };
    PandoAvailabilityComponent.prototype.setUnAvailable = function () {
        var _this = this;
        if (!this.chatStaff) {
            return;
        }
        if (!this.unavailableSelectedOption) {
            this.showWarningMessage("invalidSelection");
            return;
        }
        return this.communicationService.setUnavailable(this.isAutoAlertUser, this.unavailableNote, this.unavailableSelectedOption.Id)
            .then(function (chatStaff) {
            if (chatStaff) {
                _this.isDropdownVisible = false;
                _this.chatStaff = chatStaff;
                _this.resetUI();
                _this.showSuccessMessage("Your status has been changed to not available.");
            }
        })
            .catch(function (e) { _this.handleError(e); });
    };
    PandoAvailabilityComponent.prototype.setBusy = function () {
        var _this = this;
        if (!this.chatStaff) {
            return;
        }
        if (typeof (this.busyForMinutes) === "undefined") {
            this.showWarningMessage("invalidBusyMin");
            return;
        }
        if (!this.busySelectedOption) {
            this.showWarningMessage("invalidSelection");
            return;
        }
        return this.communicationService.setBusy(this.isAutoAlertUser, this.busyForMinutes, this.busyNote, this.busySelectedOption.Id)
            .then(function (chatStaff) {
            if (chatStaff) {
                _this.isDropdownVisible = false;
                _this.chatStaff = chatStaff;
                _this.resetUI();
                _this.showSuccessMessage("Your status has been changed as busy for the next " + _this.busyForMinutes + " minuites.");
            }
        })
            .catch(function (e) { _this.handleError(e); });
    };
    PandoAvailabilityComponent.prototype.onUserAvailabilityClick = function () {
        if (this.chatStaff) {
            if (this.chatStaff.Availability == this.userAvailability.NotAvailable) {
                this.isDropdownVisible = false;
                this.setAvailable();
            }
            else
                this.isDropdownVisible = true;
        }
    };
    PandoAvailabilityComponent.prototype.toggleAvailabilitySwitch = function () {
        this.error = null;
        this.busyNote = '';
        this.busySelectedOption = null;
        this.busyForMinutes = 15;
        this.unavailableNote = '';
        this.unavailableSelectedOption = null;
    };
    PandoAvailabilityComponent.prototype.validateBusyMin = function () {
        if (typeof (this.busyForMinutes) === "undefined")
            this.showWarningMessage("invalidBusyMin");
        else
            this.error = false;
    };
    PandoAvailabilityComponent.prototype.resetUI = function () {
        this.error = false;
        if (this.chatStaff && this.chatStaff.Availability != null)
            this.modelUserSelection = this.chatStaff.Availability.toString();
        this.toggleAvailabilitySwitch();
        this.isDropdownVisible = false;
        $(".dropdown").removeClass("open");
    };
    PandoAvailabilityComponent.prototype.submitAvailabilityStatus = function () {
        // modelUserSelection assignement in html: like *ngIf="modelUserSelection == userAvailability.NotAvailable"
        // may convert it to string so need to prase it.
        var availability = parseInt(this.modelUserSelection);
        switch (availability) {
            case this.userAvailability.Available:
                this.setAvailable();
                break;
            case this.userAvailability.Busy:
                this.setBusy();
                break;
            case this.userAvailability.NotAvailable:
                this.setUnAvailable();
                break;
        }
    };
    PandoAvailabilityComponent.prototype.handleError = function (e) {
        this.toaster.error("generalErrorMessageKey", e, "");
    };
    PandoAvailabilityComponent.prototype.showWarningMessage = function (msg) {
        this.errorMsgKey = msg;
        this.error = true;
    };
    PandoAvailabilityComponent.prototype.showSuccessMessage = function (msg) {
        this.errorMsgKey = msg;
        this.error = false;
    };
    return PandoAvailabilityComponent;
}());
exports.PandoAvailabilityComponent = PandoAvailabilityComponent;
