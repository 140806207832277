"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mobile-deal-sheet-wrapper[_ngcontent-eac-c1][_ngcontent-%COMP%]   .navbar-fixed-bottom[_ngcontent-%COMP%]{padding-top:4px}.mobile-vehicles-wrapper[_ngcontent-%COMP%]{height:calc(100vh - 130px)}.vehicle-seperator[_ngcontent-%COMP%]{width:100%;background-color:#7a8084;height:5px;margin:0}  .dealsheet-body .contract .contract-info{margin-bottom:0}  .current-contract-summary-header money-factor{margin-right:0}  .poweredby-blackbook label{margin:0}  .poweredby-blackbook img{margin:0}"];
exports.styles = styles;
